import React, { Suspense } from 'react'
import { LinearProgress } from '@material-ui/core'
import { Footer } from 'components'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import { makeStyles } from '@material-ui/core/styles'
import TopBar from './TopBar'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    minWidth: 960,
    whiteSpace: 'pre-wrap'
  },
  container: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    minHeight: 'calc(100% - 38px)',
  },
}))

const AuthLayout = ({ route }: RouteConfigComponentProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {route ? renderRoutes(route.routes) : null}
          </Suspense>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default AuthLayout
