import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useErrorStyle } from 'components'
import { FieldProps } from 'formik'
import React from 'react'

interface Props extends FieldProps {
  label: string
  optionList: {
    label: string
    value: string | number
  }[]
}

const Toggle = ({ field, form, label, optionList, ...others }: Props) => {
  const classes = useErrorStyle()
  const { name, value } = field
  const { errors, touched } = form
  const handleChange = (e: any) => {
    form.setFieldValue(name, Number.parseInt(e.target.value))
  }
  return (
    <FormControl error={Boolean(errors[name] && touched[name])}>
      <RadioGroup row value={value} onChange={handleChange} {...others}>
        {optionList.map(i => (
          <FormControlLabel
            control={<Radio color='primary' value={i.value} />}
            label={i.label}
            key={i.value}
          />
        ))}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default Toggle
