import React from 'react'
import { FieldProps } from 'formik'
import { KeyboardDatePicker as MuiDatePicker } from '@material-ui/pickers'
import { PropTypes, makeStyles } from '@material-ui/core'
import { Errors } from './Modal/MessageModal'

interface Props extends FieldProps {
  margin?: PropTypes.Margin
  disableFlag?: boolean
  icon?: React.ReactNode
  placeholder?: string
  onChange: (value: string) => void
  value: string
  name: string
  messageModalErrors: Errors
}

const useStyles = makeStyles({
  input: {
    border: '1px solid #8593a6',
    background: 'none #fff',
    boxSizing: 'border-box',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '30px',
    padding: '0 8px',
    cursor: 'text',
    fontSize: '14px',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
    '&:focus': {
      borderColor: '#3f71bd',
    },
    '&:disabled': {
      cursor: 'auto',
      border: '1px solid lightgrey',
      '&:hover': {
        border: '1px solid lightgrey',
      },
    },
  },
  datePicker: {
    minWidth: '40px !important',
  },
  inputForError: {
    border: '1px solid red',
    background: 'none #fff',
    boxSizing: 'border-box',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '30px',
    padding: '0 8px',
    cursor: 'text',
    fontSize: '14px',
    '&:focus': {
      border: '2px solid red',
    },
  },
  root: {
    height: 61,
    display: 'flex',
    '& .react-datepicker-wrapper': {
      flex: '1 0 0',
      marginRight: '16px',
    },
  },
  inputField: {
    width: '100%',
    padding: '0 20px 0 8px',
    background: 'none #fff',
    border: '1px solid #8593a6',
    borderRadius: '4px',
    boxSizing: 'border-box',
    cursor: 'auto',
    height: '32px',
    lineHeight: '30px',
    '&:disabled': {
      color: '#999999',
      backgroundColor: '#e0e0e0',
      borderColor: '#cccccc',
      pointerEvents: 'none',
    },
  },
})

const DatePicker = ({
  margin = 'dense',
  disableFlag = false,
  icon,
  placeholder = '',
  value,
  name,
  form,
  onChange,
  messageModalErrors
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { errorFlag, errorInfo } = messageModalErrors

  return (
    <div className={`${classes.root}`}>
      <MuiDatePicker
        InputProps={{ disableUnderline: true }}
        inputProps={{
          className:
            errorFlag
              ? classes.inputForError
              : classes.inputField,
        }}
        keyboardIcon={
          icon ? (
            icon
          ) : (
            <span
              className={`${'btn only-icon icon-input_calender'} ${
                classes.datePicker
              }`}
            />
          )
        }
        placeholder={placeholder}
        KeyboardButtonProps={{ edge: 'start' }}
        disabled={disableFlag}
        type={'datepicker'}
        inputVariant='standard'
        format='YYYY/MM/DD'
        fullWidth
        margin={margin}
        name={name}
        value={isNaN(Date.parse(value)) ? null : new Date(value)}
        inputValue={value}
        helperText={errorFlag ? errorInfo : null}
        error={Boolean(errorFlag)}
        onChange={(date, value) => {
          if(value){
            onChange(value)
          }else{
            onChange('')
          }
        }}
        autoOk={true}
        cancelLabel='キャンセル'
      />
    </div>
  )
}

export default DatePicker
