import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialValues1, initialValues2 } from 'pages/MCASS010/formConfig'
import { initialValues, initialValues3 } from 'pages/MCASS020/formConfig'
import { MCASS010CreateRequest } from 'types/MCASS010/MCASS010CreateRequest'
import { MCASS010Request } from 'types/MCASS010/MCASS010Request'

const openDialog030 = {
    showDialog030: false
}

export interface OpenDialog030 {
    [key: string]: boolean
    showDialog030: boolean
}

export interface JobSeekerInfo {
    [key: string]: string | boolean
    membersId: string
    familyName: string
    Name: string
    targetName: string
    jobSeekerId: string
    entryId: string
    showDialog: boolean
}

export interface SpamActReportInfo {
    [key: string]: string | number | boolean
    jobSeekerId: string
    entryId: string
    applicationRefusal: string
    interviewAbsence: string
    multipleApplication: string
    loseTouch: string
    careerMisrepresentation: string
    slander: string
    unrelated: string
    other: string
    harassmentContent: string
    harassmentDivision: string
    showDialog020: boolean
}

export interface SpamActReportValidationInfo {
    [key: string]: boolean
    showDialog020: boolean
}
export interface SpamActReportInfo020 {
    [key: string]: string
    harassmentDivision: string
    harassmentContent: string
    jobSeekerId: string
    entryId: string
    recruitmentManagementDivision: string
}

interface MCASS010Values {
    jobSeekerResults: JobSeekerInfo
    spamActReportInfoResult: SpamActReportInfo
    SpamActReportValidationInfoResult: SpamActReportValidationInfo
    spamActReportInfoResult020: SpamActReportInfo020
    showDialog030Result: OpenDialog030
}

const mcass010Values: MCASS010Values = {
    jobSeekerResults: initialValues1,
    spamActReportInfoResult: initialValues2,
    SpamActReportValidationInfoResult: initialValues,
    spamActReportInfoResult020: initialValues3,
    showDialog030Result: openDialog030
}

const spamActReportSlice = createSlice({
    name: 'spamActReport',
    initialState: mcass010Values,
    reducers: {
        getJobSeekerInfo(state, action: PayloadAction<MCASS010Request>) {
            return state
        },
        setJobSeekerInfo(state, action: PayloadAction<JobSeekerInfo>) {
            state.jobSeekerResults = action.payload
            state.spamActReportInfoResult.jobSeekerId = action.payload.jobSeekerId
            state.spamActReportInfoResult.entryId = action.payload.entryId
        },
        spamActReportValidation(state, action: PayloadAction<MCASS010CreateRequest>) {
            return state
        }, 
        setSpamActReportValidation(state, action: PayloadAction<SpamActReportInfo>) {
            state.spamActReportInfoResult = action.payload
            state.spamActReportInfoResult020.harassmentContent = action.payload.harassmentContent
            state.spamActReportInfoResult020.harassmentDivision = action.payload.harassmentDivision
            state.spamActReportInfoResult020.jobSeekerId = action.payload.jobSeekerId
            state.spamActReportInfoResult020.entryId = action.payload.entryId
            state.SpamActReportValidationInfoResult.showDialog020 = action.payload.showDialog020
        },
        sendSpamActReport(state, action: PayloadAction<SpamActReportInfo020>) {
            return state
        },
        setOpenDialog030(state, action: PayloadAction<OpenDialog030>) {
            state.showDialog030Result.showDialog030 = action.payload.showDialog030
        },
        closeDialog020(state) {
            state.SpamActReportValidationInfoResult.showDialog020 = false
        },
        closeDialog010(state) {
            state.jobSeekerResults.showDialog = false;
            state.spamActReportInfoResult.applicationRefusal = '0'
            state.spamActReportInfoResult.interviewAbsence = '0'
            state.spamActReportInfoResult.multipleApplication = '0'
            state.spamActReportInfoResult.loseTouch = '0'
            state.spamActReportInfoResult.careerMisrepresentation = '0'
            state.spamActReportInfoResult.slander = '0'
            state.spamActReportInfoResult.unrelated = '0'
            state.spamActReportInfoResult.other = '0'
            state.spamActReportInfoResult.harassmentContent = ''
        },
        closeDialog030(state) {
            state.showDialog030Result.showDialog030 = false;
        },
        openDialog010(state) {
            state.jobSeekerResults.showDialog = true;
        },
        setRecruitmentManagementDivision(state, action: PayloadAction<string>) {
            state.spamActReportInfoResult020.recruitmentManagementDivision = action.payload
        },
        setInitValues(state) {
            state.spamActReportInfoResult.showDialog020 = false
            state.spamActReportInfoResult.applicationRefusal = '0'
            state.spamActReportInfoResult.interviewAbsence = '0'
            state.spamActReportInfoResult.multipleApplication = '0'
            state.spamActReportInfoResult.loseTouch = '0'
            state.spamActReportInfoResult.careerMisrepresentation = '0'
            state.spamActReportInfoResult.slander = '0'
            state.spamActReportInfoResult.unrelated = '0'
            state.spamActReportInfoResult.other = '0'
            state.spamActReportInfoResult.harassmentContent = ''
        },
        setInitValuesAgain(state, action: PayloadAction<SpamActReportInfo>) {
            state.spamActReportInfoResult = action.payload
        }
    }
})


export const {
    getJobSeekerInfo,
    setJobSeekerInfo,
    spamActReportValidation,
    setSpamActReportValidation,
    sendSpamActReport,
    setOpenDialog030,
    closeDialog020,
    closeDialog010,
    closeDialog030,
    openDialog010,
    setRecruitmentManagementDivision,
    setInitValues,
    setInitValuesAgain,
} = spamActReportSlice.actions
export default spamActReportSlice.reducer

