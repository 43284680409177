import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '2.1rem',
    height: '64px',
    width: '362px',
  },
}));

const SearchButton = () => {
  const classes = useStyles();

  return (
    <button
      type="submit"
      className={`${classes.root} btn high on-icon icon-search`}>
      検索
    </button>
  );
};

export default SearchButton;
