import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { openModal } from 'reducers/messageReducer'
import { magiContants as contants } from 'utils/contants'
import { FormControl } from '..'
import { getMcaxs601Init, setSchoolDetailsDepartmentFlag, setSchoolGroupDetailsFlag } from 'reducers/advancedSearchModalReducer'
import { getMcaxs611Init } from 'reducers/advancedSearchModalReducer'
import { initRequest, SchoolDetail } from '../Config/schoolDetailsModalConfig'
import { Option, SchoolModalState } from '../Config/searchConditionConfig'
import { transform } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { initRequest as sddInitRequest } from '../Config/schoolDepartmentDetailsConfig'
import { innerSortFunc } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'

const optionSet = [
  {
    schoolDetailsName: '北海道大学',
    schoolDetailsId: 'comprehensiveDeviationValue1',
  },
  { schoolDetailsName: '東北大学', schoolDetailsId: 'countingDeviationValue1' },
  { schoolDetailsName: '東京大学', schoolDetailsId: 'languageDeviationValue1' },
  {
    schoolDetailsName: '東京工業大学',
    schoolDetailsId: 'comprehensiveDeviationValue2',
  },
  { schoolDetailsName: '一橋大学', schoolDetailsId: 'countingDeviationValue2' },
  {
    schoolDetailsName: '名古屋大学',
    schoolDetailsId: 'languageDeviationValue2',
  },
]

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  modal: {
    '& .MuiDialog-paper': {
      minWidth: '600px',
    },
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  link: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 0,
  },
  checkbox: {
    //display: 'grid',
    gridTemplateColumns: 'repeat(3, [col-start] max-content [col-end])',
    padding: 15,
  },
  button: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: '200px',
  },
  cancel: {
    marginRight: theme.spacing(2.5),
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
  },
  confirmButton: {
    marginLeft: theme.spacing(2.5),
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
  },
  formContainer: {
    paddingTop: 0,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  content: {
    float: 'left',
    '& div': {
      display: 'inline-block',
      margin: '0px 19px 0px 0px',
      cursor: 'pointer',
    },
    '& div input': {
      marginRight: '5px',
      cursor: 'pointer',
    }
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  title: string
  form: FormControl
  modalState: SchoolModalState
}

const SchoolDetailsModal = ({ open, onClose, title, form, modalState }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const schoolDetailsDepartmentFlag = useSelector((state: RootState) => state.advancedSearchModal.schoolDetailsDepartmentFlag)
  const schoolDetailsObj = form.values.schoolDetailsObj
  const options : Option[] = schoolDetailsObj !== null && schoolDetailsObj.length > 0 ? 
    schoolDetailsObj.map(i => {
      return { label: i.label, value: i.value }
    }) : []

  useEffect(() => {
    if (open) {
      if (modalState.schoolGroupSelected && modalState.schoolGroupSelected.length > 0) {
        initRequest.schoolGroupId = modalState.schoolGroupSelected.map(i => i.value)
        dispatch(getMcaxs601Init(initRequest))
        if(options && !schoolDetailsDepartmentFlag){
          setSelectSchoolDetails(options)
        }
      } else {
        dispatch(openModal(contants.MESSAGECODE_MCAXS601_001))
        onClose()
      }
    }
  }, [open])

  const searchConditionValues = useSelector(
    (state: RootState) => state.advancedSearch.searchCondition
  )
  const { schoolDetailsList } = useSelector(
    (state: RootState) => state.advancedSearchModal.schoolDetailsModalInfo
  )

  const [openDialog, setOpenDialog] = useState(false)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openOkDialog, setOpenOkDialog] = useState(false)

  const [selectSchoolDetails, setSelectSchoolDetails] = useState(
    modalState.schoolDetailsSelected || []
  )

  useEffect(() => {
    setSelectSchoolDetails(form.values.schoolDetailsObj || [])
  }, [form.values.schoolDetailsObj])
  
  const { undergraduateDepartmentDetailsList } = useSelector(
    (state: RootState) => state.advancedSearchModal.schoolDepartmentDetailsInfo
  )

  useEffect(() => {
    form.values.undergraduateDepartmentObj && form.values.undergraduateDepartmentObj.forEach(item => {
      undergraduateDepartmentDetailsList && undergraduateDepartmentDetailsList.some(detail => {
        if (item.schoolTypeCode === detail.schoolTypeCode
          && item.collegeCode === detail.collegeCode
          && item.facultyCode === detail.facultyCode
          && item.subjectCode === detail.subjectCode) {
            item.subjcetInfomationDagagaMasterId = detail.subjectInformationDagagaMasterId
            return true
          }
      })
    });
    if (open && undergraduateDepartmentDetailsList && undergraduateDepartmentDetailsList.length > 0) {
      modalState.schoolDepartmentDetails = true
      onClose()
    }
  }, [undergraduateDepartmentDetailsList])

  // キャンセル確認ダイアログ表示
  const handleClickDialog = () => {
    setOpenCancelDialog(true)
  }

  // OK確認ダイアログ表示
  const handleOkDialog = () => {
    setOpenOkDialog(true)
  }

  const handleCancel = () => {
    setOpenDialog(false)
    setOpenCancelDialog(false)
    setOpenOkDialog(false)
  }

  const handleCancelOk = () => {
    setOpenCancelDialog(false)
    dispatch(setSchoolDetailsDepartmentFlag(false))
    onClose()
  }

  const handleDialog = () => {
    setOpenDialog(false)
    modalState.schoolGroup = true
    dispatch(setSchoolGroupDetailsFlag(true))
    if(!options){
      setSelectSchoolDetails([])
    }
    onClose()
  }

  const handleClick = (modalName: string, open = true) => () => {
    if (modalName === 'schoolGroup') {
      if (selectSchoolDetails.length > 0) {
        setOpenDialog(true)
      } else {
        dispatch(setSchoolGroupDetailsFlag(true))
        modalState.schoolGroup = true
        onClose()
      }
    } else if ( modalName === 'schoolDepartmentDetails') { 
      eraceInvisibleSelected()
      if (modalState.schoolDetailsSelected.length === 0 ) {
        dispatch(openModal(contants.MESSAGECODE_MCAXS601_003))
      } else {
        sddInitRequest.schoolDetailsId = modalState.schoolDetailsSelected.map(
          i => i.value
        )
        dispatch(setSchoolDetailsDepartmentFlag(false))
        dispatch(getMcaxs611Init(sddInitRequest))
      }
    }
  }

  // チェックボックス処理
  const handleSelect = (detail: Option) => (e: any) => {
    const selectSchoolDetailsOption = selectSchoolDetails.filter(i => i.value === detail.value)
    if (selectSchoolDetailsOption.length > 0) {
      setSelectSchoolDetails(selectSchoolDetails.filter(i => i.value !== detail.value))
    } else {
      setSelectSchoolDetails([...selectSchoolDetails, detail])
    }
  }

  // OKボタン押下時
  const handleOk = () => {
    dispatch(setSchoolDetailsDepartmentFlag(false))

    onClose()
    setOpenOkDialog(false)
    modalState.schoolDetails = false

    const newSarchConditionValues: any = Object.assign(
      {},
      searchConditionValues
    )

    eraceInvisibleSelected()
    
    //選択した値を画面の選択肢の並び順でソート
    const orderObjArray = schoolDetailsList.map(i=>({schoolDetailsId: i.collegeCode }))
    const sortedSelected = [...modalState.schoolDetailsSelected].sort((a, b) => {
        return innerSortFunc(
          { schoolDetailsId: a.value },
          { schoolDetailsId: b.value },
          orderObjArray,
        )
    })
    modalState.schoolDetailsSelected = sortedSelected

    // 学部・学科詳細の情報は削除
    modalState.schoolDepartmentDetailsSelected = []
    form.setFieldValue('undergraduateDepartmentObj', [])
    form.setFieldValue('schoolDetailsObj', modalState.schoolDetailsSelected)
    form.setFieldValue('schoolGroupObj', modalState.schoolGroupSelected)
  }

  const eraceInvisibleSelected = () => {
    // 表示していないチェックの入った項目の削除
    const newSchoolDepartmentObj: any[] = []
    if (selectSchoolDetails.length > 0) {
      selectSchoolDetails.map(i => 
        schoolDetailsList
          .filter(j => i.value === j.collegeCode)
          .map(j => {
            if (!newSchoolDepartmentObj.includes(i)) {
              newSchoolDepartmentObj.push(i)
            }
          }))
    }
    modalState.schoolDetailsSelected = newSchoolDepartmentObj
  } 

  return (
    <div>
      <Dialog fullWidth open={open} className={classes.modal + ' mainDialog'} maxWidth='lg'>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>{title}</label>
        </DialogTitle>
        <DialogContent className={classes.checkbox}>
          {schoolDetailsList
            ? schoolDetailsList
                .map(transform)
                .map(detail => (
                  <div className={classes.content}>
                    <div onClick={handleSelect(detail)} >
                      <input type="checkbox" checked={selectSchoolDetails.some(i => i.value === detail.value)} />{detail.label}
                    </div>
                  </div>
                ))
            : null}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClick('schoolGroup')}
            color='primary'
            variant='contained'
            className={classes.button}>
            学校グループを指定する
          </Button>
          <Button
            onClick={handleClick('schoolDepartmentDetails')}
            color='primary'
            variant='contained'
            className={classes.button}>
            学部・学科詳細を指定する
          </Button>
        </DialogActions>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClickDialog}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleOkDialog}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS601_002}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleDialog} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCancelDialog} onClose={handleCancel}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS601_004}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOkDialog} onClose={handleCancel}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS601_005}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export { SchoolDetailsModal }
