import React, { useState } from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { useErrorStyle } from 'components'
import { magiContants } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '&:disabled': {
      color: '#000000',
      border: '1px solid #000000',
      backgroundColor: '#ffffff',
    },
  },
  errorBorder: {
    borderColor: '#e53935',
  }
}))

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (value: any) => void
  defaultValue?: boolean
  secondFormName?: string
}

const SelectMessage = ({
  className,
  optionList,
  placeholder,
  field,
  form,
  disabled,
  onChange,
  defaultValue,
  secondFormName,
}: Props) => {
  const classes = useStyles()
  const errorclasses = useErrorStyle()
  const { name, value } = field
  const { errors, touched } = form

  React.useEffect(() => {
    if (defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  // 郵便番号項目削除処理
  React.useEffect(() => {
    if (touched[name] && value !== magiContants.PREFECTURES_OFFLINE) {
      if (secondFormName) {
        if (secondFormName === 'prefectures' || secondFormName === 'prefecturesName') {
          form.setFieldValue('postalCode', '')
        } else if (secondFormName === 'vacPrefectures' || secondFormName === 'vacPrefecturesName') {
          form.setFieldValue('vacPostalCode', '')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (e: any) => {
    if (onChange !== undefined) {
      onChange(e)
    }
    form.setFieldValue(name, e.target.value)
    form.setFieldTouched(name, true)
    if (secondFormName) {
      // eslint-disable-next-line prefer-const
      let item: any = optionList.find((i: Option) => {
        return i.value.toString() === e.target.value.toString()
      })
      form.setFieldValue(secondFormName, item ? item.label : '')
      form.setFieldTouched(secondFormName, true)
    }
  }

  return (
    <>
      <div className={className} style={{ backgroundColor: '#ffffff' }}>
        <select
          name={ name }
          disabled={disabled}
          className={`${classes.root} ${
            errors[name] && touched[name] ? classes.errorBorder : null
          }`}
          value={value}
          onChange={handleChange}>
          {placeholder ? <option value=''>{placeholder}</option> : null}
          {optionList.map(i => (
            <option key={i.value} value={i.value}>
              {i.label}
            </option>
          ))}
        </select>
      </div>
      {errors[name] && touched[name] ? (
        <p className={errorclasses.helperText} style={{ fontFamily: "'Noto Sans JP', sans-serif" }}>{errors[name]}</p>
      ) : null}
    </>
  )
}
export default SelectMessage
