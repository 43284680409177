import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'

const initialValues = {
  internalId: '0',
  searchName:'',
  sysVersionNumber:  '0',
  sysVersionNumber1:  '0',
  searchCriteria:'',
  searchInsertName:'',
  // 次期開発9月#51248 start
  searchInsertActionName:'',
  // 次期開発9月#51248 end
  selectRadio: '0',
  nameCheck: '',
}
const selectRadioList = [
    { id: 'selectInit', label: '既存の検索条件に上書き保存', value: '0' },
    { id: 'selectNew', label: '新規条件として保存', value: '1' },
    // 次期開発9月#51248 start
    { id: 'selectAction', label: 'アクション設定条件として保存',value: '2'},
    // 次期開発9月#51248 end
]

const textMap = {
    OK: {
      submit: 'OK',
      message: getMessage(magiContants.MESSAGECODE_MCARS030_008),
    },
    cancel: {
      cancel: 'キャンセルする',
      message: getMessage(magiContants.MESSAGECODE_MCARS030_007),
    },
  }

const buttonTextMap: { [key: string]: string } = {
    no: 'キャンセル',
    yes: 'OK',
    cancel: 'キャンセルする',
    ok: 'OK',
  }

export { initialValues,selectRadioList,textMap,buttonTextMap }