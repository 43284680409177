import { all, call, put, takeLatest, select, takeLeading} from 'redux-saga/effects'
import { routeList } from 'routes/routes'
import history from 'utils/history'

import {
  mcags010Init, setNotAdoptedNotification, updateNotAdoptedNotification,
} from 'reducers/notAdoptedNotificationReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {getInit, update} from 'apis/MCAGS010Api'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
function* initSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(getInit,recruitmentManagementDivision)
    yield put(setNotAdoptedNotification(data))
  } catch (error) {
    if(error.message==='MCAGS010-012'|| error.message==='MAACOMMON-009'){
      history.push(routeList.commonSettingsMenu)
    }
    yield put(openModal(error.message))
  }
}

function* updateSaga(action: any) {
  try {
    yield call(update, action.payload.data)
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const initData = yield call(getInit,recruitmentManagementDivision)
    yield put(setNotAdoptedNotification(initData))
    yield put(openSnackbar(action.payload.message))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* notAdoptedNotificationSaga() {
  yield all([
    takeLatest(mcags010Init, initSaga),
    takeLeading(updateNotAdoptedNotification, updateSaga),
  ])
}
