import { getMCAXS441InitRequest } from 'apis/MCAXS441Api'
import { getMCAXS451InitRequest } from 'apis/MCAXS451Api'
import { getMCAXS461InitRequest } from 'apis/MCAXS461Api'
import { getMCAXS471InitRequest } from 'apis/MCAXS471Api'
import { getMCAXS481InitRequest, pushSeminarAfterScheduleHiddenCheckBoxRequest } from 'apis/MCAXS481Api'
import { getMCAXS491InitRequest } from 'apis/MCAXS491Api'
import { getMCAXS501InitRequest } from 'apis/MCAXS501Api'
import { getMCAXS511InitRequest } from 'apis/MCAXS511Api'
import { getMCAXS521InitRequest } from 'apis/MCAXS521Api'
import { getMCAXS531InitRequest } from 'apis/MCAXS531Api'
import { getMCAXS541InitRequest } from 'apis/MCAXS541Api'
import { getMCAXS551InitRequest } from 'apis/MCAXS551Api'
import { getMCAXS561InitRequest } from 'apis/MCAXS561Api'
import { getMCAXS571InitRequest } from 'apis/MCAXS571Api'
import { getMCAXS581InitRequest } from 'apis/MCAXS581Api'
import { getMCAXS591InitRequest } from 'apis/MCAXS591Api'
import { getMCAXS601InitRequest } from 'apis/MCAXS601Api'
import { getMCAXS611InitRequest } from 'apis/MCAXS611Api'
import { getMCAXS621InitRequest } from 'apis/MCAXS621Api'
import { getMCAXS691InitRequest } from 'apis/MCAXS691Api'
import { FlowMasterModalInfo } from 'componentsHsc/AdvancedSearch/Config/SelectionFlowStepPassFailModalConfig'
import { WebInterviewParticipationModalInfo } from 'componentsHsc/AdvancedSearch/Config/webInterviewParticipationModalConfig'
import moment from 'moment'
import {
  getMcaxs441Init,
  getMcaxs451Init,
  getMcaxs461Init,
  getMcaxs471Init,
  setMcaxs471Init,
  getMcaxs481Init,
  setMcaxs481Init,
  // 26P #80786 start
  pushSeminarAfterScheduleHiddenCheckBox,
  // 26P #80786 end
  getMcaxs491Init,
  setMcaxs491Init,
  getMcaxs501Init,
  setMcaxs501Init,
  getMcaxs511Init,
  setMcaxs511Init,
  getMcaxs521Init,
  getMcaxs531Init,
  setMcaxs531Init,
  getMcaxs541Init,
  getMcaxs551Init,
  setMcaxs551Init,
  getMcaxs561Init,
  setMcaxs561Init,
  getMcaxs571Init,
  setMcaxs571Init,
  getMcaxs581Init,
  getMcaxs591Init,
  setMcaxs591Init,
  getMcaxs601Init,
  setMcaxs601Init,
  getMcaxs611Init,
  setMcaxs611Init,
  setMcaxs541Init,
  getMcaxs691Init,
  setMcaxs691Init,
  getMcaxs621Init,
  setMcaxs621Init,
  pushOperationLogFor88924,
} from 'reducers/advancedSearchModalReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { all, call, put, takeLatest } from 'redux-saga/effects'

// イメジス情報指定モーダル情報を取得
function* getMcaxs441InitSaga() {
  try {
    yield call(getMCAXS441InitRequest)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// GAB情報指定モーダル情報を取得
function* getMcaxs451InitSaga() {
  try {
    yield call(getMCAXS451InitRequest)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// CAB情報指定モーダル情報を取得
function* getMcaxs461InitSaga() {
  try {
    yield call(getMCAXS461InitRequest)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// イベント参加状況指定モーダル情報を取得
function* getMcaxs471InitSaga() {
  try {
    const data = yield call(getMCAXS471InitRequest)
    yield put(setMcaxs471Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// セミナー受付日程・予約状況指定モーダル情報を取得
function* getMcaxs481InitSaga() {
  try {
    const data = yield call(getMCAXS481InitRequest)
    yield put(setMcaxs481Init(data))
    const log = {
        location:"setMcaxs481Init",
        storedTime: moment(new Date()).format('MM/DD HH:mm:ss'),
        seminarListLength: data.seminarList !== undefined? `${data.seminarList.length}`: "undefined",
    }
    yield put(pushOperationLogFor88924(JSON.stringify(log)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 26P #80786 start
// 「ok」ボタン押下、"開催後の日程を非表示にする"チェックボックスの状態保存
function* pushSeminarAfterScheduleHiddenCheckBoxSaga(action: ReturnType<typeof pushSeminarAfterScheduleHiddenCheckBox>) {
  try {
    yield call(pushSeminarAfterScheduleHiddenCheckBoxRequest, action.payload.param1)
    action.payload.handleClose()
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 26P #80786 end

// Webセミナー視聴状況指定モーダル情報を取得
function* getMcaxs491InitSaga() {
  try {
    const data = yield call(getMCAXS491InitRequest)
    yield put(setMcaxs491Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 管理項目指定モーダル情報を取得
function* getMcaxs501InitSaga() {
  try {
    const data = yield call(getMCAXS501InitRequest)
    yield put(setMcaxs501Init(data))
  } catch (error) {
    yield put(openSnackbar(error.message))
  }
}

// エントリー有無指定モーダル情報を取得
function* getMcaxs511InitSaga(action: ReturnType<typeof getMcaxs511Init>) {
  try {
    const data = yield call(getMCAXS511InitRequest)
    yield put(setMcaxs511Init(data))
    action.payload()
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 都道府県指定モーダル情報を取得
function* getMcaxs521InitSaga() {
  try {
    yield call(getMCAXS521InitRequest)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// エントリー日指定モーダル情報を取得
function* getMcaxs531InitSaga(action: ReturnType<typeof getMcaxs531Init>) {
  try {
    const data = yield call(getMCAXS531InitRequest)
    yield put(setMcaxs531Init(data))
    yield action.payload.initializeErrors(data)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// メッセージ指定モーダル情報を取得
function* getMcaxs541InitSaga() {
  try {
    const data = yield call(getMCAXS541InitRequest)
    yield put(setMcaxs541Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 学生分類指定モーダル情報を取得
function* getMcaxs551InitSaga() {
  try {
    const data = yield call(getMCAXS551InitRequest)
    yield put(setMcaxs551Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// アンケート回答内容指定モーダル情報を取得
function* getMcaxs561InitSaga(action: ReturnType<typeof getMcaxs561Init>) {
  try {
    const data = yield call(getMCAXS561InitRequest, action.payload)
    yield put(setMcaxs561Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 学校グループ指定モーダル情報を取得
function* getMcaxs571InitSaga() {
  try {
    const data = yield call(getMCAXS571InitRequest)
    yield put(setMcaxs571Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// OPQ情報指定モーダル情報を取得
function* getMcaxs581InitSaga() {
  try {
    yield call(getMCAXS581InitRequest)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 学科詳細指定モーダル情報を取得
function* getMcaxs591InitSaga(action: ReturnType<typeof getMcaxs591Init>) {
  try {
    const data = yield call(getMCAXS591InitRequest, action.payload)
    yield put(setMcaxs591Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 学校詳細指定モーダル情報を取得
function* getMcaxs601InitSaga(action: ReturnType<typeof getMcaxs601Init>) {
  try {
    const data = yield call(getMCAXS601InitRequest, action.payload)
    yield put(setMcaxs601Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 学部・学科詳細指定モーダル情報を取得
function* getMcaxs611InitSaga(action: ReturnType<typeof getMcaxs611Init>) {
  try {
    const data = yield call(getMCAXS611InitRequest, action.payload)
    yield put(setMcaxs611Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 24KH #61555 start
// WEB面談参加状況を指定モーダル情報を取得
function* getMcaxs621InitSaga() {
  try {
    const data: WebInterviewParticipationModalInfo = yield call(getMCAXS621InitRequest)
    yield put(setMcaxs621Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 24KH #61555 end

// 選考フロー×選考ステップ×合否指定モーダル情報を取得
function* getMcaxs691InitSaga() {
  try {
    const data: FlowMasterModalInfo = yield call(getMCAXS691InitRequest)
    yield put(setMcaxs691Init(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* advancedSearchModalSaga() {
  yield all([
    takeLatest(getMcaxs441Init, getMcaxs441InitSaga),
    takeLatest(getMcaxs451Init, getMcaxs451InitSaga),
    takeLatest(getMcaxs461Init, getMcaxs461InitSaga),
    takeLatest(getMcaxs471Init, getMcaxs471InitSaga),
    takeLatest(getMcaxs481Init, getMcaxs481InitSaga),
    takeLatest(getMcaxs491Init, getMcaxs491InitSaga),
    // 26P #80786 start
    takeLatest(pushSeminarAfterScheduleHiddenCheckBox, pushSeminarAfterScheduleHiddenCheckBoxSaga),
    // 26P #80786 end
    takeLatest(getMcaxs501Init, getMcaxs501InitSaga),
    takeLatest(getMcaxs511Init, getMcaxs511InitSaga),
    takeLatest(getMcaxs521Init, getMcaxs521InitSaga),
    takeLatest(getMcaxs531Init, getMcaxs531InitSaga),
    takeLatest(getMcaxs541Init, getMcaxs541InitSaga),
    takeLatest(getMcaxs551Init, getMcaxs551InitSaga),
    takeLatest(getMcaxs561Init, getMcaxs561InitSaga),
    takeLatest(getMcaxs581Init, getMcaxs581InitSaga),
    takeLatest(getMcaxs591Init, getMcaxs591InitSaga),
    takeLatest(getMcaxs601Init, getMcaxs601InitSaga),
    takeLatest(getMcaxs611Init, getMcaxs611InitSaga),
    // 24KH #61555 start
    takeLatest(getMcaxs621Init, getMcaxs621InitSaga),
    // 24KH #61555 end
    takeLatest(getMcaxs691Init, getMcaxs691InitSaga),
  ])
  yield all([
    takeLatest(getMcaxs571Init, getMcaxs571InitSaga),
  ])
}
