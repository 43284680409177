export interface InitResultt {
  selectedRequestTemplateSettingId: string  //選択中提出リクエストテンプレート設定ID
  sendTimeFlag : string  //タイマー送信する
  sendCount : string  //My CareerBox送信件数
  emailFailureCount : number  //メール送信失敗件数
  requestTemplateSettingId : string  //提出リクエストテンプレートID
  requestTemplateName	: string  //提出リクエストテンプレート名
}

export interface NewRequest {
  requestTemplateName	: string ; //提出リクエストテンプレート名
  //targetYear	: string ; //	対象年度
  destinationSelection : string; //送信先の選択
  submissionsSelectId	: number ; //	提出物内容選択ID
  submissionsName : string ;//提出物内容名
  submissionsNameStudent : string; //提出物内容名（学生画面表示用）
  submissionsEntryFlg : number ;//提出物エントリシートフラグ
  submissionsResumeFlg : number ;//提出物履歴書フラグ
  submissionsResearchFlg : number ;//提出物研究概要書フラグ
  submissionsOriginalFlg : number ;//提出物オリジナル設問フラグ
  submissionsFileFlg : number ;//提出物ファイル提出フラグ
  limitDateDetermineFlag	: number ; //	提出締切指定フラグ
  determineDateTime	: string  ;//	提出締切指定日時
  requestDayTimeLaterDay	: number;  //	依頼日から○日後
  requestDayTimeToTime	: number; //	依頼日から△時まで
  anytimeReceptionEndDateTime	: string ; //	随時受付終了日時
  reRequestReceptFlag	: number ; //	再提出受付フラグ
  senderCompanyName		: string  ;//送信元会社名
  senderMailAddress	: string;  //	送信元メールアドレス
  requestSubject		: string ; //(提出リクエスト)件名
  requestText		: string ; //(提出リクエスト)本文
  attachmentName		: string[] ; //添付ファイル名
  fileManagementId: string[] //	提出リクエストテンプレート添付ファイルID
  homepageTitle1	: string|null ; //	ホームページタイトル1
  homepageTitle2	: string|null ; //	ホームページタイトル2
  homepageTitle3	: string|null ; //	ホームページタイトル3
  homepageUrl1	: string|null ; //	ホームページURL1
  homepageUrl2	: string|null ; //	ホームページURL2
  homepageUrl3	: string|null ; //	ホームページURL3
  thanksMailSetFlag : number ;//サンクスメール設定フラグ
  thanksMailTemplateId	: number; //	サンクスメールテンプレートID
  thanksMailTemplateName : string ;//サンクスメールテンプレート名
  thanksMessageSubject	: string ; //	（サンクス）件名
  thanksMessageText	: string  ;//	（サンクス）本文
  studentReferenceId	: number  ;//	問い合わせ先選択ID
  studentReferenceName : string ;//問い合わせ先名
  studentReferenceCompanyName : string ;//問い合わせ先企業名
  contactDepartmentNameAndManagerName: string, // お問い合わせ先内容部署名担当者氏名
  studentReferenceTelephoneNumber : string ;//問い合わせ先電話番号
  studentReferenceMailAddress : string; //問い合わせ先メールアドレス
  personalDataHandlingId	: number ; //	個人情報の取り扱い選択ID
  personalDataHandlingName : string ;//個人情報の取り扱い名
  //forbiddenWordsList   : string [];// 禁止語リスト
  //recruitmentManagementDivision: string;
  submissionsDetails : string;
}

export interface OrverWriteRequest {
  requestTemplateId	: string;  //提出リクエストテンプレートID
  requestTemplateName	: string ; //提出リクエストテンプレート名
  //targetYear	: string ; //	対象年度
  destinationSelection : string; //送信先の選択
  submissionsSelectId	: number ; //	提出物内容選択ID
  submissionsName : string ;//提出物内容名
  submissionsNameStudent : string; //提出物内容名（学生画面表示用）
  submissionsEntryFlg : number ;//提出物エントリシートフラグ
  submissionsResumeFlg : number ;//提出物履歴書フラグ
  submissionsResearchFlg : number ;//提出物研究概要書フラグ
  submissionsOriginalFlg : number ;//提出物オリジナル設問フラグ
  submissionsFileFlg : number ;//提出物ファイル提出フラグ
  limitDateDetermineFlag	: number ; //	提出締切指定フラグ
  determineDateTime	: string  ;//	提出締切指定日時
  requestDayTimeLaterDay	: number;  //	依頼日から○日後
  requestDayTimeToTime	: number; //	依頼日から△時まで
  anytimeReceptionEndDateTime	: string ; //	随時受付終了日時
  reRequestReceptFlag	: number ; //	再提出受付フラグ
  senderCompanyName		: string  ;//送信元会社名
  senderMailAddress	: string;  //	送信元メールアドレス
  requestSubject		: string ; //(提出リクエスト)件名
  requestText		: string ; //(提出リクエスト)本文
  attachmentName		: string[] ; //添付ファイル名
  fileManagementId: string[] //	提出リクエストテンプレート添付ファイルID
  homepageTitle1	: string|null ; //	ホームページタイトル1
  homepageTitle2	: string|null ; //	ホームページタイトル2
  homepageTitle3	: string|null ; //	ホームページタイトル3
  homepageUrl1	: string|null ; //	ホームページURL1
  homepageUrl2	: string|null ; //	ホームページURL2
  homepageUrl3	: string|null ; //	ホームページURL3
  thanksMailSetFlag : number ;//サンクスメール設定フラグ
  thanksMailTemplateId	: number; //	サンクスメールテンプレートID
  thanksMailTemplateName : string ;//サンクスメールテンプレート名
  thanksMessageSubject	: string ; //	（サンクス）件名
  thanksMessageText	: string  ;//	（サンクス）本文
  studentReferenceId	: number  ;//	問い合わせ先選択ID
  studentReferenceName : string ;//問い合わせ先名
  studentReferenceCompanyName : string ;//問い合わせ先企業名
  contactDepartmentNameAndManagerName: string, // お問い合わせ先内容部署名担当者氏名
  studentReferenceTelephoneNumber : string ;//問い合わせ先電話番号
  studentReferenceMailAddress : string; //問い合わせ先メールアドレス
  personalDataHandlingId	: number ; //	個人情報の取り扱い選択ID
  personalDataHandlingName : string ;//個人情報の取り扱い名
  //forbiddenWordsList   : string [];// 禁止語リスト
  //recruitmentManagementDivision: string;
  sysVersionNumber: string;
  submissionsDetails : string;
}

const initResultt: InitResultt = {
  selectedRequestTemplateSettingId: '',  //選択中提出リクエストテンプレート設定ID
  sendTimeFlag :  '0',  //タイマー送信する
  sendCount :  '',  //My CareerBox送信件数
  emailFailureCount :  0,  //メール送信失敗件数
  requestTemplateSettingId :  '',  //提出リクエストテンプレートID
  requestTemplateName	:  '',  //提出リクエストテンプレート名
}

const newRequest: NewRequest = {
  //recruitmentManagementDivision: '',//採用管理区分
  requestTemplateName: '', //テンプレート名
  //targetYear:'',  //対象年度
  destinationSelection: '3', //送信先区分
  submissionsSelectId: 0, //提出物内容選択ID
  submissionsName: '',  //提出物内容（企業管理用）
  submissionsNameStudent: '', //提出物内容（学生画面表示用）
  submissionsEntryFlg: 0, //提出物エントリシートフラグ
  submissionsResumeFlg: 0, //提出物履歴書フラグ
  submissionsResearchFlg: 0, //提出物研究概要書フラグ
  submissionsOriginalFlg: 0, //提出物オリジナル設問フラグ
  submissionsFileFlg: 0, //提出物ファイル提出フラグ
  limitDateDetermineFlag: 0, //提出〆切区分
  determineDateTime: '', //提出〆切（日時指定）
  requestDayTimeLaterDay: 0, //提出〆切（依頼日から（日後））
  requestDayTimeToTime: 0, //提出〆切（依頼日から（時））
  anytimeReceptionEndDateTime: '',  //提出〆切（随時受付終了）
  reRequestReceptFlag: 0,  //再提出の受付区分
  homepageTitle1: '', //ホームページタイトル1
  homepageTitle2: '', //ホームページタイトル2
  homepageTitle3: '', //ホームページタイトル3
  homepageUrl1: '', //ホームページURL1
  homepageUrl2: '', //ホームページURL2
  homepageUrl3: '', //ホームページURL3
  thanksMailSetFlag: 0,  //サンクスメール設定区分
  thanksMailTemplateId: 0, //サンクスメールテンプレートID
  thanksMailTemplateName: '',  //サンクスメールテンプレート
  thanksMessageSubject: '', //件名（サンクスメール）
  thanksMessageText: '',  //本文（サンクスメール）
  studentReferenceId: 0, //問い合わせ先選択ID
  studentReferenceName: '', //問い合わせ先
  studentReferenceCompanyName: '', // お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: '', // お問い合わせ先内容部署名担当者氏名
  studentReferenceTelephoneNumber: '', // お問い合わせ先内容電話番号
  studentReferenceMailAddress: '', // お問い合わせ先内容メールアドレス
  personalDataHandlingId: 0, //個人情報の取り扱い選択ID
  personalDataHandlingName: '',  //個人情報の取り扱い名
  senderCompanyName: '',  //送信元会社名
  senderMailAddress: '',  //送信元メールアドレス
  requestSubject: '',	//件名（提出リクエストメッセージ）
  requestText: '',	//本文（提出リクエストメッセージ）
  fileManagementId: [],//	提出リクエストテンプレート添付ファイルID
  //forbiddenWordsList: [], //禁止語リスト
  attachmentName		: [] , //添付ファイル名 
  submissionsDetails : '',
}

const orverWriteRequest: OrverWriteRequest = {
  //recruitmentManagementDivision: '',//採用管理区分
  requestTemplateId: '', //提出リクエストテンプレート設定ID
  requestTemplateName: '', //テンプレート名
  //targetYear:'',  //対象年度
  destinationSelection: '3', //送信先区分
  submissionsSelectId: 0, //提出物内容選択ID
  submissionsName: '',  //提出物内容（企業管理用）
  submissionsNameStudent: '', //提出物内容（学生画面表示用）
  submissionsEntryFlg: 0, //提出物エントリシートフラグ
  submissionsResumeFlg: 0, //提出物履歴書フラグ
  submissionsResearchFlg: 0, //提出物研究概要書フラグ
  submissionsOriginalFlg: 0, //提出物オリジナル設問フラグ
  submissionsFileFlg: 0, //提出物ファイル提出フラグ
  limitDateDetermineFlag: 0, //提出〆切区分
  determineDateTime: '', //提出〆切（日時指定）
  requestDayTimeLaterDay: 0, //提出〆切（依頼日から（日後））
  requestDayTimeToTime: 0, //提出〆切（依頼日から（時））
  anytimeReceptionEndDateTime: '',  //提出〆切（随時受付終了）
  reRequestReceptFlag: 0,  //再提出の受付区分
  homepageTitle1: '', //ホームページタイトル1
  homepageTitle2: '', //ホームページタイトル2
  homepageTitle3: '', //ホームページタイトル3
  homepageUrl1: '', //ホームページURL1
  homepageUrl2: '', //ホームページURL2
  homepageUrl3: '', //ホームページURL3
  thanksMailSetFlag: 0,  //サンクスメール設定区分
  thanksMailTemplateId: 0, //サンクスメールテンプレートID
  thanksMailTemplateName: '',  //サンクスメールテンプレート
  thanksMessageSubject: '', //件名（サンクスメール）
  thanksMessageText: '',  //本文（サンクスメール）
  studentReferenceId: 0, //問い合わせ先選択ID
  studentReferenceName: '', //問い合わせ先
  studentReferenceCompanyName: '', // お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: '', // お問い合わせ先内容部署名担当者氏名
  studentReferenceTelephoneNumber: '', // お問い合わせ先内容電話番号
  studentReferenceMailAddress: '', // お問い合わせ先内容メールアドレス
  personalDataHandlingId: 0, //個人情報の取り扱い選択ID
  personalDataHandlingName: '',  //個人情報の取り扱い名
  senderCompanyName: '',  //送信元会社名
  senderMailAddress: '',  //送信元メールアドレス
  requestSubject: '',	//件名（提出リクエストメッセージ）
  requestText: '',	//本文（提出リクエストメッセージ）
  fileManagementId: [],//	提出リクエストテンプレート添付ファイルID
  //forbiddenWordsList: [], //禁止語リスト
  attachmentName		: [] , //添付ファイル名 
  sysVersionNumber: '',
  submissionsDetails : '',
}

export { initResultt, newRequest, orverWriteRequest }
