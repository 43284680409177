const attachmentList = [
  { name: 'attachmentExist', label: 'あり', id: '1' },
  { name: 'attachmentNot', label: 'なし', id: '2' },
]

const sendToList = [
  { name: 'destinationNavi', label: 'マイページ', id: '1' },
  { name: 'destinationMail', label: 'e-mail', id: '2' },
]

const templateOptionList = [
  { value: '0', label: '件名' },
  { value: '1', label: '本文' },
  { value: '2', label: '氏名' },
]

const judgeParticipation = [
  { value: 2, label: '参加' },
  { value: 4, label: '不参加' },
  { value: 6, label: 'その他' },
];
const judgepassing = [
  { value: 1, label: '判定中' },
  { value: 3, label: '合格' },
  { value: 5, label: '不合格' },
];

const replyNecessaryCheckList = [
  { name: 'replyNecessaryCheckOn', label: '表示あり', id: '1' },
  { name: 'replyNecessaryCheckOff', label: '表示なし', id: '2' },
]

const messageTypeCheckList = [
  { name: 'messageNormal', label: '通常', id: '1' },
  { name: 'messageThanks', label: 'サンクス', id: '2' },
  { name: 'messageScout', label: 'スカウト', id: '3' },
  { name: 'messageSeminarCancel', label: 'セミナーキャンセル', id: '4' },
]

const initialValues = {
  searchTargetList: '0',
  searchWordText: '',
  sentFromDate: '',
  sentToDate: '',
  sendTo: {
    destinationNavi: '0',
    destinationMail: '0',
  },
  attachment: {
    attachmentExist: '0',
    attachmentNot: '0',
  },
  onlyBulkSend: '0',
  selection: '',
  progress: '',
  judge: '',
  sort: '0',
  countPage: 1,
  selectedFlag: 0,
  recruitmentManagementDivision: '',
  replyNecessaryFlag: {
    replyNecessaryCheckOn: '0',
    replyNecessaryCheckOff: '0',
  },
  messageTypeFlag: {
    messageNormal: '1',
    messageThanks: '0',
    messageScout: '0',
    messageSeminarCancel: '1',
  },
  replyLimitFromDate: '',
  replyLimitToDate: '',
}

const submitCondition = {
  searchTargetList: '0',
  searchWordText: '',
  sentFromDate: '',
  sentToDate: '',
  destinationNavi: '0',
  destinationMail: '0',
  attachmentExist: '0',
  attachmentNot: '0',
  onlyBulkSend: '0',
  selection: '',
  progress: '',
  judge: '',
  sort: '0',
  countPage: 1,
  selectedFlag: 0,
  recruitmentManagementDivision: '',
  replyNecessaryCheckOn: '0',
  replyNecessaryCheckOff: '0',
  replyLimitFromDate: '',
  replyLimitToDate: '',
  messageNormal: '0',
  messageThanks: '0',
  messageScout: '0',
  messageSeminarCancel: '0',
}

export {
  initialValues,
  attachmentList,
  sendToList,
  submitCondition,
  templateOptionList,
  judgeParticipation,
  judgepassing,
  replyNecessaryCheckList,
  messageTypeCheckList,
}
