import moment from 'moment-timezone'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { getDiffFromToday, isEmpty, now, stringToDateTime, stringToDate } from 'common/generalUtil'
import { getMessage, getMessageEx } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import {
  confirm,
  getInit,
  getPreview,
  saveDraft,
  setCode,
  setInit,
  setPreview,
  setScreenDisplayItems,
  openConfirmModal,
  setCloseWindow,
  csvDownload,
  openDetailDialog,
  setReplaceBody,
  getReplaceBody,
} from 'reducers/mcbMessageSendReducer'
import { openModal } from 'reducers/messageReducer'
import {
  initRequest,
  draftRequest,
  previewRequest,
  confirmRequest,
  csvDownloadRequest,
} from 'apis/MCAZS220Api'
import { isFileExist } from 'common/businessUtil'
import {
  setSearchCriteria,
  setDetailDialogOpen,
} from 'reducers/selectCriteriaManagementReducer'
import { mcbPreviewRequest } from 'apis/McbMessageReplaceBodyApi'
moment.tz.setDefault('Asia/Tokyo')

const ALL_CONTRACT_MEDIA = 'MAGI' //すべての企業契約応募経路が対象

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const serverResponse = yield call(initRequest, action.payload)
    yield put(setInit(serverResponse))
    yield put(setScreenDisplayItems())
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* saveDraftSaga(action: ReturnType<typeof saveDraft>) {
  try {
    const {
      formData,
      files,
      attachmentFileRegistrationMax,
    } = action.payload

    // ファイル数のチェック
    if (files.length > attachmentFileRegistrationMax) {
      yield put(openModal(getMessage('MCAZS220-012')))
      return
    }

    // ファイル存在チェック
    if (files.some(item => !item || item.size <= 0)) {
      yield put(openModal(getMessage('MCAZS220-013')))
      return
    }
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const isFile = yield call(isFileExist, file)
        if (!isFile) {
          yield put(openModal(getMessage('MCAZS220-013')))
          return
        }
      }
    }

    // ファイルの拡張子チェック
    const suffixCheck = [
      'ai', 'art', 'doc', 'fla', 'gif', 'jpg', 'jpeg', 'lzh', 'mpeg', 'mpg', 'pdf', 'png', 'pps', 'ppt', 'psd', 'txt', 'xls', 'zip', 'docx', 'xlsx'
    ]
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const lastIndexOf = file.name.lastIndexOf('.')
        const suffix = file.name.substring(lastIndexOf + 1)
        for (var j = 0; j < suffixCheck.length; j++) {
          if (suffixCheck[j] == suffix.toLowerCase()) {
            break
          } else {
            if (suffixCheck[j] != 'xlsx') {
              continue
            } else {
              yield put(openModal(getMessage('MCAZS220-010')))
              return
            }
          }
        }
      }
    }

    // 同一添付ファイルチェック
    const addedfileNameList = files.map(file => file.name)
    const fullFileNameList = addedfileNameList.concat(formData.attachmentName)
    const fileNameList = fullFileNameList.filter(v => v)
    if (fileNameList.length > 1) {
      const beforeLength = fileNameList.length
      const newFileNameList = fileNameList.filter((x, index, self) => self.indexOf(x) === index)
      const afterLength = newFileNameList.length
      if (beforeLength !== afterLength) {
        yield put(openModal(getMessage('MCAZS220-039')))
        return
      }
    }

    // ファイルのサイズチェック
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const fileSize = file.size
        if (fileSize > magiContants.MESSAGE_ATTACHMENT_MAX_SIZE) {
          yield put(openModal(getMessage('MCAZS220-011')))
          return
        }
      }
    }

    yield call(draftRequest, formData, files)
    yield put(setCode(200))
    yield put(setCloseWindow(true))
  } catch (error) {
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export function* previewSaga(action: ReturnType<typeof getPreview>) {
  try {
    const formData = action.payload.formData
    const serverResponse = yield call(previewRequest, formData)

    yield put(setPreview(serverResponse))
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* confirmSaga(action: ReturnType<typeof confirm>) {
  try {
    const formData = action.payload.formData
    const files: File[] = action.payload.files
    const viewEndTimeList: Date[] = action.payload.viewEndTimeList
    const forbiddenWords: string[] = action.payload.forbiddenWords
    const attachmentFileRegistrationMax = action.payload.attachmentFileRegistrationMax

    if (files.length > 0 || formData.attachmentId.length > 0) {

      // ファイル数のチェック
      if (files.length > attachmentFileRegistrationMax) {
        yield put(openModal(getMessage('MCAZS220-015')))
        return
      }

      // ファイル存在チェック
      if (files.length > 0) {
        if (files.some(item => !item || item.size <= 0)) {
          yield put(openModal(getMessage('MCAZS220-016')))
          return
        }
      }
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        if (file) {
          const isFile = yield call(isFileExist, file)
          if (!isFile) {
            yield put(openModal(getMessage('MCAZS220-016')))
            return
          }
        }
      }

      // ファイルの拡張子チェック
      if (files.length > 0) {
        const suffixCheck = [
          'ai', 'art', 'doc', 'fla', 'gif', 'jpg', 'jpeg', 'lzh', 'mpeg', 'mpg', 'pdf', 'png', 'pps', 'ppt', 'psd', 'txt', 'xls', 'zip', 'docx', 'xlsx'
        ]
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const lastIndexOf = file.name.lastIndexOf('.')
            const suffix = file.name.substring(lastIndexOf + 1)
            for (var j = 0; j < suffixCheck.length; j++) {
              if (suffixCheck[j] == suffix.toLowerCase()) {
                break
              } else {
                if (suffixCheck[j] != 'xlsx') {
                  continue
                } else {
                  yield put(openModal(getMessage('MCAZS220-010')))
                  return
                }
              }
            }
          }
        }
      }

      // 同一添付ファイルチェック
      const addedfileNameList = files.map(file => file.name)
      const fullFileNameList = addedfileNameList.concat(formData.attachmentName)
      const fileNameList = fullFileNameList.filter(v => v)
      if (fileNameList.length > 1) {
        const beforeLength = fileNameList.length
        const newFileNameList = fileNameList.filter((x, index, self) => self.indexOf(x) === index)
        const afterLength = newFileNameList.length
        if (beforeLength !== afterLength) {
          yield put(openModal(getMessage('MCAZS220-017')))
          return
        }
      }

      // ファイルのサイズチェック
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const fileSize = file.size
            if (fileSize > magiContants.MESSAGE_ATTACHMENT_MAX_SIZE) {
              yield put(openModal(getMessage('MCAZS220-014')))
              return
            }
          }
        }
      }
    }

    // 禁止語チェック
    if (forbiddenWords && forbiddenWords.length > 0) {
      let isErrorSubject = false
      let isErrorBody = false
      forbiddenWords.map(item => {
        if (formData.subject.indexOf(item) >= 0) {
          isErrorSubject = true
          return
        }
        if (formData.text.indexOf(item) >= 0) {
          isErrorBody = true
          return
        }
      })
      if (isErrorSubject) {
        yield put(openModal(getMessage('MCAZS220-018')))
        return
      }
      if (isErrorBody) {
        yield put(openModal(getMessage('MCAZS220-019')))
        return
      }
    }

    // ホームページURLチェック
    if (((formData.homepageTitle1 && formData.homepageTitle1 !== '') && (formData.homepageUrl1 === null || formData.homepageUrl1 === ''))
      || ((formData.homepageTitle2 && formData.homepageTitle2 !== '') && (formData.homepageUrl2 === null || formData.homepageUrl2 === ''))
      || ((formData.homepageTitle3 && formData.homepageTitle3 !== '') && (formData.homepageUrl3 === null || formData.homepageUrl3 === ''))
    ) {
      yield put(openModal(getMessage('MCAZS220-020')))
      return
    }

    // 不正文字チェック
    if (formData.subject.indexOf('●●') >= 0 || formData.text.indexOf('●●') >= 0) {
      yield put(openModal(getMessage('MCAZS220-021')))
      return
    }

    // 送信予約期間チェック
    if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
      if (formData.sendPlanTime === null || formData.sendPlanTime === '') {
        yield put(openModal(getMessage('MCAZS220-023')))
        return
      }
      if (formData.sendPlanTime) {
        const sendPlanTime = stringToDateTime(formData.sendPlanTime)
        if (sendPlanTime !== null) {
          if (sendPlanTime < now()) {
            yield put(openModal(getMessage('MCAZS220-024')))
            return
          }
          const targetDate = moment(sendPlanTime)
          const toDate = moment(now()).add(magiContants.RESERVATION_MAX_DATE_LENGTH, 'day').set('hour', 23).set('minutes', 59).set('second', 59)
          if (targetDate > toDate) {
            yield put(openModal(getMessage('MCAZS220-028')))
            return
          }
          const minTime = moment(now()).add(magiContants.RESERVATION_MIN_MINUTES_LENGTH, 'minutes')
          if (targetDate <= minTime) {
            yield put(openModal(getMessage('MCAZS220-029')))
            return
          }
        }
      }
    }

    // MCBメンテナンス期間チェック
    if (formData.sendReplyImpossibleTime && formData.sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment()
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        if (!isEmpty(formData.sendPlanTime)) {
          sendTime = moment(formData.sendPlanTime.replace(/\//g, '-'))
        }
      }
      formData.sendReplyImpossibleTime.map(item => {
        let startDate
        let endDate
        let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
        let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
          startDate = moment(`${startDateStr.substring(0, 4)}-${startDateStr.substring(4, 6)}-${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}`)
        }
        if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
          endDate = moment(`${endDateStr.substring(0, 4)}-${endDateStr.substring(4, 6)}-${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`)
        }
        // #MCB1.5次開発 #8986 START
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '1'? true : false
        if (startDate && endDate && functionalDivision) {
          if ((sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) && (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0)) {
            errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
        if (startDate && !endDate && functionalDivision) {
          if (sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) {
            errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
            isError = true
            return
          }
        }
        if (!startDate && endDate && functionalDivision) {
        // #MCB1.5次開発 #8986 END
          if (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0) {
            errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
      })
      if (isError) {
        yield put(openModal(getMessageEx('MCAZS220-022', errorPeriod)))
        return
      }
    }

    // 26KH #87889 START
    // MIWSメンテナンス期間チェック
    if (formData.sendReplyImpossibleTime && formData.sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment().startOf('minute')
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        if (!isEmpty(formData.sendPlanTime)) {
          sendTime = moment(formData.sendPlanTime.replace(/\//g, '-'))
        }
      }
      formData.sendReplyImpossibleTime.map(item => {
        let startDate
        let endDate
        let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
        let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
          startDate = moment(`${startDateStr.substring(0, 4)}-${startDateStr.substring(4, 6)}-${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}`)
        }
        if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
          endDate = moment(`${endDateStr.substring(0, 4)}-${endDateStr.substring(4, 6)}-${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`)
        }
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '3'? true : false
        if (startDate && endDate && functionalDivision) {
          if ((sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) && (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0)) {
            errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
        if (startDate && !endDate && functionalDivision) {
          if (sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) {
            errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
            isError = true
            return
          }
        }
        if (!startDate && endDate && functionalDivision) {
          if (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0) {
            errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
      })
      if (isError) {
        yield put(openModal(getMessageEx('MCAZS220-048', errorPeriod)))
        return
      }
    }
    // 26KH #87889 END

    // 送信先チェック
    if (formData.senderMailAddress === null || formData.senderMailAddress === '') {
      yield put(openModal(getMessage('MCAZS220-030')))
      return
    }

    // 送信先チェック
    if (formData.sendTargetConfirmation) {
      if (!formData.sendTargetConfirmation.sendTarget.some(item => !isEmpty(item.mailAddress))) {
        yield put(openModal(getMessage('MCAZS220-031')))
        return
      }
    }

    // エントリー有効期限チェック
    if (viewEndTimeList && viewEndTimeList.length > 0) {
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_IMMEDIATE) {
        const currentDate = moment(now())
        if (viewEndTimeList.some(item => item && currentDate.isAfter(moment(item)))) {
          yield put(openModal(getMessage('MCAZS220-032')))
          return
        }
      }
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        const sendPlanTime = moment(stringToDateTime(formData.sendPlanTime))
        if (viewEndTimeList.some(item => item && sendPlanTime.isAfter(moment(item)))) {
          yield put(openModal(getMessage('MCAZS220-033')))
          return
        }
      }
    }

    yield call(confirmRequest, formData, files)
    yield put(openConfirmModal())

  } catch (error) {
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}
export function* csvDownloadSaga(action: ReturnType<typeof csvDownload>) {
  const data = yield call(csvDownloadRequest, action.payload)
  if (data.data.type.indexOf('application/json') !== -1) {
    yield put(openModal('MCAZS220-042'))
  } else {
    var blob = new Blob([data.data]);
    const date = new Date();
    var curr_date = date.getDate() + "";
    var curr_month = (date.getMonth() + 1) + "";
    const curr_year = date.getFullYear()
    var curr_hour = date.getHours() + "";
    var curr_minutue = date.getMinutes() + "";
    curr_month = curr_month.length < 2 ? "0" + curr_month : curr_month;
    curr_date = curr_date.length < 2 ? "0" + curr_date : curr_date;
    curr_hour = curr_hour.length < 2 ? "0" + curr_hour : curr_hour;
    curr_minutue = curr_minutue.length < 2 ? "0" + curr_minutue : curr_minutue;
    const yymmdd = curr_year + curr_month + curr_date + "_" + curr_hour + curr_minutue;
    const fileName = "message_job_seeker_list_" + yymmdd + ".csv"
    if (navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    }
  }
}
export function* openDetailDialogSaga(action: ReturnType<typeof openDetailDialog>) {
  yield put(setSearchCriteria(action.payload))
  yield put(setDetailDialogOpen(true))
}

export function* getReplaceBodySaga(action: ReturnType<typeof getReplaceBody>) {
  try {
    const data = yield call(mcbPreviewRequest, action.payload)
    yield put(setReplaceBody(data.replace(/\r?\n/g, '<br />')))
  } catch (error) {
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export default function* mcbMessageInputSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(saveDraft, saveDraftSaga),
    takeLatest(getPreview, previewSaga),
    takeLatest(confirm, confirmSaga),
    takeLatest(csvDownload, csvDownloadSaga),
    takeLatest(openDetailDialog, openDetailDialogSaga),
    takeLatest(getReplaceBody, getReplaceBodySaga),
  ])
}
