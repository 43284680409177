// 次期9月対応 #63073 start
const mcafs010NotificationMailInfo = {
    alertTiming: '0',
    notificationMailAddress: '',
    alertMailType: '0'
}
// 次期9月対応 #63073 end

const initialValues = {
    // 面接確定待ちアラート設定
    interviewFixedWaitingAlertPreference: '',
    // 合否連絡待ちアラート設定
    passingStatusContactWaitingAlertPreference: '',
    // 進捗滞留アラート設定
    progressResidenceAlertPreference: '',
    // 面接キャンセルアラート設定
    interviewCancelAlertPreference: '',
    // 問合せ未回答アラート設定
    contactUnansweredAlertPreference: '',
    // 面接当日リマインド設定
    interviewCurrentDayRemindPreference: '',
    // sysバージョン番号
    sysVersionNumber: -1,
    message: '',
    showDialog: false,
    title: '',
    // 次期9月対応 #63073 start
    // 個別返信メッセージ通知メール
    notificationMailInfoResult1: mcafs010NotificationMailInfo,
    // 新規応募者登録通知メール
    notificationMailInfoResult2: mcafs010NotificationMailInfo,
    // セミナーエントリー／キャンセル通知メール
    notificationMailInfoResult3: mcafs010NotificationMailInfo,
    // 送信メール未着通知メール
    notificationMailInfoResult4: mcafs010NotificationMailInfo,
    // 次期9月対応 #63073 end
    // MCB ロット３ MCAFS010 start 
    // My CareerBox提出通知アラート
    McbNotificationMailInfoResult: mcafs010NotificationMailInfo,
    // MCB ロット３ MCAFS010　end 
}

const contactUnanswered = {
    contactUnansweredAlertSwitchToggle: '1',
    versionNumber: 0,
    recruitmentManagementDivision: ''
}

const interviewFixedWaiting = {
    interviewFixedWaitingAlertSwitchToggle: '1',
    versionNumber: 0,
    recruitmentManagementDivision: ''
}

const passingStatusContactWaiting = {
    passingStatusContactWaitingAlertSwitchToggle: '1',
    versionNumber: 0,
    recruitmentManagementDivision: ''
}

const progressResidence = {
    progressResidenceAlertSwitchToggle: '1',
    versionNumber: 0,
    recruitmentManagementDivision: ''
}

const interviewCancel = {
    interviewCancelAlertSwitchToggle: '1',
    versionNumber: 0,
    recruitmentManagementDivision: ''
}

const interviewCurrentDayRemind = {
    interviewCurrentDayRemindSwitchToggle: '1',
    versionNumber: 0,
    recruitmentManagementDivision: ''
}
// 次期9月対応 #63073 start
export const alertTimingList01 = [
    { value: 1, label: '毎日', id: 'everyDay' },
    { value: 2, label: '随時', id: 'immediate' },
    { value: 0, label: '通知しない', id: 'noNotification' },
   
]

export const alertTimingList02 = [
    { value: 1, label: '1日1回朝8時', id: 'onceEveryDay' },
    { value: 0, label: '通知しない', id: 'noNotification' },
]
// 転職March #75621 START
export const alertTimingList03 = [
    // 転職March #75621 #9481 START
    { value: 2, label: '通知する', id: 'notification' },
    // 転職March #75621 #9481 END
    { value: 0, label: '通知しない', id: 'noNotification' },
]
// 転職March #75621 END
// 次期9月対応 #63073 end
export { 
    initialValues, 
    contactUnanswered, 
    interviewFixedWaiting, 
    passingStatusContactWaiting, 
    progressResidence, 
    interviewCancel,
    interviewCurrentDayRemind,
    // 次期9月対応 #63073 start
    mcafs010NotificationMailInfo,
    // 次期9月対応 #63073 end
}