import React from 'react'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import { setDisplayLimit } from 'reducers/applicantListReducer'
import { DisplayLimitOptionInfo, Option } from 'pages/MCAXS010/formConfig'

const customStyles = {
  input: () => ({
    margin: 0,
    bottom: 0,
    paddingTop: 0,
  }),
  menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
}

interface Props {
  className?: string
  displayLimit: Option
  displayLimitOption: DisplayLimitOptionInfo[]
}
const DisplayLimitSelect: React.FC<Props> = ({
  className,
  displayLimit,
  displayLimitOption,
}: Props) => {
  const dispatch = useDispatch()

  const options = displayLimitOption.map(i => ({
    label: i.label,
    value: i.value,
  }))

  const handleChangeSelect = (option: any) => {
      dispatch(setDisplayLimit(option))
  }

  return (
    <ReactSelect
      name='displaylimitSelect'
      className={className}
      styles={customStyles}
      options={options}
      onChange={handleChangeSelect}
      value={displayLimit}
      menuPlacement='auto'
      isSearchable={false}
    />
  )
}

export default DisplayLimitSelect
