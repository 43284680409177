import request from 'utils/request'
import { MCAUS010UpdateReportRequest } from 'types/MCAUS010/MCAUS010ReportRequest'

export const menuInitRequest = (errorReportID: String) =>
  request({
    url: `MCAUS010/init/${errorReportID}`,
    method: 'post',
    // data: { errorReportID },
  })
export const setErrorReportIdRequest = (
  data: MCAUS010UpdateReportRequest
) =>
  request({
    url: '/MCAUS010/update',
    method: 'post',
    data: data,
  })
