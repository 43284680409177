import React from 'react'
import { Button, Typography, useTheme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Page } from 'components'
import { replaceToOrigin } from 'utils/misc'

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}))

const Error500 = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClick = () => {
    replaceToOrigin()
  }

  return (
    <Page title='エラー'>
      <Typography align='center' variant={mobileDevice ? 'h4' : 'h1'}>
        技術的な問題が発生しています。
      </Typography>
      <Typography
        align='center'
        variant='subtitle2'
        className={classes.subtitle}>
        ご迷惑をお掛けしております。
      </Typography>
      <div className={classes.buttonContainer}>
        <Button color='primary' variant='outlined' onClick={handleClick}>
          トップページへ
        </Button>
      </div>
    </Page>
  )
}

export default Error500
