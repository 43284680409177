import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Button,
  colors,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { FastField, Field } from 'formik'
import { FormControl } from '..'
import TextField from '../TextField'
import { useDispatch } from 'react-redux'
import {
  getMcaxs441Init,
  getMcaxs451Init,
  getMcaxs461Init,
  getMcaxs581Init,
  setImagesSubmitFlag,
  setImagesModalObj,
  setGABSubmitFlag,
  setGABModalObj,
  setOPQSubmitFlag,
  setOPQModalObj,
  setCABSubmitFlag,
  setCABModalObj,
} from 'reducers/advancedSearchModalReducer'
import { magiContants as contants } from 'utils/contants'
import { Option, TestObj } from '../Config/searchConditionConfig'
import { SubTitle } from 'componentsHsc'
import LanguageSelect from '../LanguageSelect'
import DatePickerDialog from 'componentsHsc/AdvancedSearch/DatePickerDialog'
import * as yup from 'yup'
import { validationMessageSet } from 'utils/contants'
import { miscRegexSet } from 'utils/regex'

const dateYup = yup.object().shape({
  date: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) =>
      miscRegexSet.date.test(value)
    ),
})

const useStyles = makeStyles(theme => ({
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 1,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span': {
      color: colors.grey[600],
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent !important',
      color: '#1950a6',
      textDecoration: 'underline',
      '& span': {
        color: '#1950a6',
      },
    },
  },
  content: {
    height: 'inherit !important',
  },
  titleField: {
    flex: 1,
  },
  inputField: {
    flex: 2,
    display: 'flex',
    '& input': {
      width: '150px !important',
    },
    '& > div': {
      margin: theme.spacing(0, 1),
    },
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '20px !important',
  },
  dialogContainer: {
    overflowY: 'hidden',
  },
  scrollList: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)',
    msOverflowStyle: 'auto',
    borderLeft: '1px solid gray',
  },
  subTitle: {
    paddingLeft: '8px',
  },
  languageSele: {
    width: 150
  },
  errorBorder: {
    '& input': {
      border: '1px solid red'
    }
  },
  helperText: {
    fontSize: '8px', 
    color: 'red',
    whiteSpace: 'pre-wrap',
  },
  selectCss:{
    '& div' : {
      cursor: 'pointer!important',
    },
    '& input': {
      cursor: 'pointer!important',
    },
  },
  scrollToListTop: {
    marginTop: theme.spacing(-1),
  },
  inputFieldDatePicker: {
    flex: 2,
    display: 'flex',
    '& input': {
      width: '100px !important',
    },
    '& > div': {
      margin: theme.spacing(0, 1),
    },
    '& .MuiFormControl-marginDense': {
      margin: '0px !important',
    },
  },
}))

interface Errors {
  [key: string]: any
  errorFlag: boolean
  errorName: string
}

export interface ErrorsForCheck {
  from: boolean
  fromInfo: string
  formErrs : String[]
  to: boolean
  toInfo: string
  toErrs : String[]
}

interface Props {
  title: string
  subtitleList: { label: string; value: string }[]
  optionSet: { [key: string]: Option[] }
  form: FormControl
  open: boolean
  onClose: () => void
  name: string
  selectedSubtitleState: [Option, Dispatch<SetStateAction<Option>>]
}

const Modal = ({
  open,
  onClose,
  title,
  subtitleList,
  optionSet,
  form,
  name,
  selectedSubtitleState: [selectedSubtitle, setSelectedSubtitle]
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirm, setConfirm] = useState(false)
  const [imagesObj, setImagesObj] = useState(form.values.imagesObj)
  const [gabObj, setGABObj] = useState(form.values.gabObj)
  const [opqObj, setOPQObj] = useState(form.values.opqObj)
  const [cabObj, setCABObj] = useState(form.values.cabObj)
  const [errorsForCheck, setErrorsForCheck] = useState<ErrorsForCheck[]>([])
  const [errorsForCheckFlag, setErrorsForCheckFlag] = useState(false)
  const [errFlg, setErrFlg] = useState(true)
  const [errors, setErrors] = useState([] as Errors[])

  useEffect(() => {
    if (open) {
      if (title === 'イメジス情報を指定') {
        dispatch(getMcaxs441Init())
      } else if (title === 'GAB情報を指定') {
        dispatch(getMcaxs451Init())
      } else if (title === 'OPQ情報を指定') {
        dispatch(getMcaxs581Init())
      } else if (title === 'CAB情報を指定') {
        dispatch(getMcaxs461Init())
      }
    }
    interface TestObj {
      logicalName: string
      physicalName: string
      valueFrom: string
      valueTo: string
    }
    let len : string[] = []
    subtitleList.forEach(subtitle => {
      optionSet[subtitle.value].forEach(option => {
        len.push(option.value)
      })
    })
    let errList: ErrorsForCheck[] = []
    for (let i = 0; i < len.length ; i++) {
      const obj: ErrorsForCheck = {
        from: false,
        fromInfo: '',
        formErrs: [],
        to: false,
        toInfo: '',
        toErrs :[],
      }
      errList = [...errList, obj]
    }
    setErrorsForCheck(errList)
    if (
      open &&
      ((name === 'imagesObj' &&
        form.values.imagesObj &&
        form.values.imagesObj.length <= 0) ||
        (name === 'gabObj' &&
          form.values.gabObj &&
          form.values.gabObj.length <= 0) ||
        (name === 'opqObj' &&
          form.values.opqObj &&
          form.values.opqObj.length <= 0) ||
        (name === 'cabObj' &&
          form.values.cabObj &&
          form.values.cabObj.length <= 0))
    ) {
      
      let objList: TestObj[] = []
      for (var i = 0; i < len.length; i++) {
        const obj: TestObj = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
        objList = [...objList, obj]
      }
      form.setFieldValue(name, objList)
      //
    }
    setErrors([])
    setImagesObj(form.values.imagesObj)
    setGABObj(form.values.gabObj)
    setOPQObj(form.values.opqObj)
    setCABObj(form.values.cabObj)
    if(open){
      if (title === 'イメジス情報を指定') {
        if (form.values.imagesObj && form.values.imagesObj.length > 0 ){
          let imagesObj2: TestObj[] = []
          let imagesPhysicalNameList = form.values.imagesObj.map((imagesObj,index) => {
            return imagesObj.physicalName
          })
          len.forEach(option => {
            const index = imagesPhysicalNameList.indexOf(option)
            if(index >= 0  && form.values.imagesObj){
              imagesObj2.push(form.values.imagesObj[index])
            }else {
              imagesObj2.push({
                logicalName: '',
                physicalName: '',
                valueFrom: '',
                valueTo: '',
              })
            }
          })
          form.values.imagesObj = imagesObj2
        }
        dispatch(setImagesModalObj(form.values.imagesObj))
        dispatch(setImagesSubmitFlag(false))
      } else if (title === 'GAB情報を指定') {
        if (form.values.gabObj && form.values.gabObj.length > 0 ){
          let gABObj2: TestObj[] = []
          let gABPhysicalNameList = form.values.gabObj.map((gabObj,index) => {
            return gabObj.physicalName
          })
          len.forEach(option => {
            const index = gABPhysicalNameList.indexOf(option)
            if(index >= 0  && form.values.gabObj){
              gABObj2.push(form.values.gabObj[index])
            }else {
              gABObj2.push({
                logicalName: '',
                physicalName: '',
                valueFrom: '',
                valueTo: '',
              })
            }
          })
          form.values.gabObj = gABObj2
        }
        dispatch(setGABModalObj(form.values.gabObj))
        dispatch(setGABSubmitFlag(false))
      } else if (title === 'OPQ情報を指定') {
        if (form.values.opqObj && form.values.opqObj.length > 0 ){
          let oPQObj2: TestObj[] = []
          let oPQPhysicalNameList = form.values.opqObj.map((opqObj,index) => {
            return opqObj.physicalName
          })
          len.forEach(option => {
            const index = oPQPhysicalNameList.indexOf(option)
            if(index >= 0  && form.values.opqObj){
              oPQObj2.push(form.values.opqObj[index])
            }else {
              oPQObj2.push({
                logicalName: '',
                physicalName: '',
                valueFrom: '',
                valueTo: '',
              })
            }
          })
          form.values.opqObj = oPQObj2
        }
        dispatch(setOPQModalObj(form.values.opqObj))
        dispatch(setOPQSubmitFlag(false))
      } else if (title === 'CAB情報を指定') {
        if (form.values.cabObj && form.values.cabObj.length > 0 ){
          let cABObj2: TestObj[] = []
          let oPQPhysicalNameList = form.values.cabObj.map((cabObj,index) => {
            return cabObj.physicalName
          })
          len.forEach(option => {
            const index = oPQPhysicalNameList.indexOf(option)
            if(index >= 0  && form.values.cabObj){
              cABObj2.push(form.values.cabObj[index])
            }else {
              cABObj2.push({
                logicalName: '',
                physicalName: '',
                valueFrom: '',
                valueTo: '',
              })
            }
          })
          form.values.cabObj = cABObj2
        }
        dispatch(setCABModalObj(form.values.cabObj))
        dispatch(setCABSubmitFlag(false))
      }
    }
  }, [open])

  useEffect(() => {
    setSelectedSubtitle(subtitleList[0])
  }, [subtitleList])

  const handleClick = (open = true) => () => {
    setConfirm(open)
    if (errorsForCheckFlag) {
      setErrorsForCheckFlag(false)
    }
  }

  const handleClick2 = (open = true) => () => {
    setConfirm(open)
    setErrorsForCheckFlag(true)
  }
  
  const handleCancelClose = () => {
    form.values.imagesObj = imagesObj 
    form.values.gabObj = gabObj
    form.values.opqObj = opqObj
    form.values.cabObj = cabObj
    setConfirm(false)
    if (errorsForCheckFlag) {
      let len = 0
      subtitleList.forEach(item => {
        len += optionSet[item.value].length
      })
      let errList: ErrorsForCheck[] = []
      for (let i = 0; i < len; i++) {
        const obj: ErrorsForCheck = {
          from: false,
          fromInfo: '',
          formErrs: [],
          to: false,
          toInfo: '',
          toErrs: [],
        }
        errList = [...errList, obj]
      }
      setErrorsForCheck(errList)
    }
    if (title === 'イメジス情報を指定') {
      dispatch(setImagesSubmitFlag(true))
    } else if (title === 'GAB情報を指定') {
      dispatch(setGABSubmitFlag(true))
    } else if (title === 'OPQ情報を指定') {
      dispatch(setOPQSubmitFlag(true))
    } else if (title === 'CAB情報を指定') {
      dispatch(setCABSubmitFlag(true))
    }
    onClose()
  }

  const handleClose = () => {
    setConfirm(false)
    onClose()
  }

  const handleSubmit = () => {
    let err: boolean = false
    errorsForCheck.forEach(i => {
      if (i.from || i.to
        || (null != i.formErrs && i.formErrs.length !== 0)
        || (null != i.toErrs && i.toErrs.length !== 0)) {
        err = true
      }
    })
    if (err) {
      return
    }
    let submitErrFlg: boolean = true
    let submitValueObj : TestObj[] = []
    let newSubmitValueObj : TestObj[] = []
    const errorS: Errors[] = []
    if (title === 'イメジス情報を指定' && form.values.imagesObj != null) {
      submitValueObj = form.values.imagesObj
    } else if (title === 'GAB情報を指定' && form.values.gabObj != null) {
      submitValueObj = form.values.gabObj
    } else if (title === 'OPQ情報を指定' && form.values.opqObj != null) {
      submitValueObj = form.values.opqObj
    } else if (title === 'CAB情報を指定' && form.values.cabObj != null) {
      submitValueObj = form.values.cabObj
    }
    for(var i = 0; i < submitValueObj.length ; i++){
      const valueFrom = submitValueObj[i].valueFrom
      const valueTo = submitValueObj[i].valueTo
      const physicalName = submitValueObj[i].physicalName
      if(valueFrom || valueTo && physicalName){
        if(physicalName.toLowerCase().includes('date')){
          let isValidError = false
          if(valueFrom && physicalName){
            try {
              dateYup.validateSync({
                date: valueFrom,
              })
            } catch (err) {
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
                message: err.message,
                type: 'valueFrom'
              })
              isValidError = true
              submitErrFlg = false
            }
          }
          if(valueTo && physicalName){
            try {
              dateYup.validateSync({
                date: valueTo,
              })
            } catch (err) {
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
                message: err.message,
                type: 'valueTo'
              })
              isValidError = true
              submitErrFlg = false
            }
          }
          if(!isValidError &&  valueTo != "" && valueTo != null && valueFrom > valueTo){
            submitErrFlg = false
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: '終了日が開始日よりも前です',
              type: 'valueTo'
            })
          } else if (!isValidError){
            newSubmitValueObj[i] = submitValueObj[i];
          }
        } else if (physicalName.toLowerCase().includes('exam_language')){
          newSubmitValueObj[i] = submitValueObj[i];
        } else if(valueFrom != "" && valueTo!= "" &&  Number(valueFrom) > Number(valueTo)){
            submitErrFlg = false
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
            })
          }else{
            newSubmitValueObj[i] = submitValueObj[i];
          }
        
      }
    }
    setErrFlg(submitErrFlg)
    setErrors(errorS)
    if (errFlg) {
      if (title === 'イメジス情報を指定') {
        form.values.imagesObj = newSubmitValueObj
        dispatch(setImagesSubmitFlag(true))
      } else if (title === 'GAB情報を指定') {
        form.values.gabObj = newSubmitValueObj
        dispatch(setGABSubmitFlag(true))
      } else if (title === 'OPQ情報を指定') {
        form.values.opqObj = newSubmitValueObj
        dispatch(setOPQSubmitFlag(true))
      } else if (title === 'CAB情報を指定') {
        form.values.cabObj = newSubmitValueObj
        dispatch(setCABSubmitFlag(true))
      }
      handleClose()
    }
  }
  const handleLink = (subtitle: any) => () => {
    setSelectedSubtitle(subtitle)
    const toTop = document.getElementById('scrollToListTop')
    if (toTop) {
      toTop.scrollIntoView()
    }
  }

  const range = optionSet
    ? Object.keys(optionSet).reduce((result, current, index) => {
        if (index === 0) {
          result[current] = 0
          return result
        }

        const lastElement = Object.keys(optionSet)[index - 1]
        result[current] = optionSet[lastElement].length + result[lastElement]
        return result
      }, {} as any)
    : null

  const setErrorMessage = (errors: Errors[], index: number) => {
    const labelValue = optionSet[selectedSubtitle.value][index].value
    const errorLabels = errors.map(i =>{
      return i.errorName
    })
    if(errorLabels.includes(labelValue)){
      return true
    } else {
      return false
    }
  }

  const getDateErrorMessage = (
    errors: Errors[],
    index: number,
    type: string
  ) => {
    const labelValue = optionSet[selectedSubtitle.value][index].value
    const errorLabels = errors.map(i => {
      return i.errorName
    })
    if (errorLabels.includes(labelValue)) {
      const tartget = errors.find(
        i => i.errorName === labelValue && i.type === type
      )
      if (tartget) {
        return {
          type: tartget.type,
          message: tartget.message,
        }
      }
    }
    return undefined
  }

  const inputOnBlur = () => {
    let blurErrFlg: boolean = true
    const errorS: Errors[] = []
    let submitValueObjs: TestObj[] = []
    if (title === 'イメジス情報を指定' && form.values.imagesObj != null) {
      submitValueObjs = form.values.imagesObj
    } else if (title === 'GAB情報を指定' && form.values.gabObj != null) {
      submitValueObjs = form.values.gabObj
    } else if (title === 'OPQ情報を指定' && form.values.opqObj != null) {
      submitValueObjs = form.values.opqObj
    } else if (title === 'CAB情報を指定' && form.values.cabObj != null) {
      submitValueObjs = form.values.cabObj
    }
    if (submitValueObjs && submitValueObjs.length > 0) {
      for (var i = 0; i < submitValueObjs.length; i++) {
        const valueFrom = submitValueObjs[i].valueFrom
        const valueTo = submitValueObjs[i].valueTo
        const physicalName = submitValueObjs[i].physicalName
        if (valueFrom && valueTo && physicalName) {
          if (physicalName.toLowerCase().includes('date')) {
            if (valueFrom > valueTo) {
              blurErrFlg = false
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
              })
            }
          }
        }
      }
      setErrors(errorS)
      setErrFlg(blurErrFlg)
    }
  }

  const inputDateOnBlur = () => {
    let blurErrFlg: boolean = true
    const errorS: Errors[] = []
    let submitValueObjs: TestObj[] = []
    if (title === 'イメジス情報を指定' && form.values.imagesObj != null) {
      submitValueObjs = form.values.imagesObj
    } else if (title === 'GAB情報を指定' && form.values.gabObj != null) {
      submitValueObjs = form.values.gabObj
    } else if (title === 'OPQ情報を指定' && form.values.opqObj != null) {
      submitValueObjs = form.values.opqObj
    } else if (title === 'CAB情報を指定' && form.values.cabObj != null) {
      submitValueObjs = form.values.cabObj
    }
    if (submitValueObjs && submitValueObjs.length > 0) {
      for (var i = 0; i < submitValueObjs.length; i++) {
        const valueFrom = submitValueObjs[i].valueFrom
        const valueTo = submitValueObjs[i].valueTo
        const physicalName = submitValueObjs[i].physicalName
        let isValidError = false
        if(valueFrom && physicalName && physicalName.toLowerCase().includes('date')){
          try {
            dateYup.validateSync({
              date: valueFrom,
            })
          } catch (err) {
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: err.message,
              type: 'valueFrom'
            })
            isValidError = true
            blurErrFlg = false
          }
        }
        if(valueTo && physicalName && physicalName.toLowerCase().includes('date')){
          try {
            dateYup.validateSync({
              date: valueTo,
            })
          } catch (err) {
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: err.message,
              type: 'valueTo'
            })
            isValidError = true
            blurErrFlg = false
          }
        }
        if (!isValidError && valueFrom && valueTo && physicalName) {
          if (physicalName.toLowerCase().includes('date')) {
            if (valueFrom > valueTo) {
              blurErrFlg = false
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
                message: '終了日が開始日よりも前です',
                type: 'valueTo'
              })
            }
          }
        }
      }
      setErrors(errorS)
      setErrFlg(blurErrFlg)
    }
  }

  const inputDateOnChange = (
    index: any,
    name: any,
    targetPhysicalName: any,
    value: any
  ) => {
    let blurErrFlg: boolean = true
    const errorS: Errors[] = []
    let submitValueObjs: TestObj[] = []
    if (title === 'イメジス情報を指定' && form.values.imagesObj != null) {
      submitValueObjs = form.values.imagesObj
    } else if (title === 'GAB情報を指定' && form.values.gabObj != null) {
      submitValueObjs = form.values.gabObj
    } else if (title === 'OPQ情報を指定' && form.values.opqObj != null) {
      submitValueObjs = form.values.opqObj
    } else if (title === 'CAB情報を指定' && form.values.cabObj != null) {
      submitValueObjs = form.values.cabObj
    }
    if (submitValueObjs && submitValueObjs.length > 0) {
      for (var i = 0; i < submitValueObjs.length; i++) {
        let valueFrom = submitValueObjs[i].valueFrom
        let valueTo = submitValueObjs[i].valueTo
        let physicalName = submitValueObjs[i].physicalName
        let isValidError = false
        if (index === i) {
          physicalName = targetPhysicalName
          if (name === 'valueFrom') {
            valueFrom = value
          } else {
            valueTo = value
          }
        }
        if(valueFrom && physicalName && physicalName.toLowerCase().includes('date')){
          try {
            dateYup.validateSync({
              date: valueFrom,
            })
          } catch (err) {
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: err.message,
              type: 'valueFrom'
            })
            isValidError = true
            blurErrFlg = false
          }
        }
        if(valueTo && physicalName && physicalName.toLowerCase().includes('date')){
          try {
            dateYup.validateSync({
              date: valueTo,
            })
          } catch (err) {
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: err.message,
              type: 'valueTo'
            })
            isValidError = true
            blurErrFlg = false
          }
        }
        if (!isValidError && valueFrom && valueTo && physicalName) {
          if (physicalName.toLowerCase().includes('date')) {
            if (valueFrom > valueTo) {
              blurErrFlg = false
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
                message: '終了日が開始日よりも前です',
                type: 'valueTo'
              })
            }
          }
        }
      }
      setErrors(errorS)
      setErrFlg(blurErrFlg)
    }
  }

  return (
    <div>
      {/* 転職March #90253 START */}
      <Dialog fullWidth open={open} maxWidth='md' className={'mainDialog'}>
      {/* 転職March #90253 END */}
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>{title}</label>
        </DialogTitle>

        <DialogContent className={classes.dialogContainer}>
          <div className='ps-container ps'>
            <div className={classes.select}>
              <List>
                {subtitleList.map(subtitle => (
                  <ListItem
                    button
                    key={subtitle.value}
                    onClick={handleLink(subtitle)}
                    selected={subtitle.value === selectedSubtitle.value}>
                    <ListItemText primary={subtitle.label} />
                  </ListItem>
                ))}
              </List>
              <List className={classes.scrollList}>
                <div id='scrollToListTop' className={classes.scrollToListTop} />
                {optionSet[selectedSubtitle.value] && range ? (
                  <>
                    <SubTitle
                      variant='h5'
                      noUnderline
                      className={classes.subTitle}>
                      {selectedSubtitle.label}
                    </SubTitle>
                    {optionSet[selectedSubtitle.value].map((option, index) => (
                      <ListItem key={option.value}>
                        <ListItemText
                          primary={option.label}
                          className={classes.titleField}
                        />
                        {option.value.toLowerCase().includes('date') ?  (()=>{
                          const checkResultFrom = getDateErrorMessage(errors, index, 'valueFrom')
                          const checkResultTo = getDateErrorMessage(errors, index, 'valueTo')
                          return (
                            <>
                              <div className={classes.inputFieldDatePicker}>
                                <div className={checkResultFrom ? classes.errorBorder : ''}>
                                <Field
                                  name={`${name}[${index +
                                    range[selectedSubtitle.value]}].valueFrom`}
                                  selectsStart
                                  component={DatePickerDialog}
                                  placeholder='YYYY/MM/DD'
                                  option={option}
                                  onBlur={inputDateOnBlur}
                                  onChange={inputDateOnChange}
                                  targetIndex={
                                    index + range[selectedSubtitle.value]
                                  }
                                  dateYup={dateYup}
                                />
                                {checkResultFrom && (
                                  <p className={classes.helperText}>
                                  {checkResultFrom.message}
                                  </p>
                                )}
                                </div>
                                <div>~</div>
                                <div className={checkResultTo ? classes.errorBorder : ''}>
                                <Field
                                  name={`${name}[${index +
                                    range[selectedSubtitle.value]}].valueTo`}
                                  selectsEnd
                                  component={DatePickerDialog}
                                  placeholder='YYYY/MM/DD'
                                  option={option}
                                  onBlur={inputDateOnBlur}
                                  onChange={inputDateOnChange}
                                  targetIndex={
                                    index + range[selectedSubtitle.value]
                                  }
                                  dateYup={dateYup}
                                />
                                {checkResultTo && (
                                  <p className={classes.helperText}>
                                  {checkResultTo.message}
                                  </p>
                                )}
                                </div>
                              </div>
                            </>
                          )
                        })(
                        ) : option.value.toLowerCase().includes('exam_language') ? (
                            <div className={classes.inputField}>
                              <div className={classes.selectCss}>
                                <FastField
                                    name={`${name}[${index + range[selectedSubtitle.value]}].valueFrom`}
                                    component={LanguageSelect}
                                    placeholder=''
                                    option={option}
                                    className={classes.languageSele}
                                />
                                </div>
                            </div>
                        ) : (
                              (
                                <div className={classes.inputField}>
                                  <Field
                                    name={`${name}[${index +
                                      range[selectedSubtitle.value]}].valueFrom`}
                                    component={TextField}
                                    onBlur={() => inputOnBlur()}
                                    placeholder='半角数字'
                                    option={option}
                                    errorsForCheckState={[errorsForCheck, setErrorsForCheck]}
                                    title={title}
                                  />
                                  <div>~</div>
                                  <Field
                                    name={`${name}[${index +
                                      range[selectedSubtitle.value]}].valueTo`}
                                    component={TextField}
                                    onBlur={() => inputOnBlur()}
                                    placeholder='半角数字'
                                    option={option}
                                    errorsForCheckState={[errorsForCheck, setErrorsForCheck]}
                                    title={title}
                                  />
                                </div>
                              )
                            )}
                      </ListItem>
                    ))}
                  </>
                ) : null}
              </List>
            </div>
          </div>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClick2()}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS511_001}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleCancelClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Modal
