import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import momentTz from 'moment-timezone'
import * as apiConfig from 'pages/MCBHS010/apiConfig'
import * as viewConfig from 'pages/MCBHS010/viewConig'
import * as formConfig from 'pages/MCBHS010/formConfig'
import { MCBHS010RequestConfirmRequest } from 'types/MCBHS010/MCBHS010RequestConfirmRequest'
import { MCBHS010RequestDraftRequest } from 'types/MCBHS010/MCBHS010RequestDraftRequest'
import { MCBHS010RequestInitRequest } from 'types/MCBHS010/MCBHS010RequestInitRequest'
import { getAge } from 'common/businessUtil'
import { dateTimeToString, dateToString, isEmpty, now } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
import { submissionInitRequest } from 'pages/MCBHS010/formConfig'
import { MCBHS010SubmitRequestPreviewRequest } from 'types/MCBHS010/MCBHS010SubmitRequestPreviewRequest'
import { MCBHS010SubmissionTemplateRequest } from 'types/MCBHS010/MCBHS010SubmissionTemplateRequest'
import { MCBHS010RequestCsvDownloadRequest } from 'types/MCBHS010/MCBHS010RequestCsvDownloadRequest'
import { MCBHS010BlackListJobSeekerOutDto, MCBHS010ConfirmOutDto, MCBHS010SameContentCheckOutDto } from 'pages/MCBHS010/apiConfig'
momentTz.tz.setDefault('Asia/Tokyo')


const screenDisplayItems: viewConfig.ScreenDisplayItems = {
  recruitmentManagementDivision: '1',
  title: viewConfig.title,
  submissionRequestInput: viewConfig.submissionRequestInput,
  thanksMessageInput: viewConfig.thanksMessageInput,
  personalDataInput: viewConfig.personalDataInput,
  sendTargetConfirmation: viewConfig.sendTargetConfirmation,
  sendTimeInput: viewConfig.sendTimeInput,
  recruitmentManagementFlag: viewConfig.recruitmentManagementFlag,
  signature: viewConfig.signature,
  hiddenItems: viewConfig.hiddenItems,
  senderCompanyName:'',
  senderMailAddress: '',
  submissionsLink: '', // 提出物リンク
  requestExampleLink: '', // 提出リクエスト文例リンク
  thanksMailTemplateLink: '',// サンクスメールテンプレートリンク
  thanksMailExampleLink: '', // サンクスメール文例リンク
  referenceLink: '',// 問い合わせ先リンク
  personalDataHandlingLink: '', // 個人情報の取り扱いリンク
  submissionContentList: viewConfig.submissionContentList, //提出物内容リスト
  thanksMailTemplateContentList: viewConfig.thanksMailTemplateContentList,// サンクスメールテンプレート内容リスト
  contactContentList: viewConfig.contactContentList, // お問い合わせ先内容リスト
  privacyPolicyContentList: viewConfig.privacyPolicyContentList, // 個人情報の取り扱い内容リスト
  authToken: '',
  // 26P #80795、#88225 start
  annual: '', // 年度
  annualList: '', // 対象年度リスト
  annualCs: '', // チェックサム
  // 26P #80795、#88225 end
}

const registerValue: viewConfig.RegisterValue = viewConfig.registerValue

const initialState: {
  submissionInitRequest: MCBHS010RequestInitRequest
  initResult: apiConfig.InitResult
  businessCheckResult: boolean
  screenDisplayItems: viewConfig.ScreenDisplayItems
  confirmRequest: MCBHS010RequestConfirmRequest
  draftRequest: MCBHS010RequestDraftRequest
  registerValue: viewConfig.RegisterValue
  hasCloseWindow: boolean
  hasCloseWindowByDraft: boolean
  runConfirm: boolean
  openConfirm: boolean
  openSameContect: number
  successfulCountMessage: number
  successfulCountEmail: number
  failedCount: number
  isInitialized: boolean
  isDraft: boolean
  alertMessageList: string[]
  previewDataList: apiConfig.PreviewResult
  submissionReqSubjectText: string
  thanksEmailSubjectText: string
  isOnlyNonMember: boolean
  isOnlyAnonymous: boolean
  sameSubmissionList: MCBHS010SameContentCheckOutDto[]
  // #MCB1.5次開発 #9126 START
  sameMycareerCDList:MCBHS010SameContentCheckOutDto[]
  // #MCB1.5次開発 #9126 END
  notDisplayItem: viewConfig.NotDisplayItem
  attachDisableFlag: boolean
  optionDisableFlag: boolean
  // 維持保守 #9496 start
  blackListJobSeekerList: MCBHS010BlackListJobSeekerOutDto[]
  // 維持保守 #9496 end
} = {
  submissionInitRequest: submissionInitRequest,
  initResult: apiConfig.initResult,
  businessCheckResult: true,
  screenDisplayItems: screenDisplayItems,
  confirmRequest: apiConfig.requestConfirmRequest,
  draftRequest: apiConfig.requestDraftRequest,
  registerValue: registerValue,
  hasCloseWindow: false,
  hasCloseWindowByDraft: false,
  runConfirm: false,
  openConfirm: false,
  openSameContect: 0,
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  isInitialized: false,
  isDraft: false,
  alertMessageList: [],
  previewDataList: apiConfig.previewResult,
  submissionReqSubjectText: '',
  thanksEmailSubjectText: '',
  isOnlyNonMember: false,
  isOnlyAnonymous: false,
  sameSubmissionList: [],
  // #MCB1.5次開発 #9126 START
  sameMycareerCDList:[],
  // #MCB1.5次開発 #9126 END
  notDisplayItem: viewConfig.notDisplayItem,
  attachDisableFlag: false,
  optionDisableFlag: false,
  // 維持保守 #9496 start
  blackListJobSeekerList: []
  // 維持保守 #9496 end
}

const submissionRequestSendSlice = createSlice({
  name: 'submissionRequestSend',
  initialState,
  reducers: {
    // MCBリプレース MCBHS010 START
    getInit(state, action: PayloadAction<MCBHS010RequestInitRequest>) {
      state.hasCloseWindow = true
      const initRequest = action.payload
      state.submissionInitRequest = initRequest
      state.submissionInitRequest.sysVersionNumber = initRequest.sysVersionNumber
      state.submissionInitRequest.recruitmentManagementDivision = initRequest.recruitmentManagementDivision
      return state
    },
    setInit(state, action: PayloadAction<apiConfig.InitResult>) {
      state.hasCloseWindow = false
      state.initResult = action.payload
      state.attachDisableFlag = false
      state.optionDisableFlag = false
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = true
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    setRecruitmentManagementDivision(state, action: PayloadAction<string>) {
      const recruitmentManagementDivision = action.payload
      state.screenDisplayItems.recruitmentManagementDivision = recruitmentManagementDivision
      return state
    },
    setScreenDisplayItems(state) {
      const data: apiConfig.InitResult = state.initResult
      state.screenDisplayItems.authToken = data.authToken
      state.screenDisplayItems.title.selectedTemplateSettingId = data.draftMessageTemplateSettingId ? String(data.draftMessageTemplateSettingId) : ''
      state.screenDisplayItems.title.submissionRequestTemplate = data.submissionRequestTemplate
        ? data.submissionRequestTemplate
        : []
      let destinationInfoList: viewConfig.SendTarget[] = []
      let entryIdList: string[] = []
      let decisionDivisionList: string[] = []
      let manualRegistrationFlagList: string[] = []
      if (data.destinationInfo) {
        data.destinationInfo.map(target => {
          const destinationInfo: viewConfig.SendTarget = viewConfig.sendTarget
          destinationInfo.entryId = target.entryId
          destinationInfo.jobSeekerByCompanyId = target.jobSeekerByCompanyId
		// #MCB1.5次開発 #9168 START
          destinationInfo.jobSeekerIdForDisplay =target.jobSeekerIdForDisplay
		// #MCB1.5次開発 #9168 END
          // #MCB1.5次開発 #8997 START
          destinationInfo.readableFlag = target.readableFlag
		      // #MCB1.5次開発 #8997 END
          destinationInfo.sendTargetJobSeekerId=target.jobSeekerId
          destinationInfo.fullName = target.familyName + ' ' + target.name
          const age = getAge(dateToString(target.birthdate))
          destinationInfo.age = age ? age : 0
          destinationInfo.mailAddress = target.mailAddress
          const memberInfo = target.unsubscribedJobSeekerId ? magiContants.MEMBERS_TYPE_DEACTIVATED : target.memberType
          destinationInfo.mynaviMemberInfo = memberInfo
          destinationInfo.mcbUseStatusName = target.mcbUseStatus === magiContants.NUMBER_1 || target.mcbUseStatus === magiContants.NUMBER_2 ? magiContants.MCBUSE_STATUS_NAME_1 : magiContants.EMPTY 
          destinationInfo.selectionManagementId = target.selectionManagementId
          destinationInfo.schoolName = target.schoolName
          destinationInfo.facultyName = target.facultyName
          destinationInfo.subjectName = target.subjectName
          const applicationDate: string | null = momentTz(target.entryReceptionTime).format('YYYY/MM/DD HH:mm')
          destinationInfo.applicationDate = applicationDate
            ? applicationDate
            : '-'
          destinationInfo.coFormerJobSeekerId = target.coFormerJobSeekerId
          destinationInfo.viewEndTime = target.viewEndTime
          destinationInfo.familyName = target.familyName
          destinationInfo.name = target.name
          const newDestinationInfo = JSON.parse(JSON.stringify(destinationInfo))
          destinationInfoList = [...destinationInfoList, newDestinationInfo]

          entryIdList = [...entryIdList, target.entryId]
        })
      }
      state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag = data.submissionDeadlineKind ? data.submissionDeadlineKind : '0'
      state.screenDisplayItems.thanksMessageInput.thanksMessageSetFlag = data.thanksMailSettingFlag ? data.thanksMailSettingFlag : '1'
      state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag = data.resubmitReceptionFlag ? data.resubmitReceptionFlag : '0'
      let newSubmissionContentList :viewConfig.MCBHS010SubmissionsContentList[] = []
      let newContactContentList :viewConfig.MCBHS010ContactList[] = []
      let newPrivacyPolicyContentList :viewConfig.MCBHS010MyCareerBoxPrivacyPolicyList[] = []
      data.submissionContentList.map(i => {
        if(i.hiddenFlg === false){
          newSubmissionContentList = [...newSubmissionContentList,i]
        }
      })
      data.contactContentList.map(i => {
        if(i.hiddenFlg === false){
          newContactContentList = [...newContactContentList,i]
        }
      })
      data.privacyPolicyContentList.map(i => {
        if(i.hiddenFlg === false){
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList,i]
        }
      })
      state.screenDisplayItems.submissionContentList = newSubmissionContentList
      state.screenDisplayItems.thanksMailTemplateContentList = data.thanksMailTemplateContentList
      state.screenDisplayItems.contactContentList = newContactContentList
      state.screenDisplayItems.privacyPolicyContentList = newPrivacyPolicyContentList
      state.screenDisplayItems.sendTargetConfirmation.sendTarget = destinationInfoList
      state.screenDisplayItems.signature = data.signature
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName = data.searchCriteriaName
      state.screenDisplayItems.sendTargetConfirmation.searchCriteria = data.searchCriteria
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaInfo.searchCriteriaName = data.searchCriteriaName
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaInfo.searchCriteria = data.searchCriteria
      state.screenDisplayItems.sendTimeInput.sendTimeFlag = magiContants.SEND_TIME_FLAG_IMMEDIATE
      state.confirmRequest.destinationId = entryIdList
      if(newContactContentList.length === 1){
        newContactContentList.map(i =>{
          state.screenDisplayItems.submissionRequestInput.studentReferenceId = i.inquiryId
          state.screenDisplayItems.submissionRequestInput.contactName = i.inquiryRegisteredName
          state.screenDisplayItems.submissionRequestInput.companyName = i.companyName
          state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = i.departmentNameAndManagerName
          state.screenDisplayItems.submissionRequestInput.telephoneNumber = i.telNumber
          state.screenDisplayItems.submissionRequestInput.mailAddress = i.emailAddress
          state.notDisplayItem.contactModified = i.modifiedDate
        })
      }

      if(data.draftSubmissionResult){
        let submissionsList: number[]=[]
        let thanksList: number[]=[]
        let contactList: number[]=[]
        let personalList: number[]=[]
        state.screenDisplayItems.submissionContentList.map(i=>{
          // MCBリプレース #8584 START
            if(i.submissionsContentId === data.draftSubmissionResult.submissionContentId){
              state.screenDisplayItems.submissionRequestInput.submissionsSelectId = i.submissionsContentId
              state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany = i.submissionsNameForCompany
              state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent = i.submissionsNameForStudent
              state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 1 ? 1 : 0 ) :0
              state.screenDisplayItems.submissionRequestInput.submissionResumeFlag = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 2 ? 1 : 0 ) : 0
              state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag = i.researchOverviewsSubmissionFlg === true ? 1 : 0 
              state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag = i.originalQuestionSubmissionFlg === true ? 1 : 0
              state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag = i.fileSubmissionFlg === true ? 1 : 0
              state.notDisplayItem.submissionsContentModified = i.modifiedDate
            }
            // MCBリプレース #8584 END
          submissionsList.push(i.submissionsContentId)
      })
        state.screenDisplayItems.thanksMailTemplateContentList.map(i=>{
          // MCBリプレース #8584 START
          if(data.draftSubmissionResult.thanksMailSettingFlag === '1' && i.thanksMailTemplateId === data.draftSubmissionResult.thanksMailTemplateId){
            state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId = i.thanksMailTemplateId
            state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName = i.thanksMailTemplateRegisteredName
          }
          // MCBリプレース #8584 END
            thanksList.push(i.thanksMailTemplateId)
        })
        state.screenDisplayItems.contactContentList.map(i=>{
          // MCBリプレース #8584 START
          if(i.inquiryId === data.draftSubmissionResult.contactId){
            state.screenDisplayItems.submissionRequestInput.studentReferenceId = i.inquiryId
            state.screenDisplayItems.submissionRequestInput.contactName = i.inquiryRegisteredName
            state.screenDisplayItems.submissionRequestInput.companyName = i.companyName
            state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = i.departmentNameAndManagerName
            state.screenDisplayItems.submissionRequestInput.telephoneNumber = i.telNumber
            state.screenDisplayItems.submissionRequestInput.mailAddress = i.emailAddress
            state.notDisplayItem.contactModified = i.modifiedDate
          }
          // MCBリプレース #8584 END
          contactList.push(i.inquiryId)
      })
        state.screenDisplayItems.privacyPolicyContentList.map(i=>{
          // MCBリプレース #8584 START
          if(i.myCareerBoxPrivacyPolicyId === data.draftSubmissionResult.privacyPolicyId){
            state.screenDisplayItems.personalDataInput.personalDataHandlingId = i.myCareerBoxPrivacyPolicyId
            state.screenDisplayItems.personalDataInput.personalDataHandling = i.privacyPolicyRegisterdName
            state.notDisplayItem.myCareerBoxPrivacyPolicyModified = i.modifiedDate
          }
          // MCBリプレース #8584 END
          personalList.push(i.myCareerBoxPrivacyPolicyId)
      })
        if(!submissionsList.includes(data.draftSubmissionResult.submissionContentId)){
          // MCBリプレース #8584 START
          state.screenDisplayItems.submissionRequestInput.submissionsSelectId = 0
          // MCBリプレース #8584 END
          state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany = ''
          state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent = ''
        state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag = 0
        state.screenDisplayItems.submissionRequestInput.submissionResumeFlag = 0
        state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag = 0
        state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag = 0
        state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag = 0
        // MCBリプレース #8584 START
        state.notDisplayItem.submissionsContentModified = ''
        // MCBリプレース #8584 END
        }
        let submissionDeadlineDay = data.draftSubmissionResult.submissionDeadlineDay ? data.draftSubmissionResult.submissionDeadlineDay : ''
        let submissionDeadlineHour = data.draftSubmissionResult.submissionDeadlineHour ? data.draftSubmissionResult.submissionDeadlineHour : ''
        let limitDateDetermineFlag = data.draftSubmissionResult.submissionDeadlineKind ? data.draftSubmissionResult.submissionDeadlineKind : ''
        state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag = limitDateDetermineFlag
        let thanksMessageSetFlag = data.draftSubmissionResult.thanksMailSettingFlag ? data.draftSubmissionResult.thanksMailSettingFlag : '1'
        state.screenDisplayItems.thanksMessageInput.thanksMessageSetFlag = thanksMessageSetFlag
        state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag = data.draftSubmissionResult.resubmitReceptionFlag ? data.draftSubmissionResult.resubmitReceptionFlag : '0'
        
        if(limitDateDetermineFlag === '0'){
          let determineDateTime = submissionDeadlineDay + submissionDeadlineHour.toString()+'00';
          let pattern = /(\d{4})(\d{1,2})(\d{1,2})(\d{1,2})(\d{2})/;
          let formatedDate = determineDateTime.replace(pattern, '$1/$2/$3 $4:$5');
          state.screenDisplayItems.submissionRequestInput.determineDateTime = formatedDate;
        }else if(limitDateDetermineFlag === '2'){
          let anytimeReceptionEndDateTime = submissionDeadlineDay + submissionDeadlineHour.toString()+'00';
          let pattern = /(\d{4})(\d{1,2})(\d{1,2})(\d{1,2})(\d{2})/;
          let formatedDate = anytimeReceptionEndDateTime.replace(pattern, '$1/$2/$3 $4:$5');
          state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = formatedDate;
        }else{
          state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = submissionDeadlineDay
          state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = String(submissionDeadlineHour)
        }
        if(!contactList.includes(data.draftSubmissionResult.contactId)){
          // MCBリプレース #8584 Start
          state.screenDisplayItems.submissionRequestInput.studentReferenceId = 0
          // MCBリプレース #8584 END
          state.screenDisplayItems.submissionRequestInput.contactName = ''
          state.screenDisplayItems.submissionRequestInput.companyName = ''
          state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = ''
          state.screenDisplayItems.submissionRequestInput.telephoneNumber = ''
          state.screenDisplayItems.submissionRequestInput.mailAddress = ''
          // MCBリプレース #8584 START
          state.notDisplayItem.contactModified = ''
          // MCBリプレース #8584 END
        }
        state.screenDisplayItems.submissionRequestInput.requestSubject = data.draftSubmissionResult.subject
        state.screenDisplayItems.submissionRequestInput.requestText = data.draftSubmissionResult.body
        if(thanksMessageSetFlag === '1'){
          state.screenDisplayItems.thanksMessageInput.thanksSubject = data.draftSubmissionResult.thanksSubject
          state.screenDisplayItems.thanksMessageInput.thanksText = data.draftSubmissionResult.thanksBody
        }
        if(!thanksList.includes(data.draftSubmissionResult.thanksMailTemplateId)){
          state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId = 0
          state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName = ''
        }
        if(!personalList.includes(data.draftSubmissionResult.privacyPolicyId)){
          // MCBリプレース #8584 START
          state.screenDisplayItems.personalDataInput.personalDataHandlingId = 0
          state.screenDisplayItems.personalDataInput.personalDataHandling = ''
          state.notDisplayItem.myCareerBoxPrivacyPolicyModified = ''
          // MCBリプレース #8584 END
        }
        state.screenDisplayItems.sendTimeInput.sendTimeFlag = data.draftSubmissionResult.sendTimeFlag ? data.draftSubmissionResult.sendTimeFlag : magiContants.SEND_TIME_FLAG_IMMEDIATE
        if (state.screenDisplayItems.sendTimeInput.sendTimeFlag === magiContants.SEND_TIME_FLAG_IMMEDIATE ||
          data.draftSubmissionResult.sendPlanTime == null) {
          state.screenDisplayItems.sendTimeInput.sendPlanTime = null
        } else {
          const sendPlanTimeMoment = momentTz(data.draftSubmissionResult.sendPlanTime)
          const offsetTime = momentTz(sendPlanTimeMoment).add(sendPlanTimeMoment.utcOffset() + now().getTimezoneOffset(), 'minute')
          state.screenDisplayItems.sendTimeInput.sendPlanTime = offsetTime.toDate()
        }
        state.draftRequest.submissionRequestId = data.draftSubmissionResult.submissionRequestId
        let status = state.submissionInitRequest.status
        state.confirmRequest.status = status
        state.confirmRequest.submissionRequestId =  data.draftSubmissionResult.submissionRequestId
        state.confirmRequest.mcbSubmissionRequestId = data.draftSubmissionResult.mcbSubmissionRequestId
        state.screenDisplayItems.submissionContentList.map(i =>{
          if(i.submissionsContentId === data.draftSubmissionResult.submissionContentId){
            state.notDisplayItem.submissionsContentModified = i.modifiedDate
          }
        })
        state.screenDisplayItems.contactContentList.map(i =>{
          if(i.inquiryId === data.draftSubmissionResult.contactId){
            state.notDisplayItem.contactModified = i.modifiedDate
          }
        })
        state.screenDisplayItems.privacyPolicyContentList.map(i =>{
          if(i.myCareerBoxPrivacyPolicyId === data.draftSubmissionResult.privacyPolicyId){
            state.notDisplayItem.myCareerBoxPrivacyPolicyModified = i.modifiedDate
          }
        })
      }
      state.screenDisplayItems.submissionRequestInput.senderCompanyName = data.senderCompanyName
        ? data.senderCompanyName
        : ''
      state.screenDisplayItems.submissionRequestInput.senderMailAddress = data.senderMailAddress
        ? data.senderMailAddress
        : ''
      state.screenDisplayItems.submissionsLink = data.submissionsLink
      state.screenDisplayItems.referenceLink = data.referenceLink
      state.screenDisplayItems.requestExampleLink = data.requestExampleLink
      state.screenDisplayItems.thanksMailExampleLink = data.thanksMailExampleLink
      state.screenDisplayItems.thanksMailTemplateLink = data.thanksMailTemplateLink
      state.screenDisplayItems.personalDataHandlingLink = data.personalDataHandlingLink
      const replyFromSubject: string = data.replyFromSubject
      if (replyFromSubject) {
        state.screenDisplayItems.submissionRequestInput.requestSubject =
          magiContants.REPLY_PREFIX_SUBJECT + replyFromSubject
      } else {
        if (isEmpty(state.screenDisplayItems.submissionRequestInput.requestSubject)) {
          state.screenDisplayItems.submissionRequestInput.requestSubject = data.requestSubject
            ? data.requestSubject
            : ''
        }
      }
      state.screenDisplayItems.submissionRequestInput.targetInput = 'requestSubject'

      let attachmentDataList = data.attachment
      let attachmentList: viewConfig.Attachment[] = []
      if (attachmentDataList) {
        attachmentDataList.map(item => {
          let attachment: viewConfig.Attachment = {
            attachmentFileName: '',
            attachmentId: '',
            attachmentName: '',
          }
          attachment.attachmentId = item.fileManagementId
          attachment.attachmentName = item.attachmentName
          attachment.attachmentFileName = item.attachmentName
          attachmentList = [...attachmentList, attachment]
        })
      }
      if (attachmentList.length <= 0) {
        let attachment: viewConfig.Attachment = {
          attachmentFileName: '',
          attachmentId: '',
          attachmentName: '',
        }
        attachmentList = [...attachmentList, attachment]
      }
      state.screenDisplayItems.submissionRequestInput.attachment = attachmentList

      let homePageData = data.draftHomepage
      let homePageList: viewConfig.HomePage[] = []
      if (homePageData) {
        for (var i = 0; i < homePageData.length; i++) {
          let homePage: viewConfig.HomePage = {
            homePageTitle: '',
            homePageUrl: '',
          }
          homePage.homePageTitle = homePageData[i].homePageTitle
            ? homePageData[i].homePageTitle
            : ''
          homePage.homePageUrl = homePageData[i].homePageUrl
            ? homePageData[i].homePageUrl
            : ''
          homePageList = [...homePageList, homePage]
        }
      }
      if (homePageList.length <= 0) {
        let homePage: viewConfig.HomePage = {
          homePageTitle: '',
          homePageUrl: '',
        }
        homePageList = [...homePageList, homePage]
      }
      state.screenDisplayItems.submissionRequestInput.homePage = homePageList


      state.screenDisplayItems.recruitmentManagementFlag = data.recruitmentManagementFlag

      state.screenDisplayItems.hiddenItems.adjustmentInterviewScheduleAdjustmentId = data.adjustmentInterviewScheduleAdjustmentId

      state.isInitialized = true
      // 26P #80795、#88225 start
      state.screenDisplayItems.annual = data.annual
      state.screenDisplayItems.annualList = data.annualList
      state.screenDisplayItems.annualCs = data.annualCs != null ? data.annualCs : '' 
      // 26P #80795、#88225 end
      return state
    },
    deleteSendTarget(state, action: PayloadAction<{ id: string }>){
      const entryId = action.payload.id
      const targetList = state.screenDisplayItems.sendTargetConfirmation.sendTarget
      const selectionManagerIds : string[] = state.initResult.selectionManagementIdList
      const jobSeekerIds : string[] = state.initResult.jobSeekerIdList
      let newSendTargetList : viewConfig.SendTarget[] = []
      if(targetList){
      targetList.map(item =>{
        if(item.entryId !== entryId){
          newSendTargetList = [...newSendTargetList , item]
        }
      })
      for(let i=0;i<targetList.length;i++){
        if(targetList[i].entryId === entryId){
          selectionManagerIds.splice(i,1);
          jobSeekerIds.splice(i,1);
        }
      }
    }
      state.initResult.selectionManagementIdList = selectionManagerIds
      state.initResult.jobSeekerIdList = jobSeekerIds
      state.screenDisplayItems.sendTargetConfirmation.sendTarget = newSendTargetList
      return state
    },

    changeDeadlineType(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag = value
      if(value === '0'){
        state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = ''
        state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = ''
        state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = ''
      }else if(value === '1'){
        state.screenDisplayItems.submissionRequestInput.determineDateTime = ''
        state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = ''
      }else if(value === '2'){
        state.screenDisplayItems.submissionRequestInput.determineDateTime = ''
        state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = ''
        state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = ''
        state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag = '0'
      }
      return state
    },
    changeResubmission(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag = value
      return state
    },
    changethanksMailSettingFlag(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.thanksMessageInput.thanksMessageSetFlag = value
      return state
    },

    changeSendTimeFlag(state, action: PayloadAction<string>) {
      const value = action.payload
      state.screenDisplayItems.sendTimeInput.sendTimeFlag = value
    },
    inputSendTimer(state, action: PayloadAction<{ value: Date | null }>) {
      const value = action.payload.value
      state.screenDisplayItems.sendTimeInput.sendPlanTime = value
      return state
    },
    clearSendTime(state){
      state.screenDisplayItems.sendTimeInput.sendPlanTime = null
      // return state
    },

    addAttachment(state) {
      let attachment: viewConfig.Attachment[] =
        state.screenDisplayItems.submissionRequestInput.attachment
      let attachmentValue: viewConfig.Attachment = {
        attachmentFileName: '',
        attachmentId: '',
        attachmentName: '',
      }
      state.screenDisplayItems.submissionRequestInput.attachment = [
        ...attachment,
        attachmentValue,
      ]
      return state
    },

    deleteAttachment(state, action: PayloadAction<{ index: number }>) {
      let index: number = action.payload.index
      let attachment: viewConfig.Attachment[] =
        state.screenDisplayItems.submissionRequestInput.attachment
      attachment.splice(index, 1)
      state.screenDisplayItems.submissionRequestInput.attachment = attachment
      if(attachment.length < 5){
        state.attachDisableFlag = false
      }
      return state
    },
    inputAttachment(
      state,
      action: PayloadAction<{ index: number; fileName: string }>
    ) {
      const index = action.payload.index
      const fileName = action.payload.fileName
      state.screenDisplayItems.submissionRequestInput.attachment[index].attachmentFileName = fileName
      return state
    },
    inputHomePageTitle(
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) {
      let index = action.payload.index
      let value = action.payload.value
      state.screenDisplayItems.submissionRequestInput.homePage[
        index
      ].homePageTitle = value
      return state
    },
    inputHomePageUrl(
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) {
      let index = action.payload.index
      let value = action.payload.value
      state.screenDisplayItems.submissionRequestInput.homePage[index].homePageUrl = value
      return state
    },
    deleteHomePage(state, action: PayloadAction<{ index: number }>) {
      let index: number = action.payload.index
      let homePage: viewConfig.HomePage[] =
        state.screenDisplayItems.submissionRequestInput.homePage
      homePage.splice(index, 1)
      state.screenDisplayItems.submissionRequestInput.homePage = homePage
      if(homePage.length < 3){
        state.optionDisableFlag = false
      }
      return state
    },
    addHomePage(state) {
      let homePage: viewConfig.HomePage[] =
        state.screenDisplayItems.submissionRequestInput.homePage
      let homePageValue: viewConfig.HomePage = {
        homePageTitle: '',
        homePageUrl: '',
      }
      state.screenDisplayItems.submissionRequestInput.homePage = [
        ...homePage,
        homePageValue,
      ]
      return state
    },

    getReloadSubmissions(state){
      return state
    },
    setReloadSubmissions(state, action: PayloadAction<apiConfig.InitResult>){
      let newSubmissionContentList :viewConfig.MCBHS010SubmissionsContentList[] = []
      action.payload.submissionContentList.map(i => {
        if(i.hiddenFlg === false){
          newSubmissionContentList = [...newSubmissionContentList,i]
        }
      })
      state.screenDisplayItems.submissionContentList = newSubmissionContentList
      state.screenDisplayItems.submissionRequestInput.submissionsSelectId = 0
      state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany = ''
      state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent = ''
      state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag = 0
      state.screenDisplayItems.submissionRequestInput.submissionResumeFlag = 0
      state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag = 0
      state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag = 0
      state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag = 0
      return state
    },

    getReloadThanksTemplate(state){
      return state
    },
    setReloadThanksTemplate(state,action: PayloadAction<apiConfig.InitResult>) {
      state.screenDisplayItems.thanksMailTemplateContentList = action.payload.thanksMailTemplateContentList
      state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId = 0
      state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName = ''
      return state
    },
    getReloadReference(state){
      return state
    },
    setReloadReference(state,action: PayloadAction<apiConfig.InitResult>) {
      let newContactContentList :viewConfig.MCBHS010ContactList[] = []
      action.payload.contactContentList.map(i => {
        if(i.hiddenFlg === false){
          newContactContentList = [...newContactContentList,i]
        }
      })
      state.screenDisplayItems.contactContentList = newContactContentList
      state.screenDisplayItems.submissionRequestInput.studentReferenceId = 0
      state.screenDisplayItems.submissionRequestInput.contactName = ''
      state.screenDisplayItems.submissionRequestInput.companyName = ''
      state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = ''
      state.screenDisplayItems.submissionRequestInput.telephoneNumber = ''
      state.screenDisplayItems.submissionRequestInput.mailAddress = ''
      if(newContactContentList.length === 1){
        state.screenDisplayItems.submissionRequestInput.studentReferenceId = newContactContentList[0].inquiryId
        state.screenDisplayItems.submissionRequestInput.contactName = newContactContentList[0].inquiryRegisteredName
        state.screenDisplayItems.submissionRequestInput.companyName = newContactContentList[0].companyName
        state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = newContactContentList[0].departmentNameAndManagerName
        state.screenDisplayItems.submissionRequestInput.telephoneNumber = newContactContentList[0].telNumber
        state.screenDisplayItems.submissionRequestInput.mailAddress = newContactContentList[0].emailAddress
        state.notDisplayItem.contactModified = newContactContentList[0].modifiedDate
     }
      return state
    },
    getReloadPersonal(state){
      return state
    },
    setReloadPersonal(state,action: PayloadAction<apiConfig.InitResult>) {
      let newPrivacyPolicyContentList :viewConfig.MCBHS010MyCareerBoxPrivacyPolicyList[] = []
      action.payload.privacyPolicyContentList.map(i => {
        if(i.hiddenFlg === false){
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList,i]
        }
      })
      state.screenDisplayItems.privacyPolicyContentList = newPrivacyPolicyContentList
      state.screenDisplayItems.personalDataInput.personalDataHandlingId = 0
      state.screenDisplayItems.personalDataInput.personalDataHandling = ''
      return state
    },

    inputSubmissionReqSubject(state, action: PayloadAction<{value: string }>) {
      // let target = action.payload.target
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      // state.screenDisplayItems.submissionRequestInput.targetInput = target
      state.screenDisplayItems.submissionRequestInput.requestSubject = replacedValue
      return state
    },

    changeTargetInput(state, action: PayloadAction<{ target: string }>) {
      let target = action.payload.target
      state.screenDisplayItems.submissionRequestInput.targetInput = target
      return state
    },

    inputSubmissionReqBody(state, action: PayloadAction<{value: string }>) {
      let value = action.payload.value
      state.screenDisplayItems.submissionRequestInput.requestText = value
      return state
    },
    inputThanksEmailSubject(state, action: PayloadAction<{value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.thanksMessageInput.thanksSubject = replacedValue
      return state
    },
    inputThanksEmailBody(state, action: PayloadAction<{value: string }>) {
      let value = action.payload.value
      state.screenDisplayItems.thanksMessageInput.thanksText = value
      return state
    },
    getPreview(state, action: PayloadAction<{ formData: MCBHS010SubmitRequestPreviewRequest }>) {
      // state.previewDataList = []
      return state
    },
    setPreview(state, action: PayloadAction<apiConfig.PreviewResult>) {
      state.previewDataList = action.payload
      return state
    },

    inputDestinationSelection(state, action: PayloadAction<{ target: string, companyName: string, mailAddress: string }>) {
      const destinationSelection = action.payload.target
      const companyName = action.payload.companyName
      const mailAddress = action.payload.mailAddress
      state.screenDisplayItems.sendTargetConfirmation.destinationSelection = destinationSelection
      state.screenDisplayItems.submissionRequestInput.senderCompanyName = companyName
      state.screenDisplayItems.submissionRequestInput.senderMailAddress = mailAddress
      return state
    },

    setEmptyTemplateSettingId(state) {
      state.screenDisplayItems.title.selectedTemplateSettingId = '0'
      return state
    },

    getTemplateAttachment(state, action: PayloadAction<{param :MCBHS010SubmissionTemplateRequest}>){
      return state
    },
    setTemplateAttachment(state, action: PayloadAction<apiConfig.InitResult>){
      if (action.payload.submissionTemplateAttachment) {
        let attachmentList: viewConfig.Attachment[] = []
        action.payload.submissionTemplateAttachment.map(templateAttachment => {
            if (templateAttachment.sequence <= state.initResult.attachmentFileRegistrationMax) {
              let attachmentData: viewConfig.Attachment = {
              attachmentId: templateAttachment.fileManagementId,
              attachmentFileName: templateAttachment.attachmentName,
              attachmentName: templateAttachment.attachmentName
              }
              attachmentList = [...attachmentList, attachmentData]
            }
        })
        if(attachmentList && attachmentList.length >= 5){
          state.attachDisableFlag = true
        }else{
          state.attachDisableFlag = false
        }
        if (attachmentList.length <= 0) {
          const attachmentData: viewConfig.Attachment = {
            attachmentId: '',
            attachmentFileName: '',
            attachmentName: '',
          }
          attachmentList = [...attachmentList, attachmentData]
        }
        state.screenDisplayItems.submissionRequestInput.attachment = attachmentList
        
      }
      return state
    },

    clearThanks(state){
      state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId = 0
      state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName = ''
    },
    // MCBリプレース #8606 START
    clearPersonalInfo(state){
      state.screenDisplayItems.personalDataInput.personalDataHandlingId = 0
      state.screenDisplayItems.personalDataInput.personalDataHandling = ''
    },
    // MCBリプレース #8606 END
    changeSubmissionData(state, action: PayloadAction<string>){
      let id = Number(action.payload)
      state.screenDisplayItems.submissionRequestInput.submissionsSelectId = id
      let submissionList = state.screenDisplayItems.submissionContentList
      if(submissionList){
        submissionList.map(item =>{
        if(id === item.submissionsContentId){
          state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany = item.submissionsNameForCompany
          state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent = item.submissionsNameForStudent
          state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag = item.entrySheetResumeSubmissionFlg === true ? (item.entrySheetResumeSubmissionCategory === 1 ? 1 : 0 ) :0
          state.screenDisplayItems.submissionRequestInput.submissionResumeFlag = item.entrySheetResumeSubmissionFlg === true ? (item.entrySheetResumeSubmissionCategory === 2 ? 1 : 0 ) : 0
          state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag = item.researchOverviewsSubmissionFlg === true ? 1 : 0 
          state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag = item.originalQuestionSubmissionFlg === true ? 1 : 0
          state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag = item.fileSubmissionFlg === true ? 1 : 0
          state.notDisplayItem.submissionsContentModified = item.modifiedDate
        }
      })
    }
    },
    changeContactData(state, action: PayloadAction<string>){
      let id = Number(action.payload)
      state.screenDisplayItems.submissionRequestInput.studentReferenceId = id
      let contactList = state.screenDisplayItems.contactContentList
      if(contactList){
      contactList.map(item =>{
        if(id === item.inquiryId){
          state.screenDisplayItems.submissionRequestInput.contactName = item.inquiryRegisteredName
          state.screenDisplayItems.submissionRequestInput.companyName = item.companyName
          state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = item.departmentNameAndManagerName
          state.screenDisplayItems.submissionRequestInput.telephoneNumber = item.telNumber
          state.screenDisplayItems.submissionRequestInput.mailAddress = item.emailAddress
          state.notDisplayItem.contactModified = item.modifiedDate
        }
      })
    }
    },
    changeThanksData(state, action: PayloadAction<string>){
      let id = Number(action.payload)
      state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId = id
      let thanksList = state.screenDisplayItems.thanksMailTemplateContentList
      if(thanksList){
        thanksList.map(item =>{
        if(id === item.thanksMailTemplateId){
          state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName = item.thanksMailTemplateRegisteredName
          state.screenDisplayItems.thanksMessageInput.thanksSubject = item.thanksMailTemplateSubject
          state.screenDisplayItems.thanksMessageInput.thanksText = item.thanksMailTemplateBody
        }
      })
    }
    },
    changePersonalData(state, action: PayloadAction<string>){
      let id = Number(action.payload)
      state.screenDisplayItems.personalDataInput.personalDataHandlingId = id
      let personalList = state.screenDisplayItems.privacyPolicyContentList
      if(personalList){
        personalList.map(item =>{
        if(id === item.myCareerBoxPrivacyPolicyId){
          state.screenDisplayItems.personalDataInput.personalDataHandling = item.privacyPolicyRegisterdName
          state.notDisplayItem.myCareerBoxPrivacyPolicyModified = item.modifiedDate
        }
      })
    }
    },
    selectTemplate(state, action: PayloadAction<{ id: string, inputValues: any }>) {
      const submissionRequestTemplateSettingId = action.payload.id

      if (state.screenDisplayItems.title.submissionRequestTemplate) {
        state.screenDisplayItems.title.submissionRequestTemplate.map(template => {
          if (template && template.submissionRequestTemplateSettingId === submissionRequestTemplateSettingId) {
            state.screenDisplayItems.title.selectedTemplateSettingId = submissionRequestTemplateSettingId

            const sendTargetList = state.screenDisplayItems.sendTargetConfirmation.sendTarget
            let templateDestinationSelection = !isEmpty(template.destinationDivision) ? template.destinationDivision : '3'
            // let templateSendToNonMember = (template.sendToNonMember != null) ? template.sendToNonMember : magiContants.SEND_TO_NON_MEMBER
            let submissionsList: number[]=[]
            let thanksList: number[]=[]
            let contactList: number[]=[]
            let personalList: number[]=[]
            state.screenDisplayItems.submissionContentList.map(i=>{
              // MCBリプレース #8584 START
              if(i.submissionsContentId === template.submissionContentId){
                state.screenDisplayItems.submissionRequestInput.submissionsSelectId = i.submissionsContentId
                state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany = i.submissionsNameForCompany
                state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent = i.submissionsNameForStudent
                state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 1 ? 1 : 0 ) :0
                state.screenDisplayItems.submissionRequestInput.submissionResumeFlag = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 2 ? 1 : 0 ) : 0
                state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag = i.researchOverviewsSubmissionFlg === true ? 1 : 0 
                state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag = i.originalQuestionSubmissionFlg === true ? 1 : 0
                state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag = i.fileSubmissionFlg === true ? 1 : 0
                state.notDisplayItem.submissionsContentModified = i.modifiedDate
              }
              // MCBリプレース #8584 END
              submissionsList.push(i.submissionsContentId)
          })
            state.screenDisplayItems.thanksMailTemplateContentList.map(i=>{
              // MCBリプレース #8584 START
              if(template.thanksMailSettingFlag === '1' && i.thanksMailTemplateId === template.thanksMailTemplateId){
                state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId = i.thanksMailTemplateId
                state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName = i.thanksMailTemplateRegisteredName
              }
              // MCBリプレース #8584 END
                thanksList.push(i.thanksMailTemplateId)
            })
            state.screenDisplayItems.contactContentList.map(i=>{
              // MCBリプレース #8584 START
              if(i.inquiryId === template.contactId){
                state.screenDisplayItems.submissionRequestInput.studentReferenceId = i.inquiryId
                state.screenDisplayItems.submissionRequestInput.contactName = i.inquiryRegisteredName
                state.screenDisplayItems.submissionRequestInput.companyName = i.companyName
                state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = i.departmentNameAndManagerName
                state.screenDisplayItems.submissionRequestInput.telephoneNumber = i.telNumber
                state.screenDisplayItems.submissionRequestInput.mailAddress = i.emailAddress
                state.notDisplayItem.contactModified = i.modifiedDate
              }
              // MCBリプレース #8584 END
              contactList.push(i.inquiryId)
          })
            state.screenDisplayItems.privacyPolicyContentList.map(i=>{
              // MCBリプレース #8584 START
              if(i.myCareerBoxPrivacyPolicyId === template.privacyPolicyId){
                state.screenDisplayItems.personalDataInput.personalDataHandlingId = i.myCareerBoxPrivacyPolicyId
                state.screenDisplayItems.personalDataInput.personalDataHandling = i.privacyPolicyRegisterdName
                state.notDisplayItem.myCareerBoxPrivacyPolicyModified = i.modifiedDate
              }
              // MCBリプレース #8584 END
              personalList.push(i.myCareerBoxPrivacyPolicyId)
          })
            if(!submissionsList.includes(template.submissionContentId)){
              // MCBリプレース #8584 START
              state.screenDisplayItems.submissionRequestInput.submissionsSelectId = 0
              // MCBリプレース #8584 END
              state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany = ''
              state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent = ''
              state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag = 0
              state.screenDisplayItems.submissionRequestInput.submissionResumeFlag = 0
              state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag = 0
              state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag = 0
              state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag = 0
              // MCBリプレース #8584 START
              state.notDisplayItem.submissionsContentModified = ''
              // MCBリプレース #8584 END
            }
            state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag = template.submissionDeadlineKind
            // MCBリプレース #8598 START
            if(!isEmpty(template.submissionDeadlineKind) && !isEmpty(template.submissionDeadlineDay) && !isEmpty(template.submissionDeadlineHour) ){
            // MCBリプレース #8598 END
            if(template.submissionDeadlineKind === '0'){
              let determineDateTime = template.submissionDeadlineDay + template.submissionDeadlineHour.toString()+'00';
              let pattern = /(\d{4})(\d{1,2})(\d{1,2})(\d{1,2})(\d{2})/;
              let formatedDate = determineDateTime.replace(pattern, '$1/$2/$3 $4:$5');
              state.screenDisplayItems.submissionRequestInput.determineDateTime = formatedDate;
              state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = ''
              state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = ''
              state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = ''
            }else if(template.submissionDeadlineKind === '2'){
              let anytimeReceptionEndDateTime = template.submissionDeadlineDay + template.submissionDeadlineHour.toString()+'00';
              let pattern = /(\d{4})(\d{1,2})(\d{1,2})(\d{1,2})(\d{2})/;
              let formatedDate = anytimeReceptionEndDateTime.replace(pattern, '$1/$2/$3 $4:$5');
              state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = formatedDate;
              state.screenDisplayItems.submissionRequestInput.determineDateTime = ''
              state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = ''
              state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = ''
            }else{
              state.screenDisplayItems.submissionRequestInput.determineDateTime = ''
              state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = ''
              state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = template.submissionDeadlineDay
              state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = template.submissionDeadlineHour
            }
            // MCBリプレース #8598 START
          }else{
            state.screenDisplayItems.submissionRequestInput.determineDateTime = ''
            state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = ''
            state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = ''
            state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = ''
            state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag = '0'
          }
          // MCBリプレース #8598 END
            state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag = template.resubmitReceptionFlag
            let senderCompanyName = ''
            if ('' !== template.senderCompanyName) {
              senderCompanyName = template.senderCompanyName
            }
            state.screenDisplayItems.submissionRequestInput.senderCompanyName = senderCompanyName

            let senderMailAddress = ''
            if ('' !== template.senderMailAddress) {
              senderMailAddress = template.senderMailAddress
            }
            state.screenDisplayItems.submissionRequestInput.senderMailAddress = senderMailAddress
            if(!contactList.includes(template.contactId)){
              // MCBリプレース #8584 START
              state.screenDisplayItems.submissionRequestInput.studentReferenceId = 0
              // MCBリプレース #8584 END
              state.screenDisplayItems.submissionRequestInput.contactName = ''
              state.screenDisplayItems.submissionRequestInput.companyName = ''
              state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = ''
              state.screenDisplayItems.submissionRequestInput.telephoneNumber = ''
              state.screenDisplayItems.submissionRequestInput.mailAddress = ''
              // MCBリプレース #8584 START
              state.notDisplayItem.contactModified = ''
              // MCBリプレース #8584 END
            }
            state.screenDisplayItems.submissionRequestInput.requestSubject = template.requestSubject
            state.screenDisplayItems.submissionRequestInput.requestText = template.requestText
            // state.screenDisplayItems.submissionRequestInput.barcodeDisplay = !isEmpty(template.barcordDisplayFlag) ? template.barcordDisplayFlag : magiContants.BARCODE_DISPLAY_FLAG_NOT_SHOW

            let homePageList: viewConfig.HomePage[] = []
              for (var i = 0; i < 3; i++) {
                let homePage: viewConfig.HomePage = {
                  homePageTitle: '',
                  homePageUrl: '',
                }
                switch(i){
                  case (0) :
                    if((template.homePageTitle1 !== null && template.homePageTitle1 !== '')||(template.homePageUrl1 !== null && template.homePageUrl1 !== '')){
                    homePage.homePageTitle = template.homePageTitle1
                    ? template.homePageTitle1
                    : ''
                  homePage.homePageUrl = template.homePageUrl1
                    ? template.homePageUrl1
                    : ''
                  homePageList = [...homePageList, homePage]
                  }
                break;
                  case (1) :
                    if((template.homePageTitle2 !== null && template.homePageTitle2 !== '')||(template.homePageUrl2 !== null && template.homePageUrl2 !== '')){
                    homePage.homePageTitle = template.homePageTitle2
                    ? template.homePageTitle2
                    : ''
                  homePage.homePageUrl = template.homePageUrl2
                    ? template.homePageUrl2
                    : ''
                  homePageList = [...homePageList, homePage]
                    }
                break;
                  case (2) :
                    if((template.homePageTitle3 !== null && template.homePageTitle3 !== '')||(template.homePageUrl3 !== null && template.homePageUrl3 !== '')){
                    homePage.homePageTitle = template.homePageTitle3
                    ? template.homePageTitle3
                    : ''
                  homePage.homePageUrl = template.homePageUrl3
                    ? template.homePageUrl3
                    : ''
                  homePageList = [...homePageList, homePage]
                    }
                break;
                  default: break
                }
              }
              if(homePageList && homePageList.length >= 3){
                state.optionDisableFlag = true
              }else{
                state.optionDisableFlag = false
              }
            if (homePageList.length <= 0) {
              let homePage: viewConfig.HomePage = {
                homePageTitle: '',
                homePageUrl: '',
              }
              homePageList = [...homePageList, homePage]
            }
            state.screenDisplayItems.submissionRequestInput.homePage = homePageList

            state.screenDisplayItems.thanksMessageInput.thanksMessageSetFlag = template.thanksMailSettingFlag
            if(template.thanksMailSettingFlag === '1'){
              state.screenDisplayItems.thanksMessageInput.thanksSubject = template.thanksSubject
              state.screenDisplayItems.thanksMessageInput.thanksText = template.thanksBody
              if(!thanksList.includes(template.thanksMailTemplateId)){
                state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId =0
                state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName = ''
              }
            }
            if(!personalList.includes(template.privacyPolicyId)){
              // MCBリプレース #8584 START
              state.screenDisplayItems.personalDataInput.personalDataHandlingId = 0
              state.screenDisplayItems.personalDataInput.personalDataHandling = ''
              state.notDisplayItem.myCareerBoxPrivacyPolicyModified = ''
              // MCBリプレース #8584 END
            }

            
          } else if (-1 === Number(submissionRequestTemplateSettingId)) {

            state.screenDisplayItems.submissionRequestInput.submissionsSelectId = 0
            state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany = ''
            state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent = ''
            // state.screenDisplayItems.submissionRequestInput.submissionsDetails = ''
            state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag = 0
            state.screenDisplayItems.submissionRequestInput.submissionResumeFlag = 0
            state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag = 0
            state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag = 0
            state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag = 0
            state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag = '0'
            state.screenDisplayItems.submissionRequestInput.determineDateTime = ''
            state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = ''
            state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = ''
            state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = ''
            state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag = '0'
            state.screenDisplayItems.submissionRequestInput.senderCompanyName = ''
            state.screenDisplayItems.submissionRequestInput.senderMailAddress = ''
            state.screenDisplayItems.submissionRequestInput.studentReferenceId = 0
            state.screenDisplayItems.submissionRequestInput.contactName = ''
            state.screenDisplayItems.submissionRequestInput.companyName = ''
            state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName = ''
            state.screenDisplayItems.submissionRequestInput.telephoneNumber = ''
            state.screenDisplayItems.submissionRequestInput.mailAddress = ''
            state.screenDisplayItems.submissionRequestInput.requestSubject = ''
            state.screenDisplayItems.submissionRequestInput.requestText = ''

            state.screenDisplayItems.submissionRequestInput.attachment = [{
              attachmentFileName: '',
              attachmentId: '',
              attachmentName: '',
            }]

            state.screenDisplayItems.submissionRequestInput.homePage = [{
              homePageTitle: '',
              homePageUrl: '',
            }]
            state.screenDisplayItems.thanksMessageInput.thanksMessageSetFlag = '1'
            state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId = 0
            state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName = ''
            state.screenDisplayItems.thanksMessageInput.thanksSubject = ''
            state.screenDisplayItems.thanksMessageInput.thanksText = ''
            state.screenDisplayItems.personalDataInput.personalDataHandlingId = 0
            state.screenDisplayItems.personalDataInput.personalDataHandling = ''
            state.screenDisplayItems.sendTimeInput = viewConfig.sendTimeInput
            state.optionDisableFlag = false
            state.attachDisableFlag = false
            const data: apiConfig.InitResult = state.initResult
          }
        })
      }
      return state
    },

    inputSenderCompanyName(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.submissionRequestInput.senderCompanyName = replacedValue
      return state
    },
    inputSenderMailAddress(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.submissionRequestInput.senderMailAddress = replacedValue
      return state
    },

    setIsDraft(state, action: PayloadAction<boolean>) {
      state.isDraft = action.payload
      return state
    },

    setDraftReqeust(state) {
      state.draftRequest.requestTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId && state.screenDisplayItems.title.selectedTemplateSettingId !== '0' ? state.screenDisplayItems.title.selectedTemplateSettingId : ''

      // state.draftRequest.sourceDraftMessageSendId = state.messageInitRequest.draftMessageSendId ? String(state.messageInitRequest.draftMessageSendId) : ''
      // state.draftRequest.destination = Number(state.screenDisplayItems.sendTargetConfirmation.destinationSelection)
      const sendTarget =
        state.screenDisplayItems.sendTargetConfirmation.sendTarget
      let entryIdList: string[] = []
      let jobSeekerByCompanyIdList: string[] = []
      let sendTargetJobSeekerIdList: string[] = []
      let selectionManagementIdList: string[] = []
      if (sendTarget) {
        sendTarget.map(item => {
          entryIdList = [...entryIdList, item.entryId]
          jobSeekerByCompanyIdList = [
            ...jobSeekerByCompanyIdList,
            item.jobSeekerByCompanyId
          ]
          sendTargetJobSeekerIdList = [
            ...sendTargetJobSeekerIdList,
            item.sendTargetJobSeekerId
          ]
          selectionManagementIdList = [
            ...selectionManagementIdList,
            item.selectionManagementId
          ]
        })
      }
      state.draftRequest.destinationId = entryIdList
      state.draftRequest.companyContractIdList = state.initResult.companyContractIdList
      state.draftRequest.jobSeekerIdList = sendTargetJobSeekerIdList
      state.draftRequest.selectionManagementIdList = selectionManagementIdList
      state.draftRequest.jobSeekerByCompanyIdList = jobSeekerByCompanyIdList
      state.draftRequest.messageSendId = state.initResult.messageSendId
      let submissionsSelectId = state.screenDisplayItems.submissionRequestInput.submissionsSelectId
      state.draftRequest.submissionsSelectId = submissionsSelectId.toString()
      state.draftRequest.submissionsNameForCompany = state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany
      state.draftRequest.submissionsNameForStudent = state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent
      state.draftRequest.submissionEntrySheetFlag = state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag
      state.draftRequest.submissionResumeFlag = state.screenDisplayItems.submissionRequestInput.submissionResumeFlag
      state.draftRequest.submissionResearchSummaryFlag = state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag
      state.draftRequest.submissionOriginalQuestionFlag = state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag
      state.draftRequest.submissionFileSubmissionFlag = state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag
      let limitDateDetermineFlag = Number(state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag)
      state.draftRequest.limitDateDetermineFlag = limitDateDetermineFlag
      if(limitDateDetermineFlag === 0){
        state.draftRequest.determineDateTime = state.screenDisplayItems.submissionRequestInput.determineDateTime
      }else if(limitDateDetermineFlag === 1){
        state.draftRequest.requestDayTimeLaterDay = state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay
        state.draftRequest.requestDayTimeToTime = state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime
      }else if(limitDateDetermineFlag === 2){
        state.draftRequest.anytimeReceptionEndDateTime = state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime
      }
      state.draftRequest.reRequestReceptFlag = Number(state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag)
      state.draftRequest.senderCompanyName = state.screenDisplayItems.submissionRequestInput.senderCompanyName
      state.draftRequest.senderMailAddress = state.screenDisplayItems.submissionRequestInput.senderMailAddress
      state.draftRequest.requestSubject = state.screenDisplayItems.submissionRequestInput.requestSubject
      state.draftRequest.requestText = state.screenDisplayItems.submissionRequestInput.requestText
      const attachmentData = state.screenDisplayItems.submissionRequestInput.attachment
      let attachmentIdList: string[] = []
      let attachmentNameList: string[] = []
      if (attachmentData) {
        attachmentData.map(item => {
          let attachment = item
          attachmentIdList = [...attachmentIdList, attachment.attachmentId]
          attachmentNameList = [...attachmentNameList, attachment.attachmentName]
        })
      }
      state.draftRequest.requestAttachmentId =
        attachmentIdList.length > 0 && attachmentIdList[0] !== ''
          ? attachmentIdList
          : []
      state.draftRequest.requestAttachmentName =
        attachmentNameList.length > 0 && attachmentNameList[0] !== ''
          ? attachmentNameList
          : []
      const homePage1 = state.screenDisplayItems.submissionRequestInput.homePage[0]
      if (homePage1) {
        state.draftRequest.homepageTitle1 = homePage1.homePageTitle && homePage1.homePageTitle !== '' ? homePage1.homePageTitle : null
        state.draftRequest.homepageUrl1 = homePage1.homePageUrl && homePage1.homePageUrl !== '' ? homePage1.homePageUrl : null
      }else{
        state.draftRequest.homepageTitle1 = null
        state.draftRequest.homepageUrl1 = null
      }
      const homePage2 = state.screenDisplayItems.submissionRequestInput.homePage[1]
      if (homePage2) {
        state.draftRequest.homepageTitle2 = homePage2.homePageTitle && homePage2.homePageTitle !== '' ? homePage2.homePageTitle : null
        state.draftRequest.homepageUrl2 = homePage2.homePageUrl && homePage2.homePageUrl !== '' ? homePage2.homePageUrl : null
      }else{
        state.draftRequest.homepageTitle2 = null
        state.draftRequest.homepageUrl2 = null
      }
      const homePage3 = state.screenDisplayItems.submissionRequestInput.homePage[2]
      if (homePage3) {
        state.draftRequest.homepageTitle3 = homePage3.homePageTitle && homePage3.homePageTitle !== '' ? homePage3.homePageTitle : null
        state.draftRequest.homepageUrl3 = homePage3.homePageUrl && homePage3.homePageUrl !== '' ? homePage3.homePageUrl : null
      }else{
        state.draftRequest.homepageTitle3 = null
        state.draftRequest.homepageUrl3 = null
      }
      state.draftRequest.thanksMessageSetFlag = Number(state.screenDisplayItems.thanksMessageInput.thanksMessageSetFlag)
      if(state.draftRequest.thanksMessageSetFlag === 1){
        let thanksMessageTemplateId = state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId
        if(thanksMessageTemplateId === 0){
          state.draftRequest.thanksMessageTemplateId = null
        }else{
          state.draftRequest.thanksMessageTemplateId = thanksMessageTemplateId.toString()
        }
        state.draftRequest.thanksMessageTemplateName = state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName
        state.draftRequest.thanksSubject = state.screenDisplayItems.thanksMessageInput.thanksSubject
        state.draftRequest.thanksText = state.screenDisplayItems.thanksMessageInput.thanksText
      }else{
        state.draftRequest.thanksMessageTemplateId = '0'
        state.draftRequest.thanksSubject = 'thanksSubject'
        state.draftRequest.thanksText = 'thanksText'
      }
      let studentReferenceId = state.screenDisplayItems.submissionRequestInput.studentReferenceId
      state.draftRequest.studentReferenceId = studentReferenceId.toString()
      state.draftRequest.contactName = state.screenDisplayItems.submissionRequestInput.contactName
      state.draftRequest.companyName = state.screenDisplayItems.submissionRequestInput.companyName
      state.draftRequest.departmentNameAndManagerName = state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName
      state.draftRequest.telephoneNumber = state.screenDisplayItems.submissionRequestInput.telephoneNumber
      state.draftRequest.mailAddress = state.screenDisplayItems.submissionRequestInput.mailAddress
      let personalDataHandlingId = state.screenDisplayItems.personalDataInput.personalDataHandlingId
      // MCBリプレース #8606 START
      if(personalDataHandlingId === 0){
        state.draftRequest.personalDataHandlingId = null
      }else{
        state.draftRequest.personalDataHandlingId = personalDataHandlingId.toString()
      }
      // MCBリプレース #8606 START
      state.draftRequest.personalDataHandling = state.screenDisplayItems.personalDataInput.personalDataHandling
      state.draftRequest.sendTimerFlag = Number(state.screenDisplayItems.sendTimeInput.sendTimeFlag)
      state.draftRequest.sendPlanTime = dateTimeToString(state.screenDisplayItems.sendTimeInput.sendPlanTime)
      state.draftRequest.searchCriteriaName = state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName
      state.draftRequest.searchCriteria = state.screenDisplayItems.sendTargetConfirmation.searchCriteria
      state.draftRequest.searchCriteriaJson = state.initResult.searchCriteriaJson
    },

    saveDraft(
      state,
      action: PayloadAction<{
        formData: MCBHS010RequestDraftRequest
        files: Array<any | null>
        attachmentFileRegistrationMax: number
      }>
    ) {
      return state
    },

    changeDeadlineAfterDay(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay = value
      return state
    },

    changeDeadlineAfterDayTime(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime = value
      return state
    },

    changeDeadlineTime(state, action: PayloadAction<string>) {
      state.screenDisplayItems.submissionRequestInput.determineDateTime = action.payload
      return state
    },

    changeAnyTime(state, action: PayloadAction<string>) {
      state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime = action.payload
      return state
    },

    setCloseWindow(state, action: PayloadAction<boolean>) {
      state.hasCloseWindowByDraft = action.payload
      return state
    },

    setConfirmRequest(state) {
      const confirmRequest: MCBHS010RequestConfirmRequest = {
        requestTemplateSettingId: '',
        submissionRequestId:'',
        mcbSubmissionRequestId:'',
        destinationId: [],
        searchCriteriaName: '',
        searchCriteria: '',
        // 9174 start
        searchCriteriaJson: '',
        // 9174 end
        submissionsSelectId: 0,
        submissionsNameForCompany: '',
        submissionsNameForStudent: '',
        submissionsDetails: '',
        submissionEntrySheetFlag: 0,
        submissionResumeFlag: 0,
        submissionResearchSummaryFlag: 0,
        submissionOriginalQuestionFlag: 0,
        submissionFileSubmissionFlag: 0,
        limitDateDetermineFlag: 0,
        determineDateTime: '',
        requestDayTimeLaterDay: '',
        requestDayTimeToTime: '',
        anytimeReceptionEndDateTime: '',
        reRequestReceptFlag: 0,
        senderCompanyName: '',
        senderMailAddress: '',
        requestSubject: '',
        requestText: '',
        requestAttachment: [], 
        requestAttachmentId: [],
        requestAttachmentName: [],
        requestTemplateAttachmentId: [],
        requestTemplateAttachmentName: [],
        homepageTitle1: '',
        homepageTitle2: '',
        homepageTitle3: '',
        homepageUrl1: '',
        homepageUrl2: '',
        homepageUrl3: '',
        thanksMessageSetFlag: 0,
        thanksMessageTemplateId: 0,
        thanksMessageTemplateName: '',
        thanksSubject: '',
        thanksText: '',
        studentReferenceId: 0,
        contactName: '',
        companyName: '',
        departmentNameAndManagerName: '',
        telephoneNumber: '',
        mailAddress: '',
        personalDataHandlingId: 0,
        personalDataHandling: '',
        sendTimerFlag: 0,
        sendPlanTime: '',
        entryIdList: [],
        selectionManagementIdList: [],
        jobSeekerByCompanyIdList: [],
        sendTarget:[],
        status: '',
        sendReplyImpossibleTimeList: state.initResult.sendReplyImpossibleTime,
        submissionsContentModified: '',
        contactModified: '',
        // #MCB1.5次開発 #8744 #8600 START
        jobSeekerIdList: [],
        // #MCB1.5次開発 #8744 #8600 END
        myCareerBoxPrivacyPolicyModified: ''
      }

      const registerValue: viewConfig.RegisterValue = {
        requestTemplateSettingId: '',
        submissionRequestId: '',
        mcbSubmissionRequestId:'',
        destinationId: [],
        searchCriteriaName: '',
        searchCriteria: '',
        // 9174 start
        searchCriteriaJson: '',
        // 9174 end
        submissionsSelectId: 0,
        submissionsNameForCompany: '',
        submissionsNameForStudent: '',
        submissionsDetails: '',
        submissionEntrySheetFlag: 0,
        submissionResumeFlag: 0,
        submissionResearchSummaryFlag: 0,
        submissionOriginalQuestionFlag: 0,
        submissionFileSubmissionFlag: 0,
        limitDateDetermineFlag: 0,
        determineDateTime: '',
        requestDayTimeLaterDay: 0,
        requestDayTimeToTime: 0,
        anytimeReceptionEndDateTime: '',
        reRequestReceptFlag: 0,
        senderCompanyName: '',
        senderMailAddress: '',
        requestSubject: '',
        requestText: '',
        requestAttachment: [],
        requestAttachmentId: [],
        requestAttachmentName: [],
        requestTemplateAttachmentId: [],
        requestTemplateAttachmentName: [],
        homepageTitle1: '',
        homepageTitle2: '',
        homepageTitle3: '',
        homepageUrl1: '',
        homepageUrl2: '',
        homepageUrl3: '',
        thanksMessageSetFlag: 0,
        thanksMessageTemplateId: 0,
        thanksMessageTemplateName: '',
        thanksSubject: '',
        thanksText: '',
        studentReferenceId: 0,
        contactName: '',
        companyName: '',
        departmentNameAndManagerName: '',
        telephoneNumber: '',
        mailAddress: '',
        personalDataHandlingId: 0,
        personalDataHandling: '',
        sendTimerFlag: 0,
        sendPlanTime: '',
        entryIdList: [],
        selectionManagementIdList: [],
        jobSeekerByCompanyIdList: [],
        companyContractId:0,
        sendTarget: [],
        status: '',
        coordinationFormerJobSeekerIdentificationKeyList:[],
        jobSeekerIdList:[],
        submissionsContentModified: '',
        contactModified: '',
        myCareerBoxPrivacyPolicyModified: '',
        mailDestinationAddressList: []
      }

      confirmRequest.requestTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId
      registerValue.requestTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId
      const sendTarget =
      state.screenDisplayItems.sendTargetConfirmation.sendTarget
      let entryIdList: string[] = []
      let jobSeekerByCompanyIdList: string[] = []
      let sendTargetJobSeekerIdList: string[] = []
      let coordinationFormerJobSeekerIdentificationKeyList: string[] = []
      let mailDestinationAddressList: string[] = []
      let selectionManagementIdList:string[] = []
      if (sendTarget) {
        sendTarget.map(item => {
          entryIdList = [...entryIdList, item.entryId]
          jobSeekerByCompanyIdList = [...jobSeekerByCompanyIdList,item.jobSeekerByCompanyId]
          sendTargetJobSeekerIdList = [...sendTargetJobSeekerIdList,item.sendTargetJobSeekerId]
          coordinationFormerJobSeekerIdentificationKeyList = [...coordinationFormerJobSeekerIdentificationKeyList,item.coFormerJobSeekerId]
          mailDestinationAddressList = [...mailDestinationAddressList,item.mailAddress]
          selectionManagementIdList = [...selectionManagementIdList,item.selectionManagementId]
        })
      }
      confirmRequest.destinationId = entryIdList
      registerValue.destinationId = entryIdList

      confirmRequest.jobSeekerByCompanyIdList = jobSeekerByCompanyIdList
      registerValue.jobSeekerByCompanyIdList = jobSeekerByCompanyIdList
      // #MCB1.5次開発 #8744 #8600 START
      confirmRequest.jobSeekerIdList = sendTargetJobSeekerIdList
      // #MCB1.5次開発 #8744 #8600 END
      registerValue.coordinationFormerJobSeekerIdentificationKeyList = coordinationFormerJobSeekerIdentificationKeyList
      registerValue.mailDestinationAddressList = mailDestinationAddressList

      confirmRequest.senderCompanyName = state.screenDisplayItems.submissionRequestInput.senderCompanyName
      registerValue.senderCompanyName = state.screenDisplayItems.submissionRequestInput.senderCompanyName
      
      confirmRequest.senderMailAddress = state.screenDisplayItems.submissionRequestInput.senderMailAddress
      registerValue.senderMailAddress = state.screenDisplayItems.submissionRequestInput.senderMailAddress
      
      confirmRequest.submissionsSelectId = state.screenDisplayItems.submissionRequestInput.submissionsSelectId
      registerValue.submissionsSelectId = state.screenDisplayItems.submissionRequestInput.submissionsSelectId

      confirmRequest.submissionRequestId = state.confirmRequest.submissionRequestId
      registerValue.submissionRequestId = state.confirmRequest.submissionRequestId

      confirmRequest.mcbSubmissionRequestId = state.confirmRequest.mcbSubmissionRequestId
      registerValue.mcbSubmissionRequestId = state.confirmRequest.mcbSubmissionRequestId
      
      confirmRequest.submissionsNameForCompany = state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany
      registerValue.submissionsNameForCompany = state.screenDisplayItems.submissionRequestInput.submissionsNameForCompany
      
      confirmRequest.submissionsNameForStudent = state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent
      registerValue.submissionsNameForStudent = state.screenDisplayItems.submissionRequestInput.submissionsNameForStudent
      
      confirmRequest.submissionEntrySheetFlag = state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag
      registerValue.submissionEntrySheetFlag = state.screenDisplayItems.submissionRequestInput.submissionEntrySheetFlag

      confirmRequest.submissionResumeFlag = state.screenDisplayItems.submissionRequestInput.submissionResumeFlag
      registerValue.submissionResumeFlag = state.screenDisplayItems.submissionRequestInput.submissionResumeFlag

      confirmRequest.submissionResearchSummaryFlag = state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag
      registerValue.submissionResearchSummaryFlag = state.screenDisplayItems.submissionRequestInput.submissionResearchSummaryFlag

      confirmRequest.submissionOriginalQuestionFlag = state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag
      registerValue.submissionOriginalQuestionFlag = state.screenDisplayItems.submissionRequestInput.submissionOriginalQuestionFlag

      confirmRequest.submissionFileSubmissionFlag = state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag
      registerValue.submissionFileSubmissionFlag = state.screenDisplayItems.submissionRequestInput.submissionFileSubmissionFlag

      
      confirmRequest.limitDateDetermineFlag = Number(state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag)
      registerValue.limitDateDetermineFlag = Number(state.screenDisplayItems.submissionRequestInput.limitDateDetermineFlag)
      
      confirmRequest.determineDateTime = state.screenDisplayItems.submissionRequestInput.determineDateTime
      registerValue.determineDateTime = state.screenDisplayItems.submissionRequestInput.determineDateTime
      
      confirmRequest.requestDayTimeLaterDay = state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay
      registerValue.requestDayTimeLaterDay = Number(state.screenDisplayItems.submissionRequestInput.requestDayTimeLaterDay)
      
      confirmRequest.requestDayTimeToTime = state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime
      registerValue.requestDayTimeToTime = Number(state.screenDisplayItems.submissionRequestInput.requestDayTimeToTime)
      
      confirmRequest.anytimeReceptionEndDateTime = state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime
      registerValue.anytimeReceptionEndDateTime = state.screenDisplayItems.submissionRequestInput.anytimeReceptionEndDateTime
      
      confirmRequest.reRequestReceptFlag = Number(state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag)
      registerValue.reRequestReceptFlag = Number(state.screenDisplayItems.submissionRequestInput.reRequestReceptFlag)
      
      confirmRequest.requestSubject = state.screenDisplayItems.submissionRequestInput.requestSubject
      registerValue.requestSubject = state.screenDisplayItems.submissionRequestInput.requestSubject
      
      confirmRequest.requestText = state.screenDisplayItems.submissionRequestInput.requestText
      registerValue.requestText = state.screenDisplayItems.submissionRequestInput.requestText

      const attachmentList = state.screenDisplayItems.submissionRequestInput.attachment
      if (attachmentList) {
        let attachmentIdList: Array<string | null> = []
        let attachmentNameList: string[] = []
        let fileNameList: string[] = []
        attachmentList.map(attachment => {
          if (attachment.attachmentId && attachment.attachmentName) {
            attachmentIdList = [...attachmentIdList, attachment.attachmentId]
            attachmentNameList = [...attachmentNameList, attachment.attachmentName]
            fileNameList = [...fileNameList, attachment.attachmentName]
          }
        })
        confirmRequest.requestAttachmentId = attachmentIdList
        registerValue.requestAttachmentId = attachmentIdList

        confirmRequest.requestAttachmentName = attachmentNameList
        registerValue.requestAttachmentName = attachmentNameList
      }

      if (registerValue.requestTemplateSettingId && registerValue.requestTemplateSettingId != '') {
        if (attachmentList && attachmentList.length > 0) {
          registerValue.requestTemplateAttachmentId = attachmentList.map(item => item.attachmentId ? item.attachmentId : '')
          registerValue.requestTemplateAttachmentName = attachmentList.map(item => item.attachmentName ? item.attachmentName : '')
        }
      }

      let homePageTitle1: string | null = null
      let homePageTitle2: string | null = null
      let homePageTitle3: string | null = null
      let homePageUrl1: string | null = null
      let homePageUrl2: string | null = null
      let homePageUrl3: string | null = null
      homePageTitle1 =
        state.screenDisplayItems.submissionRequestInput.homePage[0] &&
          state.screenDisplayItems.submissionRequestInput.homePage[0].homePageTitle !== ''
          ? state.screenDisplayItems.submissionRequestInput.homePage[0].homePageTitle
          : null
      homePageTitle2 =
        state.screenDisplayItems.submissionRequestInput.homePage[1] &&
          state.screenDisplayItems.submissionRequestInput.homePage[1].homePageTitle !== ''
          ? state.screenDisplayItems.submissionRequestInput.homePage[1].homePageTitle
          : null
      homePageTitle3 =
        state.screenDisplayItems.submissionRequestInput.homePage[2] &&
          state.screenDisplayItems.submissionRequestInput.homePage[2].homePageTitle !== ''
          ? state.screenDisplayItems.submissionRequestInput.homePage[2].homePageTitle
          : null
      homePageUrl1 =
        state.screenDisplayItems.submissionRequestInput.homePage[0] &&
          state.screenDisplayItems.submissionRequestInput.homePage[0].homePageUrl !== ''
          ? state.screenDisplayItems.submissionRequestInput.homePage[0].homePageUrl
          : null
      homePageUrl2 =
        state.screenDisplayItems.submissionRequestInput.homePage[1] &&
          state.screenDisplayItems.submissionRequestInput.homePage[1].homePageUrl
          ? state.screenDisplayItems.submissionRequestInput.homePage[1].homePageUrl
          : null
      homePageUrl3 =
        state.screenDisplayItems.submissionRequestInput.homePage[2] &&
          state.screenDisplayItems.submissionRequestInput.homePage[2].homePageUrl
          ? state.screenDisplayItems.submissionRequestInput.homePage[2].homePageUrl
          : null

      confirmRequest.homepageTitle1 = homePageTitle1
      registerValue.homepageTitle1 = homePageTitle1

      confirmRequest.homepageTitle2 = homePageTitle2
      registerValue.homepageTitle2 = homePageTitle2

      confirmRequest.homepageTitle3 = homePageTitle3
      registerValue.homepageTitle3 = homePageTitle3

      confirmRequest.homepageUrl1 = homePageUrl1
      registerValue.homepageUrl1 = homePageUrl1

      confirmRequest.homepageUrl2 = homePageUrl2
      registerValue.homepageUrl2 = homePageUrl2

      confirmRequest.homepageUrl3 = homePageUrl3
      registerValue.homepageUrl3 = homePageUrl3

      
      const thanksEmailTemplate = Number(state.screenDisplayItems.thanksMessageInput.thanksMessageSetFlag)
      confirmRequest.thanksMessageSetFlag = thanksEmailTemplate
      registerValue.thanksMessageSetFlag = thanksEmailTemplate
      
      if(thanksEmailTemplate === 1){
        confirmRequest.thanksMessageTemplateId = state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId
        registerValue.thanksMessageTemplateId = state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateId
      
        confirmRequest.thanksMessageTemplateName = state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName
        registerValue.thanksMessageTemplateName = state.screenDisplayItems.thanksMessageInput.thanksMessageTemplateName
        const thanksEmailSubject = state.screenDisplayItems.thanksMessageInput.thanksSubject
        confirmRequest.thanksSubject = thanksEmailSubject
        registerValue.thanksSubject = thanksEmailSubject
      
        const thanksEmailBody = state.screenDisplayItems.thanksMessageInput.thanksText
        confirmRequest.thanksText = thanksEmailBody
        registerValue.thanksText = thanksEmailBody
      }else{
        confirmRequest.thanksSubject = 'thanksSubject'
        confirmRequest.thanksText = 'thanksText'
      }

      confirmRequest.studentReferenceId = state.screenDisplayItems.submissionRequestInput.studentReferenceId
      registerValue.studentReferenceId = state.screenDisplayItems.submissionRequestInput.studentReferenceId
      
      confirmRequest.contactName = state.screenDisplayItems.submissionRequestInput.contactName
      registerValue.contactName = state.screenDisplayItems.submissionRequestInput.contactName
      
      confirmRequest.companyName = state.screenDisplayItems.submissionRequestInput.companyName
      registerValue.companyName = state.screenDisplayItems.submissionRequestInput.companyName
      
      confirmRequest.departmentNameAndManagerName = state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName
      registerValue.departmentNameAndManagerName = state.screenDisplayItems.submissionRequestInput.departmentNameAndManagerName
      
      confirmRequest.telephoneNumber = state.screenDisplayItems.submissionRequestInput.telephoneNumber
      registerValue.telephoneNumber = state.screenDisplayItems.submissionRequestInput.telephoneNumber
      
      confirmRequest.mailAddress = state.screenDisplayItems.submissionRequestInput.mailAddress
      registerValue.mailAddress = state.screenDisplayItems.submissionRequestInput.mailAddress
      
      confirmRequest.personalDataHandlingId = state.screenDisplayItems.personalDataInput.personalDataHandlingId
      registerValue.personalDataHandlingId = state.screenDisplayItems.personalDataInput.personalDataHandlingId
      
      confirmRequest.personalDataHandling = state.screenDisplayItems.personalDataInput.personalDataHandling
      registerValue.personalDataHandling = state.screenDisplayItems.personalDataInput.personalDataHandling
      
      const sendTimer = Number(state.screenDisplayItems.sendTimeInput.sendTimeFlag)
      confirmRequest.sendTimerFlag = sendTimer
      registerValue.sendTimerFlag = sendTimer
      
      const sendPlanTime = dateTimeToString(state.screenDisplayItems.sendTimeInput.sendPlanTime)
      confirmRequest.sendPlanTime = sendPlanTime ? sendPlanTime : ''
      registerValue.sendPlanTime = sendPlanTime
      
      confirmRequest.searchCriteriaName = state.initResult.searchCriteriaName
      registerValue.searchCriteriaName = state.initResult.searchCriteriaName
      
      confirmRequest.searchCriteria = state.initResult.searchCriteria
      registerValue.searchCriteria = state.initResult.searchCriteria

      // 9174 start
      confirmRequest.searchCriteriaJson = state.initResult.searchCriteriaJson
      registerValue.searchCriteriaJson = state.initResult.searchCriteriaJson
      // 9174 end
      
      confirmRequest.sendTarget = sendTarget
      registerValue.sendTarget = sendTarget

      confirmRequest.submissionsContentModified = state.notDisplayItem.submissionsContentModified
      confirmRequest.contactModified = state.notDisplayItem.contactModified
      confirmRequest.myCareerBoxPrivacyPolicyModified = state.screenDisplayItems.personalDataInput.personalDataHandlingId === 0 ? "" : state.notDisplayItem.myCareerBoxPrivacyPolicyModified
      confirmRequest.status =  state.confirmRequest.status
      registerValue.submissionsContentModified = state.notDisplayItem.submissionsContentModified
      registerValue.contactModified = state.notDisplayItem.contactModified
      registerValue.myCareerBoxPrivacyPolicyModified = state.screenDisplayItems.personalDataInput.personalDataHandlingId === 0 ? "" : state.notDisplayItem.myCareerBoxPrivacyPolicyModified
      registerValue.selectionManagementIdList = selectionManagementIdList
      registerValue.companyContractId = state.initResult.companyContractIdList[0]
      registerValue.jobSeekerIdList = sendTargetJobSeekerIdList
      state.confirmRequest = confirmRequest
      state.registerValue = registerValue

      state.runConfirm = true
      return state
    },

    confirm(
      state,
      action: PayloadAction<{
        formData: MCBHS010RequestConfirmRequest
        files: Array<any | null>
        viewEndTimeList: Date[]
        forbiddenWords: string[]
        attachmentFileRegistrationMax: number
        setOpenSameContect: (value: boolean) => void
        setMCBHS020Open: (value: boolean) => void
      }>
    ) {
      return state
    },

    csvDownload(state, action: PayloadAction<MCBHS010RequestCsvDownloadRequest>) {
      return state
    },

    // 維持保守 #9496 start
    setConfirmData(state,action: PayloadAction<MCBHS010ConfirmOutDto>){
    // 維持保守 #9496 end
      // #MCB1.5次開発 #9126 START
      // 維持保守 #9496 start
      let sameSubmissionList = action.payload.sameContentCheckOutList
      // 維持保守 #9496 end
      state.sameSubmissionList =  sameSubmissionList.filter(i => i.mycareercd === null)
      state.sameMycareerCDList = sameSubmissionList.filter(i => i.mycareercd != null)
      // 維持保守 #9496 start
      state.blackListJobSeekerList = action.payload.blackListJobSeekerList
      // 維持保守 #9496 end
      // #MCB1.5次開発 #9126 END
      // if(state.sameSubmissionList.length>0){
      //   state.openSameContect = 1
      // }else{
      //   state.openSameContect = 2
      // }
      return state
    },

    changeConfirmState(state, action: PayloadAction<boolean>) {
      state.runConfirm = action.payload
      return state
    },
  
    openConfirmModal(state) {
      state.openConfirm = true
      return state
    },
    closeConfirmModal(state) {
      state.openConfirm = false
      return state
    },
    setRegisterValue(state, action: PayloadAction<viewConfig.RegisterValue>){
      state.registerValue = action.payload
      return state
    },
    // MCBリプレース MCBHS010 END

    setSubjectText(state, action: PayloadAction<string>) {
      state.submissionReqSubjectText = action.payload
      return state
    },
    openDetailDialog(state, action: PayloadAction<viewConfig.SearchCriteriaInfo>) {
      return state
    },
    // setFormInput(state, action: PayloadAction<formConfig.InputValues>) {
    //   let value = action.payload
    //   state.screenDisplayItems.messageInput.submissionContent = value.submissionContent
    //   state.screenDisplayItems.messageInput.submissionContentView = value.submissionContentView
    //   state.screenDisplayItems.messageInput.submissionNames = value.submissionNames
    //   state.screenDisplayItems.messageInput.deadlineDate = value.deadlineDate
    //   state.screenDisplayItems.messageInput.deadlineAfterDay = value.deadlineAfterDay
    //   state.screenDisplayItems.messageInput.deadlineAfterDayTime = value.deadlineAfterDayTime
    //   state.screenDisplayItems.messageInput.deadlineAnyTime = value.deadlineAnyTime
    //   // state.screenDisplayItems.messageInput.senderCompanyName = value.senderCompanyName
    //   // state.screenDisplayItems.messageInput.senderMailAddress = value.senderMailAddress
    //   // state.screenDisplayItems.messageInput.submissionReqSubject = value.submissionReqSubject
    //   // state.screenDisplayItems.messageInput.submissionReqBody = value.submissionReqBody
    //   state.screenDisplayItems.messageInput.thanksEmailTemplate = value.thanksEmailTemplate
    //   // state.screenDisplayItems.messageInput.thanksEmailSubject = value.thanksEmailSubject
    //   // state.screenDisplayItems.messageInput.thanksEmailBody = value.thanksEmailBody
    //   state.screenDisplayItems.messageInput.contactInformation = value.contactInformation
    //   state.screenDisplayItems.messageInput.contactInformationCompany = value.contactInformationCompany
    //   state.screenDisplayItems.messageInput.contactInformationTel = value.contactInformationTel
    //   state.screenDisplayItems.messageInput.handlingPersonalInformation = value.handlingPersonalInformation
    //   return state
    // },
  },
})

export const {
  getInit,
  setInit,
  setCode,
  setRecruitmentManagementDivision,
  setScreenDisplayItems,
  getPreview,
  setPreview,
  getReloadSubmissions,
  setReloadSubmissions,
  getReloadThanksTemplate,
  setReloadThanksTemplate,
  getReloadReference,
  setReloadReference,
  getReloadPersonal,
  setReloadPersonal,
  getTemplateAttachment,
  setTemplateAttachment,
  inputSenderCompanyName,
  inputSenderMailAddress,
  inputSubmissionReqSubject,
  inputSubmissionReqBody,
  inputThanksEmailSubject,
  inputThanksEmailBody,
  changeTargetInput,
  inputAttachment,
  deleteAttachment,
  addAttachment,
  inputHomePageTitle,
  inputHomePageUrl,
  deleteHomePage,
  addHomePage,
  changeResubmission,
  changethanksMailSettingFlag,
  // changeSettingThanksEmail,
  changeSendTimeFlag,
  clearSendTime,
  inputSendTimer,
  setEmptyTemplateSettingId,
  selectTemplate,
  deleteSendTarget,
  setDraftReqeust,
  saveDraft,
  setConfirmRequest,
  confirm,
  changeConfirmState,
  openConfirmModal,
  closeConfirmModal,
  setCloseWindow,
  setIsDraft,
  setSubjectText,
  openDetailDialog,
  changeDeadlineType,
  changeDeadlineAfterDay,
  changeDeadlineAfterDayTime,
  // setFormInput,
  changeSubmissionData,
  clearThanks,
  clearPersonalInfo,
  changeContactData,
  changeThanksData,
  changePersonalData,
  changeAnyTime,
  changeDeadlineTime,
  csvDownload,
  setConfirmData,
  inputDestinationSelection,
  setRegisterValue,
} = submissionRequestSendSlice.actions
export default submissionRequestSendSlice.reducer
