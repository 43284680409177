import request from 'utils/request'
import { MCAZS130InitDisplayRequest } from 'types/MCAZS130/MCAZS130InitDisplayRequest'

export const MCAZS130getDataApi = (apiData: MCAZS130InitDisplayRequest) =>
  request({
    url: `/MCAZS130/init`,
    method: 'post',
    data:apiData
  })

  export const checkInterviewLocationApi = (recruitmentManagementDivision:string) =>
  request({
    url: `/MCAZS130/register/${recruitmentManagementDivision}`,
    method: 'post'
  })

