import React, { useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import ReactSelect from 'react-select'
import customSelectOptions from 'utils/customSelectOptions'
import { magiContants } from 'utils/contants'
import { openModal } from 'reducers/messageReducer'
import { getMessage } from 'common/messageUtil'
import { useDispatch } from 'react-redux'


const customStyles = {
  input: () => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
  container: (provided: any) => ({ ...provided, maxWidth: '50vw' }),
  multiValue: (provided: any) => ({ ...provided, maxWidth: '34vw' }),
  multiValueLabel: (provided: any) => ({ ...provided, maxWidth: '100%' }),
  option: (provided: any) => ({ ...provided, whiteSpace: 'nowrap' }),
}

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  noOptionsMessage: string
  defaultValue?: any
  placeholder?: string
  className?: string
  isMulti?: boolean
}

export const Select = ({
  isMulti,
  className,
  optionList,
  noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE,
  defaultValue,
  placeholder,
  field,
  form,
}: Props) => {
  const { name, value } = field
  const dispatch = useDispatch()
  const handleChange = (option: any) => {
    if (option && option.length >= 6) {
      dispatch(openModal(getMessage("MCBHS151-003")))
    } else {
      if (isMulti) {
        form.setFieldValue(
          name,
          option ? (option as Option[]).map(i => i) : []
        )
      } else {
        form.setFieldValue(name, option as Option)
      }
    }
  }
  const [formValue, setDefaultValue] = useState(defaultValue)
  useEffect(() => {
    if (defaultValue != null) {
      setDefaultValue(defaultValue)
    }
  }, [defaultValue])

  const optionLabelList = optionList.map(i => i.label)

  return (
    <ReactSelect
      isMulti={isMulti}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={formValue !== undefined ? defaultValue : null}
      noOptionsMessage={({ inputValue }) =>
        optionLabelList.includes(inputValue)
          ? `${inputValue}${magiContants.SUGGEST_ALREADY_INPUT}`
          : noOptionsMessage
      }
      placeholder={placeholder}
      onChange={handleChange}
      filterOption={customSelectOptions.filterOption}
    />
  )
}

export const SelectOptions = ({
  isMulti,
  className,
  optionList,
  noOptionsMessage,
  defaultValue,
  placeholder,
  field,
  form,
}: Props) => {
  const { name, value } = field
  const handleChange = (option: any) => {
    if (isMulti) {
      form.setFieldValue(name, option ? (option as Option[]).map(i => i) : [])
    } else {
      form.setFieldValue(name, option as Option)
    }
  }

  const [formValue, setDefaultValue] = useState(defaultValue)
  useEffect(() => {
    if (defaultValue != null) {
      setDefaultValue(defaultValue)
    }
  }, [defaultValue])

  const optionLabelList = optionList.map(i => i.label)

  return (
    <ReactSelect
      isMulti={isMulti}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={formValue !== undefined ? defaultValue : null}
      noOptionsMessage={({ inputValue }) =>
        optionLabelList.includes(inputValue)
          ? `${inputValue}${magiContants.SUGGEST_ALREADY_INPUT}`
          : noOptionsMessage
      }
      placeholder={placeholder}
      onChange={handleChange}
      filterOption={customSelectOptions.filterOption}
    />
  )
}


