/* eslint-disable @typescript-eslint/camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { McbSendTarget } from 'pages/MCAZS220/viewConig'
import * as viewConfig from 'pages/MCAZS230/viewConig'
import { MCAZS230InitRequest } from 'types/MCAZS230/MCAZS230InitRequest'
import { MCAZS230InitResult } from 'types/MCAZS230/MCAZS230InitResult'
import { MCAZS230RegisterRequest } from 'types/MCAZS230/MCAZS230RegisterRequest'
import { MCAZS230RegisterResult } from 'types/MCAZS230/MCAZS230RegisterResult'

const messageRegisterRequest: MCAZS230RegisterRequest = {
  messageTemplateSettingId: '',
  destinationId: [''],
  searchCriteriaName: '',
  searchCriteria: '',
  destination: 1,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  text: '',
  attachment: [],
  attachmentId: [''],
  attachmentName: [''],
  templateAttachmentId: [''],
  templateAttachmentName: [''],
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  sendTimer: 0,
  sendPlanTime: '',
  messageSendId: '',
  mcbMessageDestinationResultDto: [],
  searchCriteriaJson: ''
}

const initRequest: MCAZS230InitRequest = {
  replyFromMessageSendId: null,
  messageTemplateSettingId: '',
  messageType: [],
  searchCriteriaName: '',
  searchCriteria: '',
  destination: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  text: '',
  attachment: [],
  attachmentId: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  sendTimer: 0,
  sendPlanTime: '',
  mcbMessageDestinationResultDto: [],
}

const initResult: MCAZS230InitResult = {
  adjustmentInterviewScheduleAdjustmentId: [],
  alertMessageIdList: [],
  noValueOnlySendList: [],
  noValueBulkSendList: [],
  // #MCB1.5次開発 #72395 START
  senderMailAddressFix: "",
  // #MCB1.5次開発 #72395 END
}

const registerResult: MCAZS230RegisterResult = {
  fileManagementIds: [],
  attachmentNames: [],
  addFailureCount: 0,
  saveFlag: '',
}

const initialState: {
  messageComfInitRequest: MCAZS230InitRequest
  messageComInitResult: MCAZS230InitResult
  messageComfRegisterResult: MCAZS230RegisterResult
  draftRequest: MCAZS230RegisterRequest
  businessCheckResult: boolean
  alertMessageIdList: string[]
  subject: string
  body: string
  successfulCountMessage: number
  successfulCountEmail: number
  failedCount: number
  mcbSendTarget: McbSendTarget[]
  isSending: boolean
} = {
  messageComfInitRequest: initRequest,
  messageComInitResult: initResult,
  messageComfRegisterResult: registerResult,
  draftRequest: messageRegisterRequest,
  businessCheckResult: false,
  alertMessageIdList: [],
  subject: '',
  body: '',
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  mcbSendTarget: [],
  isSending: false,
}

const mcbMessageSendConfirmSlice = createSlice({
  name: 'mcbMessageSendConfirm',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<{ initVal: MCAZS230InitRequest }>) {
      return state
    },
    setInit(state, action: PayloadAction<MCAZS230InitResult>) {
      state.messageComInitResult = action.payload
      return state
    },
    setSubject(state, action: PayloadAction<string>) {
      state.subject = action.payload
      return state
    },
    setBody(state, action: PayloadAction<string>) {
      state.body = action.payload
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = false
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    setCount(
      state,
      action: PayloadAction<{
        successfulCountMessage: number
        successfulCountEmail: number
        failedCount: number
      }>
    ) {
      state.successfulCountMessage = action.payload.successfulCountMessage
      state.successfulCountEmail = action.payload.successfulCountEmail
      state.failedCount = action.payload.failedCount
      return state
    },
    setMcbSendTarget(state, action: PayloadAction<McbSendTarget[]>) {
      state.mcbSendTarget = action.payload
      return state
    },
    redistMessage(
      state,
      action: PayloadAction<{
        formData: MCAZS230RegisterRequest
        files: Array<any | null>
        forbiddenWords: string[]
        attachmentFileRegistrationMax: number
        sendReplyImpossibleTime: viewConfig.sendReplyImpossibleTime[]
      }>
    ) {
      return state
    },
    setBusinessCheckResult(state, action: PayloadAction<boolean>) {
      state.businessCheckResult = action.payload
      return state
    },
    setRegisterResult(state, action: PayloadAction<MCAZS230RegisterResult>) {
      state.messageComfRegisterResult = action.payload
      return state
    },
    setIsSending(state, action: PayloadAction<boolean>) {
      state.isSending = action.payload
      return state
    },
  },
})

export const {
  getInit,
  setInit,
  setSubject,
  setBody,
  setCode,
  setCount,
  setMcbSendTarget,
  setBusinessCheckResult,
  redistMessage,
  setRegisterResult,
  setIsSending,
} = mcbMessageSendConfirmSlice.actions
export default mcbMessageSendConfirmSlice.reducer
