import { all, call, put, takeEvery, takeLeading ,select } from 'redux-saga/effects'
import {
  searchRequest,
  pushNewApplicantCountRequest,
  pushUnreadResumeCountRequest,
  pushUnreadInfoCountRequest,
  pushInterviewFixedWaitingCountRequest,
  pushInterviewSchedulePresentedCountRequest,
  pushInterviewPlannerListRequest,
  pushInterviewScheduleRequest,
  pushYieldCountRequest,
  pushSeminarReservedCountRequest,
  pushSeminarCanceledCountRequest,
  pushEventParticipantRequest,
  pushNewsTitleRequest,
  searchNewsInfoRequest,
  searchContractMediaRequest,
  searchBriefingSessionInfoRequest,
  searchYieldInfoRequest,
  searchInterviewPlannerInfoRequest,
  searchUnreadCountRequest,
  searchInterviewWebInfoRequest, // WEB面接対応
  pushDisplayYearYieldTitleRequest,
  // MCB ロット３　start
  submissionRequestInfor,
  // MCB ロット３　start
  // #58811 2022/05/24 start
  searchWeekProgressInfoRequest,
  pushApplicantCountRequest,
  pushApplicantionCountRequest,
  // #58811 2022/05/24 end
  // 次期12月対応 #72261 start
  pushSeminarAfterScheduleHiddenCheckBoxRequest,
  initEventAfterScheduleRequest,
  pushPassYieldCountRequest,
  // 次期12月対応 #72261 end
  initSubmissionOpenCloseRequest,
  pushSubmissionOpenCloseRequest,
  pushSubmissionCountRequest,
  pushTotalSubmissionCountRequest,
  pushAllSubmissionCountRequest
} from 'apis/MCAVS010Api'
import {
  searchRecruitmentStatusReportList,
  setRecruitmentStatusReport,
  pushNewApplicantCount,
  pushUnreadResumeCount,
  pushUnreadInfoCount,
  pushInterviewFixedWaitingCount,
  pushInterviewSchedulePresentedCount,
  pushInterviewPlannerList,
  pushInterviewSchedule,
  pushYieldCount,
  pushSeminarReservedCount,
  pushSeminarCanceledCount,
  pushEventParticipant,
  news,
  setNewsInfoResults,
  getNewsInfoinit,
  globalMenuNews,
  setMessageFlagResult,
  searchInterviewWebInfoResult, // WEB面接対応
  setInterviewWebInfoResult, // WEB面接対応
  getDisplayYearYieldTitleInfo,
  setDisplayYearYieldTitleInfo,
  setopenLoadingFlag,
  pushYieldCountOfYearChange,
  // MCB ロット３　start
  pushsubmissionRequestCount,
  pushSubmitted,
  pushUnsubmitted,
  // MCB ロット３　end
  // #58811 2022/05/24 start
  pushApplicantCount,
  pushApplicantionCount,
  // #58811 2022/05/24 end
  // 次期12月対応 #72261 start
  initSeminarAfterScheduleHiddenCheckBox,
  pushSeminarAfterScheduleHiddenCheckBox,
  setAfterScheduleSeminarFlag,
  // 次期12月対応 #72261 end
  pushPassYieldCount,
  initSubmissionOpenClose,
  setSubmissionOpenCloseFlag,
  pushSubmissionOpenClose,
  pushSubmissionCount,
  pushTotalSubmissionCount,
  pushAllSubmissionCount
} from 'reducers/recruitmentStatusReportReducer'
import { magiContants } from 'utils/contants'
import { openModal } from 'reducers/messageReducer'
import { routeList } from 'routes/routes'
import history from 'utils/history'
import { getRecruitmentManagementDivision, getGlobalMenuData } from 'selectors/authSelectors'
import { searchRequestNews } from 'apis/MCAVS020Api'
import { receiveSubmitValue } from 'pages/MCAZS010/formConfig'
import { initScreenTransitionInput as MCAXS010initScreenTransitionInput} from 'pages/MCAXS010/formConfig'
import { initScreenTransitionInput as MCAXS020initScreenTransitionInput} from 'pages/MCAXS020/formConfig'
import { AlertListData, setInitAreaContactPass } from 'reducers/progressStagentAlertReducer'
import { initAreaContactPassRequest } from 'apis/MCAWS010Api'
import { scheduledInitValues } from 'pages/MCBAS020/formConfig'
import { ApplicantsInfo } from 'pages/MCAXS010/formConfig'
import { dateToString } from 'common/generalUtil'
import { setTargetYear, setTargetYearForDisplay, yearChange,initNewsCount } from 'reducers/globalMenuReducer'
import { yearChangeRequest } from 'apis/MCAAS010Api'
import { loginSuccess } from 'reducers/companyLoginReducer'
import { MCAVS020newsgetOutDto } from 'pages/MCAVS020/formConfig'
import { getSelectionFlowStepPassFailObj } from 'pages/MCAVS010/formConfig'
import { ApplicationRouteObj, SelectionFlowObj } from 'pages/MCAXS010/searchConditionConfig'

function* searchSaga(
  action: ReturnType<typeof searchRecruitmentStatusReportList>
) {
  try {
      yield put(setMessageFlagResult(-1))
    // 採用状況レポート初期表示
    const recruitmentManagement = {
      recruitmentManagementDivision:
        action.payload.recruitmentManagementDivision,
      lastLogoutTime: action.payload.lastLogoutTime,
      preLoginTime: action.payload.preLoginTime,
      lastLoginTime: action.payload.lastLoginTime,
    }
    const apiList = [
      // 件数情報
      // お問い合わせ
      call(searchRequest, recruitmentManagement),
      // 件数情報
      call(searchUnreadCountRequest, recruitmentManagement),
      // 歩留まり情報
      call(searchYieldInfoRequest, recruitmentManagement),
      // お知らせの情報
      call(searchNewsInfoRequest, recruitmentManagement),
    ]
    const apiAreaList = [
      // アラートエリア
      call(initAreaContactPassRequest, {
        recruitmentManagementDivision:
          action.payload.recruitmentManagementDivision,
        lastLogoutTime: action.payload.lastLogoutTime,
        previousLoginTime: action.payload.preLoginTime,
      }),
    ]
    // MCB ロット３　start
    // My CareerBox提出状況エリア
    const myCareerBoxArea = [
      call(submissionRequestInfor,recruitmentManagement),
    ]
    // MCB ロット３　end
    const apiListNew = [
      ...apiList,
      // 説明会・面接情報
      call(searchBriefingSessionInfoRequest, recruitmentManagement),
      // イベントの参加状況情報
      call(searchContractMediaRequest, recruitmentManagement),
      // #58811 2022/05/24 start
      // 今週の推移エリアの情報
      call(searchWeekProgressInfoRequest,recruitmentManagement),
      // #58811 2022/05/24 end
      ...apiAreaList,
      // MCB ロット３　start
      // My CareerBox提出状況エリア
      ...myCareerBoxArea,
      // MCB ロット３　end
    ]
    const apiListJobChange = [
      ...apiList,
      // 面接予定者の情報
      call(searchInterviewPlannerInfoRequest, recruitmentManagement),
      ...apiAreaList,
    ]
    const apiListAll = [
      ...apiList,
      // 面接予定者の情報
      call(searchInterviewPlannerInfoRequest, recruitmentManagement),
      // 説明会・面接情報
      call(searchBriefingSessionInfoRequest, recruitmentManagement),
      // イベントの参加状況情報
      call(searchContractMediaRequest, recruitmentManagement),
      // #58811 2022/05/24 start
      // 今週の推移エリアの情報
      call(searchWeekProgressInfoRequest,recruitmentManagement),
      // #58811 2022/05/24 end
      ...apiAreaList,
      // MCB ロット３　start
      // My CareerBox提出状況エリア
      ...myCareerBoxArea,
      // MCB ロット３　end
    ]
    const data = yield all(
      action.payload.recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
        ? apiListJobChange
        : action.payload.recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
        ? apiListNew
        : apiListAll
    )
    // 件数情報 // お問い合わせ
    const recruitmentStatusReportData = data[0]
    // 件数情報
    const unreadCountData = data[1]
    // 未読数
    recruitmentStatusReportData.countInfoOutDto.unreadCount =
      unreadCountData.countInfoOutDto.unreadCount
    // 履歴書未読数
    recruitmentStatusReportData.countInfoOutDto.unreadResumeCount =
      unreadCountData.countInfoOutDto.unreadResumeCount
    // 日程提示済みの件数
    recruitmentStatusReportData.countInfoOutDto.interviewSchedulePresentedCount =
      unreadCountData.countInfoOutDto.interviewSchedulePresentedCount
    // 年度対応 start
    recruitmentStatusReportData.countInfoOutDto.exclamationMark2VisibleFlag = 
      unreadCountData.countInfoOutDto.exclamationMark2VisibleFlag
    // 年度対応 end
    // 歩留まり情報
    const yieldInfoOutDto = data[2]
    // お知らせの情報
    const newsInfoOutDto = data[3]
    // MCB ロット３　start
    let mcbRequestInfoOutDto = []
    // MCB ロット３　start
    // 面接予定者の情報
    let interviewPlannerInfoOutDto = []
    // 説明会・面接情報
    let briefingSessionInfoOutDto = []
    // イベントの参加状況情報
    let contractMediaOutDto = []
    // 今週の推移エリアの情報
    let weekProgressInfoOutDto = []
    // アラートエリア
    let dataArea: AlertListData
    if (
      action.payload.recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
    ) {
      // 面接予定者の情報
      // #58811 2022/05/24 start
      interviewPlannerInfoOutDto = data[4]
      // アラートエリア
      dataArea = data[5]
      // #58811 2022/05/24 end
    } else if (
      action.payload.recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
    ) {
      // 説明会・面接情報
      // #58811 2022/05/24 start
      briefingSessionInfoOutDto = data[4]
      // イベントの参加状況情報
      contractMediaOutDto = data[5]
      // 今週の推移エリアの情報
      weekProgressInfoOutDto = data[6]
      // #58811 2022/05/24 end
      // アラートエリア
      dataArea = data[7]
      // MCB ロット３　start
      // My CareerBox提出状況エリア
      mcbRequestInfoOutDto = data[8]
      // MCB ロット３　end
    } else {
      // 面接予定者の情報
      interviewPlannerInfoOutDto = data[4]
      // 説明会・面接情報
      briefingSessionInfoOutDto = data[5]
      // イベントの参加状況情報
      contractMediaOutDto = data[6]
      // 今週の推移エリアの情報
      weekProgressInfoOutDto = data[7]
      // アラートエリア
      dataArea = data[8]
      // My CareerBox提出状況エリア
      mcbRequestInfoOutDto = data[9]
      // MCB ロット３　end
    }
    // 採用状況レポート初期表示
    if (data.length === 0) {
      yield put(setMessageFlagResult(0))
      yield put(openModal(magiContants.MESSAGECODE_RESULT_NULL))
    } else {
      // 検索結果
      yield put(
        setRecruitmentStatusReport({
          ...recruitmentStatusReportData,
          interviewPlannerInfoOutDto,
          yieldInfoOutDto,
          briefingSessionInfoOutDto,
          contractMediaOutDto,
          newsInfoOutDto,
	        // MCB ロット３　start
          mcbRequestInfoOutDto,
          // MCB ロット３　start
          // #58811 2022/05/24 start
          weekProgressInfoOutDto,
          // #58811 2022/05/24 end
        })
      )
    }
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [新規応募者（応募者単位）の件数]リンクをクリックする
function* pushNewApplicantCountSaga(action: ReturnType<typeof pushNewApplicantCount>) {
  try {
    const data = yield call(pushNewApplicantCountRequest, action.payload)
    if (data === 'Successed') {
      //「応募者一覧（応募者単位）」に遷移する
      const path = {
        pathname: routeList.applicantList,
        state: {
          request: {
            ...MCAXS010initScreenTransitionInput,
            screenId: action.payload.screenId,
            searchType: action.payload.searchType,
            specificSelectionManagementIdList: action.payload.selectionManagementIdList,
            specificCriteriaName: action.payload.specificCriteriaName,
            anchorLinkId: action.payload.anchorLinkId,
          }
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [履歴書未読の件数]リンクをクリックする
function* pushUnreadResumeCountSaga(action: ReturnType<typeof pushUnreadResumeCount>) {
  try {
    const data = yield call(pushUnreadResumeCountRequest, action.payload)
    if (data === 'Successed') {
      //[応募一覧]画面に遷移する。
      const path = {
        pathname: routeList.entryList,
        state: {
          request: {
            ...MCAXS020initScreenTransitionInput,
            screenId: action.payload.screenId,
            searchType: action.payload.searchType,
          }
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [未読の件数]リンクをクリックする
function* pushUnreadInfoCountSaga(action: ReturnType<typeof pushUnreadInfoCount>) {
  try {
    const data = yield call(pushUnreadInfoCountRequest, action.payload)
    if (data === 'Successed') {
      //[メッセージ管理（受信トレイ）]画面に遷移する。
      const path = {
        pathname: routeList.messageInBox,
        state: {
          request: {
            ...receiveSubmitValue,
            unreadCheck: action.payload.unreadCheck,
            removeFunctionId: magiContants.SCREEN_ID_MCAVS010,
          }
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [確定待ちの件数]リンクをクリックする
function* pushInterviewFixedWaitingCountSaga(action: ReturnType<typeof pushInterviewFixedWaitingCount>) {
  try {
    const data = yield call(pushInterviewFixedWaitingCountRequest, action.payload)
    if (data === 'Successed') {
      //[面接調整一覧]画面に遷移する。
      const path = {
        pathname: routeList.scheduledList,
        state: {
          request: {
            ...scheduledInitValues,
            isFixedWaiting: action.payload.isFixedWaiting,
            interviewDateFrom: dateToString(new Date()),
          },
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [提示済みの件数]リンクをクリックする
function* pushInterviewSchedulePresentedCountSaga(action: ReturnType<typeof pushInterviewSchedulePresentedCount>) {
  try {
    const data = yield call(pushInterviewSchedulePresentedCountRequest, action.payload)
    if (data === 'Successed') {
      //[面接調整一覧]画面に遷移する。
      const path = {
        pathname: routeList.scheduledList,
        state: {
          request: {
            ...scheduledInitValues,
            isPresented: action.payload.isPresented,
            interviewDateFrom: dateToString(new Date()),
          },
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [面接予定者一覧へ]リンクをクリックする
function* pushInterviewPlannerListSaga(action: ReturnType<typeof pushInterviewPlannerList>) {
  try {
    const data = yield call(pushInterviewPlannerListRequest, action.payload)
    if (data === 'Successed') {
      //[面接調整一覧]画面に遷移する。
      const path = {
        pathname: routeList.scheduledList,
        state: {
          request: {
            ...scheduledInitValues,
            isFixed: action.payload.isFixed,
            interviewDateFrom: action.payload.interviewDateFrom
          },
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 各[面接日程]をクリックする
function* pushInterviewScheduleSaga(action: ReturnType<typeof pushInterviewSchedule>) {
  try {
    const data = yield call(pushInterviewScheduleRequest, action.payload)
    // 採用管理区分を取得する
    const recruitmentManagementDivision = yield select(getRecruitmentManagementDivision)
    if (data === 'Successed') {
      //新卒採用、[応募者詳細（就職）]画面に遷移する。
      if (recruitmentManagementDivision === '1') {
        // 遷移先パラメータ設定
        const applicantsInfoArray: ApplicantsInfo[] = []
        applicantsInfoArray.push({
          entryId: action.payload.entryId,
          jobSeekerId: action.payload.jobSeekerId,
          selectionManagementId: action.payload.selectionManagementId,
        })
        const detailRequest = {
          state: {
            applicantsListInfo: applicantsInfoArray,
          },
        }
        localStorage.setItem('detailRequest', JSON.stringify(detailRequest))
        history.push(routeList.applicantDetailForEmplyment)
      }
      //中途採用、[応募詳細（転職）]画面に遷移する。
      if (recruitmentManagementDivision === '2') {
        // 遷移先パラメータ設定
        const applicantsInfoArray: ApplicantsInfo[] = []
        applicantsInfoArray.push({
          entryId: action.payload.entryId,
          jobSeekerId: action.payload.jobSeekerId,
          selectionManagementId: action.payload.selectionManagementId,
        })
        const detailRequest = {
          state: {
            applicantsListInfo: applicantsInfoArray,
          },
        }
        localStorage.setItem('detailRequest', JSON.stringify(detailRequest))
        history.push(routeList.entryDetail)
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 各[歩留まりの件数]リンクをクリックする
function* pushYieldCountSaga(action: ReturnType<typeof pushYieldCount>) {
  try {
    const data = yield call(pushYieldCountRequest, action.payload)
    // 採用管理区分を取得する
    const recruitmentManagementDivision = yield select(getRecruitmentManagementDivision)

      //新卒採用、[応募者一覧（応募者単位）]画面に遷移する。
      if (recruitmentManagementDivision === '1') {
        const path = {
          pathname: routeList.applicantList,
          state: {
            request: {
              ...MCAXS010initScreenTransitionInput,
              screenId:action.payload.screenId,
              searchType: action.payload.searchType,
              specificSelectionManagementIdList: data,
              specificCriteriaName: action.payload.specificCriteriaName,                                    
            }
          },
        }
        history.push(path)
      }
      //中途採用、[応募一覧]画面に遷移する。
      if (recruitmentManagementDivision === '2') {
        const path = {
          pathname: routeList.entryList,
          state: {
            request: {
              ...MCAXS020initScreenTransitionInput,
              screenId:action.payload.screenId,
              searchType: action.payload.searchType,
              specificSelectionManagementIdList: data,
              specificCriteriaName: action.payload.specificCriteriaName,
            }
          },
        }
        history.push(path)
      }
    
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [予約の件数]リンクをクリックする
function* pushSeminarReservedCountSaga(action: ReturnType<typeof pushSeminarReservedCount>) {
  try {
    const data = yield call(pushSeminarReservedCountRequest, action.payload)
    if (data === 'Successed') {
      //「応募者一覧（応募者単位）」に遷移する
      const path = {
        pathname: routeList.applicantList,
        state: {
          request: {
            ...MCAXS010initScreenTransitionInput,
            screenId: action.payload.screenId,
            searchType: action.payload.searchType,
            seminarId: action.payload.seminarId,
            seminarScheduleId: action.payload.seminarScheduleId,
            seminarIdList: action.payload.seminarIdList, // 選択したセミナーのIDとセミナー日程のIDを設定する
            anchorLinkId: action.payload.anchorLinkId,
          }
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [キャンセルの件数]リンクをクリックする
function* pushSeminarCanceledCountSaga(action: ReturnType<typeof pushSeminarCanceledCount>) {
  try {
    const data = yield call(pushSeminarCanceledCountRequest, action.payload)
    //「応募者一覧（応募者単位）」に遷移する
    if (data === 'Successed') {
      const path = {
        pathname: routeList.applicantList,
        state: {
          request: {
            ...MCAXS010initScreenTransitionInput,
            screenId: action.payload.screenId,
            searchType: action.payload.searchType,
            seminarId: action.payload.seminarId,
            seminarScheduleId: action.payload.seminarScheduleId,
            anchorLinkId: action.payload.anchorLinkId,
          }
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [参加人数]リンクをクリックする
function* pushEventParticipantSaga(action: ReturnType<typeof pushEventParticipant>) {
  try {
    const data = yield call(pushEventParticipantRequest, action.payload)
    //「応募者一覧（応募者単位）」に遷移する
    if (data === 'Successed') {
      const path = {
        pathname: routeList.applicantList,
        state: {
          request: {
            ...MCAXS010initScreenTransitionInput,
            screenId: action.payload.screenId,
            searchType: action.payload.searchType,
            eventId: action.payload.eventId,
            anchorLinkId: action.payload.anchorLinkId,
          }
        },
      }
      history.push(path)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 各[お知らせタイトル]リンクをクリックする
function* pushNewsTitleSaga(action: ReturnType<typeof news>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
  try {
    const data = yield call(pushNewsTitleRequest, action.payload.param1)
    if (data === 'Successed') {
      //お知らせ詳細画面を別ウィンドウで表示する。
      //次期9月対応 #62669 start
      if (action.payload.param1.newsId !== null) {
        window.localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
        const newWindow = window.open('','','scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '',)

        if (newWindow) {
          newWindow.location.href = window.location.href.replace('recruitmentStatusReportTab/MCAVS010','MCAVS020/').concat(action.payload.param1.newsId).concat(action.payload.param2)
        }
        const readNews : MCAVS020newsgetOutDto = yield call(searchRequestNews, action.payload.param1)
        const globalMenuInfoOld: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
        yield put(initNewsCount(globalMenuInfoOld.newsCount - readNews.count))
        const globalMenuInfoNew = {
          ...globalMenuInfoOld,
          newsCount: globalMenuInfoOld.newsCount - readNews.count
        }
        localStorage.setItem('globalMenuInfoCheck',JSON.stringify(globalMenuInfoNew))
        localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfoNew))
      }
      //次期9月対応 #62669 end
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// globalMenu[お知らせタイトル]リンクをクリックする
function* globalMenuNewsSaga(action: ReturnType<typeof globalMenuNews>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
  try {
    const value = yield call(pushNewsTitleRequest, action.payload.param1)
    if(value === 'Successed'){
      //お知らせ詳細画面を別ウィンドウで表示する。
      //次期9月対応 #62669 start
      if (action.payload.param1.newsId !== null) {
          window.localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
          const newW = window.open('','','scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width='
          + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')

        if (newW) {
          newW.location.href = routeList.home + 'MCAVS020/' + action.payload.param1.newsId + action.payload.param2
        }
        const readNews : MCAVS020newsgetOutDto = yield call(searchRequestNews, action.payload.param1)
        const globalMenuInfoOld: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
        yield put(initNewsCount(globalMenuInfoOld.newsCount - readNews.count))
        const globalMenuInfoNew = {
          ...globalMenuInfoOld,
          newsCount: globalMenuInfoOld.newsCount - readNews.count
        }
        localStorage.setItem('globalMenuInfoCheck',JSON.stringify(globalMenuInfoNew))
        localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfoNew))
      }
      //次期9月対応 #62669 end    
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// WEB面接対応 start
// WEB面接枠数情報
function* searchInterviewWebInfoSaga(
  action: ReturnType<typeof searchInterviewWebInfoResult>
) {
  try {
      // 採用状況レポート初期表示
      const recruitmentManagement = {
      recruitmentManagementDivision:
        action.payload.recruitmentManagementDivision,
      lastLogoutTime: action.payload.lastLogoutTime,
      preLoginTime: action.payload.preLoginTime,
      lastLoginTime: action.payload.lastLoginTime,
      }
      const data = yield call(searchInterviewWebInfoRequest, recruitmentManagement)
      yield put(setInterviewWebInfoResult(data))
  } catch (error) {
      yield put(openModal(error.message))
  }
}
// WEB面接対応 end

// 年度対応 start
// 年度歩留まり表示タイトルボタン押下
function* getDisplayYearYieldTitleInfoSaga(action: ReturnType<typeof getDisplayYearYieldTitleInfo>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
  try {
    yield put(setopenLoadingFlag(false))
    const data = yield call(pushDisplayYearYieldTitleRequest, {recruitmentManagementDivision: globalMenuInfo.recruitmentManagementDivision, targetYear:  action.payload.targetYear})
    yield put(setDisplayYearYieldTitleInfo({targetyearTitleInfo: data, targetYear: action.payload.targetYear}))
    yield put(setopenLoadingFlag(true))
    action.payload.openLoding(false)
  } catch (error) {
    yield put(setopenLoadingFlag(true))
    action.payload.openLoding(false)
    yield put(openModal(error.message))
  }
}

function* pushYieldCountOfYearChangeSaga(action: ReturnType<typeof pushYieldCountOfYearChange>) {
  try {
    const data = yield call(yearChangeRequest, {
      targetYear: action.payload.targetYear,
      oldTargetYear: undefined,
      specailCaseType: undefined,
      url: undefined,
    })
    const token = data.tokenHead + ' ' + data.token
    yield put(loginSuccess({token: token}))
    yield put(setTargetYear(action.payload.targetYear))
    yield put(setTargetYearForDisplay(action.payload.targetYear))
    // 25KH #75311 start
    yield put(pushPassYieldCount(action.payload))
    // 25KH #75311 end
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 年度対応 end

//初期情報を取得する
export function* getNewsListSaga(action: ReturnType<typeof getNewsInfoinit>) {
  try {
    const data = yield call(searchRequestNews, action.payload)
    yield put(setNewsInfoResults(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// #58811 2022/05/24 start
function* pushApplicantCountSaga(action: ReturnType<typeof pushApplicantCount>) {
  try {
    const data = yield call(pushApplicantCountRequest,action.payload)
    const path = {
      pathname: routeList.applicantList,
      state: {
        request: {
          screenId:action.payload.screenId,
          searchType:action.payload.searchType,
          entryDateFrom:data.entryDateFrom,
          entryDateTo:data.entryDateTo,
          contractMediaId:action.payload.contractMediaId,
          contractMediaName:action.payload.contractMediaName,
          anchorLinkId: action.payload.anchorLinkId,
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* pushApplicantionCountSaga(action: ReturnType<typeof pushApplicantionCount>) {
  try {
    const data = yield call(pushApplicantionCountRequest,action.payload)
    const path = {
      pathname: routeList.applicantList,
      state: {
        request: {
          screenId:action.payload.screenId,
          searchType:action.payload.searchType,
          entryDateFrom:data.entryDateFrom,
          entryDateTo:data.entryDateTo,
          anchorLinkId: action.payload.anchorLinkId,
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #58811 2022/05/24 end

// MCB ロット３　start
// 提出リクエスト人数リンク
function* pushsubmissionRequestCountSaga(action: ReturnType<typeof pushsubmissionRequestCount>) {
  try {
    const path = {
      pathname: routeList.submissionRequestManagement,
      state: {
        request: {
          targetYear:action.payload.targetYear,
          submissionStatus:action.payload.submissionStatus,
          submissionContentId:action.payload.submission_content_id,
          submissionContentName:action.payload.submission_content_name,
          screenId:action.payload.sourceFunctionId,
          anchorLinkId: action.payload.anchorLinkId,
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 提出人数リンク
function* pushSubmittedSaga(action: ReturnType<typeof pushsubmissionRequestCount>) {
  try {
    const path = {
      pathname: routeList.submissionRequestManagement,
      state: {
        request: {
          targetYear:action.payload.targetYear,
          submissionStatus:action.payload.submissionStatus,
          submissionContentId:action.payload.submission_content_id,
          submissionContentName:action.payload.submission_content_name,
          screenId:action.payload.sourceFunctionId,
          anchorLinkId: action.payload.anchorLinkId,
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 未提出人数
function* pushUnsubmittedSaga(action: ReturnType<typeof pushsubmissionRequestCount>) {
  try {
    const path = {
      pathname: routeList.submissionRequestManagement,
      state: {
        request: {
          targetYear:action.payload.targetYear,
          submissionStatus:action.payload.submissionStatus,
          submissionContentId:action.payload.submission_content_id,
          submissionContentName:action.payload.submission_content_name,
          screenId:action.payload.sourceFunctionId,
          anchorLinkId: action.payload.anchorLinkId,
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCB ロット３　end
// 次期12月対応 #72261 start
// 説明会・面接情報開催後日程チェックボックス
function* pushEventAfterScheduleCheckBoxSaga(action: ReturnType<typeof initSeminarAfterScheduleHiddenCheckBox>) {
  try {
    const data = yield call(initEventAfterScheduleRequest, action.payload)
    yield put(setAfterScheduleSeminarFlag(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// "開催後の日程を非表示にする"チェックボックス押下時
function* pushSeminarAfterScheduleHiddenCheckBoxSaga(action: ReturnType<typeof pushSeminarAfterScheduleHiddenCheckBox>) {
  try {
    yield call(pushSeminarAfterScheduleHiddenCheckBoxRequest, action.payload)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 次期12月対応 #72261 end
// 25KH #75311 start
// 就職、歩留まりの累計、参加/合格/承諾件数リンク押下
function* pushPassYieldCountSaga(action: ReturnType<typeof pushPassYieldCount>) {
  try {
    let afterInfixList: any[] = []
    if (magiContants.PROGRESS_TYPE_INFIX === action.payload.progressType) {
      // 内定のリンク押下場合、バックエンドから「内定承諾」「入社」の進捗ステータス情報を取得する
      afterInfixList = yield call(pushPassYieldCountRequest)
    }
    // [選考フロー×選考ステップ×合否]のObjectを作成
    const selectionFlowStepPassFailObj = getSelectionFlowStepPassFailObj(
      action.payload.selectionInformation,
      action.payload.yieldMediaName,
      action.payload.progressOutInfo,
      action.payload.linkFlag, afterInfixList)
    // [応募経路]のObjectを作成
    const applicationRouteObj: ApplicationRouteObj[] = []
	// 26KH #89119 START
    if (action.payload.yieldMediaName.mediaName !== '合計') {
    if (action.payload.mediaSettingId !== '') {
      applicationRouteObj.push({
        applicationRouteFlag: 1,
        applicationRouteId: action.payload.mediaSettingId,
        applicationRouteName: action.payload.yieldMediaName.mediaName,
      })
    } else {
      applicationRouteObj.push({
        applicationRouteFlag: 0,
        applicationRouteId: action.payload.contractMediaId,
        applicationRouteName: action.payload.yieldMediaName.mediaName,
      })
    }
  }
	// 26KH #89119 END
    // [選考フロー]のObjectを作成
    const selectionFlowObj: SelectionFlowObj[] = [
      {
        selectionFlowId: action.payload.selectionFlowSettingId,
        selectionFlowName: selectionFlowStepPassFailObj.selectionFlowObj[0].selectionFlowName,
      },
    ]
    // 応募者一覧画面へ遷移する、パラメータはstate.requestで設定する
    const path = {
      pathname: routeList.applicantList,
      state: {
        request: {
          ...MCAXS010initScreenTransitionInput,
          screenId: action.payload.screenId,
          searchType: action.payload.searchType,
          anchorLinkId: action.payload.anchorLinkId,
          searchCondition: {
            ...MCAXS010initScreenTransitionInput.searchCondition,
            applicationRouteObj: applicationRouteObj,
            selectionFlowObj: selectionFlowObj,
            selectionFlowStepPassFailObj: selectionFlowStepPassFailObj,
          }
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 25KH #75311 end
// 26KH #89294 START
function* initSubmissionOpenCloseSaga(action: ReturnType<typeof initSubmissionOpenClose>) {
  try {
    const data = yield call(initSubmissionOpenCloseRequest, action.payload)
    yield put(setSubmissionOpenCloseFlag(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* pushSubmissionOpenCloseSaga(action: ReturnType<typeof pushSubmissionOpenClose>) {
  try {
    yield call(pushSubmissionOpenCloseRequest, action.payload)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* pushSubmissionCountSaga(action: ReturnType<typeof pushSubmissionCount>) {
  try {
    const data = yield call(pushSubmissionCountRequest,action.payload)
    const path = {
      pathname: routeList.submissionRequestManagement,
      state: {
        request: {
          screenId:action.payload.sourceFunctionId,
          targetYear:action.payload.targetYear,
          submissionDateFrom:data.submissionDateFrom,
          submissionDateTo:data.submissionDateTo,
          // 26KH #89118 START
          anchorLinkId:action.payload.anchorLinkId
          // 26KH #89118 END
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* pushTotalSubmissionCountSaga(action: ReturnType<typeof pushTotalSubmissionCount>) {
  try {
    const data = yield call(pushTotalSubmissionCountRequest,action.payload)
    const path = {
      pathname: routeList.submissionRequestManagement,
      state: {
        request: {
          screenId:action.payload.sourceFunctionId,
          targetYear:action.payload.targetYear,
          submissionDateFrom:data.submissionDateFrom,
          submissionDateTo:data.submissionDateTo,
          // 26KH #89118 START
          anchorLinkId:action.payload.anchorLinkId
          // 26KH #89118 END
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* pushAllSubmissionCountSaga(action: ReturnType<typeof pushAllSubmissionCount>) {
  try {
    const data = yield call(pushAllSubmissionCountRequest,action.payload)
    const path = {
      pathname: routeList.submissionRequestManagement,
      state: {
        request: {
          screenId:action.payload.sourceFunctionId,
          targetYear:action.payload.targetYear,
          submissionDateFrom:data.submissionDateFrom,
          submissionDateTo:data.submissionDateTo,
          // 26KH #89118 START
          anchorLinkId:action.payload.anchorLinkId
          // 26KH #89118 END
        }
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 26KH #89294 END

export default function* recruitmentStatusReportSaga() {
  yield all([
    takeLeading(searchRecruitmentStatusReportList, searchSaga),
    takeLeading(searchInterviewWebInfoResult, searchInterviewWebInfoSaga), // WEB面接対応
    takeEvery(pushNewApplicantCount, pushNewApplicantCountSaga),
    takeEvery(pushUnreadResumeCount, pushUnreadResumeCountSaga),
    takeEvery(pushUnreadInfoCount, pushUnreadInfoCountSaga),
    takeEvery(pushInterviewFixedWaitingCount, pushInterviewFixedWaitingCountSaga),
    takeEvery(pushInterviewSchedulePresentedCount, pushInterviewSchedulePresentedCountSaga),
    takeEvery(pushInterviewPlannerList, pushInterviewPlannerListSaga),
    takeEvery(pushInterviewSchedule, pushInterviewScheduleSaga),
    takeEvery(pushYieldCount, pushYieldCountSaga),
    takeEvery(pushSeminarReservedCount, pushSeminarReservedCountSaga),
    takeEvery(pushSeminarCanceledCount, pushSeminarCanceledCountSaga),
    takeEvery(pushEventParticipant, pushEventParticipantSaga),
    takeEvery(news, pushNewsTitleSaga),
    takeEvery(globalMenuNews, globalMenuNewsSaga),
    takeEvery(getNewsInfoinit, getNewsListSaga),
    // 年度対応 start
    takeEvery(getDisplayYearYieldTitleInfo, getDisplayYearYieldTitleInfoSaga),
    takeEvery(pushYieldCountOfYearChange, pushYieldCountOfYearChangeSaga),
    // 年度対応 end
    // MCB ロット３　start
    takeEvery(pushsubmissionRequestCount,pushsubmissionRequestCountSaga),
    takeEvery(pushSubmitted,pushSubmittedSaga),
    takeEvery(pushUnsubmitted,pushUnsubmittedSaga),
    // MCB ロット３　end
    // #58811 2022/05/24 start
    takeEvery(pushApplicantCount,pushApplicantCountSaga),
    takeEvery(pushApplicantionCount,pushApplicantionCountSaga),
    // #58811 2022/05/24 end
    // 次期12月対応 #72261 start
    takeEvery(initSeminarAfterScheduleHiddenCheckBox,pushEventAfterScheduleCheckBoxSaga),
    takeEvery(pushSeminarAfterScheduleHiddenCheckBox,pushSeminarAfterScheduleHiddenCheckBoxSaga),
    // 次期12月対応 #72261 end
    // 25KH #75311 start
    takeEvery(pushPassYieldCount, pushPassYieldCountSaga),
    // 25KH #75311 end
    // 26KH #89294 START
    takeEvery(initSubmissionOpenClose, initSubmissionOpenCloseSaga),
    takeEvery(pushSubmissionOpenClose, pushSubmissionOpenCloseSaga),
    takeEvery(pushSubmissionCount, pushSubmissionCountSaga),
    takeEvery(pushTotalSubmissionCount,pushTotalSubmissionCountSaga),
    takeEvery(pushAllSubmissionCount,pushAllSubmissionCountSaga)
    // 26KH #89294 END
  ])
}
