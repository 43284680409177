import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Message {
  media_flag: string
  coordination_company_key: string
  coordination_supported_message_key: string
}
const messageInitialState = {
  media_flag: '',
  coordination_company_key: '',
  coordination_supported_message_key: '',
}

const initialState: {
  message: Message
} = {
  message: messageInitialState,
}

const redirectReducer = createSlice({
  name: 'redirectGroup',
  initialState,
  reducers: {
    getMessage(state, action: PayloadAction<Message>) {
      return state
    },
  },
})

export const {
  getMessage,
} = redirectReducer.actions
export default redirectReducer.reducer