import request from 'utils/request'
import { MCAXS240EntryInitRequest } from 'types/MCAXS240/MCAXS240EntryInitRequest'
import { MCAXS240AddressRequest } from 'types/MCAXS240/MCAXS240AddressRequest'
import { MCAXS240EntryInfoRequest } from 'types/MCAXS240/MCAXS240EntryInfoRequest'
export const initRequest = (entry: MCAXS240EntryInitRequest) =>
  request({
    url: '/MCAXS240/init',
    method: 'post',
    data: entry,
  })
export const autoInputRequest = (entry: MCAXS240AddressRequest) =>
  request({
    url: '/MCAXS240/autoInput',
    method: 'post',
    data: entry,
  })
export const checkRequest = (entry: MCAXS240EntryInfoRequest) =>
  request({
    url: '/MCAXS240/check',
    method: 'post',
    data: entry,
  })
