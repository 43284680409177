import React from 'react'
import { Tooltip, IconButton, ClickAwayListener } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '14px',
    border: '1px solid #dadde9',
    lineHeight: '28px',
  },
}))(Tooltip)

const useStyles = makeStyles({
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

interface Props {
  open: boolean
  onClose: () => void
  title: string
  to: string
}

const ClickTooltip: React.FC<Props> = ({
  open,
  onClose,
  title,
  to,
  children,
}) => {
  const classes = useStyles()

  return (
    <HtmlTooltip
      interactive
      arrow
      placement='top'
      PopperProps={{
        disablePortal: true,
      }}
      open={open}
      onClose={onClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        <div>
          <div className={classes.group}>
            <span>{title}へ更新しました。</span>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <a href={to}>メッセージ送信やその他の処理を続けて行う</a>
        </div>
      }>
      {children as any}
    </HtmlTooltip>
  )
}

export default ClickTooltip
