import { MCAXS481SeminarAfterScheduleHiddenCheckBoxRequest } from 'types/MCAXS481/MCAXS481SeminarAfterScheduleHiddenCheckBoxRequest'
import request from 'utils/request'

export const getMCAXS481InitRequest = () =>
  request({
    url: '/MCAXS481/init',
    method: 'post',
  })

// 26P #80786 start
// 「ok」ボタン押下、"開催後の日程を非表示にする"チェックボックスの状態保存
export const pushSeminarAfterScheduleHiddenCheckBoxRequest = (params: MCAXS481SeminarAfterScheduleHiddenCheckBoxRequest) =>
  request({
    url: '/MCAXS481/pushSeminarAfterScheduleHiddenCheckBox',
    method: 'post',
    data: params,
  })
// 26P #80786 end
