import request from 'utils/request'
import { MCAXS220EntryInitRequest } from 'types/MCAXS220/MCAXS220EntryInitRequest'
import { MCAXS220AddressRequest } from 'types/MCAXS220/MCAXS220AddressRequest'
import { MCAXS220EntryInfoRequest } from 'types/MCAXS220/MCAXS220EntryInfoRequest'

export const initMCAXS220Request = (apiData: MCAXS220EntryInitRequest) =>
  request({
    url: `/MCAXS220/init`,
    method: 'post',
    data: apiData,
  })

export const AddressSelectRequest = (apiData: MCAXS220AddressRequest) =>
  request({
    url: '/MCAXS220/autoInput',
    method: 'post',
    data: apiData,
  })

export const insRequest = (apiData: MCAXS220EntryInfoRequest) =>
  request({
    url: '/MCAXS220/check',
    method: 'post',
    data: apiData,
  })
