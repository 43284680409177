import request from 'utils/request'
import { MCANS030UpdateRequest } from 'types/MCANS030/MCANS030UpdateRequest'
import { MCANS030DeleteRequest } from 'types/MCANS030/MCANS030DeleteRequest'

export const getSignByIdApi = (signSettingId: string) =>
  request({
    url: `MCANS030/init/${signSettingId}`,
    method: 'post',
  })

export const updateSignApi = (signUpdate: MCANS030UpdateRequest) =>
  request({
    url: '/MCANS030/update',
    method: 'post',
    data: signUpdate,
  })

export const deleteSignApi = (signDelete: MCANS030DeleteRequest) =>
  request({
    url: '/MCANS030/delete',
    method: 'post',
    data: signDelete,
  })