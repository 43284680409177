import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const headCells = [
  { label: '北海道・東北', id: 'hokkaidotohoku' },
  { label: '関東', id: 'kanto' },
  { label: '甲信越・北陸', id: 'kousinnetuhokuriku' },
  { label: '東海・中部', id: 'tokaicyubu' },
  { label: '近畿', id: 'kinnki' },
  { label: '中国・四国', id: 'cyugokusikoku' },
  { label: '九州・沖縄', id: 'kyusyuokinawa' },
  { label: '海外', id: 'kaigai' },
]

const useStyles = makeStyles({
  root: {
    backgroundColor: 'e6f1ff',
  },
  head: {
    backgroundColor: '#e6f1ff',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '6px',
    '& input:checked + mark': {
      backgroundColor: '#3f71bd',
      backgroundPosition: 'center center',
      borderColor: '#3f71bd'
    }
  },
  mark: {
    height: '16px !important',
    width: '16px !important',
    marginRight: '8px !important'
  }
})

interface EnhancedTableProps {
  onSelectAllClick: any
  areaKye: string
  selected: boolean
}

function EnhancedTableHead({
  onSelectAllClick,
  areaKye,
  selected,
}: EnhancedTableProps) {
  const classes = useStyles()
  const area = headCells.filter(headCell => headCell.id === areaKye)

  return (
    <div className={classes.root}>
      {area.map(ar => (
        <ListItem
          button
          key={ar.id}
          className={classes.head}
          disableRipple
          onClick={onSelectAllClick(selected)}>
          <ListItemIcon>
            <label className={classes.label}>
              <input
                type='checkbox'
                checked={selected}
                onChange={onSelectAllClick(!selected)}
              />
              <mark className={classes.mark}></mark>
            </label>
          </ListItemIcon>
          <ListItemText primary={ar.label} />
        </ListItem>
      ))}
    </div>
  )
}

export default EnhancedTableHead
