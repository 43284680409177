import request from 'utils/request'
import { MCAMS020InitDisplayRequest } from 'types/MCAMS020/MCAMS020InitDisplayRequest'
import { MCAMS020messageTemplateInputRequest } from 'types/MCAMS020/MCAMS020messageTemplateInputRequest'

export const messageTemplateAddRequest = (params : MCAMS020InitDisplayRequest) =>
  request({
    url: `MCAMS020/init`,
    method: 'post',
    data: params 
  })
  
export const messageTemplateConfirmRequest = (requestBody:{registeredFileName:File[],submitParams: MCAMS020messageTemplateInputRequest}) =>{
  const data = new FormData()
  requestBody.registeredFileName.forEach((file) => {
      data.append('files', file);
  });
  data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))
  return request({
    url: '/MCAMS020/confirm',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}
// 年度対応 start
export const messageTemplateTargetYearChange = (params : MCAMS020messageTemplateInputRequest) =>
  request({
    url: `MCAMS020/targetYearChange`,
    method: 'post',
    data: params 
  })
// 年度対応 end
