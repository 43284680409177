import {
    call,
    put,
    all,
    takeEvery,
    takeLeading
  } from 'redux-saga/effects'
  import {
    fetchAttachmentList,
    setAttachmentList,
    deleteAttachment
  } from 'reducers/attachmentListReducer'
  import { fetchAttachment,deleteFile } from '../apis/MCAXS090Api'
  import { openSnackbar, openModal } from 'reducers/messageReducer'
import { magiContants } from 'utils/contants'
  function* fetchSaga(action: ReturnType<typeof fetchAttachmentList>) {
    try {
      console.log("FETCH SAGA")
      const data = yield call(fetchAttachment,action.payload)
      yield put(setAttachmentList(data))
    } catch (error) {
        const [message,downLoadFlag]=error.message.split(",");
        if (message === magiContants.MESSAGECODE_RESULT_NULL) {
        yield put(setAttachmentList({downLoadFlag,fileList:[]}))
      }else{
        yield put(openModal(error.message));
      }
    }
}
  function* deleteSaga(action:ReturnType<typeof deleteAttachment>){
    try {
    const entryId1=action.payload.entryId;
    const data=yield call(deleteFile,action.payload)
    yield put(fetchAttachmentList({entryId:entryId1}))
    yield put(openSnackbar(data))
    }catch(error){
      yield put(openModal(error.message))
    }
  }
  export default function* accountSaga() {
    yield all([
      takeEvery(fetchAttachmentList, fetchSaga),
      takeLeading(deleteAttachment, deleteSaga)
    ])
  }
  
  