import { getInitListRequest } from 'apis/MCAJS010Api'
import {
  createCustomizeProgressRequest,
  createInitSelectionFlow,
  createRequest,
  deleteProgressRequest,
  updateCustomizeProgressRequest,
} from 'apis/MCAJS020Api'
import {
  createCustomizeProgressOnUpdateRequest,
  deleteProgressOnUpdateRequest,
  updateInitSelectionFlow,
  updateRequest,
  finishRequest,
  deleteRequest,
  updateCustomizeProgressOnUpdateRequest,
} from 'apis/MCAJS030Api'
import { getMessage, getDynamicMessage,getMessageEx } from 'common/messageUtil'
import { fetchSelectionFlow, fetchSelectionFlowSuccess, insertSelectionFlow, fetchSelectionFlowChange, fetchSelectionFlowChangeSuccess, changeSelectionFlow } from 'reducers/selectionFlowReducer'
import { initRequest, insertRequest } from 'apis/MCAXS271Api'
import { initChangeRequest, changeRequest } from 'apis/MCAXS280Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  creatCustomizeProgress,
  creatCustomizeProgressForUpdate,
  createSelectionFlow,
  deleteCustomizeProgress,
  deleteCustomizeProgressForUpdate,
  getSelectionFlowCreateInitData,
  getSelectionFlowTableData,
  getSelectionFlowUpdateInitData,
  setOpenSelectionFlowCreate,
  setOpenSelectionFlowUpdate,
  setProgressData,
  setProgressInitForCreateData,
  setSelctionFlowCreateCheckData,
  setSelectionFlowInitData,
  setSelectionFlowTableData,
  setSelectionFlowUpdateInitData,
  setSetSwitchForAddOrChange,
  setSetSwitchForAddOrChangeOnUpdate,
  updateCustomizeProgress,
  updateCustomizeProgressForUpdate,
  updateSelectionFlow,
  finishSelectionFlow,
  setSelctionFlowUpdateCheckDataSnackBar,
  deleteSelectionFlow,
  setSelctionFlowUpdateCheckData,
  setCopyFlag,
  setSelectionManagementId
} from 'reducers/selectionFlowReducer'
import { all, call, put, select, takeLeading, takeLatest } from 'redux-saga/effects'
import {
  getSwitchForProgressAddOrChange,
  getSwitchForProgressAddOrChangeOnUpdate,
} from 'selectors'
import { magiContants } from 'utils/contants'
import { editCheckMessage } from 'pages/MCAJS030/formConfig'
import { getRecruitmentManagementDivision, getGlobalTargetYear } from '../selectors/authSelectors'

// MCAJS010
// 画面を初期表示する。選考を表示する。
function* getSelectionFlowTableDataSaga() {
  const recruitmentManagementDivision: ReturnType<
    typeof getRecruitmentManagementDivision
  > = yield select(getRecruitmentManagementDivision)
  try {
    const data = yield call(getInitListRequest, {
      recruitmentManagementDivision: recruitmentManagementDivision,
    })
    yield put(setSelectionFlowTableData(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCAJS020
// 選考フロー登録 初期データ取得
function* getSelectionFlowCreateInitDataSaga(
  action: ReturnType<typeof getSelectionFlowCreateInitData>
) {
  try {
    const data = yield call(createInitSelectionFlow, action.payload)
    yield put(setSelectionFlowInitData(data))
    yield put(setProgressInitForCreateData(data))
    yield put(setOpenSelectionFlowCreate(true))
    yield put(setCopyFlag(action.payload.transitionSourceInformation === '1' ? true : false))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 選考フロー登録
function* createSelectionFlowSaga(
  action: ReturnType<typeof createSelectionFlow>
) {
  try {
    const createData = yield call(createRequest, action.payload)
    // 選考終了日過去日チェックされた場合
    if (
      createData !== magiContants.SUCCESED &&
      createData.checkResult === '1'
    ) {
      yield put(
        setSelctionFlowCreateCheckData({
          openCheckModal: true,
          modalMessage: getMessage(magiContants.MESSAGECODE_MCAJS020_012),
        })
      )
    } else {
      yield put(setOpenSelectionFlowCreate(false))
      yield put(setSelectionFlowTableData([]))
      const recruitmentManagementDivision: ReturnType<
        typeof getRecruitmentManagementDivision
      > = yield select(getRecruitmentManagementDivision)
      const data = yield call(getInitListRequest, {
        recruitmentManagementDivision: recruitmentManagementDivision,
      })
      yield put(setSelectionFlowTableData(data))
      // 年度対応 start
      const globalTargetYear: ReturnType<
        typeof getGlobalTargetYear
      > = yield select(getGlobalTargetYear)
      if (action.payload.targetYear != globalTargetYear && magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 === (recruitmentManagementDivision)) {
        yield put(openSnackbar(magiContants.MESSAGECODE_MCAJS020_017))
      } else {
        yield put(openSnackbar(magiContants.MESSAGECODE_MCAJS020_011))
      }
      // 年度対応 end
    } 
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAJS030
// 選考フロー編集 初期データ取得
function* getSelectionFlowUpdateInitDataSaga(
  action: ReturnType<typeof getSelectionFlowUpdateInitData>
) {
  try {
    const data = yield call(updateInitSelectionFlow, action.payload)
    yield put(setSelectionFlowUpdateInitData(data))
    yield put(setOpenSelectionFlowUpdate(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 選考フロー編集　更新
function* updateSelectionFlowSaga(
  action: ReturnType<typeof updateSelectionFlow>
) {
  try {
    const updateData = yield call(updateRequest, action.payload)
    // 非表示進捗チェックされた場合
    if (updateData !== magiContants.SUCCESED && updateData.checkErrorMessage) {
      yield put(
        setSelctionFlowUpdateCheckDataSnackBar({
          openCheckModal: true,
          modalMessage: getDynamicMessage({
            messageID: magiContants.MESSAGECODE_MCAJS030_023,
            title: editCheckMessage(updateData.checkErrorMessage),
          }),
        })
      )
    }
    // 選考フロー名称変更チェックされた場合
    else if (
      updateData !== magiContants.SUCCESED &&
      updateData.checkResult === '1'
    ) {
      yield put(
        setSelctionFlowUpdateCheckData({
          openCheckModal: true,
          modalMessage: getMessage(magiContants.MESSAGECODE_MCAJS030_020),
        })
      )
    }
    // 選考終了日過去日チェックされた場合
    else if (
      updateData !== magiContants.SUCCESED &&
      updateData.checkResult === '2'
    ) {
      yield put(
        setSelctionFlowUpdateCheckData({
          openCheckModal: true,
          modalMessage: getMessage(magiContants.MESSAGECODE_MCAJS030_022),
        })
      )
    } else {
      yield put(setOpenSelectionFlowUpdate(false))
      yield put(setSelectionFlowTableData([]))
      const recruitmentManagementDivision: ReturnType<
        typeof getRecruitmentManagementDivision
      > = yield select(getRecruitmentManagementDivision)
      const data = yield call(getInitListRequest, {
        recruitmentManagementDivision: recruitmentManagementDivision,
      })
      yield put(setSelectionFlowTableData(data))
      yield put(openSnackbar(magiContants.MESSAGECODE_MCAJS030_021))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 選考フロー編集　選考を終了する
function* finishSelectionFlowSaga(
  action: ReturnType<typeof finishSelectionFlow>
) {
  try {
    yield call(finishRequest, action.payload)
    yield put(setOpenSelectionFlowUpdate(false))
    yield put(setSelectionFlowTableData([]))
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data = yield call(getInitListRequest, {
      recruitmentManagementDivision: recruitmentManagementDivision,
    })
    yield put(setSelectionFlowTableData(data))
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAJS030_024))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 選考フロー編集　選考を削除する
function* deleteSelectionFlowSaga(
  action: ReturnType<typeof deleteSelectionFlow>
) {
  try {
    yield call(deleteRequest, action.payload)
    yield put(setOpenSelectionFlowUpdate(false))
    yield put(setSelectionFlowTableData([]))
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data = yield call(getInitListRequest, {
      recruitmentManagementDivision: recruitmentManagementDivision,
    })
    yield put(setSelectionFlowTableData(data))
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAJS030_025))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// カスタマイズ進捗
// 選考フロー登録画面で
// カスタマイズ進捗追加
function* creatCustomizeProgressSaga(
  action: ReturnType<typeof creatCustomizeProgress>
) {
  try {
    const data = yield call(createCustomizeProgressRequest, action.payload)
    yield put(setProgressData(data))
    const switchData = yield select(getSwitchForProgressAddOrChange)
    yield put(setSetSwitchForAddOrChange(!switchData))
  } catch (error) {
    // 埋字あるエラーの場合
    if ([magiContants.MESSAGECODE_MCAJS020_016].includes(error.message)) {
      // 26P #82433 start 
      yield put(openModal(getMessage(error.message, [action.payload.customizeProgressName, action.payload.selectionType == 0 ? 'プレサイト期間選考フロー' : action.payload.selectionType == 1 ? '採用選考フロー' : ''])))
      // 26P #82433 end
    } else {
      yield put(openModal(error.message))
    }
  }
}
function* creatCustomizeProgressForUpdateSaga(
  action: ReturnType<typeof creatCustomizeProgressForUpdate>
) {
  try {
    const data = yield call(
      createCustomizeProgressOnUpdateRequest,
      action.payload
    )
    yield put(setProgressData(data))
    const switchData = yield select(getSwitchForProgressAddOrChangeOnUpdate)
    yield put(setSetSwitchForAddOrChangeOnUpdate(!switchData))
  } catch (error) {
    // 埋字あるエラーの場合
    if ([magiContants.MESSAGECODE_MCAJS030_030].includes(error.message)) {
      // 26P #82433 start 
      yield put(openModal(getMessage(error.message, [action.payload.customizeProgressName, action.payload.selectionType == '採用選考フロー' ? 'プレサイト期間選考フロー' : action.payload.selectionType == 'プレサイト期間選考フロー' ? '採用選考フロー' : ''])))
      // 26P #82433 end 
    } else {
      yield put(openModal(error.message))
    }
  }
}
// カスタマイズ進捗更新
function* updateCustomizeProgressSaga(
  action: ReturnType<typeof updateCustomizeProgress>
) {
  try {
    const data = yield call(updateCustomizeProgressRequest, action.payload)
    yield put(setProgressData(data))
    if (data.message) {
      yield put(openModal(data.message))
    }
    const switchData = yield select(getSwitchForProgressAddOrChange)
    yield put(setSetSwitchForAddOrChange(!switchData))
  } catch (error) {
    // 埋字あるエラーの場合
    if ([magiContants.MESSAGECODE_MCAJS020_016].includes(error.message)) {
      // 26P #82433 start 
      yield put(openModal(getMessage(error.message, [action.payload.customizeProgressName, action.payload.selectionType == 0 ? 'プレサイト期間選考フロー' : action.payload.selectionType == 1 ? '採用選考フロー' : ''])))
      // 26P #82433 end 
    } else {
      yield put(openModal(error.message))
    }
  }
}
function* updateCustomizeProgressForUpdateSaga(
  action: ReturnType<typeof updateCustomizeProgressForUpdate>
) {
  try {
    const data = yield call(updateCustomizeProgressOnUpdateRequest, action.payload)
    yield put(setProgressData(data))
    if (data.message) {
      yield put(openModal(data.message))
    }
    const switchData = yield select(getSwitchForProgressAddOrChangeOnUpdate)
    yield put(setSetSwitchForAddOrChangeOnUpdate(!switchData))
  } catch (error) {
    // 埋字あるエラーの場合
    if ([magiContants.MESSAGECODE_MCAJS030_030].includes(error.message)) {
      // 26P #82433 start 
      yield put(openModal(getMessage(error.message, [action.payload.customizeProgressName, action.payload.selectionType == '採用選考フロー' ? 'プレサイト期間選考フロー' : action.payload.selectionType == 'プレサイト期間選考フロー' ? '採用選考フロー' : ''])))
      // 26P #82433 end
    } else {
      yield put(openModal(error.message))
    }
  }
}
// カスタマイズ進捗削除
function* deleteCustomizeProgressSaga(
  action: ReturnType<typeof deleteCustomizeProgress>
) {
  try {
    yield call(deleteProgressRequest, action.payload)
    const switchData = yield select(getSwitchForProgressAddOrChange)
    yield put(setSetSwitchForAddOrChange(!switchData))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* deleteCustomizeProgressForUpdateSaga(
  action: ReturnType<typeof deleteCustomizeProgressForUpdate>
) {
  try {
    yield call(deleteProgressOnUpdateRequest, action.payload)
    const switchData = yield select(getSwitchForProgressAddOrChangeOnUpdate)
    yield put(setSetSwitchForAddOrChangeOnUpdate(!switchData))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* fetchSaga(action: ReturnType<typeof fetchSelectionFlow>) {
    try {
      const data = yield call(initRequest,action.payload)      
      yield put(fetchSelectionFlowSuccess(data))
    } catch (error) {
      yield put(openModal(error.message))
    }
}

function* insertSaga(action: ReturnType<typeof insertSelectionFlow>) {
    try {
        const message = yield call(insertRequest,action.payload.request)
        yield put(openSnackbar(message))
        if(action.payload.onclose !== undefined){
          action.payload.onclose()
        }
    } catch (error) {
      const messageId = error.message.substring(0,12);
      const selectionName = error.message.substring(12);
      yield put(openModal(getMessageEx(messageId,selectionName)))
    }
}

function* fetchChangeSaga(action: ReturnType<typeof fetchSelectionFlowChange>) {
  try {
    const data = yield call(initChangeRequest,action.payload)
    yield put(fetchSelectionFlowChangeSuccess(data))
  } catch (error) {
    if (error.message == magiContants.MESSAGECODE_RESULT_NULL){
      yield put(openSnackbar(error.message))
    } else {
      yield put(openModal(error.message))
    } 
  }
}

function* changeSaga(action: ReturnType<typeof changeSelectionFlow>) {
  try {
      const date = yield call(changeRequest,action.payload.request)
      yield put(setSelectionManagementId(''))
      if(action.payload.request.targetSelectionManagementIdList.length === 1){
        yield put(setSelectionManagementId(date.split(',')[1]))
      }
      yield put(openSnackbar(date.split(',')[0]))
      if(action.payload.onclose !== undefined){
        action.payload.onclose()
      }
  } catch (error) {
    // #6222 start
    if (error.message == magiContants.MESSAGECODE_UPDATE_FAILED) {
      yield put(openModal(error.message))
    } else {
      const messageId = error.message.substring(0,12);
      const selectionName = error.message.substring(12);
      yield put(openModal(getMessageEx(messageId,selectionName)))
    }
    // #6222 end
  }
}
export default function* selectionFlowSaga() {
  yield all([
    // MCAJS010
    takeLatest(getSelectionFlowTableData, getSelectionFlowTableDataSaga),
    // MCAJS020
    takeLatest(
      getSelectionFlowCreateInitData,
      getSelectionFlowCreateInitDataSaga
    ),
    takeLeading(createSelectionFlow, createSelectionFlowSaga),
    // MCAJS030
    takeLatest(
      getSelectionFlowUpdateInitData,
      getSelectionFlowUpdateInitDataSaga
    ),
    takeLeading(updateSelectionFlow, updateSelectionFlowSaga),
    takeLeading(finishSelectionFlow, finishSelectionFlowSaga),
    takeLeading(deleteSelectionFlow, deleteSelectionFlowSaga),
    // カスタマイズ進捗
    takeLeading(creatCustomizeProgress, creatCustomizeProgressSaga),
    takeLeading(
      creatCustomizeProgressForUpdate,
      creatCustomizeProgressForUpdateSaga
    ),
    takeLeading(updateCustomizeProgress, updateCustomizeProgressSaga),
    takeLeading(
      updateCustomizeProgressForUpdate,
      updateCustomizeProgressForUpdateSaga
    ),
    takeLeading(deleteCustomizeProgress, deleteCustomizeProgressSaga),
    takeLeading(
      deleteCustomizeProgressForUpdate,
      deleteCustomizeProgressForUpdateSaga
    ),
        takeLeading(fetchSelectionFlow, fetchSaga),
        takeLeading(insertSelectionFlow, insertSaga),
        takeLeading(fetchSelectionFlowChange, fetchChangeSaga),
        takeLeading(changeSelectionFlow, changeSaga),
  ])
}
