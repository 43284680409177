const templateTypeList = [
  { name: 'searchTemplateTypeGeneral', label: '汎用', id: '1' },
  { name: 'searchTemplateTypeOffer', label: '合格通知', id: '2' },
  { name: 'searchTemplateTypeFailure', label: '不採用通知', id: '3' },
];

const initialFilterValues = {
  searchTemplateName:'',
  templateTypes: {
    searchTemplateTypeGeneral: '0',
    searchTemplateTypeOffer: '0',
    searchTemplateTypeFailure: '0',
  },
  searchTemplateWord:'',
  searchTemplateHidden: '0',
};

const submitValues = {
  searchTemplateName:'',
  searchTemplateTypeGeneral: '0',
  searchTemplateTypeOffer: '0',
  searchTemplateTypeFailure: '0',
  searchTemplateWord:'',
  searchTemplateHidden: '0',
  recruitmentManagementDivision:'',
  maxMessageTemplate:'',
} ;

const initialMCAMS010MessageTemplateList = {
  messageTemplateSearchCondition:submitValues,
  searchTemplateName:'',
  searchTemplateTypeGeneral: '0',
  searchTemplateTypeOffer: '0',
  searchTemplateTypeFailure: '0',
  searchTemplateWord:'',
  searchTemplateHidden: '0',
  recruitmentManagementDivision:'',
  maxMessageTemplate:'',
  templateId:[],
  sysVersionNumber:[],
  showHide:0,
  messageTemplateResult:[],
}

const initialopenModal = {
  openCheckModal:false,
  modalMessage:'',
}

const textMap: { [key: string]: string } = {
  reOrderTip: 'ドラッグ＆ドロップで表示順を入れ替えます',
}

export { initialFilterValues, templateTypeList, submitValues,initialMCAMS010MessageTemplateList,initialopenModal, textMap };
