import { getMessage } from "common/messageUtil"

const initialUpdateValues = {
  interviewLocationId: '',
  interviewLocationName: '',
  streetAddress: '',
  mapURL: '',
  sysVersionNumber: 0,
}

const initialDeleteValues = {
  interviewLocationId: '',
  sysVersionNumber: 0,
}

const textMap = {
  delete: {
    delete: '削除する',
    message: getMessage('MCAOS030-004'),
  },
  update: {
    update: '更新する',
    message: getMessage('MCAOS030-001'),
  },
  cancel: {
    cancel: 'キャンセルする',
    message: getMessage('MCAOS030-003'),
  },
}

export { initialUpdateValues, initialDeleteValues, textMap }