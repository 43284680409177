import {
  call,
  put,
  select,
  all,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import {
  setTagManagementList,
  selectTagManagementList,
  deleteTagManagement,
  deleteTagManagementFlg,
  deleteTagManagementCheck,
  deleteOkTagManagement,
  setTagManagementListSearchCondition,
  setTagManagemenSearchCondition,
  deleteNgTagManagement,
  updateTagManagementInit,
  updateTagManagement,
  insertTag,
  insertTagJob,
  getinitTag,
  setinitTag,
  setShow,
  setTagSettings,
  setMessageFlagResult,
} from 'reducers/tagManagementReducer'
import { searchRequest, deleteRequest } from 'apis/MCAKS010Api'
import { initRequest, insertRequest, insertJobRequest } from 'apis/MCAXS170Api'
import { getTagManagementSearchCondition } from 'selectors'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { magiContants } from 'utils/contants'
import { updateInitRequest, updateRequest } from 'apis/MCAKS020Api'

//イベント発生条件:"検索する"ボタン押下時、初期画面
function* selectTagPreferenceListSaga(
  action: ReturnType<typeof selectTagManagementList>
) {
  try {
    // 初期化
    yield put(setTagManagementList([]))
    // データ読み取り前処理
    yield put(setMessageFlagResult(-1));
    // 検索処理
    const data = yield call(searchRequest, action.payload)
    // 検索結果取得
    yield put(setTagManagementList(data))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

//イベント発生条件:"削除"ボタン押下
function* deleteSaga(action: ReturnType<typeof deleteTagManagement>) {
  try {
    // 初期化
    action.payload.deleteTagManagementFlg = magiContants.DELET_FLAG_0
    // 削除処理
    const deleteFlg = yield call(deleteRequest, action.payload)
    yield put(deleteTagManagementFlg(deleteFlg))
    if (deleteFlg === magiContants.DELET_FLAG_0) {
      // チェック正常、データ削除成功
      yield put(deleteTagManagementCheck(magiContants.OUT_FLG_NG))
      // 再検索条件取得
      const searchCondition: ReturnType<typeof getTagManagementSearchCondition> = yield select(
        getTagManagementSearchCondition
      )
      // 初期化
      yield put(setTagManagementList([]))
      // 検索処理
      const resultsDataList = yield call(searchRequest, searchCondition)
      yield put(setTagManagementList(resultsDataList))
      // 削除成功メッセージ出力
      yield put(openSnackbar(magiContants.MESSAGECODE_MCAKS010_004))
    } else if (deleteFlg === magiContants.DELET_FLAG_1) {
      //"タグ"に紐づくデータの存在チェック：エラー、ダイアログ出力
      yield put(deleteTagManagementCheck(magiContants.OUT_FLG_OK))
    }
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

//イベント発生条件:"タグ"に紐づくデータの存在チェックエラー、再削除
function* deleteOkSaga(action: ReturnType<typeof deleteOkTagManagement>) {
  try {
    // 26KH #89085 start
    //チェックダイアログ出力なし
    yield put(deleteTagManagementCheck(magiContants.OUT_FLG_NG))
    // 26KH #89085 end
    // 再削除
    action.payload.deleteTagManagementFlg = magiContants.DELET_FLAG_1
    const deleteFlg = yield call(deleteRequest, action.payload)
    // 削除結果
    yield put(deleteTagManagementFlg(deleteFlg))

    // 再検索条件取得
    const searchCondition: ReturnType<typeof getTagManagementSearchCondition> = yield select(
      getTagManagementSearchCondition
    )
    // 初期化
    yield put(setTagManagementList([]))
    // 検索処理
    const resultsDataList = yield call(searchRequest, searchCondition)
    yield put(setTagManagementList(resultsDataList))
    // 削除成功メッセージ出力
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAKS010_004))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

//イベント発生条件:"タグ"に紐づくデータの存在チェックエラー、ダイアログ出力なし
function* deleteNgSaga(action: ReturnType<typeof deleteNgTagManagement>) {
  //チェックダイアログ出力なし
  yield put(deleteTagManagementCheck(action.payload))
}

// 検索条件を保持
function* setTagPreferenceDate(
  action: ReturnType<typeof setTagManagemenSearchCondition>
) {
  yield put(setTagManagementListSearchCondition(action.payload))
}

//イベント発生条件:タグ名の変更の初期処理追加
function* updateInitSaga() {
  yield call(updateInitRequest)
}

//イベント発生条件:タグ名の変更
function* updateSaga(action: ReturnType<typeof updateTagManagement>) {
  try {
    yield call(updateRequest, action.payload)
    // 再検索条件取得
    const searchCondition: ReturnType<typeof getTagManagementSearchCondition> = yield select(
      getTagManagementSearchCondition
    )
    // 初期化
    yield put(setTagManagementList([]))
    // 検索処理
    const resultsDataList = yield call(searchRequest, searchCondition)
    yield put(setTagManagementList(resultsDataList))
    // 変更成功メッセージ出力
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAKS020_005))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

function* initSaga(action: ReturnType<typeof getinitTag>) {
  try {
    const serverResponse = yield call(initRequest, action.payload)
    yield put(setinitTag(serverResponse))
    yield put(setShow(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* insertSaga(action: ReturnType<typeof insertTag>) {
  try {
    yield call(insertRequest, action.payload)
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAXS170_005))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* insertJobSaga(action: ReturnType<typeof insertTagJob>) {
  try {
    const data = yield call(insertJobRequest, action.payload.request)
    yield put(setTagSettings(data))
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAXS170_005))
    if (
      action.payload.onClose !== undefined &&
      action.payload.event !== undefined
    ) {
      action.payload.onClose(action.payload.event)
    }
    if (action.payload.onCloseApplicantList !== undefined && 'MCARS060' === action.payload.request.screenId) {
      if (action.payload.selectedTag) {
        let obj = new Map<string, string>()
        action.payload.selectedTag.forEach(item => {
          obj.set(item.value.toString(), item.label.toString())
        })
        action.payload.onCloseApplicantList(obj)
      } else {
        action.payload.onCloseApplicantList(new Map<string, string>())
      }
    }
    yield put(setShow(false))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* tagManagementSaga() {
  yield all([
    takeLatest(selectTagManagementList, selectTagPreferenceListSaga),
    takeEvery(deleteTagManagement, deleteSaga),
    takeEvery(deleteOkTagManagement, deleteOkSaga),
    takeEvery(deleteNgTagManagement, deleteNgSaga),
    takeEvery(setTagManagemenSearchCondition, setTagPreferenceDate),
    takeEvery(updateTagManagementInit, updateInitSaga),
    takeEvery(updateTagManagement, updateSaga),
    takeEvery(insertTag, insertSaga),
    takeLeading(insertTagJob, insertJobSaga),
    takeLatest(getinitTag, initSaga),
  ])
}
