import request from 'utils/request'
import { MCBHS060Request } from 'types/MCBHS060/MCBHS060Request'
import { MCBHS060DestinationDownloadRequest } from 'types/MCBHS060/MCBHS060DestinationDownloadRequest'
import { MCBHS060DraftRequest } from 'types/MCBHS060/MCBHS060DraftRequest'
import { MCBHS060InitRequest } from 'types/MCBHS060/MCBHS060InitRequest'
import { MCBHS060PreviewRequest } from 'types/MCBHS060/MCBHS060PreviewRequest'


export const initRequest = (apiData: MCBHS060InitRequest) => {
  return request({
    url: '/MCBHS060/init',
    method: 'post',
    data: apiData,
  })
}

export const draftRequest = (apiData: MCBHS060DraftRequest) => {
  return request({
    url: '/MCBHS060/draft',
    method: 'post',
    data: apiData,
  })
}

export const previewRequest = (apiData: MCBHS060PreviewRequest) => {
  return request({
    url: '/MCBHS060/previewDeadlineChange',
    method: 'post',
    data: apiData,
  })
}

export const confirmRequest = (apiData: MCBHS060Request) => {
  return request({
    url: '/MCBHS060/confirm',
    method: 'post',
    data: apiData,
  })
}

export const destinationDownloadRequest = (apiData: MCBHS060DestinationDownloadRequest) => {
  return request({
    url: '/MCBHS060/destinationDownload',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })
}