import request from 'utils/request'
import { MCBJS030InitDisplayRequest } from 'types/MCBJS030/MCBJS030InitDisplayRequest'
import { MCBJS030SubmissionReqTemplateInputRequest } from 'types/MCBJS030/MCBJS030SubmissionReqTemplateInputRequest'
import { MCBJS030DeleteSubmissionReqTemplateRequest } from 'types/MCBJS030/MCBJS030DeleteSubmissionReqTemplateRequest'
import { MCBJS020SubmitRequestPreviewRequest } from 'types/MCBJS020/MCBJS020SubmitRequestPreviewRequest'

export const submissionReqTemplateDetailRequest = (params : MCBJS030InitDisplayRequest) =>
  request({
    url: 'MCBJS030/init',
    method: 'post',
    data: params 
  })

export const submissionReqTemplateInputRequest = (requestBody:{registeredFileName:File[],submitParams: MCBJS030SubmissionReqTemplateInputRequest}) =>{
  const data = new FormData()
  requestBody.registeredFileName.forEach((file) => {
      data.append('files', file);
  });
  data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))
  return request({
    url: '/MCBJS030/confirm',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}

export const deleteSubmissionReqTemplateRequest = (params: MCBJS030DeleteSubmissionReqTemplateRequest) =>
  request({
    url: '/MCBJS030/delete',
    method: 'post',
    data: params,
})

export const selectMcbApiLinkRequest = () =>
  request({
    url: '/MCBJS030/selectApiLink',
    method: 'post',
  })

export const reloadSubmissionsSetApiRequest  = () =>
request({
  url: '/MCBJS030/reloadSubmissionsSet',
  method: 'post'
})
 
export const reloadThanksTemplateApiRequest  = () =>
request({
  url: '/MCBJS030/reloadThanksTemplate',
  method: 'post'
})

export const reloadReferenceApiRequest  = () =>
request({
  url: '/MCBJS030/reloadReference',
  method: 'post'
})

export const reloadPersonalApiRequest  = () =>
request({
  url: '/MCBJS030/reloadPersonal',
  method: 'post'
})

export const previewRequest030  = (params: MCBJS020SubmitRequestPreviewRequest) =>
request({
  url: '/MCBJS030/preview',
  method: 'post',
  data: params
})
