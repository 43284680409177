import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import { FieldProps } from 'formik';
import { useErrorStyle } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
      marginLeft: '2px',
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  },
  label: {
    marginRight: '30px',
    '& .MuiIconButton-label>input': {
      width: 0,
    },
  },
  noCheck: {
    background: 'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  checked: {
    backgroundColor: '#3f71bd',
    backgroundPosition: 'center center',
    borderColor: '#3f71bd',
    background: 'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  disabledNoCheck: {
    background: 'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid lightgray',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  },
  disabledCheched: {
    background: 'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    backgroundColor: 'lightgray',
    backgroundPosition: 'center center',
    border: '1px solid lightgray',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  },
}))

interface Props extends FieldProps {
  optionList:{
    name: string; 
    label:string;
    id: string;
  }[];
  className?: string;
}

const GroupOptions = ({ field, form, optionList, className }: Props) => {
  const classes = useErrorStyle();
  const classes2 = useStyles();
  const { errors, touched } = form;
  const { name, value } = field;

  const icon = (disabled?: boolean) => {
    if (disabled) {
      return <div className={classes2.disabledNoCheck} />
    }
    return <div className={classes2.noCheck} />
  };
  
  const iconChecked = (disabled?: boolean) => {
    if (disabled) {
      return <div className={classes2.disabledCheched} />
    }
    return <div className={classes2.checked} />
  };

  const handleChange = (option: string) => (e: any) => {
    form.setFieldValue(name, {
      ...value,
      [option]: e.target.checked ? '1' : '0',
    });
  };

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={className}>
      <FormGroup row>
        {optionList.map(i => (
          <FormControlLabel
            className={classes2.label}
            key={i.name}
            control={
              <Checkbox
                className={classes2.root}
                color="primary"
                checked={Boolean(parseInt(value[i.name]))}
                onChange={handleChange(i.name)}
                icon={icon()}
                checkedIcon={iconChecked()}
              />
            }
            label={i.label}
          />
        ))}
      </FormGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  );
};

export default GroupOptions;
