import {
  call,
  put,
  all,
  takeLeading,
  select
} from 'redux-saga/effects'
import {
  fetchSeminarCancellation,
  setSeminarCancellation,
  cancleSeminarCancellation
} from 'reducers/seminarReducer'
import { initRequest, cancleRequest } from 'apis/MCAXS320Api'
import { openSnackbar,openModal } from 'reducers/messageReducer'
import { seminarInit,setSeminarInit,seminarRegister,setAddPageDialogOpen } from 'reducers/seminarReducer'
import { seminarInitRequest,seminarRegisterRequest } from 'apis/MCAXS310Api'
import { getRecruitmentManagementDivision } from '../selectors/authSelectors'

function* fetchSaga(action: ReturnType<typeof fetchSeminarCancellation>) {
    try {
      const data = yield call(initRequest,action.payload.request)
      yield put(setSeminarCancellation(data))
      action.payload.handleModalOpen('entryHistoryCancel')
    } catch (error) {
      yield put(openModal(error.message))
    }
}
function* cancleSaga(action: ReturnType<typeof cancleSeminarCancellation>) {
  try {
    const message = yield call(cancleRequest,action.payload.request)
    action.payload.onOk()
    yield put(openSnackbar(message))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* seminarInitSaga(action: ReturnType<typeof seminarInit>) {
  try {
      const data = yield call(seminarInitRequest,action.payload)
      yield put(setSeminarInit(data))
      yield put(setAddPageDialogOpen(true))

   }catch (error) {
      yield put(openModal(error.message))
  }
}

function* seminarRegisterSaga(action: ReturnType<typeof seminarRegister>) {
  try {
      try {
        const message = yield call(seminarRegisterRequest, action.payload.request);
        action.payload.onOk()
        yield put(setAddPageDialogOpen(false))
        yield put(openSnackbar(message))
      } catch (error) {
        yield put(openModal(error.message))
      }
    } catch (error) {
      yield put(openModal(error))
    }
}

  export default function* accountSaga() {
    yield all([
      takeLeading(fetchSeminarCancellation, fetchSaga),
      takeLeading(cancleSeminarCancellation, cancleSaga),
      takeLeading(seminarInit, seminarInitSaga),
      takeLeading(seminarRegister, seminarRegisterSaga)
    ])
  }
  
  