import request from 'utils/request'
import { MCAZS080MessageConfirmRequest } from 'types/MCAZS080/MCAZS080MessageConfirmRequest'
import { MCAZS080MessageDraftRequest } from 'types/MCAZS080/MCAZS080MessageDraftRequest'
import { MCAZS080MessageInitRequest } from 'types/MCAZS080/MCAZS080MessageInitRequest'
import { MCAZS080MessagePreviewRequest } from 'types/MCAZS080/MCAZS080MessagePreviewRequest'
import { MCAZS080MessageCsvDownloadRequest } from 'types/MCAZS080/MCAZS080MessageCsvDownloadRequest'
import { MCAZS080MessageNotAdoptedNotificationRequest } from 'types/MCAZS080/MCAZS080MessageNotAdoptedNotificationRequest'

export const initRequest = (apiData: MCAZS080MessageInitRequest) => {
  return request({
    url: '/MCAZS080/init',
    method: 'post',
    data: apiData,
  })
}

export const draftRequest = (apiData: MCAZS080MessageDraftRequest, files: any[]) => {
  const data = new FormData()
  data.append('draftRequest', encodeURIComponent(JSON.stringify(apiData)))
  const attachmentList = files
  if (attachmentList) {
    attachmentList.map(attachment => {
      if (attachment) {
        data.append('attachment', attachment)
      }
    })  
  }

  return request({
    url: '/MCAZS080/draft',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const previewRequest = (apiData: MCAZS080MessagePreviewRequest) => {
  return request({
    url: '/MCAZS080/preview',
    method: 'post',
    data: apiData,
  })
}

export const confirmRequest = (apiData: MCAZS080MessageConfirmRequest, files: any[]) => {
  const data = new FormData()
  data.append('confirmRequest', encodeURIComponent(JSON.stringify(apiData)))
  const attachmentList = files
  attachmentList.map(attachment => {
    if (attachment) {
      data.append('attachment', attachment)
    }
  })

  return request({
    url: '/MCAZS080/confirm',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const csvDownloadRequest = (apiData: MCAZS080MessageCsvDownloadRequest) => {
  return request({
    url: '/MCAZS080/csvDownload',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })
}

export const notAdoptedNotificationRequest = (apiData: MCAZS080MessageNotAdoptedNotificationRequest) => {
  return request({
    url: '/MCAZS080/notAdoptedNotification',
    method: 'post',
    data: apiData,
  })
}