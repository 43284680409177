import { RootState } from 'reducers'

export const getCompanyId = (state: RootState) => state.user.companyId
export const getUserId = (state: RootState) => state.user.loginId
export const getUserName = (state: RootState) => state.user.name
export const getUserAuthority = (state: RootState) => state.user.permissions
export const getRecruitmentManagementDivision = (state: RootState) =>
  state.globalMenu.recruitmentManagementDivision
export const getGlobalMenuData = (state: RootState) =>
  state.globalMenu
// 年度対応 start
export const getGlobalTargetYear = (state: RootState) =>
  state.globalMenu.targetYear
// 年度対応 end
