import { monitorApi } from 'apis/MCBCS030Api'
import { openModal, openModalWithArg } from "reducers/messageReducer"
import { getMyCareerBoxInfo,
         initMyCareerBoxInfo,
         setMyCareerBoxInfo,
         outputCsvDownload,
         exectionCooperation, 
         openModalMessage,
         monitor,
         MonitorParam,
} from "reducers/myCareerBoxDataLinkReducer"
import {
  initRequest,
  outputCsvDownloadRequest,
  cooperationRequest,
} from 'apis/MCBCS010Api'
import { all, call, put, select, takeEvery, takeLeading,} from "redux-saga/effects"
import { routeList } from "routes/routes"
import history from 'utils/history'
import { magiContants } from 'utils/contants'
import { getGlobalMenuData } from 'selectors/authSelectors'

const openMyCareerDataWindow = () => {
  const strWindowFeatures = `
    left=0,
    top=0,
    titlebar=yes,
    menubar=yes,
    toolbar=yes,
    location=yes,
    scrollbars=no,
    resizable=yes,
    width=1280,
    height=948，
    `
  // #97897 start
  // const newWin = window.open(routeList.myCareerBoxDataLink,'_blank',strWindowFeatures)
  // if (newWin) {
  //   newWin.window.focus()
  // }
  // #97897 end
}

function* openMCBCS010Saga(action: ReturnType<typeof getMyCareerBoxInfo>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
    try {
      yield call(initRequest, action.payload)
      window.localStorage.setItem(
        'requestParams',
        JSON.stringify(action.payload.targetSelectionManagementIdList)
      )
      window.localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
      openMyCareerDataWindow()
    } catch (error) {
      const arr = error.message.split(',')
      const messageId = arr[0]
      if(messageId == magiContants.MESSAGECODE_MCBCS010_002){
        const args = [arr[1]]
        yield put(openModalWithArg({messageId,args}))
      }else{
        yield put(openModal(error.message))
      } 
    }
}

function* initMCBCS010Saga(action: ReturnType<typeof initMyCareerBoxInfo>) {
  try {
    const data = yield call(initRequest, action.payload)
    yield put(setMyCareerBoxInfo(data))
  } catch (error) {
      yield put(openModal(error.message))
  }
}

const openMyCareerBoxCsvLaodingWindow = () => {
  const strWindowFeatures = `
    left=0,
    top=0,
    titlebar=yes,
    menubar=yes,
    toolbar=yes,
    location=yes,
    scrollbars=yes,
    resizable=yes,
    width=650px,
    height=600px,
    `
  // #97897 start
  // const newWin = window.open(routeList.myCareerBoxCsvLaoding,'_blank',strWindowFeatures)
  // if (newWin) {
  //   newWin.window.focus()
  // }
  // #97897 end
}

function* outputCsvDownloadSaga(action: ReturnType<typeof outputCsvDownload>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
  try {
    const data = yield call(outputCsvDownloadRequest, action.payload.mCBCS010OutputCsvRequest)
    action.payload.nextPageParam.requestId = data.requestId
    action.payload.nextPageParam.count = data.jobSeekerIdNumber
    action.payload.nextPageParam.coordinationFormerCompanyIdentificationKey = data.coordinationFormerCompanyIdentificationKey
    window.localStorage.setItem(
      'requestParam',
      JSON.stringify(action.payload.nextPageParam)
    )
    window.localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
    openMyCareerBoxCsvLaodingWindow()
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* cooperationSaga(action: ReturnType<typeof exectionCooperation>) {
  try {
    const data = yield call(cooperationRequest, action.payload)
    if(data == magiContants.MESSAGECODE_MCBCS010_007){
      yield put(openModalMessage(magiContants.MESSAGECODE_MCBCS010_007))
    }else if(data == magiContants.MESSAGECODE_MCBCS010_008){
      yield put(openModalMessage(magiContants.MESSAGECODE_MCBCS010_008))
    }else{
      // #97897 start
      // history.push(routeList.myCareerBoxDataLinkOK)
      // #97897 end
    }
  } catch (error) {
    yield put(openModal(error.message));
  }
}

// MCBCS030 start
function* getMonitorSaga(action: ReturnType<typeof monitor>) {
  try {
    const status = yield call(monitorApi, action.payload)

    if (status === magiContants.STATUS_90) {
      // #97897 start
      // history.push(routeList.myCareerBoxcsvDownload)
      // #97897 end
    }
    if (status === magiContants.STATUS_99 || status === null) {
      yield put(openModal(magiContants.MESSAGECODE_MCBCS030_003))
    }
    if (
      status === magiContants.STATUS_00 ||
      status === magiContants.STATUS_10
    ) {
      const monitorParam:MonitorParam = {
        requestId:action.payload.requestId,
        selectionManagementIdList: action.payload.selectionManagementIdList,
        selectionFlowSettingIdList: action.payload.selectionFlowSettingIdList,
        targetCount : action.payload.targetCount,
        mcbCategory : action.payload.mcbCategory, 
        coordinationFormerCompanyIdentificationKey : action.payload.coordinationFormerCompanyIdentificationKey
      }
      yield put(monitor(monitorParam))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCBCS030 end

export default function* myCareerBoxDataLinkSaga() {
    yield all([
      takeEvery(getMyCareerBoxInfo, openMCBCS010Saga),
      takeEvery(initMyCareerBoxInfo, initMCBCS010Saga),
      takeLeading(outputCsvDownload, outputCsvDownloadSaga),
      takeLeading(exectionCooperation, cooperationSaga),
      takeEvery(monitor, getMonitorSaga),
    ])
}