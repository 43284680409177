import { Options } from "ahooks/lib/useCountDown"

export interface Option {
  [key: string]: any
  label: string
  value: string
}

export interface SexObj {
  sexCode: string
  sexName: string
}

export interface McbUsageObj {
  mcbUsageCode: string
  mcbUsageName: string
}

export interface SubmissionDeadlineConditionsObj {
  submissionDeadlineConditionsFlag: string
  submissionDeadlineConditionsName: string
}

export interface languageSkillsOptionObj {
  languageSkillsOptionCode: string
  languageSkillsOptionName: string
}

export interface SubmissionStatusConditionsObj {
  submissionStatusConditionsFlag: string
  submissionStatusConditionsName: string
}


export interface SubmissionFileConditionObj {
  fileUploadStatusFlag: number | null
  fileUploadStatusName: string | null
  uploadFileName: string | null
  uploadFileId: number | null
}

export interface SubmissionFileObj {
  submissionFileConditionJudgmentFlag: number
  submissionFileConditionJudgmentName: string
  submissionFileConditionObj: SubmissionFileConditionObj[]
}

export interface EligibilityConditionsObj {
  eligibilityConditionsFlag: string
  eligibilityConditionsName: string
}

export interface affiliationSeminarLaboratoryResearchContentObj {
  divisionCode: number
  detailsCode: number
  learningKeywordCode: string  
  detailsContent: string
  }

// MCBHS161 オリジナル設問 回答
export interface AnswerSelectionList {
  originalQuestionSelectorslId: number        // 回答選択肢ID
  originalQuestionSelectorsName: string       // 回答選択肢名
}
// MCBHS161 オリジナル設問 設定内容
export interface SearchConditionList {
  originalQuestionId: number                  // 設問登録ID
  questionTitle: string                       // 設問登録名
  questionBody: string                        // 設問文
  questionType: string                        // 設問種類
  answerSelect: string                        // 回答有無
  answerSelectionList: AnswerSelectionList[] | null
  uploadSelect: string                        // アップロード区分
}
// MCBHS161 オリジナル設問
export interface OriginalQuestionObj {
  SearchConditionType: string                 // 条件判定 AND/OR
  SearchConditionList: SearchConditionList[]
}

export interface SearchCondition {
  //ApplicantBasic
  applicantId: string[] | null
  name: string[] | null
  residenceObj: ResidenceConditions | null
  schoolName: string | null
  facultyGraduateName: string | null
  mcbUsageObj: Option | null

  // SubmissionRequest
  submissionsObj: SubmissionsObj[] | null
  submissionConditionsObj: SubmissionConditionsObj | null
  submissionsExceptForcedStopedObj: SubmissionsExceptForcedStopedObj | null
  deliveryDateFrom: string | null
  deliveryDateTo: string | null
  submissionDeadlineConditionsObj: SubmissionDeadlineConditionsObj | null
  submissionDeadlineFrom: string | null
  submissionDeadlineTo: string | null
  submissionStatusConditionsObj: SubmissionStatusConditionsObj | null
  filingDateFrom: string | null
  filingDateTo: string | null
  submissionRequestConfirmationStatusConditionsObj: Option | null

  // //EntrySheet
  sexObj: SexObj[] | null
  highSchoolName: string[] | null
  languageSkillsObj: Option[] | null
  languageSkillsConditionsObj: Option | null
  eligibilityObj: Option[] | null
  eligibilityConditionsObj: EligibilityConditionsObj | null
  focusedAcademicWorkObj: Option[] | null
  affiliationSeminarLaboratoryResearchContentObj: affiliationSeminarLaboratoryResearchContentObj[] | null
  minToeic: number | null
  maxToeic: number | null
  minToeflIbt: number | null
  maxToeflIbt: number | null

  // QuestionAndFile
  // MCBHS161 オリジナル設問
  originalQuestionObj: OriginalQuestionObj | null
  // MCBHS161 提出物ファイル
  submissionFileObj: SubmissionFileObj | null

}

export interface ScoutSiteObj {
  scoutSiteCode: string
  scoutSiteName: string
}

// [phase2] start 
export interface SearchTargetObj {
  searchTargetCode: string
  searchTargetName: string
}

export interface McbCategoryAndOrObj {
  mcbCategoryAndOrCode: string
  mcbCategoryAndOrName: string
}

export interface McbCategoryObj {
  mcbCategoryCode: string
  mcbCategoryName: string
}
const categoryAndOrObj: McbCategoryAndOrObj = {
  mcbCategoryAndOrCode: '0',
  mcbCategoryAndOrName: 'AND'
}
// [phase2] end

export interface CABObj {
  cabLogicalName: string
  cabPhysicalName: string
  cabListFlag: string
  cabListName: string
  cabValueFrom: string
  cabValueTo: string
  cabTyp: string
  cabTypName: string
}

export interface OPQObj {
  opqLogicalName: string
  opqPhysicalName: string
  opqValueFrom: string
  opqValueTo: string
  opqTyp: string
  opqTypName: string
}

export interface GABObj {
  gabLogicalName: string
  gabPhysicalName: string
  gabListFlag: string
  gabListName: string
  gabValueFrom: string
  gabValueTo: string
  gabTyp: string
  gabTypName: string
}

export interface ImagesObj {
  imagesLogicalName: string
  imagesPhysicalName: string
  imagesValueFrom: string
  imagesValueTo: string
}

export interface ManagementItemFlagCheckBoxObj {
  managementItemFlagCheckBoxId: string
  managementItemFlagCheckBoxName: string
}

export interface ManagementItemConditionObj {
  recruitmentManagementFlagSettingId: string
  recruitmentManagementFlagName: string
  managementItemPresenceFlag: string
  managementItemPresenceName: string
  managementItemTypeCode: string
  managementItemTypeName: string
  managementItemFlagText: string
  managementItemFlagFrom: string
  managementItemFlagTo: string
  managementItemFlagCheckBoxObj: ManagementItemFlagCheckBoxObj[]
}

export interface ManagementItemObj {
  managementItemConditionJudgmentFlag: string
  managementItemConditionJudgmentName: string
  managementItemConditionObj: ManagementItemConditionObj[]
}

export interface SendMessegeConditionObj {
  sendMessegeMessageTypeFlag: string
  sendMessegeMessageTypeName: string
  sendMessegeSubject: string
  sendMessegeSendConditionFlag: number
  sendMessegeSendConditionName: string
  sendMessegeSendDateFrom: string
  sendMessegeSendDateTo: string
  sendMessegeTemplateConditionFlag: number
  sendMessegeTemplateConditionName: string
  sendMessegeTemplateObj: SendMessegeTemplateObj[]
  // phase2 start
  sendMessegeMyNaviAttachedOptionEntryBoxId: string
  sendMessegeMyNaviAttachedOptionSeminarId: string
  sendMessegeMyNaviAttachedOptionName: string
  // phase2 end
}

export interface SendMessegeTemplateObj {
  sendMessegeTemplateId: string
  sendMessegeTemplateName: string
  sequence: number
}

export interface SendMessegeObj {
  sendMessegeConditionJudgmentFlag: number
  sendMessegeConditionJudgmentName: string
  sendMessegeConditionObj: SendMessegeConditionObj[]
}

export interface EventsConditionObj {
  eventsIndivIdualApplicationRouteId: string
  eventsIndividualApplicationRouteName: string
  eventsIndivIdualEventTypeId: string
  eventsIndividualEventTypeName: string
  eventsIndividualCoordinationFormerEventId: string
  eventsIndividualEventSchedule: string
  eventsIndivIdualEventId: string
  eventsIndividualEventName: string
  eventsIndividualPrefecturesCode: string
  eventsIndividualPrefecturesName: string
  eventsIndividualFlag: number
  eventsIndividualName: string
  // Started By DiaoJiaHao
  eventsIndividualAreaId: string
  eventsIndividualAreaName: string
  // End By DiaoJiaHao
}

export interface EventsObj {
  eventsConditionJudgmentFlag: number
  eventsConditionJudgmentName: string
  eventsWhichOneFlag: number
  eventsWhichOneName: string
  eventsIndividualJudgmentFlag: number
  eventsIndividualJudgmentName: string
  eventsConditionObj: EventsConditionObj[]
}

export interface WebSeminarConditionObj {
  webSeminarApplicationRouteId: string
  webSeminarApplicationRouteName: string
  webSeminarSeminarScheduleId: string
  webSeminarCoordinationFormerWebSeminarId: string
  webSeminarTitle: string
  webSeminarWatchingStatusConditionFlag: number
  webSeminarWatchingStatusConditionName: string
}

export interface WebSeminarObj {
  webSeminarConditionJudgmentFlag: number
  webSeminarConditionJudgmentName: string
  webSeminarConditionObj: WebSeminarConditionObj[]
}

export interface SeminarReceptionConditionObj {
  seminarReceptionIndividualApplicationRouteId: string
  seminarReceptionIndividualApplicationRouteName: string
  seminarReceptionIndividualCoordinationFormerSeminarId: string
  seminarReceptionIndividualSeminarId: string
  seminarReceptionIndividualSeminarName: string
  seminarReceptionIndividualPublicFlag: string
  seminarReceptionIndividualPublicName: string
  seminarReceptionIndividualSeminarScheduleId: string
  seminarReceptionIndividualSeminarScheduleTitle: string
  seminarReceptionIndividualInvisibleFlag: string
  seminarReceptionIndividualInvisibleName: string
  seminarReceptionIndividualEventDate: string
  // [phase2] 開催日テキスト add start
  seminarReceptionIndividualEventDateText: string
  // [phase2] end
  seminarReceptionIndividualStartTime: string
  seminarReceptionIndividualEndTime: string
  seminarReceptionIndividualVenueLocationCode: string
  seminarReceptionIndividualVenueLocationName: string
  seminarReceptionIndividualFlag: number
  seminarReceptionIndividualName: string
}

export interface SeminarReceptionObj {
  seminarReceptionConditionJudgmentFlag: number
  seminarReceptionConditionJudgmentName: string
  seminarReceptionWhichOneFlag: number
  seminarReceptionWhichOneName: string
  seminarReceptionIndividualJudgmentFlag: number
  seminarReceptionIndividualJudgmentName: string
  seminarReceptionConditionObj: SeminarReceptionConditionObj[]
}

export interface QuestionnaireResponseAnswerCheckBoxObj {
  questionnaireResponseAnswerCheckBoxId: string
  questionnaireResponseAnswerCheckBoxName: string
}

export interface QuestionnaireResponseConditionObj {
  questionnaireResponseApplicationRouteId: string
  questionnaireResponseApplicationRouteName: string
  questionnaireResponseEntryBoxId: string
  questionnaireResponseEntryBoxName: string
  questionnaireResponseQuestionNumber: string
  questionnaireResponseQuestionTitle: string
  questionnaireResponseQuestionAnswerConditionFlag: number
  questionnaireResponseQuestionAnswerConditionName: string
  questionnaireResponseQuestionTypeCode: string
  questionnaireResponseQuestionTypeName: string
  questionnaireResponseAnswerText: string
  questionnaireResponseAnswerCheckBoxObj: QuestionnaireResponseAnswerCheckBoxObj[]
}

export interface QuestionnaireResponseObj {
  questionnaireResponseConditionJudgmentFlag: number
  questionnaireResponseConditionJudgmentName: string
  questionnaireResponseConditionObj: QuestionnaireResponseConditionObj[]
}



export interface EntryDateConditionObj {
  entryDateClassificationFlag: string
  entryDateClassificationName: string
  entryDateApplicationRouteId: string
  entryDateApplicationRouteName: string
  entryDatecoordinationFormerEntryBoxId: string
  entryDateEntryBoxId: string
  entryDateEntryBoxName: string
  entryDateFrom: string
  entryDateTo: string
}

export interface EntryDateObj {
  entryDateConditionJudgmentFlag: number
  entryDateConditionJudgmentName: string
  entryDateConditionObj: EntryDateConditionObj[]
}

export interface WelcomeObj {
  welcomeConditionsFlag: number
  welcomeConditionsName: string
}

export interface WelcomeApplicationRouteObj {
  welcomeApplicationRouteId: string
  welcomeApplicationRouteName: string
}

export interface SubmissionsObj {
  submissionsId: string
  submissionsName: string
}

export interface SubmissionConditionsObj {
  submissionConditionsFlag: string
  submissionConditionsName: string
}

export interface SubmissionsExceptForcedStopedObj {
  submissionsExceptForcedStopedId: string
  submissionsExceptForcedStopedName: string
}

export interface ScoutObj {
  scoutConditionsFlag: number
  scoutConditionsName: string
}

export interface ScoutCharacteAndOrObj {
  scoutCharacteAndOrCode: string
  scoutCharacteAndOrName: string
}

export interface ScoutApplicationRouteObj {
  scoutApplicationRouteId: string
  scoutApplicationRouteName: string
}

export interface TagTxtObj {
  tagSettingId: string
  tagName: string
}

export interface CountryRegionObj {
  countryRegionCode: string
  countryRegionName: string
}

export interface DepartmentDetailsObj {
  departmentId: string
  departmentName: string
}

export interface StudentClassificationObj {
  studentClassificationId: string
  studentClassificationName: string
}

export interface UndergraduateDepartmentObj {
  undergraduateDepartmentSchoolTypeId: string
  undergraduateDepartmentSchoolTypeName: string
  undergraduateDepartmentUniversityId: string
  undergraduateDepartmentUniversityName: string
  undergraduateDepartmentFacultyId: string
  undergraduateDepartmentFacultyName: string
  undergraduateDepartmentSubjectId: string
  undergraduateDepartmentSubjectName: string
  subjcetInfomationDagagaMasterId: string
}

export interface SchoolDetailsObj {
  schoolDetailsId: string
  schoolDetailsName: string
}

export interface SchoolGroupObj {
  schoolGroupId: string
  schoolGroupName: string
}

export interface SchoolTypeObj {
  schoolTypeId: string
  schoolTypeName: string
}

export interface PhilosophyHumanitiesObj {
  philosophyHumanitiesCode: string
  philosophyHumanitiesName: string
}

export interface GraduationPeriodObj {
  graduationPeriodCode: string
  graduationPeriodName: string
}

export interface ResidenceCurrent {
  residenceCurrentPrefecturesCode: string
  residenceCurrentPrefecturesName: string
}

export interface ResidenceVacation {
  residenceVacationPrefecturesCode: string
  residenceVacationPrefecturesName: string
}

export interface ResidenceConditions {
  residenceCurrentObj: ResidenceCurrent[]
  residenceVacationObj: ResidenceVacation[]
  // MCBリプレイス #8976 START
  residenceConnectorFlag: string
  // MCBリプレイス #8976 END
  residenceConnectorName: string
}

export interface ApplicationRouteObj {
  applicationRouteFlag: number
  applicationRouteId: string
  applicationRouteName: string
}

export interface TargetApplicationRouteObj {
  targetApplicationRouteFlag: number
  targetApplicationRouteId: string
  targetApplicationRouteName: string
}

export interface SelectionFlowObj {
  selectionFlowId: string
  selectionFlowName: string
}

export interface SelectionStepObj {
  selectionStepId: string
  selectionStepName: string
}

export interface JudgmentUndeterminedObj {
  judgmentUndeterminedCode: string
  judgmentUndeterminedName: string
}

export interface SeminarReceptionObj {
  seminarReceptionConditionJudgmentFlag: number
  seminarReceptionConditionJudgmentName: string
  seminarReceptionWhichOneObj: SeminarReceptionWhichOneObj
  seminarReceptionIndividualObj: SeminarReceptionIndividualObj
}

export interface SeminarReceptionWhichOneObj {
  seminarReceptionWhichOneFlag: number | null
  seminarReceptionWhichOneName: string | null
}

export interface SeminarReceptionIndividualObj {
  seminarReceptionIndividualJudgmentFlag: number
  seminarReceptionIndividualJudgmentName: string
  seminarReceptionIndividualObj2: SeminarReceptionIndividualObj2[]
}

export interface SeminarReceptionIndividualObj2 {
  seminarReceptionIndividualApplicationRouteId: string | null
  seminarReceptionIndividualApplicationRouteName: string | null
  seminarReceptionIndividualCoordinationFormerSeminarId: string | null
  seminarReceptionIndividualSeminarId: string | null
  seminarReceptionIndividualSeminarName: string | null
  seminarReceptionIndividualPublicFlag: string | null
  seminarReceptionIndividualPublicName: string | null
  seminarReceptionIndividualSeminarScheduleId: string
  seminarReceptionIndividualSeminarScheduleTitle: string | null
  seminarReceptionIndividualInvisibleFlag: string | null
  seminarReceptionIndividualInvisibleName: string | null
  seminarReceptionIndividualEventDate: string | null
  // [phase2] 開催日テキスト add start
  seminarReceptionIndividualEventDateText: string | null
  // [phase2] end
  seminarReceptionIndividualStartTime: string | null
  seminarReceptionIndividualEndTime: string | null
  seminarReceptionIndividualVenueLocationCode: string | null
  seminarReceptionIndividualVenueLocationName: string | null
  seminarReceptionIndividualFlag: number
  seminarReceptionIndividualName: string
}

export interface EventsObj {
  eventsConditionJudgmentFlag: number
  eventsConditionJudgmentName: string
  eventsWhichOneObj: EventsWhichOneObj
  eventsIndividualObj: EventsIndividualObj
}

export interface EventsWhichOneObj {
  eventsWhichOneFlag: number | null
  eventsWhichOneName: string | null
}

export interface EventsIndividualObj {
  eventsIndividualJudgmentFlag: number
  eventsIndividualJudgmentName: string
  eventsIndividualObj2: EventsIndividualObj2[]
}

export interface EventsIndividualObj2 {
  eventsIndivIdualApplicationRouteId: string | null
  eventsIndividualApplicationRouteName: string | null
  eventsIndivIdualEventTypeId: string | null
  eventsIndividualEventTypeName: string | null
  eventsIndividualCoordinationFormerEventId: string | null
  eventsIndividualEventSchedule: string | null
  eventsIndivIdualEventId: string
  eventsIndividualEventName: string | null
  eventsIndividualPrefecturesCode: string | null
  eventsIndividualPrefecturesName: string | null
  eventsIndividualFlag: number
  eventsIndividualName: string
  // Started By DiaoJiaHao
  eventsIndividualAreaId: string | null
  eventsIndividualAreaName: string | null
  // End By DiaoJiaHao
}

export interface OrderObjArray {
  sexCodeList: { sexCode: number }[]

}


export const initOrderObjArray: OrderObjArray = {
  sexCodeList: [],
}

export const initSearchCondition: any = {
  applicantId: null,
  name: null,
  residenceObj: null,
  mcbUsageObj: null,
  schoolName: null,
  facultyGraduateName: null,
  submissionsObj: null,
  // 提出物内容(結合条件) 初期値：AND
  submissionConditionsObj: {
    submissionConditionsFlag: '1',
    submissionConditionsName: 'AND',
  },
  submissionsExceptForcedStopedObj: {
    submissionsExceptForcedStopedId: '0',
    submissionsExceptForcedStopedName: '強制停止済を除く',
  },
  deliveryDateFrom: null,
  deliveryDateTo: null,
  // #MCB1.5次開発 #8772 START
  submissionDeadlineConditionsObj: null, 
  // #MCB1.5次開発 #8772 END
  submissionDeadlineFrom: null,
  submissionDeadlineTo: null,
  submissionStatusConditionsObj: null,
  filingDateFrom: null,
  filingDateTo: null,
  submissionRequestConfirmationStatusConditionsObj: null,
  sexObj: null,
  highSchoolName: null,
  languageSkillsObj: null,
  eligibilityObj: null,
  // 保有資格・免許条件 初期値：AND
  eligibilityConditionsObj: {
    eligibilityConditionsFlag: '1',
    eligibilityConditionsName: 'AND',
  },
  // 語学スキル条件 初期値：AND
  languageSkillsConditionsObj: {
    languageSkillsConditionsFlag: '1',
    languageSkillsConditionsName: 'AND',
  },
  focusedAcademicWorkObj: null,
  affiliationSeminarLaboratoryResearchContentObj: null,
  minToeic: null,
  maxToeic: null,
  minToeflIbt: null,
  maxToeflIbt: null,
  submissionFileObj: null
}


