import { call, put, all, takeLatest } from 'redux-saga/effects'
import { getMessage } from 'reducers/redirectReducer'
import { openSnackbar } from 'reducers/messageReducer'
import { getMessageRequest } from 'apis/redirectApi'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { getMarchModeFromToken } from 'utils/marchModeGetUtil'
import { serviceModeChange } from 'reducers/globalMenuReducer'

function* getMessageSaga(action: ReturnType<typeof getMessage>) {
  try {
    const data = yield call(getMessageRequest, action.payload);
    if (data == null) {
      history.push(routeList.home);
    }
    // 転職March #9459 #75621 START
    const pathname = routeList.messageDetail + '?messageSendId=' + data.messageSendId + '&recruitmentManagementDivision=' + data.recruitmentManagementDivision
    if (getMarchModeFromToken() == '1') {
      yield put(serviceModeChange({
        targetService: "0",
        specailCaseType: '2',
        redirectPath: pathname
      }))
    } else {
      history.push(pathname);
    }
    // 転職March #9459 #75621 END
  } catch (error) {
    yield put(openSnackbar(error.message))
  }
}

export default function* progressStatusHistorySaga() {
  yield all([
    takeLatest(getMessage, getMessageSaga),
  ])
}
