import request from 'utils/request'
import AccountRequestDto from 'types/accountRequestDto'

export const fetchRequest = () =>
  request({
    url: '/admin/list',
    method: 'get',
  })

export const createRequest = (account: AccountRequestDto) =>
  request({
    url: '/admin/create',
    method: 'post',
    data: account,
  })

export const updateRequest = (account: AccountRequestDto) =>
  request({
    url: '/admin/update',
    method: 'post',
    data: account,
  })

export const deleteRequest = (id: string) =>
  request({
    url: `admin/delete/${id}`,
    method: 'post',
  })
