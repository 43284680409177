import { MCAQS040JobSeekerDisplay } from 'types/MCAQS040/MCAQS040JobSeekerDisplay'
import { MCAQS040MergingExecutionRequest } from 'types/MCAQS040/MCAQS040MergingExecutionRequest'
import request from 'utils/request'

// 初期表示処理
export const mCAQS040InitMergingMethodConfirmation = (
  apidata: MCAQS040MergingExecutionRequest
) =>
  request({
    url: `/MCAQS040/init`,
    method: 'post',
    data: apidata,
  })

// [実行する]ボタン押下時
export const mCAQS040MergingExecutionRequest = (
  apidata: MCAQS040MergingExecutionRequest
) =>
  request({
    url: `/MCAQS040/execute`,
    method: 'post',
    data: apidata,
  })
// 「氏名（漢字）」リンク押下時
export const mCAQS040JobSeekerDisplay = (apidata: MCAQS040JobSeekerDisplay) =>
  request({
    url: `/MCAQS040/select`,
    method: 'post',
    data: apidata,
  })
