import React from 'react'

const Footer = () => {
  return (
    <>
      <footer>Copyright &copy; Mynavi Corporation</footer>
      <button type='button' className='to-top'>
        最上位に戻る
      </button>
    </>
  )
}

export default Footer
