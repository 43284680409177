import { MCAXS020SearchRequest } from 'types/MCAXS020/MCAXS020SearchRequest'
import { MCAXS020UpdateJudgmentRequest } from 'types/MCAXS020/MCAXS020UpdateJudgmentRequest'
import { MCAXS020UpdateExcludeRequest } from 'types/MCAXS020/MCAXS020UpdateExcludeRequest'
import { MCAXS020UpdateUnExcludeRequest } from 'types/MCAXS020/MCAXS020UpdateUnExcludeRequest'
import { MCAXS020ChangeDisplayTypeRequest } from 'types/MCAXS020/MCAXS020ChangeDisplayTypeRequest'
import { SearchCondition, initSearchCondition } from './searchConditionConfig'

export interface SpecificConditionInfo {
  specificConditionName: string //特定条件名
  specificConditionSelectionManagementIdList: string[] //特定条件選考管理IDリスト
}

export interface ApplicantsInfo {
  jobSeekerId: string //求職者ID
  entryId: string //エントリーID
  selectionManagementId: string //選考管理ID
}

export interface ApplicantDetailsRequest {
  applicantsListInfo: ApplicantsInfo[] //応募者情報
  listIndex: number //表示対象位置
}

/** My検索設定情報 */
export interface MySearch {
  entrySearchCriteriaSettingId: string //エントリー検索条件設定ID
  searchCriteria: string //検索条件
  searchCriteriaName: string //検索条件名
  searchCriteriaDisplay: string //検索条件(表示用)
}

/** フィルタ入力初期値 */
export const initialValues = {
  nameForm: '',
}

/** 画面表示情報 */
export interface DisplayInfo {
  applicantsListInfo: ApplicantsListInfo[] //応募一覧リスト情報
}

export interface FilterDisplay {
  filterDisplaySelenctionFlowInfo: FilterDisplaySelenctionFlowInfo[]
  filterDisplayProgressInfoAllCount: number
  filterDisplayProgressInfo: FilterDisplayProgressInfo[]
  filterDisplayDecisionInfo: FilterDisplayDecisionInfo[]
  filterDisplayTagInfo: FilterTagInfo[]
  filterDisplayCommonlyTagInfo: FilterTagInfo[]
}

export interface FilterDisplaySelenctionFlowInfo {
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
}

export interface FilterDisplayProgressInfo {
  progressStatusSettingId: string //選考ステップ設定ID
  progressName: string //進捗名
  progressClassification: string // 進捗区分
  count: number // 件数
}

export interface FilterDisplayDecisionInfo {
  decisionDivision: string //判定区分種別
  decisionName: string //判定名
}

export interface FilterTagInfo {
  tagSettingId: string //タグ設定ID
  tagName: string //タグ名称
}

export interface FilterCondition {
  nameForm: string
  jobCategoryForm: string
  selectionFlowSelect: Option
  progressStatusSelect: Option
  progressStatusSelectDecisionDivisionType: string
  progressStatusSelectProgressType: string
  judgmentStatusSelect: Option
  tagSelect: Option[]
  isUnread: boolean
}
export interface FilterRequest {
  filterCondition: FilterCondition
  searchResult: SearchResult
  tagSelect?: Option[]
  selectionFlowSelect?: Option
  progressStatusSelect?: Option
  newScreenTransitionInput?: ScreenTransitionInput
  newSearchRequest?: MCAXS020SearchRequest
}
export interface Option {
  label: string
  value: string
}

export const initOption = {
  label: '',
  value: '',
}

export interface SortCondition {
  order: string
}

/**最大カード形式　(リクエストに対するレスポンス内容) */
export interface MaxCardInfo {
  selectionManagementId: string //選考管理ID
  managementItem: ManagementItemColumn[] //管理項目
  comment: string //コメント
  messageList: Message[] //メッセージリスト[最大カード用]
}

/**サービスモード更新 (リクエストに対するレスポンス内容) */
export interface ServiceModeUpdateResult {
  token: string
  tokenHead: string
  serviceModeFlag: string
}

export interface MaxCardInfoResult {
  maxCardObj: MaxCardInfo[]
}

/** 検索結果　(検索リクエストに対するレスポンス内容) */
export interface SearchResult {
  displayInfo: Data[] //エントリー一覧画面表示情報
  flowMasterInfo: FlowMasterInfo[] //選考フロー設定関連情報
  tagMasterInfo: TagMasterInfo[] //タグ設定情報
  mediaMasterInfo: MediaMasterInfo[] //応募経路設定情報
  applicantsListInfo: ApplicantsListInfo[] //応募一覧リスト情報
  applicantListMediaInfo: ApplicantListMediaInfo[] //応募一覧応募経路情報
  applicantListTagsInfo: ApplicantListTagsInfo[] //応募一覧タグ情報
  duplicationInfo: DuplicationInfo[] //重複情報情報
  entryIcon1Info: EntryIcon1Info[] //アイコン群１情報情報
  searchCondition: string | null //検索条件JSON
  searchConditionDisplay: string //検索条件(表示用)
  searchCriteriaName: string | null //検索条件名
  applicationJobCategorySuggestInfo: ApplicationJobCategorySuggestInfo[] //応募職種サジェスト情報
  totalCount: number // 検索結果件数
  listDisplayType: string,  //表示形式
}

/** エントリー一覧画面表示情報 */
export interface Data {
  specificTargetDataGetFlag: string //削除フラグ
  selectionManagementId: string //選考管理ID
  jobSeekerId: string //求職者ID
  entryId: string //エントリーID
  selectionFlowSettingId: string //選考フロー設定ID
  mediaSettingIdList: string[] // 応募経路設定IDリスト
  id: string //表示用求職者ID
  name: string //姓名
  age: number //年齢
  address: string //住所
  suggestedWorkingPlace: string // 希望勤務地
  sex: string // 性別
  evaluation: string //星評価
  unsubscribe: boolean //退会済みバッジ表示
  datetime: string //応募日時
  applicationRouteListItem: string[] // 応募経路バッジ
  applicationTypeListItem: string //応募種別バッジ
  description: string //職種説明
  multiSelection: boolean //複数選考バッジ表示
  replicate: boolean //重複バッジ表示
  lastEducation: string // 最終学歴
  education: string //学歴
  current: string //　現在の就業状況
  experienceCount: string //経験社数
  experience: string[] //経験
  managementItem: ManagementItemColumn[] //管理項目
  badgeList: string[] //水色バッジリスト
  campus: string //応募経路
  progressStatusSettingId: string //選考ステップ設定ID
  progressName: string //進捗名
  decisionDivision: string //判定区分
  decisionDivisionType: string //判定区分種別
  notApplicable: boolean //対象外フラグ
  tagList: Tag[] //タグリスト
  comment: string //コメント
  message: Message //メッセージ
  messageList: Message[] //メッセージリスト[最大カード用]
  unread: boolean //未読フラグ
  decisionChangeToolTipOpen: boolean //判定区分変更時の吹き出し表示の有無
  specificTargetFlag: string //特定条件フラグ
  nameForFilter: string //フィルター用姓名
  progressType:string //進捗種別
  sysVersionNumber1:number;  // sysバージョン番号_選考管理
  recommendFlag: string //推薦フラグ
  recommendTime: string //推薦日時
}

/** 管理項目エリア */

export interface ManagementItemColumn {
  managementItemNo: number
  managementItemName: string
  managementItemValue: string
  managementItemType: string
  managementItemUpdateDateTime: string
}

export interface Tag {
  tagSettingId: string //タグ設定ID
  tagName: string //タグ名称
}

export interface Message {
  messageRead: string
  id: string
  type: string
  title: string
  datetime: string
  receivingTimeForSort: number | null
  sendTimeForSort: number | null
}
export const initManagementItemColumn: ManagementItemColumn[] = [{
  managementItemNo: 0,
  managementItemName: '',
  managementItemValue: '',
  managementItemType: '',
  managementItemUpdateDateTime: '',
}]



export const initMessage: Message = {
  messageRead: '',
  id: '',
  type: '',
  title: '',
  datetime: '',
  receivingTimeForSort: null,
  sendTimeForSort: null,
}

export const initData: Data = {
  specificTargetDataGetFlag: '', //削除フラグ
  selectionManagementId: '', //選考管理ID
  jobSeekerId: '', //求職者ID
  entryId: '', //エントリーID
  selectionFlowSettingId: '', //選考フロー設定ID
  mediaSettingIdList: [],
  id: '', //ID
  name: '', //姓名
  age: 0, //年齢
  address: '', //住所
  suggestedWorkingPlace: '',
  sex: '', // 性別
  evaluation: '', //星評価
  unsubscribe: false, //退会済みバッジ表示
  datetime: '', //応募日時
  applicationRouteListItem: [], //応募経路バッジ
  applicationTypeListItem: '', //応募種別バッジ
  description: '', //職種説明
  multiSelection: false, //複数選考バッジ表示
  replicate: false, //重複バッジ表示
  lastEducation: '', //最終学歴
  education: '', //学歴
  current: '', //　現在の就業状況
  experienceCount: '', //経験社数
  experience: [], //経験
  managementItem: initManagementItemColumn, //管理項目
  badgeList: [], //水色バッジリスト
  campus: '', //応募経路
  progressStatusSettingId: '', //選考ステップ設定ID
  progressName: '',
  decisionDivision: '', //判定区分
  decisionDivisionType: '', //判定区分種別
  notApplicable: false, //対象外フラグ
  tagList: [], //タグリスト
  comment: "コメントあいうえおかきくけこさしすせそたここから２１文字以上です。この先は２００文字表示をうまくできるかのテストです。あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ２００" , //コメント
  message: initMessage, //メッセージ
  messageList: [initMessage], //メッセージ
  unread: false, //未読フラグ
  decisionChangeToolTipOpen: false, //判定区分変更時の吹き出し表示の有無
  specificTargetFlag: '', //特定対象フラグ
  nameForFilter: '', //フィルター用姓名
  progressType: '', //進捗種別
  sysVersionNumber1: 0,  // sysバージョン番号_選考管理
  recommendFlag: '', //推薦フラグ
  recommendTime: '', //推薦日時
}

/** 選考フロー設定関連情報 */
export interface FlowMasterInfo {
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
  selectionClassification: string //選考種別
  displayOrder: number //表示順
  progressType: string //進捗種別
  progressName: string //進捗名
  decisionDivisionType: string //判定区分種別
  progressStatusSettingId: string //選考ステップ設定ID
}

/** タグ設定情報 */
export interface TagMasterInfo {
  tagSettingId: string //タグ設定ID
  tagName: string //タグ名称
  tagCount: number //タグ設定ID件数
}

/** 応募経路設定情報 */
export interface MediaMasterInfo {
  mediaId: string //応募経路設定フラグ
  contractMediaId: string //契約応募経路ID
  mediaSettingId: string //応募経路設定ID
  mediaName: string //応募経路名称
}

/**応募一覧リスト情報 */
export interface ApplicantsListInfo {
  jobSeekerId: string //求職者ID
  entryId: string //エントリーID
  selectionManagementId: string //選考管理ID
  progressStatusSettingId: string //選考ステップ設定ID
  nonTargetFlag: string //対象外フラグ
  jobSeekerIdForDisplay: string //表示用求職者ID
  selectionFlowSettingId: string //選考フロー設定ID
  prefecturesCode: string //都道府県コード
  entryReceptionTime: Date //エントリー受付日時
  entryReadFlag: string //エントリー既読フラグ
  entryType: string //エントリー種別
  entryJobCategoryName: string //応募種別名
  educationalBackgroundSummary: string //学歴サマリ
  workingCompanyCountExperienceCompanyCount: string //就業社数(経験社数)
  currentWorkingStatus: string //現在の就業状況
  experienceJobCategorySummary: string //経験職種サマリ
  applicantScreeningImmunityFlag: string //書類選考免除フラグ
  unsubscribeTime: Date //退会日時
  specificTargetFlag: string //特定条件対象フラグ
  specificTargetDataGetFlag: string //特定条件データ取得フラグ
  sysVersionNumber1: number //sysバージョン番号_選考管理
  sysUpdateTime: Date //sys更新日時
  sysVersionNumber2: number //sysバージョン番号_選考ステップ管理
  familyName: string //姓
  name: string //名
  messageSendId: string //メッセージ送信ID
  sendAndReceiveDivision: string //送受信区分
  messageReadFlag: string //メッセージ既読フラグ
  subject: string //件名
  sendTime: Date | null //送信日時
  receivingTime: Date | null //受信日時
  starEvaluation: string //星評価
  decisionDivision: string //判定区分
  birthdate: Date //生年月日
  sex: string //性別
  prefecturesCodeString: string //都道府県表示文字
  sexString: string //性別表示文字
}

/**応募一覧応募経路情報 */
export interface ApplicantListMediaInfo {
  selectionManagementId: string //選考管理ID
  mediaId: string //応募経路設定フラグ [0]契約応募経路 [1]応募経路設定
  contractMediaId: string //契約応募経路ID
  mediaSettingId: string //応募経路設定ID
}

/**応募一覧タグ情報 */
export interface ApplicantListTagsInfo {
  selectionManagementId: string //選考管理ID
  tagSettingId: string //タグ設定ID
}

/** 重複情報 */
export interface DuplicationInfo {
  countId: string //対象ID [0]複数選考 [1]重複
  jobSeekerId: string //求職者ID
  targetCount: number //対象件数
}

/** アイコン群１情報 */
export interface EntryIcon1Info {
  selectionManagementId: string //選考管理ID
  sequence: string //連番
  iconName: string //アイコン名
}

/** 応募職種サジェスト情報 */
export interface ApplicationJobCategorySuggestInfo {
  entryJobCategoryName: string //応募職種名
}

/** 合否ボタン押下レスポンス */
export interface UpdateJudgemtnResult {
  selectionManagementId: string
  progressStatusSettingId: string
  decisionDivision: string
  sysVersionNumber1: number
  sysVersionNumber2: number
}

/** 一覧画面遷移元画面からの入力情報 */
export interface ScreenTransitionInput {
  screenId: string // 呼出し側機能ID
  bulkManipulationId: string //一括操作識別ID
  specificCriteriaName: string //特定条件名
  specificSelectionManagementIdList: string[] //特定条件選考管理IDリスト
  listId: string // 表示一覧ID
  searchType: string // 条件種別
  selectionFlowSettingId: string //選考フロー設定ID
  progressStatusSettingId: string //選考ステップ設定ID
  contractMediaId: string //契約応募経路ID
  mediaSettingId: string //応募経路設定ID
  coordinationJobCategoryKey: string //連携元職種識別キー
  readFlag: string //既読フラグ
  entrySearchCriteriaSettingId: string //エントリー検索条件設定ID
  searchCondition: SearchCondition // 検索条件JSON(呼び出し機能IDがMCAXS020かつ、イベントIDが8である場合のみ設定する)
}

/** 検索に使用された検索条件 */
export interface SelectedSearch {
  searchCondition: SearchCondition //検索条件
  searchConditionDisplay: string //検索条件（表示用）
}

export const initScreenTransitionInput: ScreenTransitionInput = {
  screenId: '', // 呼出し側機能ID
  bulkManipulationId: '', //一括操作識別ID
  specificCriteriaName: '', //特定条件名
  specificSelectionManagementIdList: [], //特定条件選考管理IDリスト
  listId: '0', //表示一覧ID
  searchType: '', // 条件種別
  selectionFlowSettingId: '', //選考フロー設定ID
  progressStatusSettingId: '', //選考ステップ設定ID
  contractMediaId: '', //契約応募経路ID
  mediaSettingId: '', //応募経路設定ID
  coordinationJobCategoryKey: '', //連携元職種識別キー
  readFlag: '', //既読フラグ
  entrySearchCriteriaSettingId: '', //エントリー検索条件設定ID
  searchCondition: initSearchCondition,
}

export const initeSearchRequest: MCAXS020SearchRequest = {
  sourceFunctionId: '', // 呼出し側機能ID
  bulkManipulationId: '', //一括操作識別ID
  conditionType: '', // 条件種別
  listId: '', // 表示一覧ID
  searchCondition: '', // 検索条件(JSON形式)
  selectionManagementIdList: [], //特定条件選考管理IDリスト
  entrySearchCriteriaSettingId: null,
  searchCount: 1, //検索回数
  sortKey: '0', //ソート条件
}

export const initMySearch: MySearch = {
  entrySearchCriteriaSettingId: '',
  searchCriteria: '',
  searchCriteriaName: '',
  searchCriteriaDisplay: '',
}

export const initUpdateJudgmentRequest: MCAXS020UpdateJudgmentRequest = {
  jobSeekerId: '',
  entryId: '',
  progressStatusSettingId: '',
  selectionManagementId: '',
  decisionDivision: '',
  sysVersionNumber1: 0,
  sysVersionNumber2: 0,
  flowMasterInfo: [],
}

export const initSearchResult: SearchResult = {
  displayInfo: [],  //エントリー一覧画面表示情報
  flowMasterInfo: [], //選考フロー設定関連情報
  tagMasterInfo: [], //タグ設定情報
  mediaMasterInfo: [], //応募経路設定情報
  applicantsListInfo: [], //応募一覧リスト情報
  applicantListMediaInfo: [], //応募一覧応募経路情報
  applicantListTagsInfo: [], //応募一覧タグ情報
  duplicationInfo: [], //重複情報
  entryIcon1Info: [], //アイコン群１情報
  searchCondition: null, //検索条件
  searchConditionDisplay: '', //検索条件(表示用)
  searchCriteriaName: null, //検索条件名
  applicationJobCategorySuggestInfo: [], //応募職種サジェスト情報
  totalCount: 0, //検索結果件数
  listDisplayType: '', //一覧表示形式
}

export const initFilterDisplay: FilterDisplay = {
  filterDisplaySelenctionFlowInfo: [],
  filterDisplayProgressInfoAllCount: 0,
  filterDisplayProgressInfo: [],
  filterDisplayDecisionInfo: [],
  filterDisplayTagInfo: [],
  filterDisplayCommonlyTagInfo: [],
}

export const initFilterCondition: FilterCondition = {
  nameForm: '',
  jobCategoryForm: '',
  selectionFlowSelect: initOption,
  progressStatusSelect: initOption,
  progressStatusSelectDecisionDivisionType: '',
  progressStatusSelectProgressType: '',
  judgmentStatusSelect: initOption,
  tagSelect: [],
  isUnread: false,
}

export const initSortCondition: SortCondition = {
  order: '0',
}

export const initApplicantDetailsRequest: ApplicantDetailsRequest = {
  applicantsListInfo: [],
  listIndex: 0,
}

export const initMCAXS020UpdateExcludeRequest: MCAXS020UpdateExcludeRequest = {
  selectionManagementId: '',
  sysVersionNumber1: 0,
  sysVersionNumber2: 0,
  selectionFlowSettingId: '',
  flowMasterInfo: [],
}

export const initMCAXS020UpdateUnExcludeRequest: MCAXS020UpdateUnExcludeRequest = {
  selectionManagementId: '',
  sysVersionNumber1: 0,
  sysVersionNumber2: 0,
  flowMasterInfo: [],
}

export const initMCAXS020ChangeDisplayTypeRequest: MCAXS020ChangeDisplayTypeRequest = {
  selectionManagementInfo: [],   
  listDisplayType: ''         
}

export const applicantInitValues = {
  entrySearchCriteriaSettingId: '',
  screenId: '',
  searchType: '',
  specificCriteriaName: '',
  selectionManagementIdList: '',
  selectionFlowSettingId: '',
  progressStatusSettingId: '',
  contractMediaId: '',
  mediaSettingId: '',
}

export const SCREEN_ID = 'MCAXS401'

export const initSelectedSearch = {
  searchCondition: initSearchCondition,
  searchConditionDisplay: '',
}

export const LIST_DISPLAY_TYPE_DEFAULT_CARD = '1'
export const LIST_DISPLAY_TYPE_MAX_CARD = '2'
export const LIST_DISPLAY_TYPE_SIMPLE_LIST = '3'