import request from 'utils/request'
import { MCBHS040SearchRequest } from 'types/MCBHS040/MCBHS040SearchRequest'
import { MCBHS040McbCheckDetailsRequest } from 'types/MCBHS040/MCBHS040McbCheckDetailsRequest'
import { MCBHS040GetMySearchRequest } from 'types/MCBHS040/MCBHS040GetMySearchRequest'
import { MCBHS040GetContractMediaInfoRequest } from 'types/MCBHS040/MCBHS040GetContractMediaInfoRequest'

export const searchRequest = (apiData: MCBHS040SearchRequest) =>
  request({
    url: '/MCBHS040/init',
    method: 'post',
    data: apiData,
  })

export const pushMcbCheckDetailsRequest = (apiData: MCBHS040McbCheckDetailsRequest) =>
  request({
    url: '/MCBHS040/pushMcbCheckDetails',
    method: 'post',
    data: apiData,
  })

export const getMySearchRequest = (apiData: MCBHS040GetMySearchRequest) =>
  request({
    url: '/MCBHS040/pushCallSearchCondition',
    method: 'post',
    data: apiData,
  })

export const getContractMediaInfoRequest = (apiData: MCBHS040GetContractMediaInfoRequest) =>
  request({
    url: '/MCBHS040/getContractMediaInfo',
    method: 'post',
    data: apiData,
  })
