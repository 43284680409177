import { MCAXS141InitRequest } from 'types/MCAXS141/MCAXS141InitRequest'
import { MCAXS141OutputPdfRequest } from 'types/MCAXS141/MCAXS141OutputPdfRequest'
import request from 'utils/request'

export const initRequest = (apidata: MCAXS141InitRequest) =>
  request({
    url: `/MCAXS141/init`,
    method: 'post',
    data: apidata,
  })
export const outputPdfRequest = (apidata: any) =>
  request({
    url: apidata,
    method: 'get',
    responseType: 'blob',
  })
