import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MCAXS100SendHistoryCommentRequest } from "types/MCAXS100/MCAXS100SendHistoryCommentRequest";
import { commentList, sendCommentHistoryInitialValues, commentHistoryListinitialValues } from "pages/MCAXS100/formConfig";

interface SendHistoryComment {
    HistoryCommentRequest: MCAXS100SendHistoryCommentRequest
    HistoryCommentList: commentList[]
}
const initialState: SendHistoryComment = {
    HistoryCommentRequest: sendCommentHistoryInitialValues,
    HistoryCommentList: commentHistoryListinitialValues,
}
const commentHistorySlice = createSlice({
    name: 'commentHistory',
    initialState,
    reducers: {
        commentHistoryInit(state, action: PayloadAction<MCAXS100SendHistoryCommentRequest>) {
            return state
        },
        setCommentHistory: (state, action: PayloadAction<commentList[]>) => {
            state.HistoryCommentList = action.payload
        },
    }
})

export const {
    commentHistoryInit,
    setCommentHistory,
} = commentHistorySlice.actions
export default commentHistorySlice.reducer