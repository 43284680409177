import {call,put,all,takeLatest,takeEvery} from 'redux-saga/effects'
// #75311 25KH start
import {MCAXS080UpdateHistryDeleteFlagRequest, progressStatusHistoryInit,setProgressStatusHistoryInit,updateHistryDelete} from 'reducers/progressStatusHistoryReducer'
import { openSnackbar,openModal } from 'reducers/messageReducer'
import {progressStatusHistoryInitRequest,updateHistryDeleteRequest} from 'apis/MCAXS080Api'
import { magiContants } from 'utils/contants'
import { processChangeInit } from 'reducers/selectionStatusUpdateReducer'
import { InitCondition } from 'reducers/submissionReqTemplateReducer'
// #75311 25KH end

function* progressStatusHistoryInitSaga(action: ReturnType<typeof progressStatusHistoryInit>) {
  try {
    const data = yield call(progressStatusHistoryInitRequest, action.payload);
    yield put(setProgressStatusHistoryInit(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// #75311 25KH start
// 履歴の削除
function* updateHistryDeleteSaga(action: ReturnType<typeof updateHistryDelete>) {
  try {
    yield call(updateHistryDeleteRequest, action.payload.MCAXS080UpdateHistryDeleteFlagRequest);
    if(action.payload.MCAXS080UpdateHistryDeleteFlagRequest.progressStatusHistoryId.length != 0 && action.payload.MCAXS080UpdateHistryDeleteFlagRequest.sysVersionnumber.length != 0){
      yield put(openSnackbar(magiContants.MESSAGECODE_MCAXS080_004))
    }
    yield put(processChangeInit(action.payload.initCondition))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #75311 25KH end

export default function* progressStatusHistorySaga() {
  yield all([
    takeEvery(progressStatusHistoryInit, progressStatusHistoryInitSaga),
    // #75311 25KH start
    takeEvery(updateHistryDelete, updateHistryDeleteSaga),
    // #75311 25KH end
  ])
}
