import React from 'react'
import { Button } from '@material-ui/core'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  btnNormal: {
    width: '100px',
    height: '40px',
    color: 'white',
    backgroundColor: '#133e80',
    textAlign: 'center',
    lineHeight: '40px',
    borderRadius: '5px',
  },
  btnMyCareerBox: {
    width: '130px',
    height: '40px',
    color: '#133e80',
    textTransform: 'none',
  },
}))

const TabMcb = () => {
  const classes = useStyles()

  const myCareerBox = () => {
    history.push(routeList.mcbMessageOutBox)
  }

  return (
    <div>
      <div className={classes.btnNormal}>通常</div>
      <Button
        variant='outlined'
        onClick={myCareerBox}
        className={classes.btnMyCareerBox}>
        My CareerBox
      </Button>
    </div>
  )
}

export default TabMcb
