import { checkMcbServiceStatusRequest } from 'apis/MCAXS351Api'
import { getMCAXS361InitRequest } from 'apis/MCAXS361Api'
import { getMCAXS371InitRequest } from 'apis/MCAXS371Api'
import { getMCAXS381InitRequest } from 'apis/MCAXS381Api'
import { getMCAXS391InitRequest } from 'apis/MCAXS391Api'
import { getMCAXS701InitRequest } from 'apis/MCAXS701Api'
import { schoolInfoInitRequest } from 'componentsHsc/AdvancedSearch/Config/formConfig'
import { SchoolInfo } from 'componentsHsc/AdvancedSearch/Config/schoolConfig'
import {
  getMcaxs351Init,
  setMcaxs351Init,
  setCurrentTab,
  setCurrentTabMcb,
  getMcaxs371Init,
  setMcaxs371Init,
} from 'reducers/advancedSearchReducer'
import { openModal } from 'reducers/messageReducer'
import { setCircleLoadingFlag } from 'reducers/submissionRequestListReducer'
import { all, call, put, takeLatest } from 'redux-saga/effects'

// NOTE:処理をapplicantListSaga#searchSagaに移動したため、参照されない
// 検索条件詳細の初期画面表示情報を取得
function* getMcaxs401InitSaga(action: ReturnType<typeof getMcaxs351Init>) {
  try {
    const [
      mCAXS361Result, //応募情報取得
      mCAXS371Response, //学校情報取得
      mCAXS381Response, //基本情報取得
      mCAXS391Result, //採用管理情報取得
      mCAXS701Response, //My CareerBox情報
    ] = yield all([
      call(getMCAXS361InitRequest, action.payload.mCAXS361Request),
      call(getMCAXS371InitRequest, action.payload.mCAXS371Request),
      call(getMCAXS381InitRequest),
      call(getMCAXS391InitRequest, action.payload.mCAXS391Request),
      // MCBリプレース MCAXS701 START
      call(getMCAXS701InitRequest),
      // MCBリプレース MCAXS701 END
    ])

    const mCAXS371Result = {
      graduationPeriodList: mCAXS371Response.graduationPeriodList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      philosophyHumanitiesList: mCAXS371Response.philosophyHumanitiesList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      schoolTypeList: mCAXS371Response.schoolTypeList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),

      schoolNameList: mCAXS371Response.schoolNameList,
      facultyGraduateNameList: mCAXS371Response.facultyGraduateNameList,
      departmentDepartmentNameList:
        mCAXS371Response.departmentDepartmentNameList,
      countryRegionList: mCAXS371Response.countryRegionList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      schoolGroupObj: mCAXS371Response.schoolGroupObj,
      schoolDetailsObj: mCAXS371Response.schoolDetailsObj,
      undergraduateDepartmentObj: mCAXS371Response.undergraduateDepartmentObj,
      studentClassificationObj: mCAXS371Response.studentClassificationObj,
      departmentDetailsObj: mCAXS371Response.departmentDetailsObj,
    }

    const mCAXS381Result = {
      sexList: mCAXS381Response.sexList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
      languageSkillsList: mCAXS381Response.languageSkillsList,
      eligibilityList: mCAXS381Response.eligibilityList,
      memberTypeList: mCAXS381Response.memberTypeList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
    }

    // MCBリプレース MCAXS701 START
    const mCAXS701Result = {
      deliveryDetailList: mCAXS701Response.deliveryDetailList.map((option: any) => ({
        value: option.deliveryDetailConditionId,
        label: option.deliveryDetailConditionName,
      })),
      deliveryStatusList: mCAXS701Response.deliveryStatusList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
      deadlineTypeList: mCAXS701Response.deadlineTypeList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
      submitStatusList: mCAXS701Response.submitStatusList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
      errorCode:mCAXS701Response.errorCode
    }
    // MCBリプレース MCAXS701 END

    //ReduxStoreに保持
    yield put(
      setMcaxs351Init({
        mCAXS361Result,
        mCAXS381Result,
        mCAXS391Result,
        mCAXS701Result,
      })
    )

  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* setCurrentTabMcbSaga(action: ReturnType<typeof setCurrentTabMcb>) {
  try {
    yield call(checkMcbServiceStatusRequest)
    yield put(setCurrentTab(action.payload))
  } catch (e) {
    const error = e as any
    yield put(openModal(error.message))
  }
}

function* getMcaxs371InitSaga() {
  try {
    const mCAXS371Response = yield call(
      getMCAXS371InitRequest,
      schoolInfoInitRequest
    )

    const mCAXS371Result: SchoolInfo = {
      graduationPeriodList: mCAXS371Response.graduationPeriodList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      philosophyHumanitiesList: mCAXS371Response.philosophyHumanitiesList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      schoolTypeList: mCAXS371Response.schoolTypeList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
      schoolNameList: mCAXS371Response.schoolNameList,
      facultyGraduateNameList: mCAXS371Response.facultyGraduateNameList,
      departmentDepartmentNameList:
        mCAXS371Response.departmentDepartmentNameList,
      countryRegionList: mCAXS371Response.countryRegionList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      schoolGroupObj: mCAXS371Response.schoolGroupObj,
      schoolDetailsObj: mCAXS371Response.schoolDetailsObj,
      undergraduateDepartmentObj: mCAXS371Response.undergraduateDepartmentObj,
      studentClassificationObj: mCAXS371Response.studentClassificationObj,
      departmentDetailsObj: mCAXS371Response.departmentDetailsObj,
      isSchoolTabDisplay: true
    }

    yield put(setMcaxs371Init(mCAXS371Result))
  } catch (e) {
    const error = e as any
    yield put(openModal(error.message))
  }
}

export default function* advancedSearchSaga() {
  yield all([
    takeLatest(getMcaxs351Init, getMcaxs401InitSaga),
    takeLatest(setCurrentTabMcb, setCurrentTabMcbSaga),
    takeLatest(getMcaxs371Init, getMcaxs371InitSaga),
  ])
}
