import request from 'utils/request'

import { MCAZS190MessageQueryRequest } from 'types/MCAZS190/MCAZS190MessageQueryRequest'
import { MCAZS190MessageSelectRequest } from 'types/MCAZS190/MCAZS190MessageSelectRequest'

export const selectMcbMessageDraftRequest = (apiData: MCAZS190MessageQueryRequest) =>
request({
  url: '/MCAZS190/find',
  method: 'post',
  data: apiData,
})

export const initMcbRequest = (apiData: MCAZS190MessageQueryRequest) =>
request({
  url: '/MCAZS190/init',
  method: 'post',
  data: apiData,
})

export const deleteMcbMessageRequest = (apiData: MCAZS190MessageSelectRequest) =>
request({
  url: '/MCAZS190/deleteMessage',
  method: 'post',
  data: apiData,
})

export const getSelectionManagementApiMCAZS190 = (apiData: any) =>
  request({
  url:'/MCAZS190/showSendObjEntryList',
  method: 'post',
  data: apiData,
})

export const editMessageMCAZS190Request = (apiData: any) =>
request({
  url:'/MCAZS190/editMessage',
  method: 'post',
  data: apiData,
})