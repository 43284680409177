import { MCAXS361InitRequest } from 'types/MCAXS361/MCAXS361InitRequest'
import { MCAXS371SchoolInfoInitRequest } from 'types/MCAXS371/MCAXS371SchoolInfoInitRequest'
import { MCAXS391RecruitmentInfoInitRequest } from 'types/MCAXS391/MCAXS391RecruitmentInfoInitRequest'
import { MCAXS561QueryRequest } from 'types/MCAXS561/MCAXS561QueryRequest'

export const searchOptionList = [
  { label: 'OR', value: '0' },
  { label: 'AND', value: '1' },
]

export const searchCondition = {
  // 基本情報
  applicantId: [],
  name: [],
  sex: [
    {
      sexCode: '',
      sexName: '',
    },
  ],
  phoneNumber: '',
  mailAddress: '',
  residenceConditions: [
    {
      residenceCurrentPrefecturesCode: '',
      residenceCurrentPrefecturesName: '',
      residenceConnectorFlag: '',
      residenceConnectorName: '',
      residenceVacationPrefecturesCode: '',
      residenceVacationPrefecturesName: '',
    },
  ],

  languageSkills: '',
  eligibility: '',
  memberType: [
    {
      memberTypeCode: '',
      memberTypeName: '',
    },
  ],

  // 学校情報
  graduationPeriodId: {
    graduationPeriodCode: '',
    graduationPeriodName: '',
  },
  philosophyHumanities: {
    philosophyHumanitiesCode: '',
    philosophyHumanitiesName: '',
  },
  schoolType: {
    schoolTypeId: '',
    schoolTypeName: '',
  },
  schoolName: '',
  facultyGraduateName: '',
  departmentDepartmentName: '',
  schoolGroup: [
    {
      schoolGroupId: '',
      schoolGroupName: '',
    },
  ],
  schoolDetails: [
    {
      schoolDetailsId: '',
      schoolDetailsName: '',
    },
  ],
  undergraduateDepartmentConditions: {
    undergraduateDepartmentSchoolTypeId: '',
    undergraduateDepartmentSchoolTypeName: '',
    undergraduateDepartmentUniversityId: '',
    undergraduateDepartmentUniversityName: '',
    undergraduateDepartmentFacultyId: '',
    undergraduateDepartmentFacultyName: '',
    undergraduateDepartmentSubjectId: '',
    undergraduateDepartmentSubjectName: '',
  },
  studentClassificationObj: [
    {
      studentClassificationId: '',
      studentClassificationName: '',
    },
  ],
  departmentDetailsConditionsObj: {
    departmentId: '',
    departmentName: '',
  },
  countryRegion: {
    countryRegionCode: '',
    countryRegionName: '',
  },
}

/** 卒業時期情報 */
export interface GraduationPeriodObj {
  [key: string]: any
  graduationPeriodCode: string[]
  graduationPeriodName: string[]
}

/** 文理区分 */
export interface PhilosophyHumanitiesObj {
  [key: string]: any
  philosophyHumanitiesCode: string[]
  philosophyHumanitiesName: string[]
}

/** 学校種別 */
export interface SchoolTypeObj {
  [key: string]: any
  schoolTypeId: string[]
  schoolTypeName: string[]
}

/** 文理区分 */
export interface DepartmentDepartmentName {
  [key: string]: any
  schoolTypeId: string[]
  schoolTypeName: string[]
}

/** 文理区分 */
export interface SchoolGroup {
  [key: string]: any
  schoolGroupId: string[]
  schoolGroupName: string[]
}

/** 文理区分 */
export interface SchoolDetails {
  [key: string]: any
  schoolDetailsId: string[]
  schoolDetailsName: string[]
}

export interface UndergraduateDepartmentConditions {
  [key: string]: any
  undergraduateDepartmentSchoolTypeId: string[]
  undergraduateDepartmentSchoolTypeName: string[]
  undergraduateDepartmentUniversityId: string[]
  undergraduateDepartmentUniversityName: string[]
  undergraduateDepartmentFacultyId: string[]
  undergraduateDepartmentFacultyName: string[]
  undergraduateDepartmentSubjectId: string[]
  undergraduateDepartmentSubjectName: string[]
}
export interface StudentClassificationConditions {
  [key: string]: any
  studentClassificationConditionsId: string[]
  studentClassificationConditionsName: string[]
}
export interface DepartmentDetailsConditions {
  [key: string]: any
  dpartmentId: string[]
  dpartmentName: string[]
}

export interface CountryRegion {
  [key: string]: any
  countryRegionCode: string[]
  countryRegionName: string[]
}

export const schoolInfoInitRequest: MCAXS371SchoolInfoInitRequest = {
  // 学校グループID（学校グループ）
  schoolGroupId: null,

  // 学校詳細ID（学校グループ（学校詳細））
  schoolDetailsId: null,

  // 学校種別ID（学校グループ（学部・学科詳細））
  undergraduateDepartmentSchoolTypeId: null,

  // 大学ID（学校グループ（学部・学科詳細））
  undergraduateDepartmentUniversityId: null,

  // 学部・研究科ID（学校グループ（学部・学科詳細））
  undergraduateDepartmentFacultyId: null,

  // 学科・専攻ID（学校グループ（学部・学科詳細））
  undergraduateDepartmentSubjectId: null,

  // 学科区分ID（学生分類）
  studentClassificationId: null,
  
  // 学科ID（学生分類（学科詳細））
  departmentId: null
}

export const applicantInfoInitRequest: MCAXS361InitRequest = {
  
  // エントリーボックスID（エントリー日）
  entryDateEntryBoxId: null,

  // エントリーボックスID（エントリー有無）
  entryPresenceEntryBoxId: null,

  // エントリーボックスID（アンケート回答内容）
  questionnaireResponseEntryBoxId: null,

  // 設問番号（アンケート回答内容）
  questionnaireResponseQuestionNumber: null,

  // 回答選択肢ID（アンケート回答内容）
  questionnaireResponseAnswerCheckBoxId: null,

  // セミナーID（受付日程・予約状況）
  seminarReceptionIndividualSeminarId: null,

  // 公開フラグ（受付日程・予約状況）
  seminarReceptionIndividualPublicFlag: null,

  // セミナー日程ID（受付日程・予約状況）
  seminarReceptionIndividualSeminarScheduleId: null,

  // 非表示フラグ（受付日程・予約状況）
  seminarReceptionIndividualInvisibleFlag: null,

  // 連携元WebセミナーID（Webセミナー視聴状況）
  webSeminarCoordinationFormerWebSeminarId: null,

  // イベントID（イベント参加状況）
  eventsIndividualEventId: null,
}

export const recruitmentManagementInit: MCAXS391RecruitmentInfoInitRequest = {
  // 管理項目条件詳細（ID）
  recruitmentManagementFlagSettingId: null,

  // 管理項目条件詳細（詳細チェックボックスID）
  managementItemFlagCheckBoxId: null,
}

export const initeRequest: MCAXS561QueryRequest = {
  // 連携元職種識別キー
  coordinationFormerJobCategoryIdentificationKey: null
}
