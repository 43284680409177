import React, { useEffect } from 'react'
import history from 'utils/history'

import { useDispatch } from 'react-redux'
import { sendNetworkError, removeError } from 'reducers/errorReducer'
import { getErrorFromStorage } from 'utils/error';

const NetworkError = () => {
  const dispatch = useDispatch()
  const state: any = history.location.state

  useEffect(() => {
    dispatch(sendNetworkError(getErrorFromStorage()))
    dispatch(removeError())
  }, [])
 
  return (
    <div dangerouslySetInnerHTML={{__html: (state ? state.body : '')}} />
  );
};
export default NetworkError;