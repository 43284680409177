import { getMessage } from 'common/messageUtil'
import { MCBHS090InitRequest } from 'types/MCBHS090/MCBHS090InitRequest'

export interface InputValues {
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  sendTimeTransmission: string
}
const forcedStopInitRequest: MCBHS090InitRequest = {
  searchCriteriaName: '',
  searchCriteria: '',
  draftMessageSendID: '0',
  sysVersionNumber: '0',
  jobSeekerByCompanyIdSubmissionRequestIdList: [],
}
const targetTextAreas = {
  subject: 'subject',
  body: 'body',
}

const inputList = {
  senderCompanyName: {
    max: 200,
  },
  senderMailAddress: {
    max: 256,
  },
  subject: {
    max: 200,
  },
  body: {
    max: 2000,
  },
  homePageUrl: {
    max: 256,
  },
  homePageTitle: {
    max: 80,
  },
  notifyMailAddress: {
    max: 160,
  },
}
const submissionsDetailsList = [
  ['submissionEntrySheetFlag', 'エントリーシート'],
  ['submissionResumeFlag', '履歴書'],
  ['submissionResearchSummaryFlag', '研究概要書'],
  ['submissionOriginalQuestionFlag', 'オリジナル設問'],
  // MCBリプレイス #8675 START
  ['submissionFileSubmissionFlag', 'ファイル'],
  // MCBリプレイス #8675 END
]
const textMap = {
  confirm: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: '入力された内容で更新します。よろしいですか？',
  },
  cancel: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCBHS090-022'),
  },
  draft: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCBHS090-023'),
  },
  draftSaveComplete: {
    submit: 'OK',
    cancel: '',
    message: getMessage('MCBHS090-024'),
  },
}
export const conditionsTitleValue = {
  settingContentsSubmission: [
    '提出物内容名（企業管理用）',
    '提出物内容名（学生画面表示用）',
    '提出物',
  ],
}
export {
  forcedStopInitRequest,
  targetTextAreas,
  inputList,
  submissionsDetailsList,
  textMap,
}
