import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileSelectModalInfo } from 'pages/MCBHS040/DetailedSearch/Config/FileSelectModalConfig'

const initialState = {
  fileSelectModalInfo: {} as FileSelectModalInfo,
}

const submissionRequestDetailedSearchModalSlice = createSlice({
  name: 'submissionRequestDetailedSearchModal',
  initialState,
  reducers: {
    // 提出物ファイル指定モーダル情報を取得
    getMcbhs171Init(state, action: PayloadAction<() => void>) {
      return state
    },
    // 提出物ファイル指定モーダル情報を設定
    setMcbhs171Init(state, action: PayloadAction<FileSelectModalInfo>) {
      return {
        ...state,
        fileSelectModalInfo: action.payload,
      }
    },
    // 都道府県指定モーダル情報を取得
    // getMcaxs521Init(state) {
    //   return state
    // },
  },
})

export const {
  getMcbhs171Init,
  setMcbhs171Init,
  // getMcaxs521Init,
} = submissionRequestDetailedSearchModalSlice.actions
export default submissionRequestDetailedSearchModalSlice.reducer
