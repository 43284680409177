import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import blueBigLogo from 'images/miws_on-blue_shinsotsu_161x44.jpg'
import redBigLogo from 'images/miws_on-red_chuuto_161x44.jpg'
import { Tooltip } from '@material-ui/core'
import { openEntryManageTab } from 'common/generalUtil'

const useStyles = makeStyles({
  noBorder: {
    borderLeft: 'none',
  },
  topBar: {
    minWidth: '960px',
  },
  companyStyle: {
    fontSize: '1.2rem!important',
    textAlign: 'right',
    lineHeight: '1.2!important',
    wordBreak: 'break-all',
  },
})

interface Props {
  jobChange?: boolean
  loginUserName: string
  companyName: string
  companyId: string
  className?: string
}

const Header = ({ 
  jobChange, 
  loginUserName, 
  companyName,
  companyId,
  className, 
}: Props) => {
  const classes = useStyles()

  const max = (companyName: String | null) => {
    if (companyName !== null && companyName.length > 50) {
      return companyName.slice(0, 50) + "..."
    }
  }

  const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#000000',
      maxWidth: 420,
      fontSize: 11.5,
      fontWeight: 200,
      fontFamily: 'initial',
      border: '1px solid #000000',
      borderRadius: '0px',
      wordBreak: 'break-all',
      wordWrap: 'break-word',
    },
  }))(Tooltip);

  return (
    <header className={`${className} ${classes.topBar}`}>
      {/* #51347 次期開発2022年9月 start */}
      {jobChange ? <img src={redBigLogo} style={{ cursor: 'pointer' }} onClick={openEntryManageTab} /> : <img src={blueBigLogo} />}
      {/* #51347 次期開発2022年9月 end */}
      <div>
        <b className={`classes.noBorder classes.companyStyle`}>
          {/* 次期12月対応 #65650 start */}
          {companyName.length > 50 ? (
            <HtmlTooltip
              title={
                <React.Fragment>
                  {'MIWSID '}{companyId}：{companyName} / {loginUserName}
                </React.Fragment>
              }>
              <span>
                {'MIWSID '}{`${companyId}：`}{max(companyName)} {`/ ${loginUserName}`}
              </span>
            </HtmlTooltip>
          ) : (
            `MIWSID ${companyId}：${companyName} / ${loginUserName}`
          )}
          {/* 次期12月対応 #65650 end */}
        </b>
      </div>
    </header>
  )
}

export default Header
