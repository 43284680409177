import { seminaSearch } from 'pages/MCAVS010/formConfig'
import { MCAXS010SearchRequest } from 'types/MCAXS010/MCAXS010SearchRequest'
import { MCAXS010UpdateJudgmentRequest } from 'types/MCAXS010/MCAXS010UpdateJudgmentRequest'
import {
  SeminarReceptionObj,
  EventsObj,
  initSearchCondition,
  SearchCondition,
} from './searchConditionConfig'

export interface SpecificConditionInfo {
  specificConditionName: string //特定条件名
  specificConditionSelectionManagementIdList: string[] //特定条件選考管理IDリスト
}

export interface ApplicantsInfo {
  jobSeekerId: string //求職者ID
  entryId: string //エントリーID
  selectionManagementId: string //選考管理ID
}

export interface ApplicantDetailsRequest {
  applicantsListInfo: ApplicantsInfo[] //応募者情報
  listIndex: number //表示対象位置
}

/** My検索設定情報 */
export interface MySearch {
  entrySearchCriteriaSettingId: string //エントリー検索条件設定ID
  searchCriteria: string //検索条件
  searchCriteriaName: string //検索条件名
  searchCriteriaDisplay: string //検索条件(表示用)
}

/** フィルタ入力初期値 */
export const initialValues = {
  nameForm: '',
}

/** 画面表示情報 */
export interface DisplayInfo {
  applicantsListInfo: ApplicantsListInfo[] //応募一覧リスト情報
}

export interface FilterDisplay {
  filterDisplaySelenctionFlowInfo: FilterDisplaySelenctionFlowInfo[]
  filterDisplayProgressInfoAllCount: number
  filterDisplayProgressInfo: FilterDisplayProgressInfo[]
  filterDisplayDecisionInfo: FilterDisplayDecisionInfo[]
  filterDisplayTagInfo: FilterTagInfo[]
  filterDisplayCommonlyTagInfo: FilterTagInfo[]
}

export interface FilterDisplaySelenctionFlowInfo {
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
}

export interface FilterDisplayProgressInfo {
  progressStatusSettingId: string //選考ステップ設定ID
  progressName: string //進捗名
  progressType: string // 進捗区分
  count: number // 件数
}

export interface FilterDisplayDecisionInfo {
  decisionDivision: string //判定区分種別
  decisionName: string //判定名
}

export interface FilterTagInfo {
  tagSettingId: string //タグ設定ID
  tagName: string //タグ名称
}

export interface FilterCondition {
  nameForm: string
  selectionFlowSelect: Option
  progressStatusSelect: Option
  progressStatusSelectDecisionDivisionType: string
  progressStatusSelectProgressType: string
  judgmentStatusSelect: Option
  tagSelect: Option[]
}

export interface Option {
  label: string
  value: string
}

export const initOption = {
  label: '',
  value: '',
}

export interface SortCondition {
  order: string
}

/**表示順情報 */
export interface DisplayOrderInfo {
  orderNo: number //順番
  pageNo: number //ページ番号
  jobSeekerId: string //求職者ID
  selectionManagementId: string //選考管理ID
  jobSeekerIdForDisplay: string //表示用求職者ID
  nameKana: string //カナ氏名
  collegeMasterDisplayOrder: number //大学マスタ表示順
  entryReceptionTime: Date //エントリー受付日時
  dataGetFlag: string //データ取得フラグ
}

/** 検索結果　(検索リクエストに対するレスポンス内容) */
export interface SearchResult {
  flowMasterInfo: FlowMasterInfo[] //選考フロー設定関連情報
  tagMasterInfo: TagMasterInfo[] //タグ設定情報
  applicantListItemInfo: ApplicantListItemInfo[] //応募者一覧項目情報
  mediaMasterInfo: MediaMasterInfo[] //応募経路設定情報
  applicantsListInfo: ApplicantsListInfo[] //応募一覧リスト情報
  applicantListMediaInfo: ApplicantListMediaInfo[] //応募一覧応募経路情報
  applicantListTagsInfo: ApplicantListTagsInfo[] //応募一覧タグ情報
  seminarScheduleInfo: SeminarReceptionObj | null //セミナー情報
  eventInfo: EventsObj | null //イベント情報
  searchCondition: string | null //検索条件JSON
  searchConditionDisplay: string //検索条件(表示用)
  searchCriteriaName: string | null //検索条件名
  displayInfo: Data[] //画面表示情報
  totalCount: number // 検索結果件数
  displayLimitOptionInfo: DisplayLimitOptionInfo[] //検索上限設定選択肢情報
  unlimitedTotalCount: number // 表示上限を考慮しない結果件数
  isManagementOffice: boolean //運営事務局判定
  errorCode: string | null //エラーコード
}

/** 選考フロー設定関連情報 */
export interface FlowMasterInfo {
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
  displayOrder: number //表示順
  progressType: string //進捗種別
  progressName: string //進捗名
  decisionDivisionType: string //判定区分種別
  progressStatusSettingId: string //選考ステップ設定ID
}

/** タグ設定情報 */
export interface TagMasterInfo {
  tagSettingId: string //タグ設定ID
  tagName: string //タグ名称
  tagCount: number //タグ設定ID件数
}

/** 応募経路設定情報 */
export interface MediaMasterInfo {
  mediaId: string //応募経路設定フラグ
  contractMediaId: string //契約応募経路ID
  mediaSettingId: string //応募経路設定ID
  mediaName: string //応募経路名称
}

/**応募一覧応募経路情報 */
export interface ApplicantListMediaInfo {
  selectionManagementId: string //選考管理ID
  mediaId: string //応募経路設定フラグ [0]契約応募経路 [1]応募経路設定
  contractMediaId: string //契約応募経路ID
  mediaSettingId: string //応募経路設定ID
}

/**応募一覧タグ情報 */
export interface ApplicantListTagsInfo {
  selectionManagementId: string //選考管理ID
  tagSettingId: string //タグ設定ID
}

/** 合否ボタン押下レスポンス */
export interface UpdateJudgemtnResult {
  selectionManagementId: string
  progressStatusSettingId: string
  decisionDivision: string
  sysVersionNumber1: number
  sysVersionNumber2: number
}

/** 一覧画面遷移元画面からの入力情報 */
export interface ScreenTransitionInput {
  screenId: string // 呼出し側機能ID
  bulkManipulationId: string //一括操作識別ID
  specificCriteriaName: string //特定条件名
  specificSelectionManagementIdList: string[] //特定条件選考管理IDリスト
  listId: string // 表示一覧ID
  searchType: string // 条件種別
  progressStatusSettingId: string //選考ステップ設定ID
  selectionFlowSettingId: string //選考フロー設定ID
  contractMediaId: string //契約応募経路ID
  contractMediaName: string //契約応募経路名
  mediaSettingId: string //応募経路設定ID
  seminarScheduleId: string //セミナー日程ID
  eventId: string //イベントID
  // TODO #58811 start
  entryDateFrom: string
  entryDateTo: string
  // TODO #58811 end
  entrySearchCriteriaSettingId: string //エントリー検索条件設定ID
  searchCondition: any // 検索条件JSON
  seminarIdList: seminaSearch[] // 採用状況レポート画面から選択したセミナー日程ID
  applicantIdList: string[]
  anchorLinkId: string //アンカーリンクID
}

/** 選考フロー設定関連情報 */
export interface FlowMasterInfo {
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
  displayOrder: number //表示順
  progressType: string //進捗種別
  progressName: string //進捗名
  decisionDivisionType: string //判定区分種別
  progressStatusSettingId: string //選考ステップ設定ID
}

/** タグ設定情報 */
export interface TagMasterInfo {
  tagSettingId: string //タグ設定ID
  tagName: string //タグ名称
  tagCount: number //タグ設定ID件数
}

/** 応募者一覧項目情報 */
export interface ApplicantListItemInfo {
  [key: string]: string | any
  getTableId: string //取得テーブルID
  displayOrder: number //表示順
  itemName: string //項目名
  applicantListDisplayItemId: string //応募者一覧表示項目ID
}

/** 応募経路設定情報 */
export interface MediaMasterInfo {
  mediaId: string //応募経路設定フラグ
  contractMediaId: string //契約応募経路ID
  mediaSettingId: string //応募経路設定ID
  mediaName: string //応募経路名称
}

/**応募一覧リスト情報 */
export interface ApplicantsListInfo {
  [key: string]: string | any
  choiceListItem: string //選択
  jobSeekerId: string //求職者ID
  entryId: string //エントリーID
  selectionManagementId: string //選考管理ID
  progressStatusSettingId: string //選考ステップ設定ID
  nonTargetFlag: string //対象外フラグ
  jobSeekerIdForDisplay: string //表示用求職者ID
  selectionFlowSettingId: string //選考フロー設定ID
  prefecturesCode1: string //都道府県コード_自宅
  prefecturesCode2: string //都道府県コード_休暇中
  entryReceptionTime: Date //エントリー受付日時
  manualRegistrationFlag: string //手動登録フラグ
  collegeMasterDisplayOrder: number //大学マスタ表示順
  specificTargetFlag: string //特定条件対象フラグ
  specificTargetDataGetFlag: string //特定条件データ取得フラグ
  sysVersionNumber1: number //sysバージョン番号_選考管理
  sysUpdateTime: Date //sys更新日時
  sysVersionNumber2: number //sysバージョン番号_選考ステップ管理
  familyName: string //姓
  name: string //名
  schoolName: string //学校名
  facultyName: string //学部名
  subjectName: string //学科名
  humanitiesAndSciencesDivision: string //文理区分
  unreadMessagesNum: number //未読メッセージ件数
  selectionName: string //選考名
  starEvaluation: string //星評価
  decisionDivision: string //判定区分
  familyNameKana: string //姓カナ
  nameKana: string //名カナ
  mailAddress: string //メールアドレス
  sex: string //性別
  graduationPlanTimeName: string //卒業予定時期名称
  schoolGroupName: string //学校グループ名称
  phoneNumber1: string //電話番号_自宅
  phoneNumber2: string //電話番号_休暇中
  phoneNumber3: string //電話番号_携帯
  humanitiesAndSciencesDivisionString: string //文理区分表示文字
  prefecturesCode1String: string //都道府県コード_自宅表示文字
  prefecturesCode2String: string //都道府県コード_休暇中表示文字
  sexString: string //性別表示文字
  jobSeekerIdUnsubscribe: string //求職者ID退会
  schoolTypeName: string //学校種別
  currentAddressAfterCity: string //現住所市町村以降の住所
  vacationAddressAfterCity: string //休暇中市町村以降の住所
}

/**応募一覧応募経路情報 */
export interface ApplicantListMediaInfo {
  selectionManagementId: string //選考管理ID
  mediaId: string //応募経路設定フラグ [0]契約応募経路 [1]応募経路設定
  contractMediaId: string //契約応募経路ID
  mediaSettingId: string //応募経路設定ID
}

/**応募一覧タグ情報 */
export interface ApplicantListTagsInfo {
  selectionManagementId: string //選考管理ID
  tagSettingId: string //タグ設定ID
}

/** 合否ボタン押下レスポンス */
export interface UpdateJudgemtnResult {
  selectionManagementId: string
  progressStatusSettingId: string
  decisionDivision: string
  sysVersionNumber1: number
  sysVersionNumber2: number
}

/** 検索に使用された検索条件 */
export interface SelectedSearch {
  searchCondition: SearchCondition //検索条件
  searchConditionDisplay: string //検索条件（表示用）
}

export const initScreenTransitionInput: ScreenTransitionInput = {
  screenId: '', // 呼出し側機能ID
  bulkManipulationId: '', //一括操作識別ID
  specificCriteriaName: '', //特定条件名
  specificSelectionManagementIdList: [], //特定条件選考管理IDリスト
  listId: '0', //表示一覧ID
  searchType: '', // 条件種別
  progressStatusSettingId: '', //選考ステップ設定ID
  selectionFlowSettingId: '', //選考フロー設定ID
  contractMediaId: '', //契約応募経路ID
  contractMediaName: '', //契約応募経路名
  mediaSettingId: '', //応募経路設定ID
  seminarScheduleId: '', //セミナー日程ID
  eventId: '', //イベントID
  // TODO #58811 start
  entryDateFrom: '',
  entryDateTo: '',
  // TODO #58811 end
  entrySearchCriteriaSettingId: '', //エントリー検索条件設定ID
  searchCondition: { ...initSearchCondition }, // 検索条件JSON
  seminarIdList: [], // 採用状況レポート画面から選択したセミナー日程ID
  applicantIdList: [],
  anchorLinkId: '', // アンカーリンクID
}

export const initeSearchRequest: MCAXS010SearchRequest = {
  listId: '',
  searchCondition: '',
  sourceFunctionId: '',
  conditionType: '',
  bulkManipulationId: '',
  selectionManagementIdList: [],
  entrySearchCriteriaSettingId: null,
  searchCount: 1,
  displayLimit: null,
  sortKey: '0',
  senniID: '',
  applicantIdList: [],
  mySearchCondition: null,
  searchedSelectionManagementId: [],
}

export const initMySearch: MySearch = {
  entrySearchCriteriaSettingId: '',
  searchCriteria: '',
  searchCriteriaName: '',
  searchCriteriaDisplay: '',
}

export const initUpdateJudgmentRequest: MCAXS010UpdateJudgmentRequest = {
  jobSeekerId: '',
  entryId: '',
  progressStatusSettingId: '',
  selectionManagementId: '',
  decisionDivision: '',
  sysVersionNumber1: 0,
  sysVersionNumber2: 0,
  flowMasterInfo: [],
}

export const initSearchResult: SearchResult = {
  flowMasterInfo: [], //選考フロー設定関連情報
  tagMasterInfo: [], //タグ設定情報
  applicantListItemInfo: [], //応募者一覧項目情報
  mediaMasterInfo: [], //応募経路設定情報
  applicantsListInfo: [], //応募一覧リスト情報
  applicantListMediaInfo: [], //応募一覧応募経路情報
  applicantListTagsInfo: [], //応募一覧タグ情報
  seminarScheduleInfo: null, //セミナー情報
  eventInfo: null, //イベント情報
  searchCondition: null, //検索条件JSON
  searchConditionDisplay: '', //検索条件(表示用)
  searchCriteriaName: null, //検索条件名
  displayInfo: [], //画面表示情報
  totalCount: 0, //検索結果件数
  displayLimitOptionInfo: [], //検索上限設定選択肢情報
  unlimitedTotalCount: 0, // 表示上限を考慮しない結果件数
  isManagementOffice: false, //運営事務局判定
  errorCode: null, //エラーコード
}

export const initFilterDisplay: FilterDisplay = {
  filterDisplaySelenctionFlowInfo: [],
  filterDisplayProgressInfoAllCount: 0,
  filterDisplayProgressInfo: [],
  filterDisplayDecisionInfo: [],
  filterDisplayTagInfo: [],
  filterDisplayCommonlyTagInfo: [],
}

export const initFilterCondition: FilterCondition = {
  nameForm: '',
  selectionFlowSelect: initOption,
  progressStatusSelect: initOption,
  progressStatusSelectDecisionDivisionType: '',
  progressStatusSelectProgressType: '',
  judgmentStatusSelect: initOption,
  tagSelect: [],
}

export const initSortCondition: SortCondition = {
  order: '0',
}

export const initApplicantDetailsRequest: ApplicantDetailsRequest = {
  applicantsListInfo: [],
  listIndex: 0,
}

export const applicantInitValues = {
  entrySearchCriteriaSettingId: '',
  searchCriteria: '',
  searchCriteriaName: '',
}

export interface IdObject {
  name: string
  jobSeekerIdForDisplay: string
  jobSeekerId: string
}
export interface SchoolObject {
  school: string
  faculty: string
  department: string
}
export interface ResultObject {
  selectionName: string
  selectionFlowSettingId: string
  rate: string
  progressName: string
  progressType: string
  progressStatusSettingId: string
  decisionDivisionType: string
  result: string
  notApplicable: boolean
  decisionChangeToolTipOpen: boolean //判定区分変更時の吹き出し表示の有無
}
export interface ExperienceObject {
  jobSeekerId: string
  jobSeekerIdForDisplay: string
  entryId: string
  selectionManagementId: string
  media: MediaObject[]
  result: ResultObject
  specificTargetFlag: string
}

export interface MediaObject {
  mediaSettingId: string
  mediaName: string
}

export interface Data {
  specificTargetDataGetFlag: string
  id: IdObject
  school: SchoolObject
  background: string //文理区分
  address: string
  unread: number
  experience: ExperienceObject[]
  nameKana: string //カナ姓名
  manualRegistrationFlag: string //手動登録フラグ
  unsubscribeFlag: string //退会フラグ
  mailAddress: string //メールアドレス
  sex: string //性別
  graduation: string //卒年
  schoolGroup: string //学校グループ
  homePhone: string //現住所電話番号
  mobilePhone: string //携帯番号
  vacationAddress: string //休暇中都道府県
  vacationPhone: string //休暇中電話番号
  tagList: Tag[] //タグリスト
  collegeMasterDisplayOrder: number //大学マスタ表示順
  nameForFilter: string //ソート用姓名
  nameHiraganaForFilter: string //ソート用ひらがな姓名
  nameKatakanaForFilter: string //ソート用カタカナ姓名
  entryReceptionTimeForSort: number //ソート用エントリー受付日時
  schoolTypeName: string //学校種別
  currentAddressAfterCity: string //現住所市町村以降の住所
  vacationAddressAfterCity: string //休暇中市町村以降の住所
}

export const initId: IdObject = {
  name: '',
  jobSeekerIdForDisplay: '',
  jobSeekerId: '',
}

export const initSchool: SchoolObject = {
  school: '',
  faculty: '',
  department: '',
}
export interface Tag {
  tagSettingId: string //タグ設定ID
  tagName: string //タグ名称
}

export const SCREEN_ID = 'MCAXS351'

export interface DisplayLimitOptionInfo {
  label: string
  value: string
  selected: string
}
export interface JobSelectionIdList {
  jobSeekerId: string //応募者ID
  selectionFlowSettingId: string //選考フローID
}

export interface MultipleMediaError {
  message: string
  contractMediaInfo: ContractMediaInfo[]
}

export interface ContractMediaInfo {
  contractMediaId: string
  contractMediaName: string
}

export const initSelectedSearch = {
  searchCondition: initSearchCondition,
  searchConditionDisplay: '',
}

export interface MultiMySearchState {
  mySearchCondition: number | null
  multiMySearchInfo: MySearch[]
  searchedSelectionManagementId: string[]
}

export const initMultiMySearchState: MultiMySearchState = {
  mySearchCondition: null,
  multiMySearchInfo: [],
  searchedSelectionManagementId: [],
}

export interface MultiMySearchLoadingInfo {
  open: boolean
  numerator: number
  denominator: number
}

export const initMultiMySearchLoadingInfo: MultiMySearchLoadingInfo = {
  open: false,
  numerator: 0,
  denominator: 0
}

export interface MCAXS010SearchAcynchronousResult {
  searchedSelectionManagementId: string[]
}

export interface MCAXS010PushCallSearchConditionResult {
  pushCallSearchConditionInfo : MySearch[]
}