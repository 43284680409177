import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ScreenTransitionInput,
  FilterDisplay,
  FilterDisplaySelenctionFlowInfo,
  FilterDisplayProgressInfo,
  FilterDisplayDecisionInfo,
  FilterTagInfo,
  FilterCondition,
  Option,
  SortCondition,
  FlowMasterInfo,
  ApplicantsListInfo,
  SearchResult,
  UpdateJudgemtnResult,
  MySearch,
  initScreenTransitionInput,
  initeSearchRequest,
  initMySearch,
  initUpdateJudgmentRequest,
  initFilterDisplay,
  initFilterCondition,
  initSortCondition,
  initSearchResult,
  initOption,
  Data,
  ResultObject,
  MultipleMediaError,
  ContractMediaInfo,
  SelectedSearch,
  initSelectedSearch,
  MultiMySearchState,
  MultiMySearchLoadingInfo,
  initMultiMySearchState,
  initMultiMySearchLoadingInfo,
} from 'pages/MCAXS010/formConfig'

import { MCAXS010SearchRequest } from 'types/MCAXS010/MCAXS010SearchRequest'
import { MCAXS010GetMySearchRequest } from 'types/MCAXS010/MCAXS010GetMySearchRequest'
import { MCAXS010UpdateJudgmentRequest } from 'types/MCAXS010/MCAXS010UpdateJudgmentRequest'
import { MCAXS010GetContractMediaInfoRequest } from 'types/MCAXS010/MCAXS010GetContractMediaInfoRequest'
import { MCAXS030OrderRequest } from 'types/MCAXS030/MCAXS030OrderRequest'
import {
  SearchCondition,
  initSearchCondition,
} from 'pages/MCAXS010/searchConditionConfig'
import { deepEqual } from 'utils/misc'
import { SelectionFlowInitData, Status } from './selectionStatusUpdateReducer'
import { magiContants } from 'utils/contants'
import { initialSelectionFlowInitData } from 'pages/MCAYS030/formConfig'
import { getMessage } from 'common/messageUtil'

const initialState: {
  screenTransitionInput: ScreenTransitionInput //一覧画面遷移時の入力情報
  searchRequest: MCAXS010SearchRequest //検索リクエスト
  mySearch: MySearch //My検索設定情報
  multiMySearchState: MultiMySearchState //My検索×My検索設定情報
  updateJudgmentRequest: MCAXS010UpdateJudgmentRequest
  filterDisplay: FilterDisplay //フィルター表示情報
  filterCondition: FilterCondition //フィルター条件
  sortCondition: SortCondition //ソート条件
  page: number // 表示ページ番号
  searchResult: SearchResult // 検索結果
  data: Data[] //一覧表示用に整形した検索結果
  searchCondition: any
  selectedSearch: SelectedSearch
  selectedAccounts: string[] //チェックボックス選択状態
  orderRequest: MCAXS030OrderRequest
  openFlag: boolean
  refreshFlag: number
  loadingFlag: boolean
  hasNewData: boolean
  searchCount: number
  totalCount: number
  needUpdate: boolean
  displayLimit: Option
  selectionFlowInitData1: SelectionFlowInitData
  specificTargetFirstDisplayInfo: Data[]
  multipleMediaError: MultipleMediaError
  circleLoadingFlag: boolean
  displaySearchCriteriaTitleOpenFlag: boolean
  multiMySearchLoadingInfo: MultiMySearchLoadingInfo
} = {
  screenTransitionInput: initScreenTransitionInput, //一覧画面遷移時の入力情報
  searchRequest: initeSearchRequest, //検索リクエスト
  mySearch: initMySearch, //My検索設定情報
  multiMySearchState: initMultiMySearchState, //My検索×My検索設定情報
  updateJudgmentRequest: initUpdateJudgmentRequest,
  filterDisplay: initFilterDisplay, //フィルター表示情報
  filterCondition: initFilterCondition, //フィルター条件
  sortCondition: initSortCondition, //ソート条件
  page: 0, // 表示ページ番号
  searchResult: initSearchResult, // 検索結果
  data: [], //一覧表示用に整形した検索結果
  selectedAccounts: [], //チェックボックス選択状態
  orderRequest: {
    invisibleItemInfo: [],
    displayItemInfo: [],
  },
  openFlag: false,
  searchCondition: initSearchCondition,
  selectedSearch: initSelectedSearch,
  refreshFlag: 0,
  loadingFlag: false,
  hasNewData: false,
  searchCount: 1,
  totalCount: 0,
  needUpdate: false,
  displayLimit: { label: '', value: '' },
  selectionFlowInitData1: initialSelectionFlowInitData,
  specificTargetFirstDisplayInfo: [],
  multipleMediaError: {
    message: '',
    contractMediaInfo: [],
  },
  circleLoadingFlag: false,
  displaySearchCriteriaTitleOpenFlag: false,
  multiMySearchLoadingInfo: initMultiMySearchLoadingInfo,
}

const applicantListSlice = createSlice({
  name: 'applicantList',
  initialState,
  reducers: {
    updateScreenTransitionInput(
      state,
      action: PayloadAction<ScreenTransitionInput>
    ) {
      state.screenTransitionInput = action.payload
      return state
    },
    updateSearchRequest(state, action: PayloadAction<MCAXS010SearchRequest>) {
      state.searchRequest = action.payload
      return state
    },
    search(
      state,
      action: PayloadAction<{
        request: MCAXS010SearchRequest
        isInitFilter: boolean
        mySearch?: MySearch
        multiMySearch?: MultiMySearchState
        onSearch?: () => void
      }>
    ) {
      const request = {
        ...action.payload.request,
        entrySearchCriteriaSettingId: null
      }
      state.searchRequest = request
      return state
    },
    setSearchResult(state, action: PayloadAction<SearchResult>) {
      state.searchResult = action.payload
      return state
    },
    resetData(state) {
      state.data = []
      return state
    },
    setInitDisplay(
      state,
      action: PayloadAction<{
        isInitFilter: boolean
        searchResult: SearchResult
      }>
    ) {
      const searchResult = action.payload.searchResult
      if (!action.payload.isInitFilter) {
        //フィルタ欄の選考ステップ数を更新
        const selectSelectionFlowSettingId =
          state.filterCondition.selectionFlowSelect.value
        if (selectSelectionFlowSettingId !== '') {
          const applicantsListInfo = searchResult.applicantsListInfo
          const flowMasterInfoArray = searchResult.flowMasterInfo.filter(
            info => info.selectionFlowSettingId === selectSelectionFlowSettingId
          )
          const newFilterDisplay: FilterDisplay = {
            ...state.filterDisplay,
            filterDisplayProgressInfoAllCount: applicantsListInfo.filter(
              info =>
                info.selectionFlowSettingId === selectSelectionFlowSettingId
            ).length,
            filterDisplayProgressInfo: createFilterDisplayProgressInfoArray(
              selectSelectionFlowSettingId,
              state.screenTransitionInput.listId,
              JSON.parse(state.searchRequest.searchCondition),
              flowMasterInfoArray,
              applicantsListInfo
            ),
          }
          state.filterDisplay = newFilterDisplay
        }
      }

      const specificConditionSelectionManagementIdList =
        state.screenTransitionInput.specificSelectionManagementIdList
      const filterCondition = state.filterCondition
      const sortCondition = state.sortCondition

      const filteredDisplayInfoList = filterDisplayInfoList(
        searchResult.displayInfo,
        filterCondition,
        specificConditionSelectionManagementIdList
      )

      const sortedFilteredDisplayInfoList = createDisplayOrderInfoArray(
        filteredDisplayInfoList,
        sortCondition
      )

      switch (state.searchRequest.sourceFunctionId) {
        case 'MCAVS010':
          const newSearchCondition = JSON.parse(
            state.searchRequest.searchCondition
          )
          switch (state.searchRequest.conditionType) {
            case '3':
            case '4':
            case '5':
              newSearchCondition.seminarReceptionObj =
                searchResult.seminarScheduleInfo
              state.searchCondition = newSearchCondition
              break
            case '6':
              newSearchCondition.eventsObj = searchResult.eventInfo
              state.searchCondition = newSearchCondition
              break
          }
          break
        case 'MCARS010':
          state.searchCondition =
            searchResult.searchCondition !== null
              ? JSON.parse(searchResult.searchCondition)
              : null

          state.mySearch = {
            entrySearchCriteriaSettingId:
              state.screenTransitionInput.entrySearchCriteriaSettingId,
            searchCriteriaName:
              searchResult.searchCriteriaName !== null
                ? searchResult.searchCriteriaName
                : '',
            searchCriteria:
              searchResult.searchCondition !== null
                ? searchResult.searchCondition
                : '',
             searchCriteriaDisplay: searchResult.searchConditionDisplay,
          }
          break
        default:
          break
      }

      state.data = sortedFilteredDisplayInfoList
      state.page = 0
      state.selectedAccounts = []

      if (
        deepEqual(initFilterCondition, state.filterCondition) &&
        state.screenTransitionInput.specificSelectionManagementIdList.length === 0
      ) {
        state.searchResult.totalCount = state.totalCount
      } else {
        state.searchResult.totalCount = sortedFilteredDisplayInfoList.length
      }

      return state
    },
    updateFilterCondition(
      state, 
      action: PayloadAction<{
        filterCondition: FilterCondition
        searchResult: SearchResult
      }>) {
      let displayInfo = action.payload.searchResult.displayInfo
      if(state.screenTransitionInput.specificSelectionManagementIdList.length > 0){
        displayInfo = state.specificTargetFirstDisplayInfo
      }
      if (state.hasNewData) {
        const {
          specificSelectionManagementIdList,
        } = state.screenTransitionInput
        const { sortCondition, filterCondition } = state

        const filteredDisplayInfoList = filterDisplayInfoList(
          displayInfo,
          filterCondition,
          specificSelectionManagementIdList
        )

        state.data = createDisplayOrderInfoArray(
          filteredDisplayInfoList,
          sortCondition
        )
        state.hasNewData = false
      }

      if (action.payload.searchResult.displayInfo.length < state.totalCount) {
        state.needUpdate = true
        state.loadingFlag = true
      }

      const filterCondition = action.payload.filterCondition
      const specificConditionSelectionManagementIdList =
        state.screenTransitionInput.specificSelectionManagementIdList
      const sortCondition = state.sortCondition

      const filteredDisplayInfoList = filterDisplayInfoList(
        displayInfo,
        filterCondition,
        specificConditionSelectionManagementIdList
      )

      const sortedFilteredDisplayInfoList = createDisplayOrderInfoArray(
        filteredDisplayInfoList,
        sortCondition
      )

      state.data = sortedFilteredDisplayInfoList

      if (
        deepEqual(filterCondition, initFilterCondition) &&
        state.screenTransitionInput.specificSelectionManagementIdList.length === 0
      ) {
        let cantReferenceInfoCount = 0
        displayInfo.forEach(info => {
          if(info.specificTargetDataGetFlag !== '0'){
            cantReferenceInfoCount++
          }
        })
        state.searchResult.totalCount = state.totalCount - cantReferenceInfoCount
      } else {
        state.searchResult.totalCount = sortedFilteredDisplayInfoList.length
      }

      state.filterCondition = filterCondition
      state.page = 0
      state.selectedAccounts = []

      return state
    },
    updateSelectionSelect(state, action: PayloadAction<Option>) {
      const selectSelectionFlowSettingId = action.payload.value

      const applicantsListInfo = state.searchResult.applicantsListInfo

      const flowMasterInfoArray = state.searchResult.flowMasterInfo.filter(
        info => info.selectionFlowSettingId === selectSelectionFlowSettingId
      )

      state.filterDisplay.filterDisplayProgressInfoAllCount = applicantsListInfo.filter(
        info => info.selectionFlowSettingId === selectSelectionFlowSettingId
      ).length
      state.filterDisplay.filterDisplayProgressInfo = createFilterDisplayProgressInfoArray(
        selectSelectionFlowSettingId,
        state.screenTransitionInput.listId,
        JSON.parse(state.searchRequest.searchCondition),
        flowMasterInfoArray,
        applicantsListInfo
      )
      state.filterCondition.selectionFlowSelect = action.payload

      return state
    },
    updateProgressFilter(state, action: PayloadAction<Option>) {
      const progressStatusSelect = action.payload.value
      const selectionFlowSelect = state.filterCondition.selectionFlowSelect

      const flowMasterInfoArray = state.searchResult.flowMasterInfo

      const flowMasterInfo = flowMasterInfoArray
        .find(
          info =>
            info.selectionFlowSettingId === selectionFlowSelect.value &&
            info.progressStatusSettingId === progressStatusSelect
        )
      if (flowMasterInfo === undefined) {
        return state
      }

      let filterDisplayDecisionInfoArray: FilterDisplayDecisionInfo[] = []

      const decisionDivisionType = flowMasterInfo.decisionDivisionType

      switch (decisionDivisionType) {
        case '0':
        case '2':
          //[0]合否（面接）,[2]合否（書類選考）
          filterDisplayDecisionInfoArray = [middle, pass, fail]
          break
        case '1':
          //[1]参加不参加
          filterDisplayDecisionInfoArray = [participate, notParticipate, other]
          break
        default:
          filterDisplayDecisionInfoArray = []
          break
      }

      state.filterDisplay.filterDisplayDecisionInfo = filterDisplayDecisionInfoArray

      return state
    },
    updateTagFilter(state, action: PayloadAction<Option[]>) {
      //タグを指定するモーダル側でこのreducerが呼ばれる想定
      const tagMasterInfoArray = state.searchResult.tagMasterInfo

      const newFilterDisplayTagInfo: FilterTagInfo[] = []

      const tagSettingIds: string[] = action.payload.map(tag => tag.value)

      tagSettingIds.forEach(tagSettingId => {
        const tagMasterInfo = tagMasterInfoArray
          .find(info => info.tagSettingId === tagSettingId)
        if (tagMasterInfo !== undefined) {
          const tag: FilterTagInfo = {
            tagSettingId: tagMasterInfo.tagSettingId,
            tagName: tagMasterInfo.tagName,
          }
          newFilterDisplayTagInfo.push(tag)
        }
      })

      state.filterDisplay.filterDisplayTagInfo = newFilterDisplayTagInfo
      return state
    },
    updateSortCondition(
      state, 
      action: PayloadAction<{
        order: string
        searchResult: SearchResult
      }>) {
      const { totalCount } = state
      const searchResult = action.payload.searchResult

      if (searchResult.displayInfo.length < totalCount) {
        state.needUpdate = true
      }

      state.sortCondition.order = action.payload.order

      const specificConditionSelectionManagementIdList =
        state.screenTransitionInput.specificSelectionManagementIdList

      const filterCondition = state.filterCondition
      const order = action.payload.order
      const sortCondition = state.sortCondition
      sortCondition.order = order

      const filteredDisplayInfoList = filterDisplayInfoList(
        searchResult.displayInfo,
        filterCondition,
        specificConditionSelectionManagementIdList
      )

      const sortedFilteredDisplayInfoList = createDisplayOrderInfoArray(
        filteredDisplayInfoList,
        sortCondition
      )

      state.data = sortedFilteredDisplayInfoList
      state.sortCondition.order = order
      state.selectedAccounts = []
      return state
    },
    updatePage(
      state, 
      action: PayloadAction<{
        page: number
        searchResult: SearchResult
      }>) {
      state.page = action.payload.page
      state.selectedAccounts = []
      const page = action.payload.page
      const searchResult = action.payload.searchResult

      if (state.hasNewData) {
        const {
          specificSelectionManagementIdList,
        } = state.screenTransitionInput
        const { sortCondition, filterCondition } = state

        const filteredDisplayInfoList = filterDisplayInfoList(
          searchResult.displayInfo,
          filterCondition,
          specificSelectionManagementIdList
        )

        state.data = createDisplayOrderInfoArray(
          filteredDisplayInfoList,
          sortCondition
        )
        state.hasNewData = false
      }

      /**
       * Pagination out of range before the second request returns
       */
      const rowsPerPage = 100
      const startIndex = page * rowsPerPage
      const endIndex =
        startIndex + rowsPerPage > state.data.length
          ? state.data.length
          : startIndex + rowsPerPage
      
      if (state.data.slice(startIndex, endIndex).length === 0) {
        state.needUpdate = true
      }

      return state
    },
    updateOnSelectAll(state, action: PayloadAction<Data[]>) {
      const displayInfo = action.payload
      if (state.hasNewData) {
        const {
          specificSelectionManagementIdList,
        } = state.screenTransitionInput
        const { sortCondition, filterCondition } = state

        const filteredDisplayInfoList = filterDisplayInfoList(
          displayInfo,
          filterCondition,
          specificSelectionManagementIdList
        )

        state.data = createDisplayOrderInfoArray(
          filteredDisplayInfoList,
          sortCondition
        )
        state.hasNewData = false
      }

      if (displayInfo.length < state.totalCount) {
        state.needUpdate = true
      }

      return state
    },
    updateOnNewData(state,action: PayloadAction<Data[]>) {
      const {
        specificSelectionManagementIdList,
      } = state.screenTransitionInput
      const { sortCondition, filterCondition } = state

      let displayInfo = action.payload

      const filteredDisplayInfoList = filterDisplayInfoList(
        displayInfo,
        filterCondition,
        specificSelectionManagementIdList
      )

      if (
        deepEqual(initFilterCondition, state.filterCondition) &&
        state.screenTransitionInput.specificSelectionManagementIdList.length === 0
      ) {
        let cantReferenceInfoCount = 0
        displayInfo.forEach(info => {
          if(info.specificTargetDataGetFlag !== '0'){
            cantReferenceInfoCount++
          }
        })
        state.searchResult.totalCount = state.totalCount - cantReferenceInfoCount
      } else {
        state.searchResult.totalCount = filteredDisplayInfoList.length
      }

      state.data = createDisplayOrderInfoArray(
        filteredDisplayInfoList,
        sortCondition
      )
      state.hasNewData = false
      state.needUpdate = false
      return state
    },
    updateDecision(
      state,
      action: PayloadAction<MCAXS010UpdateJudgmentRequest>
    ) {
      state.updateJudgmentRequest = action.payload
      return state
    },
    updateJudgementResult(state, action: PayloadAction<UpdateJudgemtnResult>) {
      const result = action.payload

      const applicantsListInfo = state.searchResult.applicantsListInfo

      const target = applicantsListInfo.find(
        info => info.selectionManagementId === result.selectionManagementId
      )
      if (target !== undefined) {
        target.progressStatusSettingId = result.progressStatusSettingId
        target.decisionDivision = result.decisionDivision
        target.sysVersionNumber1 = result.sysVersionNumber1
        target.sysVersionNumber2 = result.sysVersionNumber2
      }
      return state
    },
    setJudgementStatusList(
      state,
      action: PayloadAction<{
        selectionManagementId: string
        judgementStatus: string
        show: boolean
      }>
    ) {
      state.data.forEach(i => {
        const targetExperience = i.experience.find(
          experience =>
            experience.selectionManagementId ===
            action.payload.selectionManagementId
        )
        if (targetExperience) {
          targetExperience.result.decisionChangeToolTipOpen =
            action.payload.show
          targetExperience.result.result = action.payload.judgementStatus
          return
        }
      })

      state.searchResult.displayInfo.forEach(i => {
        const targetExperience = i.experience.find(
          experience =>
            experience.selectionManagementId ===
            action.payload.selectionManagementId
        )
        if (targetExperience) {
          targetExperience.result.result = action.payload.judgementStatus
          return
        }
      })
      
      return state
    },
    setSelectedAccounts(state, action: PayloadAction<string[]>) {
      state.selectedAccounts = action.payload
      return state
    },
    initSetDate(state) {
      return state
    },
    setInitInfo(state, action: PayloadAction<any>) {
      state.orderRequest = action.payload
    },
    setting(
      state,
      action: PayloadAction<{
        request: MCAXS030OrderRequest
        onSubmit: (() => void) | undefined
      }>
    ) {
      return state
    },
    getMySearch(
      state,
      action: PayloadAction<{
        mySearchRequest: MCAXS010GetMySearchRequest
        searchRequest: MCAXS010SearchRequest
        mySearchCondition: number | null
      }>
    ) {
      return state
    },
    setMySearchResult(state, action: PayloadAction<MySearch>) {
      state.mySearch = action.payload
      return state
    },
    setMultiMySearchState(state, action: PayloadAction<MultiMySearchState>) {
      state.multiMySearchState = action.payload
      return state
    },
    setSearchCondition(state, action: PayloadAction<SearchCondition>) {
      state.searchCondition = action.payload

      const newSearchRequest = state.searchRequest
      newSearchRequest.searchCondition = JSON.stringify(action.payload)
      state.searchRequest = newSearchRequest
      
      return state
    },
    setSelectedSearch(state, action: PayloadAction<SelectedSearch>) {
      state.selectedSearch = action.payload
      return state
    },
    setOpenFlag(state, action: PayloadAction<boolean>) {
      state.openFlag = action.payload
      return state
    },
    setMCAXS010RefreshFlag(state) {
      state.refreshFlag = state.refreshFlag === 0 ? 1 : 0
    },
    setLoadingFlag(state, action: PayloadAction<boolean>) {
      const loadingFlag = action.payload
      state.loadingFlag = loadingFlag
      if (loadingFlag) {
        state.selectedAccounts = []
      }
      return state
    },
    setHasNewData(state, action: PayloadAction<boolean>) {
      state.hasNewData = action.payload
      return state
    },
    setSearchCount(state, action: PayloadAction<number>) {
      state.searchCount = action.payload
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload
    },
    setNeedUpdate(state, action: PayloadAction<boolean>) {
      state.needUpdate = action.payload
    },
    updateFilterDisplay(state, action: PayloadAction<SearchResult>) {
      //選考フィルター：選考フロー情報作成
      const filterDisplaySelenctionFlowInfo = createFilterDisplaySelenctionFlowInfo(
        action.payload
      )
      //選考フィルター：よく使われるタグ情報作成
      const filterDisplayCommonlyTagInfo = createFilterDisplayCommonlyTagInfo(
        action.payload
      )
      state.filterDisplay = {
        ...state.filterDisplay,
        filterDisplaySelenctionFlowInfo: filterDisplaySelenctionFlowInfo,
        filterDisplayCommonlyTagInfo: filterDisplayCommonlyTagInfo,
      }
      return state
    },
    clearFilter(state) {
      //フィルター欄クリア
      const clearedFilterDisplay = {
        ...state.filterDisplay,
        filterDisplayProgressInfoAllCount: 0,
        filterDisplayProgressInfo: [],
        filterDisplayDecisionInfo: [],
        filterDisplayTagInfo: [],
      }

      state.filterCondition = initFilterCondition
      state.filterDisplay = clearedFilterDisplay

      //フィルターの氏名欄クリア
      const nameForm = document.getElementById('nameForm') as HTMLInputElement
      if (nameForm !== null) {
        nameForm.value = ''
      }

      //ソート順クリア
      state.sortCondition.order = '0'

      return state
    },
    setDisplayLimit(state, action: PayloadAction<Option>) {
      state.displayLimit = action.payload
      return state
    },
    filterSelectionFlowInfo(state, action: PayloadAction<ResultObject>) {
      const resultObject = action.payload
      const flowMasterInfoArray = state.searchResult.flowMasterInfo.filter(item => {
        return item.selectionFlowSettingId === resultObject.selectionFlowSettingId
      })
      const selectProgressInfo = flowMasterInfoArray.filter(item => {
        return item.progressStatusSettingId === resultObject.progressStatusSettingId
      })[0]
      const leftProgresslst : string[] = []
        leftProgresslst.push(magiContants.PROGRESS_TYPE_INTERVIEW)
        leftProgresslst.push(magiContants.PROGRESS_TYPE_CUSTOM)
        leftProgresslst.push(magiContants.PROGRESS_TYPE_INFIX)
        leftProgresslst.push(magiContants.PROGRESS_TYPE_INTERNSHIP)
        leftProgresslst.push(magiContants.PROGRESS_TYPE_CONSENT)
        leftProgresslst.push(magiContants.PROGRESS_TYPE_ENTRY)
      const rightProgresslst : string[] = []
        rightProgresslst.push(magiContants.PROGRESS_TYPE_NOPASS)
        rightProgresslst.push(magiContants.PROGRESS_TYPE_NOADOPTION)
        rightProgresslst.push(magiContants.PROGRESS_TYPE_DISMISS)

      // 左進捗の進捗ステータス
      const leftProgressList = flowMasterInfoArray.filter(item => {
        return leftProgresslst.includes(item.progressType)
      }).map(mapItem => {
        return {
          id: mapItem.displayOrder,
          name: mapItem.progressName,
          settingId: Number(mapItem.progressStatusSettingId)
        }
      })

      // 右進捗の進捗ステータス
      const rightProgressList: Status[] = flowMasterInfoArray.filter(item => {
        return rightProgresslst.includes(item.progressType)
      }).map(mapItem => {
        return {
          id: mapItem.displayOrder,
          name: mapItem.progressName,
          settingId: Number(mapItem.progressStatusSettingId)
        }
      })

      let flag = selectProgressInfo.displayOrder // 旗の位置
      let leftSelect // 左進捗の選択位置
      let rightSelect = selectProgressInfo.displayOrder // 右進捗の選択位置
      let leftGrey // 左進捗のグレー表示の標識
      let rightGrey = false // 右進捗のグレー表示の標識
      let target // 対象の標識
      if (rightProgresslst.includes(selectProgressInfo.progressType.toString())) {
        leftGrey = true
        leftSelect = -1 
      } else {
        leftGrey = false
        leftSelect = selectProgressInfo.displayOrder
      }

      if (resultObject.notApplicable) {
        leftGrey = true
        rightGrey = true
        target = false
        flag = -1
        leftSelect = -1
        rightSelect = -1
      } else {
        target = true
      }

      const selectionFlowInitData : SelectionFlowInitData = {
        flag : flag, // 旗の位置
        leftSelect : leftSelect,
        rightSelect : rightSelect,// 右進捗の選択位置
        leftGrey : leftGrey, 
        rightGrey : rightGrey, 
        target : target,
        leftProgressList : leftProgressList,
        rightProgressList : rightProgressList
      }
      state.selectionFlowInitData1 = selectionFlowInitData
      return state
    },
    setSpecificTargetFirstDisplayInfo(state, action: PayloadAction<Data[]>) {
      state.specificTargetFirstDisplayInfo = action.payload
      return state
    },
    getContractMediaInfo(
      state,
      action: PayloadAction<{
        request: MCAXS010GetContractMediaInfoRequest
        onGetContractMediaInfo: (
          isMultipleContractMedia: boolean,
          contractMediaInfoList: ContractMediaInfo[]
        ) => void
      }>
    ) {
      return state
    },
    setMultipleMediaError(state, action: PayloadAction<MultipleMediaError>) {
      const message = action.payload.message
      state.multipleMediaError = {
        ...action.payload,
        message: getMessage(message) ? getMessage(message) : message,
      }
    },
    setCircleLoadingFlag(state, action: PayloadAction<boolean>) {
      state.circleLoadingFlag = action.payload
      return state
    },
    setDisplaySearchCriteriaTitleOpenFlag(state, action: PayloadAction<boolean>) {
      state.displaySearchCriteriaTitleOpenFlag = action.payload
      return state
    },
    setMultiMySearchLoadingInfo(state, action: PayloadAction<MultiMySearchLoadingInfo>) {
      state.multiMySearchLoadingInfo = action.payload
      return state
    },
  },
})

export const {
  updateScreenTransitionInput,
  updateSearchRequest,
  search,
  setSearchResult,
  resetData,
  setInitDisplay,
  updateSelectionSelect,
  updateProgressFilter,
  updateTagFilter,
  updateFilterCondition,
  updateSortCondition,
  updatePage,
  updateOnSelectAll,
  updateOnNewData,
  updateDecision,
  updateJudgementResult,
  setJudgementStatusList,
  setSelectedAccounts,
  initSetDate,
  setInitInfo,
  setting,
  getMySearch,
  setMySearchResult,
  setMultiMySearchState,
  setSearchCondition,
  setSelectedSearch,
  setOpenFlag,
  setMCAXS010RefreshFlag,
  setLoadingFlag,
  setHasNewData,
  setSearchCount,
  setTotalCount,
  setNeedUpdate,
  updateFilterDisplay,
  clearFilter,
  setDisplayLimit,
  filterSelectionFlowInfo,
  setSpecificTargetFirstDisplayInfo,
  getContractMediaInfo,
  setMultipleMediaError,
  setCircleLoadingFlag,
  setDisplaySearchCriteriaTitleOpenFlag,
  setMultiMySearchLoadingInfo,
} = applicantListSlice.actions
export default applicantListSlice.reducer

const middle: FilterDisplayDecisionInfo = {
  decisionDivision: '1', //判定区分
  decisionName: '判定中', //判定名
}
const participate: FilterDisplayDecisionInfo = {
  decisionDivision: '2', //判定区分
  decisionName: '参加', //判定名
}
const pass: FilterDisplayDecisionInfo = {
  decisionDivision: '3', //判定区分
  decisionName: '合格', //判定名
}
const fail: FilterDisplayDecisionInfo = {
  decisionDivision: '5', //判定区分
  decisionName: '不合格', //判定名
}
const notParticipate: FilterDisplayDecisionInfo = {
  decisionDivision: '4', //判定区分
  decisionName: '不参加', //判定名
}

const other: FilterDisplayDecisionInfo = {
  decisionDivision: '6', //判定区分
  decisionName: 'その他', //判定名
}

/**
 * 選考フィルター用選考フロー情報作成
 *
 * @param searchResult
 */
const createFilterDisplaySelenctionFlowInfo = (searchResult: SearchResult) => {
  const map = new Map()

  searchResult.flowMasterInfo.forEach(info => {
    const selectionFlowSettingId = info.selectionFlowSettingId
    const selectionName = info.selectionName
    map.set(selectionFlowSettingId, selectionName)
  })

  const filterDisplaySelenctionFlowInfoArray: FilterDisplaySelenctionFlowInfo[] = []
  map.forEach((value, key) => {
    const filterDisplaySelenctionFlowInfo: FilterDisplaySelenctionFlowInfo = {
      selectionFlowSettingId: key,
      selectionName: value,
    }
    filterDisplaySelenctionFlowInfoArray.push(filterDisplaySelenctionFlowInfo)
  })

  return filterDisplaySelenctionFlowInfoArray
}

/**
 * よく使うタグフィルター情報作成
 */
const createFilterDisplayCommonlyTagInfo = (searchResult: SearchResult) => {
  const tagMasterInfoArray = searchResult.tagMasterInfo

  tagMasterInfoArray.sort((a, b) => {
    //ソート：タグ件数（降順）
    if (a.tagCount < b.tagCount) {
      return 1
    }
    if (a.tagCount > b.tagCount) {
      return -1
    }
    return 0
  })

  const commonlyTagInfoArray: FilterTagInfo[] = []
  tagMasterInfoArray.forEach((info, index) => {
    if (index < 5) {
      const commonlyTagInfo: FilterTagInfo = {
        tagSettingId: info.tagSettingId, //タグ設定ID
        tagName: info.tagName, //タグ名称
      }
      commonlyTagInfoArray.push(commonlyTagInfo)
    } else {
      return
    }
  })

  return commonlyTagInfoArray
}

/**
 * フィルター処理
 *
 * @param filterCondition
 * @param searchResult
 * @todo 空チェックを共通メソッドから取得
 */
const filterDisplayInfoList = (
  displayInfoList: Data[],
  filterCondition: FilterCondition,
  specificConditionSelectionManagementIdList: string[]
) => {
  const selectionFlowSelect = filterCondition.selectionFlowSelect.value
  const progressStatusSelect = filterCondition.progressStatusSelect.value
  const judgmentStatusSelect = filterCondition.judgmentStatusSelect.value
  const progressStatusSelectProgressType =
    filterCondition.progressStatusSelectProgressType
  const nameForm = filterCondition.nameForm
  const tagSelect = filterCondition.tagSelect.map(tag => tag.value)

  //フィルタリング後の画面表示情報
  const filterdDisplayList: Data[] = []

  //フィルタリング処理
  displayInfoList.forEach(info => {
    let isTargetInfo = true

    let filterdExperience = info.experience.slice()
    //選考情報周りのフィルタリング
    if (selectionFlowSelect !== '') {
      //選考フロー設定
      filterdExperience = info.experience.filter(exp => {
        let isTargetSelection = true
        isTargetSelection =
          isTargetSelection &&
          exp.result.selectionFlowSettingId === selectionFlowSelect
        if (progressStatusSelect !== '') {
          if (progressStatusSelectProgressType !== '9') {
            //選考ステップ
            isTargetSelection =
              isTargetSelection &&
              exp.result.progressStatusSettingId === progressStatusSelect &&
              !exp.result.notApplicable
            if (judgmentStatusSelect !== '') {
              //合否判定
              isTargetSelection =
                isTargetSelection && exp.result.result === judgmentStatusSelect
            }
          } else {
            //対象外
            isTargetSelection = isTargetSelection && exp.result.notApplicable
          }
        }
        return isTargetSelection
      })
      isTargetInfo = filterdExperience.length > 0 ? isTargetInfo && true : false
    }

    if (nameForm !== '') {
      //氏名
      isTargetInfo =
        info.nameForFilter.indexOf(nameForm) > -1 ||
        info.nameHiraganaForFilter.indexOf(nameForm) > -1 ||
        info.nameKatakanaForFilter.indexOf(nameForm) > -1
          ? isTargetInfo && true
          : false
    }

    if (tagSelect.length > 0) {
      //タグ
      //選考フィルターで指定したタグの内、エントリーに関連づくタグ対象にないものが1つでもあれば表示対象から除外とする
      const infoTagList = info.tagList.map(i => i.tagSettingId)
      const unExistTagSettingId = tagSelect.find(i => !infoTagList.includes(i))

      isTargetInfo =
        unExistTagSettingId === undefined ? isTargetInfo && true : false
    }

    if (specificConditionSelectionManagementIdList.length > 0) {
      //特定条件
      filterdExperience = filterdExperience.filter(
        exp => exp.specificTargetFlag === '1'
      )
      isTargetInfo = filterdExperience.length > 0 ? isTargetInfo && true : false
    } else {
      isTargetInfo = info.specificTargetDataGetFlag === '0' ? isTargetInfo && true : false
    }

    if (isTargetInfo) {
      filterdDisplayList.push({
        ...info,
        experience: filterdExperience,
      })
    }
  })

  return filterdDisplayList
}


/**
 *
 * 表示順情報作成処理
 *
 * @param filterdList
 * @param sortCondition
 */
const createDisplayOrderInfoArray = (
  displayInfoList: Data[],
  sortCondition: SortCondition
) => {
  //表示順情報にデータを追加
  const displayOrderInfoArray = new Array(displayInfoList.length)
  for (let i = 0; i < displayInfoList.length; i++) {
    displayOrderInfoArray[i] = ({
      data: displayInfoList[i], //画面表示情報
      selectionManagementId: 
        displayInfoList[i].experience.length > 0
          ? displayInfoList[i].experience[0].selectionManagementId
          : '', //選考管理ID
      specificTargetDataGetFlag: displayInfoList[i].specificTargetDataGetFlag, //特定条件データ取得フラグ
      jobSeekerIdForDisplay: displayInfoList[i].id.jobSeekerIdForDisplay,//displayInfoList[i].id.jobSeekerIdForDisplay, //表示用求職者ID
      nameKana: displayInfoList[i].nameKana, //カナ氏名
      collegeMasterDisplayOrder: displayInfoList[i].collegeMasterDisplayOrder, //大学マスタ表示順
      entryReceptionTime: displayInfoList[i].entryReceptionTimeForSort, //ソート用エントリー受付日時
    })
  }
  //表示順情報をソート
  switch (sortCondition.order) {
    case '0':
      //[0]応募者管理ID順
      displayOrderInfoArray.sort((a, b) => {
        //第1ソート：特定条件データ取得フラグ（昇順）
        if (a.specificTargetDataGetFlag > b.specificTargetDataGetFlag) {
          return 1
        }
        if (a.specificTargetDataGetFlag < b.specificTargetDataGetFlag) {
          return -1
        }
        //第2ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第3ソート：エントリー受付日時（降順）（降順：nullは一番下）
        if (a.entryReceptionTime !== null || b.entryReceptionTime !== null) {
          if (a.entryReceptionTime === null) {
            return 1
          }
          if (b.entryReceptionTime === null) {
            return -1
          }
          if (a.entryReceptionTime < b.entryReceptionTime) {
            return 1
          }
          if (a.entryReceptionTime > b.entryReceptionTime) {
            return -1
          }
        }
        //第4ソート：選考管理ID（降順）
        if (a.selectionManagementId < b.selectionManagementId) {
          return 1
        }
        if (a.selectionManagementId > b.selectionManagementId) {
          return -1
        }
        return 0
      })
      break
    case '1':
      //[1]カナ氏名順
      displayOrderInfoArray.sort((a, b) => {
        //第1ソート：特定条件データ取得フラグ（昇順）
        if (a.specificTargetDataGetFlag > b.specificTargetDataGetFlag) {
          return 1
        }
        if (a.specificTargetDataGetFlag < b.specificTargetDataGetFlag) {
          return -1
        }
        //第2ソート：カナ氏名（昇順）
        if (a.nameKana > b.nameKana) {
          return 1
        }
        if (a.nameKana < b.nameKana) {
          return -1
        }
        //第3ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第4ソート：エントリー受付日時（降順）（降順：nullは一番下）
        if (a.entryReceptionTime !== null || b.entryReceptionTime !== null) {
          if (a.entryReceptionTime === null) {
            return 1
          }
          if (b.entryReceptionTime === null) {
            return -1
          }
          if (a.entryReceptionTime < b.entryReceptionTime) {
            return 1
          }
          if (a.entryReceptionTime > b.entryReceptionTime) {
            return -1
          }
        }
        //第5ソート：選考管理ID（降順）
        if (a.selectionManagementId < b.selectionManagementId) {
          return 1
        }
        if (a.selectionManagementId > b.selectionManagementId) {
          return -1
        }
        return 0
      })
      break
    case '2':
      //[2]学校順
      displayOrderInfoArray.sort(function(a, b) {
        //第1ソート：特定条件データ取得フラグ（昇順）
        if (a.specificTargetDataGetFlag > b.specificTargetDataGetFlag) {
          return 1
        }
        if (a.specificTargetDataGetFlag < b.specificTargetDataGetFlag) {
          return -1
        }
        //第2ソート：大学マスタ表示順（昇順）
        if (a.collegeMasterDisplayOrder > b.collegeMasterDisplayOrder) {
          return 1
        }
        if (a.collegeMasterDisplayOrder < b.collegeMasterDisplayOrder) {
          return -1
        }
        //第3ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第4ソート：エントリー受付日時（降順）（降順：nullは一番下）
        if (a.entryReceptionTime !== null || b.entryReceptionTime !== null) {
          if (a.entryReceptionTime === null) {
            return 1
          }
          if (b.entryReceptionTime === null) {
            return -1
          }
          if (a.entryReceptionTime < b.entryReceptionTime) {
            return 1
          }
          if (a.entryReceptionTime > b.entryReceptionTime) {
            return -1
          }
        }
        //第5ソート：選考管理ID（降順）
        if (a.selectionManagementId < b.selectionManagementId) {
          return 1
        }
        if (a.selectionManagementId > b.selectionManagementId) {
          return -1
        }
        return 0
      })
      break
    default:
      console.log('不正なソートキー')
  }

  const result = []
  for (let i = 0; i < displayInfoList.length; i++) {
    result.push(displayOrderInfoArray[i].data)
  }
  return result
}

const createFilterDisplayProgressInfoArray = (
  selectSelectionFlowSettingId: string,
  listId: string,
  searchCondition: SearchCondition,
  flowMasterInfoArray: FlowMasterInfo[],
  applicantsListInfo: ApplicantsListInfo[]
) => {
  //検索条件の選考ステップで、内定者一覧or選考対象外一覧の選考ステップIDのリストを保持
  let additionalProgressStatusFilters: string[] = []
  if (listId === '0') {
    //全件一覧
    const selectionStepObj = searchCondition.selectionStepObj
    if (selectionStepObj) {
      if (selectionStepObj.length === 0) {
        //検索条件で選考ステップを1つも指定していない場合
        flowMasterInfoArray.forEach(flowMasterInfo => {
          additionalProgressStatusFilters.push(
            flowMasterInfo.progressStatusSettingId
          )
        })
      } else {
        //検索条件で選考ステップを1つ以上指定している場合
        selectionStepObj.forEach(selectionStep => {
          const flowMasterInfo = flowMasterInfoArray.find(
            flow =>
              Number(flow.progressStatusSettingId) ===
              Number(selectionStep.selectionStepId)
          )
          if (flowMasterInfo) {
            if (
              ['9'].includes(flowMasterInfo.progressType)
            ) {
              //検索条件詳細で選択した選考ステップ項目が内定者一覧 or 選考対象外一覧の選考ステップである場合
              additionalProgressStatusFilters.push(
                flowMasterInfo.progressStatusSettingId
              )
            }
          }
        })
      }
    }
  }

  const filterDisplayProgressInfoArray: FilterDisplayProgressInfo[] = []
  flowMasterInfoArray.forEach(flowMasterInfo => {
    //進捗種別
    const progressStatusSettingId = flowMasterInfo.progressStatusSettingId
    const progressName = flowMasterInfo.progressName
    const progressType = flowMasterInfo.progressType

    //件数カウント処理
    let count = 0
    if (progressType !== '9') {
      //対象外以外の場合
      count = applicantsListInfo.filter(
        info =>
          info.selectionFlowSettingId === selectSelectionFlowSettingId &&
          info.progressStatusSettingId === progressStatusSettingId &&
          info.nonTargetFlag !== '1'
      ).length
    } else {
      //対象外の場合
      count = applicantsListInfo.filter(
        info =>
          info.selectionFlowSettingId ===
            flowMasterInfo.selectionFlowSettingId && info.nonTargetFlag === '1'
      ).length
    }

    const filterDisplayProgressInfo: FilterDisplayProgressInfo = {
      progressStatusSettingId: progressStatusSettingId, //進捗ステータス設定ID
      progressName: progressName, //進捗名
      progressType: progressType, // 進捗区分
      count: count, //件数
    }

    switch (listId) {
      case '0':
        //全件一覧
        if (additionalProgressStatusFilters.includes(progressStatusSettingId)) {
          filterDisplayProgressInfoArray.push(filterDisplayProgressInfo)
        } else {
          switch (progressType) {
            case '9':
              break
            default:
              //[9]以外
              filterDisplayProgressInfoArray.push(filterDisplayProgressInfo)              
              break
          }
        }
        break
      case '1':
        //内定者一覧
        switch (progressType) {
          case '2':
          case '4':
          case '5':
            //[2]内定[4]内定承諾[5]入社
            filterDisplayProgressInfoArray.push(filterDisplayProgressInfo)
            break
          default:
            break
        }
        break
      case '2':
        //選考対象外一覧
        switch (progressType) {
          case '9':
            //[9]対象外
            filterDisplayProgressInfoArray.push(filterDisplayProgressInfo)
            break
          default:
            break
        }
        break
      default:
        break
    }
  })
  return filterDisplayProgressInfoArray
}
