import { getEntryHistory, setEntryHistory } from "reducers/entryHistoryReducer";
import { put, call, takeEvery, all } from "redux-saga/effects";
import { openModal, openSnackbar } from "reducers/messageReducer";
import { entryHistoryApi } from "apis/MCAXS290Api";

function* entryHistoryInitSaga(action: ReturnType<typeof getEntryHistory>) {
  try {
    yield put(setEntryHistory([]));
    const data = yield call(entryHistoryApi, action.payload);
    yield put(setEntryHistory(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* entryHistorySaga() {
  yield all([
    takeEvery(getEntryHistory, entryHistoryInitSaga),
  ])
}