export const MCAMS020InitialValues = {
  templateId: '',
}

export const templateTypeList = [
  { value: 0, label: '汎用', id: 'templateTypeGeneral' },
  { value: 1, label: '合格通知', id: 'templateTypeOffer' },
  { value: 2, label: '不採用通知', id: 'templateTypeFailure' },
]
// MCBリプレース MCAMS020 START
export const mcbTemplateTypeList = [
  { value: 0, label: '汎用', id: 'templateTypeGeneral' },
  { value: 1, label: '合格通知', id: 'templateTypeOffer' },
  { value: 2, label: '不採用通知', id: 'templateTypeFailure' },
  { value: 3, label: 'My CareerBox', id: 'templateTypeMcb' },
]
// MCBリプレース MCAMS020 END
export const destinationList = [
  { value: 0, label: 'マイナビのみ', id: 'destinationSelectionMynaviOnly' },
  { value: 1, label: 'マイナビ優先', id: 'destinationSelectionPriorityToMynavi' },
  { value: 2, label: 'e-mailのみ', id: 'destinationSelectionEmailOnly' },
]
export const destinationListDis = [
  { value: 0, label: 'マイナビのみ', id: 'destinationSelectionMynaviOnly' },
  { value: 2, label: 'e-mailのみ', id: 'destinationSelectionEmailOnly' },
]
// MCBリプレース MCAMS020 START
export const mcbDestinationList = [
  { value: 3, label: 'My CareerBox', id: 'destinationSelectionMCB' },
]
// MCBリプレース MCAMS020 END
export const allowReplyList = [
  { value: 1, label: '返信を許可する', id: 'repliesAllow' },
  { value: 0, label: '返信を受け付けない', id: 'repliesNotAllow' },
]

export const insertTextSecruitmentManageFlagList = [
  { name: 'accountTypeSupervising', label: '統括', id: '1' },
  { name: 'accountTypeAdministrator', label: '管理者', id: '2' },
  { name: 'accountTypeSemiManager', label: '準管理者', id: '3' },
  { name: 'accountTypeGeneralUser', label: '一般ユーザ', id: '4' },
  { name: 'accountTypeLimitUser', label: '制限ユーザ', id: '5' },
];

export const barcodeDisplayList = [
  { value: 1, label: '表示する', id: 'barcodeDisplay' },
  { value: 0, label: '表示しない', id: 'barcodeNotDisplay' },
]

// const initialForbiddenWordsList = [{
//   forbiddenWords:'',
// }]

const initialMCAMS020SearchHomepageResultDto = [{
  messageTemplateSettingId:0,	//メッセージテンプレートID
  sequence:0,	//連番
  homePageTitle:'',	//ホームページタイトル
  homePageUrl:'',	//ホームページURL
}]
export const initialMCAMS020SearchAttachmentFileResultDto = [{
  messageTemplateSettingId:0,	//メッセージテンプレートID
  sequence:0,	//連番
  attachmentName:'ファイルを選択',	//添付ファイル名
  fileManagementId:'',	//ファイル管理ID
  fileSize:'',	//ファイルサイズ
}]
const initialMCAMS020SearchEntryBoxResultDto = [{
  messageTemplateSettingId:0,	//メッセージテンプレートID
  sequence:0,	//連番
  entryBoxId:0,	//エントリーボックスID 
}]
const initialMCAMS020SearchSeminarResultDto = [{
  messageTemplateSettingId:0,	//メッセージテンプレートID
  sequence:0,	//連番
  seminarId:0,	//セミナーID
}]
const initialMCAMS020MynaviAttachedOptionEntryBoxResultDto = [{
  entryBoxId:0,	//エントリーボックスID
  entryBoxName:'',	//エントリーボックス名  
  contractMediaName:'',	//契約応募経路名
  displayStartDate:'',	//表示開始日
  displayEndDate:'',	//表示終了日 
}]
const initialMCAMS020MynaviAttachedOptionSeminarResultDto = [{
  seminarId:0,//セミナーID
  seminarName:'',	//セミナー名
  contractMediaName:'',	//契約応募経路名
  displayStartDate:'',	//表示開始日
  displayEndDate:'',	//表示終了日 
}]
const initialMCAMS020RecruitmentManagementFlagResultDto = [{
  recruitmentManagementFlagSettingId:0,	//採用管理フラグ設定ID
  recruitmentManagementFlagName:'',	//採用管理フラグ名
}]
const initialMCAMS020SeminarScheduleResultDto = [{
  coordinationFormerSeminarScheduleIdentificationKey:0,	//連携元セミナー日程識別キー
  invisibleFlag:0, //非表示フラグ
  eventDate:'',	//開催日
  venueLocationName:'',	//開催地場所名
  startTime:'',	//開始時間
  endTime:'',	//終了時間
  contractMediaName:'',	//契約応募経路名
}]
const initialMCAMS020SeminarImageResultDto = [{
  seminarId:0,	//セミナーID
  coordinationFormerSeminarId:0,	//連携元セミナーID
  seminarName:0,	//セミナー名
  publicFlag:0,	//公開フラグ
  contractMediaName:'',	//契約応募経路名
}]
const initialMCAMS020SignResultDto = [{
  signSettingId:0,	//署名設定ID
  registrationName:'', //登録名
  signature:'',	//署名 
}]

export const initialMCAMS020ReturnDisplayDto ={
  recruitmentManagementDivision:'',//採用管理区分
  forbiddenWordsList:[] as string[],//禁止語リスト
  messageTemplateSettingId:'',	//メッセージテンプレートID
  templateName:'',	//テンプレート名
  // 年度対応 start
  targetYearDataList:[] as string[], //対象年度データリスト
  targetYear:'',  //対象年度
  // 年度対応 end
  templateType:0,	//テンプレート種別
  destinationDivision:0,	//送信先区分
  // [phase2] start
  sendToNonMember:0,	//マイナビ退会者を送信対象者に含む
  // [phase2] end
  senderCompanyName:'',	//送信元会社名
  senderMailAddress:'',	//送信元メールアドレス
  replyPermitFlag:0,	//返信許可フラグ
  repliesNecessary: 0, // 返信希望アイコンの指定
  repliesLimit: '', // 応募者からの返信期限の指定
  subject:'',	//件名
  body:'',	//本文
  barcodeDisplayFlag:0,	//バーコード表示フラグ
  invisibleFlag:'',	//非表示フラグ
  sysVersionNumber:'',	//sysバージョン番号
  homePageList:initialMCAMS020SearchHomepageResultDto,//ホームページ情報
  attachmentFileList:initialMCAMS020SearchAttachmentFileResultDto,//添付ファイル情報
  selectedEntryBoxList:[],//マイナビ添付オプション（エントリーボックス）情報
  selectedSeminarList:[],//マイナビ添付オプション（セミナー）情報
  entryBoxList:[],//マイナビ添付オプション（エントリー）情報
  seminarList:[],//マイナビ添付オプション（セミナー）情報
  recruitmentManagementFlagList:[],//管理項目リスト
  seminarScheduleList:[],//セミナー日程リスト
  seminarImageList:[],//セミナー画像リスト
  signList:[],//署名リスト
  exampleLink:'',//文例リンク
  homepageTitle1:'',	//ホームページタイトル1
  homepageTitle2:'',//ホームページタイトル2
  homepageTitle3:'',//ホームページタイトル3
  homepageUrl1:'',	//ホームページURL1
  homepageUrl2:'',//ホームページURL2
  homepageUrl3:'',	//ホームページURL3
  attachmentFileRegistrationMax: 0, // 添付ファイル登録数上限
  mynaviAttachedOptionRegistrationMax: 0 // マイナビ添付オプション登録数上限
}

export const initialopenModalArgs ={
  openCheckModal:false,
  modalMessageArgs:{
    messageId: "",
    args: []
  }
}
