// CSVテンプレート登録内容確認画面DB処理と画面用
const MCALS040ProcessData = {
  entryDataLayoutId: '', // エントリーデータレイアウトID
  entryDataIoTemplateSettingId: '', // エントリーデータ入出力テンプレート設定ID
  templateName: '', // テンプレート名
  // 年度対応 start
  targetYear: '', // 対象年度
  // 年度対応 end
  templateType: '', // テンプレート種別
  selectedItemListDisplayOrder: [], // 選択済項目_表示順一覧
  selectedItemListEntryDataItemId: [], // 選択済項目_エントリーデータ項目ID一覧
  selectedItemListRecruitmentManagementFlagSettingId: [], // 選択済項目_採用管理フラグ設定ID一覧
  selectedItemListRecruitmentManagementFlagName: [], // 選択済項目名
  // 26KH #89085 start
  tagSettingId:[], //選択済項目_タグ設定ID
  tagName:[], //選択済項目_タグ名
  // 26KH #89085 end
  selectedItemListEntryBoxId: [], // 選択済項目_エントリーボックスID一覧
  selectedItemListSeminarId: [], // 選択済項目_セミナーID一覧
  selectedItemListEventId: [], // 選択済項目_イベントID一覧
  entryOutputHistory: '', // エントリー履歴_出力する履歴
  seminarInterviewOutputHistory: '', // 説明会・面接履歴_出力する履歴
  interviewScheduleOutputHistory: '', // 面接日程履歴_出力する履歴
  eventOutputHistory: '', // イベント参加履歴_出力する履歴
  selectionOutputSelection: '', // 選考フロー_出力する選考フロー
  seminarInterviewCancelHistory: '', // 説明会・面接の予約履歴_キャンセルされた履歴 入力フラグ
  versionNumberTemPre: '', // テンプレート情報バージョン番号
  versionNumberItPre: [], // テンプレート情報（選択済項目）バージョン番号
  recruitmentManagementDivision: '', // 採用管理区分
  screenDivision: '', // 遷移元画面処理区分
}
// CSVテンプレート登録内容確認画面のみ用
const MCALS040OtherData = {
  entryOutputHistoryInput: '', // エントリー履歴_出力する履歴 入力フラグ
  seminarInterviewOutputHistoryInput: '', // 説明会・面接履歴_出力する履歴 入力フラグ
  interviewScheduleOutputHistoryInput: '', // 面接日程履歴_出力する履歴 入力フラグ
  eventOutputHistoryInput: '', // イベント参加履歴_出力する履歴 入力フラグ
  selectionOutputSelectionInput: '', // 選考フロー_出力する選考フロー 入力フラグ
  seminarInterviewCancelHistoryInput: '', // 説明会・面接の予約履歴_キャンセルされた履歴 入力フラグ
  seminarInterviewCancelHistory: '', // 説明会・面接の予約履歴_キャンセルされた履歴 入力フラグ
  layoutType: '', // レイアウト種別
  layoutGroup: '', // レイアウト分類
  showDialog: false //dialog表示
}

export { MCALS040ProcessData, MCALS040OtherData}
