import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { FieldGroup, FormField, Title } from 'componentsHsc'
import DatePicker from '../DatePickerWithOutFormik'
import { FormControl } from '..'
import { useDispatch, useSelector } from 'react-redux'
import { getMcaxs541Init } from 'reducers/advancedSearchModalReducer'
import { magiContants as contants, magiContants } from 'utils/contants'
import moment from 'moment'
import {
  SendMessageObj,
  SendMessageConditionObj,
  SendMessegeStatusObj,
  SendMessegeTemplateObj,
} from '../Config/searchConditionConfig'
import ReactSelect, { components, MenuListComponentProps } from 'react-select'
import { useErrorStyle } from 'componentsHsc'
import { miscRegexSet } from 'utils/regex'
import customSelectOptions from 'utils/customSelectOptions'
import { transform } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { innerSortFunc } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { RootState } from 'reducers'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'visible !important',
  },
  actions: {
    justifyContent: 'center',
    // marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 2,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span': {
      color: colors.grey[600],
    },
  },
  content: {
    height: 'inherit !important',
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '20px !important',
  },
  dividerColor: {
    backgroundColor: colors.grey[600],
  },
  dateRange: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '-20px',
  },
  group: {
    display: 'flex',
  },
  groupBlock: {
    display: 'block',
  },
  disp: {
    display: 'none',
  },
  addactions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
  },
  chipcontent: {
    minHeight: '43px',
    maxHeight: '43px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  errorContent: {
    minHeight: '24px',
  },
  errorText: {
    float: 'right',
  },
  inputRoot: {
    width: '100%',
  },
  reactSelectCss:{
    '& div' : {
      cursor: 'pointer!important',
    },
    '& input': {
      cursor: 'pointer!important',
    },
  },
  msgTemplateAll: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  msgTemplateOne: {
    cursor:'pointer!important',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0 !important',
    marginRight: '8px',
    width: '100%'
  },
  mark: {
    marginRight: '8px !important',
    width: '16px !important',
    height: '16px !important',
  },
  msgStatusALL: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-4px 0px -8px 16px '
  },
  msgTemplateText: {
    wordBreak: 'break-all',
    width: '100%',
  },
  templateMagrin: {
    marginTop: '25px !important',
  },
  errorInput: {
    '& > input': {
      borderColor: '#e53935 !important',
    },
  },
}))

const searchOptionList = [
  { label: 'メッセージを何も送信していない', value: '0' },
  { label: '特定のメッセージで検索', value: '1' },
]

const searchOptionTemplateList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

const typeOptionList = [
  { label: '指定しない', value: '00' },
  { label: '通常', value: '01' },
  { label: 'サンクス', value: '02' },
]

const sendStatusOptionList = [
  { label: '送付なし', value: '1' },
  { label: '送付あり', value: '0' },
]

const sendMessegeStatusCheckBox : SendMessegeStatusObj[] = [
  { sendMessegeStatusName: '送信予約中のメッセージを含む', sendMessegeStatusId: '20' },
]

export interface Errors {
  errorFlag: boolean
  errorInfo: string
}

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

// phase2 start
const MenuList = (props: MenuListComponentProps<any>) => {
  return (
    <components.MenuList {...props}>
      <div style={{display:'inline-block',minWidth:'100%'}}>
        {props.children}
      </div>
    </components.MenuList>
  )
}
// phase2 end

const MessageModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [dateSelect, setDateSelect] = useState('')
  // phase2 start
  const [optionListNew, setOptionList] = useState([{value: '-1',label: '指定しない'}])

  const entryBoxAndSeminarList = useSelector(
    (state: RootState) => state.advancedSearchModal.mCAXS541ReturnDisplayDto.entryBoxAndSeminarList
  ) 
  const noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE
  // phase2 end

  // メッセージ条件
  const [searchType, setSearchType] = useState('')
  const [chipArea, setChipArea] = useState([] as SendMessageConditionObj[])
  const [searchTemplateType, setSearchTemplateType] = useState('0')

  //メッセージ種別
  const [messageTypeState, setMessageTypeState] = useState(typeOptionList[0])

  // phase2 start
  const [entryBoxOptionState, setEntryBoxOptionState] = useState(optionListNew[0])
  // phase2 end
  //件名
  const [messageTitleState, setMessageTitleState] = useState('')
  //送付状態From
  const [fromDateState, setFromDateState] = useState('')
  //送付状態To
  const [toDateState, setToDateState] = useState('')
  //メッセージテンプレート
  const [sendMessegeTemplateState, setSendMessegeTemplateState] = useState([] as SendMessegeTemplateObj[])

  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    if (open) {
      dispatch(getMcaxs541Init())
      if (form.values.sendMessegeObj) {
        if (form.values.sendMessegeObj.sendMessegeConditionJudgmentFlag == 0) {
          setSearchType(searchOptionList[0].value)
        } else {
          setSearchType('')
          if (form.values.sendMessegeObj.sendMessegeConditionObj) {
            setChipArea(
              form.values.sendMessegeObj.sendMessegeConditionObj.slice()
            )
          } else {
            setChipArea([])
          }
        }
      } else {
        setSearchType('')
        setChipArea([])
      }
      setFromDateErrors({
        errorFlag: false,
        errorInfo: '',
      })
      setToDateErrors({
        errorFlag: false,
        errorInfo: '',
      })
      setSearchTemplateType('0')
      setMessageTypeState(typeOptionList[0])
      setMessageTitleState('')
      setFromDateState('')
      setToDateState('')
      setSendMessegeStatusState(sendMessegeStatusCheckBox)
      setSendMessegeTemplateState([])
      setDateSelect('')
      // phase2 start
      setEntryBoxOptionState(optionListNew[0])
      // phase2 end
    }
  }, [open])
  // phase 2 start

    useEffect(() => {
    if(entryBoxAndSeminarList != null){
      const options: {
        value: string
        label: string
      }[] = [{value:'-1',label:'指定しない'}]
      entryBoxAndSeminarList.map(e => {
        options.push({
         value: e.entryBoxOrSeminarType=='1'? 'entryBoxId:' + e.mynaviAttachedOptionId.toString() : e.entryBoxOrSeminarType=='2' ? 'seminarId:' + e.mynaviAttachedOptionId.toString() : '',
         label:
           (e.contractMediaName != null ? e.contractMediaName : '　　　') +
           '　' +
           (e.mynaviAttachedOptionName != null
             ? e.mynaviAttachedOptionName
             : '　　　'),
       })
     })
     setOptionList(options)
   }
  },[entryBoxAndSeminarList])
  const optionLabelList = optionListNew.map(i => i.label)

  const sendMessegeTemplateList =  useSelector(
       (state: RootState) => state.advancedSearchModal.mCAXS541ReturnDisplayDto.messegeConditionList)
  
  // phase 2 end
  const handleSelect = (option: SendMessegeTemplateObj) => (e: any) => {
    const sendMessegeTemplate: SendMessegeTemplateObj ={
      sendMessegeTemplateId: option.sendMessegeTemplateId,
      sendMessegeTemplateName: option.sendMessegeTemplateName,
      sequence: option.sequence
    }
    const selectedOption = sendMessegeTemplateState.filter((i: { sendMessegeTemplateId: string }) => i.sendMessegeTemplateId === sendMessegeTemplate.sendMessegeTemplateId)
    if (selectedOption.length > 0) {
      setSendMessegeTemplateState(sendMessegeTemplateState.filter((i: { sendMessegeTemplateId: string }) => i.sendMessegeTemplateId !== sendMessegeTemplate.sendMessegeTemplateId))
    } else {
      setSendMessegeTemplateState([...sendMessegeTemplateState, sendMessegeTemplate])
    }
    // 次期9月対応 #63457 start 
    // メッセージテンプレートのチェックボックス選択時
    setSearchType('1')
    // 次期9月対応 #63457 end 
  }

  //メッセージ送付状態
  const [sendMessegeStatusState, setSendMessegeStatusState] = useState([] as SendMessegeStatusObj[])
  const handleSendMessegeStatus = (checkbox: SendMessegeStatusObj) => () => {
    const sendMessegeStatus: SendMessegeStatusObj = {
      sendMessegeStatusId: checkbox.sendMessegeStatusId,
      sendMessegeStatusName: checkbox.sendMessegeStatusName,
    }
    const selectedCheckBox = sendMessegeStatusState.filter(i => i.sendMessegeStatusId === sendMessegeStatus.sendMessegeStatusId)
    if (selectedCheckBox.length > 0) {
      setSendMessegeStatusState(sendMessegeStatusState.filter(i => i.sendMessegeStatusId !== sendMessegeStatus.sendMessegeStatusId))
    } else {
      setSendMessegeStatusState([...sendMessegeStatusState, sendMessegeStatus])
    }
    // 次期9月対応 #63457 start 
    // 送信予約中のメッセージを含むチェックボックス
    setSearchType('1')
    // 次期9月対応 #63457 end 
  }

  const handleClick = (open = true) => () => {
    setConfirm(open)
  }

  const handleClose = () => {
    setConfirm(false)
    setMessageTitleFlg(false)
    setError({ isError: false, message: '' })
    onClose()
  }

  const [fromDateErrors, setFromDateErrors] = useState<Errors>({
    errorFlag: false,
    errorInfo: '',
  })

  const [toDateErrors, setToDateErrors] = useState<Errors>({
    errorFlag: false,
    errorInfo: '',
  })

  const [searchTypeCheck, setSearchTypeCheck] = useState(false)
  const handleChangeSearchType = (e: any) => {
    if(undefined !== e.target.value){
      if(searchType === e.target.value){
        setSearchType('')
      } else {
        if (e.target.value === searchOptionList[0].value) {
          if(chipArea.length == 0){
            setSearchTypeCheck(false)
            setSearchType(searchOptionList[0].value)
          }else{
            setSearchTypeCheck(true)
          }
        } else {
          setSearchType(e.target.value)
        }
      }
    }
  }

  const handleChangeSearchTemplateType = (e: any) => {
    if(undefined !== e.target.value){
      setSearchTemplateType(e.target.value)
    }
  }

  const handleChangeDateSelect = (e: any) => {
    if(undefined !== e.target.value){
      if(dateSelect === e.target.value){
        setDateSelect('')
      }else {
        setDateSelect(e.target.value)
      }
    }
    // 次期9月対応 #63457 start 
    // 送付状態の内容変更時
    setSearchType('1')
    // 次期9月対応 #63457 end 
  }

  const handleSubmit = () => {
    setSearchType(searchOptionList[0].value)
    setChipArea([])
    setSearchTypeCheck(false)
  }

  const handleSearchTypeCheck = () => {
    if (dateErrorCheck()) {
      return
    }
    handleClose()
    if (searchType && searchType === searchOptionList[1].value) {
      let messageObj: SendMessageObj = {
        sendMessegeConditionJudgmentFlag: Number(searchType),
        sendMessegeConditionJudgmentName:
          searchType === '0'
            ? 'メッセージを何も送信していない'
            : '特定のメッセージで検索',
        sendMessegeConditionObj: [],
      }
      let checkSameFlag : boolean = true
      //並び順でソート_メッセージテンプレート_sendMessegeTemplateState
      const orderObjArray = sendMessegeTemplateList != null ? sendMessegeTemplateList.map(i=>({sendMessegeTemplateId: i.sendMessegeTemplateId })) : []
      const sendMessegeTemplateStateOrder = [...sendMessegeTemplateState].sort((a, b) => {
          return innerSortFunc(
            { sendMessegeTemplateId: a.sendMessegeTemplateId },
            { sendMessegeTemplateId: b.sendMessegeTemplateId },
            orderObjArray,
          )
      })
      let messageConditionObj: SendMessageConditionObj = {	 	 
        sendMessegeMessageTypeFlag: messageTypeState.value,	 	 
        sendMessegeMessageTypeName: messageTypeState.label,	 	 
        sendMessegeSendConditionFlag: dateSelect ? Number(dateSelect) : null,
        sendMessegeSendConditionName:	 	 
          dateSelect === '0'	 	 
            ? '送付あり'	 	 
            : dateSelect === '1'	 	 
            ? '送付なし'	 	 
            : '',	 	 
        sendMessegeSendDateFrom: dateSelect === '0' ? fromDateState : '',	 	 
        sendMessegeSendDateTo: dateSelect === '0' ? toDateState : '',
        sendMessegeStatusObj: sendMessegeStatusState,
        //メッセージテンプレートstart
        sendMessegeTemplateConditionFlag: searchTemplateType ? Number(searchTemplateType) : 0,
        sendMessegeTemplateConditionName: 
        searchTemplateType === '0' ? '全てと一致' : searchTemplateType === '1' ? 'いずれかに一致' : '',
        sendMessegeTemplateObj: sendMessegeTemplateStateOrder,
        //メッセージテンプレートend
        sendMessegeSubject: messageTitleState,	 	 
        // phase2 start
        sendMessegeMyNaviAttachedOptionEntryBoxId: entryBoxOptionState.value.startsWith('entryBoxId:') ? entryBoxOptionState.value.split(':')[1] : null,
        sendMessegeMyNaviAttachedOptionSeminarId: entryBoxOptionState.value.startsWith('seminarId:') ? entryBoxOptionState.value.split(':')[1] : null,
        sendMessegeMyNaviAttachedOptionName: entryBoxOptionState.label  != '指定しない' ? entryBoxOptionState.label : '',
        // phase2 end
      }
      if (messageConditionObj.sendMessegeMessageTypeFlag === '00' 
        && messageConditionObj.sendMessegeMessageTypeName === '指定しない'
        && messageConditionObj.sendMessegeSendConditionFlag === null
        && messageConditionObj.sendMessegeSendConditionName === ''
        && messageConditionObj.sendMessegeSendDateFrom === ''
        && messageConditionObj.sendMessegeSendDateTo === ''
        && messageConditionObj.sendMessegeStatusObj.length === 0
        && messageConditionObj.sendMessegeTemplateObj.length === 0
        && messageConditionObj.sendMessegeSubject === ''
        // phase2 start
        && !messageConditionObj.sendMessegeMyNaviAttachedOptionEntryBoxId
        && !messageConditionObj.sendMessegeMyNaviAttachedOptionSeminarId
        && !messageConditionObj.sendMessegeMyNaviAttachedOptionName
        // phase2 end
        ) {
          checkSameFlag = false
      }
      if (chipArea && chipArea.length > 0) {
        for (var i = 0; chipArea.length > i; i++) {
          messageObj.sendMessegeConditionObj.push(chipArea[i])
          if (checkSameFlag) {
            checkSameFlag = getCheckSameFlag(chipArea[i], messageConditionObj)
          }
        }
      }
      if (checkSameFlag) {
        messageObj.sendMessegeConditionObj.push(messageConditionObj)
      }
      if (
        messageObj.sendMessegeConditionObj &&
        messageObj.sendMessegeConditionObj.length > 0
      ) {
        form.setFieldValue('sendMessegeObj', messageObj)
      } else {
        form.setFieldValue('sendMessegeObj', null)
      }
    } else if (searchType && searchType === searchOptionList[0].value) {
      let messageObj: SendMessageObj = {
        sendMessegeConditionJudgmentFlag: Number(searchType),
        sendMessegeConditionJudgmentName: searchOptionList[0].label,
        sendMessegeConditionObj: [],
      }
      form.setFieldValue('sendMessegeObj', messageObj)
    } else {
      let messageObj: SendMessageObj = {
        sendMessegeConditionJudgmentFlag: Number(searchOptionList[1].value),
        sendMessegeConditionJudgmentName: searchOptionList[1].label,
        sendMessegeConditionObj: [],
      }
      if (chipArea && chipArea.length > 0) {
        for (var i = 0; chipArea.length > i; i++) {
          messageObj.sendMessegeConditionObj.push(chipArea[i])
        }
      }
      if (
        messageObj.sendMessegeConditionObj &&
        messageObj.sendMessegeConditionObj.length > 0
      ) {
        form.setFieldValue('sendMessegeObj', messageObj)
      } else {
        form.setFieldValue('sendMessegeObj', null)
      }
    }
  }

  const dateErrorCheck = () => {
    setFromDateErrors({
      errorFlag: false,
      errorInfo: '',
    })
    setToDateErrors({
      errorFlag: false,
      errorInfo: '',
    })
    let errorFlag = false
    if (searchType && searchType === searchOptionList[1].value) {
      if (dateSelect === '0') {
        //送付状態が送付ありの場合

        //日付不正チェック
        if (fromDateState !== '') {
          if (!miscRegexSet.date.test(fromDateState)) {
            setFromDateErrors({
              errorFlag: true,
              errorInfo: '送付状態:日付が無効です',
            })
            errorFlag = true
          }
        }

        //日付不正チェック
        if (toDateState !== '') {
          if (!miscRegexSet.date.test(toDateState)) {
            setToDateErrors({
              errorFlag: true,
              errorInfo: '送付状態:日付が無効です',
            })
            errorFlag = true
          }
        }

        //From＞Toチェック
        if (fromDateState !== '' && toDateState !== '') {
          const from = moment(fromDateState)
          const to = moment(toDateState)
          if (from.isAfter(to)) {
            setToDateErrors({
              errorFlag: true,
              errorInfo: '送付状態:終了日が開始日よりも前です',
            })
            errorFlag = true
          }
        }
      }
    }
    if (messageTitleFlg || errorFlag) {
      return true
    }
    return false
  }

  /**
   * 追加ボタン押下イベント
   */
  const handleAddSearch = () => {
    if (dateErrorCheck()) {
      return
    }
    //並び順でソート_メッセージテンプレート_sendMessegeTemplateState
    const orderObjArray = sendMessegeTemplateList != null ? sendMessegeTemplateList.map(i=>({sendMessegeTemplateId: i.sendMessegeTemplateId })) : []
    const sendMessegeTemplateStateOrder = [...sendMessegeTemplateState].sort((a, b) => {
        return innerSortFunc(
          { sendMessegeTemplateId: a.sendMessegeTemplateId },
          { sendMessegeTemplateId: b.sendMessegeTemplateId },
          orderObjArray,
        )
    })
    let messageConditionObj: SendMessageConditionObj = {
      sendMessegeMessageTypeFlag: messageTypeState.value,
      sendMessegeMessageTypeName: messageTypeState.label,
      sendMessegeSendConditionFlag: dateSelect ? Number(dateSelect) : null,
      sendMessegeSendConditionName:
        dateSelect === '0' ? '送付あり' : dateSelect === '1' ? '送付なし' : '',
      sendMessegeSendDateFrom: dateSelect === '0' ? fromDateState : '',
      sendMessegeSendDateTo: dateSelect === '0' ? toDateState : '',
      sendMessegeStatusObj: sendMessegeStatusState,
      //メッセージテンプレートstart
      sendMessegeTemplateConditionFlag: searchTemplateType ? Number(searchTemplateType) : null,
      sendMessegeTemplateConditionName: 
        searchTemplateType === '0' ? '全てと一致' : searchTemplateType === '1' ? 'いずれかに一致' : '',
      sendMessegeTemplateObj: sendMessegeTemplateStateOrder,
      //メッセージテンプレートend
      sendMessegeSubject: messageTitleState,
      // phase2 start
      sendMessegeMyNaviAttachedOptionEntryBoxId: entryBoxOptionState.value.startsWith('entryBoxId:') ? entryBoxOptionState.value.split(':')[1] : null,
      sendMessegeMyNaviAttachedOptionSeminarId: entryBoxOptionState.value.startsWith('seminarId:') ? entryBoxOptionState.value.split(':')[1] : null,
      sendMessegeMyNaviAttachedOptionName: entryBoxOptionState.label != '指定しない' ? entryBoxOptionState.label : '',
      // phase2 end
    }
    let chipvallist: SendMessageConditionObj[] = []
    let checkSameFlag : boolean = true
    if (chipArea && chipArea.length > 0) {
      for (var i = 0; chipArea.length > i; i++) {
        chipvallist.push(chipArea[i])
        if (checkSameFlag) {
          checkSameFlag = getCheckSameFlag(chipArea[i], messageConditionObj)
        }
      }
    }
    if (checkSameFlag) {
      chipvallist.push(messageConditionObj)
    }
    setChipArea(chipvallist)
    setSearchType('')
    setDateSelect('')
    setMessageTypeState(typeOptionList[0])
    setMessageTitleState('')
    setFromDateState('')
    setToDateState('')
    setSendMessegeStatusState(sendMessegeStatusCheckBox)
    setSendMessegeTemplateState([])
    // phase2 start
    setEntryBoxOptionState(optionListNew[0])
    // phase2 end
  }

  const handleDeleteSendMessageChip = (
    option: SendMessageConditionObj
  ) => () => {
    if (chipArea && chipArea.length > 0) {
      let chipvallist: SendMessageConditionObj[] = chipArea
      const newConditionList = chipvallist.filter(i => i !== option)
      if (newConditionList && newConditionList.length > 0) {
        setChipArea([])
        setChipArea(newConditionList)
      } else {
        setChipArea([])
      }
    }
  }

  /**
   * メッセージ送付状況条件を生成
   */
  const generateLabel = (item: SendMessageConditionObj) => {
    let resultText: string = ''
    if (item.sendMessegeMessageTypeName) {
      resultText += item.sendMessegeMessageTypeName
    }
    if (item.sendMessegeSubject) {
      resultText += '　' + '「' + item.sendMessegeSubject + '」が件名に含まれる'
    }
    if (item.sendMessegeSendConditionName) {
      resultText += '　' + item.sendMessegeSendConditionName
    }
    if (item.sendMessegeSendDateFrom && item.sendMessegeSendDateTo) {
      resultText +=
        '(' +
        item.sendMessegeSendDateFrom +
        '～' +
        item.sendMessegeSendDateTo +
        ')'
    }
    if (item.sendMessegeSendDateFrom && !item.sendMessegeSendDateTo) {
      resultText += '(' + item.sendMessegeSendDateFrom + '～)'
    }
    if (!item.sendMessegeSendDateFrom && item.sendMessegeSendDateTo) {
      resultText += '(～' + item.sendMessegeSendDateTo + ')'
    }
    if (item.sendMessegeStatusObj.length > 0) {
      item.sendMessegeStatusObj.map(obj => {
        resultText += '　' + obj.sendMessegeStatusName
      })
    }
    if (item.sendMessegeTemplateObj.length > 0){
      resultText += '　' + contants.MESSAGETEMPLATE + '：' + item.sendMessegeTemplateConditionName
      for(var i = 0; item.sendMessegeTemplateObj.length > i; i++ ){
        const sequence = item.sendMessegeTemplateObj[i].sequence ? item.sendMessegeTemplateObj[i].sequence + '. ' : ''
        resultText += '「' + sequence + item.sendMessegeTemplateObj[i].sendMessegeTemplateName + '」'
      }
    }
    // phase2 start
    if(item.sendMessegeMyNaviAttachedOptionName){
      resultText += '　' + contants.Mynavi_Attached_Option + '：' + '「' + item.sendMessegeMyNaviAttachedOptionName + '」'
    }
    // phase2 end

    return `${resultText}`
  }

  const errClasses = useErrorStyle()

  const stringToEllipsis = (target: String | null) => {
    if (target !== null && target.length > contants.MCAXS541_LEADER_LENGTH) {
      return target.slice(0, contants.MCAXS541_LEADER_LENGTH) + "..."
    } else {
      return target
    }
  }

  const getCheckSameFlag = (chipAreai: SendMessageConditionObj, item2: SendMessageConditionObj) => {
    if(chipAreai.sendMessegeTemplateObj.length !== item2.sendMessegeTemplateObj.length){
      return true
    }
    for(var i = 0; chipAreai.sendMessegeTemplateObj.length > i; i++ ){
      if(chipAreai.sendMessegeTemplateObj[i].sendMessegeTemplateId === item2.sendMessegeTemplateObj[i].sendMessegeTemplateId){
        break
      }else{
        return true
      }
    }
    if (chipAreai.sendMessegeStatusObj.length !== item2.sendMessegeStatusObj.length) {
      return true
    }
    for (var i = 0; chipAreai.sendMessegeStatusObj.length > i; i++) {
      if (chipAreai.sendMessegeStatusObj[i].sendMessegeStatusId === item2.sendMessegeStatusObj[i].sendMessegeStatusId) {
        break
      }else{
        return true
      }
    }
    if (chipAreai.sendMessegeMessageTypeFlag === item2.sendMessegeMessageTypeFlag 
      && chipAreai.sendMessegeMessageTypeName === item2.sendMessegeMessageTypeName 
      && chipAreai.sendMessegeSendConditionFlag === item2.sendMessegeSendConditionFlag 
      && chipAreai.sendMessegeSendConditionName === item2.sendMessegeSendConditionName 
      && chipAreai.sendMessegeSendDateFrom === item2.sendMessegeSendDateFrom 
      && chipAreai.sendMessegeSendDateTo === item2.sendMessegeSendDateTo 
      && chipAreai.sendMessegeSubject === item2.sendMessegeSubject
      && chipAreai.sendMessegeMyNaviAttachedOptionName === item2.sendMessegeMyNaviAttachedOptionName) {
        return false
    }
    return true
  }
  // phase2 start
  const [error, setError] = useState({ isError: false, message: '' })
  const [messageTitleFlg, setMessageTitleFlg] = useState(false)
  const messageTitleStateYup = yup.object().shape({
    tagName: yup.string(),
  })
  const handleChangeMessageTitleState = (value: any) => {
    messageTitleStateYup.validate({ tagName: value }).then().catch(err => {
        setError({ isError: true, message: err.errors })
        setMessageTitleFlg(true)
    })
    setMessageTitleFlg(false)
    setMessageTitleState(value)
    setError({ isError: false, message: '' })

    // 次期9月対応 #63457 start 
    // 件名テキストボックスの内容変更時
    setSearchType('1')
    // 次期9月対応 #63457 end 
  }
  // phase2 end
  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>メッセージ送付状況を指定</label>
        </DialogTitle>
        <DialogContent>
          <div className={`ps-container ps ${classes.root}`}>
            <RadioGroup onClick={handleChangeSearchType}>
              {searchOptionList
                ? searchOptionList.map((item, index1) => (
                    <>
                      <FormControlLabel
                        control={
                          <Radio
                            color='primary'
                            checked={item.value === searchType}
                            value={item.value}
                          />
                        }
                        label={item.label}
                      />
                    </>
                  ))
                : null}
            </RadioGroup>
            <FieldGroup>
              <FormField>
                <Title title='メッセージ種別' />
                <ReactSelect
                  styles={{
                    input: () => ({
                      margin: 0,
                      paddingBottom: 0,
                      paddingTop: 0,
                    }),
                    menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
                  }}
                  value={messageTypeState}
                  onChange={(option: any) => {
                    setMessageTypeState(option)
                    // 次期9月対応 #63457 start
                    // メッセージ種別でリストの項目を選択時"特定のメッセージで検索"をチェックありにする。
                    setSearchType('1')
                    // 次期9月対応 #63457 end
                  }}
                  options={typeOptionList}
                  className = {classes.reactSelectCss}
                  filterOption={customSelectOptions.filterOption}
                />
              </FormField>
              <FormField>
                <Title title='件名' />
                <div className={error.isError ? `${classes.errorInput}` : ''}>
                  <input
                    name='tagName'
                    id='messageTitle'
                    type='text'
                    value={messageTitleState}
                    onChange={(e: any) => {
                      handleChangeMessageTitleState(e.target.value)
                    }}
                    placeholder='検索したい文字列を入力してください'
                    className={classes.inputRoot}
                  />
                  <div style = {{color : '#e53935' , fontSize: '11px', paddingLeft: '14px'}}>
                  {error.isError && error.message !== '' ? error.message : null}</div>
                </div>
              </FormField>
              <FormField>
                <Title title='送付状態' />
                <div className={classes.group}>
                  <RadioGroup row onClick={handleChangeDateSelect}>
                    {sendStatusOptionList
                      ? sendStatusOptionList.map((item, index2) => (
                          <>
                            <FormControlLabel
                              control={
                                <Radio
                                  color='primary'
                                  checked={item.value === dateSelect}
                                  value={item.value}
                                />
                              }
                              label={item.label}
                            />
                          </>
                        ))
                      : null}
                  </RadioGroup>
                  <div className={classes.dateRange}>
                    <Field
                      component={DatePicker}
                      id='messageStatusFrom'
                      selectsStart
                      disableFlag={dateSelect !== '0'}
                      value={fromDateState}
                      onChange={(value: string) => {
                        setFromDateErrors({
                          errorFlag: false,
                          errorInfo: '',
                        })
                        setFromDateState(value)
                        // 次期9月対応 #63457 start
                        // 送付ありの日付入力時"特定のメッセージで検索"をチェックありにする。
                        setSearchType('1')
                        // 次期9月対応 #63457 end
                      }}
                      placeholder='YYYY/MM/DD'
                      messageModalErrors={fromDateErrors}
                    />
                    <div style={{ margin:'0px 8px 24px 0px' }}>
                      {' ～ '}
                    </div>
                    <Field
                      component={DatePicker}
                      id='messageStatusTo'
                      selectsEnd
                      disableFlag={dateSelect !== '0'}
                      value={toDateState}
                      onChange={(value: string) => {
                        setToDateErrors({
                          errorFlag: false,
                          errorInfo: '',
                        })
                        setToDateState(value)
                        // 次期9月対応 #63457 start
                        // 送付ありの日付入力時"特定のメッセージで検索"をチェックありにする。
                        setSearchType('1')
                        // 次期9月対応 #63457 end
                      }}
                      placeholder='YYYY/MM/DD'
                      messageModalErrors={toDateErrors}
                    />
                  </div>
                </div>
              </FormField>
              <FormField>
                <Title title='' />
                <div className={classes.msgStatusALL}>
                  {sendMessegeStatusCheckBox.map(checkBox => (
                    <label className={classes.msgTemplateOne}>
                      <input 
                        type="checkbox" 
                        onClick={handleSendMessegeStatus(checkBox)}
                        checked={sendMessegeStatusState.some((item: { sendMessegeStatusId: any }) => item.sendMessegeStatusId === checkBox.sendMessegeStatusId)} />
                      <mark className={classes.mark}></mark>
                      <label className={classes.msgTemplateText}>{checkBox.sendMessegeStatusName}</label>
                    </label>
                  ))}
                </div>
              </FormField>
              {/* メッセージテンプレート */}
              
              <FormField className={classes.templateMagrin}>
                  <div className={classes.groupBlock}>
                    <Title title='メッセージテンプレート' />
                    <RadioGroup row onClick={handleChangeSearchTemplateType}>
                      {searchOptionTemplateList
                        ? searchOptionTemplateList.map((item, index3) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Radio
                                    color='primary'
                                    checked={searchTemplateType === item.value}
                                    value={item.value}
                                  />
                                }
                                label={item.label}
                              />
                            </>
                          ))
                        : null}
                    </RadioGroup>
                  </div>
                  <div className={classes.msgTemplateAll}>
                    {sendMessegeTemplateList
                      ? sendMessegeTemplateList
                          .map(option => (
                            <label className={classes.msgTemplateOne}>
                                <input
                                  onClick={handleSelect(option)}
                                  type="checkbox" 
                                  checked={sendMessegeTemplateState.some((item: { sendMessegeTemplateId: any }) => item.sendMessegeTemplateId === option.sendMessegeTemplateId)} />
                                <mark className={classes.mark}></mark>
                                <label className={classes.msgTemplateText}>{option.sequence ? option.sequence + '. ' : ''}{option.sendMessegeTemplateName}</label>
                            </label>
                          ))
                      : null}
                  </div>
              </FormField>
              <FormField>
              <Title title='マイナビ添付オプション' />
              <ReactSelect
                  styles={{
                    input: () => ({
                      margin: 0,
                      paddingBottom: 0,
                      paddingTop: 0,
                    }),
                    menu: (provided: any) => ({ ...provided, zIndex: 1000}),
                    option: (provided: any) => ({ ...provided, whiteSpace: 'nowrap' }),
                  }}
                  value={entryBoxOptionState}
                  onChange={(option: any) => {
                    setEntryBoxOptionState(option)
                    // 次期9月対応 #63457 start 
                    // マイナビ添付オプションでリストの項目を選択時"特定のメッセージで検索"をチェックありにする。
                    setSearchType('1')
                    // 次期9月対応 #63457 end 
                  }}
                  noOptionsMessage = {({ inputValue }) =>
                  optionLabelList.includes(inputValue)
                    ? `${inputValue}`
                    : noOptionsMessage
                }
                  options={optionListNew}
                  className = {classes.reactSelectCss}
                  menuPosition={'fixed'}
                  menuPlacement={'auto'}
                  components={{MenuList}}
                />
              </FormField>
              {/* <div className={classes.errorContent}>
                <div className={classes.errorText}>
                  <p className={errClasses.helperText}>
                    {errors.errorFlag ? errors.errorInfo : null}
                  </p>
                </div>
              </div>
              <div className={classes.addactions}>
                <Button
                    onClick={handleAddSearch}
                    color='primary'
                    variant='contained'
                    className={classes.confirmButton}
                    disabled={searchType !== '1' ? true : false}>
                    追加
                </Button>
              </div>
              {
                chipArea && chipArea.length > 0 ?
                <>
                  <hr style={{ backgroundColor: '#95AFDE', height: '2px', border: 'none' }} />
                  <div style={{ margin: '8px', fontSize: '16px' }}>
                    {'指定済みの条件'}
                  </div>
                </>
                : <></>
              }
              <div className={classes.chipcontent}>
                {chipArea.map((item, index) => (
                  <div title={generateLabel(item).length > contants.MCAXS541_LEADER_LENGTH ? generateLabel(item) : undefined} 
                    style= {{ minWidth: '205px', maxWidth: '305px' }}>
                    <Chip
                      className={classes.chip}
                      label={stringToEllipsis(generateLabel(item))}
                      onDelete={handleDeleteSendMessageChip(item)}
                      deleteIcon={<CancelIcon />}
                    />
                  </div>
                ))}
              </div> */}
            </FieldGroup>
          </div>
        </DialogContent>
              <div className={classes.addactions}>
                <Button
                    onClick={handleAddSearch}
                    color='primary'
                    variant='contained'
                    className={classes.confirmButton}
                    disabled={searchType !== '1' ? true : false}>
                    追加
                </Button>
              </div>
              {
                chipArea && chipArea.length > 0 ?
                <>
                  <div>
                    <hr style={{ backgroundColor: '#95AFDE', height: '2px', border: 'none', margin: '12px 24px 12px 24px' }} />
                  </div>
                  <div style={{ margin: '0px 24px 0px 24px', fontSize: '16px' }}>
                    {'指定済みの条件'}
                  </div>
                </>
                : <><div style={{minHeight: '50px'}}></div></>
              }
              <div style= {{ minHeight: '43px', maxHeight: '43px',margin: '0px 0px 0px 24px', overflowY: 'auto'}}>
                <div className={classes.chipcontent}>
                  {chipArea.map((item, index) => (
                    <div title={generateLabel(item).length > contants.MCAXS541_LEADER_LENGTH ? generateLabel(item) : undefined} 
                      style= {{ flexShrink: 0 }}>
                      <Chip
                        className={classes.chip}
                        label={stringToEllipsis(generateLabel(item))}
                        onDelete={handleDeleteSendMessageChip(item)}
                        deleteIcon={<CancelIcon />}
                      />
                    </div>
                  ))}
                </div>
              </div>
        
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClick()}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSearchTypeCheck}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS541_002}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={searchTypeCheck} onClose={() => setSearchTypeCheck(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS541_001}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setSearchTypeCheck(false)}>キャンセル</Button>
          <Button onClick={handleSubmit} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MessageModal
