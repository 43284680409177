import Grid from '@material-ui/core/Grid'
import { routeList } from 'routes/routes'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { dateToString, stringToDate } from 'common/generalUtil'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import history from 'utils/history'
import { magiContants as contants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { globalMenuNews, searchRecruitmentStatusReportList, setItemCodeValResult } from 'reducers/recruitmentStatusReportReducer'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: 1060,
    boxShadow: 'none'
  },
  btLeft: {
    textAlign:'center',
    marginLeft:'20px !important',
    wordBreak:'break-all'
  },
  // underline
  textDecoration: {
    textDecoration: "underline"
  },
  marginTop: {
    marginTop: 10
  },
  marginTop_6: {
    margin: '8px !important',
    alignItems: 'center',
  },
  disorder: {
    alignItems: 'center',
    backgroundColor: '#f5c4dc',
    display: 'inline-flex',
    fiexBasis: '150px',
    justifyContent: 'center',
    margin: '8px',
    width: '150px',
    fontColor: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontVariant: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    borderRadius: '12px',
    height: '24px'
  },
  maintenance: {
    alignItems: 'center',
    backgroundColor: '#dbc4f5',
    display: 'inline-flex',
    fiexBasis: '150px',
    justifyContent: 'center',
    margin: '8px',
    width: '150px',
    fontColor: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontVariant: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    borderRadius: '12px',
    height: '24px'
  },
  release: {
    alignItems: 'center',
    backgroundColor: '#cef0ac',
    display: 'inline-flex',
    fiexBasis: '150px',
    justifyContent: 'center',
    margin: '8px',
    width: '150px',
    fontColor: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontVariant: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    borderRadius: '12px',
    height: '24px'
  },
  information: {
    alignItems: 'center',
    backgroundColor: '#b6d3f0',
    display: 'inline-flex',
    fiexBasis: '150px',
    justifyContent: 'center',
    margin: '8px',
    width: '150px',
    fontColor: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontVariant: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    borderRadius: '12px',
    height: '24px'
  },
  display: {
    marginLeft: '10px !important',
    display: 'flex'
  },
  fontSize: {
    fontSize: 18,
    fontWeight: 600
  },
  linkButton: {
    display: 'table-cell!important',
    color: '#3380cc',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
      border: '0.2px dashed grey',
    },
  },
}));

interface Props {
  setOpen: (state: boolean) => void
}
const MCAAS020 = ({ setOpen }: Props) => {
  const classes = useStyles();
  const newsInfo = useSelector((state: RootState) => state.globalMenu.newsInfo)
  const dispatch = useDispatch()
  const globalMenu = useSelector((state: RootState) => state.globalMenu)
  const handleClick = () => {
    setOpen(false)
    dispatch(setItemCodeValResult('1'))
    const detailInitialValues = {
        recruitmentManagementDivision: globalMenu.recruitmentManagementDivision, //採用管理区分
        lastLogoutTime: globalMenu.lastLogoutTime, // 最終ログアウト日時
        preLoginTime: globalMenu.previousLoginTime, // 前回ログイン日時
        lastLoginTime: globalMenu.lastLoginTime, // 最終ログイン日時 
      }
      dispatch(searchRecruitmentStatusReportList(detailInitialValues))
    history.push(routeList.home)
  }
  const handle = (newsId: string) => {
    const initialState = {
      newsId: newsId, // お知らせID
    }
    dispatch(globalMenuNews({param1: initialState, param2: globalMenu.recruitmentManagementDivision}))
  }

  return (
    <div>
      {
        newsInfo.length === 0 ?
          <Paper className={classes.paper}>
            <Grid container className={classes.fontSize}>
              {/** 転職March #75621 START */}
              <div dangerouslySetInnerHTML={{
                __html:
                  (globalMenu.recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2
                    && globalMenu.serviceMode === contants.SERVICEMODE_MARCH)
                    ? getMessage(contants.MESSAGECODE_MCAAS020_00A)
                    : getMessage(contants.MESSAGECODE_MCAAS020_00A) + getMessage(contants.MESSAGECODE_MCAAS020_00C)
              }} />
              {!(globalMenu.recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2
                && globalMenu.serviceMode === contants.SERVICEMODE_MARCH) &&
                <button
                  type='button'
                  className={`${classes.linkButton} ${classes.textDecoration}`}
                  onClick={handleClick} >
                  <div dangerouslySetInnerHTML={{ __html: getMessage(contants.MESSAGECODE_MCAAS020_00B) }} />
                </button>
              }
              {/** 転職March #75621 END */}
            </Grid>
          </Paper>
          :
          <Paper className={classes.paper}>
            <Grid container className={classes.fontSize}>
              {/** 転職March #75621 START */}
              <div dangerouslySetInnerHTML={{
                __html:
                  (globalMenu.recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2
                    && globalMenu.serviceMode === contants.SERVICEMODE_MARCH)
                    ? getMessage(contants.MESSAGECODE_MCAAS020_000)
                    : getMessage(contants.MESSAGECODE_MCAAS020_000) + getMessage(contants.MESSAGECODE_MCAAS020_00C)
              }} />
              {!(globalMenu.recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2
                && globalMenu.serviceMode === contants.SERVICEMODE_MARCH) &&
                <button
                  type='button'
                  className={`${classes.linkButton} ${classes.textDecoration}`}
                  onClick={handleClick} >
                  <div dangerouslySetInnerHTML={{ __html: getMessage(contants.MESSAGECODE_MCAAS020_00B) }} />
                </button>
              }
              {/** 転職March #75621 END */}
            </Grid>
            {
              newsInfo.map(temp => (
                <Grid container key={temp.newsId} className={classes.marginTop}>
                  <Grid item xs={2}> {temp.category === "10" ?
                    <span className={classes.disorder}>障害</span>
                    : temp.category === "20" ?
                      <span className={classes.maintenance}>メンテナンス</span>
                      : temp.category === "30" ?
                        <span className={classes.release}>リリース</span>
                        : temp.category === "40" ?
                          <span className={classes.information}>インフォメーション</span>
                          : ""
                  }</Grid>
                  <Grid item xs={9} className={`${classes.marginTop_6} ${classes.display}`}>
                   {dateToString(stringToDate(temp.publicStartDate))}
                   <button
                      type='button'
                      className={`${classes.linkButton} ${classes.btLeft}`}
                      onClick={() => handle(temp.newsId)}>
                    {temp.subject}
                  </button>
                </Grid>
                </Grid>
              ))
            }
          </Paper>
      }
    </div>
  );
};
export default MCAAS020;
