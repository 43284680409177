import request from 'utils/request'
import { MCAZS091InitRequest } from 'types/MCAZS091/MCAZS091InitRequest'
import { MCAZS091RegisterRequest } from 'types/MCAZS091/MCAZS091RegisterRequest'

export const initRequest = (apiData: MCAZS091InitRequest) => {
  return request({
    url: '/MCAZS091/init',
    method: 'post',
    data: apiData,
  })
}
export const redistRequest = (apiData: MCAZS091RegisterRequest, files: any[]) => {
  const data = new FormData()
  data.append('sendRegister', encodeURIComponent(JSON.stringify(apiData)))
  const attachmentList = files
  if (attachmentList) {
    attachmentList.map(attachment => {
      if (attachment) {
        data.append('attachment', attachment)
      }
    })  
  }

  return request({
    url: '/MCAZS091/sendRegister',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },

  })
}
