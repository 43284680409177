import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAZS240NewRequest } from 'types/MCAZS240/MCAZS240NewRequest'
import { MCAZS240OrverWriteRequest } from 'types/MCAZS240/MCAZS240OrverWriteRequest'
import { MCAZS240InitRequest } from 'types/MCAZS240/MCAZS240InitRequest'

const messageInitRequest: MCAZS240InitRequest = {
  selectedMessageTemplateSettingId: '',
  entryId: [],
  sendTimeFlag: '',
  successfulCountEmail: 0,
  failedCount: 0,
  messageTemplateSettingId: '',
  templateName: '',
}

interface templateNameOption {
  value: string
  label: string
  sysVersionNumber: string
  templateType: string
}

const initialState: {
  messageInitRequest: MCAZS240InitRequest
  newTempName: string
  templateNameOption: templateNameOption
} = {
  messageInitRequest: messageInitRequest,
  newTempName: '',
  templateNameOption: {
    value: '',
    label: '',
    sysVersionNumber: '',
    templateType: '',
  },
}

const mcbMessageSendCompSlice = createSlice({
  name: 'mcbMessageSendComp',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<MCAZS240InitRequest>) {
      console.log('Reducer getInit')
      const initRequest = action.payload
      return state
    },
    newTemplete(
      state,
      action: PayloadAction<{
        formData: MCAZS240NewRequest
      }>
    ) {
      return state
    },
    orverWrite(
      state,
      action: PayloadAction<{
        formData: MCAZS240OrverWriteRequest
      }>
    ) {
      return state
    },
    setTemplateNameSelect(state, action: PayloadAction<templateNameOption>) {
      state.templateNameOption = action.payload
      return state
    },
  },
})

export const {
  getInit,
  newTemplete,
  orverWrite,
  setTemplateNameSelect,
} = mcbMessageSendCompSlice.actions
export default mcbMessageSendCompSlice.reducer
