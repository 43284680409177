import request from 'utils/request'
import {MCAPS010InitRequest} from 'types/MCAPS010/MCAPS010InitRequest'
import {MCAPS010IdRequest} from 'types/MCAPS010/MCAPS010IdRequest'
// 画面初期表示
export const initApi = (data:MCAPS010InitRequest) =>
  request({
    url: `/MCAPS010/init`,
    method: 'post',
    data
  })


  export const registerApi = (data:MCAPS010IdRequest) =>{
  const formData = new FormData();
  formData.append("uploadFiletmp",data.uploadFile);
  formData.append("jsonData",encodeURIComponent(JSON.stringify(data)))
  return request({
    url: `/MCAPS010/upload`,
    method: 'post',
    data:formData,
  })
  }