import { getMessage } from 'common/messageUtil'

/** 初期表示リクエスト */
export interface MessageInitRequest {
  messageSendId: string[]
  entryId: number
  selectionManagementId: string[]
  searchCriteria: string
  replyMessageSendId: string
  draftMessageSendId: string
  sysVersionNumber: string
}

export interface PreviewRequest {
  entryId: number[]
  companyId: number
}

export interface InputValues {
  homepageTitle1: string
  homepageTitle2: string
  homepageTitle3: string
  homepageUrl1: string
  homepageUrl2: string
  homepageUrl3: string
  sendTarget: string
  sendPlanTime: Date | null
  sendTimeFlag: string
  senderCompanyName: string
  senderMailAddress: string
  submissionsSelectId: number
  submissionsNameForCompany: string
  submissionsNameForStudent: string
  submissionsDetails: string
  limitDateDetermineFlag: string
  determineDateTime: string
  requestDayTimeLaterDay: string
  requestDayTimeToTime: string
  anytimeReceptionEndDateTime: string
  reRequestReceptFlag: string
  studentReferenceId: number // お問い合わせ先ID
  contactName: string // お問い合わせ先名
  companyName: string // お問い合わせ先内容企業名
  departmentNameAndManagerName: string // お問い合わせ先内容部署名担当者氏名
  telephoneNumber: string // お問い合わせ先内容電話番号
  mailAddress: string // お問い合わせ先内容メールアドレス
  requestSubject: string // 件名
  requestText: string // 本文
  thanksMessageSetFlag: string  //サンクスメール設定フラグ
  thanksMessageTemplateId: number // サンクスメールテンプレートID
  thanksMessageTemplateName: string // サンクスメールテンプレート名	
  thanksSubject: string // サンクスメール件名
  thanksText: string // サンクスメール本文	
  personalDataHandlingId: number// 個人情報の取り扱いID
  personalDataHandling: string // 個人情報の取り扱い名	
  submissionEntrySheetFlag:number
  submissionResumeFlag:number
  submissionResearchSummaryFlag:number
  submissionOriginalQuestionFlag:number
  submissionFileSubmissionFlag:number
  templateName: {
    label: string, value: string
  }
}

const submissionInitRequest = {
  jobSeekerByCompanyIdList:null,
  entryIdList: null,
  jobSeekerIdList: null,
  selectionManagementIdList: null,
  searchCriteriaName:'',
  searchCriteriaText: '',
  draftMessageSendId: '',
  sysVersionNumber: '',
  recruitmentManagementDivision:'',
  messageSendId:null,
  status: ''
}

const targetTextAreas = {
  submissionReqSubject: 'requestSubject',
  submissionReqBody: 'requestText',
  thanksEmailSubject: 'thanksSubject',
  thanksEmailBody: 'thanksText',
}

const inputList = {
  senderCompanyName: {
    max: 200,
  },
  senderMailAddress: {
    max: 256,
  },
  submissionReqSubject: {
    max: 200,
  },
  submissionReqBody: {
    max: 2000,
  },
  homePageUrl: {
    max: 256,
  },
  homePageTitle: {
    max: 80,
  },
  notifyMailAddress: {
    max: 160,
  },
  thanksEmailSubject: {
    max: 200,
  },
  thanksEmailBody: {
    max: 2000,
  },
}
const textMap = {
  confirm: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: '入力された内容で更新します。よろしいですか？',
  },
  cancel: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCBHS010-069'),
  },
  draft: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCBHS010-009'),
  },
  draftSaveComplete: {
    submit: 'OK',
    cancel: '',
    message: getMessage('MCBHS010-018'),
  },
  copyFinish: {
    submit: 'OK',
    cancel: '',
    message: '応募者管理IDをクリップボードにコピーしました。',
  },
}

export const resubmissionList = [
  { value: '1', label: '受け付ける' },
  { value: '0', label: '受け付けない' },
]

export const deadlineAfterDayList = [
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
];

export const deadlineAfterDayTimeList = [
  { label: '00', value: '00' },
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
];
export { submissionInitRequest, inputList, targetTextAreas, textMap }
