import React, { useEffect, useState, ReactNodeArray } from 'react'
import { FieldProps } from 'formik'
import Select, { MenuListComponentProps, createFilter } from 'react-select'
import ReactSelect from 'react-select'
import { FixedSizeList as List } from 'react-window'
import { makeStyles } from '@material-ui/core'
import { magiContants } from 'utils/contants'
import CreatableSelect from 'react-select/creatable'
import customSelectOptions from 'utils/customSelectOptions'

const customStyles = {
  input: () => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
  container: (provided: any) => ({ ...provided, maxWidth: '50vw' }),
  multiValue: (provided: any) => ({ ...provided, maxWidth: '34vw' }),
  multiValueLabel: (provided: any) => ({ ...provided, maxWidth: '100%' }),
  option: (provided: any) => ({ ...provided, whiteSpace: 'nowrap' }),
}

const useStyles = makeStyles(theme => ({
  test: {
    color: 'hsl(0,0%,60%)',
    textAlign: 'center',
    fontSize: '1.4rem',
    paddingTop: '0.6rem',
  },
}))

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  noOptionsMessage: string
  defaultValue?: any
  placeholder?: string
  className?: string
  isMulti?: boolean
  isCreatable?: boolean
}

export const FastSelect = ({
  isCreatable = false,
  isMulti,
  className,
  optionList,
  noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE,
  defaultValue,
  placeholder,
  field,
  form,
}: Props) => {
  const { name, value } = field

  const handleChange = (option: any) => {
    if (isMulti) {
      form.setFieldValue(
        name,
        option ? (option as Option[]).map(i => i.value) : []
      )
    } else {
      form.setFieldValue(name, option.value)
    }
  }
  const [formValue, setDefaultValue] = useState(defaultValue)
  useEffect(() => {
    if (defaultValue != null) {
      setDefaultValue(defaultValue)
    }
  }, [defaultValue])

  const defaultValueCheck = (defaultValue: any) => {
    const colourOptions =
      defaultValue !== null
        ? defaultValue.map((i: any) => {
            return { label: i, value: i }
          })
        : null
    return colourOptions
  }

  return isCreatable ? (
    <CreatableSelect
      components={optimizeSelect.components}
      filterOption={createFilter({ ignoreAccents: false })}
      isMulti={isMulti}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={formValue !== undefined ? defaultValueCheck(formValue) : null}
      placeholder={placeholder}
      onChange={handleChange}
      isClearable={false}
      noOptionsMessage={({ inputValue }) =>
        inputValue === ''
          ? noOptionsMessage
          : `${inputValue}${magiContants.SUGGEST_ALREADY_INPUT}`
      }
      formatCreateLabel={(inputValue: any) => (
        <p>
          "{inputValue}"{magiContants.MESSAGEINFO_NEW_REGULATIONS_ADD}
        </p>
      )}
      {...(customSelectOptions as any)}
    />
  ) : (
    <ReactSelect
      components={optimizeSelect.components}
      isMulti={isMulti}
      filterOption={createFilter({ ignoreAccents: false })}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={formValue !== undefined ? defaultValueCheck(formValue) : null}
      placeholder={placeholder}
      onChange={handleChange}
      noOptionsMessage={({ inputValue }) =>
        optionList.map(i => i.label).includes(inputValue)
          ? `${inputValue}${magiContants.SUGGEST_ALREADY_INPUT}`
          : noOptionsMessage
      }
      {...(customSelectOptions as any)}
    />
  )
}

export const FastSelectOptions = ({
  isMulti,
  className,
  optionList,
  noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE,
  defaultValue,
  placeholder,
  field,
  form,
}: Props) => {
  const { name, value } = field

  const handleChange = (option: any) => {
    if (isMulti) {
      form.setFieldValue(name, option ? (option as Option[]).map(i => i) : [])
    } else {
      form.setFieldValue(name, option as Option)
    }
  }

  const [formValue, setDefaultValue] = useState(defaultValue)
  useEffect(() => {
    if (defaultValue != null) {
      setDefaultValue(defaultValue)
    }
  }, [defaultValue])

  const optionLabelList = optionList.map(i => i.label)

  return (
    <Select
      filterOption={customSelectOptions.filterOption}
      isMulti={isMulti}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={formValue !== undefined ? defaultValue : null}
      noOptionsMessage={({ inputValue }) =>
        optionLabelList.includes(inputValue)
          ? `${inputValue}${magiContants.SUGGEST_ALREADY_INPUT}`
          : noOptionsMessage
      }
      placeholder={placeholder}
      onChange={handleChange}
      isClearable={false}
    />
  )
}

const OptimizedMenuList = (props: MenuListComponentProps<Option>) => {
  const classes = useStyles()
  const reactNodeArray = props.children as ReactNodeArray
  const height = 37

  const hasOptions =
    reactNodeArray && Array.isArray(reactNodeArray) ? true : false

  const menuHeight = height * reactNodeArray.length > props.maxHeight ? props.maxHeight : height * reactNodeArray.length

  return hasOptions ? (
    <List
      outerRef={props.innerRef}
      height={menuHeight}
      itemCount={reactNodeArray.length}
      itemSize={height}
      width={''}>
      {({ index, style }) => <div style={style}>{reactNodeArray[index]}</div>}
    </List>
  ) : (
    <List
      outerRef={props.innerRef}
      height={height}
      itemCount={1}
      itemSize={height}
      width={''}>
      {({ index, style }) => (
        <div className={classes.test}>
          <p>{(reactNodeArray as any).props.children}</p>
        </div>
      )}
    </List>
  )
}

const optimizeSelect = {
  components: {
    MenuList: OptimizedMenuList,
  },
}
