import request from 'utils/request'
import { MCALS040ProcessRequest } from 'types/MCALS040/MCALS040ProcessRequest'

export const initRequestApi = () =>
  request({
    url: 'MCALS040/init',
    method: 'post',
  })

// 登録する／更新する／削除するボタン押下
export const setTemplateDbProcessApi = (params: MCALS040ProcessRequest) =>
  request({
    url: '/MCALS040/decide',
    method: 'post',
    data: params,
  })
