import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { upditValues } from 'pages/MCAKS020/formConfig'
import { MCAKS010DeleteIdRequest } from 'types/MCAKS010/MCAKS010DeleteIdRequest'
import { MCAKS010QueryRequest } from 'types/MCAKS010/MCAKS010QueryRequest'
import { MCAKS020UpdateRequest } from 'types/MCAKS020/MCAKS020UpdateRequest'
import { MCAXS170InsertNameRequest } from 'types/MCAXS170/MCAXS170InsertNameRequest'
import { MCAXS170InitRequest } from 'types/MCAXS170/MCAXS170InitRequest'
import { MCAXS170AddListJobchangeRequest } from 'types/MCAXS170/MCAXS170AddListJobchangeRequest'
import { deleteValues, submitValues } from '../pages/MCAKS010/formConfig'
import * as apiConfig from 'pages/MCAXS170/apiConfig'
import { MCAXS170InitResult } from 'types/MCAXS170/MCAXS170InitResult'

// 一覧画面の検索結果
export interface TagManagementList {
  [key: string]: string | number
  tagSettingId: number //タグ設定ID
  tagName: string //タグ名称
  useNumber: number //利用数
  lastUseTime: string //最終利用日時
  fullName: string //氏名
  sysVersionNumberTapr: number //sysバージョン番号（タグ設定）
  sysVersionNumberTuif: number //sysバージョン番号（タグ利用情報）
  sysVersionNumberCact: number //sysバージョン番号（企業アカウント）
}

export interface MessageFlag {
  [key: string]: number
  messageFlagVal: number
}

const messageFlag = {
  messageFlagVal: -1
}

interface TagManagement {
  // 検索条件
  TagManagementListSearchCondition: MCAKS010QueryRequest
  // 削除条件
  TagManagementDeleteCondition: MCAKS010DeleteIdRequest
  // 検索結果
  TagManagementListResults: TagManagementList[]
  // 削除結果
  DeleteIdRequestCondition: string
  // チェック結果
  CheckLogOutFlg: boolean
  // タグ名変更条件
  TagManagementNameUpdCondition: MCAKS020UpdateRequest
  // タグを指定する初期表示
  TagManagementInit: MCAXS170InitRequest
  // タグを指定する初期表示結果
  TagManagementInitResult: MCAXS170InitResult
  // タグを指定する画面のタグ選択状態
  selectedTag: Option[] | null
  // タグを指定する画面の表示フラグ
  show: boolean
  TagSettings: number[]
  messageFlagResult: MessageFlag
}
interface Option {
  value: string
  label: string
}
const tagManageList: TagManagement = {
  TagManagementListSearchCondition: submitValues,
  TagManagementDeleteCondition: deleteValues,
  TagManagementListResults: [],
  DeleteIdRequestCondition: '',
  CheckLogOutFlg: false,
  TagManagementNameUpdCondition: upditValues,
  TagManagementInit: apiConfig.initRequest,
  TagManagementInitResult: apiConfig.initResult,
  selectedTag: [],
  show: false,
  TagSettings: [],
  messageFlagResult: messageFlag,
}

const tagManagementSlice = createSlice({
  name: 'tagManagement',
  initialState: tagManageList,
  reducers: {
    // "検索する"ボタン押下時にDBを検索
    selectTagManagementList(
      state,
      action: PayloadAction<MCAKS010QueryRequest>
    ) {
      return state
    },
    setTagManagementList(state, action: PayloadAction<TagManagementList[]>) {
      state.TagManagementListResults = action.payload
      // データ読み取り後
      if (action.payload.length > 0) {
        state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },

    // "検索する"ボタン押下時に検索条件を保持
    setTagManagemenSearchCondition(
      state,
      action: PayloadAction<MCAKS010QueryRequest>
    ) {
      return state
    },
    setTagManagementListSearchCondition(
      state,
      action: PayloadAction<MCAKS010QueryRequest>
    ) {
      state.TagManagementListSearchCondition = action.payload
      return state
    },

    // "削除"ボタン押下時にDBを更新
    deleteTagManagement(state, action: PayloadAction<MCAKS010DeleteIdRequest>) {
      return state
    },
    deleteTagManagementFlg(state, action: PayloadAction<string>) {
      state.DeleteIdRequestCondition = action.payload
      return state
    },
    deleteTagManagementCheck(state, action: PayloadAction<boolean>) {
      state.CheckLogOutFlg = action.payload
      return state
    },
    deleteOkTagManagement(
      state,
      action: PayloadAction<MCAKS010DeleteIdRequest>
    ) {
      return state
    },
    deleteNgTagManagement(state, action: PayloadAction<boolean>) {
      return state
    },

    // タグ名変更：初期処理
    updateTagManagementInit: state => state,

    // タグ名変更："変更する"ボタン押下時にDBを更新
    updateTagManagement(state, action: PayloadAction<MCAKS020UpdateRequest>) {
      return state
    },

    insertTag(state, action: PayloadAction<MCAXS170InsertNameRequest>) {
      return state
    },

    insertTagJob(
      state,
      action: PayloadAction<{
        request: MCAXS170AddListJobchangeRequest
        onClose?: (event: any) => void
        event?: any
        onCloseApplicantList?: (event: any) => void
        selectedTag: Option[] | null
      }>
    ) {
      return state
    },

    getinitTag(state, action: PayloadAction<MCAXS170InitRequest>) {
      state.TagManagementInit = action.payload
      return state
    },

    setinitTag(state, action: PayloadAction<MCAXS170InitResult>) {
      state.TagManagementInitResult = action.payload
      return state
    },

    setSelectedTag(state, action: PayloadAction<Option[] | null>) {
      state.selectedTag = action.payload
      return state
    },

    setShow(state, action: PayloadAction<boolean>) {
      state.show = action.payload
      return state
    },
    setTagSettings(state, action: PayloadAction<number[]>) {
      state.TagSettings = action.payload
      return state
    },
    setMessageFlagResult(state, action: PayloadAction<number>) {
      state.messageFlagResult.messageFlagVal = action.payload
    },
  },
})

export const {
  selectTagManagementList,
  setTagManagementList,
  setTagManagemenSearchCondition,
  setTagManagementListSearchCondition,
  deleteTagManagement,
  deleteTagManagementFlg,
  deleteTagManagementCheck,
  deleteOkTagManagement,
  deleteNgTagManagement,
  updateTagManagementInit,
  updateTagManagement,
  insertTag,
  getinitTag,
  setinitTag,
  insertTagJob,
  setSelectedTag,
  setShow,
  setTagSettings,
  setMessageFlagResult,
} = tagManagementSlice.actions

export default tagManagementSlice.reducer
