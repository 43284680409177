import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: '#133e80',
    width: '100%',
    height: '38px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  font: {
    color: '#fff!important',
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.font}>
        Copyright (C) Mynavi Corporation
      </Typography>
    </div>
  )
}

export default Footer
