import { makeStyles } from '@material-ui/core/styles'
import { DataTable } from 'componentsHsc'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { routeList } from 'routes/routes'
import {
  McbMessageData,
  McbMessageScreenTransitionRequest,
  McbMessageTableDataTransform,
} from 'utils/tableDataTransfrom'
import {
  updateStatusHsc,
} from 'reducers/entryDetailReducer'
 import {
  updateStatus,
} from 'reducers/applicantDetailForEmplymentReducer'
import { mcbMsgContents } from 'pages/MCAXS040/viewConfig'

const useStyles = makeStyles(theme => ({
  noData: {
    textAlign: 'center',
    fontSize: '20px',
  },
  table: {
    overflowX: 'hidden',
    '& table': {
      '& tr:hover td:first-child': {
        padding: '16px 16px 16px 14px !important',
      },
      '& i': {
        height: 'auto !important',
        padding: '2px 8px !important',
      },
    },
  },
  mcbMessageBoxStatus: {
    height: 'auto !important',
    padding: '2px 8px !important',
    textAlign: 'center',
    display: 'block ! important',
  },
  typeBadge: {
    backgroundColor: "#fff",
    border: "1px solid #595959",
    fontWeight: "bold",
    color: "#4596e6 !important",
    height: 'auto !important',
    padding: '2px 8px !important',
  },
  attachedIcon: { 
    display: 'block !important',
    position: "static !important" as "static", 
    margin: "auto !important",
    transform: "translate(0, 0) !important",
  },
}))
/** MCBメッセージエリア */
export const mcbMessage: McbMessageData = {
  mcbMessageBoxClassification: "",
  mcbMessageBoxStatus: undefined,
  mcbMessageBoxKinds: "",
  mcbMessageBoxSubject: "",
  mcbMessageBoxAttachment: false,
  mcbMessageBoxSender: "",
  mcbMessageBoxDateTime: "",
  mcbMessageBoxMessageTypeCode: "",
  mcbMessageBoxMessageId: 0,
  mcbMessageBoxMessageSendId: 0,
  mcbMessageBoxJobSeekerByCompanyId: 0,
  mcbMessageBoxRequestId: 0,
}

/** MCBメッセージエリア */
export const mcbMessageRequest: McbMessageScreenTransitionRequest = {
  screenId: '',
  messageSendId: '',
  jobSeekerByCompanyIdList: [],
  requestIdList: [],
}

interface Props {
  screenId: string,
  mcbMessageDataList: McbMessageData[]
}

const MCBMessage = ({ screenId, mcbMessageDataList }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const applicantMcbMessageRequest = useSelector(
    (state: RootState) => state.applicantDetailForEmplyment.mcbMessageRequest
  )

    // 代行ログインアカウントフラグ
    const delegateLoginAccountFlag = useSelector(
      (state: RootState) => state.globalMenu.delegateLoginAccountFlag
    )
  const globalMenuInfo = useSelector((state: RootState) => state.globalMenu)


  const handleMcbMessageClick = (index: number) => () => {
    const mcbMessageData = mcbMessageDataList[index]
    const mcbMessageScreenTransitionRequest = {
      mcbMessageId: String(mcbMessageData.mcbMessageBoxMessageId),
      messageSendId: String(mcbMessageData.mcbMessageBoxMessageSendId),
      jobSeekerSort: '1',
      screenId: 'MCAXS040'
    }
    let to = ''
    let localStorageName = ''
    let newWindow = ''
    if (
      mcbMessageData.mcbMessageBoxMessageTypeCode ===
      mcbMsgContents.SUBMISSION_REQUEST
    ) {
       to = routeList.submissionRequestMessageDetails
       localStorageName = "submissionRequestMessageDetails"
       newWindow = 'submissionRequestMessageDetails'
    } else {
       to = routeList.mcbMessageDetail
       localStorageName = "mcbMessageDetail"
       newWindow = 'mcbMessageDetail'
    }
     to = to + '?' + new URLSearchParams(mcbMessageScreenTransitionRequest as {}).toString()
     const request = {
      pathname: to,
      ...mcbMessageScreenTransitionRequest,
    }
    localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
    localStorage.setItem( localStorageName, JSON.stringify(request))
    const windowSize = {
      height: '948px',
      width: '1280px',
    }
    window.open(
      to,
      newWindow,
      `width=${windowSize.width}, height=${windowSize.height}, resizable=yes`
    )
  }
  return (
    <section id='messages-MCB'>
      <h1 id = "mcbMessageBoxCount">
        My CareerBoxメッセージ件数：<strong>{mcbMessageDataList.length}</strong>件
      </h1>
      {mcbMessageDataList.length > 0 ? (
        <div className='selection-table detail-message-list'>
           <DataTable
            widthPercent={['10%', '10%', '40%', '11%', '15%']}
            labelList={[
              '分類',
              '状態',
              (<span>{'種別'}<br/>{'件名'}</span>),
              '送信者',
              '送信（予定）/受信日時',
            ]}
            dataList={McbMessageTableDataTransform(mcbMessageDataList, classes)}
            rowCondition={{ keyword: 'unread', className: 'unread' }}
            handleClick={handleMcbMessageClick}
            className={classes.table}
          />
        </div>
      ) : (
        <p className={classes.noData}>
          条件に一致する検索結果がありませんでした。
        </p>
      )}
    </section>
  )
}

export default MCBMessage
