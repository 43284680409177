import { Chip, Radio } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'common/generalUtil'
import { Title, FieldGroup, FormField, SubTitle } from 'componentsHsc'
import { DatePicker } from 'components'
import GroupOptions from 'componentsHsc/AdvancedSearchJobChange/GroupOptions'
import ToggleMBC from 'componentsHsc/AdvancedSearch/ToggleMBC'
import ToggleAndOr from 'componentsHsc/AdvancedSearch/ToggleAndOr'
import { FastSelectOptions } from './FastSelect'
import { FastField, Field } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { FormControl } from '.'
import Button from './Button'
import {
  cabObject,
  cabTitles,
  gabObject,
  gabTitles,
  imagesObject,
  imagesTitles,
  opqObject,
  opqTitles,
} from './Config/modalConfig'
import { SelectionFlowObjDto, TestObj } from './Config/searchConditionConfig'
import Modal from './Modal/Modal'
import { getMessage } from 'common/messageUtil'
import { ManagementItemCondition } from './Config/searchConditionConfig'
import {
  getMcaxs501Init,
  setManagementModalState,
} from 'reducers/itemSpecificationReducer'
import { dateToString } from 'common/generalUtil'
import { setManagementModalOpen } from 'reducers/advancedSearchReducer'
import { setSearchCondition } from 'reducers/applicantListReducer'
import { toEntryList } from './Config/searchConditionConfig'
import SelectionFlowStepPassFailModal, { generateLabelSelectionFlowStepPassFail, stringToEllipsis } from './Modal/SelectionFlowStepPassFailModal'
import { useModal } from 'utils'
import { getMcaxs691Init } from 'reducers/advancedSearchModalReducer'
import { magiContants } from 'utils/contants'
// 25KH #74722 start
import FormControlLabel from '@material-ui/core/FormControlLabel'
// 25KH #74722 end

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  threeColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
    maxWidth: '295px',
  },
  chip2: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
    lineHeight: '120%',
    '& span': {
      whiteSpace: 'pre-wrap',
    }
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  // 25KH #74722 start
  tag: {
    '& div': {
      width: '100%!important',
      display: 'flex',
      justifyContent: 'flex-end !important',
    },
    '& p': {
      marginTop: '3%',
      marginRight: document.body.clientWidth > 960 ? '18%' : '31%',
    },
  },
  // 25KH #74722 end
  // [phase2] start
  dateRange: {
    '& input': {
      padding: '0 8px',
    },
  },
  datePickerDiv: {
    width: '130px',
    float: 'left',
    '& .MuiIconButton-label': {
      width: '23px',
    },
    '& .btn.only-icon.icon-input_calender': {
      margin: '0 10px 0 0',
    },
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: '13px 12px 0px 20px',
  },
  titleArea: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
    paddingBottom: 25,
    paddingTop:25,
  },
  // [phase2] end
  andOrStyleIE: {
    position: 'absolute',
    marginLeft: '130px',
    top: '6px',
  },
  andOrStyleOther: {
    position: 'absolute',
    marginLeft: '130px',
    marginTop: '6px',
  },
  cursor: {
    '& div' : {
      cursor: 'pointer!important',
    }
  },
  // 25KH #74722 start
  frequentlyUsedTagRow: {
    margin: '-16px 80px 16px 80px !important',
  },
  // 25KH #74722 end
}))

const generateLabelModal = (input: TestObj) => {
    if (input.physicalName.toLowerCase().includes('exam_language')) {
        const valueFrom = input.valueFrom
        return `${input.logicalName} ${valueFrom}`
    } else {
        const valueFrom = input.physicalName.includes('Date') || input.physicalName.includes('date')
          ? isEmpty(input.valueFrom)
            ? ''
            : dateToString(new Date(input.valueFrom))
          : input.valueFrom
        const valueTo = input.physicalName.includes('Date') || input.physicalName.includes('date')
          ? isEmpty(input.valueTo)
            ? ''
            : dateToString(new Date(input.valueTo))
          : input.valueTo
      
        const from = valueFrom
          ? valueTo
            ? valueFrom === valueTo 
              ? `${valueFrom}`
              : `${valueFrom} ～ `
            : `${valueFrom} ～`
          : ''
        const to = valueTo ? (valueFrom ? valueFrom === valueTo ? '' : valueTo : `～ ${valueTo}`) : ''
      
        const logicalName = '' === from && '' === to ? '' : input.logicalName
        return `${logicalName} ${from}${to}`
    }
}

const modalData = {
  イメジス情報: { object: imagesObject, title: imagesTitles },
  GAB情報: { object: gabObject, title: gabTitles },
  OPQ情報: { object: opqObject, title: opqTitles },
  CAB情報: { object: cabObject, title: cabTitles },
}

const nameSet = {
  イメジス情報: 'imagesObj',
  GAB情報: 'gABObj',
  OPQ情報: 'oPQObj',
  CAB情報: 'cABObj',
}

const typeList = [
  { label: '連携済み', value: '1' },
  { label: '未連携', value: '0' },
]


const andOrTypeList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

type Title = keyof typeof modalData

interface Props {
  form: FormControl
}

const generateLabel = (item: ManagementItemCondition) => {
  let valueText: string = ''
  const itemText: string = item.recruitmentManagementFlagName

  if (item.managementItemPresenceFlag === '1') {
    valueText = '値がない'
  } else {
    switch (item.managementItemTypeCode) {
      case '10':
        if (item.managementItemFlagText === '') {
          valueText = '値がある全て'
        } else {
          valueText = `[${item.managementItemFlagText}]が含まれる`
        }
        break
      case '20':
        if (
          item.managementItemFlagFrom === '' &&
          item.managementItemFlagTo === ''
        ) {
          valueText = '値がある全て'
        } else if (item.managementItemFlagFrom === item.managementItemFlagTo) {
          valueText = item.managementItemFlagFrom
        } else if (item.managementItemFlagFrom !== item.managementItemFlagTo) {
          valueText = `${item.managementItemFlagFrom}～${item.managementItemFlagTo}`
        }
        break
      case '30':
        if (item.managementItemFlagCheckBoxObj.length === 0) {
          valueText = '値がある全て'
        } else {
          const valueTextList = item.managementItemFlagCheckBoxObj.map(
            i => i.label
          )
          valueText = valueTextList.join('、')
        }
        if (item.managementItemPresenceFlag === '2') {
          valueText = valueText + ' を除く'
        }
        break
      default:
        break
    }
  }
 return `${itemText} ${valueText}`
}

const RecruitmentManagement = ({ form }: Props) => {
  const classes = useStyles()

  // 25KH #74722 start
  // 「よく使われるタグ：」解像度変更の場合、CSS変更
  window.onresize = () => { 
    const screenWidth = document.body.clientWidth;
    var tagElement = document.getElementById("tag");
    var tagExclusionElement = document.getElementById("tagExclusion");

    if (tagElement != null && tagExclusionElement != null) {
      tagElement.getElementsByTagName("p")[0].style.marginRight =  screenWidth > 960 ? "18%" : "31%";
      tagExclusionElement.getElementsByTagName("p")[0].style.marginRight =  screenWidth > 960 ? "18%" : "31%";
    }
  }
  // 25KH #74722 end

  const dispatch = useDispatch()

  const { conditionList = [], typeName = '' } =
    form.values.managementItemObj || {}

  const ImagesSubmitFlag = useSelector((state: RootState) => state.advancedSearchModal.ImagesSubmitFlag)
  const GABSubmitFlag = useSelector((state: RootState) => state.advancedSearchModal.GABSubmitFlag)
  const OPQSubmitFlag = useSelector((state: RootState) => state.advancedSearchModal.OPQSubmitFlag)
  const CABSubmitFlag = useSelector((state: RootState) => state.advancedSearchModal.CABSubmitFlag)
  
  const ImagesModalObj = useSelector((state: RootState) => state.advancedSearchModal.ImagesModalObj)
  const GABModalObj = useSelector((state: RootState) => state.advancedSearchModal.GABModalObj)
  const OPQModalObj = useSelector((state: RootState) => state.advancedSearchModal.OPQModalObj)
  const CABModalObj = useSelector((state: RootState) => state.advancedSearchModal.CABModalObj)
  
  const targetYear = useSelector(
    (state: RootState) => state.globalMenu.targetYear
  )
  
  const imagesObj = ImagesSubmitFlag ? form.values.imagesObj || [] : ImagesModalObj || []
  const gABObj = GABSubmitFlag ? form.values.gABObj || [] : GABModalObj || []
  const oPQObj = OPQSubmitFlag ? form.values.oPQObj || [] : OPQModalObj || []
  const cABObj = CABSubmitFlag ? form.values.cABObj || [] : CABModalObj || []

  const invisibleRecruitmentManagementFlagSettingIdList = useSelector(
    (state: RootState) =>
      state.itemSpecification.invisibleRecruitmentManagementFlagSettingIdList
  )

  // Modal
  const [state, setState] = useState({
    general: false,
    management: false,
  })

  const handleModal = (modalName: string, open = true) => () => {
    setState({
      ...state,
      [modalName]: open,
    })
  }

  const [currentModal, setCurrentModal] = useState<Title>('イメジス情報')
  const handleClick = (title: Title) => () => {
    setSelectedSubtitle(modalData[currentModal].title[0])
    setCurrentModal(title)
    setState({ ...state, general: true })
  }

  const {
    selectionFlowList,
    selectionStepList,
    judgementList,
    tagList,
    frequentlyUsedTagList,
    mcbCategoryList,
    displayShlInfo,
  } = useSelector(
    (state: RootState) => state.advancedSearch.recruitmentManagementInfo
  )

  // 選考フロー
  const selectionFlowOptionList = selectionFlowList != null ? selectionFlowList.map(i => {
    return { label: i.selectionName, value: i.selectionFlowSettingId }
  }) : []

  // 選考ステップ
  const selectionStepOptionList = selectionStepList != null ? selectionStepList.map(i => {
    return { label: i.progressName, value: i.progressStatusSettingId }
  }) : []

  // [phase2] start
  // カテゴリー
  const mcbCategoryOptionList = mcbCategoryList.map(i => {
    return {label: i.itemName,value: i.itemCode}
  })
  // [phase2] end 
  // 合否判定
  const judgmentOptionList = judgementList.map(i => {
    return {
      label: i.itemName,
      value: i.itemCode,
    }
  })

  // タグサジェスト情報
  const tagNameSuggestoptionList = tagList.map(i => {
    return { label: i.tagName, value: i.tagSettingId }
  })

  // 25KH #74722 start
  // タグ除外
  const tagExclusionOptionList = tagList.map(i => {
    return { label: i.tagName, value: String(i.tagSettingId) }
  })
  // 25KH #74722 end

  //　よく使われるタグ情報
  const frequentlyUsedTags = frequentlyUsedTagList.map(i => {
    return { key: i.tagSettingId, label: i.tagName }
  })

  // 25KH #74722 start
  // よく使われるタグ除外
  const FrequentlyUsedTagsExclusion = frequentlyUsedTagList.map(i => {
    return { label: i.tagName, key: String(i.tagSettingId) }
  })

  // 判定条件（タグ） ANR/OR
  const [tagCondition,setTagCondition] = useState('0')

  // 判定条件（タグ除外） ANR/OR
  const [tagExclusionCondition,setTagExclusionCondition] = useState('0')
  // 25KH #74722 end

  const handleDelete = (name: string, option: any) => () => {
    form.setFieldValue(
      name,
      // eslint-disable-next-line no-eval
      (eval(name) as any[]).map(i =>
        i ? (i.logicalName === option.logicalName ? undefined : i) : i
      )
    )
  }

  const handleDeleteImages = (option: TestObj) => () => {
    if (imagesObj) {
      const deleteNumber = imagesObj.indexOf(option)
      if(deleteNumber >= 0){
        imagesObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = imagesObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('imagesObj', newConditionList)
      } else {
        form.setFieldValue('imagesObj', null)
      }
    }
  }

  const handleDeleteGABObj = (option: TestObj) => () => {
    if (gABObj) {
      const deleteNumber = gABObj.indexOf(option)
      if(deleteNumber >= 0){
        gABObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = gABObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('gABObj', newConditionList)
      } else {
        form.setFieldValue('gABObj', null)
      }
    }
  }

  const handleDeleteOPQObj = (option: TestObj) => () => {
    if (oPQObj) {
      const deleteNumber = oPQObj.indexOf(option)
      if(deleteNumber >= 0){
        oPQObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = oPQObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('oPQObj', newConditionList)
      } else {
        form.setFieldValue('oPQObj', null)
      }
    }
  }

  const handleDeleteCABObj = (option: TestObj) => () => {
    if (cABObj) {
      const deleteNumber = cABObj.indexOf(option)
      if(deleteNumber >= 0){
        cABObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = cABObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('cABObj', newConditionList)
      } else {
        form.setFieldValue('cABObj', null)
      }
    }
  }

  /**
   * よく使われるタグクリック
   */
  const tagClick = (data: any) => (e: any) => {
    const newTag = { label: data.label, value: data.key }
    const newTagDate = form.values['tagTxtObj']
    if (newTagDate) {
      //タグが入力されている場合
      if (!newTagDate.map(tag => tag.value).includes(newTag.value)) {
        //重複が無ければ選択したタグを追加
        newTagDate.push({ label: data.label, value: data.key })
        form.setFieldValue('tagTxtObj', newTagDate)
      }else{
        //重複があれば何もしない
        return
      }
    } else {
      //タグが未入力の場合、タグを追加
      form.setFieldValue('tagTxtObj', [{ label: data.label, value: data.key }])
    }
    // 25KH #74722 start
    form.setFieldValue('tagJudgmentFlag',tagCondition)
    form.setFieldValue('tagJudgmentName',tagCondition === '0' ? '全てと一致' : 'いずれかに一致')
    // 25KH #74722 end
  }

  // 25KH #74722 start
  /**
   * よく使われるタグ除外クリック
   */
  const tagExclusionClick = (data: any) => (e: any) => {
    const newTagExclusion = { label: data.label, value: data.key }
    const newTagExclusionDate = form.values['tagExclusionTxtObj']
    if (newTagExclusionDate) {
      //タグ除外が入力されている場合
      if (!newTagExclusionDate.map(tagExclusion => tagExclusion.value).includes(newTagExclusion.value)) {
        //重複が無ければ選択したタグ除外を追加
        newTagExclusionDate.push({ label: data.label, value: data.key })
        form.setFieldValue('tagExclusionTxtObj', newTagExclusionDate)
      } else {
        //重複があれば何もしない
        return
      }
    } else {
      //タグ除外が未入力の場合、タグを追加
      form.setFieldValue('tagExclusionTxtObj', [{ label: data.label, value: data.key }])
    }
    form.setFieldValue('tagExclusionJudgmentFlag',tagExclusionCondition)
    form.setFieldValue('tagExclusionJudgmentName',tagExclusionCondition === '0' ? '全てと一致' : 'いずれかに一致')
  }
  // 25KH #74722 end
  
  useEffect(() => {
    dispatch(
      getMcaxs501Init({
        isInitManagementItemObj: true,
        isJobChange: false,
        toggle: () => {},
      })
    )
    // 25KH #74722 start
    // 検索一覧に戻る_預け入れタグ除外条件判定FLGを取得します
    if (!isEmpty(form.values['tagExclusionJudgmentFlag'])) {
      setTagExclusionCondition(String(form.values['tagExclusionJudgmentFlag']))
    }
    // 検索一覧に戻る_預け入れタグ条件判定FLGを取得します
    if (!isEmpty(form.values['tagJudgmentFlag'])) {
      setTagCondition(String(form.values['tagJudgmentFlag']))
    }
    // 25KH #74722 end
  }, [])

  // 25KH #74722 start
  //タグ除外条件判定FLG と タグ除外条件判定名_(タグ除外とタグ除外条件判定FLGの変化をモニターします)
  useEffect(() => {
      form.setFieldValue('tagExclusionJudgmentFlag', tagExclusionCondition)
      form.setFieldValue('tagExclusionJudgmentName', tagExclusionCondition === '0' ? '全てと一致' : 'いずれかに一致')
  }, [form.values['tagExclusionTxtObj'], tagExclusionCondition])

  //タグ条件判定FLG と タグ条件判定名_(タグとタグ条件判定FLGの変化をモニターします)
  useEffect(() => {
      form.setFieldValue('tagJudgmentFlag', tagCondition)
      form.setFieldValue('tagJudgmentName', tagCondition === '0' ? '全てと一致' : 'いずれかに一致')
  }, [form.values['tagTxtObj'], tagCondition])
  // 25KH #74722 end

  const managementModalState = useSelector(
    (state: RootState) => state.itemSpecification.managementModalState
  )
  const handleManagementSettingClick = () => {
    let isInitManagementItemObj = true
    if (
      managementModalState.conditionList &&
      managementModalState.conditionList.length > 0
    ) {
      if (form.values.managementItemObj) {
        isInitManagementItemObj = false
        const newManagementModalState = {
          ...managementModalState,
        }
        const managementItemObj = form.values.managementItemObj
        newManagementModalState.typeCode = managementItemObj.typeCode
        const newCond: ManagementItemCondition[] = []
        if (newManagementModalState.conditionList) {
          newManagementModalState.conditionList.forEach(i => {
            const findResult = managementItemObj.conditionList.find(
              itemObjCondition =>
                itemObjCondition.recruitmentManagementFlagSettingId ===
                i.recruitmentManagementFlagSettingId
            )
            if (findResult) {
              newCond.push(findResult)
            } else {
              newCond.push({
                ...i,
                managementItemPresenceFlag: '',
                managementItemFlagText: '',
                managementItemFlagFrom: '',
                managementItemFlagTo: '',
                managementItemFlagCheckBoxObj: [],
              })
            }
          })
        }

        newManagementModalState.conditionList = newCond
        dispatch(setManagementModalState(newManagementModalState))
      }
    }
    dispatch(
      getMcaxs501Init({
        isInitManagementItemObj: isInitManagementItemObj,
        isJobChange: false,
        toggle: () => {
          dispatch(setSearchCondition(toEntryList(form.values)))
          dispatch(setManagementModalOpen(true))
        },
      })
    )
  }
  //適性検査モーダル:検索分類選択state
  // [phase2] start
  const [isDisabled, setIsDisabled] = useState( form.values.searchTargetObj ? false : true)
  // [phase2] end
  const handleChipDelete = (
    conditionList: ManagementItemCondition[],
    index: number
  ) => {
    const newConditionList = conditionList.slice()
    newConditionList.splice(index, 1)
    if (newConditionList.length > 0) {
      form.setFieldValue('managementItemObj.conditionList', newConditionList)
    } else {
      form.setFieldValue('managementItemObj', null)
    }
  }

  //適性検査モーダル:検索分類選択state
  const [selectedSubtitle, setSelectedSubtitle] = useState(
    modalData[currentModal].title[0]
  )

  const optionSet = {
    selectionFlowObj: selectionFlowOptionList,
    selectionStepObj: selectionStepOptionList,
    judgmentUndeterminedObj: judgmentOptionList,
    // 25KH #74722 start
    tagTxtObj:tagNameSuggestoptionList,
    tagExclusionTxtObj:tagExclusionOptionList,
    // 25KH #74722 end
  }

  const handleSelectAll = (name: keyof typeof optionSet) => (e: any) => {
    form.setFieldValue(name, e.target.checked ? optionSet[name] : [])
  }

  const isChecked = (name: keyof typeof optionSet) => {
    const newValue = form.values[name]
    // 25KH #74722 start
    if (name === 'tagTxtObj' || name === 'tagExclusionTxtObj') {
      var i:number
      var j:number
      var k:string[] = []
      if (newValue) {
        for (i = 0; i < newValue.length; i++) {
          for (j = 0; j < optionSet[name].length; j++) {
            if (String(newValue[i].value) === String(optionSet[name][j].value)) {
              k.push(newValue[i].value)
            }
          }
        }
        if (k.length === optionSet[name].length) {
          return true
        }
      }
    // 25KH #74722 end
    } else if (newValue && newValue.length === optionSet[name].length) {
      return true
    }

    return false
  }

  const [show, setShow] = useModal()
  useEffect(() => {
    dispatch(getMcaxs691Init())
  }, [])
  const handleSelectionFlowStepPassFailClick = () => {
    dispatch(getMcaxs691Init())
    setShow(true)()
  }
  const selectionFlowStepPassFail = form.values.selectionFlowStepPassFailObj || null
  const handleDeleteSelectionFlowStepPassFail = (item: SelectionFlowObjDto) => () => {
    if (selectionFlowStepPassFail) {
      const newConditionList = selectionFlowStepPassFail.selectionFlowObj.filter(i => i !== item)
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('selectionFlowStepPassFailObj.selectionFlowObj', newConditionList)
      } else {
        form.setFieldValue('selectionFlowStepPassFailObj', null)
      }
    }
  }

  // 25KH #74722 start
  // タグAND/OR onChange
  const handleTagCondition = (e: any) => {
    const tagConditionObj = e.target.value === tagCondition ? '' : e.target.value
    setTagCondition(tagConditionObj)
  }

  // タグ除外AND/OR onChange
  const handleTagExclusionCondition = (e: any) => {
    const tagExclusionConditionObj = e.target.value === tagExclusionCondition ? '' : e.target.value
    setTagExclusionCondition(tagExclusionConditionObj)
  }
  // 25KH #74722 end

  return (
    <>
      <FieldGroup>
        <SubTitle variant='h3'>選考フロー/選考ステップ</SubTitle>
        <FormField helperText={getMessage('MCAXS391-001')}>
          <Title 
            title='選考フロー' 
            className={classes.title} 
            onSelectAll={selectionFlowOptionList.length > 0 ? handleSelectAll('selectionFlowObj'): undefined}
            checked={isChecked('selectionFlowObj')} />
          <FastField
            name='selectionFlowObj'
            component={GroupOptions}
            row
            optionList={selectionFlowOptionList}
          />
        </FormField>
        <FormField helperText={getMessage('MCAXS391-004')}>
          <Title 
            title='選考ステップ' 
            className={classes.title}
            onSelectAll={selectionStepOptionList.length > 0 ? handleSelectAll('selectionStepObj'): undefined}
            checked={isChecked('selectionStepObj')} />
          <FastField
            name='selectionStepObj'
            component={GroupOptions}
            row
            optionList={selectionStepOptionList}
          />
        </FormField>
        <FormField helperText={getMessage('MCAXS391-002')}>
          <Title 
            title='合否' 
            className={classes.title}
            onSelectAll={handleSelectAll('judgmentUndeterminedObj')}
            checked={isChecked('judgmentUndeterminedObj')} />
          <FastField
            name='judgmentUndeterminedObj'
            component={GroupOptions}
            row
            optionList={judgmentOptionList}
          />
        </FormField>
        <FormField>
          <Title title='選考フロー×選考ステップ×合否' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleSelectionFlowStepPassFailClick} />
            {selectionFlowStepPassFail && selectionFlowStepPassFail.selectionFlowObj && (
              <div
                className={
                  window.navigator.userAgent
                    .toLocaleLowerCase()
                    .match(/(trident)\/([\d.]+)/)
                    ? classes.andOrStyleIE
                    : classes.andOrStyleOther
                }>
                {selectionFlowStepPassFail.searchConditionName}
              </div>
            )}
            {selectionFlowStepPassFail && selectionFlowStepPassFail.selectionFlowObj && (
              <span>
                {selectionFlowStepPassFail.selectionFlowObj.map(item => (
                  <Chip
                    className={classes.chip2}
                    title={generateLabelSelectionFlowStepPassFail(item)}
                    label={stringToEllipsis(generateLabelSelectionFlowStepPassFail(item))}
                    onDelete={handleDeleteSelectionFlowStepPassFail(item)}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            )}
          </div>
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>評価</SubTitle>
        <FormField>
          <Title title='管理項目' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleManagementSettingClick} />
            {conditionList.length > 0 && (
              <div
              className={
                window.navigator.userAgent
                  .toLocaleLowerCase()
                  .match(/(trident)\/([\d.]+)/)
                  ? classes.andOrStyleIE
                  : classes.andOrStyleOther
              }>
              {conditionList.filter(i =>
                invisibleRecruitmentManagementFlagSettingIdList.includes(
                  i.recruitmentManagementFlagSettingId
                )
              ).length === conditionList.length
                ? undefined
                : typeName}
            </div>
          )}
            {conditionList.length > 0 && (
              <span>
                {conditionList.map((item, index) =>
                  invisibleRecruitmentManagementFlagSettingIdList.includes(
                    item.recruitmentManagementFlagSettingId
                  ) ? (
                    undefined
                  ) : (
                    <Chip
                      title={generateLabel(item)}
                      className={classes.chip}
                      key={index}
                      label={generateLabel(item)}
                      onDelete={() => handleChipDelete(conditionList, index)}
                    />
                  )
                )}
              </span>
            )}
          </div>
        </FormField>
        {/* 25KH #74722 start */}
        <FormField>
        {/* 25KH #74722 end */}
          <Title title='タグ'
            className={classes.title} 
            onSelectAll={handleSelectAll('tagTxtObj')}
            checked={tagNameSuggestoptionList.length != 0 ? isChecked('tagTxtObj') : false}
          />
          <div>
            <Field
              isMulti
              name='tagTxtObj'
              defaultValue={form.values.tagTxtObj}
              component={FastSelectOptions}
              className={classes.cursor}
              optionList={tagNameSuggestoptionList}
              placeholder='キーワードを入力'
            />
            {/* 25KH #74722 start */}
            <div style={{ marginLeft: '10px' }}>
              {/* AND/OR */}
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='0'
                    name='tagCondition'
                    onChange={handleTagCondition}
                    checked={tagCondition === '0'}
                  />
                }
                label='AND'
              />
              <FormControlLabel
                control={
                  <Radio
                  color='primary'
                  value='1'
                  name='tagCondition'
                  onChange={handleTagCondition}
                  checked={tagCondition === '1'}
                  />
                }
                label='OR'
              />
            </div>
            {/* 25KH #74722 end */}
          </div>
        </FormField>
        <FormField className={classes.frequentlyUsedTagRow}>
          <div id='tag'>
            <Title title='よく使われるタグ：' 
              className={classes.tag} 
            />
          </div>
          <div>
            {frequentlyUsedTags.map(data => (
              <Chip
                key={data.key}
                onClick={tagClick(data)}
                label={data.label}
                className={classes.chip}
              />
            ))}
          </div>
        </FormField>
        {/* 25KH #74722 start */}
        <FormField>
        <Title title='タグ（除外）' 
            className={classes.title} 
            onSelectAll={handleSelectAll('tagExclusionTxtObj')}
            checked={tagExclusionOptionList.length != 0 ? isChecked('tagExclusionTxtObj') : false}
          />
          <div>
            <Field
              name='tagExclusionTxtObj'
              isMulti
              component={FastSelectOptions}
              className={classes.cursor}
              defaultValue={form.values.tagExclusionTxtObj}
              optionList={tagExclusionOptionList}
              placeholder='キーワードを入力'
            />
            <div style={{marginLeft: '10px'}}>
              {/* AND/OR */}
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='0'
                    name='tagExclusionCondition'
                    onChange={handleTagExclusionCondition}
                    checked={tagExclusionCondition === '0'}
                  />
                }
                label='AND'
              />
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='1'
                    name='tagExclusionCondition'
                    onChange={handleTagExclusionCondition}
                    checked={tagExclusionCondition === '1'}
                  />
                }
                label='OR'
              />
            </div>
          </div>
        </FormField>
        <FormField className={classes.frequentlyUsedTagRow}>
          <div id='tagExclusion'>
            <Title title='よく使われるタグ：' 
              className={classes.tag} 
            />
          </div>
          <div>
            {FrequentlyUsedTagsExclusion.map(data => (
              <Chip
                key={data.key}
                onClick={tagExclusionClick(data)}
                label={data.label}
                className={classes.chip}
              />
            ))}
          </div>
        </FormField>
        {/* 25KH #74722 end */}
        {displayShlInfo.displayImages && (
          <FormField>
            <Title title='イメジス' className={classes.title} />
            <div className={classes.modal}>
              <Button onClick={handleClick('イメジス情報')} />
              {imagesObj.length > 0 && (
                <span>
                  {imagesObj.map(
                    i =>
                      i &&
                      i.physicalName &&
                      i.physicalName !== '' && (
                        <Chip
                          title={generateLabelModal(i)}
                          // key={i.logicalName}
                          className={classes.chip}
                          label={generateLabelModal(i)}
                          onDelete={handleDeleteImages(i)}
                          deleteIcon={<CancelIcon />}
                        />
                      )
                  )}
                </span>
              )}
            </div>
          </FormField>
        )}
        {displayShlInfo.displayGab && (
          <FormField>
            <Title title='GAB' className={classes.title} />
            <div className={classes.modal}>
              <Button onClick={handleClick('GAB情報')} />
              {gABObj.length > 0 && (
                <span>
                  {gABObj.map(i =>
                    i && i.physicalName && i.physicalName !== '' ? (
                      <Chip
                        title={generateLabelModal(i)}
                        key={i.physicalName}
                        className={classes.chip}
                        label={generateLabelModal(i)}
                        onDelete={handleDeleteGABObj(i)}
                        deleteIcon={<CancelIcon />}
                      />
                    ) : null
                  )}
                </span>
              )}
            </div>
          </FormField>
        )}
        {displayShlInfo.displayOpq && (
          <FormField>
            <Title title='OPQ' className={classes.title} />
            <div className={classes.modal}>
              <Button onClick={handleClick('OPQ情報')} />
              {oPQObj.length > 0 && (
                <span>
                  {oPQObj.map(i =>
                    i && i.physicalName && i.physicalName !== '' ? (
                      <Chip
                        title={generateLabelModal(i)}
                        key={i.physicalName}
                        className={classes.chip}
                        label={generateLabelModal(i)}
                        onDelete={handleDeleteOPQObj(i)}
                        deleteIcon={<CancelIcon />}
                      />
                    ) : null
                  )}
                </span>
              )}
            </div>
          </FormField>
        )}
        {displayShlInfo.displayCab && (
          <FormField>
            <Title title='CAB' className={classes.title} />
            <div className={classes.modal}>
              <Button onClick={handleClick('CAB情報')} />
              {cABObj.length > 0 && (
                <span>
                  {cABObj.map(i =>
                    i && i.physicalName && i.physicalName !== '' ? (
                      <Chip
                        title={generateLabelModal(i)}
                        key={i.physicalName}
                        className={classes.chip}
                        label={generateLabelModal(i)}
                        onDelete={handleDeleteCABObj(i)}
                        deleteIcon={<CancelIcon />}
                      />
                    ) : null
                  )}
                </span>
              )}
            </div>
          </FormField>
        )}
      </FieldGroup>
      {/* [phase2] start */}
      {targetYear < magiContants.MCB_YEAR_2025 && (
        <FieldGroup>
          <SubTitle variant='h3'>My CareerBoxへ連携</SubTitle>
          <FormField>
            <Title title='検索対象' className={classes.title} />
            <Field
              name='searchTargetObj'
              component={ToggleMBC}
              row
              optionList={typeList}
              disabledMBC={setIsDisabled}
            />
          </FormField>
          <FormField>
            <Title
              title='My CareerBoxカテゴリー'
              className={classes.titleArea}
            />
            <div>
              <div>
                <Field
                  name='mcbCategoryAndOrObj'
                  component={ToggleAndOr}
                  row
                  optionList={andOrTypeList}
                  disabled={isDisabled}
                />
              </div>
              <div>
                <Field
                  name='mcbCategoryObj'
                  component={GroupOptions}
                  row
                  optionList={mcbCategoryOptionList}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </FormField>
          <FormField>
            <Title title='連携日' className={classes.title} />
            <div className={classes.dateRange}>
              <div className={classes.datePickerDiv}>
                <Field
                  name='linkDateFrom'
                  selectsStart
                  component={DatePicker}
                  placeholder='YYYY/MM/DD'
                  disableFlag={isDisabled}
                />
              </div>
              <div className={classes.wavyLineStyle}>~</div>
              <div className={classes.datePickerDiv}>
                <Field
                  name='linkDateTo'
                  selectsEnd
                  component={DatePicker}
                  placeholder='YYYY/MM/DD'
                  disableFlag={isDisabled}
                />
              </div>
            </div>
          </FormField>
        </FieldGroup>
      )}
       {/* [phase2] end */}
      <Modal
        title={`${currentModal}を指定`}
        optionSet={modalData[currentModal].object}
        subtitleList={modalData[currentModal].title}
        form={form}
        open={state.general}
        onClose={handleModal('general', false)}
        name={nameSet[currentModal]}
        selectedSubtitleState={[selectedSubtitle, setSelectedSubtitle]}
      />
      <SelectionFlowStepPassFailModal
        open={show}
        onClose={setShow(false)}
        form={form}
      />
    </>
  )
}

export default RecruitmentManagement
