export const MCBJS020InitialValues = {
  templateId: '',
}

export const destinationList = [
  { value: '3', label: 'My CareerBox' },
]

export const resubmissionList = [
  { value: '1', label: '受け付ける' },
  { value: '0', label: '受け付けない' },
]

export const settingThanksEmailList = [
  { value: '1', label: '設定する' },
  { value: '0', label: '設定しない' },
]

export const insertTextSecruitmentManageFlagList = [
  { name: 'accountTypeSupervising', label: '統括', id: '1' },
  { name: 'accountTypeAdministrator', label: '管理者', id: '2' },
  { name: 'accountTypeSemiManager', label: '準管理者', id: '3' },
  { name: 'accountTypeGeneralUser', label: '一般ユーザ', id: '4' },
  { name: 'accountTypeLimitUser', label: '制限ユーザ', id: '5' },
];

export const submissionContentsSuggest = [
  { label: '技術職向け', title: '技術者コース', content: 'エントリーシート、研究概要書' },
  { label: '営業職向け', title: '営業コース', content: 'エントリーシート' },
  { label: '事務職向け', title: '秘書コース', content: 'エントリーシート' },
];
export const thanksEmailTemplatesSuggest = [];
export const contactInformationsSuggest = [];
export const personalInformationsSuggest = [];

// export const targetYearsSuggest = [
//   { label: '2025', value: '2025' },
//   { label: '2026', value: '2026' },
//   { label: '2027', value: '2027' },
//   { label: '2028', value: '2028' },
// ];

export const deadlineAfterDaySuggest = [
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
];

export const deadlineAfterDayTimeSuggest = [
  { label: '00', value: '00' },
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
];

const initialMCBJS020SearchHomepageResultDto = [{
  submissionReqTemplateSettingId: 0,	//提出リクエストテンプレートID
  sequence: 0,	//連番
  homePageTitle: '',	//ホームページタイトル
  homePageUrl: '',	//ホームページURL
}]
export const initialMCBJS020SearchAttachmentFileResultDto = [{
  submissionReqTemplateSettingId: 0,	//提出リクエストテンプレートID
  sequence: 0,	//連番
  attachmentName: 'ファイルを選択',	//添付ファイル名
  fileManagementId: '',	//ファイル管理ID
  fileSize: '',	//ファイルサイズ
}]

const initialMCBJS020SignResultDto = [{
  signSettingId: 0,	//署名設定ID
  registrationName: '', //登録名
  signature: '',	//署名 
}]

export const initialMCBJS020ReturnDisplayDto = {
  templateId: '',	//提出リクエストテンプレートID
  recruitmentManagementDivision: '',//採用管理区分
  requestTemplateName: '',	//テンプレート名
  targetYear: '', //対象年度
  targetYearList: [], //対象年度リスト
  destinationSelection: '3',	//送信先区分
  submissionsSelectId: '', //提出物内容選択ID
  submissionsName: '', // 提出物内容名
  submissionsNameStudent: '', //提出物内容（学生画面表示用）
  submissionNames: '', //提出物
  submissionsEntryFlg: '', // 提出物エントリシートフラグ
  submissionsResumeFlg: '', // 提出物履歴書フラグ
  submissionsResearchFlg: '', // 提出物研究概要書フラグ
  submissionsOriginalFlg: '', // 提出物オリジナル設問フラグ
  submissionsFileFlg: '', // 提出物ファイル提出フラグ
  limitDateDetermineFlag: '', // 提出締切_種別
  determineDateTime: '', //提出締切指定日時
  requestDayTimeLaterDay: '', //依頼日から○日後
  requestDayTimeToTime: '', //依頼日から△時まで
  anytimeReceptionEndDateTime: '', //随時受付終了日時
  reRequestReceptFlag: '0', //再提出受付フラグ
  homepageTitle1: '',	//ホームページタイトル1
  homepageTitle2: '',//ホームページタイトル2
  homepageTitle3: '',//ホームページタイトル3
  homepageUrl1: '',	//ホームページURL1
  homepageUrl2: '',//ホームページURL2
  homepageUrl3: '',	//ホームページURL3
  thanksMessageSetFlag: '', //サンクスメール設定フラグ
  thanksMessageTemplateId: '', //サンクスメールテンプレートID
  thanksMessageTemplateName: '', //サンクスメールテンプレート名
  thanksSubject: '', //サンクスメール_件名
  thanksText: '', //サンクスメール_本文
  studentReferenceId: '', //問い合わせ先選択ID
  studentReferenceName: '', //お問い合わせ先名
  // contactInformationDetail: '', //問い合わせ先内容
  studentReferenceCompanyName: '', //お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: '', //お問い合わせ先内容部署名担当者氏名
  studentReferenceTelephoneNumber: '', //お問い合わせ先内容電話番号
  studentReferenceMailAddress: '', //お問い合わせ先内容メールアドレス
  personalDataHandlingId: '', //個人情報の取り扱い選択ID
  personalDataHandlingName: '', // 個人情報の取り扱い名
  senderCompanyName: '',	//送信元会社名
  senderMailAddress: '',	//送信元メールアドレス
  requestSubject: '',	//件名
  requestText: '',	//本文
  attachmentFileList: initialMCBJS020SearchAttachmentFileResultDto,//添付ファイル情報
  signList: [],//署名リスト
  exampleLink: '',//文例リンク
  submissionsLink: '', //提出物リンク
  requestExampleLink: '', //提出リクエスト文例リンク
  thanksMailTemplateLink: '', //サンクスメールテンプレートリンク
  thanksMailExampleLink: '', //サンクスメール文例リンク
  referenceLink: '', //問い合わせ先リンク
  personalDataHandlingLink: '', //個人情報の取り扱いリンク
  sysVersionNumber: '',	//sysバージョン番号
  forbiddenWordsList: [] as string[],//禁止語リスト
  submissionContentList: [], //提出物内容リスト
  thanksMailTemplateContentList: [], // サンクスメールテンプレート内容リスト
  contactContentList: [], // お問い合わせ先内容リスト
  privacyPolicyContentList: [], // 個人情報の取り扱い内容リスト
  previewThanksBody: '', //サンクス本文固定文言
  previewRequestBody: '', //リクエスト本文固定文言
  authToken: '',
  // 26P #80795、#88225 start
  annual: '', // 年度
  annualList: '', // 対象年度リスト
  annualCs: '', // チェックサム
  // 26P #80795、#88225 end
}

export const initialopenModalArgs = {
  openCheckModal: false,
  modalMessageArgs: {
    messageId: "",
    args: []
  }
}
