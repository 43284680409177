import { Chip } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { FieldGroup, FormField, SubTitle, Title } from 'componentsHsc'
import Toggle from './Toggle'
import Button from './Button'
import TextFIeldForMessageTabAllowed from './TextFIeldForMessageTabAllowed'
import { TextFieldForMessage } from 'componentsHsc'
import { FastField, Field } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { useModal } from 'utils'
import { FormControl } from '.'
import { Option } from './Config/searchConditionConfig'
import GroupOptions from 'componentsHsc/AdvancedSearchJobChange/GroupOptions'
import RegionModal from './Modal/RegionModal'
import { Select } from './Select'
import { getMessage } from 'common/messageUtil'
import { Area, Address } from './Modal/RegionModal'

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  cursor: {
    '& div' : {
      cursor: 'pointer!important',
    }
  },
}))

const searchOptionList = [
  { value: '0', label: 'AND' },
  { value: '1', label: 'OR' },
]

interface Props {
  form: FormControl
}

const Basic = ({ form }: Props) => {
  const classes = useStyles()
  const [show, toggle] = useModal()

  const { current, vacation } = form.values.residenceObj || {
    current: [],
    vacation: [],
    residenceCondition: '',
  }

  const handleDelete = (option: Option, type: string) => () => {
    if (type === 'current') {
      form.setFieldValue(
        'residenceObj.current',
        current.filter(i => i !== option)
      )
      if (current.length <= 1) {
        //現住所が未指定となる場合
        if (vacation.length > 0) {
          form.setFieldValue('residenceObj.residenceCondition', '')
        } else {
          form.setFieldValue('residenceObj', null)
        }
      }
    } else if (type === 'vacation') {
      form.setFieldValue(
        'residenceObj.vacation',
        vacation.filter(i => i !== option)
      )
      if (vacation.length <= 1) {
        // 休暇中住所が未指定となる場合
        if (current.length > 0) {
          form.setFieldValue('residenceObj.residenceCondition', '')
        } else {
          form.setFieldValue('residenceObj', null)
        }
      }
    }
  }

  const {
    sexList,
    languageSkillsList,
    eligibilityList,
    memberTypeList,
  } = useSelector((state: RootState) => state.advancedSearch.basicInfo)

  const languageSkillsSuggest = languageSkillsList.map(i => {
    return { label: i, value: i }
  })

  const eligibilitySuggest = eligibilityList.map(i => {
    return { label: i, value: i }
  })

  //都道府県モーダル:タブstate
  const [tabValue, setTabValue] = React.useState<Address>('current')

  //都道府県モーダル:エリアstate
  const [selectedArea, setSelectedArea] = useState({
    current: 'hokkaidotohoku' as Area,
    vacation: 'hokkaidotohoku' as Area,
  })

  //都道府県モーダル:モーダル表示ボタン
  const handleClick = () => {
    setTabValue('current')
    setSelectedArea({
      current: 'hokkaidotohoku' as Area,
      vacation: 'hokkaidotohoku' as Area,
    })
    toggle(true)()
  }

  const handleClickClearField = (name: any) => (e: any) => {
    form.setFieldValue(name, "")
  }

  return (
    <>
      <FieldGroup>
        <SubTitle variant='h3'>プロフィール</SubTitle>
        <FormField helperText={getMessage('MCAXS381-001')}>
          <Title title='応募者管理ID' className={classes.title} />
          <FastField
            name='applicantId'
            multiline
            component={TextFieldForMessage}
            placeholder='応募者管理IDを入力 ※複数登録時は改行区切り'
            multiLineTextArea
            onClearValue={handleClickClearField('applicantId')}
          />
        </FormField>
        <FormField helperText={getMessage('MCAXS381-002')}>
          <Title title='氏名／氏名カナ' className={classes.title} />
          <FastField
            name='name'
            multiline
            component={TextFIeldForMessageTabAllowed}
            placeholder='氏名／氏名カナを入力 ※複数登録時は改行区切り'
            multiLineTextArea
          />
        </FormField>
        <FormField>
          <Title title='性別' className={classes.title} />
          <Field
            name='sexObj'
            row
            component={GroupOptions}
            optionList={sexList}
          />
        </FormField>
        <FormField helperText={getMessage('MCAXS381-003')}>
          <Title title='電話番号' className={classes.title} />
          <FastField
            name='phoneNumber'
            component={TextFieldForMessage}
            placeholder='電話番号を入力'
          />
        </FormField>
        <FormField helperText={getMessage('MCAXS381-004')}>
          <Title title='メールアドレス' className={classes.title} />
          <FastField
            name='mailAddress'
            component={TextFieldForMessage}
            placeholder='メールアドレスを入力'
          />
        </FormField>
        <FormField>
          <Title title='居住地（都道府県）' className={classes.title} />
          <div className={classes.modal}>
            <Button onClick={handleClick} />
            {current.length > 0 && (
              <span>
                現住所:{' '}
                {current.map(i => (
                  <Chip
                    key={i.value}
                    className={classes.chip}
                    label={i.label}
                    onDelete={handleDelete(i, 'current')}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            )}
            {current.length > 0 && vacation.length > 0 && (
              <Field
                name='residenceObj.residenceCondition'
                component={Toggle}
                row
                optionList={searchOptionList}
              />
            )}
            {vacation.length > 0 && (
              <span>
                休暇中住所:{' '}
                {vacation.map(i => (
                  <Chip
                    key={i.value}
                    className={classes.chip}
                    label={i.label}
                    onDelete={handleDelete(i, 'vacation')}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            )}
          </div>
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>資格・スキル</SubTitle>
        <FormField>
          <Title title='語学スキル' className={classes.title} />
          <Field
            name='languageSkills'
            component={Select}
            className={classes.cursor}
            defaultValue={form.values.languageSkills}
            optionList={languageSkillsSuggest}
            isMulti
            placeholder='語学スキルを入力'
          />
        </FormField>
        <FormField>
          <Title title='保有資格' className={classes.title} />
          <Field
            name='eligibility'
            isMulti
            defaultValue={form.values.eligibility}
            component={Select}
            className={classes.cursor}
            optionList={eligibilitySuggest}
            placeholder='保有資格を入力'
          />
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>会員情報</SubTitle>
        <FormField helperText={getMessage('MCAXS381-005')}>
          <Title title='会員種別' className={classes.title} />
          <Field
            name='memberTypeObj'
            row
            component={GroupOptions}
            optionList={memberTypeList}
          />
        </FormField>
      </FieldGroup>
      <RegionModal
        open={show}
        onClose={toggle(false)}
        form={form}
        tabValueState={[tabValue, setTabValue]}
        selectedAreaState={[selectedArea, setSelectedArea]}
      />
    </>
  )
}

export default Basic
