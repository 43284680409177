import React from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'

export interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  optionList: Option[]
  className?: string
  row?: boolean
  disabled?: boolean
  disabledMBC: (isDisabled: boolean) => void
}

const ToggleMBC = ({
  field,
  form,
  optionList,
  className,
  row,
  disabled,
  disabledMBC,
}: Props) => {
  const classes = useErrorStyle()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || '0'

  const handleRadioCheck = (value: string) => {
    let result = undefined
    result = form.values.searchTargetObj && form.values.searchTargetObj.value === value
    return result
  }
  const handleChange = (e: any) => {
    if (e.target.value !== undefined) {
      if (handleRadioCheck(e.target.value)) {
        form.setFieldValue(name, null)
        form.setFieldValue('mcbCategoryObj', null)
        form.setFieldValue('linkDateFrom', null)
        form.setFieldValue('linkDateTo', null)
        const optionA: Option = { value: '0', label: 'AND' }
        form.setFieldValue('mcbCategoryAndOrObj', optionA)
        disabledMBC(true)
      } else {
        const option: Option = { value: e.target.value, label: e.target.name }
        form.setFieldValue(name, option)
        if (e.target.value) {
          disabledMBC(false)
        } else {
          disabledMBC(true)
        }
      }
    }
  }

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={className}>
      <RadioGroup row={row} value={value} onClick={handleChange}>
        {optionList.map(i => (
          <FormControlLabel
            key={i.value}
            control={
              <Radio
                color='primary'
                value={i.value}
                disabled={disabled}
                checked={handleRadioCheck(i.value)}
                name={i.label}
              />
            }
            label={i.label}
          />
        ))}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default ToggleMBC
