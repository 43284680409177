import React from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'

const useStyles = makeStyles({
  helperText: {
    fontSize: '11px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily:
      'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 500,
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    whiteSpace: 'pre-wrap',
  },
  radioStyleset:{
    fontSize: '16px',
    height: '50px',
    // 次期開発9月#51248 start
    paddingBottom: '70px',
    // 次期開発9月#51248 end
  }
})

interface Props extends FieldProps {
  disabled?: boolean
  label: string
  radiolist: {
    label: string
    value: string
  }[]
  // 次期開発9月#51248 start
  setError:({isError: boolean, message: string}: any) => void
  setError2:({isError: boolean, message: string}: any) => void
  // 次期開発9月#51248 end
}

// 次期開発9月#51248 start
const Toggle = ({ field, form, label, disabled, radiolist,setError,setError2, ...others }: Props) => {
// 次期開発9月#51248 end
  const classes = useStyles()
  const { name, value } = field
  const { errors, touched } = form
  // 次期開発9月#51248 start
  const globalMenu = useSelector((state: RootState) => state.globalMenu)
  // 次期開発9月#51248 end

  const handleChange = (e: any) => {
    form.setFieldValue(name, e.target.value)
    if (e.target.value === '0') {
      form.setFieldValue('searchInsertName', '')
      // 次期開発9月#51248 start
      form.setFieldValue('searchInsertActionName', '')
      setError({ isError: false, message: '' })
      setError2({ isError: false, message: '' })
    } else if (e.target.value === '1') {
      form.setFieldValue('searchInsertActionName', '')
      setError2({ isError: false, message: '' })
    } else if (e.target.value === '2') {
      form.setFieldValue('searchInsertName', '')
      setError({ isError: false, message: '' })
    }
    // 次期開発9月#51248 end
  }

  return (
    // 次期開発9月#51248 start
    <FormControl style={{marginTop:'10px'}} error={Boolean(errors[name] && touched[name])}>
    {/* 次期開発9月#51248 end */}
      <RadioGroup  aria-label='gender' value={value} onChange={handleChange} {...others}>
        {/* 転職March #75621 START */}
        {radiolist.map(i => (
          i.value === '2' && (globalMenu.permissions[0] === '40' || globalMenu.permissions[0] === '50' || globalMenu.marchResponsibleCompanyFlag === "1") ? "" :
          <FormControlLabel
            className={classes.radioStyleset}
            control={<Radio />}
            label={i.label}
            color='primary'
            value={i.value}
            key={i.value}
            disabled={disabled}
          />
        ))}
        {/* 転職March #75621 END */}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default Toggle
