import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { submitValue } from '../pages/MCAZS040/formConfig'
import { submitValueMCAZS030 } from '../pages/MCAZS030/formConfig'
import { MCAZS040MessageQueryRequest } from 'types/MCAZS040/MCAZS040MessageQueryRequest'
import { MCAZS040MessageSelectRequest } from 'types/MCAZS040/MCAZS040MessageSelectRequest'
import { MCAZS040MessageSelectedRequest } from 'types/MCAZS040/MCAZS040MessageSelectedRequest'
// MCAZS020 START
import { submitCondition } from '../pages/MCAZS020/formConfig'
import { MCAZS020MessageQueryRequest } from 'types/MCAZS020/MCAZS020MessageQueryRequest'
import { MCAZS020MessageSelectRequest } from 'types/MCAZS020/MCAZS020MessageSelectRequest'
import { MCAZS020MessageAllSelectedRequest } from 'types/MCAZS020/MCAZS020MessageAllSelectedRequest'
// MCAZS020 END
import { MCAZS030MessageQueryRequest } from 'types/MCAZS030/MCAZS030MessageQueryRequest'
import { MCAZS030MessageSelectRequest } from 'types/MCAZS030/MCAZS030MessageSelectRequest'
import { MCAZS030MessageSelectedRequest } from 'types/MCAZS030/MCAZS030MessageSelectedRequest'
import { dustboxSubmitValue } from '../pages/MCAZS050/formConfig'
import { MCAZS050MessageQueryRequest } from 'types/MCAZS050/MCAZS050MessageQueryRequest'
import { MCAZS050MessageSelectRequest } from 'types/MCAZS050/MCAZS050MessageSelectRequest'
import { MCAZS050MessageSelectedRequest } from 'types/MCAZS050/MCAZS050MessageSelectedRequest'
import { DustboxMessages } from 'pages/MCAZS050/Results'
import { MCAZS010MessageQueryRequest } from 'types/MCAZS010/MCAZS010MessageQueryRequest'
import { MCAZS010MessageSelectRequest } from 'types/MCAZS010/MCAZS010MessageSelectRequest'
import { receiveSubmitValue } from 'pages/MCAZS010/formConfig'
import { MCAZS010MessageAllSelecteRequest } from 'types/MCAZS010/MCAZS010MessageAllSelecteRequest'
import { DraftMessages } from 'pages/MCAZS040/Results'

// =============================MCAZS010 START==========================
export interface messageReceiveList {
  // 次期開発5月向#58931 add
  [key: string]: string | number | Date | ApplicantsListInfo[] | boolean

  messageSendId: number //メッセージ送信ID
  messageId: number //メッセージID
  familyName: string //姓
  name: string //名
  jobSeekerIdForDisplay: string //表示用求職者ID
  readFlag: string //既読フラグ
  readFlagName: string //既読フラグ(表示用)
  repliedFlag: string //返信済みフラグ
  repliedFlagName: string //返信済みフラグ(表示用)
  unsubscribeTime: Date //退会日時
  messageType: string //メッセージ種別
  messageTypeName: string //メッセージ種別(表示用)
  entryType: string //エントリー種別
  entryTypeName: string //エントリー種別(表示用)
  subject: string //件名
  messageIdAttachment: number //メッセージID(メッセージ添付ファイル)
  progressName: string //進捗名
  decisionDivision: string //判定区分
  decisionDivisionName: string //判定区分(表示用)
  selectionName: string //選考名
  receivingTime: string //受信日時
  receivingTimeStr: string //受信日時(表示用)
  sysVersionNumber: number //システムバージョン番号
  labelFlag: string //背景色
  labelFlagGray: string //背景色再判定
  selectionManagementId: string // 選考管理ID
  selectionFlowSettingId: string // 選考フロー設定ID
  entryId: string // エントリーID
  jobSeekerId: string // 求職者ID
  // phase2 start
  // 媒体フラグ
  mediaFlag : string;
  // phase2 end
  applicantsListInfo: ApplicantsListInfo[] // 選考情報リスト
  // 次期開発5月向#58931 start
  // 閲覧権限フラグ
  displayFlag : boolean
  // 次期開発5月向#58931 end
}
export interface ApplicantsListInfo {
  [key: string]: string

  jobSeekerId: string // 求職者ID
  entryId: string // エントリーID
  selectionManagementId: string // 選考管理ID
}
export interface displaySelectionList {
  [key: string]: string | number

  selectionFlowSettingId: number // 選考フロー設定ID
  selectionName: string // 選考名
}
export interface displayProgressList {
  [key: string]: string | number

  progressStatusSettingId: number // 選考ステップ設定ID
  progressName: string // 進捗名
  selectionFlowSettingId: number // 選考フロー設定ID
  decisionDivisionType: string
}

export interface messageCount {
  [key: string]: number

  receiveUnReadMsgCount: number // 左メニュー受信トレーに表示される件数
  reserveMsgCount: number // 左メニュー送信予約に表示される件数
  draftMsgCount: number // 左メニュー下書きに表示される件数
}

export interface MessageFlag {
    [key: string]: number
    messageFlagVal: number
}

const messageFlag = {
    messageFlagVal: -1
}
// =============================MCAZS010 END===========================
// ===========================================MCAZS030 START=================================================
export interface MessageReservations {
  // 次期開発5月向#58931 add
  [key: string]: string | number | ApplicantsListInfo[] | boolean
  // メッセージ送信ID
  messageSendId: number
  // メッセージID
  messageId: any
  // 一括送信フラグ
  bulkSendFlag: string
  // 画面用一括送信フラグ
  bulkSendFlagName: string
  // 姓
  familyName: string
  // 名
  name: string
  // 表示用求職者ID
  jobSeekerIdForDisplay: string
  // 送信件数
  sendCount: number
  // 退会日時
  unsubscribeTime: string
  // 会員種別
  memberType: string
  // 画面用会員種別
  memberTypeName: string
  // メッセージ送信種別
  messageSendType: string
  // 画面用メッセージ送信種別
  messageSendTypeName: string
  // メッセージ種別
  messageType: string
  // 画面用メッセージ種別
  messageTypeName: string
  // エントリー種別
  entryType: string
  // 画面用エントリー種別
  entryTypeName: string
  // 件名
  subject: string
  // メッセージID(メッセージ添付ファイル)
  messageIdAttachment: number
  // 進捗名
  progressName: string
  // 判定区分
  decisionDivision: string
  // 画面用判定区分
  decisionDivisionName: string
  // 選考名
  selectionName: string
  // 氏名
  fullName: string
  // 送信予定日時
  sendPlanTime: string
  // 送信予定日時
  sendPlanTimeShow: string
  // システムバージョン番号
  sysVersionNumber: number
  // 選考管理ID
  selectionManagementId: string
  // エントリーID
  entryId: string
  // 求職者ID
  jobSeekerId: string
  // 背景色
  labelFlag: string
  // 選考情報リスト
  applicantsListInfo: ApplicantsListInfo[]
  // 次期開発5月向#58931 start
  // 閲覧権限フラグ
  displayFlag : boolean
  // 次期開発5月向#58931 end
}
// ===========================================MCAZS030 END=================================================

export interface MessageDrafts {
  // 次期開発5月向#58931 add
  [key: string]: string | number | Date | ApplicantsListInfo[] | boolean
  // メッセージ送信ID
  messageSendId: number
  // メッセージID
  messageId: any
  // 一括送信フラグ
  bulkSendFlag: string
  // 画面用一括送信フラグ
  bulkSendFlagName: string
  // 送信件数
  sendCount: number
  // 求職者ID
  jobSeekerId: string
  // エントリーID
  entryId: string
  // 背景色
  labelFlag: string
  // 選考管理ID
  selectionManagementId: string
  // 選考情報リスト
  applicantsListInfo: ApplicantsListInfo[]
  // 退会日時
  unsubscribeTime: Date
  // 会員種別
  memberType: string
  // 姓
  familyName: string
  // 名
  name: string
  // 表示用求職者ID
  jobSeekerIdForDisplay: number
  // 送信先
  messageSendType: string
  // 画面用送信先
  messageSendTypeName: string
  // メッセージ種別
  messageType: string
  // 画面用メッセージ種別
  messageTypeName: string
  // 応募種別
  entryType: string
  // 画面用応募種別
  entryTypeName: string
  // 件名
  subject: string
  // 添付
  messageIdAttachment: number
  // 選考ステップ
  progressName: string
  // 合否
  decisionDivision: string
  // 画面用合否
  decisionDivisionName: string
  // 選考フロー
  selectionName: string
  // 更新者
  fullName: string
  // 更新日時
  updateTime: string
  // システムバージョン番号
  sysVersionNumber: string
  // 次期開発5月向#58931 start
  // 閲覧権限フラグ
  displayFlag : boolean
  // 次期開発5月向#58931 end
}

// MCAZS020 START
export interface MessageOutBox {
  // 次期開発5月向#58931 add
  [key: string]: string | number | Date | ApplicantsListInfo[] | boolean
  // メッセージ送信ID
  messageMinId: number
  // メッセージID
  messageSendId: number
  // 画面用一括送信フラグ
  bulkSendFlagName: string
  // 一括送信フラグ
  bulkSendFlag: string
  // 姓
  familyName: string
  // 名
  name: string
  // 表示用求職者ID
  jobSeekerIdForDisplay: number
  // 送信結果（単体送信）
  sendingResult: string
  // 送信結果（単体送信）
  sendingResultName: string
  // 送信件数
  sendCount: number
  // 失敗件数
  failureCount: number
  // 送信結果（一括送信）
  sendingResultBulk: string
  // メッセージID
  messageId: string
  // 選考管理ID（一括送信）
  selectionManagementIdBulk: string
  // 退会日時
  unsubscribeTime: Date
  // 会員種別
  memberType: string
  // 送信先
  messageSendType: string
  // 画面用送信先
  messageSendTypeName: string
  // 既読フラグ
  readFlag: string
  // 既読フラグ
  readFlagName: string
  // メッセージ種別
  messageType: string
  // 画面用メッセージ種別
  messageTypeName: string
  // 応募種別
  entryType: string
  // 画面用応募種別
  entryTypeName: string
  // 件名
  subject: string
  // 添付
  messageIdAttachment: number
  // 選考ステップ
  progressName: string
  // 合否
  decisionDivision: string
  // 画面用合否
  decisionDivisionName: string
  // 選考フロー
  selectionName: string
  // 送信者
  fullName: string
  // 送信日時
  sendTime: string
  // 画面用送信日時
  sendTimeShow: string
  // システムバージョン番号
  sysVersionNumber: string
  // 背景色
  labelFlag: string
  // 求職者ID
  jobSeekerId: string
  // エントリーID
  entryId: string
  // 選考管理ID
  selectionManagementId: string
  // 選考情報リスト
  applicantsListInfo: ApplicantsListInfo[]
  // メッセージ送信状態
  senderStatus: string
  // [phase2] start by zhangxp
  // 添付オプションタイトル
  attachedOptionTitle: string
  // [phase2] end by zhangxp
  // 返信希望アイコン
  replyNecessaryFlag: string
  // 返信期限
  replyLimitTime: string
  // 次期開発5月向#58931 start
  // 閲覧権限フラグ
  displayFlag: boolean
  // 次期開発5月向#58931 end
}

// MCAZS020 END

// ===========================================MCAZS050  satrt=================================================
export interface MessageDustbox {
  // メッセージ送信ID
  messageSendId: number
  // メッセージID
  messageId: number
  // 背景色
  labelFlag: string
  // 一括送信フラグ
  bulkSendFlag: string
  // 画面用一括送信フラグ
  bulkSendFlagName: string
  // 送信件数
  sendCount: number
  // 求職者ID
  jobSeekerId: string
  // エントリーID
  entryId: string
  // 選考管理ID
  selectionManagementId: string
  // 選考情報リスト
  applicantsListInfo: ApplicantsListInfo[]
  // 退会日時
  unsubscribeTime: string
  // 会員種別
  memberType: string
  // 姓
  familyName: string
  // 名
  name: string
  // 表示用求職者ID
  jobSeekerIdForDisplay: number
  // 送信先
  messageSendType: string
  // 画面用送信先
  messageSendTypeName: string
  // 分類
  status: string
  // 送受信区分
  sendAndReceiveDivision: string
  // 画面用分類
  statusName: string
  // メッセージ種別
  messageType: string
  // 画面用メッセージ種別
  messageTypeName: string
  // 応募種別
  entryType: string
  // 画面用応募種別
  entryTypeName: string
  // 件名
  subject: string
  // 添付
  messageIdAttachment: number
  // 選考ステップ
  progressName: string
  // 合否
  decisionDivision: string
  // 画面用合否
  decisionDivisionName: string
  // 選考フロー
  selectionName: string
  // 更新者
  fullName: string
  // 削除日時
  deleteTime: String
  // システムバージョン番号
  sysVersionNumber: string
}

// ===========================================MCAZS050 end=================================================

interface MessageDraftList {
  pageChangeFlag: number
// =============================MCAZS010 START==========================
  messageReceiveListSearchCondition: MCAZS010MessageQueryRequest
  messageReceiveListResults: messageReceiveList[]
  displaySelectionResults: displaySelectionList[]
  displayProgressResults: displayProgressList[]
  // 次期開発5月向#58931 start
  selectMessageAliveList: string[]
  // 次期開発5月向#58931 end
  messageReceiveListCount: number
  messageReceiveListSort: string
  messageReceiveListPage: number
  receiveSelectedFlag: number
  selectedReceiveList: MCAZS010MessageSelectRequest[]
  receiveButtonActiveFlag: number
  // phase2 Start
  receiveCount: number // 受信トレー件数
  reserveCount: number// 送信予約件数
  draftCount: number // 下書き件数
  // phase2 End

  // MCB ロット３　start
  mcbReserveCount: number | null // MCB送信予約件数
  mcbDraftCount: number | null // MCB下書き件数
  // MCB ロット３　end

  messageInputSelected: string[]
  messageFlagResult: MessageFlag
  // =============================MCAZS010 END============================
  // ===========================================MCAZS030 START=================================================
  messageReservationListSearchCondition: MCAZS030MessageQueryRequest
  messageReservationListResults: MessageReservations[]
  messageReservationListAllCount: number
  messageReservationListSort: string
  messageReservationListPage: number
  selectedFlagMCAZS030: number
  buttonActiveFlagMCAZS030: number
  selectedReservationList: MCAZS030MessageSelectRequest[]
  reserveFromDateResult: string
  // ===========================================MCAZS030 END=================================================
  messageDraftListSearchCondition: MCAZS040MessageQueryRequest
  messageDraftListResults: MessageDrafts[]
  messageDraftListAllCount: number
  messageDraftListSort: string
  messageDraftListPage: number
  selectedFlag: number
  buttonActiveFlag: number
  selectedDraftList: MCAZS040MessageSelectRequest[]
  updateFromDateResult: string
  // MCAZS020 START
  messageOutBoxListSearchCondition: MCAZS020MessageQueryRequest
  messageOutBoxListResults: MessageOutBox[]
  messageOutBoxListAllCount: number
  messageOutBoxListSort: string
  messageOutBoxListPage: number
  outBoxSelectedFlag: number
  outBoxButtonActiveFlag: number
  selectedOutBoxList: MCAZS020MessageSelectRequest[]
  sendMessageFlag: number
  sendFromDateResult: string
  // MCAZS020 END

  // ===========================================MCAZS050 satrt=================================================

  messageDustboxListSearchCondition: MCAZS050MessageQueryRequest
  messageDustboxListResults: DustboxMessages[]
  messageDustboxListAllCount: number
  messageDustboxListSort: string
  messageDustboxListPage: number
  selectedDustboxFlag: number
  buttonActiveDustboxFlag: number
  selectedDustboxList: MCAZS050MessageSelectRequest[]
  deleteFromDateResult: string
  // ===========================================MCAZS050 end==================================================
}

const messageList: MessageDraftList = {
  pageChangeFlag: 0,
// =============================MCAZS010 START==========================
  messageReceiveListSearchCondition: receiveSubmitValue,
  messageReceiveListResults: [],
  displaySelectionResults: [],
  displayProgressResults: [],
  // 次期開発5月向#58931 start
  selectMessageAliveList: [],
  // 次期開発5月向#58931 end
  messageReceiveListCount: 0,
  messageReceiveListSort: '0',
  messageReceiveListPage: 1,
  receiveSelectedFlag: 0,
  selectedReceiveList: [],
  receiveButtonActiveFlag: 0,
  // phase2 Start
  receiveCount: 0, // 受信トレー件数
  reserveCount: 0,// 送信予約件数
  draftCount: 0, // 下書き件数
  // phase2 End
  
  // MCB ロット３　start
  mcbReserveCount:null,
  mcbDraftCount:null,
  // MCB end

  messageInputSelected: [],
  messageFlagResult: messageFlag,
  // ==============================MCAZS010 END===========================
  // ===========================================MCAZS030 START=================================================
  messageReservationListSearchCondition: submitValueMCAZS030,
  messageReservationListResults: [],
  messageReservationListAllCount: 0,
  messageReservationListSort: '0',
  messageReservationListPage: 1,
  selectedFlagMCAZS030: 0,
  buttonActiveFlagMCAZS030: 0,
  selectedReservationList: [],
  reserveFromDateResult:'',
  // ===========================================MCAZS030 END=================================================
  // ===========================================MCAZS040 START=================================================
  messageDraftListSearchCondition: submitValue,
  messageDraftListResults: [],
  messageDraftListAllCount: 0,
  messageDraftListSort: '0',
  messageDraftListPage: 1,
  selectedFlag: 0,
  buttonActiveFlag: 0,
  selectedDraftList: [],
  updateFromDateResult: '',
  // ===========================================MCAZS040 END=================================================
  // MCAZS020 START
  messageOutBoxListSearchCondition: submitCondition,
  messageOutBoxListResults: [],
  messageOutBoxListAllCount: 0,
  messageOutBoxListSort: '0',
  messageOutBoxListPage: 1,
  outBoxSelectedFlag: 0,
  outBoxButtonActiveFlag: 0,
  selectedOutBoxList: [],
  sendMessageFlag: 1,
  sendFromDateResult: '',
  // MCAZS020 END
  // ===========================================MCAZS050 satrt=================================================
  messageDustboxListSearchCondition: dustboxSubmitValue,
  messageDustboxListResults: [],
  messageDustboxListAllCount: 0,
  messageDustboxListSort: '0',
  messageDustboxListPage: 1,
  selectedDustboxFlag: 0,
  buttonActiveDustboxFlag: 0,
  selectedDustboxList: [],
  deleteFromDateResult: '',
  // ===========================================MCAZS050 end===================================================
}

const messageListSlice = createSlice({
  name: 'accout',
  initialState: messageList,
  reducers: {
    setPageChangeFlag(state, action: PayloadAction<number>) {
      state.pageChangeFlag = action.payload
      return state
    },
    updateClickData(
      state,
      action: PayloadAction<number>
    ) {
      const data = state.messageReceiveListResults
      const targetData = data.find(
        i => i.messageSendId === action.payload
      )
      if (targetData) {
        targetData.labelFlag = targetData.labelFlagGray
      }
      state.messageReceiveListResults = data
      return state
    },
    updaterepliedData(
    state,
      action: PayloadAction<number>
    ) {
      const data = state.messageReceiveListResults
      const targetData = data.find(
        i => i.messageSendId === action.payload
      )
      if (targetData) {
        targetData.repliedFlag = '0'
        targetData.readFlag = '1'
        targetData.readFlagName = '既読'
        targetData.repliedFlagName = '未返信'
      }
      state.messageReceiveListResults = data
      return state
    },
// =============================MCAZS010 START==========================
    initReceiveSelect(state, action: PayloadAction<MCAZS010MessageQueryRequest>) {
      return state
    },
    setDisplaySelectionResults(
      state,
      action: PayloadAction<displaySelectionList[]>
    ) {
      state.displaySelectionResults = action.payload
      return state
    },
    setDisplayProgressResults(
      state,
      action: PayloadAction<displayProgressList[]>
    ) {
      state.displayProgressResults = action.payload
      return state
    },
    // 次期開発5月向#58931 start
    setSelectMessageAliveList(
      state,
      action: PayloadAction<string[]>
    ) {
      state.selectMessageAliveList = action.payload
      return state
    },
    // 次期開発5月向#58931 end
    setReceiveFromDate(state,action: PayloadAction<string>){
      state.messageReceiveListSearchCondition.receiveFromDate = action.payload
      return state
    },
    setMessageReceiveList(state, action: PayloadAction<messageReceiveList[]>) {
      state.messageReceiveListResults = action.payload
      if (action.payload.length > 0) {
          state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    setMessageFlagResult(state, action: PayloadAction<number>) {
        state.messageFlagResult.messageFlagVal = action.payload
    },
    setMessageReceiveListSearchCondition(
      state,
      action: PayloadAction<MCAZS010MessageQueryRequest>
    ) {
      state.messageReceiveListSearchCondition = action.payload
      return state
    },
    setMessageReceiveListCount(state, action: PayloadAction<number>) {
      state.messageReceiveListCount = action.payload
      return state
    },
    setMessageReceiveListSort(state, action: PayloadAction<string>) {
      state.messageReceiveListSort = action.payload
      return state
    },
    setMessageReceiveListPage(state, action: PayloadAction<number>) {
      state.messageReceiveListPage = action.payload
      return state
    },
    setReceiveSelectedFlag(state, action: PayloadAction<number>) {
      state.receiveSelectedFlag = action.payload
      return state
    },
    setSelectedReceiveList(
      state,
      action: PayloadAction<MCAZS010MessageSelectRequest[]>
    ) {
      state.selectedReceiveList = action.payload
      return state
    },
    receiveToTrashbox(
      state,
      action: PayloadAction<MCAZS010MessageAllSelecteRequest>
    ) {
      return state
    },
    setReceiveButtonActiveFlag(state, action: PayloadAction<number>) {
      state.receiveButtonActiveFlag = action.payload
      return state
    },
    // phase2 Start
    selectReceiveCount(state) {
      return state
    },
    selectReserveCount(state) {
      return state
    },
    selectDraftCount(state) {
      return state
    },
    setReceiveCount(state, action: PayloadAction<number>) {
      state.receiveCount = action.payload
      return state
    },
    setReserveCount(state, action: PayloadAction<number>) {
      state.reserveCount = action.payload
      return state
    },
    setDraftCount(state, action: PayloadAction<number>) {
      state.draftCount = action.payload
      return state
    },
    getinitAreaReceive(state) {
      return state
    },
    getinitAreaOutBox(state) {
      return state
    },
    getinitAreaReservation(state) {
      return state
    },
    getinitAreaDraft(state) {
      return state
    },
    getinitAreaDustBox(state) {
      return state
    },
    // phase2 End
    initMessageReceiveListSearchCondition(
      state,
      action: PayloadAction<MCAZS010MessageQueryRequest>
    ) {
      state.messageReceiveListSearchCondition = action.payload
      return state
    },
    setMessageInputSelected(state, action: PayloadAction<string[]>) {
      state.messageInputSelected = action.payload
      return state
    },
    sendMessageMCAZS010(
      state,
      action: PayloadAction<MCAZS010MessageQueryRequest>
    ) {
      return state
    },
    // MCB ロット３　start
    selectMcbReserveCount(state) {
      return state
    },
    selectMcbDraftCount(state) {
      return state
    },
    setMcbReserveCount(state, action: PayloadAction<number | null>) {
      state.mcbReserveCount = action.payload
      return state
    },
    setMcbDraftCount(state, action: PayloadAction<number | null>) {
      state.mcbDraftCount = action.payload
      return state
    },
    // MCB ロット３　end
    // =============================MCAZS010 END==========================
    // ===========================================MCAZS030 START=================================================
    initSelectMCAZS030(
      state,
      action: PayloadAction<MCAZS030MessageQueryRequest>
    ) {
      return state
    },
    setMessageReservationListSearchCondition(
      state,
      action: PayloadAction<MCAZS030MessageQueryRequest>
    ) {
      state.messageReservationListSearchCondition = action.payload
      return state
    },
    initMessageReservationListSearchCondition(
      state,
      action: PayloadAction<MCAZS030MessageQueryRequest>
    ) {
      state.messageReservationListSearchCondition = action.payload
      return state
    },
    setMessageReservationListResults(
      state,
      action: PayloadAction<MessageReservations[]>
    ) {
      state.messageReservationListResults = action.payload
      if (action.payload.length > 0) {
          state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    setMessageReservationListAllCount(state, action: PayloadAction<number>) {
      state.messageReservationListAllCount = action.payload
      return state
    },
    setMessageReservationListSort(state, action: PayloadAction<string>) {
      state.messageReservationListSort = action.payload
      return state
    },
    setMessageReservationListPage(state, action: PayloadAction<number>) {
      state.messageReservationListPage = action.payload
      return state
    },
    setSelectedFlagMCAZS030(state, action: PayloadAction<number>) {
      state.selectedFlagMCAZS030 = action.payload
      return state
    },
    setButtonActiveFlagMCAZS030(state, action: PayloadAction<number>) {
      state.buttonActiveFlagMCAZS030 = action.payload
      return state
    },
    setSelectedReservationList(
      state,
      action: PayloadAction<MCAZS030MessageSelectRequest[]>
    ) {
      state.selectedReservationList = action.payload
      return state
    },
    deleteMessageMCAZS030(
      state,
      action: PayloadAction<MCAZS030MessageSelectedRequest>
    ) {
      return state
    },
    sendMessageMCAZS030(
      state,
      action: PayloadAction<MCAZS030MessageSelectedRequest>
    ) {
      return state
    },
    setReserveFromDate(state, action: PayloadAction<string>){
      state.reserveFromDateResult = action.payload
      state.messageReservationListSearchCondition.reserveFromDate = action.payload
      return state
    },
    // [phase2] start by zhangxp
    getFailSelectionManagementIdBulkListMCAZS030(state,action: PayloadAction<MessageReservations>){
      return state
    },
    // [phase2] end by zhangxp
    // #5654 #59450 start
    getSelectionManagementIdBulkListMCAZS030(state,action: PayloadAction<MessageReservations>){
      return state
    },
    // #5654 #59450 end
    // 次期開発5月向#58931 start
    editMessageMCAZS030(state,action: PayloadAction<any>){
      return state
    },
    // 次期開発5月向#58931 end
    // ===========================================MCAZS030 END=================================================
    // ===========================================MCAZS040 START=================================================
    setMessageDraftListResults(state, action: PayloadAction<MessageDrafts[]>) {
      state.messageDraftListResults = action.payload
      if (action.payload.length > 0) {
          state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    initSelect(state, action: PayloadAction<MCAZS040MessageQueryRequest>) {
      return state
    },
    setMessageDraftListSearchCondition(
      state,
      action: PayloadAction<MCAZS040MessageQueryRequest>
    ) {
      state.messageDraftListSearchCondition = action.payload
      return state
    },
    initMessageDraftListSearchCondition(
      state,
      action: PayloadAction<MCAZS040MessageQueryRequest>
    ) {
      state.messageDraftListSearchCondition = action.payload
      return state
    },
    setMessageDraftListAllCount(state, action: PayloadAction<number>) {
      state.messageDraftListAllCount = action.payload
      return state
    },
    setMessageDraftListSort(state, action: PayloadAction<string>) {
      state.messageDraftListSort = action.payload
      return state
    },
    setMessageDraftListPage(state, action: PayloadAction<number>) {
      state.messageDraftListPage = action.payload
      return state
    },
    setSelectedFlag(state, action: PayloadAction<number>) {
      state.selectedFlag = action.payload
      return state
    },
    setButtonActiveFlag(state, action: PayloadAction<number>) {
      state.buttonActiveFlag = action.payload
      return state
    },
    setSelectedDraftList(
      state,
      action: PayloadAction<MCAZS040MessageSelectRequest[]>
    ) {
      state.selectedDraftList = action.payload
      return state
    },
    deleteMessage(
      state,
      action: PayloadAction<MCAZS040MessageSelectedRequest>
    ) {
      return state
    },
    setUpdateFromDate(state, action: PayloadAction<string>){
      state.updateFromDateResult = action.payload
      state.messageDraftListSearchCondition.updateFromDate = action.payload
      return state
    },
    // #5654 #59450 start
    getSelectionManagementIdBulkListMCAZS040(state,action: PayloadAction<DraftMessages>){
      return state
    },
    // #5654 #59450 end
    // 次期開発5月向#58931 start
    editMessageMCAZS040(state,action: PayloadAction<any>){
      return state
    },
    // 次期開発5月向#58931 end
    // ===========================================MCAZS040 END=================================================
    // ===========================================MCAZS020 START================================================
    outBoxInit(state, action: PayloadAction<MCAZS020MessageQueryRequest>) {
      return state
    },
    setMessageOutBoxListResults(state, action: PayloadAction<MessageOutBox[]>) {
      state.messageOutBoxListResults = action.payload
      if (action.payload.length > 0) {
          state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    setMessageOutBoxListAllCount(state, action: PayloadAction<number>) {
      state.messageOutBoxListAllCount = action.payload
      return state
    },
    setMessageOutBoxListSort(state, action: PayloadAction<string>) {
      state.messageOutBoxListSort = action.payload
      return state
    },
    setMessageOutBoxListSearchCondition(
      state,
      action: PayloadAction<MCAZS020MessageQueryRequest>
    ) {
      state.messageOutBoxListSearchCondition = action.payload
      return state
    },
    initMessageOutBoxListSearchCondition(
      state,
      action: PayloadAction<MCAZS020MessageQueryRequest>
    ) {
      state.messageOutBoxListSearchCondition = action.payload
      return state
    },
    setMessageOutBoxListPage(state, action: PayloadAction<number>) {
      state.messageOutBoxListPage = action.payload
      return state
    },
    setSelectedOutBoxList(
      state,
      action: PayloadAction<MCAZS020MessageSelectRequest[]>
    ) {
      state.selectedOutBoxList = action.payload
      return state
    },
    setOutBoxSelectedFlag(state, action: PayloadAction<number>) {
      state.outBoxSelectedFlag = action.payload
      return state
    },
    setOutBoxButtonActiveFlag(state, action: PayloadAction<number>) {
      state.outBoxButtonActiveFlag = action.payload
      return state
    },
    deleteOutBoxMessage(
      state,
      action: PayloadAction<MCAZS020MessageAllSelectedRequest>
    ) {
      return state
    },
    getFailSelectionManagementIdBulkList(state,action: PayloadAction<MessageOutBox>){
      return state
    },
    // #5654 #59450 start
    getSelectionManagementIdBulkListMCAZS020(state,action: PayloadAction<MessageOutBox>){
      return state
    },
    // #5654 #59450 end
    // phase2 start by 考
    setSendMessageFlag(state, action: PayloadAction<number>){
      state.sendMessageFlag = action.payload
      return state
    },
    getSendMessageFlag(state,action: PayloadAction<string[]>){
      return state
    },
    setSentFromDate(state,action: PayloadAction<string>){
      state.sendFromDateResult = action.payload
      state.messageOutBoxListSearchCondition.sentFromDate = action.payload
      return state
    },
    // phase2 end by 考
    // ===========================================MCAZS020 END===================================================

    // ===========================================MCAZS050 satrt=================================================
    setMessageDustboxListResults(
      state,
      action: PayloadAction<DustboxMessages[]>
    ) {
      state.messageDustboxListResults = action.payload
      if (action.payload.length > 0) {
          state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    initDustboxSelect(
      state,
      action: PayloadAction<MCAZS050MessageQueryRequest>
    ) {
      return state
    },
    setMessageDustboxListSearchCondition(
      state,
      action: PayloadAction<MCAZS050MessageQueryRequest>
    ) {
      state.messageDustboxListSearchCondition = action.payload
      return state
    },
    initMessageDustboxListSearchCondition(
      state,
      action: PayloadAction<MCAZS050MessageQueryRequest>
    ) {
      state.messageDustboxListSearchCondition = action.payload
      return state
    },
    setMessageDustboxListAllCount(state, action: PayloadAction<number>) {
      state.messageDustboxListAllCount = action.payload
      return state
    },
    setMessageDustboxListSort(state, action: PayloadAction<string>) {
      state.messageDustboxListSort = action.payload
      return state
    },
    setMessageDustboxListPage(state, action: PayloadAction<number>) {
      state.messageDustboxListPage = action.payload
      return state
    },
    setSelectedDustboxFlag(state, action: PayloadAction<number>) {
      state.selectedDustboxFlag = action.payload
      return state
    },
    setButtonActiveDustboxFlag(state, action: PayloadAction<number>) {
      state.buttonActiveDustboxFlag = action.payload
      return state
    },
    setSelectedDustboxList(
      state,
      action: PayloadAction<MCAZS050MessageSelectRequest[]>
    ) {
      state.selectedDustboxList = action.payload
      return state
    },
    undoDustboxMessage(
      state,
      action: PayloadAction<MCAZS050MessageSelectedRequest>
    ) {
      return state
    },
    deleteDustboxMessage(
      state,
      action: PayloadAction<MCAZS050MessageSelectedRequest>
    ) {
      return state
    },
    setDeleteFromDate(state, action: PayloadAction<string>)
    {
      state.deleteFromDateResult = action.payload
      state.messageDustboxListSearchCondition.deleteFromDate = action.payload
      return state
    },
    // #5654 #59450 start
    getSelectionManagementIdBulkListMCAZS050(state,action: PayloadAction<DustboxMessages>){
      return state
    },
    // #5654 #59450 end
    // ===========================================MCAZS050 end=================================================
  },
})
export const {
  setPageChangeFlag,
// =============================MCAZS010 START==============================
  initReceiveSelect,
  setDisplaySelectionResults,
  setDisplayProgressResults,
  // 次期開発5月向#58931 start
  setSelectMessageAliveList,
  // 次期開発5月向#58931 end
  setReceiveFromDate,
  setMessageReceiveList,
  setMessageReceiveListSearchCondition,
  setMessageReceiveListCount,
  setMessageReceiveListSort,
  setMessageReceiveListPage,
  setReceiveSelectedFlag,
  setSelectedReceiveList,
  receiveToTrashbox,
  setReceiveButtonActiveFlag,
  // phase2 Start
  selectReceiveCount,
  selectReserveCount,
  selectDraftCount,
  setReceiveCount,
  setReserveCount,
  setDraftCount,
  getinitAreaOutBox,
  getinitAreaReceive,
  getinitAreaReservation,
  getinitAreaDraft,
  getinitAreaDustBox,
  // phase2 End
  initMessageReceiveListSearchCondition,
  setMessageInputSelected,
  setMessageFlagResult,
  updateClickData,
  updaterepliedData,
  sendMessageMCAZS010,
  // MCB ロット３　start
  selectMcbReserveCount,
  selectMcbDraftCount,
  setMcbReserveCount,
  setMcbDraftCount,
  // MCB ロット３　end
  // =============================MCAZS010 END==============================
  // ===========================================MCAZS030 START=================================================
  initSelectMCAZS030,
  setMessageReservationListSearchCondition,
  initMessageReservationListSearchCondition,
  setMessageReservationListResults,
  setMessageReservationListAllCount,
  setMessageReservationListSort,
  setMessageReservationListPage,
  setSelectedFlagMCAZS030,
  setButtonActiveFlagMCAZS030,
  setSelectedReservationList,
  sendMessageMCAZS030,
  deleteMessageMCAZS030,
  setReserveFromDate,
  // [phase2] start by zhangxp
  getFailSelectionManagementIdBulkListMCAZS030,
  // [phase2] end by zhangxp
  // #5654 #59450 start
  getSelectionManagementIdBulkListMCAZS030,
  // #5654 #59450 end
  // 次期開発5月向#58931 start
  editMessageMCAZS030,
  // 次期開発5月向#58931 end
  // ===========================================MCAZS030 END=================================================
  // ===========================================MCAZS040 START=================================================
  initSelect,
  setMessageDraftListSearchCondition,
  initMessageDraftListSearchCondition,
  setMessageDraftListResults,
  setMessageDraftListAllCount,
  setMessageDraftListSort,
  setMessageDraftListPage,
  setSelectedFlag,
  setButtonActiveFlag,
  setSelectedDraftList,
  deleteMessage,
  setUpdateFromDate,
  // #5654 #59450 start
  getSelectionManagementIdBulkListMCAZS040,
  // #5654 #59450 end
  // 次期開発5月向#58931 start
  editMessageMCAZS040,
  // 次期開発5月向#58931 end
  // ===========================================MCAZS040 END=================================================
  // ===========================================MCAZS020 START================================================
  outBoxInit,
  setMessageOutBoxListSearchCondition,
  initMessageOutBoxListSearchCondition,
  setMessageOutBoxListResults,
  setMessageOutBoxListAllCount,
  setMessageOutBoxListSort,
  setMessageOutBoxListPage,
  setSelectedOutBoxList,
  setOutBoxSelectedFlag,
  setOutBoxButtonActiveFlag,
  deleteOutBoxMessage,
  getFailSelectionManagementIdBulkList,
  setSendMessageFlag,
  getSendMessageFlag,
  setSentFromDate,
  // #5654 #59450 start
  getSelectionManagementIdBulkListMCAZS020,
  // #5654 #59450 end
  // ===========================================MCAZS020 END===================================================
  // ===========================================MCAZS050 satrt=================================================
  setMessageDustboxListResults,
  initDustboxSelect,
  setMessageDustboxListSearchCondition,
  initMessageDustboxListSearchCondition,
  setMessageDustboxListAllCount,
  setMessageDustboxListSort,
  setMessageDustboxListPage,
  setSelectedDustboxFlag,
  setButtonActiveDustboxFlag,
  setSelectedDustboxList,
  undoDustboxMessage,
  deleteDustboxMessage,
  setDeleteFromDate,
  // #5654 #59450 start
  getSelectionManagementIdBulkListMCAZS050,
  // #5654 #59450 end
  // ===========================================MCAZS050 end=================================================
} = messageListSlice.actions

export default messageListSlice.reducer
