import { getMessage } from "common/messageUtil"
import { magiContants } from "utils/contants"

const initialValues = {
  recruitmentManagementFlagId: '',
  recruitmentManagementFlag: '',
  // #72427 次期開発2022年12月 start
  targetYear: '',
  // #72427 次期開発2022年12月 end
  display: 0,
  type: 0,
  optionName: [],
  optionNameId: [] as string[],
  sysVersionNumber:'',
}

const initDeleteEmploymentManagementDataRequest = {
  recruitmentManagementFlagId: '',
  versionNumber: '',
  // #7737 start
  recruitmentManagementDivision: '',
  targetyear: ''
  // #7737 end

}

const displayRadioList = [
  { id: 'displayShow', label: '表示', value: 0 },
  { id: 'displayHide', label: '非表示', value: 1 },
]

const typeRadioList = [
  { id: 'typeString', label: '文字列', value: 10 },
  { id: 'typeInt', label: '数値', value: 20 },
  { id: 'typeList', label: '選択', value: 30 },
]

const textMap = {
  update: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCAHS030_003),
  },
  cancel: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCAHS030_006),
  },
  delete: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCAHS030_008),
  },
  confirmChange: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCAHS030_015)
  },
}

export {
  textMap,
  displayRadioList,
  typeRadioList,
  initialValues,
  initDeleteEmploymentManagementDataRequest,
}
