import React, { ReactNode } from 'react'
import moment from 'moment'
import { NavigateButton } from 'componentsHsc'
import { isEmpty } from 'common/generalUtil'
import { magiContants } from './contants'
import { cmnContents, mcbContents } from 'pages/MCAXS040/viewConfig'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'

const useStyles = makeStyles({
  mcbbutton: {
    width: '-webkit-fill-available',
    marginTop: '2px !important',
    marginBottom: '2px !important',
    marginLeft: '0px !important',
  },
  badgeBlue: {
    backgroundColor: "#fff",
    color: "#4596e6 !important",
    border: "2px solid #4596e6 !important",
    margin: "0 0px !important",
    height: 'auto !important',
    padding: '2px 8px !important',
  },
  link: {
    color: '#1950a6 !important',
    backgroundColor: 'white !important',
    borderColor: 'white !important',
    textDecoration: 'underline !important',
    '&:hover': {
      backgroundColor: 'white !important',
      borderColor: 'white !important',
      color: '#1a1a1a !important'
    },
    '&::before': {
      content: "'' !important"
    },
    padding: "0 0px !important",
  },
  mcbCheckDetails: {
    display: "flex",
    alignItems: "center",
  },
  margin: {
    margin: "0 8px"
  },
  mcbSubmissionRequestStatus: {
    fontWeight: "bold !important" as "bold",
  }
})

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    WebkitFilter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    transform: 'translate(-50%, -100%) translateY(-24px)',
    maxWidth: '400px',
    backgroundColor: '#ffffff',
    color: '#1a1a1a',
    padding: '16px',
    fontSize: '1.4rem',
    border: '1px solid #dadde9',
  },
  arrow: {
    color: '#ffffff',
    '&::before': {
      border: '0.1px solid #dadde9',
    },
  },
  popper: {
    whiteSpace: 'pre-line'
  },
}))(Tooltip)

export interface MessageData {
  classification: string
  destination: string
  messageSendId: string
  messageId: string
  status: string
  type: string
  entryType: string
  subject: string
  attachedFile: boolean
  selectionFlow: string
  sender: string
  datetime: string
  // phase2 start
  messageRepliesNecessary: string
  messageRepliesLimit: string
  messageScoutCharacter: string
  sendingResult:string
  // phase2 end
  sysVersionNumber?: number
}

/** 画面遷移リクエスト(メッセージ) */
export interface MessageScreenTransitionRequest {
  messageSendId: number[]
  entryId: string[]
  selectionManagementId: string[]
}

export interface McbMessageScreenTransitionRequest{
    screenId: string
    messageSendId: string
    jobSeekerByCompanyIdList: string[]
    requestIdList: string[]
}

export const messageTableDataTransform = (data: MessageData[], recruitmentManagementDivisions: string) =>
  data.map(item => [
    item.classification === '受信' ? (
      <i className='badge ptn1'>受信</i>
    ) : item.classification === '送信中' ? (
      <div className='messageType'>送信中</div>
    ) : (
      <i className='badge ptn10'>{item.classification}</i>
    ),
    '受信' !== item.classification && !isEmpty(item.sendingResult) && '1' === recruitmentManagementDivisions ?
    (
      <div style={{color:'red'}}>{item.sendingResult}</div>
    ):
    item.destination === 'e-mail' ? (
      <>
        <i className='badge ptn10'>e-mail</i>-
      </>
    ) 
    :(
      item.status
    ),
    <div className={item.attachedFile ? 'attached' : ''}>
      <div>
        {/* 7816　start */}
        {isEmpty(item.type) ? null : (recruitmentManagementDivisions == magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && item.type == 'お問い合わせ' ? <i className='badge ptn4'>{item.type}</i> : <i className='badge ptn7'>{item.type}</i>)}
        {/* 7816　end */}
        {isEmpty(item.entryType) ? null : (
          <i className='badge ptn3'>{item.entryType}</i>
        )}
        {/* phase2 start jxp */}
        {isEmpty(item.messageScoutCharacter) ? null  
          : item.messageScoutCharacter.split('／').map(
            value => isEmpty(value) ? null : (
              <i className='badge ptn7'
                 style={{ height: 'auto',minHeight: '24px',}}>
                {value as any}
              </i>)
        )}
        {isEmpty(item.type) && isEmpty(item.entryType) && isEmpty(item.messageScoutCharacter) ? null : <br />}
        {recruitmentManagementDivisions === "1" ? isEmpty(item.messageRepliesNecessary) ? null : <i className='badge ptn12'>{item.messageRepliesNecessary}</i>
        : null}
        {recruitmentManagementDivisions === "1" ?
           isEmpty(item.messageRepliesLimit) ? null : <span className='fontBlack'>期限：{moment(new Date(item.messageRepliesLimit)).format('YYYY/MM/DD')} </span>
           : null}
        {recruitmentManagementDivisions === "1" ? isEmpty(item.messageRepliesNecessary) && isEmpty(item.messageRepliesLimit) ? null : <br />
         : null}
        {/* phase2 end jxp */}
        <div dangerouslySetInnerHTML={{ __html: item.subject }} />
      </div>
      {item.attachedFile ? (
        <button type='button' className='btn only-icon icon-attached'>
          添付
        </button>
      ) : null}
    </div>,
    <span className='fontBlack'>{item.selectionFlow}</span>,
    item.sender,
    ( <>
    {moment(new Date(item.datetime)).format('YYYY/MM/DD')}
    <br />
    {moment(new Date(item.datetime)).format('HH:mm')}
    </>),
    item.classification === '受信' && item.status === '未読' ? 'unread' : '',
  ])
export interface McbMessageData {
    mcbMessageBoxClassification: string
    mcbMessageBoxStatus: JSX.Element | undefined
    mcbMessageBoxKinds: string | undefined
    mcbMessageBoxSubject: string
    mcbMessageBoxAttachment: boolean
    mcbMessageBoxSender: string
    mcbMessageBoxDateTime: string | undefined
    mcbMessageBoxMessageTypeCode: string
    mcbMessageBoxMessageId : number
    mcbMessageBoxMessageSendId: number
    mcbMessageBoxJobSeekerByCompanyId : number
    mcbMessageBoxRequestId : number
  }

export const McbMessageTableDataTransform = (data: McbMessageData[], classes: any) => {
  return data.map(item => [
  <div>
    <p className={`${classes.mcbMessageBoxStatus} mcbMessageBoxClassification badge ptn10`}>{item.mcbMessageBoxClassification}</p>
  </div>,
  <div>
    {item.mcbMessageBoxStatus != undefined && <p className={`mcbMessageBoxStatus`}>{item.mcbMessageBoxStatus}</p>}
  </div>,
  <div className={item.mcbMessageBoxAttachment ? 'attached' : ''} style={{ display: 'flex' }}>
    <div style={{ display: 'block',position: "static",width: "-webkit-fill-available"}}>
    {item.mcbMessageBoxKinds != undefined && 
      <p className={`mcbMessageBoxKinds badge ${classes.typeBadge}`} >{item.mcbMessageBoxKinds}</p>
    }
      <p className="mcbMessageBoxSubject" dangerouslySetInnerHTML={{ __html: item.mcbMessageBoxSubject }} />
    </div>
    {item.mcbMessageBoxAttachment && 
      <button type='button' className={`mcbMessageBoxAttachment btn only-icon icon-attached  ${classes.attachedIcon}`}>添付</button>
    }
  </div>,
  <div>
    <p className="mcbMessageBoxSender">{item.mcbMessageBoxSender}</p>
  </div>,
  <div>
    {item.mcbMessageBoxDateTime != undefined && item.mcbMessageBoxDateTime != null && 
      <p className="mcbMessageBoxDateTime">
        {moment(new Date(item.mcbMessageBoxDateTime)).format('YYYY/MM/DD')}
        <br />
        {moment(new Date(item.mcbMessageBoxDateTime)).format('HH:mm')}
      </p>
    }
  </div>
  ])
}
export interface EntryHistory {
  entryHistoryNo: number
  entryHistoryType: string
  entryHistoryTitle: string
  entryHistoryEntryDateTime: string
  entryHistoryHoldDateTime: string
  entryHistoryDistrict: string
  // [phase2] start by zhangxp
  entryHistoryArea: string
  // [phase2] end by zhangxp
  entryHistoryViewingStatus: string
  entryHistoryCancel: boolean
  entryHistoryCancelFlag: string
  entryHistoryCancelTime: string
  entryHistoryCancelType: string
  entryId: number
  manualRegistrationFlag: string
  manualRegistrationFlag2: string
}

// [phase2] start by zhangxp
export interface mcbLinkHistory {
  McbLinkHistoryNo: number
  McbLinkHistoryMcbCategory: string
  McbLinkHistoryLinkDatetime: string | null
  McbLinkHistorySearchConditionName: string
}
// [phase2] end by zhangxp
// [phase2] start by jxp
export interface scout {
  scoutNo: number
  scoutSendTime: string
  scoutMedia: string
  scoutCharacter: string
  scoutMessageStatus: string
}
// [phase2] end by jxp
export const entryHistoryDataTransform = (
  data: EntryHistory[],
  cancelAction: (entryId: number) => void
) =>
  data.map(item => [
    item.entryHistoryNo,
    item.entryHistoryType,
    // [phase2] start by zhangxp
    !item.entryHistoryTitle && !item.entryHistoryArea
      ? cmnContents.HYPHEN
      : (item.entryHistoryTitle
          ? item.entryHistoryTitle + magiContants.HALF_SPACE
          : magiContants.EMPTY) + item.entryHistoryArea,
    // [phase2] end by zhangxp
    // [phase2] #4988臨時
    item.entryHistoryType === 'セミナー' && item.manualRegistrationFlag2 != '0' ? (
      <i className='fontstyle italic'>{item.entryHistoryEntryDateTime}</i>
    ) : (
      item.entryHistoryEntryDateTime
    ),
    item.entryHistoryHoldDateTime,
    item.entryHistoryDistrict,
    item.entryHistoryViewingStatus === '-' ? (
        '-'
    ) : (           
      <i className={`badge ptn8 `} style = {{whiteSpace: 'nowrap',padding: '0 0px',marginRight: '0'}}>
        {item.entryHistoryViewingStatus}
      </i>
    ),
    item.entryHistoryCancel ? (
      item.entryHistoryCancelFlag === '1' ? (
        <div className={item.entryHistoryCancelType === '0' ? 'fontstyle' : 'fontstyle italic'} >
          {item.entryHistoryCancelTime}
        </div>
      ) : (
        <button
          className='btn high'
          type='button'
          onClick={() => {
            cancelAction(item.entryId)
          }}>
          キャンセル
        </button>
      )
    ) : (
      '-'
    ),
  ])

export interface QuestionList {
  questionNo: number
  questionContent: string
  questionRequiredFlag: boolean
  questionInvisibleFlag: boolean
  questionAnswer: string
}

export const questionnaireAnswerDataTransform = (data: QuestionList[]) =>
  data.map(item => [
    item.questionNo,
    <>
      {item.questionContent}
      {item.questionRequiredFlag && <i className='required'>必須</i>}
      {item.questionInvisibleFlag && <i className='hiddenBadge'>非表示</i>}
    </>,
    item.questionAnswer,
  ])

export interface MyCareer {
  no: string
  category: string
  submissionForm: string
  requestDate: string
  deadline: string
  submissionDate: string
}

export const myCareerDataTransform = (data: MyCareer[]) =>
  data.map(item => [
    item.no,
    item.category,
    <em>{item.submissionForm}</em>,
    item.requestDate,
    <>
      <time>{item.deadline}</time>
      <span>
        <NavigateButton to='' data={null} className='btn'>
          延長
        </NavigateButton>
      </span>
    </>,
    item.submissionDate,
    <>
      <i className='es'>ES</i>
      <i className='gray'>研究概要書</i>
    </>,
  ])

export interface Mcb {
    [key: string]: any
    mcbstatus: boolean
    mcbList: McbInfo[]
  }
  
export interface McbInfo{
    mcbNo : number
    mcbJobSeekerByCompanyId : number
    mcbSubmissionRequestId: number
    mcbSubmissionContentName : string
    mcbSubmissionRequestYear : string
    mcbCheckDetails : boolean
    mcbForceStopFlag : string
    mcbResubmit : string 
    mcbSubRequestStatus : JSX.Element | undefined
    mcbMemberId : number
    mcbSubmissionStatus : string | undefined
    mcbSubmissionStatusCode : string
    mcbUseStatus : string 
    mcbSubmission : {
      submissionEntrySheetFlag : string 
      submissionResumeFlag : string 
      submissionResearchSummaryFlag : string 
      submissionOriginalQuestionFlag : string 
      submissionFileSubmissionFlag : string 
    }
    mcbSubmissionRequestDay : string
    mcbSubmissionDeadlineDay : string | undefined
    mcbSubmissionDeadlineChangeDay : string | undefined
    mcbSubmissionRequestStatus : string | undefined
    mcbSubmissionFilingDay : string
    mcbSubmissionRecount   : number
    mcbMessageSendId : number
    mcbSubmissionRequestYearDeterminingMediaFlag : string
  }

export const McbDataTransform = (
  data: McbInfo[],
  mcbCheckDetailsClick: (index: number) => void,
  mcbSubmissionDownload: (mcbInfo: McbInfo) => void,
  mcbSubmissionMesseageMake: (mcbInfo: McbInfo) => void,
  mcbSubmissionRequestChange: (mcbInfo: McbInfo) => void,
) => {  
  const classes = useStyles()
  const { targetYear } = useSelector(
    (state: RootState) => state.globalMenu
  )
  interface SubmissionRequestChangeHtmlTooltipProps {
    disabled: boolean
    children: ReactNode
  }
  const SubmissionRequestChangeHtmlTooltip = ({
    disabled,
    children,
  }: SubmissionRequestChangeHtmlTooltipProps) => {
    return (
      <>
        {disabled ? (
          children
        ) : (
          <HtmlTooltip
            title={
              '対象年度が異なるため操作できません。\nリクエスト内容変更の操作をする場合、対象年度を切り替えて操作してください。'
            }
            placement='top'
            arrow>
            <span>{children}</span>
          </HtmlTooltip>
        )}
      </>
    )
  }

  return data.map(item => [
    <div className = "mcbNo" >
      {item.mcbNo}
    </div>,
    <div>
      <p className = "mcbContent">{item.mcbSubmissionContentName}({item.mcbSubmissionRequestYear})</p>
      <p className = {`${classes.mcbCheckDetails}`}>
        {item.mcbCheckDetails &&
          <button type='button' className={`mcbCheckDetails btn ${classes.link}`} onClick={()=>{mcbCheckDetailsClick(item.mcbNo-1)}}>
            詳細を確認する
          </button>
        }
        {item.mcbResubmit === mcbContents.ACCEPT && 
          <span className = {`mcbResubmit  badge ${classes.badgeBlue}`} style={{margin: "0 8px"}}>
            再提出可
          </span>
        }
      </p>
      <p className = "mcbSubRequestStatus">
        {item.mcbSubRequestStatus != undefined  && item.mcbSubRequestStatus}
      </p>
      <p className = "msbMemberId">
        {(item.mcbUseStatus === mcbContents.SUBMISSION_REQUESTED ||  item.mcbUseStatus === mcbContents.IN_USE) &&
          (item.mcbMemberId) &&
          <>
            <p>({item.mcbMemberId})</p>
            <br />
          </>
        }
      </p>
      <p className = "mcbSubmissionStatus" style={{color:'red',fontWeight: "bold",}}>
        {item.mcbSubmissionStatus != undefined && <>このリクエストは無効化されました。</>}
      </p>
    </div>,
    <div className = "mcbSubmission" >
      {item.mcbSubmission.submissionEntrySheetFlag        === mcbContents.TARGET && <><p>{mcbContents.ENTRY_SHEET}</p></>}
      {item.mcbSubmission.submissionResumeFlag            === mcbContents.TARGET && <><p>{mcbContents.REMUSE}</p></>}
      {item.mcbSubmission.submissionResearchSummaryFlag   === mcbContents.TARGET && <><p>{mcbContents.RESEARCH_SUMMARY}</p></>}
      {item.mcbSubmission.submissionOriginalQuestionFlag  === mcbContents.TARGET && <><p>{mcbContents.ORIGINAL_QUESTION}</p></>}
      {item.mcbSubmission.submissionFileSubmissionFlag    === mcbContents.TARGET && <><p>{mcbContents.FILE_SUBMISSION}</p></>}
    </div>,
    <div>
      {item.mcbSubmissionRequestDay &&
        <p className='mcbSubmissionRequestDay'>
          {moment(new Date(item.mcbSubmissionRequestDay)).format('YYYY/MM/DD HH:mm')}
        </p>
      }
      {item.mcbSubmissionDeadlineDay != undefined &&
        <p className="mcbSubmissionDeadlineDay">
        {item.mcbSubmissionDeadlineDay}
        </p>
      }
      {item.mcbSubmissionDeadlineChangeDay != undefined && 
        <p className={`mcbSubmissionDeadlineChangeDay badge ${classes.badgeBlue}`}>     
        {item.mcbSubmissionDeadlineChangeDay}
        </p>
      }
      <p className={`mcbSubmissionRequestStatus ${classes.mcbSubmissionRequestStatus}`}>
        {item.mcbSubmissionRequestStatus}
      </p>      
    </div>,
    <div>
      {item.mcbSubmissionFilingDay && 
        <p className = "mcbSubmissionFilingDay">
          {moment(new Date(item.mcbSubmissionFilingDay)).format('YYYY/MM/DD HH:mm')}
        </p>
      }
      {(item.mcbSubmissionRecount > 0) && 
        <i className = {`mcbSubmissionRecount  badge ${classes.badgeBlue}`}>再提出 : {item.mcbSubmissionRecount}回</i>
      }
    </div>,
    <div>
      <p>
        <HtmlTooltip
          title="該当のMCB情報のPDFを出力します。"
          placement='top'
          arrow
        >
          <button className={`mcbSubmissionDownload ${classes.mcbbutton} btn high`}  type='button' onClick={()=>{mcbSubmissionDownload(item)}}>
            {mcbContents.DOWNLOAD}
          </button>        
        </HtmlTooltip> 
      </p>
      <p>
        <HtmlTooltip
          title={
            targetYear === item.mcbSubmissionRequestYearDeterminingMediaFlag
              ? 'My CareerBoxメッセージを作成します。'
              : '対象年度が異なるため操作できません。\nMy CareerBoxメッセージ作成の操作をする場合、対象年度を切り替えて操作してください。'
          }
          placement='top'
          arrow>
          <span>
            <button
              className={`mcbSubmissionMesseageMake ${classes.mcbbutton} btn high`}
              type='button'
              disabled={
                targetYear !== item.mcbSubmissionRequestYearDeterminingMediaFlag
              }
              onClick={() => {
                mcbSubmissionMesseageMake(item)
              }}>
              {mcbContents.MESSAGE_MAKE}
            </button>
          </span>
        </HtmlTooltip>
      </p>
      <p>
        <SubmissionRequestChangeHtmlTooltip
          disabled={
            targetYear === item.mcbSubmissionRequestYearDeterminingMediaFlag
          }>
          <button
            className={`mcbSubmissionRequestChange ${classes.mcbbutton} btn high`}
            type='button'
            disabled={
              targetYear !== item.mcbSubmissionRequestYearDeterminingMediaFlag
            }
            onClick={() => {
              mcbSubmissionRequestChange(item)
            }}>
            {mcbContents.REQUEST_CHANGE}
          </button>
        </SubmissionRequestChangeHtmlTooltip>
      </p>
    </div>,
  ])
}