import request from 'utils/request'

import { MCAKS010QueryRequest } from 'types/MCAKS010/MCAKS010QueryRequest'
import { MCAKS010DeleteIdRequest } from 'types/MCAKS010/MCAKS010DeleteIdRequest'

export const searchRequest = (apiData: MCAKS010QueryRequest) =>
  request({
    url: `/MCAKS010/search`,
    method: 'post',
    data: apiData,
  })

export const deleteRequest = (apiData: MCAKS010DeleteIdRequest) =>
  request({
    url: '/MCAKS010/delete',
    method: 'post',
    data: apiData,
  })
