import React from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
  },
}))

interface Props extends FieldProps {
  placeholder?: string
  className?: string
}

export default ({ className, placeholder, field, form }: Props) => {
  const classes = useStyles()
  const { name, value } = field

  const handleChange = (e: any) => {
    form.setFieldValue(name, e.target.value)
  }

  return (
    <div className={className}>
      <input
        type='text'
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={classes.root}
      />
      <Typography variant='caption'>（数値）</Typography>
    </div>
  )
}
