import request from 'utils/request'
import { SearchCondition } from 'componentsHsc/AdvancedSearch/Config/searchConditionConfig'

export const getIntRequest = () =>
  request({
    url: `MCAXS3x1/init`,
    method: 'post',
  })

export const checkMcbServiceStatusRequest = () =>
  request({
    url: `MCAXS351/checkMcbServiceStatus`,
    method: 'post',
  })

