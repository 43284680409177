import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { removeToken } from 'utils/auth'
import { replaceToOriginUrl } from 'utils/misc'
import { routeList } from 'routes/routes'
import {
  init,
  initSuccess,
  getUserInfo,
  setUserInfo,
  getNewApplicantsCount,
  setNewApplicantsCount,
  getUnreadCVSCount,
  setUnreadCVSCount,
  getUnreadMessagesCount,
  setUnreadMessagesCount,
  getWaitForScheduleCount,
  setWaitForScheduleCount,
  signout,
  signoutSuccess,
  newsInit,
  setNewsInfo,
  initFlag,
  yearChange,
  setTargetYear,
  setTargetYearForDisplay,
  setTargetYearDatas,
  getYearData,
  setPromptForbiddenFlg,
  serviceModeChange,
  serviceModeInitial,
  setServiceMode,
  setServiceModeForDisplay,
  setMarchResponsibleCompanyFlag,
  setMarchServiceFlag,
  setMarchNoDisplayFlag,
  setUnreadCVSCountForMarch,
  setModeInitialSuccessFlag,
} from 'reducers/globalMenuReducer'
import {openModal} from 'reducers/messageReducer'
import {
  menuInitRequest,
  menuLogoutRequest,
  menuGetUserInfoRequest,
  getNewApplicantsCountRequest,
  getUnreadCVSCountRequest,
  getUnreadMessagesCountRequest,
  getWaitForScheduleCountRequest,
  menuInitNewApplicantsCountRequest,
  menuInitUnreadCVSCountRequest,
  menuInitUnreadMessagesCountRequest,
  menuInitWaitForScheduleCountRequest,
  yearChangeRequest,
  getYearDataequest,
  serviceModeInitialRequest,
  serviceChangeRequest,
  initUnreadMarchEntryCount,
} from 'apis/MCAAS010Api'

import {
  initRequest
} from 'apis/MCAAS020Api'
import { paramLoginUrlCheck } from 'layouts/Dashboard/handleTopBarUrl'
import { loginUrl } from 'selectors/index'
import { JudgeUrl } from 'layouts/Dashboard'
import { getGlobalMenuData } from 'selectors/authSelectors'
import { loginSuccess } from 'reducers/companyLoginReducer'
import history from 'utils/history'
import { getTargetYearFromToken } from 'utils/yearCheckUtil'
import { magiContants } from 'utils/contants'
import { listener as listenerMCACS020} from 'pages/MCACS020/formConfig';
import { listener as listenerMCAUS010} from 'pages/MCAUS010/formConfig';
import { listener as listenerMCAXS191} from 'pages/MCAXS191/formConfig';
import { listener as listenerMCAXS201} from 'pages/MCAXS201/formConfig';
import { listener as listenerMCAXS211} from 'pages/MCAXS211/formConfig';
import { listener as listenerMCAXS220} from 'pages/MCAXS220/formConfig';
import { listener as listenerMCAGS010} from 'pages/MCAGS010/formConfig';
import { getMarchModeFromToken } from 'utils/marchModeGetUtil'

function* menuInitSaga(action: ReturnType<typeof init>) {
  try {
    // #5583 start
    const data = yield call(menuGetUserInfoRequest)
    const magiApplicationStatus = data.magiApplicationStatus//MAGI申込状態
    if (magiApplicationStatus === "0") {
      removeToken()
      replaceToOriginUrl(routeList.readying)
    } else {
      const companyId = data.companyId//企業ID
      const companyAccountId = data.companyAccountId//企業アカウントID
      const recruitmentManagementDivision = magiApplicationStatus === "3" ? "1" : magiApplicationStatus
      const permissions = data.permissionList//権限
      yield put(setUserInfo({ companyId, companyAccountId, recruitmentManagementDivision, permissions, magiApplicationStatus }))

      const request: any = JSON.parse(localStorage.getItem('globalMenuInfo') as string)
      if (request && JudgeUrl() && (companyAccountId !== request.companyAccountId || recruitmentManagementDivision !== request.recruitmentManagementDivision)) {
        window.location.href = routeList.interruption
      }
      action.payload.recruitmentManagementDivision = recruitmentManagementDivision
      // #5583 end 
      const apiListNew = [
        // アカウント情報を取得
        call(menuInitRequest, action.payload),
        // 新規応募者数を取得
        call(menuInitNewApplicantsCountRequest, action.payload),
        // （新卒/中途）未読のメッセージ数を取得
        call(menuInitUnreadMessagesCountRequest, action.payload),
        // （新卒/中途）日程確定待ちの件数を取得
        // call(menuInitWaitForScheduleCountRequest, action.payload),
      ]
      const apiListCVS = [
        // アカウント情報を取得
        call(menuInitRequest, action.payload),
        // 履歴書の未読数を取得
        call(menuInitUnreadCVSCountRequest, action.payload),
        // （新卒/中途）未読のメッセージ数を取得
        call(menuInitUnreadMessagesCountRequest, action.payload),
        // （新卒/中途）日程確定待ちの件数を取得
        call(menuInitWaitForScheduleCountRequest, action.payload),
      ]
      // 転職March #75621 START
      // marchモード下履歴書の未読数
      if (getMarchModeFromToken() == '1') {
        apiListCVS.push(call(initUnreadMarchEntryCount))
      }
      // 転職March #75621 END
      const apiListAll = [
        // アカウント情報を取得
        call(menuInitRequest, action.payload),
        // 新規応募者数を取得
        call(menuInitNewApplicantsCountRequest, action.payload),
        // 履歴書の未読数を取得
        call(menuInitUnreadCVSCountRequest, action.payload),
        // （新卒/中途）未読のメッセージ数を取得
        call(menuInitUnreadMessagesCountRequest, action.payload),
        // （新卒/中途）日程確定待ちの件数を取得
        call(menuInitWaitForScheduleCountRequest, action.payload),
      ]

      let companyName = null //企業名
      let fullName = null //氏名
      let previousLoginTime = null  //前回ログイン日時
      let lastLogoutTime = null //最終ログアウト日時
      let lastLoginTime = null  //最終ログイン日時
      let newApplicantsCount = 0 //新規応募者数
      let unreadCVSCount = 0 //履歴書の未読数
      let unreadMessagesCount = 0  //（新卒/中途）未読のメッセージ数
      let waitForScheduleCount = 0 //（新卒/中途）日程確定待ちの件数
      let newsCount = 0  //（新卒/中途）公開されたお知らせの件数
      let delegateLoginAccountFlag = false // 代行ログインアカウントフラグ
      // 26KH #80783 START
      let syusyokuNaviCompanyId = null // 就職ナビ企業ID
      // 26KH #80783 END

      // 新卒
      if (action.payload.recruitmentManagementDivision === "1") {
        const reDatas = yield all(apiListNew)
        companyName = reDatas[0].companyName//企業名
        fullName = reDatas[0].fullName//氏名
        previousLoginTime = reDatas[0].previousLoginTime//前回ログイン日時
        lastLogoutTime = reDatas[0].lastLogoutTime//最終ログアウト日時
        lastLoginTime = reDatas[0].lastLoginTime//最終ログイン日時
        newApplicantsCount = reDatas[1].newApplicantsCount//新規応募者数
        unreadMessagesCount = reDatas[2].unreadMessagesCount//（新卒/中途）未読のメッセージ数
        // waitForScheduleCount = reDatas[3].waitForScheduleCount//（新卒/中途）日程確定待ちの件数
        newsCount = reDatas[0].newsCount//（新卒/中途）公開されたお知らせの件数
        delegateLoginAccountFlag = reDatas[0].delegateLoginAccountFlag// 代行ログインアカウントフラグ
        // 26KH #80783 START
        syusyokuNaviCompanyId = reDatas[0].syusyokuNaviCompanyId //就職ナビ企業ID
        // 26KH #80783 END
      // 中途
      } else if(action.payload.recruitmentManagementDivision === "2") {
        const reDatas = yield all(apiListCVS)
        companyName = reDatas[0].companyName//企業名
        fullName = reDatas[0].fullName//氏名
        previousLoginTime = reDatas[0].previousLoginTime//前回ログイン日時
        lastLogoutTime = reDatas[0].lastLogoutTime//最終ログアウト日時
        lastLoginTime = reDatas[0].lastLoginTime//最終ログイン日時
        unreadCVSCount = reDatas[1].unreadCVSCount//履歴書の未読数
        unreadMessagesCount = reDatas[2].unreadMessagesCount//（新卒/中途）未読のメッセージ数
        waitForScheduleCount = reDatas[3].waitForScheduleCount//（新卒/中途）日程確定待ちの件数
        newsCount = reDatas[0].newsCount//（新卒/中途）公開されたお知らせの件数
        delegateLoginAccountFlag = reDatas[0].delegateLoginAccountFlag// 代行ログインアカウントフラグ
        // 転職March #75621 START
        if (getMarchModeFromToken() == '1') {
          yield put(setUnreadCVSCountForMarch(Number(reDatas[4])))
        }
        // 転職March #75621 END
      // 統括
      } else {
        const reDatas = yield all(apiListAll)
        companyName = reDatas[0].companyName//企業名
        fullName = reDatas[0].fullName//氏名
        previousLoginTime = reDatas[0].previousLoginTime//前回ログイン日時
        lastLogoutTime = reDatas[0].lastLogoutTime//最終ログアウト日時
        lastLoginTime = reDatas[0].lastLoginTime//最終ログイン日時
        newApplicantsCount = reDatas[1].newApplicantsCount//新規応募者数
        unreadCVSCount = reDatas[2].unreadCVSCount//履歴書の未読数
        unreadMessagesCount = reDatas[3].unreadMessagesCount//（新卒/中途）未読のメッセージ数
        waitForScheduleCount = reDatas[4].waitForScheduleCount//（新卒/中途）日程確定待ちの件数
        newsCount = reDatas[0].newsCount//（新卒/中途）公開されたお知らせの件数
        delegateLoginAccountFlag = reDatas[0].delegateLoginAccountFlag// 代行ログインアカウントフラグ
      }

      // 26KH #80783 START
      yield put(initSuccess({ companyName, fullName, previousLoginTime, lastLogoutTime,lastLoginTime, newApplicantsCount, unreadCVSCount, unreadMessagesCount, waitForScheduleCount, newsCount, delegateLoginAccountFlag, syusyokuNaviCompanyId }))
      // 26KH #80783 END      
      // #5583 start
      const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
      localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
      // #5583 end
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* getUserInfoSaga() {
  try {
    const data = yield call(menuGetUserInfoRequest)
    const magiApplicationStatus = data.magiApplicationStatus//MAGI申込状態
    if (magiApplicationStatus === "0") {
      removeToken()
      replaceToOriginUrl(routeList.readying)
    } else {
      const companyId = data.companyId//企業ID
      const companyAccountId = data.companyAccountId//企業アカウントID
      const recruitmentManagementDivision = magiApplicationStatus === "3" ? "1" : magiApplicationStatus
      const permissions = data.permissionList//権限
      yield put(setUserInfo({ companyId, companyAccountId, recruitmentManagementDivision, permissions, magiApplicationStatus }))

      const recruitmentManagementDivisionss = {recruitmentManagementDivision :recruitmentManagementDivision }
      const apiListNew = [
        // アカウント情報を取得 （新卒/中途）公開されたお知らせの件数を取得
        call(menuInitRequest, recruitmentManagementDivisionss),
        // 新規応募者数を取得
        call(menuInitNewApplicantsCountRequest, recruitmentManagementDivisionss),
        // （新卒/中途）未読のメッセージ数を取得
        call(menuInitUnreadMessagesCountRequest, recruitmentManagementDivisionss),
        // （新卒/中途）日程確定待ちの件数を取得
        // call(menuInitWaitForScheduleCountRequest, recruitmentManagementDivisionss),
      ]
      const apiListCVS = [
        // アカウント情報を取得 （新卒/中途）公開されたお知らせの件数を取得
        call(menuInitRequest, recruitmentManagementDivisionss),
        // 履歴書の未読数を取得
        call(menuInitUnreadCVSCountRequest, recruitmentManagementDivisionss),
        // （新卒/中途）未読のメッセージ数を取得
        call(menuInitUnreadMessagesCountRequest, recruitmentManagementDivisionss),
        // （新卒/中途）日程確定待ちの件数を取得
        call(menuInitWaitForScheduleCountRequest, recruitmentManagementDivisionss),
      ]
      // 転職March #9626 START
      if (getMarchModeFromToken() == '1') {
        apiListCVS.push(call(initUnreadMarchEntryCount))
      }
      // 転職March #9626 END
      const apiListAll = [
        // アカウント情報を取得 （新卒/中途）公開されたお知らせの件数を取得
        call(menuInitRequest, recruitmentManagementDivisionss),
        // 新規応募者数を取得
        call(menuInitNewApplicantsCountRequest, recruitmentManagementDivisionss),
        // 履歴書の未読数を取得
        call(menuInitUnreadCVSCountRequest, recruitmentManagementDivisionss),
        // （新卒/中途）未読のメッセージ数を取得
        call(menuInitUnreadMessagesCountRequest, recruitmentManagementDivisionss),
        // （新卒/中途）日程確定待ちの件数を取得
        call(menuInitWaitForScheduleCountRequest, recruitmentManagementDivisionss),
      ]

      let companyName = null //企業名
      let fullName = null //氏名
      let previousLoginTime = null  //前回ログイン日時
      let lastLogoutTime = null //最終ログアウト日時
      let lastLoginTime = null  //最終ログイン日時
      let newApplicantsCount = 0 //新規応募者数
      let unreadCVSCount = 0 //履歴書の未読数
      let unreadMessagesCount = 0  //（新卒/中途）未読のメッセージ数
      let waitForScheduleCount = 0 //（新卒/中途）日程確定待ちの件数
      let newsCount = 0  //（新卒/中途）公開されたお知らせの件数
      let delegateLoginAccountFlag = false // 代行ログインアカウントフラグ
      // 26KH #80783 START
      let syusyokuNaviCompanyId = null
      // 26KH #80783 END

      // 新卒
      if (recruitmentManagementDivisionss.recruitmentManagementDivision === "1") {
        const reDatas = yield all(apiListNew)
        companyName = reDatas[0].companyName//企業名
        fullName = reDatas[0].fullName//氏名
        previousLoginTime = reDatas[0].previousLoginTime//前回ログイン日時
        lastLogoutTime = reDatas[0].lastLogoutTime//最終ログアウト日時
        lastLoginTime = reDatas[0].lastLoginTime//最終ログイン日時
        newApplicantsCount = reDatas[1].newApplicantsCount//新規応募者数
        unreadMessagesCount = reDatas[2].unreadMessagesCount//（新卒/中途）未読のメッセージ数
        // waitForScheduleCount = reDatas[3].waitForScheduleCount//（新卒/中途）日程確定待ちの件数
        newsCount = reDatas[0].newsCount//（新卒/中途）公開されたお知らせの件数
        delegateLoginAccountFlag = reDatas[0].delegateLoginAccountFlag// 代行ログインアカウントフラグ
        // 26KH #80783 START
        syusyokuNaviCompanyId = reDatas[0].syusyokuNaviCompanyId //就職ナビ企業ID
        // 26KH #80783 END
      // 中途
      } else if(recruitmentManagementDivisionss.recruitmentManagementDivision === "2") {
        const reDatas = yield all(apiListCVS)
        companyName = reDatas[0].companyName//企業名
        fullName = reDatas[0].fullName//氏名
        previousLoginTime = reDatas[0].previousLoginTime//前回ログイン日時
        lastLogoutTime = reDatas[0].lastLogoutTime//最終ログアウト日時
        lastLoginTime = reDatas[0].lastLoginTime//最終ログイン日時
        unreadCVSCount = reDatas[1].unreadCVSCount//履歴書の未読数
        unreadMessagesCount = reDatas[2].unreadMessagesCount//（新卒/中途）未読のメッセージ数
        waitForScheduleCount = reDatas[3].waitForScheduleCount//（新卒/中途）日程確定待ちの件数
        newsCount = reDatas[0].newsCount//（新卒/中途）公開されたお知らせの件数
        delegateLoginAccountFlag = reDatas[0].delegateLoginAccountFlag// 代行ログインアカウントフラグ
        // 転職March #9626 START
        if (getMarchModeFromToken() == '1') {
          yield put(setUnreadCVSCountForMarch(Number(reDatas[4])))
        }
        // 転職March #9626 END
      // 統括
      } else {
        const reDatas = yield all(apiListAll)
        companyName = reDatas[0].companyName//企業名
        fullName = reDatas[0].fullName//氏名
        previousLoginTime = reDatas[0].previousLoginTime//前回ログイン日時
        lastLogoutTime = reDatas[0].lastLogoutTime//最終ログアウト日時
        lastLoginTime = reDatas[0].lastLoginTime//最終ログイン日時
        newApplicantsCount = reDatas[1].newApplicantsCount//新規応募者数
        unreadCVSCount = reDatas[2].unreadCVSCount//履歴書の未読数
        unreadMessagesCount = reDatas[3].unreadMessagesCount//（新卒/中途）未読のメッセージ数
        waitForScheduleCount = reDatas[4].waitForScheduleCount//（新卒/中途）日程確定待ちの件数
        newsCount = reDatas[0].newsCount//（新卒/中途）公開されたお知らせの件数
        delegateLoginAccountFlag = reDatas[0].delegateLoginAccountFlag// 代行ログインアカウントフラグ
      }

      // 26KH #80783 START      
      yield put(initSuccess({ companyName, fullName, previousLoginTime, lastLogoutTime,lastLoginTime, newApplicantsCount, unreadCVSCount, unreadMessagesCount, waitForScheduleCount, newsCount, delegateLoginAccountFlag, syusyokuNaviCompanyId }))
      // 26KH #80783 END
      // #5583 start
      const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
      localStorage.setItem('globalMenuInfoCheck',JSON.stringify(globalMenuInfo))
      // #5583 end
      // #5721 start
      // Urlから、別ウィンドウ画面を開くの場合、globalMenuInfoの情報を再設定する
      if (paramLoginUrlCheck()) {
        localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
      }
      // #5721 end
      let target = window.location.href.split(new RegExp("/+"))[2]
      if (target === "" || target === "login" || target === "recruitmentStatusReportTab"){
        yield put(initFlag(true))
      } else if(target === "interviewAdjustTab" || target === "messageTab") {
        yield put(initFlag(true))
      } else if(target === "entryManageTab") {
        let subTarget = window.location.href.split(new RegExp("/+"))[3]
        if (subTarget === "entryList" || subTarget === "applicantList"){
          yield put(initFlag(true))
        }
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* getNewApplicantsCountSaga(action: ReturnType<typeof getNewApplicantsCount>) {
  try {
    const data = yield call(getNewApplicantsCountRequest, action.payload)
    const newApplicantsCount = data.newApplicantsCount
    yield put(setNewApplicantsCount(newApplicantsCount))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* getUnreadCVSCountSaga() {
  try {
    const data = yield call(getUnreadCVSCountRequest)
    const unreadCVSCount = data.unreadCVSCount
    yield put(setUnreadCVSCount(unreadCVSCount))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* getUnreadMessagesCountSaga(action: ReturnType<typeof getUnreadMessagesCount>) {
  try {
    const data = yield call(getUnreadMessagesCountRequest, action.payload)
    const unreadMessagesCount = data.unreadMessagesCount
    yield put(setUnreadMessagesCount(unreadMessagesCount))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* getWaitForScheduleCountSaga(action: ReturnType<typeof getWaitForScheduleCount>) {
  try {
    const data = yield call(getWaitForScheduleCountRequest, action.payload)
    const waitForScheduleCount = data.waitForScheduleCount
    yield put(setWaitForScheduleCount(waitForScheduleCount))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* signoutSaga(action: ReturnType<typeof signout>) {
  try {
    if (action.payload.companyAccountId) {
      yield call(menuLogoutRequest, action.payload)
    }
    const Login = yield select(loginUrl);
    yield put(signoutSuccess(Login))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* newsInitSaga(action: ReturnType<typeof newsInit>) {
  try {
 //次期9月対応 #62669 start 
    const data = yield call(initRequest, action.payload.previousLoginTime)
    yield put(setNewsInfo(data))
    action.payload.openFunction(true);
    //次期9月対応 #62669 end 
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 年度対応 start
// 年度切り替え
function* yearChangeSaga(action: ReturnType<typeof yearChange>) {
  try {
    // 離脱チェック一時禁止
    yield put(setPromptForbiddenFlg(true))
    const data = yield call(yearChangeRequest, action.payload)
    const token = data.tokenHead + ' ' + data.token
    yield put(loginSuccess({token: token}))
    let targetYear = action.payload.targetYear
    yield put(setTargetYear(action.payload.targetYear))
    yield put(setTargetYearForDisplay(targetYear))
    // 遷移がある
    // 年度切替成功後に画面が応募者一覧またはメッセージ一覧の場合history内の画面間用パラメターをクリア
    if (routeList.applicantList == history.location.pathname ||
      // MCBリプレース MCBHS040 START
      routeList.submissionRequestManagement == history.location.pathname ||
      // MCBリプレース MCBHS040 END
      routeList.messageInBox == history.location.pathname) {
      history.replace({ state: undefined })
    }
    if (action.payload.url) {
      if (routeList.password == history.location.pathname) {
        window.removeEventListener('beforeunload', listenerMCACS020)
      } else if (routeList.jobSeekerNameInput == history.location.pathname) {
        window.removeEventListener('beforeunload', listenerMCAXS191)
      } else if (routeList.jobSeekerLogIn == history.location.pathname) {
        window.removeEventListener('beforeunload', listenerMCAXS201)
      } else if (routeList.jobSeekerInfoInsertConfirm == history.location.pathname) {
        window.removeEventListener('beforeunload', listenerMCAXS211)
      } else if (routeList.errorReport == history.location.pathname) {
        window.removeEventListener('beforeunload', listenerMCAUS010)
      }
      window.location.href = action.payload.url
    }
    if (action.payload.specailCaseType) {
      // 特殊ケースのパスワード変更
      window.removeEventListener('beforeunload', listenerMCACS020)
      window.location.reload()
    }
    // 遷移処理がない、特殊ケースでないなら画面をリロード
    if (!action.payload.url && !action.payload.specailCaseType) {
      yield window.location.reload()
    }
    yield put(setPromptForbiddenFlg(false))
  } catch (error) {
    const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
    yield put(setTargetYearForDisplay(globalMenuInfo.targetYear))
    yield put(setPromptForbiddenFlg(false))
    yield put(openModal(error.message))
  }
}

function* getYearDataSaga(action: ReturnType<typeof getYearData>) {
  try {
    // JWTトークンを解析
    const token = getTargetYearFromToken()
    // 年度情報取得
    let data: any[] = yield call(getYearDataequest)
    let errFlg = false
    // エラー判定
    if (data && data.length > 0) {
      const errcode = data[data.length - 1]
      if (errcode === magiContants.MESSAGECODE_MCAAS010_002) {
        errFlg = true
        yield put(openModal(errcode))
        yield data.pop()
      }
    }
    // 年度プルダウンリスト用データ生成
    const targetyears = data && data.length > 0 ? data.map((i: any) => {
      return {
        value: i,
        label: i.substring(i.length - 4, i.length)
      }
    }) : []
    yield put(setTargetYearDatas(targetyears))
    if (!errFlg) {
      yield put(setTargetYear(token.year))
      yield put(setTargetYearForDisplay(token.year))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 年度対応 end

// 転職March #75621 START
// Marchモード初期化
function* serviceModeInitialSaga(action: ReturnType<typeof serviceModeInitial>) {
  try {
    // JWTトークンを解析
    const march = getMarchModeFromToken()
    let data: any[] = yield call(serviceModeInitialRequest)
    // サービスモード設定
    yield put(setServiceMode(march))
    yield put(setServiceModeForDisplay(march))
    if (data && data.length > 0) {
      // March担当企業フラグ設定
      yield put(setMarchResponsibleCompanyFlag(data[0]))
      // Marchサービスフラグ設定
      yield put(setMarchServiceFlag(data[1]))
      // March選択肢表示フラグ
      yield put(setMarchNoDisplayFlag(data[2]))
      // 未読数設定
      yield put(setUnreadCVSCountForMarch(Number(data[3])))
    }
    yield put(setModeInitialSuccessFlag(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// サービスモード切り替え
function* serviceModeChangeSaga(action: ReturnType<typeof serviceModeChange>) {
  try {
    // 離脱チェック一時禁止
    yield put(setPromptForbiddenFlg(true))
    const data = yield call(serviceChangeRequest, action.payload)
    const token = data.tokenHead + ' ' + data.token
    yield put(loginSuccess({token: token}))
    // エントリー一覧の場合画面間用パラメターをクリア
    if (routeList.entryList == history.location.pathname) {
      history.replace({ state: undefined })
    }
    if (routeList.password == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCACS020)
    } else if (routeList.errorReport == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAUS010)
    } else if (routeList.entryInsEdit == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAXS220)
    } else if (routeList.notAdoptedNotification == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAGS010)
    }
    if (action.payload.specailCaseType == '1') {
      window.location.href = routeList.entryList
    // 転職March #9459 #75621 START
    } else if (action.payload.specailCaseType == '2') {
      // 転職March #9626 START
      yield put(setServiceMode(action.payload.targetService))
      yield put(setServiceModeForDisplay(action.payload.targetService))
      if (action.payload.redirectPath.state) {
        history.replace('/#', action.payload.redirectPath.state)
        window.location.href = action.payload.redirectPath.pathname
      } else {
        window.location.href = action.payload.redirectPath
      }
      // 転職March #9626 END
    // 転職March #9459 #75621 END
    } else {
      if (action.payload.targetService == magiContants.SERVICEMODE_MARCH) {
        window.location.href = routeList.entryList
      } else {
        window.location.reload()
      }
    }
  } catch (error) {
    const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
    yield put(setServiceModeForDisplay(globalMenuInfo.serviceMode))
    yield put(setPromptForbiddenFlg(false))
    yield put(openModal(error.message))
  }
}
// 転職March #75621 END

export default function* userSaga() {
  yield all([
    takeLatest(init, menuInitSaga),
    takeLatest(signout, signoutSaga),
    takeLatest(getUserInfo, getUserInfoSaga),
    takeLatest(getNewApplicantsCount, getNewApplicantsCountSaga),
    takeLatest(getUnreadCVSCount, getUnreadCVSCountSaga),
    takeLatest(getUnreadMessagesCount, getUnreadMessagesCountSaga),
    takeLatest(getWaitForScheduleCount, getWaitForScheduleCountSaga),
    takeLatest(newsInit, newsInitSaga),
    // 年度対応 start
    takeLeading(yearChange, yearChangeSaga),
    takeLeading(getYearData, getYearDataSaga),
    // 年度対応 end
    // 転職March #75621 START
    takeLeading(serviceModeInitial, serviceModeInitialSaga),
    takeLeading(serviceModeChange, serviceModeChangeSaga),
    // 転職March #75621 END
  ])
}
