import request from 'utils/request'
import { MCALS010TemplateIdRequest } from 'types/MCALS010/MCALS010TemplateIdRequest'
import { MCALS020CreateRequest } from 'types/MCALS020/MCALS020CreateRequest'

export const MCALS020InitRequest = (params: MCALS010TemplateIdRequest) =>
    request({
        url: 'MCALS020/init',
        method: 'post',
        data: params
    })

export const MCALS020RegisterRequest = (params: MCALS020CreateRequest) =>
    request({
        url: 'MCALS020/register',
        method: 'post',
        data: params
    })