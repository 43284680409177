import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import moment from 'moment-timezone'

import { getDiffFromToday, isEmpty, now, stringToDateTime, stringToDate, dateTimeToString } from 'common/generalUtil'
import { getMessage, getMessageEx } from 'common/messageUtil'
import { isFileExist } from 'common/businessUtil'
import { magiContants } from 'utils/contants'

import {
  confirm,
  getInit,
  getPreview,
  saveDraft,
  setCode,
  setInit,
  setPreview,
  setScreenDisplayItems,
  openConfirmModal,
  setCloseWindow,
  openDetailDialog,
  getReloadSubmissions,
  setReloadSubmissions,
  getReloadThanksTemplate,
  setReloadThanksTemplate,
  getReloadReference,
  setReloadReference,
  getReloadPersonal,
  setReloadPersonal,
  getTemplateAttachment,
  setTemplateAttachment,
  csvDownload,
  setConfirmData,
  changeConfirmState,
} from 'reducers/submissionRequestSendReducer'
import {
  setSearchCriteria,
  setDetailDialogOpen,
} from 'reducers/selectCriteriaManagementReducer'
import { openModal, openModalWithArg } from 'reducers/messageReducer'

import {
  submissionRequestInputInitRequest,
  reloadSubmissionsSetRequest,
  reloadThanksTemplateRequest,
  reloadReferenceRequest,
  reloadPersonalRequest,
  previewRequest,
  templateAttachmentRequest,
  draftRequest,
  confirmRequest,
  csvDownloadRequest
} from 'apis/MCBHS010Api'

import { getRecruitmentManagementDivision } from 'selectors/authSelectors'

moment.tz.setDefault('Asia/Tokyo')

const ALL_CONTRACT_MEDIA = 'MAGI' //すべての企業契約応募経路が対象

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const data = yield call(submissionRequestInputInitRequest, action.payload)
    yield put(setInit(data))
    yield put(setScreenDisplayItems())
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* reloadSubmissionsSaga(){
  try {
    const data = yield call(reloadSubmissionsSetRequest)
    yield put(setReloadSubmissions(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}

export function* reloadThanksTemplateRequestSaga(){
  try {
    const data = yield call(reloadThanksTemplateRequest)
    yield put(setReloadThanksTemplate(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}

export function* reloadReferenceRequestSaga(){
  try {
    const data = yield call(reloadReferenceRequest)
    yield put(setReloadReference(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}

export function* reloadPersonalRequestSaga(){
  try {
    const data = yield call(reloadPersonalRequest)
    yield put(setReloadPersonal(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}

export function* previewSaga(action: ReturnType<typeof getPreview>) {
  try {
    const formData = action.payload.formData
    const serverResponse = yield call(previewRequest, formData)

    yield put(setPreview(serverResponse))
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}
export function* templateAttachmentSaga(action: ReturnType<typeof getTemplateAttachment>){
  try {
    const data = yield call(templateAttachmentRequest, action.payload.param)
    yield put(setTemplateAttachment(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}

export function* saveDraftSaga(action: ReturnType<typeof saveDraft>) {
  try {
    const {
      formData,
      files,
      attachmentFileRegistrationMax
    } = action.payload

    // ファイルの拡張子チェック
    const suffixCheck = [
      'ai', 'art', 'doc', 'fla', 'gif', 'jpg', 'jpeg', 'lzh', 'mpeg', 'mpg', 'pdf', 'png', 'pps', 'ppt', 'psd', 'txt', 'xls', 'zip', 'docx', 'xlsx'
    ]
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const lastIndexOf = file.name.lastIndexOf('.')
        const suffix = file.name.substring(lastIndexOf + 1)
        for (var j = 0; j < suffixCheck.length; j++) {
          if (suffixCheck[j] == suffix.toLowerCase()) {
            break
          } else {
            if (suffixCheck[j] != 'xlsx') {
              continue
            } else {
              yield put(openModal(getMessage('MCBHS010-010')))
              return
            }
          }
        }
      }
    }

    // ファイルのサイズチェック
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const fileSize = file.size
        if (fileSize > magiContants.MESSAGE_ATTACHMENT_MAX_SIZE) {
          yield put(openModal(getMessage('MCBHS010-011')))
          return
        }
      }
    }
    // ファイル数のチェック
    if (files.length > attachmentFileRegistrationMax || formData.requestAttachmentId.length > 5) {
      yield put(openModal(getMessage('MCBHS010-012')))
      return
    }

    // ファイル存在チェック
    if (files.some(item => !item || item.size <= 0)) {
      yield put(openModal(getMessage('MCBHS010-013')))
      return
    }
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const isFile = yield call(isFileExist, file)
        if (!isFile) {
          yield put(openModal(getMessage('MCBHS010-013')))
          return
        }
      }
    }

    // 同一添付ファイルチェック
    const addedfileNameList = files.map(file => file.name)
    const fullFileNameList = addedfileNameList.concat(formData.requestAttachmentName)
    const fileNameList = fullFileNameList.filter(v => v)
    if (fileNameList.length > 1) {
      const beforeLength = fileNameList.length
      const newFileNameList = fileNameList.filter((x, index, self) => self.indexOf(x) === index)
      const afterLength = newFileNameList.length
      if (beforeLength !== afterLength) {
        yield put(openModal(getMessage('MCBHS010-014')))
        return
      }
    }

    yield call(draftRequest, formData, files)
    yield put(setCode(200))
    yield put(setCloseWindow(true))
  } catch (error) {
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export function* confirmSaga(action: ReturnType<typeof confirm>) {
  try {
    const formData = action.payload.formData
    const files: File[] = action.payload.files
    const viewEndTimeList: Date[] = action.payload.viewEndTimeList
    const forbiddenWords: string[] = action.payload.forbiddenWords
    const attachmentFileRegistrationMax = action.payload.attachmentFileRegistrationMax
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const sendReplyImpossibleTime = formData.sendReplyImpossibleTimeList
    const curDate = dateTimeToString(now())

    // 業務チェックを実施する
    // 提出締切チェック
    switch(formData.limitDateDetermineFlag){
      case (0):
        const determineDateTime = dateTimeToString(stringToDateTime(formData.determineDateTime));
        if(determineDateTime !== null && curDate!== null){
          if(determineDateTime.substring(0,16) < curDate.substring(0,16)){
            throw { message: magiContants.MESSAGECODE_MCBHS010_029 }
          }
          const sendPlanTime = formData.sendPlanTime;
          if(sendPlanTime !== null)
            if(determineDateTime.substring(0,16) < sendPlanTime.substring(0,16)){
              throw { message: magiContants.MESSAGECODE_MCBHS010_030 }
            }
          }
          break
      // MCBリプレイス #8630 START    
      case (1):
        const sendPlanTime =formData.sendPlanTime ? formData.sendPlanTime : ''
        if (formData.requestDayTimeLaterDay !== null &&formData.requestDayTimeToTime!== null &&curDate !== null) {
          if (Number(formData.requestDayTimeLaterDay)==0 && sendPlanTime=='') {
            if(Number(formData.requestDayTimeToTime+'00')<Number(curDate.substring(11,16).replace(':',''))){
              throw { message: magiContants.MESSAGECODE_MCBHS010_029 }
              }   
           }else if(Number(formData.requestDayTimeLaterDay)==0 && sendPlanTime!==''){
            if(Number(formData.requestDayTimeToTime+'00')<Number(sendPlanTime.substring(11,16).replace(':',''))){
              throw { message: magiContants.MESSAGECODE_MCBHS010_030 }
            }
           }
        }
        break
      // MCBリプレイス #8630 END
          case (2):
            const anytimeReceptionEndDateTime = dateTimeToString(stringToDateTime(formData.anytimeReceptionEndDateTime));
            if(anytimeReceptionEndDateTime !== null && curDate!== null){
              if(anytimeReceptionEndDateTime.substring(0,16) < curDate.substring(0,16)){
                throw { message: magiContants.MESSAGECODE_MCBHS010_033 }
              }
              const sendPlanTime = formData.sendPlanTime;
              if(sendPlanTime !== null)
                if(anytimeReceptionEndDateTime.substring(0,16) < sendPlanTime.substring(0,16)){
                  throw { message: magiContants.MESSAGECODE_MCBHS010_034 }
                }
            }
            break
          default:
            break
      }

    // 提出締切チェック--メンテナンス期間
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let deadLineDay = moment()
          deadLineDay = moment(formData.determineDateTime.replace(/\//g,'-'))
      let anyTime = moment(formData.anytimeReceptionEndDateTime.replace(/\//g,'-'))
      
      sendReplyImpossibleTime.map(item => {
          let startDate
          let endDate
          // #MCB1.5次開発 #8986 START
          let functionalDivision = !isEmpty(item.functionalDivisionStr) && item.functionalDivisionStr === '2' ?true :false 
          let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
          let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
          if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
            startDate = moment(`${startDateStr.substring(0, 4)}-${startDateStr.substring(4, 6)}-${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}`)
          }
          if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
            endDate = moment(`${endDateStr.substring(0, 4)}-${endDateStr.substring(4, 6)}-${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`)
          }
          if(formData.limitDateDetermineFlag === 0 && functionalDivision){
          if (startDate && endDate) {
            if ((deadLineDay.isAfter(startDate) || deadLineDay.diff(startDate, 'minute') === 0) && (deadLineDay.isBefore(endDate) || deadLineDay.diff(endDate, 'minute') === 0)) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
          if (startDate && !endDate) {
            if (deadLineDay.isAfter(startDate) || deadLineDay.diff(startDate, 'minute') === 0) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
              isError = true
              return
            }
          }
          if (!startDate && endDate) {
            if (deadLineDay.isBefore(endDate) || deadLineDay.diff(endDate, 'minute') === 0) {
              errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
        }else if(formData.limitDateDetermineFlag === 2  && functionalDivision){
          if (startDate && endDate) {
            if ((anyTime.isAfter(startDate) || anyTime.diff(startDate, 'minute') === 0) && (anyTime.isBefore(endDate) || anyTime.diff(endDate, 'minute') === 0)) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
          if (startDate && !endDate) {
            if (anyTime.isAfter(startDate) || anyTime.diff(startDate, 'minute') === 0) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
              isError = true
              return
            }
          }
          if (!startDate && endDate) {
            if (anyTime.isBefore(endDate) || anyTime.diff(endDate, 'minute') === 0) {
              errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
        }else{
          let hour = (Number(formData.requestDayTimeToTime) <10 ? '0':'')+ formData.requestDayTimeToTime+':00'
          
          if(formData.sendTimerFlag === 0 && functionalDivision){
            let date = new Date((new Date).getFullYear()+'-'+ ((new Date).getMonth()+1)+'-'+((new Date).getDate()));
            date.setDate(date.getDate()+Number(formData.requestDayTimeLaterDay));
            let newDate = date.getFullYear()+'-'+((date.getMonth()+1)<10?('0'+(date.getMonth()+1)):(date.getMonth()+1))+'-'+(date.getDate()<10?('0'+date.getDate()):date.getDate()) +' '+hour
            let time = moment(newDate);
            if (startDate && endDate) {
              if ((time.isAfter(startDate) || time.diff(startDate, 'minute') === 0) && (time.isBefore(endDate) || time.diff(endDate, 'minute') === 0)) {
                errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
                isError = true
                return
              }
            }
            if (startDate && !endDate) {
              if (time.isAfter(startDate) || time.diff(startDate, 'minute') === 0) {
                errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
                isError = true
                return
              }
            }
            if (!startDate && endDate) {
              if (time.isBefore(endDate) || time.diff(endDate, 'minute') === 0) {
                errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
                isError = true
                return
              }
            }
          }else if(formData.sendTimerFlag === 1 && functionalDivision){
            let sendTime = formData.sendPlanTime.replace(/\//g,'-')
            let date = new Date(sendTime.substring(0,10));
            date.setDate(date.getDate()+Number(formData.requestDayTimeLaterDay));
            let newDate = date.getFullYear()+'-'+((date.getMonth()+1)<10?('0'+(date.getMonth()+1)):(date.getMonth()+1))+'-'+(date.getDate()<10?('0'+date.getDate()):date.getDate()) +' '+hour
            let time = moment(newDate);
            if (startDate && endDate) {
              if ((time.isAfter(startDate) || time.diff(startDate, 'minute') === 0) && (time.isBefore(endDate) || time.diff(endDate, 'minute') === 0)) {
                errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
                isError = true
                return
              }
            }
            if (startDate && !endDate) {
              if (time.isAfter(startDate) || time.diff(startDate, 'minute') === 0) {
                errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
                isError = true
                return
              }
            }
            if (!startDate && endDate) {
              if (time.isBefore(endDate) || time.diff(endDate, 'minute') === 0) {
                errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
                isError = true
                return
              }
            }
          }
        }
        // #MCB1.5次開発 #8986 END
      })
      if (isError) {
        throw { message: getMessageEx(magiContants.MESSAGECODE_MCBHS010_036, errorPeriod) }
      }
    }

    // 再提出の受付可否チェック
    if(formData.limitDateDetermineFlag === 2 && formData.reRequestReceptFlag=== 1){
      throw { message: magiContants.MESSAGECODE_MCBHS010_037 }
    }

    //禁止語チェック
    for (var i = 0; i < forbiddenWords.length; i++) {
      // 禁止語チェック_件名
      if (formData.requestSubject != null && formData.requestSubject.includes(forbiddenWords[i])) {
        throw { message: magiContants.MESSAGECODE_MCBHS010_038 }
      }
      // 禁止語チェック_本文
      if (formData.requestText != null && formData.requestText.includes(forbiddenWords[i])) {
        throw { message: magiContants.MESSAGECODE_MCBHS010_039 }
      }
    }

    // 不正文字チェック
    if (formData.requestSubject.indexOf('●●') >= 0 || formData.requestText.indexOf('●●') >= 0) {
      throw { message: magiContants.MESSAGECODE_MCBHS010_040 }
    }

    // ファイルの拡張子チェック
    if (files.length > 0) {
      const suffixCheck = [
        'ai', 'art', 'doc', 'fla', 'gif', 'jpg', 'jpeg', 'lzh', 'mpeg', 'mpg', 'pdf', 'png', 'pps', 'ppt', 'psd', 'txt', 'xls', 'zip', 'docx', 'xlsx'
      ]
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        if (file) {
          const lastIndexOf = file.name.lastIndexOf('.')
          const suffix = file.name.substring(lastIndexOf + 1)
          for (var j = 0; j < suffixCheck.length; j++) {
            if (suffixCheck[j] == suffix.toLowerCase()) {
              break
            } else {
              if (suffixCheck[j] != 'xlsx') {
                continue
              } else {
                throw { message: magiContants.MESSAGECODE_MCBHS010_041 }
              }
            }
          }
        }
      }
    }

    if (files.length > 0 || formData.requestAttachmentId.length > 0) {

      // ファイルのサイズチェック
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const fileSize = file.size
            if (fileSize > magiContants.MESSAGE_ATTACHMENT_MAX_SIZE) {
              throw { message: magiContants.MESSAGECODE_MCBHS010_042 }
            }
          }
        }
      }

      // ファイル数のチェック
      if (files.length > attachmentFileRegistrationMax || formData.requestAttachmentId.length > attachmentFileRegistrationMax) {
        throw { message: magiContants.MESSAGECODE_MCBHS010_043 }
      }

      // ファイル存在チェック
      if (files.length > 0) {
        if (files.some(item => !item || item.size <= 0)) {
          throw { message: magiContants.MESSAGECODE_MCBHS010_044 }
        }
      }
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        if (file) {
          const isFile = yield call(isFileExist, file)
          if (!isFile) {
            throw { message: magiContants.MESSAGECODE_MCBHS010_044 }
          }
        }
      }


      // 同一添付ファイルチェック
      const addedfileNameList = files.map(file => file.name)
      const fullFileNameList = addedfileNameList.concat(formData.requestAttachmentName)
      const fileNameList = fullFileNameList.filter(v => v)
      if (fileNameList.length > 1) {
        const beforeLength = fileNameList.length
        const newFileNameList = fileNameList.filter((x, index, self) => self.indexOf(x) === index)
        const afterLength = newFileNameList.length
        if (beforeLength !== afterLength) {
          throw { message: magiContants.MESSAGECODE_MCBHS010_045 }
        }
      }
    }


    // ホームページURLチェック
    if (((formData.homepageTitle1 && formData.homepageTitle1 !== '') && (formData.homepageUrl1 === null || formData.homepageUrl1 === ''))
      || ((formData.homepageTitle2 && formData.homepageTitle2 !== '') && (formData.homepageUrl2 === null || formData.homepageUrl2 === ''))
      || ((formData.homepageTitle3 && formData.homepageTitle3 !== '') && (formData.homepageUrl3 === null || formData.homepageUrl3 === ''))
    ) {
      throw { message: magiContants.MESSAGECODE_MCBHS010_047 }
    }

    //禁止語チェック_件名（サンクスメッセージ）
    for (var i = 0; i < forbiddenWords.length; i++) {
      // 禁止語チェック_件名（サンクスメッセージ）
      if (formData.thanksSubject != null && formData.thanksSubject.includes(forbiddenWords[i])) {
        throw { message: magiContants.MESSAGECODE_MCBHS010_048 }
      }
      // 禁止語チェック_本文（サンクスメッセージ）
      if (formData.thanksText != null && formData.thanksText.includes(forbiddenWords[i])) {
        throw { message: magiContants.MESSAGECODE_MCBHS010_049 }
      }
    }

      // 不正文字チェック
      if (formData.thanksSubject.indexOf('●●') >= 0 || formData.thanksText.indexOf('●●') >= 0) {
        throw { message: magiContants.MESSAGECODE_MCBHS010_050 }
      }

    // MCBメンテナンス期間チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment()
      if (String(formData.sendTimerFlag) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        if (!isEmpty(formData.sendPlanTime)) {
          sendTime = moment(formData.sendPlanTime.replace(/\//g,'-'))
        }
      }
      sendReplyImpossibleTime.map(item => {
          let startDate
          let endDate
          // #MCB1.5次開発 #8986 START
          let functionalDivision = !isEmpty(item.functionalDivisionStr) && item.functionalDivisionStr === '1' ?true :false 
          let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
          let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
          if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
            startDate = moment(`${startDateStr.substring(0, 4)}-${startDateStr.substring(4, 6)}-${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}`)
          }
          if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
            endDate = moment(`${endDateStr.substring(0, 4)}-${endDateStr.substring(4, 6)}-${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`)
          }
          if (startDate && endDate && functionalDivision) {
            if ((sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) && (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0)) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
          if (startDate && !endDate && functionalDivision) {
            if (sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
              isError = true
              return
            }
          }
          if (!startDate && endDate && functionalDivision) {
          // #MCB1.5次開発 #8986 END
            if (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0) {
              errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
      })
      if (isError) {
        throw { message: getMessageEx(magiContants.MESSAGECODE_MCBHS010_052, errorPeriod) }
      }
    }

    // 26KH #87889 START
    // MIWSメンテナンス期間チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment().startOf('minute')
      if (String(formData.sendTimerFlag) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        if (!isEmpty(formData.sendPlanTime)) {
          sendTime = moment(formData.sendPlanTime.replace(/\//g,'-'))
        }
      }
      sendReplyImpossibleTime.map(item => {
          let startDate
          let endDate
          let functionalDivision = !isEmpty(item.functionalDivisionStr) && item.functionalDivisionStr === '3' ?true :false 
          let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
          let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
          if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
            startDate = moment(`${startDateStr.substring(0, 4)}-${startDateStr.substring(4, 6)}-${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}`)
          }
          if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
            endDate = moment(`${endDateStr.substring(0, 4)}-${endDateStr.substring(4, 6)}-${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`)
          }
          if (startDate && endDate && functionalDivision) {
            if ((sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) && (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0)) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
          if (startDate && !endDate && functionalDivision) {
            if (sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
              isError = true
              return
            }
          }
          if (!startDate && endDate && functionalDivision) {
            if (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0) {
              errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
      })
      if (isError) {
        throw { message: getMessageEx(magiContants.MESSAGECODE_MCBHS010_080, errorPeriod) }
      }
    }
    // 26KH #87889 END

    // 送信予約期間チェック
    if (String(formData.sendTimerFlag) === magiContants.SEND_TIME_FLAG_RESERVATION) {
      if (formData.sendPlanTime === null || formData.sendPlanTime === '') {
        throw { message: magiContants.MESSAGECODE_MCBHS010_053 }
      }
      if (formData.sendPlanTime) {
        const sendPlanTime = stringToDateTime(formData.sendPlanTime)
        if (sendPlanTime !== null) {
          if (sendPlanTime < now()) {
            throw { message: magiContants.MESSAGECODE_MCBHS010_054 }
          }
          const targetDate = moment(sendPlanTime)
          const toDate = moment(now()).add(magiContants.RESERVATION_MAX_DATE_LENGTH, 'day').set('hour', 23).set('minutes', 59).set('second', 59)
          if (targetDate > toDate) {
            throw { message: magiContants.MESSAGECODE_MCBHS010_060 }
          }
          const minTime = moment(now()).add(magiContants.RESERVATION_MIN_MINUTES_LENGTH, 'minutes')
          if (targetDate <= minTime) {
            throw { message: magiContants.MESSAGECODE_MCBHS010_061 }
          }
        }
      }
    }


    // // エントリー有効期限チェック
    if (viewEndTimeList && viewEndTimeList.length > 0) {
      if (String(formData.sendTimerFlag) === magiContants.SEND_TIME_FLAG_IMMEDIATE) {
        const currentDate = moment(now())
        if (viewEndTimeList.some(item => item && currentDate.isAfter(moment(item)))) {
          throw { message: magiContants.MESSAGECODE_MCBHS010_062 }
        }
      }
      if (String(formData.sendTimerFlag) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        const sendPlanTime = moment(stringToDateTime(formData.sendPlanTime))
        if (viewEndTimeList.some(item => item && sendPlanTime.isAfter(moment(item)))) {
          throw { message: magiContants.MESSAGECODE_MCBHS010_063 }
        }
      }
    }
    
    // 送信先チェック
    if (formData.sendTarget) {
      // MCBリプレース #9219 START
      if (!formData.sendTarget.some(item => !isEmpty(item.mailAddress))) {
      // MCBリプレース #9219 END
        throw { message: magiContants.MESSAGECODE_MCBHS010_064 }
      }
    }

    const data = yield call(confirmRequest, formData, files)
    // MCBリプレース #9200 START
    if (data.mcbApiErrorList && data.mcbApiErrorList.length > 0) {
      throw { message: getMessageEx(magiContants.MESSAGECODE_MCBHS010_082, data.mcbApiErrorList.map((item:any) => '［'+ item.errorMessage +'］').join('\n')) }
    }
    // MCBリプレース #9200 END
    // 維持保守 #9496 start
    if(data.sameContentCheckOutList.length > 0 || data.blackListJobSeekerList.length>0){
    // 維持保守 #9496 end
      action.payload.setOpenSameContect(true)
    }else{
      action.payload.setMCBHS020Open(true)
    }
    // MCBリプレース #9200 START
    // 維持保守 #9496 start
    yield put(setConfirmData(data))
    // 維持保守 #9496 end
    // MCBリプレース #9200 END

  } catch (error) {
    yield put(changeConfirmState(false))
    yield put(openModal(error.message))
  }
}
export function* csvDownloadSaga(action: ReturnType<typeof csvDownload>) {
  const data = yield call(csvDownloadRequest, action.payload)
  if (data.data.type.indexOf('application/json') !== -1) {
    yield put(openModal('MCBHS010-070'))
  } else {
    var blob = new Blob([data.data]);
    const date = new Date();
    var curr_date = date.getDate() + "";
    var curr_month = (date.getMonth() + 1) + "";
    const curr_year = date.getFullYear()
    var curr_hour = date.getHours() + "";
    var curr_minutue = date.getMinutes() + "";
    curr_month = curr_month.length < 2 ? "0" + curr_month : curr_month;
    curr_date = curr_date.length < 2 ? "0" + curr_date : curr_date;
    curr_hour = curr_hour.length < 2 ? "0" + curr_hour : curr_hour;
    curr_minutue = curr_minutue.length < 2 ? "0" + curr_minutue : curr_minutue;
    const yymmdd = curr_year + curr_month + curr_date + "_" + curr_hour + curr_minutue;
    const fileName = "request_job_seeker_list_" + yymmdd + ".csv"
    if (navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    }
  }
}

export function* openDetailDialogSaga(action: ReturnType<typeof openDetailDialog>) {
  yield put(setSearchCriteria(action.payload))
  yield put(setDetailDialogOpen(true))
}

export default function* submissionRequestSendSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(saveDraft, saveDraftSaga),
    takeLatest(getPreview, previewSaga),
    takeLatest(confirm, confirmSaga),
    takeLatest(openDetailDialog, openDetailDialogSaga),
    takeLatest(getReloadSubmissions,reloadSubmissionsSaga),
    takeLatest(getReloadThanksTemplate,reloadThanksTemplateRequestSaga),
    takeLatest(getReloadReference,reloadReferenceRequestSaga),
    takeLatest(getReloadPersonal,reloadPersonalRequestSaga),
    takeLatest(getTemplateAttachment,templateAttachmentSaga),
    takeLatest(csvDownload, csvDownloadSaga)
  ])
}
