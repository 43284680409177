import { magiContants } from 'utils/contants'

export interface Row {
  [key: string]: any
  signatureId: string
  registrationName: string
  updateTime: Date
  updater: string
  displayOrder: number
  signSettingId: string
  recruitmentManagementDivision: string
}
export const initialValues: Row[] = []
interface SignList {
  signListResults: Row[]
  updateSignListResults: Row[]
  signListCount: number
}
const signListInitialValues: SignList = {
  signListResults: initialValues,
  updateSignListResults: initialValues,
  signListCount: 0,
}
const maxcount: number = magiContants.CHECK_MACNS010_MAXCOUNT
const msg: string = magiContants.CHECK_MACNS010_MAXCOUNT_MSG

const textMap: { [key: string]: string } = {
  reOrderTip: 'ドラッグ＆ドロップで表示順を入れ替えます',
}

export { signListInitialValues, maxcount, msg, textMap }
