import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core/styles'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import HeightIcon from '@material-ui/icons/Height'
import { Typography } from '@material-ui/core'

const grid = 8

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: theme.spacing(0, 2),
  },
  titleContainer: {
    display: 'flex',
    '& h3': {
      flex: 1,
    },
    '& span': {
      flex: 1,
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    padding: '3px',
  },
}))

// 結果並べ替え
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

// つのリストから別のリストにアイテムを移動します。
const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = Array.from(destination)
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // 基本様式
  userSelect: 'none',
  padding: grid * 2,
  margin: `0`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid black',

  // ドラッグして背景色を変更
  background: isDragging ? 'lightgreen' : 'white',

  // ドラッグスタイル
  ...draggableStyle,
})

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  overflow: 'auto',
  margin: `20px 0 20px`,
})

const DndList = () => {
  const classes = useStyles()

  // 項目数
  const initstate: any = {
    items: [
      { id: '1', content: 'カナ姓名', type: 1 },
      { id: '2', content: '会員・非会員', type: 1 },
      { id: '3', content: 'メールアドレス', type: 1 },
      { id: '4', content: '姓名', type: 1 },
      { id: '5', content: '卒年', type: 1 },
      { id: '6', content: '学校グループ', type: 1 },
      { id: '7', content: '現住所電話番号姓名', type: 1 },
      { id: '8', content: '携帯番号', type: 1 },
      { id: '9', content: '休暇中都道府県', type: 1 },
      { id: '10', content: '休暇中電話番号', type: 1 },
      { id: '11', content: 'タグ', type: 1 },
    ],
    selected: [
      { id: '12', content: '姓名／ID', type: 0 },
      { id: '13', content: '学校／学部／学科', type: 1 },
      { id: '14', content: '文理区分', type: 1 },
      { id: '15', content: '現住所都道府県', type: 1 },
      { id: '16', content: 'メッセージ', type: 2 },
      { id: '17', content: '応募経路', type: 1 },
      { id: '18', content: '選考／評価／進捗／判定', type: 2 },
    ],
  }

  // 複数リストの取り扱い
  const id2List: any = {
    droppable: 'items',
    droppable2: 'selected',
  }

  // 変更状態保存
  const [state, setState] = useState(initstate)
  const [isDropDisabled, setIsDropDisabled] = useState(false)

  const getList = (id: number) => state[id2List[id]]

  const onDragEnd = (result: any) => {
    const { source, destination } = result

    // リスト外にドロップ
    if (!result.destination) return

    // 同じリストにドロップ
    if (source.droppableId === destination.droppableId) {
      const items: any = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      )

      setState({
        items: items,
        selected: state.selected,
      })

      if (source.droppableId === 'droppable2') {
        setState({
          items: state.items,
          selected: items,
        })
      }
    } else {
      const result: any = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      )

      setState({
        items: result.droppable,
        selected: result.droppable2,
      })
    }
  }

  const onDragStart = (e: any) => {
    setIsDropDisabled(false)
    const { draggableId } = e
    if (draggableId === '16' || draggableId === '18') {
      setIsDropDisabled(true)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className={classes.root}>
        <div className={classes.column}>
          <Typography variant='h3'>表示しない項目</Typography>
          <Droppable droppableId='droppable' isDropDisabled={isDropDisabled}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {state.items.map((item: any, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps as any}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <p>{item.content}</p>
                        <div className={classes.iconContainer}>
                          {item.type === 1 && <OpenWithIcon />}
                          {item.type === 2 && <HeightIcon />}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className={classes.column}>
          <div className={classes.titleContainer}>
            <Typography variant='h3'>表示する項目</Typography>
            {state.selected.length >= 10 && (
              <Typography color='error' variant='caption'>
                表示可能上限数（10件）存在するため、新規に追加することはできません。
              </Typography>
            )}
          </div>
          <Droppable
            droppableId='droppable2'
            isDropDisabled={state.selected.length >= 10}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {state.selected.map((item: any, index: number) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={item.type === 0}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps as any}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <p>{item.content}</p>
                        <div className={classes.iconContainer}>
                          {item.type === 1 && <OpenWithIcon />}
                          {item.type === 2 && <HeightIcon />}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  )
}

export default DndList
