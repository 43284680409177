import React from 'react'
import { TopBar, Footer } from 'componentsHsc'

interface Props {
  jobChange?: boolean
}

const Layout: React.FC<Props> = ({ children, jobChange = false }) => (
  <>
    <TopBar jobChange={jobChange} />
    {children}
    <Footer />
  </>
)

export default Layout
