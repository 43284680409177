import React, { useEffect } from 'react'
import { TextField as MuiTextField, PropTypes } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'formik'

const useStyles = makeStyles({
  input: {
    '&::placeholder': {
      color: 'darkgray',
    },
    fontSize: '14px',
  },
  multiLineTextAreaContainar: {
    display: 'flex',
    flexFlow: 'column'
  },
  multiLineTextArea: {
    '&::placeholder': {
      color: 'darkgray',
    },
    fontSize: '14px',
    minHeight: '58px',
  },
  
  multiLineTextAreaClearLink: {
    marginLeft: 'auto'
  },
  clearLink: {
    background: 'transparent !important',
    border: 'none !important',
    outline: 'none !important',
    boxShadow: 'none !important',
    color: '#1950a6 !important',
    textDecoration: 'underline !important',
    '&:hover': {
      color: '#1a1a1a !important'
    },
    '&::before': {
      content: "'' !important"
    }
  }

})

interface Props extends FieldProps {
  type?: string
  margin?: PropTypes.Margin
  placeholder?: string
  defaultValue?: string
  multiline?: boolean
  rows?: number
  innerRef?: (instance: any) => void
  disabled?: boolean
  multiLineTextArea?: boolean
  onClearValue?: (e: any) => void;
}

const TextFieldForMessage = ({
  type = 'text',
  margin = 'dense',
  placeholder,
  field,
  form,
  multiline,
  rows,
  innerRef,
  disabled,
  multiLineTextArea = false,
  onClearValue
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { name } = field
  const value = field.value ? field.value : ""

  return (
    <div  className={classes.multiLineTextAreaContainar}>
      <MuiTextField
        variant='outlined'
        margin={margin}
        fullWidth
        multiline={multiline}
        rows={rows}
        type={type}
        inputProps={{ spellCheck: false }}
        InputProps={{
          classes: {
            input: multiLineTextArea ? classes.multiLineTextArea : classes.input,
          },
        }}
        error={Boolean(errors[name] && touched[name])}
        helperText={errors[name] && touched[name] ? errors[name] : null}
        placeholder={placeholder}
        {...field}
        value={value}
        inputRef={innerRef}
        disabled={disabled}
      />
      {onClearValue?(
        <div className={classes.multiLineTextAreaClearLink}>
          <button 
            className={`btn ${classes.clearLink}`}
            type='button'
            onClick={onClearValue}>
            一括でクリアする
          </button>
         </div>
      ): null}
    </div>
  )
}

export default TextFieldForMessage
