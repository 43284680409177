import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignContent: 'center',
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  title: string;
  subtitle?: string;
  required?: boolean;
  className?: string;
}

export default ({ title, subtitle, required, className }: Props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${className}`}>
      <div className={classes.container}>
        <p>{title}</p>
      </div>
      {subtitle && (
        <div className={classes.container}>
          <p>：{subtitle}</p>
        </div>
      )}
      {required && (
        <div className={classes.container}>
          <i className="badge ptn6">必須</i>
        </div>
      )}
    </div>
  );
};
