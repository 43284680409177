import request from 'utils/request'
import { UpdateRequest } from 'types/MCAOS010/MCAOS010DisplayOrderRequest'

export const getInterviewListRequest = (recruitmentManagementDivision:string) =>
  request({
    url: `/MCAOS010/init/${recruitmentManagementDivision}`,
    method: 'post',
  })

export const updateRequest = (apiData: UpdateRequest[]) =>
  request({
    url: '/MCAOS010/order',
    method: 'post',
    data: apiData,
  })

  export const checkInterviewCountRequest =(recruitmentManagementDivision: string)=>
  request({
    url: `/MCAOS010/new/${recruitmentManagementDivision}`,
    method: 'post',
  })
