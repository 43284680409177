import { Chip, IconButton, Tooltip as MuiTooltip } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { FieldGroup, FormField, SubTitle, Title } from 'componentsHsc'
import UncheckableToggle from './UncheckableToggle'
import { DatePicker } from 'components'
import { FastField, Field } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import React, { useEffect, useState } from 'react'
import { FormControl } from '.'
import Button from './Button'
import GroupOptions from 'componentsHsc/AdvancedSearchJobChange/GroupOptions'
import EntryDateModal from './Modal/EntryDateModal'
import EntryExitModal from './Modal/EntryExitModal'
import WebInterviewParticipationModal from './Modal/WebInterviewParticipationModal'
import EventModal from './Modal/EventModal'
import MessageModal from './Modal/MessageModal'
import SeminarDateModal from './Modal/SeminarDateModal'
import SeminarMediaModal from './Modal/SeminarMediaModal'
import { SelectOptions } from './Select'
import { getMessage } from 'common/messageUtil'
import {
  EntryDateConditionObj,
  EntryPresenceConditionObj,
  SeminarReceptionIndividualObj2,
  WebInterviewParticipationIndividualObj2,
  WebSeminarConditionObj,
  EventsIndividualObj2,
  QuestionnaireResponseConditionObj,
  SendMessageConditionObj,
} from './Config/searchConditionConfig'
import { initeRequest } from './Config/formConfig'
import {
  getMcaxs561Init,
  setEntryReplyModalState,
} from 'reducers/itemSpecificationReducer'
import { setSearchCondition } from 'reducers/applicantListReducer'
import { toEntryList } from './Config/searchConditionConfig'
import { setEntryReplyModalOpen } from 'reducers/advancedSearchReducer'
import { getMcaxs511Init } from 'reducers/advancedSearchModalReducer'
import { QuestionnaireConditionNested } from 'componentsHsc/AdvancedSearch/Config/itemConfig'
// [phase2] add package
import { isEmpty } from 'common/generalUtil'
// [phase2] add package end
import { combineClassName } from 'utils/misc'
import { magiContants as contants, magiContants } from 'utils/contants'
import ToggleAndOr from './ToggleAndOr'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
    maxWidth: '295px',
  },
  dateRange: {
    '& input': {
      padding: '0 8px',
    },
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  notitle: {
    paddingLeft: 10,
    marginRight: 80,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  datePickerDiv: {
    width: '130px',
    float: 'left',
    '& .MuiIconButton-label': {
      width: '23px',
    },
    '& .btn.only-icon.icon-input_calender': {
      margin: '0 10px 0 0',
    },
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: '13px 12px 0px 20px',
  },
  andOrStyleIE: {
    position: 'absolute',
    marginLeft: '130px',
    top: '6px',
  },
  andOrStyleOther: {
    position: 'absolute',
    marginLeft: '130px',
    marginTop: '6px',
  },
  cursor: {
    '& div' : {
      cursor: 'pointer!important',
    }
  },
  fullHeight: {
    padding: theme.spacing(1, 0)
  },
  spanDisabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  iconButton: {
    padding: 0,
    float: 'right',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const Tooltip = withStyles({
  tooltip: {
    fontSize: '1em',
  },
  popper: {
    whiteSpace: 'pre-line'
  }
})(MuiTooltip)

const scoutConditionOptionList = [
  { value: '0', label: 'スカウト送信なし' },
  { value: '1', label: 'スカウト送信あり' },
]

const welcomeConditionOptionList = [
  { value: '0', label: '付与なし' },
  { value: '1', label: '付与あり' },
]

const ScoutOptionList = [
  { value: '0', label: 'AND' },
  { value: '1', label: 'OR' },
]

// 対象応募経路項目有効・無効
const isTargetApplicationRouteDisabled = (form: FormControl) => {
  const { firstEntryDateFrom, firstEntryDateTo } = form.values
  return !firstEntryDateFrom && !firstEntryDateTo
}

interface Props {
  form: FormControl
}

const Applicant = ({ form }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    applicationRouteList,
    targetApplicationRouteList,
    scoutApplicationRouteList,
    welcomeApplicationRouteList,
    scoutFeatureObj,
  } = useSelector((state: RootState) => state.advancedSearch.applicantInfo)

  const applicationRouteSuggest = applicationRouteList.map(i => {
    return {
      label: i.applicationRouteName,
      value: i.applicationRouteId,
      applicationRouteFlag: i.applicationRouteFlag,
    }
  })

  const targetApplicationRouteSuggest = targetApplicationRouteList.map(i => {
    return {
      label: i.applicationRouteName,
      value: i.applicationRouteId,
      applicationRouteFlag: i.applicationRouteFlag,
    }
  })

  const scoutApplicationRouteSuggest = scoutApplicationRouteList.map(i => {
    return { label: i.contractMediaName, value: i.contractMediaId }
  })

  const welcomeApplicationRouteSuggest = welcomeApplicationRouteList.map(i => {
    return { label: i.contractMediaName, value: i.contractMediaId }
  })

  const ScoutPreSite = scoutFeatureObj.filter(item => item.siteDivision === '1' && item.selectShowFlag === '1').map(i => {
    return {
      label: i.scoutFeatureName,
      value: i.scoutFeatureCode
    }
  })
  const ScoutProSite = scoutFeatureObj.filter(item => item.siteDivision === '2' && item.selectShowFlag === '1').map(i => {
    return {
      label: i.scoutFeatureName,
      value: i.scoutFeatureCode
    }
  })

  const entryDate = form.values.entryDateObj || null
  const entryPresence = form.values.entryPresenceObj || null
  const questionnaire = form.values.questionnaireResponseObj || null
  const seminarReception = form.values.seminarReceptionObj || null
  const webSeminar = form.values.webSeminarObj || null
  const webInterviewParticipation = form.values.webInterviewParticipationObj || null
  const events = form.values.eventsObj || null
  const sendMessage = form.values.sendMessegeObj || null

  // Modal
  const [state, setState] = useState({
    entryDate: false,
    entryPresence: false,
    questionnaire: false,
    seminarReception: false,
    webSeminar: false,
    webInterviewParticipation: false,
    events: false,
    sendMessage: false,
  })

  const handleClick = (modalName: string, open = true) => () => {
    setState({
      ...state,
      [modalName]: open,
    })
  }

  const entryReplyModalState = useSelector(
    (state: RootState) => state.itemSpecification.entryReplyModalState
  )
  const handleEntryReplyClick = () => {
    const request = { ...initeRequest }
    request.coordinationFormerJobCategoryIdentificationKey = null

    dispatch(
      getMcaxs561Init({
        request: request,
        qro: form.values.questionnaireResponseObj,
        toggle: () => {
          dispatch(setSearchCondition(toEntryList(form.values)))
          dispatch(setEntryReplyModalOpen(true))
        },
      })
    )
  }

  const handleDeleteEntryDate = (option: EntryDateConditionObj) => () => {
    if (entryDate) {
      const newConditionList = entryDate.entryDateConditionObj.filter(
        i => i !== option
      )
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue(
          'entryDateObj.entryDateConditionObj',
          newConditionList
        )
      } else {
        form.setFieldValue('entryDateObj', null)
      }
    }
  }

  const handleDeleteEntryPresence = (
    option: EntryPresenceConditionObj
  ) => () => {
    if (entryPresence) {
      const newConditionList = entryPresence.entryPresenceConditionObj.filter(
        i => i !== option
      )
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue(
          'entryPresenceObj.entryPresenceConditionObj',
          newConditionList
        )
      } else {
        form.setFieldValue('entryPresenceObj', null)
      }
    }
  }

  const handleDeleteQuestionnaireResponse = (
    option: QuestionnaireResponseConditionObj
  ) => () => {
    if (questionnaire && questionnaire.conditionList) {
      const newConditionList = questionnaire.conditionList.filter(
        i => i !== option
      )
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue(
          'questionnaireResponseObj.conditionList',
          newConditionList
        )
      } else {
        form.setFieldValue('questionnaireResponseObj', null)
      }
    }
  }

  const handleDeleteSeminarReceptionWhich = () => {
    if (seminarReception) {
      form.setFieldValue('seminarReceptionObj', null)
    }
  }

  const handleDeleteWebInterviewParticipationWhich = () => {
    if (webInterviewParticipation) {
      form.setFieldValue('webInterviewParticipationObj', null)
    }
  }

  const handleDeleteSeminarReceptionIndividual = (
    option: SeminarReceptionIndividualObj2
  ) => () => {
    if (seminarReception && seminarReception.seminarReceptionIndividualObj) {
      const newConditionList = seminarReception.seminarReceptionIndividualObj.seminarReceptionIndividualObj2.filter(
        i => i !== option
      )
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue(
          'seminarReceptionObj.seminarReceptionIndividualObj.seminarReceptionIndividualObj2',
          newConditionList
        )
      } else {
        form.setFieldValue('seminarReceptionObj', null)
      }
    }
  }

  const handleDeleteWebSeminar = (option: WebSeminarConditionObj) => () => {
    if (webSeminar) {
      const newConditionList = webSeminar.webSeminarConditionObj.filter(
        i => i !== option
      )
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue(
          'webSeminarObj.webSeminarConditionObj',
          newConditionList
        )
      } else {
        form.setFieldValue('webSeminarObj', null)
      }
    }
  }

  const handleDeleteWebInterviewParticipationIndividual = (
    option: WebInterviewParticipationIndividualObj2
  ) => () => {
    if (webInterviewParticipation && webInterviewParticipation.webInterviewParticipationIndividualObj) {
      const newConditionList = webInterviewParticipation.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2.filter(
        i => i !== option
      )
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue(
          'webInterviewParticipationObj.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2',
          newConditionList
        )
      } else {
        form.setFieldValue('webInterviewParticipationObj', null)
      }
    }
  }

  const handleDeleteEventsWhich = () => {
    if (events) {
      form.setFieldValue('eventsObj', null)
    }
  }

  const handleDeleteEventsIndividual = (option: EventsIndividualObj2) => () => {
    if (events && events.eventsIndividualObj) {
      const newConditionList = events.eventsIndividualObj.eventsIndividualObj2.filter(
        i => i !== option
      )
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue(
          'eventsObj.eventsIndividualObj.eventsIndividualObj2',
          newConditionList
        )
      } else {
        form.setFieldValue('eventsObj', null)
      }
    }
  }

  const handleDeleteSendMessage = () => {
    if (sendMessage) {
      form.setFieldValue('sendMessegeObj', null)
    }
  }

  const handleDeleteSendMessageChip = (
    option: SendMessageConditionObj
  ) => () => {
    if (sendMessage && sendMessage.sendMessegeConditionObj) {
      const newConditionList = sendMessage.sendMessegeConditionObj.filter(
        i => i !== option
      )
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue(
          'sendMessegeObj.sendMessegeConditionObj',
          newConditionList
        )
      } else {
        form.setFieldValue('sendMessegeObj', null)
      }
    }
  }

  const handleEntryPresenceClick = () => {
    const openEntryPresenceModal = () => {
      setState({
        ...state,
        ['entryPresence']: true,
      })
    }
    dispatch(getMcaxs511Init(openEntryPresenceModal))
  }

  const generateLabelEntryDate = (item: EntryDateConditionObj) => {
    let result: string = ''
    if (item) {
      if (item.entryDateClassificationFlag === '0') {
        result += item.entryDateApplicationRouteName
        result += ' ' + item.entryDatecoordinationFormerEntryBoxId
        result += ' ' + item.entryDateEntryBoxName
      } else {
        result += item.entryDateApplicationRouteName
      }
      result += '：' + item.entryDateFrom + '～' + item.entryDateTo
    }
    return `${result}`
  }

  const generateLabelEntryPresence = (item: EntryPresenceConditionObj) => {
    let result: string = ''
    if (item) {
      if (item.entryPresenceClassificationFlag === '0') {
        //分類FLGが「[0]：エントリーボックス」の場合
        result += item.entryPresenceApplicationRouteName
        result += ' ' + item.entryPresencecoordinationFormerEntryBoxId
        result += ' ' + item.entryPresenceEntryBoxName
      } else {
        //分類FLGが「1]：外部エントリー」の場合
        result += item.entryPresenceApplicationRouteName
      }
      result += '：' + item.entryPresenceName
    }
    return `${result}`
  }

  const generateLabelEntryReply = (item: QuestionnaireResponseConditionObj) => {
    let answer: string = ''
    if (item.questionnaireResponseQuestionAnswerConditionFlag === '1') {
      //回答有無FLGが[1]回答なしの場合
      answer = '回答なし'
    } else {
      //回答有無FLGが[0]回答ありの場合
      if (item.questionnaireResponseQuestionTypeCode === '1') {
        //質問種別コードが[1]自由記述の場合
        if (item.questionnaireResponseAnswerText === '') {
          //回答テキストがない場合
          answer = '回答があるすべて'
        } else {
          //回答テキストがある場合
          answer = `回答に「${item.questionnaireResponseAnswerText}」が含まれる`
        }
      } else {
        //質問種別コードが[1]自由記述　以外の場合
        if (item.questionnaireResponseAnswerCheckBoxObj.length === 0) {
          //回答がない場合
          answer = '回答があるすべて'
        } else {
          //回答がある場合
          const answerLabelList = item.questionnaireResponseAnswerCheckBoxObj.map(
            i => i.label
          )
          answer = answerLabelList.join('、')
        }
      }
    }

    let result = 'Q.' + item.questionnaireResponseQuestionTitle
    result += ' ' + item.questionnaireResponseApplicationRouteName
    result += ' ' + item.questionnaireResponsecoordinationFormerEntryBoxId
    result += ' ' + item.questionnaireResponseEntryBoxName
    result += ' ' + 'A.' + answer

    return `${result}`
  }

  const generateLabelSeminar = (item: SeminarReceptionIndividualObj2) => {
    let valueText: string = ''
    if (item) {
      valueText += item.seminarReceptionIndividualApplicationRouteName
      valueText += ' ' + item.seminarReceptionIndividualSeminarScheduleTitle
      // [phase2] 【フェーズ２対応】連携された対象セミナー情報の開催日がNULL(開催日テキストの指定)の場合に、開催日の代わりに開催日テキストの内容を表示する
      if (!isEmpty(item.seminarReceptionIndividualEventDate)) {
        valueText += ' ' + item.seminarReceptionIndividualEventDate
        if(item.seminarReceptionIndividualStartTime !== '' || item.seminarReceptionIndividualEndTime !== ''){
          valueText += ' '
          valueText +=
          item.seminarReceptionIndividualStartTime !== ''
            ? item.seminarReceptionIndividualStartTime
            : ''
        valueText += '～'
        valueText +=
          item.seminarReceptionIndividualEndTime !== ''
            ? item.seminarReceptionIndividualEndTime
            : ''
        }
      }else{
        valueText += !isEmpty(item.seminarReceptionIndividualEventDateText)
          ? ' ' + item.seminarReceptionIndividualEventDateText
          : ''
      }
      // [phase2] end
      valueText += ' ' + item.seminarReceptionIndividualVenueLocationName
      if (item.seminarReceptionIndividualInvisibleFlag === '1') {
        valueText +=
          ' ' + '【' + item.seminarReceptionIndividualInvisibleName + '】'
      }
      valueText +=
        ' ' + item.seminarReceptionIndividualCoordinationFormerSeminarId
      valueText += ' ' + item.seminarReceptionIndividualSeminarName
      if (item.seminarReceptionIndividualPublicFlag === '0') {
        valueText +=
          ' ' + '【' + item.seminarReceptionIndividualPublicName + '】'
      }
      valueText += '：' + item.seminarReceptionIndividualName
    }

    return `${valueText}`
  }

  const generateLabelWebSeminar = (item: WebSeminarConditionObj) => {
    let valueText: string = ''
    if (item) {
      valueText += item.webSeminarApplicationRouteName
      valueText += ' ' + item.webSeminarCoordinationFormerWebSeminarId
      valueText += ' ' + item.webSeminarTitle
      
      if(item.webSeminarWatchingStatusConditionObj != null && item.webSeminarWatchingStatusConditionObj.length > 0) {
        valueText += '：'
        for (var j = 0; j < item.webSeminarWatchingStatusConditionObj.length; j++) {
          valueText += item.webSeminarWatchingStatusConditionObj[j].webSeminarWatchingStatusConditionName
          if (j !== item.webSeminarWatchingStatusConditionObj.length - 1) {
            valueText += '、'
          }
        }

      }
    }

    return `${valueText}`
  }

  const generateLabelWebInterviewParticipation = (item: WebInterviewParticipationIndividualObj2) => {
    let valueText: string = ''
    if (item) {
      valueText += item.webInterviewParticipationIndividualApplicationRouteName
      valueText += ' ' + item.webInterviewParticipationIndividualSeminarScheduleTitle
      // [phase2] 【フェーズ２対応】連携された対象セミナー情報の開催日がNULL(開催日テキストの指定)の場合に、開催日の代わりに開催日テキストの内容を表示する
      if (!isEmpty(item.webInterviewParticipationIndividualEventDate)) {
        valueText += ' ' + item.webInterviewParticipationIndividualEventDate
        if(item.webInterviewParticipationIndividualStartTime !== '' || item.webInterviewParticipationIndividualEndTime !== ''){
          valueText += ' '
          valueText +=
          item.webInterviewParticipationIndividualStartTime !== ''
            ? item.webInterviewParticipationIndividualStartTime
            : ''
        valueText += '～'
        valueText +=
          item.webInterviewParticipationIndividualEndTime !== ''
            ? item.webInterviewParticipationIndividualEndTime
            : ''
        }
      }else{
        valueText += !isEmpty(item.webInterviewParticipationIndividualEventDateText)
          ? ' ' + item.webInterviewParticipationIndividualEventDateText
          : ''
      }
      // [phase2] end
      valueText += ' ' + item.webInterviewParticipationIndividualVenueLocationName
      if (item.webInterviewParticipationIndividualInvisibleFlag === '1') {
        valueText +=
          ' ' + '【' + item.webInterviewParticipationIndividualInvisibleName + '】'
      }
      valueText +=
        ' ' + item.webInterviewParticipationIndividualCoordinationFormerSeminarId
      valueText += ' ' + item.webInterviewParticipationIndividualSeminarName
      if (item.webInterviewParticipationIndividualPublicFlag === '0') {
        valueText +=
          ' ' + '【' + item.webInterviewParticipationIndividualPublicName + '】'
      }
      valueText += '：' + item.webInterviewParticipationIndividualName
    }

    return `${valueText}`
  }


  const generateLabelMessage = (item: SendMessageConditionObj) => {
    let valueText: string = ''
    const itemText: string = '特定のメッセージで検索'
    if (item.sendMessegeMessageTypeName) {
      valueText += item.sendMessegeMessageTypeName
    }
    if (item.sendMessegeSubject) {
      valueText += '　' + '「' + item.sendMessegeSubject + '」が件名に含まれる'
    }
    if (item.sendMessegeSendConditionName) {
      valueText += '　' + item.sendMessegeSendConditionName
    }
    if (item.sendMessegeSendDateFrom && item.sendMessegeSendDateTo) {
      valueText +=
        '(' +
        item.sendMessegeSendDateFrom +
        '～' +
        item.sendMessegeSendDateTo +
        ')'
    }
    if (item.sendMessegeSendDateFrom && !item.sendMessegeSendDateTo) {
      valueText += '(' + item.sendMessegeSendDateFrom + '～)'
    }
    if (!item.sendMessegeSendDateFrom && item.sendMessegeSendDateTo) {
      valueText += '(～' + item.sendMessegeSendDateTo + ')'
    }
    if (item.sendMessegeStatusObj.length > 0) {
      item.sendMessegeStatusObj.map(obj => {
        valueText += '　' + obj.sendMessegeStatusName
      })
    }
    if (item.sendMessegeTemplateObj.length > 0){
      valueText += '　' + contants.MESSAGETEMPLATE + '：' + item.sendMessegeTemplateConditionName
      for(var i = 0; item.sendMessegeTemplateObj.length > i; i++ ){
        const sequence = item.sendMessegeTemplateObj[i].sequence ? item.sendMessegeTemplateObj[i].sequence + '. ' : ''
        valueText += '「' + sequence + item.sendMessegeTemplateObj[i].sendMessegeTemplateName + '」'
      }
    }

    // phase2 start
    if(item.sendMessegeMyNaviAttachedOptionName){
      valueText += '　' + contants.Mynavi_Attached_Option + '：' + '「' + item.sendMessegeMyNaviAttachedOptionName + '」'
    }
    // phase2 end
    return `${itemText}：${valueText}`
  }

  const generateLabelEvent = (item: EventsIndividualObj2) => {
    
    let valueText: string = ''
    //応募経路名
    valueText += item.eventsIndividualApplicationRouteName
    // Started By DiaoJiaHao
    //連携元イベントID
    if (item.eventsIndividualCoordinationFormerEventId != null) {
      valueText += ' ' + item.eventsIndividualCoordinationFormerEventId
    }
    //イベント日程
    if (item.eventsIndividualEventSchedule != null) {
      valueText += ' ' + item.eventsIndividualEventSchedule
    }
    //イベント名
    if (item.eventsIndividualEventName != null) {
      valueText += ' ' + item.eventsIndividualEventName
    }
    //エリア名
    if (item.eventsIndividualAreaName != null) {
      valueText += item.eventsIndividualAreaName
    }
    //都道府県名
    if (item.eventsIndividualPrefecturesName != null) {
      valueText += ' ' + item.eventsIndividualPrefecturesName
    }
    //イベント種別名
    if (item.eventsIndividualEventTypeName != null) {
      valueText += '(' + item.eventsIndividualEventTypeName + ')'
    }
    // End By DiaoJiaHao
    
    //イベント参加状況（各イベントごと）名
    valueText += '：' + item.eventsIndividualName
    return `${valueText}`
  }

  const siteDisabled = () => {
    if (form.values.scoutObj == null) {
      return true
    } else {
      if (form.values.scoutObj.value == '0') {
        return true
      } else {
        return false
      }
    }
  }
  return (
    <>
      <FieldGroup>
        <SubTitle variant='h3'>応募経路</SubTitle>
        <FormField>
          <Title title='応募経路' className={classes.title} />
          <FastField
            name='applicationRouteObj'
            row
            component={GroupOptions}
            optionList={applicationRouteSuggest}
          />
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>エントリー</SubTitle>
        <FormField helperText={getMessage('MCAXS361-001')} fullHeight>
          <Title title='初回エントリー日' className={classes.title} />
          <div className={combineClassName(
            classes.dateRange,
            classes.fullHeight
          )}>
            <div className={classes.datePickerDiv}>
              <FastField
                name='firstEntryDateFrom'
                selectsStart
                component={DatePicker}
                placeholder='YYYY/MM/DD'
              />
            </div>
            <div className={classes.wavyLineStyle}>~</div>
            <div className={classes.datePickerDiv}>
              <FastField
                name='firstEntryDateTo'
                selectsEnd
                component={DatePicker}
                placeholder='YYYY/MM/DD'
              />
            </div>
          </div>
        </FormField>
        <FormField fullHeight>
          <Title 
            offset
            title='対象応募経路' 
            className={classes.title} />
          <Field
            name='targetApplicationRouteObj'
            row
            disabled={isTargetApplicationRouteDisabled(form)}
            component={GroupOptions}
            optionList={targetApplicationRouteSuggest}
          />
        </FormField>
        <FormField>
          <Title title='エントリー日時' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleClick('entryDate')} />
            {entryDate && entryDate.entryDateConditionObj && (
              <div
              className={
                window.navigator.userAgent
                  .toLocaleLowerCase()
                  .match(/(trident)\/([\d.]+)/)
                  ? classes.andOrStyleIE
                  : classes.andOrStyleOther
              }>
              {entryDate.entryDateConditionJudgmentName}
            </div>
            )}
            {entryDate && entryDate.entryDateConditionObj && (
              <span>
                {entryDate.entryDateConditionObj.map(i => (
                  <Chip
                    title={generateLabelEntryDate(i)}
                    className={classes.chip}
                    label={generateLabelEntryDate(i)}
                    onDelete={handleDeleteEntryDate(i)}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            )}
          </div>
        </FormField>
        <FormField>
          <Title title='エントリー有無' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleEntryPresenceClick} />
            {entryPresence && entryPresence.entryPresenceConditionObj && (
              <div
              className={
                window.navigator.userAgent
                  .toLocaleLowerCase()
                  .match(/(trident)\/([\d.]+)/)
                  ? classes.andOrStyleIE
                  : classes.andOrStyleOther
              }>
              {entryPresence.entryPresenceConditionJudgmentName}
            </div>
            )}
            {entryPresence && entryPresence.entryPresenceConditionObj && (
              <span>
                {entryPresence.entryPresenceConditionObj.map(i => (
                  <Chip
                    title={generateLabelEntryPresence(i)}
                    className={classes.chip}
                    label={generateLabelEntryPresence(i)}
                    onDelete={handleDeleteEntryPresence(i)}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            )}
          </div>
        </FormField>
        <FormField>
          <Title title='アンケート回答内容' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleEntryReplyClick} />
            {questionnaire &&
              questionnaire.conditionList &&
              questionnaire.conditionList.length > 0 && (
                <div
                  className={
                    window.navigator.userAgent
                      .toLocaleLowerCase()
                      .match(/(trident)\/([\d.]+)/)
                      ? classes.andOrStyleIE
                      : classes.andOrStyleOther
                  }>
                  {questionnaire.typeName}
                </div>
              )}
            {questionnaire &&
              questionnaire.conditionList &&
              questionnaire.conditionList.length > 0 && (
                <span>
                  {questionnaire.conditionList.map(i => (
                    <Chip
                      title={generateLabelEntryReply(i)}
                      className={classes.chip}
                      label={generateLabelEntryReply(i)}
                      onDelete={handleDeleteQuestionnaireResponse(i)}
                      deleteIcon={<CancelIcon />}
                    />
                  ))}
                </span>
              )}
          </div>
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>セミナー</SubTitle>
        <FormField>
          <Title title='受付日程・予約状況' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleClick('seminarReception')} />
            {seminarReception &&
              (seminarReception.seminarReceptionIndividualObj ||
                seminarReception.seminarReceptionWhichOneObj) && (
                  <div
                   className={
                    window.navigator.userAgent
                      .toLocaleLowerCase()
                      .match(/(trident)\/([\d.]+)/)
                      ? classes.andOrStyleIE
                      : classes.andOrStyleOther
                  }>
                  {seminarReception.seminarReceptionConditionJudgmentName + '　'}
                  {seminarReception.seminarReceptionIndividualObj
                    ? seminarReception.seminarReceptionIndividualObj
                        .seminarReceptionIndividualJudgmentName
                    : null}
                </div>
              )}
            {seminarReception &&
              (seminarReception.seminarReceptionIndividualObj ||
                seminarReception.seminarReceptionWhichOneObj) && (
                <span>
                  {seminarReception.seminarReceptionIndividualObj ? (
                    seminarReception.seminarReceptionIndividualObj.seminarReceptionIndividualObj2.map(
                      i => (
                        <Chip
                          title={generateLabelSeminar(i)}
                          className={classes.chip}
                          label={generateLabelSeminar(i)}
                          onDelete={handleDeleteSeminarReceptionIndividual(i)}
                          deleteIcon={<CancelIcon />}
                        />
                      )
                    )
                  ) : seminarReception.seminarReceptionWhichOneObj ? (
                    <Chip
                      title={`${seminarReception.seminarReceptionWhichOneObj.seminarReceptionWhichOneName}`}
                      className={classes.chip}
                      label={`${seminarReception.seminarReceptionWhichOneObj.seminarReceptionWhichOneName}`}
                      onDelete={handleDeleteSeminarReceptionWhich}
                      deleteIcon={<CancelIcon />}
                    />
                  ) : null}
                </span>
              )}
          </div>
        </FormField>
        <Tooltip 
          title={getMessage(magiContants.MESSAGECODE_MCAXS361_002)} 
          placement='top' 
          tabIndex={-1}
        >
          <IconButton className={classes.iconButton}>
            <HelpOutlineIcon fontSize='large' />
          </IconButton>
        </Tooltip>
        <FormField>
          <Title title='予約したセミナー開催日' className={classes.title} />
          <div className={classes.dateRange}>
            <div className={classes.datePickerDiv}>
              <FastField
                name='reservationDateFrom'
                selectsStart
                component={DatePicker}
                placeholder='YYYY/MM/DD'
              />
            </div>
            <div className={classes.wavyLineStyle}>~</div>
            <div className={classes.datePickerDiv}>
              <FastField
                name='reservationDateTo'
                selectsEnd
                component={DatePicker}
                placeholder='YYYY/MM/DD'
              />
            </div>
          </div>
        </FormField>
        <FormField>
          <Title title='Webセミナー視聴状況' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleClick('webSeminar')} />
            {webSeminar && webSeminar.webSeminarConditionObj && (
              <div
              className={
                window.navigator.userAgent
                  .toLocaleLowerCase()
                  .match(/(trident)\/([\d.]+)/)
                  ? classes.andOrStyleIE
                  : classes.andOrStyleOther
              }>
              {webSeminar.webSeminarConditionJudgmentName}
            </div>
            )}
            {webSeminar && webSeminar.webSeminarConditionObj && (
              <span>
                {webSeminar.webSeminarConditionObj.map(i => (
                  <Chip
                    title={generateLabelWebSeminar(i)}
                    className={classes.chip}
                    label={generateLabelWebSeminar(i)}
                    onDelete={handleDeleteWebSeminar(i)}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            )}
          </div>
        </FormField>
        <FormField>
          <Title title='WEB面談参加状況' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleClick('webInterviewParticipation')} />
            {webInterviewParticipation &&
              (webInterviewParticipation.webInterviewParticipationIndividualObj ||
                webInterviewParticipation.webInterviewParticipationWhichOneObj) && (
                  <div
                   className={
                    window.navigator.userAgent
                      .toLocaleLowerCase()
                      .match(/(trident)\/([\d.]+)/)
                      ? classes.andOrStyleIE
                      : classes.andOrStyleOther
                  }>
                  {webInterviewParticipation.webInterviewParticipationConditionJudgmentName + '　'}
                  {webInterviewParticipation.webInterviewParticipationIndividualObj
                    ? webInterviewParticipation.webInterviewParticipationIndividualObj
                        .webInterviewParticipationIndividualJudgmentName
                    : null}
                </div>
              )}
            {webInterviewParticipation &&
              (webInterviewParticipation.webInterviewParticipationIndividualObj ||
                webInterviewParticipation.webInterviewParticipationWhichOneObj) && (
                <span>
                  {webInterviewParticipation.webInterviewParticipationIndividualObj ? (
                    webInterviewParticipation.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2.map(
                      i => (
                        <Chip
                          title={generateLabelWebInterviewParticipation(i)}
                          className={classes.chip}
                          label={generateLabelWebInterviewParticipation(i)}
                          onDelete={handleDeleteWebInterviewParticipationIndividual(i)}
                          deleteIcon={<CancelIcon />}
                        />
                      )
                    )
                  ) : webInterviewParticipation.webInterviewParticipationWhichOneObj ? (
                    <Chip
                      title={`${webInterviewParticipation.webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneName}`}
                      className={classes.chip}
                      label={`${webInterviewParticipation.webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneName}`}
                      onDelete={handleDeleteWebInterviewParticipationWhich}
                      deleteIcon={<CancelIcon />}
                    />
                  ) : null}
                </span>
              )}
          </div>
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>イベント</SubTitle>
        <FormField>
          <Title title='イベント参加状況' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleClick('events')} />
            {events &&
              (events.eventsIndividualObj || events.eventsWhichOneObj) && (
                <div
              className={
                window.navigator.userAgent
                  .toLocaleLowerCase()
                  .match(/(trident)\/([\d.]+)/)
                  ? classes.andOrStyleIE
                  : classes.andOrStyleOther
              }>
              {events.eventsConditionJudgmentName + '　'}
              {events.eventsIndividualObj
                    ? events.eventsIndividualObj.eventsIndividualJudgmentName
                    : null}
            </div>
              )}
            {events &&
              (events.eventsIndividualObj || events.eventsWhichOneObj) && (
                <span>
                  {events.eventsIndividualObj ? (
                    events.eventsIndividualObj.eventsIndividualObj2.map(i => (
                      <Chip
                        title={generateLabelEvent(i)}
                        className={classes.chip}
                        label={generateLabelEvent(i)}
                        onDelete={handleDeleteEventsIndividual(i)}
                        deleteIcon={<CancelIcon />}
                      />
                    ))
                  ) : events.eventsWhichOneObj ? (
                    <Chip
                      title={`${events.eventsWhichOneObj.eventsWhichOneName}`}
                      className={classes.chip}
                      label={`${events.eventsWhichOneObj.eventsWhichOneName}`}
                      onDelete={handleDeleteEventsWhich}
                      deleteIcon={<CancelIcon />}
                    />
                  ) : null}
                </span>
              )}
          </div>
        </FormField>
        <FormField>
          <Title title='イベント開催日' className={classes.title} />
          <div className={classes.dateRange}>
            <div className={classes.datePickerDiv}>
              <FastField
                name='eventDateFrom'
                selectsStart
                component={DatePicker}
                placeholder='YYYY/MM/DD'
              />
            </div>
            <div className={classes.wavyLineStyle}>~</div>
            <div className={classes.datePickerDiv}>
              <FastField
                name='eventDateTo'
                selectsEnd
                component={DatePicker}
                placeholder='YYYY/MM/DD'
              />
            </div>
          </div>
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>メッセージ</SubTitle>
        <FormField>
          <Title title='メッセージ送付状況' className={classes.title} />
          <div className={classes.modal}>
            <Button onClick={handleClick('sendMessage')} />
            {sendMessage && (
              <span>
                {sendMessage.sendMessegeConditionObj &&
                sendMessage.sendMessegeConditionObj.length > 0 ? (
                  sendMessage.sendMessegeConditionObj.map(i => (
                    <Chip
                      title={generateLabelMessage(i)}
                      className={classes.chip}
                      label={generateLabelMessage(i)}
                      onDelete={handleDeleteSendMessageChip(i)}
                      deleteIcon={<CancelIcon />}
                    />
                  ))
                ) : (
                  <Chip
                    title='メッセージを何も送信していない'
                    className={classes.chip}
                    label='メッセージを何も送信していない'
                    onDelete={handleDeleteSendMessage}
                    deleteIcon={<CancelIcon />}
                  />
                )}
              </span>
            )}
          </div>
        </FormField>
        <FormField>
          <Title title='スカウト' className={classes.title} />
          <FastField
            name='scoutApplicationRouteObj'
            isMulti={true}
            optionList={scoutApplicationRouteSuggest}
            defaultValue={form.values.scoutApplicationRouteObj}
            placeholder='応募経路を選択'
            component={SelectOptions}
            className={classes.cursor}
            isClearable
          />
        </FormField>
        <FormField>
          <Title title='' className={classes.notitle} />
          <Field
            name='scoutObj.value'
            optionList={scoutConditionOptionList}
            row
            component={UncheckableToggle}
            targetIndex={1}
            targetIndex2={1}
            target='scoutObj'
          />
        </FormField>
        <FormField>
          <Title title='' className={classes.notitle} />
          <div>
            {
              ScoutPreSite.length > 0 ?
                <div>
                  <span className={siteDisabled() ? `${classes.spanDisabled}` : undefined}>{'＜プレサイト＞'}</span>
                  <div>
                    <Field
                      name='scoutPreSiteObj'
                      component={GroupOptions}
                      disabled={siteDisabled()}
                      optionList={ScoutPreSite}
                      row
                    />
                  </div>
                </div> : <></>
            }
            {
              ScoutPreSite.length > 0 || ScoutProSite.length > 0 ?
              <div>
                <Field
                  name='scoutCharacterConditionJudgment'
                  component={ToggleAndOr}
                  row
                  optionList={ScoutOptionList}
                  disabled={siteDisabled()}
                />
              </div> : <></>
            }
            {
              ScoutProSite.length > 0 ?
                <div>
                  <span className={siteDisabled() ? `${classes.spanDisabled}` : undefined}>{'＜本サイト＞'}</span>
                  <div>
                    <Field
                      name='scoutProSiteObj'
                      component={GroupOptions}
                      disabled={siteDisabled()}
                      optionList={ScoutProSite}
                      row
                    />
                  </div>
                </div> : <></>
            }
          </div>
        </FormField>
        <FormField>
          <Title title='応募歓迎' className={classes.title} />
          <FastField
            name='welcomeApplicationRouteObj'
            isMulti={true}
            defaultValue={form.values.welcomeApplicationRouteObj}
            optionList={welcomeApplicationRouteSuggest}
            placeholder='応募経路を選択'
            component={SelectOptions}
            className={classes.cursor}
            isClearable
          />
        </FormField>
        <FormField>
          <Title title='' className={classes.notitle} />
          <Field
            name='welcomeObj.value'
            optionList={welcomeConditionOptionList}
            row
            component={UncheckableToggle}
            targetIndex={2}
            targetIndex2={2}
            target='welcomeObj'
          />
        </FormField>
      </FieldGroup>

      <EntryDateModal
        open={state.entryDate}
        onClose={handleClick('entryDate', false)}
        form={form}
      />
      <EntryExitModal
        open={state.entryPresence}
        onClose={handleClick('entryPresence', false)}
        form={form}
      />

      <SeminarDateModal
        open={state.seminarReception}
        onClose={handleClick('seminarReception', false)}
        form={form}
      />
      <SeminarMediaModal
        open={state.webSeminar}
        onClose={handleClick('webSeminar', false)}
        form={form}
      />
      <WebInterviewParticipationModal
        open={state.webInterviewParticipation}
        onClose={handleClick('webInterviewParticipation', false)}
        form={form}
      />
      <EventModal
        open={state.events}
        onClose={handleClick('events', false)}
        form={form}
      />
      <MessageModal
        open={state.sendMessage}
        onClose={handleClick('sendMessage', false)}
        form={form}
      />
    </>
  )
}

export default Applicant
