import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialMCBJS010SubmissionReqTemplateList, initialopenModal } from 'pages/MCBJS010/formConfig'
import { initialMCBJS020ReturnDisplayDto, initialopenModalArgs } from 'pages/MCBJS020/formConfig'
import { initMCBJS040SubmissionReqTemplateList, initialSubmitValues } from 'pages/MCBJS040/formConfig'
import { MCBJS010InitDisplayRequest } from 'types/MCBJS010/MCBJS010InitDisplayRequest'
import { MCBJS010CopySubmissionReqTemplateRequest } from 'types/MCBJS010/MCBJS010CopySubmissionReqTemplateRequest'
import { MCBJS010UpdateDisplayStatusRequest } from 'types/MCBJS010/MCBJS010UpdateDisplayStatusRequest'
import { MCBJS020InitDisplayRequest } from 'types/MCBJS020/MCBJS020InitDisplayRequest'
import { MCBJS020SubmissionRequestTemplateInputRequest } from 'types/MCBJS020/MCBJS020submissionReqTemplateInputRequest'
import { MCBJS040SubmissionRequestTemplateRequest } from 'types/MCBJS040/MCBJS040SubmissionRequestTemplateRequest'
import { MCBJS040InitDisplayRequest } from 'types/MCBJS040/MCBJS040InitDisplayRequest'
import { MCBJS030InitDisplayRequest } from 'types/MCBJS030/MCBJS030InitDisplayRequest'
import { MCBJS030DeleteSubmissionReqTemplateRequest } from 'types/MCBJS030/MCBJS030DeleteSubmissionReqTemplateRequest'
import { MCBJS030SubmissionReqTemplateInputRequest } from 'types/MCBJS030/MCBJS030SubmissionReqTemplateInputRequest'
import { MCBJS020SubmitRequestPreviewRequest } from 'types/MCBJS020/MCBJS020SubmitRequestPreviewRequest'


export interface InitCondition {
  templateId: number//提出リクエストテンプレート設定ID
}
// MCBリプレース MCBJS020 START
export interface MCBJS020SearchHomepageResultDto {
  submissionReqTemplateSettingId: number	//提出リクエストテンプレートID
  sequence: number	//連番
  homePageTitle: string	//ホームページタイトル
  homePageUrl: string	//ホームページURL
}
export interface MCBJS020SubmissionReqTempAttachPreResultOutDto {
  submissionReqTemplateSettingId: number	//提出リクエストテンプレートID
  sequence: number	//連番
  attachmentName: string	//添付ファイル名
  fileManagementId: string	//ファイル管理ID
  fileSize: string	//ファイルサイズ
}
interface MCBJS020SignPreResultOutDto {
  signSettingId: number	//署名設定ID
  registrationName: string //登録名
  signature: string	//署名 
}

export interface MCBJS020SubmissionsContentList {
submissionsContentId: number // 提出物内容ID
submissionsNameForCompany: string // 提出物内容名（企業管理用）
submissionsNameForStudent: string // 提出物内容名（学生）
entrySheetResumeSubmissionFlg: boolean // エントリーシート/履歴書提出フラグ
entrySheetResumeSubmissionCategory: number //エントリーシート/履歴書選択区分
researchOverviewsSubmissionFlg: boolean // 研究概要書提出フラグ
originalQuestionSubmissionFlg: boolean // オリジナル設問提出フラグ
fileSubmissionFlg: boolean // ファイル提出フラグ
hiddenFlg: boolean // 非表示フラグ
modified: string  // 更新日時
}
export interface MCBJS020ThanksMailTemplateList {
  thanksMailTemplateId: number  // サンクスメールテンプレートID
  thanksMailTemplateRegisteredName: string //サンクスメールテンプレート件名
  thanksMailTemplateSubject: string //サンクスメールテンプレート件名
  thanksMailTemplateBody: string //サンクスメールテンプレート本文
  modified: string //更新日時
  }

export interface MCBJS020ContactList {
  inquiryId: number // お問い合わせ先ID
  inquiryRegisteredName: string// お問い合わせ先名
  companyName: string// 企業名
  departmentNameAndManagerName: string// 部署名／担当者氏名
  telNumber: string// 電話番号
  emailAddress: string// メールアドレス
  hiddenFlg: boolean// 非表示フラグ
  modifiedDate: string// 更新日時
}

export interface MCBJS020MyCareerBoxPrivacyPolicyList {
  myCareerBoxPrivacyPolicyId: number // My CareerBoxに関する個人情報の取り扱いID
  privacyPolicyRegisterdName: string // My CareerBoxに関する個人情報の取り扱い名
  hiddenFlg: boolean // 非表示フラグ
  modifiedDate: string// 更新日時
}

export interface templateAttachmentInfo {
  templateAttachmentName: string[],
  templateAttachmentSize: number[]
}
export interface SelectTemplateResultDto {
  templateId: number //提出リクエストテンプレート設定ID
  sequence: number // 連番
  templateName: string //テンプレート名
  invisibleFlag: number //表示／非表示
  updateTime: Date //更新日時
  fullName: string //更新者
  sysVersionNumber: number //バージョン番号
}

export interface MCBJS020ReturnDisplayOutDto {
  templateId: string	//提出リクエストテンプレートID
  recruitmentManagementDivision: string//採用管理区分
  requestTemplateName: string	//テンプレート名
  targetYear: string  //対象年度
  targetYearList: string[] //対象年度リスト
  destinationSelection: string	//送信先区分
  submissionsSelectId: string //提出物内容選択ID
  submissionsName: string // 提出物内容名
  submissionsNameStudent: string //提出物内容（学生画面表示用）
  submissionNames: string //提出物
  submissionsEntryFlg: string// 提出物エントリシートフラグ
  submissionsResumeFlg: string // 提出物履歴書フラグ
  submissionsResearchFlg: string // 提出物研究概要書フラグ
  submissionsOriginalFlg: string // 提出物オリジナル設問フラグ
  submissionsFileFlg: string // 提出物ファイル提出フラグ
  limitDateDetermineFlag: string // 提出締切_種別
  determineDateTime: string //提出締切指定日時
  requestDayTimeLaterDay: string //依頼日から○日後
  requestDayTimeToTime: string //依頼日から△時まで
  anytimeReceptionEndDateTime: string //随時受付終了日時
  reRequestReceptFlag: string //再提出受付フラグ
  homepageTitle1: string	//ホームページタイトル1
  homepageTitle2: string//ホームページタイトル2
  homepageTitle3: string//ホームページタイトル3
  homepageUrl1: string	//ホームページURL1
  homepageUrl2: string//ホームページURL2
  homepageUrl3: string	//ホームページURL3
  thanksMessageSetFlag: string //サンクスメール設定フラグ
  thanksMessageTemplateId: string //サンクスメールテンプレートID
  thanksMessageTemplateName: string //サンクスメールテンプレート名
  thanksSubject: string //サンクスメール_件名
  thanksText: string //サンクスメール_本文
  studentReferenceId: string //問い合わせ先選択ID
  studentReferenceName: string //お問い合わせ先名
  studentReferenceCompanyName: string //お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: string //お問い合わせ先内容部署名担当者氏名
  studentReferenceTelephoneNumber: string //お問い合わせ先内容電話番号
  studentReferenceMailAddress: string //お問い合わせ先内容メールアドレス
  personalDataHandlingId: string //個人情報の取り扱い選択ID
  personalDataHandlingName: string // 個人情報の取り扱い名
  senderCompanyName: string	//送信元会社名
  senderMailAddress: string	//送信元メールアドレス
  requestSubject: string	//件名
  requestText: string	//本文
  attachmentFileList: MCBJS020SubmissionReqTempAttachPreResultOutDto[]//添付ファイル情報
  signList: MCBJS020SignPreResultOutDto[]//署名リスト
  exampleLink: string//文例リンク
  submissionsLink: string //提出物リンク
  requestExampleLink: string //提出リクエスト文例リンク
  thanksMailTemplateLink: string //サンクスメールテンプレートリンク
  thanksMailExampleLink: string //サンクスメール文例リンク
  referenceLink: string //問い合わせ先リンク
  personalDataHandlingLink: string //個人情報の取り扱いリンク
  sysVersionNumber: string	//sysバージョン番号
  forbiddenWordsList: string[]//禁止語リスト
  submissionContentList: MCBJS020SubmissionsContentList[] //提出物内容リスト
  thanksMailTemplateContentList: MCBJS020ThanksMailTemplateList[] // サンクスメールテンプレート内容リスト
  contactContentList: MCBJS020ContactList[] // お問い合わせ先内容リスト
  privacyPolicyContentList: MCBJS020MyCareerBoxPrivacyPolicyList[] // 個人情報の取り扱い内容リスト
  previewThanksBody: string //サンクス本文固定文言
  previewRequestBody: string //リクエスト本文固定文言
  authToken: string // MCB認証トークン
  // 26P #80795、#88225 start
  annual: string // 年度
  annualList: string // 対象年度リスト
  annualCs: string // チェックサム
  // 26P #80795、#88225 end
}
// MCBリプレース MCBJS020 END

interface modalMessageArgs {
  messageId: string,
  args: string[]
}
export interface openModalDataArgs {
  openCheckModal: boolean,
  modalMessageArgs: modalMessageArgs
}

const resetModal: openModalData = {
  openCheckModal: false,
  modalMessage: ""
}
const resetModalArgs: openModalDataArgs = {
  openCheckModal: false,
  modalMessageArgs: {
    messageId: "",
    args: []
  }
}

export interface addSubmitParams {
  templateId: string	//提出リクエストテンプレート設定ID
  templateName: string	//テンプレート名
  targetYear: string  //対象年度
  destinationDivision: string	//送信先区分
  submissionsSelectId: string //提出物内容選択ID
  submissionContentName: string //提出物内容（企業管理用）
  submissionContentStudent: string //提出物内容（学生画面表示用）
  submissionNames: string //提出物
  submissionsEntryFlg: string //提出物エントリシートフラグ
  submissionsResumeFlg: string //提出物履歴書フラグ
  submissionsResearchFlg: string //提出物研究概要書フラグ
  submissionsOriginalFlg: string //提出物オリジナル設問フラグ
  submissionsFileFlg: string //提出物ファイル提出フラグ
  deadline: string  //提出〆切区分
  deadlineDate: string  //提出〆切（日時指定）
  deadlineAfterDay: string  //提出〆切（依頼日から（日後））
  deadlineAfterDayTime: string  //提出〆切（依頼日から（時））
  deadlineAnyTime: string //提出〆切（随時受付終了）
  resubmission: string  //再提出の受付区分
  homepageTitle1: string	//ホームページタイトル1
  homepageTitle2: string	//ホームページタイトル2
  homepageTitle3: string	//ホームページタイトル3
  homepageUrl1: string	//ホームページURL1
  homepageUrl2: string	//ホームページURL2
  homepageUrl3: string	//ホームページURL3
  settingThanksEmail: string  //サンクスメール設定区分
  thanksMailTemplateId: string //サンクスメールテンプレートID
  thanksEmailTemplate: string  //サンクスメールテンプレート
  thanksEmailSubject: string  //件名（サンクスメール）
  thanksEmailBody: string //本文（サンクスメール）
  studentReferenceId: string //問い合わせ先選択ID
  contactInformation: string  //問い合わせ先
  contactCompanyName: string // お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: string // お問い合わせ先内容部署名担当者氏名
  contactTelephoneNumber: string // お問い合わせ先内容電話番号
  contactMailAddress: string // お問い合わせ先内容メールアドレス
  personalDataHandlingId: string //個人情報の取り扱い選択ID
  handlingPersonalInformation: string //個人情報の取り扱い名
  senderCompanyName: string	//送信元会社名
  senderMailAddress: string	//送信元メールアドレス
  submissionReqSubject: string	//件名（提出リクエストメッセージ）
  submissionReqBody: string	//本文（提出リクエストメッセージ）
  attachment: templateAttachmentInfo	//添付ファイル[]
  templateattachmentList: MCBJS020ConfirmData[]  //テンプレート添付ファイル情報[]
  sysVersionNumber: string	//sysバージョン番号
  forbiddenWordsList: string[]	//禁止語リスト
  previewThanksBody: string //サンクス本文固定文言
  previewRequestBody: string //リクエスト本文固定文言

}

export interface MCBJS010SubmissionReqTemplateList {
  submissionReqTemplateSearchCondition: MCBJS010InitDisplayRequest //入力する検索条件
  targetYear: string; //対象年度
  recruitmentManagementDivision: string;//採用管理区分
  maxSubmissionReqTemplate: string;//提出リクエストテンプレート数上限
  submissionReqTemplateResult: SelectTemplateResultDto[] //検索結果
}

export interface openModalData {
  openCheckModal: boolean,
  modalMessage: string,
}

export interface MCBJS020ConfirmData {
  fileManagementId: number // ファイル管理ID
  attachmentName: string // 添付ファイル名
  fileSize: string // ファイルサイズ
}

// MCBJS040 Start
export interface MCBJS040SubmissionReqTemplateList {
  returnRequestBody: string// プレビュー本文;
  returnThanksBody: string// プレビューサンクス本文;
  // #MCB1.5次開発 #72395 START
  senderMailAddressFix: string //送信元メールアドレス固定
  // #MCB1.5次開発 #72395 END
}

// MCBJS040 End

interface SubmissionReqTemplateList {
  mCBJS010SubmissionReqTemplateList: MCBJS010SubmissionReqTemplateList,//提出リクエストテンプレート一覧
  returnDisplayDto: MCBJS020ReturnDisplayOutDto,
  mCBJS040SubmissionReqTemplateList: MCBJS040SubmissionReqTemplateList,// 提出リクエストテンプレート登録 内容確認
  pageDialogOpen020: boolean,//画面openフラグ(MCBJS020)
  addCopyflag020: number,//登録コピー画面の区分(MCBJS020)
  pageDialogOpen040: boolean,//画面openフラグ(MCBJS040)
  confirmData: MCBJS020ConfirmData[] //(MCBJS020 内容を確認)
  // mCAMS030ReturnDisplayDto: MCBJS020ReturnDisplayDto, // MCBJS030 内容
  pageDialogOpen030: boolean,//画面openフラグ(MCBJS030)
  submissionReqTemplatePage: number,//提出リクエストテンプレート一覧に選択されるページ数
  selectedTemplates: number[],//提出リクエストテンプレート一覧に選択されるデータ
  option: string,//表示非表示option
  addSubmit: addSubmitParams,
  openModalData: openModalData,
  openModalDataArgs: openModalDataArgs,
  bodyFlag: number,
  returnBody: string
  
}

const submissionReqTemplateList: SubmissionReqTemplateList = {
  mCBJS010SubmissionReqTemplateList: initialMCBJS010SubmissionReqTemplateList,//提出リクエストテンプレート一覧
  returnDisplayDto: initialMCBJS020ReturnDisplayDto,
  mCBJS040SubmissionReqTemplateList: initMCBJS040SubmissionReqTemplateList,// 提出リクエストテンプレート登録 内容確認
  pageDialogOpen020: false,//画面openフラグ(MCBJS020)
  addCopyflag020: 0,//登録コピー画面の区分(MCBJS020)
  pageDialogOpen040: false,//画面openフラグ(MCBJS040)
  confirmData: [],
  pageDialogOpen030: false,//画面openフラグ(MCBJS030)
  submissionReqTemplatePage: 0,//提出リクエストテンプレート一覧に選択されるページ数
  selectedTemplates: [],//提出リクエストテンプレート一覧に選択されるデータ
  option: '',//表示非表示option
  addSubmit: initialSubmitValues,
  openModalData: initialopenModal,
  openModalDataArgs: initialopenModalArgs,
  bodyFlag: 0,
  returnBody:''
}

export interface MCBJS020LinkResultDto {
  authToken: string
  sourceCompanyCode: string
  url: string
  // 26P #80795、#88225 start
  annualParam: string
  // 26P #80795、#88225 end
}

const submissionReqTemplateSlice = createSlice({
  name: 'submissionReqTemplate',
  initialState: submissionReqTemplateList,
  reducers: {
    //MCBリプレース MCBJS020 START
    submissionReqTemplateAddInit(state, action: PayloadAction<MCBJS020InitDisplayRequest>) {
      return state
    },
    setSubmissionReqTemplateAddInit(state, action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto = action.payload
      let newSubmissionContentList :MCBJS020SubmissionsContentList[] = []
      let newContactContentList : MCBJS020ContactList[] = []
      let newPrivacyPolicyContentList :MCBJS020MyCareerBoxPrivacyPolicyList[] = []
      action.payload.submissionContentList.map(i => {
        if(i.hiddenFlg === false){
          newSubmissionContentList = [...newSubmissionContentList,i]
        }
      })
      action.payload.contactContentList.map(i => {
        if(i.hiddenFlg === false){
          newContactContentList = [...newContactContentList,i]
        }
      })
      action.payload.privacyPolicyContentList.map(i => {
        if(i.hiddenFlg === false){
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList,i]
        }
      })
      state.returnDisplayDto.submissionContentList = newSubmissionContentList
      state.returnDisplayDto.contactContentList = newContactContentList
      state.returnDisplayDto.privacyPolicyContentList = newPrivacyPolicyContentList

      let submissionsList: number[]=[]
      let thanksList: number[]=[]
      let contactList: number[]=[]
      let personalList: number[]=[]
      state.returnDisplayDto.submissionContentList.map(i=>{
        // MCBリプレース #8584 START
        if(i.submissionsContentId === Number(action.payload.submissionsSelectId)){
          state.returnDisplayDto.submissionsName = i.submissionsNameForCompany
          state.returnDisplayDto.submissionsNameStudent = i.submissionsNameForStudent

          state.returnDisplayDto.submissionsEntryFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 1 ? '1' : '0' ) :'0'
          state.returnDisplayDto.submissionsResumeFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 2 ? '1' : '0' ) : '0'
          state.returnDisplayDto.submissionsResearchFlg = i.researchOverviewsSubmissionFlg === true ? '1' : '0' 
          state.returnDisplayDto.submissionsOriginalFlg = i.originalQuestionSubmissionFlg === true ? '1' : '0'
          state.returnDisplayDto.submissionsFileFlg = i.fileSubmissionFlg === true ? '1' : '0'

          state.returnDisplayDto.submissionNames = (state.returnDisplayDto.submissionsEntryFlg==='1'? 'エントリーシート、' : '' ) + 
          (state.returnDisplayDto.submissionsResumeFlg==='1' ? '履歴書、' : '')+
          (state.returnDisplayDto.submissionsResearchFlg==='1' ? '研究概要書、' : '')+
          (state.returnDisplayDto.submissionsOriginalFlg==='1' ? 'オリジナル設問、' : '')+
          // MCBリプレイス #8675 START
          (state.returnDisplayDto.submissionsFileFlg==='1' ? 'ファイル、' : '')
          // MCBリプレイス #8675 END
        }
        // MCBリプレース #8584 END
        submissionsList.push(i.submissionsContentId)
    })
      state.returnDisplayDto.thanksMailTemplateContentList.map(i=>{
        // MCBリプレース #8584 START
        if(action.payload.thanksMessageSetFlag === '1' && i.thanksMailTemplateId === Number(action.payload.thanksMessageTemplateId)){
          state.returnDisplayDto.thanksMessageTemplateName = i.thanksMailTemplateRegisteredName
        }
        // MCBリプレース #8584 END
          thanksList.push(i.thanksMailTemplateId)
      })
      state.returnDisplayDto.contactContentList.map(i=>{
        // MCBリプレース #8584 START
        if(i.inquiryId === Number(action.payload.studentReferenceId)){
          state.returnDisplayDto.studentReferenceName = i.inquiryRegisteredName
          state.returnDisplayDto.studentReferenceCompanyName = i.companyName
          state.returnDisplayDto.contactDepartmentNameAndManagerName = i.departmentNameAndManagerName
          state.returnDisplayDto.studentReferenceTelephoneNumber = i.telNumber
          state.returnDisplayDto.studentReferenceMailAddress = i.emailAddress
        }
        // MCBリプレース #8584 END
        contactList.push(i.inquiryId)
    })
      state.returnDisplayDto.privacyPolicyContentList.map(i=>{
        // MCBリプレース #8584 START
        if(i.myCareerBoxPrivacyPolicyId === Number(action.payload.personalDataHandlingId)){
          state.returnDisplayDto.personalDataHandlingName = i.privacyPolicyRegisterdName
        }
        // MCBリプレース #8584 END
        personalList.push(i.myCareerBoxPrivacyPolicyId)
    })

      if(!submissionsList.includes(Number(action.payload.submissionsSelectId))){
        state.returnDisplayDto.submissionNames = ''
        state.returnDisplayDto.submissionsNameStudent = ''
        state.returnDisplayDto.submissionsSelectId = ''
        state.returnDisplayDto.submissionsName = ''
             }

      if(!contactList.includes(Number(action.payload.studentReferenceId))){
        state.returnDisplayDto.studentReferenceId = ''
        state.returnDisplayDto.studentReferenceName = ''
        state.returnDisplayDto.studentReferenceCompanyName = ''
        state.returnDisplayDto.contactDepartmentNameAndManagerName = ''
        state.returnDisplayDto.studentReferenceTelephoneNumber = ''
        state.returnDisplayDto.studentReferenceMailAddress = ''
             }

      if(!thanksList.includes(Number(action.payload.thanksMessageTemplateId))){
        state.returnDisplayDto.thanksMessageTemplateId = ''
        state.returnDisplayDto.thanksMessageTemplateName = ''
            }      
      
      if(!personalList.includes(Number(action.payload.personalDataHandlingId))){
        state.returnDisplayDto.personalDataHandlingId = ''
        state.returnDisplayDto.personalDataHandlingName = ''
            }

      //テンプレート名値がない場合、初期値
      if (action.payload.requestTemplateName == null) {
        state.returnDisplayDto.requestTemplateName = initialMCBJS020ReturnDisplayDto.requestTemplateName
      }

      //対象年度値がない場合、初期値を設定する
      if (action.payload.targetYear == null) {
        state.returnDisplayDto.targetYear = initialMCBJS020ReturnDisplayDto.targetYear
      }

      //送信先区分値がない場合、MyCareerBoxを設定する
      if (action.payload.destinationSelection == '3') {
        state.returnDisplayDto.destinationSelection = action.payload.destinationSelection
      } else {
        state.returnDisplayDto.destinationSelection = '3'
      }

      //提出〆切 値がない場合、初期値を設定する
      if (action.payload.limitDateDetermineFlag == '0' || action.payload.limitDateDetermineFlag == '1' || action.payload.limitDateDetermineFlag == '2') {
        state.returnDisplayDto.limitDateDetermineFlag = action.payload.limitDateDetermineFlag
      } else {
        state.returnDisplayDto.limitDateDetermineFlag = '0'
      }

      //再提出の受付 値がない場合、初期値を設定する
      if (action.payload.reRequestReceptFlag == '0' || action.payload.reRequestReceptFlag == '1') {
        state.returnDisplayDto.reRequestReceptFlag = action.payload.reRequestReceptFlag
      } else {
        state.returnDisplayDto.reRequestReceptFlag = '0'
      }

      //送信元会社名値がない場合、初期値
      if (action.payload.senderCompanyName == null) {
        state.returnDisplayDto.senderCompanyName = initialMCBJS020ReturnDisplayDto.senderCompanyName
      }
      //送信元メールアドレス値がない場合、初期値
      if (action.payload.senderMailAddress == null) {
        state.returnDisplayDto.senderMailAddress = initialMCBJS020ReturnDisplayDto.senderMailAddress
      }
      //件名値がない場合、初期値
      if (action.payload.requestSubject == null) {
        state.returnDisplayDto.requestSubject = initialMCBJS020ReturnDisplayDto.requestSubject
      }
      //本文値がない場合、初期値
      if (action.payload.requestText == null) {
        state.returnDisplayDto.requestText = initialMCBJS020ReturnDisplayDto.requestText
      }
      state.returnDisplayDto.homepageTitle1 = state.returnDisplayDto.homepageTitle1 != undefined ? state.returnDisplayDto.homepageTitle1 : ''	//ホームページタイトル1
      state.returnDisplayDto.homepageTitle2 = state.returnDisplayDto.homepageTitle2 != undefined ? state.returnDisplayDto.homepageTitle2 : ''	//ホームページタイトル2
      state.returnDisplayDto.homepageTitle3 = state.returnDisplayDto.homepageTitle3 != undefined ? state.returnDisplayDto.homepageTitle3 : ''	//ホームページタイトル3
      state.returnDisplayDto.homepageUrl1 = state.returnDisplayDto.homepageUrl1 != undefined ? state.returnDisplayDto.homepageUrl1 : ''	//ホームページURL1
      state.returnDisplayDto.homepageUrl2 = state.returnDisplayDto.homepageUrl2 != undefined ? state.returnDisplayDto.homepageUrl2 : ''	//ホームページURL2
      state.returnDisplayDto.homepageUrl3 = state.returnDisplayDto.homepageUrl3 != undefined ? state.returnDisplayDto.homepageUrl3 : ''	//ホームページURL3

      //サンクスメール設定値がない場合、初期値を設定する
      if (action.payload.thanksMessageSetFlag == '0' || action.payload.thanksMessageSetFlag == '1') {
        state.returnDisplayDto.thanksMessageSetFlag = action.payload.thanksMessageSetFlag
      } else {
        state.returnDisplayDto.thanksMessageSetFlag = '1'
      }

      state.returnDisplayDto.authToken = action.payload.authToken
      // 26P #80795、#88225 start
      state.returnDisplayDto.annual = action.payload.annual
      state.returnDisplayDto.annualList = action.payload.annualList
      state.returnDisplayDto.annualCs = action.payload.annualCs != null ? action.payload.annualCs : ''
      // 26P #80795、#88225 end
      return state
    },
    submissionTemplateTargetYearChange(state, action: PayloadAction<MCBJS020SubmissionRequestTemplateInputRequest>) {
      // 26P #80795 start
      state.returnDisplayDto.templateId = action.payload.templateId
      state.returnDisplayDto.requestTemplateName = action.payload.requestTemplateName
      state.returnDisplayDto.targetYear = action.payload.targetYear
      state.returnDisplayDto.destinationSelection = action.payload.destinationSelection
      state.returnDisplayDto.limitDateDetermineFlag = action.payload.limitDateDetermineFlag
      state.returnDisplayDto.submissionNames = ''
      state.returnDisplayDto.submissionsNameStudent = ''
      state.returnDisplayDto.submissionsSelectId = ''
      state.returnDisplayDto.submissionsName = ''
      state.returnDisplayDto.determineDateTime = action.payload.determineDateTime
      state.returnDisplayDto.requestDayTimeLaterDay = action.payload.requestDayTimeLaterDay
      state.returnDisplayDto.requestDayTimeToTime = action.payload.requestDayTimeToTime
      state.returnDisplayDto.anytimeReceptionEndDateTime = action.payload.anytimeReceptionEndDateTime
      state.returnDisplayDto.reRequestReceptFlag = action.payload.reRequestReceptFlag
      state.returnDisplayDto.senderCompanyName = action.payload.senderCompanyName
      state.returnDisplayDto.senderMailAddress = action.payload.senderMailAddress
      state.returnDisplayDto.requestSubject = action.payload.requestSubject
      state.returnDisplayDto.requestText = action.payload.requestText
    
      state.returnDisplayDto.thanksMessageSetFlag = action.payload.thanksMessageSetFlag
      state.returnDisplayDto.thanksMessageTemplateId = action.payload.thanksMessageTemplateId
      state.returnDisplayDto.thanksMessageTemplateName = action.payload.thanksMessageTemplateName
      state.returnDisplayDto.thanksSubject = action.payload.thanksSubject
      state.returnDisplayDto.thanksText = action.payload.thanksText

      state.returnDisplayDto.studentReferenceId = action.payload.studentReferenceId
      state.returnDisplayDto.studentReferenceName = action.payload.studentReferenceName
      state.returnDisplayDto.studentReferenceCompanyName = action.payload.studentReferenceCompanyName
      state.returnDisplayDto.contactDepartmentNameAndManagerName = action.payload.studentReferenceDepartmentName
      state.returnDisplayDto.contactDepartmentNameAndManagerName = action.payload.studentReferenceManagerName
      state.returnDisplayDto.studentReferenceTelephoneNumber = action.payload.studentReferenceTelephoneNumber
      state.returnDisplayDto.studentReferenceMailAddress = action.payload.studentReferenceMailAddress

      state.returnDisplayDto.personalDataHandlingId = action.payload.personalDataHandlingId
      state.returnDisplayDto.personalDataHandlingName = action.payload.personalDataHandlingName
      state.returnDisplayDto.recruitmentManagementDivision = action.payload.recruitmentManagementDivision
      state.returnDisplayDto.sysVersionNumber = action.payload.sysVersionNumber
      state.returnDisplayDto.homepageTitle1 = action.payload.homepageTitle1
      state.returnDisplayDto.homepageTitle2 = action.payload.homepageTitle2
      state.returnDisplayDto.homepageTitle3 = action.payload.homepageTitle3
      state.returnDisplayDto.homepageUrl1 = action.payload.homepageUrl1
      state.returnDisplayDto.homepageUrl2 = action.payload.homepageUrl2
      state.returnDisplayDto.homepageUrl3 = action.payload.homepageUrl3
      // 26P #80795 end
      return state
    },
    setSubmissionTemplateTargetYearChange(state, action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      let newSubmissionContentList :MCBJS020SubmissionsContentList[] = []
      let newContactContentList : MCBJS020ContactList[] = []
      let newPrivacyPolicyContentList :MCBJS020MyCareerBoxPrivacyPolicyList[] = []
      // 26P #80795 start
      let contactList: number[]=[]
      let thanksList: number[]=[]
      let personalList: number[]=[]
      action.payload.thanksMailTemplateContentList.map(i => {
        thanksList.push(i.thanksMailTemplateId)
      })
      if(!thanksList.includes(Number(state.returnDisplayDto.thanksMessageTemplateId)) ){
        state.returnDisplayDto.thanksMessageTemplateId = ''
        state.returnDisplayDto.thanksMessageTemplateName = ''
        state.returnDisplayDto.thanksSubject = ''
        state.returnDisplayDto.thanksText = ''
      }
      // 26P #80795 end
      action.payload.submissionContentList.map(i => {
        if(i.hiddenFlg === false){
          newSubmissionContentList = [...newSubmissionContentList,i]
        }
      })
      action.payload.contactContentList.map(i => {
        if(i.hiddenFlg === false){
          newContactContentList = [...newContactContentList,i]
          // 26P #80795 start
          contactList.push(i.inquiryId)
          // 26P #80795 end
        }
      })
      action.payload.privacyPolicyContentList.map(i => {
        if(i.hiddenFlg === false){
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList,i]
        }
        // 26P #80795 start
        personalList.push(i.myCareerBoxPrivacyPolicyId)
        // 26P #80795 end
      })
      // 26P #80795 start
      if(!contactList.includes(Number(state.returnDisplayDto.studentReferenceId)) ){
        state.returnDisplayDto.studentReferenceName = ''
        state.returnDisplayDto.studentReferenceCompanyName = ''
        state.returnDisplayDto.contactDepartmentNameAndManagerName = ''
        state.returnDisplayDto.contactDepartmentNameAndManagerName = ''
        state.returnDisplayDto.studentReferenceTelephoneNumber = ''
        state.returnDisplayDto.studentReferenceMailAddress = ''
      }
      if(!personalList.includes(Number(state.returnDisplayDto.personalDataHandlingId)) ){
        state.returnDisplayDto.personalDataHandlingName = ''
      }
      state.returnDisplayDto.thanksMailTemplateContentList = action.payload.thanksMailTemplateContentList
      state.returnDisplayDto.annual = action.payload.annual
      state.returnDisplayDto.annualList = action.payload.annualList
      state.returnDisplayDto.annualCs = action.payload.annualCs != null ? action.payload.annualCs : ''
      // 26P #80795 end
      state.returnDisplayDto.submissionContentList = newSubmissionContentList
      state.returnDisplayDto.contactContentList = newContactContentList
      state.returnDisplayDto.privacyPolicyContentList = newPrivacyPolicyContentList
      return state
    },
    getReloadSubmissions(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      state.returnDisplayDto = action.payload
      return state
    },
    setReloadSubmissions(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto.submissionsSelectId = ''
      state.returnDisplayDto.submissionsNameStudent = ''
      state.returnDisplayDto.submissionsName = ''
      state.returnDisplayDto.submissionNames = ''
      let newSubmissionContentList :MCBJS020SubmissionsContentList[] = []
      action.payload.submissionContentList.map(i => {
        if(i.hiddenFlg === false){
          newSubmissionContentList = [...newSubmissionContentList,i]
        }
      })
      state.returnDisplayDto.submissionContentList = newSubmissionContentList
      return state
    },
    getReloadThanksTemplate(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      state.returnDisplayDto = action.payload
      return state
    },
    setReloadThanksTemplate(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto.thanksMailTemplateContentList = action.payload.thanksMailTemplateContentList
      state.returnDisplayDto.thanksMessageTemplateId = ''
      state.returnDisplayDto.thanksMessageTemplateName = ''
      return state
    },
    getReloadReference(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      state.returnDisplayDto = action.payload
      return state
    },
    setReloadReference(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto.studentReferenceId = ''
      state.returnDisplayDto.studentReferenceName = ''
      state.returnDisplayDto.studentReferenceCompanyName = ''  
      state.returnDisplayDto.contactDepartmentNameAndManagerName = ''    
      state.returnDisplayDto.studentReferenceMailAddress = ''        
      state.returnDisplayDto.studentReferenceTelephoneNumber = ''       
      let newContactContentList : MCBJS020ContactList[] = []        
      action.payload.contactContentList.map(i => {
        if(i.hiddenFlg === false){
          newContactContentList = [...newContactContentList,i]
        }
      })      
      state.returnDisplayDto.contactContentList = newContactContentList      
      return state
    },
    getReloadPersonal(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      state.returnDisplayDto = action.payload
      return state
    },
    setReloadPersonal(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto.personalDataHandlingId = ''
      state.returnDisplayDto.personalDataHandlingName = ''
      let newPrivacyPolicyContentList :MCBJS020MyCareerBoxPrivacyPolicyList[] = []
      action.payload.privacyPolicyContentList.map(i => {
        if(i.hiddenFlg === false){
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList,i]
        }
      })
      state.returnDisplayDto.privacyPolicyContentList = newPrivacyPolicyContentList
      return state
    },

    setAddPageDialogOpen(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen020 = action.payload
      return state
    },
    setPageOpen(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen020 = action.payload
      return state
    },
    submissionReqTemplateConfirm(state, action: PayloadAction<
      { submitData: MCBJS020SubmissionRequestTemplateInputRequest, data: addSubmitParams,  files: File[] }>) {
      return state
    },
    setSubmissionReqTemplateConfirm(state, action: PayloadAction<MCBJS020ConfirmData[]>) {
      state.confirmData = action.payload
      return state
    },
    changeDeadlineValue(state, action: PayloadAction<{ values:MCBJS020ReturnDisplayOutDto,value: string }>) {
      const value = action.payload.value
      // MCB リプレイス B票870 start
      state.returnDisplayDto = JSON.parse(JSON.stringify(action.payload.values))
      // MCB リプレイス B票870 end
      state.returnDisplayDto.limitDateDetermineFlag = value
      if(value === '0'){
      state.returnDisplayDto.requestDayTimeLaterDay = ''
      state.returnDisplayDto.requestDayTimeToTime = ''
      state.returnDisplayDto.anytimeReceptionEndDateTime = ''
      }else if(value === '1'){
        state.returnDisplayDto.determineDateTime = ''
        state.returnDisplayDto.anytimeReceptionEndDateTime = ''
      }else{
        state.returnDisplayDto.determineDateTime = ''
        state.returnDisplayDto.requestDayTimeLaterDay = ''
        state.returnDisplayDto.requestDayTimeToTime = ''
        state.returnDisplayDto.reRequestReceptFlag = '0'
      }
      return state
    },
    openApi(state, action:  PayloadAction<MCBJS020LinkResultDto>) {
      return state
    },

    getPreview(state, action: PayloadAction<{formData :MCBJS020SubmitRequestPreviewRequest, 
      setHasDisplayPreview: (value: boolean) => void}>) {
      return state
    },
    setPreview(state,action: PayloadAction<string>) {
      state.returnBody = action.payload
      return state
    },
        //MCBリプレース MCBJS020 END
    openModalMessage(state, action: PayloadAction<string>) {
      state.openModalData.modalMessage = action.payload;
      state.openModalData.openCheckModal = true
    },
    openModalWithArgMessage(state, action: PayloadAction<string>) {
      state.openModalDataArgs.modalMessageArgs.messageId = action.payload
      state.openModalDataArgs.openCheckModal = true
    },
    closeModel(state) {
      state.openModalData = resetModal
      return state;
    },
    closeModelArgs(state) {
      state.openModalDataArgs = resetModalArgs
      return state;
    },

 // MCBJS010 Start
    selectSubmissionReqTemplateList(state, action: PayloadAction<MCBJS010InitDisplayRequest>) {
      return state
    },
    setSelectSubmissionReqTemplateList(state, action: PayloadAction<MCBJS010SubmissionReqTemplateList>) {
      state.mCBJS010SubmissionReqTemplateList = action.payload
      return state
    },
    addSubmissionReqTemplateList(state, action: PayloadAction<string>) {
      return state
    },
    setAddSubmissionReqTemplateList(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen020 = action.payload
      state.addCopyflag020 = 0
      return state
    },
    executeSubmissionReqTemplateList(state, action: PayloadAction<MCBJS010UpdateDisplayStatusRequest>) {
      return state
    },
    setExecuteSubmissionReqTemplateList(state, action: PayloadAction<MCBJS010SubmissionReqTemplateList>) {
      state.mCBJS010SubmissionReqTemplateList = action.payload
      return state
    },
    copySubmissionReqTemplateList(state, action: PayloadAction<MCBJS010CopySubmissionReqTemplateRequest>) {
      return state
    },
    setCopySubmissionReqTemplateList(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen020 = action.payload
      state.addCopyflag020 = 1
      return state
    },
    setSubmissionReqTemplateListPage(state, action: PayloadAction<number>) {
      state.submissionReqTemplatePage = action.payload
      return state
    },
    setSubmissionReqTemplateListData(state, action: PayloadAction<number[]>) {
      state.selectedTemplates = action.payload
      return state
    },
    setSubmissionReqTemplateListOption(state, action: PayloadAction<string>) {
      state.option = action.payload
      return state
    },
    setOpenModalMeassageData(state, action: PayloadAction<openModalData>) {
      state.openModalData = action.payload
      return state
    },
    updateSubmissionReqTemplateSort(state, action: PayloadAction<{ updateOrderList: SelectTemplateResultDto[], selectParam: MCBJS010InitDisplayRequest }>) {
      state.mCBJS010SubmissionReqTemplateList.submissionReqTemplateResult = action.payload.updateOrderList
      return state
    },
    // MCBJS010 End

    // MCBJS040 Start
    //登録内容確認初期表示時
    fetchSubmissionReqTemplateConfirm(state, action: PayloadAction<MCBJS040InitDisplayRequest>) {
      return state
    },
    setSubmissionReqTemplateContentConfirm(state, action: PayloadAction<MCBJS040SubmissionReqTemplateList>) {
      state.mCBJS040SubmissionReqTemplateList = action.payload
      return state
    },
    setOpenConfirmContentPage(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen040 = action.payload
      return state
    },
    setCloseConfirmContentPage(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen040 = action.payload
      return state
    },
    insertOrUpdateSubmissionReqTemplate(state, action: PayloadAction<{ registeredFileName: File[], submitParams: MCBJS040SubmissionRequestTemplateRequest, mCBJS010InitDisplayRequest: MCBJS010InitDisplayRequest }>) {
      return state
    },
    setAddSubmit(state, action: PayloadAction<addSubmitParams>) {
      state.addSubmit = action.payload
      return state
    },
    // MCBJS040 End

    // MCBリプレース MCBJS030 START
    // 初期表示
    submissionReqTemplateDetailInit(state, action: PayloadAction<MCBJS030InitDisplayRequest>) {
      return state
    },
    // 初期表示内容
    setSubmissionReqTemplateDetailInit(state, action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto = action.payload
      let submissionsResumeFlg = action.payload.submissionsResumeFlg
      let submissionsEntryFlg = action.payload.submissionsEntryFlg
      let submissionsResearchFlg = action.payload.submissionsResearchFlg
      let submissionsOriginalFlg = action.payload.submissionsOriginalFlg
      let submissionsFileFlg = action.payload.submissionsFileFlg
      state.returnDisplayDto.submissionNames = (submissionsEntryFlg==='1'? 'エントリーシート、' : '' ) + 
      (submissionsResumeFlg==='1' ? '履歴書、' : '')+
      (submissionsResearchFlg==='1' ? '研究概要書、' : '')+
      (submissionsOriginalFlg==='1' ? 'オリジナル設問、' : '')+
      // MCBリプレイス #8675 START
      (submissionsFileFlg==='1' ? 'ファイル、' : '')
      // MCBリプレイス #8675 END
      let newSubmissionContentList :MCBJS020SubmissionsContentList[] = []
      let newContactContentList : MCBJS020ContactList[] = []
      let newPrivacyPolicyContentList :MCBJS020MyCareerBoxPrivacyPolicyList[] = []
      action.payload.submissionContentList.map(i => {
        if(i.hiddenFlg === false){
          newSubmissionContentList = [...newSubmissionContentList,i]
        }
      })
      action.payload.contactContentList.map(i => {
        if(i.hiddenFlg === false){
          newContactContentList = [...newContactContentList,i]
        }
      })
      action.payload.privacyPolicyContentList.map(i => {
        if(i.hiddenFlg === false){
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList,i]
        }
      })
      state.returnDisplayDto.submissionContentList = newSubmissionContentList
      state.returnDisplayDto.contactContentList = newContactContentList
      state.returnDisplayDto.privacyPolicyContentList = newPrivacyPolicyContentList

      let submissionsList: number[]=[]
      let thanksList: number[]=[]
      let contactList: number[]=[]
      let personalList: number[]=[]
      state.returnDisplayDto.submissionContentList.map(i=>{
        // MCBリプレース #8584 START
        if(i.submissionsContentId === Number(action.payload.submissionsSelectId)){
          state.returnDisplayDto.submissionsName = i.submissionsNameForCompany
          state.returnDisplayDto.submissionsNameStudent = i.submissionsNameForStudent

          state.returnDisplayDto.submissionsEntryFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 1 ? '1' : '0' ) :'0'
          state.returnDisplayDto.submissionsResumeFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 2 ? '1' : '0' ) : '0'
          state.returnDisplayDto.submissionsResearchFlg = i.researchOverviewsSubmissionFlg === true ? '1' : '0' 
          state.returnDisplayDto.submissionsOriginalFlg = i.originalQuestionSubmissionFlg === true ? '1' : '0'
          state.returnDisplayDto.submissionsFileFlg = i.fileSubmissionFlg === true ? '1' : '0'

          state.returnDisplayDto.submissionNames = (state.returnDisplayDto.submissionsEntryFlg==='1'? 'エントリーシート、' : '' ) + 
          (state.returnDisplayDto.submissionsResumeFlg==='1' ? '履歴書、' : '')+
          (state.returnDisplayDto.submissionsResearchFlg==='1' ? '研究概要書、' : '')+
          (state.returnDisplayDto.submissionsOriginalFlg==='1' ? 'オリジナル設問、' : '')+
          // MCBリプレイス #8675 START
          (state.returnDisplayDto.submissionsFileFlg==='1' ? 'ファイル、' : '')
          // MCBリプレイス #8675 END
        }
        // MCBリプレース #8584 END
        submissionsList.push(i.submissionsContentId)
    })
      state.returnDisplayDto.thanksMailTemplateContentList.map(i=>{
        // MCBリプレース #8584 START
        if(action.payload.thanksMessageSetFlag === '1' && i.thanksMailTemplateId === Number(action.payload.thanksMessageTemplateId)){
          state.returnDisplayDto.thanksMessageTemplateName = i.thanksMailTemplateRegisteredName
        }
        // MCBリプレース #8584 END
          thanksList.push(i.thanksMailTemplateId)
      })
      state.returnDisplayDto.contactContentList.map(i=>{
        // MCBリプレース #8584 START
        if(i.inquiryId === Number(action.payload.studentReferenceId)){
          state.returnDisplayDto.studentReferenceName = i.inquiryRegisteredName
          state.returnDisplayDto.studentReferenceCompanyName = i.companyName
          state.returnDisplayDto.contactDepartmentNameAndManagerName = i.departmentNameAndManagerName
          state.returnDisplayDto.studentReferenceTelephoneNumber = i.telNumber
          state.returnDisplayDto.studentReferenceMailAddress = i.emailAddress
        }
        // MCBリプレース #8584 END
        contactList.push(i.inquiryId)
    })
      state.returnDisplayDto.privacyPolicyContentList.map(i=>{
        // MCBリプレース #8584 START
        if(i.myCareerBoxPrivacyPolicyId === Number(action.payload.personalDataHandlingId)){
          state.returnDisplayDto.personalDataHandlingName = i.privacyPolicyRegisterdName
        }
        // MCBリプレース #8584 END
        personalList.push(i.myCareerBoxPrivacyPolicyId)
    })

      if(!submissionsList.includes(Number(action.payload.submissionsSelectId))){
        state.returnDisplayDto.submissionNames = ''
        state.returnDisplayDto.submissionsNameStudent = ''
        state.returnDisplayDto.submissionsSelectId = ''
        state.returnDisplayDto.submissionsName = ''
             }

      if(!contactList.includes(Number(action.payload.studentReferenceId))){
        state.returnDisplayDto.studentReferenceId = ''
        state.returnDisplayDto.studentReferenceName = ''
        state.returnDisplayDto.studentReferenceCompanyName = ''
        state.returnDisplayDto.contactDepartmentNameAndManagerName = ''
        state.returnDisplayDto.studentReferenceTelephoneNumber = ''
        state.returnDisplayDto.studentReferenceMailAddress = ''
             }

      if(!thanksList.includes(Number(action.payload.thanksMessageTemplateId))){
        state.returnDisplayDto.thanksMessageTemplateId = ''
        state.returnDisplayDto.thanksMessageTemplateName = ''
            }      
      
      if(!personalList.includes(Number(action.payload.personalDataHandlingId))){
        state.returnDisplayDto.personalDataHandlingId = ''
        state.returnDisplayDto.personalDataHandlingName = ''
            }

      //テンプレート名値がない場合、初期値
      if (action.payload.requestTemplateName == null) {
        state.returnDisplayDto.requestTemplateName = initialMCBJS020ReturnDisplayDto.requestTemplateName
      }
      //送信先区分値がない場合、マイナビのみを設定する
      if (action.payload.destinationSelection == '3') {
        state.returnDisplayDto.destinationSelection = action.payload.destinationSelection
      } else {
        state.returnDisplayDto.destinationSelection = '3'
      }
      //提出〆切 値がない場合、初期値を設定する
      if (action.payload.limitDateDetermineFlag == '0' || action.payload.limitDateDetermineFlag == '1' || action.payload.limitDateDetermineFlag == '2') {
        state.returnDisplayDto.limitDateDetermineFlag = action.payload.limitDateDetermineFlag
      } else {
        state.returnDisplayDto.limitDateDetermineFlag = '0'
      }
      //再提出の受付 値がない場合、初期値を設定する
      if (action.payload.reRequestReceptFlag == '0' || action.payload.reRequestReceptFlag == '1') {
        state.returnDisplayDto.reRequestReceptFlag = action.payload.reRequestReceptFlag
      } else {
        state.returnDisplayDto.reRequestReceptFlag = '0'
      }
      //送信元会社名値がない場合、初期値
      if (action.payload.senderCompanyName == null) {
        state.returnDisplayDto.senderCompanyName = initialMCBJS020ReturnDisplayDto.senderCompanyName
      }
      //送信元メールアドレス値がない場合、初期値
      if (action.payload.senderMailAddress == null) {
        state.returnDisplayDto.senderMailAddress = initialMCBJS020ReturnDisplayDto.senderMailAddress
      }
      //件名値がない場合、初期値
      if (action.payload.requestSubject == null) {
        state.returnDisplayDto.requestSubject = initialMCBJS020ReturnDisplayDto.requestSubject
      }
      //本文値がない場合、初期値
      if (action.payload.requestText == null) {
        state.returnDisplayDto.requestText = initialMCBJS020ReturnDisplayDto.requestText
      }
      state.returnDisplayDto.homepageTitle1 = state.returnDisplayDto.homepageTitle1 != undefined ? state.returnDisplayDto.homepageTitle1 : ''	//ホームページタイトル1
      state.returnDisplayDto.homepageTitle2 = state.returnDisplayDto.homepageTitle2 != undefined ? state.returnDisplayDto.homepageTitle2 : ''	//ホームページタイトル2
      state.returnDisplayDto.homepageTitle3 = state.returnDisplayDto.homepageTitle3 != undefined ? state.returnDisplayDto.homepageTitle3 : ''	//ホームページタイトル3
      state.returnDisplayDto.homepageUrl1 = state.returnDisplayDto.homepageUrl1 != undefined ? state.returnDisplayDto.homepageUrl1 : ''	//ホームページURL1
      state.returnDisplayDto.homepageUrl2 = state.returnDisplayDto.homepageUrl2 != undefined ? state.returnDisplayDto.homepageUrl2 : ''	//ホームページURL2
      state.returnDisplayDto.homepageUrl3 = state.returnDisplayDto.homepageUrl3 != undefined ? state.returnDisplayDto.homepageUrl3 : ''	//ホームページURL3
      
      //サンクスメール設定値がない場合、初期値を設定する
      if (action.payload.thanksMessageSetFlag == '0' || action.payload.thanksMessageSetFlag == '1') {
        state.returnDisplayDto.thanksMessageSetFlag = action.payload.thanksMessageSetFlag
      } else {
        state.returnDisplayDto.thanksMessageSetFlag = '1'
      }
      // 26P #80795、#88225 start
      state.returnDisplayDto.annual = action.payload.annual
      state.returnDisplayDto.annualList = action.payload.annualList
      state.returnDisplayDto.annualCs = action.payload.annualCs != null ? action.payload.annualCs : ''
      // 26P #80795、#88225 end
      return state
    },
    //「内容を確認する」ボタン押下
    submissionReqTemplateDetailConfirm(state, action: PayloadAction<{ submitData: MCBJS030SubmissionReqTemplateInputRequest, data: addSubmitParams, files: File[] }>) {
      return state
    },
    setDetailPageDialogOpen(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen030 = action.payload
      return state
    },
    editSubmissionReqTemplate(state, action: PayloadAction<MCBJS030InitDisplayRequest>) {
      return state
    },
    //「削除する」ボタン押下
    deletesubmissionReqTemplate(state, action: PayloadAction<MCBJS030DeleteSubmissionReqTemplateRequest>) {
      return state
    },
    getReloadSubmissionsApi(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      state.returnDisplayDto = action.payload
      return state
    },
    setReloadSubmissionsApi(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto.submissionsSelectId = ''
      state.returnDisplayDto.submissionsNameStudent = ''
      state.returnDisplayDto.submissionsName = ''
      state.returnDisplayDto.submissionNames = ''
      let newSubmissionContentList :MCBJS020SubmissionsContentList[] = []
      action.payload.submissionContentList.map(i => {
        if(i.hiddenFlg === false){
          newSubmissionContentList = [...newSubmissionContentList,i]
        }
      })
      state.returnDisplayDto.submissionContentList = newSubmissionContentList
      return state
    },
    getReloadThanksTemplateApi(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      state.returnDisplayDto = action.payload
      return state
    },
    setReloadThanksTemplateApi(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto.thanksMailTemplateContentList = action.payload.thanksMailTemplateContentList
      state.returnDisplayDto.thanksMessageTemplateId = ''
      state.returnDisplayDto.thanksMessageTemplateName = ''
      return state
    },
    getReloadReferenceApi(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      state.returnDisplayDto = action.payload
      return state
    },
    setReloadReferenceApi(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto.studentReferenceId = ''
      state.returnDisplayDto.studentReferenceName = ''
      state.returnDisplayDto.studentReferenceCompanyName = ''  
      state.returnDisplayDto.contactDepartmentNameAndManagerName = ''    
      state.returnDisplayDto.studentReferenceMailAddress = ''        
      state.returnDisplayDto.studentReferenceTelephoneNumber = ''       
      let newContactContentList : MCBJS020ContactList[] = []        
      action.payload.contactContentList.map(i => {
        if(i.hiddenFlg === false){
          newContactContentList = [...newContactContentList,i]
        }
      })      
      state.returnDisplayDto.contactContentList = newContactContentList      
      return state
    },
    getReloadPersonalApi(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>){
      state.returnDisplayDto = action.payload
      return state
    },
    setReloadPersonalApi(state,action: PayloadAction<MCBJS020ReturnDisplayOutDto>) {
      state.returnDisplayDto.personalDataHandlingId = ''
      state.returnDisplayDto.personalDataHandlingName = ''
      let newPrivacyPolicyContentList :MCBJS020MyCareerBoxPrivacyPolicyList[] = []
      action.payload.privacyPolicyContentList.map(i => {
        if(i.hiddenFlg === false){
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList,i]
        }
      })
      state.returnDisplayDto.privacyPolicyContentList = newPrivacyPolicyContentList
      return state
    },
    openMcbApi(state, action:  PayloadAction<MCBJS020LinkResultDto>) {
      return state
    },

    
    getPreview030(state, action: PayloadAction<{formData :MCBJS020SubmitRequestPreviewRequest, 
      setHasDisplayPreview: (value: boolean) => void}>) {
      return state
    },
    setPreview030(state,action: PayloadAction<string>) {
      state.returnBody = action.payload
      return state
    },
    // MCBリプレース MCBJS030 END
  },
})

export const {
  submissionReqTemplateAddInit,
  setSubmissionReqTemplateAddInit,
  setAddPageDialogOpen,
  setPageOpen,
  submissionReqTemplateConfirm,
  setSubmissionReqTemplateConfirm,
  selectSubmissionReqTemplateList,
  setSelectSubmissionReqTemplateList,
  addSubmissionReqTemplateList,
  setAddSubmissionReqTemplateList,
  executeSubmissionReqTemplateList,
  setExecuteSubmissionReqTemplateList,
  copySubmissionReqTemplateList,
  setCopySubmissionReqTemplateList,
  setSubmissionReqTemplateListPage,
  setSubmissionReqTemplateListData,
  setSubmissionReqTemplateListOption,
  setOpenModalMeassageData,
  fetchSubmissionReqTemplateConfirm,
  setSubmissionReqTemplateContentConfirm,
  insertOrUpdateSubmissionReqTemplate,
  setOpenConfirmContentPage,
  setCloseConfirmContentPage,
  setSubmissionReqTemplateDetailInit,
  submissionReqTemplateDetailConfirm,
  setDetailPageDialogOpen,
  editSubmissionReqTemplate,
  submissionReqTemplateDetailInit,
  deletesubmissionReqTemplate,
  setAddSubmit,
  openModalMessage,
  openModalWithArgMessage,
  closeModel,
  closeModelArgs,
  changeDeadlineValue,
  updateSubmissionReqTemplateSort,
  submissionTemplateTargetYearChange,
  setSubmissionTemplateTargetYearChange,
  getReloadSubmissions,
  setReloadSubmissions,
  getReloadThanksTemplate,
  setReloadThanksTemplate,
  getReloadReference,
  setReloadReference,
  getReloadPersonal,
  setReloadPersonal,
  getReloadSubmissionsApi,
  setReloadSubmissionsApi,
  getReloadThanksTemplateApi,
  setReloadThanksTemplateApi,
  getReloadReferenceApi,
  setReloadReferenceApi,
  getReloadPersonalApi,
  setReloadPersonalApi,
  openApi,
  openMcbApi,
  getPreview,
  setPreview,
  getPreview030,
  setPreview030
} = submissionReqTemplateSlice.actions
export default submissionReqTemplateSlice.reducer