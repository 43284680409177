import request from 'utils/request'
import { MCBHS050InitRequest } from 'types/MCBHS050/MCBHS050InitRequest'
import { MCBHS050DownloadFileRequest } from 'types/MCBHS050/MCBHS050DownloadFileRequest'
import { MCBHS050DownloadCsvRequest } from 'types/MCBHS050/MCBHS050DownloadCsvRequest'

export const searchSubmissionRequestMessageDetailRequest = (apiData: MCBHS050InitRequest) => {
  return request({
    url: '/MCBHS050/init',
    method: 'post',
    data: apiData,
  })
}

export const attachmentFileDownloadRequest = (apiData: MCBHS050DownloadFileRequest) =>
  request({
    url: '/MCBHS050/AttachmentFileLink',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })

export const destinationDownloadRequest = (apiData: MCBHS050DownloadCsvRequest) =>
  request({
    url: '/MCBHS050/destinationDownloadExecute',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })