export interface SearchCondition {
  applicantId: string[] | null
  name: string[] | null
  ageFrom: string | null
  ageTo: string | null
  sexObj: SexObj[] | null
  mailAddress: string | null
  spouseObj: SpouseObj[] | null
  residenceObj: ResidenceObj[] | null
  finalEducationUniversityObj: FinalEducationUniversityObj[] | null
  educationalBackground: string[] | null
  languageSkills: string[] | null
  eligibility: string[] | null
  experienceCountObj: ExperienceCountObj[] | null
  employmentSituationObj: EmploymentSituationObj[] | null
  preferredWorkplaceObj: PreferredWorkplaceObj[] | null
  jobCareer: string[] | null
  experiencedJob: string[] | null
  wholeResume: string[] | null
  applicationFrom: string | null
  applicationTo: string | null
  memberTypeObj: MemberTypeObj[] | null
  applicationRouteObj: ApplicationRouteObj[] | null
  postingStartTime: string | null
  postingEndTime: string | null
  applicationTypeObj: ApplicationTypeObj | null
  questionnaireObj: QuestionnaireObj | null
  entryTypeObj: EntryTypeObj[] | null
  entryClassificationObj: EntryClassificationObj[] | null
  scoutBenefitsObj: ScoutBenefitsObj[] | null
  exemptionObj: ExemptionObj | null
  unreadObj: UnreadObj | null
  duplicationObj: DuplicationObj | null
  multipleObj: MultipleObj | null
  selectionFlowObj: SelectionFlowObj[] | null
  selectionStepObj: SelectionStepObj[] | null
  judgmentUndeterminedObj: JudgmentUndeterminedObj[] | null
  managementItemObj: ManagementItemObj | null
  priorityFrom: string | null
  priorityTo: string | null
  tagTxtObj: TagTxtObj[] | null
  //タグ条件判定FLG
  tagJudgmentFlag: string | null
  //タグ条件判定名
  tagJudgmentName: string | null
  // タグ除外
  tagExclusionTxtObj: TagExclusionTxtObj[] | null
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: string | null
  // タグ除外条件判定名
  tagExclusionJudgmentName: string | null
  // #58578 次期開発2022年5月 start
  opqObj: OPQObj[] | null
  gabObj: GABObj[] | null
  imagesObj: ImagesObj[] | null
  cabObj: CABObj[] | null
  // #58578 次期開発2022年5月 end
}

// #58578 次期開発2022年5月 start
export interface OPQObj {
  opqLogicalName: string
  opqPhysicalName: string
  opqValueFrom: string | null
  opqValueTo: string | null
  opqTyp: string
  opqTypName: string
}
export interface GABObj {
  gabLogicalName: string
  gabPhysicalName: string
  gabListFlag: string | null
  gabListName: string | null
  gabValueFrom: string | null
  gabValueTo: string | null
  gabTyp: string
  gabTypName: string
}
export interface ImagesObj {
  imagesLogicalName: string
  imagesPhysicalName: string
  imagesValueFrom: string | null
  imagesValueTo: string | null
}
export interface CABObj {
  cabLogicalName: string
  cabPhysicalName: string
  cabListFlag: string | null
  cabListName: string | null
  cabValueFrom: string | null
  cabValueTo: string | null
  cabTyp: string
  cabTypName: string
}
// #58578 次期開発2022年5月 end

export interface SexObj {
  sexCode: string
  sexName: string
}

export interface SpouseObj {
  spouseCode: string
  spouseName: string
}

export interface ResidenceObj {
  residencePrefecturesCode: string
  residencePrefecturesName: string
}

export interface FinalEducationUniversityObj {
  finalEducationUniversityCode: string
  finalEducationUniversityName: string
}

export interface ExperienceCountObj {
  experienceCountCode: string
  experienceCountName: string
}

export interface EmploymentSituationObj {
  employmentSituationCode: string
  employmentSituationName: string
}

export interface PreferredWorkplaceObj {
  preferredWorkplacePrefecturesCode: string
  preferredWorkplacePrefecturesName: string
}

export interface MemberTypeObj {
  memberTypeCode: string
  memberTypeName: string
}

export interface ApplicationRouteObj {
  applicationRouteFlag: number
  applicationRouteId: string
  applicationRouteName: string | null
}

export interface ApplicationTypeObj {
  jobId: string | null
  applicationTypeId: string
  applicationTypeName: string | null
}

export interface QuestionnaireObj {
  questionnaireConditionsJudgmentFlag: number
  questionnaireConditionsJudgmentName: string
  questionnaireConditionsObj: QuestionnaireConditionsObj[]
}

export interface QuestionnaireConditionsObj {
  questionNumber: string
  questionTitle: string
  questionOption?: string
  answerConditionFlag: number
  answerConditionName: string
  questionTypeCode: string
  questionTypeName: string
  answerText: string
  answerCheckBoxObj: AnswerCheckBoxObj[]
}

export interface AnswerCheckBoxObj {
  answerCheckBoxId: string
  answerCheckBoxName: string
}

export interface EntryTypeObj {
  entryTypeCode: string
  entryTypeName: string
}

export interface EntryClassificationObj {
  entryClassificationCode: string
  entryClassificationName: string
}

export interface ScoutBenefitsObj {
  scoutBenefitsCode: string
  scoutBenefitsName: string
}

export interface ExemptionObj {
  exemptionCode: string
  exemptionName: string
}

export interface UnreadObj {
  unreadCode: string
  unreadName: string
}

export interface DuplicationObj {
  duplicationFlag: string
  duplicationName: string
}

export interface MultipleObj {
  multipleFlag: string
  multipleName: string
}

export interface SelectionFlowObj {
  selectionFlowId: string
  selectionFlowName: string | null
}

export interface SelectionStepObj {
  selectionStepId: string
  selectionStepName: string | null
}

export interface JudgmentUndeterminedObj {
  judgmentUndeterminedId: string
  judgmentUndeterminedName: string
}

export interface ManagementItemObj {
  managementItemJudgmentFlag: string
  managementItemJudgmentName: string
  managementItemConditionsObj: ManagementItemConditionsObj[]
}

export interface ManagementItemConditionsObj {
  recruitmentManagementFlagSettingId: string
  recruitmentManagementFlagName: string
  managementItemPresenceFlag: number
  managementItemPresenceName: string
  managementItemTypeCode: string
  managementItemTypeName: string
  managementItemTagText: string
  managementItemTagFrom: string
  managementItemFlagTo: string
  managementItemFlagCheckBoxObj: ManagementItemFlagCheckBoxObj[]
}

export interface ManagementItemFlagCheckBoxObj {
  managementItemFlagCheckBoxId: string
  managementItemFlagCheckBoxName: string
}

export interface TagTxtObj {
  tagSettingId: string
  tagName: string
}

// タグ除外
export interface TagExclusionTxtObj {
  tagExclusionSettingId: string
  tagExclusionName: string
}


export const initSearchCondition: SearchCondition = {
  applicantId: null,
  name: null,
  ageFrom: null,
  ageTo: null,
  sexObj: null,
  mailAddress: null,
  spouseObj: null,
  residenceObj: null,
  finalEducationUniversityObj: null,
  educationalBackground: null,
  languageSkills: null,
  eligibility: null,
  experienceCountObj: null,
  employmentSituationObj: null,
  preferredWorkplaceObj: null,
  jobCareer: null,
  experiencedJob: null,
  wholeResume: null,
  applicationFrom: null,
  applicationTo: null,
  memberTypeObj: null,
  applicationRouteObj: null,
  postingStartTime: null,
  postingEndTime: null,
  applicationTypeObj: null,
  questionnaireObj: null,
  entryTypeObj: null,
  entryClassificationObj: null,
  scoutBenefitsObj: null,
  exemptionObj: null,
  unreadObj: null,
  duplicationObj: null,
  multipleObj: null,
  selectionFlowObj: null,
  selectionStepObj: null,
  judgmentUndeterminedObj: null,
  managementItemObj: null,
  priorityFrom: null,
  priorityTo: null,
  tagTxtObj: null,
  tagJudgmentFlag: null,
  tagJudgmentName: null,
  tagExclusionTxtObj: null,
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: null,
  // タグ除外条件判定名
  tagExclusionJudgmentName: null,
  // #58578 次期開発2022年5月 start
  opqObj: null,
  gabObj: null,
  imagesObj: null,
  cabObj: null,
  // #58578 次期開発2022年5月 end
}
