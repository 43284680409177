import { Button, colors, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormLabel, List, ListItem, ListItemText, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { FormControl } from '..'
import CustomScrollbar from 'react-custom-scrollbars'
import { SeminarLevel2, SeminarLevel3 } from '../Config/webInterviewParticipationModalConfig'
import SubTitle from 'componentsHsc/SubTitle'
import FieldGroup from 'componentsHsc/FieldGroup'
import { dateToString, dateTimeToString, isEmpty } from 'common/generalUtil'
import { openModal } from 'reducers/messageReducer'
import { WebInterviewParticipationIndividualObj, WebInterviewParticipationIndividualObj2, WebInterviewParticipationObj, WebInterviewParticipationWhichOneObj } from '../Config/searchConditionConfig'
import { getMcaxs621Init } from 'reducers/advancedSearchModalReducer'
import { getMessage } from 'common/messageUtil'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor: 'auto',
  },
  content2: {
    margin: theme.spacing(1, 2),
  },
  divider: {
    margin: theme.spacing(1, 0),
    backgroundColor: 'lightgrey',
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    cursor: 'auto',
  },
  selectAll: {
    '& div': {
      display: 'inline-block',
      margin: '8px 20px 0px 0px',
      cursor: 'pointer',
    },
    '& div input': {
      marginRight: '5px',
      cursor: 'pointer',
    }
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 1,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span.MuiFormControlLabel-label': {
      color: colors.grey[600],
    },
    height: 'auto'
  },
  subContent: {
    margin: theme.spacing(2),
    '& .Mui-selected': {
      backgroundColor: 'transparent !important',
      color: '#1950a6',
      textDecoration: 'underline',
      '& span': {
        color: '#1950a6',
      },
    },
    height: '100%',
  },
  seminarName: {
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  seminarName2: {
    '& > div > :first-child': {
      overflowX: 'hidden !important',
    }
  },
  containerOfForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitleOfForm: {
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  selectAllOfForm: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
    '& div': {
      display: 'inline-block',
      margin: '0px 20px 0px 0px',
      cursor: 'pointer',
      fontWeight: 400,
      fontSize: '14px',
    },
    '& div input': {
      marginRight: '5px',
      cursor: 'pointer',
    }
  },
  content: {
    margin: theme.spacing(1, 2),
    '& div': {
      display: 'inline-block',
      margin: '0px 20px 0px 0px',
      cursor: 'pointer',
    },
    '& div input': {
      marginRight: '5px',
      cursor: 'pointer',
    }
  },
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '20px !important',
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

interface RdoSeleDate {
  [key: string]: string
  dateId: string
  rdoVal: string
}

interface SeleDateOfForm {
  [key: string]: string | any
  value: string
  coordinate: {
    index1: number
    index2: number
  }
}

const WebInterviewParticipationModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirm, setConfirm] = useState(false)

  const [interviewSelect, setInterviewSelect] = useState('') // 「いずれかの日程」と「各受付ごと」のラジオボタン
  const [whichOne, setWhichOne] = useState('') // 「いずれかの日程」の受付状況チェック
  const [individual, setIndividual] = useState('0') // 「各受付ごと」の日付間の検索方法 ANR/OR
  const [rdoDateSelect, setRdoDateSelect] = useState<RdoSeleDate[]>([]) // 「各受付ごと」の受付状況チェック
  const [selectedSub, setSelectedSub] = useState('') // 説明会・面接名リストクリック時に画面のスクロール
  const [selectAllFlag, setSelectAllFlag] = useState('') // 「各受付ごと」の一括チェック
  const [selectAllFlagOfForm, setSelectAllFlagOfForm] = useState<SeleDateOfForm[]>([]) // フォームIDごとに一括チェック

  const { seminarList } = useSelector((state: RootState) => state.advancedSearchModal.webInterviewParticipationModalInfo)

  useEffect(() => {
    dispatch(getMcaxs621Init())
  }, [open])

  useEffect(() => {
    if (open) {
      setSelectedSub('')
      if (form.values.webInterviewParticipationObj) {
        setInterviewSelect(form.values.webInterviewParticipationObj.webInterviewParticipationConditionJudgmentFlag.toString())
        if (form.values.webInterviewParticipationObj.webInterviewParticipationConditionJudgmentFlag.toString() === '0') {
          setRdoDateSelect([])
          setSelectAllFlag('')
          if (form.values.webInterviewParticipationObj.webInterviewParticipationWhichOneObj) {
            let whichoneObj = form.values.webInterviewParticipationObj.webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneFlag !== null
              ? form.values.webInterviewParticipationObj.webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneFlag.toString() : ''
            setWhichOne(whichoneObj)
          }
        } else if (form.values.webInterviewParticipationObj.webInterviewParticipationConditionJudgmentFlag.toString() === '1') {
          setWhichOne('')
          if (form.values.webInterviewParticipationObj.webInterviewParticipationIndividualObj) {
            const individualObj = form.values.webInterviewParticipationObj.webInterviewParticipationIndividualObj
            let individualFlg = individualObj.webInterviewParticipationIndividualJudgmentFlag !== null
              ? individualObj.webInterviewParticipationIndividualJudgmentFlag.toString() : ''
            setIndividual(individualFlg)
            if (individualObj.webInterviewParticipationIndividualObj2) {
              let flglst2: RdoSeleDate[] = []
              const rdoValSortList: string[] = []
              const individualObj2 = individualObj.webInterviewParticipationIndividualObj2
              let seminarCount: number = 0

              seminarList.map(item => {
                item.seminarLevel2Dto.map(item1 => {
                  item1.seminarLevel3Dto.map(item2 => {
                    seminarCount++;
                    individualObj2.map(info => {
                      if (item2.seminarScheduleId.toString() === info.webInterviewParticipationIndividualSeminarScheduleId.toString()) {
                        flglst2.push({
                          dateId: info.webInterviewParticipationIndividualSeminarScheduleId,
                          rdoVal: info.webInterviewParticipationIndividualFlag.toString()
                        })
                        rdoValSortList.push(info.webInterviewParticipationIndividualFlag.toString())
                      }
                    })
                  })
                })
              })

              rdoValSortList.sort()
              if (rdoValSortList[0] === rdoValSortList[rdoValSortList.length - 1] && rdoValSortList.length == seminarCount) {
                setSelectAllFlag(rdoValSortList[0])
              } else {
                setSelectAllFlag('')
              }
              setRdoDateSelect(flglst2)
            }
          }
        }
      } else {
        setInterviewSelect('')
        setWhichOne('')
        setIndividual('0')
        setSelectAllFlag('')
        setRdoDateSelect([])
        setSelectAllFlagOfForm([])
      }
    }
  }, [open])

  // "説明会・面接名リスト"選択時
  const handleSubClick = (index1: number, index2: number) => () => {
    setSelectedSub(index1 + '_' + index2)
    const toTop = document.getElementById(`sel_${index1}_${index2}`)
    if (toTop) {
      toTop.scrollIntoView()
    }
  }

  // "説明会・面接名"の作成
  const createSeminarName = (seminar: SeminarLevel2) => {
    let result = seminar.coordinationFormerSeminarId + ' ' + seminar.seminarName
    if (seminar.publicFlag === '0') {
      result += ' 【' + seminar.publicName + '】'
    }
    return result
  }

  // 受付予約ラジオボタンをクリック
  const handleInterviewSelect = (e: any) => {
    if ('0' === e.target.value) {
      setRdoDateSelect([])
      setIndividual('')
      setSelectAllFlag('')
      setSelectAllFlagOfForm([])
    } else if ('1' === e.target.value) {
      setWhichOne('')
      setIndividual('0')
    }
    setInterviewSelect(e.target.value)
  }

  // 受付状況ラジオボタン（いずれかの日程）をクリック
  const handleWhichOne = (e: any) => {
    const whichOneObj = e.target.value === whichOne ? '' : e.target.value
    setWhichOne(whichOneObj)
    // 受付状況ラジオボタン（いずれかの日程）の場合 
    // 受付予約ラジオボタンの"いずれかの日程"をチェックありにする。
    setInterviewSelect('0')
    setRdoDateSelect([])
    // ANR/ORの設定
    setIndividual('')
    // 「各受付ごと」の一括チェック：選択しない
    setSelectAllFlag('')
    setSelectAllFlagOfForm([])
  }

  // 予約日程検索方法ラジオボタンをクリック
  const handleIndividual = (e: any) => {
    setIndividual(e.target.value)
  }

  // 一括操作ラジオボタンをクリック
  const handleSelectAll2 = async (e: string) => {
    await handleSelectAll(e)
    let tempDataOfForm = [...selectAllFlagOfForm]
    seminarList.map((item: any, index1: number) => {
      item.seminarLevel2Dto.map((item1: any, index2: number) => {
        let doms = document.getElementsByName(`selectSearch5[${index1.toString()}_${index2.toString()}]`)
        let tempValue = ''
        if (doms) {
          for (let i = 0; i < doms.length; i++) {
            if ((doms[i] as any).checked) {
              tempValue = i.toString()
            }
          }
          let tempIndex = tempDataOfForm.findIndex(item2 => item2.coordinate.index1 == index1 && item2.coordinate.index2 == index2)
          if (tempIndex != -1) {
            tempDataOfForm[tempIndex].value = tempValue
          } else {
            tempDataOfForm.push({ value: tempValue, coordinate: { index1: index1, index2: index2 } })
          }

        }
      })
    })
    setSelectAllFlagOfForm(tempDataOfForm)
  }

  // 一括操作ラジオボタンをクリック、すべての「受付状況ラジオボタン」をクリック
  const handleSelectAll = (e: string) => {
    const selectValue = e === selectAllFlag ? '' : e
    let tmpval2: RdoSeleDate[] = []
    seminarList.map(item => {
      item.seminarLevel2Dto.map(item1 => {
        item1.seminarLevel3Dto.map(item2 => {
          tmpval2.push({ dateId: item2.seminarScheduleId, rdoVal: selectValue })
        })
      })
    })
    setSelectAllFlag(selectValue)
    setRdoDateSelect(tmpval2)
    // 「各受付ごと」選択する
    setInterviewSelect('1')
    //「いずれかの日程」の受付状況チェック：選択しない
    setWhichOne('')
    individual ? setIndividual(individual) : setIndividual('0')
  }

  // 受付状況ラジオボタン（説明会・面接名ごと）をクリック
  const handleSelectAllOfForm = (e: string, seminarId: string, index1: number, index2: number) => {
    // 操作記録
    let selectValue = ''
    let tempSelectAllFlagOfForm = [...selectAllFlagOfForm]
    let index = tempSelectAllFlagOfForm.findIndex(item => item.coordinate.index1 == index1 && item.coordinate.index2 == index2)
    if (index == -1) {
      selectValue = e
      let tempSeleDataOfForm: SeleDateOfForm = { coordinate: { index1: index1, index2: index2 }, value: selectValue }
      setSelectAllFlagOfForm(tempSelectAllFlagOfForm.concat([tempSeleDataOfForm]))
    } else {
      selectValue = e == selectAllFlagOfForm[index].value ? '' : e
      let tempSeleDataOfForm: SeleDateOfForm = { coordinate: { index1: index1, index2: index2 }, value: selectValue }
      tempSelectAllFlagOfForm[index] = tempSeleDataOfForm
      setSelectAllFlagOfForm(tempSelectAllFlagOfForm)
    }
    // データ保持
    let tmpval2: RdoSeleDate[] = []
    seminarList.map(item => {
      item.seminarLevel2Dto.filter(i => i.seminarId == seminarId).map(item1 => {
        item1.seminarLevel3Dto.map(item2 => {
          tmpval2.push({ dateId: item2.seminarScheduleId, rdoVal: selectValue })
        })
      })
    })
    let tempRdoDateSelect = [...rdoDateSelect]
    let addTempRdoDateSelect: RdoSeleDate[] = []
    tmpval2.forEach(i => {
      let tempIndex = tempRdoDateSelect.findIndex(item => item.dateId == i.dateId)
      if (tempIndex == -1) {
        addTempRdoDateSelect.push(i)
      } else {
        tempRdoDateSelect[tempIndex] = i
      }
    })
    let result = tempRdoDateSelect.concat(addTempRdoDateSelect)
    // 全チェック判定
    let count = 0
    seminarList.map(item => {
      item.seminarLevel2Dto.map(item1 => {
        item1.seminarLevel3Dto.map(item2 => {
          count++
        })
      })
    })
    const rdoValSortList: string[] = []
    result.forEach(i => {
      rdoValSortList.push(i.rdoVal)
    })
    rdoValSortList.sort()
    if (rdoValSortList.length === count && rdoValSortList[0] === rdoValSortList[rdoValSortList.length - 1]) {
      setSelectAllFlag(rdoValSortList[0])
    } else {
      setSelectAllFlag('')
    }
    setRdoDateSelect(result)
    // 「各受付ごと」選択する
    setInterviewSelect('1')
    //「いずれかの日程」の受付状況チェック：選択しない
    setWhichOne('')
    individual ? setIndividual(individual) : setIndividual('0')
  }

  // 受付状況ラジオボタン（説明会・面接名ごと）のチェック
  const rdoCheckedOfForm = (e: string, seminar: SeminarLevel2) => {
    return seminar.seminarLevel3Dto.every(item => {
      let temp = rdoDateSelect.filter(item2 => item2.dateId == item.seminarScheduleId && item2.rdoVal == e)
      if (temp && temp.length > 0) {
        return true
      } else {
        return false
      }
    })
  }

  // セミナー詳細名を作成
  const createSeminarScheduleName = (seminar: SeminarLevel3) => {
    // 連携された対象セミナー情報の開催日がNULL(開催日テキストの指定)の場合に、開催日の代わりに開催日テキストの内容を表示する
    let result = ''
    let eventDate = dateToString(seminar.eventDate)
    result += seminar.seminarScheduleTitle
    if (seminar.invisibleFlag === '1') {
      result += ' 【' + seminar.invisibleName + '】'
    }
    if (isEmpty(eventDate)) {
      result += !isEmpty(seminar.eventDateText) ? ' ' + seminar.eventDateText : ''
    } else {
      result += ' ' + dateToString(seminar.eventDate)
      let startTime = dateTimeToString(seminar.startTime)
      let endTime = dateTimeToString(seminar.endTime)
      if (startTime || endTime) {
        result += ' '
        if (startTime) {
          result += startTime.substring(11)
        }
        result += '～'
        if (endTime) {
          result += endTime.substring(11)
        }
      }
    }
    result += ' ' + 'WEB面談'
    return result
  }

  // 受付状況ラジオボタン（各受付ごと）をクリック
  const handleRdoDateSelect2 = async (seminar: SeminarLevel3, values: string, index1: number, index2: number) => {
    await handleRdoDateSelect(seminar, values, index1, index2)
    let doms = document.getElementsByName(`selectSearch5[${index1}_${index2}]`)
    let tempDataOfForm = [...selectAllFlagOfForm]
    let tempValue = ''
    if (doms) {
      for (let i = 0; i < doms.length; i++) {
        if ((doms[i] as any).checked) {
          tempValue = i.toString()
        }
      }
      let tempIndex = tempDataOfForm.findIndex(item => item.coordinate.index1 == index1 && item.coordinate.index2 == index2)
      if (tempIndex != -1) {
        tempDataOfForm[tempIndex].value = tempValue
      } else {
        tempDataOfForm.push({ value: tempValue, coordinate: { index1: index1, index2: index2 } })
      }
      setSelectAllFlagOfForm(tempDataOfForm)
    }
  }

  // 受付状況ラジオボタン（各受付ごと）をクリック、別の「受付状況ラジオボタン」チェックかどうかを判定する
  const handleRdoDateSelect = (seminar: SeminarLevel3, values: string, index1: number, index2: number) => {
    let rdoSeleData = rdoDateSelect.find(item => {
      return item.dateId.toString() === seminar.seminarScheduleId.toString()
    })
    let tmpval2: RdoSeleDate[] = []
    rdoDateSelect.map(info => {
      if (info.dateId.toString() === seminar.seminarScheduleId.toString()) {
        tmpval2.push({ dateId: seminar.seminarScheduleId, rdoVal: rdoSeleData && rdoSeleData.rdoVal === values ? '' : values })
      } else {
        tmpval2.push(info)
      }
    })
    if (!rdoSeleData) {
      tmpval2.push({ dateId: seminar.seminarScheduleId, rdoVal: values })
    }
    let count = 0
    seminarList.map(item => {
      item.seminarLevel2Dto.map(item1 => {
        item1.seminarLevel3Dto.map(item2 => {
          count++
        })
      })
    })
    const rdoValSortList: string[] = []
    tmpval2.forEach(i => {
      rdoValSortList.push(i.rdoVal)
    })
    rdoValSortList.sort()
    if (rdoValSortList.length === count && rdoValSortList[0] === rdoValSortList[rdoValSortList.length - 1]) {
      setSelectAllFlag(rdoValSortList[0])
    } else {
      setSelectAllFlag('')
    }
    setRdoDateSelect(tmpval2)
    // 「各受付ごと」選択する
    setInterviewSelect('1')
    //「いずれかの日程」の受付状況チェック：選択しない
    setWhichOne('')
    individual ? setIndividual(individual) : setIndividual('0')
  }

  // 受付状況ラジオボタン（各受付ごと）「参加あり」をクリック
  const rdoCheckedZero = (seminar: SeminarLevel3) => {
    let rdoSeleData = rdoDateSelect.find(item => {
      return item.dateId.toString() === seminar.seminarScheduleId.toString()
    })
    if (rdoSeleData) {
      if (rdoSeleData.rdoVal === "0") {
        return true
      }
    }
    return false
  }

  // 受付状況ラジオボタン（各受付ごと）「参加なし」をクリック
  const rdoCheckedOne = (seminar: SeminarLevel3) => {
    let rdoSeleData = rdoDateSelect.find(item => {
      return item.dateId.toString() === seminar.seminarScheduleId.toString()
    })
    if (rdoSeleData) {
      if (rdoSeleData.rdoVal === "1") {
        return true
      }
    }
    return false
  }

  // 受付状況ラジオボタン（各受付ごと）「キャンセル」をクリック
  const rdoCheckedTwo = (seminar: SeminarLevel3) => {
    let rdoSeleData = rdoDateSelect.find(item => {
      return item.dateId.toString() === seminar.seminarScheduleId.toString()
    })
    if (rdoSeleData) {
      if (rdoSeleData.rdoVal === "2") {
        return true
      }
    }
    return false
  }

  const handleClick = (open = true) => () => {
    setConfirm(open)
  }
  const handleSubmit = () => {
    let webInterviewParticipationObj: WebInterviewParticipationObj = {
      webInterviewParticipationConditionJudgmentFlag: 1,
      webInterviewParticipationConditionJudgmentName: '',
      webInterviewParticipationWhichOneObj: null,
      webInterviewParticipationIndividualObj: null,
    }

    var searchValue: any = document.getElementsByName('selectSearch')
    if (searchValue) {
      if (searchValue[0].checked) {
        webInterviewParticipationObj.webInterviewParticipationConditionJudgmentFlag = 0
        webInterviewParticipationObj.webInterviewParticipationConditionJudgmentName = 'いずれかの日程'
        let webInterviewParticipationWhichOneObj: WebInterviewParticipationWhichOneObj = {
          webInterviewParticipationWhichOneFlag: 0,
          webInterviewParticipationWhichOneName: '',
        }
        var searchValue1: any = document.getElementsByName('selectSearch1')
        if (searchValue1) {
          if (searchValue1[0].checked) {
            webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneFlag = 0
            webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneName = '参加あり'
          } else if (searchValue1[1].checked) {
            webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneFlag = 1
            webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneName = '参加なし'
          } else if (searchValue1[2].checked) {
            webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneFlag = 2
            webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneName = 'キャンセル'
          }
          if (webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneFlag == 0
            && webInterviewParticipationWhichOneObj.webInterviewParticipationWhichOneName == '') {
            dispatch(openModal(getMessage('MCAXS621-002')))
            return
          }
          webInterviewParticipationObj.webInterviewParticipationWhichOneObj = webInterviewParticipationWhichOneObj
        }
      } else if (searchValue[1].checked) {
        webInterviewParticipationObj.webInterviewParticipationConditionJudgmentFlag = 1
        webInterviewParticipationObj.webInterviewParticipationConditionJudgmentName = '各受付ごと'
        var searchValue2: any = document.getElementsByName('selectSearch2')
        if (searchValue2) {
          let webInterviewParticipationIndividualObj: WebInterviewParticipationIndividualObj = {
            webInterviewParticipationIndividualJudgmentFlag: 0,
            webInterviewParticipationIndividualJudgmentName: '',
            webInterviewParticipationIndividualObj2: [],
          }
          if (searchValue2[0].checked) {
            webInterviewParticipationIndividualObj.webInterviewParticipationIndividualJudgmentFlag = 0
            webInterviewParticipationIndividualObj.webInterviewParticipationIndividualJudgmentName = 'AND'
          } else if (searchValue2[1].checked) {
            webInterviewParticipationIndividualObj.webInterviewParticipationIndividualJudgmentFlag = 1
            webInterviewParticipationIndividualObj.webInterviewParticipationIndividualJudgmentName = 'OR'
          }

          seminarList.map((item, index) => {
            item.seminarLevel2Dto.map((item1, index1) => {
              item1.seminarLevel3Dto.map((item2, index2) => {
                var searchValue3: any = document.getElementsByName(`selectSearch3[${index}_${index1}_${index2}]`)
                if (searchValue3) {
                  //開始時刻、終了時刻の文字列作成
                  let startTime = dateTimeToString(item2.startTime)
                  let endTime = dateTimeToString(item2.endTime)
                  if (startTime) {
                    startTime = startTime.substring(11)
                  }
                  if (endTime) {
                    endTime = endTime.substring(11)
                  }

                  let webInterviewParticipationIndividualObj2: WebInterviewParticipationIndividualObj2 = {
                    webInterviewParticipationIndividualApplicationRouteId: item2.contractMediaId,
                    webInterviewParticipationIndividualApplicationRouteName: item2.contractMediaName,
                    webInterviewParticipationIndividualCoordinationFormerSeminarId: item2.coordinationFormerSeminarId,
                    webInterviewParticipationIndividualSeminarId: item2.seminarId,
                    webInterviewParticipationIndividualSeminarName: item2.seminarName,
                    webInterviewParticipationIndividualPublicFlag: item2.publicFlag,
                    webInterviewParticipationIndividualPublicName: item2.publicName,
                    webInterviewParticipationIndividualSeminarScheduleId: item2.seminarScheduleId,
                    webInterviewParticipationIndividualSeminarScheduleTitle: item2.seminarScheduleTitle,
                    webInterviewParticipationIndividualInvisibleFlag: item2.invisibleFlag,
                    webInterviewParticipationIndividualInvisibleName: item2.invisibleName,
                    webInterviewParticipationIndividualEventDate: item2.eventDate ? dateToString(item2.eventDate) : '',
                    webInterviewParticipationIndividualStartTime: startTime ? startTime : '',
                    webInterviewParticipationIndividualEndTime: endTime ? endTime : '',
                    webInterviewParticipationIndividualVenueLocationCode: item2.venueLocationCode,
                    webInterviewParticipationIndividualVenueLocationName: item2.venueLocationName,
                    webInterviewParticipationIndividualFlag: 0,
                    webInterviewParticipationIndividualName: '',
                    webInterviewParticipationIndividualEventDateText: item2.eventDateText,
                  }

                  if (searchValue3[0].checked) {
                    webInterviewParticipationIndividualObj2.webInterviewParticipationIndividualFlag = 0
                    webInterviewParticipationIndividualObj2.webInterviewParticipationIndividualName = '参加あり'
                    webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2.push(webInterviewParticipationIndividualObj2)
                  } else if (searchValue3[1].checked) {
                    webInterviewParticipationIndividualObj2.webInterviewParticipationIndividualFlag = 1
                    webInterviewParticipationIndividualObj2.webInterviewParticipationIndividualName = '参加なし'
                    webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2.push(webInterviewParticipationIndividualObj2)
                  } else if (searchValue3[2].checked) {
                    webInterviewParticipationIndividualObj2.webInterviewParticipationIndividualFlag = 2
                    webInterviewParticipationIndividualObj2.webInterviewParticipationIndividualName = 'キャンセル'
                    webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2.push(webInterviewParticipationIndividualObj2)
                  }
                }
              })
            })
          })

          if (webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2.length == 0) {
            dispatch(openModal(getMessage('MCAXS621-002')))
            return
          }
          webInterviewParticipationObj.webInterviewParticipationIndividualObj = webInterviewParticipationIndividualObj
        }
      } else {
        dispatch(openModal(getMessage('MCAXS621-002')))
        return
      }
    }
    if (webInterviewParticipationObj.webInterviewParticipationIndividualObj !== null
      || webInterviewParticipationObj.webInterviewParticipationWhichOneObj !== null) {
      form.setFieldValue('webInterviewParticipationObj', webInterviewParticipationObj)
    }
    handleClose()
  }

  const handleClose = () => {
    setConfirm(false)
    onClose()
  }

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>Web面談参加状況を指定</label>
        </DialogTitle>
        <DialogContent>
          <RadioGroup>
            <FormControlLabel
              control={
                <Radio
                  color='primary'
                  value='0'
                  name='selectSearch'
                  onChange={handleInterviewSelect}
                  checked={'0' === interviewSelect}
                />
              }
              label='いずれかの日程'
            />
            <RadioGroup row className={classes.content2}>
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='0'
                    name='selectSearch1'
                    onClick={handleWhichOne}
                    checked={'0' === whichOne}
                  />
                }
                label='参加あり'
              />
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='1'
                    name='selectSearch1'
                    onClick={handleWhichOne}
                    checked={'1' === whichOne}
                  />
                }
                label='参加なし'
              />
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='2'
                    name='selectSearch1'
                    onClick={handleWhichOne}
                    checked={'2' === whichOne}
                  />
                }
                label='キャンセル'
              />
            </RadioGroup>
            <Divider className={classes.divider} />
            <FormControlLabel
              control={
                <Radio
                  color='primary'
                  value='1'
                  name='selectSearch'
                  onChange={handleInterviewSelect}
                  checked={'1' === interviewSelect}
                />
              }
              label='各受付ごと'
            />
            <div className={classes.subTitle}>
              <RadioGroup row>
                <FormLabel className={classes.labelTitle}>日付間の検索方法</FormLabel>
                <FormControlLabel
                  control={
                    <Radio
                      color='primary'
                      value='0'
                      name='selectSearch2'
                      onChange={handleIndividual}
                      checked={'0' === individual}
                    />
                  }
                  label='AND'
                />
                <FormControlLabel
                  control={
                    <Radio
                      color='primary'
                      value='1'
                      name='selectSearch2'
                      onChange={handleIndividual}
                      checked={'1' === individual}
                    />
                  }
                  label='OR'
                />
              </RadioGroup>
              <RadioGroup row className={classes.selectAll}>
                <FormLabel className={classes.labelTitle}>一括チェック</FormLabel>
                <div onClick={() => handleSelectAll2("0")}>
                  <input type="radio" name='selectSearch4' value='0' checked={'0' === selectAllFlag} />参加あり
                </div>
                <div onClick={() => handleSelectAll2("1")}>
                  <input type="radio" name='selectSearch4' value='1' checked={'1' === selectAllFlag} />参加なし
                </div>
                <div onClick={() => handleSelectAll2("2")}>
                  <input type="radio" name='selectSearch4' value='2' checked={'2' === selectAllFlag} />キャンセル
                </div>
              </RadioGroup>
            </div>
            <div className={classes.select}>
              <div className={classes.subContent}>
                <CustomScrollbar autoHeight={true} autoHeightMin={0} autoHeightMax={500} className={classes.seminarName}>
                  {seminarList ? seminarList.map((seminar1, index1) => (
                    <div>
                      <div>{seminar1.contractMediaName}</div>
                      {seminar1.seminarLevel2Dto.map((seminar2, index2) => (
                        <>
                          <List>
                            <ListItem
                              button
                              selected={selectedSub === `${index1}_${index2}`}
                              onClick={handleSubClick(index1, index2)}>
                              <ListItemText
                                primary={createSeminarName(seminar2)}
                              />
                            </ListItem>
                          </List>
                        </>
                      ))}
                    </div>
                  )) : null}
                </CustomScrollbar>
              </div>
              <div style={{ height: '100%' }} className={`${classes.seminarName} ${classes.seminarName2}`}>
                <CustomScrollbar autoHeight={true} autoHeightMax={500}>
                  {seminarList ? seminarList.map((seminar1, index1) => (
                    <>
                      <SubTitle variant='h4'>
                        {seminar1.contractMediaName}
                      </SubTitle>
                      {seminar1.seminarLevel2Dto.map((seminar2, index2) => (
                        <>
                          <FieldGroup id={`sel_${index1}_${index2}`}>
                            <div className={classes.containerOfForm}>
                              <div>
                                <Typography variant='h5' className={classes.subtitleOfForm}>
                                  {createSeminarName(seminar2)}
                                </Typography>
                              </div>
                              <RadioGroup row className={classes.selectAllOfForm}>
                                <FormLabel className={classes.labelTitle}>一括チェック</FormLabel>
                                <div onClick={() => handleSelectAllOfForm("0", seminar2.seminarId, index1, index2)}>
                                  <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='0' checked={rdoCheckedOfForm('0', seminar2)} />参加あり
                                </div>
                                <div onClick={() => handleSelectAllOfForm("1", seminar2.seminarId, index1, index2)}>
                                  <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='1' checked={rdoCheckedOfForm('1', seminar2)} />参加なし
                                </div>
                                <div onClick={() => handleSelectAllOfForm("2", seminar2.seminarId, index1, index2)}>
                                  <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='2' checked={rdoCheckedOfForm('2', seminar2)} />キャンセル
                                </div>
                              </RadioGroup>
                              <Divider className={classes.divider} />
                            </div>
                            {seminar2.seminarLevel3Dto.map((seminar3, index3) => (
                              <>
                                <div>{createSeminarScheduleName(seminar3)}</div>
                                <div className={classes.content}>
                                  <div onClick={() => handleRdoDateSelect2(seminar3, "0", index1, index2)}>
                                    <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='0' checked={rdoCheckedZero(seminar3)} />参加あり
                                  </div>
                                  <div onClick={() => handleRdoDateSelect2(seminar3, "1", index1, index2)}>
                                    <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='1' checked={rdoCheckedOne(seminar3)} />参加なし
                                  </div>
                                  <div onClick={() => handleRdoDateSelect2(seminar3, "2", index1, index2)}>
                                    <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='2' checked={rdoCheckedTwo(seminar3)} />キャンセル
                                  </div>
                                </div>
                              </>
                            ))}
                          </FieldGroup>
                        </>
                      ))}
                    </>
                  ))
                    : null}
                </CustomScrollbar>
              </div>
            </div>
          </RadioGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClick()}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{getMessage('MCAXS621-001')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default WebInterviewParticipationModal