import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import momentTz from 'moment-timezone'
import * as apiConfig from 'pages/MCAZS220/apiConfig'
import * as viewConfig from 'pages/MCAZS220/viewConig'
import { MCAZS220MessageConfirmRequest } from 'types/MCAZS220/MCAZS220MessageConfirmRequest'
import { MCAZS220MessageDraftRequest } from 'types/MCAZS220/MCAZS220MessageDraftRequest'
import { MCAZS220MessageInitRequest } from 'types/MCAZS220/MCAZS220MessageInitRequest'
import { MCAZS220MessagePreviewRequest } from 'types/MCAZS220/MCAZS220MessagePreviewRequest'
import { MCAZS220MessageCsvDownloadRequest } from 'types/MCAZS220/MCAZS220MessageCsvDownloadRequest'
import { dateTimeToString, now } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
import { McbMessageReplaceBodyRequest } from 'types/McbMessageReplaceBodyRequest'
momentTz.tz.setDefault('Asia/Tokyo')

const messageInitRequest: MCAZS220MessageInitRequest = {
  submissionRequestObjList: [],
  jobSeekerByCompanyIdList: [],
  submissionRequestIdList: [],
  messageSendId: [],
  searchCriteriaName: '',
  searchCriteria: '',
  draftMessageSendId: 0,
  sysVersionNumber: 0,
}

const screenDisplayItems: viewConfig.ScreenDisplayItems = {
  title: viewConfig.title,
  messageInput: viewConfig.messageInput,
  sendTargetConfirmation: viewConfig.sendTargetConfirmation,
  sendTimeInput: viewConfig.sendTimeInput,
  signature: viewConfig.signature,
}

const registerValue: viewConfig.RegisterValue = viewConfig.registerValue

const initialState: {
  messageInitRequest: MCAZS220MessageInitRequest
  initResult: apiConfig.InitResult
  businessCheckResult: boolean
  screenDisplayItems: viewConfig.ScreenDisplayItems
  confirmRequest: MCAZS220MessageConfirmRequest
  draftRequest: MCAZS220MessageDraftRequest
  registerValue: viewConfig.RegisterValue
  hasCloseWindow: boolean
  hasCloseWindowByDraft: boolean
  runConfirm: boolean
  openConfirm: boolean
  open006ConfirmModal: boolean
  successfulCountMessage: number
  successfulCountEmail: number
  failedCount: number
  isInitialized: boolean
  isDraft: boolean
  alertMessageList: string[]
  previewDataList: apiConfig.PreviewResult[]
  subjectText: string
  replaceBody: string
  // #MCB1.5次開発 #9240 START
  attachDisableFlag: boolean
  optionDisableFlag: boolean
  // #MCB1.5次開発 #9240 END
} = {
  messageInitRequest: messageInitRequest,
  initResult: apiConfig.initResult,
  businessCheckResult: true,
  screenDisplayItems: screenDisplayItems,
  confirmRequest: apiConfig.messageConfirmRequest,
  draftRequest: apiConfig.messageDraftRequest,
  registerValue: registerValue,
  hasCloseWindow: false,
  hasCloseWindowByDraft: false,
  runConfirm: false,
  openConfirm: false,
  open006ConfirmModal: false,
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  isInitialized: false,
  isDraft: false,
  alertMessageList: [],
  previewDataList: [],
  subjectText: '',
  replaceBody: '',
  // #MCB1.5次開発 #9240 START
  attachDisableFlag: false,
  optionDisableFlag: false
  // #MCB1.5次開発 #9240 END
}

const mcbMessageSendSlice = createSlice({
  name: 'mcbMessageSend',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<MCAZS220MessageInitRequest>) {
      state.hasCloseWindow = true
      const initRequest = action.payload
      state.messageInitRequest = initRequest
      state.messageInitRequest.sysVersionNumber = Number(initRequest.sysVersionNumber)
      return state
    },
    setInit(state, action: PayloadAction<apiConfig.InitResult>) {
      state.hasCloseWindow = false
      state.initResult = action.payload
      // #MCB1.5次開発 #9240 START
      state.attachDisableFlag = false
      state.optionDisableFlag = false
      // #MCB1.5次開発 #9240 END
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = true
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    setScreenDisplayItems(state) {
      // 初期値設定
      const data: apiConfig.InitResult = state.initResult
      // メッセージテンプレート
      state.screenDisplayItems.title.selectedTemplateSettingId = data.draftMessageTemplateSettingId ? String(data.draftMessageTemplateSettingId) : ''
      state.screenDisplayItems.title.messageTemplate = data.messageTemplate
        ? data.messageTemplate
        : []
      // 送信先 3：My CareerBox
      state.screenDisplayItems.sendTargetConfirmation.destinationSelection = magiContants.DESTINATION_DIVISION_MYCAREERBOX
      // 送信対象
      let destinationInfoList: viewConfig.SendTarget[] = []
      let entryIdList: string[] = []
      if (data.destinationInfo) {
        data.destinationInfo.map(target => {
          const destinationInfo: viewConfig.SendTarget = viewConfig.sendTarget
          destinationInfo.entryId = target.entryId
          destinationInfo.fullName = target.familyName + ' ' + target.name
          destinationInfo.mailAddress = target.mailAddress
          destinationInfo.jobSeekerId = target.jobSeekerIdForDisplay
		      // #MCB1.5次開発 #8997 START
          destinationInfo.readableFlag = target.readableFlag
		      // #MCB1.5次開発 #8997 END
          const memberInfo = target.unsubscribedJobSeekerId ? magiContants.MEMBERS_TYPE_DEACTIVATED : target.memberType
          destinationInfo.mynaviMemberInfo = memberInfo
          destinationInfo.mcbUseStatusName = (target.mcbUseStatus === magiContants.MCBUSE_STATUS_1 || target.mcbUseStatus === magiContants.MCBUSE_STATUS_2) ? magiContants.MCBUSE_STATUS_NAME_1 : magiContants.EMPTY
          destinationInfo.schoolName = target.schoolName
          destinationInfo.facultyName = target.facultyName
          destinationInfo.subjectName = target.subjectName
          const applicationDate: string | null = momentTz(target.entryReceptionTime).format('YYYY/MM/DD HH:mm')
          destinationInfo.applicationDate = applicationDate
            ? applicationDate
            : '-'
          destinationInfo.viewEndTime = target.viewEndTime
          const newDestinationInfo = JSON.parse(JSON.stringify(destinationInfo))
          destinationInfoList = [...destinationInfoList, newDestinationInfo]
          entryIdList = [...entryIdList, target.entryId]
        })
      }
      state.screenDisplayItems.sendTargetConfirmation.sendTarget = destinationInfoList
      // mcb送信対象
      state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination = data.mcbDestinationInfo
      // 検索条件名
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName = data.searchCriteriaName
      // 検索条件
      state.screenDisplayItems.sendTargetConfirmation.searchCriteria = data.searchCriteria
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaInfo.searchCriteriaName = data.searchCriteriaName
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaInfo.searchCriteria = data.searchCriteria
      // 送信日時 すぐ送信：0 タイマー送信：1
      state.screenDisplayItems.sendTimeInput.transmission = data.sendTimeFlag ? data.sendTimeFlag : magiContants.SEND_TIME_FLAG_IMMEDIATE
      if (state.screenDisplayItems.sendTimeInput.transmission === magiContants.SEND_TIME_FLAG_IMMEDIATE ||
        data.sendPlanTime == null) {
        state.screenDisplayItems.sendTimeInput.sendPlanTime = null
      } else {
        const sendPlanTimeMoment = momentTz(data.sendPlanTime)
        const offsetTime = momentTz(sendPlanTimeMoment).add(sendPlanTimeMoment.utcOffset() + now().getTimezoneOffset(), 'minute')
        state.screenDisplayItems.sendTimeInput.sendPlanTime = offsetTime.toDate()
      }
      // エントリーリスト
      state.confirmRequest.destinationId = entryIdList
      state.draftRequest.destinationId = entryIdList
      // 文例リンクURL
      state.screenDisplayItems.messageInput.exampleLink = data.exampleLinkUrl
        ? data.exampleLinkUrl
        : ''
      // 送信元会社名
      state.screenDisplayItems.messageInput.senderCompanyName = data.senderCompanyName
        ? data.senderCompanyName
        : ''
      // 送信元メールアドレス
      state.screenDisplayItems.messageInput.senderMailAddress = data.senderMailAddress
        ? data.senderMailAddress
        : ''
      // 件名
      state.screenDisplayItems.messageInput.subject = data.subject
        ? data.subject
        : ''
      // 本文
      state.screenDisplayItems.messageInput.body = data.body ? data.body : ''
      // 目標初期値
      state.screenDisplayItems.messageInput.targetInput = 'subject'
      // 添付ファイル
      let attachmentDataList = data.attachment
      let attachmentList: viewConfig.Attachment[] = []
      if (attachmentDataList) {
        attachmentDataList.map(item => {
          let attachment: viewConfig.Attachment = {
            attachmentFileName: '',
            attachmentId: null,
            attachmentName: '',
          }
          attachment.attachmentId = item.fileManagementId
          attachment.attachmentName = item.attachmentName
          attachment.attachmentFileName = item.attachmentName
          attachmentList = [...attachmentList, attachment]
        })
      }
      if (attachmentList.length <= 0) {
        let attachment: viewConfig.Attachment = {
          attachmentFileName: '',
          attachmentId: null,
          attachmentName: '',
        }
        attachmentList = [...attachmentList, attachment]
      }
      state.screenDisplayItems.messageInput.attachment = attachmentList
      // ホームページ
      let homePageData = data.homePage
      let homePageList: viewConfig.HomePage[] = []
      if (homePageData) {
        for (var i = 0; i < homePageData.length; i++) {
          let homePage: viewConfig.HomePage = {
            homePageTitle: '',
            homePageUrl: '',
          }
          homePage.homePageTitle = homePageData[i].homePageTitle
            ? homePageData[i].homePageTitle
            : ''
          homePage.homePageUrl = homePageData[i].homePageUrl
            ? homePageData[i].homePageUrl
            : ''
          homePageList = [...homePageList, homePage]
        }
      }
      if (homePageList.length <= 0) {
        let homePage: viewConfig.HomePage = {
          homePageTitle: '',
          homePageUrl: '',
        }
        homePageList = [...homePageList, homePage]
      }
      state.screenDisplayItems.messageInput.homePage = homePageList
      // 署名
      state.screenDisplayItems.signature = data.signature
      state.isInitialized = true
      return state
    },
    getPreview(state, action: PayloadAction<{ formData: MCAZS220MessagePreviewRequest }>) {
      state.previewDataList = []
      return state
    },
    setPreview(state, action: PayloadAction<apiConfig.PreviewResult[]>) {
      state.previewDataList = action.payload
      return state
    },
    inputDestinationSelection(state, action: PayloadAction<{ target: string, companyName: string, mailAddress: string }>) {
      const destinationSelection = action.payload.target
      const companyName = action.payload.companyName
      const mailAddress = action.payload.mailAddress
      state.screenDisplayItems.sendTargetConfirmation.destinationSelection = destinationSelection
      state.screenDisplayItems.messageInput.previewSubject = ''
      state.screenDisplayItems.messageInput.previewBody = ''
      let shouldShowMessageInput: viewConfig.ShouldShowMessageInput = {
        senderCompanyName: true,
        senderMailAddress: true,
        subject: true,
        body: true,
        attachment: true,
        homePage: true,
      }
      state.screenDisplayItems.messageInput.senderCompanyName = companyName
      state.screenDisplayItems.messageInput.senderMailAddress = mailAddress
      state.screenDisplayItems.messageInput.shouldShowMessageInput = shouldShowMessageInput
      return state
    },
    inputSenderCompanyName(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.senderCompanyName = replacedValue
      return state
    },
    inputSenderMailAddress(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.senderMailAddress = replacedValue
      return state
    },
    inputSubject(
      state,
      action: PayloadAction<{ target: string; value: string }>
    ) {
      let target = action.payload.target
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.targetInput = target
      state.screenDisplayItems.messageInput.subject = replacedValue
      return state
    },
    inputBody(state, action: PayloadAction<{ target: string; value: string }>) {
      let target = action.payload.target
      let value = action.payload.value
      state.screenDisplayItems.messageInput.targetInput = target
      state.screenDisplayItems.messageInput.body = value
      return state
    },
    changeTargetInput(state, action: PayloadAction<{ target: string }>) {
      let target = action.payload.target
      state.screenDisplayItems.messageInput.targetInput = target
      return state
    },
    inputAttachment(
      state,
      action: PayloadAction<{ index: number; fileName: string }>
    ) {
      const index = action.payload.index
      const fileName = action.payload.fileName
      state.screenDisplayItems.messageInput.attachment[index].attachmentFileName = fileName
      return state
    },
    deleteAttachment(state, action: PayloadAction<{ index: number }>) {
      let index: number = action.payload.index
      let attachment: viewConfig.Attachment[] =
        state.screenDisplayItems.messageInput.attachment
      attachment.splice(index, 1)
      state.screenDisplayItems.messageInput.attachment = attachment
      // #MCB1.5次開発 #9240 START
      if(attachment.length < 5){
        state.attachDisableFlag = false
      }
      // #MCB1.5次開発 #9240 END
      return state
    },
    addAttachment(state) {
      let attachment: viewConfig.Attachment[] =
        state.screenDisplayItems.messageInput.attachment
      let attachmentValue: viewConfig.Attachment = {
        attachmentFileName: '',
        attachmentId: null,
        attachmentName: '',
      }
      state.screenDisplayItems.messageInput.attachment = [
        ...attachment,
        attachmentValue,
      ]
      return state
    },
    inputHomePageTitle(
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) {
      let index = action.payload.index
      let value = action.payload.value
      state.screenDisplayItems.messageInput.homePage[
        index
      ].homePageTitle = value
      return state
    },
    inputHomePageUrl(
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) {
      let index = action.payload.index
      let value = action.payload.value
      state.screenDisplayItems.messageInput.homePage[index].homePageUrl = value
      return state
    },
    deleteHomePage(state, action: PayloadAction<{ index: number }>) {
      let index: number = action.payload.index
      let homePage: viewConfig.HomePage[] =
        state.screenDisplayItems.messageInput.homePage
      homePage.splice(index, 1)
      state.screenDisplayItems.messageInput.homePage = homePage
      // #MCB1.5次開発 #9240 START
      if(homePage.length < 3){
        state.optionDisableFlag = false
      }
      // #MCB1.5次開発 #9240 END
      return state
    },
    addHomePage(state) {
      let homePage: viewConfig.HomePage[] =
        state.screenDisplayItems.messageInput.homePage
      let homePageValue: viewConfig.HomePage = {
        homePageTitle: '',
        homePageUrl: '',
      }
      state.screenDisplayItems.messageInput.homePage = [
        ...homePage,
        homePageValue,
      ]
      return state
    },
    changeTransmission(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.sendTimeInput.transmission = value
      return state
    },
    inputSendTimer(state, action: PayloadAction<{ value: Date | null }>) {
      const value = action.payload.value
      state.screenDisplayItems.sendTimeInput.sendPlanTime = value
      return state
    },
    deleteSendTarget(state, action: PayloadAction<{ id: string }>) {
      const entryId = action.payload.id
      const sendTargetList = state.screenDisplayItems.sendTargetConfirmation.sendTarget
      let newSendTargetList: viewConfig.SendTarget[] = []
      if (sendTargetList) {
        sendTargetList.map(item => {
          if (item.entryId !== entryId) {
            newSendTargetList = [...newSendTargetList, item]
          }
        })
      }
      state.screenDisplayItems.sendTargetConfirmation.sendTarget = newSendTargetList
      const mcbMessageDestinationList = state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination
      let newMcbSendTargetList: viewConfig.McbSendTarget[] = []
      if (mcbMessageDestinationList) {
        mcbMessageDestinationList.map(item => {
          if (item.entryId != entryId) {
            newMcbSendTargetList = [...newMcbSendTargetList, item]
          }
        })
      }
      state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination = newMcbSendTargetList
      return state
    },
    setDraftReqeust(state) {
      state.draftRequest.messageTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId && state.screenDisplayItems.title.selectedTemplateSettingId !== '' ? state.screenDisplayItems.title.selectedTemplateSettingId : null
      state.draftRequest.mcbMessageDestination = state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination
      state.draftRequest.sourceDraftMessageSendId = state.messageInitRequest.draftMessageSendId ? String(state.messageInitRequest.draftMessageSendId) : ''
      state.draftRequest.destination = Number(state.screenDisplayItems.sendTargetConfirmation.destinationSelection)
      const sendTarget =
        state.screenDisplayItems.sendTargetConfirmation.sendTarget
      let entryIdList: string[] = []
      if (sendTarget) {
        sendTarget.map(item => {
          entryIdList = [...entryIdList, item.entryId]
        })
      }
      state.draftRequest.destinationId = entryIdList
      state.draftRequest.senderCompanyName =
        state.screenDisplayItems.messageInput.senderCompanyName
      if (state.screenDisplayItems.sendTargetConfirmation.destinationSelection !== magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
        state.draftRequest.senderMailAddress = state.screenDisplayItems.messageInput.senderMailAddress
      } else {
        state.draftRequest.senderMailAddress = ''
      }
      state.draftRequest.subject = state.screenDisplayItems.messageInput.subject
      state.draftRequest.text = state.screenDisplayItems.messageInput.body
      const attachmentData = state.screenDisplayItems.messageInput.attachment
      let attachmentIdList: Array<string | null> = []
      let attachmentNameList: string[] = []
      if (attachmentData) {
        attachmentData.map(item => {
          let attachment = item
          attachmentIdList = [...attachmentIdList, attachment.attachmentId]
          attachmentNameList = [...attachmentNameList, attachment.attachmentName]
        })
      }
      state.draftRequest.attachmentId =
        attachmentIdList.length > 0 && attachmentIdList[0] !== ''
          ? attachmentIdList
          : []
      state.draftRequest.attachmentName =
        attachmentNameList.length > 0 && attachmentNameList[0] !== ''
          ? attachmentNameList
          : []
      const homePage1 = state.screenDisplayItems.messageInput.homePage[0]
      if (homePage1) {
        state.draftRequest.homepageTitle1 = homePage1.homePageTitle && homePage1.homePageTitle !== '' ? homePage1.homePageTitle : null
        state.draftRequest.homepageUrl1 = homePage1.homePageUrl && homePage1.homePageUrl !== '' ? homePage1.homePageUrl : null
      }
      const homePage2 = state.screenDisplayItems.messageInput.homePage[1]
      if (homePage2) {
        state.draftRequest.homepageTitle2 = homePage2.homePageTitle && homePage2.homePageTitle !== '' ? homePage2.homePageTitle : null
        state.draftRequest.homepageUrl2 = homePage2.homePageUrl && homePage2.homePageUrl !== '' ? homePage2.homePageUrl : null
      }
      const homePage3 = state.screenDisplayItems.messageInput.homePage[2]
      if (homePage3) {
        state.draftRequest.homepageTitle3 = homePage3.homePageTitle && homePage3.homePageTitle !== '' ? homePage3.homePageTitle : null
        state.draftRequest.homepageUrl3 = homePage3.homePageUrl && homePage3.homePageUrl !== '' ? homePage3.homePageUrl : null
      }
      state.draftRequest.sendTimer = Number(
        state.screenDisplayItems.sendTimeInput.transmission
      )
      state.draftRequest.sendPlanTime = dateTimeToString(state.screenDisplayItems.sendTimeInput.sendPlanTime)
      state.draftRequest.searchCriteriaName = state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName
      state.draftRequest.searchCriteria = state.screenDisplayItems.sendTargetConfirmation.searchCriteria
      state.draftRequest.searchCriteriaJson = state.initResult.searchCriteriaJson
    },
    setEmptyTemplateSettingId(state) {
      state.screenDisplayItems.title.selectedTemplateSettingId = ''
      return state
    },
    selectTemplate(state, action: PayloadAction<{ id: number, inputValues: any }>) {
      const messageTemplateSettingId = action.payload.id
      if (state.screenDisplayItems.title.messageTemplate) {
        state.screenDisplayItems.title.messageTemplate.map(template => {
          if (template && template.messageTemplateSettingId === messageTemplateSettingId) {
            state.screenDisplayItems.title.selectedTemplateSettingId = String(messageTemplateSettingId)
            state.screenDisplayItems.sendTargetConfirmation.destinationSelection = magiContants.DESTINATION_DIVISION_MYCAREERBOX
            let shouldShowMessageInput = state.screenDisplayItems.messageInput.shouldShowMessageInput
            shouldShowMessageInput.senderCompanyName = true
            shouldShowMessageInput.senderMailAddress = true
            shouldShowMessageInput.attachment = true
            shouldShowMessageInput.homePage = true
            state.screenDisplayItems.messageInput.shouldShowMessageInput = shouldShowMessageInput
            let senderCompanyName = ''
            if ('' !== template.senderCompanyName) {
              senderCompanyName = template.senderCompanyName
            }
            state.screenDisplayItems.messageInput.senderCompanyName = senderCompanyName

            let senderMailAddress = ''
            if ('' !== template.senderMailAddress) {
              senderMailAddress = template.senderMailAddress
            }
            state.screenDisplayItems.messageInput.senderMailAddress = senderMailAddress
            state.screenDisplayItems.messageInput.subject = template.subject
            state.subjectText = template.subject
            state.screenDisplayItems.messageInput.body = template.body
            if (state.initResult.messageTemplateHomepage) {
              let homePageList: viewConfig.HomePage[] = []
              state.initResult.messageTemplateHomepage.map(templateHomePage => {
                if (templateHomePage && templateHomePage.messageTemplateSettingId === messageTemplateSettingId) {
                  let homePageData: viewConfig.HomePage = {
                    homePageTitle: '',
                    homePageUrl: '',
                  }
                  homePageData.homePageTitle = templateHomePage.homePageTitle
                  homePageData.homePageUrl = templateHomePage.homePageUrl
                  homePageList = [...homePageList, homePageData]
                }
              })
              // #MCB1.5次開発 #9240 START
              if(homePageList && homePageList.length >= 3){
                state.optionDisableFlag = true
              }else{
                state.optionDisableFlag = false
              }
              // #MCB1.5次開発 #9240 END
              if (homePageList.length <= 0) {
                const homePageData: viewConfig.HomePage = {
                  homePageTitle: '',
                  homePageUrl: '',
                }
                homePageList = [...homePageList, homePageData]
              }
              state.screenDisplayItems.messageInput.homePage = homePageList
            }

            if (state.initResult.messageTemplateAttachment) {
              let attachmentList: viewConfig.Attachment[] = []
              state.initResult.messageTemplateAttachment.map(templateAttachment => {
                if (templateAttachment && templateAttachment.messageTemplateSettingId === messageTemplateSettingId) {
                  let attachmentData: viewConfig.Attachment = {
                    attachmentId: null,
                    attachmentFileName: '',
                    attachmentName: '',
                  }
                  if (templateAttachment.sequence <= state.initResult.attachmentFileRegistrationMax) {
                    attachmentData.attachmentId = templateAttachment.fileManagementId
                    attachmentData.attachmentFileName = templateAttachment.attachmentName
                    attachmentData.attachmentName = templateAttachment.attachmentName
                    attachmentList = [...attachmentList, attachmentData]
                  }
                }
              })
              // #MCB1.5次開発 #9240 START
              if(attachmentList && attachmentList.length >= 5){
                state.attachDisableFlag = true
              }else{
                state.attachDisableFlag = false
              }
              // #MCB1.5次開発 #9240 END
              if (attachmentList.length <= 0) {
                const attachmentData: viewConfig.Attachment = {
                  attachmentId: null,
                  attachmentFileName: '',
                  attachmentName: '',
                }
                attachmentList = [...attachmentList, attachmentData]
              }
              state.screenDisplayItems.messageInput.attachment = attachmentList
            }
          } else if (-1 === messageTemplateSettingId) {
            state.screenDisplayItems.sendTargetConfirmation.destinationSelection = magiContants.DESTINATION_DIVISION_MYCAREERBOX
            state.screenDisplayItems.messageInput.senderCompanyName = ''
            state.screenDisplayItems.messageInput.senderMailAddress = ''
            state.screenDisplayItems.messageInput.subject = ''
            state.screenDisplayItems.messageInput.body = ''
            state.screenDisplayItems.messageInput.targetInput = 'subject'
            state.screenDisplayItems.messageInput.attachment = [{
              attachmentFileName: '',
              attachmentId: null,
              attachmentName: '',
            }]
            state.screenDisplayItems.messageInput.templateAttachmentId = []
            state.screenDisplayItems.messageInput.homePage = [{
              homePageTitle: '',
              homePageUrl: '',
            }]
            state.screenDisplayItems.messageInput.previewSubject = ''
            state.screenDisplayItems.messageInput.previewBody = ''

            let shouldShowMessageInput = state.screenDisplayItems.messageInput.shouldShowMessageInput
            shouldShowMessageInput.senderCompanyName = true
            shouldShowMessageInput.senderMailAddress = true
            shouldShowMessageInput.attachment = true
            shouldShowMessageInput.homePage = true

            state.screenDisplayItems.messageInput.shouldShowMessageInput = shouldShowMessageInput
            state.screenDisplayItems.sendTimeInput = viewConfig.sendTimeInput
            // #MCB1.5次開発 #9240 START
            state.optionDisableFlag = false
            state.attachDisableFlag = false
            // #MCB1.5次開発 #9240 END
            const data: apiConfig.InitResult = state.initResult
          }
        })
      }
      return state
    },
    saveDraft(
      state,
      action: PayloadAction<{
        formData: MCAZS220MessageDraftRequest
        files: Array<any | null>
        attachmentFileRegistrationMax: number
      }>
    ) {
      return state
    },
    setConfirmRequest(state) {
      const destinationSelection = state.screenDisplayItems.sendTargetConfirmation.destinationSelection

      const shouldShowMessageInput = state.screenDisplayItems.messageInput.shouldShowMessageInput

      const confirmRequest: MCAZS220MessageConfirmRequest = {
        draftMessageSendId: null,
        mcbMessageDestination: [],
        messageTemplateSettingId: '0',
        destinationId: [],
        destination: 0,
        senderCompanyName: '',
        senderMailAddress: '',
        subject: '',
        text: '',
        attachment: [],
        attachmentId: [],
        attachmentName: [],
        templateAttachmentId: [],
        templateAttachmentName: [],
        homepageTitle1: '',
        homepageTitle2: '',
        homepageTitle3: '',
        homepageUrl1: '',
        homepageUrl2: '',
        homepageUrl3: '',
        sendTimer: 0,
        sendPlanTime: '',
        title: state.screenDisplayItems.title,
        messageInput: state.screenDisplayItems.messageInput,
        sendTargetConfirmation: {
          destinationSelection: apiConfig.sendTargetConfirmationCon.destinationSelection,
          sendTarget: state.screenDisplayItems.sendTargetConfirmation.sendTarget,
          searchCriteriaName: apiConfig.sendTargetConfirmationCon.searchCriteriaName,
          searchCriteria: apiConfig.sendTargetConfirmationCon.searchCriteria,
          searchCriteriaInfo: apiConfig.sendTargetConfirmationCon.searchCriteriaInfo,
        },
        sendTimeInput: state.screenDisplayItems.sendTimeInput,
        signature: [],
        sendReplyImpossibleTime: state.initResult.sendReplyImpossibleTime,
        searchCriteriaJson: '',
      }

      const registerValue: viewConfig.RegisterValue = {
        messageType: [],
        mcbMessageDestination: [],
        messageTemplateSettingId: '',
        destinationId: [],
        memberType: [],
        destination: 0,
        senderCompanyName: '',
        senderMailAddress: '',
        subject: '',
        body: '',
        attachment: [],
        attachmentId: [],
        templateAttachmentId: [],
        templateAttachmentName: [],
        homePageTitle1: null,
        homePageTitle2: null,
        homePageTitle3: null,
        homePageUrl1: null,
        homePageUrl2: null,
        homePageUrl3: null,
        sendTimer: 0,
        sendPlanTime: null,
        fileManagementId: [],
        fileManagementName: [],
        entryId: [],
        sysVersionNumber: '',
        messageSendId: '',
        sendTimeFlag: '',
        destinationDivision: '',
        title: viewConfig.title,
        messageInput: viewConfig.messageInput,
        sendTargetConfirmation: viewConfig.sendTargetConfirmation,
        sendTimeInput: viewConfig.sendTimeInput,
        signature: [],
        fullName: '',
        searchCriteriaJson: '',
      }
      confirmRequest.mcbMessageDestination = state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination
      registerValue.mcbMessageDestination = state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination

      confirmRequest.destination = Number(destinationSelection)
      registerValue.destination = Number(destinationSelection)

      if (state.initResult.messageType) {
        registerValue.messageType = state.initResult.messageType.map(
          messageType => messageType.messageType
        )
      }
      confirmRequest.messageTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId
      registerValue.messageTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId && state.screenDisplayItems.title.selectedTemplateSettingId !== '' ? state.screenDisplayItems.title.selectedTemplateSettingId : null

      if (state.screenDisplayItems.sendTargetConfirmation.sendTarget) {
        const destinationId: string[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.entryId
        )
        confirmRequest.destinationId = destinationId
        registerValue.destinationId = destinationId

        registerValue.entryId = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => Number(sendTarget.entryId)
        )
        const memberType: string[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(item => item.mynaviMemberInfo)
        registerValue.memberType = memberType
      }
      const destination = state.screenDisplayItems.sendTargetConfirmation.destinationSelection
      confirmRequest.destination = Number(destination)
      registerValue.destinationDivision = destination
      const subject = state.screenDisplayItems.messageInput.subject
      confirmRequest.subject = subject
      registerValue.subject = subject

      const body = state.screenDisplayItems.messageInput.body
      confirmRequest.text = body
      registerValue.body = body

      let senderCompanyName = ''
      if (shouldShowMessageInput.senderCompanyName) {
        senderCompanyName = state.screenDisplayItems.messageInput.senderCompanyName
      }
      confirmRequest.senderCompanyName = senderCompanyName
      registerValue.senderCompanyName = senderCompanyName

      let senderMailAddress = ''
      if (shouldShowMessageInput.senderMailAddress) {
        senderMailAddress = state.screenDisplayItems.messageInput.senderMailAddress
      }
      confirmRequest.senderMailAddress = senderMailAddress
      registerValue.senderMailAddress = senderMailAddress

      if (shouldShowMessageInput.attachment) {
        const attachmentList = state.screenDisplayItems.messageInput.attachment
        if (attachmentList) {
          let attachmentIdList: Array<string | null> = []
          let attachmentNameList: Array<string | null> = []
          let fileNameList: string[] = []
          attachmentList.map(attachment => {
            if (attachment.attachmentId && attachment.attachmentName) {
              attachmentIdList = [...attachmentIdList, attachment.attachmentId]
              attachmentNameList = [...attachmentNameList, attachment.attachmentName]
              fileNameList = [...fileNameList, attachment.attachmentName]
            }
          })
          confirmRequest.attachmentName = fileNameList
          registerValue.attachmentId = attachmentIdList
          registerValue.attachment = attachmentNameList
        }
        registerValue.templateAttachmentId = []
        registerValue.templateAttachmentName = []
        if (registerValue.messageTemplateSettingId && registerValue.messageTemplateSettingId != '') {
          if (attachmentList && attachmentList.length > 0) {
            registerValue.templateAttachmentId = attachmentList.map(item => item.attachmentId ? item.attachmentId : '')
            registerValue.templateAttachmentName = attachmentList.map(item => item.attachmentName ? item.attachmentName : '')
          }
        }
      }
      let homePageTitle1: string | null = null
      let homePageTitle2: string | null = null
      let homePageTitle3: string | null = null
      let homePageUrl1: string | null = null
      let homePageUrl2: string | null = null
      let homePageUrl3: string | null = null
      if (shouldShowMessageInput.homePage) {
        homePageTitle1 =
          state.screenDisplayItems.messageInput.homePage[0] &&
            state.screenDisplayItems.messageInput.homePage[0].homePageTitle !== ''
            ? state.screenDisplayItems.messageInput.homePage[0].homePageTitle
            : null
        homePageTitle2 =
          state.screenDisplayItems.messageInput.homePage[1] &&
            state.screenDisplayItems.messageInput.homePage[1].homePageTitle !== ''
            ? state.screenDisplayItems.messageInput.homePage[1].homePageTitle
            : null
        homePageTitle3 =
          state.screenDisplayItems.messageInput.homePage[2] &&
            state.screenDisplayItems.messageInput.homePage[2].homePageTitle !== ''
            ? state.screenDisplayItems.messageInput.homePage[2].homePageTitle
            : null
        homePageUrl1 =
          state.screenDisplayItems.messageInput.homePage[0] &&
            state.screenDisplayItems.messageInput.homePage[0].homePageUrl !== ''
            ? state.screenDisplayItems.messageInput.homePage[0].homePageUrl
            : null
        homePageUrl2 =
          state.screenDisplayItems.messageInput.homePage[1] &&
            state.screenDisplayItems.messageInput.homePage[1].homePageUrl
            ? state.screenDisplayItems.messageInput.homePage[1].homePageUrl
            : null
        homePageUrl3 =
          state.screenDisplayItems.messageInput.homePage[2] &&
            state.screenDisplayItems.messageInput.homePage[2].homePageUrl
            ? state.screenDisplayItems.messageInput.homePage[2].homePageUrl
            : null
      }
      confirmRequest.homepageTitle1 = homePageTitle1
      confirmRequest.homepageTitle2 = homePageTitle2
      confirmRequest.homepageTitle3 = homePageTitle3
      confirmRequest.homepageUrl1 = homePageUrl1
      confirmRequest.homepageUrl2 = homePageUrl2
      confirmRequest.homepageUrl3 = homePageUrl3
      registerValue.homePageTitle1 = homePageTitle1
      registerValue.homePageTitle2 = homePageTitle2
      registerValue.homePageTitle3 = homePageTitle3
      registerValue.homePageUrl1 = homePageUrl1
      registerValue.homePageUrl2 = homePageUrl2
      registerValue.homePageUrl3 = homePageUrl3

      const sendTimer = Number(state.screenDisplayItems.sendTimeInput.transmission)
      confirmRequest.sendTimer = sendTimer
      registerValue.sendTimer = sendTimer
      registerValue.sendTimeFlag =
        state.screenDisplayItems.sendTimeInput.transmission

      const sendPlanTime = dateTimeToString(state.screenDisplayItems.sendTimeInput.sendPlanTime)
      confirmRequest.sendPlanTime = sendPlanTime ? sendPlanTime : ''
      registerValue.sendPlanTime = sendPlanTime

      let fileManagementIdList: string[] = []
      let attachmentNameList: string[] = []
      state.screenDisplayItems.messageInput.attachment.map(item => {
        if (item.attachmentId && item.attachmentId !== '' && item.attachmentName && item.attachmentName !== '') {
          fileManagementIdList = [...fileManagementIdList, item.attachmentId]
          attachmentNameList = [...attachmentNameList, item.attachmentName]
        }
      })
      confirmRequest.attachmentName = attachmentNameList
      confirmRequest.attachmentId = fileManagementIdList
      registerValue.fileManagementId = fileManagementIdList
      registerValue.fileManagementName = attachmentNameList

      confirmRequest.draftMessageSendId = state.messageInitRequest.draftMessageSendId && state.messageInitRequest.draftMessageSendId !== 0 ? String(state.messageInitRequest.draftMessageSendId) : null
      registerValue.messageSendId = state.messageInitRequest.draftMessageSendId && state.messageInitRequest.draftMessageSendId !== 0 ? String(state.messageInitRequest.draftMessageSendId) : ''
      registerValue.sysVersionNumber = state.messageInitRequest.sysVersionNumber ? String(state.messageInitRequest.sysVersionNumber) : ''

      const title = state.screenDisplayItems.title
      confirmRequest.title = title
      registerValue.title = title

      const messageInput = state.screenDisplayItems.messageInput
      registerValue.messageInput = messageInput

      const sendTargetConfirmation = state.screenDisplayItems.sendTargetConfirmation
      registerValue.sendTargetConfirmation = sendTargetConfirmation

      const sendTimeInput = state.screenDisplayItems.sendTimeInput
      confirmRequest.sendTimeInput = sendTimeInput
      registerValue.sendTimeInput = sendTimeInput
      confirmRequest.searchCriteriaJson = state.initResult.searchCriteriaJson
      registerValue.searchCriteriaJson = state.initResult.searchCriteriaJson
      const signature = state.screenDisplayItems.signature
      confirmRequest.signature = signature
      registerValue.signature = signature
      state.confirmRequest = confirmRequest
      state.registerValue = registerValue

      state.runConfirm = true
      return state
    },
    confirm(
      state,
      action: PayloadAction<{
        formData: MCAZS220MessageConfirmRequest
        files: Array<any | null>
        viewEndTimeList: Date[]
        forbiddenWords: string[]
        attachmentFileRegistrationMax: number
      }>
    ) {
      return state
    },
    changeConfirmState(state, action: PayloadAction<boolean>) {
      state.runConfirm = action.payload
      return state
    },
    openConfirmModal(state) {
      state.openConfirm = true
      return state
    },
    closeConfirmModal(state) {
      state.openConfirm = false
      return state
    },
    setCloseWindow(state, action: PayloadAction<boolean>) {
      state.hasCloseWindowByDraft = action.payload
      return state
    },
    setIsDraft(state, action: PayloadAction<boolean>) {
      state.isDraft = action.payload
      return state
    },
    setSubjectText(state, action: PayloadAction<string>) {
      state.subjectText = action.payload
      return state
    },
    csvDownload(state, action: PayloadAction<MCAZS220MessageCsvDownloadRequest>) {
      return state
    },
    openDetailDialog(state, action: PayloadAction<viewConfig.SearchCriteriaInfo>) {
      return state
    },
    getReplaceBody(state, action: PayloadAction<McbMessageReplaceBodyRequest>) {
      return state
    },
    setReplaceBody(state, action: PayloadAction<string>) {
      state.replaceBody = action.payload
      return state
    },
  },
})

export const {
  getInit,
  setInit,
  setCode,
  setScreenDisplayItems,
  getPreview,
  setPreview,
  inputDestinationSelection,
  inputSenderCompanyName,
  inputSenderMailAddress,
  inputSubject,
  inputBody,
  changeTargetInput,
  inputAttachment,
  deleteAttachment,
  addAttachment,
  inputHomePageTitle,
  inputHomePageUrl,
  deleteHomePage,
  addHomePage,
  changeTransmission,
  inputSendTimer,
  setEmptyTemplateSettingId,
  selectTemplate,
  deleteSendTarget,
  setDraftReqeust,
  saveDraft,
  setConfirmRequest,
  confirm,
  changeConfirmState,
  openConfirmModal,
  closeConfirmModal,
  setCloseWindow,
  setIsDraft,
  setSubjectText,
  csvDownload,
  openDetailDialog,
  getReplaceBody,
  setReplaceBody,
} = mcbMessageSendSlice.actions
export default mcbMessageSendSlice.reducer
