import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialValues as searchCriteriaValues } from '../pages/MCARS010/formConfig'
import { ScreenTransitionInput as MCAXS020initScreenTransitionInput } from '../pages/MCAXS020/formConfig'
import { ScreenTransitionInput as MCAXS010initScreenTransitionInput } from '../pages/MCAXS010/formConfig'
import { MCARS010BatchProcessRequest } from 'types/MCARS010/MCARS010BatchProcessRequest'
import { MCARS030CreateRequest } from 'types/MCARS030/MCARS030CreateRequest'
import { MCARS030CreateRequestEmployment } from 'types/MCARS030/MCARS030CreateRequestEmployment'

export interface EntrySrhCriPreSelectSight {
  [key: string]: string | any
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  actionSummary: string                // アクションサマリ
  updateTime: string                   // 更新日時
  userTime: string                     // 日時
  fullName: string                     // 更新者
  displayOrder: number                 // 表示順
  userFlag: boolean                    // フラグ
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  sysVersionNumber: number             // sysバージョン番号
}

export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  sysVersionNumber: number             // sysバージョン番号
}
export interface ActionPreListData {
  entrySearchCriteriaSettingId: string
  searchCriteriaName: string
  // 次期開発9月#58497 start
  searchCriteria: string
  maxCheckBoxLimit: number
  // 次期開発9月#58497 end
}
export interface searchCriteriaRow {
  [key: string]: string
  entrySearchCriteriaSettingId: string//エントリー検索条件設定ID
  searchCriteriaName: string //検索条件名
  sysVersionNumber: string//sysバージョン番号
  sysVersionNumberTime: string//sysバージョン番号時間
}
// 次期開発9月#51248 start
export interface unSchCriName {
  unActionSearchCriteriaList: string[] // アクション設定でない検索条件の項目名
  unActionSearchCriteriaNameList: string[] // アクション設定でない検索条件の項目名（表示用）
}
// 次期開発9月#51248 end
export interface OptionType {
  [key: string]: string;
  label: string
  value: string
  sysVersionNumber: string
  sysVersionNumber1: string
}

interface InitialValuesType {
  searchCriteriaSightInfo: EntrySrhCriPreSelectSight[]
  searchCriteriaInfo: SearchCriteriaInfo
  detailDialogOpen: boolean
  editDialogOpen: boolean
  ActionPreList: ActionPreListData[],
  createDialogOpen: boolean
  searchCriteriaListResults: searchCriteriaRow[]
  searchCriteria: string
  open: boolean;
  selectDate: OptionType[],
  entrySearchCriteriaSettingId: string, //エントリー検索条件設定ID
  flag: number
  checkDialogOpen: boolean
  checkMsg: string
  // 次期開発9月#51248 start
  openActionDialog: boolean
  searchCriteriaToAction:string
  unSchCriName:unSchCriName
  // 次期開発9月#51248 end
}

// 次期開発9月#51248 start
const unSchCriNameInit = {
  unActionSearchCriteriaList: [], // アクション設定でない検索条件の項目名
  unActionSearchCriteriaNameList: [] // アクション設定でない検索条件の項目名（表示用）
}
// 次期開発9月#51248 end

const initialState: InitialValuesType = {
  searchCriteriaSightInfo: [],
  searchCriteriaInfo: searchCriteriaValues,
  detailDialogOpen: false,
  editDialogOpen: false,
  ActionPreList: [],
  createDialogOpen: false,
  searchCriteriaListResults: [],
  searchCriteria: '',
  open: false,
  selectDate: [],
  entrySearchCriteriaSettingId: '',
  flag: 0,
  checkDialogOpen: false,
  checkMsg: '',
  // 次期開発9月#51248 start
  openActionDialog: false,
  searchCriteriaToAction: '',
  // 次期開発9月#51248 end
  unSchCriName:unSchCriNameInit,
}

const selectCriteriaManagementSlice = createSlice({
  name: 'selectCriteriaManagement',
  initialState,
  reducers: {
    // MCARS010 画面初期表示
    getSearchCriteriaSightInfo(state) {
      return state
    },
    // MCARS010 画面初期表示
    setSearchCriteriaSightInfo(state, action: PayloadAction<EntrySrhCriPreSelectSight[]>) {
      state.searchCriteriaSightInfo = action.payload
      return state
    },
    // MCARS010 並び替え
    updatescsDisplayOrder(state, action: PayloadAction<EntrySrhCriPreSelectSight[]>) {
      state.searchCriteriaSightInfo = action.payload
      return state
    },
    // MCARS010 検索条件取得
    getSearchCriteria(state, action: PayloadAction<string>) {
      return state
    },
    // MCARS010 検索条件設定
    setSearchCriteria(state, action: PayloadAction<SearchCriteriaInfo>) {
      state.searchCriteriaInfo = action.payload
      return state
    },
    // MCARS010 「検索条件名」画面を表示
    setDetailDialogOpen(state, action: PayloadAction<boolean>) {
      state.detailDialogOpen = action.payload
      return state
    },
    // MCARS010 検索条件名取得
    getSearchCriteriaName(state, action: PayloadAction<string>) {
      return state
    },
    // MCARS010 検索条件名設定
    setSearchCriteriaName(state, action: PayloadAction<SearchCriteriaInfo>) {
      state.searchCriteriaInfo = action.payload
      return state
    },
    // MCARS010 「検索条件名の設定」画面を表示
    setEditDialogOpen(state, action: PayloadAction<boolean>) {
      state.editDialogOpen = action.payload
      return state
    },
    // MCARS010 検索条件名更新
    updateSearchCriteriaName(state, action: PayloadAction<SearchCriteriaInfo>) {
      return state
    },
    // MCARS010 検索条件削除
    deleteSearchCriteria(state, action: PayloadAction<MCARS010BatchProcessRequest>) {
      return state
    },
    // MCARS020 初期表示
    getActionPreList(state) {
      return state
    },
    // MCARS020 初期表示
    setActionPreList(state, action: PayloadAction<ActionPreListData[]>) {
      state.ActionPreList = action.payload
      return state
    },
    // MCARS020 自分画面（非）表示
    setCreateDialogOpen(state, action: PayloadAction<boolean>) {
      state.createDialogOpen = action.payload
      return state
    },
    // 画面初期表示時用
    getSelectCriteriaList(state) {
      return state
    },
    // 新規条件として保存"が選択されている場合
    createsearchCriteriaName(state, action: PayloadAction<MCARS030CreateRequestEmployment>) {
      return state
    },
    // 既存の検索条件に上書き保存"が選択されている場合
    updatesearchCriteria(state, action: PayloadAction<MCARS030CreateRequest>) {
      return state
    },
    setDialogOpen(
      state,
      action: PayloadAction<boolean>
    ) {
      state.open = action.payload
      return state
    },
    setSelectData(state, action: PayloadAction<OptionType[]>) {
      state.selectDate = action.payload;
      return state;
    },
    setEscSettingId(state, action: PayloadAction<string>) {
      state.entrySearchCriteriaSettingId = action.payload;
      return state;
    },
    setFlag(state, action: PayloadAction<number>){
      state.flag = action.payload;
      return state;
    },
    myInitFlag(state){
      return state;
    },
    // MCARS030 アクションが設定されているかをチェックする。
    checkActionInfo(state, action: PayloadAction<MCARS030CreateRequest>) {
      return state
    },
    // 次期開発9月#51248 start
    selectUnActionSearchCriteriaName(state, action: PayloadAction<string>) {
      return state
    },
    setUnActionSearchCriteriaName(state,action: PayloadAction<unSchCriName>) {
      state.unSchCriName = action.payload
      return state
    },
    setOpenActionDialog(state, action:PayloadAction<boolean>) {
      state.openActionDialog = action.payload
      return state
    },
    // 次期開発9月#51248 end
    // MCARS030 アクションが設定されている場合、確認メッセージを表示する。
    setCheckDialogOpen(state, action: PayloadAction<boolean>) {
      state.checkDialogOpen = action.payload
      return state
    },
    // MCARS030 アクションが設定されている場合、確認メッセージを表示する。
    setCheckMsg(state, action: PayloadAction<string>) {
      state.checkMsg = action.payload
      return state
    },
    // MCARS010 特定の検索条件が含まれていないかをチェックする。
    checkSearchCriteria(state, action: PayloadAction<{entrySearchCriteriaSetting:number,setOpenActionPreDialog:Function}>) {
      return state
    },
    selectSearchCriteria(state,action:PayloadAction<{entrySearchCriteriaSetting:string,
      MCAXS020initScreenTransitionInput : MCAXS020initScreenTransitionInput,
      MCAXS010initScreenTransitionInput : MCAXS010initScreenTransitionInput,
      recruitmentManagementDivision : string}>) {
      return state
    },
    // 次期開発9月#51248 start
    setSearchCriteriaToAction(state, action: PayloadAction<string>) {
      state.searchCriteriaToAction = action.payload
      return state;
    },
    // 次期開発9月#51248 end
  },
})

export const {
  // MCARS010
  getSearchCriteriaSightInfo,
  setSearchCriteriaSightInfo,
  updatescsDisplayOrder,
  getSearchCriteria,
  setSearchCriteria,
  setDetailDialogOpen,
  getSearchCriteriaName,
  setSearchCriteriaName,
  setEditDialogOpen,
  updateSearchCriteriaName,
  deleteSearchCriteria,
  checkSearchCriteria,
  // MCARS020
  getActionPreList,
  setActionPreList,
  setCreateDialogOpen,
  // MCARS030
  getSelectCriteriaList,
  // 次期開発9月#51248 start
  selectUnActionSearchCriteriaName,
  setUnActionSearchCriteriaName,
  setOpenActionDialog,
  // 次期開発9月#51248 end
  createsearchCriteriaName,
  updatesearchCriteria,
  setDialogOpen,
  setSelectData,
  setEscSettingId,
  setFlag,
  myInitFlag,
  checkActionInfo,
  setCheckDialogOpen,
  setCheckMsg,
  selectSearchCriteria,
  // 次期開発9月#51248 start
  setSearchCriteriaToAction,
  // 次期開発9月#51248 end
} = selectCriteriaManagementSlice.actions
export default selectCriteriaManagementSlice.reducer
