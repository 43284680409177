import request from 'utils/request'
import { MCAYS040UpdateRequest } from 'types/MCAYS040/MCAYS040UpdateRequest'

export const starEvaluationInitRequest = (selectionId: number[]) =>
  request({
    url: '/MCAYS040/init',
    method: 'post',
    data: selectionId,
  })

export const starEvaluationUpdateRequest = (updateRequest: MCAYS040UpdateRequest) =>
  request({
    url: '/MCAYS040/update',
    method: 'post',
    data: updateRequest,
  })
