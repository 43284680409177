import React from 'react'
import { routeList } from 'routes/routes'
import history from 'utils/history'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { badgeFormatComma } from 'common/generalUtil'
import { submitValue } from 'pages/MCAZS190/formConfig'
import { submitValueMCAZS180 } from 'pages/MCAZS180/formConfig'
import {
  setMessageOutBoxListSort,
  setOutBoxButtonActiveFlag,
  setOutBoxSelectedFlag,
  initMcbMessageOutBoxListSearchCondition,
  setMessageOutBoxListPage,
  setMcbMessageReservationListSort,
  setButtonActiveFlagMCAZS180,
  initMcbMessageReservationListSearchCondition,
  setMcbMessageReservationListPage,
  setSelectedFlagMCAZS180,
  setPageChangeFlag,
  setMcbMessageDraftListSort,
  setButtonActiveFlagMCAZS190,
  setMcbMessageDraftListPage,
  setSelectedFlagMCAZS190,
  initMcbMessageDraftListSearchCondition,
  setMcbMessageDustboxListPage,
  setMcbMessageDustboxListSort,
  setMcbSelectedDustboxFlag,
  setMcbButtonActiveDustboxFlag,
  initMcbMessageDustboxListSearchCondition,
} from 'reducers/mcbMessageListReducer'
import { dustMcbboxSubmitValue } from 'pages/MCAZS200/formConfig'
import { submitCondition } from 'pages/MCAZS170/formConfig'
interface Props {
  pageValue: number
}

const TabForMessageMycareerbox = ({ pageValue }: Props) => {
  const pageChangeFlag = useSelector(
    (state: RootState) => state.mcbMessageList.pageChangeFlag
  )
  const recruitmentManagementDivisions = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const dispatch = useDispatch()

  const handleClickOutbox = (event: any) => {
    event.preventDefault()
    dispatch(setMessageOutBoxListSort('0'))
    dispatch(setOutBoxButtonActiveFlag(0))
    dispatch(setOutBoxSelectedFlag(0))
    dispatch(setMessageOutBoxListPage(1))
    const submitConditionOperationValues = {
      //検索対象
      searchTargetList: submitCondition.searchTargetList,
      //検索文字
      searchWordText: submitCondition.searchWordText,
      //送信期間（FROM）
      sentFromDate: submitCondition.sentFromDate,
      //送信期間（TO）
      sentToDate: submitCondition.sentToDate,
      //提出締切日（FROM）
      sentFromDateDeadline: submitCondition.sentFromDateDeadline,
      //提出締切日（TO）
      sentToDateDeadline: submitCondition.sentToDateDeadline,
      //添付_あり
      attachmentExist: submitCondition.attachmentExist,
      //添付_なし
      attachmentNot: submitCondition.attachmentNot,
      //一括送信のみ
      onlyBulkSend: submitCondition.onlyBulkSend,
      //ソート順
      sort: submitCondition.sort,
      //ページ数
      countPage: submitCondition.countPage,
      //全件選択フラグ
      selectedFlag: submitCondition.selectedFlag,
      //メッセージ種別_提出リクエスト
      messageSubmissionRequest: submitCondition.messageSubmissionRequest,
      //メッセージ種別_締切変更
      messageDeadlineChange: submitCondition.messageDeadlineChange,
      //メッセージ種別_強制停止
      messageForcedStop: submitCondition.messageForcedStop,
      //メッセージ種別_My CareerBoxメッセージ
      messageMyCareerBox: submitCondition.messageMyCareerBox,
      //メッセージ種別_サンクス
      messageThanksMyCareerBox: submitCondition.messageThanksMyCareerBox,
      //メッセージ送信ID
      messageSendId: submitCondition.messageSendId,
      //sysバージョン番号
      sysVersionNumber: submitCondition.sysVersionNumber,
      // 初回表示フラグ
      firstDispFlag:'1'
    }
    dispatch(
      initMcbMessageOutBoxListSearchCondition(submitConditionOperationValues)
    )
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    history.push(routeList.mcbMessageOutBox)
  }

  const handleClickReservation = (event: any) => {
    event.preventDefault()
    dispatch(setMcbMessageReservationListSort('0'))
    dispatch(setButtonActiveFlagMCAZS180(0))
    dispatch(setSelectedFlagMCAZS180(0))
    dispatch(setMcbMessageReservationListPage(1))
    const operationValues = {
      searchTargetList: submitValueMCAZS180.searchTargetList,
      searchWordText: submitValueMCAZS180.searchWordText,
      reserveFromDate: submitValueMCAZS180.reserveFromDate,
      reserveToDate: submitValueMCAZS180.reserveToDate,
      attachmentExist: submitValueMCAZS180.attachmentExist,
      attachmentNot: submitValueMCAZS180.attachmentNot,
      onlyBulkSend: submitValueMCAZS180.onlyBulkSend,
      sort: submitValueMCAZS180.sort,
      countPage: 1,
      firstDispFlag:'1',
      selectedFlag:'0'
    }
    dispatch(initMcbMessageReservationListSearchCondition(operationValues))
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    history.push(routeList.mcbMessageReservation)
  }

  const handleClickDraft = (event: any) => {
    event.preventDefault()
    dispatch(setMcbMessageDraftListSort('0'))
    dispatch(setButtonActiveFlagMCAZS190(0))
    dispatch(setSelectedFlagMCAZS190('0'))
    dispatch(setMcbMessageDraftListPage(1))
    const operationValues = {
      searchTargetList: submitValue.searchTargetList,
      searchWordText: submitValue.searchWordText,
      updateFromDate: submitValue.updateFromDate,
      updateToDate: submitValue.updateToDate,
      attachmentExist: submitValue.attachmentExist,
      attachmentNot: submitValue.attachmentNot,
      onlyBulkSend: submitValue.onlyBulkSend,
      sort: submitValue.sort,
      countPage: 1,
      selectedFlag: '0',
      firstDispFlag:'1'
    }
    dispatch(initMcbMessageDraftListSearchCondition(operationValues))
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    history.push(routeList.mcbMessageDraft)
  }

  const handleClickDustbox = (event: any) => {
    event.preventDefault()
    dispatch(setMcbMessageDustboxListSort('0'))
    dispatch(setMcbButtonActiveDustboxFlag(0))
    dispatch(setMcbSelectedDustboxFlag('0'))
    dispatch(setMcbMessageDustboxListPage(1))
    const operationValues = {
      searchTargetList: dustMcbboxSubmitValue.searchTargetList,
      searchWordText: dustMcbboxSubmitValue.searchWordText,
      deleteFromDate: dustMcbboxSubmitValue.deleteFromDate,
      deleteToDate: dustMcbboxSubmitValue.deleteToDate,
      classificationSent: dustMcbboxSubmitValue.classificationSent,
      classificationDraft: dustMcbboxSubmitValue.classificationDraft,
      sort: dustMcbboxSubmitValue.sort,
      countPage: 1,
      selectedFlag: '0',
      firstDispFlag:'1'
    }
    dispatch(initMcbMessageDustboxListSearchCondition(operationValues))
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    history.push(routeList.mcbMessageDustBox)
  }
  // MCB リプレース #8010 対応 start
  const mcbReserveMsgCount = useSelector(
    (state: RootState) => state.mcbMessageList.mcbReserveCount
  )
  const mcbDraftMsgCount = useSelector(
    (state: RootState) => state.mcbMessageList.mcbDraftCount
  )
  // MCB リプレース #8010 対応 end
  return (
    <ul>
      <li>
        <a
          href={'#!'}
          onClick={handleClickOutbox}
          id={'mcazs170Reservation'}
          className={
            pageValue === 1
              ? `${'btn icon-outbox current'}`
              : `${'btn icon-outbox '}`
          }>
          送信トレイ
        </a>
      </li>
      <li>
        <a
          href={'#!'}
          onClick={handleClickReservation}
          id={'mcazs180Reservation'}
          className={
            pageValue === 2
              ? `${'btn icon-reservation current'}`
              : `${'btn icon-reservation '}`
          }>
          送信予約
          {mcbReserveMsgCount && mcbReserveMsgCount >= 1 ? (
            <i>{badgeFormatComma(mcbReserveMsgCount)}</i>
          ) : (
            ''
          )}
        </a>
      </li>
      <li>
        <a
          href={'#!'}
          onClick={handleClickDraft}
          id={'mcazs190Reservation'}
          className={
            pageValue === 3
              ? `${'btn icon-draft current'}`
              : `${'btn icon-draft '}`
          }>
          下書き
          {mcbDraftMsgCount && mcbDraftMsgCount >= 1 ? <i>{badgeFormatComma(mcbDraftMsgCount)}</i> : ''}
        </a>
      </li>
      <li>
        <a
          href={'#!'}
          onClick={handleClickDustbox}
          id={'mcazs200Reservation'}
          className={
            pageValue === 4
              ? `${'btn icon-dustbox current'}`
              : `${'btn icon-dustbox '}`
          }>
          ゴミ箱
        </a>
      </li>
    </ul>
  )
}
export default TabForMessageMycareerbox
