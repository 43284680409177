import request from 'utils/request'
import { MCAXS211InitRequest } from 'types/MCAXS211/MCAXS211InitRequest'
import { MCAXS211CreateRequest } from 'types/MCAXS211/MCAXS211CreateRequest'

export const MCAXS211InitRequestApi = (params: MCAXS211InitRequest) =>
    request({
        url: '/MCAXS211/init',
        method: 'post',
        data: params
    })

export const MCAXS211RegisterRequestApi = (params: MCAXS211CreateRequest) =>
    request({
        url: '/MCAXS211/register',
        method: 'post',
        data: params
    })