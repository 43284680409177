import { MCAZS230InitRequest } from 'types/MCAZS230/MCAZS230InitRequest'
import { MCAZS230RegisterRequest } from 'types/MCAZS230/MCAZS230RegisterRequest'
import request from 'utils/request'

export const initRequest = (apiData: MCAZS230InitRequest) => {
  return request({
    url: '/MCAZS230/init',
    method: 'post',
    data: apiData,
  })
}
export const redistRequest = (
  apiData: MCAZS230RegisterRequest,
  files: any[]
) => {
  const data = new FormData()
  data.append('sendRegister', encodeURIComponent(JSON.stringify(apiData)))
  const attachmentList = files
  if (attachmentList) {
    attachmentList.map(attachment => {
      if (attachment) {
        data.append('attachment', attachment)
      }
    })
  }

  return request({
    url: '/MCAZS230/sendRegister',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
