import { initRMFInfoApi, insertByRMFApiRequest } from 'apis/MCAXS340Api'
import {
  initRMFInfo,
  setinitRequest,
  insertByRMF,
  setDialogOpen,
  setRMFInfo,
} from 'reducers/recruitmentManagementFlagChangeReducer'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import {setUpdateFlag,setMCAXS340Open as setOpenManageListDialogMessageOthers } from 'reducers/selectionStatusUpdateReducer'
import { setOpenManageListDialog as setOpenManageListDialogSelectionStatusUpdate } from 'reducers/selectionStatusUpdateReducer'
import { setOpenManageListDialog as setOpenManageListDialogApplicantDetail } from 'reducers/applicantDetailForEmplymentReducer'
import { setOpenManageListDialog as setOpenManageListDialogEntryDetail } from 'reducers/entryDetailReducer'
import { setMCAXS340Open as setOpenManageListDialogChangeOthers } from 'reducers/changeOthersReducer'

export function* initRMFInfoSaga(action: ReturnType<typeof initRMFInfo>) {
  try {
    yield put(
      setRMFInfo({
        mcaxs340RecruitmentManagementFlagListResultDto: [],
        mcaxs340RecruitmentManagementFlagOptionListResultDto: [],
        mcaxs340RecruitmentManagementFlagCompanyListResultDto: [],
        mcaxs340RecruitmentManagementFlagUpdateCheckDto: [],
      })
    )
    yield put(
      setinitRequest({
        jobSeekerId: [],
        entryId: [],
        selectionManagementId: [],
        recruitmentManagementFlagSettingId: [],
        recruitmentManagementFlagOptionSettingId: [],
        settingString: [],
        settingNumber: [],
        recruitmentManagementDivision: ''
      })
    )
    const data = yield call(initRMFInfoApi, action.payload.request)
    yield put(setRMFInfo(data))
    yield put(setDialogOpen(true))
    switch (action.payload.id) {
      case 'MCAXS040':
        yield put(setOpenManageListDialogApplicantDetail(true))
        break
      case 'MCAXS050':
        yield put(setOpenManageListDialogEntryDetail(true))
        break
      case 'MCAYS020':
      case 'MCAYS030':
        yield put(setOpenManageListDialogSelectionStatusUpdate(true))
        break
      case 'MCAXS260':
        yield put(setOpenManageListDialogChangeOthers(true))
        break
      case 'MCAZS101':
        yield put(setOpenManageListDialogMessageOthers(true))
        break
      default:
        break
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* insertByRMFSaga(action: ReturnType<typeof insertByRMF>) {
  try {
    const message = yield call(insertByRMFApiRequest, encodeURIComponent(JSON.stringify(action.payload.request)))
    action.payload.onOk()
    // 次期開発12月 #51829 start
    action.payload.clearSelectedRFSettingId()
    // 次期開発12月 #51829 end
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(message))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* MCAXS340Saga() {
  yield all([
    takeEvery(initRMFInfo, initRMFInfoSaga),
    takeLeading(insertByRMF, insertByRMFSaga),
  ])
}
