import { call, put, all, takeEvery} from 'redux-saga/effects'
import { openModal } from 'reducers/messageReducer'
import { checkMcbServiceStatus, submissionReqTemplatecheck } from 'reducers/commonSettingsMenuReducer'
import { popupParams } from 'utils/misc'
import { checkMcbServiceStatusRequest } from 'apis/MCABS010Api'
import { routeList } from 'routes/routes'
import history from 'utils/history'
import axios from 'axios'
import { getToken } from 'utils/auth'

export default function* commonSettingsMenuSage() {
    // リンク押下時
    // MCBサービス停止チェック
    function* checkMcbServiceStatusSaga(action: ReturnType<typeof checkMcbServiceStatus>) {
        try {
            const data = yield call(checkMcbServiceStatusRequest,action.payload.title)
            const params = popupParams(1280, 948)
            const authToken = action.payload.authToken
            const sourceSystemType = '1'
            const sourceCompanyCode = action.payload.sourceCompanyCode
            // 26P #80795、88225 start
            let annualParam = ''
            if (action.payload.title === 'submissionsContentManagement') {
                const annual = action.payload.annual && action.payload.annual.length > 0 ? Number(action.payload.annual) : action.payload.annual
                const annualList = action.payload.annualList
                const annualCs = action.payload.annualCs != null ? action.payload.annualCs : ''
                annualParam = '&annual=' + annual + '&annualList=' + annualList + '&annualCs=' + annualCs
            }
            axios.get(action.payload.url + '?' + 'sourceSystemType=' + sourceSystemType + '&sourceCompanyCode=' + sourceCompanyCode + annualParam, {
                withCredentials: true,
                headers: {
                    Authorization: getToken(),
                },
            }).then(res => {
                let newWindow = window.open(action.payload.url + '?' + 'sourceSystemType=' + sourceSystemType + '&sourceCompanyCode=' + sourceCompanyCode +
                    annualParam,
                  action.payload.title,
                  params
                )
            }).catch(error => {
                console.log(error);
            })
            // 26P #80795、88225 end
        } catch (error) {
            yield put(openModal(error.message))
        }
    }
    // MCBサービス停止チェック
    function* submissionReqTemplatecheckSaga(action: ReturnType<typeof submissionReqTemplatecheck>) {
        try {
            const data = yield call(checkMcbServiceStatusRequest,action.payload)
            history.push(routeList.submissionReqTemplate)
        } catch (error) {
            yield put(openModal(error.message))
        }
    }

    yield all([
        takeEvery(checkMcbServiceStatus, checkMcbServiceStatusSaga),
        takeEvery(submissionReqTemplatecheck, submissionReqTemplatecheckSaga)
    ])
}
