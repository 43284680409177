import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface urlMappings {
  urls: string[];
}

const initialState = () => ({
  //イメジス_CAB_GAB_OPQ
  PDF_IMAGES_PATH: '',
  PDF_IMAGES_PATH_ALL: '',
  PDF_CAB_PATH: '',
  PDF_CAB_PATH_ALL: '',
  PDF_GAB_PATH: '',
  PDF_GAB_PATH_ALL: '',
  PDF_OPQ_PATH: '',
  PDF_OPQ_PATH_ALL: '',
  //面接場所登録、面接場所編集
  MCAO_MAP_SERCH_URL_OPEN: '',
  MCAO_MAP_IMAGE_URL: '',
  //040、050ShareURL
  MCAXS040_ShareURL: '',
  MCAXS050_ShareURL: '',
  //loginUrl
  LOGIN_URL: '',
  //　GET_METHOD_URL
  MCAXS141_GET_METHOD_URL: '',
  //new-graduate url
  NEW_GRADUATE_URL: '',
  //career url
  CAREER_URL: '',
  //w3 url
  W3_URL: '',
  //adobe url
  ADOBE_URL: '',
  // MIWS Web操作マニュアル rul
  OPER_MANUAL_RUL: '',
  // MIWS Web操作マニュアル rul(不採用通知)
  OPER_MANUAL_RUL_385: '',
  // MCBリプレース MCBHS161 START
  // オリジナル設問モーダルURL(MCB側)
  ORIGIN_MODAL_URL: '',
  // ドメイン
  MCB_COMMON_URL: '', 
  // MCBリプレース MCBHS161 END
  // MCBリプレース MCBLS020 START
  MCBLS020_GET_METHOD_URL: '',
  // MCBリプレース MCBLS020 END
  // 次期開発9月#51248 start
  MCARS030_HELP_URL_1:'',

  MCARS030_HELP_URL_2:'',
  // 次期開発9月#51248 end
  // MCBリプレース MCBHS190 START
  // MCBPDF出力マニュアルURL
  MCB_PDF_OUTPUT_MANUAL_URL:'',
  // MCBリプレース MCBHS190 END
})

const urlReducer = createSlice({
  name: 'url',
  initialState:initialState(),
  reducers: {
    getUrl(state, action: PayloadAction<urlMappings>){
      return state
    },
    setUrl(state, action: PayloadAction<any>){
      state = action.payload
      return state
    },
  },
})

export const {
  getUrl,
  setUrl,
} = urlReducer.actions
export default urlReducer.reducer