import request from 'utils/request'
import { MCAOS030UpdateRequest } from 'types/MCAOS030/MCAOS030UpdateRequest'
import { MCAOS030DeleteRequest } from 'types/MCAOS030/MCAOS030DeleteRequest'

export const getInterviewPlaceByIdApi = (interviewLocationId: string) =>
  request({
    url: `MCAOS030/init/${interviewLocationId}`,
    method: 'post',
  })

export const updateInterviewPlaceApi = (interview: MCAOS030UpdateRequest) =>
  request({
    url: '/MCAOS030/update',
    method: 'post',
    data: interview,
  })

export const deleteInterviewPlaceApi = (interview: MCAOS030DeleteRequest) =>
  request({
    url: '/MCAOS030/delete',
    method: 'post',
    data: interview,
  })

export const getMapUrl = (mapUrlCode: string) =>
  request({
    url: `/MCAOS030/getMap/${mapUrlCode}`,
    method: 'post',
  })
