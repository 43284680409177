import { call, put, all, takeEvery, select, takeLeading, takeLatest } from 'redux-saga/effects'
import { updateRequest, getInterviewListRequest, checkInterviewCountRequest } from 'apis/MCAOS010Api'
import { openSnackbar,openModal} from 'reducers/messageReducer'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import {
  setInterviewCreate, setInterviewList, updateInterviewSortOrder,
  getInterviewPlaceById, setCreateDialogOpen, setUpdateDialogOpen,
  setInterviewPlace, updateInterviewPlace, deleteInterviewPlace,
  getInterviewList, checkInterviewCount,
  getinterviewLocationResultList,
  setInterviewLocationList,checkInterviewLocation
} from 'reducers/interviewReducer'
import { setInterviewApi } from 'apis/MCAOS020Api'
import { getInterviewPlaceByIdApi, updateInterviewPlaceApi, deleteInterviewPlaceApi } from 'apis/MCAOS030Api'
import { magiContants } from 'utils/contants'
import {checkInterviewLocationApi,MCAZS130getDataApi} from 'apis/MCAZS130Api'
import { MCAZS130InitDisplayRequest } from 'types/MCAZS130/MCAZS130InitDisplayRequest'
// -----------------MCAZS130_面接場所指定_START------------------------//
export function* getInterviewLocationListRequest(action:ReturnType<typeof getinterviewLocationResultList>) {
  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
  try {
    const data = yield call(MCAZS130getDataApi,{...action.payload,recruitmentManagementDivision:recruitmentManagementDivision});
    yield put(setInterviewLocationList(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// -----------------MCAZS130_面接場所指定_END------------------------//

// -----------------MCAOS0I0_面接場所一覧_START------------------------//
export function* getInterviewListSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(getInterviewListRequest, recruitmentManagementDivision);
    yield put(setInterviewList(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* checkInterviewCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    yield call(checkInterviewCountRequest, recruitmentManagementDivision)
    yield put(setCreateDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* updateInterviewSortOrderSaga(action: ReturnType<typeof updateInterviewSortOrder>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.map((item) => (
      item.recruitmentManagementDivision = recruitmentManagementDivision
    ))
    yield call(updateRequest, action.payload);
    yield getInterviewListSaga();
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// -----------------MCAOS0I0_面接場所一覧_END------------------------//

// -----------------MCAOS020_面接場所登録_START------------------------//
function* setInterviewSaga(action: ReturnType<typeof setInterviewCreate>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const message = yield call(setInterviewApi, action.payload.param)
    if (action.payload.screenId === magiContants.SCREENID_MCAOS010) {
      yield getInterviewListSaga()
    } else if (action.payload.screenId === magiContants.SCREENID_MCAZS130) {
      const param: MCAZS130InitDisplayRequest = {
        recruitmentManagementDivision: recruitmentManagementDivision
      }
      const data = yield call(MCAZS130getDataApi, param);
      yield put(setInterviewLocationList(data));
    }
    yield put(setCreateDialogOpen(false))
    yield put(openSnackbar(message))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// -----------------MCAOS020_面接場所登録_END------------------------//

// -----------------MCAOS030_面接場所編集_START------------------------//
function* getInterviewPlaceBySettingId(action: ReturnType<typeof getInterviewPlaceById>) {
  try {
    const data = yield call(getInterviewPlaceByIdApi, action.payload);
    yield put(setUpdateDialogOpen(true));
    yield put(setInterviewPlace(data));
  } catch (error) {
    if (error.message === magiContants.MESSAGECODE_RESULT_NULL) {
      yield put(openSnackbar(magiContants.MESSAGECODE_RESULT_NULL))
    } else {
      yield put(openModal(error.message))
    }
  }
}

function* interviewPlaceUpdate(action: ReturnType<typeof updateInterviewPlace>) {
  try {
    const message = yield call(updateInterviewPlaceApi, action.payload);
    yield getInterviewListSaga();
    yield put(setUpdateDialogOpen(false));
    yield put(openSnackbar(message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* interviewPlaceDelete(action: ReturnType<typeof deleteInterviewPlace>) {
  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
  try {
    const message = yield call(deleteInterviewPlaceApi, action.payload);
    const data = yield call(getInterviewListRequest, recruitmentManagementDivision);
    yield put(setInterviewList(data));
    yield put(setUpdateDialogOpen(false));
    yield put(openSnackbar(message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
// -----------------MCAOS030_面接場所編集_END------------------------//
// -----------------MCAZS130_面接場所指定_START------------------------//
function* checkInterviewLocationSaga(action: ReturnType<typeof checkInterviewLocation>) {
  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
  try {
    const message = yield call(checkInterviewLocationApi,recruitmentManagementDivision);
    yield put(setCreateDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message));
    yield put(getinterviewLocationResultList({recruitmentManagementDivision:recruitmentManagementDivision}))
  }
}
// -----------------MCAZS130_面接場所指定_END------------------------//

export default function* interviewListSaga() {
  yield all([
    // -----------------MCAOS0I0_面接場所一覧_START------------------------//
    takeEvery(updateInterviewSortOrder, updateInterviewSortOrderSaga),
    takeEvery(checkInterviewCount, checkInterviewCountSaga),
    takeEvery(getInterviewList, getInterviewListSaga),
    // -----------------MCAOS0I0_面接場所一覧_END------------------------//

    // -----------------MCAOS020_面接場所登録_START------------------------//
    takeLeading(setInterviewCreate, setInterviewSaga),
    // -----------------MCAOS020_面接場所登録_END------------------------//

    // -----------------MCAOS030_面接場所編集_START------------------------//
    takeEvery(getInterviewPlaceById, getInterviewPlaceBySettingId),
    takeLeading(updateInterviewPlace, interviewPlaceUpdate),
    takeLeading(deleteInterviewPlace, interviewPlaceDelete),
    // -----------------MCAOS030_面接場所編集_END------------------------//
    // -----------------MCAZS130_面接場所指定_START------------------------//
    takeLatest(getinterviewLocationResultList, getInterviewLocationListRequest),
    takeLatest(checkInterviewLocation, checkInterviewLocationSaga),
    // -----------------MCAZS130_面接場所指定_END------------------------//
  ])
}
