import request from 'utils/request'
import MCAXS300InitRequest from 'types/MCAXS300/MCAXS300InitRequest'
import MCAXS300UploadImageRequest from 'types/MCAXS300/MCAXS300UploadImageRequest'

export const facePhotoQueryRequest = (initCondition: MCAXS300InitRequest) =>
request({
  url: '/MCAXS300/init',
  method: 'post',
  data: initCondition,
})

export const uploadFileRegisterRequest = (requestData:MCAXS300UploadImageRequest) => {
  const formData = new FormData()
  formData.append("uploadFiletmp", requestData.uploadFile)
  formData.append('jsonData', encodeURIComponent(JSON.stringify(requestData)))
  return request({
    url: `/MCAXS300/upload`,
    method: 'post',
    data: formData
  })
}
