import request from 'utils/request'

import { MCAZS180MessageQueryRequest } from 'types/MCAZS180/MCAZS180MessageQueryRequest'
import { MCAZS180MessageSelectRequest } from 'types/MCAZS180/MCAZS180MessageSelectRequest'

export const selectMcbMessageReservationRequest = (apiData: MCAZS180MessageQueryRequest) =>
request({
  url: '/MCAZS180/find',
  method: 'post',
  data: apiData,
})

export const initMcbReservationRequest = (apiData: MCAZS180MessageQueryRequest) =>
request({
  url: '/MCAZS180/init',
  method: 'post',
  data: apiData,
})

export const deleteMcbMessageReservationRequest = (apiData: MCAZS180MessageSelectRequest) =>
request({
  url: '/MCAZS180/moveDraftMessage',
  method: 'post',
  data: apiData,
})

export const sendMcbMessageReservationRequest = (apiData: MCAZS180MessageSelectRequest) =>
request({
  url: '/MCAZS180/sendMessage',
  method: 'post',
  data: apiData,
})

export const getMcbFailSelectionManagementApiMCAZS180 = (apiData: any) =>
  request({
  url:'/MCAZS180/showEntryList',
  method: 'post',
  data: apiData,
})

export const editMessageMCAZS180Request = (apiData: any) =>
request({
  url:'/MCAZS180/editMessage',
  method: 'post',
  data: apiData,
})

export const getSelectionManagementApiMCAZS180 = (apiData: any) =>
request({
url:'/MCAZS180/showSendObjEntryList',
method: 'post',
data: apiData,
})