import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCBAS020SearchConditionRequest } from 'types/MCBAS020/MCBAS020SearchConditionRequest'
import { MCBAS020InterviewScheduleRequest } from 'types/MCBAS020/MCBAS020InterviewScheduleRequest'
import { MCBAS020InterviewWebUrlRequest } from 'types/MCBAS020/MCBAS020InterviewWebUrlRequest'

export interface InterviewScheduleAdjustmentList {
  interviewScheduleAdjustmentId: number      // 面接日程調整ID
  selectionFlowSettingId: number             // 選考フロー設定ID
  progressStatusSettingId: number            // 選考ステップ設定ID
  facePhotoManagementId: string              // 顔写真管理ID
  facePhoto: string                          // 顔写真
  familyName: string                         // 姓
  name: string                               // 名
  fullName: string                           // 姓名(表示用)
  familyNameKana: string                     // 姓カナ
  nameKana: string                           // 名カナ
  jobSeekerId: number                        // 求職者ID
  id: string                                 // 表示用求職者ID
  starEvaluation: string                     // 星評価
  entryId: number                            // エントリーID
  applicationType: string                    // 応募職種
  applicationTypeShort: string               // 応募職種(略)
  interviewContents: string                  // 面接内容
  selectionFlow: string                      // 選考フロー
  selectionType: string                      // 選考種別
  selectionClassification: string            // 選考分類
  interviewTimeOrder: Date                   // 面接日時
  interviewScheduleAdjustmentStatus: string  // 面接日程調整ステータス
  statusSort: string                         // ステータス(ソート用)
  statusUpdateTime: string                   // ステータス更新日時
  updateTime: string                         // 更新日時(表示用)
  sendMessageId: number                      // 送信予約のメッセージID
  status: string                             // ステータス
  cancelMessageId: number                    // 応募者キャンセルのメッセージID
  sysVersionNumber: number                   // sysバージョン番号
  interviewSchedule: InterviewScheduleList[] // 面接日程
  selectionManagementId: number              // 選考管理ID
  entryIdForJump: number                     // エントリーID_画面遷移用
}

export interface InterviewScheduleList {
  interviewScheduleId: number                // 面接日程ID
  interviewStartTime: string                 // 面接開始日時
  interviewTime: string                      // 面接日時(表示用)
  interviewDurationId: number                // 面接所要時間ID
  requiredTime: string                       // 所要時間
  interviewLocationSettingId: number         // 面接場所設定ID
  interviewLocation: string                  // 面接場所
  address: string                            // 住所
  interviewPlaceShort: string                // 面接場所(略)
  mapUrl: string                             // 地図URL
  personInCharge: string                     // 担当者
  fixedFlag: string                          // 確定フラグ
  interviewScheduleStatus: string            // 面接日程ステータス
  // WEB面接対応 start
  interviewWebUrlId: string                  // WEB面接URLID
  interviewWebStaffUrl: string               // 担当者用WEB面接URL
  interviewWebJobSeekerUrl: string           // 応募者用WEB面接URL
  interviewWebEnableFlag: string             // WEB面接有効フラグ
  webSysVersionNumber: number                // WEB面接sysバージョン番号
  // WEB面接対応 end
}

export interface PlaceSearchList {
  [key: string]: string | number
  interviewLocationSettingId: number // 面接場所設定ID
  interviewLocationName: string      // 面接場所名
}

export interface SelectionFlowSearchList {
  [key: string]: string | number
  selectionFlowSettingId: number // 選考フロー設定ID
  selectionName: string          // 選考名
}

export interface ProgressList {
  [key: string]: string | number
  progressStatusSettingId: number // 選考ステップ設定ID
  progressName: string            // 進捗名
  selectionFlowSettingId: number  // 選考フロー設定ID
}

export interface MessageFlag {
    [key: string]: number
    messageFlagVal: number
}

const messageFlag = {
    messageFlagVal: -1,
}

interface InitialValuesType {
  interviewScheduleList: InterviewScheduleAdjustmentList[]
  placeSearchList: PlaceSearchList[]
  selectionFlowSearchList: SelectionFlowSearchList[]
  progressList: ProgressList[]
  selectionFlowFlag: string
  refreshFlag: number
  messageFlagResult: MessageFlag
  // WEB面接対応 start
  issueOverDialogOpen: boolean
  // WEB面接対応 end
}

const initialState: InitialValuesType = {
  interviewScheduleList: [],
  placeSearchList: [],
  selectionFlowSearchList: [],
  progressList: [],
  selectionFlowFlag: '1',
  refreshFlag: 0,
  messageFlagResult: messageFlag,
  // WEB面接対応 start
  issueOverDialogOpen: false,
  // WEB面接対応 end
}

const interviewScheduleSlice = createSlice({
  name: 'interviewSchedule',
  initialState,
  reducers: {
    // MCBAS020 画面初期表示(データ取得)
    getInterviewScheduleList(state, action: PayloadAction<MCBAS020SearchConditionRequest>) {
      return state
    },
    // MCBAS020 画面初期表示(一覧データ設定)
    setInterviewScheduleList(state, action: PayloadAction<InterviewScheduleAdjustmentList[]>) {
      state.interviewScheduleList = action.payload
      if (action.payload.length > 0) {
        state.messageFlagResult.messageFlagVal = 1
      } else {
          state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    // MCBAS020 画面初期表示(面接場所)
    setPlaceSearchList(state, action: PayloadAction<PlaceSearchList[]>) {
      state.placeSearchList = action.payload
      return state
    },
    // MCBAS020 画面初期表示(選考フロー)
    setSelectionFlowSearchList(state, action: PayloadAction<SelectionFlowSearchList[]>) {
      state.selectionFlowSearchList = action.payload
      return state
    },
    // MCBAS020 画面初期表示(選考ステップ)
    setProgressList(state, action: PayloadAction<ProgressList[]>) {
      state.progressList = action.payload
      return state
    },
    // MCBAS020 画面初期表示(選考フローフラグ)
    setSelectionFlowFlag(state, action: PayloadAction<string>) {
      state.selectionFlowFlag = action.payload
      return state
    },
    // MCBAS020 検索ボタン押下
    searchInterviewScheduleList(state, action: PayloadAction<MCBAS020SearchConditionRequest>) {
      return state
    },
    // MCBAS020 面接調整状態整合性チェック
    checkVersionNumber(state, action: PayloadAction<MCBAS020InterviewScheduleRequest>) {
      return state
    },
    // リフレッシュ
    setRefreshFlagOfInterview(state) {
      state.refreshFlag = state.refreshFlag === 0 ? 1 : 0
    },
    setMessageFlagResult(state, action: PayloadAction<number>) {
        state.messageFlagResult.messageFlagVal = action.payload
    },
    // WEB面接対応 start
    // MCBAS020 WEB面接URLを発行する。
    createInterviewWebUrl(state, action: PayloadAction<MCBAS020InterviewWebUrlRequest>) {
      return state
    },
    // MCBAS020 WEB面接URLをキャンセルする。
    cancelInterviewWebUrl(state, action: PayloadAction<MCBAS020InterviewWebUrlRequest>) {
      return state
    },
    // MCBAS020 「WEB面接URL発行完了」ダイアログを表示する。
    setIssueOverDialogOpen(state, action: PayloadAction<boolean>) {
      state.issueOverDialogOpen = action.payload
      return state
    },
    // WEB面接対応 end
  },
})

export const {
  // MCBAS020
  getInterviewScheduleList,
  setInterviewScheduleList,
  setPlaceSearchList,
  setSelectionFlowSearchList,
  setProgressList,
  setSelectionFlowFlag,
  searchInterviewScheduleList,
  checkVersionNumber,
  setRefreshFlagOfInterview,
  setMessageFlagResult,
  // WEB面接対応 start
  createInterviewWebUrl,
  cancelInterviewWebUrl,
  setIssueOverDialogOpen,
  // WEB面接対応 end
} = interviewScheduleSlice.actions
export default interviewScheduleSlice.reducer
