import { MCAXS290InitRequest } from "types/MCAXS290/MCAXS290InitRequest"

const MCAXS290initialValues: MCAXS290InitRequest = {
    jobSeekerId: [],
    entryIdList: [],
    recruitmentManagementDivision: '',
}
export interface entryHistoryList {
    // エントリー受付日時
    entryReceptionTime: string
    // 選考名
    selectionName: string
    // エントリー種別
    entryType: number
    // 進捗名
    progressName: string
    // 判定区分種別
    decisionDivisionType:string
    // 判定区分
    decisionDivision: number
    // 応募職種名
    entryJobCategoryName: string
    // 転職March #75621 START
    // 推薦更新フラグ
    recommendFlag : string
    //推薦日時
    recommendTime : string
    // 転職March #75621 END
}
const entryHistoryValues: entryHistoryList[] = []
export { MCAXS290initialValues, entryHistoryValues }