import {call,put,all,takeEvery} from 'redux-saga/effects'
import { openSnackbar,openModal} from 'reducers/messageReducer'
import {initRequest} from 'apis/MCAXS660Api'
import { init } from 'reducers/endReducer';

function* initSaga(action: ReturnType<typeof init>) {
    try {
        const data = yield call(initRequest);
    } catch (error) {
        yield put(openModal(error.message))
    }
}
export default function* MCAXS660Saga() {
    yield all([
      takeEvery(init, initSaga),
    ])
  }
