import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { submitCondition } from '../pages/MCAZS170/formConfig'
import { MCAZS170MessageQueryRequest } from 'types/MCAZS170/MCAZS170MessageQueryRequest'
import { MCAZS170MessageSelectRequest,McbMessageInfoListDto } from 'types/MCAZS170/MCAZS170MessageSelectRequest'


import { MCAZS200MessageQueryRequest } from 'types/MCAZS200/MCAZS200MessageQueryRequest'
import { MCAZS200MessageSelectRequest } from 'types/MCAZS200/MCAZS200MessageSelectRequest'
import { dustMcbboxSubmitValue, selectedMcbDraftListInit } from 'pages/MCAZS200/formConfig'


import { MCAZS180MessageQueryRequest } from 'types/MCAZS180/MCAZS180MessageQueryRequest'
import { submitValueMCAZS180 } from 'pages/MCAZS180/formConfig'
import { MCAZS180MessageSelectRequest, McbMessageDetailListDto } from 'types/MCAZS180/MCAZS180MessageSelectRequest'
import { MCAZS190MessageQueryRequest } from 'types/MCAZS190/MCAZS190MessageQueryRequest'
import { submitValue, selectedDraftListInit } from 'pages/MCAZS190/formConfig'
import { MCAZS190MessageSelectRequest } from 'types/MCAZS190/MCAZS190MessageSelectRequest'
import { displayProgressList, displaySelectionList } from './messageListReducer'
import { EditMessageMCAZS180TransferData } from 'pages/MCAZS180/Results'
import { EditMessageMCAZS190TransferData } from 'pages/MCAZS190/Results'

export interface ApplicantsListInfo {
  [key: string]: string

  jobSeekerId: string // 求職者ID
  entryId: string // エントリーID
  selectionManagementId: string // 選考管理ID
}

export interface MessageFlag {
  [key: string]: number
  messageFlagVal: number
}
const messageFlag = {
  messageFlagVal: -1,
}
// MCAZS170 Satrt
export interface MessageOutBox {
  [key: string]: string | number | Date | ApplicantsListInfo[] | boolean
  // メッセージID
  messageSendId: string
  // 一括送信フラグ
  bulkSendFlag: string
  // 画面用一括送信フラグ
  bulkSendFlagName: string
  // 姓
  familyName: string
  // 名
  name: string
  // 表示用求職者ID
  jobSeekerIdForDisplay: string
  // 送信結果（単体送信）
  sendingResult: string
  // 送信結果（単体送信）
  sendingResultName: string
  // 送信件数
  sendCount: number
  // 失敗件数
  failureCount: number
  // 送信結果（一括送信）
  sendingResultBulk: string
  // メッセージID
  mcbMessageId: string
  // 選考管理ID（一括送信）
  selectionManagementIdBulk: string
  // 退会日時
  unsubscribeTime: Date
  // 会員種別
  memberType: string
  // 会員種別
  memberTypeName: string
  // 送信先
  messageSendType: string
  //送信先区分
  destinationDivision: string
  // 既読フラグ
  readFlag: string
  // 既読フラグ
  readFlagName: string
  // メッセージ種別
  messageType: string
  // 画面用メッセージ種別
  messageTypeName: string
  // 応募種別
  entryType: string
  // 件名
  subject: string
  //MCBメッセージ送信ID(メッセージ添付ファイル)
  mcbMessageSendId: number
  // 選考フロー
  selectionName: string
  // 送信者
  fullName: string
  // 送信日時
  sendTime: string
  // システムバージョン番号
  sysVersionNumber: string
  // 選考管理ID
  selectionManagementId: string
  // エントリーID
  entryId: string
  // 求職者ID
  jobSeekerId: string
  //対象外フラグ
  nonTargetFlag: string
  //送受信区分
  sendAndReceiveDivision: string
  //変換バッチ完了フラグ
  convertingBatchCompleteFlag: string
  //送信用件名
  subjectForSending: string
  // メッセージ送信状態
  status: string
  //送信元会社名
  senderCompanyName: string
  //MCB利用ステータス
  mcbUseStatus: string
  //送信日時フラグ
  sendTimeFlag: string
  // 添付オプションタイトル
  attachedOptionTitle: string
  // MCB リプレース対応 #8043 start
  // MCBメッセージID
  mcbMessageMinId: number
  // MCB リプレース対応 #8043 end
}
// MCAZS170 End
// MCAZS170 Satrt
export interface messageReceiveList {
  [key: string]: string | number | Date | ApplicantsListInfo[] | boolean

  messageSendId: number //メッセージ送信ID
  messageId: number //メッセージID
  familyName: string //姓
  name: string //名
  jobSeekerIdForDisplay: string //表示用求職者ID
  readFlag: string //既読フラグ
  readFlagName: string //既読フラグ(表示用)
  repliedFlag: string //返信済みフラグ
  repliedFlagName: string //返信済みフラグ(表示用)
  unsubscribeTime: Date //退会日時
  messageType: string //メッセージ種別
  messageTypeName: string //メッセージ種別(表示用)
  subject: string //件名
  messageIdAttachment: number //メッセージID(メッセージ添付ファイル)
  progressName: string //進捗名
  decisionDivision: string //判定区分
  decisionDivisionName: string //判定区分(表示用)
  receivingTime: string //受信日時
  receivingTimeStr: string //受信日時(表示用)
  sysVersionNumber: number //システムバージョン番号
  labelFlag: string //背景色
  labelFlagGray: string //背景色再判定
  selectionManagementId: string // 選考管理ID
  entryId: string // エントリーID
  jobSeekerId: string // 求職者ID
  mediaFlag: string // 媒体フラグ
  displayFlag: boolean // 閲覧権限フラグ
}
// MCAZS170 End
// MCAZS190 satrt
export interface McbMessageDrafts {
  [key: string]: string | number | Date | ApplicantsListInfo[] | boolean
  // メッセージ送信ID
  messageSendId: string
  // MCBメッセージID
  mcbMessageId: string
  // 一括送信フラグ
  bulkSendFlag: string
  // 画面用一括送信フラグ
  bulkSendFlagName: string
  // 送信件数
  sendCount: number
  // 求職者ID
  jobSeekerId: string
  // エントリーID
  entryId: string
  // 選考管理ID
  selectionManagementId: string
  // 選考情報リスト
  applicantsListInfo: ApplicantsListInfo[]
  // 退会日時
  unsubscribeTime: Date
  // 会員種別
  memberType: string
  // 姓
  familyName: string
  // 名
  name: string
  // 送信先
  messageSendType: string
  // メッセージ種別
  messageType: string
  // 件名
  subject: string
  // 更新者
  fullName: string
  // 更新日時
  updateTime: string
  // システムバージョン番号
  sysVersionNumber: string
  // 閲覧権限フラグ
  displayFlag: boolean
  // 送受信区分
  sendAndReceiveDivision: string
  // 変換バッチ完了フラグ
  convertingBatchCompleteFlag: string
  // 送信用件名
  subjectForSending: string
  // ステータス
  status: string
  // 送信先区分
  destinationDivision: string
  // 送信結果
  sendingResult: string
  // MCB利用ステータス
  mcbUseStatus: string
  // 失敗件数
  failureCount: number
  // 企業別求職者ID
  jobSeekerByCompanyId: string
  // MCBメッセージ送信ID
  mcbMessageSendId: number
}
// MCAZS190 End
// MCAZS180 satrt
export interface mcbMessageInfoListDto {
  [key: string]: string | number | Date | ApplicantsListInfo[] | boolean
  // メッセージ送信ID
  messageSendId: number
  // メッセージID
  mcbMessageId: any
  // 一括送信フラグ
  bulkSendFlag: string
  // 画面用一括送信フラグ
  bulkSendFlagName: string
  // 送信件数
  sendCount: number
  // 失敗件数
  failureCount: number
  // 求職者ID
  jobSeekerId: string
  // エントリーID
  entryId: string
  // 背景色
  labelFlag: string
  // 選考管理ID
  selectionManagementId: string
  // 選考情報リスト
  applicantsListInfo: ApplicantsListInfo[]
  // 退会日時
  unsubscribeTime: Date
  // 会員種別
  memberType: string
  // 姓
  familyName: string
  // 名
  name: string
  // 表示用求職者ID
  jobSeekerIdForDisplay: number
  // 送信先
  messageSendType: string
  // 画面用送信先
  messageSendTypeName: string
  // メッセージ種別
  messageType: string
}
// MCAZS180 End
// MCAZS200 satrt
export interface McbDustboxMessages {
  [key: string]: string | number | Date | ApplicantsListInfo[] | boolean
  // メッセージ送信ID
  messageSendId: string
  // MCBメッセージID
  mcbMessageId: string
  // 一括送信フラグ
  bulkSendFlag: string
  // 画面用一括送信フラグ
  bulkSendFlagName: string
  // 送信件数
  sendCount: number
  // 求職者ID
  jobSeekerId: string
  // エントリーID
  entryId: string
  // 選考管理ID
  selectionManagementId: string
  // 選考情報リスト
  applicantsListInfo: ApplicantsListInfo[]
  // 退会日時
  unsubscribeTime: Date
  // 会員種別
  memberType: string
  // 姓
  familyName: string
  // 名
  name: string
  // 送信先
  messageSendType: string
  // メッセージ種別
  messageType: string
  // 件名
  subject: string
  // 更新者
  fullName: string
  // 更新日時
  updateTime: string
  // システムバージョン番号
  sysVersionNumber: string
  // 閲覧権限フラグ
  displayFlag: boolean
  // 送受信区分
  sendAndReceiveDivision: string
  // 変換バッチ完了フラグ
  convertingBatchCompleteFlag: string
  // 送信用件名
  subjectForSending: string
  // ステータス
  status: string
  // 送信先区分
  destinationDivision: string
  // 送信結果
  sendingResult: string
  // MCB利用ステータス
  mcbUseStatus: string
  // 失敗件数
  failureCount: number
  // 企業別求職者ID
  jobSeekerByCompanyId: string
}
// MCAZS200 End
interface MessageDraftList {
  // MCAZS170 Satrt
  pageChangeFlag: number
  messageFlagResult: MessageFlag
  messageReceiveListResults: messageReceiveList[]
  messageOutBoxListSearchCondition: MCAZS170MessageQueryRequest
  messageOutBoxListResults: MessageOutBox[]
  messageOutBoxListAllCount: number
  messageOutBoxListSort: string
  messageOutBoxListPage: number
  //全件選択フラグ
  outBoxSelectedFlag: number
  outBoxButtonActiveFlag: number
  selectedOutBoxList: MCAZS170MessageSelectRequest[]
  sendMessageFlag: number
  sendFromDateResult: string
// MCAZS170 End


  // ===========================================MCAZS200 satrt=================================================
  mcbMessageDustboxListSearchCondition: MCAZS200MessageQueryRequest
  mcbMessageDustboxListResults: McbDustboxMessages[]
  mcbMessageDustboxListAllCount: number
  mcbMessageDustboxListSort: string
  mcbMessageDustboxListPage: number
  mcbSelectedDustboxFlag: string
  mcbButtonActiveDustboxFlag: number
  mcbSelectedDustboxList: MCAZS200MessageSelectRequest
  mcbDeleteFromDateResult: string
  // ===========================================MCAZS200 end==================================================


  // MCAZS180 satrt
  mcbMessageReservationListSearchCondition: MCAZS180MessageQueryRequest
  mcbMessageReservationListSort: string
  buttonActiveFlagMCAZS180: number
  mcbMessageReservationListPage: number
  selectedFlagMCAZS180: number
  messageReservationListResults: McbMessageReservations[]
  mcbMessageReservationListAllCount: number
  reserveFromDateResult: string
  mcbMessageDetailListDto: McbMessageDetailListDto[]
  nomalBoxIconFlag:boolean
  firstDispFlag:string
  // MCAZS180 end
  // MCAZS190 satrt
  mcbMessageDraftListSearchCondition: MCAZS190MessageQueryRequest
  mcbMessageDraftListSort: string,
  selectedFlag: string,
  buttonActiveFlag: number,
  selectedDraftList: MCAZS190MessageSelectRequest,
  // MCB リプレース対応 B票#522対応 start
  mcbMessageDraftListPage: number,
  // MCB リプレース対応 B票#522対応 end
  updateFromDateResult: string,
  mcbMessageDraftListResults: McbMessageDrafts[],
  mcbMessageDraftListAllCount: number
  displaySelectionResults: displaySelectionList[],
  displayProgressResults: displayProgressList[],
  // MCBリプレイス #8782 START
  mcbReserveCount: number// Mcb送信予約件数
  mcbDraftCount: number // Mcb下書き件数
  receiveCount: number // 受信トレー件数
  reserveCount: number// 送信予約件数
  draftCount: number // 下書き件数
  // MCBリプレイス #8782 END
  // MCAZS190 end
  // MCAZS170 Satrt
  McbMessageInfoListDto: McbMessageInfoListDto[]
  // MCAZS170 End
}
const mcbMessageList: MessageDraftList = {
  pageChangeFlag: 0,
  messageReceiveListResults: [],
  messageOutBoxListSearchCondition: submitCondition,
  messageOutBoxListResults: [],
  messageOutBoxListAllCount: 0,
  messageOutBoxListSort: '0',
  messageOutBoxListPage: 1,
  outBoxSelectedFlag: 0,
  outBoxButtonActiveFlag: 0,
  selectedOutBoxList: [],
  sendMessageFlag: 1,
  sendFromDateResult: '',
  messageFlagResult: messageFlag,


  // ===========================================MCAZS200 satrt=================================================
  mcbMessageDustboxListSearchCondition: dustMcbboxSubmitValue,
  mcbMessageDustboxListResults: [],
  mcbMessageDustboxListAllCount: 0,
  mcbMessageDustboxListSort: '0',
  mcbMessageDustboxListPage: 1,
  mcbSelectedDustboxFlag: '0',
  mcbButtonActiveDustboxFlag: 0,
  mcbSelectedDustboxList: selectedMcbDraftListInit,
  mcbDeleteFromDateResult: '',
  // ===========================================MCAZS200 end===================================================
  // MCAZS180 satrt
  mcbMessageReservationListSearchCondition: submitValueMCAZS180,
  mcbMessageReservationListSort: '0',
  buttonActiveFlagMCAZS180: 0,
  mcbMessageReservationListPage: 1,
  selectedFlagMCAZS180: 0,
  messageReservationListResults: [],
  mcbMessageReservationListAllCount: 0,
  reserveFromDateResult: '',
  mcbMessageDetailListDto: [],
  nomalBoxIconFlag:false,
  firstDispFlag:'',
  // MCAZS180 end
  // MCAZS190 satrt
  mcbMessageDraftListSearchCondition: submitValue,
  mcbMessageDraftListSort: '0',
  selectedFlag: '0',
  buttonActiveFlag: 0,
  selectedDraftList: selectedDraftListInit,
  // MCB リプレース対応 B票#522対応 start
  mcbMessageDraftListPage: 1,
  // MCB リプレース対応 B票#522対応 end
  updateFromDateResult: '',
  mcbMessageDraftListResults: [],
  mcbMessageDraftListAllCount: 0,
  displaySelectionResults: [],
  displayProgressResults: [],
  McbMessageInfoListDto: [],
  // MCBリプレイス #8782 START
  mcbReserveCount: 0,// Mcb送信予約件数
  mcbDraftCount: 0,// Mcb下書き件数
  receiveCount: 0, // 受信トレー件数
  reserveCount: 0,// 送信予約件数
  draftCount: 0, // 下書き件数
  // MCBリプレイス #8782 END
}

const messageListSlice = createSlice({
  name: 'mcbMessageList',
  initialState: mcbMessageList,
  reducers: {
    setPageChangeFlag(state, action: PayloadAction<number>) {
      state.pageChangeFlag = action.payload
      return state
    },
    updateClickData(state, action: PayloadAction<number>) {
      const data = state.messageReceiveListResults
      const targetData = data.find(i => i.messageSendId === action.payload)
      if (targetData) {
        targetData.labelFlag = targetData.labelFlagGray
      }
      state.messageReceiveListResults = data
      return state
    },
    updaterepliedData(state, action: PayloadAction<number>) {
      const data = state.messageReceiveListResults
      const targetData = data.find(i => i.messageSendId === action.payload)
      if (targetData) {
        targetData.repliedFlag = '0'
        targetData.readFlag = '1'
        targetData.readFlagName = '既読'
        targetData.repliedFlagName = '未返信'
      }
      state.messageReceiveListResults = data
      return state
    },
    mcbOutBoxInit(state, action: PayloadAction<MCAZS170MessageQueryRequest>) {
      return state
    },
    setMessageOutBoxListResults(state, action: PayloadAction<MessageOutBox[]>) {
      state.messageOutBoxListResults = action.payload
      if (action.payload.length > 0) {
        state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    setMessageOutBoxListAllCount(state, action: PayloadAction<number>) {
      state.messageOutBoxListAllCount = action.payload
      return state
    },
    setMessageOutBoxListSort(state, action: PayloadAction<string>) {
      state.messageOutBoxListSort = action.payload
      return state
    },
    setMcbMessageOutBoxListSearchCondition(
      state,
      action: PayloadAction<MCAZS170MessageQueryRequest>
    ) {
      state.messageOutBoxListSearchCondition = action.payload
      return state
    },
    initMcbMessageOutBoxListSearchCondition(
      state,
      action: PayloadAction<MCAZS170MessageQueryRequest>
    ) {
      state.messageOutBoxListSearchCondition = action.payload
      return state
    },
    setMessageOutBoxListPage(state, action: PayloadAction<number>) {
      state.messageOutBoxListPage = action.payload
      return state
    },
    setSelectedOutBoxList(
      state,
      action: PayloadAction<McbMessageInfoListDto[]>
    ) {
      state.McbMessageInfoListDto = action.payload
      return state
    },
    setOutBoxSelectedFlag(state, action: PayloadAction<number>) {
      state.outBoxSelectedFlag = action.payload
      return state
    },
    setOutBoxButtonActiveFlag(state, action: PayloadAction<number>) {
      state.outBoxButtonActiveFlag = action.payload
      return state
    },
    deleteOutBoxMessage(
      state,
      action: PayloadAction<MCAZS170MessageSelectRequest>
    ) {
      return state
    },
    getFailSelectionManagementIdBulkList(
      state,
      action: PayloadAction<MessageOutBox>
    ) {
      return state
    },
    getSelectionManagementIdBulkListMCAZS170(
      state,
      action: PayloadAction<MessageOutBox>
    ) {
      return state
    },
    setSentFromDate(state, action: PayloadAction<string>) {
      state.sendFromDateResult = action.payload
      state.messageOutBoxListSearchCondition.sentFromDate = action.payload
      return state
    },
    setMessageFlagResult(state, action: PayloadAction<number>) {
      state.messageFlagResult.messageFlagVal = action.payload
	return state
    },
	
    setDisplaySelectionResults(
      state,
      action: PayloadAction<displaySelectionList[]>
    ) {
      state.displaySelectionResults = action.payload
      return state
    },
    setDisplayProgressResults(
      state,
      action: PayloadAction<displayProgressList[]>
    ) {
      state.displayProgressResults = action.payload
      return state
    },
    selectReceiveCount(state) {
      return state
    },
    selectReserveCount(state) {
      return state
    },
    selectDraftCount(state) {
      return state
    },
    // MCBリプレイス #8782 START
    selectMcbReserveCount(state) {
      return state
    },
    selectMcbDraftCount(state) {
      return state
    },
    // MCBリプレイス #8782 END
    getinitAreaOutBox(state) {
      return state
    },
    // MCBリプレイス #8782 START
    setReceiveCount(state, action: PayloadAction<number>) {
      state.receiveCount = action.payload
      return state
    },
    setReserveCount(state, action: PayloadAction<number>) {
      state.reserveCount = action.payload
      return state
    },
    setDraftCount(state, action: PayloadAction<number>) {
      state.draftCount = action.payload
      return state
    },
    // MCBリプレイス #8782 END
    // MCB リプレース #7965 再対応 start
    setMcbReserveCount(state, action: PayloadAction<number>) {
      state.mcbReserveCount = action.payload
      return state
    },
    setMcbDraftCount(state, action: PayloadAction<number>) {
      state.mcbDraftCount = action.payload
      return state
    },
    // MCB リプレース #7965 再対応 end

    // ===========================================MCAZS200 satrt=================================================
    setMcbMessageDustboxListResults(
      state,
      action: PayloadAction<McbDustboxMessages[]>
    ) {
      state.mcbMessageDustboxListResults = action.payload
      if (action.payload.length > 0) {
          state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    initMcbDustboxSelect(
      state,
      action: PayloadAction<MCAZS200MessageQueryRequest>
    ) {
      return state
    },
    setMcbMessageDustboxListSearchCondition(
      state,
      action: PayloadAction<MCAZS200MessageQueryRequest>
    ) {
      state.mcbMessageDustboxListSearchCondition = action.payload
      return state
    },
    initMcbMessageDustboxListSearchCondition(
      state,
      action: PayloadAction<MCAZS200MessageQueryRequest>
    ) {
      state.mcbMessageDustboxListSearchCondition = action.payload
      return state
    },
    setMcbMessageDustboxListAllCount(state, action: PayloadAction<number>) {
      state.mcbMessageDustboxListAllCount = action.payload
      return state
    },
    setMcbMessageDustboxListSort(state, action: PayloadAction<string>) {
      state.mcbMessageDustboxListSort = action.payload
      return state
    },
    setMcbMessageDustboxListPage(state, action: PayloadAction<number>) {
      state.mcbMessageDustboxListPage = action.payload
      return state
    },
    setMcbSelectedDustboxFlag(state, action: PayloadAction<string>) {
      state.mcbSelectedDustboxFlag = action.payload
      return state
    },
    setMcbButtonActiveDustboxFlag(state, action: PayloadAction<number>) {
      state.mcbButtonActiveDustboxFlag = action.payload
      return state
    },
    setMcbSelectedDustboxList(state,action: PayloadAction<MCAZS200MessageSelectRequest>
      ) {
        state.mcbSelectedDustboxList = action.payload
        return state
    },
    undoMcbDustboxMessage(
      state,
      action: PayloadAction<MCAZS200MessageSelectRequest>
    ) {
      return state
    },
    deleteMcbDustboxMessage(
      state,
      action: PayloadAction<MCAZS200MessageSelectRequest>
    ) {
      return state
    },
    getinitAreaDustBox(state) {
      return state
    },
    setMcbDeleteFromDate(state, action: PayloadAction<string>)
    {
      state.mcbDeleteFromDateResult = action.payload
      state.mcbMessageDustboxListSearchCondition.deleteFromDate = action.payload
      return state
    },
    getSelectionManagementIdBulkListMCAZS200(state,action: PayloadAction<any>) {
      return state
    },
    
    // ===========================================MCAZS200 end=================================================



    // MCAZS180 START
    deleteMessageMCAZS180(
      state,
      action: PayloadAction<MCAZS180MessageSelectRequest>
    ) {
      return state
    },
    sendMessageMCAZS180(
      state,
      action: PayloadAction<MCAZS180MessageSelectRequest>
    ) {
      return state
    },
    setMcbMessageReservationListSort(state, action: PayloadAction<string>) {
      state.mcbMessageReservationListSort = action.payload
      return state
    },
    initSelectMCAZS180(
      state,
      action: PayloadAction<MCAZS180MessageQueryRequest>
      ) {
      return state
      
    },
    // 初期検索条件
    initMcbMessageReservationListSearchCondition(
      state,
      action: PayloadAction<MCAZS180MessageQueryRequest>
    ) {
      state.mcbMessageReservationListSearchCondition = action.payload
      return state
    },
    setMcbMessageReservationListSearchCondition(
      state,
      action: PayloadAction<MCAZS180MessageQueryRequest>
    ) {
      state.mcbMessageReservationListSearchCondition = action.payload
      return state
    },
    // 一括操作ボタン活性判定
    setButtonActiveFlagMCAZS180(state, action: PayloadAction<number>) {
      state.buttonActiveFlagMCAZS180 = action.payload
      return state
    },
    setMcbMessageReservationListPage(state, action: PayloadAction<number>) {
      state.mcbMessageReservationListPage = action.payload
      return state
    },
    setSelectedFlagMCAZS180(state, action: PayloadAction<number>) {
      state.selectedFlagMCAZS180 = action.payload
      return state
    },
    getinitAreaReservation(state) {
      return state
    },
    setSelectedReservationList(
      state,
      action: PayloadAction<McbMessageDetailListDto[]>
    ) {
      state.mcbMessageDetailListDto = action.payload
      return state
    },
    setMcbMessageReservationListResults(
      state,
      action: PayloadAction<McbMessageReservations[]>
    ) {
      state.messageReservationListResults = action.payload
      if (action.payload.length > 0) {
          state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    getFailSelectionManagementIdBulkListMCAZS180(state,action: PayloadAction<McbMessageReservations>){
      return state
    },
    setReserveFromDate(state, action: PayloadAction<string>){
      state.reserveFromDateResult = action.payload
      state.mcbMessageReservationListSearchCondition.reserveFromDate = action.payload
      return state
    },
    setMcbMessageReservationListAllCount(state, action: PayloadAction<number>) {
      state.mcbMessageReservationListAllCount = action.payload
      return state
    },
    setNomalBoxIconFlag(state, action: PayloadAction<boolean>) {
      state.nomalBoxIconFlag = action.payload
      return state
    },
    // MCB リプレース #7964(B票#480,#481対応済)  start
    editMessageMCAZS180(state,action: PayloadAction<EditMessageMCAZS180TransferData>){
      return state
    },
    // MCB リプレース #7964(B票#480,#481対応済)  end
    getSelectionManagementIdBulkListMCAZS180(state,action: PayloadAction<McbMessageReservations>){
      return state
    },
    // MCAZS180 END
    // MCAZS190 START
    initMcbSelect(state, action: PayloadAction<MCAZS190MessageQueryRequest>) {
      return state
    },
    initMcbMessageDraftListSearchCondition(
      state,
      action: PayloadAction<MCAZS190MessageQueryRequest>
    ) {
      state.mcbMessageDraftListSearchCondition = action.payload
      return state
    },
    setMcbMessageDraftListSearchCondition(
      state,
      action: PayloadAction<MCAZS190MessageQueryRequest>
    ) {
      state.mcbMessageDraftListSearchCondition = action.payload
      return state
    },
    deleteMcbMessage(
      state,
      action: PayloadAction<MCAZS190MessageSelectRequest>
    ) {
      return state
    },
    setMcbMessageDraftListSort(state, action: PayloadAction<string>) {
      state.mcbMessageDraftListSort = action.payload
      return state
    },
    setSelectedFlagMCAZS190(state, action: PayloadAction<string>) {
      state.selectedFlag = action.payload
      return state
    },
    setButtonActiveFlagMCAZS190(state, action: PayloadAction<number>) {
      state.buttonActiveFlag = action.payload
      return state
    },
    setMcbSelectedDraftList(state,action: PayloadAction<MCAZS190MessageSelectRequest>
      ) {
        state.selectedDraftList = action.payload
        return state
    },
    setMcbMessageDraftListPage(state, action: PayloadAction<number>) {
      // MCB リプレース対応 B票#522対応 start
      state.mcbMessageDraftListPage = action.payload
      // MCB リプレース対応 B票#522対応 end
      return state
    },
    getinitAreaDraft(state) {
      return state
    },
    setMcbMessageDraftListResults(state, action: PayloadAction<McbMessageDrafts[]>) {
      state.mcbMessageDraftListResults = action.payload
      if (action.payload.length > 0) {
          state.messageFlagResult.messageFlagVal = 1
      } else {
        state.messageFlagResult.messageFlagVal = 0
      }
      return state
    },
    setMcbMessageDraftListAllCount(state, action: PayloadAction<number>) {
      state.mcbMessageDraftListAllCount = action.payload
      return state
    },
    setMcbUpdateFromDate(state, action: PayloadAction<string>){
      state.updateFromDateResult = action.payload
      // MCB リプレース対応 B票#522対応 start
      state.mcbMessageDraftListSearchCondition.updateFromDate = action.payload
      // MCB リプレース対応 B票#522対応 end
      return state
    },
    getSelectionManagementIdBulkListMCAZS190(state,action: PayloadAction<any>) {
      return state
    },
    // MCB リプレース #7964(B票#482,#483,#484,#485対応済)  start
    editMessageMCAZS190(state, action: PayloadAction<EditMessageMCAZS190TransferData>) {
      return state
    }
    // MCB リプレース #7964(B票#482,#483,#484,#485対応済)  end
    // MCAZS190 END
  },
})
export const {
  setPageChangeFlag,
  mcbOutBoxInit,
  setMcbMessageOutBoxListSearchCondition,
  initMcbMessageOutBoxListSearchCondition,
  setMessageOutBoxListResults,
  setMessageOutBoxListAllCount,
  setMessageOutBoxListSort,
  setMessageOutBoxListPage,
  setSelectedOutBoxList,
  setOutBoxSelectedFlag,
  setOutBoxButtonActiveFlag,
  deleteOutBoxMessage,
  getFailSelectionManagementIdBulkList,
  setSentFromDate,
  getSelectionManagementIdBulkListMCAZS170,
  setMessageFlagResult,
  selectReceiveCount,
  selectReserveCount,
  selectDraftCount,
  // MCBリプレイス #8782 START
  selectMcbReserveCount,
  selectMcbDraftCount,
  // MCBリプレイス #8782 END
  getinitAreaOutBox,


  // ===========================================MCAZS200 satrt=================================================
  setMcbMessageDustboxListResults,
  // 初期
  initMcbDustboxSelect,
  // "検索"ボタン押下
  setMcbMessageDustboxListSearchCondition,
  initMcbMessageDustboxListSearchCondition,
  // 初期検索総件数
  setMcbMessageDustboxListAllCount,
  setMcbMessageDustboxListSort,
  setMcbMessageDustboxListPage,
  setMcbSelectedDustboxFlag,
  setMcbButtonActiveDustboxFlag,
  setMcbSelectedDustboxList,
  undoMcbDustboxMessage,
  deleteMcbDustboxMessage,
  setMcbDeleteFromDate,
  getinitAreaDustBox,
  // 送信対象リンク押下
  getSelectionManagementIdBulkListMCAZS200,
  // 行選択時
  // editMessageMCAZS200,
  // ===========================================MCAZS200 end=================================================
  // MCAZS180 START
  // 初期検索条件
  initMcbMessageReservationListSearchCondition,
  setMcbMessageReservationListSearchCondition,
  getinitAreaReservation,

  // 初期
  initSelectMCAZS180,
  // "検索"ボタン押下
  setMcbMessageReservationListResults,
  // 送信予約期間（FROM）
  setReserveFromDate,
  // 初期検索総件数
  setMcbMessageReservationListAllCount,
  // 選択した情報
  setSelectedReservationList,
  // 送信をキャンセル
  deleteMessageMCAZS180,
  // すぐに送信"ボタン押下
  sendMessageMCAZS180,
  // 一括操作ボタン活性判定
  setButtonActiveFlagMCAZS180,
  // ソート順
  setMcbMessageReservationListSort,
  // 全件選択フラグ
  setSelectedFlagMCAZS180,
  // ページ数
  setMcbMessageReservationListPage,
  // 初回表示フラグ編集
  setNomalBoxIconFlag,
  // 行選択時
  editMessageMCAZS180,
  // "送信対象表示"リンク押下時
  getSelectionManagementIdBulkListMCAZS180,
  // "送信失敗表示"リンク押下時
  getFailSelectionManagementIdBulkListMCAZS180,
  // MCAZS180 END
  // MCAZS190 START
  // 初期
  initMcbSelect,
  initMcbMessageDraftListSearchCondition,
  // "検索"ボタン押下
  setMcbMessageDraftListSearchCondition,
  // "ソート順"リスト選択時
  setMcbMessageDraftListSort,
  // ダイアログの"ゴミ箱へ移動"ボタン押下時
  deleteMcbMessage,
  // 選択flag
  setSelectedFlagMCAZS190,
  setButtonActiveFlagMCAZS190,
  // 選択した情報
  setMcbSelectedDraftList,
  setMcbMessageDraftListPage,
  getinitAreaDraft,
  // 検索結果
  setMcbMessageDraftListResults,
  // 検索総件数
  setMcbMessageDraftListAllCount,
  // 更新日（FROM）
  setMcbUpdateFromDate,
  // 送信対象リンク押下
  getSelectionManagementIdBulkListMCAZS190,
  // 行選択時
  editMessageMCAZS190,
  // MCB リプレース #7965 再対応 start
  setMcbReserveCount,
  setMcbDraftCount,
  // MCB リプレース #7965 再対応 end
  // MCBリプレイス #8782 START
  setReceiveCount,
  setReserveCount,
  setDraftCount,
  // MCBリプレイス #8782 END
    // MCAZS190 END
} = messageListSlice.actions

export default messageListSlice.reducer
// MCAZS180 START
export interface McbMessageReservations {
  [key: string]: string | number | ApplicantsListInfo[] | boolean
  // 背景色
  labelFlag: string
  // メッセージ送信ID
  messageSendId: number
  // メッセージID
  mcbMessageId: number
  // 一括送信フラグ
  bulkSendFlag: string
  // 姓
  familyName: string
  // 名
  name: string
  // 企業別求職者ID
  jobSeekerByCompanyId: string
  // 送信件数
  sendCount: number
  // 送信件数
  failureCount: number
  // 退会日時
  unsubscribeTime: string
  // 送信結果
  sendingResult: string
  // 会員種別
  memberType: string
  // メッセージ送信種別
  messageSendType: string
  // メッセージ種別
  messageType: string
  // 件名
  subject: string
  // (MCBメッセージ添付ファイル)メッセージ送信ID
  mcbMessageSendId: number
  // 送信先区分
  destinationDivision: string
  // 氏名
  fullName: string
  // システムバージョン番号
  sysVersionNumber: number
  // 求職者ID
  jobSeekerId: string
  // 閲覧権限フラグ
  displayFlag : boolean
  // 初回表示フラグ
  nomalBoxIconFlag:boolean
  // 提出リクエストID
  submissionRequestId: string  
  // 選考情報リスト
  applicantsListInfo: ApplicantsListInfo[]
  // MCBリプレイス #8511 start
  // MCB提出リクエストID
  mcbSubmissionRequestId: string
  // MCBリプレイス #8511 end
}
// MCAZS180 END