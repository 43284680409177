import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import {
  getRecruitmentManagementDivision,
  getGlobalMenuData,
} from 'selectors/authSelectors'

import { magiContants } from 'utils/contants'
import { routeList } from 'routes/routes'
import {
  init,
  outputPdfdata,
  outputCsvdata,
  sendMessagedata,
  setOpenMCAXS111,
  openModel,
  openDialog,
  changerequestdeadlinedata,
  forcedStopdata,
  setState,
  toJobSeekManagedata,
} from 'reducers/bulkOperationReducer'

import {
  openSnackbar,
  openModal,
  openModalWithArg,
} from 'reducers/messageReducer'
import {
  setInitValues,
  setTemplateInfoList,
  setSelectionFlowList,
  setSelectionFlag,
  setSelectedTemplateInfo,
  TemplateInfo,
  SelectionFlowInfo,
} from 'reducers/csvOutputReducer'

import {
  initApi,
  outputPdfApi,
  outputCsvApi,
  sendMessageApi,
  toJobSeekManageApi,
  changerequestdeadlineApi,
  forcedstopApi,
} from 'apis/MCBHS180Api'
import { getInitApi } from 'apis/MCAXS111Api'

import { initialValues } from 'pages/MCAXS111/formConfig'

import { MCBHS180McbMessageRequest } from 'types/MCBHS180/MCBHS180McbMessageRequest'
import { MCBHS180ForcedStopRequest } from 'types/MCBHS180/MCBHS180ForcedStopRequest'
import { setMCBHS190OpenFlg } from 'reducers/mcbPdfDownloadReducer'
import { MCAXS111initRequest } from 'types/MCAXS111/MCAXS111initRequest'
import { MCBHS180ChangeRequestDeadlineRequest } from 'types/MCBHS180/MCBHS180ChangeRequestDeadlineRequest'

function* initSaga() {
  try {
    const data = yield call(initApi)
    yield put(setState(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* outputPdfSaga(action: ReturnType<typeof outputPdfdata>) {
  try {
    yield call(outputPdfApi, action.payload)
    
    window.localStorage.setItem(
      'pdfParam',
      JSON.stringify({
        submissionList:action.payload.submissionList,
        transitionKind: 1,
        applicantSortKind: action.payload.applicantSortKind,
        prevScreenId: 'MCBHS180',
      })
    )
    yield put(setMCBHS190OpenFlg(true))
  } catch (error) {
    const messResult: Array<string> = error.message.split(',')
    if (messResult.length == 1) {
      yield put(openModal(messResult[0]))
    } else {
      const [messageId, ...args] = messResult
      yield put(openModalWithArg({ messageId, args }))
    }
  }
}

function* outputCsvSaga(action: ReturnType<typeof outputCsvdata>) {
  try {
    yield call(outputCsvApi, action.payload)
     // MCAXS111 start
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    // MCBリプレース Lot3 start
    const param: MCAXS111initRequest = {
      jobSeekerIdList: [],
      entryIdList: [],
      selectionManagementIdList: action.payload.selectionManagementIdList,
      selectionFlowSettingIdList: action.payload.selectionFlowSettingIdList,
      requestList: action.payload.submissionRequestObjList,
      callDivision: '2',
    }
    const data: {
      templateInfo: TemplateInfo[]
      selectionFlowInfo: SelectionFlowInfo[]
    } = yield call(getInitApi, recruitmentManagementDivision, param)
    // MCBリプレース Lot3 end

    let tmpItem: any = {}
    if (data.templateInfo && data.templateInfo.length > 0) {
      tmpItem = data.templateInfo.find(
        (i: any) => i.defaultTemplateFlag === magiContants.STRING_1
      )
      if (tmpItem) {
        yield put(
          setInitValues({
            ...initialValues,
            entryDataIoTemplateSettingId: tmpItem.entryDataIoTemplateSettingId,
            templateName: tmpItem.templateName,
            templateItemList: tmpItem.templateItemList,
          })
        )
        yield put(
          setSelectedTemplateInfo({
            entryDataIoTemplateSettingId: tmpItem.entryDataIoTemplateSettingId,
            templateName: tmpItem.templateName,
          })
        )
        if (tmpItem.selectionFlowNecessaryFlag === magiContants.STRING_1) {
          yield put(setSelectionFlag(true))
        }
      } else {
        yield put(
          setInitValues({
            ...initialValues,
            entryDataIoTemplateSettingId:
              data.templateInfo[0].entryDataIoTemplateSettingId,
            templateName: data.templateInfo[0].templateName,
            templateItemList: data.templateInfo[0].templateItemList,
          })
        )
        yield put(
          setSelectedTemplateInfo({
            entryDataIoTemplateSettingId:
              data.templateInfo[0].entryDataIoTemplateSettingId,
            templateName: data.templateInfo[0].templateName,
          })
        )
        if (
          data.templateInfo[0].selectionFlowNecessaryFlag ===
          magiContants.STRING_1
        ) {
          yield put(setSelectionFlag(true))
        }
      }
    }
    yield put(setTemplateInfoList(data.templateInfo))
    yield put(setSelectionFlowList(data.selectionFlowInfo))
    yield put(setOpenMCAXS111(true))
  } catch (error) {
    const messResult: Array<string> = error.message.split(',')
    if (messResult.length == 1) {
      yield put(openModal(messResult[0]))
    } else {
      const [messageId, ...args] = messResult
      yield put(openModalWithArg({ messageId, args }))
    }
  }
}
function* changerequestdeadlineSaga(action: ReturnType<typeof changerequestdeadlinedata>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    // 締切変更へ遷移する
    const requestParam: MCBHS180ChangeRequestDeadlineRequest = {
      submissionContentIdList: action.payload.submissionContentIdList,
      submissionStatusList: action.payload.submissionStatusList,
      forceStopFlagList: action.payload.forceStopFlagList,
      myCareercd: action.payload.myCareercd,
      mcbUseStatus: action.payload.mcbUseStatus,
      searchCriteria: action.payload.searchCriteria,
      searchCriteriaName: action.payload.searchCriteriaName,
      recruitmentManagementDivision: action.payload.recruitmentManagementDivision,
      submissionRequestObjList: action.payload.submissionRequestObjList,
      myCareerCdMCBObjList:action.payload.myCareerCdMCBObjList,
    }
    yield call(changerequestdeadlineApi, action.payload)
    const submissionRequestObjListForcedStop = requestParam.submissionRequestObjList.map(
      item => {
        return {
          jobSeekerByCompanyId: item.jobSeekerByCompanyId,
          submissionRequestId: item.submissionRequestId,
        }
      }
    )
    const changeRequest = localStorage.getItem('changeRequest')
    localStorage.setItem(
      'changeRequest',
      changeRequest
        ? JSON.stringify({
            ...JSON.parse(changeRequest),
            jobSeekerByCompanyIdSubmissionRequestIdList: submissionRequestObjListForcedStop,
            searchCriteria: requestParam.searchCriteria,
            searchCriteriaName: requestParam.searchCriteriaName,
          })
        : JSON.stringify({
          jobSeekerByCompanyIdSubmissionRequestIdList: submissionRequestObjListForcedStop,
            searchCriteria: requestParam.searchCriteria,
            searchCriteriaName: requestParam.searchCriteriaName,
          })
    )

    window.localStorage.setItem(
      'globalMenuInfo',
      JSON.stringify(globalMenuInfo)
    )
    window.open(
      routeList.ChangeTermRequest,
      '_blank',
      'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' +
        1280 +
        ',height=' +
        948 +
        ',left=' +
        10 +
        ',top=' +
        10 +
        ''
    )
  } catch (error) {
    const messResult: Array<string> = error.message.split(',')
    if (messResult.length == 1) {
      yield put(openModal(messResult[0]))
    } else {
      const [messageId, ...args] = messResult
      yield put(openModalWithArg({ messageId, args }))
    }
  }
}

function* forcedstopSaga(action: ReturnType<typeof forcedStopdata>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    // 強制停止へ遷移する
    const requestParam: MCBHS180ForcedStopRequest = {
      submissionContentIdList: action.payload.submissionContentIdList,
      submissionStatusList: action.payload.submissionStatusList,
      forceStopFlagList: action.payload.forceStopFlagList,
      myCareercd: action.payload.myCareercd,
      mcbUseStatus: action.payload.mcbUseStatus,
      searchCriteria: action.payload.searchCriteria,
      searchCriteriaName: action.payload.searchCriteriaName,
      recruitmentManagementDivision: action.payload.recruitmentManagementDivision,
      submissionRequestObjList: action.payload.submissionRequestObjList,
      myCareerCdMCBObjList: action.payload.myCareerCdMCBObjList,
    }
    yield call(forcedstopApi, action.payload)
    const submissionRequestObjListForcedStop = requestParam.submissionRequestObjList.map(
      item => {
        return {
          jobSeekerByCompanyId: item.jobSeekerByCompanyId,
          submissionRequestId: item.submissionRequestId,
        }
      }
    )
    const forcedStopRequest = localStorage.getItem('forcedStopRequest')
    localStorage.setItem(
      'forcedStopRequest',
      forcedStopRequest
        ? JSON.stringify({
            ...JSON.parse(forcedStopRequest),
            jobSeekerByCompanyIdSubmissionRequestIdList: submissionRequestObjListForcedStop,
            searchCriteria: requestParam.searchCriteria,
            searchCriteriaName: requestParam.searchCriteriaName,
          })
        : JSON.stringify({
          jobSeekerByCompanyIdSubmissionRequestIdList: submissionRequestObjListForcedStop,
            searchCriteria: requestParam.searchCriteria,
            searchCriteriaName: requestParam.searchCriteriaName,
          })
    )

    window.localStorage.setItem(
      'globalMenuInfo',
      JSON.stringify(globalMenuInfo)
    )
    window.open(
      routeList.forcedStopSubmissionRequest,
      '_blank',
      'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' +
        1280 +
        ',height=' +
        948 +
        ',left=' +
        10 +
        ',top=' +
        10 +
        ''
    )
  } catch (error) {
    const messResult: Array<string> = error.message.split(',')
    if (messResult.length == 1) {
      yield put(openModal(messResult[0]))
    } else {
      const [messageId, ...args] = messResult
      yield put(openModalWithArg({ messageId, args }))
    }
  }
}

function* sendMessageSaga(action: ReturnType<typeof sendMessagedata>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {   
    // メッセージ入力へ遷移する
    const requestParam: MCBHS180McbMessageRequest = {
      submissionContentIdList: action.payload.submissionContentIdList,
      submissionStatusList: action.payload.submissionStatusList,
      forceStopFlagList: action.payload.forceStopFlagList,
      myCareercd: action.payload.myCareercd,
      mcbUseStatus: action.payload.mcbUseStatus,
      searchCriteria: action.payload.searchCriteria,
      searchCriteriaName: action.payload.searchCriteriaName,
      recruitmentManagementDivision:
        action.payload.recruitmentManagementDivision,
      submissionRequestObjList: action.payload.submissionRequestObjList,
      myCareerCdMCBObjList: action.payload.myCareerCdMCBObjList,
    }
    yield call(sendMessageApi, requestParam)
    const messageInputRequest = localStorage.getItem('messageInputRequest')
    localStorage.setItem(
      'messageInputRequest',
      messageInputRequest
        ? JSON.stringify({
            ...JSON.parse(messageInputRequest),
            ...requestParam,
          })
        : JSON.stringify(requestParam)
    )

    window.localStorage.setItem(
      'globalMenuInfo',
      JSON.stringify(globalMenuInfo)
    )
    window.open(
      routeList.mcbMessageSend,
      '_blank',
      'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' +
        1280 +
        ',height=' +
        948 +
        ',left=' +
        10 +
        ',top=' +
        10 +
        ''
    )
  } catch (error) {
    const messResult: Array<string> = error.message.split(',')
    if (messResult.length == 1) {
      yield put(openModal(messResult[0]))
    } else {
      const [messageId, ...args] = messResult
      yield put(openModalWithArg({ messageId, args }))
    }
  }
}
function* toJobSeekManageSaga(action: ReturnType<typeof toJobSeekManagedata>) {
  try {
    yield call(toJobSeekManageApi, action.payload)
   
  } catch (error) {
    const messResult: Array<string> = error.message.split(',')
    if (messResult.length == 1) {
      yield put(openModal(messResult[0]))
    } else {
      const [messageId, ...args] = messResult
      yield put(openModalWithArg({ messageId, args }))
    }
  }
}
export default function* bulkOperationSaga() {
  yield all([
    takeEvery(init, initSaga),
    takeEvery(outputPdfdata, outputPdfSaga),
    takeEvery(outputCsvdata, outputCsvSaga),
    takeEvery(sendMessagedata, sendMessageSaga),
    takeEvery(changerequestdeadlinedata, changerequestdeadlineSaga),
    takeEvery(forcedStopdata, forcedstopSaga),
    takeEvery(toJobSeekManagedata, toJobSeekManageSaga),
    
  ])
}
