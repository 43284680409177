/* eslint-disable prefer-const */
import { call, put, all, takeLatest } from 'redux-saga/effects'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  mcaxs191InitReducer,
  mcaxs201InitReducer,
  mcaxs201AutoInputReducer,
  setMcaxs201AutoInputReducer,
  mcaxs201VocationalSchoolMajorSelectReducer,
  setMcaxs201VocationalSchoolMajorSelectReducer,
  mcaxs201CheckReducer,
  mcaxs201GraduateSchoolDepartmentReducer,
  mcaxs201GraduateSchoolDepartmentSeelectReducer,
  mcaxs201JuniorCollegeSubjectSelectReducer,
  setMcaxs201GraduateSchoolDepartmentReducer,
  mcaxs201SchoolInitialOkReducer,
  setMcaxs201SchoolInitialOkReducer,
  setMCAXS230InitRedecer,
  mcaxs230InitReducer,
  setMCAXS230InitResult,
  mcaxs211InitReducer,
  setMCAXS211InitReducer,
  mcaxs211CreateReducer,
  mcaxs650InitReducer,
  setMCAXS650InitReducer,
  mcaxs650UpdateReducer,
  setNameInputNextReducer,
  mcaxs230AutoInfoReducer,
  setMcaxs230AutoInfoReducer,
  vocationalSchoolMajorSelectRedecer,
  setVocationalSchoolMajorSelectRedecer,
  mcaxs230CheckReducer,
  graduateSchoolDepartmentReducer,
  setGraduateSchoolDepartmentReducer,
  graduateSchoolDepartmentSeelectReducer,
  juniorCollegeSubjectSelectReducer,
  schoolInitialOkReducer,
  setSchoolInitialOkReducer,
  setMCAXS230InitDataResult,
} from 'reducers/jobSeekerInfoReducer'
import {
  initRequest,
  mcaxs230AutoInputRequest,
  mcaxs230VocationalSchoolMajorSelectRequest,
  mcaxs230CheckRequest,
  mcaxs230GraduateSchoolDepartmentRequest,
  mcaxs230GraduateSchoolDepartmentSeelectRequest,
  mcaxs230JuniorCollegeSubjectSelectRequest,
  mcaxs230SchoolInitialOkRequest,
} from 'apis/MCAXS230Api'
import {
  MCAXS211InitRequestApi,
  MCAXS211RegisterRequestApi,
} from 'apis/MCAXS211Api'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import {
  MCAXS650InitRequestApi,
  MCAXS650RegisterRequestApi,
} from 'apis/MCAXS650Api'
import { MCAXS191NameInputRequestApi, MCAXS191InitApi } from 'apis/MCAXS191Api'
import {
  MCAXS201AutoInputRequestApi,
  MCAXS201VocationalSchoolMajorSelectRequestApi,
  mcaxs201GraduateSchoolDepartmentApi,
  mcaxs201GraduateSchoolDepartmentSeelectApi,
  mcaxs201JuniorCollegeSubjectSelectApi,
  mcaxs201SchoolInitialOkApi,
  MCAXS201CheckRequestApi,
} from 'apis/MCAXS201Api'

// MCAXS191
// 画面初期表示
function* mcaxs191InitSaga(action: ReturnType<typeof mcaxs191InitReducer>) {
  try {
    yield call(MCAXS191InitApi)
    const path = {
      pathname: routeList.jobSeekerNameInput,
      state: action.payload,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCAXS191
// 次へ進むボタン押下する
function* mcaxs201InitSaga(action: ReturnType<typeof mcaxs201InitReducer>) {
  try {
    const data = yield call(MCAXS191NameInputRequestApi, action.payload)
    yield put(setNameInputNextReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS201
// "郵便番号から自動入力する"ボタン押下する
function* mcaxs201VocationalSchoolMajorSelectSaga(
  action: ReturnType<typeof mcaxs201VocationalSchoolMajorSelectReducer>
) {
  try {
    const data = yield call(MCAXS201VocationalSchoolMajorSelectRequestApi, action.payload)
    yield put(setMcaxs201VocationalSchoolMajorSelectReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS201
// "専門学校_専攻分野"ボタン押下する
function* mcaxs201AutoInputSaga(
  action: ReturnType<typeof mcaxs201AutoInputReducer>
) {
  try {
    const data = yield call(MCAXS201AutoInputRequestApi, action.payload)
    yield put(setMcaxs201AutoInputReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS201
// 大学院_研究科・専攻名ボタン押下する
function* mcaxs201GraduateSchoolDepartmentSaga(
  action: ReturnType<typeof mcaxs201GraduateSchoolDepartmentReducer>
) {
  try {
    const data = yield call(mcaxs201GraduateSchoolDepartmentApi, action.payload)
    yield put(setMcaxs201GraduateSchoolDepartmentReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS201
// 大学_学部・学科名ボタン押下する
function* mcaxs201GraduateSchoolDepartmentSeelectSaga(
  action: ReturnType<typeof mcaxs201GraduateSchoolDepartmentSeelectReducer>
) {
  try {
    const data = yield call(
      mcaxs201GraduateSchoolDepartmentSeelectApi,
      action.payload
    )
    yield put(setMcaxs201GraduateSchoolDepartmentReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS201
// 短大_学科名ボタン押下する
function* mcaxs201JuniorCollegeSubjectSelectSaga(
  action: ReturnType<typeof mcaxs201JuniorCollegeSubjectSelectReducer>
) {
  try {
    const data = yield call(
      mcaxs201JuniorCollegeSubjectSelectApi,
      action.payload
    )
    yield put(setMcaxs201GraduateSchoolDepartmentReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS201
// 学校名ボタン押下する
function* mcaxs201SchoolInitialOkSaga(
  action: ReturnType<typeof mcaxs201SchoolInitialOkReducer>
) {
  try {
    const data = yield call(mcaxs201SchoolInitialOkApi, action.payload)
    yield put(setMcaxs201SchoolInitialOkReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS201_入力内容を確認する"ボタン押下
function* mcaxs201CheckSaga(action: ReturnType<typeof mcaxs201CheckReducer>) {
  try {
    yield call(MCAXS201CheckRequestApi, action.payload)
    const path = {
      pathname: routeList.jobSeekerInfoInsertConfirm,
      state: action.payload,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 応募者情報編集-画面遷移
function* jobSeekerMigrationSaga(
  action: ReturnType<typeof setMCAXS230InitRedecer>
) {
  try {
    yield call(initRequest, action.payload)
    const path = {
      pathname: routeList.jobSeekerInfoEdit,
      state: action.payload,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 応募者情報編集-画面初期表示
function* jobSeekerInitSaga(action: ReturnType<typeof mcaxs230InitReducer>) {
  try {
    const data = yield call(initRequest, action.payload)
    yield put(setMCAXS230InitResult(data))
    yield put(setMCAXS230InitDataResult(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 応募者情報登録 内容確認-画面初期表示
function* jobSeekerInsertConfirmInitSaga(
  action: ReturnType<typeof mcaxs211InitReducer>
) {
  try {
    const data = yield call(MCAXS211InitRequestApi, action.payload)
    yield put(setMCAXS211InitReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 応募者情報登録 内容確認-登録するボタン押下
function* jobSeekerInsertConfirmSaga(
  action: ReturnType<typeof mcaxs211CreateReducer>
) {
  try {
    const jobSeekerId = yield call(MCAXS211RegisterRequestApi, action.payload)
    let ids: string[] = []
    ids[0] = jobSeekerId
    // 登録するは正常終了した場合、
    // 氏名入力元画面に戻り共通方針の完了バナーメッセージを表示する。
    history.push(routeList.jobSeekerNameInput)
    yield put(
      openSnackbar(getMessage(magiContants.MESSAGECODE_MCAXS211_006, ids))
    )
  } catch (error) {
    if (error.message) {
      const path = {
        pathname: routeList.jobSeekerLogIn,
        state: {
          surname: action.payload.surname, // 姓
          firstName: action.payload.firstName, // 名
          screenId: '', // バージョン番号
        },
      }
      yield history.push(path)
    }

    // 登録するは異常終了した場合、
    yield put(openModal(error.message))
  }
}
// 応募者情報編集 内容確認-画面初期表示
function* jobSeekerUpdateConfirmInitSaga(
  action: ReturnType<typeof mcaxs650InitReducer>
) {
  try {
    const data = yield call(MCAXS650InitRequestApi, action.payload.params1)
    yield put(setMCAXS650InitReducer(data))
  } catch (error) {
    const path = {
      pathname: routeList.jobSeekerInfoEdit,
      state: {
        prevDisplayId: action.payload.params2.prevDisplayId,
        jobSeekerId: action.payload.params2.jobSeekerId,
        entryId: action.payload.params2.entryId,
        selectionManagementId: action.payload.params2.selectionManagementId,
        recruitmentManagementDivision:
          action.payload.params2.recruitmentManagementDivision,
        initFlag: '1',
      },
    }
    yield history.push(path)
    yield put(openModal(error.message))
  }
}

// 応募者情報編集 内容確認-更新するボタン押下
function* jobSeekerUpdateConfirmSaga(
  action: ReturnType<typeof mcaxs650UpdateReducer>
) {
  try {
    yield call(MCAXS650RegisterRequestApi, action.payload.params1)
    // 更新するは正常終了した場合、
    history.push(routeList.applicantDetailForEmplyment)
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCAXS650_005)))
  } catch (error) {
    if (error.message) {
      const path = {
        pathname: routeList.jobSeekerInfoEdit,
        state: {
          prevDisplayId: action.payload.params2.prevDisplayId,
          jobSeekerId: action.payload.params2.jobSeekerId,
          entryId: action.payload.params2.entryId,
          selectionManagementId: action.payload.params2.selectionManagementId,
          recruitmentManagementDivision:
            action.payload.params2.recruitmentManagementDivision,
          initFlag: '1',
        },
      }
      yield history.push(path)
    }
    // 更新するは異常終了した場合、
    yield put(openModal(error.message))
  }
}

// 郵便番号から自動入力する
function* mcaxs230AutoInfoSaga(
  action: ReturnType<typeof mcaxs230AutoInfoReducer>
) {
  try {
    const data = yield call(mcaxs230AutoInputRequest, action.payload)
    yield put(setMcaxs230AutoInfoReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 専門学校_専攻分野_選択する
function* vocationalSchoolMajorSelectSaga(
  action: ReturnType<typeof vocationalSchoolMajorSelectRedecer>
) {
  try {
    const data = yield call(mcaxs230VocationalSchoolMajorSelectRequest, action.payload)
    yield put(setVocationalSchoolMajorSelectRedecer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 更新内容を確認する"ボタン押下
function* mcaxs230CheckSaga(action: ReturnType<typeof mcaxs230CheckReducer>) {
  try {
    yield call(mcaxs230CheckRequest, action.payload.params1)
    const path = {
      pathname: routeList.jobSeekerInfoEditConfirm,
      state: action.payload,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* mcaxs230GraduateSchoolDepartmentSaga(
  action: ReturnType<typeof graduateSchoolDepartmentReducer>
) {
  try {
    const data = yield call(
      mcaxs230GraduateSchoolDepartmentRequest,
      action.payload
    )
    yield put(setGraduateSchoolDepartmentReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* mcaxs230GraduateSchoolDepartmentSeelectSaga(
  action: ReturnType<typeof graduateSchoolDepartmentSeelectReducer>
) {
  try {
    const data = yield call(
      mcaxs230GraduateSchoolDepartmentSeelectRequest,
      action.payload
    )
    yield put(setGraduateSchoolDepartmentReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* mcaxs230JuniorCollegeSubjectSelectSaga(
  action: ReturnType<typeof juniorCollegeSubjectSelectReducer>
) {
  try {
    const data = yield call(
      mcaxs230JuniorCollegeSubjectSelectRequest,
      action.payload
    )
    yield put(setGraduateSchoolDepartmentReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* mcaxs230SchoolInitialOkSaga(
  action: ReturnType<typeof schoolInitialOkReducer>
) {
  try {
    const data = yield call(mcaxs230SchoolInitialOkRequest, action.payload)
    yield put(setSchoolInitialOkReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* jobSeekerInfoSaga() {
  yield all([
    takeLatest(mcaxs191InitReducer, mcaxs191InitSaga),
    takeLatest(mcaxs201InitReducer, mcaxs201InitSaga),
    takeLatest(mcaxs201AutoInputReducer, mcaxs201AutoInputSaga),
    takeLatest(
      mcaxs201VocationalSchoolMajorSelectReducer,
      mcaxs201VocationalSchoolMajorSelectSaga
    ),
    takeLatest(mcaxs201CheckReducer, mcaxs201CheckSaga),
    takeLatest(
      mcaxs201GraduateSchoolDepartmentReducer,
      mcaxs201GraduateSchoolDepartmentSaga
    ),
    takeLatest(
      mcaxs201GraduateSchoolDepartmentSeelectReducer,
      mcaxs201GraduateSchoolDepartmentSeelectSaga
    ),
    takeLatest(
      mcaxs201JuniorCollegeSubjectSelectReducer,
      mcaxs201JuniorCollegeSubjectSelectSaga
    ),
    takeLatest(mcaxs201SchoolInitialOkReducer, mcaxs201SchoolInitialOkSaga),
    takeLatest(setMCAXS230InitRedecer, jobSeekerMigrationSaga),
    takeLatest(mcaxs230InitReducer, jobSeekerInitSaga),
    takeLatest(mcaxs211InitReducer, jobSeekerInsertConfirmInitSaga),
    takeLatest(mcaxs211CreateReducer, jobSeekerInsertConfirmSaga),
    takeLatest(mcaxs650InitReducer, jobSeekerUpdateConfirmInitSaga),
    takeLatest(mcaxs650UpdateReducer, jobSeekerUpdateConfirmSaga),
    takeLatest(mcaxs230AutoInfoReducer, mcaxs230AutoInfoSaga),
    takeLatest(
      vocationalSchoolMajorSelectRedecer,
      vocationalSchoolMajorSelectSaga
    ),
    takeLatest(mcaxs230CheckReducer, mcaxs230CheckSaga),
    takeLatest(
      graduateSchoolDepartmentReducer,
      mcaxs230GraduateSchoolDepartmentSaga
    ),
    takeLatest(
      graduateSchoolDepartmentSeelectReducer,
      mcaxs230GraduateSchoolDepartmentSeelectSaga
    ),
    takeLatest(
      juniorCollegeSubjectSelectReducer,
      mcaxs230JuniorCollegeSubjectSelectSaga
    ),
    takeLatest(schoolInitialOkReducer, mcaxs230SchoolInitialOkSaga),
  ])
}
