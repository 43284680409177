import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  getInit,
  newTemplete,
  orverWrite,
} from 'reducers/mcbMessageSendCompReducer'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import { initRequest, newRequest, orverWriteRequest } from 'apis/MCAZS240Api'
import { getMessage } from 'common/messageUtil'

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    yield call(initRequest, action.payload)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* newSaga(action: ReturnType<typeof newTemplete>) {
  try {
    const formData = action.payload.formData
    yield call(newRequest, formData)
    yield put(openSnackbar(getMessage('MCAZS240-009')))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* orverWriteSaga(action: ReturnType<typeof orverWrite>) {
  try {
    const formData = action.payload.formData
    yield call(orverWriteRequest, formData)
    yield put(openSnackbar(getMessage('MCAZS240-010')))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* messageInputSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(newTemplete, newSaga),
    takeLatest(orverWrite, orverWriteSaga),
  ])
}
