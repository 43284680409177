import { MCAXS100SendHistoryCommentRequest } from "types/MCAXS100/MCAXS100SendHistoryCommentRequest"

export interface commentList {
    [key: string]: string | Date | number
    evaluationCommentId: number
    comment: string
    recordTime: Date
    recorder: string
}
const commentHistoryListinitialValues: commentList[] = []
const sendCommentHistoryInitialValues: MCAXS100SendHistoryCommentRequest = {
    jobSeekerId: 0,
    entryId: 0,
    selectionManagementId: 0,
}
export { commentHistoryListinitialValues, sendCommentHistoryInitialValues }