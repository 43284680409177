import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialValues } from 'pages/MCAUS010/formConfig'
import { act } from 'react-dom/test-utils'
import { MCAUS010UpdateReportRequest } from 'types/MCAUS010/MCAUS010ReportRequest'

export interface enterErrorReport {
  errorReportId: string
  generationStatus: string
  sysVersionNumber: number
  sysRegistrantId: string
  companyAccountId: string
}

const errorReport = createSlice({
  name: 'errorReport',
  initialState: initialValues,
  reducers: {
    //初期表示時
    initErrorReport(state, action: PayloadAction<string>) {
      return state
    },
    //初期表示後の値付け操作
    initSuccess(state, action: PayloadAction<enterErrorReport>) {
      state = action.payload
      return state
    },
    //発生状況更新
    updateErrorReport(state, action: PayloadAction<MCAUS010UpdateReportRequest>) {
      return state
    },
  },
})

export const { initErrorReport, initSuccess, updateErrorReport } = errorReport.actions
export default errorReport.reducer
