const accountTypeList = [
  { value: '0', label: '(以下から選択)' },
  { value: '20', label: '管理者' },
  { value: '30', label: '準管理者' },
  { value: '40', label: '一般ユーザ' },
  { value: '50', label: '制限ユーザ' },
]

const initialValues = {
  name: '',
  department: '',
  mailAddress: '',
  tel: '',
  accountType: { value: '0', label: '(以下から選択)' },
  recruitmentGroup: [],
  forbidDownload: '0',
  forbidMessageReply: '0',
  // 次期開発12月 #75981 start
  initialDisplayYear: '0',
  // 次期開発12月 #75981 end
};
export {initialValues, accountTypeList} 
