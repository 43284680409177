import { getMessage } from "common/messageUtil"
import { magiContants } from "utils/contants"

export const initialValues = {
  companyAccountId:'',
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
}

// 年度対応 start
export const listener = (ev: {
  preventDefault: () => void
  returnValue: string
}) => {
  ev.preventDefault()
  ev.returnValue = getMessage(magiContants.MESSAGECODE_MCACS020_005)
}
// 年度対応 end