import React, { useEffect, useState } from 'react'

interface Size {
  width?: number
  height?: number
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>()

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])



  return windowSize
}

export default useWindowSize