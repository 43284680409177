/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { CSSProperties, HTMLAttributes, useEffect } from 'react'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import { createStyles, emphasize, makeStyles, Theme, useTheme, } from '@material-ui/core/styles'
import { Chip, MenuItem, Typography } from '@material-ui/core'
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import CreatableSelect from 'react-select/creatable'
import {components as componentsML, MenuListComponentProps} from 'react-select'
import { ValueContainerProps } from 'react-select/src/components/containers'
import { ControlProps } from 'react-select/src/components/Control'
import { NoticeProps } from 'react-select/src/components/Menu'
import { MultiValueProps } from 'react-select/src/components/MultiValue'
import { OptionProps } from 'react-select/src/components/Option'
import { PlaceholderProps } from 'react-select/src/components/Placeholder'
import { SingleValueProps } from 'react-select/src/components/SingleValue'
import { Omit } from '@material-ui/types'
import { magiContants } from 'utils/contants'
import customSelectOptions from 'utils/customSelectOptions'

export interface OptionType {
    label: string
    value: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            height: 250,
            minWidth: 290,
        },
        input: {
            display: 'flex',
            padding: theme.spacing(0.5, 1),
            height: 'auto',
            backgroundColor: '#ffffff',
            cursor: 'pointer',
        },
        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
            ' & > div:last-child': {
              width: '0px',
              margin: '0px',
            },
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
            fontSize: '14px',
        },
        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[700],
                0.08
            ),
        },
        noOptionsMessage: {
            padding: theme.spacing(1, 2),
        },
        singleValue: {
            fontSize: 16,
        },
        placeholder: {
            position: 'absolute',
            left: 2,
            bottom: 6,
            fontSize: 16,
        },
        divider: {
            height: theme.spacing(2),
        },
        helperText: {
            fontSize: '11px',
            marginTop: '8px',
            minHeight: '1em',
            textAlign: 'left',
            fontFamily:
                'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            fontWeight: 400,
            lineHeight: '1em',
            letterSpacing: '0.33px',
            color: '#e53935',
            margin: '8px 14px 0',
            whiteSpace: 'pre-wrap',
        },
        valueContainerForDisabled: {
            margin: theme.spacing(0.5, 0.25),
            fontSize: '14px',
            color: '#999999',
        },
        fontStyle1: {
            color: '#000000',
        },
        fontStyle2: {
            color: '#999999',
        },
    })
)

function NoOptionsMessage(props: NoticeProps<OptionType>) {
    return (
        <Typography
            color='textSecondary'
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
    HTMLAttributes<HTMLDivElement>

function inputComponent({ inputRef, ...props }: InputComponentProps) {
    return <div ref={inputRef} {...props} />
}

function Control(props: ControlProps<OptionType>) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props

    const { form } = TextFieldProps
    const { errors, touched } = form

    return (
        <TextField
            fullWidth
            variant='outlined'
            margin='normal'
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
            error={Boolean(errors["accountType"] && errors["accountType"].value && touched["accountType"])}
            helperText={errors["accountType"] && errors["accountType"].value && touched["accountType"] ? errors["accountType"].value : null}
        />
    )
}

function Option(props: OptionProps<OptionType>) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            {...{component: 'div'} as any}
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}>
            {props.children}
        </MenuItem>
    )
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, 'innerProps'> &
    Partial<Pick<PlaceholderProps<OptionType>, 'innerProps'>>

function SingleValue(props: SingleValueProps<OptionType>) {
    return (
        <Typography
            className={props.selectProps.classes.singleValue}
            {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
    return (
        <div className={props.selectProps.classes.valueContainer}>
            {props.children}
        </div>
    )
}

function MultiValue(props: MultiValueProps<OptionType>) {
    return (
        <Chip
            tabIndex={-1}
            label={<a title={props.data.value ? props.data.value : ''} className={props.selectProps.isDisabled ? props.selectProps.classes.fontStyle2 : props.selectProps.classes.fontStyle1}> {props.data.value && props.data.value.length > 30 ? props.data.value.substring(0, 30) + '...' : props.children} </a>}
            className={props.selectProps.isDisabled ? clsx(props.selectProps.classes.valueContainerForDisabled, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            }) : clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    )
}

function MenuList (props: MenuListComponentProps<any>) {
  return (
    <componentsML.MenuList {...props}>
      <div style={{display:'inline-block',minWidth:'100%'}}>
        {props.children}
      </div>
    </componentsML.MenuList>
  )
}

const components = {
    Control,
    MultiValue,
    NoOptionsMessage,
    Option,
    SingleValue,
    ValueContainer,
    MenuList,
}

interface Props extends FieldProps {
    options: OptionType[]
    placeholder?: string
    multipul?: boolean
    recruitmentGroup: string
    select?: any
    defaultValue?: any
    disabled: boolean
}

const TypeSuggestSelect = ({
    field,
    form,
    options,
    multipul,
    placeholder = '',
    select,
    defaultValue,
    disabled,
}: Props) => {
    const classes = useStyles()
    const theme = useTheme()
    const { name, value } = field
    const { errors, touched } = form
    useEffect(() => {
        if (defaultValue != null) {
            form.setFieldValue(name, defaultValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])
    const selectStyles = {
        input: (base: CSSProperties) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        })
    }
    const handleChange = (e: any) => {
        if (select !== undefined) {
            if (e != null) {
                select(e)
            }
        }
        for (var i in e) {
            let vars
            if (e[i].label.length === undefined) {
                vars = e[i].value
                e[i].label = vars
            }
        }
        form.setFieldValue(name, e)
        form.setFieldTouched(name, true, true)
    }

    return (
        <>
            <CreatableSelect
                classes={classes}
                styles={selectStyles}
                TextFieldProps={{
                    InputLabelProps: {
                        htmlFor: 'react-select-multiple',
                        shrink: true,
                    },
                    form,
                    field,
                }}
                menuPlacement={'auto'}
                menuPosition={'absolute'}
                isMulti={multipul}
                placeholder={placeholder}
                options={options}
                components={components}
                value={value}
                onChange={handleChange}
                isDisabled={disabled}
                noOptionsMessage={({ inputValue }) => {
                    return magiContants.SUGGEST_NO_OPTIONS_MESSAGE
                  }}
                formatCreateLabel={(inputValue) => (<p>"{inputValue}"{magiContants.MESSAGEINFO_NEW_REGULATIONS_ADD}</p>)}
                { ...customSelectOptions as any }
            />
            {errors[name] && touched[name] ? (
                <p className={classes.helperText}>{errors[name]}</p>
            ) : null}
        </>
    )
}

export default TypeSuggestSelect