import request from 'utils/request'
import { MCAXS271InitRequest } from 'types/MCAXS271/MCAXS271InitRequest'
import { MCAXS271AddSelectionRequest } from 'types/MCAXS271/MCAXS271AddSelectionRequest'

export const initRequest = (param: MCAXS271InitRequest) =>
  request({
    url: `/MCAXS271/init`,
    method: 'post',
    data: param,
  })

export const insertRequest = (params: MCAXS271AddSelectionRequest) =>
request({
    url: `MCAXS271/addSelection`,
    method: 'post',
    data: params,
})