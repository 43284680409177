import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormGroup } from '@material-ui/core'
import { FieldProps } from 'formik'
import { useErrorStyle } from 'componentsHsc'
import { Option } from './Config/searchConditionConfig'

export const useStyles = makeStyles(theme => ({
  root: {
    cursor:'pointer!important',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0 !important',
    paddingRight: '8px',
    '& input:checked + mark': {
      backgroundColor: '#3f71bd',
      backgroundPosition: 'center center',
      borderColor: '#3f71bd',
    },
    '& input:disabled + mark': {
      backgroundColor: '#e0e0e0 !important',
      borderColor: '#cccccc !important',
      pointerEvents: 'none !important',
    },
    '& input:disabled ~ span': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  mark: {
    height: '16px !important',
    width: '16px !important',
    marginRight: '8px !important',
  },
}))

interface Props extends FieldProps {
  optionList: Option[]
  rootClassName?: string
  groupClassName?: string
  row?: boolean
  disabled?: boolean
  relatedName? :string
  index?: number
}

const GroupOptions = ({
  field,
  form,
  optionList,
  rootClassName,
  groupClassName,
  row,
  disabled,
  relatedName = '',
  index,
}: Props) => {
  const classes = useStyles()
  const errorClasses = useErrorStyle()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || []

  const handleChange = (option: Option) => (e: any) => {
    form.setFieldValue(
      name,
      e.target.checked
        ? [...value, option]
        : value.filter((i: any) => i.label !== option.label)
    )
    if (relatedName) {
      if (e.target.checked) {
        if (undefined !== index && form.values.conditionList[index].managementItemPresenceFlag !== '2') {
          form.setFieldValue(relatedName, '0')
        }
      }
    }
  }

  return (
    <FormControl
      className={rootClassName}
      error={Boolean(errors[name] && touched[name])}>
      <FormGroup className={groupClassName} row={row}>
        {optionList.map(i => (
          <label key={i.value} className={classes.root}>
            <input
              type='checkbox'
              disabled={disabled}
              checked={value.map((x: any) => x.label).indexOf(i.label) !== -1}
              onChange={handleChange(i)}
            />
            <mark className={classes.mark}></mark>
            <span>{i.label}</span>
          </label>
        ))}
      </FormGroup>
      {errors[name] && touched[name] ? (
        <p className={errorClasses.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default GroupOptions
