import request from 'utils/request'
import { MCALS010TemplateTypeRequest } from 'types/MCALS010/MCALS010TemplateTypeRequest'
import { MCALS010TemplateIdRequest } from 'types/MCALS010/MCALS010TemplateIdRequest'
import { MCALS010BulkOperationRequest } from 'types/MCALS010/MCALS010BulkOperationRequest'
import { MCALS010DisplayOrderRequest } from 'types/MCALS010/MCALS010DisplayOrderRequest'
import { MCALS010IdRequest } from 'types/MCALS010/MCALS010IdRequest'

// 画面初期表示
export const templateInitRequestApi = (params: MCALS010IdRequest) =>
  request({
    url: '/MCALS010/init',
    method: 'post',
    data: params,
  })
// テンプレート種別"ラジオボタン押下
export const templateTypeSerachRequestApi = (
  params: MCALS010TemplateTypeRequest
) =>
  request({
    url: '/MCALS010/changeTemplate',
    method: 'post',
    data: params,
  })
// 新規登録ボタン押下
export const templateTourokuRequestApi = (params: MCALS010TemplateIdRequest) =>
  request({
    url: '/MCALS010/newCreat',
    method: 'post',
    data: params,
  })
// リンクボタン押下
export const templateLinkRequestApi = (params: MCALS010TemplateIdRequest) =>
  request({
    url: '/MCALS010/edit',
    method: 'post',
    data: params,
  })
// コピーボタン押下
export const templateCopyRequestApi = (params: MCALS010TemplateIdRequest) =>
  request({
    url: '/MCALS010/copy',
    method: 'post',
    data: params,
  })
// 選択対象を表示の状態に変更する
export const templateUpdateEnableApi = (
  params: MCALS010BulkOperationRequest[]
) =>
  request({
    url: '/MCALS010/operateBulkDisplay',
    method: 'post',
    data: params,
  })
// 選択対象を非表示の状態に変更する
export const templateUpdateDisableApi = (
  params: MCALS010BulkOperationRequest[]
) =>
  request({
    url: '/MCALS010/operateBulkHide',
    method: 'post',
    data: params,
  })
// 表示順変更する
export const templateOrderApi = (params: MCALS010DisplayOrderRequest[]) =>
  request({
    url: '/MCALS010/order',
    method: 'post',
    data: params,
  })

// "フォーマットダウンロード"ボタン押下時
export const templateFormatDownloadApi = (params: MCALS010TemplateIdRequest) =>
  request({
    url: '/MCALS010/formatDownload',
    method: 'post',
    responseType:"blob",
    data: params,
})
