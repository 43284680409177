import { MCBHS040SearchRequest } from 'types/MCBHS040/MCBHS040SearchRequest'
// import { MCAXS010UpdateJudgmentRequest } from 'types/MCAXS010/MCAXS010UpdateJudgmentRequest'
import {
  initSearchCondition,
  SearchCondition,
} from './searchConditionConfig'
import { MCBIS020SearchRequest } from 'types/MCBIS020/MCBIS020SearchRequest'

export interface SpecificConditionInfo {
  specificConditionName: string //特定条件名
  specificConditionSelectionManagementIdList: string[] //特定条件選考管理IDリスト
}
export const initOption = {
  label: '',
  value: '',
}

export interface SubmissionRequestsInfo {
  jobSeekerId: string //求職者ID
  entryId: string | null //エントリーID
  selectionManagementId: string | null //選考管理ID
}

/** My検索設定情報 */
export interface MySearch {
  entrySearchCriteriaSettingId: string //エントリー検索条件設定ID
  searchCriteria: string //検索条件
  searchCriteriaName: string //検索条件名
  searchCriteriaDisplay: string //検索条件(表示用)
}

/** フィルタ入力初期値 */
export const initialValues = {
  nameForm: '',
}

export interface Option {
  label: string
  value: string
}


export interface SortCondition {
  order: string
}

/** 検索結果　(検索リクエストに対するレスポンス内容) */
export interface SearchResult {
  submissionRequestListItemInfo: SubmissionRequestListItemInfo[] //提出リクエスト一覧項目情報
  submissionRequestsListInfo: SubmissionRequestsListInfo[] //提出リクエスト一覧リスト情報
  searchCondition: string | null //検索条件JSON
  searchConditionDisplay: string //検索条件(表示用)
  searchCriteriaName: string | null //検索条件名
  displayInfo: Data[] //画面表示情報
  totalCount: number // 検索結果件数
  displayLimitOptionInfo: DisplayLimitOptionInfo[] //検索上限設定選択肢情報
  unlimitedTotalCount: number // 表示上限を考慮しない結果件数
  isManagementOffice: boolean // 運営事務局判定
  sourceFunctionId: string | null // 呼出し側機能ID
  errorCode: string | null //エラーコード
}

/** 一覧画面遷移元画面からの入力情報 */
export interface ScreenTransitionInput {
  screenId: string // 呼出し側機能ID
  requestList: RequestObj[] //提出リクエストリスト
  targetYear: string // 対象年度
  submissionStatus: string // 提出物状況
  submissionContentId: string //提出物内容ID
  submissionContentName: string //提出物内容名
  entrySearchCriteriaSettingId: string //検索条件設定ID
  searchCondition: any // 検索条件JSON
  // 26KH #89294 START
  submissionDateFrom: string // 最終提出日From
  submissionDateTo: string // 最終提出日To
  // 26KH #89294 END
}

/** 一覧画面遷移元画面からの提出リクエスト入力情報 */
export interface RequestObj {
  jobSeekerByCompanyId: string // 企業別求職者ID
  requestId: string | null //提出リクエストID
}

/** 提出リクエスト一覧項目情報 */
export interface SubmissionRequestListItemInfo {
  [key: string]: string | any
  getTableId: string //取得テーブルID
  displayOrder: number //表示順
  itemName: string //項目名
  submissionRequestListDisplayItemId: string //提出リクエスト一覧表示項目ID
}

/**提出リクエスト一覧リスト情報 */
export interface SubmissionRequestsListInfo {
  [key: string]: string | any
  choiceListItem: string //選択
  jobSeekerId: string //求職者ID
  familyName: string //姓
  name: string //名
  schoolName: string //学校名
  facultyName: string //学部名
  subjectName: string //学科名
  humanitiesAndSciencesDivision: string //文理区分
  humanitiesAndSciencesDivisionString: string //文理区分表示文字
  familyNameKana: string //姓カナ
  nameKana: string //名カナ
  graduationPlanTimeName: string //卒業予定時期名称
  schoolTypeCode: string //学校種別コード
  manualRegistrationFlag: string //手動登録フラグ
  coordinationFormerJobSeekerIdentificationKey: string //連携元求職者識別キー
  postalCode1: string //郵便番号（現住所）
  prefecturesCode1: string //都道府県コード（現住所）
  addressAfterCity1: string //市区町村以降の住所（現住所）
  prefecturesCode1String: string	//都道府県コード（現住所）表示文字
  postalCode2: string //郵便番号（休暇中住所）
  prefecturesCode2: string //都道府県コード（休暇中住所）
  addressAfterCity2: string //市区町村以降の住所（休暇中住所）
  prefecturesCode2String: string	//都道府県コード（休暇中住所）表示文字
  mailAddress: string //メールアドレス
  mailAddressSub: string //メールアドレス（サブ）
  phoneNumber1: string //電話番号（自宅）
  phoneNumber2: string //電話番号（休暇中）
  phoneNumber3: string //電話番号（携帯）
  jobSeekerIdUnsubscribe: string //会員・非会員
  schoolGroupName: string //学校グループ名称
  schoolTypeName: string //学校種別名称
  collegeMasterDisplayOrder: string //大学マスタ表示順
  jobSeekerByCompanyId: string //企業別求職者ID
  jobSeekerIdForDisplay: string //表示用求職者ID
  mcbUseStatus: string //MCB利用ステータス
  submissionRequestId: string //提出リクエストID
  submissionContentId: string //提出物内容ID
  submissionContentName: string //提出物内容名
  submissionEntrySheetFlag: string //提出物エントリシートフラグ
  submissionResumeFlag: string //提出物履歴書フラグ
  submissionResearchSummaryFlag: string //提出物研究概要書フラグ
  submissionOriginalQuestionFlag: string //提出物オリジナル設問フラグ
  submissionFileSubmissionFlag: string //提出物ファイル提出フラグ
  requestStatus: string //リクエスト状況
  sendTime: Date //送信日時
  submissionDeadlineKindRequest: string //提出締切_種別（MCB提出リクエスト）
  submissionDeadlineDayRequest: Date //提出締切_日（MCB提出リクエスト）
  submissionDeadlineHourRequest: number //提出締切_時（MCB提出リクエスト）
  submissionDeadlineKindStatus: string //提出締切_種別（MCB提出状況）
  submissionDeadlineDayStatus: Date //提出締切_日（MCB提出状況）
  submissionDeadlineHourStatus: number //提出締切_時（MCB提出状況）
  resubmitReceptionFlag: string //再提出受付フラグ
  deadlineChangeFlag: string //締切変更フラグ
  submissionDatetime: Date //提出日時
  submissionTimes: number //提出回数
  forceStopFlag: string //強制停止フラグ
  submissionStatus: string //提出状況
  mycareercd: string //My CareerID
  mcbMemberId: string //MCB会員ID
  mcbSubmissionRequestId: string //MCB提出リクエストID
  authenticationKey: string //認証キー
  entryId: string //エントリーID
  selectionManagementId: string //選考管理ID
  mcbMessageId: string //MCBメッセージID
  messageSendId: string //メッセージ送信ID
  readFlg: string //既読フラグ
  sendingResult: string //送信結果
  selectionFlowSettingId: string //選考フロー設定ID
  jsbcIdSrId: string //企業別求職者ID提出リクエストID
  // 26KH #89293 START
  originJobSeekerId: string //名寄せ前求職者ID：リクエスト時点の求職者ID
  originJobSeekerByCompanyId: string //名寄せ前企業別求職者ID：リクエスト時点の企業別求職者ID
  // 26KH #89293 END
}

/** 検索に使用された検索条件 */
export interface SelectedSearch {
  searchCondition: SearchCondition //検索条件
  searchConditionDisplay: string //検索条件（表示用）
}

export const initScreenTransitionInput: ScreenTransitionInput = {
  screenId: '',
  requestList: [],
  targetYear: '',
  submissionStatus: '',
  submissionContentId: '',
  submissionContentName: '',
  entrySearchCriteriaSettingId: '',
  // 26KH #89294 START
  submissionDateFrom: '',
  submissionDateTo: '',
  // 26KH #89294 END
  searchCondition: { ...initSearchCondition }
}

export const initeSearchRequest: MCBHS040SearchRequest = {
  entrySearchCriteriaSettingId: null,
  searchCondition: '',
  sourceFunctionId: '',
  requestList: [],
  targetYear: '',
  submissionStatus: '0',
  submissionContentId: '',
  submissionContentName: '',
  searchCount: 1,
  displayLimit: null,
  sortKey: '0',
  // 26KH #89294 START
  submissionDateFrom: '',
  submissionDateTo: '',
  // 26KH #89294 END
}

export const initSearchRequest: MCBIS020SearchRequest = {
  sourceFunctionId: '',
  bulkManipulationId: '',
  conditionType: '',
  listId: '',
  searchCondition: '',
  selectionManagementIdList: [],
  entrySearchCriteriaSettingId: null,
  searchCount: 1,
  senniID: '',
  displayLimit: null,
  sortKey: ''
}

export const initMySearch: MySearch = {
  entrySearchCriteriaSettingId: '',
  searchCriteria: '',
  searchCriteriaName: '',
  searchCriteriaDisplay: '',
}

export const initSearchResult: SearchResult = {
  submissionRequestListItemInfo: [], //提出リクエスト一覧項目情報
  submissionRequestsListInfo: [], //提出リクエスト一覧リスト情報
  searchCondition: null, //検索条件JSON
  searchConditionDisplay: '', //検索条件(表示用)
  searchCriteriaName: null, //検索条件名
  displayInfo: [], //画面表示情報
  totalCount: 0, //検索結果件数
  displayLimitOptionInfo: [], //検索上限設定選択肢情報
  unlimitedTotalCount: 0, // 表示上限を考慮しない結果件数
  isManagementOffice:false, // 運営事務局判定
  sourceFunctionId: null,// 呼出し側機能ID
  errorCode: null
}

export const initSortCondition: SortCondition = {
  order: '0',
}

export interface IdObject {
  jobSeekerId: string //求職者ID
  jobSeekerByCompanyId: string //企業別求職者ID
  jobSeekerIdForDisplay: string //表示用求職者ID
  name: string //氏名
  mcbUseStatus: string //MCB利用ステータス
  mcbUseStatusName: string //MCB利用ステータス表示文字
  coordinationFormerJobSeekerIdentificationKey: string //連携元求職者識別キー
  mycareercd: string //My CareerID
  mcbMemberId: string //MCB会員ID
  // 26KH #89293 START
  originJobSeekerId: string //名寄せ前求職者ID：リクエスト時点の求職者ID
  originJobSeekerByCompanyId: string //名寄せ前企業別求職者ID：リクエスト時点の企業別求職者ID
  // 26KH #89293 END
}

export interface SubmissionRequestObject {
  jobSeekerByCompanyId: string //企業別求職者ID
  jobSeekerId: string //求職者ID
  submissionRequestId: string //提出リクエストID
  jsbcIdSrId: string //企業別求職者ID提出リクエストID
  mcbSubmissionRequestId: string //MCB提出リクエストID
  authenticationKey: string //認証キー
  mcbMessageId: string //MCBメッセージID
  messageSendId: string //メッセージ送信ID
  entryId: string //エントリーID
  selectionManagementId: string //選考管理ID
  selectionFlowSettingId: string //選考フロー設定ID
  mycareercd: string //My CareerID
  mcbMemberId: string //MCB会員ID
  content: ContentConfirmObject //提出物内容／提出リクエスト確認状況周辺情報
  submissionNames: SubmissionNamesObject //提出物周辺情報
  requestPeriod: RequestPeriodObject //提出リクエスト日／提出締切日／リクエスト状況周辺情報
  submissionTiming: SubmissionTimingObject //提出日周辺情報
  // 26KH #89293 START
  originJobSeekerId: string //名寄せ前求職者ID：リクエスト時点の求職者ID
  originJobSeekerByCompanyId: string //名寄せ前企業別求職者ID：リクエスト時点の企業別求職者ID
  // 26KH #89293 END
}

export interface ContentConfirmObject {
  submissionContentId: string //提出物内容ID
  submissionContentName: string //提出物内容
  submissionStatus: string //提出状況
  resubmitReceptionFlag: string //再提出可否フラグ
  resubmitReceptionFlagName: string //再提出可否フラグ表示文字
  confirmStatus: string //提出リクエスト確認状況
  confirmStatusName: string //提出リクエスト確認状況名称
}

export interface SubmissionNamesObject {
  submissionEntrySheetFlag: string //提出物エントリシートフラグ
  submissionResumeFlag: string //提出物履歴書フラグ
  submissionResearchSummaryFlag: string //提出物研究概要書フラグ
  submissionOriginalQuestionFlag: string //提出物オリジナル設問フラグ
  submissionFileSubmissionFlag: string //提出物ファイル提出フラグ
  submissionNames: string //提出物
}

export interface RequestPeriodObject {
  requestDate: string //提出リクエスト日
  deadline: string //提出締切日
  changeDeadlineFlag: string //締切変更フラグ
  changeDeadlineDate: string //締切変更日
  requestStatus: string //リクエスト状況
  requestStatusName: string //リクエスト状況名
  forceStopFlag: string //強制停止フラグ
}

export interface SubmissionTimingObject {
  resubmitReceptionFlag: string //再提出受付フラグ
  submissionDatetime: string //最終提出日
  submissionTimes: string //再提出回数
}

export interface SchoolObject {
  school: string //学校
  faculty: string //学部
  department: string //学科
}

export interface Data {

  id:IdObject //氏名／応募者管理ID周辺情報
  submissionRequests:SubmissionRequestObject[] //提出リクエスト周辺情報
  nameKana: string //カナ姓名
  mailAddress: string //メールアドレス
  homePhone: string //現住所電話番号
  mobilePhone: string //携帯番号
  vacationPhone: string //休暇中電話番号
  graduation: string //卒年
  schoolTypeCode: string //学校種別
  schoolTypeName: string //学校種別名称
  school: SchoolObject //学校周辺情報
  schoolGroup: string //学校グループ
  collegeMasterDisplayOrder: number //大学マスタ表示順
  humanitiesAndSciencesDivision: string //文理区分
  humanitiesAndSciencesDivisionString: string //文理区分表示文字
  unsubscribeFlag: string //退会フラグ
  manualRegistrationFlag: string //手動登録フラグ
  addressCode: string //都道府県コード（現住所）
  address: string //都道府県コード（現住所）表示文字
  addressAfterCity: string //市区町村以降の住所（現住所）
  postalCode: string //郵便番号（現住所）
  vacationAddressCode: string //都道府県コード（休暇中住所）
  vacationAddress: string //都道府県コード（休暇中住所）表示文字
  vacationAddressAfterCity: string //市区町村以降の住所（休暇中住所）
  vacationPostalCode: string //郵便番号（休暇中住所）

  nameForFilter: string //フィルター用姓名
  nameHiraganaForFilter: string //フィルター用ひらがな姓名
  nameKatakanaForFilter: string //フィルター用カタカナ姓名
}

export const SCREEN_ID = 'MCAXS351'

export interface DisplayLimitOptionInfo {
  label: string
  value: string
  selected: string
}

export const initSelectedSearch = {
  searchCondition: initSearchCondition,
  searchConditionDisplay: '',
}

export const initMcbCheckDetailsRequest = {
  jobSeekerByCompanyId: '',
  mcbSubmissionRequestId: '',
  jobSeekerId: '',
  entryId: '',
}