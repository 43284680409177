import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { FieldGroup, FormField, SubTitle, Title } from 'componentsHsc'
import { FastField } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { FormControl } from '.'
import { useStyles as useModalStyles } from './Basic'
import Button from './Button'
import GroupOptions from 'componentsHsc/AdvancedSearchJobChange/GroupOptions'
import SchoolGroupModal from './Modal/SchoolGroupModal'
import { SchoolDetailsModal } from './Modal/SchoolDetailsModal'
import { SchoolDepartmentDetails } from './Modal/SchoolDepartmentDetails'
import StudentModal from './Modal/StudentModal'
import { StudentDetailsModal } from './Modal/StudentDetailsModal'
import { FastSelect } from './FastSelect'
import { getMessage } from 'common/messageUtil'
import { Option, UndergraduateDepartmentSearchObj } from './Config/searchConditionConfig'

const useStyles = makeStyles(theme => ({
  graduationPeriod: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  schoolType: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  button: {
    width: '20%',
    marginBottom: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
  },
  cursor: {
    '& div' : {
      cursor: 'pointer!important',
    }
  },
}))

interface Props {
  form: FormControl
}

const School = ({ form }: Props) => {
  const classes = useStyles()
  const modalClasses = useModalStyles()

  const schoolGroup = form.values.schoolGroupObj || []
  const schoolDetails = form.values.schoolDetailsObj || []
  const undergraduateDepartment = form.values.undergraduateDepartmentObj || []
  const studentClassification = form.values.studentClassificationObj || []
  const departmentDetails = form.values.departmentDetailsObj || []

  const {
    graduationPeriodList,
    philosophyHumanitiesList,
    schoolTypeList,
    schoolNameList,
    facultyGraduateNameList,
    departmentDepartmentNameList,
    countryRegionList,
  } = useSelector((state: RootState) => state.advancedSearch.schoolInfo)

  const schoolNameSuggest = schoolNameList.map(i => {
    return { label: i, value: i }
  })

  const facultyGraduateNameSuggest = facultyGraduateNameList.map(i => {
    return { label: i, value: i }
  })

  const departmentDepartmentNameSuggest = departmentDepartmentNameList.map(
    i => {
      return { label: i, value: i }
    }
  )

  // Modal
  const [modalState, setModalState] = useState({
    schoolGroup: false,
    schoolDetails: false,
    schoolDepartmentDetails: false,
    studentClassification: false,
    studentDetails: false,
    schoolGroupSelected: [] as Option[],
    schoolDetailsSelected: [] as Option[],
    schoolDepartmentDetailsSelected: [] as UndergraduateDepartmentSearchObj[],
    studentClassificationSelected: [] as Option[],
    studentDetailsSelected: [] as Option[],
  })

  const handleClick = (modalName: string, open = true) => () => {
    setModalState({
      ...modalState,
      [modalName]: open,
    })
  }

  const handleDeleteSchoolGroup = (option: Option) => () => {
    if (schoolGroup) {
      const newConditionList = schoolGroup.filter(i => i !== option)
      form.setFieldValue('schoolGroupObj', newConditionList)
      modalState.schoolGroupSelected = modalState.schoolGroupSelected.filter(i => i !== option)
    }
  }

  const handleDeleteSchoolDetails = (option: Option) => () => {
    if (schoolDetails) {
      const newConditionList = schoolDetails.filter(i => i !== option)
      if (newConditionList.length < 1) {
        // 子条件全消去で親条件全消去
        form.setFieldValue('schoolGroupObj', [])
        modalState.schoolGroupSelected = []
      }
      form.setFieldValue('schoolDetailsObj', newConditionList)
      modalState.schoolDetailsSelected = modalState.schoolDetailsSelected.filter(i => i !== option)
    }
  }

  const handleDeleteUndergraduateDepartment = (option: UndergraduateDepartmentSearchObj) => () => {
    if (undergraduateDepartment) {
      const newConditionList = undergraduateDepartment.filter(i => i !== option)
      if (newConditionList.length < 1) {
        // 孫条件全削除で子条件と親条件全消去
        form.setFieldValue('schoolGroupObj', [])
        form.setFieldValue('schoolDetailsObj', [])
        modalState.schoolGroupSelected = []
        modalState.schoolDetailsSelected = []
      }
      form.setFieldValue('undergraduateDepartmentObj', newConditionList)
      modalState.schoolDepartmentDetailsSelected = modalState.schoolDepartmentDetailsSelected.filter(i => i.subjcetInfomationDagagaMasterId !== option.subjcetInfomationDagagaMasterId)
    }
  }

  const handleDeleteStudentClassification = (option: Option) => () => {
    if (studentClassification) {
      const newConditionList = studentClassification.filter(i => i !== option)
      form.setFieldValue('studentClassificationObj', newConditionList)
      modalState.studentClassificationSelected = modalState.studentClassificationSelected.filter(i => i !== option)
    }
  }

  const handleDeleteDepartmentDetails = (option: Option) => () => {
    if (departmentDetails) {
      const newConditionList = departmentDetails.filter(i => i !== option)
      if (newConditionList.length < 1) {
        // 子条件全消去で親条件全消去
        form.setFieldValue('studentClassificationObj', [])
        modalState.studentClassificationSelected = []
      }
      form.setFieldValue('departmentDetailsObj', newConditionList)
      modalState.studentDetailsSelected = modalState.studentDetailsSelected.filter(i => i !== option)
    }
  }

  return (
    <>
      <FieldGroup>
        <SubTitle variant='h3'>学校情報</SubTitle>
        <FormField>
          <Title title='卒業予定時期' className={classes.title} />
          <FastField
            name='graduationPeriodObj'
            component={GroupOptions}
            row
            optionList={graduationPeriodList}
          />
        </FormField>
        <FormField>
          <Title title='文理区分' className={classes.title} />
          <FastField
            name='philosophyHumanitiesObj'
            component={GroupOptions}
            row
            optionList={philosophyHumanitiesList}
          />
        </FormField>
        <FormField>
          <Title title='学校種別' className={classes.title} />
          <FastField
            name='schoolTypeObj'
            component={GroupOptions}
            row
            optionList={schoolTypeList}
          />
        </FormField>
        <FormField>
          <Title title='学校名' className={classes.title} />
          <FastField
            name='schoolName'
            isMulti
            component={FastSelect}
            className={classes.cursor}
            defaultValue={form.values.schoolName}
            optionList={schoolNameSuggest}
            placeholder='学校名を入力'
          />
        </FormField>
        <FormField>
          <Title title='学部・研究科' className={classes.title} />
          <FastField
            name='facultyGraduateName'
            isMulti
            component={FastSelect}
            className={classes.cursor}
            defaultValue={form.values.facultyGraduateName}
            optionList={facultyGraduateNameSuggest}
            placeholder='学部・研究科を入力'
          />
        </FormField>
        <FormField>
          <Title title='学科・専攻' className={classes.title} />
          <FastField
            name='departmentDepartmentName'
            isMulti
            component={FastSelect}
            className={classes.cursor}
            defaultValue={form.values.departmentDepartmentName}
            optionList={departmentDepartmentNameSuggest}
            placeholder='学科・専攻を入力'
          />
        </FormField>
        <FormField>
          <Title title='学校グループ' className={classes.title} />
          <div className={modalClasses.modal}>
            <Button onClick={handleClick('schoolGroup')} />
            {undergraduateDepartment.length > 0 ? (
              <span>
                {undergraduateDepartment.map(i => (
                  <Chip
                    title={i.collegeName + " " + i.facultyName + " " + i.subjectName}
                    key={i.subjcetInfomationDagagaMasterId}
                    className={classes.chip}
                    label={i.collegeName + " " + i.facultyName + " " + i.subjectName}
                    onDelete={handleDeleteUndergraduateDepartment(i)}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            ) : (
              schoolDetails.length > 0 ? (
                <span>
                  {schoolDetails.map(i => (
                    <Chip
                      key={i.value}
                      className={classes.chip}
                      label={i.label}
                      onDelete={handleDeleteSchoolDetails(i)}
                      deleteIcon={<CancelIcon />}
                    />
                  ))}
                </span>
              ) : (
                schoolGroup.length > 0 && (
                  <span>
                    {schoolGroup.map(i => (
                      <Chip
                        key={i.value}
                        className={classes.chip}
                        label={i.label}
                        onDelete={handleDeleteSchoolGroup(i)}
                        deleteIcon={<CancelIcon />}
                      />
                      ))}
                  </span>
                ))
            )}
          </div>
        </FormField>
        <FormField helperText={getMessage('MCAXS371-001')}>
          <Title title='学生分類（専攻分野）' className={classes.title} />
          <div className={modalClasses.modal}>
            <Button onClick={handleClick('studentClassification')} />
            {departmentDetails.length > 0 ? (
              <span>
                {departmentDetails.map(i => (
                  <Chip
                    title={i.label}
                    key={i.value}
                    className={classes.chip}
                    label={i.label}
                    onDelete={handleDeleteDepartmentDetails(i)}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            ) : (
              studentClassification.length > 0 && (
                <span>
                  {studentClassification.map(i => (
                    <Chip
                      title={i.label}
                      key={i.value}
                      className={classes.chip}
                      label={i.label}
                      onDelete={handleDeleteStudentClassification(i)}
                      deleteIcon={<CancelIcon />}
                    />
                  ))}
                </span>
              )
            )}
          </div>
        </FormField>
        <FormField helperText={getMessage('MCAXS371-002')}>
          <Title title='国・地域' className={classes.title} />
          <FastField
            name='countryRegionObj'
            component={GroupOptions}
            row
            optionList={countryRegionList}
          />
        </FormField>
      </FieldGroup>
      <SchoolGroupModal
        open={modalState.schoolGroup}
        onClose={handleClick('schoolGroup', false)}
        title='学校グループを指定'
        form={form}
        modalState={modalState}
      />
      <SchoolDetailsModal
        open={modalState.schoolDetails}
        onClose={handleClick('schoolDetails', false)}
        title='学校詳細を指定'
        form={form}
        modalState={modalState}
      />
      <SchoolDepartmentDetails
        open={modalState.schoolDepartmentDetails}
        onClose={handleClick('schoolDepartmentDetails', false)}
        title='学部・学科詳細を指定'
        form={form}
        modalState={modalState}
      />

      <StudentModal
        open={modalState.studentClassification}
        onClose={handleClick('studentClassification', false)}
        title='学生分類（専攻分野）を指定'
        form={form}
        modalState={modalState}
      />
      <StudentDetailsModal
        open={modalState.studentDetails}
        onClose={handleClick('studentDetails', false)}
        title='学科の詳細を指定'
        form={form}
        modalState={modalState}
      />
    </>
  )
}

export default School
