import { magiContants } from "utils/contants"

// 求職者情報
export interface MCAXS230EntrySelJobSeekerOutDto {
    [key: string]: any
    jobSeekerId: string // 求職者ID
    jobSeekerByCompanyId: string // 企業別求職者ID
    manualRegistrationFlag: string // 手動登録フラグ
    memberType: string // 会員種別
    memberTypeG: string // 会員種別画面表示用
    jobSeekerIdForDisplay: string // 応募者管理ID
    surname: string // 姓
    firstName: string // 名
    surnameKana: string // 姓カナ
    firstNameKana: string // 名カナ
    birthday: string // 生年月日
    sex: string // 性別
    mailAddressPC: string // メールアドレス（メイン）
    mailAddressMobile: string // メールアドレス（サブ）
    phoneNumberMobile: string // 電話番号（携帯）
    phoneNumberHome: string // 電話番号（自宅）
    phoneNumberVacation: string // 電話番号（休暇中）
    postalCode: string // 郵便番号　※現住所の項目
    prefecturesCode: string // 都道府県コード　※現住所の項目
    prefectures: string // 都道府県　※現住所の項目
    addressAfterMunicipalities: string // 市区町村以降の住所　※現住所の項目
    vacPostalCode: string // 郵便番号　※休暇中住所の項目
    vacPrefecturesCode: string // 都道府県コード　※休暇中住所の項目
    vacPrefectures: string // 都道府県　※休暇中住所の項目
    vacAddressAfterMunicipalities: string // 市区町村以降の住所　※休暇中住所の項目
    graduationPlanTime: string // 卒業予定時期
    graduationPlanTimeName: string // 卒業予定時期
    studentDivision: string // 学生区分
    studentDivisionG: string // 学生区分画面表示用
    collegeCode: string // 大学コード
    facultyCode: string // 学部コード
    subjectCode: string // 学科コード
    schoolName: string // 学校名　※海外の学校以外の表示項目
    facultyName: string // 研究科・専攻(学部)
    subjectName: string // 研究科・専攻(学科)
    vocationalSchoolMajor: string // 専攻分野
    overseasSchoolMajor: string // 専攻分野
    humanitiesAndSciencesDivision: string // 文理区分
    overseasSchoolDegree: string // 取得学位
    overseasSchoolCountryRegion: string // 国・地域
    overseasSchoolState: string // 州（アメリカの場合）
    zeminar: string // ゼミ
    circle: string // サークル
    majorTheme: string // 専攻テーマ
    sysVersionNumber: number // バージョン番号（求職者）
    sysVersionNumberForJobSeekerByCompany: number // バージョン番号（企業別求職者）
    sameAsCurrentAddress: string // 現住所と同じ
    inputSchoolName: string // 学校名入力
    inputFaculty: string // 学部入力
    inputSubject: string // 学科入力
    schoolGroupCode: string // 学校グループコード
    overseasSchoolName: string // 学校名 ※海外の学校の表示項目
    overseasSchoolFacultyDepartment: string // 学部 ※海外の学校の表示項目
    overseasSchoolSubjectDepartment: string // 学科 ※海外の学校の表示項目
}

// 都道府県プルダウン情報
export interface MCAXS230PrefecturesMstSelCodeNameOutDto {
    [key: string]: any
    prefecturesCode: string
    prefecturesName: string
}

// 保有資格サジェスト情報
// 語学スキルサジェスト情報
export interface MCAXS230SuggestSelDataNameOutDto {
    [key: string]: any
    dataName: string
}

// 画面初期表示に必要なタグ情報（保有資格）を取得する
// 画面初期表示に必要なタグ情報（語学スキル）を取得する
export interface MCAXS230JobSeekerTagOutDto {
    [key: string]: any
    jobSeekerId: string
    sequence: string
    tag: string
}

// 応募者情報編集-画面初期表示
export interface MCAXS230OutputDto {
    [key: string]: any
    entrySelJobSeekerOutDto: MCAXS230EntrySelJobSeekerOutDto // 求職者情報
    prefecturesList: MCAXS230PrefecturesMstSelCodeNameOutDto[] // 都道府県プルダウン情報
    ownedQualificationList: MCAXS230SuggestSelDataNameOutDto[] // 保有資格サジェスト情報
    languageForceList: MCAXS230SuggestSelDataNameOutDto[] // 語学スキルサジェスト情報
    ownedQualificationGList: MCAXS230JobSeekerTagOutDto[] // 画面初期表示に必要なタグ情報（保有資格）を取得する
    languageForceGList: MCAXS230JobSeekerTagOutDto[] // 画面初期表示に必要なタグ情報（語学スキル）を取得する
}

// 応募者情報編集-画面初期表示
const mcaxs230OutputDto = {
    entrySelJobSeekerOutDto: {
        jobSeekerId: '', // 求職者ID
        jobSeekerByCompanyId: '', // 企業別求職者ID
        manualRegistrationFlag: '', // 手動登録フラグ
        memberType: '', // 会員種別
        memberTypeG: '', // 会員種別画面表示用
        jobSeekerIdForDisplay: '', // 応募者管理ID
        surname: '', // 姓
        firstName: '', // 名
        surnameKana: '', // 姓カナ
        firstNameKana: '', // 名カナ
        birthday: '', // 生年月日
        sex: '', // 性別
        mailAddressPC: '', // メールアドレス（メイン）
        mailAddressMobile: '', // メールアドレス（サブ）
        phoneNumberMobile: '', // 電話番号（携帯）
        phoneNumberHome: '', // 電話番号（自宅）
        phoneNumberVacation: '', // 電話番号（休暇中）
        postalCode: '', // 郵便番号　※現住所の項目
        prefecturesCode: '', // 都道府県コード　※現住所の項目
        prefectures: '', // 都道府県　※現住所の項目
        addressAfterMunicipalities: '', // 市区町村以降の住所　※現住所の項目
        vacPostalCode: '', // 郵便番号　※休暇中住所の項目
        vacPrefecturesCode: '', // 都道府県コード　※休暇中住所の項目
        vacPrefectures: '', // 都道府県　※休暇中住所の項目
        vacAddressAfterMunicipalities: '', // 市区町村以降の住所　※休暇中住所の項目
        graduationPlanTime: '', // 卒業予定時期
        graduationPlanTimeName: '', // 卒業予定時期
        studentDivision: '', // 学生区分
        studentDivisionG: '', // 学生区分画面表示用
        collegeCode: '', // 大学コード
        facultyCode: '', // 学部コード
        subjectCode: '', // 学科コード
        schoolName: '', // 学校名　※海外の学校以外の表示項目
        facultyName: '', // 研究科・専攻(学部)
        subjectName: '', // 研究科・専攻(学科)
        vocationalSchoolMajor: '', // 専攻分野
        overseasSchoolMajor: '', // 専攻分野
        humanitiesAndSciencesDivision: '', // 文理区分
        overseasSchoolDegree: '', // 取得学位
        overseasSchoolCountryRegion: '', // 国・地域
        overseasSchoolState: '', // 州（アメリカの場合）
        zeminar: '', // ゼミ
        circle: '', // サークル
        majorTheme: '', // 専攻テーマ
        sysVersionNumber: -1, // バージョン番号（求職者）
        sysVersionNumberForJobSeekerByCompany: -1, // バージョン番号（企業別求職者）
        sameAsCurrentAddress: '', // 現住所と同じ
        inputSchoolName: '', // 学校名入力
        inputFaculty: '', // 学部入力
        inputSubject: '', // 学科入力
        schoolGroupCode: '', // 学校グループコード
        overseasSchoolName: '', // 学校名 ※海外の学校の表示項目
        overseasSchoolFacultyDepartment: '', // 学部 ※海外の学校の表示項目
        overseasSchoolSubjectDepartment: '', // 学科 ※海外の学校の表示項目
    },
    prefecturesList: [],
    ownedQualificationList: [],
    languageForceList: [],
    ownedQualificationGList: [],
    languageForceGList: []
}

// 住所を取得する
export interface MCAXS230PostalCodeMstSelPrefecturesNameOutDto {
    [key: string]: any
    prefecturesNameKanJi: string // 都道府県名漢字
    cityNameKanJi: string // 市区町村名漢字
    townNameKanJi: string // 町域名漢字
}

// 専門学校_専攻分野_選択する
export interface MCAXS230DepartmentOutDto {
    [key: string]: string
    departmentCode: string // 学科分類コード
    departmentName: string // 学科分類名
    facultyCode: string // 学部コード
    subjectCode: string // 学科コード
}

// 学校詳細区分情報を取得する
export interface MCAXS230SubInforDagMstSelFacultySubjectNameOutDto {
    [key: string]: string
    facultyCode: string // 学部コード
    subjectCode: string // 学科コード
    facultyName: string // 学校詳細区分_学部名称
    subjectName: string // 学校詳細区分_学科名称
}

// 学校名（頭文字）選択モーダル"OK"ボタン押下時
export interface MCAXS230CollegeMstSelSchoolNameOutDto {
    [key: string]: any
    collegeCode: string // 大学コード
    collegeName: string // 学校名
    facultyCode: string // 学部コード
    subjectCode: string // 学科コード
    schoolGroupCode: string // 学校グループコード
}

// 性別
const sexList = [
    { label: '男性', value: '1' },
    { label: '女性', value: '2' },
    { label: '未選択', value: '0' },
]

// 学生区分
const studentDivisionList = [
    { label: '大学院', value: '0' },
    { label: '大学', value: '1' },
    { label: '短大', value: '2' },
    { label: '高専', value: '3' },
    { label: '専門学校', value: '4' },
    { label: '海外の学校', value: '5' },
]

// 文理区分
const humanitiesAndSciencesDivisionList = [
    { label: '文系', value: '0' },
    { label: '理系', value: '1' },
    { label: 'その他', value: '2' },
]

// 取得学位
const overseasSchoolDegreeList = [
    { label: 'Bachelor', value: '0' },
    { label: 'Master', value: '1' },
    { label: 'MBA', value: '2' },
    { label: 'Ph.D.', value: '3' },
    { label: 'その他', value: '4' },
    { label: '選択なし', value: '5' },
]

// 専攻分野
const vocationalSchoolMajorList = [
    { label: 'ビジネス系', value: '201' },
    { label: '外国語系', value: '203' },
    { label: '文学系', value: '205' },
    { label: '教育系', value: '207' },
    { label: '人文・教養・総合科学系', value: '209' },
    { label: '福祉系', value: '211' },
    { label: '機械系', value: '221' },
    { label: '電気・電子系', value: '223' },
    { label: '化学系', value: '225' },
    { label: '土木・建築系', value: '227' },
    { label: '航空宇宙・船舶海洋系', value: '229' },
    { label: '情報工学系', value: '231' },
    { label: '生物系', value: '233' },
    { label: '材料系', value: '235' },
    { label: '数学・情報科学系', value: '237' },
    { label: '物理系', value: '239' },
    { label: '地学系', value: '241' },
    { label: '資源系', value: '243' },
    { label: '経営・管理系', value: '245' },
    { label: '農学系', value: '251' },
    { label: '獣医・畜産系', value: '253' },
    { label: '医学・歯学系', value: '261' },
    { label: '薬学系（４年制）', value: '263' },
    { label: '薬学系（６年制）', value: '264' },
    { label: '医療・保健・看護系', value: '265' },
    { label: '家政・生活科学系', value: '271' },
    { label: '保育系', value: '275' },
    { label: '体育・健康科学系', value: '281' },
    { label: '芸術系', value: '291' },
    { label: 'デザイン系', value: '293' },
    { label: 'その他理系', value: '297' },
    { label: '学科不明', value: '299' },
]

// 国・地域
const overseasSchoolCountryRegionList = [
    { label: 'アメリカ', value: '110' },
    { label: 'カナダ', value: '120' },
    { label: 'イギリス', value: '210' },
    { label: 'ドイツ', value: '220' },
    { label: 'フランス', value: '230' },
    { label: '西欧諸国（上記以外）', value: '290' },
    { label: '中国', value: '310' },
    { label: '韓国', value: '320' },
    { label: 'アジア諸国（上記以外）', value: '390' },
    { label: '東欧・ロシア', value: '490' },
    { label: 'オーストラリア', value: '510' },
    { label: 'ニュージランド', value: '520' },
    { label: 'オセアニア諸国（上記以外）', value: '590' },
    { label: '南米諸国', value: '690' },
    { label: 'アフリカ諸国', value: '790' },
    { label: '中東諸国', value: '890' },
]

// 州(アメリカの場合)
const overseasSchoolStateList = [
    { label: 'Alabama', value: '10' },
    { label: 'Alaska', value: '20' },
    { label: 'Arizona', value: '30' },
    { label: 'Arkansas', value: '40' },
    { label: 'California', value: '50' },
    { label: 'Colorado', value: '60' },
    { label: 'Connecticut', value: '70' },
    { label: 'Delaware', value: '80' },
    { label: 'District of Columbia', value: '90' },
    { label: 'Florida', value: '100' },
    { label: 'Georgia', value: '110' },
    { label: 'Hawaii', value: '120' },
    { label: 'Idaho', value: '130' },
    { label: 'Illinois', value: '140' },
    { label: 'Indiana', value: '150' },
    { label: 'Iowa', value: '160' },
    { label: 'Kansas', value: '170' },
    { label: 'Kentucky', value: '180' },
    { label: 'Louisiana', value: '190' },
    { label: 'Maine', value: '200' },
    { label: 'Maryland', value: '210' },
    { label: 'Massachusetts', value: '220' },
    { label: 'Michigan', value: '230' },
    { label: 'Minnesota', value: '240' },
    { label: 'Mississippi', value: '250' },
    { label: 'Missouri', value: '260' },
    { label: 'Montana', value: '270' },
    { label: 'Nebraska', value: '280' },
    { label: 'Nevada', value: '290' },
    { label: 'New Hampshire', value: '300' },
    { label: 'New Jersey', value: '310' },
    { label: 'New Mexico', value: '320' },
    { label: 'New York', value: '330' },
    { label: 'North Carolina', value: '340' },
    { label: 'North Dakota', value: '350' },
    { label: 'Ohio', value: '360' },
    { label: 'Oklahoma', value: '370' },
    { label: 'Oregon', value: '380' },
    { label: 'Pennsylvania', value: '390' },
    { label: 'Rhode Island', value: '400' },
    { label: 'South Carolina', value: '410' },
    { label: 'South Dakota', value: '420' },
    { label: 'Tennessee', value: '430' },
    { label: 'Texas', value: '440' },
    { label: 'Utah', value: '450' },
    { label: 'Vermont', value: '460' },
    { label: 'Virginia', value: '470' },
    { label: 'Wisconsin', value: '480' },
    { label: 'Washington', value: '490' },
    { label: 'West Virginia', value: '500' },
    { label: 'Wyoming', value: '510' },
]

// 卒業予定時期
const graduationPlanTime = []
// 2019年春～現在の西暦＋６年分。
const difference = new Date().getFullYear() - 2011
for (let i = 1; i < difference; i++) {
    graduationPlanTime.push(
        {
            label: 2019 + (i - 1) + magiContants.SPRING,
            value: 2019 + (i - 1) + magiContants.SPRING,
        },
        {
            label: 2019 + (i - 1) + magiContants.AUTUMN,
            value: 2019 + (i - 1) + magiContants.AUTUMN,
        }
    )
}
const selectList = graduationPlanTime.map(i => ({
    label: i.label,
    value: i.value,
}))

export interface FiftyPhonogramData {
    [key: string]: any
    value: string
    label: string
    index: number
}

export {
    mcaxs230OutputDto,
    sexList,
    studentDivisionList,
    humanitiesAndSciencesDivisionList,
    overseasSchoolDegreeList,
    vocationalSchoolMajorList,
    overseasSchoolCountryRegionList,
    overseasSchoolStateList,
    selectList,
}