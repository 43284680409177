import { tableUpdateSexInitRequest, tableUpdateSexUpdateRequest } from "apis/MCBKS010Api";
import { MCBKS020CheckRequest, MCBKS020InitRequest } from "apis/MCBKS020Api";
import { getMessage } from "common/messageUtil";
import { TableUpdateSexInfoDisplayTarget, SexUpdateResponse } from "pages/MCBKS010/formConfig";
import { CheckSysVersionRequest, RecruitmentManagementFlag } from "pages/MCBKS020/formConfig";
import { initTableUpdateRecruitmentManagementFlagPreferenceRequest, initTableUpdateRecruitmentManagementFlagRequest, initTableUpdateSexRequest, openMCBKS030Request, setTableUpdateRecruitmentManagementFlag, setTableUpdateRecruitmentManagementFlagPreference, setTableUpdateSexInfoDisplayTargetList, updateTableUpdateRecruitmentManagementFlag, updateTableUpdateSexRequest } from "reducers/tableUpdateReducer";
import { openSnackbar, openModal } from "reducers/messageReducer";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { magiContants } from "utils/contants";
import { MCBKS030RecruitementManagementItemChangeResponseDto, MCBKS030TableUpdateDisplayResponseDto, TableUpdateRecruitmentManagementFlagDisplayList } from "pages/MCBKS030/formConfig";
import { mCBKS030InitRequest, mCBKS030UpdateRequest } from "apis/MCBKS030Api";

function* initTableUpdateSexRequestSaga(action: ReturnType<typeof initTableUpdateSexRequest>) {
  try {
    const data: TableUpdateSexInfoDisplayTarget[] = yield call(tableUpdateSexInitRequest, action.payload.mCBKS010InitRequest)
    yield put(setTableUpdateSexInfoDisplayTargetList(data))
  } catch(e) {
    const error = e as any
    yield put(openModal(error.message))
  }
}

function* updateTableUpdateSexRequestSaga(action: ReturnType<typeof updateTableUpdateSexRequest>) {
  try {
    const data: SexUpdateResponse = yield call(tableUpdateSexUpdateRequest, action.payload.request)
    if(data.updateNum > 0) {
      //更新完了のバナー表示
      yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBKS010_007)))
    }
    action.payload.initRequest()
  } catch(e) {
    const error = e as any
    yield put(openModal(error.message))
  }
}

function* initTableUpdateRecruitmentManagementFlagRequestSaga() {
  try {
    const data: RecruitmentManagementFlag[] = yield call(MCBKS020InitRequest)
    yield put(setTableUpdateRecruitmentManagementFlag(data))
  } catch(e) {
    const error = e as any
    yield put(openModal(error.message))
  }
}

function* openMCBKS030RequestSaga(action: ReturnType<typeof openMCBKS030Request>) {
  const request: CheckSysVersionRequest = {
    recruitmentMangementSettingFlagRequest: action.payload.recruitmentManagementFlagSysVersion
  }
  try {
    yield call(MCBKS020CheckRequest, request)
    action.payload.setOpenMCBKS030(true)
    action.payload.setOpenMCBKS020(false)
  } catch(e) {
    const error = e as any
    action.payload.setErrorModalMessage(getMessage(error.message))
    action.payload.setOpenErrorModal(true)
  }
}

function* initTableUpdateRecruitmentManagementFlagPreferenceRequestSaga(action: ReturnType<typeof initTableUpdateRecruitmentManagementFlagPreferenceRequest>) {
  try {
    const data: MCBKS030TableUpdateDisplayResponseDto = yield call(mCBKS030InitRequest, action.payload.mCBKS030InitRequest)
    yield put(setTableUpdateRecruitmentManagementFlagPreference(data))
  } catch(e) {
    const error = e as any
    action.payload.setModalMessage(getMessage(error.message))
    action.payload.setOpenMessageModal(true)
  }
}

function* updateTableUpdateRecruitmentManagementFlagSaga(action: ReturnType<typeof updateTableUpdateRecruitmentManagementFlag>) {
  try {
    const response: MCBKS030RecruitementManagementItemChangeResponseDto = yield call(mCBKS030UpdateRequest, action.payload.request)
    if(response.otherUpdatedRecruimentManagementFlagInfo) {   
      action.payload.duplicateUpddateModal(response)
    }
    else if(response.updateCount > 0) {
      //更新完了のバナー表示
      yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBKS030_009)))
      action.payload.initRequest(0, true)
    }
    else {
      action.payload.initRequest(0, true)
    }
  } catch(e) {
    const error = e as any
    action.payload.setModalMessage(getMessage(error.message))
    action.payload.setOpenMessageModal(true)
  }
}

export default function* tableUpdateSaga() {
  yield all([
    takeLatest(initTableUpdateSexRequest, initTableUpdateSexRequestSaga),
    takeLatest(updateTableUpdateSexRequest, updateTableUpdateSexRequestSaga),
    takeLatest(initTableUpdateRecruitmentManagementFlagRequest, initTableUpdateRecruitmentManagementFlagRequestSaga),
    takeLatest(openMCBKS030Request, openMCBKS030RequestSaga),
    takeLatest(initTableUpdateRecruitmentManagementFlagPreferenceRequest, initTableUpdateRecruitmentManagementFlagPreferenceRequestSaga),
    takeLatest(updateTableUpdateRecruitmentManagementFlag, updateTableUpdateRecruitmentManagementFlagSaga)
  ])
}