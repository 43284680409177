import request from 'utils/request'

import { MCBIS010InitRequest } from 'types/MCBIS010/MCBIS010InitRequest'
import { MCBIS010BatchProcessRequest } from 'types/MCBIS010/MCBIS010BatchProcessRequest'
import { MCBIS010IdRequest } from 'types/MCBIS010/MCBIS010IdRequest'
import { MCBIS010OrderRequest } from 'types/MCBIS010/MCBIS010OrderRequest'
import { MCBIS010UpdateSearchTitleRequest } from 'types/MCBIS010/MCBIS010UpdateSearchTitleRequest'

export const mCBIS010InitRequest = (apiData: MCBIS010InitRequest) =>
  request({
    url: '/MCBIS010/init',
    method: 'post',
    data: apiData,
})

export const mCBIS010ExecuteRequest = (apiData: MCBIS010BatchProcessRequest) =>
  request({
    url: '/MCBIS010/execute',
    method: 'post',
    data: apiData,
})

export const mCBIS010OpenSearchTitleRequest = (apiData: MCBIS010IdRequest) =>
  request({
    url: '/MCBIS010/openSearchTitle',
    method: 'post',
    data: apiData,
})

export const mCBIS010OpenSearchDetailRequest = (apiData: MCBIS010IdRequest) =>
  request({
    url: '/MCBIS010/openSearchDetail',
    method: 'post',
    data: apiData,
})

export const mCBIS010SortRequest = (apiData: MCBIS010OrderRequest) =>
  request({
    url: '/MCBIS010/sort',
    method: 'post',
    data: apiData,
})

export const mCBIS010UpdateSearchTitleRequest = (apiData: MCBIS010UpdateSearchTitleRequest) =>
  request({
    url: '/MCBIS010/update',
    method: 'post',
    data: apiData,
})

export const mCBIS010selectSearchCriteriaRequest = (apiData:MCBIS010IdRequest) =>
  request({
    url:'/MCBIS010/selectSearchCriteria',
    method:'post',
    data:apiData,
})
