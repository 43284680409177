import request from 'utils/request'
import { MCAXS310InitRequest } from 'types/MCAXS310/MCAXS310InitRequest'
import { MCAXS310ReserveRequest } from 'types/MCAXS310/MCAXS310ReserveRequest'

export const seminarInitRequest = (params : MCAXS310InitRequest) =>
  request({
    url: `MCAXS310/init`,
    method: 'post',
    data: params 
  })

export const seminarRegisterRequest = (params: MCAXS310ReserveRequest) =>
  request({
    url: '/MCAXS310/registerSeminar',
    method: 'post',
    data: params,
})

