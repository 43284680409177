/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react'
import { Dialog, makeStyles, DialogTitle, Grid, DialogContent, List, DialogActions, Button, ListItem } from '@material-ui/core'
import { MCAXS230SubInforDagMstSelFacultySubjectNameOutDto } from 'pages/MCAXS230/formConfig'
import { magiContants } from 'utils/contants'

interface Props {
    schoolDetailedDialog: boolean
    setSchoolDetailedDialog: (state: boolean) => void
    optionList: MCAXS230SubInforDagMstSelFacultySubjectNameOutDto[]
    studentDivision: string
    getSchoolDetailedDivision: (values: MCAXS230SubInforDagMstSelFacultySubjectNameOutDto) => void
}

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: '#133e80',
        padding: '8px',
    },
    title: {
        color: '#ffffff',
        fontSize: '22px',
        fontWeight: 'bold',
    },
    dialogContent: {
        padding: '8px 60px 0px 60px',
    },
    listConent: {
        height: '370px',
        overflowY: 'auto',
        border: '1px solid black',
        '& :hover': {
            backgroundColor: '#e6f1ff',
        },
        '& .Mui-selected': {
            backgroundColor: '#fcebeb',
            border: '1px solid #e2b4e3'
        },
        '& .Mui-selected:hover': {
            backgroundColor: '#fcebeb',
            border: '1px solid #e2b4e3'
        },
        padding: '0px',
    },
    listItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        height: '60px',
        cursor: 'pointer'
    },
    // "キャンセルする"ボタン
    cancel: {
        color: '#1a1a1a',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#e0e0e0',
        borderColor: '#cbcbcb',
        '&:hover': {
            backgroundColor: '#c1d1eb',
            borderColor: '#999999',
        },
        minWidth: '180px',
    },
    // "入力内容を確認する"ボタン
    confirmButton: {
        color: '#ffffff',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#3380cc',
        borderColor: '#3380cc',
        '&:hover': {
            backgroundColor: '#4596e6',
            borderColor: '#3380cc',
        },
        minWidth: '180px',
    },
    listText1: {
        color: '#3380cc',
    },
    listText2: {
        marginRight: '14px',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        color: '#3380cc',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '14px',
        paddingTop: '0.2em',
    },
    listText3: {
        marginRight: '14px',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        color: '#3380cc',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingTop: '0.2em',
    },
}))

const SchoolDetailedDivision = ({ schoolDetailedDialog, setSchoolDetailedDialog, optionList, studentDivision, getSchoolDetailedDivision }: Props) => {
    const classes = useStyles()
    const [selectedIndex, setSelectedIndex] = useState(-2)
    useEffect(() => {
        setSelectedIndex(-2)
    }, [schoolDetailedDialog])
    const [selectedData, setSelectedData] = useState<MCAXS230SubInforDagMstSelFacultySubjectNameOutDto>({
        facultyCode: magiContants.EMPTY,
        subjectCode: magiContants.EMPTY,
        facultyName: magiContants.EMPTY,
        subjectName: magiContants.EMPTY,
    })

    const handleCancel = () => {
        setSchoolDetailedDialog(false)
    }

    const onLink = (values: MCAXS230SubInforDagMstSelFacultySubjectNameOutDto, index: number) => {
        if(values.facultyName === magiContants.NONE || values.subjectName === magiContants.NONE){
            let datas: MCAXS230SubInforDagMstSelFacultySubjectNameOutDto = {
                facultyCode: magiContants.EMPTY,
                subjectCode: magiContants.EMPTY,
                facultyName: magiContants.NONE,
                subjectName: magiContants.NONE,
            }
            setSelectedData(datas)
            setSelectedIndex(-1)
        }else{
            let datas: MCAXS230SubInforDagMstSelFacultySubjectNameOutDto = {
                facultyCode: values.facultyCode,
                subjectCode: values.subjectCode,
                facultyName: studentDivision !== magiContants.STRING_2 ? values.facultyName : magiContants.EMPTY,
                subjectName: values.subjectName,
            }
            setSelectedData(datas)
            setSelectedIndex(index)
        }
        
    }

    const confirmButton = () => {
        if (selectedIndex !== -2) {
            getSchoolDetailedDivision(selectedData)
        } else {
            setSchoolDetailedDialog(false)
        }
    }

    return (
        <>
            <Dialog open={schoolDetailedDialog} fullWidth maxWidth='md'>
                <DialogTitle className={classes.dialogTitle}>
                    <Grid className={classes.title}>学校詳細区分選択</Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <List component='div' className={classes.listConent}>
                        {optionList.map((data: MCAXS230SubInforDagMstSelFacultySubjectNameOutDto, index: number) => (
                            <ListItem
                                selected={selectedIndex === index}
                                button
                                divider
                                className={classes.listItem}
                                onClick={() => {
                                    onLink(data, index)
                                }}>
                                {data.facultyName !== magiContants.NONE ? <div className={classes.listText1} >{data.facultyName}</div> : magiContants.EMPTY}
                                <div className={data.subjectName !== magiContants.NONE ? studentDivision !== magiContants.STRING_2 ? classes.listText2 : classes.listText3 : classes.listText1}>{data.subjectName}</div>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Grid container direction='row' justify='center'>
                        <Grid xs={5} item container justify='flex-end'>
                            <Button className={classes.cancel} onClick={handleCancel} variant='contained'>キャンセルする</Button>
                        </Grid>
                        <Grid style={{ width: '20px' }} />
                        <Grid xs={5} item>
                            <Button className={classes.confirmButton} onClick={confirmButton} variant='contained'>OK</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SchoolDetailedDivision