import request from 'utils/request'
import { MCBJS020InitDisplayRequest } from 'types/MCBJS020/MCBJS020InitDisplayRequest'
import { MCBJS020SubmissionRequestTemplateInputRequest } from 'types/MCBJS020/MCBJS020submissionReqTemplateInputRequest'
import { MCBJS020SubmitRequestPreviewRequest } from 'types/MCBJS020/MCBJS020SubmitRequestPreviewRequest'
import { MCBJS020ReloadSubmissionsSetRequest } from 'types/MCBJS020/MCBJS020ReloadSubmissionsSetRequest'

export const submissionReqTemplateAddRequest = (params: MCBJS020InitDisplayRequest) =>
  request({
    url: `MCBJS020/init`,
    method: 'post',
    data: params
  })

export const submissionReqTemplateConfirmRequest = (requestBody: { registeredFileName: File[], submitParams: MCBJS020SubmissionRequestTemplateInputRequest }) => {
  const data = new FormData()
  requestBody.registeredFileName.forEach((file) => {
    data.append('files', file);
  });
  data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))
  return request({
    url: '/MCBJS020/confirm',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}

export const submissionReqTemplTargetYearChangeRequest = (params: MCBJS020SubmissionRequestTemplateInputRequest) =>
request({
  url: '/MCBJS020/requestTargetYearChange',
  method: 'post',
  data: params
})

export const selectApiLinkRequest = () =>
  request({
    url: '/MCBJS020/selectApiLink',
    method: 'post',
  })
// 26P #80795 start
export const reloadSubmissionsSetRequest  = (params: MCBJS020ReloadSubmissionsSetRequest) =>
// 26P #80795 end
request({
  url: '/MCBJS020/reloadSubmissionsSet',
  method: 'post',
  // 26P #80795 start
  data: params
  // 26P #80795 end
})

export const reloadThanksTemplateRequest  = () =>
request({
  url: '/MCBJS020/reloadThanksTemplate',
  method: 'post'
})

export const reloadReferenceRequest  = () =>
request({
  url: '/MCBJS020/reloadReference',
  method: 'post'
})

export const reloadPersonalRequest  = () =>
request({
  url: '/MCBJS020/reloadPersonal',
  method: 'post'
})

export const previewRequest  = (params: MCBJS020SubmitRequestPreviewRequest) =>
request({
  url: '/MCBJS020/preview',
  method: 'post',
  data: params
})
