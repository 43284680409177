import request from 'utils/request'
import { MCAYS010UpdateRequest } from 'types/MCAYS010/MCAYS010UpdateRequest'

export const commentInitRequest = () =>
  request({
    url: '/MCAYS010/init',
    method: 'post',
  })

export const commentUpdateRequest = (comment: MCAYS010UpdateRequest) =>
  request({
    url: '/MCAYS010/register',
    method: 'post',
    data: comment,
  })
