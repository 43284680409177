import { getMessage } from "common/messageUtil"

const signCreateInitialValues = {
  resisteredName: '',
  signatureText: '',
} 

const textMap = {
  register: {
    submit: '登録する',
    message: getMessage('MCANS020-003'),
  },
  cancel: {
    cancel: 'キャンセルする',
    message: getMessage('MCANS020-004'),
  },
}

export { signCreateInitialValues, textMap }
