import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps } from 'formik';
import Textarea from '@material-ui/core/TextareaAutosize';
import { FormControl } from '@material-ui/core';

interface Props extends FieldProps {
  label?: string
  type?: string
  variant?: any
  className?: any
  maxAmount?: number
  innerRef?: (instance: any) => void
}

const useStyles = makeStyles(theme => ({
  textarea: {
    '&:hover': {
      border: '1.2px solid black',
      transition: 'all 0.5s',
    },
    border: '1.2px solid darkgray',
    borderRadius: '3px',
  },
  textareaError: {
    borderColor: '#e53935',
    '&:hover': {
      border: '1.2px solid #e53935',
    },
  },
  helperText: {
    fontSize: '11px',
    marginTop: '4px',
    minHeight: '1em',
    textAlign: 'left',
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    whiteSpace: 'pre-wrap',
    marginLeft: '14px',
    marginRight: '14px'
  },
}));

const TextareaAutosize = ({ 
  field, 
  form, 
  label, 
  type, 
  innerRef,
  ...others 
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { name, value, onChange, onBlur } = field
  const { className } = others

  const handleChangeStrToArr = (event: any) => {
    const value = event.target.value;
    form.setFieldValue(name, value === '' ? [] : value.split('\n'))
  }

  return (
    <React.Fragment>
      <Textarea
        {...others}
        className={[
          className,
          classes.textarea,
          touched[name] && errors[name] ? classes.textareaError : '',
        ].join(' ')}
        value={value && name === 'newsTargetCompany' ? value.join('\n') : value}
        name={name}
        onChange={
          name === 'newsTargetCompany' ? handleChangeStrToArr : onChange
        }
        onBlur={onBlur}
        ref={innerRef}
      />
      <div>
        <FormControl error={Boolean(touched[name] && errors[name])} style={{display:'inline-block'}}>
          {touched[name] && errors[name] ? (
            <p className={classes.helperText}>
              {name === 'newsTargetCompany'
                ? '公開対象にはフォーマットが正しくない項目が存在する'
                : errors[name]}
            </p>
          ) : null}
        </FormControl>
      </div>
    </React.Fragment>
  )

}

export default TextareaAutosize;
