const initialValues = {
    showDialog020: false
}

const initialValues3 = {
    harassmentDivision: '',
    harassmentContent: '',
    jobSeekerId: '',
    entryId: '',
    recruitmentManagementDivision: ''
}

export { initialValues, initialValues3 }