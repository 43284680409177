import React from 'react'

import { sendError } from 'reducers/errorReducer'
import { getErrorFromStorage } from 'utils/error'
import { connect } from 'react-redux'
import Error500 from './Error500'
import { getWithExpiry, setWithExpiry } from 'utils/misc'
import { CHUNK_FAILED_KEY } from 'utils/contants'

const cssChunkFailedMessage = /Loading CSS chunk [\d]+ failed/
const jsChunkFailedName = 'ChunkLoadError'

interface Props {
  sendError: (error: ReturnType<typeof getErrorFromStorage>) => void
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (
      error &&
      (error.name === jsChunkFailedName ||
        cssChunkFailedMessage.test(error.message))
    ) {
      // prevent the possibility of an infinite reload loop
      if (!getWithExpiry(CHUNK_FAILED_KEY)) {
        setWithExpiry(CHUNK_FAILED_KEY, 'true', 10000)
        window.location.reload()
      }
      return
    }
    this.props.sendError({
      errorMessage: error.toString(),
      errorStack: (error.message ? error.message : "") + errorInfo.componentStack,
    })
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return <Error500 />
    }

    return this.props.children
  }
}

const mapDispatchToProps = {
  sendError,
}

export default connect(
  null,
  mapDispatchToProps
)(ErrorBoundary) as any
