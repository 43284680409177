import { magiContants } from 'utils/contants'

const initialValues = {
  selectionName: '',
  standardSeletion: false,
  selectionPeriodStart: '',
  selectionPeriodEnd: '',
  // 年度対応 start
  targetYear:'',  //対象年度
  targetYearDataList:[], //対象年度データリスト
  // 年度対応 end
  selectionType: 0,
  recruitmentCountTarget: '',
  selectionFlowArea: '',
}

const textMap = {
  register: {
    submit: '登録する',
    message: magiContants.MESSAGECODE_MCAJS020_003,
  },
  cancel: {
    cancel: 'キャンセルする',
    message: magiContants.MESSAGECODE_MCAJS020_005,
  },
}
const optionListSelection = [
  { label: '採用選考フロー', value: 0 },
  // 26P #82433 start 
  { label: 'プレサイト期間選考フロー', value: 1 },
  // 26P #82433 end
]

const buttonTextMap: { [key: string]: string } = {
  no: 'キャンセル',
  yes: 'OK',
  cancel: 'キャンセルする',
  ok: 'OK',
}

const constans = {
  PROGRESSSTATSTYPE_CUSTOMIZE: '1',
  PROGRESSSTATSTYPE_SYSTEM: '0',
  INVISIBLENOTPOSSIBLEFLAG_YES: '0',
  INVISIBLENOTPOSSIBLEFLAG_NO: '1',
  INVISIBLEFLAG_YES: 0,
  INVISIBLEFLAG_NO: 1,
  DECISION_DIVISION_TYPE_GOUHI_0: '0',
  DECISION_DIVISION_TYPE_GOUHI_2: '2',
  DECISION_DIVISION_TYPE_SANKA: '1',
}

const decisionDivisionTypeRadioData = [
  { key: '0', label: '合否管理型（選択肢：判定中／合格／不合格）' },
  { key: '1', label: '出欠管理型（選択肢：参加／不参加／その他）' },
]

export { initialValues, textMap, buttonTextMap, optionListSelection, constans, decisionDivisionTypeRadioData }

export type typeOfDialog = 'add' | 'change' | 'delete' | ''

export interface Errors {
  errorFlag: boolean
  errorInfo: any
}

