import { commentHistoryInit, setCommentHistory } from "reducers/commentHistoryReducer";
import { call, put, all, takeEvery } from 'redux-saga/effects'
import { commentHistoryApi } from "apis/MCAXS100Api";
import { openSnackbar, openModal } from "reducers/messageReducer";

function* commentHistoryInitSaga(action: ReturnType<typeof commentHistoryInit>) {
    try {
        const data = yield call(commentHistoryApi, action.payload);
        yield put(setCommentHistory(data));
    } catch (error) {
        yield put(openModal(error.message))
    }
}

export default function* commentHistorySaga() {
    yield all([
        takeEvery(commentHistoryInit, commentHistoryInitSaga),
    ])
}