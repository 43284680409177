import request from 'utils/request'
import UpdateRequestData from 'types/MCAIS030/MCAIS030UpdateRequest'
import DeleteRequestData from 'types/MCAIS030/MCAIS030DeleteRequest'

export const setMediaEditRequest = (mediaSettingId: string) =>
  request({
    url: `/MCAIS030/init/${mediaSettingId}`,
    method: 'post',
  })
export const updateMediaRequest = (mediaUpdate: UpdateRequestData) =>
  request({
    url: '/MCAIS030/update',
    method: 'post',
    data: mediaUpdate,
  })
export const deleteMediaRequest = (mediaDelete: DeleteRequestData) =>
  request({
    url: '/MCAIS030/delete',
    method: 'post',
    data: mediaDelete,
  })
