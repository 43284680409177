import React from 'react'
import { FieldProps } from 'formik'
import CreatableSelect from 'react-select/creatable'
import customSelectOptions from 'utils/customSelectOptions'
import { magiContants } from 'utils/contants'
import { makeStyles, Theme } from '@material-ui/core/styles'

const selectStyles = (errorFlg: boolean) => {
  if (errorFlg) {
    return {
      input: () => ({
        margin: 0,
        paddingBottom: 0,
        paddingTop: 0,
      }),
      menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
      container: (provided: any) => ({ ...provided, maxWidth: '50vw' }),
      multiValue: (provided: any) => ({ ...provided, maxWidth: '34vw' }),
      multiValueLabel: (provided: any) => ({ ...provided, maxWidth: '100%' }),
      option: (provided: any) => ({ ...provided, whiteSpace: 'nowrap' }),
      control: (provided: any, state: any) => ({
        ...provided,
        borderColor: '#e53935',
        boxShadow: state.isSelected ? '0 0 0 1px #e53935' : 'none',
        '&:hover': {
          borderColor: '#e53935',
          boxShadow: '0 0 0 1px #e53935',
        },
      }),
    }
  } else {
    return {
      input: () => ({
        margin: 0,
        paddingBottom: 0,
        paddingTop: 0,
      }),
      menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
      container: (provided: any) => ({ ...provided, maxWidth: '50vw' }),
      multiValue: (provided: any) => ({ ...provided, maxWidth: '34vw' }),
      multiValueLabel: (provided: any) => ({ ...provided, maxWidth: '100%' }),
      option: (provided: any) => ({ ...provided, whiteSpace: 'nowrap' }),
    }
  }
}

const useStyles = makeStyles(theme => ({
    message: {
      color: '#e53935',
    },
  })
)

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  placeholder?: string
  className?: string
  optionList?: Option[]
  noOptionsMessage: string
}

export const GroupSelect = ({
  className,
  placeholder,
  field,
  form,
  optionList,
  noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE,
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const {errors} = form

  const convert = (arg: string[]) =>
    arg
      ? arg.map(i => ({
          value: i,
          label: i,
        }))
      : null

  const handleChange = (option: any) => {
    form.setFieldValue(name, option ? option.map((i: any) => 
      i.label.length === undefined ? i.value : i.label
    ) : null)
  }

  return (
    <>
      <CreatableSelect
        name={name}
        value={convert(value)}
        className={className}
        isMulti
        options={optionList}
        placeholder={placeholder}
        styles={selectStyles(Boolean(errors[name]))}
        onChange={handleChange}
        formatCreateLabel={inputValue => `${inputValue}`}
        noOptionsMessage={({ inputValue }) => 
          inputValue === '' ? noOptionsMessage : `${inputValue}${magiContants.SUGGEST_ALREADY_INPUT}`
        }
        createOptionPosition={'first'}
        { ...customSelectOptions as any }
      />
      {errors[name] ? (
        <span className={classes.message}>{errors[name]}</span>
      ) : null}
    </>
  )
}
