import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAJS020CreateRequest } from 'types/MCAJS020/MCAJS020CreateRequest'
import { MCAJS020CustomizeProgressRequest } from 'types/MCAJS020/MCAJS020CustomizeProgressRequest'
import { MCAJS020IdRequest } from 'types/MCAJS020/MCAJS020IdRequest'
import { MCAJS020ProgressIdRequest } from 'types/MCAJS020/MCAJS020ProgressIdRequest'
import { MCAJS020UpdateCustomizeProgressRequest } from 'types/MCAJS020/MCAJS020UpdateCustomizeProgressRequest'
import { MCAJS030CustomizeProgressRequest } from 'types/MCAJS030/MCAJS030CustomizeProgressRequest'
import { MCAJS030FinishRequest } from 'types/MCAJS030/MCAJS030FinishRequest'
import { MCAJS030IdRequest } from 'types/MCAJS030/MCAJS030IdRequest'
import { MCAJS030ProgressIdRequest } from 'types/MCAJS030/MCAJS030ProgressIdRequest'
import { MCAJS030UpdateCustomizeProgressRequest } from 'types/MCAJS030/MCAJS030UpdateCustomizeProgressRequest'
import { MCAJS030UpdateRequest } from 'types/MCAJS030/MCAJS030UpdateRequest'
import { initialValues } from '../pages/MCAJS020/formConfig'
import { initialValues as initialValuesOfMCAJS030 } from '../pages/MCAJS030/formConfig'
import { MCAXS271InitRequest } from 'types/MCAXS271/MCAXS271InitRequest'
import { MCAXS271AddSelectionRequest} from 'types/MCAXS271/MCAXS271AddSelectionRequest'
import { MCAXS280InitRequest } from 'types/MCAXS280/MCAXS280InitRequest'
import { MCAXS280ChangeSelectionRequest } from 'types/MCAXS280/MCAXS280ChangeSelectionRequest'

export interface ModalForCheck {
  openCheckModal: boolean
  modalMessage: string
}

// 登録進捗用
export interface ItemProperty {
  progressStatusSettingId: number // 選考ステップ設定ID
  progressStatsType: string // 選考ステップ種別 0:システム固定、1:カスタマイズ進捗
  progressClassification: number // 進捗分類
  progressName: string // 進捗名
  invisibleFlag: number // 非表示フラグ 0: 表示、1: 非表示
  displayOrder: number // 表示順
  selectionFlowDisplayOrder: number // 選考フロー表示順
  groupDisplayOrder: number // グループ表示順
  settingWithinInvisible: string // 設定中非表示
  invisibleNotPossibleFlag: string // 非表示不可フラグ
  sysVersionNumber: number // sysバージョン番号
  decisionDivisionType: string // 判定区分種別
}

// 編集進捗用
export interface ItemPropertyForUpdate {
  progressStatusSettingId: number // 選考ステップ設定ID
  progressStatsType: string // 選考ステップ種別 0:システム固定、1:カスタマイズ進捗
  progressClassification: number // 進捗分類
  progressName: string // 進捗名
  invisibleFlag: number // 非表示フラグ 0: 表示、1: 非表示
  displayOrder: number // 表示順
  selectionFlowDisplayOrder: number // 選考フロー表示順
  groupDisplayOrder: number // グループ表示順
  settingWithinInvisible: string // 設定中非表示
  invisibleNotPossibleFlag: string // 非表示不可フラグ
  sysVersionNumber: number // sysバージョン番号
  entryUse: number // エントリー利用中
  filterAvailable: number // フィルタアクション利用中
  decisionDivisionType: string // 判定区分種別
}

// 登録進捗初期値
const initialProgressData: ItemProperty = {
  progressStatusSettingId: 0,
  progressName: '',
  progressStatsType: '0',
  progressClassification: 0,
  invisibleFlag: 0,
  displayOrder: 0,
  selectionFlowDisplayOrder: 0,
  groupDisplayOrder: 0,
  settingWithinInvisible: '0',
  invisibleNotPossibleFlag: '0',
  sysVersionNumber: 0,
  decisionDivisionType: '0',
}

// MCAJS010
export interface SelectionFlow {
  selectionFlowSettingId: number //選考フロー設定ID
  selectionName: string //選考名
  selectionClassification: string //選考分類
  selectionType: string //選考分類
  selectionStartDate: string //選考開始日
  selectionEndDate: string //選考終了日
  selectionPeriod: string //選考期間
  selectionFlow: string //選考フローサマリ
  selectionEndFlag: string //選考終了フラグ
  grayFlag: string //グレーアウトフラグ
  targetCount: number
  selectionInfo: selectionInfo[]
  changeCandidateSelectionInfoDtoList: changeCandidateSelectionInfoDtoList[]
  currentSelectionInfo:currentSelectionInfo
}

// MCAJS020
// フォームデータ
export interface SelectionFlowInfo {
  selectionName: string //選考名
  standardSeletion: boolean //標準選考フラグ
  // 年度対応 start
  targetYear:string //対象年度
  targetYearDataList:string[] //対象年度データリスト
  // 年度対応 end
  selectionType: number //選考分類
  selectionPeriodStart: string //選考期間_開始日
  selectionPeriodEnd: string //選考期間_終了日
  recruitmentCountTarget: string //採用目標
  selectionFlowArea: string
}
// 進捗初期表示データ
export interface ProgressInitForCreate {
  newGraduateRecruitmentDataList: ItemProperty[] // 新卒採用データ
  newGraduatePrepareDataList: ItemProperty[] // 新卒プレデータ
  midCareerDataList: ItemProperty[] // 中途データ
}

// MCAJS030
export interface SelectionFlowUpdateInfo {
  selectionFlowSettingId: number //選考フロー設定ID
  selectionName: string //選考名
  selectionNameFirst: string //選考名(初期)
  standardSeletion: boolean //標準選考フラグ
  // 年度対応 start
  targetYear:string //対象年度
  // 年度対応 end
  selectionType: string //選考分類
  selectionTypeFlag: boolean //選考分類フラグ
  selectionPeriodStart: string //選考開始日
  selectionPeriodEnd: string //選考終了日
  recruitmentCountTarget: string //採用目標
  selectionFlowArea: string
  versionNumber: number //sysバージョン番号（選考フロー設定）
  selectionFinish: boolean
  progressDataList: ItemPropertyForUpdate[] //進捗データリスト
}

// MCAXS271SelectionInfoDto
export interface selectionInfo {
  selectionFlowSettingId: string
  selectionName: string //選考名
  selectionClassification: string //選考分類
  selectionStartDate: string //選考開始日
  selectionEndDate: string //選考終了日
  selectionFlowSummary: string //選考フローサマリ
}

export interface changeCandidateSelectionInfoDtoList {
selectionFlowSettingId: string
selectionName: string //選考名
selectionClassification: string //選考分類
selectionStartDate: string //選考開始日
selectionEndDate: string //選考終了日
selectionFlowSummary: string //選考フローサマリ
progressName:string //進捗名
}

export interface currentSelectionInfo {
selectionFlowSettingId: string
selectionName: string //選考名
selectionClassification: string //選考分類
selectionStartDate: string //選考開始日
selectionEndDate: string //選考終了日
selectionFlowSummary: string //選考フローサマリ
progressName:string //進捗名
}

const SelectionFlowValues: selectionInfo[] = []
const ChangeSelectionFlowValues: changeCandidateSelectionInfoDtoList[] = []
const selectionCurrentInitialValues = {
selectionFlowSettingId: '',
selectionName: '',
selectionClassification: '',
selectionStartDate: '',
selectionEndDate: '',
selectionFlowSummary: '',
progressName:'', //進捗名
}

export interface SelectionFlow1 {
targetCount: number
selectionInfo: selectionInfo[]
changeCandidateSelectionInfoDtoList: changeCandidateSelectionInfoDtoList[]
currentSelectionInfo:currentSelectionInfo
}

const selectionInitialState: SelectionFlow1 = {
  targetCount: 0,
  selectionInfo: SelectionFlowValues,
  changeCandidateSelectionInfoDtoList:ChangeSelectionFlowValues,
  currentSelectionInfo:selectionCurrentInitialValues,
}
export {selectionInitialState}

// redux state定義
const initialState: {
  // 選考フロー一覧
  selectionFlowTableData: SelectionFlow[]
  // 選考フロー登録
  selectionFlowInfoData: SelectionFlowInfo
  openMCAJS020: boolean
  copyFlag: boolean
  modalForCheckOfMCAJS020: ModalForCheck
  ProgressInitForCreateData: ProgressInitForCreate
  // 選考フロー編集
  selectionFlowInfoDataForUpdate: SelectionFlowUpdateInfo
  openMCAJS030: boolean
  modalForCheckOfMCAJS030: ModalForCheck
  snackBarForCheckOfMCAJS030: ModalForCheck
  // 進捗操作
  switchForProgressAddOrChange: boolean
  switchForProgressAddOrChangeOnUpdate: boolean
  progressData: ItemProperty
  targetCount: number
  selectionInfo: selectionInfo[]
  changeCandidateSelectionInfoDtoList: changeCandidateSelectionInfoDtoList[]
  currentSelectionInfo: currentSelectionInfo
  selectionManagementId: string
} = {
  selectionFlowTableData: [],
  selectionFlowInfoData: initialValues,
  openMCAJS020: false,
  copyFlag: false,
  modalForCheckOfMCAJS020: {
    openCheckModal: false,
    modalMessage: '',
  },
  ProgressInitForCreateData: {
    midCareerDataList: [],
    newGraduatePrepareDataList: [],
    newGraduateRecruitmentDataList: [],
  },
  selectionFlowInfoDataForUpdate: initialValuesOfMCAJS030,
  openMCAJS030: false,
  modalForCheckOfMCAJS030: {
    openCheckModal: false,
    modalMessage: '',
  },
  snackBarForCheckOfMCAJS030: {
    openCheckModal: false,
    modalMessage: '',
  },
  switchForProgressAddOrChange: false,
  switchForProgressAddOrChangeOnUpdate: false,
  progressData: initialProgressData,
  targetCount: 0,
  selectionInfo: [],
  changeCandidateSelectionInfoDtoList:[],
  currentSelectionInfo:{
    selectionFlowSettingId: '',
    selectionName: '',
    selectionClassification: '',
    selectionStartDate: '',
    selectionEndDate: '',
    selectionFlowSummary: '',
    progressName:'', //進捗名
  },
  selectionManagementId:''
}

const selectionFlowReducer = createSlice({
  name: 'selectionFlow',
  initialState,
  reducers: {
    getSelectionFlowTableData(state) {
      return state
    },
    setSelectionFlowTableData(state, action: PayloadAction<SelectionFlow[]>) {
      state.selectionFlowTableData = action.payload
      return state
    },
    // MCAJS020
    // 本モーダルスイッチ
    setOpenSelectionFlowCreate(state, action: PayloadAction<boolean>) {
      state.openMCAJS020 = action.payload
      return state
    },
    // 選考フロー登録 初期データ取得
    getSelectionFlowCreateInitData(
      state,
      action: PayloadAction<MCAJS020IdRequest>
    ) {
      return state
    },
    // 選考フロー登録初期表示データを設定
    setSelectionFlowInitData(state, action: PayloadAction<SelectionFlowInfo>) {
      state.selectionFlowInfoData = action.payload
      return state
    },
    // 選考フロー登録初期表示　進捗データを設定
    setProgressInitForCreateData(
      state,
      action: PayloadAction<ProgressInitForCreate>
    ) {
      state.ProgressInitForCreateData = action.payload
      return state
    },
    // 選考フロー登録
    createSelectionFlow(state, action: PayloadAction<MCAJS020CreateRequest>) {
      return state
    },
    // 選考フロー登録チェック情報設定
    setSelctionFlowCreateCheckData(
      state,
      action: PayloadAction<ModalForCheck>
    ) {
      state.modalForCheckOfMCAJS020 = action.payload
      return state
    },
    // コピーフラグ設定
    setCopyFlag(state, action: PayloadAction<boolean>) {
      state.copyFlag = action.payload
    },

    // MCAJS030
    // モーダルスイッチ
    setOpenSelectionFlowUpdate(state, action: PayloadAction<boolean>) {
      state.openMCAJS030 = action.payload
      return state
    },
    // 選考フロー編集 初期データ取得
    getSelectionFlowUpdateInitData(
      state,
      action: PayloadAction<MCAJS030IdRequest>
    ) {
      return state
    },
    // 選考フロー編集初期表示データを設定
    setSelectionFlowUpdateInitData(
      state,
      action: PayloadAction<SelectionFlowUpdateInfo>
    ) {
      state.selectionFlowInfoDataForUpdate = action.payload
      return state
    },
    // 選考フロー更新
    updateSelectionFlow(state, action: PayloadAction<MCAJS030UpdateRequest>) {
      return state
    },
    // 選考フロー終了
    finishSelectionFlow(state, action: PayloadAction<MCAJS030FinishRequest>) {
      return state
    },
    // 選考フロー終了
    deleteSelectionFlow(state, action: PayloadAction<MCAJS030IdRequest>) {
      return state
    },
    // 選考フロー編集チェック情報設定
    setSelctionFlowUpdateCheckData(
      state,
      action: PayloadAction<ModalForCheck>
    ) {
      state.modalForCheckOfMCAJS030 = action.payload
      return state
    },
    // 選考フロー編集チェック情報設定（SnackBar）
    setSelctionFlowUpdateCheckDataSnackBar(
      state,
      action: PayloadAction<ModalForCheck>
    ) {
      state.snackBarForCheckOfMCAJS030 = action.payload
      return state
    },

    // カスタマイズ進捗
    // カスタマイズ進捗追加
    creatCustomizeProgress(
      state,
      action: PayloadAction<MCAJS020CustomizeProgressRequest>
    ) {
      return state
    },
    creatCustomizeProgressForUpdate(
      state,
      action: PayloadAction<MCAJS030CustomizeProgressRequest>
    ) {
      return state
    },
    // カスタマイズ進捗更新
    updateCustomizeProgress(
      state,
      action: PayloadAction<MCAJS020UpdateCustomizeProgressRequest>
    ) {
      return state
    },
    updateCustomizeProgressForUpdate(
      state,
      action: PayloadAction<MCAJS030UpdateCustomizeProgressRequest>
    ) {
      return state
    },
    // カスタマイズ進捗削除
    deleteCustomizeProgress(
      state,
      action: PayloadAction<MCAJS020ProgressIdRequest>
    ) {
      return state
    },
    deleteCustomizeProgressForUpdate(
      state,
      action: PayloadAction<MCAJS030ProgressIdRequest>
    ) {
      return state
    },
    // 操作しているカスタマイズ進捗データ設定
    setProgressData(state, action: PayloadAction<ItemProperty>) {
      state.progressData = action.payload
      return state
    },
    // カスタマイズ進捗追加/更新スイッチ
    setSetSwitchForAddOrChange(state, action: PayloadAction<boolean>) {
      state.switchForProgressAddOrChange = action.payload
      return state
    },
    setSetSwitchForAddOrChangeOnUpdate(state, action: PayloadAction<boolean>) {
      state.switchForProgressAddOrChangeOnUpdate = action.payload
      return state
    },
    // MCAXS271
    fetchSelectionFlow(state,action:PayloadAction<MCAXS271InitRequest>) {
      return state
    },
    fetchSelectionFlowSuccess: (state, action: PayloadAction<SelectionFlow1>) => {
      state.selectionInfo = action.payload.selectionInfo
      state.targetCount = action.payload.targetCount
      return state
    },

    insertSelectionFlow(state,action:PayloadAction<{request: MCAXS271AddSelectionRequest, onclose?: () => void}>) {
      return state
    },  
    // MCAXS280
    fetchSelectionFlowChange(state,action:PayloadAction<MCAXS280InitRequest>){
      return state
    },

    fetchSelectionFlowChangeSuccess: (state, action: PayloadAction<SelectionFlow1>) => {
      state.currentSelectionInfo = action.payload.currentSelectionInfo
      state.changeCandidateSelectionInfoDtoList = action.payload.changeCandidateSelectionInfoDtoList
      state.targetCount = action.payload.targetCount
      return state
    },

    changeSelectionFlow(state,action:PayloadAction<{request: MCAXS280ChangeSelectionRequest, onclose?: () => void}>) {
      return state
    },
    setSelectionManagementId(state, action: PayloadAction<string>) {
      state.selectionManagementId = action.payload
      return state
    },
  },
})

export const {
  // MCAJS010
  getSelectionFlowTableData,
  setSelectionFlowTableData,
  // MCAJS020
  setSelectionFlowInitData,
  getSelectionFlowCreateInitData,
  setProgressInitForCreateData,
  setOpenSelectionFlowCreate,
  createSelectionFlow,
  setSelctionFlowCreateCheckData,
  setCopyFlag,
  // MCAJS030
  getSelectionFlowUpdateInitData,
  setSelectionFlowUpdateInitData,
  setOpenSelectionFlowUpdate,
  updateSelectionFlow,
  finishSelectionFlow,
  deleteSelectionFlow,
  setSelctionFlowUpdateCheckData,
  setSelctionFlowUpdateCheckDataSnackBar,
  // カスタマイズ進捗
  creatCustomizeProgress,
  creatCustomizeProgressForUpdate,
  updateCustomizeProgress,
  updateCustomizeProgressForUpdate,
  deleteCustomizeProgress,
  deleteCustomizeProgressForUpdate,
  setSetSwitchForAddOrChange,
  setSetSwitchForAddOrChangeOnUpdate,
  setProgressData,
  // 選考フロー追加と変更
  fetchSelectionFlow,
  fetchSelectionFlowSuccess,
  insertSelectionFlow,
  fetchSelectionFlowChange,
  fetchSelectionFlowChangeSuccess,
  changeSelectionFlow,
  setSelectionManagementId
} = selectionFlowReducer.actions

//MCAJS020
export default selectionFlowReducer.reducer


