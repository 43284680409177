import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FieldGroup, SubTitle, Title, FormField } from 'componentsHsc'
import { FormControl } from '..'
import { RootState } from 'reducers'
import { useSelector, useDispatch } from 'react-redux'
import { getMcaxs491Init } from 'reducers/advancedSearchModalReducer'
import { magiContants as contants } from 'utils/contants'
import {
  WebSeminarObj,
  WebSeminarConditionObj,
  WebSeminarWatchingStatusConditionObj,
} from '../Config/searchConditionConfig'
import { WebSeminar } from '../Config/seminarMediaModalConfig'

import { getMessage } from 'common/messageUtil'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { Tooltip as MuiTooptip, IconButton } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'visible !important'
  },
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 2,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span': {
      color: colors.grey[600],
    },
  },
  content: {
    height: 'inherit !important',
  },
  cancel: {
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 150,
    marginRight: '20px'
  },
  confirmButton: {
    color: '#ffffff',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#3380cc',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
      borderColor: '#3380cc',
    },
    minWidth: 150,
    marginLeft: '20px !important'
  },
  dividerColor: {
    backgroundColor: colors.grey[600],
  },
  labelTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelExplain: {
    lineHeight: '1.5',
    whiteSpace: 'nowrap',
    color: 'black',
  },
  labelTitle2: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    marginLeft:theme.spacing(15),
  },

  helperExplain: {
    margin: '0px 0px !important',
    width: '80% !important',
  },
  checkBoxItemData: {
    flex: '1 !important',
  },
  checkBoxRoot: {
    marginLeft: '2px',
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  },
  noCheck: {
    background: 'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  checked: {
    backgroundColor: '#3f71bd',
    backgroundPosition: 'center center',
    borderColor: '#3f71bd',
    background: 'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  rootTooltip: {
    width: '90%',
    display: 'flex',
    alignItems: ({ fullHeight }: any) => 
      fullHeight ? 'stretch' : 'flex-start',
    margin: ({ fullHeight }: any) => 
      fullHeight ? theme.spacing(0, 10) : theme.spacing(2, 10),
    '& > :first-child': {
      flex: 3,
    },
    '& > :nth-child(2)': {
      flex: 6,
    },
    '& > :last-child': {
      flex: 1,
    },
  },
  buttonTooltip: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const Tooltip = withStyles({
  tooltip: {
    fontSize: '1em',
    maxWidth: '500px',
  },
  popper: {
    whiteSpace: 'pre-line'
  }
})(MuiTooptip)

const searchOptionList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

const optionList = [
  { label: '未視聴', value: '0' },
  { label: '視聴中', value: '1' },
  { label: '視聴済', value: '2' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

const SeminarMediaModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirm, setConfirm] = useState(false)
  const [selectedSearchType, setSelectedSearchType] = useState([] as boolean[])
  const [checkBoxItemData, setCheckBoxItemData] = useState<boolean[][]>([])
  const [selectAllFlag, setSelectAllFlag] = useState([false,false,false] as boolean[])

  const icon = () => {return <div className={classes.noCheck} />}
  const iconChecked = () => {return <div className={classes.checked} />}

  const { webSeminarList } = useSelector(
    (state: RootState) => state.advancedSearchModal.seminarMediaModalInfo
  )

  useEffect(() => {
    dispatch(getMcaxs491Init())
  }, [open])

  useEffect(() => {
    if (open) {
      if (form.values.webSeminarObj) {
        let flglst: boolean[] = []
        if (form.values.webSeminarObj.webSeminarConditionJudgmentFlag === 0) {
          flglst.push(true)
          flglst.push(false)
        } else {
          flglst.push(false)
          flglst.push(true)
        }
        setSelectedSearchType(flglst)
        if (form.values.webSeminarObj.webSeminarConditionObj) {
          let checkBoxItemDataList: boolean[][] = []
          const fromval = form.values.webSeminarObj.webSeminarConditionObj
          if (webSeminarList) {
            if (webSeminarList.length > 0) {
              for (var i = 0; i < webSeminarList.length; i++) {
                const seminarScheduleId1 = webSeminarList[i].seminarScheduleId
                for (var j = 0; j < webSeminarList.length; j++) {
                  const seminarScheduleId2 = webSeminarList[j].seminarScheduleId
                  if (seminarScheduleId1 == seminarScheduleId2) {
                    let checkBoxItem : boolean[] = [false, false, false]
                    for (var z = 0; z < fromval.length; z++) {
                      if (
                        fromval[z].webSeminarSeminarScheduleId ===
                          webSeminarList[j].seminarScheduleId &&
                        fromval[z].webSeminarCoordinationFormerWebSeminarId ===
                          webSeminarList[j].coordinationFormerWebSeminarId &&
                        fromval[z].webSeminarTitle ===
                          webSeminarList[j].webSeminarTitle
                      ) {
                        if (fromval[z].webSeminarWatchingStatusConditionObj !== null ) {
                          for (let k = 0; k < fromval[z].webSeminarWatchingStatusConditionObj.length; k++) {
                            if(0 == fromval[z].webSeminarWatchingStatusConditionObj[k].webSeminarWatchingStatusConditionFlag) {
                              checkBoxItem[0] = true
                            } else if(1 == fromval[z].webSeminarWatchingStatusConditionObj[k].webSeminarWatchingStatusConditionFlag) {
                              checkBoxItem[1] = true
                            } else if(2 == fromval[z].webSeminarWatchingStatusConditionObj[k].webSeminarWatchingStatusConditionFlag) {
                              checkBoxItem[2] = true
                            }
                          }
                          break
                        }
                        
                      }
                    }
                    checkBoxItemDataList.push(checkBoxItem)
                  }
                }
              }
            }
          }
          setCheckBoxItemData(checkBoxItemDataList)
          let selectAllFlag2: boolean[] = []
          for (let g = 0; g < 3; g++) {
            let selectAllFlagChecksTrue = checkBoxItemDataList.filter(i => i[g] === true)
      
            if (checkBoxItemDataList.length != 0 && selectAllFlagChecksTrue.length === checkBoxItemDataList.length) {
              selectAllFlag2[g] = true
            } else {
              selectAllFlag2[g] = false
            }
          }
          setSelectAllFlag(selectAllFlag2)
        }
      } else {
        let flglst: boolean[] = []
        let checkBoxItemDataList: boolean[][] = []
        flglst.push(true)
        flglst.push(false)
        setSelectedSearchType(flglst)
        setSelectAllFlag([false,false,false])
        if(webSeminarList){
          for(var i = 0; i < webSeminarList.length; i++){
            checkBoxItemDataList.push([false,false,false])
          }
        }
        setCheckBoxItemData(checkBoxItemDataList)
      }
    }
  }, [open])

  const handleClick = (open = true) => () => {
    setConfirm(open)
  }

  const handleClose = () => {
    setConfirm(false)
    onClose()
  }

  const handleSubmit = () => {
    let webSeminarObj: WebSeminarObj = {
      webSeminarConditionJudgmentFlag: 1,
      webSeminarConditionJudgmentName: '',
      webSeminarConditionObj: [],
    }

    var searchValue: any = document.getElementsByName('seminarMediaSearch')
    if (searchValue) {
      if (searchValue[0].checked) {
        webSeminarObj.webSeminarConditionJudgmentFlag = 0
        webSeminarObj.webSeminarConditionJudgmentName = 'AND'
      } else if (searchValue[1].checked) {
        webSeminarObj.webSeminarConditionJudgmentFlag = 1
        webSeminarObj.webSeminarConditionJudgmentName = 'OR'
      }
      if (webSeminarList) {
        if (webSeminarList.length > 0) {
          for (var i = 0; i < webSeminarList.length; i++) {
            const seminarScheduleId1 = webSeminarList[i].seminarScheduleId
            for (var j = 0; j < webSeminarList.length; j++) {
              const seminarScheduleId2 = webSeminarList[j].seminarScheduleId
              const contractMediaId2 = webSeminarList[j].contractMediaId
              if (seminarScheduleId1 == seminarScheduleId2) {
                var fromValue: any = document.getElementsByName(
                  `${contractMediaId2}webSeminarList[${j}]`
                )
                if (fromValue) {
                  let webSeminarConditionObj: WebSeminarConditionObj = {
                    webSeminarApplicationRouteId: webSeminarList[j].contractMediaId,
                    webSeminarApplicationRouteName: webSeminarList[j].contractMediaName,
                    webSeminarSeminarScheduleId:
                      webSeminarList[j].seminarScheduleId,
                    webSeminarCoordinationFormerWebSeminarId:
                      webSeminarList[j].coordinationFormerWebSeminarId,
                    webSeminarTitle: webSeminarList[j].webSeminarTitle,
                    webSeminarWatchingStatusConditionFlag: 0,
                    webSeminarWatchingStatusConditionName: '',
                    webSeminarWatchingStatusConditionObj: []
                  }
                  let objFlg = false

                  if (fromValue[0].checked) {
                    let webSeminarWatchingStatusConditionObj: WebSeminarWatchingStatusConditionObj = {
                      webSeminarWatchingStatusConditionFlag: 0,
                      webSeminarWatchingStatusConditionName: ''
                    }
                    webSeminarWatchingStatusConditionObj.webSeminarWatchingStatusConditionFlag = 0
                    webSeminarWatchingStatusConditionObj.webSeminarWatchingStatusConditionName = '未視聴'
                    webSeminarConditionObj.webSeminarWatchingStatusConditionObj.push(webSeminarWatchingStatusConditionObj)
                    objFlg = true
                  }
                  if (fromValue[1].checked) {
                    let webSeminarWatchingStatusConditionObj: WebSeminarWatchingStatusConditionObj = {
                      webSeminarWatchingStatusConditionFlag: 1,
                      webSeminarWatchingStatusConditionName: ''
                    }
                    webSeminarWatchingStatusConditionObj.webSeminarWatchingStatusConditionFlag = 1
                    webSeminarWatchingStatusConditionObj.webSeminarWatchingStatusConditionName = '視聴中'
                    webSeminarConditionObj.webSeminarWatchingStatusConditionObj.push(webSeminarWatchingStatusConditionObj)
                    objFlg = true
                  }
                  if (fromValue[2].checked) {
                    let webSeminarWatchingStatusConditionObj: WebSeminarWatchingStatusConditionObj = {
                      webSeminarWatchingStatusConditionFlag: 2,
                      webSeminarWatchingStatusConditionName: ''
                    }  
                    webSeminarWatchingStatusConditionObj.webSeminarWatchingStatusConditionFlag = 2
                    webSeminarWatchingStatusConditionObj.webSeminarWatchingStatusConditionName = '視聴済'
                    webSeminarConditionObj.webSeminarWatchingStatusConditionObj.push(webSeminarWatchingStatusConditionObj)
                    objFlg = true
                  }
                  if(objFlg) {
                    webSeminarObj.webSeminarConditionObj.push(webSeminarConditionObj)
                  }
                }
              }
            }
          }
        }
      }
    }

    if (webSeminarObj.webSeminarConditionObj.length > 0) {
      form.setFieldValue('webSeminarObj', webSeminarObj)
    } else {
      form.setFieldValue('webSeminarObj', null)
    }

    handleClose()
    // TODO: formik
  }

  const handleChangeSearchType = (e: any) => {
    let flglst: boolean[] = []
    if (e.target.value === '0') {
      flglst.push(true)
      flglst.push(false)
    } else {
      flglst.push(false)
      flglst.push(true)
    }
    setSelectedSearchType(flglst)
  }

  const handleCheckBoxAllChange = (e: any) => {
    let selectAllFlag2: boolean[] = []
    selectAllFlag2.push(selectAllFlag[0])
    selectAllFlag2.push(selectAllFlag[1])
    selectAllFlag2.push(selectAllFlag[2])
    selectAllFlag2[e.target.value] = e.target.checked
    setSelectAllFlag(selectAllFlag2)

    let checkBoxItemData2: boolean[][] = []
    for (let i = 0; i < checkBoxItemData.length; i++) {
      checkBoxItemData2.push(checkBoxItemData[i])
      checkBoxItemData2[i][e.target.value] = e.target.checked
    }
    setCheckBoxItemData(checkBoxItemData2)
  }

  const handleCheckBoxItemDataSelect = (index: number) => (e: any) => {
    if (checkBoxItemData && checkBoxItemData.length > 0) {
      let checkBoxItemData2: boolean[][] = []
      for (let i = 0; i < checkBoxItemData.length; i++) {
        checkBoxItemData2.push(checkBoxItemData[i])
      }
      checkBoxItemData2[index][e.target.value] = e.target.checked
      setCheckBoxItemData(checkBoxItemData2)

      let selectAllFlagChecksTrue = checkBoxItemData.filter(i => i[e.target.value] === true)
      let selectAllFlag2: boolean[] = []
      selectAllFlag2.push(selectAllFlag[0])
      selectAllFlag2.push(selectAllFlag[1])
      selectAllFlag2.push(selectAllFlag[2])

      if (selectAllFlagChecksTrue.length === checkBoxItemData.length) {
        selectAllFlag2[e.target.value] = true
      } else {
        selectAllFlag2[e.target.value] = false
      }
      setSelectAllFlag(selectAllFlag2)
    }
  }

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='md' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>Webセミナー視聴状況を指定</label>
        </DialogTitle>
        <DialogContent>
          <div className={`ps-container ps ${classes.root}`}>
            <div className={classes.subTitle}>
            <RadioGroup row onChange={handleChangeSearchType}>
                <FormLabel className={classes.labelTitle}>
                  Webセミナー間の検索方法
                </FormLabel>
              {searchOptionList
                ? searchOptionList.map((item, index1) => (
                    <>
                      <FormControlLabel
                        control={
                          <Radio
                            color='primary'
                            name='seminarMediaSearch'
                            checked={
                              selectedSearchType
                                ? selectedSearchType[index1]
                                : undefined
                            }
                            value={item.value}
                          />
                        }
                        label={item.label}
                      />
                    </>
                  ))
                : null}
            </RadioGroup>
            <div>
                <FormLabel className={classes.labelTitle2}>
                  一括チェック　　
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox color='primary' value='0' name='selectSearch4' checked={selectAllFlag[0] === true}
                              onChange={handleCheckBoxAllChange}
                              className={classes.checkBoxRoot}
                              icon={icon()}
                              checkedIcon={iconChecked()}
                    />
                  }
                  label='未視聴'
                />
                <FormControlLabel
                  control={
                    <Checkbox color='primary' value='1' name='selectSearch4' checked={selectAllFlag[1] === true}
                              onChange={handleCheckBoxAllChange}
                              className={classes.checkBoxRoot}
                              icon={icon()}
                              checkedIcon={iconChecked()}
                    />
                  }
                  label='視聴中'
                />
                <FormControlLabel
                  control={
                    <Checkbox color='primary' value='2' name='selectSearch4' checked={selectAllFlag[2] === true}
                              onChange={handleCheckBoxAllChange}
                              className={classes.checkBoxRoot}
                              icon={icon()}
                              checkedIcon={iconChecked()}
                    />
                  }
                  label='視聴済'
                />
                </div>
            </div>
          <div className={classes.subTitle}>
            <FormLabel className={classes.labelExplain}>
              1つのWebセミナーに対して複数の視聴状況を選択して検索が可能です。
              <br />
              その場合の検索結果は「OR検索」となります。
            </FormLabel>
            <div className={`${classes.helperExplain} ${classes.rootTooltip}`}>
              <Tooltip title={getMessage('MCAXS491-002')} placement='bottom-end'>
                <IconButton className={classes.buttonTooltip}>
                  <HelpOutlineIcon fontSize='large' />
                </IconButton>
              </Tooltip>
            </div>
            </div>
            {webSeminarList
              ? webSeminarList.map((webSeminar1, index1) => (
                  <>
                    {(index1 === 0 ||
                      webSeminar1.contractMediaId !==
                        webSeminarList[index1 - 1].contractMediaId) && (
                      <FieldGroup>
                        <SubTitle variant='h5' noUnderline>
                          {webSeminar1.contractMediaName}
                        </SubTitle>
                        {webSeminarList.map((webSeminar2, index2) => (
                          <>
                            {webSeminar2.contractMediaId ===
                              webSeminar1.contractMediaId && (
                              <FormField>
                                <Title
                                  title={`${webSeminar2.coordinationFormerWebSeminarId} ${webSeminar2.webSeminarTitle}`}
                                />
                                  {optionList
                                    ? optionList.map((item1, index1) => (
                                        <>
                                          <FormControlLabel className={classes.checkBoxItemData}
                                            control={
                                              <Checkbox color='primary' 
                                                        name={`${webSeminar1.contractMediaId}webSeminarList[${index2}]`}
                                                        checked={checkBoxItemData && checkBoxItemData[index2]
                                                          ? checkBoxItemData[index2][Number(item1.value)] : false
                                                        }
                                                        value={item1.value}
                                                        onChange={handleCheckBoxItemDataSelect(index2)}
                                                        className={classes.checkBoxRoot}
                                                        icon={icon()}
                                                        checkedIcon={iconChecked()}
                                              />
                                            }
                                            label={item1.label}
                                          />
                                        </>
                                      ))
                                    : null}
                              </FormField>
                            )}
                          </>
                        ))}
                      </FieldGroup>
                    )}
                  </>
                ))
              : null}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClick()}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS491_001}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SeminarMediaModal
