/**
 * SearchRequestValidation
 */
import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({
  /**
   * 基本情報
   */

  // 応募者管理ID
  // -
  applicantId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumberIndention.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 氏名／氏名カナ
  // -
  name: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 年齢From
  // -
  ageFrom: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 年齢To
  // -
  ageTo: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 性別
  // -
  sexObj: yup.lazy(value => (value ? yup.string() : yup.mixed().notRequired())),

  // 性別コード
  // -
  sexCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 性別名
  // -
  sexName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // メールアドレス
  // -
  mailAddress: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthAlphanumericSymbol',
            validationMessageSet.halfWidthAlphanumericSymbol,
            (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 配偶者
  // -
  spouseObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 配偶者名
  // -
  spouseCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 配偶者名
  // -
  spouseName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 居住地（都道府県）
  // -
  residenceObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 居住地条件（都道府県）コード
  // -
  residencePrefecturesCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 居住地条件（都道府県）名
  // -
  residencePrefecturesName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 最終学歴
  // -
  finalEducationUniversityObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 最終学歴コード
  // -
  finalEducationUniversityCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 最終学歴名
  // -
  finalEducationUniversityName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 学歴
  // -
  educationalBackground: yup.lazy(value =>
    value
      ? yup
          .mixed()
          .test('testString',
          '',
          async function (value: any[]) {
            let flg = false
            let message
            await test.validate({ testStrings: value.map(i => i).join('') }).then(async () => {
              await yup.mixed()
              .test(
                'isHalfWidth',
                validationMessageSet.halfWidth,
                (value: any[]) => {return value && value.every((i: any) => {
                  return miscRegexSet.halfWidth.test(i);
                })}
              )
              .validate(value,{abortEarly:false}).catch(err=>{
                message = Array.isArray(err.errors) ? err.errors.join('\n') : err.errors
                flg = true
              })
            }).catch(err => {
              message = err.errors
              flg = true
            })
            if (flg) {
              return this.createError({ message: message })
            } else {
              return true
            }
          })
      : yup.mixed().notRequired()
  ),

  // 語学スキル
  // -
  languageSkills: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 保有資格
  // -
  eligibility: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 経験社数
  // -
  experienceCountObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 経験社数コード
  // -
  experienceCountCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 経験社数名
  // -
  experienceCountName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 現在の就業状況
  // -
  employmentSituationObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // // 現在の就業状況コード
  // // -
  employmentSituationCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // // 現在の就業状況名
  // // -
  employmentSituationName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 希望勤務地（都道府県）
  // -
  preferredWorkplaceObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 希望勤務地（都道府県）コード
  // -
  preferredWorkplacePrefecturesCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 希望勤務地（都道府県）名
  // -
  preferredWorkplacePrefecturesName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 職務経歴
  // -
  jobCareer: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 経験職種
  // -
  experiencedJob: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 履歴書全体
  // -
  wholeResume: yup.lazy(value =>
    value
      ? yup
          .mixed()
          .test('testString',
          '',
          async function (value: any[]) {
            let flg = false
            let message
            await test.validate({ testStrings: value.map(i => i.label).join('') }).then(async () => {
              await yup.mixed()
              .test(
                'isHalfWidth',
                validationMessageSet.halfWidth,
                (value: any[]) => {return value && value.every((i: any) => {
                  return miscRegexSet.halfWidth.test(i.label);
                })}
              )
              .validate(value,{abortEarly:false}).catch(err=>{
                message = Array.isArray(err.errors) ? err.errors.join('\n') : err.errors
                flg = true
              })
            }).catch(err => {
              message = err.errors
              flg = true
            })
            if (flg) {
              return this.createError({ message: message })
            } else {
              return true
            }
          })
      : yup.mixed().notRequired()
  ),

  /**
   * 応募情報
   */

  // 応募⽇From
  // -
  applicationFrom: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isDate', validationMessageSet.date, (value: any) =>
            miscRegexSet.date.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // // 応募⽇To
  // // -
  applicationTo: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isDate', validationMessageSet.date, (value: any) =>
            miscRegexSet.date.test(value)
          )
      : yup.mixed().notRequired()
  ),

  postingStartTime: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isDate', validationMessageSet.date, (value: any) =>
            miscRegexSet.date.test(value)
          )
      : yup.mixed().notRequired()
  ),

  postingEndTime: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isDate', validationMessageSet.date, (value: any) =>
            miscRegexSet.date.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 会員種別
  // -
  memberTypeObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 会員種別コード
  // -
  memberTypeCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 会員種別名
  // -
  memberTypeName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 応募経路
  // -
  applicationRouteObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募経路フラグ
  // [0]契約応募経路 [1]外部応募経路
  applicationRouteFlag: yup
    .number()
    .min(0, validationMessageSet.minValue)
    .max(1, validationMessageSet.maxValue),

  // 応募経路ID
  // -
  applicationRouteId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 応募経路名
  // -
  applicationRouteName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 応募職種
  // -
  applicationTypeObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募職種ID
  // -
  applicationTypeId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 応募職種名
  // -
  applicationTypeName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // アンケート回答
  // -
  questionnaireObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // アンケート条件判定FLG
  // [0]かつ [1]または
  questionnaireConditionsJudgmentFlag: yup
    .number()
    .min(0, validationMessageSet.minValue)
    .max(1, validationMessageSet.maxValue),

  // アンケート条件判定名
  // -
  questionnaireConditionsJudgmentName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // アンケート回答条件
  // -
  questionnaireConditionsObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 設問番号
  // -
  questionNumber: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 質問文
  // -
  questionTitle: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 回答有無FLG
  // [0]回答あり [1]回答なし
  answerConditionFlag: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 回答有無名
  // -
  answerConditionName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 質問種別コード
  // -
  questionTypeCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 質問種別名
  // -
  questionTypeName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 回答テキスト
  // -
  answerText: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 回答選択肢
  // -
  answerCheckBoxObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 回答選択肢ID
  // 配列の配列
  answerCheckBoxId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 回答選択肢名
  // 配列の配列
  answerCheckBoxName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // エントリー種別
  // -
  entryTypeObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // エントリー種別（通常応募）コード
  // -
  entryTypeCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // エントリー種別（通常応募）名
  // -
  entryTypeName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // エントリー区分
  // -
  entryClassificationObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // エントリー区分コード
  // -
  entryClassificationCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // エントリー区分名
  // -
  entryClassificationName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // スカウト特典
  // -
  scoutBenefitsObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // スカウト特典コード
  // -
  scoutBenefitsCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // スカウト特典名
  // -
  scoutBenefitsName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 書類選考免除
  // -
  exemptionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 書類選考免除コード
  // -
  exemptionCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 書類選考免除名
  // -
  exemptionName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 履歴書未読
  // -
  unreadObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 履歴書未読コード
  // -
  unreadCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 履歴書未読名
  // -
  unreadName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 重複応募
  // -
  duplicationObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 重複応募FLG
  // -
  duplicationFlag: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 重複応募名
  // -
  duplicationName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 複数選考
  // -
  multipleObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 複数選考FLG
  // -
  multipleFlag: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 複数選考名
  // -
  multipleName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  /**
   * 採用管理情報
   */

  // 選考フロー
  // -
  selectionFlowObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 選考フローID
  // -
  selectionFlowId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 選考フロー名
  // -
  selectionFlowName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 選考ステップ
  // -
  selectionStepObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 選考ステップID
  // -
  selectionStepId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 選考ステップ名
  // -
  selectionStepName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 合否判定
  // -
  judgmentUndeterminedObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 合否判定ID
  // -
  judgmentUndeterminedId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 合否判定名
  // -
  judgmentUndeterminedName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 管理項目
  // -
  managementItemObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // // 管理項目条件判定FLG
  // // [0]かつ [1]または
  managementItemJudgmentFlag: yup
    .number()
    .min(0, validationMessageSet.minValue)
    .max(1, validationMessageSet.maxValue),

  // 管理項目条件判定名
  // -
  managementItemJudgmentName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 管理項目条件
  // -
  managementItemConditionsObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 採用管理フラグ設定ID
  // -
  recruitmentManagementFlagSettingId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 採用管理フラグ名
  // -
  recruitmentManagementFlagName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 管理項目値有無条件FLG
  // [0]値がある [1]値がない
  managementItemPresenceFlag: yup
    .number()
    .min(0, validationMessageSet.minValue)
    .max(1, validationMessageSet.maxValue),

  // 管理項目値有無条件名
  // -
  managementItemPresenceName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 種別コード
  // -
  managementItemTypeCode: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 種別名
  // -
  managementItemTypeName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 詳細テキスト
  // -
  managementItemTagText: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 詳細範囲From
  // -
  managementItemTagFrom: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isDate', validationMessageSet.date, (value: any) =>
            miscRegexSet.date.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 詳細範囲To
  // -
  managementItemFlagTo: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isDate', validationMessageSet.date, (value: any) =>
            miscRegexSet.date.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 詳細選択肢
  // -
  managementItemFlagCheckBoxObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 詳細選択肢ID
  // 配列の配列
  managementItemFlagCheckBoxId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 詳細選択肢名
  // 配列の配列
  managementItemFlagCheckBoxName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 優先度FROM
  // -
  priorityFrom: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // 優先度TO
  // -
  priorityTo: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // タグ
  // -
  tagTxtObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // タグ設定ID
  // -
  tagSettingId: yup.lazy(value =>
    value
      ? yup
          .string()
          .test(
            'isHalfWidthNumber',
            validationMessageSet.halfWidthNumber,
            (value: any) => miscRegexSet.halfWidthNumber.test(value)
          )
      : yup.mixed().notRequired()
  ),

  // タグ名
  // -
  tagName: yup.lazy(value =>
    value
      ? yup
          .string()
          .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
            miscRegexSet.halfWidth.test(value)
          )
      : yup.mixed().notRequired()
  ),
})

const test = yup.object().shape({
  testStrings: yup.string().test('myStringLocale', '', () => true)
})