import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MCBLS020InitRequest } from 'types/MCBLS020/MCBLS020InitRequest'
import { MCBLS030InitRequest } from 'types/MCBLS030/MCBLS030InitRequest'

// redux state定義
const initialState: { [key: string]: any } = {
  // 初期化対象件数
} = {}

const mcbPdfGeneratingReducer = createSlice({
  name: 'mcbPdfGenerating',
  initialState,
  reducers: {
    // MCBLS030 PDF作成中(同期)
    pdfMakingInit(state, action: PayloadAction<MCBLS030InitRequest>) {
      return state
    },
    // MCBLS020 PDFダウロード(同期)
    // 初期処理
    getInitDataForDownload(state, action: PayloadAction<MCBLS020InitRequest>) {
      return state
    },
  },
})

export const {
  pdfMakingInit,
  getInitDataForDownload,
} = mcbPdfGeneratingReducer.actions

export default mcbPdfGeneratingReducer.reducer
