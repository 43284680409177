import request from 'utils/request'
import { ApplicantDetailsRequest } from 'pages/MCAXS050/apiConfig'
import { MCAXS620ScreenTransitionRequest } from 'types/MCAXS620/MCAXS620ScreenTransitionRequest'
import { MCAXS620RouteScreenTransitionRequest } from 'types/MCAXS620/MCAXS620RouteScreenTransitionRequest'
import { MCAXS620UpdateExcludeRequest } from 'types/MCAXS620/MCAXS620UpdateExcludeRequest'
import { MCAXS620UpdateUnExcludeRequest } from 'types/MCAXS620/MCAXS620UpdateUnExcludeRequest'

export const mcaxs620InitRequest = (interview: ApplicantDetailsRequest) =>
  request({
    url: '/MCAXS620/init',
    method: 'post',
    data: interview,
  })

export const mcaxs620SelectionFlowChangeRequest = (
  interview: MCAXS620ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS620/selectionFlowChange',
    method: 'post',
    data: interview,
  })

export const mcaxs620SelectionFlowRouteAddRequest = (
  interview: MCAXS620RouteScreenTransitionRequest
) =>
  request({
    url: '/MCAXS620/selectionFlowRouteAdd',
    method: 'post',
    data: interview,
  })

export const mcaxs620SelectionFlowRouteChangeRequest = (
  interview: MCAXS620RouteScreenTransitionRequest
) =>
  request({
    url: '/MCAXS620/selectionFlowRouteChange',
    method: 'post',
    data: interview,
  })

export const mcaxs620SelectionFlowExcludeRequest = (
  interview: MCAXS620UpdateExcludeRequest
) =>
  request({
    url: '/MCAXS620/selectionFlowExclude',
    method: 'post',
    data: interview,
  })

export const mcaxs620SelectionFlowUnExcludeRequest = (
  interview: MCAXS620UpdateUnExcludeRequest
) =>
  request({
    url: '/MCAXS620/selectionFlowUnExclude',
    method: 'post',
    data: interview,
  })
