import { call, put, all, takeEvery, takeLeading } from 'redux-saga/effects'
import {
    getJobSeekerInfo,
    setJobSeekerInfo,
    spamActReportValidation,
    setSpamActReportValidation,
    sendSpamActReport,
    setOpenDialog030,
    setInitValues
} from 'reducers/spamActReportReducer'

import { initRequest, checkRequest } from 'apis/MCASS010Api'
import { sendRequest } from 'apis/MCASS020Api'
import { openModal } from 'reducers/messageReducer'

function* spamActReportInitSaga(action: ReturnType<typeof getJobSeekerInfo>) {
    try {
        const data = yield call(initRequest, action.payload);
        yield put(setJobSeekerInfo(data))
        yield put(setInitValues())
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* spamActReportCheckSaga(action: ReturnType<typeof spamActReportValidation>) {
    try {
        const data = yield call(checkRequest, action.payload);
        yield put(setSpamActReportValidation(data))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* sendSpamactReportSaga(action: ReturnType<typeof sendSpamActReport>) {
    try {
        const data = yield call(sendRequest, action.payload);
        yield put(setOpenDialog030(data))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

export default function* spamActReportSaga() {
    yield all([
        takeEvery(getJobSeekerInfo, spamActReportInitSaga),
        takeEvery(spamActReportValidation, spamActReportCheckSaga),
        takeLeading(sendSpamActReport, sendSpamactReportSaga),
    ])
}