import {call,put,all,takeLatest,takeLeading} from 'redux-saga/effects'
import {starEvaluationInit,setStarEvaluationInit,starEvaluationUpdate,} from 'reducers/starEvaluationUpdateReducer'
import { openSnackbar ,openModal } from 'reducers/messageReducer'
import {starEvaluationInitRequest,starEvaluationUpdateRequest,} from 'apis/MCAYS040Api'

function* starEvaluationInitSaga(action: ReturnType<typeof starEvaluationInit>) {
  try {
    const data = yield call(starEvaluationInitRequest, action.payload);
    yield put(setStarEvaluationInit(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* starEvaluationUpdateSaga(action: ReturnType<typeof starEvaluationUpdate>) {
  try {
    const message = yield call(starEvaluationUpdateRequest, action.payload.request);
    yield put(openSnackbar(message))
    if(action.payload.onclose !== undefined){
      action.payload.onclose()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* accountSaga() {
  yield all([
    takeLatest(starEvaluationInit, starEvaluationInitSaga),
    takeLeading(starEvaluationUpdate, starEvaluationUpdateSaga),
  ])
}
