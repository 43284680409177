import { call, put, all, takeEvery, select } from 'redux-saga/effects'
import { 
  searchSubmissionRequestMessageDetail, 
  setSubmissionRequestMessageDetail, 
  attachmentFileDownload, 
  csvFileDownload
} from 'reducers/submissionRequestMessageDetailReducer'
import { searchSubmissionRequestMessageDetailRequest, attachmentFileDownloadRequest, destinationDownloadRequest } from 'apis/MCBHS050Api'
import { openModal } from 'reducers/mcbMessageReducer'
import { routeList } from 'routes/routes'
import { magiContants } from 'utils/contants'
import { setDefFlag} from 'reducers/mediaReducer'
import { getGlobalMenuData } from 'selectors/authSelectors'


function* searchSubmissionRequestMessageDetaiSaga(action: ReturnType<typeof searchSubmissionRequestMessageDetail>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
  try {
    const data = yield call(searchSubmissionRequestMessageDetailRequest, action.payload.request);
    yield put(setDefFlag(1))
    yield put(setSubmissionRequestMessageDetail(data))
    if (action.payload.flag === '0') {
      localStorage.setItem("submissionRequestMessageDetai",JSON.stringify(action.payload))
      localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
      const w = window.open('','','scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
      if (w){
        const params = new URLSearchParams(action.payload.request as {}).toString()
        w.location.href = routeList.submissionRequestMessageDetails + '?' + params
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* attachmentFileDownloadSaga(action: ReturnType<typeof attachmentFileDownload>) {
  try {
    const data = yield call(attachmentFileDownloadRequest, action.payload);
    var blob = new Blob([data.data]);

    if ('application/json' == data.data.type || 'application/json;charset=UTF-8' == data.data.type) {
      yield put(openModal(magiContants.MESSAGECODE_MCBHS050_003))
    } else {
      if (navigator.msSaveBlob) {
        // window.navigator.msSaveBlob(blob, action.payload.fileName);
      } else {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = action.payload.fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      }
    }

  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* csvFileDownloadSaga(action: ReturnType<typeof csvFileDownload>) {
  try {
    const data = yield call(destinationDownloadRequest, action.payload);
    var blob = new Blob([data.data]);
    const date = new Date();
    var curr_date = date.getDate() + "";
    var curr_month = (date.getMonth() + 1) + "";
    const curr_year = date.getFullYear()
    var curr_hour = date.getHours() + "";
    var curr_minutue = date.getMinutes() + "";
    curr_month = curr_month.length < 2 ? "0" + curr_month : curr_month;
    curr_date = curr_date.length < 2 ? "0" + curr_date : curr_date;
    curr_hour = curr_hour.length < 2 ? "0" + curr_hour : curr_hour;
    curr_minutue = curr_minutue.length < 2 ? "0" + curr_minutue : curr_minutue;
    const yymmdd = curr_year + curr_month + curr_date + "_" + curr_hour + curr_minutue;
    const fileName = "request_job_seeker_list_"
      + action.payload.messageSendId + "_" + yymmdd + ".csv"

    if ('application/json' == data.data.type || 'application/json;charset=UTF-8' == data.data.type) {
      yield put(openModal(magiContants.MESSAGECODE_MCBHS050_004))
    } else {
      if (navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
export default function* submisssionRequestMessageDetailSaga() {
  yield all([
    takeEvery(searchSubmissionRequestMessageDetail, searchSubmissionRequestMessageDetaiSaga),
    // takeEvery(searchMessageDetailInterview, searchMessageDetailInterviewSaga),
    takeEvery(attachmentFileDownload, attachmentFileDownloadSaga),
    takeEvery(csvFileDownload, csvFileDownloadSaga),
    // takeEvery(sysVersionNumberCheck, sysVersionNumberCheckSaga),
  ])
}