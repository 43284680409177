import { call, put, all, takeEvery, takeLatest, select } from 'redux-saga/effects'
import {
  monitor,
  init,
  authorizeCheck,
  setSelectionFlag,
  // #57536 start
  setAllSelectionFlag,
  // #57536 end
  setInitValues,
  setSelectedTemplateInfo,
  setSelectedSelectionFlowInfo,
} from 'reducers/csvOutputReducer'
import { initialValues } from 'pages/MCAXS111/formConfig'
import { setMCAXS111Open} from 'reducers/changeOthersReducer'
import { openModal } from 'reducers/messageReducer'
import { authorizeCheckApi } from 'apis/MCAXS111Api'
import { initApi, monitorApi } from 'apis/MCAXS121Api'
import { magiContants } from 'utils/contants'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { getGlobalMenuData } from 'selectors/authSelectors'
import { setOpenMCAXS111 } from 'reducers/bulkOperationReducer'

// MCAXS111 start
export function* authorizeCheckSaga(action: ReturnType<typeof authorizeCheck>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
  try {
    // #57536 start
    if (
      action.payload.selectionFlag &&
      (action.payload.selectedSelectionFlowId == null || action.payload.selectedSelectionFlowId.length == 0)
    ) {
    // #57536 end
      yield put(openModal(magiContants.MESSAGECODE_MCAXS111_001))
    } else {
      yield call(authorizeCheckApi)
      window.localStorage.setItem(
        'param',
        JSON.stringify(action.payload.nextPageParam)
      )
      window.localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
      yield put(setMCAXS111Open(false))
      yield put(setOpenMCAXS111(false))
      yield put(setSelectionFlag(false))
      // #57536 start
      yield put(setAllSelectionFlag(false))
      // #57536 end
      yield put(setInitValues(initialValues))
      yield put(setSelectedTemplateInfo({
        entryDataIoTemplateSettingId: '',
        templateName: '',
      }))
      yield put(setSelectedSelectionFlowInfo({
        selectionFlowSettingId: '',
        selectionName: ''
      }))
      openNewWindow()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

const openNewWindow = () => {
  const strWindowFeatures = `
    left=0,
    top=0,
    titlebar=yes,
    menubar=yes,
    toolbar=yes,
    location=yes,
    scrollbars=yes,
    resizable=yes,
    width=650px,
    height=600px,
    `
  const newWin = window.open(routeList.csvLaoding, '_blank', strWindowFeatures)
  if (newWin) {
    newWin.window.focus()
  }
}
// MCAXS111 end
// MCAXS121 start
let requestId = ''

function* getInitSaga(action: ReturnType<typeof init>) {
  try {
    requestId = yield call(initApi, action.payload.InitRequest)
    const MCAXS121monitorValues = {
      requestId:requestId,
      selectionManagementIdList: action.payload.InitRequest.selectionManagementIdList,
      entryDataIoTemplateSettingId: action.payload.InitRequest.entryDataIoTemplateSettingId,
      selectionFlowSettingId: action.payload.InitRequest.selectionFlowSettingId,
      recruitmentManagementDivision: action.payload.InitRequest.recruitmentManagementDivision,
      // MCBリプレース #8306 start
      callDivision: action.payload.InitRequest.callDivision,
      // MCBリプレース #8306 end
    }
    yield put(monitor(MCAXS121monitorValues))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* getMonitorSaga(action: ReturnType<typeof monitor>) {
  try {
    const status = yield call(monitorApi, action.payload)

    if (status === magiContants.STATUS_90) {
      history.push(routeList.csvDownload)
    }
    if (status === magiContants.STATUS_99 || status === null) {
      yield put(openModal(magiContants.MESSAGECODE_MCAXS121_006))
    }
    if (
      status === magiContants.STATUS_00 ||
      status === magiContants.STATUS_10
    ) {
      const MCAXS121monitorValue = {
        requestId:requestId,
        selectionManagementIdList: action.payload.selectionManagementIdList,
        entryDataIoTemplateSettingId: action.payload.entryDataIoTemplateSettingId,
        selectionFlowSettingId: action.payload.selectionFlowSettingId,
        recruitmentManagementDivision: action.payload.recruitmentManagementDivision,
        // MCBリプレース #8306 start
        callDivision: action.payload.callDivision,
        // MCBリプレース #8306 end
      }
      yield put(monitor(MCAXS121monitorValue))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS121 end

export default function* csvOutputSaga() {
  yield all([
    takeEvery(authorizeCheck, authorizeCheckSaga),
    takeLatest(init, getInitSaga),
    takeEvery(monitor, getMonitorSaga),
  ])
}
