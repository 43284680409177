export interface NewsInfo {
  [key: string]: string
  newsId: string // お知らせID
}

export interface MCAVS020newsgetOutDto {
  [key: string]: string | string[] | number
  newsId: string // お知らせID
  category: string // 分類
  newsClassification: string // お知らせ分類
  subject: string // 件名
  publicStartDate: string // 公開開始日
  publicEndDate: string // 公開終了日
  publicDate: string // 公開日
  body: string // 本文
  attachmentNameList: string[] // ファイル名
  fileSizeList: string[] // ファイルサイズ
  fileManagementIdList: string[] // ファイル管理ID
  //次期9月対応 #62669 start 
  count: number // 既読お知らせテーブル(新規)にレコード追加件数
  //次期9月対応 #62669 end 
}

const newsInfo = {
  newsId: '', // お知らせID
}

const initialState = {
  newsId: '', // お知らせID
  category: '', // 分類
  newsClassification: '', // お知らせ分類
  subject: '', // 件名
  publicStartDate: '', // 公開開始日
  publicEndDate: '', // 公開終了日
  publicDate: '', // 公開日
  body: '', // 本文
  attachmentNameList: [], // ファイル名
  fileSizeList: [], // ファイルサイズ
  fileManagementIdList: [], // ファイル管理ID
  //次期9月対応 #62669 start 
  count: 0, // 既読お知らせテーブル(新規)にレコード追加件数
  //次期9月対応 #62669 end 
}

export { newsInfo, initialState }
