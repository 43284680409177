import {
  Button,
  colors,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FieldGroup, SubTitle } from 'componentsHsc'
import { FormControl } from '..'
import { RootState } from 'reducers'
import { useSelector, useDispatch } from 'react-redux'
import { getMcaxs471Init } from 'reducers/advancedSearchModalReducer'
import { Event, EventLevel3Dto } from 'componentsHsc/AdvancedSearch/Config/eventModalConfig'
import { dateToString } from 'common/generalUtil'
import { magiContants as contants } from 'utils/contants'
import {
  EventsObj,
  EventsWhichOneObj,
  EventsIndividualObj,
  EventsIndividualObj2,
} from '../Config/searchConditionConfig'
import CustomScrollbar from 'react-custom-scrollbars'
import { openModal } from 'reducers/messageReducer'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'visible !important'
  },
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 1,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span.MuiFormControlLabel-label': {
      color: colors.grey[600],
    },
    height: 'auto'
  },
  content: {
    margin: theme.spacing(1, 2),
    '& div': {
        display: 'inline-block',
        margin: '0px 20px 0px 0px',
        cursor: 'pointer',
    },
    '& div input': {
        marginRight: '5px',
        cursor: 'pointer',
    }
  },
  content2: {
    margin: theme.spacing(1, 2),
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px'
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '20px !important'
  },
  dividerColor: {
    backgroundColor: colors.grey[600],
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    cursor:'auto',
  },
  subContent: {
    margin: theme.spacing(2),
    '& .Mui-selected': {
        backgroundColor: 'transparent !important',
        color: '#1950a6',
        textDecoration: 'underline',
        '& span': {
            color: '#1950a6',
        },
    },
    height: '100%',
  },
  sectionTitle: {
    borderTop: '1px solid gray',
    paddingTop: '5px'
  },
  selectAll: {
      '& div': {
          display: 'inline-block',
          margin: '8px 20px 0px 0px',
          cursor: 'pointer',
      },
      '& div input': {
          marginRight: '5px',
          cursor: 'pointer',
      }
  },
  eventName: {
    wordBreak:'break-all',
    wordWrap:'break-word',
  },
  eventName2: {
    '& > div > :first-child':{
        overflowX: 'hidden !important',
    }
  },
}))

const searchOptionList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

const optionList = [
  { label: '参加あり', value: '1' },
  { label: '参加なし', value: '0' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

interface RdoSeleDate {
    [key: string]: string
    dateId: string
    rdoVal: string
}

const EventModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirm, setConfirm] = useState(false)

  const [rdoDateSelect, setRdoDateSelect] = useState<RdoSeleDate[]>([])// 「各イベントごと」の参加状況チェック
  const [eventSelect, setEventSelect] = useState('') // 「いずれかのイベント」と「各イベントごと」のラジオボタン
  const [whichOne, setWhichOne] = useState('') // 「いずれかのイベント」の参加状況チェック
  const [individual, setIndividual] = useState('0') // 「各イベントごと」のイベント間の検索方法 ANR/OR
  const [selectedSub, setSelectedSub] = useState('')
  const [selectAllFlag, setSelectAllFlag] = useState('') // 「各イベントごと」の一括チェック

  const handleSubClick = (index1: number, index2: number) => () => {
    setSelectedSub(index1 + '_' + index2)
    const toTop = document.getElementById(`sel_${index1}_${index2}`)
    if (toTop) {
      toTop.scrollIntoView()
    }
  }

  useEffect(() => {
    dispatch(getMcaxs471Init())
  }, [open])

  useEffect(() => {
    if (open) {
      setSelectedSub('')
      if (form.values.eventsObj) {
        setEventSelect(
          form.values.eventsObj.eventsConditionJudgmentFlag.toString()
        )
        if (
          form.values.eventsObj.eventsConditionJudgmentFlag.toString() === '0'
        ) {
          setRdoDateSelect([])
          setSelectAllFlag('')
          if (form.values.eventsObj.eventsWhichOneObj) {
            let whichoneObj =
              form.values.eventsObj.eventsWhichOneObj.eventsWhichOneFlag !==
              null
                ? form.values.eventsObj.eventsWhichOneObj.eventsWhichOneFlag.toString()
                : ''
            setWhichOne(whichoneObj)
          }
        } else if (
          form.values.eventsObj.eventsConditionJudgmentFlag.toString() === '1'
        ) {
          setWhichOne('')
          if (form.values.eventsObj.eventsIndividualObj) {
            const individualObj = form.values.eventsObj.eventsIndividualObj
            let individualFlg =
              individualObj.eventsIndividualJudgmentFlag !== null
                ? individualObj.eventsIndividualJudgmentFlag.toString()
                : ''
            setIndividual(individualFlg)
            if (individualObj.eventsIndividualObj2) {
                let flglst2: RdoSeleDate[] = []
                const rdoValSortList: string[] = []
                const individualObj2 = individualObj.eventsIndividualObj2
                let eventCount : number = 0
                eventList.map(event => {
                    event.eventLevel2Dtos.map(event1 => {
                        event1.eventLevel3Dtos.map(event2 => {
                            eventCount++;
                            individualObj2.map(item => {
                                if (event2.eventId == item.eventsIndivIdualEventId) {
                                    flglst2.push({dateId: item.eventsIndivIdualEventId, rdoVal: item.eventsIndividualFlag.toString()})
                                    rdoValSortList.push(item.eventsIndividualFlag.toString())
                                }
                            })
                        })
                    })
                })
                rdoValSortList.sort()
                if(rdoValSortList[0] === rdoValSortList[rdoValSortList.length-1] && rdoValSortList.length == eventCount){
                    setSelectAllFlag(rdoValSortList[0])
                }else {
                    setSelectAllFlag('')
                }
                setRdoDateSelect(flglst2)
            }
          }
        }
      }else {
        setEventSelect('')
        setWhichOne('')
        setIndividual('0')
        setSelectAllFlag('')
        setRdoDateSelect([])
      }
    }
  }, [open])

  const handleClick = (open = true) => () => {
    setConfirm(open)
  }

  const handleClose = () => {
    setConfirm(false)
    onClose()
  }

  const { eventList } = useSelector(
    (state: RootState) => state.advancedSearchModal.eventModalInfo
  )

  const handleSubmit = () => {
    let eventsObj: EventsObj = {
      eventsConditionJudgmentFlag: 1,
      eventsConditionJudgmentName: '',
      eventsWhichOneObj: null,
      eventsIndividualObj: null,
    }
    var datelist: string[] = []

    var searchValue: any = document.getElementsByName('selectSearch')
    if (searchValue) {
      if (searchValue[0].checked) {
        eventsObj.eventsConditionJudgmentFlag = 0
        eventsObj.eventsConditionJudgmentName = 'いずれかのイベント'
        var searchValue1: any = document.getElementsByName('selectSearch1')
        if (searchValue1) {
          let eventsWhichOneObj: EventsWhichOneObj = {
            eventsWhichOneFlag: null,
            eventsWhichOneName: '',
          }
          if (searchValue1[0].checked) {
            eventsWhichOneObj.eventsWhichOneFlag = 0
            eventsWhichOneObj.eventsWhichOneName = '参加あり'
          } else if (searchValue1[1].checked) {
            eventsWhichOneObj.eventsWhichOneFlag = 1
            eventsWhichOneObj.eventsWhichOneName = '参加なし'
          }
          if (eventsWhichOneObj.eventsWhichOneFlag !== null) {
            eventsObj.eventsWhichOneObj = eventsWhichOneObj
            form.setFieldValue('eventsObj', eventsObj)
          } else {
            dispatch(openModal(contants.MESSAGECODE_MCAXS471_002))
            return
          }
        }
      } else if (searchValue[1].checked) {
        eventsObj.eventsConditionJudgmentFlag = 1
        eventsObj.eventsConditionJudgmentName = '各イベントごと'
        datelist.push('各イベントごと')
        var searchValue2: any = document.getElementsByName('selectSearch2')
        if (searchValue2) {
          let eventsIndividualObj: EventsIndividualObj = {
            eventsIndividualJudgmentFlag: 1,
            eventsIndividualJudgmentName: '',
            eventsIndividualObj2: [],
          }
          if (searchValue2[0].checked) {
            eventsIndividualObj.eventsIndividualJudgmentFlag = 0
            eventsIndividualObj.eventsIndividualJudgmentName = 'AND'
          } else if (searchValue2[1].checked) {
            eventsIndividualObj.eventsIndividualJudgmentFlag = 1
            eventsIndividualObj.eventsIndividualJudgmentName = 'OR'
          }
          eventList.map((event, index) => {
            event.eventLevel2Dtos.map((event1, index1) => {
                event1.eventLevel3Dtos.map((event2, index2) => {
                    var searchValue3: any = document.getElementsByName(`selectSearch3[${index}_${index1}_${index2}]`)
                    if (searchValue3) {
                        let eventsIndividualObj2: EventsIndividualObj2 = {
                            eventsIndivIdualApplicationRouteId: event2.contractMediaId,
                            eventsIndividualApplicationRouteName: event2.contractMediaName,
                            eventsIndivIdualEventTypeId: event2.eventType,
                            eventsIndividualEventTypeName: event2.eventTypeName,
                            eventsIndividualCoordinationFormerEventId: event2.coordinationFormerEventId,
                            eventsIndividualEventSchedule: dateToString(event2.eventSchedule),
                            eventsIndivIdualEventId: event2.eventId,
                            eventsIndividualEventName: event2.eventName,
                            eventsIndividualPrefecturesCode: event2.prefecturesCode,
                            eventsIndividualPrefecturesName: event2.prefecturesName,
                            eventsIndividualFlag: 0,
                            eventsIndividualName: '',
                            // Started By DiaoJiaHao
                            eventsIndividualAreaId: event2.areaId,
                            eventsIndividualAreaName: event2.areaName
                            // End By DiaoJiaHao
                        }
                        if (searchValue3[0].checked) {
                            eventsIndividualObj2.eventsIndividualFlag = 0
                            eventsIndividualObj2.eventsIndividualName = '参加あり'
                            eventsIndividualObj.eventsIndividualObj2.push(eventsIndividualObj2)
                        } else if (searchValue3[1].checked) {
                            eventsIndividualObj2.eventsIndividualFlag = 1
                            eventsIndividualObj2.eventsIndividualName = '参加なし'
                            eventsIndividualObj.eventsIndividualObj2.push(eventsIndividualObj2)
                        }
                    }
                })
            })
          })
          eventsObj.eventsIndividualObj = eventsIndividualObj
          if(eventsIndividualObj.eventsIndividualObj2.length > 0){
            form.setFieldValue('eventsObj', eventsObj)
          } else {
            dispatch(openModal(contants.MESSAGECODE_MCAXS471_002))
            return
          }
        }
      }
      else {
        dispatch(openModal(contants.MESSAGECODE_MCAXS471_002))
        return
      }
    }
    handleClose()
    // TODO: formik
  }

  const createEventName = (event: EventLevel3Dto) => {
    return (
      // Started By DiaoJiaHao
      (event.coordinationFormerEventId === null ? '' : event.coordinationFormerEventId) +
      ' ' +
      (event.eventSchedule === null ? '' : dateToString(event.eventSchedule)) +
      ' ' +
      (event.eventName === null ? '' :  event.eventName) +
      ' ' +
      (event.areaName === null ? '' : event.areaName) +
      ' ' +
      (event.prefecturesName === null ? '' : event.prefecturesName)
      // End By DiaoJiaHao
    )
  }

  const events = form.values.eventsObj || null

  const handleSelectAll = (e: string) => {
    const selectValue = e === selectAllFlag ? '' : e
    let tmpval2: RdoSeleDate[] = []
    eventList.map(event => {
        event.eventLevel2Dtos.map(event1 => {
            event1.eventLevel3Dtos.map(event2 => {
                tmpval2.push({dateId: event2.eventId, rdoVal: selectValue})
            })
        })
    })
    setSelectAllFlag(selectValue)
    setRdoDateSelect(tmpval2)
    // 次期9月対応 #63457 start 
    // 「各イベントごと」選択する
    setEventSelect('1')
    //「いずれかのイベント」の受付状況チェック：選択しない
    setWhichOne('')
    individual ? setIndividual(individual) : setIndividual('0')
    // 次期9月対応 #63457 end 
  }

  const handleEventSelect = (e: any) => {
    if('0' === e.target.value){
      setRdoDateSelect([])
      setIndividual('')
      setSelectAllFlag('')
    }else if('1' === e.target.value){
      setWhichOne('')
      setIndividual('0')
    }
    setEventSelect(e.target.value)
  }

  const handleWhichOne = (e: any) => {
    const whichOneObj = e.target.value === whichOne ? '' : e.target.value
    setWhichOne(whichOneObj)
    // 次期9月対応 #63457 start
    // イベント参加状況ラジオボタン（いずれかのイベント）の場合
    // 参加状況ラジオボタンの"いずれかのイベント"をチェックありにする。
    setEventSelect('0')
    setRdoDateSelect([])
    // ANR/ORの設定
    setIndividual('')
    // 「各受付ごと」の一括チェック：選択しない
    setSelectAllFlag('')
    // 次期9月対応 #63457 end
  }

  const handleIndividual = (e: any) => {
    const　individualObj= e.target.value === individual ? '' : e.target.value
    setIndividual(individualObj)　
  }

  const handleRdoDateSelect = (event: EventLevel3Dto, values: string) => {
    let rdoSeleData = rdoDateSelect.find(item => {
        return item.dateId.toString() === event.eventId.toString()
    })
    let tmpval2: RdoSeleDate[] = []
    rdoDateSelect.map(item => {
        if (item.dateId.toString() === event.eventId.toString()) {
            tmpval2.push({ dateId: event.eventId, rdoVal: rdoSeleData && rdoSeleData.rdoVal === values ? '' : values })
        } else {
            tmpval2.push(item)
        }
    })
    if (!rdoSeleData) {
        tmpval2.push({ dateId: event.eventId, rdoVal: values })
    }
    let count = 0
    eventList.map(event => {
        event.eventLevel2Dtos.map(event1 => {
            event1.eventLevel3Dtos.map(event2 => {
                count++;
            })
        })
    })
    const rdoValSortList: string[] = []
    tmpval2.forEach(i => {
        rdoValSortList.push(i.rdoVal)
    })
    rdoValSortList.sort()
    if(rdoValSortList.length === count && rdoValSortList[0] === rdoValSortList[rdoValSortList.length-1]){
        setSelectAllFlag(rdoValSortList[0])
    }else {
        setSelectAllFlag('')
    }
    setRdoDateSelect(tmpval2)
    // 次期9月対応 #63457 start 
    // 「各イベントごと」選択する
    setEventSelect('1')
    //「いずれかのイベント」の受付状況チェック：選択しない
    setWhichOne('')
    individual ? setIndividual(individual) : setIndividual('0')
    // 次期9月対応 #63457 end 
  }

  const rdoCheckedZero = (event: EventLevel3Dto) => {
      let rdoSeleData = rdoDateSelect.find(item => {
          return item.dateId.toString() === event.eventId.toString()
      })
      if (rdoSeleData) {
          if (rdoSeleData.rdoVal === "0") {
              return true
          }
      }
      return false
  }

  const rdoCheckedOne = (event: EventLevel3Dto) => {
      let rdoSeleData = rdoDateSelect.find(item => {
          return item.dateId.toString() === event.eventId.toString()
      })
      if (rdoSeleData) {
          if (rdoSeleData.rdoVal === "1") {
              return true
          }
      }
      return false
  }

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>イベント参加状況を指定</label>
        </DialogTitle>
        <DialogContent>
          <div className={`ps-container ps ${classes.root}`}>
            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='0'
                    name='selectSearch'
                    onChange={handleEventSelect}
                    checked={eventSelect ? eventSelect === '0' : undefined}
                  />
                }
                label='いずれかのイベント'
              />
              <RadioGroup row className={classes.content2}>
                <FormControlLabel
                  control={
                    <Radio
                      color='primary'
                      value='0'
                      name='selectSearch1'
                      onClick={handleWhichOne}
                      checked={whichOne === '0'}
                    />
                  }
                  label='参加あり'
                />
                <FormControlLabel
                  control={
                    <Radio
                      color='primary'
                      value='1'
                      name='selectSearch1'
                      onClick={handleWhichOne}
                      checked={whichOne === '1'}
                    />
                  }
                  label='参加なし'
                />
              </RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='1'
                    name='selectSearch'
                    onChange={handleEventSelect}
                    checked={eventSelect ? eventSelect === '1' : undefined}
                  />
                }
                className={classes.sectionTitle}
                label='各イベントごと'
              />
              <div className={classes.subTitle}>
                <RadioGroup row defaultValue = {'0'}>
                  <FormLabel className={classes.labelTitle}>
                    イベント間の検索方法
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        value='0'
                        name='selectSearch2'
                        onChange={handleIndividual}
                        checked={individual === '0'}
                      />
                    }
                    label='AND'
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        value='1'
                        name='selectSearch2'
                        onChange={handleIndividual}
                        checked={individual === '1'}
                      />
                    }
                    label='OR'
                  />
                </RadioGroup>
                <RadioGroup row className={classes.selectAll}>
                  <FormLabel className={classes.labelTitle}>一括チェック</FormLabel>
                  <div onClick={() => handleSelectAll("0")}>
                    <input type="radio" name='selectSearch4' value='0' checked={'0' === selectAllFlag} />参加あり
                  </div>
                  <div onClick={() => handleSelectAll("1")}>
                    <input type="radio" name='selectSearch4' value='1' checked={'1' === selectAllFlag} />参加なし
                  </div>
                </RadioGroup>
              </div>
              <div className={classes.select}>
                <div className={classes.subContent}>
                    <CustomScrollbar autoHeight={true} autoHeightMax={500} className={classes.eventName}>
                        {eventList ? eventList.map((event1, index1) => (
                            <div>
                                <div>{event1.contractMediaName}</div>
                                {event1.eventLevel2Dtos.map((event2, index2) => (
                                    <List>
                                        <ListItem
                                            button
                                            selected={selectedSub === `${index1}_${index2}`}
                                            onClick={handleSubClick(index1, index2)}>
                                            <ListItemText
                                                primary={event2.eventTypeName}
                                            />
                                        </ListItem>
                                    </List>
                                ))}
                            </div>
                        ))
                        : null}
                    </CustomScrollbar>
                </div>
                <div style={{height: '100%', overflow: "hidden!important"}} className={`${classes.eventName} ${classes.eventName2}`}>
                    <CustomScrollbar autoHeight={true} autoHeightMax={500} >
                        {eventList
                            ? eventList.map((event1, index1) => (
                                <>
                                    <SubTitle variant='h4'>{event1.contractMediaName}</SubTitle>
                                    {event1.eventLevel2Dtos.map((event2, index2) => (
                                        <>
                                            <FieldGroup id={`sel_${index1}_${index2}`}>
                                                <SubTitle variant='h5'>
                                                    {event2.eventTypeName}
                                                </SubTitle>
                                                {event2.eventLevel3Dtos.map((event3, index3) => (
                                                    <>
                                                        <div>{createEventName(event3)}</div>
                                                        <div className={classes.content}>
                                                            <div onClick={() => handleRdoDateSelect(event3, "0")}>
                                                                <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='0' checked={rdoCheckedZero(event3)} />参加あり
                                                            </div>
                                                            <div onClick={() => handleRdoDateSelect(event3, "1")}>
                                                                <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='1' checked={rdoCheckedOne(event3)} />参加なし
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </FieldGroup>
                                        </>
                                    ))}
                                </>
                            ))
                        : null}
                    </CustomScrollbar>
                </div>
              </div>
            </RadioGroup>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClick()}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS471_001}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EventModal
