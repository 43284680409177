import request from 'utils/request'

import { MCAXS191NameRequest } from 'types/MCAXS191/MCAXS191NameRequest'

export const MCAXS191InitApi = () =>
  request({
    url: '/MCAXS191/init',
    method: 'post',
  })

export const MCAXS191NameInputRequestApi = (apiData: MCAXS191NameRequest) =>
  request({
    url: '/MCAXS201/init',
    method: 'post',
    data: apiData,
  })
