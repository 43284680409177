/* eslint-disable @typescript-eslint/camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCBHS020InitRequest } from 'types/MCBHS020/MCBHS020InitRequest'
import { MCBHS020InitResult } from 'types/MCBHS020/MCBHS020InitResult'
import { MCBHS020RegisterResult } from 'types/MCBHS020/MCBHS020RegisterResult'
import { MCBHS020RegisterRequest } from 'types/MCBHS020/MCBHS020RegisterRequest'
import * as viewConfig from 'pages/MCBHS020/viewConfig'
import { SendTarget } from 'pages/MCBHS010/viewConig'
import { MCBHS020DisplayInfoOutDto } from 'types/MCBHS020/MCBHS020DisplayInfoOutDto'

const initRequest: MCBHS020InitRequest = {
  replyFromRequestSendId: null,
  requestTemplateSettingId: '',
  entryIdList: [],
  selectionManagementIdList: [],
  jobSeekerByCompanyIdList: [],
  messageType: [],
  searchCriteriaName: '',
  searchCriteria: '',
  submissionsSelectId: '',
  submissionsNameForCompany: '',
  submissionsNameForStudent: '',
  submissionsDetails: '',
  submissionEntrySheetFlag: 0,
  submissionResumeFlag: 0,
  submissionResearchSummaryFlag: 0,
  submissionOriginalQuestionFlag: 0,
  submissionFileSubmissionFlag: 0,
  limitDateDetermineFlag: 0,
  determineDateTime: '',
  requestDayTimeLaterDay: 0,
  requestDayTimeToTime: 0,
  anytimeReceptionEndDateTime: '',
  reRequestReceptFlag: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  requestSubject: '',
  requestText: '',
  requestAttachment: [],
  requestAttachmentId: [],
  requestTemplateAttachmentId: [],
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  thanksMessageSetFlag: 0,
  thanksMessageTemplateId: '',
  thanksMessageTemplateName: '',
  thanksSubject: '',
  thanksText: '',
  studentReferenceId: '',
  contactName: '',
  companyName: '',
  departmentNameAndManagerName: '',
  telephoneNumber: '',
  mailAddress: '',
  personalDataHandlingId: '',
  personalDataHandling: '',
  sendTimer: 0,
  sendPlanTime: '',
  attachmentFlag:false
}

const registerResult: MCBHS020RegisterResult = {
  fileManagementIds: [],
  attachmentNames: [],
  addFailureCount: 0,
  // MCBリプレース #9200 START
  mcbApiErrorList:[],
  // MCBリプレース #9200 END
}


const displayInfoOutDto: MCBHS020DisplayInfoOutDto = {
  jobSeekerInfoList: [],
  returnRequestBody: '',
  // #MCB1.5次開発 #72395 START
  returnThanksBody: '',
  senderMailAddressFix: ''
  // #MCB1.5次開発 #72395 END
}
 
const initialState: {
  messageComfInitRequest: MCBHS020InitRequest
  messageComInitResult: MCBHS020DisplayInfoOutDto
  messageComfRegisterResult: MCBHS020RegisterResult
  businessCheckResult: boolean
  alertMessageIdList: string[]
  requestSubject: string
  requestText: string
  thanksSubject: string
  thanksText: string
  successfulCountMessage: number
  successfulCountEmail: number
  failedCount: number
  isSending: boolean
  sendTarget: SendTarget[]
  searchCriteriaName: string
  searchCriteria: string
} = {
  messageComfInitRequest: initRequest,
  messageComInitResult: displayInfoOutDto,
  messageComfRegisterResult: registerResult,
  businessCheckResult: false,
  alertMessageIdList: [],
  requestSubject: "",
  requestText: "",
  thanksSubject: "",
  thanksText: "",
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  sendTarget: [],
  isSending: false,
  searchCriteriaName: "",
  searchCriteria: "",
}

const submissionRequestSendConfirmSlice = createSlice({
  name: 'submissionRequestSendConfirm',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<{initVal: MCBHS020InitRequest, setReplace: (
      previewDataList: MCBHS020DisplayInfoOutDto, requestSubjectVl: string,  thanksSubjectVl: string, 
    ) => { requestSubject: string, requestText: string, thanksSubject: string, thanksText: string }, requestAttachment: File[]}>) {
      return state
    },
    setInit(state, action: PayloadAction<MCBHS020DisplayInfoOutDto>) {
      state.messageComInitResult = action.payload
      return state
    },
    setRequestSubject(state, action: PayloadAction<string>) {
      state.requestSubject = action.payload
      return state
    },
    setRequestText(state, action: PayloadAction<string>) {
      state.requestText = action.payload
      return state
    },
    setThanksSubject(state, action: PayloadAction<string>) {
      state.thanksSubject = action.payload
      return state
    },
    setThanksText(state, action: PayloadAction<string>) {
      state.thanksText = action.payload
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = false
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    setCount(state, action: PayloadAction<{ successfulCountMessage: number, successfulCountEmail: number, failedCount: number }>) {
      state.successfulCountMessage = action.payload.successfulCountMessage
      state.successfulCountEmail = action.payload.successfulCountEmail
      state.failedCount = action.payload.failedCount
      return state
    },
    redistMessage(
      state,
      action: PayloadAction<{
        formData: MCBHS020RegisterRequest
        files: Array<any | null>
        attachmentFileRegistrationMax: number
        forbiddenWords: string[]
        sendReplyImpossibleTime: viewConfig.sendReplyImpossibleTime[]
        sendTarget: SendTarget[]
      }>
    ) {
      return state
    },
    setBusinessCheckResult(state, action: PayloadAction<boolean>) {
      state.businessCheckResult = action.payload
      return state
    },
    setRegisterResult(state, action: PayloadAction<MCBHS020RegisterResult>) {
      state.messageComfRegisterResult = action.payload
      return state
    },
    setIsSending(state, action: PayloadAction<boolean>) {
      state.isSending = action.payload
      return state
    },
    setSendTarget(state, action: PayloadAction<SendTarget[]>) {
      state.sendTarget = action.payload
      return state
    },
  },
})

export const {
  getInit,
  setInit,
  setRequestSubject,
  setRequestText,
  setThanksSubject,
  setThanksText,
  setCode,
  setCount,
  setBusinessCheckResult,
  redistMessage,
  setRegisterResult,
  setIsSending,
  setSendTarget,
} = submissionRequestSendConfirmSlice.actions
export default submissionRequestSendConfirmSlice.reducer
