import React, { ChangeEvent } from 'react'
import {
  Radio,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useSelect } from 'utils'
import bracketsList from './brackets_list.png'
import bracketsListOver from './brackets_list_over.png'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 650,
    marginTop: (props: Props) => props.noMargin ? 0 : theme.spacing(1),
    '& table': {
      marginTop: (props: Props) => props.noMargin ? 0 : theme.spacing(1),
    },
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#e0e0e0',
    },
  },
  tableContainer: {
    '& tbody td': {
      cursor: 'pointer !important',
      '& i.badge': {
        margin: '0 8px 8px 0',
      },
    },
    '& tbody td:last-child': {
      backgroundImage: `url(${bracketsList}), url(${bracketsListOver})`,
      backgroundPosition: 'right center, right -100vw center',
      backgroundRepeat: 'no-repeat,no-repeat',
    },
    '& tr:hover': {
      '& td:last-child': {
        backgroundPosition: 'right -100vw center, right center',
      },
    },
    '& tbody tr:hover': {
      border: 'medium solid #9fb8de',
    },
    '& td > div.attached': {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'static !important',
      '& > button.icon-attached': {
        position: 'static !important',
        display: 'block',
        transform: 'none !important',
        padding: '0 10px',
      },
    },
  },
}))

interface Props {
  labelList: any[]
  dataList: any[]
  selectable?: boolean
  selected?: string
  handleSelect?: (event: ChangeEvent<HTMLInputElement>) => void
  handleClick?: (id?: any) => () => void
  className?: string
  stickyHeader?: boolean
  captaion?: any
  widthPercent?: string[]
  rowCondition?: {
    keyword: string
    className: string
  }
  noMargin?: boolean
}

export default ({
  labelList,
  dataList,
  selectable,
  selected,
  handleSelect,
  handleClick,
  className,
  stickyHeader,
  captaion,
  widthPercent,
  rowCondition,
  noMargin
}: Props) => {
  const classes = useStyles({ noMargin } as Props)

  if (!(selectable && selected && handleSelect)) {
    ;[selected, handleSelect] = useSelect('')
  }

  const multiLineText = (text: string) => {
    if (text == null) {
      return ''
    }
    return text.split(/\\r\\n|\\n/g).map((line, key) => (
      <span key={key}>
        {line}
        <br />
      </span>
    ))
  }

  return (
    <TableContainer className={`${classes.root} ${className}`}>
      <Table
        stickyHeader={stickyHeader}
        className={handleClick ? classes.tableContainer : ''}>
        {captaion}
        <TableHead>
          <TableRow>
            {selectable && <TableCell>選択</TableCell>}
            {labelList.map((label, labelIndex) => (
              <TableCell
                key={label}
                style={widthPercent ? { width: widthPercent[labelIndex] } : {}}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={className}>
          {dataList.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={
                rowCondition && row.includes(rowCondition.keyword)
                  ? rowCondition.className
                  : ''
              }
              onClick={handleClick ? handleClick(rowIndex) : undefined}>
              {selectable && (
                <TableCell>
                  <Radio
                    name='selection'
                    value={Object.values(row)[0]}
                    onChange={handleSelect as any}
                    checked={selected === Object.values(row)[0]}
                  />
                </TableCell>
              )}
              {Object.values(row).map((item, index) => {
                if (index > labelList.length - 1) {
                  return undefined
                } else {
                  return (
                    <TableCell
                      key={index}
                      style={{
                        wordWrap: 'break-word',
                        padding: '16px',
                      }}>
                      <>
                        {typeof item === 'string' ? multiLineText(item) : item}
                      </>
                    </TableCell>
                  )
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}