import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FieldProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'components/TextField';


const useStyles = makeStyles(theme => ({
  textCounter: {
    float: "right",
    minWidth: 120,
    textAlign: "right"
  },
  inputWithCounter: {
    marginTop: '5.9%',
  },
}));

interface Props extends FieldProps {
  name?: string
  label: string
  type?: string
  variant?: any
  className?: any
  maxAmount?: number
  onChange?: (value: string) => void
}

const InputWithCounter = ({ field, form, label, type, variant, className, maxAmount, onChange, ...others }: Props) => {
  const classes = useStyles();
  const { name, value } = field;
  const handleChange = (event: any) => {
    if (400 >= event.target.value.length) {
      if (onChange) {
        onChange(event.target.value)
      }
      form.setFieldValue(name, event.target.value);
    }
  };

  return (
    <React.Fragment>
      <Grid container className={classes.inputWithCounter}>
        <Grid item xs={12}>
          <TextField
            form={form}
            field={{ ...field, onChange: handleChange }}
            // label={label}
            type={type}
            {...others}
          />
        </Grid>
        <Grid item xs={12} className={classes.textCounter}>
          <Typography gutterBottom>
            {`入力 ${value ? value.length : 0} / 最大 ${maxAmount}`}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default InputWithCounter;
