import { makeStyles } from '@material-ui/core/styles'

export const useErrorStyle = makeStyles({
  helperText: {
    fontSize: '11px',
    marginTop: '8px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily:
      'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    margin: '8px 14px 0',
    whiteSpace: 'pre-wrap',
  },
})

export { default as AccountEdit } from './AccountEdit'
export { default as AdvancedSearch } from './AdvancedSearch'
export { default as AdvancedSearchJobChange } from './AdvancedSearchJobChange'
export { default as Alert } from './Alert'
export { default as BulkChange } from './BulkChange'
export { default as Button } from './Button'
export { default as ButtonGroup } from './ButtonGroup'
export { default as Checkbox } from './Checkbox'
export { default as ConfirmModal } from './ConfirmModal'
export { default as DataTable } from './DataTable'
export { default as DatePicker } from './DatePicker'
export { default as DndList } from './DndList'
export { default as EntryTable } from './EntryTable'
export { default as ErrorFocus } from './ErrorFocus'
export { default as FieldGroup } from './FieldGroup'
export { default as Filter } from './Filter'
export { default as Footer } from './Footer'
export { default as FormField } from './FormField'
export { default as GroupOptions } from './GroupOptions'
export { default as Label } from './Label'
export { default as NumberField } from './NumberField'
export { default as OperationPanel } from './OperationPanel'
export { default as Page } from './Page'
export { default as Paginate } from './Paginate'
export { default as ProgressStatus } from './ProgressStatus'
export { default as Radio } from './Radio'
export { default as ScrollToTop } from './ScrollToTop'
export { default as SearchButton } from './SearchButton'
export { default as SearchPanel } from './SearchPanel'
export { default as Select } from './Select'
export { default as SelectMessage } from './SelectMessage'
export { default as StyledTableCell } from './StyledTableCell'
export { default as SubTitle } from './SubTitle'
export { default as SubmitButton } from './SubmitButton'
export { default as Tab } from './Tab'
export { default as Table } from './Table'
export { default as TabPanel } from './TabPanel'
export { default as TextareaAutosize } from './TextareaAutosize'
export { default as TextField } from './TextField'
export { default as TextFieldForMessage } from './TextFieldForMessage'
export { default as Title } from './Title'
export { default as Toggle } from './Toggle'
export { default as TopBar } from './TopBar'
export { default as Layout } from './Layout'
export { default as OtherOperation } from './OtherOperation'
export { default as Header } from './Header'
export { default as Message } from './Message'
export { default as NavigateButton } from './NavigateButton'
export { default as OverlaySearch } from './OverlaySearch'
export { default as Captcha } from './Captcha'
export { default as LoadingDialog } from './LoadingDialog'
export { default as MCBMessage } from './MyCareerBoxMessage'