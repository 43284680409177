import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import { dateTimeToString, isEmpty, now, stringToDateTime } from 'common/generalUtil'
import { getMessage, getMessageEx } from 'common/messageUtil'
import { isFileExist } from 'common/businessUtil'
import { magiContants } from 'utils/contants'
import { insertionCharactersMcbMsg } from 'utils/messageChangeUtil'
import { changeConfirmState, closeConfirmModal, openConfirmModal } from 'reducers/submissionRequestSendReducer'
import {
  getInit,
  setInit,
  setIsSending,
  setCode,
  setBusinessCheckResult,
  redistMessage,
  setRegisterResult,
  setRequestSubject,
  setRequestText,
  setThanksSubject,
  setThanksText
} from 'reducers/submissionRequestSendConfirmReducer'
import { openModal } from 'reducers/messageReducer'
import { redistRequest, initRequest, } from 'apis/MCBHS020Api'
import * as apiConfig from 'pages/MCBHS020/apiConfig'
import * as viewConfig from 'pages/MCBHS020/viewConfig'

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const initVal = action.payload.initVal
    const data = yield call(initRequest, initVal)
    yield put(setInit(data))
    const result = action.payload.setReplace(data, initVal.requestSubject, initVal.thanksSubject)
    yield put(setRequestSubject(result.requestSubject))
    yield put(setRequestText(result.requestText))
    yield put(setThanksSubject(result.thanksSubject))
    yield put(setThanksText(result.thanksText))
    yield put(openConfirmModal())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* redistMessageSaga(action: ReturnType<typeof redistMessage>) {
  try {
    const formData = action.payload.formData
    const files: File[] = action.payload.files
    // 添付ファイルの登録上限数
    const attachmentFileRegistrationMax =
      action.payload.attachmentFileRegistrationMax
    // 禁止語
    const forbiddenWords = action.payload.forbiddenWords
    // メンテナンス日時
    const sendReplyImpossibleTime = action.payload.sendReplyImpossibleTime
    // No.29 送信先チェック
    if (
      !action.payload.sendTarget ||
      !action.payload.sendTarget.some(item => !isEmpty(item.mailAddress))
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCBHS020-029')))
      return
    }
    if (files.length > 0 || formData.requestAttachmentId.length > 0) {
      // No.14 ファイル数のチェック
      if (files.length > attachmentFileRegistrationMax) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS020-014')))
        return
      }

      // No.15 ファイル存在チェック
      if (files.length > 0) {
        if (files.some(item => !item || item.size <= 0)) {
          yield put(changeConfirmState(false))
          yield put(closeConfirmModal())
          yield put(openModal(getMessage('MCBHS020-015')))
          return
        }
      }
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        if (file) {
          const isFile = yield call(isFileExist, file)
          if (!isFile) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCBHS020-015')))
            return
          }
        }
      }

      // No.12 ファイルの拡張子チェック
      if (files.length > 0) {
        const suffixCheck = [
          'ai', 'art', 'doc', 'fla', 'gif', 'jpg', 'jpeg', 'lzh', 'mpeg', 'mpg', 'pdf', 'png', 'pps', 'ppt', 'psd', 'txt', 'xls', 'zip', 'docx', 'xlsx'
        ]
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const lastIndexOf = file.name.lastIndexOf('.')
            const suffix = file.name.substring(lastIndexOf + 1)
            for (var j = 0; j < suffixCheck.length; j++) {
              if (suffixCheck[j] == suffix.toLowerCase()) {
                break
              } else {
                if (suffixCheck[j] != 'xlsx') {
                  continue
                } else {
                  yield put(changeConfirmState(false))
                  yield put(closeConfirmModal())
                  yield put(openModal(getMessage('MCBHS020-012')))
                  return
                }
              }
            }
          }
        }
      }

      // No.16 同一添付ファイルチェック
      const addedfileNameList = files.map(file => file.name)
      const fullFileNameList = addedfileNameList.concat(formData.requestAttachment)
      const fileNameList = fullFileNameList.filter(v => v)
      if (fileNameList.length > 1) {
        const beforeLength = fileNameList.length
        const newFileNameList = fileNameList.filter(
          (x, index, self) => self.indexOf(x) === index
        )
        const afterLength = newFileNameList.length
        if (beforeLength !== afterLength) {
          yield put(changeConfirmState(false))
          yield put(closeConfirmModal())
          yield put(openModal(getMessage('MCBHS020-016')))
          return
        }
      }

      // No.13 ファイルのサイズチェック
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const fileSize = file.size
            if (fileSize > magiContants.MESSAGE_ATTACHMENT_MAX_SIZE) {
              yield put(changeConfirmState(false))
              yield put(closeConfirmModal())
              yield put(openModal(getMessage('MCBHS020-013')))
              return
            }
          }
        }
      }
    }

    // No.17 不正文字チェック
    if (
      formData.requestSubject.indexOf('●●') >= 0 ||
      formData.requestText.indexOf('●●') >= 0 ||
      formData.thanksSubject.indexOf('●●') >= 0 ||
      formData.thanksText.indexOf('●●') >= 0
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCBHS020-017')))
      return
    }

    // No.18 No.19 禁止語チェック
    if (forbiddenWords && forbiddenWords.length > 0) {
      let isErrorSubject = false
      let isErrorBody = false
      forbiddenWords.map(item => {
        if (formData.requestSubject.indexOf(item) >= 0 ||
            formData.thanksSubject.indexOf(item) >= 0
        ) {
          isErrorSubject = true
          return
        }
        if (formData.requestText.indexOf(item) >= 0 ||
            formData.thanksText.indexOf(item) >= 0
        ) {
          isErrorBody = true
          return
        }
      })
      if (isErrorSubject) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS020-018')))
        return
      }
      if (isErrorBody) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS020-019')))
        return
      }
    }

    // No.20 ホームページURLチェック
    if (
      (formData.homepageTitle1 &&
        formData.homepageTitle1 !== '' &&
        (formData.homepageUrl1 === null || formData.homepageUrl1 === '')) ||
      (formData.homepageTitle2 &&
        formData.homepageTitle2 !== '' &&
        (formData.homepageUrl2 === null || formData.homepageUrl2 === '')) ||
      (formData.homepageTitle3 &&
        formData.homepageTitle3 !== '' &&
        (formData.homepageUrl3 === null || formData.homepageUrl3 === ''))
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCBHS020-020')))
      return
    }

    if (
      String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION
    ) {
      // No.22 送信予約期間チェック
      if (formData.sendPlanTime === null || formData.sendPlanTime === '') {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS020-022')))
        return
      }
      if (formData.sendPlanTime) {
        const sendPlanTime = stringToDateTime(formData.sendPlanTime)
        if (sendPlanTime !== null) {
          // No.23 送信予約期間チェック
          if (sendPlanTime < now()) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCBHS020-023')))
            return
          }
          const targetDate = moment(sendPlanTime)
          const toDate = moment(now())
            .add(magiContants.RESERVATION_MAX_DATE_LENGTH, 'day')
            .set('hour', 23)
            .set('minutes', 59)
            .set('second', 59)
          // No.27 送信予約期間チェック
          if (targetDate > toDate) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCBHS020-027')))
            return
          }
          const minTime = moment(now()).add(
            magiContants.RESERVATION_MIN_MINUTES_LENGTH,
            'minutes'
          )
          // No.28 送信予約時刻チェック
          if (targetDate <= minTime) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCBHS020-028')))
            return
          }
        }
      }
    }

    // No.21 MCBメンテナンス期間チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment()
      if (
        String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION
      ) {
        if (!isEmpty(formData.sendPlanTime)) {
          sendTime = moment(
            formData.sendPlanTime
              ? formData.sendPlanTime.replace(/\//g, '-')
              : formData.sendPlanTime
          )
        }
      }
      sendReplyImpossibleTime.map(item => {
        let startDate
        let endDate
        let startDateStr = !isEmpty(item.startDateStr)
          ? item.startDateStr.trim()
          : ''
        let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
          startDate = moment(
            `${startDateStr.substring(0, 4)}-${startDateStr.substring(
              4,
              6
            )}-${startDateStr.substring(6, 8)} ${startDateStr.substring(
              8,
              10
            )}:${startDateStr.substring(10, 12)}`
          )
        }
        if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
          endDate = moment(
            `${endDateStr.substring(0, 4)}-${endDateStr.substring(
              4,
              6
            )}-${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
          )
        }
        // #MCB1.5次開発 #8986 START
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '1'? true : false
        if (startDate && endDate && functionalDivision) {
          if (
            (sendTime.isAfter(startDate) ||
              sendTime.diff(startDate, 'minute') === 0) &&
            (sendTime.isBefore(endDate) ||
              sendTime.diff(endDate, 'minute') === 0)
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～${endDateStr.substring(0, 4)}/${endDateStr.substring(
              4,
              6
            )}/${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
        if (startDate && !endDate && functionalDivision) {
          if (
            sendTime.isAfter(startDate) ||
            sendTime.diff(startDate, 'minute') === 0
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～`
            isError = true
            return
          }
        }
        if (!startDate && endDate && functionalDivision) {
        // #MCB1.5次開発 #8986 END
          if (
            sendTime.isBefore(endDate) ||
            sendTime.diff(endDate, 'minute') === 0
          ) {
            errorPeriod = `～${endDateStr.substring(
              0,
              4
            )}/${endDateStr.substring(4, 6)}/${endDateStr.substring(
              6,
              8
            )} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
      })
      if (isError) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessageEx('MCBHS020-021', errorPeriod)))
        return
      }
    }

    // 26KH #87889 START
    // MIWSメンテナンス期間チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment().startOf('minute')
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        if (!isEmpty(formData.sendPlanTime)) {
          sendTime = moment(formData.sendPlanTime ? formData.sendPlanTime.replace(/\//g, '-') : formData.sendPlanTime)
        }
      }
      sendReplyImpossibleTime.map(item => {
        let startDate
        let endDate
        let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
        let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
          startDate = moment(`${startDateStr.substring(0, 4)}-${startDateStr.substring(4, 6)}-${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}`)
        }
        if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
          endDate = moment(`${endDateStr.substring(0, 4)}-${endDateStr.substring(4, 6)}-${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`)
        }
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '3'? true : false
        if (startDate && endDate && functionalDivision) {
          if ((sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) && (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0)) {
            errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
        if (startDate && !endDate && functionalDivision) {
          if (sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) {
            errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
            isError = true
            return
          }
        }
        if (!startDate && endDate && functionalDivision) {
          if (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0) {
            errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
      })
      if (isError) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessageEx('MCBHS020-044', errorPeriod)))
        return
      }
    }
    // 26KH #87889 END

    // No.32-No.35 提出締切チェック
    const sendPlanTime = formData.sendPlanTime ? formData.sendPlanTime : '';
    const curDate = dateTimeToString(now())
    switch(formData.limitDateDetermineFlag){
      case (0):
        const determineDateTime = dateTimeToString(stringToDateTime(formData.determineDateTime));
        if(determineDateTime != null && curDate!== null){
          if(determineDateTime.substring(0,16) < curDate.substring(0,16)){
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCBHS020-032')))
            return
          }
          if(sendPlanTime != ''){
            if(determineDateTime.substring(0,16) < sendPlanTime.substring(0,16)){
              yield put(changeConfirmState(false))
              yield put(closeConfirmModal())
              yield put(openModal(getMessage('MCBHS020-033')))
              return
            }
          }
        }
        break
      case (1):
      // MCBリプレイス #8630 START          
        const sendPlanTimeHour=formData.sendPlanTime ? formData.sendPlanTime : ''
        if (formData.requestDayTimeLaterDay !== null &&formData.requestDayTimeToTime!== null &&curDate !== null) {
          if (formData.requestDayTimeLaterDay==0&&sendPlanTimeHour=='') {
            if(Number(formData.requestDayTimeToTime+'00')<Number(curDate.substring(11,16).replace(':',''))){
              yield put(changeConfirmState(false))
              yield put(closeConfirmModal())
              yield put(openModal(getMessage('MCBHS020-032')))
              return
              }
           }else if(formData.requestDayTimeLaterDay==0&&sendPlanTimeHour!==''){
            if(Number(formData.requestDayTimeToTime+'00')<Number(sendPlanTimeHour.substring(11,16).replace(':',''))){
              yield put(changeConfirmState(false))
              yield put(closeConfirmModal())
              yield put(openModal(getMessage('MCBHS020-033')))
              return
              }
           }
        }
      // MCBリプレイス #8630 END
        break
      case (2):
        const anytimeReceptionEndDateTime = dateTimeToString(stringToDateTime(formData.anytimeReceptionEndDateTime));
        if(anytimeReceptionEndDateTime != null && curDate!== null){
          if(anytimeReceptionEndDateTime.substring(0,16) < curDate.substring(0,16)){
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCBHS020-034')))
            return
          }
          if(sendPlanTime != ''){
            if(anytimeReceptionEndDateTime.substring(0,16) < sendPlanTime.substring(0,16)){
              yield put(changeConfirmState(false))
              yield put(closeConfirmModal())
              yield put(openModal(getMessage('MCBHS020-035')))
              return
            }
          }
        }
        break
      default:
        break
    }

    // No.36 提出締切チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let deadlineChangeTime = ''
      const leftPadZero = (target:number)=>{
        return target < 10 ? '0' + target : String(target)
      }
      const calRequestDayTime = (
        laterDay: number,
        toTime: number,
        sendPlanTime: string | null
      ) => {
        let baseMoment
        if (sendPlanTime) {
          baseMoment = moment(sendPlanTime)
        } else {
          baseMoment = moment()
        }
        return (
          baseMoment.add(laterDay, 'day').format('YYYY/MM/DD') +
          ' ' +
          leftPadZero(toTime) +
          ':00'
        )
      }
      if (String(formData.limitDateDetermineFlag) === magiContants.LIMIT_DATE_DETERMINE_FLAG_0) {
        deadlineChangeTime = formData.determineDateTime
      } else if (String(formData.limitDateDetermineFlag) === magiContants.LIMIT_DATE_DETERMINE_FLAG_1) {
        deadlineChangeTime = calRequestDayTime(
          formData.requestDayTimeLaterDay,
          formData.requestDayTimeToTime,
          formData.sendPlanTime
        )
      } else if (String(formData.limitDateDetermineFlag) === magiContants.LIMIT_DATE_DETERMINE_FLAG_2) {
        deadlineChangeTime = formData.anytimeReceptionEndDateTime
      }
      if (!isEmpty(deadlineChangeTime)) {
        let deadlineChangeDateTime = moment(
          deadlineChangeTime
            ? deadlineChangeTime.replace(/\//g, '-')
            : deadlineChangeTime
        )
        sendReplyImpossibleTime.map(item => {
          let startDate
          let endDate
          let startDateStr = !isEmpty(item.startDateStr)
            ? item.startDateStr.trim()
            : ''
          let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
          if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
            startDate = moment(
              `${startDateStr.substring(0, 4)}-${startDateStr.substring(
                4,
                6
              )}-${startDateStr.substring(6, 8)} ${startDateStr.substring(
                8,
                10
              )}:${startDateStr.substring(10, 12)}`
            )
          }
          if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
            endDate = moment(
              `${endDateStr.substring(0, 4)}-${endDateStr.substring(
                4,
                6
              )}-${endDateStr.substring(6, 8)} ${endDateStr.substring(
                8,
                10
              )}:${endDateStr.substring(10, 12)}`
            )
          }
          // #MCB1.5次開発 #8986 START
          let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '2'? true : false
          if (startDate && endDate && functionalDivision) {
            if (
              (deadlineChangeDateTime.isAfter(startDate) ||
              deadlineChangeDateTime.diff(startDate, 'minute') === 0) &&
              (deadlineChangeDateTime.isBefore(endDate) ||
              deadlineChangeDateTime.diff(endDate, 'minute') === 0)
            ) {
              errorPeriod = `${startDateStr.substring(
                0,
                4
              )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
                6,
                8
              )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
                10,
                12
              )}～${endDateStr.substring(0, 4)}/${endDateStr.substring(
                4,
                6
              )}/${endDateStr.substring(6, 8)} ${endDateStr.substring(
                8,
                10
              )}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
          if (startDate && !endDate && functionalDivision) {
            if (
              deadlineChangeDateTime.isAfter(startDate) ||
              deadlineChangeDateTime.diff(startDate, 'minute') === 0
            ) {
              errorPeriod = `${startDateStr.substring(
                0,
                4
              )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
                6,
                8
              )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
                10,
                12
              )}～`
              isError = true
              return
            }
          }
          if (!startDate && endDate && functionalDivision) {
            // #MCB1.5次開発 #8986 END
            if (
              deadlineChangeDateTime.isBefore(endDate) ||
              deadlineChangeDateTime.diff(endDate, 'minute') === 0
            ) {
              errorPeriod = `～${endDateStr.substring(
                0,
                4
              )}/${endDateStr.substring(4, 6)}/${endDateStr.substring(
                6,
                8
              )} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
        })
        if (isError) {
          yield put(changeConfirmState(false))
          yield put(closeConfirmModal())
          yield put(openModal(getMessageEx('MCBHS020-036', errorPeriod)))
          return
        }
      }
    }

    // No.37 再提出の受付可否チェック
    if(formData.limitDateDetermineFlag===2 && formData.reRequestReceptFlag===1){
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCBHS020-037')))
      return
    }

    yield put(setIsSending(true))
    const serverResponse: apiConfig.MCBHS020RegisterResult = yield call(
      redistRequest,
      formData,
      files
    )
    // MCBリプレース #9200 START
    if (serverResponse.mcbApiErrorList && serverResponse.mcbApiErrorList.length > 0) {
      throw { message: getMessageEx(magiContants.MESSAGECODE_MCBHS020_043, serverResponse.mcbApiErrorList.map((item:any) => '［'+ item.errorMessage +'］').join('\n')) }
    }
    // MCBリプレース #9200 END
    yield put(setRegisterResult(serverResponse))
    yield put(setCode(200))
    yield put(setBusinessCheckResult(true))
    yield put(setIsSending(false))
  } catch (error) {
    yield put(changeConfirmState(false))
    yield put(closeConfirmModal())
    yield put(setIsSending(false))
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export default function* submissionRequestSendConfirmSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(redistMessage, redistMessageSaga),
  ])
}
