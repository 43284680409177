import { MCANS010UpdateRequest } from 'types/MCANS010/MCANS010UpdateRequest'
import request from 'utils/request'

export const getSignListRequest = (recruitmentManagementDivision:string) =>
  request({
    url: `/MCANS010/init/${recruitmentManagementDivision}`,
    method: 'post',
  })

export const updateRequest = (apiData: MCANS010UpdateRequest[]) =>
  request({
    url: '/MCANS010/sort',
    method: 'post',
    data: apiData,
  })

  export const checkSignCountRequest =(recruitmentManagementDivision: string)=>
  request({
    url: `/MCANS010/new/${recruitmentManagementDivision}`,
    method: 'post',
  })