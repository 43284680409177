import request from 'utils/request'
import { MCALS010TemplateIdRequest } from 'types/MCALS010/MCALS010TemplateIdRequest'
import { MCALS030UpdateRequest } from 'types/MCALS030/MCALS030UpdateRequest'
import { MCALS030TemplateIdRequest } from 'types/MCALS030/MCALS030TemplateIdRequest'

export const MCALS030InitRequest = (params: MCALS010TemplateIdRequest) =>
    request({
        url: 'MCALS030/init',
        method: 'post',
        data: params
    })
// TODO 更新内容を確認するボタン押下
export const MCALS030updateRequest = (params: MCALS030UpdateRequest) =>
  request({
    url: '/MCALS030/update',
    method: 'post',
    data: params,
  })
  
// TODO 削除内容を確認するボタン押下
export const MCALS030deleteRequest = (params: MCALS030TemplateIdRequest) =>
request({
  url: '/MCALS030/delete',
  method: 'post',
  data: params,
})