import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// テスト用
export interface SearchCriteria351 {
  applicantId: string[];                                           // 応募者管理ID
  name: string[];                                                  // 氏名・氏名カナ
  sexObj: Sex;                                                     // 性別
  phoneNumber: string;                                             // 電話番号
  mailAddress: string;                                             // メールアドレス
  residenceObj: Residence                                          // 居住地（都道府県）
  languageSkills: string[];                                        // 語学スキル
  schoolName: string[];                                            // 学校名
  eventDateFrom: string;                                           // イベント開催日From
  eventDateTo: string;                                             // イベント開催日To
  scoutApplicationRouteObj: ScoutApplicationRoute;                 // スカウト（応募経路）
  scoutObj: Scout;                                                 // スカウト（条件）
  schoolGroupObj: SchoolGroup;                                     // 学校グループ
  schoolDetailsObj: SchoolDetails;                                 // 学校グループ（学校詳細）
  undergraduateDepartmentObj: UndergraduateDepartment;             // 学校グループ（学部・学科詳細）
  entryDateObj: EntryDate;                                         // エントリー日
  entryPresenceObj: EntryPresence;                                 // エントリー有無
  questionnaireResponseObj: QuestionnaireResponse;                 // アンケート回答内容
  seminarReceptionObj: SeminarReception;                           // 受付日程・予約状況
  webSeminarObj: WebSeminar;                                       // Webセミナー視聴状況
  eventsObj: Events;                                               // イベント・参加状況
  sendMessegeObj: SendMessege;                                     // メッセージ送付状況
  selectionFlowObj: SelectionFlow                                     // 選考フロー
  managementItemObj: ManagementItem;                               // 管理項目
  gabObj: GABCondition;                                            // GAB情報
  firstEntryDateFrom: string;                                      // 初回エントリー日（From)
  firstEntryDateTo: string;                                        // 初回エントリー日（To)
  targetApplicationRouteObj: TargetApplicationRoute;               // 対象応募経路
}

export interface SearchCriteria401 {
  applicantId: string[];                                           // 応募者管理ID
  name: string[];                                                  // 氏名・氏名カナ
  ageFrom: string;                                                 // 年齢(From)
  ageTo: string;                                                   // 年齢(To)
  sexObj: Sex;                                                     // 性別
  mailAddress: string;                                             // メールアドレス
  educationalBackground: string[];                                 // 学歴
  languageSkills: string[];                                        // 語学スキル
  applicationTypeObj: ApplicationType;                             // 応募職種
  questionnaireObj: Questionnaire;                                 // アンケート回答
  selectionFlowObj: SelectionFlow401                               // 選考フロー
  managementItemObj: ManagementItem;                               // 管理項目
  tagTxtObj: TagTxt                                                   // タグ
}

export interface Sex {
  sexCode: string[];  // 性別コード
  sexName: string[];  // 性別名
}

export interface Residence {
  residenceCurrentObj: ResidenceCurrent;       // 現住所条件
  residenceConnectorFlag: string               // 居住地条件（結合子）FLG
  residenceConnectorName: string               // 居住地条件（結合子）名
  residenceVacationObj: ResidenceVacation;     // 休暇中住所条件
}

export interface ResidenceCurrent {
  residenceCurrentPrefecturesCode: string[];   // 居住地条件（現住所：都道府県）コード
  residenceCurrentPrefecturesName: string[];   // 現住所
}

export interface ResidenceVacation {
  residenceVacationPrefecturesCode: string[];  // 居住地条件（休暇中住所：都道府県）コード
  residenceVacationPrefecturesName: string[];  // 休暇中住所
}

export interface ScoutApplicationRoute {
  scoutApplicationRouteId: string;    // スカウト（応募経路）ID
  scoutApplicationRouteName: string;  // スカウト（応募経路）名
}

export interface Scout {
  scoutConditionsFlag: string;  // スカウト（条件）FLG
  scoutConditionsName: string;  // スカウト（条件）名
}

export interface ApplicationType {
  applicationTypeName: string;  // 応募職種名
}

export interface SchoolGroup {
  schoolGroupId: string[];    // 学校グループID
  schoolGroupName: string[];  // 学校グループ名
}

export interface SchoolDetails {
  schoolDetailsId: string[];    // 学校詳細ID
  schoolDetailsName: string[];  // 学校詳細名
}

export interface UndergraduateDepartment {
  undergraduateDepartmentSchoolTypeId: string[];    // 学校種別ID
  undergraduateDepartmentSchoolTypeName: string[];  // 学校種別名
  undergraduateDepartmentUniversityId: string[];    // 大学ID
  undergraduateDepartmentUniversityName: string[];  // 大学名
  undergraduateDepartmentFacultyId: string[];       // 学部・研究科ID
  undergraduateDepartmentFacultyName: string[];     // 学部・研究科名
  undergraduateDepartmentSubjectId: string[];       // 学科・専攻ID
  undergraduateDepartmentSubjectName: string[];     // 学科・専攻名
}

export interface EntryDate {
  entryDateConditionJudgmentFlag: string;     // エントリー日条件判定FLG
  entryDateConditionJudgmentName: string;     // エントリー日条件判定名
  entryDateConditionObj: EntryDateCondition;  // エントリー日条件
}

export interface EntryDateCondition {
  entryDateClassificationFlag: string[];    // 分類FLG
  entryDateClassificationName: string[];    // 分類名
  entryDateApplicationRouteId: string[];    // 応募経路ID
  entryDateApplicationRouteName: string[];  // 応募経路名
  entryDatecoordinationFormerEntryBoxId: string[];  // 連携元エントリーボックスID
  entryDateEntryBoxId: string[];            // エントリーボックスID
  entryDateEntryBoxName: string[];          // エントリーボックス名
  entryDateFrom: string[];                  // エントリー日（From）
  entryDateTo: string[];                    // エントリー日（To）
}

export interface EntryPresence {
  entryPresenceConditionJudgmentFlag: string;         // エントリー有無条件判定FLG
  entryPresenceConditionJudgmentName: string;         // エントリー有無条件判定名
  entryPresenceConditionObj: EntryPresenceCondition;  // エントリー有無条件
}

export interface EntryPresenceCondition {
  entryPresenceClassificationFlag: string[];    // 分類FLG
  entryPresenceClassificationName: string[];    // 分類名
  entryPresenceApplicationRouteId: string[];    // 応募経路ID
  entryPresenceApplicationRouteName: string[];  // 応募経路名
  entryPresencecoordinationFormerEntryBoxId: string[];  // 連携元エントリーボックスID
  entryPresenceEntryBoxId: string[];            // エントリーボックスID
  entryPresenceEntryBoxName: string[];          // エントリーボックス名
  entryPresenceFlag: string[];                  // エントリー有無FLG
  entryPresenceName: string[];                  // エントリー有無名
}

export interface QuestionnaireResponse {
  questionnaireResponseConditionJudgmentFlag: string;                 // アンケート条件判定FLG
  questionnaireResponseConditionJudgmentName: string;                 // アンケート条件判定名
  questionnaireResponseConditionObj: QuestionnaireResponseCondition;  // アンケート回答条件
}

export interface QuestionnaireResponseCondition {
  questionnaireResponseApplicationRouteId: string[];           // 応募経路ID
  questionnaireResponseApplicationRouteName: string[];         // 応募経路名
  questionnaireResponsecoordinationFormerEntryBoxId: string[]; // 連携元エントリーボックスID
  questionnaireResponseEntryBoxId: string[];                   // エントリーボックスID
  questionnaireResponseEntryBoxName: string[];                 // エントリーボックス名
  questionnaireResponseQuestionNumber: string[];               // 設問番号
  questionnaireResponseQuestionTitle: string[];                // 質問文
  questionnaireResponseQuestionAnswerConditionFlag: string[];  // 回答有無FLG
  questionnaireResponseQuestionAnswerConditionName: string[];  // 回答有無名
  questionnaireResponseQuestionTypeCode: string[];             // 質問種別コード
  questionnaireResponseQuestionTypeName: string[];             // 質問種別コード
  questionnaireResponseAnswerText: string[];                   // 回答テキスト
  questionnaireResponseAnswerCheckBoxObj: QuestionnaireResponseAnswerCheckBox[];  // 回答選択肢
}

export interface QuestionnaireResponseAnswerCheckBox {
  questionnaireResponseAnswerCheckBoxId: string[];    // 回答選択肢ID
  questionnaireResponseAnswerCheckBoxName: string[];  // 回答選択肢名
}

export interface Questionnaire {
  questionnaireConditionsJudgmentFlag: string;  // アンケート条件判定FLG
  questionnaireConditionsJudgmentName: string;  // アンケート条件判定名
  questionnaireConditionsObj: QuestionnaireConditions;  // 設問番号
}

export interface QuestionnaireConditions {
  questionNumber: string[];                     // 設問番号
  questionTitle: string[];                      // 質問文
  answerConditionFlag: string[];                // 回答有無FLG
  answerConditionName: string[];                // 回答有無名
  questionTypeCode: string[];                   // 質問種別コード
  questionTypeName: string[];                   // 質問種別コード
  answerText: string[];                         // 回答テキスト
  answerCheckBoxObj: AnswerCheckBox[];             // 回答選択肢
}

export interface AnswerCheckBox {
  answerCheckBoxId: string[];    // 回答選択肢
  answerCheckBoxName: string[];  // 回答選択肢名
}

export interface SeminarReception {
  seminarReceptionConditionJudgmentFlag: string;   // セミナー受付日程・予約状況条件判定FLG
  seminarReceptionConditionJudgmentName: string;   // セミナー受付日程・予約状況条件判定名
  seminarReceptionWhichOneObj: SeminarReceptionWhichOne;     // セミナー受付予約状況（いずれかの日程）条件
  seminarReceptionIndividualObj: SeminarReceptionIndividual; // セミナー受付日程・予約状況（各受付ごと）
}

export interface SeminarReceptionWhichOne {
  seminarReceptionWhichOneFlag: string;            // セミナー受付予約状況（いずれかの日程）FLG
  seminarReceptionWhichOneName: string;            // セミナー受付予約状況（いずれかの日程）名
}

export interface SeminarReceptionIndividual {
  seminarReceptionIndividualJudgmentFlag: string;  // セミナー受付日程・予約状況（各受付ごと）条件判定FLG
  seminarReceptionIndividualJudgmentName: string;  // セミナー受付日程・予約状況（各受付ごと）条件判定名
  seminarReceptionIndividualObj2: SeminarReceptionIndividualObj2; // セミナー受付日程・予約状況（各受付ごと）条件
}

export interface SeminarReceptionIndividualObj2 {
  seminarReceptionIndividualApplicationRouteId: string[];           // 応募経路ID
  seminarReceptionIndividualApplicationRouteName: string[];         // 応募経路名
  seminarReceptionIndividualCoordinationFormerSeminarId: string[];  // 連携元セミナーID
  seminarReceptionIndividualSeminarId: string[];                    // セミナーID
  seminarReceptionIndividualSeminarName: string[];                  // セミナー名
  seminarReceptionIndividualPublicFlag: string[];                   // 公開フラグ
  seminarReceptionIndividualPublicName: string[];                   // 公開フラグ名
  seminarReceptionIndividualSeminarScheduleId: string[];            // セミナー日程ID
  seminarReceptionIndividualSeminarScheduleTitle: string[];         // セミナー日程タイトル
  seminarReceptionIndividualInvisibleFlag: string[];                // 非表示フラグ
  seminarReceptionIndividualInvisibleName: string[];                // 非表示フラグ名
  seminarReceptionIndividualEventDate: string[];                    // 開催日
  seminarReceptionIndividualStartTime: string[];                    // 開催時間
  seminarReceptionIndividualEndTime: string[];                      // 終了時間
  seminarReceptionIndividualVenueLocationCode: string[];            // 開催地場所コード
  seminarReceptionIndividualVenueLocationName: string[];            // 開催地場所名
  seminarReceptionIndividualFlag: string[];                         // セミナー受付予約状況（各受付ごと）FLG
  seminarReceptionIndividualName: string[];                         // セミナー受付予約状況（各受付ごと）名
}

export interface WebSeminar {
  webSeminarConditionJudgmentFlag: string;             // Webセミナー視聴状況条件判定FLG
  webSeminarConditionJudgmentName: string;             // Webセミナー視聴状況条件判定名
  webSeminarConditionObj: WebSeminarCondition;         // Webセミナー視聴状況条件
}

export interface WebSeminarCondition {
  webSeminarApplicationRouteId: string[];              // 応募経路ID
  webSeminarApplicationRouteName: string[];            // 応募経路名
  webSeminarCoordinationFormerWebSeminarId: string[];  // 連携元WebセミナーID
  webSeminarTitle: string[];                           // Webセミナータイトル
  webSeminarWatchingStatusConditionFlag: string[];     // Webセミナー視聴状況条件FLG
  webSeminarWatchingStatusConditionName: string[];     // Webセミナー視聴状況条件名
}

export interface Events {
  eventsConditionJudgmentFlag: string;   // イベント参加状況条件判定FLG
  eventsConditionJudgmentName: string;   // イベント参加状況条件判定名
  eventsWhichOneObj: EventsWhichOne;     // イベント参加状況（いずれかのイベント）条件
  eventsIndividualObj: EventsIndividual; // イベント参加状況（各イベントごと）
}

export interface EventsWhichOne {
  eventsWhichOneFlag: string;            // イベント参加状況（いずれかのイベント）FLG
  eventsWhichOneName: string;            // イベント参加状況（いずれかのイベント）名
}

export interface EventsIndividual {
  eventsIndividualJudgmentFlag: string;      // イベント参加状況（各イベントごと）条件判定FLG
  eventsIndividualJudgmentName: string;      // イベント参加状況（各イベントごと）条件判定名
  eventsIndividualObj2: EventsIndividualObj; // イベント参加状況（各イベントごと）条件
}

export interface EventsIndividualObj {
  eventsIndividualApplicationRouteId: string[];         // 応募経路ID
  eventsIndividualApplicationRouteName: string[];       // 応募経路名
  eventsIndividualEventTypeId: string[];                // イベント種別ID
  eventsIndividualEventTypeName: string[];              // イベント種別名
  eventsIndividualCoordinationFormerEventId: string[];  // 連携元イベントID
  eventsIndividualEventSchedule: string[];              // イベント日程
  eventsIndividualEventId: string[];                    // イベントID
  eventsIndividualEventName: string[];                  // イベント名
  eventsIndividualPrefecturesCode: string[];            // 都道府県コード
  eventsIndividualPrefecturesName: string[];            // 都道府県名
  eventsIndividualFlag: string[];                       // イベント参加状況（各イベントごと）FLG
  eventsIndividualName: string[];                       // イベント参加状況（各イベントごと）名
  // Started By DiaoJiaHao
  eventsIndividualAreaId: string[]                      // 連携元イベント区分ID
  eventsIndividualAreaName: string[]                    // イベント区分名
  // End By DiaoJiaHao
}

export interface SendMessege {
  sendMessegeConditionJudgmentFlag: string;      // メッセージ送付状況条件FLG
  sendMessegeConditionJudgmentName: string;      // メッセージ送付状況条件名
  sendMessegeConditionObj: SendMessegeCondition; // メッセージ送付状況条件
}

export interface SendMessegeCondition {
  sendMessegeMessageTypeFlag: string[];      // メッセージ種別FLG
  sendMessegeMessageTypeName: string[];      // メッセージ種別名
  sendMessegeSubject: string[];              // 件名
  sendMessegeSendConditionFlag: string[];    // 送付状態FLG
  sendMessegeSendConditionName: string[];    // 送付状態名
  sendMessegeSendDateFrom: string[];         // 送付From
  sendMessegeSendDateTo: string[];           // 送付To
}

export interface SelectionFlow {
  selectionFlowId: string[];    // 選考フローID
  selectionFlowName: string[];  // 選考フロー名
}

export interface SelectionFlow401 {
  selectionFlowId: string;    // 選考フローID
  selectionFlowName: string;  // 選考フロー名
}

export interface ManagementItem {
  managementItemConditionJudgmentFlag: string;               // 管理項目条件判定FLG
  managementItemConditionJudgmentName: string;               // 管理項目条件判定名
  managementItemConditionObj: ManagementItemCondition;       // 管理項目条件
}

export interface ManagementItemCondition {
  recruitmentManagementFlagSettingId: string[];              // 採用管理フラグ設定ID
  recruitmentManagementFlagName: string[];                   // 採用管理フラグ名
  managementItemPresenceFlag: String[];                      // 管理項目値有無条件FLG
  managementItemPresenceName: string[];                      // 管理項目値有無条件名
  managementItemTypeCode: string[];                          // 種別コード
  managementItemTypeName: string[];                          // 種別名
  managementItemFlagText: string[];                          // 詳細テキスト
  managementItemFlagFrom: string[];                          // 詳細範囲From
  managementItemFlagTo: string[];                            // 詳細範囲To
  managementItemFlagCheckBoxObj: ManagementItemFlagCheckBox[];  // 詳細選択肢
}

export interface ManagementItemFlagCheckBox {
  managementItemFlagCheckBoxId: string[];    // 詳細選択肢
  managementItemFlagCheckBoxName: string[];  // 詳細選択肢名
}

export interface GABCondition {
  gabLogicalName: string[];   // 項目論理名
  gabPhysicalName: string[];  // 項目物理名
  gabTyp: string[];           // GABタイプ
  gabTypName: string[];       // GABタイプ名
  gabListFlag: string[];      // 条件リストFLG
  gabListName: string[];      // 条件リスト名
  gabValueFrom: string[];     // 値From
  gabValueTo: string[];       // 値To
}

export interface TargetApplicationRoute {
  targetApplicationRouteFlag: string[];  // 対象応募経路フラグ
  targetApplicationRouteId: string[];    // 対象応募経路ID 
  targetApplicationRouteName: string[];  // 対象応募経路名
}

export interface TagTxt {
  tagSettingId: string[];  // タグ設定ID
  tagName: string[];       // タグ名
}

const initSex = {  // 0014
  sexCode: ['1'],
  sexName: ['男性'],
}

const initSex2 = {  // 0013
  sexCode: [],
  sexName: [],
}

const initSex3 = {  // 0015
  sexCode: ['1', '2'],
  sexName: ['男性', '女性'],
}

const initResidenceCurrent = {  // 0095, 0100
  residenceCurrentPrefecturesCode: [],
  residenceCurrentPrefecturesName: [],
}
const initResidenceCurrent2 = {  // 0096, 0098
  residenceCurrentPrefecturesCode: ['07'],
  residenceCurrentPrefecturesName: ['北海道'],
}
const initResidenceCurrent3 = { // 0097, 0099
  residenceCurrentPrefecturesCode: ['07','01'],
  residenceCurrentPrefecturesName: ['北海道','東京都'],
}
const initResidenceVacation = {  // 0095, 0099
  residenceVacationPrefecturesCode: [],
  residenceVacationPrefecturesName: [],
}
const initResidenceVacation2 = { // 0096, 0097
  residenceVacationPrefecturesCode: ['01'],
  residenceVacationPrefecturesName: ['東京都'],
}
const initResidenceVacation3 = {  // 0098, 0100
  residenceVacationPrefecturesCode: ['01','03'],
  residenceVacationPrefecturesName: ['東京都','神奈川県'],
}
const initResidence = { // 0097
  residenceCurrentObj: initResidenceCurrent3,
  residenceConnectorFlag: '0',
  residenceConnectorName: 'かつ',
  residenceVacationObj: initResidenceVacation2,
}

const initResidence2 = {  // 0095
  residenceCurrentObj: initResidenceCurrent,
  residenceConnectorFlag: '0',
  residenceConnectorName: 'かつ',
  residenceVacationObj: initResidenceVacation,
}

const initResidence3 = {  // 0096
  residenceCurrentObj: initResidenceCurrent2,
  residenceConnectorFlag: '0',
  residenceConnectorName: 'かつ',
  residenceVacationObj: initResidenceVacation2,
}

const initResidence4 = {  // 0098
  residenceCurrentObj: initResidenceCurrent2,
  residenceConnectorFlag: '0',
  residenceConnectorName: 'かつ',
  residenceVacationObj: initResidenceVacation3,
}

const initResidence5 = {  // 0099
  residenceCurrentObj: initResidenceCurrent3,
  residenceConnectorFlag: '0',
  residenceConnectorName: 'かつ',
  residenceVacationObj: initResidenceVacation,
}

const initResidence6 = {  // 0100
  residenceCurrentObj: initResidenceCurrent,
  residenceConnectorFlag: '0',
  residenceConnectorName: 'かつ',
  residenceVacationObj: initResidenceVacation3,
}

const initScoutApplicationRoute = {  // 0032,0033
  scoutApplicationRouteId: '2023',
  scoutApplicationRouteName: 'マイナビ2023',
}

const initScoutApplicationRoute2 = {  // 0031,0034
  scoutApplicationRouteId: '',
  scoutApplicationRouteName: '',
}

const initScout = {  // 0032,0034
  scoutConditionsFlag: '0',
  scoutConditionsName: 'スカウト送信あり',
}

const initScout2 = {  // 0031,0033
  scoutConditionsFlag: '',
  scoutConditionsName: '',
}

const initApplicationType = {  // 0041
  applicationTypeName: '『マイナビ転職』の営業職',
}

const initApplicationType2 = {  // 0040
  applicationTypeName: '',
}

const initSchoolGroup = {  // 0042,0045-0048
  schoolGroupId: [],
  schoolGroupName: [],
}

const initSchoolGroup2 = {  // 0043
  schoolGroupId: ['01'],
  schoolGroupName: ['旧帝大'],
}

const initSchoolGroup3 = {  // 0044
  schoolGroupId: ['01', '02'],
  schoolGroupName: ['旧帝大', '関東国公立'],
}

const initSchoolDetails = {  // 0042-0044,0047-0048
  schoolDetailsId: [],
  schoolDetailsName: [],
}

const initSchoolDetails2 = {  // 0045
  schoolDetailsId: ['10'],
  schoolDetailsName: ['北海道大学'],
}

const initSchoolDetails3 = {  // 0046
  schoolDetailsId: ['10', '20'],
  schoolDetailsName: ['北海道大学', '東北大学'],
}

const initUndergraduateDepartment = {  // 0048
  undergraduateDepartmentSchoolTypeId: ['01', '02'],
  undergraduateDepartmentSchoolTypeName: ['大学院', '大学'],
  undergraduateDepartmentUniversityId: ['11', '12'],
  undergraduateDepartmentUniversityName: ['秋田大学', '大阪教育大学'],
  undergraduateDepartmentFacultyId: ['21', '22'],
  undergraduateDepartmentFacultyName: ['教育学研究科', '教育学部'],
  undergraduateDepartmentSubjectId: ['31', '32'],
  undergraduateDepartmentSubjectName: ['心理教育実践選考', '教養学科'],
}

const initUndergraduateDepartment2 = {  // 0042-0046
  undergraduateDepartmentSchoolTypeId: [],
  undergraduateDepartmentSchoolTypeName: [],
  undergraduateDepartmentUniversityId: [],
  undergraduateDepartmentUniversityName: [],
  undergraduateDepartmentFacultyId: [],
  undergraduateDepartmentFacultyName: [],
  undergraduateDepartmentSubjectId: [],
  undergraduateDepartmentSubjectName: [],
}

const initUndergraduateDepartment3 = {  // 0047
  undergraduateDepartmentSchoolTypeId: ['01'],
  undergraduateDepartmentSchoolTypeName: ['大学院'],
  undergraduateDepartmentUniversityId: ['11'],
  undergraduateDepartmentUniversityName: ['秋田大学'],
  undergraduateDepartmentFacultyId: ['21'],
  undergraduateDepartmentFacultyName: ['教育学研究科'],
  undergraduateDepartmentSubjectId: ['31'],
  undergraduateDepartmentSubjectName: ['心理教育実践選考'],
}

const initEntryDateCondition = {  // 0051
  entryDateClassificationFlag: ['0', '1', '1'],
  entryDateClassificationName: ['エントリーボックス', '外部エントリー', '外部エントリー'],
  entryDateApplicationRouteId: ['22', '10', '10'],
  entryDateApplicationRouteName: ['マイナビ2022', 'Indeed', 'Indeed'],
  entryDatecoordinationFormerEntryBoxId: ['108888', '', ''],
  entryDateEntryBoxId: ['8888', '', ''],
  entryDateEntryBoxName: ['一括エントリー', '', ''],
  entryDateFrom: ['2019/05/01', '', '2019/05/01'],
  entryDateTo: ['', '2019/06/01', '2019/05/01'],
}

const initEntryDateCondition2 = { // 0049
  entryDateClassificationFlag: [],
  entryDateClassificationName: [],
  entryDateApplicationRouteId: [],
  entryDateApplicationRouteName: [],
  entryDatecoordinationFormerEntryBoxId: [],
  entryDateEntryBoxId: [],
  entryDateEntryBoxName: [],
  entryDateFrom: [],
  entryDateTo: [],
}

const initEntryDateCondition3 = {  // 0050
  entryDateClassificationFlag: ['0'],
  entryDateClassificationName: ['エントリーボックス'],
  entryDateApplicationRouteId: ['22'],
  entryDateApplicationRouteName: ['マイナビ2022'],
  entryDatecoordinationFormerEntryBoxId: ['108888'],
  entryDateEntryBoxId: ['8888'],
  entryDateEntryBoxName: ['一括エントリー'],
  entryDateFrom: ['2019/05/01'],
  entryDateTo: ['2019/06/01'],
}

const initEntryDate = {  // 0051
  entryDateConditionJudgmentFlag: '0',
  entryDateConditionJudgmentName: '全てと一致',
  entryDateConditionObj: initEntryDateCondition,
}

const initEntryDate2 = { // 0049
  entryDateConditionJudgmentFlag: '',
  entryDateConditionJudgmentName: '',
  entryDateConditionObj: initEntryDateCondition2,
}

const initEntryDate3 = {  // 0050
  entryDateConditionJudgmentFlag: '0',
  entryDateConditionJudgmentName: '全てと一致',
  entryDateConditionObj: initEntryDateCondition3,
}

const initEntryPresenceCondition = {  // 0054
  entryPresenceClassificationFlag: ['0', '1'],
  entryPresenceClassificationName: ['エントリーボックス', '外部エントリー'],
  entryPresenceApplicationRouteId: ['23', '10'],
  entryPresenceApplicationRouteName: ['マイナビ2023', 'Indeed'],
  entryPresencecoordinationFormerEntryBoxId: ['108888', ''],
  entryPresenceEntryBoxId: ['8888', ''],
  entryPresenceEntryBoxName: ['一括エントリー', ''],
  entryPresenceFlag: ['0', '1'],
  entryPresenceName: ['エントリーあり', 'エントリーなし'],
}

const initEntryPresenceCondition2 = {  // 0052
  entryPresenceClassificationFlag: [],
  entryPresenceClassificationName: [],
  entryPresenceApplicationRouteId: [],
  entryPresenceApplicationRouteName: [],
  entryPresencecoordinationFormerEntryBoxId: [],
  entryPresenceEntryBoxId: [],
  entryPresenceEntryBoxName: [],
  entryPresenceFlag: [],
  entryPresenceName: [],
}

const initEntryPresenceCondition3 = {  // 0053
  entryPresenceClassificationFlag: ['0'],
  entryPresenceClassificationName: ['エントリーボックス'],
  entryPresenceApplicationRouteId: ['23'],
  entryPresenceApplicationRouteName: ['マイナビ2023'],
  entryPresencecoordinationFormerEntryBoxId: ['108888'],
  entryPresenceEntryBoxId: ['8888'],
  entryPresenceEntryBoxName: ['一括エントリー'],
  entryPresenceFlag: ['0'],
  entryPresenceName: ['エントリーあり'],
}

const initEntryPresence = {  // 0054
  entryPresenceConditionJudgmentFlag: '0',
  entryPresenceConditionJudgmentName: '全てと一致',
  entryPresenceConditionObj: initEntryPresenceCondition,
}

const initEntryPresence2 = {  // 0052
  entryPresenceConditionJudgmentFlag: '',
  entryPresenceConditionJudgmentName: '',
  entryPresenceConditionObj: initEntryPresenceCondition2,
}

const initEntryPresence3 = {  // 0053
  entryPresenceConditionJudgmentFlag: '0',
  entryPresenceConditionJudgmentName: '全てと一致',
  entryPresenceConditionObj: initEntryPresenceCondition3,
}

const initQRAnswerCheckBox1 = {
  questionnaireResponseAnswerCheckBoxId: [],
  questionnaireResponseAnswerCheckBoxName: [],
}

const initQRAnswerCheckBox2 = {
  questionnaireResponseAnswerCheckBoxId: ['1', '2'],
  questionnaireResponseAnswerCheckBoxName: ['営業系 （営業職）', '開発系（SE職）'],
}

const initQuestionnaireResponseCondition = {  // 0057
  questionnaireResponseApplicationRouteId: ['22', '22', '23'],
  questionnaireResponseApplicationRouteName: ['マイナビ2022', 'マイナビ2022', 'マイナビ2023'],
  questionnaireResponsecoordinationFormerEntryBoxId: ['108888', '108888', '109998'],
  questionnaireResponseEntryBoxId: ['8888', '8888', '9998'],
  questionnaireResponseEntryBoxName: ['一括エントリー', '一括エントリー', '（1day）インターンシップ法人営業'],
  questionnaireResponseQuestionNumber: ['1', '2', '1'],
  questionnaireResponseQuestionTitle: ['いま、自分がやりたい仕事についてどのくらいイメージできていますか。', 'いま、一番「やりたい」と思う仕事（職種）を選んでください。', 'いま、自分がやりたい仕事についてどのくらいイメージできていますか。'],
  questionnaireResponseQuestionAnswerConditionFlag: ['0', '0', '1'],
  questionnaireResponseQuestionAnswerConditionName: ['回答あり', '回答あり', '回答なし'],
  questionnaireResponseQuestionTypeCode: ['01', '02', '01'],
  questionnaireResponseQuestionTypeName: ['全角50文字', '複数選択', '全角50文字'],
  questionnaireResponseAnswerText: ['ぼんやりとしたイメージはある', '', ''],
  questionnaireResponseAnswerCheckBoxObj: [initQRAnswerCheckBox1, initQRAnswerCheckBox2, initQRAnswerCheckBox1],
}

const initQuestionnaireResponseCondition2 = {  // 0055
  questionnaireResponseApplicationRouteId: [],
  questionnaireResponseApplicationRouteName: [],
  questionnaireResponsecoordinationFormerEntryBoxId: [],
  questionnaireResponseEntryBoxId: [],
  questionnaireResponseEntryBoxName: [],
  questionnaireResponseQuestionNumber: [],
  questionnaireResponseQuestionTitle: [],
  questionnaireResponseQuestionAnswerConditionFlag: [],
  questionnaireResponseQuestionAnswerConditionName: [],
  questionnaireResponseQuestionTypeCode: [],
  questionnaireResponseQuestionTypeName: [],
  questionnaireResponseAnswerText: [],
  questionnaireResponseAnswerCheckBoxObj: [],
}

const initQuestionnaireResponseCondition3 = {  // 0056
  questionnaireResponseApplicationRouteId: ['22'],
  questionnaireResponseApplicationRouteName: ['マイナビ2022'],
  questionnaireResponsecoordinationFormerEntryBoxId: ['108888'],
  questionnaireResponseEntryBoxId: ['8888'],
  questionnaireResponseEntryBoxName: ['一括エントリー'],
  questionnaireResponseQuestionNumber: ['1'],
  questionnaireResponseQuestionTitle: ['いま、自分がやりたい仕事についてどのくらいイメージできていますか。'],
  questionnaireResponseQuestionAnswerConditionFlag: ['0'],
  questionnaireResponseQuestionAnswerConditionName: ['回答あり'],
  questionnaireResponseQuestionTypeCode: ['01'],
  questionnaireResponseQuestionTypeName: ['全角50文字'],
  questionnaireResponseAnswerText: [''],
  questionnaireResponseAnswerCheckBoxObj: [initQRAnswerCheckBox1],
}

const initQuestionnaireResponse = {  // 0057
  questionnaireResponseConditionJudgmentFlag: '0',
  questionnaireResponseConditionJudgmentName: '全てと一致',
  questionnaireResponseConditionObj: initQuestionnaireResponseCondition,
}

const initQuestionnaireResponse2 = {  // 0055
  questionnaireResponseConditionJudgmentFlag: '',
  questionnaireResponseConditionJudgmentName: '',
  questionnaireResponseConditionObj: initQuestionnaireResponseCondition2,
}

const initQuestionnaireResponse3 = {  // 0056
  questionnaireResponseConditionJudgmentFlag: '0',
  questionnaireResponseConditionJudgmentName: '全てと一致',
  questionnaireResponseConditionObj: initQuestionnaireResponseCondition3,
}

const initAnswerCheckBox1 = {
  answerCheckBoxId: [],
  answerCheckBoxName: [],
}

const initAnswerCheckBox2 = {
  answerCheckBoxId: ['1', '2'],
  answerCheckBoxName: ['営業系 （営業職）', '開発系（SE職）'],
}

const initQuestionnaireConditions = {  // 0060
  questionNumber: ['1', '2', '1'],
  questionTitle: ['いま、自分がやりたい仕事についてどのくらいイメージできていますか。', 'いま、一番「やりたい」と思う仕事（職種）を選んでください。', 'いま、自分がやりたい仕事についてどのくらいイメージできていますか。'],
  answerConditionFlag: ['0', '0', '1'],
  answerConditionName: ['回答あり', '回答あり', '回答なし'],
  questionTypeCode: ['01', '02', '01'],
  questionTypeName: ['全角50文字', '複数選択', '全角50文字'],
  answerText: ['ぼんやりとしたイメージはある', '', ''],
  answerCheckBoxObj: [initAnswerCheckBox1, initAnswerCheckBox2, initAnswerCheckBox1],
}

const initQuestionnaireConditions2 = {  // 0058
  questionNumber: [],
  questionTitle: [],
  answerConditionFlag: [],
  answerConditionName: [],
  questionTypeCode: [],
  questionTypeName: [],
  answerText: [],
  answerCheckBoxObj: [],
}

const initQuestionnaireConditions3 = {  // 0059
  questionNumber: ['1'],
  questionTitle: ['いま、自分がやりたい仕事についてどのくらいイメージできていますか。'],
  answerConditionFlag: ['0'],
  answerConditionName: ['回答あり'],
  questionTypeCode: ['01'],
  questionTypeName: ['全角50文字'],
  answerText: [''],
  answerCheckBoxObj: [initAnswerCheckBox1],
}

const initQuestionnaire = {  // 0060
  questionnaireConditionsJudgmentFlag: '0',
  questionnaireConditionsJudgmentName: '全てと一致',
  questionnaireConditionsObj: initQuestionnaireConditions,
}

const initQuestionnaire2 = {  // 0058
  questionnaireConditionsJudgmentFlag: '',
  questionnaireConditionsJudgmentName: '',
  questionnaireConditionsObj: initQuestionnaireConditions2,
}

const initQuestionnaire3 = {  // 0059
  questionnaireConditionsJudgmentFlag: '0',
  questionnaireConditionsJudgmentName: '全てと一致',
  questionnaireConditionsObj: initQuestionnaireConditions3,
}

const initSeminarReceptionWhichOne = {  // 0061, 0063, 0064
  seminarReceptionWhichOneFlag: '',
  seminarReceptionWhichOneName: '',
}

const initSeminarReceptionWhichOne2 = {  // 0062
  seminarReceptionWhichOneFlag: '0',
  seminarReceptionWhichOneName: '予約あり',
}

const initSeminarReceptionIndividualObj = {  // 0064
  seminarReceptionIndividualApplicationRouteId: ['20', '20'],
  seminarReceptionIndividualApplicationRouteName: ['マイナビ2020', 'マイナビ2020'],
  seminarReceptionIndividualCoordinationFormerSeminarId: ['99999', '99999'],
  seminarReceptionIndividualSeminarId: ['10', '10'],
  seminarReceptionIndividualSeminarName: ['クリスマスセミナーのご案内', 'クリスマスセミナーのご案内'],
  seminarReceptionIndividualPublicFlag: ['0', '0'],
  seminarReceptionIndividualPublicName: ['【非公開】', '【非公開】'],
  seminarReceptionIndividualSeminarScheduleId: ['536', '537'],
  seminarReceptionIndividualSeminarScheduleTitle: ['S-20200116H1536', 'S-20200116H1537'],
  seminarReceptionIndividualInvisibleFlag: ['0', '1'],
  seminarReceptionIndividualInvisibleName: ['', '【非表示】'],
  seminarReceptionIndividualEventDate: ['2020/03/26', '2020/03/26'],
  seminarReceptionIndividualStartTime: ['13:00', '16:00'],
  seminarReceptionIndividualEndTime: ['15:00', '17:00'],
  seminarReceptionIndividualVenueLocationCode: ['01', '01'],
  seminarReceptionIndividualVenueLocationName: ['東京', '東京'],
  seminarReceptionIndividualFlag: ['0', '1'],
  seminarReceptionIndividualName: ['予約あり', '予約なし'],
}

const initSeminarReceptionIndividualObj2 = {  // 0061, 0062
  seminarReceptionIndividualApplicationRouteId: [],
  seminarReceptionIndividualApplicationRouteName: [],
  seminarReceptionIndividualCoordinationFormerSeminarId: [],
  seminarReceptionIndividualSeminarId: [],
  seminarReceptionIndividualSeminarName: [],
  seminarReceptionIndividualPublicFlag: [],
  seminarReceptionIndividualPublicName: [],
  seminarReceptionIndividualSeminarScheduleId: [],
  seminarReceptionIndividualSeminarScheduleTitle: [],
  seminarReceptionIndividualInvisibleFlag: [],
  seminarReceptionIndividualInvisibleName: [],
  seminarReceptionIndividualEventDate: [],
  seminarReceptionIndividualStartTime: [],
  seminarReceptionIndividualEndTime: [],
  seminarReceptionIndividualVenueLocationCode: [],
  seminarReceptionIndividualVenueLocationName: [],
  seminarReceptionIndividualFlag: [],
  seminarReceptionIndividualName: [],
}

const initSeminarReceptionIndividualObj3 = {  // 0063
  seminarReceptionIndividualApplicationRouteId: ['20'],
  seminarReceptionIndividualApplicationRouteName: ['マイナビ2020'],
  seminarReceptionIndividualCoordinationFormerSeminarId: ['99999'],
  seminarReceptionIndividualSeminarId: ['10'],
  seminarReceptionIndividualSeminarName: ['クリスマスセミナーのご案内'],
  seminarReceptionIndividualPublicFlag: [''],
  seminarReceptionIndividualPublicName: [''],
  seminarReceptionIndividualSeminarScheduleId: ['536'],
  seminarReceptionIndividualSeminarScheduleTitle: ['S-20200116H1536'],
  seminarReceptionIndividualInvisibleFlag: ['0'],
  seminarReceptionIndividualInvisibleName: [''],
  seminarReceptionIndividualEventDate: ['2020/04/10'],
  seminarReceptionIndividualStartTime: ['13:00'],
  seminarReceptionIndividualEndTime: ['15:00'],
  seminarReceptionIndividualVenueLocationCode: ['01'],
  seminarReceptionIndividualVenueLocationName: ['東京'],
  seminarReceptionIndividualFlag: ['0'],
  seminarReceptionIndividualName: ['予約あり'],
}

const initSeminarReceptionIndividual = {  // 0064
  seminarReceptionIndividualJudgmentFlag: '0',
  seminarReceptionIndividualJudgmentName: '全てと一致',
  seminarReceptionIndividualObj2: initSeminarReceptionIndividualObj,
}

const initSeminarReceptionIndividual2 = {  // 0061, 0062
  seminarReceptionIndividualJudgmentFlag: '',
  seminarReceptionIndividualJudgmentName: '',
  seminarReceptionIndividualObj2: initSeminarReceptionIndividualObj2,
}

const initSeminarReceptionIndividual3 = {  // 0063
  seminarReceptionIndividualJudgmentFlag: '0',
  seminarReceptionIndividualJudgmentName: '全てと一致',
  seminarReceptionIndividualObj2: initSeminarReceptionIndividualObj3,
}

const initSeminarReception = {  // 0064
  seminarReceptionConditionJudgmentFlag: '1',
  seminarReceptionConditionJudgmentName: '各受付ごと',
  seminarReceptionWhichOneObj: initSeminarReceptionWhichOne,
  seminarReceptionIndividualObj: initSeminarReceptionIndividual,
}

const initSeminarReception2 = {  // 0061
  seminarReceptionConditionJudgmentFlag: '',
  seminarReceptionConditionJudgmentName: '',
  seminarReceptionWhichOneObj: initSeminarReceptionWhichOne,
  seminarReceptionIndividualObj: initSeminarReceptionIndividual2,
}

const initSeminarReception3 = {  // 0062
  seminarReceptionConditionJudgmentFlag: '0',
  seminarReceptionConditionJudgmentName: 'いずれかの日程',
  seminarReceptionWhichOneObj: initSeminarReceptionWhichOne2,
  seminarReceptionIndividualObj: initSeminarReceptionIndividual2,
}

const initSeminarReception4 = {  // 0063
  seminarReceptionConditionJudgmentFlag: '1',
  seminarReceptionConditionJudgmentName: '各受付ごと',
  seminarReceptionWhichOneObj: initSeminarReceptionWhichOne,
  seminarReceptionIndividualObj: initSeminarReceptionIndividual3,
}

const initWebSeminarCondition = {  // 0067
  webSeminarApplicationRouteId: ['23', '23'],
  webSeminarApplicationRouteName: ['マイナビ2023', 'マイナビ2023'],
  webSeminarCoordinationFormerWebSeminarId: ['14987', '21367'],
  webSeminarTitle: ['Webセミナー', '会社説明会'],
  webSeminarWatchingStatusConditionFlag: ['0', '2'],
  webSeminarWatchingStatusConditionName: ['未視聴', '視聴済'],
}

const initWebSeminarCondition2 = {  // 0065
  webSeminarApplicationRouteId: [],
  webSeminarApplicationRouteName: [],
  webSeminarCoordinationFormerWebSeminarId: [],
  webSeminarTitle: [],
  webSeminarWatchingStatusConditionFlag: [],
  webSeminarWatchingStatusConditionName: [],
}

const initWebSeminarCondition3 = {  // 0066
  webSeminarApplicationRouteId: ['23'],
  webSeminarApplicationRouteName: ['マイナビ2023'],
  webSeminarCoordinationFormerWebSeminarId: ['14987'],
  webSeminarTitle: ['Webセミナー'],
  webSeminarWatchingStatusConditionFlag: ['0'],
  webSeminarWatchingStatusConditionName: ['未視聴'],
}

const initWebSeminar = {  // 0067
  webSeminarConditionJudgmentFlag: '1',
  webSeminarConditionJudgmentName: '全てと一致',
  webSeminarConditionObj: initWebSeminarCondition,
}

const initWebSeminar2 = {  // 0065
  webSeminarConditionJudgmentFlag: '',
  webSeminarConditionJudgmentName: '',
  webSeminarConditionObj: initWebSeminarCondition2,
}

const initWebSeminar3 = {  // 0066
  webSeminarConditionJudgmentFlag: '1',
  webSeminarConditionJudgmentName: '全てと一致',
  webSeminarConditionObj: initWebSeminarCondition3,
}

const initEventsWhichOne = {  // 0068, 0070, 0071
  eventsWhichOneFlag: '',
  eventsWhichOneName: '',
}

const initEventsWhichOne2 = {  // 0069
  eventsWhichOneFlag: '0',
  eventsWhichOneName: '参加あり',
}

const initEventsIndividualObj = {  // 0071
  eventsIndividualApplicationRouteId: ['23', '23'],
  eventsIndividualApplicationRouteName: ['マイナビ2023', 'マイナビ2023'],
  eventsIndividualEventTypeId: ['01', '02'],
  eventsIndividualEventTypeName: ['マイナビ主催', '個社'],
  eventsIndividualCoordinationFormerEventId: ['13567', '17645'],
  eventsIndividualEventSchedule: ['2018/07/20', '2019/07/19'],
  eventsIndividualEventId: ['12', '12'],
  eventsIndividualEventName: ['インターシップ', 'インターシップ'],
  eventsIndividualPrefecturesCode: ['01', '05'],
  eventsIndividualPrefecturesName: ['東京', '大阪'],
  eventsIndividualFlag: ['0', '1'],
  eventsIndividualName: ['参加あり', '参加なし'],
  eventsIndividualAreaId: ['49','49'],
  eventsIndividualAreaName: ['40569イベント区分008', '40569イベント区分008']
}

const initEventsIndividualObj2 = {  // 0068, 0069
  eventsIndividualApplicationRouteId: [],
  eventsIndividualApplicationRouteName: [],
  eventsIndividualEventTypeId: [],
  eventsIndividualEventTypeName: [],
  eventsIndividualCoordinationFormerEventId: [],
  eventsIndividualEventSchedule: [],
  eventsIndividualEventId: [],
  eventsIndividualEventName: [],
  eventsIndividualPrefecturesCode: [],
  eventsIndividualPrefecturesName: [],
  eventsIndividualFlag: [],
  eventsIndividualName: [],
}

const initEventsIndividualObj3 = {  // 0070
  eventsIndividualApplicationRouteId: ['23'],
  eventsIndividualApplicationRouteName: ['マイナビ2023'],
  eventsIndividualEventTypeId: ['01'],
  eventsIndividualEventTypeName: ['マイナビ主催'],
  eventsIndividualCoordinationFormerEventId: ['13567'],
  eventsIndividualEventSchedule: ['2018/07/20'],
  eventsIndividualEventId: ['12'],
  eventsIndividualEventName: ['インターシップ'],
  eventsIndividualPrefecturesCode: ['01'],
  eventsIndividualPrefecturesName: ['東京'],
  eventsIndividualFlag: ['0'],
  eventsIndividualName: ['参加あり'],
}

const initeventsIndividual = {  // 0071
  eventsIndividualJudgmentFlag: '0',
  eventsIndividualJudgmentName: '全てと一致',
  eventsIndividualObj2: initEventsIndividualObj,
}

const initeventsIndividual2 = {  // 0068, 0069
  eventsIndividualJudgmentFlag: '',
  eventsIndividualJudgmentName: '',
  eventsIndividualObj2: initEventsIndividualObj2,
}

const initeventsIndividual3 = {  // 0070
  eventsIndividualJudgmentFlag: '0',
  eventsIndividualJudgmentName: '全てと一致',
  eventsIndividualObj2: initEventsIndividualObj3,
}

const initEvents = {  // 0071
  eventsConditionJudgmentFlag: '1',
  eventsConditionJudgmentName: '各受付ごと',
  eventsWhichOneObj: initEventsWhichOne,
  eventsIndividualObj: initeventsIndividual,
}

const initEvents2 = {  // 0068
  eventsConditionJudgmentFlag: '',
  eventsConditionJudgmentName: '',
  eventsWhichOneObj: initEventsWhichOne,
  eventsIndividualObj: initeventsIndividual2,
}

const initEvents3 = {  // 0069
  eventsConditionJudgmentFlag: '0',
  eventsConditionJudgmentName: 'いずれかのイベント',
  eventsWhichOneObj: initEventsWhichOne2,
  eventsIndividualObj: initeventsIndividual2,
}

const initEvents4 = {  // 0070
  eventsConditionJudgmentFlag: '1',
  eventsConditionJudgmentName: '各受付ごと',
  eventsWhichOneObj: initEventsWhichOne,
  eventsIndividualObj: initeventsIndividual3,
}

const initSendMessegeCondition = {  // 0075
  sendMessegeMessageTypeFlag: ['2', '1', '0', '0', '0'],
  sendMessegeMessageTypeName: ['サンクス', '通常', '指定しない', '指定しない', '指定しない'],
  sendMessegeSubject: ['お礼状', '最終面接', '', '', ''],
  sendMessegeSendConditionFlag: ['1', '0', '0', '0', '0'],
  sendMessegeSendConditionName: ['送付なし', '送付あり', '送付あり', '送付あり', '送付あり'],
  sendMessegeSendDateFrom: ['', '', '2020/04/01', '', '2020/04/01'],
  sendMessegeSendDateTo: ['', '', '', '2020/04/30', '2020/04/01'],
}

const initSendMessegeCondition2 = {  // 0072, 0073
  sendMessegeMessageTypeFlag: [],
  sendMessegeMessageTypeName: [],
  sendMessegeSubject: [],
  sendMessegeSendConditionFlag: [],
  sendMessegeSendConditionName: [],
  sendMessegeSendDateFrom: [],
  sendMessegeSendDateTo: [],
}

const initSendMessegeCondition3 = {  // 0074
  sendMessegeMessageTypeFlag: ['0'],
  sendMessegeMessageTypeName: ['指定しない'],
  sendMessegeSubject: [''],
  sendMessegeSendConditionFlag: ['0'],
  sendMessegeSendConditionName: ['送付あり'],
  sendMessegeSendDateFrom: ['2020/04/01'],
  sendMessegeSendDateTo: ['2020/04/30'],
}

const initSendMessege = {  // 0075
  sendMessegeConditionJudgmentFlag: '1',
  sendMessegeConditionJudgmentName: '特定のメッセージで検索',
  sendMessegeConditionObj: initSendMessegeCondition,
}

const initSendMessege2 = {  // 0072
  sendMessegeConditionJudgmentFlag: '',
  sendMessegeConditionJudgmentName: '',
  sendMessegeConditionObj: initSendMessegeCondition2,
}

const initSendMessege3 = {  // 0073
  sendMessegeConditionJudgmentFlag: '0',
  sendMessegeConditionJudgmentName: 'メッセージを何も送信していない',
  sendMessegeConditionObj: initSendMessegeCondition2,
}

const initSendMessege4 = {  // 0074
  sendMessegeConditionJudgmentFlag: '1',
  sendMessegeConditionJudgmentName: '特定のメッセージで検索',
  sendMessegeConditionObj: initSendMessegeCondition3,
}

const initSelectionFlow = {  // 0092
  selectionFlowId: ["1", "3"],
  selectionFlowName: ["新卒採用標準", "2024本採用"],
}

const initSelectionFlow401 = {  // 0093
  selectionFlowId: '2',
  selectionFlowName: '2023本採用',
}

const initMIFCheckBox1 = {
  managementItemFlagCheckBoxId: [],
  managementItemFlagCheckBoxName: [],
}

const initMIFCheckBox2 = {
  managementItemFlagCheckBoxId: ['1', '9'],
  managementItemFlagCheckBoxName: ['合格','その他'],
}

const initManagementItemCondition = {  // 0078
  recruitmentManagementFlagSettingId: ['11', '12', '13', '13', '13', '14'],
  recruitmentManagementFlagName: ['雰囲気', '適性検査', '独自テスト', '独自テスト', '独自テスト', 'アンケート内容'],
  managementItemPresenceFlag: ['0', '0', '0', '0', '0', '1'],
  managementItemPresenceName: ['値がある', '値がある', '値がある', '値がある', '値がある', '値がない'],
  managementItemTypeCode: ['01', '02', '03', '03', '03', '01'],
  managementItemTypeName: ['文字列', '選択', '数値', '数値', '数値', '文字列'],
  managementItemFlagText: ['覇気がある', '', '', '', '', ''],
  managementItemFlagFrom: ['', '', '60', '', '60', ''],
  managementItemFlagTo: ['', '', '', '100', '60', ''],
  managementItemFlagCheckBoxObj: [initMIFCheckBox1, initMIFCheckBox2, initMIFCheckBox1, initMIFCheckBox1, initMIFCheckBox1, initMIFCheckBox1],
}

const initManagementItemCondition2 = {  // 0076
  recruitmentManagementFlagSettingId: [],
  recruitmentManagementFlagName: [],
  managementItemPresenceFlag: [],
  managementItemPresenceName: [],
  managementItemTypeCode: [],
  managementItemTypeName: [],
  managementItemFlagText: [],
  managementItemFlagFrom: [],
  managementItemFlagTo: [],
  managementItemFlagCheckBoxObj: [],
}

const initManagementItemCondition3 = {  // 0077
  recruitmentManagementFlagSettingId: ['13'],
  recruitmentManagementFlagName: ['独自テスト'],
  managementItemPresenceFlag: ['0'],
  managementItemPresenceName: ['値がある'],
  managementItemTypeCode: ['03'],
  managementItemTypeName: ['数値'],
  managementItemFlagText: [''],
  managementItemFlagFrom: ['60'],
  managementItemFlagTo: ['100'],
  managementItemFlagCheckBoxObj: [initMIFCheckBox1],
}

const initManagementItemCondition4 = {  // 0101
  recruitmentManagementFlagSettingId: ['11'],
  recruitmentManagementFlagName: ['雰囲気'],
  managementItemPresenceFlag: ['0'],
  managementItemPresenceName: ['値がある'],
  managementItemTypeCode: ['01'],
  managementItemTypeName: ['文字列'],
  managementItemFlagText: [''],
  managementItemFlagFrom: [''],
  managementItemFlagTo: [''],
  managementItemFlagCheckBoxObj: [initMIFCheckBox1],
}

const initManagementItem = {  // 0078
  managementItemConditionJudgmentFlag: '0',
  managementItemConditionJudgmentName: '全てと一致',
  managementItemConditionObj: initManagementItemCondition,
}

const initManagementItem2 = {  // 0076
  managementItemConditionJudgmentFlag: '',
  managementItemConditionJudgmentName: '',
  managementItemConditionObj: initManagementItemCondition2,
}

const initManagementItem3 = {  // 0077
  managementItemConditionJudgmentFlag: '0',
  managementItemConditionJudgmentName: '全てと一致',
  managementItemConditionObj: initManagementItemCondition3,
}

const initManagementItem4 = {  // 0101
  managementItemConditionJudgmentFlag: '0',
  managementItemConditionJudgmentName: '全てと一致',
  managementItemConditionObj: initManagementItemCondition4,
}

const initGABCondition = {  // 0081
  gabLogicalName: ['受検言語','言語受検日','言語標準点','計数標準点'],
  gabPhysicalName: ['language','date','lStandard','cStandard'],
  gabTyp:['', '', '', ''],
  gabTypName: ['', '', '', ''],
  gabListFlag: ['', '', '', ''],
  gabListName: ['', '', '', ''],
  gabValueFrom: ['日本語','2020/03/01','80',''],
  gabValueTo: ['','','150','60'],
}

const initGABCondition2 = {  // 0079
  gabLogicalName: [],
  gabPhysicalName: [],
  gabTyp:[],
  gabTypName: [],
  gabListFlag: [],
  gabListName: [],
  gabValueFrom: [],
  gabValueTo: [],
}

const initGABCondition3 = {  // 0080
  gabLogicalName: ['計数標準点'],
  gabPhysicalName: ['cStandard'],
  gabTyp:[''],
  gabTypName: [''],
  gabListFlag: [''],
  gabListName: [''],
  gabValueFrom: ['60'],
  gabValueTo: ['60'],
}

const initTApplicationRoute = {  // 0087
  targetApplicationRouteFlag: ['',''],
  targetApplicationRouteId: ['23','99'],
  targetApplicationRouteName: ['マイナビ2023','Indeed'],
}

const initTApplicationRoute2 = {  // 0082,0088
  targetApplicationRouteFlag: [],
  targetApplicationRouteId: [],
  targetApplicationRouteName: [],
}

const initTApplicationRoute3 = {  // 0083-0086
  targetApplicationRouteFlag: [''],
  targetApplicationRouteId: ['23'],
  targetApplicationRouteName: ['マイナビ2023'],
}

const initTagTxt = {  // 0102
  tagSettingId: [],
  tagName: [],
}

const initTagTxt1 = {  // 0103
  tagSettingId: ['1'],
  tagName: ['キーワード１'],
}

const initTagTxt2 = {  // 0104
  tagSettingId: ['1', '2'],
  tagName: ['キーワード１', 'キーワード２'],
}

const initSearchCriteria351 = {
  applicantId: ["00001111", "12345678", "45678912"],       // 0009
  name: ["山田", "鈴木", "タロウ"],                          // 0012
  sexObj: initSex,                                         // 0014
  phoneNumber: '0312345678',                               // 0017
  mailAddress: 'yamada@',                                  // 0019
  residenceObj: initResidence,                             // 0097
  languageSkills: ['英語', 'スペイン語'],                    // 0091
  schoolName: ['東京', '商科'],                             // 0025
  eventDateFrom: '2019/03/01',                             // 0029
  eventDateTo: '2019/03/31',
  scoutApplicationRouteObj: initScoutApplicationRoute,     // 0032
  scoutObj: initScout,
  schoolGroupObj: initSchoolGroup,                         // 0048
  schoolDetailsObj: initSchoolDetails,
  undergraduateDepartmentObj: initUndergraduateDepartment,
  entryDateObj: initEntryDate,                             // 0051
  entryPresenceObj: initEntryPresence,                     // 0054
  questionnaireResponseObj: initQuestionnaireResponse,     // 0057
  seminarReceptionObj: initSeminarReception,               // 0064
  webSeminarObj: initWebSeminar,                           // 0067
  eventsObj: initEvents,                                   // 0071
  sendMessegeObj: initSendMessege,                         // 0075
  selectionFlowObj: initSelectionFlow,                     // 0092
  managementItemObj: initManagementItem,                   // 0078
  gabObj: initGABCondition,                                // 0081
  firstEntryDateFrom: '2020/03/01',                        // 0085
  firstEntryDateTo: '2020/03/31',
  targetApplicationRouteObj: initTApplicationRoute3,
}

const initSearchCriteria351_2 = {
  applicantId: [],                                         // 0007
  name: [],                                                // 0010
  sexObj: initSex2,                                        // 0013
  phoneNumber: '',                                         // 0016
  mailAddress: '',                                         // 0018
  residenceObj: initResidence2,                            // 0095
  languageSkills: [],                                      // 0089
  schoolName: [],                                          // 0023
  eventDateFrom: '',                                       // 0026
  eventDateTo: '',
  scoutApplicationRouteObj: initScoutApplicationRoute2,    // 0034
  scoutObj: initScout,
  schoolGroupObj: initSchoolGroup2,                        // 0043
  schoolDetailsObj: initSchoolDetails,
  undergraduateDepartmentObj: initUndergraduateDepartment2,
  entryDateObj: initEntryDate2,                            // 0049
  entryPresenceObj: initEntryPresence2,                    // 0052
  questionnaireResponseObj: initQuestionnaireResponse2,    // 0055
  seminarReceptionObj: initSeminarReception2,              // 0061
  webSeminarObj: initWebSeminar2,                          // 0065
  eventsObj: initEvents2,                                  // 0068
  sendMessegeObj: initSendMessege2,                        // 0072
  selectionFlowObj: initSelectionFlow,
  managementItemObj: initManagementItem2,                  // 0076
  gabObj: initGABCondition2,                               // 0079
  firstEntryDateFrom: '2020/03/01',                        // 0083
  firstEntryDateTo: '',
  targetApplicationRouteObj: initTApplicationRoute3,
}

const initSearchCriteria351_3 = {
  applicantId: ["00001111"],                               // 0008
  name: ["山田"],                                          // 0011
  sexObj: initSex3,                                        // 0015
  phoneNumber: '0312345678',
  mailAddress: 'yamada@',
  residenceObj: initResidence3,                           // 0096
  languageSkills: ['英語'],                                // 0090
  schoolName: ['東京'],                                    // 0024
  eventDateFrom: '2019/03/01',                             // 0027
  eventDateTo: '',
  scoutApplicationRouteObj: initScoutApplicationRoute,     // 0033
  scoutObj: initScout2,
  schoolGroupObj: initSchoolGroup3,                        // 0044
  schoolDetailsObj: initSchoolDetails,
  undergraduateDepartmentObj: initUndergraduateDepartment2,
  entryDateObj: initEntryDate3,                            // 0050
  entryPresenceObj: initEntryPresence3,                    // 0053
  questionnaireResponseObj: initQuestionnaireResponse3,    // 0056
  seminarReceptionObj: initSeminarReception3,              // 0062
  webSeminarObj: initWebSeminar3,                          // 0066
  eventsObj: initEvents3,                                  // 0069
  sendMessegeObj: initSendMessege3,                        // 0073
  selectionFlowObj: initSelectionFlow,
  managementItemObj: initManagementItem3,                  // 0077
  gabObj: initGABCondition3,                               // 0080
  firstEntryDateFrom: '',                                  // 0084
  firstEntryDateTo: '2020/03/01',
  targetApplicationRouteObj: initTApplicationRoute3,
}

const initSearchCriteria351_4 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  sexObj: initSex,
  phoneNumber: '0312345678',
  mailAddress: 'yamada@',
  residenceObj: initResidence4,                            // 0098
  languageSkills: ['英語', 'スペイン語'],
  schoolName: ['東京', '商科'],
  eventDateFrom: '',                                       // 0028
  eventDateTo: '2019/03/01',
  scoutApplicationRouteObj: initScoutApplicationRoute2,    // 0031
  scoutObj: initScout2,
  schoolGroupObj: initSchoolGroup,                         // 0045
  schoolDetailsObj: initSchoolDetails2,
  undergraduateDepartmentObj: initUndergraduateDepartment2,
  entryDateObj: initEntryDate,
  entryPresenceObj: initEntryPresence,
  questionnaireResponseObj: initQuestionnaireResponse,
  seminarReceptionObj: initSeminarReception4,              // 0063
  webSeminarObj: initWebSeminar,
  eventsObj: initEvents4,                                  // 0070
  sendMessegeObj: initSendMessege4,                        // 0074
  selectionFlowObj: initSelectionFlow,
  managementItemObj: initManagementItem,
  gabObj: initGABCondition,
  firstEntryDateFrom: '',                                  // 0082
  firstEntryDateTo: '',
  targetApplicationRouteObj: initTApplicationRoute2,
}

const initSearchCriteria351_5 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  sexObj: initSex,
  phoneNumber: '0312345678',
  mailAddress: 'yamada@',
  residenceObj: initResidence5,                            // 0099
  languageSkills: ['英語', 'スペイン語'],
  schoolName: ['東京', '商科'],
  eventDateFrom: '2019/03/01',                             // 0030
  eventDateTo: '2019/03/01',
  scoutApplicationRouteObj: initScoutApplicationRoute,
  scoutObj: initScout,
  schoolGroupObj: initSchoolGroup,                         // 0046
  schoolDetailsObj: initSchoolDetails3,
  undergraduateDepartmentObj: initUndergraduateDepartment2,
  entryDateObj: initEntryDate,
  entryPresenceObj: initEntryPresence,
  questionnaireResponseObj: initQuestionnaireResponse,
  seminarReceptionObj: initSeminarReception,
  webSeminarObj: initWebSeminar,
  eventsObj: initEvents,
  sendMessegeObj: initSendMessege,
  selectionFlowObj: initSelectionFlow,
  managementItemObj: initManagementItem,
  gabObj: initGABCondition,
  firstEntryDateFrom: '2020/03/01',                        // 0086
  firstEntryDateTo: '2020/03/01',
  targetApplicationRouteObj: initTApplicationRoute3,
}

const initSearchCriteria351_6 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  sexObj: initSex,
  phoneNumber: '0312345678',
  mailAddress: 'yamada@',
  residenceObj: initResidence6,                            // 0100
  languageSkills: ['英語', 'スペイン語'],
  schoolName: ['東京', '商科'],
  eventDateFrom: '2019/03/01',
  eventDateTo: '2019/03/01',
  scoutApplicationRouteObj: initScoutApplicationRoute,
  scoutObj: initScout,
  schoolGroupObj: initSchoolGroup,                         // 0047
  schoolDetailsObj: initSchoolDetails,
  undergraduateDepartmentObj: initUndergraduateDepartment3,
  entryDateObj: initEntryDate,
  entryPresenceObj: initEntryPresence,
  questionnaireResponseObj: initQuestionnaireResponse,
  seminarReceptionObj: initSeminarReception,
  webSeminarObj: initWebSeminar,
  eventsObj: initEvents,
  sendMessegeObj: initSendMessege,
  selectionFlowObj: initSelectionFlow,
  managementItemObj: initManagementItem,
  gabObj: initGABCondition,
  firstEntryDateFrom: '',                                  // 0087
  firstEntryDateTo: '',
  targetApplicationRouteObj: initTApplicationRoute,
}

const initSearchCriteria351_7 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  sexObj: initSex,
  phoneNumber: '0312345678',
  mailAddress: 'yamada@',
  residenceObj: initResidence,
  languageSkills: ['英語', 'スペイン語'],
  schoolName: ['東京', '商科'],
  eventDateFrom: '2019/03/01',
  eventDateTo: '2019/03/01',
  scoutApplicationRouteObj: initScoutApplicationRoute,
  scoutObj: initScout,
  schoolGroupObj: initSchoolGroup,                         // 0042
  schoolDetailsObj: initSchoolDetails,
  undergraduateDepartmentObj: initUndergraduateDepartment2,
  entryDateObj: initEntryDate,
  entryPresenceObj: initEntryPresence,
  questionnaireResponseObj: initQuestionnaireResponse,
  seminarReceptionObj: initSeminarReception,
  webSeminarObj: initWebSeminar,
  eventsObj: initEvents,
  sendMessegeObj: initSendMessege,
  selectionFlowObj: initSelectionFlow,
  managementItemObj: initManagementItem,
  gabObj: initGABCondition,
  firstEntryDateFrom: '2020/03/01',                        // 0088
  firstEntryDateTo: '2020/03/31',
  targetApplicationRouteObj: initTApplicationRoute2,
}

const initSearchCriteria401 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  ageFrom: '22',                                           // 0038
  ageTo: '40',
  sexObj: initSex,
  mailAddress: 'yamada@',
  educationalBackground: ['東京都立毎日高等学校', '北海道大学'],  // 0022
  languageSkills: ['英語', 'スペイン語'],
  applicationTypeObj: initApplicationType,                 // 0041
  questionnaireObj: initQuestionnaire,                     // 0060
  selectionFlowObj: initSelectionFlow401,                  // 0093
  managementItemObj: initManagementItem,
  tagTxtObj: initTagTxt,
}

const initSearchCriteria401_2 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  ageFrom: '',                                             // 0035
  ageTo: '',
  sexObj: initSex,
  mailAddress: 'yamada@',
  educationalBackground: [],                               // 0020
  languageSkills: ['英語', 'スペイン語'],
  applicationTypeObj: initApplicationType2,                // 0040
  questionnaireObj: initQuestionnaire2,                    // 0058
  selectionFlowObj: initSelectionFlow401,
  managementItemObj: initManagementItem,
  tagTxtObj: initTagTxt,
}

const initSearchCriteria401_3 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  ageFrom: '22',                                           // 0036
  ageTo: '',
  sexObj: initSex,
  mailAddress: 'yamada@',
  educationalBackground: ['東京都立毎日高等学校'],           // 0021
  languageSkills: ['英語', 'スペイン語'],
  applicationTypeObj: initApplicationType,
  questionnaireObj: initQuestionnaire3,                    // 0059
  selectionFlowObj: initSelectionFlow401,
  managementItemObj: initManagementItem,
  tagTxtObj: initTagTxt,
}

const initSearchCriteria401_4 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  ageFrom: '',                                             // 0037
  ageTo: '22',
  sexObj: initSex,
  mailAddress: 'yamada@',
  educationalBackground: ['東京都立毎日高等学校', '北海道大学'],
  languageSkills: ['英語', 'スペイン語'],
  applicationTypeObj: initApplicationType,
  questionnaireObj: initQuestionnaire,
  selectionFlowObj: initSelectionFlow401,
  managementItemObj: initManagementItem,
  tagTxtObj: initTagTxt,
}

const initSearchCriteria401_5 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  ageFrom: '22',                                           // 0039
  ageTo: '22',
  sexObj: initSex,
  mailAddress: 'yamada@',
  educationalBackground: ['東京都立毎日高等学校', '北海道大学'],
  languageSkills: ['英語', 'スペイン語'],
  applicationTypeObj: initApplicationType,
  questionnaireObj: initQuestionnaire,
  selectionFlowObj: initSelectionFlow401,
  managementItemObj: initManagementItem,
  tagTxtObj: initTagTxt,                                   // 0102
}

const initSearchCriteria401_6 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  ageFrom: '22',
  ageTo: '22',
  sexObj: initSex,
  mailAddress: 'yamada@',
  educationalBackground: ['東京都立毎日高等学校', '北海道大学'],
  languageSkills: ['英語', 'スペイン語'],
  applicationTypeObj: initApplicationType,
  questionnaireObj: initQuestionnaire,
  selectionFlowObj: initSelectionFlow401,
  managementItemObj: initManagementItem4,                  // 0101
  tagTxtObj: initTagTxt1,                                  // 0103
}

const initSearchCriteria401_7 = {
  applicantId: ["00001111", "12345678", "45678912"],
  name: ["山田", "鈴木", "タロウ"],
  ageFrom: '22',
  ageTo: '22',
  sexObj: initSex,
  mailAddress: 'yamada@',
  educationalBackground: ['東京都立毎日高等学校', '北海道大学'],
  languageSkills: ['英語', 'スペイン語'],
  applicationTypeObj: initApplicationType,
  questionnaireObj: initQuestionnaire,
  selectionFlowObj: initSelectionFlow401,
  managementItemObj: initManagementItem,
  tagTxtObj: initTagTxt2,                                  // 0104
}

interface InitialValuesType {
  searchCriteria351: SearchCriteria351
  searchCriteria401: SearchCriteria401
}

const initialState: InitialValuesType = {
  searchCriteria351: initSearchCriteria351,
  searchCriteria401: initSearchCriteria401,
}

const applicantInfoManagementSlice = createSlice({
  name: 'applicantInfoManagement',
  initialState,
  reducers: {
  },
})

export const {
} = applicantInfoManagementSlice.actions
export default applicantInfoManagementSlice.reducer
