import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {initialValues} from 'pages/MCAZS210/formConfig'
import { MCAZS210InitRequest } from 'types/MCAZS210/MCAZS210InitRequest'
import { MCAZS210DownloadFileRequest } from 'types/MCAZS210/MCAZS210DownloadFileRequest'
import { MCAZS210DownloadCsvRequest } from 'types/MCAZS210/MCAZS210DownloadCsvRequest'
import { MCAZS150InterviewScheduleRequest } from 'types/MCAZS150/MCAZS150InterviewScheduleRequest'
import { MCBAS020InterviewScheduleRequest } from 'types/MCBAS020/MCBAS020InterviewScheduleRequest'

export interface McbMessageDetailInfos {
    attachedList: McbAttachedOption[] // ホームページ
    jobSeekerInfo: JobSeekersInfo[]
    jobSeekersInfoCountFlag: string
    selectMsgDetail: McbMessageDetail
    attachmentFileList: McbAttachmentFile[] // 添付ファイル
    downloadAuthority: boolean // ダウンロード権限
    dateTimeDetermine: string // 提出締切
    searchCriteriaInfo: SearchCriteriaInfoResult
    // #MCB1.5次開発 #72395 START
    senderAddressFix: string //  送信元メールアドレス固定値
    // #MCB1.5次開発 #72395 END
}

export interface McbMessageDetail {
    
    mcbMessageId: string // MCBメッセージID
    messageTypeCode: string // メッセージ種別（code）
    sendAndReceiveDivision: string // 送受信区分
    sendTimeShow: string // 送受信日時
    sendPlanTime: string // 送信予定日時
    companyContractMediaId: string // 企業契約媒体ID
    entryId: string // エントリーID
    destinationDivision: string // 送信先区分
    senderCompanyName: string // 送信元会社名
    senderMailAddress: string // 送信元メールアドレス
    notificationMailAddress: string // 通知先メールアドレス
    barcodeDisplayFlag: string // バーコード表示フラグ
    subject: string // 件名
    body: string // 本文
    sendTimeFlag: string  
    readFlag: string // 既読フラグ
    sysVersionNumber: string // sysバージョン番号
    subjectForSending:string // 送信用件名
    bodyForSending:string // 送信用本文
    bulkSendFlag:string // 一括送信フラグ
    convertingBatchCompleteFlag:string // 変換バッチ完了フラグ
    status:string // ステータス
    messageSendType: string // メッセージ送信種別
    submissionContentName: string // 提出物内容名
    submissionContentNameStudent: string // 提出物内容名（学生画面表示用）
    submissionEntrySheetFlag: string // 提出物エントリシートフラグ
    submissionResumeFlag: string // 提出物履歴書フラグ
    submissionResearchSummaryFlag: string // 提出物研究概要書フラグ
    submissionOriginalQuestionFlag: string // 提出物オリジナル設問フラグ
    submissionFileSubmissionFlag: string // 提出物ファイル提出フラグ
    resubmitReceptionFlag : string // 再提出受付フラグ
/*---------------------------------------------------------------------------------*/
    unsubscribeFlag: string // 退会済みフラグ 
    buttonDisableFlag: string // ボタンを活性化フラグ 0:非活性化 1:活性化
    buttonDisplayFlag: string // ボタンを表示フラグ 0:非表示 1:表示
    attachedOptionTitle: string // 添付オプションタイトル   
}

export interface McbAttachedOption {
    attachedOptionType: string //添付オプション種別
    sequence: number // 連番
    homePageTitle: string // ホームページタイトル
    homePageUrl: string // ホームページURL
}

export interface McbAttachmentFile {
    attachmentName: string
    fileManagementId: string
}

export interface JobSeekersInfo {
    memberType: string // 会員種別
    readableFlag: string // 求職者分類
    messageType: string // メッセージ種別
    messageSendType: string // メッセージ送信種別
    fullName: string // 氏名
    jobSeekerIdForDisplay: string // 表示用求職者ID
    jobSeekerId: string // 求職者ID
    entryId: string // エントリーID
    readFlag: string // 未読/既読
    sendingResult: string // 送信失敗（code）
    unsubscribeTime: string // 退会済み（code）
    schoolName: string // 大学
    facultyName: string // 学部
    subjectName: string // 学科
    entryType: string // 応募種別
    entryReceptionTime: string // 応募日時
    progressNameCode: string // 送受信時選考ステップ
    decisionDivision: string // 判定区分（code）
    selectionName: string // 選考フロー
    labelFlag: string // 背景色
    mcbMemberId: number // MCB会員ID;
    mcbUseStatus: string // MCB利用ステータス;
    selectionManagementId: string // 選考管理ID
    applicantsListInfo: ApplicantsListInfo[]  // 選考情報リスト
}

interface ApplicantsListInfo {
    jobSeekerId: string // 求職者ID
    entryId: string // エントリーID
    selectionManagementId: string // 選考管理ID
}

interface SearchCriteriaInfoResult {
    searchCriteriaName: string // 検索条件名
    searchCriteria: string // 検索条件
}

const initialState: McbMessageDetailInfos = initialValues

export interface detailRequest {
    flag: string
    request: MCAZS210InitRequest
}

export interface detailRequestEx {
    flag: string
    request: MCAZS210InitRequest
    request020: MCBAS020InterviewScheduleRequest
}

const mcbMessageDetailSlice = createSlice({
  name: 'mcbMessageDetail',
  initialState,
  reducers: {
    searchMcbMessageDetail(state, action: PayloadAction<detailRequest>) {
        return state;
      },
    searchMessageDetailInterview(state, action: PayloadAction<detailRequestEx>) {
        return state;
      },
    setMcbMessageDetail(state, action: PayloadAction<McbMessageDetailInfos>) {
        state = action.payload;
        return state;
    },
    attachmentFileDownload(state, action: PayloadAction<MCAZS210DownloadFileRequest>) {
        return state;
    },
    csvFileDownload(state, action: PayloadAction<MCAZS210DownloadCsvRequest>) {
        return state;
    },
    sysVersionNumberCheck(state,action: PayloadAction<MCAZS150InterviewScheduleRequest>) {
        return state;
    }
  },
})

export const {
    searchMcbMessageDetail,
    searchMessageDetailInterview,
    setMcbMessageDetail,
    attachmentFileDownload,
    csvFileDownload,
    sysVersionNumberCheck,
} = mcbMessageDetailSlice.actions
export default mcbMessageDetailSlice.reducer
