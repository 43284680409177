/**
 * MCAGS010UpdateRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({
  // 不採用ステータス変更設定
  autoRejection: yup
    .string()
    .required(validationMessageSet.required)
    .test('isFlag', validationMessageSet.flag, (value: any) =>
      miscRegexSet.flag.test(value)
    ),

  // 書類選考不採用通知送信予約設定
  // 採用管理区分が中途（転職）の場合は必須
  rejectionLetterSendReservationApplicant: yup
    .string()
    .test(
      'rejectionLetterSendReservationApplicant',
      validationMessageSet.flag,
      function(value: any) {
        return (
          this.parent.recruitmentManagementDivision === '1' ||
          miscRegexSet.flag.test(value)
        )
      }
    )
    .test(
      'rejectionLetterSendReservationApplicant',
      validationMessageSet.required,
      function(value: string) {
        return (
          this.parent.recruitmentManagementDivision === '1' ||
          (value !== undefined && value !== '')
        )
      }
    ),

  // 書類選考不採用通知メッセージテンプレート設定ID
  // 採用管理区分が中途（転職）の場合は必須
  // 次期9月対応 #58576 start
  templateIdApplicant: yup
    .number()
    .required()
    .min(1, validationMessageSet.required),
  // 次期9月対応 #58576 end

  // 面接不採用通知送信予約設定
  // 採用管理区分が中途（転職）の場合は必須
  rejectionLetterSendReservationInterview: yup
    .string()
    .test(
      'rejectionLetterSendReservationInterview',
      validationMessageSet.flag,
      function(value: any) {
        return (
          this.parent.recruitmentManagementDivision === '1' ||
          miscRegexSet.flag.test(value)
        )
      }
    )
    .test(
      'rejectionLetterSendReservationInterview',
      validationMessageSet.required,
      function(value: string) {
        return (
          this.parent.recruitmentManagementDivision === '1' ||
          (value !== undefined && value !== '')
        )
      }
    ),

  // 面接不採用通知メッセージテンプレート設定ID
  // 採用管理区分が中途（転職）の場合は必須
  // 次期9月対応 #58576 start
  templateIdInterview: yup
    .number()
    .required()
    .min(1, validationMessageSet.required),
  // 次期9月対応 #58576 end

  // sysバージョン番号
  sysVersionNumber: yup.string().required(validationMessageSet.required),
})
