
export interface MCAXS260BulkOperationMaxProcessInfoDto {
    // 処理上限可否
    maxProcessType:number;

    // 処理上限値
    maxProcessCount:number;
  }

  export interface MCAXS260InitResultDto {
    // 適性テスト有効フラグ有無
    data:string;

    // 処理上限情報_管理項目
    maxProcessInfoManagementItem:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_コメント
     maxProcessInfoComment:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_優先度
    maxProcessInfoPriority:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_タグ
    maxProcessInfoTag:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_対象外
    maxProcessInfoExclude:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_対象外から戻す
    maxProcessInfoRestore:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_選考フロー追加
    maxProcessInfoAddSelection:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_選考フロー変更
     maxProcessInfoChangeSelection:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_応募経路追加
    maxProcessInfoAddMedia:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_応募経路変更
    maxProcessInfoChangeMedia:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_メッセージ送信
     maxProcessInfoSendMessage:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_関係者にシェア
    maxProcessInfoMailShare:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_削除する
    maxProcessInfoDelete:MCAXS260BulkOperationMaxProcessInfoDto;

    // 処理上限情報_一括予約更新
    maxProcessInfoUpdateAppointment:MCAXS260BulkOperationMaxProcessInfoDto,

    // MCBリプレース start
    // 処理上限情報_処理上限情報_MCBメッセージ
    maxProcessInfoSendMcbMessage: MCAXS260BulkOperationMaxProcessInfoDto,
    // MCBリプレース end 

    // 次期開発9月 #47314 start
    // 性別表更新最大処理人数
    maxPersonCountSex:number;

    // 管理項目表更新最大処理人数
    maxPersonCountRecruitementManageFlag:number;

    // 表更新ページ人数
    pagePersonCount:number;

    // 管理項目選択上限
    recruitementManagentFlagSelectCount:number;
    // 次期開発9月 #47314 end

    // 処理上限情報_CSV出力(就職)
    maxProcessInfoCsvOutputSyusyoku:MCAXS260BulkOperationMaxProcessInfoDto,
  }

  export const MCAXS260InitValue ={
    // 処理上限可否
    maxProcessType:0,

    // 処理上限値
    maxProcessCount:0,
  }

  export const MCAXS260InitResultValue = {
    // 適性テスト有効フラグ有無
    data:'',

    // 処理上限情報_管理項目
    maxProcessInfoManagementItem:MCAXS260InitValue,

    // 処理上限情報_コメント
     maxProcessInfoComment:MCAXS260InitValue,

    // 処理上限情報_優先度
    maxProcessInfoPriority:MCAXS260InitValue,

    // 処理上限情報_タグ
    maxProcessInfoTag:MCAXS260InitValue,

    // 処理上限情報_対象外
    maxProcessInfoExclude:MCAXS260InitValue,

    // 処理上限情報_対象外から戻す
    maxProcessInfoRestore:MCAXS260InitValue,

    // 処理上限情報_選考フロー追加
    maxProcessInfoAddSelection:MCAXS260InitValue,

    // 処理上限情報_選考フロー変更
     maxProcessInfoChangeSelection:MCAXS260InitValue,

    // 処理上限情報_応募経路追加
    maxProcessInfoAddMedia:MCAXS260InitValue,

    // 処理上限情報_応募経路変更
    maxProcessInfoChangeMedia:MCAXS260InitValue,

    // 処理上限情報_メッセージ送信
     maxProcessInfoSendMessage:MCAXS260InitValue,

    // 処理上限情報_関係者にシェア
    maxProcessInfoMailShare:MCAXS260InitValue,

    // 処理上限情報_削除する
    maxProcessInfoDelete:MCAXS260InitValue,

    // 処理上限情報_一括予約更新
    maxProcessInfoUpdateAppointment:MCAXS260InitValue,

    // MCBリプレース start
    // 処理上限情報_処理上限情報_MCBメッセージ
    maxProcessInfoSendMcbMessage: MCAXS260InitValue,
    // MCBリプレース end 

    // 次期開発9月 #47314 start
    // 性別表更新最大処理人数
    maxPersonCountSex:0,

    // 管理項目表更新最大処理人数
    maxPersonCountRecruitementManageFlag:0,

    // 表更新ページ人数
    pagePersonCount:0,

    // 管理項目選択上限
    recruitementManagentFlagSelectCount:0,
    // 次期開発9月 #47314 end

    // 処理上限情報_CSV出力(就職)
    maxProcessInfoCsvOutputSyusyoku:MCAXS260InitValue,

  }
