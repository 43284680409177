/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAXS230InitRequest } from 'types/MCAXS230/MCAXS230InitRequest'
import {
  MCAXS230OutputDto,
  mcaxs230OutputDto,
  MCAXS230PostalCodeMstSelPrefecturesNameOutDto,
  MCAXS230DepartmentOutDto,
  MCAXS230SubInforDagMstSelFacultySubjectNameOutDto,
  MCAXS230CollegeMstSelSchoolNameOutDto,
} from 'pages/MCAXS230/formConfig'

import {
  MCAXS201OutputDto,
  mcaxs201OutputDto,
  MCAXS201PostalCodeMstSelPrefecturesNameOutDto,
  MCAXS201DepartmentOutDto,
  MCAXS201SubInforDagMstSelFacultySubjectNameOutDto,
  MCAXS201CollegeMstSelSchoolNameOutDto,
  MCAXS201Init,
  mcaxs201Commit,
} from 'pages/MCAXS201/formConfig'
import { MCAXS201AddressRequest } from 'types/MCAXS201/MCAXS201AddressRequest'
import {
  MCAXS211Init,
  MCAXS211Insert,
  mcaxs211Init,
  mcaxs211Insert,
  MCAXS211Image,
  mcaxs211Image,
} from 'pages/MCAXS211/formConfig'
import { MCAXS211InitRequest } from 'types/MCAXS211/MCAXS211InitRequest'
import { MCAXS211CreateRequest } from 'types/MCAXS211/MCAXS211CreateRequest'
import {
  MCAXS650Init,
  MCAXS650Update,
  MCAXS650Image,
  MCAXS650InitReturn,
  mcaxs650Init,
  mcaxs650Update,
  mcaxs650Image,
  mcaxs650InitReturn,
} from 'pages/MCAXS650/formConfig'
import { MCAXS650InitRequest } from 'types/MCAXS650/MCAXS650InitRequest'
import { MCAXS650ApplicantInfoRequest } from 'types/MCAXS650/MCAXS650ApplicantInfoRequest'
import { MCAXS191NameRequest } from 'types/MCAXS191/MCAXS191NameRequest'
import { MCAXS230AddressRequest } from 'types/MCAXS230/MCAXS230AddressRequest'
import { MCAXS230ApplicantInfoRequest } from 'types/MCAXS230/MCAXS230ApplicantInfoRequest'
import { MCAXS230SchoolDetailedDivisionInfoRequest } from 'types/MCAXS230/MCAXS230SchoolDetailedDivisionInfoRequest'
import { MCAXS191Init, mcaxs191Init } from 'pages/MCAXS191/formConfig'
import { MCAXS201ApplicantInfoRequest } from 'types/MCAXS201/MCAXS201ApplicantInfoRequest'
import { MCAXS201SchoolDetailedDivisionInfoRequest } from 'types/MCAXS201/MCAXS201SchoolDetailedDivisionInfoRequest'
import { MCAXS201SchoolInfoRequest } from 'types/MCAXS201/MCAXS201SchoolInfoRequest'
import { MCAXS230SchoolInfoRequest } from 'types/MCAXS230/MCAXS230SchoolInfoRequest'
import { magiContants } from 'utils/contants'
import { getDynamicCode } from 'utils/code'

export interface JobSeekerInfo {
  MCAXS191Init: MCAXS191Init
  MCAXS201Init: MCAXS201Init
  MCAXS201OutputDtoResult: MCAXS201OutputDto
  MCAXS201PostalCodeMstSelPrefecturesNameOutDtoResult: MCAXS201PostalCodeMstSelPrefecturesNameOutDto[]
  MCAXS201DepartmentOutDtoResult: MCAXS201DepartmentOutDto[]
  MCAXS201SubInforDagMstSelFacultySubjectNameOutDto: MCAXS201SubInforDagMstSelFacultySubjectNameOutDto[]
  MCAXS201CollegeMstSelSchoolNameOutDtoResult: MCAXS201CollegeMstSelSchoolNameOutDto[]
  MCAXS230OutputDtoResult: MCAXS230OutputDto
  MCAXS211InitResult: MCAXS211Init
  MCAXS211InsertResult: MCAXS211Insert
  MCAXS211ImageResult: MCAXS211Image
  MCAXS650InitResult: MCAXS650Init
  MCAXS650UpdateResult: MCAXS650Update
  MCAXS650ImageResult: MCAXS650Image
  MCAXS650InitReturnResult: MCAXS650InitReturn
  MCAXS230PostalCodeMstSelPrefecturesNameOutDtoResult: MCAXS230PostalCodeMstSelPrefecturesNameOutDto[]
  MCAXS230DepartmentOutDtoResult: MCAXS230DepartmentOutDto[]
  MCAXS230SubInforDagMstSelFacultySubjectNameOutDtoResult: MCAXS230SubInforDagMstSelFacultySubjectNameOutDto[]
  MCAXS230CollegeMstSelSchoolNameOutDtoResult: MCAXS230CollegeMstSelSchoolNameOutDto[]
  MCAXS230InitDataResult: MCAXS230OutputDto
}

const initialState: JobSeekerInfo = {
  MCAXS191Init: mcaxs191Init,
  MCAXS201Init: mcaxs201Commit,
  MCAXS201OutputDtoResult: mcaxs201OutputDto,
  MCAXS201PostalCodeMstSelPrefecturesNameOutDtoResult: [],
  MCAXS201DepartmentOutDtoResult: [],
  MCAXS201SubInforDagMstSelFacultySubjectNameOutDto: [],
  MCAXS201CollegeMstSelSchoolNameOutDtoResult: [],
  MCAXS230OutputDtoResult: mcaxs230OutputDto,
  MCAXS211InitResult: mcaxs211Init,
  MCAXS211InsertResult: mcaxs211Insert,
  MCAXS211ImageResult: mcaxs211Image,
  MCAXS650InitResult: mcaxs650Init,
  MCAXS650UpdateResult: mcaxs650Update,
  MCAXS650ImageResult: mcaxs650Image,
  MCAXS650InitReturnResult: mcaxs650InitReturn,
  MCAXS230PostalCodeMstSelPrefecturesNameOutDtoResult: [],
  MCAXS230DepartmentOutDtoResult: [],
  MCAXS230SubInforDagMstSelFacultySubjectNameOutDtoResult: [],
  MCAXS230CollegeMstSelSchoolNameOutDtoResult: [],
  MCAXS230InitDataResult: mcaxs230OutputDto,
}

const jobSeekerInfoSlice = createSlice({
  name: 'jobSeekerInfo',
  initialState,
  reducers: {
    // 離脱チェック画面値保存
    mcaxs191BackReducer(state, action: PayloadAction<MCAXS191Init>) {
      state.MCAXS191Init = action.payload
      return state
    },
    // MCAXS191_応募者氏名入力
    // 初期表示
    mcaxs191InitReducer(state, action: PayloadAction<MCAXS191Init>) {
      state.MCAXS191Init = action.payload
      return state
    },
    // MCAXS191_応募者氏名入力
    // "次へ進む"ボタン押下時とF5押下
    mcaxs201InitReducer(state, action: PayloadAction<MCAXS191NameRequest>) {
      return state
    },
    // 郵便番号から自動入力する
    mcaxs201AutoInputReducer(
      state,
      action: PayloadAction<MCAXS201AddressRequest>
    ) {
      return state
    },
    // 郵便番号の設定する
    setMcaxs201AutoInputReducer(
      state,
      action: PayloadAction<MCAXS201PostalCodeMstSelPrefecturesNameOutDto[]>
    ) {
      state.MCAXS201PostalCodeMstSelPrefecturesNameOutDtoResult = action.payload
    },
    // 一覧を設定
    setNameInputNextReducer(state, action: PayloadAction<MCAXS201OutputDto>) {
      state.MCAXS201OutputDtoResult = action.payload
      // state.MCAXS201Init = mcaxs201Commit
      return state
    },

    // 一覧を設定
    setMcaxs201InitReducer(state, action: PayloadAction<MCAXS201Init>) {
      state.MCAXS201Init = action.payload
      return state
    },

    // MCAXS201_専門学校_専攻分野ボタン押下
    mcaxs201VocationalSchoolMajorSelectReducer(
      state,
      action: PayloadAction<MCAXS201SchoolDetailedDivisionInfoRequest>
      ) {
      return state
    },
    // MCAXS201_専門学校_専攻分野設定
    setMcaxs201VocationalSchoolMajorSelectReducer(
      state,
      action: PayloadAction<MCAXS201DepartmentOutDto[]>
    ) {
      state.MCAXS201DepartmentOutDtoResult = action.payload
    },
    // MCAXS201_大学院_研究科・専攻名ボタン押下する
    mcaxs201GraduateSchoolDepartmentReducer(
      state,
      action: PayloadAction<MCAXS201SchoolDetailedDivisionInfoRequest>
    ) {
      state.MCAXS201SubInforDagMstSelFacultySubjectNameOutDto = []
      return state
    },
    // MCAXS201_大学_学部・学科名ボタン押下する
    mcaxs201GraduateSchoolDepartmentSeelectReducer(
      state,
      action: PayloadAction<MCAXS201SchoolDetailedDivisionInfoRequest>
    ) {
      state.MCAXS201SubInforDagMstSelFacultySubjectNameOutDto = []
      return state
    },
    // MCAXS201_短大_学科名ボタン押下する
    mcaxs201JuniorCollegeSubjectSelectReducer(
      state,
      action: PayloadAction<MCAXS201SchoolDetailedDivisionInfoRequest>
    ) {
      state.MCAXS201SubInforDagMstSelFacultySubjectNameOutDto = []
      return state
    },
    // MCAXS201_学校詳細区分設定
    setMcaxs201GraduateSchoolDepartmentReducer(
      state,
      action: PayloadAction<MCAXS201SubInforDagMstSelFacultySubjectNameOutDto[]>
    ) {
      state.MCAXS201SubInforDagMstSelFacultySubjectNameOutDto = action.payload
    },
    // MCAXS201_学校名ボタン押下する
    mcaxs201SchoolInitialOkReducer(
      state,
      action: PayloadAction<MCAXS201SchoolInfoRequest>
    ) {
      state.MCAXS201CollegeMstSelSchoolNameOutDtoResult = []
      return state
    },
    // MCAXS201_学校名設定する
    setMcaxs201SchoolInitialOkReducer(
      state,
      action: PayloadAction<MCAXS201CollegeMstSelSchoolNameOutDto[]>
    ) {
      state.MCAXS201CollegeMstSelSchoolNameOutDtoResult = action.payload
    },
    // MCAXS201_入力ボタン押下
    mcaxs201CheckReducer(
      state,
      action: PayloadAction<MCAXS201ApplicantInfoRequest>
    ) {
      return state
    },
    // 登録画面から確認画面にデータを設定する
    setMCAX211SetReducer(
      state,
      action: PayloadAction<MCAXS201ApplicantInfoRequest>
    ) {
      // 姓
      state.MCAXS211InsertResult.surname = action.payload.surname
      // 名
      state.MCAXS211InsertResult.firstName = action.payload.firstName
      // 姓カナ
      if (action.payload.surnameKana !== null) {
        state.MCAXS211InsertResult.surnameKana = action.payload.surnameKana
      } else {
        state.MCAXS211InsertResult.surnameKana = magiContants.EMPTY
      }
      // 名カナ
      if (action.payload.firstNameKana !== null) {
        state.MCAXS211InsertResult.firstNameKana = action.payload.firstNameKana
      } else {
        state.MCAXS211InsertResult.firstNameKana = magiContants.EMPTY
      }
      // 生年月日
      if (action.payload.birthday !== null) {
        state.MCAXS211InsertResult.birthday = action.payload.birthday
      } else {
        action.payload.birthday = magiContants.EMPTY
      }
      // 性別
      state.MCAXS211InsertResult.sex = action.payload.sex.toString()
      // メールアドレス（メイン）
      if (action.payload.mailAddressPC !== null) {
        state.MCAXS211InsertResult.mailAddressPC = action.payload.mailAddressPC
      } else {
        state.MCAXS211InsertResult.mailAddressPC = magiContants.EMPTY
      }
      // メールアドレス（サブ）
      if (action.payload.mailAddressMobile !== null) {
        state.MCAXS211InsertResult.mailAddressMobile =
          action.payload.mailAddressMobile
      } else {
        state.MCAXS211InsertResult.mailAddressMobile = magiContants.EMPTY
      }
      // 電話番号（携帯）
      if (action.payload.phoneNumberMobile !== null) {
        state.MCAXS211InsertResult.phoneNumberMobile =
          action.payload.phoneNumberMobile
      } else {
        state.MCAXS211InsertResult.phoneNumberMobile = magiContants.EMPTY
      }
      // 電話番号（自宅）
      if (action.payload.phoneNumberHome !== null) {
        state.MCAXS211InsertResult.phoneNumberHome =
          action.payload.phoneNumberHome
      } else {
        state.MCAXS211InsertResult.phoneNumberHome = magiContants.EMPTY
      }
      // 電話番号（休暇中）
      if (action.payload.phoneNumberVacation !== null) {
        state.MCAXS211InsertResult.phoneNumberVacation =
          action.payload.phoneNumberVacation
      } else {
        state.MCAXS211InsertResult.phoneNumberVacation = magiContants.EMPTY
      }
      // 郵便番号
      if (action.payload.postalCode !== null) {
        state.MCAXS211InsertResult.postalCode = action.payload.postalCode
      } else {
        state.MCAXS211InsertResult.postalCode = magiContants.EMPTY
      }
      // 都道府県
      if (action.payload.prefectures !== null) {
        state.MCAXS211InsertResult.prefectures = action.payload.prefectures //コード
        state.MCAXS211ImageResult.prefectures = action.payload.prefecturesName //名
      } else {
        state.MCAXS211InsertResult.prefectures = magiContants.EMPTY
        state.MCAXS211ImageResult.prefectures = magiContants.EMPTY
      }
      // 市区町村以降の住所
      if (action.payload.addressAfterMunicipalities !== null) {
        state.MCAXS211InsertResult.addressAfterMunicipalities =
          action.payload.addressAfterMunicipalities
      } else {
        state.MCAXS211InsertResult.addressAfterMunicipalities =
          magiContants.EMPTY
      }
      // 郵便番号
      if (action.payload.vacPostalCode !== null) {
        state.MCAXS211InsertResult.vacPostalCode = action.payload.vacPostalCode
      } else {
        state.MCAXS211InsertResult.vacPostalCode = magiContants.EMPTY
      }
      // 都道府県
      if (action.payload.vacPrefectures !== null) {
        state.MCAXS211InsertResult.vacPrefectures =
          action.payload.vacPrefectures //コード
        state.MCAXS211ImageResult.vacPrefectures =
          action.payload.vacPrefecturesName //名
      } else {
        state.MCAXS211InsertResult.vacPrefectures = magiContants.EMPTY
        state.MCAXS211ImageResult.vacPrefectures = magiContants.EMPTY
      }
      // 市区町村以降の住所
      if (action.payload.vacAddressAfterMunicipalities !== null) {
        state.MCAXS211InsertResult.vacAddressAfterMunicipalities =
          action.payload.vacAddressAfterMunicipalities
      } else {
        state.MCAXS211InsertResult.vacAddressAfterMunicipalities =
          magiContants.EMPTY
      }
      //現住所と同じの場合、休暇中住所が現住所同じする
      if (
        action.payload.sameAsCurrentAddress ===
        magiContants.SAMEASCURRENTADDRESS_1
      ) {
        if (action.payload.postalCode !== null) {
          state.MCAXS211InsertResult.vacPostalCode = action.payload.postalCode
        } else {
          state.MCAXS211InsertResult.vacPostalCode = magiContants.EMPTY
        }
        if (action.payload.prefectures !== null) {
          state.MCAXS211InsertResult.vacPrefectures = action.payload.prefectures
          state.MCAXS211ImageResult.vacPrefectures =
            action.payload.prefecturesName
        } else {
          state.MCAXS211InsertResult.vacPrefectures = magiContants.EMPTY
          state.MCAXS211ImageResult.vacPrefectures = magiContants.EMPTY
        }
        if (action.payload.addressAfterMunicipalities !== null) {
          state.MCAXS211InsertResult.vacAddressAfterMunicipalities =
            action.payload.addressAfterMunicipalities
        } else {
          state.MCAXS211InsertResult.vacAddressAfterMunicipalities =
            magiContants.EMPTY
        }
      }
      // 学生区分
      state.MCAXS211InsertResult.studentDivision =
        Number(action.payload.studentDivision) === magiContants.NUMBER_0
          ? magiContants.STRING_2
          : Number(action.payload.studentDivision) === magiContants.NUMBER_1
          ? magiContants.STRING_3
          : Number(action.payload.studentDivision) === magiContants.NUMBER_2
          ? magiContants.STRING_4
          : Number(action.payload.studentDivision) === magiContants.NUMBER_3
          ? magiContants.STRING_5
          : Number(action.payload.studentDivision) === magiContants.NUMBER_4
          ? magiContants.STRING_6
          : Number(action.payload.studentDivision) === magiContants.NUMBER_5
          ? magiContants.STRING_8
          : magiContants.EMPTY
      state.MCAXS211InitResult.studentDivision =
        Number(action.payload.studentDivision) === magiContants.NUMBER_0
          ? magiContants.STRING_2
          : Number(action.payload.studentDivision) === magiContants.NUMBER_1
          ? magiContants.STRING_3
          : Number(action.payload.studentDivision) === magiContants.NUMBER_2
          ? magiContants.STRING_4
          : Number(action.payload.studentDivision) === magiContants.NUMBER_3
          ? magiContants.STRING_5
          : Number(action.payload.studentDivision) === magiContants.NUMBER_4
          ? magiContants.STRING_6
          : Number(action.payload.studentDivision) === magiContants.NUMBER_5
          ? magiContants.STRING_8
          : magiContants.EMPTY
      // 卒業予定時期
      state.MCAXS211InsertResult.graduationPlanTime =
        action.payload.graduationPlanTime

      if (Number(action.payload.studentDivision) === magiContants.NUMBER_5) {
        // 海外場合
        // 学校名
        state.MCAXS211InsertResult.schoolName =
          action.payload.overseasSchoolName
        // 大学コード
        state.MCAXS211InitResult.collegeCode = magiContants.EMPTY
        state.MCAXS211InsertResult.collegeCode = magiContants.EMPTY
      } else if (action.payload.schoolName === magiContants.NONE) {
        // 該当なし場合
        // 学校名
        state.MCAXS211InsertResult.schoolName = action.payload.inputSchoolName
        // 大学コード
        state.MCAXS211InitResult.collegeCode = magiContants.EMPTY
        state.MCAXS211InsertResult.collegeCode = magiContants.EMPTY
      } else {
        // 選択した場合
        // 学校名
        state.MCAXS211InsertResult.schoolName = action.payload.schoolName
        // 大学コード
        state.MCAXS211InitResult.collegeCode = action.payload.collegeCode
        state.MCAXS211InsertResult.collegeCode = action.payload.collegeCode
      }

      if (
        Number(action.payload.studentDivision) === magiContants.NUMBER_0 ||
        Number(action.payload.studentDivision) === magiContants.NUMBER_1
      ) {
        if (
          action.payload.schoolName === magiContants.NONE ||
          action.payload.facultyName === magiContants.NONE ||
          action.payload.subjectName === magiContants.NONE
        ) {
          // 学部
          state.MCAXS211InitResult.facultyCode = action.payload.facultyCode
          state.MCAXS211InsertResult.universityFaculty =
            action.payload.inputFaculty
          state.MCAXS211InsertResult.facultyCode = action.payload.facultyCode
          // 学科
          state.MCAXS211InsertResult.universitySubject =
            action.payload.inputSubject
          state.MCAXS211InitResult.subjectCode = action.payload.subjectCode
          state.MCAXS211InsertResult.subjectCode = action.payload.subjectCode
        } else {
          // 学部
          state.MCAXS211InitResult.facultyCode = action.payload.facultyCode
          state.MCAXS211InsertResult.universityFaculty =
            action.payload.facultyName
          state.MCAXS211InsertResult.facultyCode = action.payload.facultyCode
          // 学科
          state.MCAXS211InsertResult.universitySubject =
            action.payload.subjectName
          state.MCAXS211InitResult.subjectCode = action.payload.subjectCode
          state.MCAXS211InsertResult.subjectCode = action.payload.subjectCode
        }
      } else if (
        Number(action.payload.studentDivision) === magiContants.NUMBER_2
      ) {
        if (
          action.payload.schoolName === magiContants.NONE ||
          action.payload.facultyName === magiContants.NONE ||
          action.payload.subjectName === magiContants.NONE
        ) {
          // 学科
          state.MCAXS211InsertResult.universitySubject =
            action.payload.inputSubject
          state.MCAXS211InitResult.subjectCode = action.payload.subjectCode
          state.MCAXS211InsertResult.subjectCode = action.payload.subjectCode
        } else {
          // 学科
          state.MCAXS211InsertResult.universitySubject =
            action.payload.subjectName
          state.MCAXS211InitResult.subjectCode = action.payload.subjectCode
          state.MCAXS211InsertResult.subjectCode = action.payload.subjectCode
        }
        // 学部
        state.MCAXS211InitResult.facultyCode = action.payload.facultyCode
        state.MCAXS211InsertResult.universityFaculty = magiContants.EMPTY
        state.MCAXS211InsertResult.facultyCode = action.payload.facultyCode
      } else if (
        Number(action.payload.studentDivision) === magiContants.NUMBER_5
      ) {
        // 学部
        state.MCAXS211InitResult.facultyCode = magiContants.EMPTY
        state.MCAXS211InsertResult.universityFaculty =
          action.payload.overseasSchoolFacultyDepartment
        state.MCAXS211InsertResult.facultyCode = magiContants.EMPTY
        // 学科
        state.MCAXS211InsertResult.universitySubject =
          action.payload.overseasSchoolSubjectDepartment
        state.MCAXS211InitResult.subjectCode = magiContants.EMPTY
        state.MCAXS211InsertResult.subjectCode = magiContants.EMPTY
      } else {
        // 学部
        state.MCAXS211InitResult.facultyCode = action.payload.facultyCode
        state.MCAXS211InsertResult.universityFaculty = action.payload.vocationalSchoolMajorName
        state.MCAXS211InsertResult.facultyCode = action.payload.facultyCode
        // 学科
        state.MCAXS211InsertResult.universitySubject = magiContants.EMPTY
        state.MCAXS211InitResult.subjectCode = action.payload.subjectCode
        state.MCAXS211InsertResult.subjectCode = action.payload.subjectCode
      }

      // 学科分類   専攻分野
      // 学科分類コード
      if (Number(action.payload.studentDivision) === magiContants.NUMBER_4) {
        state.MCAXS211InitResult.subjectClassification =
          action.payload.vocationalSchoolMajorName // 名
        state.MCAXS211InitResult.subjectClassificationCode =
          action.payload.vocationalSchoolMajor // コード
      } else if (
        Number(action.payload.studentDivision) === magiContants.NUMBER_5
      ) {
        state.MCAXS211InitResult.subjectClassification = getDynamicCode(
          magiContants.MESSAGECODE_SUBJECT_CLASSIFICATION,
          action.payload.overseasSchoolMajor
        ) // 名
        state.MCAXS211InitResult.subjectClassificationCode =
          action.payload.overseasSchoolMajor // コード
      } else {
        state.MCAXS211InitResult.subjectClassification = magiContants.EMPTY
        state.MCAXS211InitResult.subjectClassificationCode = magiContants.EMPTY
      }
      // 文理区分
      // 文理区分コード
      if (
        action.payload.schoolName === magiContants.NONE ||
        action.payload.facultyName === magiContants.NONE ||
        action.payload.subjectName === magiContants.NONE ||
        Number(action.payload.studentDivision) === magiContants.NUMBER_5
      ) {
        state.MCAXS211InitResult.humanitiesAndSciencesDivision = getDynamicCode(
          magiContants.MESSAGECODE_HUMANITIES_AND_SCIENCES_DIVISION,
          Number(action.payload.humanitiesAndSciencesDivision) ===
            magiContants.NUMBER_0
            ? magiContants.STRING_1
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_1
            ? magiContants.STRING_2
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_2
            ? magiContants.STRING_7
            : magiContants.EMPTY
        )
        state.MCAXS211InitResult.humanitiesAndSciencesDivisionCode =
          Number(action.payload.humanitiesAndSciencesDivision) ===
          magiContants.NUMBER_0
            ? magiContants.STRING_1
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_1
            ? magiContants.STRING_2
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_2
            ? magiContants.STRING_7
            : magiContants.EMPTY
      } else {
        state.MCAXS211InitResult.humanitiesAndSciencesDivision =
          magiContants.EMPTY
        state.MCAXS211InitResult.humanitiesAndSciencesDivisionCode =
          magiContants.EMPTY
      }
      // 取得学位
      state.MCAXS211InsertResult.degree =
        Number(action.payload.overseasSchoolDegree) === magiContants.NUMBER_0
          ? magiContants.STRING_10
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_1
          ? magiContants.STRING_20
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_2
          ? magiContants.STRING_30
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_3
          ? magiContants.STRING_40
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_4
          ? magiContants.STRING_90
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_5
          ? magiContants.EMPTY
          : magiContants.EMPTY
      // 国・地域コード
      if (action.payload.overseasSchoolCountryRegion !== null) {
        state.MCAXS211InsertResult.schoolCountryRegion =
          action.payload.overseasSchoolCountryRegion
      } else {
        state.MCAXS211InsertResult.schoolCountryRegion = magiContants.EMPTY
      }
      // 州（アメリカの場合）コード
      if (action.payload.overseasSchoolState !== null) {
        state.MCAXS211InsertResult.schoolState =
          action.payload.overseasSchoolState
        if (
          action.payload.overseasSchoolCountryRegion !==
          magiContants.SCHOOL_COUNTRY_REGION_110
        ) {
          state.MCAXS211InsertResult.schoolState = magiContants.EMPTY
        }
      } else {
        state.MCAXS211InsertResult.schoolState = magiContants.EMPTY
      }
      // ゼミ
      if (action.payload.zeminar !== null) {
        state.MCAXS211InsertResult.zeminar = action.payload.zeminar
      } else {
        state.MCAXS211InsertResult.zeminar = magiContants.EMPTY
      }
      // サークル
      if (action.payload.circle !== null) {
        state.MCAXS211InsertResult.circle = action.payload.circle
      } else {
        state.MCAXS211InsertResult.circle = magiContants.EMPTY
      }
      // 専攻テーマ
      if (action.payload.majorTheme !== null) {
        state.MCAXS211InsertResult.majorTheme = action.payload.majorTheme
      } else {
        state.MCAXS211InsertResult.majorTheme = magiContants.EMPTY
      }
      // 保有資格_タグ
      if (action.payload.ownedQualification !== null) {
        state.MCAXS211InsertResult.ownedQualificationTag =
          action.payload.ownedQualification
      } else {
        let tags: string[] = []
        tags[0] = magiContants.EMPTY
        state.MCAXS211InsertResult.ownedQualificationTag = tags
      }
      //保有資格_サマリに保有資格_タグの項目と' / 'を接続
      state.MCAXS211InsertResult.ownedQualificationSummary = magiContants.EMPTY
      state.MCAXS211InsertResult.ownedQualificationTag.map(i => {
        state.MCAXS211InsertResult.ownedQualificationSummary = state.MCAXS211InsertResult.ownedQualificationSummary.concat(
          i,
          magiContants.BLANK,
          magiContants.FORWARD_SLASH,
          magiContants.BLANK
        )
      })
      state.MCAXS211InsertResult.ownedQualificationSummary = state.MCAXS211InsertResult.ownedQualificationSummary.substring(
        magiContants.NUMBER_0,
        state.MCAXS211InsertResult.ownedQualificationSummary.length -
          magiContants.NUMBER_3
      )
      // 語学スキル_タグ
      if (action.payload.languageForce !== null) {
        state.MCAXS211InsertResult.languageForceTag =
          action.payload.languageForce
      } else {
        let tags: string[] = []
        tags[0] = magiContants.EMPTY
      }
      // 語学スキル_サマリに語学スキル_タグの項目と' / 'を接続
      state.MCAXS211InsertResult.languageForceSummary = magiContants.EMPTY
      state.MCAXS211InsertResult.languageForceTag.map(i => {
        state.MCAXS211InsertResult.languageForceSummary = state.MCAXS211InsertResult.languageForceSummary.concat(
          i,
          magiContants.BLANK,
          magiContants.FORWARD_SLASH,
          magiContants.BLANK
        )
      })
      state.MCAXS211InsertResult.languageForceSummary = state.MCAXS211InsertResult.languageForceSummary.substring(
        magiContants.NUMBER_0,
        state.MCAXS211InsertResult.languageForceSummary.length -
          magiContants.NUMBER_3
      )
      // 応募経路コード
      // 応募経路
      state.MCAXS211InsertResult.mediaName = action.payload.mediaName //コード
      state.MCAXS211ImageResult.mediaName = action.payload.mediaNameG //名
      // エントリー日
      state.MCAXS211InsertResult.entryDate = action.payload.entryDate
      // 選考フロー
      state.MCAXS211InsertResult.selection = action.payload.selectionName
      // 選考フロー設定ID
      state.MCAXS211InsertResult.selectionFlowSettingId =
        action.payload.selection
      // 学校グループコード
      state.MCAXS211InsertResult.schoolGroupCode =
        action.payload.schoolGroupCode
      // 現住所と同じ
      state.MCAXS211ImageResult.sameAsCurrentAddress =
        action.payload.sameAsCurrentAddress
    },

    setMCAXS230InitRedecer(state, action: PayloadAction<MCAXS230InitRequest>) {
      return state
    },
    mcaxs230InitReducer(state, action: PayloadAction<MCAXS230InitRequest>) {
      return state
    },
    setMCAXS230InitResult(state, action: PayloadAction<MCAXS230OutputDto>) {
      state.MCAXS230OutputDtoResult = action.payload
    },
    setMCAXS230InitDataResult(state, action: PayloadAction<MCAXS230OutputDto>) {
      state.MCAXS230InitDataResult = action.payload
    },
    setMCAXS211InitReducer(state, action: PayloadAction<MCAXS211InitRequest>) {
      state.MCAXS211InitResult.subjectClassification =
        action.payload.subjectClassification
      state.MCAXS211InitResult.subjectClassificationCode =
        action.payload.subjectClassificationCode
      state.MCAXS211InitResult.humanitiesAndSciencesDivision =
        action.payload.humanitiesAndSciencesDivision
      state.MCAXS211InitResult.humanitiesAndSciencesDivisionCode =
        action.payload.humanitiesAndSciencesDivisionCode
      state.MCAXS211InsertResult.subjectClassification =
        action.payload.subjectClassificationCode
      state.MCAXS211InsertResult.humanitiesAndSciencesDivision =
        action.payload.humanitiesAndSciencesDivisionCode
    },
    mcaxs211InitReducer(state, action: PayloadAction<MCAXS211InitRequest>) {
      return state
    },
    // MCAXS211登録するボタン押下し、DB処理
    mcaxs211CreateReducer(state, action: PayloadAction<MCAXS211CreateRequest>) {
      state.MCAXS191Init.surname = ''
      state.MCAXS191Init.firstName = ''
      state.MCAXS191Init.screenId = 'MCAXS211'
      return state
    },
    // MCAXS650初期設定
    setMCAXS650InitReducer(state, action: PayloadAction<MCAXS650InitReturn>) {
      state.MCAXS650InitResult.subjectClassificationCode =
        action.payload.subjectClassificationCode
      state.MCAXS650InitResult.subjectClassification =
        action.payload.subjectClassification
      state.MCAXS650InitResult.humanitiesAndSciencesDivisionCode =
        action.payload.humanitiesAndSciencesDivisionCode
      state.MCAXS650InitResult.humanitiesAndSciencesDivision =
        action.payload.humanitiesAndSciencesDivision
      state.MCAXS650UpdateResult.subjectClassification =
        action.payload.subjectClassificationCode
      state.MCAXS650UpdateResult.humanitiesAndSciencesDivision =
        action.payload.humanitiesAndSciencesDivisionCode
      state.MCAXS650InitReturnResult.updateTime = action.payload.updateTime
      state.MCAXS650InitReturnResult.unsubscribeTime =
        action.payload.unsubscribeTime
    },
    mcaxs650InitReducer(
      state,
      action: PayloadAction<{
        params1: MCAXS650InitRequest
        params2: MCAXS230InitRequest
      }>
    ) {
      return state
    },
    // MCAXS650更新するボタン押下し、DB処理
    mcaxs650UpdateReducer(
      state,
      action: PayloadAction<{
        params1: MCAXS650ApplicantInfoRequest
        params2: MCAXS230InitRequest
      }>
    ) {
      return state
    },
    // 編集画面から確認画面にデータを設定する
    setMCAX650SetkReducer(
      state,
      action: PayloadAction<MCAXS230ApplicantInfoRequest>
    ) {
      // 会員種別
      state.MCAXS650UpdateResult.memberType = action.payload.memberType
      // 応募者管理ID
      state.MCAXS650UpdateResult.jobSeekerId = action.payload.jobSeekerId
      state.MCAXS650InitResult.jobSeekerId = action.payload.jobSeekerId
      // 姓
      state.MCAXS650UpdateResult.surname = action.payload.surname
      // 名
      state.MCAXS650UpdateResult.firstName = action.payload.firstName
      // 姓カナ
      if (action.payload.surnameKana !== null) {
        state.MCAXS650UpdateResult.surnameKana = action.payload.surnameKana
      } else {
        state.MCAXS650UpdateResult.surnameKana = magiContants.EMPTY
      }
      // 名カナ
      if (action.payload.firstNameKana !== null) {
        state.MCAXS650UpdateResult.firstNameKana = action.payload.firstNameKana
      } else {
        state.MCAXS650UpdateResult.firstNameKana = magiContants.EMPTY
      }
      // 生年月日
      if (action.payload.birthday !== null) {
        state.MCAXS650UpdateResult.birthday = action.payload.birthday
      } else {
        state.MCAXS650UpdateResult.birthday = magiContants.EMPTY
      }
      // 性別
      state.MCAXS650UpdateResult.sex = action.payload.sex.toString()
      // メールアドレス（メイン）
      if (action.payload.mailAddressPC !== null) {
        state.MCAXS650UpdateResult.mailAddressPC = action.payload.mailAddressPC
      } else {
        state.MCAXS650UpdateResult.mailAddressPC = magiContants.EMPTY
      }
      // メールアドレス（サブ）
      if (action.payload.mailAddressMobile !== null) {
        state.MCAXS650UpdateResult.mailAddressMobile =
          action.payload.mailAddressMobile
      } else {
        state.MCAXS650UpdateResult.mailAddressMobile = magiContants.EMPTY
      }
      // 電話番号（携帯）
      if (action.payload.phoneNumberMobile !== null) {
        state.MCAXS650UpdateResult.phoneNumberMobile =
          action.payload.phoneNumberMobile
      } else {
        state.MCAXS650UpdateResult.phoneNumberMobile = magiContants.EMPTY
      }
      // 電話番号（自宅）
      if (action.payload.phoneNumberHome !== null) {
        state.MCAXS650UpdateResult.phoneNumberHome =
          action.payload.phoneNumberHome
      } else {
        state.MCAXS650UpdateResult.phoneNumberHome = magiContants.EMPTY
      }
      // 電話番号（休暇中）
      if (action.payload.phoneNumberVacation !== null) {
        state.MCAXS650UpdateResult.phoneNumberVacation =
          action.payload.phoneNumberVacation
      } else {
        state.MCAXS650UpdateResult.phoneNumberVacation = magiContants.EMPTY
      }
      // 郵便番号
      if (action.payload.postalCode !== null) {
        state.MCAXS650UpdateResult.postalCode = action.payload.postalCode
      } else {
        state.MCAXS650UpdateResult.postalCode = magiContants.EMPTY
      }
      // 都道府県
      if (action.payload.prefectures) {
        state.MCAXS650UpdateResult.prefectures = action.payload.prefecturesCode //コード
        state.MCAXS650ImageResult.prefectures = action.payload.prefectures //名
      } else {
        state.MCAXS650UpdateResult.prefectures = magiContants.EMPTY
        state.MCAXS650ImageResult.prefectures = magiContants.EMPTY
      }
      // 市区町村以降の住所
      if (action.payload.addressAfterMunicipalities !== null) {
        state.MCAXS650UpdateResult.addressAfterMunicipalities =
          action.payload.addressAfterMunicipalities
      } else {
        state.MCAXS650UpdateResult.addressAfterMunicipalities =
          magiContants.EMPTY
      }
      // 郵便番号
      if (action.payload.vacPostalCode !== null) {
        state.MCAXS650UpdateResult.vacPostalCode = action.payload.vacPostalCode
      } else {
        state.MCAXS650UpdateResult.vacPostalCode = magiContants.EMPTY
      }
      // 都道府県
      if (action.payload.vacPrefectures !== null) {
        state.MCAXS650UpdateResult.vacPrefectures =
          action.payload.vacPrefecturesCode //コード
        state.MCAXS650ImageResult.vacPrefectures = action.payload.vacPrefectures //名
      } else {
        state.MCAXS650UpdateResult.vacPrefectures = magiContants.EMPTY
        state.MCAXS650ImageResult.vacPrefectures = magiContants.EMPTY
      }
      // 市区町村以降の住所
      if (action.payload.vacAddressAfterMunicipalities !== null) {
        state.MCAXS650UpdateResult.vacAddressAfterMunicipalities =
          action.payload.vacAddressAfterMunicipalities
      } else {
        state.MCAXS650UpdateResult.vacAddressAfterMunicipalities =
          magiContants.EMPTY
      }
      //現住所と同じの場合、休暇中住所が現住所同じする
      if (
        action.payload.sameAsCurrentAddress ===
        magiContants.SAMEASCURRENTADDRESS_1
      ) {
        if (action.payload.postalCode !== null) {
          state.MCAXS650UpdateResult.vacPostalCode = action.payload.postalCode
        } else {
          state.MCAXS650UpdateResult.vacPostalCode = magiContants.EMPTY
        }
        if (action.payload.prefectures !== null) {
          state.MCAXS650UpdateResult.vacPrefectures =
            action.payload.prefecturesCode
          state.MCAXS650ImageResult.vacPrefectures = action.payload.prefectures
        } else {
          state.MCAXS650UpdateResult.vacPrefectures = magiContants.EMPTY
          state.MCAXS650ImageResult.vacPrefectures = magiContants.EMPTY
        }
        if (action.payload.addressAfterMunicipalities !== null) {
          state.MCAXS650UpdateResult.vacAddressAfterMunicipalities =
            action.payload.addressAfterMunicipalities
        } else {
          state.MCAXS650UpdateResult.vacAddressAfterMunicipalities =
            magiContants.EMPTY
        }
      }
      // 学生区分
      state.MCAXS650UpdateResult.studentDivision =
        Number(action.payload.studentDivision) === magiContants.NUMBER_0
          ? magiContants.STRING_2
          : Number(action.payload.studentDivision) === magiContants.NUMBER_1
          ? magiContants.STRING_3
          : Number(action.payload.studentDivision) === magiContants.NUMBER_2
          ? magiContants.STRING_4
          : Number(action.payload.studentDivision) === magiContants.NUMBER_3
          ? magiContants.STRING_5
          : Number(action.payload.studentDivision) === magiContants.NUMBER_4
          ? magiContants.STRING_6
          : Number(action.payload.studentDivision) === magiContants.NUMBER_5
          ? magiContants.STRING_8
          : magiContants.EMPTY
      state.MCAXS650InitResult.studentDivision =
        Number(action.payload.studentDivision) === magiContants.NUMBER_0
          ? magiContants.STRING_2
          : Number(action.payload.studentDivision) === magiContants.NUMBER_1
          ? magiContants.STRING_3
          : Number(action.payload.studentDivision) === magiContants.NUMBER_2
          ? magiContants.STRING_4
          : Number(action.payload.studentDivision) === magiContants.NUMBER_3
          ? magiContants.STRING_5
          : Number(action.payload.studentDivision) === magiContants.NUMBER_4
          ? magiContants.STRING_6
          : Number(action.payload.studentDivision) === magiContants.NUMBER_5
          ? magiContants.STRING_8
          : magiContants.EMPTY
      // 卒業予定時期
      state.MCAXS650UpdateResult.graduationPlanTime =
        action.payload.graduationPlanTimeName

      if (Number(action.payload.studentDivision) === magiContants.NUMBER_5) {
        // 海外場合
        // 学校名
        state.MCAXS650UpdateResult.schoolName =
          action.payload.overseasSchoolName
        // 大学コード
        state.MCAXS650InitResult.collegeCode = magiContants.EMPTY
        state.MCAXS650UpdateResult.collegeCode = magiContants.EMPTY
      } else if (action.payload.schoolName === magiContants.NONE) {
        // 該当なし場合
        // 学校名
        state.MCAXS650UpdateResult.schoolName = action.payload.inputSchoolName
        // 大学コード
        state.MCAXS650InitResult.collegeCode = magiContants.EMPTY
        state.MCAXS650UpdateResult.collegeCode = magiContants.EMPTY
      } else {
        // 選択した場合
        // 学校名
        state.MCAXS650UpdateResult.schoolName = action.payload.schoolName
        // 大学コード
        state.MCAXS650InitResult.collegeCode = action.payload.collegeCode
        state.MCAXS650UpdateResult.collegeCode = action.payload.collegeCode
      }

      if (
        Number(action.payload.studentDivision) === magiContants.NUMBER_0 ||
        Number(action.payload.studentDivision) === magiContants.NUMBER_1
      ) {
        if (
          action.payload.schoolName === magiContants.NONE ||
          action.payload.facultyName === magiContants.NONE ||
          action.payload.subjectName === magiContants.NONE
        ) {
          // 学部
          state.MCAXS650InitResult.facultyCode = action.payload.facultyCode
          state.MCAXS650UpdateResult.universityFaculty =
            action.payload.inputFaculty
          state.MCAXS650UpdateResult.facultyCode = action.payload.facultyCode
          // 学科
          state.MCAXS650UpdateResult.universitySubject =
            action.payload.inputSubject
          state.MCAXS650InitResult.subjectCode = action.payload.subjectCode
          state.MCAXS650UpdateResult.subjectCode = action.payload.subjectCode
        } else {
          // 学部
          state.MCAXS650InitResult.facultyCode = action.payload.facultyCode
          state.MCAXS650UpdateResult.universityFaculty =
            action.payload.facultyName
          state.MCAXS650UpdateResult.facultyCode = action.payload.facultyCode
          // 学科
          state.MCAXS650UpdateResult.universitySubject =
            action.payload.subjectName
          state.MCAXS650InitResult.subjectCode = action.payload.subjectCode
          state.MCAXS650UpdateResult.subjectCode = action.payload.subjectCode
        }
      } else if (
        Number(action.payload.studentDivision) === magiContants.NUMBER_2
      ) {
        if (
          action.payload.schoolName === magiContants.NONE ||
          action.payload.facultyName === magiContants.NONE ||
          action.payload.subjectName === magiContants.NONE
        ) {
          // 学科
          state.MCAXS650UpdateResult.universitySubject =
            action.payload.inputSubject
          state.MCAXS650InitResult.subjectCode = action.payload.subjectCode
          state.MCAXS650UpdateResult.subjectCode = action.payload.subjectCode
        } else {
          // 学科
          state.MCAXS650UpdateResult.universitySubject =
            action.payload.subjectName
          state.MCAXS650InitResult.subjectCode = action.payload.subjectCode
          state.MCAXS650UpdateResult.subjectCode = action.payload.subjectCode
        }
        // 学部
        state.MCAXS650InitResult.facultyCode = action.payload.facultyCode
        state.MCAXS650UpdateResult.universityFaculty = magiContants.EMPTY
        state.MCAXS650UpdateResult.facultyCode = action.payload.facultyCode
      } else if (
        Number(action.payload.studentDivision) === magiContants.NUMBER_5
      ) {
        // 学部
        state.MCAXS650InitResult.facultyCode = magiContants.EMPTY
        state.MCAXS650UpdateResult.universityFaculty =
          action.payload.overseasSchoolFacultyDepartment
        state.MCAXS650UpdateResult.facultyCode = magiContants.EMPTY
        // 学科
        state.MCAXS650UpdateResult.universitySubject =
          action.payload.overseasSchoolSubjectDepartment
        state.MCAXS650InitResult.subjectCode = magiContants.EMPTY
        state.MCAXS650UpdateResult.subjectCode = magiContants.EMPTY
      } else {
        // 学部
        state.MCAXS650InitResult.facultyCode = action.payload.facultyCode
        state.MCAXS650UpdateResult.universityFaculty = action.payload.vocationalSchoolMajor
        state.MCAXS650UpdateResult.facultyCode = action.payload.facultyCode
        // 学科
        state.MCAXS650UpdateResult.universitySubject = magiContants.EMPTY
        state.MCAXS650InitResult.subjectCode = action.payload.subjectCode
        state.MCAXS650UpdateResult.subjectCode = action.payload.subjectCode
      }

      // 学科分類   専攻分野
      // 学科分類コード
      if (Number(action.payload.studentDivision) === magiContants.NUMBER_4) {
        state.MCAXS650InitResult.subjectClassification =
          action.payload.vocationalSchoolMajor // 名
        state.MCAXS650InitResult.subjectClassificationCode =
          action.payload.overseasSchoolMajor // コード
      } else if (
        Number(action.payload.studentDivision) === magiContants.NUMBER_5
      ) {
        state.MCAXS650InitResult.subjectClassification = getDynamicCode(
          magiContants.MESSAGECODE_SUBJECT_CLASSIFICATION,
          action.payload.overseasSchoolMajor
        ) // 名
        state.MCAXS650InitResult.subjectClassificationCode =
          action.payload.overseasSchoolMajor // コード
      } else {
        state.MCAXS650InitResult.subjectClassification = magiContants.EMPTY
        state.MCAXS650InitResult.subjectClassificationCode = magiContants.EMPTY
      }

      // 文理区分
      // 文理区分コード
      if (
        action.payload.schoolName === magiContants.NONE ||
        action.payload.facultyName === magiContants.NONE ||
        action.payload.subjectName === magiContants.NONE ||
        Number(action.payload.studentDivision) === magiContants.NUMBER_5
      ) {
        state.MCAXS650UpdateResult.humanitiesAndSciencesDivision =
          Number(action.payload.humanitiesAndSciencesDivision) ===
          magiContants.NUMBER_0
            ? magiContants.STRING_1
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_1
            ? magiContants.STRING_2
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_2
            ? magiContants.STRING_7
            : magiContants.EMPTY // コード
        state.MCAXS650InitResult.humanitiesAndSciencesDivision = getDynamicCode(
          magiContants.MESSAGECODE_HUMANITIES_AND_SCIENCES_DIVISION,
          Number(action.payload.humanitiesAndSciencesDivision) ===
            magiContants.NUMBER_0
            ? magiContants.STRING_1
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_1
            ? magiContants.STRING_2
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_2
            ? magiContants.STRING_7
            : magiContants.EMPTY
        ) // 名
        state.MCAXS650InitResult.humanitiesAndSciencesDivisionCode =
          Number(action.payload.humanitiesAndSciencesDivision) ===
          magiContants.NUMBER_0
            ? magiContants.STRING_1
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_1
            ? magiContants.STRING_2
            : Number(action.payload.humanitiesAndSciencesDivision) ===
              magiContants.NUMBER_2
            ? magiContants.STRING_7
            : magiContants.EMPTY // コード
      } else {
        state.MCAXS650InitResult.humanitiesAndSciencesDivision =
          magiContants.EMPTY
        state.MCAXS650InitResult.humanitiesAndSciencesDivisionCode =
          magiContants.EMPTY
      }
      // 取得学位
      state.MCAXS650UpdateResult.degree =
        Number(action.payload.overseasSchoolDegree) === magiContants.NUMBER_0
          ? magiContants.STRING_10
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_1
          ? magiContants.STRING_20
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_2
          ? magiContants.STRING_30
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_3
          ? magiContants.STRING_40
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_4
          ? magiContants.STRING_90
          : Number(action.payload.overseasSchoolDegree) ===
            magiContants.NUMBER_5
          ? magiContants.EMPTY
          : magiContants.EMPTY
      // 国・地域コード
      if (action.payload.overseasSchoolCountryRegion !== null) {
        state.MCAXS650UpdateResult.schoolCountryRegion =
          action.payload.overseasSchoolCountryRegion
      } else {
        state.MCAXS650UpdateResult.schoolCountryRegion = magiContants.EMPTY
      }
      // 州（アメリカの場合）コード
      if (action.payload.overseasSchoolState !== null) {
        state.MCAXS650UpdateResult.schoolState =
          action.payload.overseasSchoolState
        if (
          action.payload.overseasSchoolCountryRegion !==
          magiContants.SCHOOL_COUNTRY_REGION_110
        ) {
          state.MCAXS650UpdateResult.schoolState = magiContants.EMPTY
        }
      } else {
        state.MCAXS650UpdateResult.schoolState = magiContants.EMPTY
      }
      // ゼミ
      if (action.payload.zeminar !== null) {
        state.MCAXS650UpdateResult.zeminar = action.payload.zeminar
      } else {
        state.MCAXS650UpdateResult.zeminar = magiContants.EMPTY
      }
      // サークル
      if (action.payload.circle !== null) {
        state.MCAXS650UpdateResult.circle = action.payload.circle
      } else {
        state.MCAXS650UpdateResult.circle = magiContants.EMPTY
      }
      // 専攻テーマ
      if (action.payload.majorTheme !== null) {
        state.MCAXS650UpdateResult.majorTheme = action.payload.majorTheme
      } else {
        state.MCAXS650UpdateResult.majorTheme = magiContants.EMPTY
      }
      // 保有資格_タグ
      if (action.payload.ownedQualification !== null) {
        state.MCAXS650UpdateResult.ownedQualificationTag =
          action.payload.ownedQualification
      } else {
        let tags: string[] = []
        tags[0] = magiContants.EMPTY
        state.MCAXS650UpdateResult.ownedQualificationTag = tags
      }
      //保有資格_サマリに保有資格_タグの項目と' / 'を接続
      state.MCAXS650UpdateResult.ownedQualificationSummary = magiContants.EMPTY
      state.MCAXS650UpdateResult.ownedQualificationTag.map(i => {
        state.MCAXS650UpdateResult.ownedQualificationSummary = state.MCAXS650UpdateResult.ownedQualificationSummary.concat(
          i,
          magiContants.BLANK,
          magiContants.FORWARD_SLASH,
          magiContants.BLANK
        )
      })
      state.MCAXS650UpdateResult.ownedQualificationSummary = state.MCAXS650UpdateResult.ownedQualificationSummary.substring(
        magiContants.NUMBER_0,
        state.MCAXS650UpdateResult.ownedQualificationSummary.length -
          magiContants.NUMBER_3
      )
      // 語学スキル_タグ
      if (action.payload.languageForce !== null) {
        state.MCAXS650UpdateResult.languageForceTag =
          action.payload.languageForce
      } else {
        let tags: string[] = []
        tags[0] = magiContants.EMPTY
        state.MCAXS650UpdateResult.languageForceTag = tags
      }
      // 語学スキル_サマリに語学スキル_タグの項目と' / 'を接続
      state.MCAXS650UpdateResult.languageForceSummary = magiContants.EMPTY
      state.MCAXS650UpdateResult.languageForceTag.map(i => {
        state.MCAXS650UpdateResult.languageForceSummary = state.MCAXS650UpdateResult.languageForceSummary.concat(
          i,
          magiContants.BLANK,
          magiContants.FORWARD_SLASH,
          magiContants.BLANK
        )
      })
      state.MCAXS650UpdateResult.languageForceSummary = state.MCAXS650UpdateResult.languageForceSummary.substring(
        magiContants.NUMBER_0,
        state.MCAXS650UpdateResult.languageForceSummary.length -
          magiContants.NUMBER_3
      )
      // バージョン番号
      state.MCAXS650UpdateResult.sysVersionNumber =
        action.payload.sysVersionNumber
      // バージョン番号（企業別求職者）
      state.MCAXS650UpdateResult.sysVersionNumberForJobSeekerByCompany =
        action.payload.sysVersionNumberForJobSeekerByCompany
      // 手動更新フラグ
      state.MCAXS650UpdateResult.manualRegistrationFlag =
        action.payload.manualRegistrationFlag
      // 学校グループコード
      state.MCAXS650UpdateResult.schoolGroupCode =
        action.payload.schoolGroupCode
      // 企業別求職者ID
      state.MCAXS650UpdateResult.jobSeekerByCompanyId =
        action.payload.jobSeekerByCompanyId
      // 現住所と同じ
      state.MCAXS650ImageResult.sameAsCurrentAddress =
        action.payload.sameAsCurrentAddress
      // 表示用求職者ID
      state.MCAXS650ImageResult.jobSeekerIdForDisplay =
        action.payload.jobSeekerIdForDisplay
      // 手動登録フラグ
        state.MCAXS650InitResult.manualRegistrationFlag = action.payload.manualRegistrationFlag
      // #5917学校情報変更有無判定flag
      if (
        action.payload.collegeCode === action.payload.collegeCodeOld &&
        action.payload.facultyCode === action.payload.facultyCodeOld &&
        action.payload.subjectCode === action.payload.subjectCodeOld
      ) {
        state.MCAXS650InitResult.schoolInfoflag = false
      }else{
        state.MCAXS650InitResult.schoolInfoflag = true
      }
      // 文理区分名
      state.MCAXS650InitResult.humanitiesAndSciencesDivisionByJobSeekerInfo = getDynamicCode(
          magiContants.MESSAGECODE_HUMANITIES_AND_SCIENCES_DIVISION,
          Number(action.payload.humanitiesAndSciencesDivisionOld) ===
            magiContants.NUMBER_0
            ? magiContants.STRING_1
            : Number(action.payload.humanitiesAndSciencesDivisionOld) ===
              magiContants.NUMBER_1
            ? magiContants.STRING_2
            : Number(action.payload.humanitiesAndSciencesDivisionOld) ===
              magiContants.NUMBER_2
            ? magiContants.STRING_7
            : magiContants.EMPTY
      )
      // 文理区分コード
      state.MCAXS650InitResult.humanitiesAndSciencesDivisionCodeByJobSeekerInfo =
        Number(action.payload.humanitiesAndSciencesDivisionOld) ===
        magiContants.NUMBER_0
          ? magiContants.STRING_1
          : Number(action.payload.humanitiesAndSciencesDivisionOld) ===
            magiContants.NUMBER_1
          ? magiContants.STRING_2
          : Number(action.payload.humanitiesAndSciencesDivisionOld) ===
            magiContants.NUMBER_2
          ? magiContants.STRING_7
          : magiContants.EMPTY
      // 学科分類コード
      state.MCAXS650InitResult.subjectClassificationCodeByJobSeekerInfo =
        action.payload.subjectClassificationCodeOld
    },
    // 郵便番号から自動入力する
    mcaxs230AutoInfoReducer(
      state,
      action: PayloadAction<MCAXS230AddressRequest>
    ) {
      return state
    },
    setMcaxs230AutoInfoReducer(
      state,
      action: PayloadAction<MCAXS230PostalCodeMstSelPrefecturesNameOutDto[]>
    ) {
      state.MCAXS230PostalCodeMstSelPrefecturesNameOutDtoResult = action.payload
    },
    // 専門学校_専攻分野_選択する
    vocationalSchoolMajorSelectRedecer(
      state,
      action: PayloadAction<MCAXS230SchoolDetailedDivisionInfoRequest>
      ) {
      return state
    },
    setVocationalSchoolMajorSelectRedecer(
      state,
      action: PayloadAction<MCAXS230DepartmentOutDto[]>
    ) {
      state.MCAXS230DepartmentOutDtoResult = action.payload
    },
    mcaxs230CheckReducer(
      state,
      action: PayloadAction<{
        params1: MCAXS230ApplicantInfoRequest
        params2: MCAXS230InitRequest
        params3: MCAXS230OutputDto
      }>
    ) {
      return state
    },
    graduateSchoolDepartmentReducer(
      state,
      action: PayloadAction<MCAXS230SchoolDetailedDivisionInfoRequest>
    ) {
      state.MCAXS230SubInforDagMstSelFacultySubjectNameOutDtoResult = []
      return state
    },
    graduateSchoolDepartmentSeelectReducer(
      state,
      action: PayloadAction<MCAXS230SchoolDetailedDivisionInfoRequest>
    ) {
      state.MCAXS230SubInforDagMstSelFacultySubjectNameOutDtoResult = []
      return state
    },
    juniorCollegeSubjectSelectReducer(
      state,
      action: PayloadAction<MCAXS230SchoolDetailedDivisionInfoRequest>
    ) {
      state.MCAXS230SubInforDagMstSelFacultySubjectNameOutDtoResult = []
      return state
    },
    setGraduateSchoolDepartmentReducer(
      state,
      action: PayloadAction<MCAXS230SubInforDagMstSelFacultySubjectNameOutDto[]>
    ) {
      state.MCAXS230SubInforDagMstSelFacultySubjectNameOutDtoResult =
        action.payload
    },
    schoolInitialOkReducer(
      state,
      action: PayloadAction<MCAXS230SchoolInfoRequest>
    ) {
      state.MCAXS230CollegeMstSelSchoolNameOutDtoResult = []
      return state
    },
    setSchoolInitialOkReducer(
      state,
      action: PayloadAction<MCAXS230CollegeMstSelSchoolNameOutDto[]>
    ) {
      state.MCAXS230CollegeMstSelSchoolNameOutDtoResult = action.payload
    },
    // MCAXS191初期表示
    setMcaxs191ClearReducer(state) {
      state.MCAXS191Init.surname = ''
      state.MCAXS191Init.firstName = ''
      return state
    },
  },
})

export const {
  mcaxs191BackReducer,
  mcaxs191InitReducer,
  mcaxs201InitReducer,
  setMcaxs201InitReducer,
  mcaxs201AutoInputReducer,
  setMcaxs201AutoInputReducer,
  mcaxs201VocationalSchoolMajorSelectReducer,
  setMcaxs201VocationalSchoolMajorSelectReducer,
  mcaxs201CheckReducer,
  mcaxs201GraduateSchoolDepartmentReducer,
  mcaxs201GraduateSchoolDepartmentSeelectReducer,
  mcaxs201JuniorCollegeSubjectSelectReducer,
  setMcaxs201GraduateSchoolDepartmentReducer,
  mcaxs201SchoolInitialOkReducer,
  setMcaxs201SchoolInitialOkReducer,
  setMCAX211SetReducer,
  setMCAXS230InitRedecer,
  mcaxs230InitReducer,
  setMCAXS230InitResult,
  setMCAXS211InitReducer,
  mcaxs211InitReducer,
  mcaxs211CreateReducer,
  setMCAXS650InitReducer,
  mcaxs650InitReducer,
  mcaxs650UpdateReducer,
  setNameInputNextReducer,
  mcaxs230AutoInfoReducer,
  setMcaxs230AutoInfoReducer,
  vocationalSchoolMajorSelectRedecer,
  setVocationalSchoolMajorSelectRedecer,
  mcaxs230CheckReducer,
  graduateSchoolDepartmentReducer,
  setGraduateSchoolDepartmentReducer,
  setMCAX650SetkReducer,
  graduateSchoolDepartmentSeelectReducer,
  juniorCollegeSubjectSelectReducer,
  schoolInitialOkReducer,
  setSchoolInitialOkReducer,
  setMcaxs191ClearReducer,
  setMCAXS230InitDataResult,
} = jobSeekerInfoSlice.actions
export default jobSeekerInfoSlice.reducer
