export interface templateInfoDto {
  // エントリーデータ入出力テンプレート設定ID
  entryDataIoTemplateSettingId: string
  // テンプレート名
  templateName: string
}

export interface uploadHistoryInfoDto {
  // エントリーアップロード履歴ID
  entryUploadHistoryId: string
  // 実行日時
  executionTime: string
  // 実行者
  executor: string
  // テンプレート名
  templateName: string
  // 処理結果
  processResult: string
  // アップロード件数
  uploadCount: string
  // 重複件数
  duplicateCount: string
  // 自動適用件数
  filterAutoCount: string
  // 名寄せチェック履歴ID
  duplicateIdentificationCheckHistoryId: string
  // 名寄せチェック結果
  dentificationConfirm: string
  // エラー結果
  errorConfirm: string
  // 次期開発12月 #73504 start
  // 自動名寄せ件数
  autoMergeCount: string
  // 次期開発12月 #73504 end
}

export interface applicantListInfoResultDto {
  // 選考管理ID
  selectionFlowSettingId: string[]
  // 特定条件名
  specificConditionName: string
}
const applicantListInfoResultDtoValues: applicantListInfoResultDto = {
  selectionFlowSettingId: [],
  specificConditionName: ''
}

export interface EntryUploadHistoryData {
  templateInfo: templateInfoDto[]
  uploadHistoryInfo: uploadHistoryInfoDto[]
  applicantListInfoResultDto: applicantListInfoResultDto
}
const entryUploadHistoryDataValues: EntryUploadHistoryData = {
  templateInfo: [],
  uploadHistoryInfo: [],
  applicantListInfoResultDto: applicantListInfoResultDtoValues
}

const MCAPS020QueryRequestInitialValues = {
  templateName: '',
  executionDateStart: '',
  executionDateEnd: '',
  executor: 0,
  processingResult: {
    processingResultProcessing: '0',
    processingResultSuccess: '0',
    processingResultFailure: '0',
  }
}

const processingResultList = [
  { name: 'processingResultProcessing', label: '処理中', id: '1' },
  { name: 'processingResultSuccess', label: '成功', id: '2' },
  { name: 'processingResultFailure', label: '失敗', id: '3' },
]
const executorList = [
  { id: 'all', label: '全て', value: 0 },
  { id: 'myself', label: '自分自身', value: 1 },
  { id: 'outself', label: '自分以外', value: 2 },
]
export { MCAPS020QueryRequestInitialValues, entryUploadHistoryDataValues, executorList, processingResultList }