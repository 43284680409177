export interface ErrorContentsInfoDto {
    // ファイル行番号
    fileRowNumber: string
    // エラーメッセージ
    errorMessage: string
}

export interface UploadErrorInfoResultDto {
    // エントリーアップロード履歴ID
    entryUploadHistoryId: string
    // 選考フロー設定ID
    selectionFlowSettingId: string
    // 実行日時
    executionTime: string
    // 実行者
    executor: string
    // テンプレート名
    templateName: string
    // アップロードファイル名
    uploadFileName: string
    // アップロード件数
    uploadCount: string
    // 失敗件数
    failureCount: string
    // アップロードエラー情報
    uploadErrorInfo: ErrorContentsInfoDto[]
}

const initUploadErrorInfoResult: UploadErrorInfoResultDto = {
    entryUploadHistoryId: '',
    selectionFlowSettingId: '',
    executionTime: '',
    executor: '',
    templateName: '',
    uploadFileName: '',
    uploadCount: '',
    failureCount: '',
    uploadErrorInfo: [],
}

export { initUploadErrorInfoResult }