import request from 'utils/request'

export const getMcbhs211McbServiceCheckApi = () =>
  request({
    url: '/MCBHS211/doMcbServiceCheck',
    method: 'post',
  })



