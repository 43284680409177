import { useEffect } from 'react'
import { connect } from 'formik'

const ErrorFocus = ({ formik }: any) => {
  const { isSubmitting, isValidating, errors } = formik

  useEffect(() => {
    const keys = Object.keys(errors)

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(`[name="${keys[0]}"],[data-name="${keys[0]}"]`)

      if (errorElement) {
        errorElement.scrollIntoView()
      }
    }
  }, [isSubmitting, isValidating, errors])

  return null
}

export default connect<{}>(ErrorFocus)
