import {MCAMS010InitDisplayRequest} from "types/MCAMS010/MCAMS010InitDisplayRequest"
import {MCAMS010UpdateDisplayStatusRequest} from "types/MCAMS010/MCAMS010UpdateDisplayStatusRequest"
import {MCAMS010CopyMessageTemplateRequest} from "types/MCAMS010/MCAMS010CopyMessageTemplateRequest"
import request from 'utils/request'
import { SelectTemplateResultDto } from "reducers/messageTemplateReducer"

export const selectMeassageTemplateListRequest = (param:MCAMS010InitDisplayRequest) =>
  request({
    url: '/MCAMS010/init',
    method: 'post',
    data: param,
})

export const addMeassageTemplateListRequest = (recruitmentManagementDivision:string) =>
  request({
    url:  `/MCAMS010/register/${recruitmentManagementDivision}`,
    method: 'post',
})

export const executeMeassageTemplateListRequest = (param:MCAMS010UpdateDisplayStatusRequest) =>
  request({
    url: '/MCAMS010/execute',
    method: 'post',
    data: param,
})

export const copyMeassageTemplateListRequest = (param:MCAMS010CopyMessageTemplateRequest) =>
  request({
    url: '/MCAMS010/copy',
    method: 'post',
    data: param,
})

export const updateMessageTemplateSortRequest = (param:SelectTemplateResultDto[]) =>
  request({
    url: '/MCAMS010/displayOrder',
    method: 'post',
    data: param,
})