
export const popupInitialValues = {
  interviewCancelMessage: '',
  contactUnansweredMessage: '',
  interviewCurrentMessage: '',
}
export const alertInitialValues = {
  interviewFixedMessage: '',
  passingStatusMessage: '',
  progressResidenceMessage: '',
  passingSMID: [0],
  progressSMID: [0]
}

