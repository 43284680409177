import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAXS640EntryInfoRequest } from 'types/MCAXS640/MCAXS640EntryInfoRequest'
import { MCAXS240EntryInfoRequest } from 'types/MCAXS240/MCAXS240EntryInfoRequest'
import {
  initialValues,
} from 'pages/MCAXS240/formConfig'
import { MCAXS240EntryInitRequest } from 'types/MCAXS240/MCAXS240EntryInitRequest'
import { MCAXS640EntryInitRequest } from 'types/MCAXS640/MCAXS640EntryInitRequest'
import { MCAXS220EntryInitRequest } from 'types/MCAXS220/MCAXS220EntryInitRequest'
import { MCAXS220EntryInfoRequest } from 'types/MCAXS220/MCAXS220EntryInfoRequest'
import { MCAXS630EntryInfoRequest } from 'types/MCAXS630/MCAXS630EntryInfoRequest'
import { initialInsValues } from 'pages/MCAXS220/formConfig'

// MCAXS220初期画面検索条件
export interface EntryInit {
  [key: string]: string | number
  recruitmentManagementDivision: string //採用管理区分
}
export interface MCAXS240EntryUpdOutDto {
  [key: string]: any
  mediaName: string //応募経路
  entryDate: string //エントリー日
  applicationJobTitle: string //応募職種名
  publishedStartDate: string //掲載開始日
  publishedEndDate: string //掲載終了日
  surname: string //姓
  firstName: string //名
  surnameKana: string //姓カナ
  firstNameKana: string //名カナ
  birthday: string //生年月日
  sex: number //性別
  mailAddressPc: string //メールアドレス（PC)
  mailAddressMobile: string //メールアドレス（携帯）
  phoneNumberMobile: string //電話番号（携帯）
  phoneNumberHome: string //電話番号（自宅）
  postalCode: string //郵便番号
  prefectures: string //都道府県
  addressAfterMunicipalities: string //市区町村以降の住所
  sysVersionNumberEntry: string //バージョン番号（エントリー）
  sysVersionNumberJobSeeker: string //バージョン番号（求職者）
  sysVersionNumberSeeInfor: string //バージョン番号（企業別求職者）
  prefecturesCode: string //都道府県コード
  entryId: string //エントリーID
  jobSeekerByCompanyId: string //企業別求職者ID
  jobSeekerId: string //求職者ID
  manualRegistrationFlag: string //手動登録フラグ
}
// 応募経路情報
export interface MediaPreferenceSelect {
  [key: string]: string
  mediaSettingId: string //応募経路設定ID
  mediaRouteName: string //応募経路名
}
// 選考フロー情報
export interface SelectFlwPreSelect {
  [key: string]: string
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
}
// 応募職種サジェスト情報リスト
export interface EntryJobCategoryNameSelect {
  [key: string]: string
  applicationJobTitleId: string //応募職種サジェストID
  applicationJobTitleName: string //応募職種サジェスト名
}
// エントリー情報登録の初期表示
export interface EntryInsSearchResults {
  // 応募経路情報リスト
  mediaPreferenceList: MediaPreferenceSelect[]
  // 応募職種サジェスト情報リスト
  entryJobCategoryNameList: EntryJobCategoryNameSelect[]
  // 選考フロー情報リスト
  selectFlwPreList: SelectFlwPreSelect[]
  // 都道府県情報リスト
  prefecturesMstList: PrefecturesMstSelect[]
  // 選考フローフラグ
  selectionFlowFlg: string
}
// エントリー情報登録情報
export interface EntryInsOutResults {
  // エントリー情報登録情報
  entryOutDto: MCAXS220EntryInfoRequest
  // 応募経路情報リスト
  mediaNameOutList: MediaPreferenceSelect[]
  // 応募職種サジェスト情報リスト
  applicationJobTitleOutList: EntryJobCategoryNameSelect[]
  // 選考フロー情報リスト
  selectionOutList: SelectFlwPreSelect[]
  // 都道府県情報リスト
  prefecturesOutList: PrefecturesMstSelect[]
}
// 都道府県情報
export interface PrefecturesMstSelect {
  [key: string]: string
  prefecturesCode: string //都道府県コード
  prefecturesName: string //都道府県名
}
// 住所選択モーダル情報取得
export interface PostalCodeMstSelect {
  // 都道府県名漢字
  prefecturesNameKanji: string
  // 市区町村名漢字
  cityNameKanji: string
  // 町域名漢字
  townNameKanji: string
}
export interface Prim {
  [key: string]: any
  outData: MCAXS240EntryUpdOutDto
  insOutData: MCAXS220EntryInfoRequest
  // エントリー情報登録の初期表示
  entryInsSearchData: EntryInsSearchResults
  // エントリー情報登録情報
  entryInsOutResultsData: EntryInsOutResults
  // 住所選択モーダル情報取得
  postalCodeMstSelectList: PostalCodeMstSelect[]
}
const PrimInitialState: Prim = {
  outData: initialValues,
  insOutData: initialInsValues,
  entryInsSearchData: {
    mediaPreferenceList: [],
    entryJobCategoryNameList: [],
    selectFlwPreList: [],
    prefecturesMstList: [],
    selectionFlowFlg: '',
  },
  entryInsOutResultsData: {
    entryOutDto: initialInsValues,
    mediaNameOutList: [],
    applicationJobTitleOutList: [],
    selectionOutList: [],
    prefecturesOutList: [],
  },
  postalCodeMstSelectList: [],
}
const entrySlice = createSlice({
  name: 'entry',
  initialState: PrimInitialState,
  reducers: {
    // MCAXS220初期画面の検索
    selectEntryInsList(state, action: PayloadAction<MCAXS220EntryInitRequest>) {
      return state
    },
    // エントリー情報登録の初期画面
    setEntryInsList(state, action: PayloadAction<EntryInsSearchResults>) {
      state.entryInsSearchData = action.payload
      return state
    },
    // MCAXS240画面初期表示
    initReducer(state, action: PayloadAction<MCAXS240EntryInitRequest>) {
      return state
    },
    // 検索結果
    setInitResult(state, action: PayloadAction<MCAXS240EntryUpdOutDto>) {
      state.outData = action.payload
    },
    // 住所選択モーダル情報の検索条件
    selectPostalCodeList(state, action: PayloadAction<string>) {
      return state
    },
    // 住所選択モーダル情報の検索結果
    setPostalCodeList(state, action: PayloadAction<PostalCodeMstSelect[]>) {
      state.postalCodeMstSelectList = action.payload
      return state
    },
    // 住所選択モーダル情報の検索条件
    mCAXS220selectPostalCodeList(state, action: PayloadAction<string>) {
      return state
    },
    // MCAXS220入力内容を確認する"ボタン押下時
    editInsReducer(state, action: PayloadAction<{params1: MCAXS220EntryInfoRequest, params2: EntryInsOutResults}>) {
      state.insOutData = action.payload.params1
      return state
    },
    //MCAXS220結果保存
    setEditInsReducer(state, action: PayloadAction<EntryInsOutResults>) {
      state.entryInsOutResultsData = action.payload
    },
    selectEditInsReducer(state, action: PayloadAction<EntryInsOutResults>) {
      return state
    },
    // MCAXS240"入力内容を確認する"ボタン押下時
    editReducer(state, action: PayloadAction<{params1: MCAXS240EntryInfoRequest,
       params2: MCAXS240EntryUpdOutDto ,
       params3: EntryInsSearchResults }>) {
      state.prim = action.payload
      return state
    },
    //MCAXS630初期表示
    initInsReducer(state, action: PayloadAction<MCAXS220EntryInfoRequest>) {
      state.prim = action.payload
      return state
    },
    // MCAXS630"登録する"ボタン押下時
    editcontentconfirmationInsReducer(
      state,
      action: PayloadAction<{params1: MCAXS630EntryInfoRequest, params2:MCAXS220EntryInitRequest}>
    ) {
      return state
    },
    //MCAXS640初期表示
    mCAXS640InitReducer(
      state,
      action: PayloadAction<MCAXS640EntryInitRequest>
    ) {
      return state
    },
    setMCAXS240InitResult(state, action: PayloadAction<MCAXS240EntryUpdOutDto>) {
      state.outData = action.payload
    },
    setMCAXS240SelectResult(state, action: PayloadAction<EntryInsSearchResults>) {
      state.entryInsSearchData = action.payload
    },
    // MCAXS640更新する"ボタン押下時、DB処理
    editcontentconfirmationReducer(
      state,
      action: PayloadAction<{params1: MCAXS640EntryInfoRequest, params2:MCAXS240EntryInitRequest}>
    ) {
      return state
    },
    setOutResult(state) {
      return state
    },
  },
})

export const {
  selectEntryInsList,
  setEntryInsList,
  initReducer,
  setInitResult,
  selectPostalCodeList,
  mCAXS220selectPostalCodeList,
  setPostalCodeList,
  editInsReducer,
  setEditInsReducer,
  editReducer,
  initInsReducer,
  editcontentconfirmationInsReducer,
  mCAXS640InitReducer,
  setMCAXS240SelectResult,
  editcontentconfirmationReducer,
  setMCAXS240InitResult,
  setOutResult,
} = entrySlice.actions
export default entrySlice.reducer
