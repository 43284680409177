import request from 'utils/request'
import { MCAVS020NewsRequest } from 'types/MCAVS020/MCAVS020NewsRequest'
export const searchRequestNews = (params: MCAVS020NewsRequest) =>
  request({
    url: '/MCAVS020/init',
    method: 'post',
    data: params,
  })

export const downloadFile = (fileManagerId: string) =>
  request({
    responseType: 'blob',
    url: `/MCAVS020/downloadFile/${fileManagerId}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  })
