import request from 'utils/request'

import { MCAZS050MessageQueryRequest } from 'types/MCAZS050/MCAZS050MessageQueryRequest'
import { MCAZS050MessageSelectedRequest } from 'types/MCAZS050/MCAZS050MessageSelectedRequest'

export const initDustboxRequest = (apiData: MCAZS050MessageQueryRequest) =>
  request({
    url: '/MCAZS050/init',
    method: 'post',
    data: apiData,
  })

export const selectMessageDustboxRequest = (
  apiData: MCAZS050MessageQueryRequest
) =>
  request({
    url: '/MCAZS050/find',
    method: 'post',
    data: apiData,
  })

export const undoDustboxMessageRequest = (apiData: MCAZS050MessageSelectedRequest) =>
  request({
    url: '/MCAZS050/undoMessage',
    method: 'post',
    data: apiData,
  })

export const deleteDustboxMessageRequest = (
  apiData: MCAZS050MessageSelectedRequest
) =>
  request({
    url: '/MCAZS050/deleteMessage',
    method: 'post',
    data: apiData,
  })

// #5654 #59450 start
export const getSelectionManagementApiMCAZS050 = (apiData: any) =>
  request({
  url:`/MCAZS050/getSelectionManagementIdBulkList`,
  method: 'post',
  data: apiData,
})
// #5654 #59450 end

// export const showEntryInfo = (apiData: MCAZS050TransitionEntryRequest) =>
//   request({
//     url: '/MCAZS050/showEntryInfo',
//     method: 'post',
//     data: apiData,
//   })

// export const showMessage = (apiData: MCAZS050TransitionMessageRequest) =>
//   request({
//     url: '/MCAZS050/showMessage',
//     method: 'post',
//     data: apiData,
//   })
