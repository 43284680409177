import { getMessage } from "common/messageUtil";

const accountTypeList = [
  { name: 'accountTypeSupervising', label: '統括', id: '1' },
  { name: 'accountTypeAdministrator', label: '管理者', id: '2' },
  { name: 'accountTypeSemiManager', label: '準管理者', id: '3' },
  { name: 'accountTypeGeneralUser', label: '一般ユーザ', id: '4' },
  { name: 'accountTypeLimitUser', label: '制限ユーザ', id: '5' },
];

const initialValues = {
  name: '',
  accountTypes: {
    accountTypeSupervising: '0',
    accountTypeAdministrator: '0',
    accountTypeSemiManager: '0',
    accountTypeGeneralUser: '0',
    accountTypeLimitUser: '0',
  },
  selectGroup: '',
  invalidUser: '0',
};

const submitValues = {
  name: '',
  accountTypeSupervising: '0',
  accountTypeAdministrator: '0',
  accountTypeSemiManager: '0',
  accountTypeGeneralUser: '0',
  accountTypeLimitUser: '0',
  selectGroup: '',
  invalidUser: '0',
  recruitmentManagementDivision: '0'
} ;

const messageMap: { [key: string]: string } = {
  enable: getMessage('MCADS010-009'),
  disable: getMessage('MCADS010-008'),
  resetPassword:getMessage('MCADS010-007'),
  invalidReset: getMessage('MCADS010-005'),
};

export { initialValues, accountTypeList, messageMap, submitValues };
