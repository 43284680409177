import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getMessage } from 'common/messageUtil'

const initialState = {
  message: '',
  type: '', // snackbar, modal, fullpage
}

const invalidMessage = getMessage("401")

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    openSnackbar(state, action: PayloadAction<string>) {
      if (state.message !== invalidMessage) {
        return {
          message: getMessage(action.payload)
            ? getMessage(action.payload)
            : action.payload,
          type: 'snackbar',
        }
      }
    },
    openSnackbarWithArg(state, action: PayloadAction<{ 
        messageId: string,
        args?: string[]}>) {
      if (state.message !== invalidMessage) {
        return {
          message: getMessage(action.payload.messageId, action.payload.args)
            ? getMessage(action.payload.messageId, action.payload.args)
            : action.payload.messageId,
          type: 'snackbar',
        }
      }
    },
    openModal(state, action: PayloadAction<string>) {
      if (action.payload === invalidMessage) {
        return {
            message: getMessage(action.payload)
              ? getMessage(action.payload)
              : action.payload,
            type: 'modalLogout',
        }
      } else {
        if (state.message !== invalidMessage) {
            return {
              message: getMessage(action.payload)
                ? getMessage(action.payload)
                : action.payload,
              type: 'modal',
            }
        }
      }
      
    },
    openModalWithArg(state, action: PayloadAction<{ 
        messageId: string,
        args?: string[]}>) {
      if (state.message !== invalidMessage) {
        return {
          message: getMessage(action.payload.messageId, action.payload.args)
            ? getMessage(action.payload.messageId, action.payload.args)
            : action.payload.messageId,
          type: 'modal',
        }
      }
    },
    openModalWindowClose(state, action: PayloadAction<string>) {
      if (state.message !== invalidMessage) {
        return {
          message: getMessage(action.payload)
            ? getMessage(action.payload)
            : action.payload,
          type: 'modalWindowClose',
        }
      }
    },
    closeMessage(state) {
      return initialState
    },
  },
})

export const { openSnackbar, openSnackbarWithArg, openModal, openModalWithArg, openModalWindowClose, closeMessage } = messageSlice.actions
export default messageSlice.reducer
