import {
  initRMFInfoInitialValues,
  initValues,
  MCAXS340InitRequest,
  MCAXS340RecruitmentManagementFlagCompanyListResultDto,
  MCAXS340RecruitmentManagementFlagListResultDto,
  MCAXS340RecruitmentManagementFlagOptionListResultDto,
  MCAXS340RecruitmentManagementFlagUpdateCheckDto,
} from 'pages/MCAXS340/formConfig'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAXS340UpdateFlagRequest } from 'types/MCAXS340/MCAXS340UpdateFlagRequest'

export interface InitRecruitmentManagementFlagInfo {
  mcaxs340RecruitmentManagementFlagListResultDto: MCAXS340RecruitmentManagementFlagListResultDto[]
  mcaxs340RecruitmentManagementFlagOptionListResultDto: MCAXS340RecruitmentManagementFlagOptionListResultDto[]
  mcaxs340RecruitmentManagementFlagCompanyListResultDto: MCAXS340RecruitmentManagementFlagCompanyListResultDto[]
  mcaxs340RecruitmentManagementFlagUpdateCheckDto: MCAXS340RecruitmentManagementFlagUpdateCheckDto[]
}
interface MCAXS340RecruitmentManagementFlagInfoInitial {
  initRequest: MCAXS340InitRequest
  initRMFInfo: InitRecruitmentManagementFlagInfo
  isDialogOpen: boolean
}
const initialState: MCAXS340RecruitmentManagementFlagInfoInitial = {
  initRequest: initValues,
  initRMFInfo: initRMFInfoInitialValues,
  isDialogOpen: false,
}
const MCAXS340Reducer = createSlice({
  name: 'recruitmentManagementFlagUpdate',
  initialState,
  reducers: {
    // 画面初期化
    initRMFInfo(
      state,
      action: PayloadAction<{ request: MCAXS340InitRequest; id: string }>
    ) {
      return state
    },
    setRMFInfo(
      state,
      action: PayloadAction<InitRecruitmentManagementFlagInfo>
    ) {
      state.initRMFInfo = action.payload
      return state
    },
    setinitRequest(
      state,
      action: PayloadAction<MCAXS340InitRequest>
    ) {
      state.initRequest = action.payload
      return state
    },
    setDialogOpen(state, action: PayloadAction<boolean>) {
      state.isDialogOpen = action.payload
      return state
    },
    insertByRMF(
      state,
      action: PayloadAction<{
        request: MCAXS340UpdateFlagRequest
        onOk: () => void
        // 次期開発12月 #51829 start
        clearSelectedRFSettingId: () => void
        // 次期開発12月 #51829 end
      }>
    ) {
      return state
    },
  },
})

export const {
  initRMFInfo,
  setRMFInfo,
  setinitRequest,
  setDialogOpen,
  insertByRMF,
} = MCAXS340Reducer.actions
export default MCAXS340Reducer.reducer
