import {
  getMcaxs401Init,
  setMcaxs401Init,
} from 'reducers/advancedSearchJobChangeReducer'
import { getMCAXS411InitRequest } from 'apis/MCAXS411Api'
import { getMCAXS421InitRequest } from 'apis/MCAXS421Api'
import { getMCAXS431InitRequest } from 'apis/MCAXS431Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { all, put, takeLatest, call } from 'redux-saga/effects'
import { ApplicantInfo } from 'componentsHsc/AdvancedSearchJobChange/Config/applicantConfig'
import { BasicInfo } from 'componentsHsc/AdvancedSearchJobChange/Config/basicConfig'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'

// NOTE:処理をentryListSaga#searchSagaに移動したため、参照されない
function* getMcaxs401InitSaga(action: ReturnType<typeof getMcaxs401Init>) {
  try {
    const [
      mCAXS411Response, //応募情報
      mCAXS421Response, //基本情報
      mCAXS431Result, //採用管理情報取得
    ] = yield all([
      call(getMCAXS411InitRequest),
      call(getMCAXS421InitRequest),
      call(getMCAXS431InitRequest, action.payload.mCAXS431Request),
    ])

    const mCAXS411Result: ApplicantInfo = {
      memberTypeList: mCAXS411Response.memberTypeList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),

      applicationRouteList: mCAXS411Response.applicationRouteList.map(
        (option: any) => ({
          applicationRouteFlag: option.applicationRouteFlag,
          applicationRouteId: option.applicationRouteId,
          applicationRouteName: option.applicationRouteName,
        })
      ),

      entryJobCategoryList: mCAXS411Response.entryJobCategoryList.map(
        (option: any) => ({
          coordinationFormerJobCategoryIdentificationKey:
            option.coordinationFormerJobCategoryIdentificationKey,
          entryJobCategoryName: option.entryJobCategoryName,
          umuFlag: option.umuFlag,
        })
      ),

      entryTypeList: mCAXS411Response.entryTypeList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
      entryClassificationList: mCAXS411Response.entryClassificationList.map(
        (option: any) => ({
          value: option.sequence,
          label: option.iconName,
        })
      ),
      scoutBenefitsList: mCAXS411Response.scoutBenefitsList.map(
        (option: any) => ({
          value: option.sequence,
          label: option.iconName,
        })
      ),
    }

    const mCAXS421Result: BasicInfo = {
      sexList: mCAXS421Response.sexList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
      spouseList: mCAXS421Response.spouseList.map((option: any) => ({
        value: option.itemCode,
        label: option.itemName,
      })),
      finalEducationList: mCAXS421Response.finalEducationList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      educationBackgroundList: mCAXS421Response.educationBackgroundList,
      languageSkillsList: mCAXS421Response.languageSkillsList,
      eligibilityList: mCAXS421Response.eligibilityList,
      experienceCountList: mCAXS421Response.experienceCountList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      employmentSituationList: mCAXS421Response.employmentSituationList.map(
        (option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })
      ),
      jobCareerList: mCAXS421Response.jobCareerList,
      experiencedJobList: mCAXS421Response.experiencedJobList,
    }

    //ReduxStoreに保持
    yield put(
      setMcaxs401Init({
        mCAXS411Result,
        mCAXS421Result,
        mCAXS431Result,
      })
    )
  } catch (error) {
    if (error.message == getMessage(magiContants.MESSAGECODE_401)) {
      yield put(openModal(error.message))
    } else {
      yield put(openSnackbar(error.message))
    }
  }
}

export default function* advancedSearchSaga() {
  yield all([
    takeLatest(getMcaxs401Init, getMcaxs401InitSaga),
  ])
}
