import { MCAXS601InitRequest } from 'types/MCAXS601/MCAXS601InitRequest'

/**
 * 学校詳細指定モーダル_初期表示用データ格納情報
 */
export interface SchoolDetailsModalInfo {
  schoolDetailsList: SchoolDetail[] // 学校詳細リスト
}

/** 学校詳細リスト */
export interface SchoolDetail {
  [key: string]: any
  collegeCode: string // 大学コード
  collegeName: string // 大学名称
}

export const initRequest: MCAXS601InitRequest = {
  schoolGroupId: null, // 学校グループID
}
