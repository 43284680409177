import request from 'utils/request'

import { MCAHS020CreateRequest } from 'types/MCAHS020/MCAHS020CreateRequest'
import { MCAHS020InitRequest } from 'types/MCAHS020/MCAHS020InitRequest'

export const doCreateApi = (param: MCAHS020CreateRequest) =>
  request({
    url: '/MCAHS020/register',
    method: 'post',
    data: param,
})

// #72427 次期開発2022年12月 start
export const initCheckApi = (param: MCAHS020InitRequest) =>
  request({
    url: '/MCAHS020/init',
    method: 'post',
    data: param,
})
// #72427 次期開発2022年12月 end