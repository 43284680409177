import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Radio } from 'components';
import { useSelect } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 650,
    marginTop: theme.spacing(1),
    '& table': {
      marginTop: theme.spacing(1),
    },
  },
}));

interface Props {
  labelList: string[];
  dataList: any[];
  selectable?: boolean;
  selected?: string;
  handleSelect?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default ({
  labelList,
  dataList,
  selectable,
  selected,
  handleSelect,
}: Props) => {
  const classes = useStyles();

  if (!(selectable && selected && handleSelect)) {
    [selected, handleSelect] = useSelect('');
  }

  return (
    <div className={`selection-table applicant ${classes.root}`}>
      <table>
        <thead>
          <tr>
            {selectable && <th>選択</th>}
            {labelList.map(i => (
              <th key={i}>{i}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataList.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {selectable && (
                <td>
                  <Radio
                    name="selection"
                    value={Object.values(row)[0] as string}
                    onChange={handleSelect as any}
                  />
                </td>
              )}
              {Object.values(row).map((item, index) => (
                <td key={index}>
                  <>{item}</>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
