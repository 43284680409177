import {
  MessageData,
  McbMessageData,
  EntryHistory,
  QuestionList,
  mcbLinkHistory,
  scout,
  Mcb,
  McbInfo,
} from 'utils/tableDataTransfrom'

/** 画面表示項目 */
export interface ScreenDisplayItems {
  jobSeekerId: number
  commonHeader: CommonHeader
  profile: Profile
  entryHistory: EntryHistory[]
  // [phase2] start by zhangxp
  mcbLinkHistory: mcbLinkHistory[]
  // [phase2] end by zhangxp
  // [phase2] start by jxp
  scout: scout[]
  // [phase2] end by jxp
  question: Question[]
  myCareerBox : Mcb
  images: Images
  gab: Gab
  opq: Opq
  cab: Cab
  message: MessageData[]
  mcbMessage: McbMessageData[]
}

/** 共通ヘッダーエリア */
export interface CommonHeader {
  [key: string]: any
  companyName: string
  loginUserName: string
  goBack: boolean
  goNext: boolean
  attachedFile: string
  pdfOutput: string
  changeBasicInformation: boolean
  changeMessage: boolean
  fullName: string
  familyName: string
  name: string
  fullNameKana: string
  familyNameKana: string
  nameKana: string
  jobSeekerIdForDisplay: string
  UnsubscribedBadge: boolean
  createNewMessage: string
}

/** プロフィールエリア */
export interface Profile {
  [key: string]: any
  profileFacePhoto: string
  profileFacePhotoUpload: string
  profileFacePhotoUploadButtonDisabledFlag: boolean
  profileFullName: string
  profilePostalCode: string
  profileStreetAddress: string
  profileBirthDateSex: string
  profileSchoolInformation: string
  profileEligibility: string
  profileMobilePhone: string
  profileFixedLinePhone: string
  profileMobileEmail: string
  profileEmail: string
  profileLanguageSkills: string
  // [phase2] start by zhangxp
  profileZeminar: string
  profileCircle: string
  profileMajorTheme: string
  // [phase2] end by zhangxp
  profileEdit: string
  schoolTypeName: string
  schoolGroupName: string
  humanitiesAndSciencesDivisionName: string
  profileExtracurricularActivities: string 
}

/** アンケートエリア */
export interface Question {
  [key: string]: any
  questionType: string
  questionMediumBadge: string
  questionEntryBoxName: string
  questionEntryDateTime: string
  questionList: QuestionList[]
}

/** 適性テスト（イメジス）エリア */
export interface Images {
  [key: string]: any
  viewFlag: boolean
  imagesCheckResultsReport: boolean
  imagesIntelligenceDeviationSynthesis: number | null
  imagesIntelligenceDeviationCounting: number | null
  imagesIntelligenceDeviationLanguage: number | null
  imagesIntelligenceDeviationEnglish: number | null
  imagesIntelligenceStandardCounting: number | null
  imagesIntelligenceStandardLanguage: number | null
  imagesIntelligenceStandardEnglish: number | null
  imagesPersonalityI: number | null
  imagesPersonalityM: number | null
  imagesPersonalityA: number | null
  imagesPersonalityG: number | null
  imagesPersonalityE: number | null
  imagesPersonalityS: number | null
  imagesPersonalityVitality: number | null
  imagesPersonalityInterpersonal: number | null
  imagesPersonalityEstablishment: number | null
  // Phase2 Start
  imagesPersonalityD: number | null
  imagesPersonalityW: number | null
  // Phase2 End
  imagesBirthday: string
  imagesUpdateDate: string
  imagesUpdatePath: string
}

/** 適性テスト（GAB）エリア */
export interface Gab {
  [key: string]: any
  viewFlag: boolean
  gabCheckResultsReport: boolean
  gabTestLanguage: string
  gabExamDateCounting: string
  gabExamDateLanguage: string
  gabExamDateOpq: string
  gabIntelligenceDeviationSynthesis: number | null
  gabIntelligenceDeviationCounting: number | null
  gabIntelligenceDeviationLanguage: number | null
  gabIntelligenceStandardSynthesis: number | null
  gabIntelligenceStandardCounting: number | null
  gabIntelligenceStandardLanguage: number | null
  gabManagementQualities: number | null
  gabAbilityVitality: number | null
  gabAbilityPerPerson: number | null
  gabAbilityTeamwork: number | null
  gabAbilityCreativeThinking: number | null
  gabAbilityProblemSolving: number | null
  gabAbilitySituationalAdaptability: number | null
  gabAbilityPressureResistance: number | null
  gabAbilityOrganizingAbility: number | null
  gabAbilityLeadership: number | null
  gabJobAptitudeSales1: number | null
  gabJobAptitudeSales2: number | null
  gabJobAptitudeStaff: number | null
  gabJobAptitudeGeneralSecretary: number | null
  gabJobAptitudeEngineer: number | null
  gabJobAptitudeResearchDevelopment: number | null
  gabJobAptitudeCustomersCounterCallCenter: number | null
  gabOpqPersuasiveness: number | null
  gabOpqExtrovert: number | null
  gabOpqHumility: number | null
  gabOpqConcreteThings: number | null
  gabOpqHuman: number | null
  gabOpqConceptuality: number | null
  gabOpqElaborate: number | null
  gabOpqAnxiousMind: number | null
  gabOpqOptimistic: number | null
  gabOpqCompetitive: number | null
  gabOpqLeadership: number | null
  gabOpqFriendliness: number | null
  gabOpqConsultativeness: number | null
  gabOpqData: number | null
  gabOpqOrthodox: number | null
  gabOpqCreative: number | null
  gabOpqMeticulous: number | null
  gabOpqTough: number | null
  gabOpqCritical: number | null
  gabOpqAscension: number | null
  gabOpqUniqueness: number | null
  gabOpqSocial: number | null
  gabOpqTrouble: number | null
  gabOpqAestheticValue: number | null
  gabOpqChangeOriented: number | null
  gabOpqPlanning: number | null
  gabOpqMargin: number | null
  gabOpqSuppression: number | null
  gabOpqAbilityTakeAction: number | null
  gabOpqDetermination: number | null
  gabConsistency: number | null
  gabUpdateDate: string
  gabUpdatePath: string
}

/** 適性テスト（OPQ）エリア */
export interface Opq {
  [key: string]: any
  viewFlag: boolean
  opqCheckResultsReport: boolean
  opqExamDate: string
  opqManagementQualities: number | null
  opqAbilityVitality: number | null
  opqAbilityPerPerson: number | null
  opqAbilityTeamwork: number | null
  opqAbilityCreativeThinking: number | null
  opqAbilityProblemSolving: number | null
  opqAbilitySituationalAdaptability: number | null
  opqAbilityPressureResistance: number | null
  opqAbilityOrganizingAbility: number | null
  opqAbilityLeadership: number | null
  opqJobAptitudeSales1: number | null
  opqJobAptitudeSales2: number | null
  opqJobAptitudeStaff: number | null
  opqJobAptitudeGeneralSecretary: number | null
  opqJobAptitudeEngineer: number | null
  opqJobAptitudeResearchDevelopment: number | null
  opqJobAptitudeCustomersCounterCallCenter: number | null
  opqOpqPersuasiveness: number | null
  opqOpqExtrovert: number | null
  opqOpqHumility: number | null
  opqOpqConcreteThings: number | null
  opqOpqHuman: number | null
  opqOpqConceptuality: number | null
  opqOpqElaborate: number | null
  opqOpqAnxiousMind: number | null
  opqOpqOptimistic: number | null
  opqOpqCompetitive: number | null
  opqOpqLeadership: number | null
  opqOpqFriendliness: number | null
  opqOpqConsultativeness: number | null
  opqOpqData: number | null
  opqOpqOrthodox: number | null
  opqOpqCreative: number | null
  opqOpqMeticulous: number | null
  opqOpqTough: number | null
  opqOpqCritical: number | null
  opqOpqAscension: number | null
  opqOpqUniqueness: number | null
  opqOpqSocial: number | null
  opqOpqTrouble: number | null
  opqOpqAestheticValue: number | null
  opqOpqChangeOriented: number | null
  opqOpqPlanning: number | null
  opqOpqMargin: number | null
  opqOpqSuppression: number | null
  opqOpqAbilityTakeAction: number | null
  opqOpqDetermination: number | null
  opqConsistency: number | null
  opqUpdateDate: string
  opqUpdatePath: string
}

/** 適性テスト（CAB）エリア */
export interface Cab {
  [key: string]: any
  viewFlag: boolean
  cabCheckResultsReport: boolean
  cabTestLanguage: string
  cabExamDateCab1: string
  cabExamDateCab2: string
  cabExamDateCab3: string
  cabExamDateCab4: string
  cabExamDateOpq: string
  cabIntelligenceDeviationSynthesis: number | null
  cabIntelligenceDeviationCab1: number | null
  cabIntelligenceDeviationCab2: number | null
  cabIntelligenceDeviationCab3: number | null
  cabIntelligenceDeviationCab4: number | null
  cabIntelligenceStandardSynthesis: number | null
  cabIntelligenceStandardCab1: number | null
  cabIntelligenceStandardCab2: number | null
  cabIntelligenceStandardCab3: number | null
  cabIntelligenceStandardCab4: number | null
  cabComputerAptitudePg: number | null
  cabComputerAptitudeSe: number | null
  cabComputerAptitudeCe: number | null
  cabComputerAptitudePm: number | null
  cabAbilityVitality: number | null
  cabAbilityPerPerson: number | null
  cabAbilityTeamwork: number | null
  cabAbilityCreativeThinking: number | null
  cabAbilityProblemSolving: number | null
  cabAbilitySituationalAdaptability: number | null
  cabAbilityPressureResistance: number | null
  cabAbilityOrganizingAbility: number | null
  cabAbilityLeadership: number | null
  cabOpqPersuasiveness: number | null
  cabOpqExtrovert: number | null
  cabOpqHumility: number | null
  cabOpqConcreteThings: number | null
  cabOpqHuman: number | null
  cabOpqConceptuality: number | null
  cabOpqElaborate: number | null
  cabOpqAnxiousMind: number | null
  cabOpqOptimistic: number | null
  cabOpqCompetitive: number | null
  cabOpqLeadership: number | null
  cabOpqFriendliness: number | null
  cabOpqConsultativeness: number | null
  cabOpqData: number | null
  cabOpqOrthodox: number | null
  cabOpqCreative: number | null
  cabOpqMeticulous: number | null
  cabOpqTough: number | null
  cabOpqCritical: number | null
  cabOpqAscension: number | null
  cabOpqUniqueness: number | null
  cabOpqSocial: number | null
  cabOpqTrouble: number | null
  cabOpqAestheticValue: number | null
  cabOpqChangeOriented: number | null
  cabOpqPlanning: number | null
  cabOpqMargin: number | null
  cabOpqSuppression: number | null
  cabOpqAbilityTakeAction: number | null
  cabOpqDetermination: number | null
  cabConsistency: number | null
  cabUpdateDate: string
  cabUpdatePath: string
}

/** 共通ヘッダーエリア */
export const commonHeader: CommonHeader = {
  companyName: '',
  loginUserName: '',
  goBack: false,
  goNext: false,
  attachedFile: '',
  pdfOutput: '',
  changeBasicInformation: false,
  changeMessage: false,
  fullName: '',
  familyName: '',
  name: '',
  fullNameKana: '',
  familyNameKana: '',
  nameKana: '',
  jobSeekerIdForDisplay: '',
  UnsubscribedBadge: false,
  createNewMessage: '',
}

/** プロフィールエリア */
export const profile: Profile = {
  profileFacePhoto: '',
  profileFacePhotoUpload: '',
  profileFacePhotoUploadButtonDisabledFlag: false,
  profileFullName: '',
  profilePostalCode: '',
  profileStreetAddress: '',
  profileBirthDateSex: '',
  profileSchoolInformation: '',
  profileEligibility: '',
  profileMobilePhone: '',
  profileFixedLinePhone: '',
  profileMobileEmail: '',
  profileEmail: '',
  profileLanguageSkills: '',
  // [phase2] start by zhangxp
  profileZeminar: '',
  profileCircle: '',
  profileMajorTheme: '',
  // [phase2] end by zhangxp
  profileEdit: '',
  schoolTypeName: '',
  schoolGroupName: '',
  humanitiesAndSciencesDivisionName: '',
  profileExtracurricularActivities: '',
}

/** エントリー履歴エリア */
export const entryHistory: EntryHistory = {
  entryHistoryNo: 0,
  entryHistoryType: '',
  entryHistoryTitle: '',
  entryHistoryEntryDateTime: '',
  entryHistoryHoldDateTime: '',
  entryHistoryDistrict: '',
  // [phase2] start by zhangxp
  entryHistoryArea: '',
  // [phase2] end by zhangxp
  entryHistoryViewingStatus: '',
  entryHistoryCancel: false,
  entryHistoryCancelFlag: '',
  entryHistoryCancelTime: '',
  entryHistoryCancelType: '',
  entryId: 0,
  manualRegistrationFlag: '',
  manualRegistrationFlag2: ''
}

/** アンケートエリア */
export const question: Question = {
  questionType: '',
  questionMediumBadge: '',
  questionEntryBoxName: '',
  questionEntryDateTime: '',
  questionList: [],
}
export const questionList: QuestionList = {
  questionNo: 0,
  questionContent: '',
  questionAnswer: '',
  questionRequiredFlag: false,
  questionInvisibleFlag: false,
}

/** プロフィールエリア */
export const mcb: Mcb = {
  mcbstatus: false,
  mcbList: [],
}

/** 適性テスト（イメジス）エリア */
export const images: Images = {
  viewFlag: false,
  imagesCheckResultsReport: false,
  imagesIntelligenceDeviationSynthesis: null,
  imagesIntelligenceDeviationCounting: null,
  imagesIntelligenceDeviationLanguage: null,
  imagesIntelligenceDeviationEnglish: null,
  imagesIntelligenceStandardCounting: null,
  imagesIntelligenceStandardLanguage: null,
  imagesIntelligenceStandardEnglish: null,
  imagesPersonalityI: null,
  imagesPersonalityM: null,
  imagesPersonalityA: null,
  imagesPersonalityG: null,
  imagesPersonalityE: null,
  imagesPersonalityS: null,
  imagesPersonalityVitality: null,
  imagesPersonalityInterpersonal: null,
  imagesPersonalityEstablishment: null,
  // Phase2 Start
  imagesPersonalityD: null,
  imagesPersonalityW: null,
  // Phase2 End
  imagesBirthday: '',
  imagesUpdateDate: '',
  imagesUpdatePath: '',
}

/** 適性テスト（GAB）エリア */
export const gab: Gab = {
  viewFlag: false,
  gabOpenClose: '',
  gabCheckResultsReport: false,
  gabTestLanguage: '',
  gabExamDateCounting: '',
  gabExamDateLanguage: '',
  gabExamDateOpq: '',
  gabIntelligenceDeviationSynthesis: null,
  gabIntelligenceDeviationCounting: null,
  gabIntelligenceDeviationLanguage: null,
  gabIntelligenceStandardSynthesis: null,
  gabIntelligenceStandardCounting: null,
  gabIntelligenceStandardLanguage: null,
  gabManagementQualities: null,
  gabAbilityVitality: null,
  gabAbilityPerPerson: null,
  gabAbilityTeamwork: null,
  gabAbilityCreativeThinking: null,
  gabAbilityProblemSolving: null,
  gabAbilitySituationalAdaptability: null,
  gabAbilityPressureResistance: null,
  gabAbilityOrganizingAbility: null,
  gabAbilityLeadership: null,
  gabJobAptitudeSales1: null,
  gabJobAptitudeSales2: null,
  gabJobAptitudeStaff: null,
  gabJobAptitudeGeneralSecretary: null,
  gabJobAptitudeEngineer: null,
  gabJobAptitudeResearchDevelopment: null,
  gabJobAptitudeCustomersCounterCallCenter: null,
  gabOpqPersuasiveness: null,
  gabOpqExtrovert: null,
  gabOpqHumility: null,
  gabOpqConcreteThings: null,
  gabOpqHuman: null,
  gabOpqConceptuality: null,
  gabOpqElaborate: null,
  gabOpqAnxiousMind: null,
  gabOpqOptimistic: null,
  gabOpqCompetitive: null,
  gabOpqLeadership: null,
  gabOpqFriendliness: null,
  gabOpqConsultativeness: null,
  gabOpqData: null,
  gabOpqOrthodox: null,
  gabOpqCreative: null,
  gabOpqMeticulous: null,
  gabOpqTough: null,
  gabOpqCritical: null,
  gabOpqAscension: null,
  gabOpqUniqueness: null,
  gabOpqSocial: null,
  gabOpqTrouble: null,
  gabOpqAestheticValue: null,
  gabOpqChangeOriented: null,
  gabOpqPlanning: null,
  gabOpqMargin: null,
  gabOpqSuppression: null,
  gabOpqAbilityTakeAction: null,
  gabOpqDetermination: null,
  gabConsistency: null,
  gabUpdateDate: '',
  gabUpdatePath: '',
}

/** 適性テスト（OPQ）エリア */
export const opq: Opq = {
  viewFlag: false,
  opqOpenClose: '',
  opqCheckResultsReport: false,
  opqExamDate: '',
  opqManagementQualities: null,
  opqAbilityVitality: null,
  opqAbilityPerPerson: null,
  opqAbilityTeamwork: null,
  opqAbilityCreativeThinking: null,
  opqAbilityProblemSolving: null,
  opqAbilitySituationalAdaptability: null,
  opqAbilityPressureResistance: null,
  opqAbilityOrganizingAbility: null,
  opqAbilityLeadership: null,
  opqJobAptitudeSales1: null,
  opqJobAptitudeSales2: null,
  opqJobAptitudeStaff: null,
  opqJobAptitudeGeneralSecretary: null,
  opqJobAptitudeEngineer: null,
  opqJobAptitudeResearchDevelopment: null,
  opqJobAptitudeCustomersCounterCallCenter: null,
  opqOpqPersuasiveness: null,
  opqOpqExtrovert: null,
  opqOpqHumility: null,
  opqOpqConcreteThings: null,
  opqOpqHuman: null,
  opqOpqConceptuality: null,
  opqOpqElaborate: null,
  opqOpqAnxiousMind: null,
  opqOpqOptimistic: null,
  opqOpqCompetitive: null,
  opqOpqLeadership: null,
  opqOpqFriendliness: null,
  opqOpqConsultativeness: null,
  opqOpqData: null,
  opqOpqOrthodox: null,
  opqOpqCreative: null,
  opqOpqMeticulous: null,
  opqOpqTough: null,
  opqOpqCritical: null,
  opqOpqAscension: null,
  opqOpqUniqueness: null,
  opqOpqSocial: null,
  opqOpqTrouble: null,
  opqOpqAestheticValue: null,
  opqOpqChangeOriented: null,
  opqOpqPlanning: null,
  opqOpqMargin: null,
  opqOpqSuppression: null,
  opqOpqAbilityTakeAction: null,
  opqOpqDetermination: null,
  opqConsistency: null,
  opqUpdateDate: '',
  opqUpdatePath: '',
}

/** 適性テスト（CAB）エリア */
export const cab: Cab = {
  viewFlag: false,
  cabOpenClose: '',
  cabCheckResultsReport: false,
  cabTestLanguage: '',
  cabExamDateCab1: '',
  cabExamDateCab2: '',
  cabExamDateCab3: '',
  cabExamDateCab4: '',
  cabExamDateOpq: '',
  cabIntelligenceDeviationSynthesis: null,
  cabIntelligenceDeviationCab1: null,
  cabIntelligenceDeviationCab2: null,
  cabIntelligenceDeviationCab3: null,
  cabIntelligenceDeviationCab4: null,
  cabIntelligenceStandardSynthesis: null,
  cabIntelligenceStandardCab1: null,
  cabIntelligenceStandardCab2: null,
  cabIntelligenceStandardCab3: null,
  cabIntelligenceStandardCab4: null,
  cabComputerAptitudePg: null,
  cabComputerAptitudeSe: null,
  cabComputerAptitudeCe: null,
  cabComputerAptitudePm: null,
  cabAbilityVitality: null,
  cabAbilityPerPerson: null,
  cabAbilityTeamwork: null,
  cabAbilityCreativeThinking: null,
  cabAbilityProblemSolving: null,
  cabAbilitySituationalAdaptability: null,
  cabAbilityPressureResistance: null,
  cabAbilityOrganizingAbility: null,
  cabAbilityLeadership: null,
  cabOpqPersuasiveness: null,
  cabOpqExtrovert: null,
  cabOpqHumility: null,
  cabOpqConcreteThings: null,
  cabOpqHuman: null,
  cabOpqConceptuality: null,
  cabOpqElaborate: null,
  cabOpqAnxiousMind: null,
  cabOpqOptimistic: null,
  cabOpqCompetitive: null,
  cabOpqLeadership: null,
  cabOpqFriendliness: null,
  cabOpqConsultativeness: null,
  cabOpqData: null,
  cabOpqOrthodox: null,
  cabOpqCreative: null,
  cabOpqMeticulous: null,
  cabOpqTough: null,
  cabOpqCritical: null,
  cabOpqAscension: null,
  cabOpqUniqueness: null,
  cabOpqSocial: null,
  cabOpqTrouble: null,
  cabOpqAestheticValue: null,
  cabOpqChangeOriented: null,
  cabOpqPlanning: null,
  cabOpqMargin: null,
  cabOpqSuppression: null,
  cabOpqAbilityTakeAction: null,
  cabOpqDetermination: null,
  cabConsistency: null,
  cabUpdateDate: '',
  cabUpdatePath: '',
}

/** 画面用共通定数定義 */
export const cmnContents = {
  /** MCAXS040 */
  SCREEN_ID: 'MCAXS040',
  /** - */
  HYPHEN: '-',
  /** '' */
  BLANK: '',
  /** [01]一括 */
  CODE_BULK: '01',
  /** [02]個別 */
  CODE_INDIVIDUAL: '02',
  /** [03]セミナー */
  CODE_SEMINAR: '03',
  /** [04]イベント */
  CODE_EVENT: '04',
  /** 一括 */
  STR_BULK: '一括',
  /** 個別 */
  STR_INDIVIDUAL: '個別',
  /** セミナー */
  STR_SEMINAR: 'セミナー',
  /** イベント */
  STR_EVENT: 'イベント',
  /** 取込 */
  IMPORT: '取込',
}

/** プロフィールエリア用定数定義 */
export const profileContents = {
  /** 不明 */
  UNKNOWN: '不明',
}

/** エントリー履歴エリア用定数定義 */
export const entryHistoryContents = {
  /** 未視聴 */
  NOT_VIEWED: '未視聴',
  /** 視聴中 */
  IN_WATCHING: '視聴中',
  /** 視聴済 */
  WATCHED: '視聴済',
  /** キャンセルフラグ_キャンセル */
  CANCEL_FLAG_CANCEL: '1',
  /** 応募経路追加 */
  APPLICATION_ROUTE_ADDITION: '応募経路追加',
  /** 画面入力 */
  SCREEN_ENTRY: '画面入力',
  /** CSVアップロード */
  CSV_UPLOAD: 'CSVアップロード',
  /** 参加あり */
  PARTICIPATION: '参加あり',
  /** WEB面談*/
  WEB_INTERVIEW: "2",
}

/** 適性テストエリア用定数定義 */
export const webContents = {
  /** 手動登録 */
  MANUAL: '手動登録',
  /** 自動登録 */
  AUTOMATIC: '自動登録',
}

export const mcbContents = {
  /**未リクエスト */
  NOT_REQUEST: "0",
  /**未提出 */
  NOT_SUBMITTED: "1",
  /**受領済み */
  RECEIVED: "2",
  /**提出済み */
  SUBMITTED: "3",
  /**受領エラー */ 
  RECEIVED_ERROR: "4",
  /**強制停止無し */
  NOT_FORCESTOP: "0",
  /**強制停止 */
  FORCESTOP:"1",
  /**受付 */
  ACCEPT: "1",
  /**既読 */
  READ: "1",
  /**未読 */
  UNREAD: "0",
  SUCCESS: "0",
  FAILURE: "1",
  /**対象 */
  TARGET: "1",
  ENTRY_SHEET: "エントリーシート",
  REMUSE: "履歴書",
  RESEARCH_SUMMARY: "研究概要書",
  ORIGINAL_QUESTION: "オリジナル設問",
  FILE_SUBMISSION: "ファイル",
  DOWNLOAD: "提出データのPDF出力",
  MESSAGE_MAKE: "My CareerBoxメッセージ作成",
  REQUEST_CHANGE: "リクエスト内容変更",
  /**提出リクエスト済み */
  SUBMISSION_REQUESTED: "1",
  /**利用中 */
  IN_USE: "2",
  /**退会 */
  DEACTIVATED: "3",
  /**提出締切 */
  SUBMISSION_DEADLINE: "0",
  /**依頼日から */
  FROM_DATE_OF_REQUEST: "1",
  /**随時受付終了 */
  RECEPTION_ENDS_AT_ANY_TIME: "2",
}

/** メッセージ一覧エリア用定数定義 */
export const msgContents = {
  /** [2]企業からのメッセージ(採用担当者から見て送信) */
  DIVISION_SEND: '2',
  /** [1]応募者からのメッセージ(採用担当者から見て受信) */
  DIVISION_RECEIV: '1',

  /** [20]送信予約 */
  STATUS_SEND_RESERVATION: '20',
  /** [30]送信済み */
  STATUS_SEND: '30',

  /** [0]未読 */
  FLAG_READ: '0',
  /** [0]未返信 */
  FLAG_UNREPLIED: '0',

  /** [20]電子メール */
  SEND_TYPE_E_MAIL: '20',

  /** 送信中 */
  SENDING: '送信中',
    /** 送信 */
  SEND: '送信',
  /** 送信予約 */
  SEND_RESERVATION: '送信予約',
  /** 受信 */
  RECEIVING: '受信',
  /** 未読 */
  UNREAD: '未読',
  /** 既読 */
  READ: '既読',
  /** 未返信 */
  UNREPLIED: '未返信',
  /** 返信済み */
  REPLIED: '返信済み',
  /** e-mail */
  E_MAIL: 'e-mail',

  STRING_0: '0',
  MESSAGETYPE_02: 'サンクス',
  MESSAGETYOE_20: 'スカウト',
}

/** MCBメッセージ一覧エリア用定数定義 */
export const mcbMsgContents = {
  /** [2]企業からのメッセージ(採用担当者から見て送信) */
  DIVISION_SEND: '2',
  /** [1]応募者からのメッセージ(採用担当者から見て受信) */
  DIVISION_RECEIV: '1',

  /** [20]送信予約 */
  STATUS_SEND_RESERVATION: '20',
  /** [30]送信済み */
  STATUS_SEND: '30',

  /** [0]未読 */
  FLAG_READ: '0',
  /** [0]未返信 */
  FLAG_UNREPLIED: '0',

  /** [20]電子メール */
  SEND_TYPE_E_MAIL: '20',

  /** 送信中 */
  SENDING: '送信中',
    /** 送信 */
  SEND: '送信',
  /** 送信予約 */
  SEND_RESERVATION: '送信予約',
  /** 受信 */
  RECEIVING: '受信',
  /** 未読 */
  UNREAD: '未読',
  /** 既読 */
  READ: '既読',
  /** 未返信 */
  UNREPLIED: '未返信',
  /** 返信済み */
  REPLIED: '返信済み',
  /** e-mail */
  E_MAIL: 'e-mail',

  STRING_0: '0',
  SUBMISSION_REQUEST: '51',
  DEADLINE_CHANGE: '52',
  FORCE_STOP: '53',
  THANKS_MAIL: '54',
  MCB_MESSAGE: '55',
}

/** MCB操作エラー用定数定義 */
export const mcbErrorContents = {
  /** PDF出力 */
  OUTPUT_PDF: 'PDF出力',
  /** メッセージ送信 */
  SEND_MESSAGE: 'メッセージ送信',
  /** 変更 */
  REQUEST_CONTENTS_CHANGE: '変更'
}