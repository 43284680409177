import { MCBHS010RequestConfirmRequest } from 'types/MCBHS010/MCBHS010RequestConfirmRequest'
import { MCBHS010RequestDraftRequest } from 'types/MCBHS010/MCBHS010RequestDraftRequest'

export interface InitResult {
  [key: string]: any
  companyContractIdList: number[]
  jobSeekerIdList: string[]
  jobSeekerByCompanyIdList: string[]
  selectionManagementIdList: string[]
  messageSendId: string
  draftSubmissionTemplateSettingId: number
  destinationInfo: MCBHS010SendTargetInfoOutDto[]
  senderCompanyName: string
  senderMailAddress: string
  submissionDeadlineKind: string// 提出締切_種別	
  thanksMailSettingFlag: string  //サンクスメール設定フラグ
  resubmitReceptionFlag: string// 再提出受付フラグ
  attachment: MCBHS010DraftAttachmentResultDto[]
  draftHomepage: MCBHS010DraftHomepageResultDto[]
  draftSubmissionResult: MCBHS010DraftSubmissionResultDto
  submissionRequestTemplate: MCBHS010SubmissionRequestTemplateResultDto[]
  submissionTemplateAttachment: MCBHS010SubmissionTemplateAttachmentDto[]
  signature: MCBHS010SignResultDto[]
  companyId: number
  forbiddenWord: string[]
  submissionsLink: string // 提出物リンク
  requestExampleLink: string // 提出リクエスト文例リンク
  thanksMailTemplateLink: string// サンクスメールテンプレートリンク
  thanksMailExampleLink: string // サンクスメール文例リンク
  referenceLink: string// 問い合わせ先リンク
  personalDataHandlingLink: string // 個人情報の取り扱いリンク   
  authToken: string // MCB認証トークン
  attachmentFileRegistrationMax: number//添付ファイル登録数上限
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaJson: string
  filterCondition: string
  sendReplyImpossibleTime: MCBHS010SendReplyImpossibleTimeDto[]
  submissionContentList: MCBHS010SubmissionsContentList[] //提出物内容リスト
  thanksMailTemplateContentList:MCBHS010ThanksMailTemplateList[] // サンクスメールテンプレート内容リスト
  contactContentList: MCBHS010ContactList[] // お問い合わせ先内容リスト
  privacyPolicyContentList: MCBHS010MyCareerBoxPrivacyPolicyList[] // 個人情報の取り扱い内容リスト
  // 26P #80795、#88225 start
  annual: string // 年度
  annualList: string // 対象年度リスト
  annualCs: string // チェックサム
  // 26P #80795、#88225 end
}
export interface MCBHS010SubmissionsContentList {
  submissionsContentId: number // 提出物内容ID
  submissionsNameForCompany: string // 提出物内容名（企業管理用）
  submissionsNameForStudent: string // 提出物内容名（学生）
  entrySheetResumeSubmissionFlg: boolean // エントリーシート/履歴書提出フラグ
  entrySheetResumeSubmissionCategory: number //エントリーシート/履歴書選択区分
  researchOverviewsSubmissionFlg: boolean // 研究概要書提出フラグ
  originalQuestionSubmissionFlg: boolean // オリジナル設問提出フラグ
  fileSubmissionFlg: boolean // ファイル提出フラグ
  hiddenFlg: boolean // 非表示フラグ
  modifiedDate: string  // 更新日時
}
export interface MCBHS010ThanksMailTemplateList {
  thanksMailTemplateId: number  // サンクスメールテンプレートID
  thanksMailTemplateRegisteredName: string //サンクスメールテンプレート件名
  thanksMailTemplateSubject: string //サンクスメールテンプレート件名
  thanksMailTemplateBody: string //サンクスメールテンプレート本文
  modified: string //更新日時
}
export interface MCBHS010ContactList {
  inquiryId: number // お問い合わせ先ID
  inquiryRegisteredName: string// お問い合わせ先名
  companyName: string// 企業名
  departmentNameAndManagerName: string// 部署名／担当者氏名
  telNumber: string// 電話番号
  emailAddress: string// メールアドレス
  hiddenFlg: boolean// 非表示フラグ
  modifiedDate: string// 更新日時
}
export interface MCBHS010MyCareerBoxPrivacyPolicyList {
  myCareerBoxPrivacyPolicyId: number // My CareerBoxに関する個人情報の取り扱いID
  privacyPolicyRegisterdName: string // My CareerBoxに関する個人情報の取り扱い名
  hiddenFlg: boolean // 非表示フラグ
  modifiedDate: string// 更新日時
}
export interface MCBHS010MessageResultDto {
  [key: string]: any
  messageSendId: number
  messageType: string
  status: string
}

export interface MCBHS010SendTargetInfoOutDto {
  [key: string]: any
  entryId: string  // エントリーID
  jobSeekerByCompanyId: string
  jobSeekerIdForDisplay: string // 表示用求職者ID
  jobSeekerId: string // 求職者ID
  familyName: string // 姓
  name: string // 名
  birthdate: Date // 生年月日
  mailAddress: string // メールアドレス
  memberType: string // 会員種別
  unsubscribedJobSeekerId: number// 求職者退会情報ID
  mcbUseStatus: number// MCB利用ステータス
  schoolName: string // 学校名
  facultyName: string // 学部名
  subjectName: string // 学科名
  entryReceptionTime: string // 応募日時
  coFormerJobSeekerId: string // 連携元求職者識別キー
  viewEndTime: Date //閲覧終了日時
  // #MCB1.5次開発 #8997 START
  //閲覧権限
  readableFlag: string
  // #MCB1.5次開発 #8997 END
}

export interface MCBHS010DraftMessageResultDto {
  [key: string]: any
  messageId: number
  mediaFlag: number
  companyContractMediaId: number
  entryId: number
  selectionManagementId: number
  messageType: string
  destinationDivision: string
  senderCompanyName: string
  requestSubject: string
  body: string
  homePage1Title: string
  homePage1Url: string
  homePage2Title: string
  homePage2Url: string
  homePage3Title: string
  homePage3Url: string
  sendTimeFlag: string
  sendPlanTime: Date | null
  bulkSendFlag: string
  messageSendId: number
  replyFormerMessageId: number
  senderMailAddress: string
  messageTemplateSettingId: number
  sysVersionNumber: number
}

// 維持保守 #9496 start
export interface MCBHS010ConfirmOutDto {
  sameContentCheckOutList: MCBHS010SameContentCheckOutDto[]
  blackListJobSeekerList:MCBHS010BlackListJobSeekerOutDto[]
  mcbApiErrorList: McbApiErrorDto[]
}

export interface McbApiErrorDto {
  errorCode: string
  errorMessage: string
}

export interface MCBHS010BlackListJobSeekerOutDto {
  jobSeekerByCompanyId: number // 企業別求職者ID
  jobSeekerIdForDisplay: string  // 表示用求職者ID
}
// 維持保守 #9496 end

export interface MCBHS010SameContentCheckOutDto {
  [key: string]: any
  jobSeekerByCompanyId: number // 企業別求職者ID
  jobSeekerIdForDisplay: string  // 表示用求職者ID
  // #MCB1.5次開発 #9126 START
  mycareercd: string
  // #MCB1.5次開発 #9126 END
}

interface MCBHS010DraftAttachmentResultDto {
  [key: string]: any
  sequence: number
  attachmentName: string
  fileManagementId: string
}

interface MCBHS010SubmissionTemplateAttachmentDto {
  [key: string]: any
  requestTemplateSettingId: number
  sequence: number
  attachmentName: string
  fileManagementId: string
}

interface MCBHS010DraftHomepageResultDto {
  [key: string]: any
  sequence: number
  homePageTitle: string
  homePageUrl: string
}

interface MCBHS010DraftSubmissionResultDto {
  submissionRequestId:string //提出リクエストID
  mcbSubmissionRequestId: string // MCB提出リクエストID
  submissionContentId: number// 提出物内容ID
  submissionContentName:string// 提出物内容名
  submissionContentNameStudent: string// 提出物内容名（学生画面表示用）   
  submissionEntrySheetFlag: string // 提出物エントリシートフラグ
  submissionResumeFlag: string // 提出物履歴書フラグ
  submissionResearchSummaryFlag: string // 提出物研究概要書フラグ
  submissionOriginalQuestionFlag: string // 提出物オリジナル設問フラグ
  submissionFileSubmissionFlag: string // 提出物ファイル提出フラグ
  submissionDeadlineKind: string// 提出締切_種別	
  submissionDeadlineDay: string// 提出締切_日
  submissionDeadlineHour: string// 提出締切_時
  resubmitReceptionFlag: string// 再提出受付フラグ
  senderCompanyName: string // 送信元会社名
  senderMailAddress: string // 送信元メールアドレス
  contactId: number // お問い合わせ先ID
  contactName: string // お問い合わせ先名
  contactCompanyName: string // お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: string // お問い合わせ先内容部署名担当者氏名
  contactTelephoneNumber: string // お問い合わせ先内容電話番号
  contactMailAddress: string // お問い合わせ先内容メールアドレス
  subject: string // 件名
  body: string // 本文
  thanksMailSettingFlag: string  //サンクスメール設定フラグ
  thanksMailTemplateId: number // サンクスメールテンプレートID
  thanksMailTemplateName: string // サンクスメールテンプレート名	
  thanksSubject: string // サンクスメール件名
  thanksBody: string // サンクスメール本文	
  privacyPolicyId: number // 個人情報の取り扱いID
  privacyPolicyName: string // 個人情報の取り扱い名	
  submissionRequestUrl: string // 提出リクエストURL	
  sendTimeFlag: string // 送信日時フラグ
  sendPlanTime: Date// 送信予定日時
}

interface MCBHS010SubmissionRequestTemplateResultDto {
  [key: string]: any
  submissionRequestTemplateSettingId: string
  templateName: string
  templateType: string
  destinationDivision: string
  submissionContentId: number
  submissionContentName: string
  submissionContentNameStudent: string
  submissionEntrySheetFlag: string
  submissionResumeFlag: string
  submissionResearchSummaryFlag: string
  submissionOriginalQuestionFlag: string
  submissionFileSubmissionFlag: string
  submissionDeadlineKind: string
  submissionDeadlineDay: string
  submissionDeadlineHour: string
  resubmitReceptionFlag: string
  homePageTitle1: string
  homePageUrl1: string
  homePageTitle2: string
  homePageUrl2: string
  homePageTitle3: string
  homePageUrl3: string
  thanksMailSettingFlag: string
  thanksMailTemplateId: number
  thanksMailTemplateName: string
  thanksSubject: string
  thanksBody: string
  contactId: number
  contactName: string
  contactCompanyName: string
  contactDepartmentNameAndManagerName: string
  contactTelephoneNumber: string
  contactMailAddress: string
  privacyPolicyId: number
  privacyPolicyName: string
  senderCompanyName: string
  senderMailAddress: string
  requestSubject: string
  requestText: string
  sequence: number
  sysVersionNumber: string
}




interface MCBHS010SignResultDto {
  [key: string]: any
  registrationName: string
  signSettingId: number
  signature: string
}

interface MCBHS010SendReplyImpossibleTimeDto {
  [key: string]: any
  rowNoStr: string// 項番
  // #MCB1.5次開発 #8986 START
  functionalDivisionStr: string
  // #MCB1.5次開発 #8986 END
  startDateStr: string// 開始日
  endDateStr: string// 終了日
}


const destinationResultDto: MCBHS010SendTargetInfoOutDto = {
  entryId: ''  ,// エントリーID
  jobSeekerByCompanyId: '',
  jobSeekerIdForDisplay: '', // 表示用求職者ID
  jobSeekerId:'', // 求職者ID
  familyName: '', // 姓
  name: '', // 名
  birthdate: new Date ,// 生年月日
  mailAddress: '', // メールアドレス
  memberType: '', // 会員種別
  unsubscribedJobSeekerId: 0,// 求職者退会情報ID
  mcbUseStatus: 0,// MCB利用ステータス
  schoolName: '', // 学校名
  facultyName: '', // 学部名
  subjectName: '', // 学科名
  entryReceptionTime: '', // 応募日時
  coFormerJobSeekerId: '', // 連携元求職者識別キー
  // #MCB1.5次開発 #8997 START
  viewEndTime: new Date, //閲覧終了日時
  readableFlag: '',
  // #MCB1.5次開発 #8997 END
}

const draftSubmissionResultDto: MCBHS010DraftSubmissionResultDto = {
  submissionRequestId:'' , //提出リクエストID
  mcbSubmissionRequestId: '',
  submissionContentId: 0,// 提出物内容ID
  submissionContentName:'',// 提出物内容名
  submissionContentNameStudent: '',// 提出物内容名（学生画面表示用）
  submissionEntrySheetFlag: '',
  submissionResumeFlag: '',
  submissionResearchSummaryFlag: '',
  submissionOriginalQuestionFlag: '',
  submissionFileSubmissionFlag: '',
  submissionDeadlineKind: '',// 提出締切_種別	
  submissionDeadlineDay: '',// 提出締切_日
  submissionDeadlineHour: '',// 提出締切_時
  resubmitReceptionFlag: '',// 再提出受付フラグ
  senderCompanyName: '', // 送信元会社名
  senderMailAddress: '', // 送信元メールアドレス
  contactId: 0, // お問い合わせ先ID
  contactName: '', // お問い合わせ先名
  contactCompanyName: '', // お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: '', // お問い合わせ先内容部署名担当者氏名
  contactTelephoneNumber: '', // お問い合わせ先内容電話番号
  contactMailAddress: '', // お問い合わせ先内容メールアドレス
  subject: '', // 件名
  body: '', // 本文
  thanksMailSettingFlag: '', //サンクスメール設定フラグ
  thanksMailTemplateId: 0, // サンクスメールテンプレートID
  thanksMailTemplateName: '', // サンクスメールテンプレート名	
  thanksSubject: '', // サンクスメール件名
  thanksBody: '', // サンクスメール本文	
  privacyPolicyId: 0, // 個人情報の取り扱いID
  privacyPolicyName: '', // 個人情報の取り扱い名	
  submissionRequestUrl: '', // 提出リクエストURL	
  sendTimeFlag: '', // 送信日時フラグ
  sendPlanTime: new Date// 送信予定日時
}

/** 送信対象確認エリア */
export interface SendTargetConfirmation {
  [key: string]: any
  destinationSelection: number
  sendTarget: SendTarget[]
  searchedCondition: string
}

/** 送信対象 */
export interface SendTarget {
  [key: string]: any
  fullName: string
  mailAddress: string
  age: number
  jobSeekerId: string
  mynaviMemberInfo: string
  applicationType: string
  jobTitle: string
  schoolName: string
  facultyName: string
  subjectName: string
  applicationDate: string
  progress: string
  selectionFlow: string
  invisibleDestination: string
  deleteDestination: string
}

/** メッセージ入力エリア  */
export interface MessageInput {
  [key: string]: any
  headingMessageInput: string
  headingMessageInputDescription: string
  exampleLink: string
  senderCompanyName: string
  senderMailAddress: string
  requestSubject: string
  body: string
  targetInput: string
  attachment: File[]
  attachmentId: string[]
  templateAttachmentId: string[]
  homePageTitle1: string
  homePageTitle2: string
  homePageTitle3: string
  homePageUrl1: string
  homePageUrl2: string
  homePageUrl3: string
  previewSubject: string
  previewBody: string
}

export interface PreviewResult {
  jobSeekerInfoList: PreviewJobSeekerResult[]
  returnRequestBody: string
  returnThanksBody: string
}

export interface PreviewJobSeekerResult {
  entryId: number
  familyName: string
  name: string
  jobSeekerIdForDisplay: string
  submissionRequestUrl: string
}

const previewResult: PreviewResult = {
  jobSeekerInfoList: [],
  returnRequestBody: '',
  returnThanksBody: ''
}
const initResult: InitResult = {
  companyContractIdList: [],
  jobSeekerIdList: [],
  jobSeekerByCompanyIdList: [],
  selectionManagementIdList: [],
  messageSendId: '',
  draftSubmissionTemplateSettingId: 0,
  destinationInfo: [],
  senderCompanyName: '',
  senderMailAddress: '',
  attachment: [],
  draftHomepage: [],
  draftSubmissionResult: draftSubmissionResultDto,
  submissionRequestTemplate: [],
  submissionTemplateAttachment: [],
  signature: [],
  companyId: 0,
  forbiddenWord: [],
  submissionsLink: '', // 提出物リンク
  requestExampleLink: '', // 提出リクエスト文例リンク
  thanksMailTemplateLink: '',// サンクスメールテンプレートリンク
  thanksMailExampleLink: '', // サンクスメール文例リンク
  referenceLink: '',// 問い合わせ先リンク
  personalDataHandlingLink: '', // 個人情報の取り扱いリンク
  authToken: '', // MCB認証トークン
  attachmentFileRegistrationMax: 0,//添付ファイル登録数上限
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaJson: '',
  filterCondition: '',
  sendReplyImpossibleTime: [],
  submissionContentList: [], //提出物内容リスト
  thanksMailTemplateContentList:[], // サンクスメールテンプレート内容リスト
  contactContentList: [] ,// お問い合わせ先内容リスト
  privacyPolicyContentList: [], // 個人情報の取り扱い内容リスト
  submissionDeadlineKind: '',// 提出締切_種別	
  thanksMailSettingFlag: '',  //サンクスメール設定フラグ
  resubmitReceptionFlag: '',// 再提出受付フラグ
  // 26P #80795、#88225 start
  annual: '', // 年度
  annualList: '', // 対象年度リスト
  annualCs: '', // チェックサム
  // 26P #80795、#88225 end
}

/** タイトルエリア */
export interface TitleCon {
  [key: string]: any
  messageTemplate: MessageTemplateCon[]
}

export interface MessageTemplateCon {
  [key: string]: any
  messageTemplateSettingId: number
  templateName: string
  templateType: string
  destinationDivision: string
  senderCompanyName: string
  senderMailAddress: string
  requestSubject: string
  body: string
}


export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  sysVersionNumber: number             // sysバージョン番号
}


export interface AttachmentCon {
  attachmentFileName: string
  attachmentId: string
  attachmentName: string
}

export interface HomePageCon {
  homePageTitle: string
  homePageUrl: string
}

export interface RecruitmentManagementFlagCon {
  [key: string]: any
  recruitmentManagementFlagSettingId: number
  recruitmentManagementFlagName: string
}

export interface SignatureCon {
  [key: string]: any
  registrationName: string
  signSettingId: string
  signature: string
}



const searchCriteriaInfo: SearchCriteriaInfo = {
  entrySearchCriteriaSettingId: '',
  searchCriteriaName: '',
  searchCriteria: '',
  sysVersionNumber: 0,
}


const recruitmentManagementFlagCon: RecruitmentManagementFlagCon[] = []

const signatureCon: SignatureCon[] = []


const requestConfirmRequest: MCBHS010RequestConfirmRequest = {
  requestTemplateSettingId: '',
  submissionRequestId: '',
  mcbSubmissionRequestId: '',
  destinationId: [],
  searchCriteriaName: '',
  searchCriteria: '',
  // 9174 start
  searchCriteriaJson: '',
  // 9174 end
  submissionsSelectId: 0,
  submissionsNameForCompany: '',
  submissionsNameForStudent: '',
  submissionsDetails: '',
  submissionEntrySheetFlag: 0,
  submissionResumeFlag: 0,
  submissionResearchSummaryFlag: 0,
  submissionOriginalQuestionFlag: 0,
  submissionFileSubmissionFlag: 0,
  limitDateDetermineFlag: 0,
  determineDateTime: '',
  requestDayTimeLaterDay: '',
  requestDayTimeToTime: '',
  anytimeReceptionEndDateTime: '',
  reRequestReceptFlag: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  requestSubject: '',
  requestText: '',
  requestAttachment: [], 
  requestAttachmentId: [],
  requestAttachmentName: [],
  requestTemplateAttachmentId: [],
  requestTemplateAttachmentName: [],
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  thanksMessageSetFlag: 0,
  thanksMessageTemplateId: 0,
  thanksMessageTemplateName: '',
  thanksSubject: '',
  thanksText: '',
  studentReferenceId: 0,
  contactName: '',
  companyName: '',
  departmentNameAndManagerName: '',
  telephoneNumber: '',
  mailAddress: '',
  personalDataHandlingId: 0,
  personalDataHandling: '',
  sendTimerFlag: 0,
  sendPlanTime: '',
  entryIdList: [],
  selectionManagementIdList: [],
  jobSeekerByCompanyIdList: [],
  sendReplyImpossibleTimeList: [],
  sendTarget: [],
  status: '',
  submissionsContentModified: '',
  contactModified: '',
  // #MCB1.5次開発 #8744 #8600 START
  jobSeekerIdList: [],
  // #MCB1.5次開発 #8744 #8600 END
  myCareerBoxPrivacyPolicyModified:''
}

const requestDraftRequest: MCBHS010RequestDraftRequest = {
  companyContractIdList: [],
  jobSeekerIdList: [],
  submissionRequestId: '',
  requestTemplateSettingId: '',	// 提出リクエストテンプレート設定ID
  destinationId: [],	// 送信対象ID
  searchCriteriaName: '',	// 検索条件名
  searchCriteria: '',	// 検索条件
  searchCriteriaJson:'', //検索条件Json
  submissionsSelectId: '',	// 提出物内容選択ID
  submissionsNameForCompany: '',	// 提出物内容名(企業管理用)
  submissionsNameForStudent: '',	// 提出物内容名(学生画面表示用)
  submissionsDetails: '',	// 提出物
  submissionEntrySheetFlag: 0,
  submissionResumeFlag: 0,
  submissionResearchSummaryFlag: 0,
  submissionOriginalQuestionFlag: 0,
  submissionFileSubmissionFlag: 0,
  limitDateDetermineFlag: 0,	// 提出締切指定フラグ
  determineDateTime: '',	// 提出締切指定日時
  requestDayTimeLaterDay: '',	// 依頼日から○日後
  requestDayTimeToTime: '',	// 依頼日から△時まで
  anytimeReceptionEndDateTime: '',	// 随時受付終了日時
  reRequestReceptFlag: 0,	// 再提出受付フラグ
  senderCompanyName: '',	// 送信元会社名
  senderMailAddress: '',	// 送信元メールアドレス
  requestSubject: '',	// (提出リクエスト)件名
  requestText: '',	// (提出リクエスト)本文
  requestAttachment: [],	// 添付ファイル
  requestAttachmentId: [],	// 提出リクエスト添付ファイルID
  requestAttachmentName: [],	// 提出リクエスト添付ファイル名
  requestTemplateAttachmentId: [],	// 提出リクエストテンプレート添付ファイルID
  requestTemplateAttachmentName: [],	// 提出リクエストテンプレート添付ファイル名
  homepageTitle1: '',	// ホームページタイトル１
  homepageTitle2: '',	// ホームページタイトル２
  homepageTitle3: '',	// ホームページタイトル３
  homepageUrl1: '',	// ホームページURL１
  homepageUrl2: '',	// ホームページURL２
  homepageUrl3: '',	// ホームページURL３
  thanksMessageSetFlag: 0,
  thanksMessageTemplateId: '',	// サンクスメッセージテンプレートID
  thanksMessageTemplateName: '',	// サンクスメッセージテンプレート名
  thanksSubject: '',	// （サンクス）件名
  thanksText: '',	// （サンクス）本文
  studentReferenceId: '',	// 問い合わせ先選択ID
  contactName: '',	// 問い合わせ先
  companyName: '',	// (問い合わせ先)企業名
  departmentNameAndManagerName: '',	// (問い合わせ先)部署名／担当者氏名
  telephoneNumber: '',	// (問い合わせ先)電話番号
  mailAddress: '',	// (問い合わせ先)メールアドレス
  personalDataHandlingId: '',	// 個人情報の取り扱い選択ID
  personalDataHandling: '',	// 個人情報の取り扱い
  sendTimerFlag: 0,	// タイマー送信するフラグ
  sendPlanTime: '',	// タイマー日時
  manualRegistrationFlag: [],	// 手動登録フラグ
  actionDraft: '',	// 動作
  sysVersionNumber: 0,	// sysバージョン番号
  entryIdList: [],
  selectionManagementIdList: [],
  jobSeekerByCompanyIdList:[],
  messageSendId:'',
}

export {
  initResult,
  previewResult,
  destinationResultDto,
  requestConfirmRequest,
  requestDraftRequest,
}
