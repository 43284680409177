import request from 'utils/request'
import { MCAJS030UpdateRequest } from 'types/MCAJS030/MCAJS030UpdateRequest'
import { MCAJS030IdRequest } from 'types/MCAJS030/MCAJS030IdRequest'
import { MCAJS030CustomizeProgressRequest } from 'types/MCAJS030/MCAJS030CustomizeProgressRequest'
import { MCAJS030UpdateCustomizeProgressRequest } from 'types/MCAJS030/MCAJS030UpdateCustomizeProgressRequest'
import { MCAJS030ProgressIdRequest } from 'types/MCAJS030/MCAJS030ProgressIdRequest'
import { MCAJS030FinishRequest } from 'types/MCAJS030/MCAJS030FinishRequest'

export const updateInitSelectionFlow = (apidata: MCAJS030IdRequest) =>
  request({
    url: '/MCAJS030/init',
    method: 'post',
    data: apidata,
  })

export const updateRequest = (apidata: MCAJS030UpdateRequest) =>
  request({
    url: '/MCAJS030/update',
    method: 'post',
    data: apidata,
  })

export const finishRequest = (apidata: MCAJS030FinishRequest) =>
  request({
    url: '/MCAJS030/finish',
    method: 'post',
    data: apidata,
  })

export const deleteRequest = (apidata: MCAJS030IdRequest) =>
  request({
    url: '/MCAJS030/delete',
    method: 'post',
    data: apidata,
  })

export const createCustomizeProgressOnUpdateRequest = (
  apidata: MCAJS030CustomizeProgressRequest
) =>
  request({
    url: '/MCAJS030/createCustomizeProgress',
    method: 'post',
    data: apidata,
  })

export const updateCustomizeProgressOnUpdateRequest = (
  apidata: MCAJS030UpdateCustomizeProgressRequest
) =>
  request({
    url: '/MCAJS030/updateCustomizeProgress',
    method: 'post',
    data: apidata,
  })

export const deleteProgressOnUpdateRequest = (
  apidata: MCAJS030ProgressIdRequest
) =>
  request({
    url: '/MCAJS030/deleteProgress',
    method: 'post',
    data: apidata,
  })
