import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  targetUrl: '',
}
// MCBリプレース MCABS010 START
export interface MCABS010InitResultDto {
  authToken: string
  sourceCompanyCode: string
  url: string
  title: string
  // 26P #80795、88225 start
  annual: string
  annualList: string
  annualCs: string
  // 26P #80795、88225 end
}
// MCBリプレース MCABS010 END
const commonSettingsMenuSlice = createSlice({
  name: 'commonSettingsMenu',
  initialState,
  reducers: {
    setTargetUrl(state, action: PayloadAction<string>) {
      state.targetUrl = action.payload
      return state
    },
    // MCBリプレース MCABS010 START
    // リンク押下時
    // MCBサービス停止チェック
    checkMcbServiceStatus(state, action: PayloadAction<MCABS010InitResultDto>) {
      return state
    },
    submissionReqTemplatecheck(state, action: PayloadAction<String>) {
      return state
    },
    // MCBリプレース MCABS010 END
  },
})

// MCBリプレース MCABS010 START
export const {
  setTargetUrl,
  checkMcbServiceStatus,
  submissionReqTemplatecheck
} = commonSettingsMenuSlice.actions
// MCBリプレース MCABS010 END
export default commonSettingsMenuSlice.reducer
