import { MCBHS090InitRequest } from 'types/MCBHS090/MCBHS090InitRequest'
import { MCBHS090Request } from 'types/MCBHS090/MCBHS090Request'
import { MCBHS090DraftRequest } from 'types/MCBHS090/MCBHS090DraftRequest'

export interface InitResult {
  mcbDestinationInfo: MessageUtilMcbMessagesDetail[]
  destinationInfo: DestinationResult[]
  submissionContent: SubmissionResult
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  signature: SignResult[]
  companyId: number
  forbiddenWord: string[]
  searchCriteria: string
  searchCriteriaName: string
  searchCriteriaJson: string
  sysVersionNumber: number
  sendReplyImpossibleTime: SendReplyImpossibleTime[]
}
export interface SubmissionRequestObj {
  // 企業別求職者ID
  jobSeekerByCompanyId: string

  // 提出リクエストID
  submissionRequestId: string
}

export interface MessageUtilMcbMessagesDetail {
  entryId: string
  selectionManagementId: string
  jobSeekerId: string
  mycareercd: string
  mcbMemberId: string
  mcbSubmissionRequestId: string
  jobSeekerByCompanyId: string
  submissionRequestId: string
  coordinationFormerJobSeekerIdentificationKey: string
  notSend: boolean
  // 26KH #89293 START
  originJobSeekerId: string
  originJobSeekerByCompanyId: string
  // 26KH #89293 END
}
export interface DestinationResult {
  entryId: string
  jobSeekerIdForDisplay: string
  familyName: string
  name: string
  birthdate: Date
  mailAddress: string
  memberType: string
  unsubscribedJobSeekerId: number
  mcbUseStatus: string
  schoolName: string
  facultyName: string
  subjectName: string
  entryReceptionTime: Date
  coordinationFormerJobSeekerIdentificationKey: string
  // #MCB1.5次開発 #8997 START
  //閲覧権限
  readableFlag: string
  // #MCB1.5次開発 #8997 END
}
export interface SubmissionResult {
  [key: string]: any
  submissionRequestId: number
  submissionContentId: string
  submissionContentName: string
  submissionContentNameStudent: string
  submissionEntrySheetFlag: number
  submissionResumeFlag: number
  submissionResearchSummaryFlag: number
  submissionOriginalQuestionFlag: number
  submissionFileSubmissionFlag: number
}
const submissionResult: SubmissionResult={
  submissionRequestId: 0,
  submissionContentId: '',
  submissionContentName: '',
  submissionContentNameStudent: '',
  submissionEntrySheetFlag: 0,
  submissionResumeFlag: 0,
  submissionResearchSummaryFlag: 0,
  submissionOriginalQuestionFlag: 0,
  submissionFileSubmissionFlag: 0,
}

export interface SendReplyImpossibleTime {
  rowNoStr: string
  startDateStr: string
  // #MCB1.5次開発 #8986 START
  functionalDivisionStr: string
  // #MCB1.5次開発 #8986 END
  endDateStr: string
}

export interface SignResult {
  signSettingId: string
  registrationName: string
  signature: string
}
export interface PreviewResult {
  entryId: number
  familyName: string
  name: string
  jobSeekerIdForDisplay: string
  submissionRequestUrl: string
}

const messageInitRequest: MCBHS090InitRequest = {
  searchCriteriaName: '',
  searchCriteria: '',
  draftMessageSendID: '',
  sysVersionNumber: '',
  jobSeekerByCompanyIdSubmissionRequestIdList: [],
}
const destinationResultDto: DestinationResult = {
  entryId: '',
  jobSeekerIdForDisplay: '',
  familyName: '',
  name: '',
  birthdate: new Date(),
  mailAddress: '',
  memberType: '',
  unsubscribedJobSeekerId: 0,
  mcbUseStatus: '',
  schoolName: '',
  facultyName: '',
  subjectName: '',
  entryReceptionTime: new Date(),
  coordinationFormerJobSeekerIdentificationKey: '',
  // #MCB1.5次開発 #8997 START
  readableFlag: '',
  // #MCB1.5次開発 #8997 END
}

const initResult: InitResult = {
  mcbDestinationInfo: [],
  destinationInfo: [],
  submissionContent:submissionResult,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  signature: [],
  companyId: 0,
  forbiddenWord: [],
  searchCriteria: '',
  searchCriteriaName: '',
  searchCriteriaJson: '',
  sysVersionNumber:0,
  sendReplyImpossibleTime: [],
}

const confirmRequest: MCBHS090Request = {
  destinationId: [],
  searchCriteriaName: '',
  searchCriteria: '',
  submissionsSelectId: '',
  submissionsNameForCompany: '',
  submissionsNameForStudent: '',
  submissionsDetails: '',
  senderCompanyName: '',
  senderMailAddress: '',
  requestSubject: '',
  requestText: '',
  sendTimerFlag: 0,
  messageUtilMcbMessagesDetailDtoList: [],
}
const draftRequest: MCBHS090DraftRequest = {
  destinationId: [],
  searchCriteriaName: '',
  searchCriteria: '',
  // 9174 start
  searchCriteriaJson: '',
  // 9174 end
  senderCompanyName: '',
  senderMailAddress: '',
  requestSubject: '',
  requestText: '',
  sendTimerFlag: 0,
  sysVersionNumber: 0,
  messageUtilMcbMessagesDetailDtoList: [],
  sourceDraftMessageSendId: '',
}
export {
  initResult,
  messageInitRequest,
  destinationResultDto,
  confirmRequest,
  draftRequest,
}
