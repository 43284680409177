import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MCAXS330AptitudeTestIdInitResultList} from "../types/MCAXS330/MCAXS330AptitudeTestIdInitResultList";
import { MCAXS330AptitudeTestIdCreateRequest } from 'types/MCAXS330/MCAXS330AptitudeTestIdCreateRequest';

export interface ShlManagementSuggests{
  shlManagement: string[]
}
interface NameCheckListReducerList {
  initResultList: MCAXS330AptitudeTestIdInitResultList[],
  testInfo:TestInfo,
  flag: boolean,
  ShlManagementSuggestData: ShlManagementSuggests
}
interface TestInfo{
  value:string,
  label:string
}
const initialState: NameCheckListReducerList = {
  //set result value MCAXS330
  initResultList: [],
  testInfo:{
    value:"0",
    label:""
  },
  flag: false,
  ShlManagementSuggestData: {
    shlManagement: []
  }
};

const aptitudeTestIDIssue = createSlice({
  name: 'aptitudeTestIDIssue',
  initialState: initialState,
  reducers: {
    //init set result to reducer MCAXS330
    setSelectionCompanyContractWebTest(state, action: PayloadAction<{ recruitmentManagementDivision: string }>) {
      return state;
    },
    //set result value MCAXS330
    setSelectionJobSeekerInfo(state, action: PayloadAction<{testInfo:MCAXS330AptitudeTestIdInitResultList[]}>) {
      if(action.payload.testInfo.length>0){
      if(action.payload.testInfo.length>1){
      action.payload.testInfo.unshift({testId:"-1",testName:"（以下から選択）"})
      }
      state.initResultList = action.payload.testInfo;
      state.testInfo.label=action.payload.testInfo.length>1 ?  "（以下から選択）" : action.payload.testInfo[0].testName
      state.testInfo.value=action.payload.testInfo.length>1 ?  "-1" : action.payload.testInfo[0].testId
      }else{
        state.initResultList=[];
        state.testInfo.label="";
        state.testInfo.value="0"
      }
      return state;
    },
    AptitudeTestIdCreate(state,action:PayloadAction<MCAXS330AptitudeTestIdCreateRequest>){
      return state;
    },
    setSHLFlag( state, action: PayloadAction<boolean>){
      state.flag = action.payload;
      return state;
    },
    setShlManagementSuggestList(state, action: PayloadAction<ShlManagementSuggests>){
      state.ShlManagementSuggestData = action.payload;
      return state
    }
  },
});
export const {
  //set search value MCAXS330
  setSelectionCompanyContractWebTest,
  //set result value MCAXS330
  setSelectionJobSeekerInfo,
  AptitudeTestIdCreate,
  setSHLFlag,
  setShlManagementSuggestList
} = aptitudeTestIDIssue.actions;

export default aptitudeTestIDIssue.reducer
