import { getMessage } from 'common/messageUtil'
import { MCBHS060InitRequest } from 'types/MCBHS060/MCBHS060InitRequest'

export interface InputValues {
  senderCompanyName: string
  senderMailAddress: string
  limitDateDetermineFlag: string
  deadlineDate: Date|undefined
  deadlineAfterDay: string
  deadlineAfterDayTime: string
  deadlineAnyTime: Date|undefined
  reRequestReceptFlag: string
  subject: string
  body: string
  sendTimeTransmission: string
}
const changeTermInitRequest: MCBHS060InitRequest = {
  searchCriteriaName: '',
  searchCriteria: '',
  draftMessageSendID: '0',
  sysVersionNumber: '0',
  jobSeekerByCompanyIdSubmissionRequestIdList: [],
}
const targetTextAreas = {
  subject: 'subject',
  body: 'body',
}

const inputList = {
  senderCompanyName: {
    max: 200,
  },
  senderMailAddress: {
    max: 256,
  },
  subject: {
    max: 200,
  },
  body: {
    max: 2000,
  },
  homePageUrl: {
    max: 256,
  },
  homePageTitle: {
    max: 80,
  },
  notifyMailAddress: {
    max: 160,
  },
}
const submissionsDetailsList = [
  ['submissionEntrySheetFlag', 'エントリーシート'],
  ['submissionResumeFlag', '履歴書'],
  ['submissionResearchSummaryFlag', '研究概要書'],
  ['submissionOriginalQuestionFlag', 'オリジナル設問'],
  // MCBリプレイス #8675 START
  ['submissionFileSubmissionFlag', 'ファイル'],
  // MCBリプレイス #8675 END
]
const textMap = {
  confirm: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: '入力された内容で更新します。よろしいですか？',
  },
  cancel: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCBHS060-023'),
  },
  draft: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCBHS060-024'),
  },
  draftSaveComplete: {
    submit: 'OK',
    cancel: '',
    message: getMessage('MCBHS060-025'),
  },
}
export const conditionsTitleValue = {
  settingContentsSubmission: [
    '提出物内容名（企業管理用）',
    '提出物内容名（学生画面表示用）',
    '提出物',
  ],
}
export {
  changeTermInitRequest,
  targetTextAreas,
  inputList,
  submissionsDetailsList,
  textMap,
}
  export const scheduleAdjustmentRadioValue = [
    { id: 'accept', label: '受け付ける', value: '1' },
    { id: 'unAccept', label: '受け付けない', value: '0' },
  ]
  export const deadlineAfterDaySuggest = [
    { label: '01', value: '1' },
    { label: '02', value: '2' },
    { label: '03', value: '3' },
    { label: '04', value: '4' },
    { label: '05', value: '5' },
    { label: '06', value: '6' },
    { label: '07', value: '7' },
    { label: '08', value: '8' },
    { label: '09', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
  ];
  export const deadlineAfterDayTimeSuggest = [
    { label: '00', value: '0' },
    { label: '01', value: '1' },
    { label: '02', value: '2' },
    { label: '03', value: '3' },
    { label: '04', value: '4' },
    { label: '05', value: '5' },
    { label: '06', value: '6' },
    { label: '07', value: '7' },
    { label: '08', value: '8' },
    { label: '09', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
  ];
