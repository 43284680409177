import request from 'utils/request'
import {MCAQS020InitDuplicateIdentificationList} from "types/MCAQS020/MCAQS020InitDuplicateIdentificationList";
import {MCAQS020SelectDuplicateIdentification} from "types/MCAQS020/MCAQS020SelectDuplicateIdentification";

/**
 * request url to initMethod and result data
 * @param data initSearchCondition
 */
export const selectInitDuplicateIdentificationResultRequest = (data: MCAQS020InitDuplicateIdentificationList) =>
  request({
    url: '/MCAQS020/init',
    method: 'post',
    data: data
  });

/**
 * request url to click link Method and result data
 * @param data clickSearchCondition
 */
export const selectDuplicateIdentificationTargetBalanceCountRequest = (data: MCAQS020SelectDuplicateIdentification) =>
  request({
    url: '/MCAQS020/select',
    method: 'post',
    data: data
  });
