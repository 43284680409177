import {all, call, put, takeLatest,} from 'redux-saga/effects'
import {openModal} from 'reducers/messageReducer'
import {setSelectionCompanyContractWebTest, setSelectionJobSeekerInfo,AptitudeTestIdCreate,setSHLFlag,setShlManagementSuggestList} from 'reducers/aptitudeTestIDIssueReducer'
import {setSelectionCompanyContractWebTestRequest,register} from "apis/MCAXS330Api";

/**
 * call url set init result to reducer MCAXS330
 * @param action reducer return value
 */
function* setSelectionCompanyContractWebTestSaga(action: ReturnType<typeof setSelectionCompanyContractWebTest>) {
  try {
    const data = yield call(setSelectionCompanyContractWebTestRequest, action.payload.recruitmentManagementDivision);
    yield put(setSelectionJobSeekerInfo(data));
    yield put(setShlManagementSuggestList(data));
  } catch (error) {
    yield put(openModal(error.message))
  }

}
function* AptitudeTestIdCreateSaga(action: ReturnType<typeof AptitudeTestIdCreate>) {
  try {
    const data = yield call(register, action.payload);
      yield put(setSHLFlag(true))
  } catch (error) {
    yield put(setSHLFlag(false))
    yield put(openModal(error.message))
  }

}

export default function* aptitudeTestIDIssue() {
  yield all([
    //call url set init result to reducer MCAXS330
    takeLatest(setSelectionCompanyContractWebTest, setSelectionCompanyContractWebTestSaga),
    takeLatest(AptitudeTestIdCreate, AptitudeTestIdCreateSaga),
  ])
}
