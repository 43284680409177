import request from 'utils/request'
import { MCAXS381ResidenceRequest } from 'types/MCAXS381/MCAXS381ResidenceRequest'

export const getMCAXS381InitRequest = () => 
request({
  url: '/MCAXS381/init',
  method: 'post',
})


export const mcaxs381ResidenceRequest = (interview: MCAXS381ResidenceRequest) =>
  request({
    url: '/MCAXS381/ResidenceClick',
    method: 'post',
    data: interview,
  })
