import {
  mCARS010InitRequest,
  mCARS010SortRequest,
  mCARS010OpenSearchDetailRequest,
  mCARS010OpenSearchTitleRequest,
  mCARS010UpdateSearchTitleRequest,
  mCARS010ExecuteRequest,
  mCARS010OpenFilterSettingRequest,
  mCARS010selectSearchCriteriaRequest,
} from 'apis/MCARS010Api'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { selectCriteriaRequest } from '../apis/MCARS020Api'
// 次期開発9月#51248 start
import { initRequest, updateRequest, createRequest, checkRequest, selectUnActionSearchCriteriaNameRequest } from 'apis/MCARS030Api'
// 次期開発9月#51248 end
import {
  getActionPreList,
  setActionPreList,
  setCreateDialogOpen,
  getSearchCriteriaSightInfo,
  setSearchCriteriaSightInfo,
  updatescsDisplayOrder,
  getSearchCriteria,
  setSearchCriteria,
  setDetailDialogOpen,
  getSearchCriteriaName,
  setSearchCriteriaName,
  setEditDialogOpen,
  updateSearchCriteriaName,
  deleteSearchCriteria,
  getSelectCriteriaList,
  createsearchCriteriaName,
  setDialogOpen,
  setSelectData,
  updatesearchCriteria,
  setEscSettingId,
  setFlag,
  myInitFlag,
  checkActionInfo,
  setCheckDialogOpen,
  setCheckMsg,
  checkSearchCriteria,
  selectSearchCriteria,
  // 次期開発9月#51248 start
  selectUnActionSearchCriteriaName,
  setUnActionSearchCriteriaName,
  setOpenActionDialog,
  setSearchCriteriaToAction,
  // changeSearchCriteriaToAction,
  // 次期開発9月#51248 end
} from 'reducers/selectCriteriaManagementReducer'
import { fetchActionPreSet } from 'reducers/actionPreReducer'
import { MCARS010InitRequest } from 'types/MCARS010/MCARS010InitRequest'
import { MCARS010OrderRequest } from 'types/MCARS010/MCARS010OrderRequest'
import { MCARS010IdRequest } from 'types/MCARS010/MCARS010IdRequest'
import { MCARS010UpdateSearchTitleRequest } from 'types/MCARS010/MCARS010UpdateSearchTitleRequest'
import { MCARS010BatchProcessRequest } from 'types/MCARS010/MCARS010BatchProcessRequest'
import { MCARS020InitRequest } from 'types/MCARS020/MCARS020InitRequest'
import { MCARS030InitRequest } from 'types/MCARS030/MCARS030InitRequest'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { getRecruitmentManagementDivision, getGlobalTargetYear} from 'selectors/authSelectors'

// MCARS010 画面初期表示
function* getSearchCriteriaSightInfoSaga() {
  try {
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let request: MCARS010InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 初期データ取得
    const data = yield call(mCARS010InitRequest, request)
    yield put(setSearchCriteriaSightInfo(data))
  } catch (error) {
  }
}

// MCARS010 並び替え
function* updatescsDisplayOrderSaga(action: ReturnType<typeof updatescsDisplayOrder>) {
  try {
    let updateOrders: MCARS010OrderRequest = {
      entrSearchCriteriaSettingId: [],
      displayOrder: [],
      versionNumber: [],
    }
    action.payload.forEach(item => {
      updateOrders.entrSearchCriteriaSettingId.push(
        item.entrySearchCriteriaSettingId
      )
      updateOrders.displayOrder.push(item.displayOrder)
      updateOrders.versionNumber.push(item.sysVersionNumber)
    })
    // 並び替え
    yield call(mCARS010SortRequest, updateOrders)

    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let request: MCARS010InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 最新データ取得
    const data = yield call(mCARS010InitRequest, request)
    yield put(setSearchCriteriaSightInfo(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCARS010 検索条件取得
function* getSearchCriteriaSaga(action: ReturnType<typeof getSearchCriteria>) {
  try {
    let searchCriteria: MCARS010IdRequest = {
      entrSearchCriteriaSettingId: action.payload,
    }
    // 検索条件取得
    const data = yield call(mCARS010OpenSearchDetailRequest, searchCriteria)
    yield put(setSearchCriteria(data))
    // 「検索条件名」画面を表示
    yield put(setDetailDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCARS010 検索条件名取得
function* getSearchCriteriaNameSaga(action: ReturnType<typeof getSearchCriteriaName>) {
  try {
    let searchCriteriaName: MCARS010IdRequest = {
      entrSearchCriteriaSettingId: action.payload,
    }
    // 検索条件名取得
    const data = yield call(mCARS010OpenSearchTitleRequest, searchCriteriaName)
    yield put(setSearchCriteriaName(data))
    // 「検索条件名の設定」画面を表示
    yield put(setEditDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCARS010 検索条件名更新
function* updateSearchCriteriaNameSaga(action: ReturnType<typeof updateSearchCriteriaName>) {
  try {
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    // #7737 start
    const targetyear: ReturnType<
      typeof getGlobalTargetYear
    > = yield select(getGlobalTargetYear)
    // #7737 end
    let updateSearchTitle: MCARS010UpdateSearchTitleRequest = {
      entrSearchCriteriaSettingId: action.payload.entrySearchCriteriaSettingId,
      searchCriteriaName: action.payload.searchCriteriaName,
      versionNumber: action.payload.sysVersionNumber,
      recruitmentManagementDivision: recruitmentManagementDivision,
      // #7737 start
      targetyear: targetyear,
      // #7737 end
    }
    // 検索条件名更新
    yield call(mCARS010UpdateSearchTitleRequest, updateSearchTitle)

    // 最新データ取得
    let request: MCARS010InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    const data = yield call(mCARS010InitRequest, request)
    yield put(setSearchCriteriaSightInfo(data))
    // 「検索条件名の設定」画面を非表示
    yield put(setEditDialogOpen(false))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCARS010_010)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCARS010 検索条件削除
function* deleteSearchCriteriaSaga(action: ReturnType<typeof deleteSearchCriteria>) {
  try {
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let deleteInfo: MCARS010BatchProcessRequest = {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 検索条件削除
    const data = yield call(mCARS010ExecuteRequest, deleteInfo)
    yield put(setSearchCriteriaSightInfo(data))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCARS010_009)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCARS020 初期表示
function* getActionPreListSaga() {
  try {
    yield put(setActionPreList([]))
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let request: MCARS020InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 初期データ取得
    const data = yield call(selectCriteriaRequest, request)
    yield put(setFlag(1))
    // 自分画面を表示
    yield put(setCreateDialogOpen(true))
    yield put(setActionPreList(data))
  } catch (error) {
    yield put(setFlag(1))
    yield put(setCreateDialogOpen(true));
  }
}

function* setInitFlagSaga(){
  try{
    yield put(setFlag(0))
  }catch (error) {
    yield put(openModal(error.message))
  }
}

// 初期化表示
export function* getSelectCriteriaListSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    let request: MCARS030InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    const data = yield call(initRequest, request);
    yield put(setSelectData(data));
    yield put(setDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 検索条件名を更新する
export function* updatesearchCriteriaSaga(action: ReturnType<typeof updatesearchCriteria>) {
  try {
    yield call(updateRequest, action.payload);
    // データ更新成功
    yield put(setDialogOpen(false))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCARS030_009)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 検索条件名を登録する
export function* createsearchCriteriaSaga(action: ReturnType<typeof createsearchCriteriaName>) {
  try {
    const data = yield call(createRequest, action.payload);
    // チェック正常、データ保存成功
    yield put(setDialogOpen(false))
    yield put(setEscSettingId(data))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCARS030_009)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// アクションが設定されているかをチェックするする
export function* checkActionInfoSaga(action: ReturnType<typeof updatesearchCriteria>) {
  try {
    const data = yield call(checkRequest, action.payload);
    if (data === 'Successed') {
      yield put(setCheckMsg(magiContants.MESSAGECODE_MCARS030_008))
    } else {
      yield put(setCheckMsg(data))
    }
    yield put(setCheckDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// アクションが設定されているかをチェックするする
export function* checkSearchCriteriaSaga(action: ReturnType<typeof fetchActionPreSet>) {
  try {
    let searchCriteria: MCARS010IdRequest = {
      entrSearchCriteriaSettingId: String(action.payload.entrySearchCriteriaSetting)
    }
    yield call(mCARS010OpenFilterSettingRequest, searchCriteria)
    yield put(fetchActionPreSet(action.payload))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* selectSearchCriteriaSaga(action:ReturnType<typeof selectSearchCriteria>){
  try{
    let selectCriteria: MCARS010IdRequest = {
      entrSearchCriteriaSettingId: action.payload.entrySearchCriteriaSetting
    }
    yield call(mCARS010selectSearchCriteriaRequest,selectCriteria)
    if (action.payload.recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2) {
      // MCAXS020：エントリー一覧
      const applicantManagement = {
        pathname: routeList.entryList,
        state: {
          request: {
            ...action.payload.MCAXS020initScreenTransitionInput,
            entrySearchCriteriaSettingId: action.payload.entrySearchCriteriaSetting,
            screenId: 'MCARS010'
          }
        },
      }
      history.push(applicantManagement)
      } else {
        // MCAXS010:応募者一覧
        const applicantManagement = {
          pathname: routeList.applicantList,
          state: {
            request: {
              ...action.payload.MCAXS010initScreenTransitionInput,
              entrySearchCriteriaSettingId: action.payload.entrySearchCriteriaSetting,
              screenId: 'MCARS010'
            }
          },
        }
        history.push(applicantManagement)
      }
  } catch(error) {
    yield put(openModal(error.message))
  }
}

// 次期開発9月#51248 start
// アクション設定でない検索条件の項目名を抽出
export function* selectUnActionSearchCriteriaNameSaga(action:ReturnType<typeof selectUnActionSearchCriteriaName>) {
  try {
    const data = yield call(selectUnActionSearchCriteriaNameRequest,action.payload)
    yield put(setUnActionSearchCriteriaName(data))
    yield put(setOpenActionDialog(true))
  } catch(error) {
    yield put(openModal(error.message))
  }
}
// 次期開発9月#51248 end

export default function* selectCriteriaSaga() {
  yield all([
    // MCARS010
    takeEvery(getSearchCriteriaSightInfo, getSearchCriteriaSightInfoSaga),
    takeLatest(updatescsDisplayOrder, updatescsDisplayOrderSaga),
    takeLatest(getSearchCriteria, getSearchCriteriaSaga),
    takeLatest(getSearchCriteriaName, getSearchCriteriaNameSaga),
    takeLatest(updateSearchCriteriaName, updateSearchCriteriaNameSaga),
    takeLatest(deleteSearchCriteria, deleteSearchCriteriaSaga),
    takeEvery(checkSearchCriteria,checkSearchCriteriaSaga),
    // MCARS020
    takeEvery(getActionPreList, getActionPreListSaga),
    // MCARS030
    takeEvery(getSelectCriteriaList, getSelectCriteriaListSaga),
    takeLatest(createsearchCriteriaName, createsearchCriteriaSaga),
    takeLatest(updatesearchCriteria, updatesearchCriteriaSaga),
    takeEvery(myInitFlag,setInitFlagSaga),
    takeEvery(checkActionInfo,checkActionInfoSaga),
    takeEvery(selectSearchCriteria,selectSearchCriteriaSaga),
    // 次期開発9月#51248 start
    takeEvery(selectUnActionSearchCriteriaName,selectUnActionSearchCriteriaNameSaga),
    // 次期開発9月#51248 end
  ])
}
