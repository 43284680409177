import request from 'utils/request'

export const loginRequest = (loginId: string, password: string) =>
  request({
    url: '/auth/login',
    method: 'post',
    data: {
      loginId,
      password,
    },
  })

export const logoutRequest = () =>
  request({
    url: '/auth/logout',
    method: 'post',
  })

export const getUserInfoRequest = () =>
  request({
    url: '/auth/info',
    method: 'get',
  })

export const updatePasswordRequest = (
  loginId: string,
  oldPassword: string,
  newPassword: string
) =>
  request({
    url: '/auth/update_password',
    method: 'post',
    data: {
      loginId,
      oldPassword,
      newPassword,
    },
  })
