import { MCBHS070InitRequest } from 'types/MCBHS070/MCBHS070InitRequest'
import { MCBHS070RegisterRequest } from 'types/MCBHS070/MCBHS070RegisterRequest'
import request from 'utils/request'

export const initRequest = (apiData: MCBHS070InitRequest) => {
  return request({
    url: '/MCBHS070/init',
    method: 'post',
    data: apiData,
  })
}
export const redistRequest = (apiData: MCBHS070RegisterRequest) => {
  const data = new FormData()
  data.append('sendRegister', encodeURIComponent(JSON.stringify(apiData)))
  return request({
    url: '/MCBHS070/sendRegister',
    method: 'post',
    data: apiData,
  })
}
