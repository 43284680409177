import request from 'utils/request'
import { MCAZS101InitRequest } from 'types/MCAZS101/MCAZS101InitRequest'
import { MCAZS101NewRequest } from 'types/MCAZS101/MCAZS101NewRequest'
import { MCAZS101OrverWriteRequest } from 'types/MCAZS101/MCAZS101OrverWriteRequest'

export const initRequest = (apiData: MCAZS101InitRequest) => {
  return request({
    url: '/MCAZS101/init',
    method: 'post',
    data: apiData,
  })
}

export const newRequest = (apiData: MCAZS101NewRequest) => {
  return request({
    url: '/MCAZS101/new',
    method: 'post',
    data: apiData,
  })
}

export const orverWriteRequest = (apiData: MCAZS101OrverWriteRequest) => {
  return request({
    url: '/MCAZS101/Overwrite',
    method: 'post',
    data: apiData,
  })
}

export const chkNotAdoptedNotificationTypeRequest = (apiData: string[]) => {
  return request({
    url: '/MCAZS101/chkNotAdoptedNotificationType',
    method: 'post',
    data: apiData,
  })
}

