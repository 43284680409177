import { MCAXS591InitRequest } from 'types/MCAXS591/MCAXS591InitRequest'

/**
 * 学科詳細指定モーダル_初期表示用データ格納情報
 */
export interface StudentDetailsModalInfo {
  departmentDetailsList: DepartmentDetail[] // 学科詳細リスト
}

/** 学科詳細リスト */
export interface DepartmentDetail {
  [key: string]: any
  subjectCode: string // 学科コード
  subjectName: string // 学科名称
  subjectDivisionCode: string // 学科区分コード
  subjectDivisionName: string // 学科区分名称
}

export const initRequest: MCAXS591InitRequest = {
  studentClassificationId: null, // 学科区分ID
}
