import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { 
    initialValues,
    contactUnanswered,
    interviewFixedWaiting,
    passingStatusContactWaiting,
    progressResidence,
    interviewCancel,
    interviewCurrentDayRemind
} from 'pages/MCAFS010/formConfig'
import { MCAFS010MailSettingLinkRequest } from 'types/MCAFS010/MCAFS010MailSettingLinkRequest'

// init
export interface AlertPreferenceSettingInfo {
    [key: string]: string | number | boolean | NotificationMailInfo
    interviewFixedWaitingAlertPreference: string
    passingStatusContactWaitingAlertPreference: string
    progressResidenceAlertPreference: string
    interviewCancelAlertPreference: string
    contactUnansweredAlertPreference: string
    interviewCurrentDayRemindPreference: string
    sysVersionNumber: number
    message: string
    showDialog: boolean
    title: string
    // 次期9月対応 #63073 start
    // 個別返信メッセージ通知メール 
    notificationMailInfoResult1: NotificationMailInfo
    // 新規応募者登録通知メール
    notificationMailInfoResult2: NotificationMailInfo
    // セミナーエントリー／キャンセル通知メール
    notificationMailInfoResult3: NotificationMailInfo
    // 送信メール未着通知メール
    notificationMailInfoResult4: NotificationMailInfo
    // 次期9月対応 #63073 end
    // MCB ロット３ MCAFS010　start 
    // My CareerBox提出通知アラート
    McbNotificationMailInfoResult: NotificationMailInfo
    // MCB ロット３ MCAFS010　end 
}

// ContactUnanswered
export interface ContactUnanswered {
    [key: string]: string | number
    contactUnansweredAlertSwitchToggle: string
    versionNumber: number
    recruitmentManagementDivision: string
}

// interviewFixedWaiting
export interface InterviewFixedWaiting {
    [key: string]: string | number
    interviewFixedWaitingAlertSwitchToggle: string
    versionNumber: number
    recruitmentManagementDivision: string
}

// passingStatusContactWaiting
export interface PassingStatusContactWaiting {
    [key: string]: string | number
    passingStatusContactWaitingAlertSwitchToggle: string
    versionNumber: number
    recruitmentManagementDivision: string
}

// progressResidence
export interface ProgressResidence {
    [key: string]: string | number
    progressResidenceAlertSwitchToggle: string
    versionNumber: number
    recruitmentManagementDivision: string
}

// interviewCancel
export interface InterviewCancel {
    [key: string]: string | number
    interviewCancelAlertSwitchToggle: string
    versionNumber: number
    recruitmentManagementDivision: string
}

// interviewCurrentDayRemind
export interface InterviewCurrentDayRemind {
    [key: string]: string | number
    interviewCurrentDayRemindSwitchToggle: string
    versionNumber: number
    recruitmentManagementDivision: string
}
// notificationMailInfo
export interface NotificationMailInfo {
    [key: string]: string | number
    alertTiming: string
    notificationMailAddress: string
    alertMailType: string
}

interface MCAFS010Values {
    alertPreferenceSettingInfoResults: AlertPreferenceSettingInfo
    contactUnansweredResult: ContactUnanswered
    interviewFixedWaitingResult: InterviewFixedWaiting
    passingStatusContactWaitingResult: PassingStatusContactWaiting
    progressResidenceResult: ProgressResidence
    interviewCancelResult: InterviewCancel
    interviewCurrentDayRemindResult: InterviewCurrentDayRemind
}

const mcafs010Values: MCAFS010Values = {
    alertPreferenceSettingInfoResults: initialValues,
    contactUnansweredResult: contactUnanswered,
    interviewFixedWaitingResult: interviewFixedWaiting,
    passingStatusContactWaitingResult: passingStatusContactWaiting,
    progressResidenceResult: progressResidence,
    interviewCancelResult: interviewCancel,
    interviewCurrentDayRemindResult: interviewCurrentDayRemind
}

const alertPreferenceSettingSlice = createSlice({
    name: 'alertPreferenceSetting',
    initialState: mcafs010Values,
    reducers: {
        alertPreferenceInit(state, action: PayloadAction<string>) {
            return state
        },
        setAlertPreferenceSettingInfoResults(state, action: PayloadAction<AlertPreferenceSettingInfo>) {
            state.alertPreferenceSettingInfoResults = action.payload
            state.contactUnansweredResult.versionNumber = action.payload.sysVersionNumber
            state.interviewFixedWaitingResult.versionNumber = action.payload.sysVersionNumber
            state.passingStatusContactWaitingResult.versionNumber = action.payload.sysVersionNumber
            state.progressResidenceResult.versionNumber = action.payload.sysVersionNumber
            state.interviewCancelResult.versionNumber = action.payload.sysVersionNumber
            state.interviewCurrentDayRemindResult.versionNumber = action.payload.sysVersionNumber
        },
        interFixWaitAlertOn(state, action: PayloadAction<InterviewFixedWaiting>) {
            return state
        },
        interFixWaitAlertOff(state, action: PayloadAction<InterviewFixedWaiting>) {
            return state
        },
        passStsContAlertOn(state, action: PayloadAction<PassingStatusContactWaiting>) {
            return state
        },
        passStsContAlertOff(state, action: PayloadAction<PassingStatusContactWaiting>) {
            return state
        },
        progsResdenceAlertOn(state, action: PayloadAction<ProgressResidence>) {
            return state
        },
        progsResdenceAlertOff(state, action: PayloadAction<ProgressResidence>) {
            return state
        },
        interCancelAlertOn(state, action: PayloadAction<InterviewCancel>) {
            return state
        },
        interCancelAlertOff(state, action: PayloadAction<InterviewCancel>) {
            return state
        },
        contUnanswrAlertOn(state, action: PayloadAction<ContactUnanswered>) {
            return state
        },
        contUnanswrAlertOff(state, action: PayloadAction<ContactUnanswered>) {
            return state
        },
        interCurtDayAlertOn(state, action: PayloadAction<InterviewCurrentDayRemind>) {
            return state
        },
        interCurtDayAlertOff(state, action: PayloadAction<InterviewCurrentDayRemind>) {
            return state
        },
        closeDialog(state) {
            state.alertPreferenceSettingInfoResults.showDialog = false
        },
        setRecruitmentManagementDivision(state, action: PayloadAction<string>) {
            state.contactUnansweredResult.recruitmentManagementDivision = action.payload
            state.interviewFixedWaitingResult.recruitmentManagementDivision = action.payload
            state.passingStatusContactWaitingResult.recruitmentManagementDivision = action.payload
            state.progressResidenceResult.recruitmentManagementDivision = action.payload
            state.interviewCancelResult.recruitmentManagementDivision = action.payload
            state.interviewCurrentDayRemindResult.recruitmentManagementDivision = action.payload
        },
        // 次期9月対応 #63073 start
        validAlertMailLinkFlag(state, action: PayloadAction<MCAFS010MailSettingLinkRequest>) {
            return state
        }
        // 次期9月対応 #63073 end
    }
})

export const {
    alertPreferenceInit,
    setAlertPreferenceSettingInfoResults,
    interFixWaitAlertOn,
    interFixWaitAlertOff,
    passStsContAlertOn,
    passStsContAlertOff,
    progsResdenceAlertOn,
    progsResdenceAlertOff,
    interCancelAlertOn,
    interCancelAlertOff,
    contUnanswrAlertOn,
    contUnanswrAlertOff,
    interCurtDayAlertOn,
    interCurtDayAlertOff,
    closeDialog,
    setRecruitmentManagementDivision,
    // 次期9月対応 #63073 start
    validAlertMailLinkFlag
    // 次期9月対応 #63073 end
} = alertPreferenceSettingSlice.actions
export default alertPreferenceSettingSlice.reducer