import {call,put,all,takeLatest} from 'redux-saga/effects'
import { openModal} from 'reducers/messageReducer'
import { getUrl , setUrl } from 'reducers/urlReducer'
import { urlApi } from 'apis/urlApi'

function* getUrlSaga(action: ReturnType<typeof getUrl>) {
    try {
        const data = yield call(urlApi,action.payload);
        yield put(setUrl(data))
    } catch (error) {
        yield put(openModal(error.message))
    }
}
export default function* urlSaga() {
    yield all([
      takeLatest(getUrl, getUrlSaga),
    ])
  }
