import request from 'utils/request'


export const fetchAttachment = (requestBody: { entryId: string }) =>
  request({
    url: '/MCAXS090/init',
    method: 'post',
    data: requestBody
  })
export const uploadFiletoBehind = (requestBody: { entryId: number, registeredFileName: File }) => {
  const data = new FormData()
  data.append('entryId', JSON.stringify(requestBody.entryId))
  data.append("registeredFileName", requestBody.registeredFileName)
  return request({
    url: '/MCAXS090/register',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}

export const deleteFile = (requestBody: { entryAttachedFileId: string, entryId: string,fileName:string,sequence: number, sysVersionNumber: number }) =>
  request(
    {
      url: '/MCAXS090/delete',
      method: 'post',
      data: requestBody
    }
  )

export const downLoadApi = (requestBody: { entryAttachedFileId: string,entryId:number, fileName: string,recruitmentManagementDivision: string }) =>
  request(
    {
      url: '/MCAXS090/download',  
      method: 'post',
      responseType: 'blob',
      data: requestBody 
    }
  )