import request from 'utils/request'
import { MCAVS010Request } from 'types/MCAVS010/MCAVS010Request'
import { MCAVS010NewsRequest } from 'types/MCAVS010/MCAVS010NewsRequest'
import { MCAVS010NewApplicantRequest } from 'types/MCAVS010/MCAVS010NewApplicantRequest'
import { MCAVS010UnreadResumeRequest } from 'types/MCAVS010/MCAVS010UnreadResumeRequest'
import { MCAVS010UnreadInfoRequest } from 'types/MCAVS010/MCAVS010UnreadInfoRequest'
import { MCAVS010InterviewFixedWaitingRequest } from 'types/MCAVS010/MCAVS010InterviewFixedWaitingRequest'
import { MCAVS010InterviewSchedulePresentedRequest } from 'types/MCAVS010/MCAVS010InterviewSchedulePresentedRequest'
import { MCAVS010InterviewPlannerListRequest } from 'types/MCAVS010/MCAVS010InterviewPlannerListRequest'
import { MCAVS010InterviewPlannerRequest } from 'types/MCAVS010/MCAVS010InterviewPlannerRequest'
import { MCAVS010YieldRequest } from 'types/MCAVS010/MCAVS010YieldRequest'
import { MCAVS010BriefingSessionRequest } from 'types/MCAVS010/MCAVS010BriefingSessionRequest'
import { MCAVS010EventRequest } from 'types/MCAVS010/MCAVS010EventRequest'
import { MCAVS010YearYieldRequest } from 'types/MCAVS010/MCAVS010YearYieldRequest'
// #58811 2022/05/24 start
import { MCAVS010ApplicantRequest } from 'types/MCAVS010/MCAVS010ApplicantRequest'
import { MCAVS010ApplicantionRequest } from 'types/MCAVS010/MCAVS010ApplicantionRequest'
// #58811 2022/05/24 end
// 次期12月対応 #72261 start
import { MCAVS010SeminarAfterScheduleHiddenCheckBoxRequest } from 'types/MCAVS010/MCAVS010SeminarAfterScheduleHiddenCheckBoxRequest'
// 次期12月対応 #72261 end
import { MCAVS010SubmissionStatusRequest } from 'types/MCAVS010/MCAVS010SubmissionStatusRequest'
import { MCAVS010SubmissionCountRequest } from 'types/MCAVS010/MCAVS010SubmissionCountRequest'

export const searchRequest = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/init',
    method: 'post',
    data: recruitmentStatusReport,
  })
  // [phase2] start by zhangxp
  // 面接予定者の情報
  export const searchInterviewPlannerInfoRequest = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/initInterviewPlannerInfo',
    method: 'post',
    data: recruitmentStatusReport,
  })
  export const searchUnreadCountRequest = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/initUnreadCount',
    method: 'post',
    data: recruitmentStatusReport,
  })
  // 歩留まり情報
  export const searchYieldInfoRequest = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/initYieldInfo',
    method: 'post',
    data: recruitmentStatusReport,
  })
  // 説明会・面接情報
  export const searchBriefingSessionInfoRequest = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/initBriefingSessionInfo',
    method: 'post',
    data: recruitmentStatusReport,
  })
  // イベントの参加状況情報
  export const searchContractMediaRequest = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/initContractMedia',
    method: 'post',
    data: recruitmentStatusReport,
  })
  // お知らせの情報
  export const searchNewsInfoRequest = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/initNewsInfo',
    method: 'post',
    data: recruitmentStatusReport,
  })
  // [phase2] end by zhangxp
  //[新規応募者（応募者単位）の件数]リンクをクリックする
  export const pushNewApplicantCountRequest = (params: MCAVS010NewApplicantRequest) =>
  request({
    url: '/MCAVS010/pushNewApplicantCount',
    method: 'post',
    data: params,
  })
  // [履歴書未読の件数]リンクをクリックする
  export const pushUnreadResumeCountRequest = (params: MCAVS010UnreadResumeRequest) =>
  request({
    url: '/MCAVS010/pushUnreadResumeCount',
    method: 'post',
    data: params,
  })
  // [未読の件数]リンクをクリックする
  export const pushUnreadInfoCountRequest = (params: MCAVS010UnreadInfoRequest) =>
  request({
    url: '/MCAVS010/pushUnreadInfoCount',
    method: 'post',
    data: params,
  })
  // [確定待ちの件数]リンクをクリックする
  export const pushInterviewFixedWaitingCountRequest = (params: MCAVS010InterviewFixedWaitingRequest) =>
  request({
    url: '/MCAVS010/pushInterviewFixedWaitingCount',
    method: 'post',
    data: params,
  })
  // [提示済みの件数]リンクをクリックする
  export const pushInterviewSchedulePresentedCountRequest = (params: MCAVS010InterviewSchedulePresentedRequest) =>
  request({
    url: '/MCAVS010/pushInterviewSchedulePresentedCount',
    method: 'post',
    data: params,
  })
  // [面接予定者一覧へ]リンクをクリックする
  export const pushInterviewPlannerListRequest = (params: MCAVS010InterviewPlannerListRequest) =>
  request({
    url: '/MCAVS010/pushInterviewPlannerList',
    method: 'post',
    data: params,
  })
  // 各[面接日程]をクリックする
  export const pushInterviewScheduleRequest = (params: MCAVS010InterviewPlannerRequest) =>
  request({
    url: '/MCAVS010/pushInterviewSchedule',
    method: 'post',
    data: params,
  })
  // 各[歩留まりの件数]リンクをクリックする
  export const pushYieldCountRequest = (params: MCAVS010YieldRequest) =>
  request({
    url: '/MCAVS010/pushYieldCount',
    method: 'post',
    data: params,
  })
  // [予約の件数]リンクをクリックする
  export const pushSeminarReservedCountRequest = (params: MCAVS010BriefingSessionRequest) =>
  request({
    url: '/MCAVS010/pushSeminarReservedCount',
    method: 'post',
    data: params,
  })
  // [キャンセルの件数]リンクをクリックする
  export const pushSeminarCanceledCountRequest = (params: MCAVS010BriefingSessionRequest) =>
  request({
    url: '/MCAVS010/pushSeminarCanceledCount',
    method: 'post',
    data: params,
  })
  // [参加人数]リンクをクリックする
  export const pushEventParticipantRequest = (params: MCAVS010EventRequest) =>
  request({
    url: '/MCAVS010/pushEventParticipant',
    method: 'post',
    data: params,
  })
  //各[お知らせタイトル]リンクをクリックする
  export const pushNewsTitleRequest = (params: MCAVS010NewsRequest) =>
  request({
    url: '/MCAVS010/pushNewsTitle',
    method: 'post',
    data: params,
  })
  // WEB面接対応 start
  // WEB面接枠数情報
  export const searchInterviewWebInfoRequest = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/initInterviewWebInfo',
    method: 'post',
    data: recruitmentStatusReport,
  })
  // WEB面接対応 end
  // 年度対応 start
  // 年度歩留まり表示タイトルボタン押下
  export const pushDisplayYearYieldTitleRequest = (params: MCAVS010YearYieldRequest) =>
  request({
    url: '/MCAVS010/pushDisplayYearYieldTitle',
    method: 'post',
    data: params,
  })
  // 年度対応 end

  // #58811 2022/05/24 start
  // 「今週の遷移」情報
  export const searchWeekProgressInfoRequest = (recruitmentStatusReport:MCAVS010Request) =>
  request({
    url: `MCAVS010/initWeekProgressInfo`,
    method: 'post',
    data: recruitmentStatusReport,
  })

  // 「応募者数」リンク押下
  export const pushApplicantCountRequest = (params: MCAVS010ApplicantRequest) =>
  request({
    url: `MCAVS010/pushApplicantCount`,
    method: 'post',
    data: params,
  })

  // 「応募件数」リンク押下
  export const pushApplicantionCountRequest = (params:MCAVS010ApplicantionRequest) =>
  request({
    url: `MCAVS010/pushApplicantionCount`,
    method: 'post',
    data: params,
  })
  // #58811 2022/05/24 end

  // MCB ロット３　start
  // My CareerBox提出状況
  export const submissionRequestInfor = (recruitmentStatusReport: MCAVS010Request) =>
  request({
    url: '/MCAVS010/initMcbSubmissionRequestInfor',
    method: 'post',
    data: recruitmentStatusReport,
  })
  // MCB ロット３　end
  // 次期12月対応 #72261 start
  // 開催後の日程チェックボックスの初期表示
  export const initEventAfterScheduleRequest = (requestPayload:string) =>
  request({
    url: `/MCAVS010/initEventAfterSchedule/${requestPayload}`,
    method: 'post',
  })

  // "開催後の日程を非表示にする"チェックボックス押下時
  export const pushSeminarAfterScheduleHiddenCheckBoxRequest = (params: MCAVS010SeminarAfterScheduleHiddenCheckBoxRequest) =>
  request({
    url: '/MCAVS010/pushSeminarAfterScheduleHiddenCheckBox',
    method: 'post',
    data: params,
  })
  // 次期12月対応 #72261 end
  // 25KH #75311 start
  export const pushPassYieldCountRequest = () =>
  request({
    url: '/MCAVS010/pushPassYieldCount',
    method: 'post',
  })
  // 25KH #75311 end

  // 26KH #89294 START
  // MCB提出状況表示フラグの初期表示
  export const initSubmissionOpenCloseRequest = (requestPayload:string) =>
  request({
    url: `/MCAVS010/initSubmissionOpenClose/${requestPayload}`,
    method: 'post',
  })
  // 今週の推移エリア[提出状況開閉]ボタンクリック時
  export const pushSubmissionOpenCloseRequest = (params: MCAVS010SubmissionStatusRequest) =>
  request({
    url: '/MCAVS010/pushSubmissionOpenClose',
    method: 'post',
    data: params,
  })
  // 各[提出件数]リンククリック時
  export const pushSubmissionCountRequest = (params:MCAVS010SubmissionCountRequest) =>
  request({
    url: `MCAVS010/pushSubmissionCount`,
    method: 'post',
    data: params,
  })
  // [7日合計提出件数]リンククリック時
  export const pushTotalSubmissionCountRequest = (params:MCAVS010SubmissionCountRequest) =>
  request({
    url: `MCAVS010/pushTotalSubmissionCount`,
    method: 'post',
    data: params,
  })
  // [総合計提出件数]リンククリック時
  export const pushAllSubmissionCountRequest = (params:MCAVS010SubmissionCountRequest) =>
  request({
    url: `MCAVS010/pushAllSubmissionCount`,
    method: 'post',
    data: params,
  })
  // 26KH #89294 END
