import { MCAXS290initialValues, entryHistoryList, entryHistoryValues } from "pages/MCAXS290/formConfig";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MCAXS290InitRequest } from "types/MCAXS290/MCAXS290InitRequest";


interface EntryHistory {
    entryHistoryRequest: MCAXS290InitRequest
    entryHistoryList: entryHistoryList[]
}
const initialState: EntryHistory = {
    entryHistoryRequest: MCAXS290initialValues,
    entryHistoryList: entryHistoryValues,
}
const entryHistorySlice = createSlice({
    name: 'entryHistory',
    initialState,
    reducers: {
        getEntryHistory(state, action: PayloadAction<MCAXS290InitRequest>) {
            return state
        },
        setEntryHistory(state, action: PayloadAction<entryHistoryList[]>) {
            state.entryHistoryList = action.payload
        }
    }
})

export const {
    getEntryHistory,
    setEntryHistory,
} = entryHistorySlice.actions
export default entryHistorySlice.reducer