import React, { useState } from 'react'
import {
  makeStyles,
  colors,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // 次期開発9月#58497 start
  RadioGroup,
  FormControlLabel,
  Radio
  // 次期開発9月#58497 end
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {Page, LoadingDialog} from 'components'
import { RootState } from 'reducers'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  ActionPreListData,
  setCreateDialogOpen,
} from  'reducers/selectCriteriaManagementReducer';
import { magiContants } from 'utils/contants'
import {getMessage} from 'common/messageUtil'
// 次期開発9月#58497 start
import { withStyles } from '@material-ui/styles'
import { Tooltip as MuiTooptip } from '@material-ui/core'
// 次期開発9月#58497 end

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop:'-2%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: '100%',
    minHeight:'26px',
    backgroundColor: 'theme.palette.background.paper',
    marginTop:'-1.5%',
    '&:hover': {
      backgroundColor: '#E6F1FF',
    },
  },
  // 次期開発9月#58497 start
  list1: {
    width: '100%',
    minHeight:'26px',
    backgroundColor: 'theme.palette.background.paper',
    marginTop:'-1%',
  },
  // 次期開発9月#58497 end
  listTitle:{
    display: 'inline-block',
    marginTop: '1%',
    fontSize:'19px',
    marginLeft:'4%',
  },
  // 次期開発9月#58497 start
  listTitle1:{
    display: 'inline-block',
    marginTop: '1%',
    fontSize:'14px',
    marginLeft:'2.8%',
  },
  // 次期開発9月#58497 end
  textHeight: {
    height: '55px',
  },
  listText:{
    width: '100%',
    maxWidth: '500px',
    fontSize:'14px',
    lineHeight:'23px',
    cursor: 'pointer',
   },
  listConent:{
    width: '95%',
    height:'500px',
    marginLeft:'2.8%',
    maxHeight:'450px',
    marginTop: '-1%',
    overflow:'auto',
    border:'1px solid black',
  },
  // 次期開発9月#58497 start
  listConent1: {
    width: '90%',
    height:'500px',
    marginLeft:'2.8%',
    maxHeight:'450px',
    marginTop: '2%',
    overflow:'auto',
    border:'1px solid black',
  },
  // 次期開発9月#58497 end
  nested: {
    paddingLeft: theme.spacing(4),
  },
  buttonGroup: {
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(50),
  },
  formContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
  },
  // 次期開発9月#58497 start
  formContainer1: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(20),
  },
  // 次期開発9月#58497 end
  description: {
    marginLeft: theme.spacing(5),
  },
  cancel: {
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 150,
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
    },
    minWidth: 150,
  },
  mapURLButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3d8bcd',
    '&:hover': {
      backgroundColor: colors.blue[900],
    },
  },
  lableStyle: {
    backgroundColor: '#133e80',
    color: 'white',
    height: '35px%',
    fontSize: '20px',
    paddingLeft: '2%',
    lineHeight: '35px', 
    position: 'relative',
    display: 'inline-block',
  }, 
  subTilefontSize: {
    backgroundColor: '#133e80',
    height: '40px',
    paddingTop:'10px',
},
subTitle: {
    color: (theme.palette as any).white,
    fontWeight: 700,
    fontSize: '18px',
},
dialog: {
  '& .MuiDialog-paperScrollPaper':{
    overflowY: window.navigator.userAgent
      .toLocaleLowerCase()
      .match(/(trident)\/([\d.]+)/) ? 'auto' : 'hidden',
    display: window.navigator.userAgent
      .toLocaleLowerCase()
      .match(/(trident)\/([\d.]+)/) ? 'block' : 'flex',
  },
},
// 次期開発9月#58497 start
checkbox: {
  zoom: '150%',
  cursor: 'pointer',
  opacity:0.5,
  '&:focus': {
    opacity:1,
  },
  '&:checked':{
    opacity:1,
    zoom:'150%',
  }
},
em: {
  marginLeft: '10px',
  color: '#3380CC',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:focus': {
    textDecoration: 'underline',
  }
},
// 次期開発9月#58497 end
}))

// 次期開発9月#58497 start
const searchOptionList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]
const Tooltip = withStyles({
  tooltip: {
    fontSize: '1em',
    backgroundColor: 'white',
    color: '#000000',
    border: '1px solid',
    borderRadius: '0px',
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  popper: {
    whiteSpace: 'pre-line',
  }
})(MuiTooptip)

// 次期開発9月#58497 end

interface Props {
  onClose: (
    entrySearchCriteriaSettingId: string[],
    mySearchCondition: number | null
  ) => void
}

const MCARS020 = ({ onClose }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // 次期開発9月#58497 start
  const [searchType, setSearchType] = useState('0')
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  // 次期開発9月#58497 end
  const tableData = useSelector(
    (state: RootState) => state.selectCriteriaManagement.ActionPreList
  )
  // 次期開発9月#58497 start
  const maxCheckBoxLimit = tableData && tableData[0] ? tableData[0].maxCheckBoxLimit : 0
  // 次期開発9月#58497 end
  const createDialogOpen = useSelector(
    (state: RootState) => state.selectCriteriaManagement.createDialogOpen
  )
  const flag = useSelector(
    (state: RootState) => state.selectCriteriaManagement.flag
  )
  const handleCreate = () => {
      dispatch(setCreateDialogOpen(false))
      // 次期開発9月#58497 start
      setCheckActionList([])
      setSearchType('0')
      // 次期開発9月#58497 end
  }
  const onLink =(value: string)=>{
    dispatch(setCreateDialogOpen(false))
    // 次期開発9月#58497 start
    onClose([value],null)
    setCheckActionList([])
    setSearchType('0')
  }
  const handleChangeSearchType = (e: any) => {
    setSearchType(e.target.value)
  }
  const [checkActionList, setCheckActionList] = useState([] as string[])
  const handleCheckBoxClick = (item: ActionPreListData) => {
    const checkAction = checkActionList.includes(item.entrySearchCriteriaSettingId)
    if (checkAction) {
      setCheckActionList(checkActionList.filter(i => i !== item.entrySearchCriteriaSettingId))
    } else {
      setCheckActionList([
        ...checkActionList,
        item.entrySearchCriteriaSettingId,
      ])
    }
  }
  const handleSubmit = () => {
    if (checkActionList != null && checkActionList.length == 1) {
      dispatch(setCreateDialogOpen(false))
      onClose([checkActionList[0]],null)
    }else {
      dispatch(setCreateDialogOpen(false))
      onClose(checkActionList,Number(searchType))
    }
    setCheckActionList([])
    setSearchType('0')
  }
  const notHtml = (searchCriteria: string) => {
    var html = {__html: searchCriteria}
    return <div dangerouslySetInnerHTML={html}></div>
  }
  // 次期開発9月#58497 end
  
  return (
      <Dialog fullWidth  maxWidth='md' open={createDialogOpen} 
              className={classes.dialog + ' mainDialog mySearchMCAR'}>
                 <LoadingDialog className='overlay' />
                 <DialogContent style={{padding: 0,minWidth: '960px', maxHeight: '100%'}}>
                 <DialogTitle className={classes.subTilefontSize} id="max-width-dialog-title">
                 {/* MCB ロット３ MY検索（応募管理）呼び出し start */}
                    <div className={classes.subTitle}>MY検索（応募管理）呼び出し</div>
                 {/* MCB ロット３ MY検索（応募管理）呼び出し end */} 
            </DialogTitle>          
        <DialogContent>  
          <div className={classes.textHeight}>
          {flag === 1 ? 
          tableData.length !== 0 ?
          // 次期開発9月#58497 start
          (recruitmentManagementDivision === '1' ?
          <label className={classes.listTitle1}>単一のMY検索を利用する場合は、MY検索名をクリックしてください。
                                              <br/>複数のMY検索を掛け合わせて利用する場合は、チェックボックスで選択し、「OK」ボタンを押下して下さい。
                                              <br/>MY検索の掛け合わせは３件までです。</label>
          :<label className={classes.listTitle}>{getMessage(magiContants.MESSAGECODE_MCARS020_000)}</label>):
          // 次期開発9月#58497 end
           <label className={classes.listTitle}>{getMessage(magiContants.MESSAGECODE_MCARS020_001)}</label>
          : ''
          }
            </div>
            {/* 次期開発9月#58497 start */}
            {recruitmentManagementDivision === '1' ? 
            <div style={{marginLeft: '2.8%',marginTop: '2%'}}>
              <RadioGroup onChange={handleChangeSearchType} row style={{display: 'inline-block'}}>
                {searchOptionList
                    ? searchOptionList.map((item) => (
                        <>
                          <FormControlLabel
                            control={
                              <Radio
                                color='primary'
                                checked={searchType === item.value}
                                value={item.value}
                                tabIndex={-1}
                              />
                            }
                            label={item.label}
                          />
                        </>
                      ))
                    : null}
              </RadioGroup>
            </div>
            : ''
            }

          {recruitmentManagementDivision === '1' ?
            <List component="div" className={classes.listConent1}>
              {tableData.map((item: ActionPreListData, index)=>
              <ListItem className={classes.list1}> 
                <input className={classes.checkbox} type="checkbox" key={item.entrySearchCriteriaSettingId} 
                onClick={() => handleCheckBoxClick(item)} tabIndex={index+1}/>
                <Tooltip title={notHtml(item.searchCriteria)} placement='right' interactive={true}>
                <em className={classes.em} onClick={() => {onLink(item.entrySearchCriteriaSettingId)}} tabIndex={index+2}>
                  {item.searchCriteriaName}
                </em>
                </Tooltip>
              </ListItem>
              )}
            </List> :
          // 次期開発9月#58497 end
          <List component="div" className={classes.listConent}>
              {tableData.map((item: ActionPreListData, index)=>

              <ListItem button className={classes.list}  
              onClick={() => {
                onLink(item.entrySearchCriteriaSettingId)
                 
              }
              }>
                        <label className={classes.listText}>{item.searchCriteriaName}</label>
                        <input type="hidden" value={item.entrySearchCriteriaSettingId}></input>
             </ListItem>
      )}
           {/* 次期開発9月#58497 start */}
           </List>}
           {recruitmentManagementDivision === '1' && maxCheckBoxLimit && checkActionList.length > maxCheckBoxLimit ?
            <div style={{marginTop: '5px',marginLeft: '2.8%',color: '#FF0000'}}>MY検索の掛け合わせは{maxCheckBoxLimit}件までです</div> : ''}
           {/* 次期開発9月#58497 end */}
           </DialogContent>
      <DialogActions className={classes.buttonGroup}>
                  <Grid
                    container
                    direction='row'
                    justify='space-between'
                    className={classes.formContainer}>
                    <Grid item xs={3}>
                      <Button
                        variant='contained'
                        className={classes.cancel}
                        onClick={handleCreate}
                        tabIndex={tableData.length +1}>
                          キャンセルする
                      </Button>
                    </Grid> 
                  </Grid>
                  {/* 次期開発9月#58497 start */}
                  {recruitmentManagementDivision === '1' ?
                  <Grid
                    container
                    direction='row'
                    justify='space-between'
                    className={classes.formContainer1}>
                    <Grid item xs={3}>
                      <Button
                        variant='contained'
                        className={classes.confirmButton}
                        disabled={checkActionList.length > 0 && checkActionList.length <= maxCheckBoxLimit ? false : true}
                        onClick={handleSubmit}
                        tabIndex={tableData.length + 2}
                        >
                          OK
                      </Button>
                    </Grid> 
                  </Grid> : ''}
                  {/* 次期開発9月#58497 end */}
      </DialogActions>
      </DialogContent>

        </Dialog>  
  )
}
export default MCARS020