import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { FieldProps } from 'formik'
import RootRef from '@material-ui/core/RootRef'
import { TextareaAutosize } from 'components'

const useStyles = makeStyles(theme => ({
  formContorl: {
    paddingTop: theme.spacing(2),
  },
  textCounter: {
    float: 'right',
    minWidth: 120,
    textAlign: 'right',
    marginTop: theme.spacing(-2),
  },
  MultiTextWithCounter: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: theme.spacing(30),
    maxHeight: theme.spacing(30),
    overflow: 'auto !important',
  },
}))

interface Props extends FieldProps {
  className: any
  maxAmount: number
  onChange?: (value: string) => void
}

const MultiTextWithCounter = ({ field, form, maxAmount, onChange }: Props) => {
  const classes = useStyles()
  const textArea = useRef<HTMLTextAreaElement>(null)
  const { name, value } = field
  const handleChangeTextAreaValue = (event: any) => {
    if (1000 >= event.target.value.length) {
      if (onChange) {
        onChange(event.target.value)
      }
      form.setFieldValue(name, event.target.value)
    }
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <RootRef rootRef={textArea}>
            <TextareaAutosize
              field={{ ...field, onChange: handleChangeTextAreaValue }}
              form={form}
              label=''
              aria-label='empty textarea'
              className={classes.MultiTextWithCounter}
            />
            <Grid container justify='flex-end'>
              <Grid item className={classes.textCounter}>
                <Typography gutterBottom>
                  {`入力 ${value ? value.length : 0} / 最大 ${maxAmount}`}
                </Typography>
              </Grid>
            </Grid>
          </RootRef>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MultiTextWithCounter
