import React from 'react'
import { Permission } from 'reducers/accountReducer'
import { routeList } from 'routes/routes'
import moment from 'moment-timezone'
import history from './history'
import { formatTemplate, unitSet, magiContants } from './contants'
import * as yup from 'yup'
moment.tz.setDefault('Asia/Tokyo')

export type Order = 'asc' | 'desc'

export interface Area {
  day: number
  startDate: string
  endDate: string
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string | string[] },
  b: { [key in K]: number | string | string[] }
) => number {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

export function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export const permissionMap: { [key: string]: number } = {
  システム管理: 1,
  アカウント管理: 2,
  企業管理: 3,
  企業サポート: 4,
  営業: 5,
}

export const convertPermissions = (
  administratorId: string,
  permissions: string[]
) =>
  permissions.map(i => ({
    administratorId,
    permissionId: permissionMap[i],
  }))

export const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

export const replaceToOrigin = () => {
  const baseUrl = window.location.origin
  window.location.replace(baseUrl)
}
export const replaceToOriginUrl = (url:string) => {
  window.location.replace(url)
}

export const bytesToSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const covertSizeToBytes = (size: string) => {
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  // eslint-disable-next-line prefer-const
  let temp = size
  for (let i = 0; i < sizes.length; i++) {
    if (size.endsWith(sizes[i])) {
      return parseFloat(temp.replace(sizes[i], '')) * Math.pow(1024, i + 1)
    }
  }
  if (size.endsWith('B')) {
    return parseFloat(temp.replace('B', ''))
  }
  return 0
}

export const convertToAccountDto = (data: any) => ({
  ...data,
  permissions: data.permissions.map((i: any) => {
    let id: string
    switch (i) {
      case 'システム管理':
        id = '1'
        break
      case 'アカウント管理':
        id = '2'
        break
      case '企業管理':
        id = '3'
        break
      case '企業サポート':
        id = '4'
        break
      case '営業':
        id = '5'
        break
      default:
        id = '1'
    }
    return { id, name: i, sortOrder: null, status: null } as Permission
  }),
})

export const backToAccountPage = () => history.push(routeList.account)
export const convertDateString = (input: string) =>
  isNaN(Date.parse(input))
    ? ''
    : new Intl.DateTimeFormat('ja-JP').format(new Date(input))

/**
 * Input String Format: YYYY/MM/DD
 */
export const convertStringToDateArgs = (input: string) => {
  const [year, month, day] = input
    .split('/')
    .map(i => parseInt(i))
    .map((item, index) => (index === 1 ? item - 1 : item)) // Month is zero-indexed

  return [year, month, day] as const
}

export const convertStringForIe = (input: string) => input.split('/').join('-')

export const formatDateString = (input: string, full?: boolean) => {
  const [date, datetime, other] = input.split(" ")

  if (datetime) {
    const [hour, minute] = datetime.split(":")
    return other
      ? `${date} ${hour}:${minute} ${other}`
      : `${date} ${hour}:${minute}`
  } else {
    return full ? `${date} 00:00` : date
  }
}

export const formatBirthString = (input: string) => {
  const [date, age, gender] = input.split(" ")

  if (age) {
    if (
      age === '(歳)' ||
      age === '(null歳)' ||
      age === '（歳）' ||
      age === '（null歳）'
    ) {
      return date + ' ' + gender
    }
  }

  return input
}

export const formatAgeString = (input: string) => 
  input === '歳' ? '' : input

export const formatGenderString = (input: string) =>
  input === '不明' ? '未選択' : input

export const pickBy = (input: any, paths: string[]) =>
  paths.reduce((result, current) => {
    result[current] = input[current]
    return result
  }, {} as any)

export const popupParams = (width: number, height: number) => {
  const a = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft;
  const i = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop;
  const g = typeof window.outerWidth!='undefined' ? window.outerWidth : document.documentElement.clientWidth;
  const f = typeof window.outerHeight != 'undefined' ? window.outerHeight: (document.documentElement.clientHeight - 22);
  const h = (a < 0) ? window.screen.width + a : a;

  const left = parseInt(`${h + ((g - width) / 2)}`, 10);
  const top = parseInt(`${i + ((f-height) / 2.5)}`, 10);
  
  return 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + width + ',height=' + height + ',left=' + left + ',top=' + top
}  

export const multiLineText = (text: string) => {
  if (text == null) {
    return ''
  }
  return text.split(/\\r\\n|\\n/g).map((line, key) => (
    <span key={key}>
      {line}
      <br />
    </span>
  ))
}

export const stringify = (input: string | null) => (input ? input : '-')

export const throttle = (func: Function, timeFrame: number) => {
  let lastTime = 0
  return () => {
    const now = (new Date()).valueOf()
    if (now - lastTime >= timeFrame) {
      func()
      lastTime = now
    }
  }
}

export const standardizeDate = (input: string) => {
  const startDate = moment(input)
  const remainder = -(startDate.minute() % 30)

  return startDate
    .add(remainder, unitSet.minutes)
    .format(formatTemplate.dateWithHourMinute)
}

export const dateIntervals = ({
  startDate,
  endDate,
  format = formatTemplate.time,
}: {
  startDate: string
  endDate: string
  format?: string
}) => {
  return Math.floor(
    moment(endDate, format).diff(
      moment(startDate, format),
      unitSet.minutes,
      true
    ) / 30
  )
}

export const formatDay = (day: number) => {
  return moment()
    .add(day, unitSet.days)
    .format(formatTemplate.date)
}

export const formatDate = ({
  date,
  add,
  substract,
}: {
  date: string
  add?: string
  substract?: string | null
}) => {
  return moment(date, formatTemplate.time)
    .add(add, unitSet.minutes)
    .subtract(substract, unitSet.minutes)
    .format(formatTemplate.time)
}

export const formatReservationDate = (date: string) => {
  return moment(date, formatTemplate.dateWithHourMinute)
    .format(formatTemplate.time)
}

export const splitEventByDate = ({
  eventList,
  option,
  extra,
}: {
  eventList: Area[]
  option: {}
  extra?: string | null
}) => {
  return eventList.reduce((result, current) => {
    const { startDate, endDate, day } = current
    const intervals = dateIntervals({ startDate, endDate })

    if (intervals > 1) {
      return [
        ...result,
        ...Array(intervals)
          .fill(0)
          .map((_item, index) => ({
            start:
              formatDay(day) +
              'T' +
              formatDate({
                date: startDate,
                add: `${index * 30}`,
              }),
            end:
              formatDay(day) +
              'T' +
              formatDate({
                date: startDate,
                add: `${(index + 1) * 30}`,
                substract: extra,
              }),
            ...option,
          })),
      ]
    } else {
      return [
        ...result,
        {
          start: formatDay(day) + 'T' + startDate,
          end:
            formatDay(day) +
            'T' +
            formatDate({ date: endDate, substract: extra }),
          ...option,
        },
      ]
    }
  }, [] as any[])
}

export const validateDate = ({
  input,
  min,
  max,
  className,
  timeIntervals = magiContants.ZS080_TIMEINTERVALS,
}: {
  input: Date | null
  min: string
  max: string
  className: string
  timeIntervals?: number
}) => {
  if (input) {
    const inputTimeString = input
      .toTimeString()
      .split(' ')[0]
      .split(':')
      .slice(0, 2)
      .join(':')

    if (inputTimeString < min || inputTimeString > max) {
      return (
        <p className={className}>
          面接日時は{min}-{max}の間で入力してください。
        </p>
      )
    }

    if (input.getMinutes() % timeIntervals !== 0) {
      return (
        <p className={className}>
          面接日時は{timeIntervals}分単位で設定してください。
        </p>
      )
    }
  } else {
    return (
        <p className={className}>
          面接日時は必須です。
        </p>
    )
  }
}

export const validateStaffName = ({
  input,
  className,
  index,
}: {
  input: string | null
  className: string
  index: number
}) => {
  if (input) {
    const staffNameYup = yup.object().shape({
      [`staffName${index}`]: yup.string(),
    })
    
    try {
      staffNameYup.validateSync({ [`staffName${index}`]: input })
    } catch (err) {
      return (
        <p className={className}>
          {err.errors}
        </p>
      )
    }
  }
}

export const roundMinutes = (time: any) => {
  if (time.start instanceof Date) {
    const start = moment(time.start.getTime())
    const remainder = 30 - (start.minute() % 30)
    const dateTime = moment(start).add(remainder, unitSet.minutes)
    return dateTime
  }
  if (typeof time.start === 'string') {
    const start = moment(time.start)
    const remainder = 30 - (start.minute() % 30)
    const dateTime = moment(start).add(remainder, unitSet.minutes)
    return dateTime
  }
  return null
}

export const diffTime = (i: any, current: any) => {
  return (
    moment(roundMinutes(i)).diff(
      moment(roundMinutes(current)),
      unitSet.minutes
    ) === 0
  )}

export const isObject = (object: any) => {
  return object != null && typeof object === 'object'
}

type ObjectLike = {
  [key: string]: any
}

export const deepEqual = (object1: ObjectLike, object2: ObjectLike) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }

  return true
}

export const formatSpace = (input: string[]) => 
  input.filter(i => Boolean(i)).join("\xa0")

export const combineClassName = (...classNames: string[]) =>
  classNames.join(' ')

export const textOmit = (text: string, len: number, ellipsis: string) =>
  text
    ? text.length >= len + 1
      ? text.slice(0, len + 1 - ellipsis.length) + ellipsis
      : text
    : text

export const setWithExpiry = (key: string, value: string, ttl: number) => {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const getWithExpiry = (key: string) => {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}
