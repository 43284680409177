import { InitRecruitmentManagementFlagInfo } from 'reducers/recruitmentManagementFlagChangeReducer'
import { MCAXS340UpdateFlagRequest } from 'types/MCAXS340/MCAXS340UpdateFlagRequest'

export interface MCAXS340InitRequest {
  // 求職者ID
  jobSeekerId: number[]
  // エントリーID
  entryId: number[]
  // 選考管理ID
  selectionManagementId: number[]
  // 採用管理フラグ設定ID
  recruitmentManagementFlagSettingId: number[]
  // 採用管理フラグ選択肢設定ID
  recruitmentManagementFlagOptionSettingId: number[]
  // 設定文字列
  settingString: string[]
  // 設定数値
  settingNumber: number[]
  // 採用管理区分
  recruitmentManagementDivision: string
}
// 採用管理フラグ選択肢情報
export interface MCAXS340RecruitmentManagementFlagOptionListResultDto {
  // 採用管理フラグ設定ID
  recruitmentManagementFlagSettingId: number
  // (選択肢)採用管理フラグ選択肢設定ID
  recruitmentManagementFlagOptionSettingId: number
  // 選択肢
  option: string
  // (選択肢)表示順
  displayOrder: number
}

// アクション設定の採用管理フラグ情報(選考管理IDが設定されていない)
// 応募者の採用管理フラグ情報(選考管理IDが1件)
export interface MCAXS340RecruitmentManagementFlagListResultDto {
  // 採用管理フラグID
  recruitmentManagementFlagId: number
  // 選考管理ID
  selectionManagementId: number
  // 採用管理フラグ設定ID
  recruitmentManagementFlagSettingId: number
  // 採用管理フラグ選択肢設定ID
  recruitmentManagementFlagOptionSettingId: number
  // 設定文字列
  settingString: string
  // 設定数値
  settingNumber: string
  // 採用管理フラグ名
  recruitmentManagementFlagName: string
  // 種別
  type: string
  // 表示順
  displayOrder: number
  // sysバージョン番号
  sysVersionNumber: string
}

// 応募者の採用管理フラグ情報(選考管理IDが複数件)
export interface MCAXS340RecruitmentManagementFlagCompanyListResultDto {
  // 採用管理フラグ設定ID
  recruitmentManagementFlagSettingId: number
  // 企業ID
  companyId: number
  // 採用管理区分
  recruitmentManagementDivision: string
  // 採用管理フラグ名
  recruitmentManagementFlagName: string
  // 種別
  type: string
  // 表示順
  displayOrder: number
}
// 選考の採用管理フラグ情報
export interface MCAXS340RecruitmentManagementFlagUpdateCheckDto {
  // 選考管理ID
  selectionManagementId: number
  // 採用管理フラグ設定ID
  recruitmentManagementFlagSettingId: number
  // 採用管理フラグID
  recruitmentManagementFlagId: number
  // sysバージョン番号
  sysVersionNumber: number
}

const initValues = {
  jobSeekerId: [] as number[],
  entryId: [] as number[],
  selectionManagementId: [] as number[],
  recruitmentManagementFlagSettingId: [] as number[],
  recruitmentManagementFlagOptionSettingId: [] as number[],
  settingString: [] as string[],
  settingNumber: [] as number[],
  recruitmentManagementDivision: '',
}

const RMFOptionListResultValues: MCAXS340RecruitmentManagementFlagOptionListResultDto[] = []

const RMFListResultValues: MCAXS340RecruitmentManagementFlagListResultDto[] = []

const RMFCompanyListResultValues: MCAXS340RecruitmentManagementFlagCompanyListResultDto[] = []

const RMFUpdateCheckValues: MCAXS340RecruitmentManagementFlagUpdateCheckDto[] = []

const initRMFInfoInitialValues: InitRecruitmentManagementFlagInfo = {
  mcaxs340RecruitmentManagementFlagListResultDto: RMFListResultValues,
  mcaxs340RecruitmentManagementFlagOptionListResultDto: RMFOptionListResultValues,
  mcaxs340RecruitmentManagementFlagCompanyListResultDto: RMFCompanyListResultValues,
  mcaxs340RecruitmentManagementFlagUpdateCheckDto: RMFUpdateCheckValues,
}

export { initValues, initRMFInfoInitialValues }
