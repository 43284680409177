import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { magiContants as contants } from 'utils/contants'
import { FormControl } from '..'
import { RootState } from 'reducers'
import { openModal } from 'reducers/messageReducer'
import { useDispatch, useSelector } from 'react-redux'
import { getMcaxs551Init, setStudentDetailsFlag } from 'reducers/advancedSearchModalReducer'
import { Option, SchoolModalState } from '../Config/searchConditionConfig'
import { transform } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { innerSortFunc } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'

const optionSet: { [key: string]: string[] } = {
  大学院: [
    '院／事務系',
    '院／機械系',
    '院／電気・電子系',
    '院／化学系',
    '院／土木・建築系',
    '院／航空宇宙・船舶海洋系',
    '院／情報工学系',
    '院／生物系',
    '院／材料系',
    '院／数学・情報科学系',
    '院／物理系',
    '院／地学系',
    '院／資源系',
    '院／経営・管理系',
    '院／農学系',
    '院／獣医・畜産系',
    '院／医学・歯学系',
    '院／薬学系',
    '院／医療・保健・看護系',
    '院／家政・生活科学系',
    '院／保育系',
    '院／体育・健康科学系',
    '院／芸術系',
    '院／デザイン系',
    '院／その他理系',
    '院／課程不明',
    '専門院／法科',
    '専門院／会計・ファイナンス',
    '専門院／経営・政策・医療他',
  ],
  大学: [
    'ビジネス系',
    '外国語系',
    '文学系',
    '教育系',
    '人文・教養・総合科学系',
    '福祉系',
    '機械系',
    '電気・電子系',
    '化学系',
    '土木・建築系',
    '航空宇宙・船舶海洋系',
    '情報工学系',
    '生物系',
    '材料系',
    '数学・情報科学系',
    '物理系',
    '地学系',
    '資源系',
    '経営・管理系',
    '農学系',
    '獣医・畜産系',
    '医学・歯学系',
    '薬学系（４年制）',
    '薬学系（６年制）',
    '医療・保健・看護系',
    '家政・生活科学系',
    '保育系',
    '体育・健康科学系',
    '芸術系',
    'デザイン系',
    'その他理系',
    '学科不明',
  ],
  対象外: [
    '短大',
    '高専',
    'ＩＴ・マルチメディア系',
    '建築・インテリア系',
    '医療・福祉・栄養・美容系',
    'その他工業・技術・環境系',
    'ビジネス・語学・経営系',
    '旅行・ホテル・ブライダル系',
    'アート・ファッション系',
    'その他',
    '専門大学／ビジネス系',
    '専門大学／外国語系',
    '専門大学／文学系',
    '専門大学／教育系',
    '専門大学／人文・教養・総合科学系',
    '専門大学／福祉系',
    '専門大学／機械系',
    '専門大学／電気・電子系',
    '専門大学／化学系',
    '専門大学／土木・建築系',
    '専門大学／航空宇宙・船舶海洋系',
    '専門大学／情報工学系',
    '専門大学／生物系',
    '専門大学／材料系',
    '専門大学／数学・情報科学系',
    '専門大学／物理系',
    '専門大学／地学系',
    '専門大学／資源系',
    '専門大学／経営・管理系',
    '専門大学／農学系',
    '専門大学／獣医・畜産系',
    '専門大学／薬学系（４年制）',
    '専門大学／医療・保健・看護系',
    '専門大学／家政・生活科学系',
    '専門大学／保育系',
    '専門大学／体育・健康科学系',
    '専門大学／芸術系',
    '専門大学／デザイン系',
    '専門大学／その他理系',
    '専門大学／学科不明',
    '専門短大',
  ],
}

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'block',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  subtitle: {
    margin: theme.spacing(3, 0, 1),
  },
  link: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 0,
  },
  checkbox: {
    //display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: 15,
  },
  modalButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
  },
  button: {
    marginLeft: theme.spacing(2.5),
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: '30%',
  },
  cancel: {
    marginRight: theme.spacing(2.5),
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
  },
  confirmButton: {
    marginLeft: theme.spacing(2.5),
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
  },
  content: {
    float: 'left',
    '& div': {
      display: 'inline-block',
      margin: '0px 19px 0px 0px',
      cursor: 'pointer',
    },
    '& div input': {
      marginRight: '5px',
      cursor: 'pointer',
    }
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  title: string
  form: FormControl
  modalState: SchoolModalState
}

const StudentModal = ({ open, onClose, title, form, modalState }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const studentModalInfo = useSelector(
    (state: RootState) => state.advancedSearchModal.studentModalInfo
  )

  const studentDetailsFlag = useSelector((state: RootState) => state.advancedSearchModal.studentDetailsFlag)
  const studentClassificationObj = form.values.studentClassificationObj
  const options : Option[] = null !== studentClassificationObj && studentClassificationObj.length > 0 ?
    studentClassificationObj.map(i => {
      return { label: i.label, value: i.value }
    }) : []
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openOkDialog, setOpenOkDialog] = useState(false)
  useEffect(() => {
    if (open) {
      dispatch(getMcaxs551Init())
      if(options && !studentDetailsFlag){
        setSelected(options)
      }
    }
  }, [open])

  const [currentModal, setCurrentModal] = useState('')
  const [modalStatus, setModalStatus] = useState({
    confirm: false,
  })

  // OK/Cancel
  const handleConfirm = (title: string, open = true) => () => {
    setCurrentModal(title)
    setModalStatus({ ...modalStatus, confirm: open })
  }

  // 学生分類を指定する
  const handleClick = (open = true) => () => {
    if (selected.length) {
      setCurrentModal('studentDetails')
      modalState.studentDetails = true
      modalState.studentClassificationSelected = selected
      dispatch(setStudentDetailsFlag(false))
      onClose()
    } else {
      dispatch(openModal(contants.MESSAGECODE_MCAXS551_001))
    }
  }
  const handleCancel = () => {
    setOpenCancelDialog(false)
    setOpenOkDialog(false)
  }
   
  const handleCancelOk = () => {
    setOpenCancelDialog(false)
    dispatch(setStudentDetailsFlag(false))
    onClose()
  }
  // キャンセル確認ダイアログ表示
  const handleClickDialog = () => {
    setOpenCancelDialog(true)
  }

  // OK確認ダイアログ表示
  const handleOkDialog = () => {
    setOpenOkDialog(true)
  }

  const handleSubmit = () => {
    //選択した値を画面の選択肢の並び順でソート
    let orderObjArray: { type: string; subjectDivisionCode: string }[] = []
    orderObjArray = orderObjArray.concat(
      studentModalInfo.studentClassificationListForGraduate.map(i => ({
        type: '大学院',
        subjectDivisionCode: i.subjectDivisionCode,
      }))
    )
    orderObjArray = orderObjArray.concat(
      studentModalInfo.studentClassificationListForCollege.map(i => ({
        type: '大学',
        subjectDivisionCode: i.subjectDivisionCode,
      }))
    )
    const sortedSelected = [...selected].sort((a, b) => {
        return innerSortFunc(
          { type: a.type, subjectDivisionCode: a.value },
          { type: b.type, subjectDivisionCode: b.value },
          orderObjArray,
        )
    })

    handleConfirm(currentModal, false)()
    dispatch(setStudentDetailsFlag(false))
    setOpenOkDialog(false)
    onClose()
    // 学科詳細情報は削除
    form.setFieldValue('departmentDetailsObj', [])
    form.setFieldValue('studentClassificationObj', sortedSelected)
    modalState.studentDetailsSelected = []
    modalState.studentClassificationSelected = sortedSelected
  }

  const [selected, setSelected] = useState(
    modalState.studentClassificationSelected || []
  )

  useEffect(() => {
    setSelected(form.values.studentClassificationObj || [])
  }, [form.values.studentClassificationObj])

  const handleSelect = (option: Option, type: string) => (e: any) => {
    const selectedOption = selected.filter(i => i.value === option.value)
    if (selectedOption.length > 0) {
      setSelected(selected.filter(i => i.value !== option.value))
    } else {
      setSelected([...selected, {...option,type: type}])
    }
  }

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>{title}</label>
        </DialogTitle>
        <DialogContent>
          {studentModalInfo
            ? Object.keys(studentModalInfo).map(info => (
                <FormGroup key={info}>
                  <Typography variant='subtitle1' className={classes.subtitle}>
                    {info === 'studentClassificationListForGraduate'
                      ? '大学院' : '大学'
                    }
                  </Typography>
                  <div className={classes.checkbox}>
                    {studentModalInfo[info]
                      ? studentModalInfo[info]
                          .map(transform)
                          .map(item => (
                            <div className={classes.content}>
                              <div onClick={handleSelect(item, info === 'studentClassificationListForGraduate' ? '大学院' : '大学')} >
                                <input type="checkbox" checked={selected.some(i => i.value === item.value)} />{item.label}
                              </div>
                            </div>
                          ))
                      : null}
                  </div>
                </FormGroup>
              ))
            : null}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleClick()}
              color='primary'
              variant='contained'
              className={classes.modalButton}>
              学科詳細を指定する
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleClickDialog}
              className={classes.cancel}>
              キャンセルする
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={handleOkDialog}
              className={classes.confirmButton}>
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      
      <Dialog open={openCancelDialog} onClose={handleCancel}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS551_002}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOkDialog} onClose={handleConfirm(currentModal, false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS551_003}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleSubmit} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default StudentModal
