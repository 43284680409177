import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface starEvaluationUpdate {
    selectionId: number[] //選考管理ID
    evaluationId: string //星評価
    sysVersionNumber: number[] //sysバージョン番号
}

const initialState: starEvaluationUpdate = {
    selectionId: [], //選考管理ID
    evaluationId: '', //星評価
    sysVersionNumber: [], //sysバージョン番号
}

const starEvaluationUpdateSlice = createSlice({
    name: 'accout',
    initialState,
    reducers: {
        // 画面初期表示時用
        starEvaluationInit(state, action: PayloadAction<number[]>) {
            return state
        }, 
        setStarEvaluationInit(state, action: PayloadAction<starEvaluationUpdate>) {
            return action.payload
        },
        starEvaluationUpdate(state, action: PayloadAction<{request: starEvaluationUpdate, onclose?: () => void}>) {
            return state
        },
    },
})
  
export const {
    starEvaluationInit,
    setStarEvaluationInit,
    starEvaluationUpdate,
} = starEvaluationUpdateSlice.actions

export default starEvaluationUpdateSlice.reducer