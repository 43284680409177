import request from 'utils/request'
import { MCAXS121InitRequest } from 'types/MCAXS121/MCAXS121InitRequest'
import { MCAXS121MonitorRequest } from 'types/MCAXS121/MCAXS121MonitorRequest'

export const initApi = (apiData: MCAXS121InitRequest) =>
  request({
    url: "/MCAXS121/init",
    method: 'post',
    data: apiData,
  })

export const monitorApi = (apiData: MCAXS121MonitorRequest) =>
  request({
    url: "/MCAXS121/monitor",
    method: 'post',
    data: apiData,
  })
