import {
  call,
  put,
  all,
  takeEvery,
  takeLeading,
  select,
} from 'redux-saga/effects'
import { setDialogOpen, progressStatusChangeInit, setProgressStatusChangeInit, recommendCheck, setRecommendCheckDialogOpen, updateProgress } from 'reducers/progressStatusChangeReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { RootState } from 'reducers'
import { processChangeListInit, UnifiedInit, initCondition as InitCondition, processChangeInit, setUpdateFlag, processChangeMessageInit, processChangeMessageListInit } from 'reducers/selectionStatusUpdateReducer'
import { progressStatusChangeInitRequest, recommendCheckRequest, updateProgressRequest } from 'apis/MCAXS110Api'

function* progressStatusChangeInitSaga(action: ReturnType<typeof progressStatusChangeInit>) {
  try {

    const data = yield call(progressStatusChangeInitRequest, action.payload.selectionManagementId);
    yield put(setProgressStatusChangeInit(data))
    yield put(setDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 推薦チェック
function* recommendCheckSaga(action: ReturnType<typeof recommendCheck>) {
  try {
    const recommendEntryCount = yield call(recommendCheckRequest, encodeURIComponent(JSON.stringify(action.payload)));
    yield put(setRecommendCheckDialogOpen(recommendEntryCount))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 
function* updateProgressSaga(action: ReturnType<typeof updateProgress>) {
  try {
    const message = yield call(updateProgressRequest, encodeURIComponent(JSON.stringify(action.payload)));
    // 1.5.9 画面制御
    yield put(openSnackbar(message));
    // 1.5.10 本画面を閉じる。
    yield put(setDialogOpen(false))
    let selectionManagementIds: number[] = []
    for (var i = 0; i < action.payload.selectionManagementId.length; i++) {
      selectionManagementIds.push(Number(action.payload.selectionManagementId[i]))
    }
    // 呼出し側機能ID
    const screenId = yield select((state: RootState) => state.progressStatusChange.initParm.screenId)
    if (screenId == 'MCAYS030') {
      const initCondition: UnifiedInit = {
        selectionManagementIdArray: selectionManagementIds,
        recruitmentManagementDivision: '2',
      }
      // 合否・選考ステップを一括変更
      yield put(processChangeListInit(initCondition))
      // エントリー一覧変更フラグ
      yield put(setUpdateFlag(true));
    }
    else if (screenId == 'MCAYS020') {
      const initCondition: InitCondition = {
        selectionManagementId: selectionManagementIds[0],
        recruitmentManagementDivision: '2',
      }
      // 合否・選考ステップを変更
      yield put(processChangeInit(initCondition))
      // エントリー一覧変更フラグ
      yield put(setUpdateFlag(true));
    }
    else if (screenId == 'MCAZS101') {
      const selectionManagementId = selectionManagementIds[0]
      const initCondition: InitCondition = {
        selectionManagementId: Number(selectionManagementId),
        recruitmentManagementDivision: '2',
      }
      yield put(processChangeMessageInit(initCondition))
  
      const unifiedInit: UnifiedInit = {
        selectionManagementIdArray: selectionManagementIds,
        recruitmentManagementDivision: '2',
      }
      yield put(processChangeMessageListInit(unifiedInit))

      // メッセージ送信完了
      yield put(setUpdateFlag(true));
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* progressStatusChangeSaga() {
  yield all([
    takeEvery(progressStatusChangeInit, progressStatusChangeInitSaga),
    takeEvery(recommendCheck, recommendCheckSaga),
    takeLeading(updateProgress, updateProgressSaga),
  ])
}
