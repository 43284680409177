import request from 'utils/request'
import { MCAXS260TargetIdRequest } from 'types/MCAXS260/MCAXS260TargetIdRequest'
import {MCAXS260OutputPdfRequest} from 'types/MCAXS260/MCAXS260OutputPdfRequest'
import {MCAXS260MailShareRequest} from 'types/MCAXS260/MCAXS260MailShareRequest'
import { MCAXS111initRequest } from 'types/MCAXS111/MCAXS111initRequest'
import { MCAXS260OutputMcbPdfRequest } from 'types/MCAXS260/MCAXS260OutputMcbPdfRequest'
import { MCAXS260OutputMcbCsvRequest } from 'types/MCAXS260/MCAXS260OutputMcbCsvRequest'
import { MCAXS260OtherOperationOfMcbRequest } from 'types/MCAXS260/MCAXS260OtherOperationOfMcbRequest'
import { MCAXS260SendMcbRequestMessageRequest } from 'types/MCAXS260/MCAXS260SendMcbRequestMessageRequest'
export const excludeApi = (requestdata: MCAXS260TargetIdRequest) =>
  request({
    url: "/MCAXS260/exclude",
    method: 'post',
    data: requestdata
  })

  
  export const restoreApi = (requestdata: MCAXS260TargetIdRequest) =>
  request({
    url: "/MCAXS260/restore",
    method: 'post',
    data: requestdata
  })

  export const deleteApi = (requestdata: MCAXS260TargetIdRequest) =>
  request({
    url: "/MCAXS260/delete",
    method: 'post',
    data: requestdata
  })

  export const outputPdfApi=(requestdata:MCAXS260OutputPdfRequest)=>
  request({
    url: "/MCAXS260/outputPdf",
    method: 'post',
    data: requestdata
  })

  export const mailShareApi=(requestdata:MCAXS260MailShareRequest)=>
  request({
    url: "/MCAXS260/mailShare",
    method: 'post',
    data: requestdata
  })
  

  export const sendMessageApi=(requestdata:MCAXS260TargetIdRequest)=>
  request({
    url: "/MCAXS260/sendMessage",
    method: 'post',
    data: requestdata
  })

  export const outputCsvApi=(requestdata:MCAXS111initRequest)=>
  request({
    url: "/MCAXS260/outputCsv",
    method: 'post',
    data: requestdata
  })

  export const  initApi=()=>
  request({
    url: "/MCAXS260/init",
    method: 'post'
  })

  // [phase2] start by zhangxp
  export const  mcbCheckApi=()=>
  request({
    url: "/MCAXS260/mcbCheck",
    method: 'post'
  })
  // [phase2] end by zhangxp
  
// MCBリプレース start
export const sendMcbRequestMessageApi = (param: MCAXS260SendMcbRequestMessageRequest) =>
  request({
    url: "/MCAXS260/sendMcbRequestMessage",
    method: 'post',
    data: param
  })

export const outputMcbPdfApi = (param: MCAXS260OutputMcbPdfRequest) =>
  request({
    url: "/MCAXS260/outputMcbPdf",
    method: 'post',
    data: param
  })

export const outputMcbCsvApi = (param: MCAXS260OutputMcbCsvRequest) =>
  request({
    url: "/MCAXS260/outputMcbCsv",
    method: 'post',
    data: param
  })

export const otherOperationOfMcbApi = (param: MCAXS260OtherOperationOfMcbRequest) =>
  request({
    url: "/MCAXS260/otherOperationOfMcb",
    method: 'post',
    data: param
  })
  // MCBリプレース end