import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCANS020CreateRequest } from 'types/MCANS020/MCANS020CreateRequest'
import { MCANS030UpdateRequest } from 'types/MCANS030/MCANS030UpdateRequest'
import { MCANS030DeleteRequest } from 'types/MCANS030/MCANS030DeleteRequest'
import { Row } from 'pages/MCANS010/formConfig'
import { signListInitialValues } from 'pages/MCANS010/formConfig'
import { signCreateInitialValues } from 'pages/MCANS020/formConfig'
import { signUpdateInitialValues } from 'pages/MCANS030/formConfig'

export interface SignList {
  signListResults: Row[]
  updateSignListResults: Row[]
  signListCount: number
}

export interface SignCreate {
  resisteredName: string;
  signatureText: string;
}

export interface SignUpdate {
  resisteredName: string;
  signatureText: string;
  signSettingId: string;
  sysVersionNumber: number;
}

interface Sign {
  SignList: SignList
  SignCreate: SignCreate
  SignUpdate: SignUpdate
  updateDialogOpen: boolean
  createDialogOpen: boolean
}

const initialState: Sign = {
  SignList: signListInitialValues,
  SignCreate: signCreateInitialValues,
  SignUpdate: signUpdateInitialValues,
  updateDialogOpen: false,
  createDialogOpen: false
}

const mediaSlice = createSlice({
  name: 'sign',
  initialState,
  reducers: {
    // -----------------MCANS0I0_署名一覧_START------------------------//
    // 画面初期表示時用
    getSignList(state) {
      return state
    },
    // DBから一覧データ取得
    getSignListSuccess: (state, action: PayloadAction<Row[]>) => {
      state.SignList.signListResults = action.payload
      state.SignList.signListCount = action.payload.length
    },

    // 表示順変更時用
    updateSignSortOrder(state, action: PayloadAction<Row[]>) {
      state.SignList.signListResults = action.payload
      return state
    },
    checkSignCount(state) {
      return state;
    },
    setCreateDialogOpen(state, action: PayloadAction<boolean>) {
      state.createDialogOpen = action.payload
      return state
    },
    // -----------------MCANS0I0_署名一覧_END------------------------//

    // -----------------MCANS020_署名登録_START------------------------//
    setSignCreate(state, action: PayloadAction<MCANS020CreateRequest>) {
      return state;
    },
    // -----------------MCANS020_署名登録_END------------------------//

    // -----------------MCANS030_署名編集_START------------------------//
    getSignById(state, action: PayloadAction<string>) {
      return state;
    },
    setSign(state, action: PayloadAction<SignUpdate>) {
      state.SignUpdate = action.payload
      return state;
    },
    updateSign(state, action: PayloadAction<MCANS030UpdateRequest>) {
      return state;
    },
    deleteSign(state, action: PayloadAction<MCANS030DeleteRequest>) {
      return state;
    },
    setUpdateDialogOpen(state, action: PayloadAction<boolean>) {
      state.updateDialogOpen = action.payload
      return state
    },
    // -----------------MCANS030_署名編集_END------------------------//
  },
})

export const {
  getSignList,
  getSignListSuccess,
  updateSignSortOrder,
  checkSignCount,
  setCreateDialogOpen,
  setSignCreate,
  getSignById,
  setSign,
  updateSign,
  deleteSign,
  setUpdateDialogOpen,
} = mediaSlice.actions
export default mediaSlice.reducer
