import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getMessage } from 'common/messageUtil'
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { FormControl } from '..'
import EnhancedTableHead from './EnhancedTableHead'
import { useModal } from 'utils'
import { Option } from '../Config/searchConditionConfig'

const regionSet = {
  hokkaidotohoku: [
    { label: '北海道', value: '01' },
    { label: '青森県', value: '02' },
    { label: '岩手県', value: '03' },
    { label: '宮城県', value: '04' },
    { label: '秋田県', value: '05' },
    { label: '山形県', value: '06' },
    { label: '福島県', value: '07' },
  ],
  kanto: [
    { label: '茨城県', value: '08' },
    { label: '栃木県', value: '09' },
    { label: '群馬県', value: '10' },
    { label: '埼玉県', value: '11' },
    { label: '千葉県', value: '12' },
    { label: '東京都', value: '13' },
    { label: '神奈川県', value: '14' },
  ],
  kousinnetuhokuriku: [
    { label: '新潟県', value: '15' },
    { label: '富山県', value: '16' },
    { label: '石川県', value: '17' },
    { label: '福井県', value: '18' },
    { label: '山梨県', value: '19' },
    { label: '長野県', value: '20' },
  ],
  tokaicyubu: [
    { label: '岐阜県', value: '21' },
    { label: '静岡県', value: '22' },
    { label: '愛知県', value: '23' },
    { label: '三重県', value: '24' },
  ],
  kinnki: [
    { label: '滋賀県', value: '25' },
    { label: '京都府', value: '26' },
    { label: '大阪府', value: '27' },
    { label: '兵庫県', value: '28' },
    { label: '奈良県', value: '29' },
    { label: '和歌山県', value: '30' },
  ],
  cyugokusikoku: [
    { label: '鳥取県', value: '31' },
    { label: '島根県', value: '32' },
    { label: '岡山県', value: '33' },
    { label: '広島県', value: '34' },
    { label: '山口県', value: '35' },
    { label: '徳島県', value: '36' },
    { label: '香川県', value: '37' },
    { label: '愛媛県', value: '38' },
    { label: '高知県', value: '39' },
  ],
  kyusyuokinawa: [
    { label: '福岡県', value: '40' },
    { label: '佐賀県', value: '41' },
    { label: '長崎県', value: '42' },
    { label: '熊本県', value: '43' },
    { label: '大分県', value: '44' },
    { label: '宮崎県', value: '45' },
    { label: '鹿児島県', value: '46' },
    { label: '沖縄県', value: '47' },
  ],
  kaigai: [{ label: '海外', value: '99' }],
}

export type Area = keyof typeof regionSet
export type Region = 'preferredWorkplaceObj'

const areaList: { label: string; value: Area }[] = [
  { label: '北海道・東北', value: 'hokkaidotohoku' },
  { label: '関東', value: 'kanto' },
  { label: '甲信越・北陸', value: 'kousinnetuhokuriku' },
  { label: '東海・中部', value: 'tokaicyubu' },
  { label: '近畿', value: 'kinnki' },
  { label: '中国・四国', value: 'cyugokusikoku' },
  { label: '九州・沖縄', value: 'kyusyuokinawa' },
  { label: '海外', value: 'kaigai' },
]

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 2,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span': {
      color: colors.grey[600],
    },
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '6px',
    '& input:checked + mark': {
      backgroundColor: '#3f71bd',
      backgroundPosition: 'center center',
      borderColor: '#3f71bd',
    },
  },
  mark: {
    height: '16px !important',
    width: '16px !important',
    marginRight: '8px !important',
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
  name: Region
  selectedAreaState: [Area, Dispatch<SetStateAction<Area>>]
}

type Address = 'current' | 'vacation'

const RegionModalWorkPlace = ({
  open,
  onClose,
  form,
  name,
  selectedAreaState: [selectedArea, setSelectedArea],
}: Props) => {
  const classes = useStyles()


  const [selectedRegions, setSelectedRegions] = useState(
    form.values[name] || []
  )

  useEffect(() => {
    setSelectedRegions(form.values[name] || [])
  }, [form.values[name]])

  const handleClick = (area: Area) => () => {
    setSelectedArea(area)
  }

  const handleSelect = (region: Option, checked: boolean) => () => {
    setSelectedRegions(
      checked        
        ? selectedRegions.filter(i => i.value !== region.value)
        : [...selectedRegions, region]
    )
  }

  const handleSelectAll = (checked: boolean) => () => {
    setSelectedRegions(
      checked
        ? selectedRegions.filter(i => regionSet[selectedArea].indexOf(i) === -1)
        : [
            ...new Map(
              [...selectedRegions, ...regionSet[selectedArea]].map(v => [
                v.value,
                v,
              ])
            ).values(),
          ]
    )
  }

  // Confirm Modal
  const [show, toggle] = useModal(false)
  const handleCancelOk = () => {
    setSelectedRegions(form.values[name] || [])
    toggle(false)()
    onClose()
  }

  const handleSave = () => {
    const tempValue = new Map();
    const selectValue: Option[] = selectedRegions.filter((i) => !tempValue.has(i.value) && tempValue.set(i.value, 1));
    const result = selectValue.slice().sort((a, b) => {
      if (a.value > b.value) {
        return 1
      }
      if (a.value < b.value) {
        return -1
      }
      return 0
    })
    form.setFieldValue(name, result)
    onClose()
  }
  return (
    <>
      {/* 転職March #90253 START */}
      <Dialog open={open} maxWidth='md' fullWidth className={'mainDialog'}>
      {/* 転職March #90253 END */}
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>
            希望勤務地（都道府県）を指定
          </label>
        </DialogTitle>
        <DialogContent>
          <div className={classes.select}>
            <List>
              {areaList.map(area => (
                <ListItem
                  button
                  key={area.value}
                  onClick={handleClick(area.value)}
                  selected={area.value === selectedArea}>
                  <ListItemText primary={area.label} />
                </ListItem>
              ))}
            </List>
            <List>
              <EnhancedTableHead
                onSelectAllClick={handleSelectAll}
                areaKye={selectedArea}
                selected={regionSet[selectedArea].every(
                  (region, i) => selectedRegions
                    .map((i: any) => i.value)
                    .indexOf(region.value) !== -1
                )}
              />
              {regionSet[selectedArea].map(region => {
                const checked = selectedRegions.map((i: any) => i.value).indexOf(region.value) !== -1
                return (
                  <ListItem button key={region.value}
                     onClick={handleSelect(region, checked)}
                     disableRipple>
                    <ListItemIcon>
                      <label className={classes.label}>
                        <input
                          type='checkbox'
                          checked={checked}
                          onChange={handleSelect(region, !checked)}
                        />
                        <mark className={classes.mark}></mark>
                      </label>
                    </ListItemIcon>
                    <ListItemText primary={region.label} />
                  </ListItem>
                )
              })}
            </List>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={toggle(true)}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSave}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={show} onClose={toggle(false)}>
        <DialogTitle>{getMessage('MCAXS521-001')}</DialogTitle>
        <DialogActions>
          <Button onClick={toggle(false)}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RegionModalWorkPlace
