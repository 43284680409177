import { getMessage } from "common/messageUtil"

const itemList = (type: string) => {
  // 画面レイアウト表示
  // const commonList = [
  //   { name: 'mediaSettingId', label: '応募経路設定ID' },
  //   { name: 'mediaName', label: '応募経路（経路）名' },
  //   { name: 'sysVersionNumber', label: 'sysバージョン番号' },
  // ]
}
const mediaUpdateInitialValues = {
  mediaSettingId: '',
  mediaName: '',
  sysVersionNumber: 0,
}
const textMap = {
  // テキストマップ
  edit: {
    submit: '更新する',
    message: getMessage('MCAIS030-004'),
  },
  delete: {
    submit: '削除する',
    message: getMessage('MCAIS030-005'),
  },
  cancel: {
    submit: 'キャンセルする',
    message: getMessage('MCAIS030-003'),
  },
}

export { mediaUpdateInitialValues, itemList, textMap }
