import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAWS010InitPopupRequest } from 'types/MCAWS010/MCAWS010InitPopupRequest'
import { popupInitialValues, alertInitialValues } from '../pages/MCAWS010/formConfig'
export interface PopupData {
    interviewCancelMessage: string
    contactUnansweredMessage: string
    interviewCurrentMessage: string
}
export interface AlertListData {
    interviewFixedMessage: string
    passingStatusMessage: string
    progressResidenceMessage: string
    passingSMID: number[]
    progressSMID: number[]
}
interface InitialValuesType {
    actionPopupInfo: PopupData,
    actionPreInfo: AlertListData,
    open: boolean,
    topBarFlg: boolean,
}

const initialState: InitialValuesType = {
    actionPopupInfo: popupInitialValues,
    actionPreInfo: alertInitialValues,
    open: false,
    topBarFlg: false,
}

const progressStagentAlertSlice = createSlice({
    name: 'progressStagentAlert',
    initialState,
    reducers: {

        // MCAWS010 「進捗停滞アラート（ポップアップ）」画面初期表示
        getInitPopup(state, action: PayloadAction<MCAWS010InitPopupRequest>) {
            return state
        },
        // MCAWS010 「進捗停滞アラート（ポップアップ）」画面初期表示
        setInitPopup(state, action: PayloadAction<PopupData>) {
            state.actionPopupInfo = action.payload
            return state
        },
        // MCAWS010 「進捗停滞アラート（アラートエリア）」画面初期表示
        getInitAreaContactPass(state, action: PayloadAction<MCAWS010InitPopupRequest>) {
            return state
        },
        // MCAWS010 「進捗停滞アラート（アラートエリア）」画面初期表示
        setInitAreaContactPass(state, action: PayloadAction<AlertListData>) {
            state.actionPreInfo = action.payload
            return state
        },
        // MCAWS010 ポップアップ
        setProgressAlertDialogOpen(state, action: PayloadAction<boolean>) {
            state.open = action.payload
            return state
        },
        // MCAWS010 フラグ
        setTopBarFlg(state, action: PayloadAction<boolean>) {
            state.topBarFlg = action.payload
            return state
        },
    },
})

export const {
    // MCAWS010
    getInitPopup,
    setInitPopup,
    getInitAreaContactPass,
    setInitAreaContactPass,
    setProgressAlertDialogOpen,
    setTopBarFlg,
} = progressStagentAlertSlice.actions
export default progressStagentAlertSlice.reducer
