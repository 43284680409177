import request from 'utils/request'
import { MCAZS170MessageQueryRequest } from 'types/MCAZS170/MCAZS170MessageQueryRequest'
import { MCAZS170MessageSelectRequest } from 'types/MCAZS170/MCAZS170MessageSelectRequest'

export const outBoxInitRequest = (apiData: MCAZS170MessageQueryRequest) =>
  request({
    url: '/MCAZS170/init',
    method: 'post',
    data: apiData,
  })

export const selectMessageOutBoxRequest = (
  apiData: MCAZS170MessageQueryRequest
) =>
  request({
    url: '/MCAZS170/find',
    method: 'post',
    data: apiData,
  })

export const moveTrashMessageRequest = (
  apiData: MCAZS170MessageSelectRequest
) =>
  request({
    url: '/MCAZS170/moveTrashMessage',
    method: 'post',
    data: apiData,
  })

export const showEntryListRequest = (apiData: any) =>
  request({
    url: `/MCAZS170/showEntryList`,
    method: 'post',
    data: apiData,
  })

export const showSendObjEntryListRequest = (apiData: any) =>
  request({
    url: `/MCAZS170/showSendObjEntryList`,
    method: 'post',
    data: apiData,
  })