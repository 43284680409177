const initialValues = {
    jobSeekerId: '',
    entryId: ''
}

const initialValues1 = {
    membersId: '',
    familyName: '',
    Name: '',
    targetName: '',
    jobSeekerId: '',
    entryId: '',
    showDialog: false
}

const initialValues2 = {
    jobSeekerId: '',
    entryId: '',
    applicationRefusal: '0',
    interviewAbsence: '0',
    multipleApplication: '0',
    loseTouch: '0',
    careerMisrepresentation: '0',
    slander: '0',
    unrelated: '0',
    other: '0',
    harassmentContent: '',
    harassmentDivision: '',
    showDialog020: false
}
export { initialValues, initialValues1, initialValues2 }