import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApplicantInfo } from 'componentsHsc/AdvancedSearchJobChange/Config/applicantConfig'
import { BasicInfo } from 'componentsHsc/AdvancedSearchJobChange/Config/basicConfig'
import { RecruitmentManagementInfo } from 'componentsHsc/AdvancedSearchJobChange/Config/recruitmentManagementConfig'
import {
  SearchCondition,
  initSearchCondition,
  OrderObjArray,
  initOrderObjArray,
} from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { MCAXS431QueryRequest } from 'types/MCAXS431/MCAXS431QueryRequest'
import { SearchCondition as MCAXS020SearchCondition } from 'pages/MCAXS020/searchConditionConfig'

/**
 * 応募情報_初期表示用データ格納情報
 */
const applicantInfo: ApplicantInfo = {
  memberTypeList: [], // 会員種別
  applicationRouteList: [], // 応募経路
  entryJobCategoryList: [], // 応募職種
  entryTypeList: [], // エントリー種別
  entryClassificationList: [], // エントリー区分
  scoutBenefitsList: [], // スカウト特典
}

/**
 * 基本情報_初期表示用データ格納情報
 */
const basicInfo: BasicInfo = {
  sexList: [], // 性別情報
  spouseList: [], // 配偶者
  finalEducationList: [], // 最終学歴
  educationBackgroundList: [], // 学歴
  languageSkillsList: [], // 語学スキル
  eligibilityList: [], // 保有資格
  experienceCountList: [], // 経験社数
  employmentSituationList: [], // 現在の就業状況
  jobCareerList: [], // 職務経歴
  experiencedJobList: [], // 経験職種
}

/**
 * 採用管理情報_初期表示用データ格納情報
 */
const recruitmentManagementInfo: RecruitmentManagementInfo = {
  selectionFlowList: [], // 選考フロー
  selectionStepList: [], // 選考ステップ
  judgementList: [], // 合否判定
  tagList: [], // タグ
  frequentlyUsedTagList: [], // よく使うタグ
  managementItemConditionObj: [], // 管理項目条件
  // #58578 次期開発2022年5月 start
  displayShlInfo: {
    displayOpq: false,
    displayGab: false,
    displayImages: false,
    displayCab: false,
  }, //SHL項目表示情報
  // #58578 次期開発2022年5月 end
}

const initialState: {
  applicantInfo: ApplicantInfo // 応募情報結果
  basicInfo: BasicInfo // 基本情報結果
  recruitmentManagementInfo: RecruitmentManagementInfo // 採用管理情報結果
  searchCondition: SearchCondition // 検索条件
  open: boolean //　検索条件エリア開閉フラグ
  entryReplyModalOpen: boolean //アンケート回答モーダル開閉フラグ
  managementModalOpen: boolean //管理項目モーダル開閉フラグ
  orderObjArray: OrderObjArray
} = {
  applicantInfo: applicantInfo,
  basicInfo: basicInfo,
  recruitmentManagementInfo: recruitmentManagementInfo,
  searchCondition: initSearchCondition,
  open: false,
  entryReplyModalOpen: false,
  managementModalOpen: false,
  orderObjArray: initOrderObjArray,
}

const advancedSearchJobChangeSlice = createSlice({
  name: 'advancedSearchJobChange',
  initialState,
  reducers: {
    // 検索条件詳細部品の初期画面表示情報を取得
    getMcaxs401Init(
      state,
      action: PayloadAction<{
        mCAXS431Request: MCAXS431QueryRequest
        mCAXS020SearchCondition?: MCAXS020SearchCondition
      }>
    ) {
      return state
    },
    // 検索条件詳細部品の初期画面表示情報を設定
    setMcaxs401Init(
      state,
      action: PayloadAction<{
        mCAXS411Result: ApplicantInfo
        mCAXS421Result: BasicInfo
        mCAXS431Result: RecruitmentManagementInfo
      }>
    ) {
      state.applicantInfo = action.payload.mCAXS411Result
      state.basicInfo = action.payload.mCAXS421Result
      state.recruitmentManagementInfo = action.payload.mCAXS431Result

      const {
        sexList,
        spouseList,
        finalEducationList,
        experienceCountList,
        employmentSituationList,
      } = action.payload.mCAXS421Result
      const {
        memberTypeList,
        applicationRouteList,
        entryTypeList,
        entryClassificationList,
        scoutBenefitsList,
      } = action.payload.mCAXS411Result
      const {
        selectionStepList,
        judgementList
      } = action.payload.mCAXS431Result
      
      state.orderObjArray = {
        sexCodeList: sexList.map(i => ({sexCode:Number(i.value)})),
        spouseCodeList: spouseList.map(i => ({spouseCode:Number(i.value)})),
        finalEducationUniversityCodeList: finalEducationList.map(i => ({finalEducationUniversityCode:Number(i.value)})),
        experienceCountCodeList: experienceCountList.map(i => ({experienceCountCode:Number(i.value)})),
        employmentSituationCodeList: employmentSituationList.map(i => ({employmentSituationCode:Number(i.value)})),
        memberTypeCodeList: memberTypeList.map(i => ({memberTypeCode:Number(i.value)})),
        applicationRouteInfoList:applicationRouteList.map(i => ({applicationRouteFlag:Number(i.applicationRouteFlag), applicationRouteId:Number(i.applicationRouteId)})),
        entryTypeCodeList: entryTypeList.map(i => ({entryTypeCode:Number(i.value)})),
        entryClassificationCodeList: entryClassificationList.map(i => ({entryClassificationCode:Number(i.value)})),
        scoutBenefitsCodeList: scoutBenefitsList.map(i => ({scoutBenefitsCode:Number(i.value)})),
        selectionStepIdList: selectionStepList.map(i => ({selectionStepId:Number(i.progressStatusSettingId)})),
        judgmentUndeterminedIdList: judgementList.map(i => ({judgmentUndeterminedId:Number(i.itemCode)}))
      }
      return state
    },
    // 応募情報を取得
    getMcaxs411Init(state) {
      return state
    },
    // 応募情報結果を設定
    setMcaxs411Init(state, action: PayloadAction<ApplicantInfo>) {
      state.applicantInfo = action.payload
      return state
    },
    // 基本情報を取得
    getMcaxs421Init(state) {
      return state
    },
    // 基本情報結果を設定
    setMcaxs421Init(state, action: PayloadAction<BasicInfo>) {
      state.basicInfo = action.payload
      return state
    },
    // 採用管理情報を取得
    getMcaxs431Init(state, action: PayloadAction<MCAXS431QueryRequest>) {
      return state
    },
    // 採用管理情報結果を設定
    setMcaxs431Init(state, action: PayloadAction<RecruitmentManagementInfo>) {
      state.recruitmentManagementInfo = action.payload
      return state
    },
    // 検索条件設定
    setSearchCondition(state, action: PayloadAction<SearchCondition>) {
      return state
    },
    // My検索に保存
    saveSearchCondition(state, action: PayloadAction<SearchCondition>) {
      return state
    },
    // 検索条件エリア開閉フラグを設定
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload
      return state
    },
    // アンケート回答モーダルの開閉フラグを設定
    setEntryReplyModalOpen(state, action: PayloadAction<boolean>) {
      state.entryReplyModalOpen = action.payload
      return state
    },
    // 管理項目モーダルの開閉フラグを設定
    setManagementModalOpen(state, action: PayloadAction<boolean>) {
      state.managementModalOpen = action.payload
      return state
    },
  },
})

export const {
  getMcaxs401Init,
  setMcaxs401Init,
  setSearchCondition,
  saveSearchCondition,
  setOpen,
  setEntryReplyModalOpen,
  setManagementModalOpen,
} = advancedSearchJobChangeSlice.actions
export default advancedSearchJobChangeSlice.reducer
