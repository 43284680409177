/**
 * MCASS010CreateRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({
    // 応募辞退
    // No1～8のうち、いずれかが必須
    applicationRefusal: yup
        .string()
        .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 面接欠席
    // -
    interviewAbsence: yup
        .string()
        .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 複数回応募
    // -
    multipleApplication: yup
        .string()
        .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 音信不通
    // -
    loseTouch: yup
        .string()
        .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 経歴詐称
    // -
    careerMisrepresentation: yup
        .string()
        .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 誹謗中傷
    // -
    slander: yup
        .string()
        .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 無関係
    // -
    unrelated: yup
        .string()
        .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // その他
    // -
    other: yup
        .string()
        .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
    // 迷惑行為の内容
    // -
    harassmentContent: yup
        .string()
        .max(1000, validationMessageSet.maxLength),
});