import React, { useEffect } from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, FormControl } from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '&:disabled': {
      color: '#999999',
      backgroundColor: '#e0e0e0',
      borderColor: '#cccccc',
      pointerEvents: 'none',
    },
  },
  hint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.5),
  },
  textarea: {
    resize: 'none',
  },
  error: {
    borderColor: '#e53935!important',
    '&:focus': {
      borderWidth: 2,
      padding: '0px 7px',
    }
  },
}))

interface Props extends FieldProps {
  hint?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  multiline?: boolean
  type?: string
  formName?: string
  id?: string
}

export default ({
  className,
  placeholder,
  field,
  form,
  hint,
  disabled,
  multiline,
  type,
  formName,
  id,
}: Props) => {
  const classes = useStyles()
  const errorClasses = useErrorStyle()
  const { name, value } = field
  const { errors, touched } = form
  let index1 = name.match(/conditionList\[(\S*)\].questionInfoList.*/) ? (name.match(/conditionList\[(\S*)\].questionInfoList.*/) as any)[1] : -1
  let index2 = name.match(/.*questionInfoList\[(\S*)\].questionnaireResponseAnswerText/) ? (name.match(/.*questionInfoList\[(\S*)\].questionnaireResponseAnswerText/) as any)[1] : -1
  let { message, errorFlg1 } = function() {
    let errorFlg1 = false
    let message
    if (errors['conditionList']) {
      if ((errors['conditionList'] as any)[index1]) {
        if ((errors['conditionList'] as any)[index1]['questionInfoList']) {
          if ((errors['conditionList'] as any)[index1]['questionInfoList'][index2]) {
            message = (errors['conditionList'] as any)[index1]['questionInfoList'][index2].questionnaireResponseAnswerText
            errorFlg1 = true
          }
        }
      }
    }
    return { message, errorFlg1 }
  }()
  let errorFlg2 = function() {
    let errorFlg2 = false
    if (touched['conditionList']) {
      if ((touched['conditionList'] as any)[index1]) {
        if ((touched['conditionList'] as any)[index1]['questionInfoList']) {
          if ((touched['conditionList'] as any)[index1]['questionInfoList'][index2]) {
            errorFlg2 = (touched['conditionList'] as any)[index1]['questionInfoList'][index2].questionnaireResponseAnswerText
          }
        }
      }
    }
    return errorFlg2
  }()

  useEffect(() => {
    if (disabled) {
      form.setFieldValue(name, '')
    }
  }, [disabled])

  const handleChange = (e: any) => {
    if (!(hint && e.target.value.length > 100)) {
      form.setFieldValue(name, e.target.value)
      form.setFieldTouched(name, true, true)
    }
  }

  return (
    <FormControl
      error={Boolean(errorFlg1 && errorFlg2)}>
      <div className={className}>
        {multiline ? (
          <textarea
            id={id}
            value={value ? value : ''}
            onChange={handleChange}
            placeholder={placeholder}
            className={`${classes.root} ${classes.textarea} ${errorFlg1 && errorFlg2 ? classes.error : ''}`}
            disabled={disabled}
            name={name}
          />
        ) : (
            <input
              id={id}
              type={type ? type : 'text'}
              value={value ? value : ''}
              onChange={handleChange}
              placeholder={placeholder}
              className={`${classes.root} ${errorFlg1 && errorFlg2 ? classes.error: ''}`}
              disabled={disabled}
              name={name}
            />
          )}
        {hint ? (
          <div className={classes.hint}>
            <Typography variant='caption'>{`入力${value.length} / 最大100`}</Typography>
          </div>
        ) : null}
        {errorFlg1 && errorFlg2 ? (
          <div className={errorClasses.helperText}>{message}</div>
        ) : null}
      </div>
    </FormControl>
  )
}
