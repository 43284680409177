import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MCAQS020InitDuplicateIdentificationList} from "types/MCAQS020/MCAQS020InitDuplicateIdentificationList";
import {clickSearchCondition, initSearchCondition} from "pages/MCAQS020/formConfig";
import {MCAQS020ResultList} from "types/MCAQS020/MCAQS020ResultList";
import {MCAQS020SelectDuplicateIdentification} from "types/MCAQS020/MCAQS020SelectDuplicateIdentification";
import {MCAQS010SearchDuplicateIdentification} from 'types/MCAQS010/MCAQS010SearchDuplicateIdentification';
import {initialValues} from 'pages/MCAQS010/formConfig';

export interface duplicateIdentification {

  screenId: string // 画面ID
  duplicateIdentificationCheckHistoryId: string // 名寄せチェック履歴ID
}

const duplicateIdentificationValue = {
  screenId: '', // 画面ID
  duplicateIdentificationCheckHistoryId: '' // 名寄せチェック履歴ID
}

export interface SearchCriteria {
  //氏名（漢字）
  fullNameCheck: string
  //学校
  schoolCheck: string
  //学校＋学部
  schoolFacultyCheck: string
  //学校＋学部＋学科
  schoolFacultySubjectCheck: string
  //電話番号（自宅）
  homePhoneCheck: string
  //電話番号（携帯）
  mobilePhoneCheck: string
  //メールアドレス（メイン）
  pcAddressCheck: string
  //メールアドレス（サブ）
  mobileAddressCheck: string
}

const searchCriteriaValue = {
  fullNameCheck: '',
  schoolCheck: '',
  schoolFacultyCheck: '',
  schoolFacultySubjectCheck: '',
  homePhoneCheck: '',
  mobilePhoneCheck: '',
  pcAddressCheck: '',
  mobileAddressCheck: '',
};

export interface AllResult {
  duplicateIdentificationInfoList: MCAQS020ResultList[]
  searchCriteria: SearchCriteria
}

const allResultValue = {
  duplicateIdentificationInfoList: [],
  searchCriteria: searchCriteriaValue,
}

interface NameCheckListReducerList {
  //init condition   type MCAQS020
  initSearchCondition: MCAQS020InitDuplicateIdentificationList,
  //init  condition  result type MCAQS020
  initSearchResultList: MCAQS020ResultList[],
  //all result valueMCAQS020
  allResult: AllResult,
  //init criteria result type MCAQS020
  initSearchCriteriaResult: SearchCriteria,
  //click  result type MCAQS020
  clickSearchCondition: MCAQS020SelectDuplicateIdentification,
  //click  condition type MCAQS020
  clickSearchResult: string,
// =======================MCAQS010 START==================
  initSearchCondCheck: MCAQS010SearchDuplicateIdentification,
  duplicateIdentificationResult: duplicateIdentification,
// =======================MCAQS010 END====================

}


const initialState: NameCheckListReducerList = {
  //init condition result   type MCAQS020
  initSearchResultList: [],
  //init  condition  result type MCAQS020
  initSearchCondition: initSearchCondition,
  //init criteria result type MCAQS020
  initSearchCriteriaResult: searchCriteriaValue,
  //all result value MCAQS020
  allResult: allResultValue,
  //click set result initValue MCAQS020
  clickSearchCondition: clickSearchCondition,
  //click set condition initValue MCAQS020
  clickSearchResult: '',
  // =======================MCAQS010 START==================
  initSearchCondCheck: initialValues,
  duplicateIdentificationResult: duplicateIdentificationValue,
  // =======================MCAQS010 END====================
}

const nameCheckListSlice = createSlice({
  name: 'nameCheck',
  initialState: initialState,
  reducers: {
    //init set result to reducer MCAQS020
    setInitDuplicateIdentificationResult(state, action: PayloadAction<AllResult>) {
      state.allResult = action.payload;
      state.initSearchResultList = action.payload.duplicateIdentificationInfoList;
      state.initSearchCriteriaResult = action.payload.searchCriteria;
      return state;
    },
    //init set condition to reducer MCAQS020
    selectInitDuplicateIdentificationResult(state, action: PayloadAction<MCAQS020InitDuplicateIdentificationList>) {
      state.initSearchCondition = action.payload;
      return state;
    },
    //click set result to reducer MCAQS020
    setSelectDuplicateIdentificationTargetBalanceCount(state, action: PayloadAction<string>) {
      state.clickSearchResult = action.payload;
      return state;
    },
    //click set condition to reducer MCAQS020
    selectDuplicateIdentificationTargetBalanceCount(state, action: PayloadAction<MCAQS020SelectDuplicateIdentification>) {
      state.clickSearchCondition = action.payload;
      return state;
    },
    // =============================MCAQS010 START==========================
    initSelectCheck(state) {
      return state
    },
    setInitSelectCheck(state, action: PayloadAction<MCAQS010SearchDuplicateIdentification>) {
      state.initSearchCondCheck = action.payload
      return state
    },
    duplicateIdentificationInit(state, action: PayloadAction<MCAQS010SearchDuplicateIdentification>) {
      return state
    },
    duplicateIdentificationInitRelsult(state, action: PayloadAction<duplicateIdentification>) {
      state.duplicateIdentificationResult = action.payload
      return state
    },
    executeCheckValue(state, action: PayloadAction<MCAQS010SearchDuplicateIdentification>) {
      state.initSearchCondCheck = action.payload
      return state
    },
    // =============================MCAQS010 END=============================
  },
});
export const {
  //init set result to reducer MCAQS020
  setInitDuplicateIdentificationResult,
  //init set condition to reducer MCAQS020
  selectInitDuplicateIdentificationResult,
  //click set result to reducer MCAQS020
  setSelectDuplicateIdentificationTargetBalanceCount,
  //click set condition to reducer MCAQS020
  selectDuplicateIdentificationTargetBalanceCount,
// ==============MCAQS010 START==========
  initSelectCheck,
  setInitSelectCheck,
  duplicateIdentificationInit,
  duplicateIdentificationInitRelsult,
  executeCheckValue,
// ==============MCAQS010 END============
} = nameCheckListSlice.actions

export default nameCheckListSlice.reducer
