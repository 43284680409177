import React, { useEffect } from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '&:disabled': {
      color: '#999999',
      backgroundColor: '#e0e0e0',
      borderColor: '#cccccc',
      pointerEvents: 'none',
    },
  },
  hint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.5),
  },
  textarea: {
    resize: 'none',
  },
  error: {
    borderColor: '#e53935!important',
    '&:focus': {
      borderWidth: 2,
      padding: '0px 7px',
    }
  },
}))

interface Props extends FieldProps {
  hint?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  multiline?: boolean
  type?: string
  formName?: string
}

export default ({
  className,
  placeholder,
  field,
  form,
  hint,
  disabled,
  multiline,
  type,
  formName,
}: Props) => {
  const classes = useStyles()
  const errorClasses = useErrorStyle()
  const { name, value } = field
  const { errors, touched } = form
  let index = name.match(/conditionList\[(\S*)\].answerText.*/) ? (name.match(/conditionList\[(\S*)\].answerText.*/) as any)[1] : -1

  let { errorFlg1, message } = function () {
    let errorFlg1 = false
    let message
    if (errors['conditionList']) {
      if ((errors['conditionList'] as any)[index]) {
        if ((errors['conditionList'] as any)[index].answerText) {
          message = (errors['conditionList'] as any)[index].answerText
          errorFlg1 = true
        }
      }
    }
    return { errorFlg1, message }
  }()

  let errorFlg2 = function() {
    let errorFlg2 = false
    if (touched['conditionList']) {
      if ((touched['conditionList'] as any)[index]) {
        if ((touched['conditionList'] as any)[index].answerText) {
          errorFlg2 = (touched['conditionList'] as any)[index].answerText
        }
      }
    }
    return errorFlg2
  }()

  const handleChange = (e: any) => {
    if (!(hint && e.target.value.length > 100)) {
      form.setFieldValue(name, e.target.value)
      form.setFieldTouched(name ,true, true)
    }
  }

  useEffect(() => {
    if (disabled) {
      form.setFieldValue(name, '')
    }
  }, [disabled])

  return (
    <div className={className}>
      {multiline ? (
        <textarea
          value={value ? value : ''}
          onChange={handleChange}
          placeholder={placeholder}
          className={`${classes.root} ${classes.textarea} ${errorFlg1 && errorFlg2 ? classes.error : ''}`}
          disabled={disabled}
          name={name}
        />
      ) : (
        <input
          type={type ? type : 'text'}
          value={value ? value : ''}
          onChange={handleChange}
          placeholder={placeholder}
          className={`${classes.root} ${errorFlg1 && errorFlg2 ? classes.error : ''}`}
          disabled={disabled}
          name={name}
        />
      )}
      {hint ? (
        <div className={classes.hint}>
          <Typography variant='caption'>{`入力${value.length} / 最大100`}</Typography>
        </div>
      ) : null}
      {errorFlg1 && errorFlg2 ? (
        <div className={errorClasses.helperText}>{message}</div>
      ) : null}
    </div>
  )
}
