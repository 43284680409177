import React from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'

export interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  optionList: Option[]
  className?: string
  row?: boolean
  disabled?: boolean
}

const Toggle = ({
  field,
  form,
  optionList,
  className,
  row,
  disabled,
}: Props) => {
  const classes = useErrorStyle()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || '0'

  const handleChange = (e: any) => {
    form.setFieldValue(name, e.target.value)
  }

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={className}>
      <RadioGroup row={row} value={value} onChange={handleChange}>
        {optionList.map(i => (
          <FormControlLabel
            key={i.value}
            control={
              <Radio color='primary' value={i.value} disabled={disabled} />
            }
            label={i.label}
          />
        ))}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default Toggle
