import { getMessage } from "common/messageUtil"

const mediaCreateInitialValues = {
  mediaName: '',
}

const textMap = {
  register: {
    submit: '登録する',
    message: getMessage('MCAIS020-003'),
  },
  cancel: {
    cancel: 'キャンセルする',
    message: getMessage('MCAIS020-002'),
  },
}

export { mediaCreateInitialValues,  textMap }
