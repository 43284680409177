import { useState, ChangeEvent } from 'react';

export default (initialValue = '') => {
  const [selected, setSelected] = useState(initialValue);
  const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value);
  };

  return [selected, handleSelect] as const;
};
