import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles as useCheckboxStyles } from 'componentsHsc/AdvancedSearchJobChange/GroupOptions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: ({ offset }: any) => 
      offset ? 'flex-end' : 'flex-start'
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: ({ offset }: any) => 
      offset ? 'flex-start' : 'center',
    marginRight: theme.spacing(1),
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    marginRight: 80
  }
}))

interface Props {
  title: string;
  subtitle?: string;
  required?: boolean;
  className?: string;
  offset?: boolean;
  onSelectAll?: (e: any) => void;
  checked?: boolean;
}

export default ({
  title,
  subtitle,
  required,
  className,
  offset,
  onSelectAll,
  checked,
}: Props) => {
  const classes = useStyles({ offset });
  const checkboxClasses = useCheckboxStyles();

  return (
    <div>
      <div className={`${classes.root} ${className}`}>
        <div className={classes.container}>
          <p>{title}</p>
        </div>
        {subtitle && (
          <div className={classes.container}>
            <p>：{subtitle}</p>
          </div>
        )}
        {required && (
          <div className={classes.container}>
            <i className='badge ptn6'>必須</i>
          </div>
        )}
      </div>
      {onSelectAll ? (
        <div className={classes.checkbox}>
          <label className={checkboxClasses.root}>
            <input
              type='checkbox'
              checked={checked}
              onChange={onSelectAll}
            />
            <mark className={checkboxClasses.mark}></mark>
            <span>全て選択</span>
          </label>
        </div>
      ) : null}
    </div>
  )
}
