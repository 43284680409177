
import request from 'utils/request'
import { MCAXS080InitRequest } from 'types/MCAXS080/MCAXS080InitRequest'
// #75311 25KH start
import { MCAXS080UpdateHistryDeleteFlagRequest } from 'types/MCAXS080/MCAXS080UpdateHistryDeleteFlagRequest'
// #75311 25KH end

export const progressStatusHistoryInitRequest = (param : MCAXS080InitRequest) =>
  request({
    url: '/MCAXS080/init',
    method: 'post',
    data: param,
})

// #75311 25KH start
// 履歴の削除
export const updateHistryDeleteRequest = (param : MCAXS080UpdateHistryDeleteFlagRequest) =>
  request({
    url: '/MCAXS080/ok',
    method: 'post',
    data: param,
})
// #75311 25KH end
