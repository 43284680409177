import {
  call,
  put,
  select,
  all,
  takeLeading,
} from 'redux-saga/effects'
import {
  submissionReqTemplateAddInit,
  setAddPageDialogOpen,
  setSubmissionReqTemplateAddInit,
  selectSubmissionReqTemplateList,
  setSelectSubmissionReqTemplateList,
  submissionReqTemplateConfirm,
  addSubmissionReqTemplateList,
  setAddSubmissionReqTemplateList,
  executeSubmissionReqTemplateList,
  setExecuteSubmissionReqTemplateList,
  copySubmissionReqTemplateList,
  setCopySubmissionReqTemplateList,
  setSubmissionReqTemplateListPage,
  setSubmissionReqTemplateListData,
  setSubmissionReqTemplateListOption,
  openModalData,
  setOpenModalMeassageData,
  setSubmissionReqTemplateContentConfirm,
  insertOrUpdateSubmissionReqTemplate,
  editSubmissionReqTemplate,
  setSubmissionReqTemplateDetailInit,
  setDetailPageDialogOpen,
  setOpenConfirmContentPage,
  deletesubmissionReqTemplate,
  submissionReqTemplateDetailConfirm,
  setAddSubmit,
  openModalMessage,
  updateSubmissionReqTemplateSort,
  MCBJS010SubmissionReqTemplateList,
  submissionTemplateTargetYearChange,
  setSubmissionTemplateTargetYearChange,
  getReloadSubmissions,
  setReloadSubmissions,
  getReloadThanksTemplate,
  setReloadThanksTemplate,
  getReloadReference,
  setReloadReference,
  getReloadPersonal,
  setReloadPersonal,
  getReloadSubmissionsApi,
  setReloadSubmissionsApi,
  getReloadThanksTemplateApi,
  setReloadThanksTemplateApi,
  getReloadReferenceApi,
  setReloadReferenceApi,
  getReloadPersonalApi,
  setReloadPersonalApi,
  openApi,
  openMcbApi,
  getPreview,
  setPreview,
  getPreview030,
  setPreview030
} from 'reducers/submissionReqTemplateReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  selectSubmissionReqTemplateListRequest, addSubmissionReqTemplateListRequest,
  executeSubmissionReqTemplateListRequest, copySubmissionReqTemplateListRequest, updateSubmissionReqTemplateSortRequest,findSubmissionReqTemplateListRequest,
} from 'apis/MCBJS010Api'
import { insertOrUpdateRequest } from 'apis/MCBJS040Api'
import { MCBJS040SubmissionRequestTemplateRequest } from 'types/MCBJS040/MCBJS040SubmissionRequestTemplateRequest'
import { previewRequest, reloadPersonalRequest, reloadReferenceRequest, reloadSubmissionsSetRequest, reloadThanksTemplateRequest, selectApiLinkRequest, submissionReqTemplateAddRequest, submissionReqTemplateConfirmRequest, submissionReqTemplTargetYearChangeRequest } from 'apis/MCBJS020Api'
import { getRecruitmentManagementDivision } from '../selectors/authSelectors'
import { initRequest } from 'apis/MCBJS040Api'
import { reloadPersonalApiRequest, reloadReferenceApiRequest, reloadSubmissionsSetApiRequest, reloadThanksTemplateApiRequest, submissionReqTemplateDetailRequest, deleteSubmissionReqTemplateRequest, submissionReqTemplateInputRequest, selectMcbApiLinkRequest, previewRequest030 } from 'apis/MCBJS030Api'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { MCBJS010InitDisplayRequest } from 'types/MCBJS010/MCBJS010InitDisplayRequest'
import { MCBJS020InitDisplayRequest } from 'types/MCBJS020/MCBJS020InitDisplayRequest'
import { MCBJS040InitDisplayRequest } from 'types/MCBJS040/MCBJS040InitDisplayRequest'
import { getSubSearchCondition } from 'selectors'
import { isFileExist } from 'common/businessUtil'
import { isEmpty, now, stringToDateTime } from 'common/generalUtil'
import axios from 'axios'
import { popupParams } from 'utils/misc'
import { getToken } from 'utils/auth'

//MCBリプレース MCBJS020 START
function* submissionReqTemplateAddInitSaga(action: ReturnType<typeof submissionReqTemplateAddInit>) {
  try {
    const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision;
    const data = yield call(submissionReqTemplateAddRequest, action.payload)
    yield put(setSubmissionReqTemplateAddInit(data));
    yield put(setAddPageDialogOpen(true));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* submissionReqTemplTargetYearChangeSage(action: ReturnType<typeof submissionTemplateTargetYearChange>){
  try {
    const data = yield call(submissionReqTemplTargetYearChangeRequest, action.payload)
    yield put(setSubmissionTemplateTargetYearChange(data));
  }catch (error) {
    yield put(openModal(error.message));
  }
}
// 26P #80795 start
function* reloadSubmissionsSetRequestSaga(action: ReturnType<typeof getReloadSubmissions>){
// 26P #80795 end
  try {
    // 26P #80795 start
    const data = yield call(reloadSubmissionsSetRequest, action.payload)
    // 26P #80795 end
    yield put(setReloadSubmissions(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}
function* reloadThanksTemplateRequestSaga(){
  try {
    const data = yield call(reloadThanksTemplateRequest)
    yield put(setReloadThanksTemplate(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}
function* reloadReferenceRequestSaga(){
  try {
    const data = yield call(reloadReferenceRequest)
    yield put(setReloadReference(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}
function* reloadPersonalRequestSaga(){
  try {
    const data = yield call(reloadPersonalRequest)
    yield put(setReloadPersonal(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}



function* submissionReqTemplateConfirmSaga(action: ReturnType<typeof submissionReqTemplateConfirm>) {
  try {
    const files: File[] = action.payload.files
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const isFile = yield call(isFileExist, file)
        if (!isFile) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_021)))
          return
        }
      }
    }
    const data1 = yield call(submissionReqTemplateConfirmRequest,
      {
        registeredFileName: files,
        submitParams: action.payload.submitData
      })
    action.payload.data.templateattachmentList = data1;
    const data = action.payload.data
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)

    // 業務チェックを実施する
    // 提出締切チェック
    switch(data.deadline){
      case ('0'):
        const determineDateTime = stringToDateTime(data.deadlineDate);
        if(determineDateTime !== null){
          if(determineDateTime < now()){
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_013)))
            return
          }
        }
        break
          case ('2'):
            const anytimeReceptionEndDateTime = stringToDateTime(data.deadlineAnyTime);
            if(anytimeReceptionEndDateTime !== null){
              if(anytimeReceptionEndDateTime < now()){
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_015)))
                return
              }
            }
            break
          default:
            break
      }
      // 再提出の受付可否チェック
      if(data.deadline==='2' && data.resubmission==='1'){
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_016)))
        return
      }
    // 不正文字チェック
    if (null != data.submissionReqSubject || null != data.submissionReqBody) {
      if (data.submissionReqSubject.includes('●●') || data.submissionReqBody.includes('●●')) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_017)))
        return
      }
    }
    // ファイルの拡張子チェック
    const suffixCheck = [
      'ai', 'art', 'doc', 'fla', 'gif', 'jpg', 'jpeg', 'lzh', 'mpeg', 'mpg', 'pdf', 'png', 'pps', 'ppt', 'psd', 'txt', 'xls', 'zip', 'docx', 'xlsx'
    ]
    for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
      var lastIndexOf = data.attachment.templateAttachmentName[i].lastIndexOf('.')
      var suffix = data.attachment.templateAttachmentName[i].substring(lastIndexOf + 1)
      if (!suffixCheck.includes(suffix.toLowerCase())) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_018)))
        return
      }
    }
    // ファイルのサイズチェック
    for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
      if (Number(data.attachment.templateAttachmentSize[i]) > 10485760) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_019)))
        return
      } else if (Number(data.attachment.templateAttachmentSize[i]) == magiContants.NUMBER_0) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_021)))
        return
      }
    }

    // 同一添付ファイルチェック
    for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
      for (var j = 0; j < data.templateattachmentList.length; j++) {
        if (data.attachment.templateAttachmentName[j] == data.templateattachmentList[j].attachmentName) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_020)))
          return
        }
      }
      for (var j = i + 1; j < data.attachment.templateAttachmentName.length; j++) {
        if (data.attachment.templateAttachmentName[j] == data.attachment.templateAttachmentName[i]) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_020)))
          return
        }
      }
    }

    //禁止語チェック
    for (var i = 0; i < data.forbiddenWordsList.length; i++) {
      // 禁止語チェック_件名
      if (data.submissionReqSubject != null && data.submissionReqSubject.includes(data.forbiddenWordsList[i])) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_008)))
        return
      }
      // 禁止語チェック_本文
      if (data.submissionReqBody != null && data.submissionReqBody.includes(data.forbiddenWordsList[i])) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_009)))
        return
      }
    }
    // ホームページURLの入力チェック
    if (data.homepageTitle1 != magiContants.NULL &&
      data.homepageTitle1 != null) {
      if (data.homepageUrl1 == magiContants.NULL ||
        data.homepageUrl1 == null) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_023)))
        return
      }
    }
    if (data.homepageTitle2 != magiContants.NULL &&
      data.homepageTitle2 != null) {
      if (data.homepageUrl2 == magiContants.NULL ||
        data.homepageUrl2 == null) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_023)))
        return
      }
    }
    if (data.homepageTitle3 != magiContants.NULL &&
      data.homepageTitle3 != null) {
      if (data.homepageUrl3 == magiContants.NULL ||
        data.homepageUrl3 == null) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_023)))
        return
      }
    }
    // サンクス不正文字チェック
    if (null != data.thanksEmailSubject || null != data.thanksEmailBody) {
      if (data.thanksEmailSubject.includes('●●') || data.thanksEmailBody.includes('●●')) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_024)))
        return
      }
    }
    //禁止語チェック(サンクス)
    for (var i = 0; i < data.forbiddenWordsList.length; i++) {
      // 禁止語チェック_サンクス件名
      if (data.thanksEmailSubject != null && data.thanksEmailSubject.includes(data.forbiddenWordsList[i])) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_010)))
        return
      }
      // 禁止語チェック_サンクス本文
      if (data.thanksEmailBody != null && data.thanksEmailBody.includes(data.forbiddenWordsList[i])) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS020_011)))
        return
      }
    }


    let fileFlag: boolean = false
    if (data.attachment.templateAttachmentName.length>0 ||
        action.payload.submitData.requestAttachment.length>0){
          fileFlag = true
      }
      let homepageTitle :string[] = []; 
      let homepageUrl :string[] = []; 
      if (data.homepageTitle1 !=='' && data.homepageUrl1 !== '') {
        homepageTitle[0] = data.homepageTitle1
      }else{
        homepageTitle[0] = ''
      }
      if(data.homepageUrl1 !== ''){
        homepageUrl[0] = data.homepageUrl1
      }
      if (data.homepageTitle2 !=='' && data.homepageUrl2 !== '') {
        homepageTitle[1] = data.homepageTitle2
      }else{
        homepageTitle[1] = ''
      }
      if (data.homepageUrl2 !== '') {
        homepageUrl[1] = data.homepageUrl2
      }
      if (data.homepageTitle3 !=='' && data.homepageUrl3 !== '') {
        homepageTitle[2] = data.homepageTitle3
      }else{
        homepageTitle[2] = ''
      }
      if (data.homepageUrl3 !== '') {
        homepageUrl[2] = data.homepageUrl3
      }
      let requestMessageBody = data.submissionReqBody
      let thanksMessageBody = data.settingThanksEmail === '1' ? data.thanksEmailBody:''
      if(!isEmpty(requestMessageBody)){
        requestMessageBody = requestMessageBody.replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
      }
      if(!isEmpty(thanksMessageBody)){
        thanksMessageBody = thanksMessageBody.replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
      }
      requestMessageBody = requestMessageBody.replace(
        /{@request_url}/g,
        '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>'
      )
      thanksMessageBody = thanksMessageBody.replace(
        /{@request_url}/g,
        '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>'
      )
    const data2: MCBJS040InitDisplayRequest = {
      templateId: data.templateId,
      recruitmentManagementDivision: recruitmentManagementDivision,
      requestMessageBody:requestMessageBody,
      thanksMessageBody:thanksMessageBody,
      submissionContentName:data.submissionContentName,
      attachmentFlg:fileFlag,
      homePageTitleList:homepageTitle,
      homePageUrlList:homepageUrl
    }
    const data3 = yield call(initRequest, data2)
    yield put(setSubmissionReqTemplateContentConfirm(data3))
    yield put(setAddSubmit(data))
    yield put(setOpenConfirmContentPage(true))

  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectApiLinkSaga(action: ReturnType<typeof openApi>){
  try{
    yield call(selectApiLinkRequest)
    // MCBリプレイス #8682 START
    const params = popupParams(1280, 948)
    // MCBリプレイス #8682 END
    const authToken = action.payload.authToken
    const sourceSystemType = '1'
    const sourceCompanyCode = action.payload.sourceCompanyCode
    // 26P #80795、#88225 start
    const annualParam = action.payload.annualParam
    const url = action.payload.url + '?sourceSystemType=' + sourceSystemType + '&sourceCompanyCode=' + sourceCompanyCode + annualParam
    // 26P #80795、#88225 end
    axios.get(url, {
      withCredentials: true,
      headers: {
        Authorization: getToken(),
      },
    }).then(res => {
      let newWindow = window.open(url, "_blank", params)
    }).catch(error => {
      console.log(error)
    })
  }catch(error){
    yield put(openModal(error.message))
  }
}

export function* previewSaga(action: ReturnType<typeof getPreview>) {
  try {
    const formData = action.payload.formData
    const serverResponse = yield call(previewRequest, formData)

    yield put(setPreview(serverResponse))
    action.payload.setHasDisplayPreview(true)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCBリプレース MCBJS020 END
// MCBJS010 Start
function* selectSubmissionReqTemplateListSaga(action: ReturnType<typeof selectSubmissionReqTemplateList>) {
  try {
    const data = yield call(selectSubmissionReqTemplateListRequest, action.payload)
    yield put(setSelectSubmissionReqTemplateList(data));
    yield put(setSubmissionReqTemplateListPage(0));
    yield put(setSubmissionReqTemplateListData([]));
    yield put(setSubmissionReqTemplateListOption(''));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* addSubmissionReqTemplateListSaga(action: ReturnType<typeof addSubmissionReqTemplateList>) {
  try {
    yield call(addSubmissionReqTemplateListRequest, action.payload)
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const templateIdInfo: MCBJS020InitDisplayRequest = {
      templateId: null,
      recruitmentManagementDivision: recruitmentManagementDivision
    }
    const data = yield call(submissionReqTemplateAddRequest, templateIdInfo)
    yield put(setSubmissionReqTemplateAddInit(data));
    yield put(setAddSubmissionReqTemplateList(true));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* executeSubmissionReqTemplateListSaga(action: ReturnType<typeof executeSubmissionReqTemplateList>) {
  try {
    const data = yield call(executeSubmissionReqTemplateListRequest, action.payload)
    yield put(setExecuteSubmissionReqTemplateList(data));
    yield put(setSubmissionReqTemplateListPage(0));
    yield put(setSubmissionReqTemplateListData([]));
    yield put(setSubmissionReqTemplateListOption(''));
    yield put(openSnackbar(data.message));
  } catch (error) {
    if (error.message == magiContants.MESSAGECODE_MCBJS010_004) {
      const openModalData: openModalData = {
        openCheckModal: true,
        modalMessage: getMessage(magiContants.MESSAGECODE_MCBJS010_004),
      }
     
      yield put(setOpenModalMeassageData(openModalData));
    } else {
      yield put(openModal(error.message));
    }
  }
}

function* copySubmissionReqTemplateListSaga(action: ReturnType<typeof copySubmissionReqTemplateList>) {
  try {
    yield call(copySubmissionReqTemplateListRequest, action.payload)
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const templateIdInfo: MCBJS020InitDisplayRequest = {
      templateId: action.payload.templateId,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    const data = yield call(submissionReqTemplateAddRequest, templateIdInfo)
    yield put(setSubmissionReqTemplateAddInit(data));
    yield put(setCopySubmissionReqTemplateList(true));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* updateSubmissionReqTemplateSortSaga(action: ReturnType<typeof updateSubmissionReqTemplateSort>) {
  try {
    yield call(updateSubmissionReqTemplateSortRequest, action.payload.updateOrderList)
    const data: MCBJS010SubmissionReqTemplateList = yield call(selectSubmissionReqTemplateListRequest, action.payload.selectParam)
    yield put(setSelectSubmissionReqTemplateList(data));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
// MCBJS010 End

// MCBJS040 Start
function* insertOrUpdateSaga(action: ReturnType<typeof insertOrUpdateSubmissionReqTemplate>) {
  try {
  

    const sendData: File[] = action.payload.registeredFileName
    // ファイル存在チェック
    for (var i = 0; i < sendData.length; i++) {
      const file = sendData[i]
      if (file) {
        const isFile = yield call(isFileExist, file)
        if (!isFile) {
          yield put(setOpenConfirmContentPage(false))
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS040_013)))
          return
        }
      }
    }
    const submitParams: MCBJS040SubmissionRequestTemplateRequest = action.payload.submitParams
    const data = yield call(insertOrUpdateRequest, {
      registeredFileName: sendData,
      submitParams: submitParams
    })

    yield put(setOpenConfirmContentPage(false))
    yield put(setAddPageDialogOpen(false))
    yield put(setDetailPageDialogOpen(false))
    yield put(selectSubmissionReqTemplateList(action.payload.mCBJS010InitDisplayRequest))
    yield put(setSubmissionReqTemplateListPage(0));
    yield put(setSubmissionReqTemplateListData([]));
    yield put(setSubmissionReqTemplateListOption(''));
    yield put(openSnackbar(data));
  } catch (error) {
    yield put(setOpenConfirmContentPage(false))
    yield put(openModal(error.message))
  }
}
// MCBJS040 End

// MCBリプレース MCBJS030 START
function* editSubmissionReqTemplateSaga(action: ReturnType<typeof editSubmissionReqTemplate>) {
    try {
      yield call(findSubmissionReqTemplateListRequest, action.payload)
      const data = yield call(submissionReqTemplateDetailRequest,action.payload)
      yield put(setSubmissionReqTemplateDetailInit(data))
      yield put(setDetailPageDialogOpen(true))
    } catch (error) {
      yield put(openModal(error.message))
    }
}

function* deletesubmissionReqTemplateSaga(action: ReturnType<typeof deletesubmissionReqTemplate>) {
  try {
    yield call(deleteSubmissionReqTemplateRequest,action.payload)
    yield put(setDetailPageDialogOpen(false))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_DELETE_SUCCESS)))

    const subSearchCondition: ReturnType<typeof getSubSearchCondition> = yield select(getSubSearchCondition)
    const result = yield call(selectSubmissionReqTemplateListRequest, subSearchCondition)
    yield put(setSelectSubmissionReqTemplateList(result));
    yield put(setSubmissionReqTemplateListPage(0));
    yield put(setSubmissionReqTemplateListData([]));
    yield put(setSubmissionReqTemplateListOption(''));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* reloadSubmissionsSetApiRequestSaga(){
  try {
    const data = yield call(reloadSubmissionsSetApiRequest)
    yield put(setReloadSubmissionsApi(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}
function* reloadThanksTemplateApiRequestSaga(){
  try {
    const data = yield call(reloadThanksTemplateApiRequest)
    yield put(setReloadThanksTemplateApi(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}
function* reloadReferenceApiRequestSaga(){
  try {
    const data = yield call(reloadReferenceApiRequest)
    yield put(setReloadReferenceApi(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}
function* reloadPersonalApiRequestSaga(){
  try {
    const data = yield call(reloadPersonalApiRequest)
    yield put(setReloadPersonalApi(data))
  }catch(error) {
    yield put(openModal(error.message));
  }
}

function* submissionReqTemplateDetailConfirmSaga(action: ReturnType<typeof submissionReqTemplateDetailConfirm>) {
  try {
    const files: File[] = action.payload.files
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const isFile = yield call(isFileExist, file)
        if (!isFile) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_021)))
          return
        }
      }
    }
    const data1 = yield call(submissionReqTemplateInputRequest,
      {
        registeredFileName: files,
        submitParams: action.payload.submitData
      });
    action.payload.data.templateattachmentList = data1;
    const data = action.payload.data
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)

    // 業務チェックを実施する
    // 提出締切チェック
    switch(data.deadline){
      case ('0'):
        const determineDateTime = stringToDateTime(data.deadlineDate);
        if(determineDateTime !== null){
          if(determineDateTime < now()){
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_013)))
            return
          }
        }
        break
        case ('1'):
        if((data.deadlineAfterDay === null || data.deadlineAfterDay ==='') || (data.deadlineAfterDayTime === null || data.deadlineAfterDayTime === '')){
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_014)))
          return
        }
          break
          case ('2'):
            const anytimeReceptionEndDateTime = stringToDateTime(data.deadlineAnyTime);
            if(anytimeReceptionEndDateTime !== null){
              if(anytimeReceptionEndDateTime < now()){
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_015)))
                return
              }
            }
            break
          default:
            break
      }
      // 再提出の受付可否チェック
      if(data.deadline==='2' && data.resubmission==='1'){
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_016)))
        return
      }
    // 不正文字チェック
    if (null != data.submissionReqSubject || null != data.submissionReqBody) {
      if (data.submissionReqSubject.includes('●●') || data.submissionReqBody.includes('●●')) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_017)))
        return
      }
    }
    // サンクス不正文字チェック
    if (null != data.thanksEmailSubject || null != data.thanksEmailBody) {
      if (data.thanksEmailSubject.includes('●●') || data.thanksEmailBody.includes('●●')) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_024)))
        return
      }
    }

    // ファイルの拡張子チェック
    const suffixCheck = [
      'ai', 'art', 'doc', 'fla', 'gif', 'jpg', 'jpeg', 'lzh', 'mpeg', 'mpg', 'pdf', 'png', 'pps', 'ppt', 'psd', 'txt', 'xls', 'zip', 'docx', 'xlsx'
    ]
    for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
      var lastIndexOf = data.attachment.templateAttachmentName[i].lastIndexOf('.')
      var suffix = data.attachment.templateAttachmentName[i].substring(lastIndexOf + 1)
      if (!suffixCheck.includes(suffix.toLowerCase())) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_018)))
        return
      }
    }
    // ファイルのサイズチェック
    for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
      if (Number(data.attachment.templateAttachmentSize[i]) > 10485760) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_019)))
        return
      } else if (Number(data.attachment.templateAttachmentSize[i]) == magiContants.NUMBER_0) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_021)))
        return
      }
    }

    // 同一添付ファイルチェック
    for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
      for (var j = 0; j < data.templateattachmentList.length; j++) {
        if (data.attachment.templateAttachmentName[j] == data.templateattachmentList[j].attachmentName) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_020)))
          return
        }
      }
      for (var j = i + 1; j < data.attachment.templateAttachmentName.length; j++) {
        if (data.attachment.templateAttachmentName[j] == data.attachment.templateAttachmentName[i]) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_020)))
          return
        }
      }
    }

    //禁止語チェック
    for (var i = 0; i < data.forbiddenWordsList.length; i++) {
      // 禁止語チェック_件名
      if (data.submissionReqSubject != null && data.submissionReqSubject.includes(data.forbiddenWordsList[i])) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_008)))
        return
      }
      // 禁止語チェック_本文
      if (data.submissionReqBody != null && data.submissionReqBody.includes(data.forbiddenWordsList[i])) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_009)))
        return
      }
    }
    //禁止語チェック(サンクス)
    for (var i = 0; i < data.forbiddenWordsList.length; i++) {
      // 禁止語チェック_サンクス件名
      if (data.thanksEmailSubject != null && data.thanksEmailSubject.includes(data.forbiddenWordsList[i])) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_010)))
        return
      }
      // 禁止語チェック_サンクス本文
      if (data.thanksEmailBody != null && data.thanksEmailBody.includes(data.forbiddenWordsList[i])) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_011)))
        return
      }
    }

    // ホームページURLの入力チェック
    if (data.homepageTitle1 != magiContants.NULL &&
      data.homepageTitle1 != null) {
      if (data.homepageUrl1 == magiContants.NULL ||
        data.homepageUrl1 == null) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_023)))
        return
      }
    }
    if (data.homepageTitle2 != magiContants.NULL &&
      data.homepageTitle2 != null) {
      if (data.homepageUrl2 == magiContants.NULL ||
        data.homepageUrl2 == null) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_023)))
        return
      }
    }
    if (data.homepageTitle3 != magiContants.NULL &&
      data.homepageTitle3 != null) {
      if (data.homepageUrl3 == magiContants.NULL ||
        data.homepageUrl3 == null) {
        yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBJS030_023)))
        return
      }
    }

    let fileFlag: boolean = false
    if (data.attachment.templateAttachmentName.length>0 || 
      action.payload.submitData.requestAttachment.length>0){
          fileFlag = true
      }
      let homepageTitle :string[] = []; 
      let homepageUrl :string[] = []; 
      if (data.homepageTitle1 !=='' && data.homepageUrl1 !== '') {
        homepageTitle[0] = data.homepageTitle1
      }else{
        homepageTitle[0] = ''
      }
      if(data.homepageUrl1 !== ''){
        homepageUrl[0] = data.homepageUrl1
      }
      if (data.homepageTitle2 !=='' && data.homepageUrl2 !== '') {
        homepageTitle[1] = data.homepageTitle2
      }else{
        homepageTitle[1] = ''
      }
      if (data.homepageUrl2 !== '') {
        homepageUrl[1] = data.homepageUrl2
      }
      if (data.homepageTitle3 !=='' && data.homepageUrl3 !== '') {
        homepageTitle[2] = data.homepageTitle3
      }else{
        homepageTitle[2] = ''
      }
      if (data.homepageUrl3 !== '') {
        homepageUrl[2] = data.homepageUrl3
      }
    let requestMessageBody = data.submissionReqBody
    let thanksMessageBody = data.settingThanksEmail === '1' ? data.thanksEmailBody:''
    if(!isEmpty(requestMessageBody)){
      requestMessageBody = requestMessageBody.replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
    }
    if(!isEmpty(thanksMessageBody)){
      thanksMessageBody = thanksMessageBody.replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
    }
    requestMessageBody = requestMessageBody.replace(
      /{@request_url}/g,
      '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>'
    )
    thanksMessageBody = thanksMessageBody.replace(
      /{@request_url}/g,
      '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>'
    )
    const data2: MCBJS040InitDisplayRequest = {
      templateId: data.templateId,
      recruitmentManagementDivision: recruitmentManagementDivision,
      requestMessageBody:requestMessageBody,
      thanksMessageBody:thanksMessageBody,
      submissionContentName:data.submissionContentName,
      attachmentFlg:fileFlag,
      homePageTitleList:homepageTitle,
      homePageUrlList:homepageUrl
    }
    const data3 = yield call(initRequest, data2)
    yield put(setSubmissionReqTemplateContentConfirm(data3))
    yield put(setAddSubmit(data))
    yield put(setOpenConfirmContentPage(true))

  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectMcbApiLinkSaga(action: ReturnType<typeof openMcbApi>){
  try{
    yield call(selectMcbApiLinkRequest)
    // MCBリプレイス #8682 START
    const params = popupParams(1280, 948)
    // MCBリプレイス #8682 END
    const authToken = action.payload.authToken
    const sourceSystemType = '1'
    const sourceCompanyCode = action.payload.sourceCompanyCode
    // 26P #80795、#88225 start
    const annualParam = action.payload.annualParam
    const url = action.payload.url + '?sourceSystemType=' + sourceSystemType + '&sourceCompanyCode=' + sourceCompanyCode + annualParam
    // 26P #80795、#88225 end
    axios.get(url, {
      withCredentials: true,
      headers: {
        Authorization: getToken(),
      },
    }).then(res => {
      let newWindow = window.open(url, "_blank", params)
    }).catch(error => {
      console.log(error)
    })
  }catch(error){
    yield put(openModal(error.message))
  }
}

export function* previewSaga030(action: ReturnType<typeof getPreview030>) {
  try {
    const formData = action.payload.formData
    const serverResponse = yield call(previewRequest030, formData)

    yield put(setPreview030(serverResponse))
    action.payload.setHasDisplayPreview(true)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCBリプレース MCBJS030 END

export default function* submissionReqTemplateSaga() {
  yield all([
    takeLeading(submissionReqTemplateAddInit, submissionReqTemplateAddInitSaga),
    takeLeading(submissionReqTemplateConfirm, submissionReqTemplateConfirmSaga),
    takeLeading(addSubmissionReqTemplateList, addSubmissionReqTemplateListSaga),
    takeLeading(selectSubmissionReqTemplateList, selectSubmissionReqTemplateListSaga),
    takeLeading(executeSubmissionReqTemplateList, executeSubmissionReqTemplateListSaga),
    takeLeading(copySubmissionReqTemplateList, copySubmissionReqTemplateListSaga),
    takeLeading(insertOrUpdateSubmissionReqTemplate, insertOrUpdateSaga),
    takeLeading(editSubmissionReqTemplate,editSubmissionReqTemplateSaga),
    takeLeading(deletesubmissionReqTemplate,deletesubmissionReqTemplateSaga),
    takeLeading(submissionReqTemplateDetailConfirm, submissionReqTemplateDetailConfirmSaga),
    takeLeading(updateSubmissionReqTemplateSort, updateSubmissionReqTemplateSortSaga),
    takeLeading(submissionTemplateTargetYearChange,submissionReqTemplTargetYearChangeSage),
    takeLeading(getReloadThanksTemplate,reloadThanksTemplateRequestSaga),
    takeLeading(getReloadReference,reloadReferenceRequestSaga),
    takeLeading(getReloadPersonal,reloadPersonalRequestSaga),
    takeLeading(getReloadSubmissions,reloadSubmissionsSetRequestSaga), 
    takeLeading(getReloadThanksTemplateApi,reloadThanksTemplateApiRequestSaga),
    takeLeading(getReloadReferenceApi,reloadReferenceApiRequestSaga),
    takeLeading(getReloadPersonalApi,reloadPersonalApiRequestSaga),
    takeLeading(getReloadSubmissionsApi,reloadSubmissionsSetApiRequestSaga),
    takeLeading(openApi,selectApiLinkSaga),
    takeLeading(openMcbApi,selectMcbApiLinkSaga),
    takeLeading(getPreview,previewSaga),
    takeLeading(getPreview030,previewSaga030),
  ])
}
