import { now } from "common/generalUtil"
import { getMessage } from "common/messageUtil"
import moment from "moment"
import { magiContants } from "utils/contants"

// 文字列変換(日付)
// 与えられた日付を文字列に変換する。
const dateToString = (target: Date | null) => {
  return target && moment(target).isValid()
    ? moment(target).format('YYYY/MM/DD')
    : ''
}

// 初期画面初期化
const initialInsValues = {
  mediaName: '',
  entryDate: dateToString(now()),
  applicationJobTitle: '',
  publishedStartDate: '',
  publishedEndDate: '',
  selection: '',
  surname: '',
  firstName: '',
  surnameKana: '',
  firstNameKana: '',
  birthday: '',
  sex: 0,
  mailAddressPc: '',
  mailAddressMobile: '',
  phoneNumberMobile: '',
  phoneNumberHome: '',
  postalCode: '',
  prefectures: '',
  addressAfterMunicipalities: '',
  selectionFlowSettingId: '',
  mediaSettingId: '',
  prefecturesCode: '',
  selectionFlowFlg: '',
}
const optionListSex = [
  { label: '男性　　　　　　', value: 1 },
  { label: '女性　　　　　　', value: 2 },
  { label: '未選択　　　　　', value: 0 },
]

// 転職March #75621 START
const listener = (ev: {
  preventDefault: () => void
  returnValue: string
}) => {
  ev.preventDefault()
  ev.returnValue = getMessage(magiContants.MESSAGECODE_MCAXS220_006)
}
// 転職March #75621 END

export { initialInsValues, optionListSex, listener }
