import React from 'react';

interface Props {
  name: string;
  label?: string;
  value?: string;
  onChange?: () => void;
  className?: string;
}

export default ({ label, name, className, ...otherProps }: Props) => {
  return (
    <label className={className}>
      <input type="radio" name={name} {...otherProps} />
      <mark />
      {label}
    </label>
  );
};
