import React from 'react'
import { FieldProps } from 'formik'
import { useErrorStyle } from 'components'
import { FormControl } from '@material-ui/core'

interface Props extends FieldProps {
    label: string
}

const  ContentMessage = ({ field, form, ...others }: Props) => {
    const classes = useErrorStyle()
    const { name } = field
    const { errors, touched } = form

    return (
        <FormControl error={Boolean(errors[name] && touched[name])}>
            {errors[name] && touched[name] ? (
                <p className={classes.helperText}>{errors[name]}</p>
            ) : null}
        </FormControl>
    )
}

export default ContentMessage