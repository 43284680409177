import {all, call, put, select, takeEvery, takeLatest, takeLeading,} from 'redux-saga/effects'
import {removeToken} from "../utils/auth";

import {
  queryRecruitmentGroup,
  selectEditList,
  selectExtraAccountList,
  selectLoginCount,
  selectLoginCountAndUpdate,
  selectLoginCountConfirmI,
  selectLoginCountI,
  selectRecruitmentGroup,
  selectRecruitmentIdConfirm,
  selectRecruitmentIdInsert,
  selectRecruitmentIdUpdate,
  selectRecruitmentName,
  selectVersionNumberByTemporaryLoginId,
  setEditList,
  setExtraAccountListResults,
  setExtraAccountListSearchCondition,
  setLoginCountResults,
  setLoginCountResultsConfirmI,
  setLoginCountResultsI,
  setRecruitmentGroupResults,
  setRecruitmentGroupsResults,
  setRecruitmentIdConfirm,
  setRecruitmentIdInsert,
  setRecruitmentIdUpdate,
  setRecruitmentName,
  setVersionNumberByTemporaryLoginId,
  toIUDSubmit,
  updateByInvalid,
  updateByPassword,
  updateByValid,
  updateInvDataByPwd,
  selectSsoLoginFlag,
  setSsoLoginFlag,
  setLoadingFlag,
  setUpdateOpenFlag,
  setUpdateModal,
  setInitialDisplayYear,
} from 'reducers/extraAccountReducer'
import {openModal, openSnackbar} from 'reducers/messageReducer'

import {
  selectAccountRequest,
  selectGourpRequest,
  selectLoginCountRequest,
  updateByInvalidRequest,
  updateByPasswordRequest,
  updateByValidRequest,
  updateInvDataByPwdRequest,
} from 'apis/MCADS010Api'

import {getExtraAccountListSearchCondition} from 'selectors'
import {magiContants} from 'utils/contants'
import {selectRecruitmentGroupRequest, selectRecruitmentIdRequest} from "../apis/MCADS020Api";
import {selectLoginCountAndUpdateRequest, selectVersionNumberByTemporaryLoginIdRequest} from 'apis/MCADS030Api'
import {replaceToOrigin} from "../utils/misc";
import {selectEditListRequest, selectRecruitmentNameRequest} from "../apis/MCADS040Api";
import {toIUDSubmitRequest} from "../apis/MCADS050Api";
import {getRecruitmentManagementDivision} from "../selectors/authSelectors";
import {MCADS040LoginIdRequest} from "../types/MCADS040/MCADS040LoginIdRequest";
import {submitValues} from "../pages/MCADS010/formConfig";
import { mcabs010InitRequest } from 'apis/MCABS010Api';

function* selectAccountListSaga(
  action: ReturnType<typeof selectExtraAccountList>
) {
  try {
    yield put(setExtraAccountListResults([]))
    const data = yield call(selectAccountRequest, action.payload)
    yield put(setExtraAccountListResults(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectGroupListSaga(action: ReturnType<typeof selectRecruitmentGroup>) {
  try {
    yield put(setExtraAccountListResults([]))
    yield put(setRecruitmentGroupResults([]))
    const data = yield call(selectGourpRequest, action.payload.recruitmentManagementDivision)
    yield put(setExtraAccountListSearchCondition({...submitValues, recruitmentManagementDivision: action.payload.recruitmentManagementDivision}))
    const searchCondition: ReturnType<typeof getExtraAccountListSearchCondition> = yield select(getExtraAccountListSearchCondition)
    const accountListdata = yield call(selectAccountRequest, searchCondition)
    yield put(setRecruitmentGroupResults(data))
    yield put(setExtraAccountListResults(accountListdata))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* updateByID(action: any) {
  try {
    if (action.type.includes('updateByInvalid')) {
      yield call(updateByInvalidRequest, action.payload.data)
    } else if (action.type.includes('updateByValid')) {
      yield call(updateByValidRequest, action.payload.data)
    } else if (action.type.includes('updateByPassword')) {
      yield call(updateByPasswordRequest, action.payload.data)
    } else {
      yield call(updateInvDataByPwdRequest, action.payload.data)
    }
    const searchCondition: ReturnType<typeof getExtraAccountListSearchCondition> = yield select(getExtraAccountListSearchCondition)
    const data = yield call(selectAccountRequest, searchCondition)
    yield put(setExtraAccountListResults(data))
    yield put(openSnackbar(action.payload.message))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectLoginCountSaga(action: ReturnType<typeof selectLoginCount>) {
  try {
    yield put(setLoginCountResults(Number.MAX_VALUE))
    const data = yield call(selectLoginCountRequest, action.payload.recruitmentManagementDivision)
    yield put(setLoginCountResults(data))
    if (data >= 100) {
      yield put(openModal(magiContants.MESSAGECODE_MCADS010_003))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectLoginICountSaga(action: ReturnType<typeof selectLoginCount>) {
  try {
    yield put(setLoginCountResultsI(Number.MAX_VALUE))
    const data = yield call(selectLoginCountRequest, action.payload.recruitmentManagementDivision)
    yield put(setLoginCountResultsI(data))
    if (data >= 100) {
      yield put(openModal(magiContants.MESSAGECODE_MCADS010_003))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectLoginCountConfirmISaga(action: ReturnType<typeof selectLoginCount>) {
  try {
    yield put(setLoginCountResultsConfirmI(Number.MAX_VALUE))
    const data = yield call(selectLoginCountRequest, action.payload.recruitmentManagementDivision)
    yield put(setLoginCountResultsConfirmI(data))
    if (data >= 100) {
      yield put(openModal(magiContants.MESSAGECODE_MCADS010_003))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectRecruitmentGroupSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    yield put(setRecruitmentGroupsResults([]))
    const data = yield call(selectRecruitmentGroupRequest, recruitmentManagementDivision)
    // 次期開発12月 #75981 start
    yield put(setRecruitmentGroupsResults(data.recruitmentGroupPreferenceList))
    yield put(setInitialDisplayYear(data.targetYearDataList))
    // 次期開発12月 #75981 end
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectRecruitmentIdUpdateSaga(action: any) {
  try {
    yield put(setRecruitmentIdUpdate(0))
    const data = yield call(selectRecruitmentIdRequest, action.payload)
    yield put(setRecruitmentIdUpdate(data))

  } catch (error) {
    yield put(setRecruitmentIdUpdate(-1))
    yield put(openModal(error.message))
  }
}
function* selectRecruitmentIdInsertSaga(action: any) {
  try {
    yield put(setRecruitmentIdInsert(0))
    const data = yield call(selectRecruitmentIdRequest, action.payload)
    yield put(setRecruitmentIdInsert(data))

  } catch (error) {
    yield put(setRecruitmentIdInsert(-1))
    yield put(openModal(error.message))
  }
}
function* selectRecruitmentIdConfirmSaga(action: any) {
  try {
    yield put(setRecruitmentIdConfirm(0))
    const data = yield call(selectRecruitmentIdRequest, action.payload)
    yield put(setRecruitmentIdConfirm(data))

  } catch (error) {
    yield put(setRecruitmentIdConfirm(-1))
    yield put(openModal(error.message))
  }
}

function* selectLoginCountAndUpdateSage(action: ReturnType<typeof selectLoginCountAndUpdate>) {
  try {
    yield call(selectLoginCountAndUpdateRequest, action.payload.data)
    replaceToOrigin();
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectListByTemporaryLoginIdSage(action: any) {
  try {
    const data = yield call(selectVersionNumberByTemporaryLoginIdRequest, action.payload)
    removeToken()
    yield put(setVersionNumberByTemporaryLoginId(data))
    yield put(setLoadingFlag(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* selectEditListSaga(action: ReturnType<typeof selectEditList>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const requestValue: MCADS040LoginIdRequest = {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision
    }
    const data = yield call(selectEditListRequest, requestValue)
    yield put(setUpdateOpenFlag(true))
    yield put(setEditList(data))
  } catch (error) {
    yield put(setUpdateOpenFlag(false))
    yield put(setUpdateModal(false))
    yield put(openModal(error.message))
  }
}

function* selectRecruitmentNameSaga(action: any) {
  try {

    put(setRecruitmentName([]))
    const data = yield call(selectRecruitmentNameRequest, action.payload)
    yield put(setRecruitmentName(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* toIUDSubmitSaga(action: ReturnType<typeof toIUDSubmit>) {
  try {
    yield put(setRecruitmentIdUpdate(0))
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    yield call(toIUDSubmitRequest, action.payload.data)
    yield put(setExtraAccountListResults([]))
    const searchCondition: ReturnType<typeof getExtraAccountListSearchCondition> = yield select(getExtraAccountListSearchCondition)
    const searchValue = {
      ...searchCondition,
      recruitmentManagementDivision: recruitmentManagementDivision
    }
    yield put(setUpdateOpenFlag(false))
    const data = yield call(selectAccountRequest, searchValue)
    yield put(setExtraAccountListResults(data))
    yield put(openSnackbar(action.payload.message))
  } catch (error) {
    yield put(openModal(error.message))

  }
}

/**
 * SSOログイン状態取得
 */
function* selectSsoLoginFlagSaga() {
  try {
    const data = yield call(mcabs010InitRequest)
    yield put(setSsoLoginFlag(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* extraAccountSaga() {
  yield all([
    takeLatest(selectExtraAccountList, selectAccountListSaga),
    takeLatest(selectRecruitmentGroup, selectGroupListSaga),
    takeEvery(updateByInvalid, updateByID),
    takeEvery(updateByValid, updateByID),
    takeEvery(updateByPassword, updateByID),
    takeEvery(updateInvDataByPwd, updateByID),
    takeLatest(selectLoginCount, selectLoginCountSaga),
    takeLatest(selectLoginCountI, selectLoginICountSaga),
    takeLatest(selectLoginCountConfirmI, selectLoginCountConfirmISaga),
    takeLatest(queryRecruitmentGroup, selectRecruitmentGroupSaga),
    takeLatest(selectRecruitmentIdUpdate, selectRecruitmentIdUpdateSaga),
    takeLatest(selectRecruitmentIdInsert, selectRecruitmentIdInsertSaga),
    takeLatest(selectRecruitmentIdConfirm, selectRecruitmentIdConfirmSaga),
    //MCADS030_アカウント情報登録
    takeLeading(selectLoginCountAndUpdate, selectLoginCountAndUpdateSage),
    //MCADS030_アカウント情報登録
    takeLatest(selectVersionNumberByTemporaryLoginId, selectListByTemporaryLoginIdSage),
    takeLatest(selectEditList, selectEditListSaga),
    takeLatest(selectRecruitmentName, selectRecruitmentNameSaga),
    //MCADS050_追加アカウント確認
    takeLeading(toIUDSubmit, toIUDSubmitSaga),
    //MCABS010_SSOログインチェック
    takeLatest(selectSsoLoginFlag, selectSsoLoginFlagSaga),
  ])
}
