import request from 'utils/request'

import { MCBHS190InitRequest } from 'types/MCBHS190/MCBHS190InitRequest'
import { MCBHS190DetailRequest } from 'types/MCBHS190/MCBHS190DetailRequest'
import { MCBHS190CreateRequest } from 'types/MCBHS190/MCBHS190CreateRequest'


export const initMCBHS190Api = (requestdata: MCBHS190InitRequest) =>
  request({
    url: "/MCBHS190/init",
    method: 'post',
    data: requestdata
  })

export const clickLookDetailApi = (requestdata: MCBHS190DetailRequest) =>
  request({
    url: "/MCBHS190/clickLookDetail",
    method: 'post',
    data: requestdata
  })

export const createApi = (requestdata: MCBHS190CreateRequest) =>
  request({
    url: "/MCBHS190/create",
    method: 'post',
    data: requestdata
  })




