import request from 'utils/request'

import { MCARS010InitRequest } from 'types/MCARS010/MCARS010InitRequest'
import { MCARS010BatchProcessRequest } from 'types/MCARS010/MCARS010BatchProcessRequest'
import { MCARS010IdRequest } from 'types/MCARS010/MCARS010IdRequest'
import { MCARS010OrderRequest } from 'types/MCARS010/MCARS010OrderRequest'
import { MCARS010UpdateSearchTitleRequest } from 'types/MCARS010/MCARS010UpdateSearchTitleRequest'

export const mCARS010InitRequest = (apiData: MCARS010InitRequest) =>
  request({
    url: '/MCARS010/init',
    method: 'post',
    data: apiData,
})

export const mCARS010ExecuteRequest = (apiData: MCARS010BatchProcessRequest) =>
  request({
    url: '/MCARS010/execute',
    method: 'post',
    data: apiData,
})

export const mCARS010OpenSearchTitleRequest = (apiData: MCARS010IdRequest) =>
  request({
    url: '/MCARS010/openSearchTitle',
    method: 'post',
    data: apiData,
})

export const mCARS010OpenSearchDetailRequest = (apiData: MCARS010IdRequest) =>
  request({
    url: '/MCARS010/openSearchDetail',
    method: 'post',
    data: apiData,
})

export const mCARS010SortRequest = (apiData: MCARS010OrderRequest) =>
  request({
    url: '/MCARS010/sort',
    method: 'post',
    data: apiData,
})

export const mCARS010UpdateSearchTitleRequest = (apiData: MCARS010UpdateSearchTitleRequest) =>
  request({
    url: '/MCARS010/update',
    method: 'post',
    data: apiData,
})

export const mCARS010OpenFilterSettingRequest = (apiData: MCARS010IdRequest) =>
  request({
    url: '/MCARS010/openFilterSetting',
    method: 'post',
    data: apiData,
})

export const mCARS010selectSearchCriteriaRequest = (apiData:MCARS010IdRequest) =>
  request({
    url:'/MCARS010/selectSearchCriteria',
    method:'post',
    data:apiData,
})