import request from 'utils/request'
import { UpdateRequest } from 'types/MCAIS010/MCAIS010OrderRequest'

export const getMediaListRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAIS010/init/${recruitmentManagementDivision}`,
    method: 'post',
  })

export const updateRequest = (apiData: UpdateRequest[]) =>
  request({
    url: '/MCAIS010/sort',
    method: 'post',
    data: apiData,
  })

export const checkMediaCountRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAIS010/create/${recruitmentManagementDivision}`,
    method: 'post',
  }) 
