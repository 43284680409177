import { MCBKS030InitRequest, UpdateRequest } from 'pages/MCBKS030/formConfig'
import request from 'utils/request'

export const mCBKS030InitRequest = (
  interview: MCBKS030InitRequest
) =>
  request({
    url: '/MCBKS030/init',
    method: 'post',
    data: interview
  })

export const mCBKS030UpdateRequest = (
  interview: UpdateRequest
) =>
  request({
    url: '/MCBKS030/update',
    method: 'post',
    data: interview
  })