import request from 'utils/request'
import { MCAQS010SearchDuplicateIdentification } from 'types/MCAQS010/MCAQS010SearchDuplicateIdentification'

export const initApi = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAQS010/init/${recruitmentManagementDivision}`,
    method: 'post',
  })

export const executeApi = (params: MCAQS010SearchDuplicateIdentification) =>
  request({
    url: '/MCAQS010/execute',
    method: 'post',
    data: params,
  })