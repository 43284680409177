import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  initialValuesRecruitmentStatusReport,
  MCAVS010CountInfoOutDto,
  MCAVS010InterviewPlannerInfoOutDto,
  MCAVS010SelectionInformationOutDto,
  MCAVS010BriefingSessionOutDto,
  MCAVS010ContractMediaOutDto,
  MCAVS010NewsInfoOutDto,
  prim,
  MCAVS010GuideOutDto,
  interviewWebInfoOutDto, // WEB面接対応
  MCAVS010DisplayYearYieldInfoOutDto,
  initialValuesDisplayYearYieldInfo,
  // MCB ロット３　start
  MCAVS010McbRequestInfoOutDto,
  // MCB ロット３　end
  // #58811 2022/05/24 start
  MCAVS010WeekProgressInfoOutDto,
  // #58811 2022/05/24 end
} from 'pages/MCAVS010/formConfig'
import {
  newsInfo,
  initialState,
  NewsInfo,
  MCAVS020newsgetOutDto,
} from 'pages/MCAVS020/formConfig'
import { MCAVS010Request } from 'types/MCAVS010/MCAVS010Request'
import { MCAVS010NewsRequest } from 'types/MCAVS010/MCAVS010NewsRequest'
import { MCAVS010NewApplicantRequest } from 'types/MCAVS010/MCAVS010NewApplicantRequest'
import { MCAVS010UnreadResumeRequest } from 'types/MCAVS010/MCAVS010UnreadResumeRequest'
import { MCAVS010UnreadInfoRequest } from 'types/MCAVS010/MCAVS010UnreadInfoRequest'
import { MCAVS010InterviewFixedWaitingRequest } from 'types/MCAVS010/MCAVS010InterviewFixedWaitingRequest'
import { MCAVS010InterviewSchedulePresentedRequest } from 'types/MCAVS010/MCAVS010InterviewSchedulePresentedRequest'
import { MCAVS010InterviewPlannerListRequest } from 'types/MCAVS010/MCAVS010InterviewPlannerListRequest'
import { MCAVS010InterviewPlannerRequest } from 'types/MCAVS010/MCAVS010InterviewPlannerRequest'
import { MCAVS010YieldRequest } from 'types/MCAVS010/MCAVS010YieldRequest'
import { MCAVS010BriefingSessionRequest } from 'types/MCAVS010/MCAVS010BriefingSessionRequest'
import { MCAVS010EventRequest } from 'types/MCAVS010/MCAVS010EventRequest'
// MCB ロット３　start
import { MCAVS010SubmissionRequest } from 'types/MCAVS010/MCAVS010SubmissionRequest'
// MCB ロット３　end
// #58811 2022/05/24 start
import { MCAVS010ApplicantRequest } from 'types/MCAVS010/MCAVS010ApplicantRequest'
import { MCAVS010ApplicantionRequest } from 'types/MCAVS010/MCAVS010ApplicantionRequest'
// #58811 2022/05/24 end
// 次期12月対応 #72261 start
import { MCAVS010SeminarAfterScheduleHiddenCheckBoxRequest } from 'types/MCAVS010/MCAVS010SeminarAfterScheduleHiddenCheckBoxRequest'
// 次期12月対応 #72261 end
import { MCAVS010SubmissionStatusRequest } from 'types/MCAVS010/MCAVS010SubmissionStatusRequest'
import { MCAVS010SubmissionCountRequest } from 'types/MCAVS010/MCAVS010SubmissionCountRequest'
export interface MCAVS010RecruitmentReportInfoOutDto {
  [key: string]: any
  countInfoOutDto: MCAVS010CountInfoOutDto //件数情報
  interviewPlannerInfoOutDto: MCAVS010InterviewPlannerInfoOutDto[] //面接予定者の情報
  yieldInfoOutDto: MCAVS010SelectionInformationOutDto[] //歩留まり情報(新卒：単一年度の歩留まり情報、中途：すべての歩留まり情報)
  briefingSessionInfoOutDto: MCAVS010BriefingSessionOutDto[] //説明会・面接情報
  contractMediaOutDto: MCAVS010ContractMediaOutDto[] //イベントの参加状況情報
  newsInfoOutDto: MCAVS010NewsInfoOutDto[] //お知らせの情報
  guideOutDto: MCAVS010GuideOutDto //お問い合わせ
  // 年度対応 start
  displayYearYieldInfo: MCAVS010DisplayYearYieldInfoOutDto[]//表示可能年度の歩留まり情報
  // 年度対応 end
  // MCB ロット３　start
  mcbRequestInfoOutDto:MCAVS010McbRequestInfoOutDto[]
  // MCB ロット３　end
  // #58811 2022/05/24 start
  weekProgressInfoOutDto:MCAVS010WeekProgressInfoOutDto
  // #58811 2022/05/24 end
}

// WEB面接対応 start
export interface MCAVS010InterviewWebInfoOutDto {
  [key: string]: any
  code: string
  statusCode: string
  interviewWebBorderNumber: string
  interviewWebExprirationDate: string
}
// WEB面接対応 end

export interface ItemCodeVal {
    [key: string]: any
    itemCode: string
}

const itemCodeVal = {
    itemCode: '0',
}

export interface MessageFlag {
    [key: string]: number
    messageFlagVal: number
}

const messageFlag = {
    messageFlagVal: -1
}

export interface Prim {
  [key: string]: any
  prim: MCAVS010Request
  outDto: MCAVS010RecruitmentReportInfoOutDto
  address:String
  newsInfoResult: NewsInfo
  newsGetOutDtoResult: MCAVS020newsgetOutDto
  refreshFlag: number
  itemCodeValResult: ItemCodeVal
  messageFlagResult: MessageFlag
  interviewWebInfoResult: MCAVS010InterviewWebInfoOutDto // WEB面接対応
  // 年度対応 start
  openLoadingFlag: boolean
  // 年度対応 end
  // 次期12月対応 #72261 start
  afterScheduleSeminarFlag: boolean
  // 次期12月対応 #72261 end
  // 26KH #89294 START
  submissionStatusFlag: boolean // MCB提出状況表示フラグ
  // 26KH #89294 END
  anchorLinkId:String
}

//初期化
const PrimInitialState: Prim = {
  prim: prim, //検索条件初期化
  outDto: initialValuesRecruitmentStatusReport, //検索結果初期化
  address:"",
  newsInfoResult: newsInfo,
  newsGetOutDtoResult: initialState,
  refreshFlag: 0,
  itemCodeValResult: itemCodeVal,
  interviewWebInfoResult: interviewWebInfoOutDto, // WEB面接対応
  // 年度対応 start
  messageFlagResult: messageFlag,
  openLoadingFlag: true,
  // 年度対応 end
  // 次期12月対応 #72261 start
  afterScheduleSeminarFlag: false,
  // 次期12月対応 #72261 end
  // 26KH #89294 START
  submissionStatusFlag: true, // MCB提出状況表示フラグ
  // 26KH #89294 END
  anchorLinkId: '',
}

const recruitmentStatusReportSlice = createSlice({
  name: 'recruitmentStatusReport',
  initialState: PrimInitialState,
  reducers: {
    // 検索
    searchRecruitmentStatusReportList(
      state,
      action: PayloadAction<MCAVS010Request>
    ) {
      return state
    },
    // 検索結果
    setRecruitmentStatusReport(
      state,
      action: PayloadAction<MCAVS010RecruitmentReportInfoOutDto>
    ) {
      state.outDto = action.payload
      // 年度対応 start
      if(action.payload && action.payload.yieldInfoOutDto && action.payload.yieldInfoOutDto.length > 0){
        let displayYearYieldTitleList = action.payload.yieldInfoOutDto[0].displayYearYieldTitleList
        action.payload.displayYearYieldInfo = []
        displayYearYieldTitleList.forEach((info,index) => {
          let displayYearYieldInfo = {
            targetYear: info.targetYear,
            defaultChecked: info.defaultChecked,
            displayYearYieldInfoByYear: info.defaultChecked && action.payload.yieldInfoOutDto[0].yieldMediaNameOutDto
                                        && action.payload.yieldInfoOutDto[0].yieldMediaNameOutDto.length > 0 ? action.payload.yieldInfoOutDto : []
          }
          action.payload.displayYearYieldInfo.push(displayYearYieldInfo);
        })
      }
      // 年度対応 end
      state.messageFlagResult.messageFlagVal = 0
      return state
    },
    // WEB面接対応 start
    searchInterviewWebInfoResult(
      state,
      action: PayloadAction<MCAVS010Request>
    ) {
      return state
    },
    setInterviewWebInfoResult(
      state,
      action: PayloadAction<MCAVS010InterviewWebInfoOutDto>
    ) {
      state.interviewWebInfoResult = action.payload
      state.messageFlagResult.messageFlagVal = 0
      return state
    },
    // WEB面接対応 end
    setMessageFlagResult(state, action: PayloadAction<number>) {
        state.messageFlagResult.messageFlagVal = action.payload
    },
    // [新規応募者（応募者単位）の件数]リンクをクリックする
    pushNewApplicantCount(
      state,
      action: PayloadAction<MCAVS010NewApplicantRequest>  
    ) {
      return state
    },
    // [履歴書未読の件数]リンクをクリックする
    pushUnreadResumeCount(
      state,
      action: PayloadAction<MCAVS010UnreadResumeRequest>  
    ) {
      return state
    },
    // [未読の件数]リンクをクリックする
    pushUnreadInfoCount(
      state,
      action: PayloadAction<MCAVS010UnreadInfoRequest>    
    ) {
      return state
    },
    // [確定待ちの件数]リンクをクリックする
    pushInterviewFixedWaitingCount(
      state,
      action: PayloadAction<MCAVS010InterviewFixedWaitingRequest>    
    ) {
      return state
    },
    // [提示済みの件数]リンクをクリックする
    pushInterviewSchedulePresentedCount(
      state,
      action: PayloadAction<MCAVS010InterviewSchedulePresentedRequest>    
    ) {
      return state
    },
    // [面接予定者一覧へ]リンクをクリックする
    pushInterviewPlannerList(
      state,
      action: PayloadAction<MCAVS010InterviewPlannerListRequest>   
    ) {
      return state
    },
    // 各[面接日程]をクリックする
    pushInterviewSchedule(
      state,
      action: PayloadAction<MCAVS010InterviewPlannerRequest>   
    ) {
      return state
    },
    // 各[歩留まりの件数]リンクをクリックする
    pushYieldCount(
      state,
      action: PayloadAction<MCAVS010YieldRequest>   
    ) {
      return state
    },
    // [予約の件数]リンクをクリックする
    pushSeminarReservedCount(
      state,
      action: PayloadAction<MCAVS010BriefingSessionRequest>   
    ) {
      return state
    },
    // [キャンセルの件数]リンクをクリックする
    pushSeminarCanceledCount(
      state,
      action: PayloadAction<MCAVS010BriefingSessionRequest>   
    ) {
      return state
    },
    // [参加人数]リンクをクリックする
    pushEventParticipant(
      state,
      action: PayloadAction<MCAVS010EventRequest>   
    ) {
      return state
    },
    //お知らせ
    news(
      state,
      action: PayloadAction<{param1: MCAVS010NewsRequest, param2: string}>
      ) {
     return state
    },
    //お知らせ画面用
    getNewsInfoinit(state, action: PayloadAction<NewsInfo>) {
      return state
    },
    //採用状況レポート画面から遷移した時、その画面で選択した行のデータをセットする。
    setNewsInfoResults(state, action: PayloadAction<MCAVS020newsgetOutDto>) {
      state.newsGetOutDtoResult = action.payload
    },
    //お知らせ
    globalMenuNews(state,action: PayloadAction<{param1: MCAVS010NewsRequest, param2: string}>) {
      return state
    },
    // 年度対応 start
    // 年度歩留まり表示タイトルボタン押下
    getDisplayYearYieldTitleInfo(state, action: PayloadAction<{targetYear: string, openLoding:any}>) {
      return state
    },
    setDisplayYearYieldTitleInfo(state, action: PayloadAction<{targetyearTitleInfo: MCAVS010SelectionInformationOutDto[], targetYear: string}>) {
      if(state.outDto && state.outDto.displayYearYieldInfo){
        let displayYearYieldInfo = state.outDto.displayYearYieldInfo
        displayYearYieldInfo.forEach((info,index) => {
          if(info.targetYear == action.payload.targetYear){
            state.outDto.displayYearYieldInfo[index].targetYear = action.payload.targetYear
            state.outDto.displayYearYieldInfo[index].defaultChecked = true
            state.outDto.displayYearYieldInfo[index].displayYearYieldInfoByYear = action.payload.targetyearTitleInfo
          }
        })
      }
      return state
    },
    setopenLoadingFlag(state, action: PayloadAction<boolean>) {
      state.openLoadingFlag = action.payload
      return state
    },
    pushYieldCountOfYearChange(state, action: PayloadAction<MCAVS010YieldRequest & {targetYear: string}>) {
      return state
    },
    // 年度対応 end
    // リフレッシュ
    setRefreshFlagOfReport(state) {
      state.refreshFlag = state.refreshFlag === 0 ? 1 : 0
    },
    setItemCodeValResult(state, action: PayloadAction<string>) {
        state.itemCodeValResult.itemCode = action.payload
    },
    // MCB ロット３　start
    //提出リクエスト人数
    pushsubmissionRequestCount(state,action: PayloadAction<MCAVS010SubmissionRequest>) {
      return state
    },
    //提出人数
    pushSubmitted(state,action: PayloadAction<MCAVS010SubmissionRequest>) {
      return state
    },
	  //未提出人数
    pushUnsubmitted(state,action: PayloadAction<MCAVS010SubmissionRequest>) {
      return state
    },
    // MCB ロット３　end
    // #58811 2022/05/24 start
    pushApplicantCount(state, action:PayloadAction<MCAVS010ApplicantRequest>) {
      return state
    },
    pushApplicantionCount(state, action:PayloadAction<MCAVS010ApplicantionRequest>) {
      return state
    },
    // #58811 2022/05/24 end
    // 次期12月対応 #72261 start
    // "開催後の日程を非表示にする"チェックボックス押下時
    initSeminarAfterScheduleHiddenCheckBox(state, action: PayloadAction<string>) {
      return state
    },
    // "開催後の日程を非表示にする"チェックボックス押下時
    pushSeminarAfterScheduleHiddenCheckBox(
      state,
      action: PayloadAction<MCAVS010SeminarAfterScheduleHiddenCheckBoxRequest>    
    ) {
      return state
    },
    setAfterScheduleSeminarFlag(state, action: PayloadAction<string>) {
      state.afterScheduleSeminarFlag = action.payload === '1' ? true : false
    },
    // 次期12月対応 #72261 end
    // 25KH #75311 start
    // 就職、歩留まりの累計、参加/合格/承諾件数リンク押下
    pushPassYieldCount(state, action: PayloadAction<any>) {
      return state
    },
    // 25KH #75311 end
    // 26KH #89294 START
    // 初期表示[提出状況開閉]
    initSubmissionOpenClose(state, action: PayloadAction<string>) {
      return state
    },
    setSubmissionOpenCloseFlag(state, action: PayloadAction<string>) {
      state.submissionStatusFlag = action.payload === '1' ? true : false
    },
    // [提出状況開閉]ボタンクリック時
    pushSubmissionOpenClose(
      state,
      action: PayloadAction<MCAVS010SubmissionStatusRequest>    
    ) {
      return state
    },
    pushSubmissionCount(state, action:PayloadAction<MCAVS010SubmissionCountRequest>) {
      return state
    },
    pushTotalSubmissionCount(state, action:PayloadAction<MCAVS010SubmissionCountRequest>) {
      return state
    },
    pushAllSubmissionCount(state, action:PayloadAction<MCAVS010SubmissionCountRequest>) {
      return state
    },
    // 26KH #89294 END
    setAnchorLinkId(state, action: PayloadAction<string>) {
      state.anchorLinkId = action.payload
      return state
    },
  },
})
const { actions, reducer } = recruitmentStatusReportSlice
export const {
  searchRecruitmentStatusReportList,
  setRecruitmentStatusReport,
  pushNewApplicantCount,
  pushUnreadResumeCount,
  pushUnreadInfoCount,
  pushInterviewFixedWaitingCount,
  pushInterviewSchedulePresentedCount,
  pushInterviewPlannerList,
  pushInterviewSchedule,
  pushYieldCount,
  pushSeminarReservedCount,
  pushSeminarCanceledCount,
  pushEventParticipant,
  news,
  getNewsInfoinit,
  setNewsInfoResults,
  setRefreshFlagOfReport,
  globalMenuNews,
  // 年度対応 start
  getDisplayYearYieldTitleInfo,
  setDisplayYearYieldTitleInfo,
  setopenLoadingFlag,
  pushYieldCountOfYearChange,
  // 年度対応 end
  setItemCodeValResult,
  setMessageFlagResult,
  // MCB ロット３　start
  pushsubmissionRequestCount,
  pushSubmitted,
  pushUnsubmitted,
  // MCB ロット３　end
  // #58811 2022/05/24 start
  pushApplicantCount,
  pushApplicantionCount,
  // #58811 2022/05/24 end
  searchInterviewWebInfoResult, // WEB面接対応
  setInterviewWebInfoResult, // WEB面接対応
  // 次期12月対応 #72261 start
  initSeminarAfterScheduleHiddenCheckBox,
  pushSeminarAfterScheduleHiddenCheckBox,
  setAfterScheduleSeminarFlag,
  // 次期12月対応 #72261 end
  // 25KH #75311 start
  pushPassYieldCount,
  // 25KH #75311 end
  // 26KH #89294 START
  initSubmissionOpenClose,
  setSubmissionOpenCloseFlag,
  pushSubmissionOpenClose,
  pushSubmissionCount,
  pushTotalSubmissionCount,
  pushAllSubmissionCount,
  // 26KH #89294 END
  setAnchorLinkId,
} = actions
export default reducer
