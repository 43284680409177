import React from 'react'
import { Button, Grid } from '@material-ui/core'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  btnNormal: {
    width: '100px',
    height: '40px',
    color: '#133e80',
  },
  btnMyCareerBox: {
    width: '130px',
    height: '40px',
    color: 'white',
    backgroundColor: '#133e80',
    textTransform: 'none',
    borderRadius: '5px',
    textAlign: 'center',
    lineHeight: '40px',
  },
}))

const TabNormal = () => {
  const classes = useStyles()

  const normal = () => {
    history.push(routeList.messageOutBox)
  }

  return (
    <div>
      <Grid item xs={12}>
        <Button
          variant='outlined'
          onClick={normal}
          className={classes.btnNormal}>
          {' '}
          通常{' '}
        </Button>
        <div className={classes.btnMyCareerBox}>My CareerBox</div>
      </Grid>
    </div>
  )
}

export default TabNormal
