import request from '../utils/request'
import {MCADS030CreateRequest} from 'types/MCADS030/MCADS030CreateRequest'


export const selectLoginCountAndUpdateRequest = (apiData: MCADS030CreateRequest) =>
    request({
        url: '/MCADS030/selectAndUpdate',
        method: 'post',
        data: apiData,
    })

export const selectVersionNumberByTemporaryLoginIdRequest = (temporaryLoginId: string) =>
    request(
        {
            url: `/MCADS030/init`,
            method: 'post',
            data: {temporaryLoginId : temporaryLoginId},
        }
    )