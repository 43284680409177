import {
  call,
  put,
  select,
  all,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects'
import {
  fetchActionPreList,
  setActionPreList,
  sendOrder,
  fetchActionPreSet,
  setActionPreSet,
  saveActionPreSet,
  // 次期開発9月#51248 start
  toMCARS060Init,
  // 次期開発9月#51248 end
} from 'reducers/actionPreReducer'
import {
  getSearchCriteriaSightInfo, setDialogOpen,
} from 'reducers/selectCriteriaManagementReducer'
import {
  actionSettingInitRequest,
  actionSettingSaveRequest
} from 'apis/MCARS060Api'

import {
  fetchEntrySearchCriteriaPreferenceList,
  sort
} from 'apis/MCARS050Api'
// 次期開発9月#51248 start
import { changeSearchCriteriaToActionRequest, createRequest } from 'apis/MCARS030Api'
// 次期開発9月#51248 end
import { getRecruitmentManagementDivision } from '../selectors/authSelectors'
import { openSnackbar,openModal } from 'reducers/messageReducer'
import {MCARS060InitRequest} from "../types/MCARS060/MCARS060InitRequest"
import { magiContants } from 'utils/contants'
import { setTagSettings } from 'reducers/tagManagementReducer'
import { getMessage } from 'common/messageUtil'
// 次期開発9月#51248 start
import { MCARS030CreateRequestEmployment } from 'types/MCARS030/MCARS030CreateRequestEmployment'
import { MCARS030ChangeSchCriToActionRequest } from 'types/MCARS030/MCARS030ChangeSchCriToActionRequest'
// 次期開発9月#51248 end
function* fetchSaga(action: ReturnType<typeof fetchActionPreSet>) {
  try {
    yield put(setTagSettings([]))
      let initRequestBody:MCARS060InitRequest={
        entrySearchCriteriaSettingId:action.payload.entrySearchCriteriaSetting
    }
    const data = yield call(actionSettingInitRequest,initRequestBody)
    if(data!=null){
    data.progressStatusSettingIds.unshift(-1);
    data.progressNames.unshift("（以下から選択）");
    data.selectionFlowSettingIds.unshift(-1);
    data.selectionNames.unshift("（以下から選択）");
    data.recruitmentManagementDivisions.unshift("-1");
    data.systemSelectionFlowMasterIds.unshift(-1);
    yield put(setActionPreSet(data))
    action.payload.setOpenActionPreDialog(true)
  } }catch (error) {
    if (error.message == getMessage(magiContants.MESSAGECODE_401)) {
      yield put(openModal(error.message))
    } else {
      yield put(openSnackbar(error.message))
    }
  }
}
function* saveSaga(action: ReturnType<typeof saveActionPreSet>) {
  try {
      try {
        const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
        action.payload.reqestInfo.recruitmentManagementDivision = recruitmentManagementDivision;
        const message = yield call(actionSettingSaveRequest, action.payload.reqestInfo);
        action.payload.closeFunction(false);
        const data = yield call(fetchEntrySearchCriteriaPreferenceList,{recruitmentManagementDivision:recruitmentManagementDivision})
        const screenId = action.payload.screenId
        if(screenId == 'MCARS050'){
          yield put(setActionPreList(data))
        }else if(screenId == 'MCARS010'){
          yield put(getSearchCriteriaSightInfo())
        }
        yield put(openSnackbar(message))
      } catch (error) {
        yield put(openModal(error.message))
      }
    } catch (error) {
      yield put(openSnackbar(error.message))
    }
}
function* fetchListSaga() {
  try {
    const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
    const data = yield call(fetchEntrySearchCriteriaPreferenceList,{recruitmentManagementDivision:recruitmentManagementDivision})
    yield put(setActionPreList(data))
  } catch (error) {
    if (error.message == getMessage(magiContants.MESSAGECODE_401)) {
      yield put(openModal(error.message))
    } else {
      yield put(openSnackbar(error.message))
    }
  }
}
function* sendToBehind(action: ReturnType<typeof sendOrder>) {
  const requestparam = action.payload
  const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
  try {
    const data = yield call(sort, {...requestparam,recruitmentManagementDivision:recruitmentManagementDivision});
    yield put(setActionPreList(data))
  } catch (error) {
    if (error.message == getMessage(magiContants.MESSAGECODE_401)) {
      yield put(openModal(error.message))
    } else {
      yield put(openSnackbar(error.message))
    }
  }
}

// 次期開発9月#51248 start
function* toMCARS060InitSaga(action: ReturnType<typeof toMCARS060Init>) {
  try {
    const unActionSearchCriteriaNameList = action.payload.unActionSearchCriteriaNameList
    let searchCriteria = null
    // 検索条件にアクション設定でない条件があるかどうか
    if (unActionSearchCriteriaNameList != null && unActionSearchCriteriaNameList.length > 0) {
      const changeSchCriToActionParam : MCARS030ChangeSchCriToActionRequest ={
        unActionSearchCriteriaList: action.payload.unActionSearchCriteriaList,
        searchCriteria: action.payload.searchCriteria
      }
      // 検索条件をアクション設定条件に変換
      searchCriteria = yield call(changeSearchCriteriaToActionRequest,changeSchCriToActionParam)
    }
    const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
    let param1:MCARS030CreateRequestEmployment={
      searchCriteria:searchCriteria == null ? action.payload.searchCriteria : JSON.parse(searchCriteria),
      searchName:action.payload.searchName,
      recruitmentManagementDivision:recruitmentManagementDivision
    }
    // MY検索条件保存
    const entrySearchCriteriaSettingId = yield call(createRequest,param1)
    let param2:MCARS060InitRequest={
      entrySearchCriteriaSettingId:entrySearchCriteriaSettingId,
    }
    // MCARS060アクション設定モーダルに遷移
    const data = yield call(actionSettingInitRequest,param2)
    yield put(setDialogOpen(false))
    if(data!=null){
      data.progressStatusSettingIds.unshift(-1);
      data.progressNames.unshift("（以下から選択）");
      data.selectionFlowSettingIds.unshift(-1);
      data.selectionNames.unshift("（以下から選択）");
      data.recruitmentManagementDivisions.unshift("-1");
      data.systemSelectionFlowMasterIds.unshift(-1);
      yield put(setActionPreSet(data))
      action.payload.setOpenActionPreDialog(true)
      yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCARS030_009)))
    }
  }catch (error) {
      yield put(openModal(error.message))
  }
}
// 次期開発9月#51248 end

export default function* accountSaga() {
  yield all([
    takeEvery(fetchActionPreList, fetchListSaga),
    takeEvery(sendOrder, sendToBehind),
    takeEvery(fetchActionPreSet, fetchSaga),
    takeLeading(saveActionPreSet, saveSaga),
    // 次期開発9月#51248 start
    takeEvery(toMCARS060Init,toMCARS060InitSaga),
    // 次期開発9月#51248 end
  ])
}

