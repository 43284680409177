import request from '../utils/request'

export const selectRecruitmentGroupRequest = (recruitmentManagementDivision:string) =>
  request({
    url: `/MCADS020/init/${recruitmentManagementDivision}`,
    method: 'post',
  })
export const selectLoginCountApi = () =>
  request({
    url: '/MCADS010/selectLoginCount',
    method: 'post',
  })

export const selectRecruitmentIdRequest = (apiData: string[]) =>
  request({
    url: '/MCADS020/selectRecruitmentId',
    method: 'post',
    data: apiData,
  })
