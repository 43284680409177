import request from 'utils/request'
import { MCAGS010UpdateRequest } from 'types/MCAGS010/MCAGS010UpdateRequest'


export const getInit = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAGS010/init/${recruitmentManagementDivision}`,
    method: 'post',
})

export const update = (apiData: MCAGS010UpdateRequest) =>
  request({
    url: '/MCAGS010/update',
    method: 'post',
    data: apiData,
})