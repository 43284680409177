import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { 
    mcafs020NotificationMailInfo,
} from 'pages/MCAFS020/formConfig'
import { MCAFS020InitDisplayRequest } from 'types/MCAFS020/MCAFS020InitDisplayRequest'
import { MCAFS020UpdateDisplayRequest } from 'types/MCAFS020/MCAFS020UpdateDisplayRequest'

// AlertMailPreferenceInfo
export interface AlertMailPreferenceInfo {
    [key: string]: string | number
    alertTimingRadio: number
    mailAddress: string
    sysVersionNumber: string
}

interface MCAFS020Values {
    notificationMailInfoResult: AlertMailPreferenceInfo
    notificationMailDialogOpen: boolean
    linkFlag: string
}

const mcafs020Values: MCAFS020Values = {
    notificationMailInfoResult: mcafs020NotificationMailInfo,
    notificationMailDialogOpen: false,
    linkFlag: '',
}

const alertMailSettingSlice = createSlice({
    name: 'alertMailSetting',
    initialState: mcafs020Values,
    reducers: {
        // MCAFS020 画面初期表示
        selectAlertMail(state, action: PayloadAction<MCAFS020InitDisplayRequest>) {
            return state
        },
        setAlertMail(state,action:PayloadAction<AlertMailPreferenceInfo>){
            state.notificationMailInfoResult = action.payload
            if (action.payload == null || action.payload.alertTimingRadio == null) {
                state.notificationMailInfoResult.alertTimingRadio = 0
            } else {
                state.notificationMailInfoResult.alertTimingRadio = Number(action.payload.alertTimingRadio)
            }
            return state
        },
        setNotificationMailDialogOpen(state, action: PayloadAction<boolean>) {
            state.notificationMailDialogOpen = action.payload
            return state
        },
        setLinkFlag(state, action: PayloadAction<string>) {
            state.linkFlag = action.payload
            return state
        },
        // MCAFS020「更新する」ボタンを押下
        updateAlertMail(state, action: PayloadAction<{updateAlertMailRequest: MCAFS020UpdateDisplayRequest, recruitmentManagementDivision: string}>) {
            return state
        }
    }
})

export const {
    selectAlertMail,
    setAlertMail,
    setNotificationMailDialogOpen,
    setLinkFlag,
    updateAlertMail
} = alertMailSettingSlice.actions
export default alertMailSettingSlice.reducer