import request from 'utils/request'

import { MCBLS040InitRequest } from 'types/MCBLS040/MCBLS040InitRequest'

export const initMCBLS040Api = (requestdata: MCBLS040InitRequest) =>
  request({
    url: '/MCBLS040/init',
    method: 'post',
    data: requestdata
  })





