import { MCBJS040InitDisplayRequest } from 'types/MCBJS040/MCBJS040InitDisplayRequest'
import { MCBJS040SubmissionRequestTemplateRequest } from 'types/MCBJS040/MCBJS040SubmissionRequestTemplateRequest'
import request from 'utils/request'

export const initRequest = (params: MCBJS040InitDisplayRequest) =>
request({
    url: `MCBJS040/init`,
    method: 'post',
    data: params,
})

export const insertOrUpdateRequest=(requestBody:{registeredFileName:File[],submitParams:MCBJS040SubmissionRequestTemplateRequest}) =>{
    const data = new FormData()
    requestBody.registeredFileName.forEach((file) => {
        data.append('files', file);
    });
    data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))
    
    return request({
      url: `MCBJS040/resister`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data:data,
    })
  }