// 初期画面初期化
const initialValues = {
  tagName: '',
  lastUpdateDateFrom: '',
  lastUpdateDateTo: '',
  recruitmentManagementDivision: '',
};
// 検索画面初期化
const submitValues = {
  tagName: '',
  lastUpdateDateFrom: '',
  lastUpdateDateTo: '',
  recruitmentManagementDivision: '',
} ;
// 削除ボタン初期化
const deleteValues = {
  tagSettingId: '',
  versionNumberTapr: '',
  versionNumberTuif: '',
  versionNumberCact: '',
  deleteTagManagementFlg: '',
} ;

export { initialValues, submitValues, deleteValues };
