// 画面初期表示時
export interface MCAXS650Init {
  jobSeekerId: string //求職者ID
  collegeCode: string //大学コード
  facultyCode: string //学部コード
  subjectCode: string //学科コード
  subjectClassificationCode: string //学科分類コード
  subjectClassification: string //学科分類
  humanitiesAndSciencesDivisionCode: string //文理区分コード
  humanitiesAndSciencesDivision: string //文理区分
  studentDivision: string //学生区分
  manualRegistrationFlag: string // 手動登録フラグ
  schoolInfoflag: boolean // 学校情報変更有無判定flag
  humanitiesAndSciencesDivisionCodeByJobSeekerInfo: string //文理区分コード
  humanitiesAndSciencesDivisionByJobSeekerInfo: string //文理区分
  subjectClassificationCodeByJobSeekerInfo: string //学科分類コード
}

// 画面初期返
export interface MCAXS650InitReturn {
  subjectClassificationCode: string //学科分類コード
  subjectClassification: string //学科分類
  humanitiesAndSciencesDivisionCode: string //文理区分コード
  humanitiesAndSciencesDivision: string //文理区分
  updateTime: string //更新日時
  unsubscribeTime: string //退会日時
}

// "更新する"ボタン押下時
export interface MCAXS650Update {
  memberType: string //会員種別
  jobSeekerId: string //応募者管理ID
  surname: string //姓
  firstName: string //名
  surnameKana: string //姓カナ
  firstNameKana: string //名カナ
  birthday: string //生年月日
  sex: string //性別
  mailAddressPC: string //メールアドレス（メイン）
  mailAddressMobile: string //メールアドレス（サブ）
  phoneNumberMobile: string //電話番号（携帯）
  phoneNumberHome: string //電話番号（自宅）
  phoneNumberVacation: string //電話番号（休暇中）
  postalCode: string //郵便番号
  prefectures: string //都道府県
  addressAfterMunicipalities: string //市区町村以降の住所
  vacPostalCode: string //郵便番号
  vacPrefectures: string //都道府県
  vacAddressAfterMunicipalities: string //市区町村以降の住所
  studentDivision: string //学生区分
  graduationPlanTime: string //卒業予定時期
  schoolName: string //学校名
  universityFaculty: string //学部
  universitySubject: string //学科
  subjectClassification: string //学科分類
  humanitiesAndSciencesDivision: string //文理区分
  degree: string //取得学位
  schoolCountryRegion: string //国・地域コード
  schoolState: string //州（アメリカの場合）コード
  zeminar: string //ゼミ
  circle: string //サークル
  majorTheme: string //専攻テーマ
  ownedQualificationTag: string[] //保有資格_タグ
  ownedQualificationSummary: string //保有資格_サマリ
  languageForceTag: string[] //語学スキル_タグ
  languageForceSummary: string //語学スキル_サマリ
  sysVersionNumber: string //バージョン番号
  sysVersionNumberForJobSeekerByCompany: string //バージョン番号（企業別求職者）
  manualRegistrationFlag: string //手動更新フラグ
  collegeCode: string //大学コード
  facultyCode: string //学部コード
  subjectCode: string //学科コード
  schoolGroupCode: string //学校グループコード
  jobSeekerByCompanyId: string //企業別求職者ID
  recruitmentManagementDivision: string //採用管理区分
}

// 画面用
export interface MCAXS650Image {
  sameAsCurrentAddress: string //現住所と同じ
  prefectures: string //都道府県
  vacPrefectures: string //都道府県
  jobSeekerIdForDisplay: string //表示用求職者ID
}

// 画面初期表示時
const mcaxs650Init = {
  jobSeekerId: '', //求職者ID
  collegeCode: '', //大学コード
  facultyCode: '', //学部コード
  subjectCode: '', //学科コード
  subjectClassificationCode: '', //学科分類コード
  subjectClassification: '', //学科分類
  humanitiesAndSciencesDivisionCode: '', //文理区分コード
  humanitiesAndSciencesDivision: '', //文理区分
  studentDivision: '', //学生区分
  manualRegistrationFlag: '', // 手動登録フラグ
  schoolInfoflag: false, // 学校情報変更有無判定flag
  humanitiesAndSciencesDivisionCodeByJobSeekerInfo: '', //文理区分コード
  humanitiesAndSciencesDivisionByJobSeekerInfo: '', //文理区分
  subjectClassificationCodeByJobSeekerInfo: '' //学科分類コード
}

// 画面初期返
const mcaxs650InitReturn = {
  subjectClassificationCode: '', //学科分類コード
  subjectClassification: '', //学科分類
  humanitiesAndSciencesDivisionCode: '', //文理区分コード
  humanitiesAndSciencesDivision: '', //文理区分
  updateTime: '', //更新日時
  unsubscribeTime: '', //退会日時
}

// "更新する"ボタン押下時
const mcaxs650Update = {
  memberType: '', //会員種別
  jobSeekerId: '', //応募者管理ID
  surname: '', //姓
  firstName: '', //名
  surnameKana: '', //姓カナ
  firstNameKana: '', //名カナ
  birthday: '', //生年月日
  sex: '', //性別
  mailAddressPC: '', //メールアドレス（メイン）
  mailAddressMobile: '', //メールアドレス（サブ）
  phoneNumberMobile: '', //電話番号（携帯）
  phoneNumberHome: '', //電話番号（自宅）
  phoneNumberVacation: '', //電話番号（休暇中）
  postalCode: '', //郵便番号
  prefectures: '', //都道府県
  addressAfterMunicipalities: '', //市区町村以降の住所
  vacPostalCode: '', //郵便番号
  vacPrefectures: '', //都道府県
  vacAddressAfterMunicipalities: '', //市区町村以降の住所
  studentDivision: '', //学生区分
  graduationPlanTime: '', //卒業予定時期
  schoolName: '', //学校名
  universityFaculty: '', //学部
  universitySubject: '', //学科
  subjectClassification: '', //学科分類
  humanitiesAndSciencesDivision: '', //文理区分
  degree: '', //取得学位
  schoolCountryRegion: '', //国・地域コード
  schoolState: '', //州（アメリカの場合）コード
  zeminar: '', //ゼミ
  circle: '', //サークル
  majorTheme: '', //専攻テーマ
  ownedQualificationTag: [], //保有資格_タグ
  ownedQualificationSummary: '', //保有資格_サマリ
  languageForceTag: [], //語学スキル_タグ
  languageForceSummary: '', //語学スキル_サマリ
  sysVersionNumber: '', //バージョン番号
  sysVersionNumberForJobSeekerByCompany: '', //バージョン番号（企業別求職者）
  manualRegistrationFlag: '', //手動更新フラグ
  collegeCode: '', //大学コード
  facultyCode: '', //学部コード
  subjectCode: '', //学科コード
  schoolGroupCode: '', //学校グループコード
  jobSeekerByCompanyId: '', //企業別求職者ID
  recruitmentManagementDivision: '', //採用管理区分
}

// 画面用
const mcaxs650Image = {
  sameAsCurrentAddress: '', //現住所と同じ
  prefectures: '', //現住所都道府県
  vacPrefectures: '', //休暇中都道府県
  jobSeekerIdForDisplay: '', //表示用求職者ID
}

export { mcaxs650Init, mcaxs650Update, mcaxs650Image, mcaxs650InitReturn }
