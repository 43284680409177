// 次期開発9月#51248 start
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FieldProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as MuiTextField } from '@material-ui/core'
import * as yup from 'yup'


const useStyles = makeStyles(theme => ({
    input: {
        '&::placeholder': {
            color: 'darkgray',
        },
        fontSize: '14px',
        '&:disabled': {
            borderColor: '#F2F2F2',
            backgroundColor: '#F2F2F2',
        },
    },
    textCounter: {
        float: "right",
        minWidth: 120,
        textAlign: "right"
    },
    inputWithCounter: {
        marginTop: '0px ',
        width: '267px',
    },
}));

interface Props extends FieldProps {
    maxAmount?: number
    onChange?: (value: string) => void
    setError2: ({isError: boolean, message: string}: any) => void
    error2: {isError: boolean, message: string}
}

const InputWithCounterForAction = ({ field, form, maxAmount, onChange, setError2,error2, ...others }: Props) => {
    const classes = useStyles();
    const { errors, touched } = form
    const { name, value } = field;
    const searchInsertActionNameYup = yup.object().shape({
        searchInsertActionName: yup.string(),
    })
    const handleChange = (event: any) => {
        searchInsertActionNameYup.validate({ searchInsertActionName: event.target.value })
        .then()
        .catch(err => {
          setError2({isError: true, message: err.errors})
        })
        if (400 >= event.target.value.length) {
            if (onChange) {
                onChange(event.target.value)
            }
            form.setFieldValue(name, event.target.value);
        }
        setError2({ isError: false, message: '' })
    };

    return (
        <React.Fragment>
            <Grid container className={classes.inputWithCounter}>
                <Grid item xs={12}>
                    <MuiTextField
                        name={name}
                        value={value}
                        onChange={handleChange}
                        type='text'
                        margin='dense'
                        variant='outlined'
                        fullWidth
                        inputProps={{ spellCheck: false }}
                        InputProps={{
                           classes: { input: classes.input },
                        }}
                        disabled={form.values.selectRadio === '0' || form.values.selectRadio === '1' ? true : false}
                        error={Boolean(errors[name] && touched[name]) || error2.isError}
                        helperText={error2.isError && form.values.selectRadio === '2' ? error2.message : errors[name] && touched[name] ? errors[name] : null}
                    />
                </Grid>
                <Grid item xs={12} className={classes.textCounter}>
                    <Typography gutterBottom variant='h6'>
                        {`入力 ${value ? value.length : 0} / 最大 ${maxAmount}`}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default InputWithCounterForAction;
// 次期開発9月#51248 end
