const textMap = {
  reOrderTip: 'ドラッグ＆ドロップで表示順を入れ替えます',
}

export const initialValues = {
  entrySearchCriteriaSettingId: '',
  searchCriteriaName: '',
  searchCriteria: '',
  sysVersionNumber: 0,
}
export {
  textMap,
}
