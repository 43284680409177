import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: 'lightgrey',
  },
}))

interface Props {
  variant?: string
  className?: string
  noUnderline?: boolean
}

const SubTitle: React.FC<Props> = ({ 
  children, 
  variant = 'h2' ,
  className,
  noUnderline = false
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography
        variant={variant as any}
        gutterBottom
        className={`${classes.title} ${className}`}>
        {children}
      </Typography>
      {noUnderline ? null : <Divider className={classes.divider} />}
    </div>
  )
}

export default SubTitle
