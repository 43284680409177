/* eslint-disable prefer-const */
import { Dialog, DialogContent, DialogTitle, Grid, List, makeStyles, ListItem, DialogActions, Button } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { magiContants } from 'utils/contants'

export interface OptionData {
    label: string
    value: string
    facultyCode: string
    subjectCode: string
}

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: '#133e80',
        padding: '8px',
    },
    title: {
        color: '#ffffff',
        fontSize: '22px',
        fontWeight: 'bold',
    },
    dialogContent: {
        padding: '8px 60px 0px 60px',
    },
    listConent: {
        height: '370px',
        overflowY: 'auto',
        border: '1px solid black',
        '& :hover': {
            backgroundColor: '#e6f1ff',
        },
        '& .Mui-selected': {
            backgroundColor: '#fcebeb',
            border: '1px solid #e2b4e3'
        },
        '& .Mui-selected:hover': {
            backgroundColor: '#fcebeb',
            border: '1px solid #e2b4e3'
        },
        padding: '0px',
    },
    listItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        height: '60px',
        cursor: 'pointer'
    },
    // "キャンセルする"ボタン
    cancel: {
        color: '#1a1a1a',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#e0e0e0',
        borderColor: '#cbcbcb',
        '&:hover': {
            backgroundColor: '#c1d1eb',
            borderColor: '#999999',
        },
        minWidth: '180px',
    },
    // "入力内容を確認する"ボタン
    confirmButton: {
        color: '#ffffff',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#3380cc',
        borderColor: '#3380cc',
        '&:hover': {
            backgroundColor: '#4596e6',
            borderColor: '#3380cc',
        },
        minWidth: '180px',
    },
}))

interface Props {
    majorDialog: boolean
    setMajorDialog: (state: boolean) => void
    optionList: OptionData[]
    getSelectedMajor: (values: OptionData) => void
}
const MajorField = ({ majorDialog, setMajorDialog, optionList, getSelectedMajor }: Props) => {
    const classes = useStyles()
    const [selectedData, setSelectedData] = useState<OptionData>({ label: magiContants.EMPTY, value: magiContants.EMPTY , facultyCode: magiContants.EMPTY , subjectCode: magiContants.EMPTY })
    const [selectedIndex, setSelectedIndex] = useState(-1)

    useEffect(() => {
        setSelectedIndex(-1)
    }, [optionList])

    const onLink = (data: OptionData, index: number) => {
        let dataSelect: OptionData = {
            label: data.label,
            value: data.value,
            facultyCode: data.facultyCode,
            subjectCode: data.subjectCode
        }
        setSelectedData(dataSelect)
        setSelectedIndex(index)
    }

    const handleCancel = () => {
        setMajorDialog(false)
    }

    const confirmButton = () => {
        if (selectedIndex !== -2) {
            getSelectedMajor(selectedData)
        } else {
            setMajorDialog(false)
        }
    }

    return (
        <>
            <Dialog open={majorDialog} fullWidth maxWidth='md'>
                <DialogTitle className={classes.dialogTitle}>
                    <Grid className={classes.title}>専攻分野選択</Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <List component='div' className={classes.listConent}>
                        {optionList.map((data: OptionData, index: number) => (
                            <ListItem
                                selected={selectedIndex === index}
                                button
                                divider
                                className={classes.listItem}
                                onClick={() => {
                                    onLink(data, index)
                                }}>
                                {data.label}
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Grid container direction='row' justify='center'>
                        <Grid xs={5} item container justify='flex-end'>
                            <Button className={classes.cancel} onClick={handleCancel} variant='contained'>キャンセルする</Button>
                        </Grid>
                        <Grid style={{ width: '20px' }} />
                        <Grid xs={5} item>
                            <Button className={classes.confirmButton} onClick={confirmButton} variant='contained'>OK</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MajorField