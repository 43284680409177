import { getMessage } from "common/messageUtil"
import { magiContants } from "utils/contants"

const  initialValues= {
    errorReportId: '', //エラー報告ID
    generationStatus: '',//発生状況
    sysVersionNumber: 1,//バージョン番号
    sysRegistrantId: '',//sys登録者ID
    companyAccountId: '',//企業アカウントID
}

// 年度対応 start
export const listener = (ev: {
    preventDefault: () => void
    returnValue: string
}) => {
    ev.preventDefault()
    ev.returnValue = getMessage(magiContants.MESSAGECODE_MCAUS010_003)
}
// 年度対応 end

export{initialValues}
