import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAQS030AutoForceStopSubmisionRequestDto } from 'pages/MCAQS030/formConfig'
import { MCAQS030ConfirmationRequest } from 'types/MCAQS030/MCAQS030ConfirmationRequest'
import { MCAQS030InitMergingMethodEntry } from 'types/MCAQS030/MCAQS030InitMergingMethodEntry'
import { MCAQS030JobSeekerDisplay } from 'types/MCAQS030/MCAQS030JobSeekerDisplay'
import { MCAQS040JobSeekerDisplay } from 'types/MCAQS040/MCAQS040JobSeekerDisplay'
import { MCAQS040MergingExecutionRequest } from 'types/MCAQS040/MCAQS040MergingExecutionRequest'


// MCAQS030.List.画面初期表示時
export interface DupIdentCheckHistory {
  jobSeekerId: number // 求職者ID
  jobSeekerByCompanyId: number // 企業別求職者ID
  jobSeekerIdForDisplay: string // 表示用求職者ID
  memberType: string // 会員種別
  fullName: string // 姓名
  schoolName: string // 学校名
  facultyName: string // 学部名
  subjectName: string // 学科名
  homePhone: string // 電話番号（自宅）
  mobilePhone: string // 電話番号（携帯）
  pcAddress: string // メールアドレス（メイン）
  mobileAddress: string // メールアドレス（サブ）
  recordCounts: string // 名寄せの重複件数が規定値
  recordCountsFlg: number // 重複件数が規定値を超えた
  entryId: number // エントリーID
  selectionManagementId: number // 選考管理ID
}

// Param
export interface Merge {
  recruitmentManagementDivision: string // 採用管理区分
  mergeCheckHistory: string // 名寄せチェック履歴ID
  mergeCheckHistoryDetailId: string // 名寄せチェック履歴明細ID
}

// MCAQS040
export interface ItemProperty {
  jobSeekerId: number // 求職者ID
  jobSeekerByCompanyId: number // 企業別求職者ID
  jobSeekerIdForDisplay: string // 表示用求職者ID
  fullName: string // 姓名
  schoolName: string // 学校名
  facultyName: string // 学部名
  subjectName: string // 学科名
  homePhone: string // 電話番号（自宅）
  mobilePhone: string // 電話番号（携帯）
  pcAddress: string // メールアドレス（メイン）
  mobileAddress: string // メールアドレス（サブ）
  entryId: number // エントリーID
  selectionManagementId: number // 選考管理ID
}

interface InitialValuesType {
  // MCAQS030.List
  dupIdentCheckHistoryTableData: DupIdentCheckHistory[]
  // qs030 start on/off
  openMCAQS030: boolean
  // 初期化データ
  delectFlag: boolean
  // パラメータ
  mergeParam: Merge

  initDataList: ItemProperty[]
  openMCAQS040: boolean
  updateParam: MCAQS040MergingExecutionRequest
  jobSeekerId: string
  messageId: string // メッセージ（選考重複チェック）
  selectionName: string // 重複選考名
  openCheckDialog: boolean // boolean
  // 25KH #74726 start
  selectionFlowSettingId: string // 選考フロー設定ID
  // 25KH #74726 end
  // 26KH #89293 start
  openAutoStopCheckDialog: boolean // boolean
  confirmResultInfo: MCAQS030AutoForceStopSubmisionRequestDto // 確認結果情報
  // 26KH #89293 end
}

const initialState: InitialValuesType = {
  // MCAQS030.List
  dupIdentCheckHistoryTableData: [],
  // qs030 start on/off
  openMCAQS030: false,
  // 初期化データ
  delectFlag: false,
  // パラメータ
  mergeParam: {
    recruitmentManagementDivision: '',
    mergeCheckHistory: '',
    mergeCheckHistoryDetailId: '',
  },

  initDataList: [],
  openMCAQS040: false,
  updateParam: {
    recruitmentManagementDivision: '',
    mergeCheckHistory: '',
    mergeCheckHistoryDetailId: '',
    remainMergeCheckJobSeekerIdDetailId: '',
    removeMergeCheckJobSeekerIdDetailId: '',
    remainMergeCheckJobSeekerByCompanyId: '',
    removeMergeCheckJobSeekerByCompanyId: '',
    recruitmentManagementMergeFlag: '',
    // 25KH #74726 start
    selectionFlowMergeInfoObj: [],
    // 25KH #74726 end
    // 26KH #89293 start
    autoForceStopDescriptionType: '0',
    autoForceStopSubmisionRequestObj: [],
    // 26KH #89293 end
  },
  jobSeekerId: '',
  messageId: '',
  selectionName: '',
  openCheckDialog: false,
  // 25KH #74726 start
  selectionFlowSettingId: '',
  // 25KH #74726 end
  // 26KH #89293 start
  openAutoStopCheckDialog: false,
  confirmResultInfo: {
    autoForceStopDescriptionType: '0', // 自動強制停止説明文種別
    autoForceStopSubmisionRequestObj: [], // 自動強制停止対象提出リクエスト
  },
  // 26KH #89293 end
}

const duplicateIdentificationSlice = createSlice({
  name: 'duplicateIdentification',
  initialState,
  reducers: {
    // MCAQS030
    // パラメータ
    setMergeParam(state, action: PayloadAction<Merge>) {
      state.mergeParam = action.payload
      return state
    },
    // 初期表示処理
    getDupIdentCheckHistoryTableData(
      state,
      action: PayloadAction<MCAQS030InitMergingMethodEntry>
    ) {
      return state
    },
    setDupIdentCheckHistoryTableData(
      state,
      action: PayloadAction<DupIdentCheckHistory[]>
    ) {
      state.dupIdentCheckHistoryTableData = action.payload
      // 26KH #89293 start
      state.confirmResultInfo = {
        autoForceStopDescriptionType: '0', // 自動強制停止説明文種別
        autoForceStopSubmisionRequestObj: [], // 自動強制停止対象提出リクエスト
      }
      // 26KH #89293 end
      return state
    },
    // [確認する]ボタン押下時
    getConfirmationInitData(
      state,
      action: PayloadAction<MCAQS030ConfirmationRequest>
    ) {
      return state
    },
    // リンク押下時
    getDetailInitData(state, action: PayloadAction<MCAQS030JobSeekerDisplay>) {
      return state
    },
    // 初期化データ
    setOpenDupIdentCreate(state, action: PayloadAction<boolean>) {
      state.openMCAQS030 = action.payload
      return state
},
    // 本モーダルスイッチ
    setDelectFlag(state, action: PayloadAction<boolean>) {
      state.delectFlag = action.payload
      return state
    },

    // 25KH #74726 start
    // 重複選考名
    setSelectionName(state, action: PayloadAction<{messageId:string, selectionName:string, selectionFlowSettingId:string}>) {
    // 25KH #74726 end
      state.messageId = action.payload.messageId
      state.selectionName = action.payload.selectionName
      // 25KH #74726 start
      state.selectionFlowSettingId = action.payload.selectionFlowSettingId
      // 25KH #74726 end
    },

    // ダイアログ
    setOpenCheckDialog(state, action: PayloadAction<boolean>) {
      state.openCheckDialog = action.payload
    },

    // 26KH #89293 start
    // 確認結果情報
    setConfirmResultInfo(
      state,
      action: PayloadAction<MCAQS030AutoForceStopSubmisionRequestDto>
    ) {
      state.confirmResultInfo = action.payload
    },

    // 自動強制停止ダイアログ
    setOpenAutoStopCheckDialog(state, action: PayloadAction<boolean>) {
      state.openAutoStopCheckDialog = action.payload
    },
    // 26KH #89293 end

    // MCAQS040
    // 初期表示処理
    getInitDataList(
      state,
      action: PayloadAction<MCAQS040MergingExecutionRequest>
    ) {
      return state
    },
    setInitDataList(state, action: PayloadAction<ItemProperty[]>) {
      state.initDataList = action.payload
      return state
    },
    setUpdateParam(
      state,
      action: PayloadAction<MCAQS040MergingExecutionRequest>
    ) {
      state.updateParam = action.payload
      return state
    },
    // モジュール
    setOpenMCAQS040(state, action: PayloadAction<boolean>) {
      state.openMCAQS040 = action.payload
      return state
    },
    // [実行する]ボタン押下時
    updateSelectedDate(
      state,
      action: PayloadAction<MCAQS040MergingExecutionRequest>
    ) {
      return state
    },
    // リンク押下時
    getDetailData(state, action: PayloadAction<MCAQS040JobSeekerDisplay>) {
      return state
    },
  },
})

export const {
  // MCAQS030
  getDupIdentCheckHistoryTableData,
  setDupIdentCheckHistoryTableData,
  getConfirmationInitData,
  setDelectFlag,
  setOpenDupIdentCreate,
  setMergeParam,
  getDetailInitData,
  setSelectionName,
  setOpenCheckDialog,
  // 26KH #89293 start
  setConfirmResultInfo,
  setOpenAutoStopCheckDialog,
  // 26KH #89293 end

  // MCAQS040
  // 初期表示処理
  getInitDataList,
  setInitDataList,
  setOpenMCAQS040,
  setUpdateParam,
  // [実行する]ボタン押下時
  updateSelectedDate,
  // リンク押下時
  getDetailData,
} = duplicateIdentificationSlice.actions
export default duplicateIdentificationSlice.reducer
