import request from 'utils/request'
import { MCAXS630EntryInfoRequest } from 'types/MCAXS630/MCAXS630EntryInfoRequest'

export const insInitRequest = () =>
  request({
    url: '/MCAXS630/init',
    method: 'post',
  })
export const registerRequest = (entry: MCAXS630EntryInfoRequest) =>
  request({
    url: '/MCAXS630/register',
    method: 'post',
    data: entry,
  })
