import { MessageData } from 'utils/tableDataTransfrom'

/** 画面表示項目 */
export interface ScreenDisplayItems {
  jobSeekerId: number
  selectionFlowArea: SelectionFlowArea
  commonHeader: CommonHeader
  profile: Profile
  question: Question[]
  jobCareer: JobCareer
  qualificationsSkills: QualificationsSkills
  selfPR: SelfPR
  aptitudeTestAreaViewFlag: boolean
  images: Images
  gab: Gab
  opq: Opq
  cab: Cab
  message: MessageData[]
  nuisanceAreaViewFlag: boolean
}

/** 選考フローエリア */
export interface SelectionFlowArea {
  [key: string]: any
  selectionFlowAreaViewFlag: SelectionFlowAreaViewFlag
}

/** 選考フローエリア */
export interface SelectionFlowAreaViewFlag {
  [key: string]: any
  interviewSchedule: boolean
  applicationDateTime: boolean
  recommendDate: boolean
  selectionFlowChange: boolean
  selectionFlowRouteChange: boolean
  selectionFlowExclude: boolean
  selectionFlowUnExclude: boolean
  changePassingStatusSelection: boolean
  selectionFlowProgressBar: boolean
  selectionFlowProgressUpdateDateTime: boolean
  marchLabel: boolean
  selectionFlowExtraInfo: boolean
  selectionFlowEvaluation: boolean 
  selectionFlowComment: boolean
  entryJobCategoryNameBadge: boolean 
  applicationDivisionBadge: boolean
  scoutBenefits: boolean 
  tagFunction: boolean
  createNotAdopetdNortificationApplicant: boolean
  createNotAdopetdNortificationInterview: boolean
}

/** 共通ヘッダーエリア */
export interface CommonHeader {
  [key: string]: any
  companyName: string
  loginUserName: string
  goBack: boolean
  goNext: boolean
  nameWithLinkFlag: boolean
  fullName: string
  familyName: string
  name: string
  fullNameKana: string
  familyNameKana: string
  nameKana: string
  age: string
  sex: string
  jobSeekerIdForDisplay: string
  UnsubscribedBadge: boolean
  duplicationSelectionBadge: boolean
  duplicationBadge: boolean
  recommendedBadge: boolean
  pastApplicationConfirmation: string
  createNewMessage: string
  createNewMessageViewFlag: boolean
  tabMessageViewFlag :boolean
}

/** プロフィールエリア */
export interface Profile {
  [key: string]: any
  profileFacePhoto: string
  profileFacePhotoUpload: string
  profileFacePhotoUploadViewFlag: boolean
  profileFacePhotoUploadButtonDisabledFlag: boolean
  profileFullName: string
  profilePostalCode: string
  profileStreetAddress: string
  profileBirthDateSex: string
  profileSpouse: string
  profileAnnualIncome: string
  profileCompaniesExperience: string
  profileEmploymentStatus: string
  profileMobilePhone: string
  profileFixedLinePhone: string
  profileMobileEmail: string
  profileEmail: string
  profileSchoolInformation: string
  profileEditViewFlag: boolean
  profileWhenApplyingFlag: boolean
  profileWhenApplyingOpenClose: string
  profileWhenApplyingFullName: string
  profileWhenApplyingPostalCode: string
  profileWhenApplyingStreetAddress: string
  profileWhenApplyingBirthDateSex: string
  profileWhenApplyingSpouse: string
  profileWhenApplyingAnnualIncome: string
  profileWhenApplyingCompaniesExperience: string
  profileWhenApplyingEmploymentStatus: string
  profileWhenApplyingMobilePhone: string
  profileWhenApplyingFixedLinePhone: string
  profileWhenApplyingMobileEmail: string
  profileWhenApplyingEmail: string
  profileWhenApplyingSchoolInformation: string
  profileEdit: string
  desiredWorkRegion: string
  desiredSalary: string
  timeToChangeJob: string
}

/** 質問回答エリア */
export interface Question {
  [key: string]: any
  questionNo: number
  questionContent: string
  questionAnswer: string
}

/** 職務経歴エリア */
export interface JobCareer {
  [key: string]: any
  experienceCompanies: string
  experienceOccupation: string
  jobCareerDetail: JobCareerDetail[]
}

/** 職務経歴詳細 */
export interface JobCareerDetail {
  [key: string]: any
  jobCareerNumberOfCompanies: string
  jobCareerCompanyName: string
  jobCareerEnrollmentPeriod: string
  jobCareerContent: string
}

/** 資格・スキルエリア */
export interface QualificationsSkills {
  [key: string]: any
  qualificationsSkillsAvailability: boolean
  qualificationsSkillsLanguage: string
  qualificationsSkillsEligibility: string
}

/** 自己PRなどエリア */
export interface SelfPR {
  [key: string]: any
  selfPRAvailability: boolean
  selfPRMotive: string
  selfPRContent: string
  selfPRRemarks: string
}

/** メッセージエリア */
export interface MessageDataList {
  [key: string]: any
  messageSendId: string
  messageClassification: string
  messageDestination: string
  messageStatus: string
  messageType: string
  messageApplicationType: string
  messageSubject: string
  messageAttachedFile: boolean
  messageSelectionFlow: string
  messageSender: string
  messageDateTime: string
}

/** 適性テスト（イメジス）エリア */
export interface Images {
  [key: string]: any
  viewFlag: boolean
  imagesCheckResultsReport: boolean
  imagesIntelligenceDeviationSynthesis: number | null
  imagesIntelligenceDeviationCounting: number | null
  imagesIntelligenceDeviationLanguage: number | null
  imagesIntelligenceDeviationEnglish: number | null
  imagesIntelligenceStandardCounting: number | null
  imagesIntelligenceStandardLanguage: number | null
  imagesIntelligenceStandardEnglish: number | null
  imagesPersonalityI: number | null
  imagesPersonalityM: number | null
  imagesPersonalityA: number | null
  imagesPersonalityG: number | null
  imagesPersonalityE: number | null
  imagesPersonalityS: number | null
  imagesPersonalityVitality: number | null
  imagesPersonalityInterpersonal: number | null
  imagesPersonalityEstablishment: number | null
  // Phase2 Start
  imagesPersonalityD: number | null
  imagesPersonalityW: number | null
  // Phase2 End
  imagesBirthday: string
  imagesUpdateDate: string
  imagesUpdatePath: string
}

/** 適性テスト（GAB）エリア */
export interface Gab {
  [key: string]: any
  viewFlag: boolean
  gabCheckResultsReport: boolean
  gabTestLanguage: string
  gabExamDateCounting: string
  gabExamDateLanguage: string
  gabExamDateOpq: string
  gabIntelligenceDeviationSynthesis: number | null
  gabIntelligenceDeviationCounting: number | null
  gabIntelligenceDeviationLanguage: number | null
  gabIntelligenceStandardSynthesis: number | null
  gabIntelligenceStandardCounting: number | null
  gabIntelligenceStandardLanguage: number | null
  gabManagementQualities: number | null
  gabAbilityVitality: number | null
  gabAbilityPerPerson: number | null
  gabAbilityTeamwork: number | null
  gabAbilityCreativeThinking: number | null
  gabAbilityProblemSolving: number | null
  gabAbilitySituationalAdaptability: number | null
  gabAbilityPressureResistance: number | null
  gabAbilityOrganizingAbility: number | null
  gabAbilityLeadership: number | null
  gabJobAptitudeSales1: number | null
  gabJobAptitudeSales2: number | null
  gabJobAptitudeStaff: number | null
  gabJobAptitudeGeneralSecretary: number | null
  gabJobAptitudeEngineer: number | null
  gabJobAptitudeResearchDevelopment: number | null
  gabJobAptitudeCustomersCounterCallCenter: number | null
  gabOpqPersuasiveness: number | null
  gabOpqExtrovert: number | null
  gabOpqHumility: number | null
  gabOpqConcreteThings: number | null
  gabOpqHuman: number | null
  gabOpqConceptuality: number | null
  gabOpqElaborate: number | null
  gabOpqAnxiousMind: number | null
  gabOpqOptimistic: number | null
  gabOpqCompetitive: number | null
  gabOpqLeadership: number | null
  gabOpqFriendliness: number | null
  gabOpqConsultativeness: number | null
  gabOpqData: number | null
  gabOpqOrthodox: number | null
  gabOpqCreative: number | null
  gabOpqMeticulous: number | null
  gabOpqTough: number | null
  gabOpqCritical: number | null
  gabOpqAscension: number | null
  gabOpqUniqueness: number | null
  gabOpqSocial: number | null
  gabOpqTrouble: number | null
  gabOpqAestheticValue: number | null
  gabOpqChangeOriented: number | null
  gabOpqPlanning: number | null
  gabOpqMargin: number | null
  gabOpqSuppression: number | null
  gabOpqAbilityTakeAction: number | null
  gabOpqDetermination: number | null
  gabConsistency: number | null
  gabUpdateDate: string
  gabUpdatePath: string
}

/** 適性テスト（OPQ）エリア */
export interface Opq {
  [key: string]: any
  viewFlag: boolean
  opqCheckResultsReport: boolean
  opqExamDate: string
  opqManagementQualities: number | null
  opqAbilityVitality: number | null
  opqAbilityPerPerson: number | null
  opqAbilityTeamwork: number | null
  opqAbilityCreativeThinking: number | null
  opqAbilityProblemSolving: number | null
  opqAbilitySituationalAdaptability: number | null
  opqAbilityPressureResistance: number | null
  opqAbilityOrganizingAbility: number | null
  opqAbilityLeadership: number | null
  opqJobAptitudeSales1: number | null
  opqJobAptitudeSales2: number | null
  opqJobAptitudeStaff: number | null
  opqJobAptitudeGeneralSecretary: number | null
  opqJobAptitudeEngineer: number | null
  opqJobAptitudeResearchDevelopment: number | null
  opqJobAptitudeCustomersCounterCallCenter: number | null
  opqOpqPersuasiveness: number | null
  opqOpqExtrovert: number | null
  opqOpqHumility: number | null
  opqOpqConcreteThings: number | null
  opqOpqHuman: number | null
  opqOpqConceptuality: number | null
  opqOpqElaborate: number | null
  opqOpqAnxiousMind: number | null
  opqOpqOptimistic: number | null
  opqOpqCompetitive: number | null
  opqOpqLeadership: number | null
  opqOpqFriendliness: number | null
  opqOpqConsultativeness: number | null
  opqOpqData: number | null
  opqOpqOrthodox: number | null
  opqOpqCreative: number | null
  opqOpqMeticulous: number | null
  opqOpqTough: number | null
  opqOpqCritical: number | null
  opqOpqAscension: number | null
  opqOpqUniqueness: number | null
  opqOpqSocial: number | null
  opqOpqTrouble: number | null
  opqOpqAestheticValue: number | null
  opqOpqChangeOriented: number | null
  opqOpqPlanning: number | null
  opqOpqMargin: number | null
  opqOpqSuppression: number | null
  opqOpqAbilityTakeAction: number | null
  opqOpqDetermination: number | null
  opqConsistency: number | null
  opqUpdateDate: string
  opqUpdatePath: string
}

/** 適性テスト（CAB）エリア */
export interface Cab {
  [key: string]: any
  viewFlag: boolean
  cabCheckResultsReport: boolean
  cabTestLanguage: string
  cabExamDateCab1: string
  cabExamDateCab2: string
  cabExamDateCab3: string
  cabExamDateCab4: string
  cabExamDateOpq: string
  cabIntelligenceDeviationSynthesis: number | null
  cabIntelligenceDeviationCab1: number | null
  cabIntelligenceDeviationCab2: number | null
  cabIntelligenceDeviationCab3: number | null
  cabIntelligenceDeviationCab4: number | null
  cabIntelligenceStandardSynthesis: number | null
  cabIntelligenceStandardCab1: number | null
  cabIntelligenceStandardCab2: number | null
  cabIntelligenceStandardCab3: number | null
  cabIntelligenceStandardCab4: number | null
  cabComputerAptitudePg: number | null
  cabComputerAptitudeSe: number | null
  cabComputerAptitudeCe: number | null
  cabComputerAptitudePm: number | null
  cabAbilityVitality: number | null
  cabAbilityPerPerson: number | null
  cabAbilityTeamwork: number | null
  cabAbilityCreativeThinking: number | null
  cabAbilityProblemSolving: number | null
  cabAbilitySituationalAdaptability: number | null
  cabAbilityPressureResistance: number | null
  cabAbilityOrganizingAbility: number | null
  cabAbilityLeadership: number | null
  cabOpqPersuasiveness: number | null
  cabOpqExtrovert: number | null
  cabOpqHumility: number | null
  cabOpqConcreteThings: number | null
  cabOpqHuman: number | null
  cabOpqConceptuality: number | null
  cabOpqElaborate: number | null
  cabOpqAnxiousMind: number | null
  cabOpqOptimistic: number | null
  cabOpqCompetitive: number | null
  cabOpqLeadership: number | null
  cabOpqFriendliness: number | null
  cabOpqConsultativeness: number | null
  cabOpqData: number | null
  cabOpqOrthodox: number | null
  cabOpqCreative: number | null
  cabOpqMeticulous: number | null
  cabOpqTough: number | null
  cabOpqCritical: number | null
  cabOpqAscension: number | null
  cabOpqUniqueness: number | null
  cabOpqSocial: number | null
  cabOpqTrouble: number | null
  cabOpqAestheticValue: number | null
  cabOpqChangeOriented: number | null
  cabOpqPlanning: number | null
  cabOpqMargin: number | null
  cabOpqSuppression: number | null
  cabOpqAbilityTakeAction: number | null
  cabOpqDetermination: number | null
  cabConsistency: number | null
  cabUpdateDate: string
  cabUpdatePath: string
}

/** 選考フローエリア */
export const selectionFlowAreaViewFlag: SelectionFlowAreaViewFlag = {
  interviewSchedule: false,
  applicationDateTime: false,
  recommendDate: false,
  selectionFlowChange: false,
  selectionFlowRouteChange: false,
  selectionFlowExclude: false,
  selectionFlowUnExclude: false,
  changePassingStatusSelection: false,
  selectionFlowProgressBar: false,
  selectionFlowProgressUpdateDateTime: false,
  marchLabel: false,
  selectionFlowExtraInfo: false,
  selectionFlowEvaluation: false,
  selectionFlowComment: false,
  entryJobCategoryNameBadge: false,
  applicationDivisionBadge: false,
  scoutBenefits: false,
  tagFunction: false,
  createNotAdopetdNortificationApplicant: false,
  createNotAdopetdNortificationInterview: false,
}

export const selectionFlowArea: SelectionFlowArea = {
  selectionFlowAreaViewFlag: selectionFlowAreaViewFlag,
  selectionFlowDisplayItems: [],
}

/** 共通ヘッダーエリア */
export const commonHeader: CommonHeader = {
  companyName: '',
  loginUserName: '',
  goBack: false,
  goNext: false,
  nameWithLinkFlag: false,
  fullName: '',
  familyName: '',
  name: '',
  fullNameKana: '',
  familyNameKana: '',
  nameKana: '',
  age: '',
  sex: '',
  jobSeekerIdForDisplay: '',
  UnsubscribedBadge: false,
  duplicationSelectionBadge: false,
  duplicationBadge: false,
  recommendedBadge: false,
  pastApplicationConfirmation: '',
  createNewMessage: '',
  createNewMessageViewFlag: false,
  tabMessageViewFlag: false,
}

/** プロフィールエリア */
export const profile: Profile = {
  profileFacePhoto: '',
  profileFacePhotoUpload: '',
  profileFacePhotoUploadViewFlag: false,
  profileFacePhotoUploadButtonDisabledFlag: false,
  profileFullName: '',
  profilePostalCode: '',
  profileStreetAddress: '',
  profileBirthDateSex: '',
  profileSpouse: '',
  profileAnnualIncome: '',
  profileCompaniesExperience: '',
  profileEmploymentStatus: '',
  profileMobilePhone: '',
  profileFixedLinePhone: '',
  profileMobileEmail: '',
  profileEmail: '',
  profileSchoolInformation: '',
  profileEditViewFlag: false,
  profileWhenApplyingFlag: false,
  profileWhenApplyingOpenClose: '',
  profileWhenApplyingFullName: '',
  profileWhenApplyingPostalCode: '',
  profileWhenApplyingStreetAddress: '',
  profileWhenApplyingBirthDateSex: '',
  profileWhenApplyingSpouse: '',
  profileWhenApplyingAnnualIncome: '',
  profileWhenApplyingCompaniesExperience: '',
  profileWhenApplyingEmploymentStatus: '',
  profileWhenApplyingMobilePhone: '',
  profileWhenApplyingFixedLinePhone: '',
  profileWhenApplyingMobileEmail: '',
  profileWhenApplyingEmail: '',
  profileWhenApplyingSchoolInformation: '',
  profileEdit: '',
  desiredWorkRegion: '',
  desiredSalary: '',
  timeToChangeJob: '',
}

/** 質問回答エリア */
export const question: Question = {
  questionNo: 0,
  questionContent: '',
  questionAnswer: '',
}

/** 職務経歴エリア */
export const jobCareer: JobCareer = {
  experienceCompanies: '',
  experienceOccupation: '',
  jobCareerDetail: [],
}
/** 職務経歴詳細 */
export const jobCareerDetail: JobCareerDetail = {
  jobCareerNumberOfCompanies: '',
  jobCareerCompanyName: '',
  jobCareerEnrollmentPeriod: '',
  jobCareerContent: '',
}

/** 資格・スキルエリア */
export const qualificationsSkills: QualificationsSkills = {
  qualificationsSkillsAvailability: false,
  qualificationsSkillsLanguage: '',
  qualificationsSkillsEligibility: '',
}

/** 自己PRなどエリア */
export const selfPR: SelfPR = {
  selfPRAvailability: false,
  selfPRMotive: '',
  selfPRContent: '',
  selfPRRemarks: '',
}


/** 適性テスト（イメジス）エリア */
export const images: Images = {
  viewFlag: false,
  imagesCheckResultsReport: false,
  imagesIntelligenceDeviationSynthesis: null,
  imagesIntelligenceDeviationCounting: null,
  imagesIntelligenceDeviationLanguage: null,
  imagesIntelligenceDeviationEnglish: null,
  imagesIntelligenceStandardCounting: null,
  imagesIntelligenceStandardLanguage: null,
  imagesIntelligenceStandardEnglish: null,
  imagesPersonalityI: null,
  imagesPersonalityM: null,
  imagesPersonalityA: null,
  imagesPersonalityG: null,
  imagesPersonalityE: null,
  imagesPersonalityS: null,
  imagesPersonalityVitality: null,
  imagesPersonalityInterpersonal: null,
  imagesPersonalityEstablishment: null,
  // Phase2 Start
  imagesPersonalityD: null,
  imagesPersonalityW: null,
  // Phase2 End
  imagesBirthday: '',
  imagesUpdateDate: '',
  imagesUpdatePath: '',
}

/** 適性テスト（GAB）エリア */
export const gab: Gab = {
  viewFlag: false,
  gabOpenClose: '',
  gabCheckResultsReport: false,
  gabTestLanguage: '',
  gabExamDateCounting: '',
  gabExamDateLanguage: '',
  gabExamDateOpq: '',
  gabIntelligenceDeviationSynthesis: null,
  gabIntelligenceDeviationCounting: null,
  gabIntelligenceDeviationLanguage: null,
  gabIntelligenceStandardSynthesis: null,
  gabIntelligenceStandardCounting: null,
  gabIntelligenceStandardLanguage: null,
  gabManagementQualities: null,
  gabAbilityVitality: null,
  gabAbilityPerPerson: null,
  gabAbilityTeamwork: null,
  gabAbilityCreativeThinking: null,
  gabAbilityProblemSolving: null,
  gabAbilitySituationalAdaptability: null,
  gabAbilityPressureResistance: null,
  gabAbilityOrganizingAbility: null,
  gabAbilityLeadership: null,
  gabJobAptitudeSales1: null,
  gabJobAptitudeSales2: null,
  gabJobAptitudeStaff: null,
  gabJobAptitudeGeneralSecretary: null,
  gabJobAptitudeEngineer: null,
  gabJobAptitudeResearchDevelopment: null,
  gabJobAptitudeCustomersCounterCallCenter: null,
  gabOpqPersuasiveness: null,
  gabOpqExtrovert: null,
  gabOpqHumility: null,
  gabOpqConcreteThings: null,
  gabOpqHuman: null,
  gabOpqConceptuality: null,
  gabOpqElaborate: null,
  gabOpqAnxiousMind: null,
  gabOpqOptimistic: null,
  gabOpqCompetitive: null,
  gabOpqLeadership: null,
  gabOpqFriendliness: null,
  gabOpqConsultativeness: null,
  gabOpqData: null,
  gabOpqOrthodox: null,
  gabOpqCreative: null,
  gabOpqMeticulous: null,
  gabOpqTough: null,
  gabOpqCritical: null,
  gabOpqAscension: null,
  gabOpqUniqueness: null,
  gabOpqSocial: null,
  gabOpqTrouble: null,
  gabOpqAestheticValue: null,
  gabOpqChangeOriented: null,
  gabOpqPlanning: null,
  gabOpqMargin: null,
  gabOpqSuppression: null,
  gabOpqAbilityTakeAction: null,
  gabOpqDetermination: null,
  gabConsistency: null,
  gabUpdateDate: '',
  gabUpdatePath: '',
}

/** 適性テスト（OPQ）エリア */
export const opq: Opq = {
  viewFlag: false,
  opqOpenClose: '',
  opqCheckResultsReport: false,
  opqExamDate: '',
  opqManagementQualities: null,
  opqAbilityVitality: null,
  opqAbilityPerPerson: null,
  opqAbilityTeamwork: null,
  opqAbilityCreativeThinking: null,
  opqAbilityProblemSolving: null,
  opqAbilitySituationalAdaptability: null,
  opqAbilityPressureResistance: null,
  opqAbilityOrganizingAbility: null,
  opqAbilityLeadership: null,
  opqJobAptitudeSales1: null,
  opqJobAptitudeSales2: null,
  opqJobAptitudeStaff: null,
  opqJobAptitudeGeneralSecretary: null,
  opqJobAptitudeEngineer: null,
  opqJobAptitudeResearchDevelopment: null,
  opqJobAptitudeCustomersCounterCallCenter: null,
  opqOpqPersuasiveness: null,
  opqOpqExtrovert: null,
  opqOpqHumility: null,
  opqOpqConcreteThings: null,
  opqOpqHuman: null,
  opqOpqConceptuality: null,
  opqOpqElaborate: null,
  opqOpqAnxiousMind: null,
  opqOpqOptimistic: null,
  opqOpqCompetitive: null,
  opqOpqLeadership: null,
  opqOpqFriendliness: null,
  opqOpqConsultativeness: null,
  opqOpqData: null,
  opqOpqOrthodox: null,
  opqOpqCreative: null,
  opqOpqMeticulous: null,
  opqOpqTough: null,
  opqOpqCritical: null,
  opqOpqAscension: null,
  opqOpqUniqueness: null,
  opqOpqSocial: null,
  opqOpqTrouble: null,
  opqOpqAestheticValue: null,
  opqOpqChangeOriented: null,
  opqOpqPlanning: null,
  opqOpqMargin: null,
  opqOpqSuppression: null,
  opqOpqAbilityTakeAction: null,
  opqOpqDetermination: null,
  opqConsistency: null,
  opqUpdateDate: '',
  opqUpdatePath: '',
}

/** 適性テスト（CAB）エリア */
export const cab: Cab = {
  viewFlag: false,
  cabOpenClose: '',
  cabCheckResultsReport: false,
  cabTestLanguage: '',
  cabExamDateCab1: '',
  cabExamDateCab2: '',
  cabExamDateCab3: '',
  cabExamDateCab4: '',
  cabExamDateOpq: '',
  cabIntelligenceDeviationSynthesis: null,
  cabIntelligenceDeviationCab1: null,
  cabIntelligenceDeviationCab2: null,
  cabIntelligenceDeviationCab3: null,
  cabIntelligenceDeviationCab4: null,
  cabIntelligenceStandardSynthesis: null,
  cabIntelligenceStandardCab1: null,
  cabIntelligenceStandardCab2: null,
  cabIntelligenceStandardCab3: null,
  cabIntelligenceStandardCab4: null,
  cabComputerAptitudePg: null,
  cabComputerAptitudeSe: null,
  cabComputerAptitudeCe: null,
  cabComputerAptitudePm: null,
  cabAbilityVitality: null,
  cabAbilityPerPerson: null,
  cabAbilityTeamwork: null,
  cabAbilityCreativeThinking: null,
  cabAbilityProblemSolving: null,
  cabAbilitySituationalAdaptability: null,
  cabAbilityPressureResistance: null,
  cabAbilityOrganizingAbility: null,
  cabAbilityLeadership: null,
  cabOpqPersuasiveness: null,
  cabOpqExtrovert: null,
  cabOpqHumility: null,
  cabOpqConcreteThings: null,
  cabOpqHuman: null,
  cabOpqConceptuality: null,
  cabOpqElaborate: null,
  cabOpqAnxiousMind: null,
  cabOpqOptimistic: null,
  cabOpqCompetitive: null,
  cabOpqLeadership: null,
  cabOpqFriendliness: null,
  cabOpqConsultativeness: null,
  cabOpqData: null,
  cabOpqOrthodox: null,
  cabOpqCreative: null,
  cabOpqMeticulous: null,
  cabOpqTough: null,
  cabOpqCritical: null,
  cabOpqAscension: null,
  cabOpqUniqueness: null,
  cabOpqSocial: null,
  cabOpqTrouble: null,
  cabOpqAestheticValue: null,
  cabOpqChangeOriented: null,
  cabOpqPlanning: null,
  cabOpqMargin: null,
  cabOpqSuppression: null,
  cabOpqAbilityTakeAction: null,
  cabOpqDetermination: null,
  cabConsistency: null,
  cabUpdateDate: '',
  cabUpdatePath: '',
}

/** 画面用共通定数定義 */
export const cmnContents = {
  /** - */
  HYPHEN: '-',
  /** '' */
  BLANK: '',
}

export const screenIdContents = {
  /** エントリー詳細：MCAXS050 */
  ENTRY_DETAIL: 'MCAXS050',
  /** 応募者詳細（転職）：MCAXS620 */
  APPLICANT_DETAIL_FOR_JOBCHANGE: 'MCAXS620',
}

export const cmnHeadContents = {
  /** 複数選考 */
  MULTIPLE_SELECTION: '0',
  /** 重複 */
  DUPLICATION: '1',
  /** 過去の応募を確認 */
  PAST_APPLICATION_CONFIRMATION_LABEL:{
    PAST_APPLICATION: '過去の応募を確認', 
    PAST_RECOMMEND: '過去の推薦を確認'
  },
  RECOMMEND_FLAG_ENABLE: '1'
}

/** ワンクリック不採用通知 */
export const notAdoptedNotificationContents = {
  /** 書類選考 */
  APPLICANT_SCREENING: '1',
  /** 面接選考 */
  INTERVIEW: '2',
}

/** プロフィールエリア用定数定義 */
export const profileContents = {
  /** 不明 */
  UNKNOWN: '不明',
}

export const entryHistoryContents = {
  /** 未視聴 */
  NOT_VIEWED: '未視聴',
  /** 視聴中 */
  IN_WATCHING: '視聴中',
  /** 視聴済 */
  WATCHED: '視聴済',
  /** 応募経路追加 */
  APPLICATION_ROUTE_ADDITION: '応募経路追加',
  /** 画面入力 */
  SCREEN_ENTRY: '画面入力',
  /** CSVアップロード */
  CSV_UPLOAD: 'CSVアップロード',
}

/** 適性テストエリア用定数定義 */
export const webContents = {
  /** 手動登録 */
  MANUAL: '手動登録',
  /** 自動登録 */
  AUTOMATIC: '自動登録',
}

export const msgContents = {
  /** [2]企業からのメッセージ(採用担当者から見て送信) */
  DIVISION_SEND: '2',
  /** [1]応募者からのメッセージ(採用担当者から見て受信) */
  DIVISION_RECEIV: '1',

  /** [20]送信予約 */
  STATUS_SEND_RESERVATION: '20',
  /** [30]送信済み */
  STATUS_SEND: '30',

  /** [0]未読 */
  FLAG_READ: '0',
  /** [0]未返信 */
  FLAG_UNREPLIED: '0',

  /** [20]電子メール */
  SEND_TYPE_E_MAIL: '20',

  /** 送信 */
  SENDING: '送信中',
   /** 送信 */
  SEND: '送信',
  /** 送信予約 */
  SEND_RESERVATION: '送信予約',
  /** 受信 */
  RECEIVING: '受信',
  /** 未読 */
  UNREAD: '未読',
  /** 既読 */
  READ: '既読',
  /** 未返信 */
  UNREPLIED: '未返信',
  /** 返信済み */
  REPLIED: '返信済み',
  /** - */
  HYPHEN: '-',
  /** e-mail */
  E_MAIL: 'e-mail',
  MESSAGETYPE_02: 'サンクス',
  MESSAGETYOE_20: 'スカウト',
  STRING_0: '0'
}

/** march用定数定義 */
export const marchContents = {
  // Marchモードフラグ 「0:Marchモード以外でのログイン」「1:Marchモードでのログイン」
  MARCH_ENABLED_FLAG_OFF: '0',
  MARCH_ENABLED_FLAG_ON: '1',
  // March契約企業フラグ 「0：無効」「1：有効」
  MARCH_RESPONSIBLE_COMPANY_FLAG_OFF: '0',
  MARCH_RESPONSIBLE_COMPANY_FLAG_ON: '1',
}