import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getToken, setToken, removeToken } from 'utils/auth'
import { redirect } from 'common/businessUtil'

const initialState = () => ({
  name: '',
  loginId: '',
  companyId: '',
  recruitmentManagementDivision: '',
  companyAccountId:'',
  permissions: [] as string[],
  token: getToken(),
  termsOfUseAgreementTime:'',
  sysVersionNumber:0,
  selectToken:'',
  url:'',
  jumpState:true as boolean,
})
const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    login: (state, _: PayloadAction<{ loginId: string; password: string }>) =>
      state,
    loginSuccess: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token
      setToken(action.payload.token)
    },
    getUserInfo: state => state,
    setUserInfo: (
      state,
      action: PayloadAction<{
        loginId: string
        companyId: string
        recruitmentManagementDivision: string
        name: string
        permissions: string[]
      }>
    ) => ({
      ...state,
      ...action.payload,
    }),
    setInitHiddenInfo(state, action: PayloadAction<any>) {
      state.termsOfUseAgreementTime = action.payload.termsOfUseAgreementTime
      state.sysVersionNumber = action.payload.sysVersionNumber
      state.loginId = action.payload.loginId
      return state;
    },
    setSysVersionNumber(state, action: PayloadAction<any>) {
      state.sysVersionNumber=action.payload
      return state;
    },
    signout: state => state,
    signoutSuccess: () => {
      removeToken()
      return initialState()
    },

    initDate(state, action: PayloadAction<{companyAccountId:string}>) {
      return state;
    },
    agreeUpdate(state, action: PayloadAction<{sysVersionNumber: number ;companyAccountId:string}>) {
      return state;
    },
    setComAccIdAndLoginId(state, action: PayloadAction<{companyAccountId: string;url: string}>) {
      state.companyAccountId=action.payload.companyAccountId;
      state.url=action.payload.url;
    },
    updatePassword: (
      state,
      action: PayloadAction<{
        companyAccountId: string
        currentPassword: string
        newPassword: string
        newPasswordConfirm:string
        sysVersionNumber:number
        loginId:string
      }>
    ) => state,
    temUpdatePassword: (
      state,
      action: PayloadAction<{
        companyAccountId: string
        currentPassword: string
        newPassword: string
        newPasswordConfirm:string
        sysVersionNumber:number
        loginId:string
      }>
    ) => state,
    agreeInit(state, action: PayloadAction<{companyAccountId: string}>) {
      return state;
    },
  },
})

const { actions, reducer } = userSlice

export const {
  login,
  loginSuccess,
  signout,
  signoutSuccess,
  getUserInfo,
  setUserInfo,
  updatePassword,
  temUpdatePassword,
  initDate,
  setInitHiddenInfo,
  agreeInit,
  setSysVersionNumber,
  agreeUpdate,
  setComAccIdAndLoginId,
} = actions
export default reducer
