// 検索画面初期化
const upditValues = {
  tagSettingId: '',
  tagName: '',
  versionNumberTapr: '',
  versionNumberTuif: '',
  versionNumberCact: '',
  recruitmentManagementDivision: '',
} ;
export { upditValues};
