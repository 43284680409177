import {
  Button,
  colors,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { FieldGroup, SubTitle } from 'componentsHsc'
import React, { useEffect, useState } from 'react'
import { FormControl } from '..'
import { RootState } from 'reducers'
import { useSelector, useDispatch } from 'react-redux'
import { getMcaxs481Init, pushOperationLogFor88924, pushSeminarAfterScheduleHiddenCheckBox } from 'reducers/advancedSearchModalReducer'
import { Seminar, SeminarLevel2, SeminarLevel3 } from 'componentsHsc/AdvancedSearch/Config/seminarDateModalConfig'
import { dateToString, dateTimeToString, isEmpty, now as systemTime, stringToDateTime } from 'common/generalUtil'
import { magiContants as contants, magiContants } from 'utils/contants'
import {
  SeminarReceptionObj,
  SeminarReceptionWhichOneObj,
  SeminarReceptionIndividualObj,
  SeminarReceptionIndividualObj2,
} from '../Config/searchConditionConfig'
import CustomScrollbar from 'react-custom-scrollbars'
import { openModal } from 'reducers/messageReducer'
import { now } from 'moment'
import moment from 'moment'
// 26P #80786 start
import { Tooltip as MuiTooptip, IconButton } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import momentTz from 'moment-timezone'
import { MCAXS481SeminarAfterScheduleHiddenCheckBoxRequest } from 'types/MCAXS481/MCAXS481SeminarAfterScheduleHiddenCheckBoxRequest'
// 26P #80786 end

const useStyles = makeStyles(theme => ({
    actions: {
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    dialogTitle: {
        color: (theme.palette as any).white,
        backgroundColor: '#133e80',
        '&:hover': {
            backgroundColor: '#133e80',
        },
    },
    title: {
        color: (theme.palette as any).white,
        fontWeight: 900,
        fontSize: 18,
        cursor:'auto',
    },
    select: {
        display: 'flex',
        '& > :first-child': {
            flex: 1,
        },
        '& > :last-child': {
            flex: 3,
        },
        '& span.MuiFormControlLabel-label': {
            color: colors.grey[600],
        },
        height: 'auto'
    },
    content: {
        margin: theme.spacing(1, 2),
        '& div': {
            display: 'inline-block',
            margin: '0px 20px 0px 0px',
            cursor: 'pointer',
        },
        '& div input': {
            marginRight: '5px',
            cursor: 'pointer',
        }
    },
    selectAll: {
        // 26P #80786 start
        alignSelf: 'flex-end',
        // 26P #80786 end
        '& div': {
            display: 'inline-block',
            // 26P #80786 start
            margin: '0px 20px 0px 0px',
            // 26P #80786 end
            cursor: 'pointer',
        },
        '& div input': {
            marginRight: '5px',
            cursor: 'pointer',
        },
        // 26P #80786 start
        '& label': {
            marginTop: '0px',
        }
        // 26P #80786 end
    },
    content2: {
        margin: theme.spacing(1, 2),
     },
    cancel: {
        color: (theme.palette as any).black,
        backgroundColor: '#e0e0e0',
        '&:hover': {
            backgroundColor: '#c1d1eb',
        },
        width: 130,
        marginRight: '20px',
    },
    confirmButton: {
        color: (theme.palette as any).white,
        backgroundColor: '#3380cc',
        '&:hover': {
            backgroundColor: '#23598e',
        },
        width: 130,
        marginLeft: '20px !important',
    },
    dividerColor: {
        backgroundColor: colors.grey[600],
    },
    subTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labelTitle: {
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        cursor:'auto',
    },
    subContent: {
        margin: theme.spacing(2),
        '& .Mui-selected': {
            backgroundColor: 'transparent !important',
            color: '#1950a6',
            textDecoration: 'underline',
            '& span': {
                color: '#1950a6',
            },
        },
        height: '100%',
    },
    divider: {
        margin: theme.spacing(1, 0),
        backgroundColor: 'lightgrey',
    },
    seminarName: {
        wordBreak:'break-all',
        wordWrap:'break-word',
        // 26P #80786 start
        '& > div:first-child': {
            marginBottom: '0px !important',
            overflowX: 'hidden !important',
            maxHeight: '500px !important',
        }
        // 26P #80786 end
    },
    seminarName2: {
        '& > div > :first-child':{
            overflowX: 'hidden !important',
        }
    },
    selectAllOfForm: {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(1),
        '& div': {
            display: 'inline-block',
            margin: '0px 20px 0px 0px',
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: '14px',
        },
        '& div input': {
            marginRight: '5px',
            cursor: 'pointer',
        }
    },
    containerOfForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    subtitleOfForm: {
        marginTop: theme.spacing(1),
        alignSelf: 'flex-start',
    },
    // 26P #80786 start
    checkBoxLabel: {
        textAlign: 'right',
        marginTop: '-2%',
        width: '224px',
        marginLeft: 'auto'
    },
    button: {
        padding: 0,
        marginTop: '-10px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
    },
    mark: {
        height: '16px !important',
        width: '16px !important',
        marginBottom: '2px !important',
    },
    // 26P #80786 end
    // 26P #80786 start
    seminarName3 : {
        '& > ul' : {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
        },
        '& > ul > div' : {
            paddingTop: '0px !important',
            paddingBottom: '0px !important'
        },
        '& > ul > div > div' : {
            marginTop: '2px !important',
            marginBottom: '2px !important'
        },
    }
    // 26P #80786 end
}))

const searchOptionList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

const optionList = [
  { label: '予約あり', value: '1' },
  { label: '予約なし', value: '0' },
  { label: 'キャンセル', value: '2' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

interface RdoSeleDate {
    [key: string]: string
    dateId: string
    rdoVal: string
}

interface SeleDateOfForm {
    [key: string]: string | any
    // 26P #80786 start
    id: string
    // 26P #80786 end
    value: string
    coordinate: {
        index1: number
        index2: number
    }
}

const SeminarDateModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirm, setConfirm] = useState(false)

    const [rdoDateSelect, setRdoDateSelect] = useState<RdoSeleDate[]>([]) // 「各受付ごと」の受付状況チェック
    const [seminarSelect, setSeminarSelect] = useState('') // 「いずれかの日程」と「各受付ごと」のラジオボタン
    const [whichOne, setWhichOne] = useState('') // 「いずれかの日程」の受付状況チェック
    const [individual, setIndividual] = useState('0') // 「各受付ごと」の日付間の検索方法 ANR/OR
    const [selectedSub, setSelectedSub] = useState('') // 説明会・面接名リストクリック時に画面のスクロール
    const [selectAllFlag, setSelectAllFlag] = useState('') // 「各受付ごと」の一括チェック
    const [selectAllFlagOfForm, setSelectAllFlagOfForm] = useState<SeleDateOfForm[]>([]) // フォームIDごとに一括チェック
    const [/* state */, setState] = useState();
    // 26P #80786 start
    // 「開催後の日程を非表示にする」のチェックフラグ
    const [checkBoxFlag, setCheckBoxFlag] = useState(false as boolean)
    // 表示用説明会・面接の予約状況
    const [seminarListTemp, setSeminarListTemp] = useState([] as Seminar[])
    // すでに選択されているセミナー日程（過去）
    const [selectedSeminarSchList, setSelectedSeminarSchList] = useState([] as SeminarReceptionIndividualObj2[])
    // 初期画面表示値（応募一覧に既に選択値が存在し、modelでsubmitの場合、選択値が非表示になっている場合は、最終submitされた値に追加）
    const [invisibleSeminarSch, setInvisibleSeminarSch] = useState([] as SeminarReceptionIndividualObj2[])
    // 初期画面表示フラグ（「開催後の日程を非表示にする」のチェックを変更していないの場合）
    const [initFlag, setInitFlag] = useState(false as boolean)
    // 26P #80786 end

    const handleSubClick = (index1: number, index2: number) => () => {
        setSelectedSub(index1 + '_' + index2)
        const toTop = document.getElementById(`sel_${index1}_${index2}`)
        if (toTop) {
            toTop.scrollIntoView()
        }
    }

    const getSeminarListLengthForLog = () => {
        // undefinedなら初期状態
        if(seminarList !== undefined){
            return `${seminarList.length}`
        }
        // 0ならセミナー無、0以外ならセミナーあり
        else{
            return "undefined"
        }
    }
    useEffect(() => {
        const log = {
            location:"useEffect_before_request",
            open: open,
            storedTime: moment(new Date()).format('MM/DD HH:mm:ss'),
            seminarListLength: getSeminarListLengthForLog(),
        }
        dispatch(pushOperationLogFor88924(JSON.stringify(log)))
        dispatch(getMcaxs481Init())
    },[open])

    useEffect(() => {
        if (open) {
            setSelectedSub('')
            if (form.values.seminarReceptionObj) {
                // 26P #80786 start
                let seminarListFilter: Seminar[] = []
                // 画面に保持されているセミナー日程がなく、ユーザが開催日後のセミナー日程を非表示に設定した場合
                if (afterScheduleSeminarFlag === "1") {
                    // 「開催後の日程を非表示にする」のチェックフラグが「true」を設定する
                    setCheckBoxFlag(true)
                    // 開催後日程filter
                    seminarListFilter = selectSeminarAfterSchedule()
                } else {
                    //　画面に保持されているセミナー日程がなく、ユーザが開催日後のセミナー日程を設定しない場合
                    // 「開催後の日程を非表示にする」のチェックフラグが「false」を設定する
                    setCheckBoxFlag(false)
                    //　表示用説明会・面接の予約状況が「seminarList（全て説明会・面接の予約状況）」を設定する
                    setSeminarListTemp(seminarList)
                }
                // 26P #80786 end
                setSeminarSelect(
                    form.values.seminarReceptionObj.seminarReceptionConditionJudgmentFlag.toString()
                )
                if (
                    form.values.seminarReceptionObj.seminarReceptionConditionJudgmentFlag.toString() ===
                    '0'
                ) {
                    setRdoDateSelect([])
                    setSelectAllFlag('')
                    if (form.values.seminarReceptionObj.seminarReceptionWhichOneObj) {
                        let whichoneObj =
                            form.values.seminarReceptionObj.seminarReceptionWhichOneObj
                                .seminarReceptionWhichOneFlag !== null
                                ? form.values.seminarReceptionObj.seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag.toString()
                                : ''
                        setWhichOne(whichoneObj)
                    }
                } else if (
                    form.values.seminarReceptionObj.seminarReceptionConditionJudgmentFlag.toString() ===
                    '1'
                ) {
                    setWhichOne('')
                    if (form.values.seminarReceptionObj.seminarReceptionIndividualObj) {
                        const individualObj =
                            form.values.seminarReceptionObj.seminarReceptionIndividualObj
                        let individualFlg =
                            individualObj.seminarReceptionIndividualJudgmentFlag !== null
                                ? individualObj.seminarReceptionIndividualJudgmentFlag.toString()
                                : ''
                        setIndividual(individualFlg)
                        if (individualObj.seminarReceptionIndividualObj2) {
                            let flglst2: RdoSeleDate[] = []
                            const rdoValSortList: string[] = []
                            const individualObj2 =
                                individualObj.seminarReceptionIndividualObj2
                            let seminarCount : number = 0
                            // 26P #80786 start
                            // 初期画面表示設定値
                            setInitFlag(true)
                            // 26P #80786 end
                            
                            log80680(seminarList,"useEffect_seminarList") && seminarList.map(item => {
                                log80680(item.seminarLevel2Dto,"useEffect_item.seminarLevel2Dto") && item.seminarLevel2Dto.map(item1 => {
                                    log80680(item1.seminarLevel3Dto,"useEffect_item1.seminarLevel3Dto") && item1.seminarLevel3Dto.map(item2 => {
                                        seminarCount++;
                                        individualObj2.map(info => {
                                            if (item2.seminarScheduleId.toString() === info.seminarReceptionIndividualSeminarScheduleId.toString()) {
                                                flglst2.push({
                                                    dateId: info.seminarReceptionIndividualSeminarScheduleId,
                                                    rdoVal: info.seminarReceptionIndividualFlag.toString()
                                                })
                                                rdoValSortList.push(info.seminarReceptionIndividualFlag.toString())
                                            }
                                        })
                                    })
                                })
                            })
                            // 26P #80786 start
                            if (afterScheduleSeminarFlag === '1') {
                                let allFilterSeminarSchId: string[] = []
                                let newIndividualObj2: SeminarReceptionIndividualObj2[] = []
                                seminarListFilter.map(item => {
                                    item.seminarLevel2Dto.map(item1 => {
                                        item1.seminarLevel3Dto.map(item2 => {
                                            allFilterSeminarSchId.push(item2.seminarScheduleId.toString())
                                        })
                                    })
                                })
                                individualObj2.map(item => {
                                    if (allFilterSeminarSchId.indexOf(item.seminarReceptionIndividualSeminarScheduleId.toString()) == -1) {
                                        newIndividualObj2.push(item)
                                    }
                                })
                                setInvisibleSeminarSch(newIndividualObj2)
                            }
                            // 26P #80786 end
                            
                            rdoValSortList.sort()
                            // 26P #80786 start
                            // 開催日後のセミナー日程を非表示に設定した場合、一括操作ラジオボタン再設定
                            if (afterScheduleSeminarFlag === '1') {
                                // filter後の全てセミナー日程ID
                                let futureSeminarSchId: string[] = []
                                // filter後のセミナー情報件数
                                let futureSeminarCount : number = 0
                                // filter後の全てセミナー日程の受付状況ラジオボタン（各受付ごと）値
                                const newRdoValSortList: string[] = []
                                // filter後のセミナー情報を取得する
                                let seminarListFilter = selectSeminarAfterSchedule()
                                seminarListFilter.map((x) => {
                                    x.seminarLevel2Dto.map((y) => {
                                        y.seminarLevel3Dto.map((z) => {
                                            futureSeminarCount++
                                            futureSeminarSchId.push(z.seminarScheduleId.toString())
                                            
                                        })
                                    })
                                })
                                // すでに選択されているセミナー日程で、フィルタをフィルタしたセミナー日程
                                flglst2.map((a) => {
                                    if (futureSeminarSchId.indexOf(a.dateId.toString()) != -1) {
                                        newRdoValSortList.push(a.rdoVal)
                                    }
                                })
                                newRdoValSortList.sort()
                                if(newRdoValSortList[0] === newRdoValSortList[newRdoValSortList.length - 1] && newRdoValSortList.length == futureSeminarCount) {
                                    setSelectAllFlag(newRdoValSortList[0])
                                } else {
                                    setSelectAllFlag('')
                                }
                            } else {
                            // 26P #80786 end
                                if(rdoValSortList[0] === rdoValSortList[rdoValSortList.length-1] && rdoValSortList.length == seminarCount){
                                    setSelectAllFlag(rdoValSortList[0])
                                }else {
                                    setSelectAllFlag('')
                                }
                            }
                            setRdoDateSelect(flglst2)
                        }
                    }
                }
            }else {
                // 26P #80786 start
                //　画面に保持されているセミナー日程がなく、ユーザが開催日後のセミナー日程を非表示に設定した場合
                if (afterScheduleSeminarFlag === '1') {
                    // 開催後日程filter
                    selectSeminarAfterSchedule()
                    // 「開催後の日程を非表示にする」のチェックフラグが「true」を設定する
                    setCheckBoxFlag(true)
                } else {
                    //　画面に保持されているセミナー日程がなく、ユーザが開催日後のセミナー日程を設定しない場合
                    //　表示用説明会・面接の予約状況が「seminarList（全て説明会・面接の予約状況）」を設定する
                    setSeminarListTemp(seminarList)
                    // 「開催後の日程を非表示にする」のチェックフラグが「false」を設定する
                    setCheckBoxFlag(false)
                }
                // 26P #80786 end
                setSeminarSelect('')
                setWhichOne('')
                setIndividual('0')
                setSelectAllFlag('')
                setRdoDateSelect([])
                setSelectAllFlagOfForm([])
            }
        }
    }, [open])

  const handleClick = (open = true) => () => {
    setConfirm(open)
  }

  const handleClose = () => {
    setConfirm(false)
    onClose()
  }

  // 26P #80786 start
  const { seminarList, afterScheduleSeminarFlag } = useSelector(
    (state: RootState) => state.advancedSearchModal.seminarDateModalInfo
  )
  // 26P #80786 end

  const { operationLogFor88924 } = useSelector(
    (state: RootState) => state.advancedSearchModal
  )
  const propsInfo = {
    open: open, 
}

  const useStateInfo = {
    confirm: confirm,
    rdoDateSelect: rdoDateSelect.length, 
    seminarSelect: seminarSelect,
    whichOne: whichOne, 
    individual: individual,
    selectedSub: selectedSub,
    selectAllFlag: selectAllFlag,
    selectAllFlagOfForm: selectAllFlagOfForm.length,
  }

  const useSelectorInfo = {
    seminarList:
      seminarList === undefined
        ? {}
        : seminarList.map((item, i) => {
            if (item.seminarLevel2Dto === undefined) {
              return {
                seminarListIndex: i,
                contractMediaId: item.contractMediaId,
                contractMediaName: item.contractMediaName,
              }
            } else {
              return {
                seminarListIndex: i,
                contractMediaId: item.contractMediaId,
                contractMediaName: item.contractMediaName,
                seminarLevel2Dto: item.seminarLevel2Dto
                  .filter(item1 => item1.seminarLevel3Dto === undefined)
                  .map((item1, j) => {
                    return {
                      seminarLevel2DtoIndex: j,
                      coordinationFormerSeminarId:
                        item1.coordinationFormerSeminarId, // 連携元セミナーID
                      seminarId: item1.seminarId, // セミナーID
                      seminarName: item1.seminarName, // セミナー名
                      publicFlag: item1.publicFlag, // 公開フラグ
                      publicName: item1.publicName, // 公開フラグ名
                    }
                  }),
              }
            }
          }),
  }

  const log80680 = (item: any, location: string) => {
    if (item === undefined) {
      const latestLog = {
          location:"log80680",
          open: open,
          storedTime: moment(new Date()).format('MM/DD HH:mm:ss'),
          seminarListLength: getSeminarListLengthForLog(),
      }
      let newOperationLogFor88924: string[] = [...operationLogFor88924]
      newOperationLogFor88924.push(JSON.stringify(latestLog))
      const log = {
        title: '#80680_調査用ログ_MCAXS481_SeminarDateModal',
        location: location,
        propsInfo: propsInfo,
        useStateInfo: useStateInfo,
        useSelectorInfo: useSelectorInfo,
        operationLogFor88924: newOperationLogFor88924,
      }
      setState(()=>{
        //error boundaryがキャッチしないエラー用
        throw new Error(JSON.stringify(log))
      })
      throw new Error(JSON.stringify(log))
    }
    return true
  }

  // 26P #80786 start
  const handleSubmit = async () => {
  // 26P #80786 end
    let seminarReceptionObj: SeminarReceptionObj = {
      seminarReceptionConditionJudgmentFlag: 1,
      seminarReceptionConditionJudgmentName: '',
      seminarReceptionWhichOneObj: null,
      seminarReceptionIndividualObj: null,
    }

        var searchValue: any = document.getElementsByName('selectSearch')
        if (searchValue) {
            if (searchValue[0].checked) {
                seminarReceptionObj.seminarReceptionConditionJudgmentFlag = 0
                seminarReceptionObj.seminarReceptionConditionJudgmentName =
                    'いずれかの日程'
                let seminarReceptionWhichOneObj: SeminarReceptionWhichOneObj = {
                    seminarReceptionWhichOneFlag: 0,
                    seminarReceptionWhichOneName: '',
                }
                var searchValue1: any = document.getElementsByName('selectSearch1')
                if (searchValue1) {
                    if (searchValue1[0].checked) {
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag = 0
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneName =
                            '予約あり'
                    } else if (searchValue1[1].checked) {
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag = 1
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneName =
                            '予約なし'
                    } else if (searchValue1[2].checked) {
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag = 2
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneName =
                            'キャンセル'
                    } else if (searchValue1[3].checked) {
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag = 3
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneName =
                            '採用担当による手動予約'
                    }
                    if(seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag == 0 
                        && seminarReceptionWhichOneObj.seminarReceptionWhichOneName == ''){
                        dispatch(openModal(contants.MESSAGECODE_MCAXS481_002))
                        return
                    }
                    seminarReceptionObj.seminarReceptionWhichOneObj = seminarReceptionWhichOneObj
                }
            } else if (searchValue[1].checked) {
                seminarReceptionObj.seminarReceptionConditionJudgmentFlag = 1
                seminarReceptionObj.seminarReceptionConditionJudgmentName = '各受付ごと'
                var searchValue2: any = document.getElementsByName('selectSearch2')
                if (searchValue2) {
                    let seminarReceptionIndividualObj: SeminarReceptionIndividualObj = {
                        seminarReceptionIndividualJudgmentFlag: 0,
                        seminarReceptionIndividualJudgmentName: '',
                        seminarReceptionIndividualObj2: [],
                    }
                    if (searchValue2[0].checked) {
                        seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentFlag = 0
                        seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentName =
                            'AND'
                    } else if (searchValue2[1].checked) {
                        seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentFlag = 1
                        seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentName =
                            'OR'
                    }
                    
                    // 26P #80786 start
                    seminarListTemp.map((item, index) => {
                    // 26P #80786 end
                        item.seminarLevel2Dto.map((item1, index1) => {
                            item1.seminarLevel3Dto.map((item2, index2) => {
                                var searchValue3: any = document.getElementsByName(
                                    `selectSearch3[${index}_${index1}_${index2}]`
                                )
                                if (searchValue3) {
                                    //開始時刻、終了時刻の文字列作成
                                    let startTime = dateTimeToString(
                                        item2.startTime
                                    )
                                    let endTime = dateTimeToString(item2.endTime)
                                    if (startTime) {
                                        startTime = startTime.substring(11)
                                    }
                                    if (endTime) {
                                        endTime = endTime.substring(11)
                                    }

                                    let seminarReceptionIndividualObj2: SeminarReceptionIndividualObj2 = {
                                        seminarReceptionIndividualApplicationRouteId: item2.contractMediaId,
                                        seminarReceptionIndividualApplicationRouteName: item2.contractMediaName,
                                        seminarReceptionIndividualCoordinationFormerSeminarId: item2.coordinationFormerSeminarId,
                                        seminarReceptionIndividualSeminarId: item2.seminarId,
                                        seminarReceptionIndividualSeminarName: item2.seminarName,
                                        seminarReceptionIndividualPublicFlag: item2.publicFlag,
                                        seminarReceptionIndividualPublicName: item2.publicName,
                                        seminarReceptionIndividualSeminarScheduleId: item2.seminarScheduleId,
                                        seminarReceptionIndividualSeminarScheduleTitle: item2.seminarScheduleTitle,
                                        seminarReceptionIndividualInvisibleFlag: item2.invisibleFlag,
                                        seminarReceptionIndividualInvisibleName: item2.invisibleName,
                                        seminarReceptionIndividualEventDate: item2.eventDate ? dateToString(item2.eventDate) : '',
                                        seminarReceptionIndividualStartTime: startTime ? startTime : '',
                                        seminarReceptionIndividualEndTime: endTime ? endTime : '',
                                        seminarReceptionIndividualVenueLocationCode: item2.venueLocationCode,
                                        seminarReceptionIndividualVenueLocationName: item2.venueLocationName,
                                        seminarReceptionIndividualFlag: 0,
                                        seminarReceptionIndividualName: '',
                                        seminarReceptionIndividualEventDateText: item2.eventDateText,
                                    }

                                    if (searchValue3[0].checked) {
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 0
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                            '予約あり'
                                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                            seminarReceptionIndividualObj2
                                        )
                                    } else if (searchValue3[1].checked) {
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 1
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                            '予約なし'
                                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                            seminarReceptionIndividualObj2
                                        )
                                    } else if (searchValue3[2].checked) {
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 2
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                            'キャンセル'
                                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                            seminarReceptionIndividualObj2
                                        )
                                    } else if (searchValue3[3].checked) {
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 3
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                            '採用担当による手動予約'
                                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                            seminarReceptionIndividualObj2
                                        )
                                    }
                                }
                            })
                        })
                    })
                    // 26P #80786 start
                    // 「開催後の日程を非表示にする」のチェックフラグが「true」　かつ　すでに選択されているセミナー日程（過去）はnullではない　の場合
                    if (!initFlag && checkBoxFlag && selectedSeminarSchList.length > 0) {
                        // すでに選択されているセミナー日程（過去）繰り返し
                        selectedSeminarSchList.map((item) => {
                            // 選択したセミナー日程に選択した過去のセミナー日程を追加する
                            seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                item
                            )
                        })
                        // 元の順序でソート
                        var sortSeminarReceptionIndividualObj2: SeminarReceptionIndividualObj2[] = []
                        seminarList.map((i) => {
                            i.seminarLevel2Dto.map((j) => {
                                j.seminarLevel3Dto.map((k) => {
                                    seminarReceptionIndividualObj.seminarReceptionIndividualObj2.map((l) => {
                                        if (l.seminarReceptionIndividualSeminarScheduleId == k.seminarScheduleId) {
                                            sortSeminarReceptionIndividualObj2.push(l)
                                        }
                                    })
                                })
                            })
                        })
                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2 = sortSeminarReceptionIndividualObj2
                    }
                    // 画面初期に値がある場合、かつ「開催後の日程を非表示にする」のチェックを変更していない
                    if (checkBoxFlag && initFlag && invisibleSeminarSch.length > 0) {
                        invisibleSeminarSch.map(item => {
                            seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(item)
                        })
                        // 元の順序でソート
                        var sortSeminarReceptionIndividualObj2: SeminarReceptionIndividualObj2[] = []
                        seminarList.map((i) => {
                            i.seminarLevel2Dto.map((j) => {
                                j.seminarLevel3Dto.map((k) => {
                                    seminarReceptionIndividualObj.seminarReceptionIndividualObj2.map((l) => {
                                        if (l.seminarReceptionIndividualSeminarScheduleId == k.seminarScheduleId) {
                                            sortSeminarReceptionIndividualObj2.push(l)
                                        }
                                    })
                                })
                            })
                        })
                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2 = sortSeminarReceptionIndividualObj2
                    }
                    // 26P #80786 end
                    if(seminarReceptionIndividualObj.seminarReceptionIndividualObj2.length == 0){
                        dispatch(openModal(contants.MESSAGECODE_MCAXS481_002))
                        return
                    }
                    seminarReceptionObj.seminarReceptionIndividualObj = seminarReceptionIndividualObj
                }
            } else {
                dispatch(openModal(contants.MESSAGECODE_MCAXS481_002))
                return
            }
        } 
    if (
      seminarReceptionObj.seminarReceptionIndividualObj !== null ||
      seminarReceptionObj.seminarReceptionWhichOneObj !== null
    ) {
      form.setFieldValue('seminarReceptionObj', seminarReceptionObj)
    }
    // 26P #80786 start
    // ※ここで画面上にすでに値がある場合を解決するために、さらにmodelを開くと、checkboxはまだ更新されていない値を取得します、awaitを利用します。
    let request: MCAXS481SeminarAfterScheduleHiddenCheckBoxRequest = {
        seminarAfterScheduleHiddenCheckBox: checkBoxFlag? '1' : '0'
    }
    // 企業アカウントテーブルで開催後セミナー非表示フラグを持たせる※開催後セミナー非表示フラグ「０　：　表示」、「１　：　非表示」を保持させ
    dispatch(pushSeminarAfterScheduleHiddenCheckBox({param1:request, handleClose: handleClose}))
    // handleClose()
    // 26P #80786 end
    // TODO: formik
  }

    const createSeminarName = (seminar: SeminarLevel2) => {
        let result = seminar.coordinationFormerSeminarId + ' ' + seminar.seminarName
        if (seminar.publicFlag === '0') {
            result += ' 【' + seminar.publicName + '】'
        }
        return result
    }

    const createSeminarScheduleName = (seminar: SeminarLevel3) => {
        // [phase2] 連携された対象セミナー情報の開催日がNULL(開催日テキストの指定)の場合に、開催日の代わりに開催日テキストの内容を表示する start
        let result = ''
        let eventDate = dateToString(seminar.eventDate)
        result += seminar.seminarScheduleTitle
        if (seminar.invisibleFlag === '1') {
        result += ' 【' + seminar.invisibleName + '】'
        }
        if(isEmpty(eventDate)){
        result += !isEmpty(seminar.eventDateText) ? ' ' + seminar.eventDateText : ''
        }else{
        result += ' ' + dateToString(seminar.eventDate)
        let startTime = dateTimeToString(seminar.startTime)
        let endTime = dateTimeToString(seminar.endTime)
        if (startTime || endTime) {
            result += ' '
            if (startTime) {
            result += startTime.substring(11)
            }
            result += '～'
            if (endTime) {
            result += endTime.substring(11)
            }
        }
        }
        result += ' ' + seminar.venueLocationName
        return result
        // [phase2] end
    }

    const handleSelectAll = (e: string) => {
        const selectValue = e === selectAllFlag ? '' : e
        let tmpval2: RdoSeleDate[] = []
        // 26P #80786 start
        let seminarScheduleId: string[] = []
        seminarListTemp.map(item => {
        // 26P #80786 end    
            item.seminarLevel2Dto.map(item1 => {
                item1.seminarLevel3Dto.map(item2 => {
                    tmpval2.push({ dateId: item2.seminarScheduleId, rdoVal: selectValue })
                    // 26P #80786 start
                    seminarScheduleId.push(item2.seminarScheduleId)
                    // 26P #80786 end
                })
            })
        })
        // 26P #80786 start
        // 「開催後の日程を非表示にする」チェックボックスかONの場合、現在画面に存在するセミナー日程の選択のみを変更し、その他のセミナー日程選択は保留する
        //　データソースがフィルタリングされたセミナー情報であることを解決するために、tmpval2にはすべてのセミナー情報が存在せず、他のすでに選択されているセミナー情報が存在しない
        if (checkBoxFlag) {
            rdoDateSelect.map((x) => {
                // 選択したセミナー日程にフィルタリングされたセミナー日程が含まれている場合
                if (seminarScheduleId.indexOf(x.dateId) == -1) {
                    tmpval2.push(x)
                }
            })
        }
        // 26P #80786 end
        setSelectAllFlag(selectValue)
        setRdoDateSelect(tmpval2)
        // 次期9月対応 #63457 start 
        // 「各受付ごと」選択する
        setSeminarSelect('1')
        //「いずれかの日程」の受付状況チェック：選択しない
        setWhichOne('')
        individual ? setIndividual(individual) : setIndividual('0')
        // 次期9月対応 #63457 end 
    }

    const handleSelectAll2 = async (e: string) => {
        await handleSelectAll(e)
        let tempDataOfForm = [...selectAllFlagOfForm]
        // 26P #80786 start
        seminarListTemp.map((item: any, index1: number) => {
        // 26P #80786 end
            item.seminarLevel2Dto.map((item1: any, index2: number) => {
                let doms = document.getElementsByName(`selectSearch5[${index1.toString()}_${index2.toString()}]`)
                let tempValue = ''
                if (doms) {
                    for (let i = 0; i < doms.length; i++) {
                        if ((doms[i] as any).checked) {
                            tempValue = i.toString()
                        }
                    }
                    let tempIndex = tempDataOfForm.findIndex(item2 => item2.coordinate.index1 == index1 && item2.coordinate.index2 == index2)
                    if (tempIndex != -1) {
                        tempDataOfForm[tempIndex].value = tempValue
                    } else {
                        // 26P #80786 start
                        tempDataOfForm.push({id:item1.seminarId ,value:tempValue,coordinate:{index1:index1,index2:index2}})
                        // 26P #80786 end
                    }
                    
                }
            })
        })
        setSelectAllFlagOfForm(tempDataOfForm)
    }

    const handleSeminarSelect = (e: any) => {
        if('0' === e.target.value){
            setRdoDateSelect([])
            setIndividual('')
            setSelectAllFlag('')
            setSelectAllFlagOfForm([])
        }else if('1' === e.target.value){
            setWhichOne('')
            setIndividual('0')
        }
        setSeminarSelect(e.target.value)
    }

    const handleWhichOne = (e: any) => {
        const whichOneObj = e.target.value === whichOne ? '' : e.target.value
        setWhichOne(whichOneObj)
        // 次期9月対応 #63457 start
        // 受付状況ラジオボタン（いずれかの日程）の場合 
        // 受付予約ラジオボタンの"いずれかの日程"をチェックありにする。
        setSeminarSelect('0')
        setRdoDateSelect([])
        // ANR/ORの設定
        setIndividual('')
        // 「各受付ごと」の一括チェック：選択しない
        setSelectAllFlag('')
        // 次期9月対応 #63457 end
        setSelectAllFlagOfForm([])
    }

    const handleIndividual = (e: any) => {
        setIndividual(e.target.value)
    }

    const handleRdoDateSelect = (seminar: SeminarLevel3, values: string, index1: number, index2: number) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        let tmpval2: RdoSeleDate[] = []
        rdoDateSelect.map(info => {
            if (info.dateId.toString() === seminar.seminarScheduleId.toString()) {
                tmpval2.push({ dateId: seminar.seminarScheduleId, rdoVal: rdoSeleData && rdoSeleData.rdoVal === values ? '' : values })
            } else {
                tmpval2.push(info)
            }
        })
        if (!rdoSeleData) {
            tmpval2.push({ dateId: seminar.seminarScheduleId, rdoVal: values })
        }
        let count = 0
        // 26P #80786 start
        // filter後の全てセミナー日程ID
        let futureSeminarSchId: string[] = []
        seminarListTemp.map(item => {
        // 26P #80786 end
            item.seminarLevel2Dto.map(item1 => {
                item1.seminarLevel3Dto.map(item2 => {
                    count++
                    // 26P #80786 start
                    futureSeminarSchId.push(item2.seminarScheduleId.toString())
                    // 26P #80786 end
                })
            })
        })
        // 26P #80786 start
        if (checkBoxFlag) {
            // filter後の全てセミナー日程の受付状況ラジオボタン（各受付ごと）値
            const newRdoValSortList: string[] = []
            tmpval2.forEach(i => {
                if (futureSeminarSchId.indexOf(i.dateId.toString()) != -1) {
                    newRdoValSortList.push(i.rdoVal)
                }
            })
            newRdoValSortList.sort()
            if(newRdoValSortList.length === count && newRdoValSortList[0] === newRdoValSortList[newRdoValSortList.length-1]){
                setSelectAllFlag(newRdoValSortList[0])
            }else {
                setSelectAllFlag('')
            }
        } else {
        // 26P #80786 end
            const rdoValSortList: string[] = []
            tmpval2.forEach(i => {
                rdoValSortList.push(i.rdoVal)
            })
            rdoValSortList.sort()
            if(rdoValSortList.length === count && rdoValSortList[0] === rdoValSortList[rdoValSortList.length-1]){
                setSelectAllFlag(rdoValSortList[0])
            }else {
                setSelectAllFlag('')
            }
        }
        setRdoDateSelect(tmpval2)
        // 次期9月対応 #63457 start 
        // 「各受付ごと」選択する
        setSeminarSelect('1')
        //「いずれかの日程」の受付状況チェック：選択しない
        setWhichOne('')
        individual ? setIndividual(individual) : setIndividual('0')
        // 次期9月対応 #63457 end 
    }

    const handleRdoDateSelect2 = async (seminar: SeminarLevel3, values: string, index1: number, index2: number) => {
        await handleRdoDateSelect(seminar, values, index1, index2)
        let doms = document.getElementsByName(`selectSearch5[${index1}_${index2}]`)
        let tempDataOfForm = [...selectAllFlagOfForm]
        let tempValue = ''
        if (doms) {
            for (let i = 0; i < doms.length; i++) {
                if ((doms[i] as any).checked) {
                    tempValue = i.toString()
                }
            }
            let tempIndex = tempDataOfForm.findIndex(item => item.coordinate.index1 == index1 && item.coordinate.index2 == index2)
            if (tempIndex != -1) {
                tempDataOfForm[tempIndex].value = tempValue
            } else {
                // 26P #80786 start
                tempDataOfForm.push({id:seminar.seminarId ,value:tempValue,coordinate:{index1:index1,index2:index2}})
                // 26P #80786 end
            }
            setSelectAllFlagOfForm(tempDataOfForm)
        }
    }

    const rdoCheckedZero = (seminar: SeminarLevel3) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        if (rdoSeleData) {
            if (rdoSeleData.rdoVal === "0") {
                return true
            }
        }
        return false
    }

    const rdoCheckedOne = (seminar: SeminarLevel3) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        if (rdoSeleData) {
            if (rdoSeleData.rdoVal === "1") {
                return true
            }
        }
        return false
    }

    const rdoCheckedTwo = (seminar: SeminarLevel3) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        if (rdoSeleData) {
            if (rdoSeleData.rdoVal === "2") {
                return true
            }
        }
        return false
    }
    
    const rdoCheckedThree = (seminar: SeminarLevel3) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        if (rdoSeleData) {
            if (rdoSeleData.rdoVal === "3") {
                return true
            }
        }
        return false
    }

    
    const handleSelectAllOfForm = (e: string, seminarId: string, index1: number, index2: number) => {
        // 操作記録
        let selectValue = ''
        let tempSelectAllFlagOfForm = [...selectAllFlagOfForm]
        // 26P #80786 start
        let index = tempSelectAllFlagOfForm.findIndex(item => item.id == seminarId)
        // 26P #80786 end
        if (index == -1) {
            selectValue = e
            // 26P #80786 start
            let tempSeleDataOfForm: SeleDateOfForm = {id:seminarId, coordinate: { index1: index1, index2: index2 }, value: selectValue }
            // 26P #80786 end
            setSelectAllFlagOfForm(tempSelectAllFlagOfForm.concat([tempSeleDataOfForm]))
        } else {
            selectValue = e == selectAllFlagOfForm[index].value ? '' : e
            // 26P #80786 start
            let tempSeleDataOfForm: SeleDateOfForm = {id:seminarId, coordinate: { index1: index1, index2: index2 }, value: selectValue }
            // 26P #80786 end
            tempSelectAllFlagOfForm[index] = tempSeleDataOfForm
            setSelectAllFlagOfForm(tempSelectAllFlagOfForm)
        }
        // データ保持
        let tmpval2: RdoSeleDate[] = []
        // 26P #80786 start
        seminarListTemp.map(item => {
        // 26P #80786 end
            item.seminarLevel2Dto.filter(i => i.seminarId == seminarId).map(item1 => {
                item1.seminarLevel3Dto.map(item2 => {
                    tmpval2.push({ dateId: item2.seminarScheduleId, rdoVal: selectValue })
                })
            })
        })
        let tempRdoDateSelect = [...rdoDateSelect]
        let addTempRdoDateSelect: RdoSeleDate[] = []
        tmpval2.forEach(i => {
            let tempIndex = tempRdoDateSelect.findIndex(item => item.dateId == i.dateId)
            if (tempIndex == -1) {
                addTempRdoDateSelect.push(i)
            } else {
                tempRdoDateSelect[tempIndex] = i
            }
        })
        let result = tempRdoDateSelect.concat(addTempRdoDateSelect)
        // 全チェック判定
        let count = 0
        // 26P #80786 start
        // filter後の全てセミナー日程ID
        let futureSeminarSchId: string[] = []
        seminarListTemp.map(item => {
        // 26P #80786 end
            item.seminarLevel2Dto.map(item1 => {
                item1.seminarLevel3Dto.map(item2 => {
                    count++
                    // 26P #80786 start
                    futureSeminarSchId.push(item2.seminarScheduleId.toString())
                    // 26P #80786 end
                })
            })
        })
        // 26P #80786 start
        if (checkBoxFlag) {
            // filter後の全てセミナー日程の受付状況ラジオボタン（各受付ごと）値
            const newRdoValSortList: string[] = []
            result.forEach(i => {
                if (futureSeminarSchId.indexOf(i.dateId.toString()) != -1) {
                    newRdoValSortList.push(i.rdoVal)
                }
            })
            newRdoValSortList.sort()
            if (newRdoValSortList.length === count && newRdoValSortList[0] === newRdoValSortList[newRdoValSortList.length - 1]) {
                setSelectAllFlag(newRdoValSortList[0])
            } else {
                setSelectAllFlag('')
            }
        } else {
        // 26P #80786 end
            const rdoValSortList: string[] = []
            result.forEach(i => {
                rdoValSortList.push(i.rdoVal)
            })
            rdoValSortList.sort()
            if (rdoValSortList.length === count && rdoValSortList[0] === rdoValSortList[rdoValSortList.length - 1]) {
                setSelectAllFlag(rdoValSortList[0])
            } else {
                setSelectAllFlag('')
            }
        }
        setRdoDateSelect(result)
        // 次期9月対応 #63457 start 
        // 「各受付ごと」選択する
        setSeminarSelect('1')
        //「いずれかの日程」の受付状況チェック：選択しない
        setWhichOne('')
        individual ? setIndividual(individual) : setIndividual('0')
        // 次期9月対応 #63457 end 
    }

    const rdoCheckedOfForm = (e: string, seminar: SeminarLevel2) => {
        return log80680(seminar.seminarLevel3Dto,"rdoCheckedOfForm_seminar.seminarLevel3Dto") && seminar.seminarLevel3Dto.every(item => {
            let temp = rdoDateSelect.filter(item2 => item2.dateId == item.seminarScheduleId && item2.rdoVal == e)
            if (temp && temp.length > 0) {
                return true
            } else {
                return false
            }
        })
    }

    // 26P #80786 start
    // 「開催後の日程を非表示にする」チェックボックス change
    const handleCheckBox = () => {
        setInitFlag(false)
        // ON　→　OFF
        if(checkBoxFlag) {
            setCheckBoxFlag(false)
            setSeminarListTemp(seminarList)
            // 「一括操作ラジオボタン」のチェックの状態判定
            // 全てセミナー日程ID
            let seminarLevel3DtoSeminarSeminarScheduleIdList : string[] = []
            let selectedAllFlag : boolean = false
            seminarList.map((item) => {
                item.seminarLevel2Dto.map((item1) => {
                    item1.seminarLevel3Dto.map((item2) => {
                        seminarLevel3DtoSeminarSeminarScheduleIdList.push(item2.seminarScheduleId)
                    })
                })
            })
            // 予約あり
            let temp1 : RdoSeleDate[]= []
            // 予約なし
            let temp2 : RdoSeleDate[]= []
            // キャンセル
            let temp3 : RdoSeleDate[]= []
            // 採用担当による手動予約
            let temp4 : RdoSeleDate[]= []
            rdoDateSelect.map((it) => {
                seminarLevel3DtoSeminarSeminarScheduleIdList.map(item => {
                    if (it.dateId == item && it.rdoVal === '0') {
                        temp1.push(it)
                        return
                    } else if (it.dateId == item && it.rdoVal === '1') {
                        temp2.push(it)
                        return
                    } else if (it.dateId == item && it.rdoVal === '2') {
                        temp3.push(it)
                        return
                    } else if (it.dateId == item && it.rdoVal === '3') {
                        temp4.push(it)
                        return
                    }
                })
            })
            if (temp1 && temp1.length > 0 && temp1.length == seminarLevel3DtoSeminarSeminarScheduleIdList.length) {
                selectedAllFlag = true
            } else if (temp2 && temp2.length > 0 && temp2.length == seminarLevel3DtoSeminarSeminarScheduleIdList.length) {
                selectedAllFlag = true
            } else if (temp3 && temp3.length && temp3.length == seminarLevel3DtoSeminarSeminarScheduleIdList.length) {
                selectedAllFlag = true
            } else if (temp4 && temp4.length && temp4.length == seminarLevel3DtoSeminarSeminarScheduleIdList.length) {
                selectedAllFlag = true
            }
            if (selectedAllFlag) {
                setSelectAllFlag(rdoDateSelect[0].rdoVal)
            } else {
                setSelectAllFlag('')
            }
            // 受付状況ラジオボタン（説明会・面接名ごと）
            let selectAllSeminarId: SeleDateOfForm[] = []
            seminarList.map((x,index1) => {
                x.seminarLevel2Dto.map((y,index2) => {
                    let count: number = 0
                    let groupRdoDateSelect: RdoSeleDate[] = []
                    let groupRdoVal: string[] =[]
                    y.seminarLevel3Dto.map((z) => {
                        count++
                        let index = rdoDateSelect.findIndex(item => item.dateId == z.seminarScheduleId) 
                        if (index != -1) {
                            groupRdoDateSelect.push({dateId: z.seminarScheduleId, rdoVal: rdoDateSelect[index].rdoVal})
                        }
                    })
                    groupRdoDateSelect.forEach(item => {
                        groupRdoVal.push(item.rdoVal)
                    })
                    groupRdoVal.sort()
                    if (groupRdoVal.length == count && groupRdoVal[0] == groupRdoVal[groupRdoVal.length -1]) {
                        selectAllSeminarId.push({id: y.seminarId, value: groupRdoVal[0], coordinate: {index1:index1,index2:index2}})
                    } else {
                        selectAllSeminarId.push({id: y.seminarId, value: '', coordinate: {index1:index1,index2:index2}})
                    }
                })
            })
            let newSelectAllFlagOfForm = [...selectAllFlagOfForm]
            selectAllSeminarId.map((a) => {
                let index = newSelectAllFlagOfForm.findIndex(item => item.id == a.id)
                if (index != -1) {
                    let temp: SeleDateOfForm = {id: a.id, value: a.value, coordinate: {index1: a.coordinate.index1, index2: a.coordinate.index2}}
                    newSelectAllFlagOfForm[index] = temp
                    setSelectAllFlagOfForm(newSelectAllFlagOfForm)
                }
            })
        } else {
            // OFF → ON
            const seminarReceptionIndividualObj2List: SeminarReceptionIndividualObj2[] = []
            const selectedAfterSeminarScheduleIdList: string[] = []
            seminarList.map((item, index) => {
                item.seminarLevel2Dto.map((item1, index1) => {
                    item1.seminarLevel3Dto.map((item2, index2) => {
                        const selectSeminarCheckBox: any = document.getElementsByName(
                            `selectSearch3[${index}_${index1}_${index2}]`
                        )
                        if (selectSeminarCheckBox) {
                            //開始時刻の文字列作成
                            let startTime = dateTimeToString(
                                item2.startTime
                            )
                            // 終了時刻の文字列作成
                            let endTime = dateTimeToString(item2.endTime)
                            if (startTime) {
                                startTime = startTime.substring(11)
                            }
                            if (endTime) {
                                endTime = endTime.substring(11)
                            }
                            let seminarReceptionIndividualObj2: SeminarReceptionIndividualObj2 = {
                                seminarReceptionIndividualApplicationRouteId: item2.contractMediaId,
                                seminarReceptionIndividualApplicationRouteName: item2.contractMediaName,
                                seminarReceptionIndividualCoordinationFormerSeminarId: item2.coordinationFormerSeminarId,
                                seminarReceptionIndividualSeminarId: item2.seminarId,
                                seminarReceptionIndividualSeminarName: item2.seminarName,
                                seminarReceptionIndividualPublicFlag: item2.publicFlag,
                                seminarReceptionIndividualPublicName: item2.publicName,
                                seminarReceptionIndividualSeminarScheduleId: item2.seminarScheduleId,
                                seminarReceptionIndividualSeminarScheduleTitle: item2.seminarScheduleTitle,
                                seminarReceptionIndividualInvisibleFlag: item2.invisibleFlag,
                                seminarReceptionIndividualInvisibleName: item2.invisibleName,
                                seminarReceptionIndividualEventDate: item2.eventDate ? dateToString(item2.eventDate) : '',
                                seminarReceptionIndividualStartTime: startTime ? startTime : '',
                                seminarReceptionIndividualEndTime: endTime ? endTime : '',
                                seminarReceptionIndividualVenueLocationCode: item2.venueLocationCode,
                                seminarReceptionIndividualVenueLocationName: item2.venueLocationName,
                                seminarReceptionIndividualFlag: 0,
                                seminarReceptionIndividualName: '',
                                seminarReceptionIndividualEventDateText: item2.eventDateText,
                            }
                            // システム日時
                            let currentTime:any = momentTz(dateTimeToString(systemTime()))
                            // 現在のセミナー日程の終了時間
                            let kEndTime = item2.endTime != null ? dateToStringFunc(item2.endTime) : '23:59:00'
                            // 開催日時取得
                            let date = item2.eventDate ? dateToString(item2.eventDate) + ' ' +  (kEndTime !== '23:59:00' ? kEndTime.substring(11,19) : '23:59:00') : null

                            if (selectSeminarCheckBox[0].checked) {
                                seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 0
                                seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                    '予約あり'
                                if (date) {
                                    // 開催日時ある場合
                                    let seminarStartDate = momentTz(date)
                                    if (seminarStartDate < currentTime) {
                                        seminarReceptionIndividualObj2List.push(seminarReceptionIndividualObj2)
                                    } else {
                                        selectedAfterSeminarScheduleIdList.push(item2.seminarScheduleId)
                                    }
                                }
                            } else if (selectSeminarCheckBox[1].checked) {
                                seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 1
                                seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                    '予約なし'
                                if (date) {
                                    // 開催日時ある場合
                                    let seminarStartDate = momentTz(date)
                                    if (seminarStartDate < currentTime) {
                                        seminarReceptionIndividualObj2List.push(seminarReceptionIndividualObj2)
                                    } else {
                                        selectedAfterSeminarScheduleIdList.push(item2.seminarScheduleId)
                                    }
                                }
                            } else if (selectSeminarCheckBox[2].checked) {
                                seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 2
                                seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                    'キャンセル'
                                if (date) {
                                    // 開催日時ある場合
                                    let seminarStartDate = momentTz(date)
                                    if (seminarStartDate < currentTime) {
                                        seminarReceptionIndividualObj2List.push(seminarReceptionIndividualObj2)
                                    } else {
                                        selectedAfterSeminarScheduleIdList.push(item2.seminarScheduleId)
                                    }
                                }
                            } else if (selectSeminarCheckBox[3].checked) {
                                seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 3
                                seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                    '採用担当による手動予約'
                                if (date) {
                                    // 開催日時ある場合
                                    let seminarStartDate = momentTz(date)
                                    if (seminarStartDate < currentTime) {
                                        seminarReceptionIndividualObj2List.push(seminarReceptionIndividualObj2)
                                    } else {
                                        selectedAfterSeminarScheduleIdList.push(item2.seminarScheduleId)
                                    }
                                }
                            }
                        }
                    })
                })
            })
            setCheckBoxFlag(true)
            // すでに選択されているセミナー日程（過去）
            setSelectedSeminarSchList(seminarReceptionIndividualObj2List)
            // 開催後日程filter
            let seminarListFilter = selectSeminarAfterSchedule()
            // 選択されているセミナー日程（過去）のセミナー日程ID
            let beforeSeminarScheduleList : string[] = []
            selectedSeminarSchList.map((item => {
                beforeSeminarScheduleList.push(item.seminarReceptionIndividualSeminarScheduleId)
            }))
            // filter後のセミナー日程のセミナー日程ID
            let seminarLevel3DtoSeminarSeminarScheduleIdList2 : string[] = []
            let selectedAllFlag : boolean = false
            seminarListFilter.map((item) => {
                item.seminarLevel2Dto.map((item1) => {
                    item1.seminarLevel3Dto.map((item2) => {
                        seminarLevel3DtoSeminarSeminarScheduleIdList2.push(item2.seminarScheduleId)
                    })
                })
            })
            // 予約あり
            let temp1 : RdoSeleDate[]= []
            // 予約なし
            let temp2 : RdoSeleDate[]= []
            // キャンセル
            let temp3 : RdoSeleDate[]= []
            // 採用担当による手動予約
            let temp4 : RdoSeleDate[]= []
            // 選択されているセミナー日程（過去のセミナー日程は含まれていません）
            let newRdoDateSelect : RdoSeleDate[] = []
            rdoDateSelect.map((x) => {
                if (beforeSeminarScheduleList && beforeSeminarScheduleList.indexOf(x.dateId) == -1 && x.rdoVal != '') {
                    newRdoDateSelect.push(x)
                }
            })
            newRdoDateSelect.map((it) => {
                seminarLevel3DtoSeminarSeminarScheduleIdList2.map(item => {
                    if (it.dateId == item && it.rdoVal === '0') {
                        temp1.push(it)
                        return
                    } else if (it.dateId == item && it.rdoVal === '1') {
                        temp2.push(it)
                        return
                    } else if (it.dateId == item && it.rdoVal === '2') {
                        temp3.push(it)
                        return
                    } else if (it.dateId == item && it.rdoVal === '3') {
                        temp4.push(it)
                        return
                    }
                })
            })
            if (temp1 && temp1.length > 0 && temp1.length == seminarLevel3DtoSeminarSeminarScheduleIdList2.length) {
                selectedAllFlag = true
            } else if (temp2 && temp2.length > 0 && temp2.length == seminarLevel3DtoSeminarSeminarScheduleIdList2.length) {
                selectedAllFlag = true
            } else if (temp3 && temp3.length > 0 && temp3.length == seminarLevel3DtoSeminarSeminarScheduleIdList2.length) {
                selectedAllFlag = true
            } else if (temp4 && temp4.length > 0 && temp4.length == seminarLevel3DtoSeminarSeminarScheduleIdList2.length) {
                selectedAllFlag = true
            }
            if (selectedAllFlag) {
                setSelectAllFlag(newRdoDateSelect[0].rdoVal)
            } else {
                setSelectAllFlag('')
            }
            // 受付状況ラジオボタン（説明会・面接名ごと）
            let selectAllSeminarId: SeleDateOfForm[] = []
            seminarListFilter.map((a,index1) => {
                a.seminarLevel2Dto.map((b,index2) => {
                    let count: number = 0
                    let groupRdoDateSelect: RdoSeleDate[] = []
                    let groupRdoVal: string[] =[]
                    b.seminarLevel3Dto.map((c) => {
                        count++
                        let index = rdoDateSelect.findIndex(item => item.dateId == c.seminarScheduleId) 
                        if (index != -1) {
                            groupRdoDateSelect.push({dateId: c.seminarScheduleId, rdoVal: rdoDateSelect[index].rdoVal})
                        }
                    })
                    groupRdoDateSelect.forEach(item => {
                        groupRdoVal.push(item.rdoVal)
                    })
                    groupRdoVal.sort()
                    if (groupRdoVal.length == count && groupRdoVal[0] == groupRdoVal[groupRdoVal.length -1]) {
                        selectAllSeminarId.push({id: b.seminarId, value: groupRdoVal[0], coordinate: {index1:index1,index2:index2}})
                    } else {
                        selectAllSeminarId.push({id: b.seminarId, value: '', coordinate: {index1:index1,index2:index2}})
                    }
                })
            })
            let newSelectAllFlagOfForm = [...selectAllFlagOfForm]
            selectAllSeminarId.map((a) => {
                let index = newSelectAllFlagOfForm.findIndex(item => item.id == a.id)
                if (index != -1) {
                    let temp: SeleDateOfForm = {id: a.id, value: a.value, coordinate: {index1: a.coordinate.index1, index2: a.coordinate.index2}}
                    newSelectAllFlagOfForm[index] = temp
                    setSelectAllFlagOfForm(newSelectAllFlagOfForm)
                }
            })
        }
    }
    // 26P #80786 end

    // 26P #80786 start
    // 開催後日程filter
    const selectSeminarAfterSchedule = () => {
        // システム日付
        let currentTime:any = momentTz(dateTimeToString(systemTime()))

        let seminarListTempList : Seminar[] = []
        let seminarLevel2Dto: SeminarLevel2[] = []
        let seminarLevel3Dto: SeminarLevel3[] = []

        let a : number = 0
        // チェックありになる場合
        for (let i of seminarList) {
            seminarLevel2Dto = []
            for (let j of i.seminarLevel2Dto) {
                a = 0
                seminarLevel3Dto = []
                for (let k of j.seminarLevel3Dto) {
                    let kEndTime = k.endTime != null ? dateToStringFunc(k.endTime) : '23:59:00'
                    // 開催日時取得
                    let date = k.eventDate ? dateToString(k.eventDate) + ' ' +  (kEndTime !== '23:59:00' ? kEndTime.substring(11,19) : '23:59:00') : null

                    if (date) {
                        // 開催日時ある場合
                        let seminarStartDate = momentTz(date)
                        // 開催日時とシステム時間チェック
                        if (seminarStartDate < currentTime) {
                            // 開催後の日程場合、件数アップ
                            a = a + 1
                        } else {
                            seminarLevel3Dto.push(k)
                        }
                    } else {
                        seminarLevel3Dto.push(k)
                    }
                }
                // 開催後の日程ある場合
                if(a !== j.seminarLevel3Dto.length){
                    let tempC = {
                        ...j,
                        seminarLevel3Dto:seminarLevel3Dto
                    }
                    seminarLevel2Dto.push(tempC)
                }
            }
            // セミナー情報ある場合
            if(seminarLevel2Dto.length > 0){
                let tempb = {
                    ...i,
                    seminarLevel2Dto:seminarLevel2Dto
                }
                seminarListTempList.push(tempb)
            }
        }
        setSeminarListTemp(seminarListTempList)
        return seminarListTempList
    }
    // 26P #80786 end

    // 26P #80786 start
    // date　→　yyyy-MM-dd HH:mm:ss
    const dateToStringFunc = (param:Date) => {
        var date = new Date(param)
        var year = date.getFullYear()
        var month = (date.getMonth() + 1 ).toString()
        var day = (date.getDate()).toString()
        if (month.length == 1) {
            month = "0" + month
        }
        if  (day.length == 1) {
            day = "0" + day
        }
        var hours = date.getHours().toString()
        if (hours.length == 1) {
            hours = "0" + hours
        }
        var minutes = date.getMinutes().toString()
        if (minutes.length == 1) {
            minutes = "0" + minutes
        }
        var second = date.getSeconds().toString()
        if (second.length == 1) {
            second = "0" + second
        }
        var dateTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + second
        return dateTime
    }
    // 26P #80786 end

    // 26P #80786 start
    const Tooltip = withStyles({
        tooltip: {
          fontSize: '1em',
        },
        popper: {
          whiteSpace: 'pre-line',
        }
    })(MuiTooptip)
    // 26P #80786 end

    return (
        <div>
            <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
                <DialogTitle className={classes.dialogTitle}>
                    <label className={classes.title}>セミナー受付日程・予約状況を指定</label>
                </DialogTitle>
                {/* 26P #80786 start */}
                <DialogContent style={{height: '698px'}}>
                {/* 26P #80786 end */}
                    <RadioGroup>
                        <FormControlLabel
                            control={
                                <Radio
                                    color='primary'
                                    value='0'
                                    name='selectSearch'
                                    onChange={handleSeminarSelect}
                                    checked={'0' === seminarSelect}
                                />
                            }
                            label='いずれかの日程'
                        />
                        <RadioGroup row className={classes.content2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        color='primary'
                                        value='0'
                                        name='selectSearch1'
                                        onClick={handleWhichOne}
                                        checked={'0' === whichOne}
                                    />
                                }
                                label='予約あり'
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        color='primary'
                                        value='1'
                                        name='selectSearch1'
                                        onClick={handleWhichOne}
                                        checked={'1' === whichOne}
                                    />
                                }
                                label='予約なし'
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        color='primary'
                                        value='2'
                                        name='selectSearch1'
                                        onClick={handleWhichOne}
                                        checked={'2' === whichOne}
                                    />
                                }
                                label='キャンセル'
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        color='primary'
                                        value='3'
                                        name='selectSearch1'
                                        onClick={handleWhichOne}
                                        checked={'3' === whichOne}
                                    />
                                }
                                label='採用担当による手動予約'
                            />
                        </RadioGroup>
                        <Divider className={classes.divider} />
                        <FormControlLabel
                            control={
                                <Radio
                                    color='primary'
                                    value='1'
                                    name='selectSearch'
                                    onChange={handleSeminarSelect}
                                    checked={'1' === seminarSelect}
                                />
                            }
                            label='各受付ごと'
                        />
                        {/* 26P #80786 start */}
                        <label className={classes.checkBoxLabel}>
                            <input
                                type='checkbox'
                                checked={checkBoxFlag}
                                onChange={() => handleCheckBox()}
                                />
                            <mark className={classes.mark}></mark>
                            <span style={{cursor: 'pointer'}}>開催後の日程を非表示にする</span>
                            <Tooltip title={magiContants.MESSAGECODE_MCAXS481_003} placement='top'>
                                <IconButton className={classes.button}>
                                    <HelpOutlineIcon fontSize='large' htmlColor='black' />
                                </IconButton>
                            </Tooltip>
                        </label>
                        {/* 26P #80786 end */}
                        <div className={classes.subTitle}>
                            <RadioGroup row>
                                <FormLabel className={classes.labelTitle}>日付間の検索方法</FormLabel>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            color='primary'
                                            value='0'
                                            name='selectSearch2'
                                            onChange={handleIndividual}
                                            checked={'0' === individual}
                                        />
                                    }
                                    label='AND'
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            color='primary'
                                            value='1'
                                            name='selectSearch2'
                                            onChange={handleIndividual}
                                            checked={'1' === individual}
                                        />
                                    }
                                    label='OR'
                                />
                            </RadioGroup>
                        {/* 26P #80786 start */}
                        </div>
                        <div className={classes.containerOfForm}>
                            <RadioGroup row className={classes.selectAll}>
                        {/* 26P #80786 end */}
                                <FormLabel className={classes.labelTitle}>一括チェック</FormLabel>
                                <div onClick={() => handleSelectAll2("0")}>
                                    <input type="radio" name='selectSearch4' value='0' checked={'0' === selectAllFlag} />予約あり
                                </div>
                                <div onClick={() => handleSelectAll2("1")}>
                                    <input type="radio" name='selectSearch4' value='1' checked={'1' === selectAllFlag} />予約なし
                                </div>
                                <div onClick={() => handleSelectAll2("2")}>
                                    <input type="radio" name='selectSearch4' value='2' checked={'2' === selectAllFlag} />キャンセル
                                </div>
                                <div onClick={() => handleSelectAll2("3")}>
                                    <input type="radio" name='selectSearch4' value='3' checked={'3' === selectAllFlag} />採用担当による手動予約
                                </div>
                            </RadioGroup>
                        </div>
                        <div className={classes.select}>
                            {/* 26P #80786 start */}
                            <div className={classes.subContent} style={{marginTop: '-15px'}}>
                            {/* 26P #80786 end */}
                                <CustomScrollbar autoHeight={true} autoHeightMin={0} autoHeightMax={500} className={classes.seminarName}>
                                {/* 26P #80786 start */}
                                    {seminarListTemp
                                        ? seminarListTemp.map((seminar1, index1) => (
                                // 26P #80786 end
                                            // 26P #80786 start
                                            <div className={classes.seminarName3}>
                                            {/* 26P #80786 end */}
                                                <div>{seminar1.contractMediaName}</div>
                                                {log80680(seminar1.seminarLevel2Dto,"return_seminar1.seminarLevel2Dto") && seminar1.seminarLevel2Dto.map((seminar2, index2) => (
                                                    <>
                                                        <List>
                                                            <ListItem
                                                                button
                                                                selected={selectedSub === `${index1}_${index2}`}
                                                                onClick={handleSubClick(index1, index2)}>
                                                                <ListItemText
                                                                    primary={createSeminarName(seminar2)}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </>
                                                ))}
                                            </div>
                                        ))
                                        : null}
                                </CustomScrollbar>
                            </div>
                            <div style={{ height: '100%' }} className={`${classes.seminarName} ${classes.seminarName2}`}>
                                <CustomScrollbar autoHeight={true} autoHeightMax={500}>
                                {/* 26P #80786 start */}
                                    {seminarListTemp
                                        ? seminarListTemp.map((seminar1, index1) => (
                                // 26P #80786 start
                                            <>
                                                <SubTitle variant='h4'>
                                                    {seminar1.contractMediaName}
                                                </SubTitle>
                                                {log80680(seminar1.seminarLevel2Dto,"return_seminar1.seminarLevel2Dto") && seminar1.seminarLevel2Dto.map((seminar2, index2) => (
                                                    <>
                                                        <FieldGroup id={`sel_${index1}_${index2}`}>
                                                            <div className={classes.containerOfForm}>
                                                                <div>
                                                                    <Typography variant='h5' className={classes.subtitleOfForm}>
                                                                        {createSeminarName(seminar2)}
                                                                    </Typography>
                                                                </div>
                                                                <RadioGroup row className={classes.selectAllOfForm}>
                                                                    <FormLabel className={classes.labelTitle}>一括チェック</FormLabel>
                                                                    <div onClick={() => handleSelectAllOfForm("0", seminar2.seminarId, index1, index2)}>
                                                                        <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='0' checked={rdoCheckedOfForm('0', seminar2)} />予約あり
                                                                    </div>
                                                                    <div onClick={() => handleSelectAllOfForm("1", seminar2.seminarId, index1, index2)}>
                                                                        <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='1' checked={rdoCheckedOfForm('1', seminar2)} />予約なし
                                                                    </div>
                                                                    <div onClick={() => handleSelectAllOfForm("2", seminar2.seminarId, index1, index2)}>
                                                                        <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='2' checked={rdoCheckedOfForm('2', seminar2)} />キャンセル
                                                                    </div>
                                                                    <div onClick={() => handleSelectAllOfForm("3", seminar2.seminarId, index1, index2)}>
                                                                        <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='3' checked={rdoCheckedOfForm('3', seminar2)} />採用担当による手動予約
                                                                    </div>
                                                                </RadioGroup>
                                                                <Divider className={classes.divider} />
                                                            </div>
                                                            { log80680(seminar2.seminarLevel3Dto,"return_seminar2.seminarLevel3Dto") && seminar2.seminarLevel3Dto.map((seminar3, index3) => (
                                                                <>
                                                                    <div>{createSeminarScheduleName(seminar3)}</div>
                                                                    <div className={classes.content}>
                                                                        <div onClick={() => handleRdoDateSelect2(seminar3, "0", index1, index2)}>
                                                                            <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='0' checked={rdoCheckedZero(seminar3)} />予約あり
                                                                        </div>
                                                                        <div onClick={() => handleRdoDateSelect2(seminar3, "1", index1, index2)}>
                                                                            <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='1' checked={rdoCheckedOne(seminar3)} />予約なし
                                                                        </div>
                                                                        <div onClick={() => handleRdoDateSelect2(seminar3, "2", index1, index2)}>
                                                                            <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='2' checked={rdoCheckedTwo(seminar3)} />キャンセル
                                                                        </div>
                                                                        <div onClick={() => handleRdoDateSelect2(seminar3, "3", index1, index2)}>
                                                                            <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='3' checked={rdoCheckedThree(seminar3)} />採用担当による手動予約
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </FieldGroup>
                                                    </>
                                                ))}
                                            </>
                                        ))
                                        : null}
                                </CustomScrollbar>
                            </div>
                        </div>
                    </RadioGroup>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button
                        onClick={handleClick()}
                        color='primary'
                        variant='contained'
                        className={classes.cancel}>
                        キャンセルする
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color='primary'
                        variant='contained'
                        className={classes.confirmButton}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS481_001}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SeminarDateModal
