import request from 'utils/request'
import { MCAPS020QueryRequest } from 'types/MCAPS020/MCAPS020QueryRequest'
import { MCAPS020ApplicantListRequest } from 'types/MCAPS020/MCAPS020ApplicantListRequest'
import { MCAPS020DuplicateRequest } from 'types/MCAPS020/MCAPS020DuplicateRequest'
import { MCAPS020ErrorRequest } from 'types/MCAPS020/MCAPS020ErrorRequest'
import { MCAPS020AutoMergeConfirmRequest } from 'types/MCAPS020/MCAPS020AutoMergeConfirmRequest'

// 初期表示
export const initEntryUplodaHistoryApi = (recruitmentManagementDivision:string) =>
  request({
    url: `/MCAPS020/init/${recruitmentManagementDivision}`,
    method: 'post',
  })

// 「検索する」ボタン押下時
export const selectEntryUplodaHistoryApi = (param: MCAPS020QueryRequest) =>
  request({
    url: '/MCAPS020/find',
    method: 'post',
    data: param
  })

// 「アップロード件数」リンク押下
export const uploadCountApi = (param: MCAPS020ApplicantListRequest) =>
  request({
    url: '/MCAPS020/selectUploadCount',
    method: 'post',
    data: param
  })

// 「重複件数」リンク押下
export const duplicateCountApi = (param: MCAPS020DuplicateRequest) =>
  request({
    url: '/MCAPS020/selectDuplicateCount',
    method: 'post',
    data: param
  })

// 「自動適用件数」リンク押下
export const applicantInfoCountApi = (param: MCAPS020ApplicantListRequest) =>
  request({
    url: '/MCAPS020/selectFilterAutoCount',
    method: 'post',
    data: param
  })

// 「エラー確認」リンク押下
export const errorInfoCountApi = (param: MCAPS020ErrorRequest) =>
  request({
    url: '/MCAPS020/errorConfirm',
    method: 'post',
    data: param
  })

// 次期開発12月 #73504 start
// 「自動名寄せ件数」リンク押下
export const autoMergeCountApi = (param: MCAPS020AutoMergeConfirmRequest) =>
  request({
    url: '/MCAPS020/selectAutoMergeCount',
    method: 'post',
    data: param
  })
// 次期開発12月 #73504 end