import { submitValues } from 'pages/MCAMS010/formConfig'
export const initialValuess = {
        templateId: '',
        templateName: '',
        templateType: 0,
        senderMailAddress: '',
        allowReply: 0,
        repliesNecessary: 0, 
        repliesLimit: '', 
        templateAttachmentId: [],
        attachmentFile: [],
        mynaviAttachedOptionEntryID: [],
        mynaviAttachedOptionSeminarID: [],
        recruitmentManagementDivision: '',
        text: '',
        subject: '',
        destination: 0,
        // [phase2] start
        sendToNonMember: 0,
        // [phase2] end
        barcodeDisplay: 0,
        senderCompanyName: '',
        homepageTitle1: '',
        homepageTitle2: '',
        homepageTitle3: '',
        homepageUrl1: '',
        homepageUrl2: '',
        homepageUrl3: '',
        forbiddenWordsList: [],
        sysVersionNumber: 0,
        seminarScheduleList: [],
        seminarImageList: [],
      
  }
  export const initMCAMS040MessageTemplateList = {
    entryBoxList: [],
    seminarList: [],
    attachmentFileRegistrationMax: 0,
    mynaviAttachedOptionRegistrationMax: 0,
    recruitmentManagementDivision: '',
    // 次期開発12月 #71768 start
    replyMailAddress: '', 
    // 次期開発12月 #71768 end
} 

export const initialTemplateAttachmentInfo = {
  templateAttachmentName :[],
  templateAttachmentSize :[],
}

export const initialValues = {
  templateId: '',
  templateName: '',
  // 年度対応　MCAMS040 start
  targetYear: '',
   // 年度対応　MCAMS040 end 
  templateType: 0,
  senderMailAddress: '',
  allowReply: 0,
  repliesNecessary: 0, 
  repliesLimit: '', 
  templateattachmentList: [],
  attachment: initialTemplateAttachmentInfo,
  mynaviAttachedOptionEntryID: [],
  mynaviAttachedOptionSeminarID: [],
  recruitmentManagementDivision: '',
  text: '',
  subject: '',
  destination: 0,
  insertTextSecruitmentManageFlag	:0,	//管理フラグ対象フラグ
  // [phase2] start
  sendToNonMember: 0,
  // [phase2] end
  barcodeDisplay: 0,
  senderCompanyName: '',
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  forbiddenWordsList: [],
  sysVersionNumber: '',
  seminarScheduleList: [],
  seminarImageList: [],
  recruitmentManagementFlagList:[],
}


export const initialSubmitValues = {
  templateId: '',
  templateName: '',
  // 年度対応 start
  targetYear:'',
  // 年度対応 end
  templateType: 0,
  senderMailAddress: '',
  allowReply: 0,
  repliesNecessary: 0, 
  repliesLimit: '', 
  templateattachmentList: [],
  insertTextSecruitmentManageFlag: 0,
  attachment: initialTemplateAttachmentInfo,
  mynaviAttachedOptionEntryID: [],
  mynaviAttachedOptionSeminarID: [],
  recruitmentManagementDivision: '',
  text: '',
  subject: '',
  destination: 0,
  // [phase2] start
  sendToNonMember: 0,
  // [phase2] end
  barcodeDisplay: 0,
  senderCompanyName: '',
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  forbiddenWordsList: [],
  sysVersionNumber: '',
  seminarScheduleList: [],
  seminarImageList: [],
  messageTemplateSearchCondition:submitValues,
  recruitmentManagementFlagList:[],
}