import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialValues as searchCriteriaValues } from '../pages/MCARS010/formConfig'
import { ScreenTransitionInput as MCBHS040initScreenTransitionInput } from '../pages/MCBHS040/formConfig'
import { MCBIS010BatchProcessRequest } from 'types/MCBIS010/MCBIS010BatchProcessRequest'
import { MCBIS030CreateRequest } from 'types/MCBIS030/MCBIS030CreateRequest'
import { MCBIS030CreateRequestEmployment } from 'types/MCBIS030/MCBIS030CreateRequestEmployment'

export interface EntrySrhCriPreSelectSight {
  [key: string]: string | any
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  updateTime: string                   // 更新日時
  fullName: string                     // 更新者
  displayOrder: number                 // 表示順
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  sysVersionNumber: number             // sysバージョン番号
}

export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  sysVersionNumber: number             // sysバージョン番号
}
export interface ActionPreListData {
  entrySearchCriteriaSettingId: string
  searchCriteriaName: string

}
export interface searchCriteriaRow {
  [key: string]: string
  entrySearchCriteriaSettingId: string//エントリー検索条件設定ID
  searchCriteriaName: string //検索条件名
  sysVersionNumber: string//sysバージョン番号
  sysVersionNumberTime: string//sysバージョン番号時間
}
export interface OptionType {
  [key: string]: string;
  label: string
  value: string
  sysVersionNumber: string
  sysVersionNumber1: string
}

interface InitialValuesType {
  searchCriteriaSightInfo: EntrySrhCriPreSelectSight[]
  searchCriteriaInfo: SearchCriteriaInfo
  detailDialogOpen: boolean
  editDialogOpen: boolean
  ActionPreList: ActionPreListData[],
  createDialogOpen: boolean
  searchCriteriaListResults: searchCriteriaRow[]
  searchCriteria: string
  open: boolean;
  selectDate: OptionType[],
  entrySearchCriteriaSettingId: string, //エントリー検索条件設定ID
  flag: number
  checkDialogOpen: boolean
  checkMsg: string
}

const initialState: InitialValuesType = {
  searchCriteriaSightInfo: [],
  searchCriteriaInfo: searchCriteriaValues,
  detailDialogOpen: false,
  editDialogOpen: false,
  ActionPreList: [],
  createDialogOpen: false,
  searchCriteriaListResults: [],
  searchCriteria: '',
  open: false,
  selectDate: [],
  entrySearchCriteriaSettingId: '',
  flag: 0,
  checkDialogOpen: false,
  checkMsg: '',
}

const selectCriteriaManagementMcbSlice = createSlice({
  name: 'selectCriteriaManagementMcb',
  initialState,
  reducers: {
    // MCBIS010 画面初期表示
    getSearchCriteriaMcbSightInfo(state) {
      return state
    },
    // MCBIS010 画面初期表示
    setSearchCriteriaMcbSightInfo(state, action: PayloadAction<EntrySrhCriPreSelectSight[]>) {
      state.searchCriteriaSightInfo = action.payload
      return state
    },
    // MCBIS010 並び替え
    updatescsMcbDisplayOrder(state, action: PayloadAction<EntrySrhCriPreSelectSight[]>) {
      state.searchCriteriaSightInfo = action.payload
      return state
    },
    // MCBIS010 検索条件取得
    getSearchCriteriaMcb(state, action: PayloadAction<string>) {
      return state
    },
    // MCBIS010 検索条件設定
    setSearchCriteria(state, action: PayloadAction<SearchCriteriaInfo>) {
      state.searchCriteriaInfo = action.payload
      return state
    },
    // MCBIS010 「検索条件名」画面を表示
    setDetailDialogOpen(state, action: PayloadAction<boolean>) {
      state.detailDialogOpen = action.payload
      return state
    },
    // MCBIS010 検索条件名取得
    getSearchCriteriaMcbName(state, action: PayloadAction<string>) {
      return state
    },
    // MCBIS010 検索条件名設定
    setSearchCriteriaMcbName(state, action: PayloadAction<SearchCriteriaInfo>) {
      state.searchCriteriaInfo = action.payload
      return state
    },
    // MCBIS010 「検索条件名の設定」画面を表示
    setEditDialogOpen(state, action: PayloadAction<boolean>) {
      state.editDialogOpen = action.payload
      return state
    },
    // MCBIS010 検索条件名更新
    updateSearchCriteriaMcbName(state, action: PayloadAction<SearchCriteriaInfo>) {
      return state
    },
    // MCBIS010 検索条件削除
    deleteSearchCriteriaMcb(state, action: PayloadAction<MCBIS010BatchProcessRequest>) {
      return state
    },

    // MCBIS030 画面初期表示時用
    getSelectCriteriaList(state) {
      return state
    },
    setDialogOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload
      return state
    },
    setSelectData(state, action: PayloadAction<OptionType[]>) {
      state.selectDate = action.payload;
      return state;
    },
    // 新規条件として保存"が選択されている場合
    createsearchCriteriaName(state, action: PayloadAction<MCBIS030CreateRequestEmployment>) {
      return state
    },
    // 既存の検索条件に上書き保存"が選択されている場合
    updatesearchCriteria(state, action: PayloadAction<MCBIS030CreateRequest>) {
      return state
    },

    setEscSettingId(state, action: PayloadAction<string>) {
      state.entrySearchCriteriaSettingId = action.payload;
      return state;
    },

    // MCBIS030 アクションが設定されている場合、確認メッセージを表示する。
    setCheckDialogOpen(state, action: PayloadAction<boolean>) {
      state.checkDialogOpen = action.payload
      return state
    },

    selectSearchCriteriaMcb(state, action: PayloadAction<{
      entrySearchCriteriaSetting: string,
      MCBHS040initScreenTransitionInput: MCBHS040initScreenTransitionInput,
      recruitmentManagementDivision: string
    }>) {
      return state
    }
  },
})

export const {
  // MCBIS010
  getSearchCriteriaMcbSightInfo,
  setSearchCriteriaMcbSightInfo,
  updatescsMcbDisplayOrder,
  getSearchCriteriaMcb,
  setSearchCriteria,
  setDetailDialogOpen,
  getSearchCriteriaMcbName,
  setSearchCriteriaMcbName,
  setEditDialogOpen,
  updateSearchCriteriaMcbName,
  deleteSearchCriteriaMcb,

  //  MCBIS030
  getSelectCriteriaList,
  createsearchCriteriaName,
  updatesearchCriteria,
  setDialogOpen,
  setSelectData,
  setEscSettingId,

  selectSearchCriteriaMcb,
} = selectCriteriaManagementMcbSlice.actions
export default selectCriteriaManagementMcbSlice.reducer
