import request from 'utils/request'
import { MCBHS080InitRequest } from 'types/MCBHS080/MCBHS080InitRequest'

export const initRequest = (apiData: MCBHS080InitRequest) => {
  return request({
    url: '/MCBHS080/init',
    method: 'post',
    data: apiData,
  })
}
