import { magiContants } from 'utils/contants'

const initialValues = {
  selectionFlowSettingId: -1,
  selectionName: '',
  selectionNameFirst: '',
  standardSeletion: false,
  selectionPeriodStart: '',
  selectionPeriodEnd: '',
  // 年度対応 start
  targetYear:'',  //対象年度
  // 年度対応 end
  selectionType: '',
  selectionTypeFlag:false,
  recruitmentCountTarget: '',
  selectionFlowArea: '',
  versionNumber: -1,
  selectionFinish: false,
  progressDataList: [],
}

const textMap = {
  update: {
    submit: '更新する',
    message: magiContants.MESSAGECODE_MCAJS030_005,
  },
  cancel: {
    cancel: 'キャンセルする',
    message: magiContants.MESSAGECODE_MCAJS030_007,
  },
  delete: {
    delete: '削除する',
    message: magiContants.MESSAGECODE_MCAJS030_018,
    //#72716 start
    message1: magiContants.MESSAGECODE_MCAJS030_029, 
    //#72716 end
  },
  finish: {
    finish: '選考を終了する',
    message: magiContants.MESSAGECODE_MCAJS030_017,
  },
  init: {
    init: '',
    message: '',
  },
}

const buttonTextMap: { [key: string]: string } = {
  no: 'キャンセル',
  yes: 'OK',
  cancel: 'キャンセルする',
  ok: 'OK',
}

const constans = {
  PROGRESSSTATSTYPE_CUSTOMIZE: '1',
  PROGRESSSTATSTYPE_SYSTEM: '0',
  INVISIBLENOTPOSSIBLEFLAG_YES: '0',
  INVISIBLENOTPOSSIBLEFLAG_NO: '1',
  INVISIBLEFLAG_YES: 0,
  INVISIBLEFLAG_NO: 1,
  ENTRY_EXIST: 1,
  DECISION_DIVISION_TYPE_GOUHI_0: '0',
  DECISION_DIVISION_TYPE_GOUHI_2: '2',
  DECISION_DIVISION_TYPE_SANKA: '1',
}

const decisionDivisionTypeRadioData = [
  { key: '0', label: '合否管理型（選択肢：判定中／合格／不合格）' },
  { key: '1', label: '出欠管理型（選択肢：参加／不参加／その他）' },
]

const editCheckMessage = (message: string[]) => {
  let editedMesage = ''
  message.map((item: string) => {
    editedMesage = editedMesage + '・['+item+']'+'\n'
  })
  return editedMesage
}

export { initialValues, textMap, buttonTextMap, constans, editCheckMessage, decisionDivisionTypeRadioData }

export type typeOfDialog = 'add' | 'change' | 'delete' | ''

export interface Errors {
  errorFlag: boolean
  errorInfo: any
}
