import { addMediaAdditionalApi, getInitInfoApi, updatemediaChangeApi } from 'apis/MCAXS670Api'
import { getMessage } from 'common/messageUtil'
import { addMediaAdditional, getInitInfo, setEntryRout, setEntryRouteDialogOpen, updatemediaChange } from 'reducers/entryRouteReducer'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import { all, call, put, select, takeEvery, takeLeading } from 'redux-saga/effects'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { magiContants } from 'utils/contants'

 // -----------------MCAXS670_応募経路追加_START------------------------//
function* getInitInfoSaga(action: ReturnType<typeof getInitInfo>) {
  try {
    const data = yield call(getInitInfoApi, action.payload.request);
    if(action.payload.request.operationType === '0'   && (data.addAndAfterEntryRouteInfoList.length == null || data.addAndAfterEntryRouteInfoList.length === 0)){
      yield put(openSnackbar(magiContants.MESSAGECODE_RESULT_NULL))
    }else if(action.payload.request.operationType === '1' 
              && (data.beforeEntryRouteInfoList == null  || data.beforeEntryRouteInfoList.length === 0 || data.addAndAfterEntryRouteInfoList.length == null   ||data.addAndAfterEntryRouteInfoList.length === 0  )){
      yield put(openSnackbar(magiContants.MESSAGECODE_RESULT_NULL))
    }else{
      yield put(setEntryRout(data));
      yield put(setEntryRouteDialogOpen(true));
    }
    
  } catch (error) {
    yield put(openModal(error.message))
    if (action.payload.onError) {
      action.payload.onError()
    }
  }
}

function* addMediaAdditionalSaga(action: ReturnType<typeof addMediaAdditional>) {
  try {
    const message = yield call(addMediaAdditionalApi, action.payload.request);
    yield put(setEntryRouteDialogOpen(false));
    yield put(openSnackbar(message));
    if(action.payload.onclose !== undefined){
      action.payload.onclose()
    }

  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* updatemediaChangeSaga(action: ReturnType<typeof updatemediaChange>) {

  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
  try {
    if(recruitmentManagementDivision === '1' && action.payload.request.entryDate === ''){
      yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAXS670_015)));
    }else if(recruitmentManagementDivision === '2' && action.payload.request.beforeMediaSettingId.toString() === action.payload.request.afterMediaSettingId.toString()){
      yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAXS670_008)));
    }else{
      const message = yield call(updatemediaChangeApi, action.payload.request);
      yield put(setEntryRouteDialogOpen(false));
      yield put(openSnackbar(message));
      if(action.payload.onclose !== undefined){
        action.payload.onclose()
      }

    }  
  } catch (error) {
    yield put(openModal(error.message));
  }
}


 // -----------------MCAXS670_応募経路追加_END------------------------//

export default function* entryRouteSaga() {
  yield all([

    // -----------------MCAXS670_応募経路追加_START------------------------//
    takeEvery(getInitInfo, getInitInfoSaga),
    takeLeading(addMediaAdditional, addMediaAdditionalSaga),
    takeLeading(updatemediaChange, updatemediaChangeSaga),

    // -----------------MCAXS670_応募経路追加_END------------------------//
  ])
}
