import { call, put, all, takeLatest, takeLeading, select} from 'redux-saga/effects'
import { commentInit, commentUpdate } from 'reducers/commentUpdateReducer'
import { openSnackbar,openModal } from 'reducers/messageReducer'
import { commentInitRequest, commentUpdateRequest} from 'apis/MCAYS010Api'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors';
import { initCondition, setProcessChangeInit } from 'reducers/selectionStatusUpdateReducer';
import { processChangeQueryRequest } from 'apis/MCAYS020Api';
import {setUpdateFlag} from 'reducers/selectionStatusUpdateReducer'

function* commentInitSaga(action: ReturnType<typeof commentInit>) {
  try {
    yield call(commentInitRequest);
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* commentUpdateSaga(action: ReturnType<typeof commentUpdate>) {
  try {
    const message = yield call(commentUpdateRequest, action.payload.request);
    if(action.payload.request.screenId == "MCAYS020"){
      const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
      const init : initCondition = {
        selectionManagementId:action.payload.request.selectionManagementId[0],//選考管理ID
        recruitmentManagementDivision:recruitmentManagementDivision,//採用管理区分
      }
      const data = yield call(processChangeQueryRequest, init);
      yield put(setProcessChangeInit(data));
    }
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(message))
    if(action.payload.onclose !== undefined){
      action.payload.onclose()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* accountSaga() {
  yield all([
    takeLatest(commentInit, commentInitSaga),
    takeLeading(commentUpdate, commentUpdateSaga),
  ])
}
  