import request from 'utils/request'
import { MCBHS010RequestInitRequest } from "types/MCBHS010/MCBHS010RequestInitRequest";
import { MCBHS010SubmitRequestPreviewRequest } from 'types/MCBHS010/MCBHS010SubmitRequestPreviewRequest';
import { MCBHS010SubmissionTemplateRequest } from 'types/MCBHS010/MCBHS010SubmissionTemplateRequest';
import { MCBHS010RequestDraftRequest } from 'types/MCBHS010/MCBHS010RequestDraftRequest';
import { MCBHS010RequestConfirmRequest } from 'types/MCBHS010/MCBHS010RequestConfirmRequest';
import { MCBHS010RequestCsvDownloadRequest } from 'types/MCBHS010/MCBHS010RequestCsvDownloadRequest';

export const submissionRequestInputInitRequest = (params: MCBHS010RequestInitRequest) =>
  request({
    url: '/MCBHS010/init',
    method: 'post',
    data: params
  })

  export const reloadSubmissionsSetRequest  = () =>
  request({
    url: '/MCBHS010/reloadSubmissionsSet',
    method: 'post'
  })

  export const reloadThanksTemplateRequest  = () =>
  request({
    url: '/MCBHS010/reloadThanksTemplate',
    method: 'post'
  })
  
  export const reloadReferenceRequest  = () =>
  request({
    url: '/MCBHS010/reloadReference',
    method: 'post'
  })
  
  export const reloadPersonalRequest  = () =>
  request({
    url: '/MCBHS010/reloadPersonal',
    method: 'post'
  })

  export const previewRequest  = (params: MCBHS010SubmitRequestPreviewRequest) =>
  request({
    url: '/MCBHS010/preview',
    method: 'post',
    data: params
  })

  export const templateAttachmentRequest  = (params: MCBHS010SubmissionTemplateRequest) =>
  request({
    url: '/MCBHS010/templateAttachment',
    method: 'post',
    data: params
  })

  export const draftRequest  = (apiData: MCBHS010RequestDraftRequest, files: any[]) =>{
    const data = new FormData()
    data.append('draftRequest', encodeURIComponent(JSON.stringify(apiData)))
    const attachmentList = files
    if (attachmentList) {
      attachmentList.map(attachment => {
        if (attachment) {
          data.append('attachment', attachment)
        }
      })  
    }
  
    return request({
      url: '/MCBHS010/draft',
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  export const confirmRequest  = (apiData: MCBHS010RequestConfirmRequest, files: any[]) =>{
    const data = new FormData()
    data.append('confirmRequest', encodeURIComponent(JSON.stringify(apiData)))
    const attachmentList = files
    if (attachmentList) {
      attachmentList.map(attachment => {
        if (attachment) {
          data.append('attachment', attachment)
        }
      })  
    }
  
    return request({
      url: '/MCBHS010/confirm',
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  export const csvDownloadRequest = (apiData: MCBHS010RequestCsvDownloadRequest) => {
    return request({
      url: '/MCBHS010/csvDownload',
      method: 'post',
      responseType:"blob",
      data: apiData,
    })
  }
  