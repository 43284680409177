import React, { useEffect } from 'react'
import { TextField as MuiTextField, PropTypes } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'formik'

const useStyles = makeStyles({
  input: {
    '&::placeholder': {
      color: 'darkgray',
    },
  },
})

interface Props extends FieldProps {
  type?: string
  margin?: PropTypes.Margin
  placeholder?: string
  onBlur?: (event: any) => void,
}

const MapTextField = ({
  type = 'text',
  margin = 'dense',
  placeholder,
  field,
  form,
  onBlur,
  ...others
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { name } = field
  const handleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event)
    }
    form.setFieldTouched(name, true);
  };
  return (
    <MuiTextField
      variant='outlined'
      margin={margin}
      fullWidth
      type={type}
      inputProps={{ spellCheck: false }}
      InputProps={{
        classes: { input: classes.input },
      }}
      error={Boolean(errors[name] && touched[name])}
      helperText={errors[name] && touched[name] ? errors[name] : null}
      placeholder={placeholder}
      {...field}
      {...others}
      onBlur={handleBlur}
    />
  )
}

export default MapTextField
