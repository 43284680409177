import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'
import DisplayLimitSelect from './DisplayLimitSelect'
import { DisplayLimitOptionInfo, Option } from 'pages/MCAXS010/formConfig'

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    height: '70px',
    width: '100vw',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    marginBottom: '0 !important',
    display: ({ show }: any) => show ? 'flex' : 'none',
    alignItems: 'center',
    padding: '0 70px',
    '& button': {
      cursor: 'pointer',
      border: 'none',
      marginLeft: 'auto'
    },
    '& .mainButton': {
      padding: '3px 60px',
      borderRadius: '5px',
      fontSize: '25px',
      backgroundColor: '#3380cc',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fontSize: '28px',
        marginRight: '5px'
      },
      "@media (min-width: 1036px)" :{
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
      }
    },
    '& .subButton': {
      padding: '2px 20px',
      borderRadius: '3px',
      fontSize: '14px',
      backgroundColor: '#e0e0e0',
      marginTop: '20px',
    }
  },
  hidenButton: {
    display: 'none',
  },
  displaylimitSelect: {
    width: '200px',
    padding: '2px',
    borderRadius: '3px',
    fontSize: '14px',
    marginLeft: '20px',
    "@media (min-width: 1036px)" :{
      transform: 'translateX(50%)',
    },
  },
  centeredButton: {
    "@media (min-width: 1036px)" :{
      position: 'absolute',
      left: '50%',
      transform: 'translate(-52%, -50%)',
      "@media screen and (-webkit-min-device-pixel-ratio:0)" :{
        transform: 'translate(-52%, 0%)'
      }
    },
    display: 'flex',
  },
  miniScreenCenterdButton: {
    "@media (max-width: 1035px)" :{
      marginLeft: 'auto',
    },
  },
  miniScreenCenterdButtonForJobChange: {
    "@media (max-width: 1035px)" :{
      position: 'absolute',
      left: '50%',
      transform: 'translate(-52%, -50%)',
      "@media screen and (-webkit-min-device-pixel-ratio:0)" :{
        transform: 'translate(-52%, 0%)'
      }
    },
  }
})

interface Props {
  show: boolean
  onClick: (type: string) => void
  enableDisplayLimit?: boolean
  displayLimit?: Option
  displayLimitOption?: DisplayLimitOptionInfo[]
  serviceMode?: string
}

const OverlaySearch = ({
  show,
  onClick,
  enableDisplayLimit = false,
  displayLimit = { label: '', value: '' },
  displayLimitOption = [],
  serviceMode = '0'
}: Props) => {
  const classes = useStyles({ show })

  const handleClick = (type: string) => () => {
    onClick(type)
  }

  return (
    <div className={classes.root}>
      <div
        className={
          enableDisplayLimit
            ? `${classes.centeredButton} ${classes.miniScreenCenterdButton}`
            : `${classes.centeredButton} ${classes.miniScreenCenterdButtonForJobChange}`
        }>
        <button
          className='mainButton'
          type='submit'
          onClick={handleClick('search')}>
          <SearchIcon />
          検索
        </button>
        {enableDisplayLimit && (
          <React.Fragment>
            <DisplayLimitSelect
              className={classes.displaylimitSelect}
              displayLimit={displayLimit}
              displayLimitOption={displayLimitOption}
            />
          </React.Fragment>
        )}
      </div>
      {serviceMode !== '1' && (
        <button className='subButton' type='submit' onClick={handleClick('save')}>
          MY検索に保存
        </button>
      )}
      <button
        id='searhConditionReset'
        type='reset'
        className={classes.hidenButton}
      />
    </div>
  )
}

export default OverlaySearch