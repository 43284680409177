const csvTemplateRadioList = [
  { id: 'displayShow', label: 'ダウンロード', value: 1 },
  { id: 'displayHide', label: 'アップロード', value: 0 },
]
const mcals010TemplateTypeRequest = {
  templateType: '0', // テンプレート種別
  recruitmentManagementDivision: '', // 採用管理区分
}
const mcals010TemplateIdRequest = {
  entryDataIoTemplateSettingId: '', // エントリーデータ入出力テンプレート設定ID
  versionNumber: '', // バージョン番号
  recruitmentManagementDivision: '', // 採用管理区分
  seniFlag: '', // 遷移元フラグ
  templateName: '',
  // 年度対応 start
  targetYear: '',
  // 年度対応 end
}

const macls010IdRequest = {
  recruitmentManagementDivision: '', // 採用管理区分
}

const messageMap: { [key: string]: string } = {
  updateShowFlag: '選択されたすべてのテンプレートに対して、[表示]に変更します。よろしいですか？',
  updateHideFlag: '選択されたすべてのテンプレートに対して、[非表示]に変更します。よろしいですか？',
}

export {
  mcals010TemplateTypeRequest,
  csvTemplateRadioList,
  mcals010TemplateIdRequest,
  messageMap,
  macls010IdRequest,
}
