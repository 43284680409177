import React from 'react'
import { FieldProps } from 'formik'
import ReactSelect from 'react-select'
import customSelectOptions from 'utils/customSelectOptions'

const customStyles = {
  input: () => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
    cursor: 'pointer',
  }),
}

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  noOptionsMessage: string
  placeholder?: string
  className?: string
  isSearchable?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  menuPlacement?: String
  isMulti?: boolean
  onChange?: (value: any) => void
  defaultValue?: boolean;
}

export default ({
  isMulti,
  className,
  optionList,
  noOptionsMessage,
  onChange,
  placeholder,
  isSearchable,
  isDisabled,
  isClearable,
  menuPlacement,
  defaultValue,
  field,
  form,
}: Props) => {
  const { name, value } = field
  React.useEffect(() => {
    if(defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, "");
    }
  }, [defaultValue])

  const handleChange = (e: any) => {
    if (onChange !== undefined) {
      onChange(e)
    }
    if (isMulti) {
      form.setFieldValue(name, e ? (e as Option[]).map(i => i.value) : [])
    } else {
      form.setFieldValue(name, e ? (e as Option).value: "")
    }
  }

  return (
    <ReactSelect
      isMulti={isMulti}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={optionList.find(i => i.value === value)}
      noOptionsMessage={() => noOptionsMessage}
      placeholder={placeholder}
      onChange={handleChange}
      filterOption={customSelectOptions.filterOption}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
  )
}
