import request from 'utils/request'
import { MCAMS030InitDisplayRequest } from 'types/MCAMS030/MCAMS030InitDisplayRequest'
import { MCAMS030messageTemplateInputRequest } from 'types/MCAMS030/MCAMS030messageTemplateInputRequest'
import { MCAMS030deleteMessageTemplateRequest } from 'types/MCAMS030/MCAMS030deleteMessageTemplateRequest'

export const messageTemplateDetailRequest = (params : MCAMS030InitDisplayRequest) =>
  request({
    url: 'MCAMS030/init',
    method: 'post',
    data: params 
  })

export const messageTemplateInputRequest = (requestBody:{registeredFileName:File[],submitParams: MCAMS030messageTemplateInputRequest}) =>{
  const data = new FormData()
  requestBody.registeredFileName.forEach((file) => {
      data.append('files', file);
  });
  data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))
  return request({
    url: '/MCAMS030/confirm',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}

export const deleteMessageTemplateRequest = (params: MCAMS030deleteMessageTemplateRequest) =>
  request({
    url: '/MCAMS030/delete',
    method: 'post',
    data: params,
})

