import request from 'utils/request'
import { MCAZS240InitRequest } from 'types/MCAZS240/MCAZS240InitRequest'
import { MCAZS240NewRequest } from 'types/MCAZS240/MCAZS240NewRequest'
import { MCAZS240OrverWriteRequest } from 'types/MCAZS240/MCAZS240OrverWriteRequest'

export const initRequest = (apiData: MCAZS240InitRequest) => {
  return request({
    url: '/MCAZS240/init',
    method: 'post',
    data: apiData,
  })
}

export const newRequest = (apiData: MCAZS240NewRequest) => {
  return request({
    url: '/MCAZS240/new',
    method: 'post',
    data: apiData,
  })
}

export const orverWriteRequest = (apiData: MCAZS240OrverWriteRequest) => {
  return request({
    url: '/MCAZS240/Overwrite',
    method: 'post',
    data: apiData,
  })
}

