/**
 * MCAXS121InitRequest validation
 */

import * as yup from 'yup'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({

  // 選考管理ID
  // -
  selectionManagementIdList: yup
    .string()
    .required(validationMessageSet.required),

  // エントリーデータ入出力テンプレート設定ID
  // -
  entryDataIoTemplateSettingId: yup
    .string()
    .required(validationMessageSet.required),

  // 選考フロー設定ID
  // -
  selectionFlowSettingId: yup
    .string(),

  recruitmentManagementDivision: yup
  .string(),
});