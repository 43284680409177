import React, { useState } from 'react'
import { Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Paginate, BulkChange, OtherOperation } from 'componentsHsc'

const useStyles = makeStyles({
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0)',
    zIndex: 2,
  },
  tableContainer: {
    '& tbody td': {
      cursor: 'pointer !important',
    },
    '& tr:hover': {
      border: 'inset 2px #9fb8de',
    },
  },
  empty: {
    display: 'flex',
    height: '80vh',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '100px',
  },
  iconChangeProgress: {
    paddingBottom: "12px!important",
  },
})

const mockData = [
  {
    id: { name: '毎日太郎', id: '01234567890' },
    school: {
      school: 'あいうえおかきくけこ大学',
      faculty: 'あいうえお学部',
      department: '広告コミュニケーション学科',
    },
    type: '理系',
    address: '北海道',
    unread: '10',
    experience: [
      {
        route: 'マイナビ2019',
        result: {
          flow: '20本採用＜営業＞',
          rate: '4',
          progress: '一次面接',
          result: '判定中',
        },
      },
      {
        route: 'マイナビ2019',
        result: {
          flow: '20本採用＜営業＞',
          rate: '4',
          progress: '一次面接',
          result: '判定中',
        },
      },
    ],
  },
  {
    id: { name: '毎日太郎', id: '01234567890' },
    school: {
      school: 'あいうえおかきくけこ大学',
      faculty: 'あいうえお学部',
      department: '広告コミュニケーション学科',
    },
    type: '理系',
    address: '北海道',
    unread: '10',
    experience: [
      {
        route: 'マイナビ2019',
        result: {
          flow: '20本採用＜営業＞',
          rate: '4',
          progress: '対象外（一次面接）',
          result: '判定中',
        },
      },
    ],
  },
  {
    id: { name: '毎日太郎', id: '01234567890' },
    school: {
      school: 'あいうえおかきくけこ大学',
      faculty: 'あいうえお学部',
      department: '広告コミュニケーション学科',
    },
    type: '理系',
    address: '北海道',
    unread: '10',
    experience: [
      {
        route: 'マイナビ2019',
        result: {
          flow: '20本採用＜営業＞',
          rate: '4',
          progress: '一次面接',
          result: '判定中',
        },
      },
    ],
  },
  {
    id: { name: '毎日太郎', id: '01234567890' },
    school: {
      school: 'あいうえおかきくけこ大学',
      faculty: 'あいうえお学部',
      department: '広告コミュニケーション学科',
    },
    type: '理系',
    address: '北海道',
    unread: '10',
    experience: [
      {
        route: 'マイナビ2019',
        result: {
          flow: '20本採用＜営業＞',
          rate: '4',
          progress: '一次面接',
          result: '判定中',
        },
      },
    ],
  },
  {
    id: { name: 'このデータは削除されました', id: '01234567890' },
    school: {},
    type: '',
    address: '',
    unread: '',
    experience: [],
  },
]

const rowsPerPage = 10

export default ({ data = mockData }) => {
  const classes = useStyles()
  const dataLength = data.reduce(
    (result, current) =>
      current.experience.length
        ? (result += current.experience.length)
        : (result += 1),
    0
  )

  const [selectedAccounts, setSelectedAccounts] = useState([] as string[])
  const [page, setPage] = useState(0)
  const startIndex = page * rowsPerPage
  const endIndex =
    startIndex + rowsPerPage > dataLength
      ? dataLength
      : startIndex + rowsPerPage

  const handleSelectAll = () => {
    setSelectedAccounts(() =>
      selectedAccounts.length
        ? []
        : mockData.reduce(
            (result: any, current: any, index) =>
              current.experience.length
                ? [
                    ...result,
                    ...current.experience.map(
                      (expItem: any, expIndex: any) => `${index}-${expIndex}`
                    ),
                  ]
                : [...result, `${index}-0`],
            []
          )
    )
  }

  const handleSelectOne = (id: string) => () => {
    selectedAccounts.includes(id)
      ? setSelectedAccounts(selectedAccounts.filter(i => i !== id))
      : setSelectedAccounts([...selectedAccounts, id])
  }

  const handleChangePage = (data: any) => {
    setPage(data.selected)
  }

  const [bulkChangeState, setBulkChangeState] = useState(false)
  const handleBulkChangeState = () => {
    setBulkChangeState(!bulkChangeState)
  }

  const [otherOperationState, setOtherOperationState] = useState(false)
  const handleOtherOperationState = () => {
    setOtherOperationState(!otherOperationState)
  }

  const handleBackgroundClick = () => {
    setBulkChangeState(false)
  }

  const handleClick = (id: string) => () => {
    window.open(`https://example.com/${id}`)
  }

  return (
    <>
      <section>
        <h1>
          件数：<strong>{dataLength}</strong>件
        </h1>
        <hr />
        {dataLength !== 0 ? (
          <>
            <button type='button' className='btn on-icon icon-changeItem'>
              一覧の表示項目を設定
            </button>
            <div className='group-horizontal'>
              <a href='applicant-search-entry.html' className='btn'>
                エントリーで表示
              </a>
              <a href='applicant-search.html' className='btn current'>
                応募者で表示
              </a>
            </div>
            <div className='selection-table applicant'>
              <div className='other-operation'>
                <button
                  type='button'
                  disabled={selectedAccounts.length ? false : true}
                  onClick={handleOtherOperationState}
                  className={`btn most circular icon-changeProgress ${classes.iconChangeProgress}`}
                  data-action='check-open-change-progress'
                  data-balloon='changeProgress'>
                  合否・選考
                  <br />
                  ステップを
                  <br />
                  一括変更
                </button>
                <button
                  type='button'
                  disabled={selectedAccounts.length ? false : true}
                  onClick={handleBulkChangeState}
                  className='btn most circular icon-otherOperation'
                  data-action='open-balloon-otherOperations'
                  data-balloon='otherOperation'>
                  その他の
                  <br />
                  一括操作
                </button>
              </div>
              <label>
                <input type='checkbox' onClick={handleSelectAll} />
                <mark></mark>全て選択
              </label>
              <hr />
              <label>
                <select>
                  <option>ID順（昇順）</option>
                </select>
                でソート
              </label>
              <Paginate
                pageCount={Math.ceil(dataLength / rowsPerPage)}
                forcePage={page}
                onPageChange={handleChangePage}
              />
              <table className={classes.tableContainer}>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>選択</th>
                    <th>
                      姓名
                      <br />
                      ID
                    </th>
                    <th>
                      学校
                      <br />
                      学部
                      <br />
                      学科
                    </th>
                    <th>
                      文理
                      <br />
                      区分
                    </th>
                    <th>現住所</th>
                    <th>
                      未読
                      <br />
                      メッセージ
                    </th>
                    <th>応募経路</th>
                    <th>
                      選考フロー
                      <br />
                      評価
                      <br />
                      進捗
                      <br />
                      判定
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .map((item, index) =>
                      item.experience.length ? (
                        (item.experience as any).map(
                          (expItem: any, expIndex: any) =>
                            expIndex === 0 ? (
                              <tr
                                key={`${index}-${expIndex}`}
                                onClick={handleClick(item.id.id)}
                                className={
                                  expItem.result.progress.includes('対象外')
                                    ? 'duplicate'
                                    : ''
                                }>
                                <td>
                                  <label>
                                    <input
                                      type='checkbox'
                                      checked={selectedAccounts.includes(
                                        `${index}-${expIndex}`
                                      )}
                                      onChange={handleSelectOne(
                                        `${index}-${expIndex}`
                                      )}
                                    />
                                    <mark></mark>
                                  </label>
                                </td>
                                <td rowSpan={item.experience.length}>
                                  <a href='#'>{item.id.name}</a>({item.id.id})
                                </td>
                                <td rowSpan={item.experience.length}>
                                  {item.school.school}
                                  <br />
                                  {item.school.faculty}
                                  <br />
                                  {item.school.department}
                                </td>
                                <td rowSpan={item.experience.length}>
                                  {item.type}
                                </td>
                                <td rowSpan={item.experience.length}>
                                  {item.address}
                                </td>
                                <td rowSpan={item.experience.length}>
                                  <b>
                                    <a href='#'>{item.unread}</a>
                                  </b>
                                  件
                                </td>
                                <td>
                                  <i className='badge ptn1'>{expItem.route}</i>
                                </td>
                                <td>
                                  <span>{expItem.result.flow}</span>
                                  <input
                                    type='number'
                                    defaultValue={expItem.result.rate}
                                    className='evaluation-meter'
                                  />
                                  <em>{expItem.result.progress}</em>
                                  <span className='group-horizontal'>
                                    <label>
                                      <input
                                        type='radio'
                                        name='select-table-01'
                                        defaultChecked={
                                          expItem.result.result === '判定中'
                                        }
                                      />
                                      <mark>判定中</mark>
                                    </label>
                                    <label>
                                      <input
                                        type='radio'
                                        name='select-table-01'
                                        defaultChecked={
                                          expItem.result.result === '合格'
                                        }
                                      />
                                      <mark>合格</mark>
                                    </label>
                                    <label>
                                      <input
                                        type='radio'
                                        name='select-table-01'
                                        defaultChecked={
                                          expItem.result.result === '不合格'
                                        }
                                      />
                                      <mark>不合格</mark>
                                    </label>
                                  </span>
                                </td>
                              </tr>
                            ) : (
                              <tr
                                onClick={handleClick(item.id.id)}
                                key={`${index}-${expIndex}`}>
                                <td>
                                  <label>
                                    <input
                                      type='checkbox'
                                      checked={selectedAccounts.includes(
                                        `${index}-${expIndex}`
                                      )}
                                      onChange={handleSelectOne(
                                        `${index}-${expIndex}`
                                      )}
                                    />
                                    <mark></mark>
                                  </label>
                                </td>
                                <td>
                                  <i className='badge ptn1'>{expItem.route}</i>
                                </td>
                                <td>
                                  <span>{expItem.result.flow}</span>
                                  <input
                                    type='number'
                                    defaultValue={expItem.result.rate}
                                    className='evaluation-meter'
                                  />
                                  <em>{expItem.result.progress}</em>
                                  <span className='group-horizontal'>
                                    <label>
                                      <input
                                        type='radio'
                                        name='select-table-01'
                                        defaultChecked={
                                          expItem.result.result === '判定中'
                                        }
                                      />
                                      <mark>判定中</mark>
                                    </label>
                                    <label>
                                      <input
                                        type='radio'
                                        name='select-table-01'
                                        defaultChecked={
                                          expItem.result.result === '合格'
                                        }
                                      />
                                      <mark>合格</mark>
                                    </label>
                                    <label>
                                      <input
                                        type='radio'
                                        name='select-table-01'
                                        defaultChecked={
                                          expItem.result.result === '不合格'
                                        }
                                      />
                                      <mark>不合格</mark>
                                    </label>
                                  </span>
                                </td>
                              </tr>
                            )
                        )
                      ) : (
                        <tr
                          onClick={handleClick(item.id.id)}
                          key={`${index}-0`}
                          className='unsubscribe'>
                          <td>
                            <label>
                              <input
                                type='checkbox'
                                checked={selectedAccounts.includes(
                                  `${index}-0`
                                )}
                                onChange={handleSelectOne(`${index}-0`)}
                              />
                              <mark></mark>
                            </label>
                          </td>
                          <td>
                            <a href='#'>{item.id.name}</a>({item.id.id})
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )
                    )
                    .slice(startIndex, endIndex)}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className='selection-table applicant'>
            <div className='other-operation'>
              <button
                type='button'
                disabled={selectedAccounts.length ? false : true}
                onClick={handleOtherOperationState}
                className='btn most circular icon-changeProgress'
                data-action='check-open-change-progress'
                data-balloon='changeProgress'>
                合否・選考
                <br />
                ステップを
                <br />
                一括変更
              </button>
              <button
                type='button'
                disabled={selectedAccounts.length ? false : true}
                onClick={handleBulkChangeState}
                className='btn most circular icon-otherOperation'
                data-action='open-balloon-otherOperations'
                data-balloon='otherOperation'>
                その他の
                <br />
                一括操作
              </button>
            </div>
            <div className={classes.empty}>
              条件に一致する検索結果がありませんでした。
            </div>
          </div>
        )}
      </section>
      <BulkChange type='startJob' source='selection' show={bulkChangeState} />
      <OtherOperation
        show={otherOperationState}
        onClose={handleOtherOperationState}
      />
      <Backdrop
        open={bulkChangeState}
        className={classes.backDrop}
        onClick={handleBackgroundClick}
      />
    </>
  )
}
