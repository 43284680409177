import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
const classificatiList = [
  { name: 'classificationReceived', label: '受信', id: '1' },
  { name: 'classificationSent', label: '送信', id: '2' },
  { name: 'classificationDraft', label: '下書き', id: '4' },
]

const templateOptionList = [
  { value: '0', label: '件名' },
  { value: '1', label: '本文' },
  { value: '2', label: '氏名' },
]

const initialValues = {
  searchTargetList: '0',
  searchWordText: '',
  deleteFromDate: '',
  deleteToDate: '',
  classification: {
    classificationReceived: '0',
    classificationSent: '0',
    classificationReserved: '0',
    classificationDraft: '0',
  },
  sort: '0',
  countPage: 1,
  selectedFlag: 0,
  recruitmentManagementDivision: '',
}

const dustboxSubmitValue = {
  searchTargetList: '0',
  searchWordText: '',
  deleteFromDate: '',
  deleteToDate: '',
  classificationReceived: '0',
  classificationSent: '0',
  classificationReserved: '0',
  classificationDraft: '0',
  sort: '0',
  countPage: 1,
  selectedFlag: 0,
  recruitmentManagementDivision: '',
}

const messageMap: { [key: string]: string } = {
  delete: getMessage(magiContants.MESSAGECODE_MCAZS050_004),
  undo: getMessage(magiContants.MESSAGECODE_MCAZS050_003),
}

export {
  initialValues,
  classificatiList,
  messageMap,
  dustboxSubmitValue,
  templateOptionList,
}
