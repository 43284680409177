import { MCBLS010DeletePdfDownloadRecordRequest } from 'types/MCBLS010/MCBLS010DeletePdfDownloadRecordRequest'
import { MCBLS010PdfDownloadRequest } from 'types/MCBLS010/MCBLS010PdfDownloadRequest'
import { MCBLS010SearchRequest } from 'types/MCBLS010/MCBLS010SearchRequest'
import { MCBLS010TransitionSubmissionRequestListRequest } from 'types/MCBLS010/MCBLS010TransitionSubmissionRequestListRequest'
import { MCBLS010ViewErrorCheckModalRequest } from 'types/MCBLS010/MCBLS010ViewErrorCheckModalRequest'
import request from 'utils/request'

export const mCBLS010InitRequest = (apidata: MCBLS010SearchRequest) =>
  request({
    url: `/MCBLS010/init`,
    method: 'post',
    data: apidata,
  })

  export const searchRequest = (apiData: MCBLS010SearchRequest) =>
  request({
    url: `/MCBLS010/find`,
    method: 'post',
    data: apiData,
  })

  export const outputPdfRequest = (apidata: MCBLS010PdfDownloadRequest) =>
  request({
    url: `/MCBLS010/clickTitleLink`,
    method: 'post',
    responseType: 'blob',
    data:apidata,
  })

  export const clickErrorRequest = (apidata: MCBLS010ViewErrorCheckModalRequest) =>
  request({
    url: `/MCBLS010/clickErrorConfirmButton`,
    method: 'post',
    data:apidata,
  })

  export const clickNumberRequest = (apiData: MCBLS010TransitionSubmissionRequestListRequest) =>
  request({
    url: '/MCBLS010/clickNumbeOfSubmissionsLink',
    method: 'post',
    data: apiData,
  })

  export const deleteRequest = (apiData: MCBLS010DeletePdfDownloadRecordRequest) =>
  request({
    url: '/MCBLS010/clickDeleteButton',
    method: 'post',
    data: apiData,
  })
